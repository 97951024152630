import React from "react";
export default function Six() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_6{left:87px;bottom:58px;}
#t2_6{left:75px;bottom:59px;}
#t3_6{left:87px;bottom:936px;letter-spacing:0.14px;word-spacing:0.12px;}
#t4_6{left:87px;bottom:881px;letter-spacing:-0.16px;word-spacing:4.84px;}
#t5_6{left:87px;bottom:861px;letter-spacing:-0.14px;word-spacing:6.48px;}
#t6_6{left:87px;bottom:840px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_6{left:87px;bottom:812px;letter-spacing:-0.14px;word-spacing:1.93px;}
#t8_6{left:87px;bottom:791px;letter-spacing:-0.14px;word-spacing:-1.87px;}
#t9_6{left:87px;bottom:771px;letter-spacing:-0.15px;word-spacing:-0.53px;}
#ta_6{left:87px;bottom:751px;letter-spacing:-0.16px;word-spacing:4.29px;}
#tb_6{left:87px;bottom:731px;letter-spacing:-0.18px;word-spacing:0.09px;}
#tc_6{left:147px;bottom:702px;letter-spacing:-0.14px;word-spacing:9.37px;}
#td_6{left:147px;bottom:682px;letter-spacing:-0.19px;word-spacing:0.1px;}
#te_6{left:147px;bottom:653px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tf_6{left:147px;bottom:624px;letter-spacing:-0.14px;word-spacing:4.05px;}
#tg_6{left:147px;bottom:604px;letter-spacing:-0.14px;}
#th_6{left:147px;bottom:575px;letter-spacing:-0.17px;word-spacing:3.05px;}
#ti_6{left:147px;bottom:555px;letter-spacing:-0.1px;word-spacing:0.02px;}
#tj_6{left:147px;bottom:526px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tk_6{left:147px;bottom:497px;letter-spacing:-0.15px;word-spacing:-0.58px;}
#tl_6{left:147px;bottom:477px;letter-spacing:-0.14px;}
#tm_6{left:147px;bottom:448px;letter-spacing:-0.13px;word-spacing:3.24px;}
#tn_6{left:147px;bottom:428px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_6{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_6{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_6{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_6{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_6{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts6" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg6" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="6/6.svg"
          type="image/svg+xml"
          id="pdf6"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_6" class="t s1_6">
          6{" "}
        </span>
        <span id="t3_6" class="t s2_6">
          ЗӨВЛӨМЖИЙГ ХЭРХЭН АШИГЛАХ ВЭ?{" "}
        </span>
        <span id="t4_6" class="t s3_6">
          Зөвлөмжийн гол хэрэглэгчид нь сургуулийн удирдлага (захирал болон{" "}
        </span>
        <span id="t5_6" class="t s3_6">
          бусад); дүүрэг, бүс нутаг, орон нутгийн боловсролын байгууллагын{" "}
        </span>
        <span id="t6_6" class="t s3_6">
          ажилтнууд болно.{" "}
        </span>
        <span id="t7_6" class="t s4_6">
          Энэхүү зөвлөмж нь сургуулийн удирдлагын өнөөгийн бодлого, практикийг{" "}
        </span>
        <span id="t8_6" class="t s4_6">
          эргэн харах, цаашид хэрхэн ажиллах талаар нийтлэг ойлголтыг бий
          болгоход{" "}
        </span>
        <span id="t9_6" class="t s4_6">
          туслах зорилготой юм. Сургуулийн менежментийн талаар ойлгохоос эхлээд{" "}
        </span>
        <span id="ta_6" class="t s4_6">
          өөрийгөө үнэлэх, асуудлаа эрэмбэлэх, уялдаатай стратеги төлөвлөхөөс{" "}
        </span>
        <span id="tb_6" class="t s4_6">
          эхлээд суралцах болно. Тухайлбал,{" "}
        </span>
        <span id="tc_6" class="t s4_6">
          Улс орны өнөөгийн нөхцөлд юуг онцлох, хэрхэн тасралтгүй{" "}
        </span>
        <span id="td_6" class="t s4_6">
          тогтвортой авч явах вэ?{" "}
        </span>
        <span id="te_6" class="t s4_6">
          ЕБС-ийн сургалтын байгууллагын менежментийн зураглал{" "}
        </span>
        <span id="tf_6" class="t s4_6">
          Танхимын болон онлайн сургалтын хослол, түүнийг хэрэгжүүлэх{" "}
        </span>
        <span id="tg_6" class="t s4_6">
          менежмент{" "}
        </span>
        <span id="th_6" class="t s4_6">
          Нэвтэд нь авч үзэх хөндлөн холбоотой ямар асуудлуудыг (cross-{" "}
        </span>
        <span id="ti_6" class="t s4_6">
          cutting issues) сонгох вэ?{" "}
        </span>
        <span id="tj_6" class="t s4_6">
          Насан туршийн суралцахуй{" "}
        </span>
        <span id="tk_6" class="t s4_6">
          Боловсролын чанар - суралцахуйн чанарыг тэргүүлэх чиглэл болгох{" "}
        </span>
        <span id="tl_6" class="t s4_6">
          ЯАЖ?{" "}
        </span>
        <span id="tm_6" class="t s4_6">
          Байгууллагын болон сурагчдын гүйцэтгэлийн үнэлгээний үр дүнд{" "}
        </span>
        <span id="tn_6" class="t s4_6">
          тулгуурлан сургуулийн менежментээ хэрхэн сайжруулах вэ?{" "}
        </span>
      </div>
    </div>
  );
}
