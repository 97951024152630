import React from "react";
export default function SixtyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_65{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_65{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_65{left:171px;bottom:945px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t4_65{left:87px;bottom:654px;letter-spacing:-0.14px;word-spacing:4.61px;}
#t5_65{left:87px;bottom:633px;letter-spacing:-0.17px;word-spacing:6.67px;}
#t6_65{left:87px;bottom:613px;letter-spacing:-0.15px;word-spacing:3.48px;}
#t7_65{left:87px;bottom:593px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_65{left:126px;bottom:560px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t9_65{left:88px;bottom:278px;letter-spacing:0.14px;word-spacing:0.33px;}
#ta_65{left:510px;bottom:415px;letter-spacing:0.12px;}
#tb_65{left:526px;bottom:396px;letter-spacing:0.1px;}
#tc_65{left:87px;bottom:253px;letter-spacing:-0.15px;word-spacing:10.86px;}
#td_65{left:87px;bottom:232px;letter-spacing:-0.11px;word-spacing:0.02px;}
#te_65{left:87px;bottom:199px;letter-spacing:-0.38px;}
#tf_65{left:113px;bottom:199px;letter-spacing:-0.45px;word-spacing:-0.97px;}
#tg_65{left:113px;bottom:179px;letter-spacing:-0.49px;word-spacing:5.57px;}
#th_65{left:113px;bottom:159px;letter-spacing:-0.51px;word-spacing:0.08px;}
#ti_65{left:87px;bottom:139px;letter-spacing:-0.55px;}
#tj_65{left:113px;bottom:139px;letter-spacing:-0.68px;word-spacing:2.02px;}
#tk_65{left:113px;bottom:119px;letter-spacing:-0.63px;word-spacing:0.04px;}

.s0_65{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_65{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_65{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_65{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_65{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s5_65{font-size:14px;font-family:Arial_gu;color:#000;}
.s6_65{font-size:14px;font-family:Calibri_h0;color:#000;}
.s7_65{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.t.m0_65{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts65" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: Arial_gu;
	src: url("fonts/Arial_gu.woff") format("woff");
}

@font-face {
	font-family: Calibri_h0;
	src: url("fonts/Calibri_h0.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg65" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="65/65.svg"
          type="image/svg+xml"
          id="pdf65"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_65" class="t s1_65">
          65{" "}
        </span>
        <span id="t3_65" class="t s2_65">
          Зураг 2.15. Хөтөлбөрийн хэрэгжилтийн үр дүн (агуулгаар, ангиар){" "}
        </span>
        <span id="t4_65" class="t s3_65">
          Багшийн хичээл заадаг ангийн суралцагчдын гүйцэтгэл ямар агуулгаар{" "}
        </span>
        <span id="t5_65" class="t s3_65">
          хамгийн өндөр болон хамгийн сул байгааг тогтооно. Тухайн агуулгад{" "}
        </span>
        <span id="t6_65" class="t s3_65">
          хамаарах гүйцэтгэлийг оноор харьцуулан харна. Тухайн багшийн заадаг{" "}
        </span>
        <span id="t7_65" class="t s3_65">
          ангийн ямар агуулгад дэмжлэг хэрэгцээтэй байгааг тодорхойлоорой.{" "}
        </span>
        <span id="t8_65" class="t s4_65">
          Зураг 2.16. Хөтөлбөрийн хэрэгжилтийн үр дүн (үнэлгээний зорилтоор,
          багшаар){" "}
        </span>
        <span id="t9_65" class="t s5_65">
          Хөтөлбөрийн хэрэгжилтийг судлагдахууны агуулга, анги, үнэлгээний
          зорилтоор нягталж{" "}
        </span>
        <span id="ta_65" class="t s6_65">
          Багшийн{" "}
        </span>
        <span id="tb_65" class="t s6_65">
          нэр{" "}
        </span>
        <span id="tc_65" class="t s3_65">
          Хөтөлбөрийн хэрэгжилтийг судлагдахууны агуулга, анги, үнэлгээний{" "}
        </span>
        <span id="td_65" class="t s3_65">
          зорилтоор нягталж харахдаа дараах зүйлсийг анхаарна. Үүнд:{" "}
        </span>
        <span id="te_65" class="t s7_65">
          1.{" "}
        </span>
        <span id="tf_65" class="t s3_65">
          Анги бүрийн шалгалтын үр дүнг 2 оноор харьцуулан харна. Ингэхдээ
          тухайн{" "}
        </span>
        <span id="tg_65" class="t s3_65">
          ангийн шалгалтын гүйцэтгэлийн дундаж үзүүлэлт ямар судлагдахууны{" "}
        </span>
        <span id="th_65" class="t s3_65">
          шалгалтын гүйцэтгэлээр тодорхойлогдож буйг тодруулж хараарай.{" "}
        </span>
        <span id="ti_65" class="t s7_65">
          2.{" "}
        </span>
        <span id="tj_65" class="t s3_65">
          Агуулгын үр дүнг судлагдахуун бүрийн үнэлгээний зорилт (мэдлэг
          ойлголт,{" "}
        </span>
        <span id="tk_65" class="t s3_65">
          чадвар, хэрэглээ)-оор гүйцэтгэлийн дунджийг харьцуулан харна.{" "}
        </span>
      </div>
    </div>
  );
}
