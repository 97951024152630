import React from "react";
export default function F160() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_160{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_160{left:216px;bottom:959px;letter-spacing:-0.07px;}
#t3_160{left:300px;bottom:959px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t4_160{left:87px;bottom:820px;letter-spacing:-0.17px;word-spacing:6.85px;}
#t5_160{left:87px;bottom:799px;letter-spacing:-0.13px;word-spacing:1.36px;}
#t6_160{left:87px;bottom:779px;letter-spacing:-0.14px;word-spacing:4.3px;}
#t7_160{left:595px;bottom:779px;letter-spacing:-0.12px;word-spacing:4.31px;}
#t8_160{left:87px;bottom:759px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_160{left:87px;bottom:726px;letter-spacing:-0.14px;word-spacing:1.8px;}
#ta_160{left:87px;bottom:706px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tb_160{left:102px;bottom:670px;letter-spacing:0.07px;word-spacing:0.07px;}
#tc_160{left:237px;bottom:653px;letter-spacing:0.12px;word-spacing:0.03px;}
#td_160{left:116px;bottom:632px;letter-spacing:0.08px;word-spacing:0.06px;}
#te_160{left:198px;bottom:615px;letter-spacing:0.1px;}
#tf_160{left:467px;bottom:624px;letter-spacing:0.08px;word-spacing:0.06px;}
#tg_160{left:101px;bottom:594px;letter-spacing:0.15px;word-spacing:-0.01px;}
#th_160{left:187px;bottom:577px;letter-spacing:0.14px;}
#ti_160{left:387px;bottom:594px;letter-spacing:0.16px;word-spacing:-0.01px;}
#tj_160{left:482px;bottom:577px;letter-spacing:0.14px;}
#tk_160{left:113px;bottom:449px;letter-spacing:0.07px;word-spacing:0.07px;}
#tl_160{left:195px;bottom:433px;letter-spacing:0.14px;}
#tm_160{left:447px;bottom:441px;letter-spacing:0.06px;word-spacing:0.09px;}
#tn_160{left:106px;bottom:410px;letter-spacing:0.17px;word-spacing:-0.02px;}
#to_160{left:224px;bottom:410px;letter-spacing:0.14px;}
#tp_160{left:191px;bottom:393px;letter-spacing:0.14px;}
#tq_160{left:395px;bottom:410px;letter-spacing:0.14px;word-spacing:-0.01px;}
#tr_160{left:482px;bottom:393px;letter-spacing:0.14px;}
#ts_160{left:227px;bottom:263px;letter-spacing:0.09px;word-spacing:0.05px;}
#tt_160{left:95px;bottom:234px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_160{left:386px;bottom:242px;letter-spacing:0.1px;word-spacing:0.04px;}
#tv_160{left:460px;bottom:225px;letter-spacing:0.06px;word-spacing:0.09px;}

.s0_160{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_160{font-size:17px;font-family:Calibri_1b4;color:#000;}
.s2_160{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_160{font-size:15px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts160" type="text/css">
        @
        {`font-face {
	font-family: Calibri_1b4;
	src: url("fonts/Calibri_1b4.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg160" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="160/160.svg"
          type="image/svg+xml"
          id="pdf160"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_160" class="t s0_160">
          160{" "}
        </span>
        <span id="t2_160" class="t s1_160">
          Зураг 3.31.{" "}
        </span>
        <span id="t3_160" class="t s1_160">
          Сургалтын орчны үнэлгээний цикл{" "}
        </span>
        <span id="t4_160" class="t s2_160">
          Тиймээс цаашид сургалтын гадаад, дотоод орчны үнэлгээг дараах 2{" "}
        </span>
        <span id="t5_160" class="t s2_160">
          чиглэлээр, 1) эрүүл, аюулгүй байх талаас нь эрсдэлийн үнэлгээ, 2)
          эрүүл,{" "}
        </span>
        <span id="t6_160" class="t s2_160">
          тав тухтай байх, хөтөлбөрийг хэрэгжүүлэх сургалтын орчин{" "}
        </span>
        <span id="t7_160" class="t s2_160">
          талаас нь{" "}
        </span>
        <span id="t8_160" class="t s2_160">
          хийхийг зөвлөж байна.{" "}
        </span>
        <span id="t9_160" class="t s2_160">
          Дараах үнэлгээний хуудас, зөвлөмжийг ашиглан сургуулийн орчны эрүүл,{" "}
        </span>
        <span id="ta_160" class="t s2_160">
          аюулгүй байдалд өөрийн үнэлгээг хийж болно. Үүнд:{" "}
        </span>
        <span id="tb_160" class="t s3_160">
          Сургууль, дотуур байрны эрүүл ахуй, аюулгүй орчны талаарх стандарт,
          дүрэм,{" "}
        </span>
        <span id="tc_160" class="t s3_160">
          журамд баримжаалсан хяналтын хуудас{" "}
        </span>
        <span id="td_160" class="t s3_160">
          Сургалтын орчин, дотуур байрын{" "}
        </span>
        <span id="te_160" class="t s3_160">
          үйлчилгээ{" "}
        </span>
        <span id="tf_160" class="t s3_160">
          Сургалтын орчин{" "}
        </span>
        <span id="tg_160" class="t s3_160">
          БШУ-ны Сайдын тушаал. 2023.03.28.{" "}
        </span>
        <span id="th_160" class="t s3_160">
          Дугаар А/127{" "}
        </span>
        <span id="ti_160" class="t s3_160">
          БCШУC-ны Сайдын тушаал 2019.12.27.{" "}
        </span>
        <span id="tj_160" class="t s3_160">
          Дугаар А/803{" "}
        </span>
        <span id="tk_160" class="t s3_160">
          Дотуур байр, хоол үйлдвэрлэлийн{" "}
        </span>
        <span id="tl_160" class="t s3_160">
          эрүүл ахуй{" "}
        </span>
        <span id="tm_160" class="t s3_160">
          Галын аюулгүй байдал{" "}
        </span>
        <span id="tn_160" class="t s3_160">
          ЭМ-ын Сайдын{" "}
        </span>
        <span id="to_160" class="t s3_160">
          тушаал.2023.03.06.{" "}
        </span>
        <span id="tp_160" class="t s3_160">
          Дугаар А/78{" "}
        </span>
        <span id="tq_160" class="t s3_160">
          ОБЕГ-ын даргын тушаал. 2020.10.28.{" "}
        </span>
        <span id="tr_160" class="t s3_160">
          Дугаар А/235{" "}
        </span>
        <span id="ts_160" class="t s3_160">
          Аюулгүй байдлыг үнэлэх арга зүй, зөвлөмж{" "}
        </span>
        <span id="tt_160" class="t s3_160">
          Гамшгийн үнэлгээний ерөнхий арга зүй{" "}
        </span>
        <span id="tu_160" class="t s3_160">
          Сургуулийн орчинд гамшгийн эрсдэлийг{" "}
        </span>
        <span id="tv_160" class="t s3_160">
          бууруулах зөвлөмж{" "}
        </span>
      </div>
    </div>
  );
}
