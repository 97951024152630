import React from "react";
export default function HundredTwentyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_125{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_125{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:2.46px;}
#t3_125{left:87px;bottom:921px;letter-spacing:-0.08px;word-spacing:-0.02px;}
#t4_125{left:113px;bottom:894px;}
#t5_125{left:139px;bottom:894px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t6_125{left:113px;bottom:871px;}
#t7_125{left:139px;bottom:871px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t8_125{left:113px;bottom:848px;}
#t9_125{left:139px;bottom:848px;letter-spacing:-0.13px;word-spacing:6.41px;}
#ta_125{left:139px;bottom:830px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tb_125{left:113px;bottom:807px;}
#tc_125{left:139px;bottom:807px;letter-spacing:-0.12px;word-spacing:0.02px;}
#td_125{left:113px;bottom:785px;}
#te_125{left:139px;bottom:785px;letter-spacing:-0.14px;word-spacing:4.02px;}
#tf_125{left:139px;bottom:766px;letter-spacing:-0.07px;}
#tg_125{left:87px;bottom:724px;letter-spacing:-0.16px;word-spacing:1.19px;}
#th_125{left:87px;bottom:703px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ti_125{left:87px;bottom:656px;letter-spacing:0.13px;word-spacing:0.05px;}
#tj_125{left:353px;bottom:628px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tk_125{left:91px;bottom:595px;letter-spacing:-0.19px;word-spacing:0.08px;}
#tl_125{left:303px;bottom:595px;letter-spacing:-0.22px;word-spacing:0.1px;}
#tm_125{left:465px;bottom:595px;letter-spacing:-0.2px;word-spacing:0.06px;}
#tn_125{left:91px;bottom:573px;letter-spacing:-0.17px;word-spacing:0.05px;}
#to_125{left:91px;bottom:556px;letter-spacing:-0.19px;}
#tp_125{left:162px;bottom:556px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tq_125{left:91px;bottom:540px;letter-spacing:-0.23px;word-spacing:0.12px;}
#tr_125{left:91px;bottom:523px;letter-spacing:-0.1px;}
#ts_125{left:91px;bottom:498px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tt_125{left:229px;bottom:498px;letter-spacing:-0.12px;}
#tu_125{left:91px;bottom:482px;letter-spacing:-0.19px;word-spacing:0.07px;}
#tv_125{left:91px;bottom:465px;letter-spacing:-0.21px;word-spacing:0.09px;}
#tw_125{left:91px;bottom:449px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tx_125{left:91px;bottom:432px;letter-spacing:-0.11px;}
#ty_125{left:91px;bottom:407px;letter-spacing:-0.21px;word-spacing:0.1px;}
#tz_125{left:91px;bottom:390px;letter-spacing:-0.23px;word-spacing:0.11px;}
#t10_125{left:91px;bottom:374px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t11_125{left:234px;bottom:374px;letter-spacing:-0.06px;}
#t12_125{left:253px;bottom:374px;}
#t13_125{left:91px;bottom:349px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t14_125{left:91px;bottom:332px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t15_125{left:91px;bottom:316px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t16_125{left:91px;bottom:299px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t17_125{left:91px;bottom:283px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t18_125{left:303px;bottom:573px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t19_125{left:303px;bottom:556px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1a_125{left:303px;bottom:531px;letter-spacing:-0.17px;}
#t1b_125{left:303px;bottom:506px;letter-spacing:-0.11px;}
#t1c_125{left:303px;bottom:481px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t1d_125{left:303px;bottom:456px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t1e_125{left:303px;bottom:430px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1f_125{left:303px;bottom:414px;letter-spacing:-0.22px;}
#t1g_125{left:303px;bottom:389px;letter-spacing:-0.12px;}
#t1h_125{left:303px;bottom:372px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1i_125{left:303px;bottom:347px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1j_125{left:303px;bottom:331px;letter-spacing:-0.21px;word-spacing:0.06px;}
#t1k_125{left:303px;bottom:305px;letter-spacing:-0.17px;word-spacing:0.03px;}
#t1l_125{left:465px;bottom:573px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1m_125{left:465px;bottom:556px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1n_125{left:465px;bottom:540px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1o_125{left:465px;bottom:523px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t1p_125{left:465px;bottom:507px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1q_125{left:465px;bottom:482px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1r_125{left:465px;bottom:465px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1s_125{left:465px;bottom:449px;letter-spacing:-0.22px;}
#t1t_125{left:465px;bottom:423px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t1u_125{left:465px;bottom:407px;letter-spacing:-0.12px;}
#t1v_125{left:465px;bottom:382px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t1w_125{left:465px;bottom:357px;letter-spacing:-0.28px;word-spacing:0.15px;}
#t1x_125{left:465px;bottom:331px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t1y_125{left:465px;bottom:315px;letter-spacing:-0.15px;}
#t1z_125{left:465px;bottom:290px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t20_125{left:87px;bottom:234px;letter-spacing:-0.13px;word-spacing:-1.71px;}
#t21_125{left:87px;bottom:213px;letter-spacing:-0.13px;word-spacing:5.62px;}
#t22_125{left:87px;bottom:193px;letter-spacing:-0.15px;word-spacing:-0.17px;}
#t23_125{left:87px;bottom:173px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t24_125{left:87px;bottom:153px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_125{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_125{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_125{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_125{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_125{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts125" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg125" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="125/125.svg"
          type="image/svg+xml"
          id="pdf125"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_125" class="t s0_125">
          125{" "}
        </span>
        <span id="t2_125" class="t s1_125">
          үйл явц юм. Явцын үнэлгээ нь сурах үйл ажиллагаанд дараах байдлаар{" "}
        </span>
        <span id="t3_125" class="t s1_125">
          дэмжлэг үзүүлдэг: Үүнд{" "}
        </span>
        <span id="t4_125" class="t s2_125">
          •{" "}
        </span>
        <span id="t5_125" class="t s1_125">
          сурагчидтайгаа суралцахуйн зорилгоо ярилцах{" "}
        </span>
        <span id="t6_125" class="t s2_125">
          •{" "}
        </span>
        <span id="t7_125" class="t s1_125">
          сурагчид юу мэдэж, чадаж байгааг ойлгох, хэлэх{" "}
        </span>
        <span id="t8_125" class="t s2_125">
          •{" "}
        </span>
        <span id="t9_125" class="t s1_125">
          тухайн даалгавар үйл ажиллагааны хүрэх үр дүн, хийсэн байх{" "}
        </span>
        <span id="ta_125" class="t s1_125">
          шалгуур ямар байдаг талаар сурагчид ойлгоход туслах{" "}
        </span>
        <span id="tb_125" class="t s2_125">
          •{" "}
        </span>
        <span id="tc_125" class="t s1_125">
          өөрийн болон анги хамт олны үнэлгээг дэмжих{" "}
        </span>
        <span id="td_125" class="t s2_125">
          •{" "}
        </span>
        <span id="te_125" class="t s1_125">
          сурагчдын цаашид хийх алхмуудыг нарийвчлан чиглүүлсэн хариу{" "}
        </span>
        <span id="tf_125" class="t s1_125">
          өгөх.{" "}
        </span>
        <span id="tg_125" class="t s1_125">
          Өдөр тутмын үйл ажиллагаанд тулгуурлан сурагчдын ахиц, амжилт, сурах{" "}
        </span>
        <span id="th_125" class="t s1_125">
          үйл ажиллагааны үр дүнг харуулахад дараах арга замыг ашиглаж болно.{" "}
        </span>
        <span id="ti_125" class="t s3_125">
          Явцын үнэлгээг хэрэгжүүлэх арга зам{" "}
        </span>
        <span id="tj_125" class="t s4_125">
          Хүснэгт 3.11 Явцын үнэлгээг хэрэгжүүлэх арга зам{" "}
        </span>
        <span id="tk_125" class="t s4_125">
          Арга/ техник{" "}
        </span>
        <span id="tl_125" class="t s4_125">
          Ажиглалт, тэмдэглэгээ{" "}
        </span>
        <span id="tm_125" class="t s4_125">
          Хариу мэдээлэл{" "}
        </span>
        <span id="tn_125" class="t s4_125">
          Богино хугацаанд мэдээлэл{" "}
        </span>
        <span id="to_125" class="t s4_125">
          цуглуулах{" "}
        </span>
        <span id="tp_125" class="t s4_125">
          (Асуулт асуух, гар,{" "}
        </span>
        <span id="tq_125" class="t s4_125">
          эрхий хуруу, гэрлэн дохио{" "}
        </span>
        <span id="tr_125" class="t s4_125">
          өргөх... ){" "}
        </span>
        <span id="ts_125" class="t s4_125">
          Ойлголтыг илрүүлэх{" "}
        </span>
        <span id="tt_125" class="t s4_125">
          (оюуны{" "}
        </span>
        <span id="tu_125" class="t s4_125">
          зураглал, KWL, шат ба{" "}
        </span>
        <span id="tv_125" class="t s4_125">
          жагсаалт, венийн диаграмм,{" "}
        </span>
        <span id="tw_125" class="t s4_125">
          мэдлэгийн картууд, урсгал{" "}
        </span>
        <span id="tx_125" class="t s4_125">
          диаграмм ... ){" "}
        </span>
        <span id="ty_125" class="t s4_125">
          Хичээлийн төгсгөлд (дүгнэлт,{" "}
        </span>
        <span id="tz_125" class="t s4_125">
          тэмдэглэл, ойлголтын тойрог,{" "}
        </span>
        <span id="t10_125" class="t s4_125">
          эргэцүүлэх тэмдэглэл{" "}
        </span>
        <span id="t11_125" class="t s4_125">
          ....{" "}
        </span>
        <span id="t12_125" class="t s4_125">
          ){" "}
        </span>
        <span id="t13_125" class="t s4_125">
          Хичээлийн дараа (гэрийн{" "}
        </span>
        <span id="t14_125" class="t s4_125">
          даалгавар, бүтээлийн сан, бие{" "}
        </span>
        <span id="t15_125" class="t s4_125">
          даалт, даалгавар, төсөлт үйл{" "}
        </span>
        <span id="t16_125" class="t s4_125">
          ажиллагааны явц, багийн ажил{" "}
        </span>
        <span id="t17_125" class="t s4_125">
          дүгнэлт ... ){" "}
        </span>
        <span id="t18_125" class="t s4_125">
          Арга техникийг{" "}
        </span>
        <span id="t19_125" class="t s4_125">
          хэрэгжүүлэх алхам{" "}
        </span>
        <span id="t1a_125" class="t s4_125">
          Шалгуур{" "}
        </span>
        <span id="t1b_125" class="t s4_125">
          Рубрик{" "}
        </span>
        <span id="t1c_125" class="t s4_125">
          Ажиглалтын хуудас{" "}
        </span>
        <span id="t1d_125" class="t s4_125">
          Асуулгын хуудас{" "}
        </span>
        <span id="t1e_125" class="t s4_125">
          Өөрийн үнэлгээний{" "}
        </span>
        <span id="t1f_125" class="t s4_125">
          хуудас{" "}
        </span>
        <span id="t1g_125" class="t s4_125">
          Үе тэнгийнхний{" "}
        </span>
        <span id="t1h_125" class="t s4_125">
          үнэлгээний хуудас{" "}
        </span>
        <span id="t1i_125" class="t s4_125">
          Бие даан гүйцэтгэх{" "}
        </span>
        <span id="t1j_125" class="t s4_125">
          ажлын удирдамж{" "}
        </span>
        <span id="t1k_125" class="t s4_125">
          Ярилцлагын асуултууд{" "}
        </span>
        <span id="t1l_125" class="t s4_125">
          Үнэлгээний төлөвлөлт /хугацаа{" "}
        </span>
        <span id="t1m_125" class="t s4_125">
          агуулгын хүрээ, арга хэлбэр/-{" "}
        </span>
        <span id="t1n_125" class="t s4_125">
          сурагч эцэг эхэд нээлттэй{" "}
        </span>
        <span id="t1o_125" class="t s4_125">
          байх Шалгуур тодорхой байх /{" "}
        </span>
        <span id="t1p_125" class="t s4_125">
          гүйцэтгэлийн түвшин, /{" "}
        </span>
        <span id="t1q_125" class="t s4_125">
          Алхам тус бүрийн гүйцэтгэл-,{" "}
        </span>
        <span id="t1r_125" class="t s4_125">
          өөрийн үнэлгээний шалгах{" "}
        </span>
        <span id="t1s_125" class="t s4_125">
          хуудас{" "}
        </span>
        <span id="t1t_125" class="t s4_125">
          Даалгаврыг үнэлэх аргачлалд{" "}
        </span>
        <span id="t1u_125" class="t s4_125">
          үндэслэсэн зөвлөмж{" "}
        </span>
        <span id="t1v_125" class="t s4_125">
          Даалгаврын анализ, зөвлөмж{" "}
        </span>
        <span id="t1w_125" class="t s4_125">
          Дүгнэлт, зөвлөмж{" "}
        </span>
        <span id="t1x_125" class="t s4_125">
          Багшийн чиглүүлэг, дэмжлэг{" "}
        </span>
        <span id="t1y_125" class="t s4_125">
          зөвлөгөө,{" "}
        </span>
        <span id="t1z_125" class="t s4_125">
          Алдааны шинжилгээ, тайлбар{" "}
        </span>
        <span id="t20_125" class="t s1_125">
          Явцын үнэлгээг хэрэгжүүлэх дээр дурьдсан арга техник, ажиглалт{" "}
        </span>
        <span id="t21_125" class="t s1_125">
          тэмдэглэгээ, хариу мэдээлэл өгөх хэлбэрүүдээс тус бүр нэг{" "}
        </span>
        <span id="t22_125" class="t s1_125">
          жишээ орууллаа.Үүнийг сургуулын удирдах ажилтан багш нарын{" "}
        </span>
        <span id="t23_125" class="t s1_125">
          хамт хэрэгжүүлэх боломжтой.{" "}
        </span>
        <span id="t24_125" class="t s1_125">
          QR кодыг уншуулан дэлгэрүүлж судлаарай{" "}
        </span>
      </div>
    </div>
  );
}
