import React from "react";
export default function ThirtyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_38{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_38{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_38{left:107px;bottom:925px;letter-spacing:0.07px;word-spacing:0.07px;}
#t4_38{left:107px;bottom:907px;letter-spacing:0.04px;word-spacing:-1.11px;}
#t5_38{left:107px;bottom:888px;letter-spacing:0.07px;word-spacing:-0.31px;}
#t6_38{left:107px;bottom:870px;letter-spacing:0.09px;word-spacing:-1.51px;}
#t7_38{left:107px;bottom:852px;letter-spacing:0.1px;word-spacing:1.15px;}
#t8_38{left:107px;bottom:833px;letter-spacing:0.06px;word-spacing:4.18px;}
#t9_38{left:107px;bottom:815px;letter-spacing:0.06px;word-spacing:0.45px;}
#ta_38{left:107px;bottom:797px;letter-spacing:0.12px;word-spacing:0.01px;}
#tb_38{left:107px;bottom:770px;letter-spacing:0.09px;word-spacing:0.05px;}
#tc_38{left:107px;bottom:751px;letter-spacing:0.15px;word-spacing:2.89px;}
#td_38{left:107px;bottom:733px;letter-spacing:0.01px;word-spacing:0.13px;}
#te_38{left:107px;bottom:706px;letter-spacing:0.1px;word-spacing:0.68px;}
#tf_38{left:107px;bottom:688px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_38{left:107px;bottom:669px;letter-spacing:0.02px;word-spacing:0.11px;}
#th_38{left:87px;bottom:592px;letter-spacing:-0.14px;word-spacing:4.13px;}
#ti_38{left:87px;bottom:572px;letter-spacing:-0.15px;word-spacing:4.89px;}
#tj_38{left:87px;bottom:552px;letter-spacing:-0.14px;word-spacing:11.13px;}
#tk_38{left:87px;bottom:532px;letter-spacing:-0.16px;word-spacing:1.28px;}
#tl_38{left:87px;bottom:512px;letter-spacing:-0.13px;word-spacing:2.36px;}
#tm_38{left:87px;bottom:492px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tn_38{left:285px;bottom:463px;letter-spacing:-0.24px;word-spacing:0.11px;}

.s0_38{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_38{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_38{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_38{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_38{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_38{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts38" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg38" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="38/38.svg"
          type="image/svg+xml"
          id="pdf38"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_38" class="t s1_38">
          38{" "}
        </span>
        <span id="t3_38" class="t s2_38">
          А. Алсын харааг хэрхэн тодорхойлох вэ?{" "}
        </span>
        <span id="t4_38" class="t s3_38">
          Х. Энэ нь тодорхой үе шаттай үйл явц байдаг. Тухайлбал, сурагчид,
          сургуулийн{" "}
        </span>
        <span id="t5_38" class="t s3_38">
          өмнө тулгарсан суурь асуудал, сурагчдын хүлээлт, тэдний хөгжлийн
          ирээдүйн{" "}
        </span>
        <span id="t6_38" class="t s3_38">
          чиг хандлагыг судлахаас эхэлж, оролцогчдын санал бодлыг цуглуулах,
          нэгтгэх,{" "}
        </span>
        <span id="t7_38" class="t s3_38">
          эцэслэн томьёолох хүртэл менежмент зохион байгуулалтын үйл ажиллагааг{" "}
        </span>
        <span id="t8_38" class="t s3_38">
          байдаг. Алсын харааг сурагч, багш, хамт олон, бусад оролцогч талуудад{" "}
        </span>
        <span id="t9_38" class="t s3_38">
          ойлгуулан таниулж, итгэл үнэмшил болгож, сургуулийн зорилго, зорилт,
          өдөр{" "}
        </span>
        <span id="ta_38" class="t s3_38">
          тутмын СҮА-нд тусгаж хэрэгжүүлэх нь чухал.{" "}
        </span>
        <span id="tb_38" class="t s2_38">
          А. Алсын харааг хэзээ тодорхойлох вэ?{" "}
        </span>
        <span id="tc_38" class="t s3_38">
          Х. Өмнөх алсын хараандаа хүрсэн эсэхээ дүгнэж, дараагийн 5-10 жилийн{" "}
        </span>
        <span id="td_38" class="t s3_38">
          алсын хараагаа тодорхойлдог.{" "}
        </span>
        <span id="te_38" class="t s2_38">
          А. Сургуулийн алсын харааг тодорхойлох ажлын удирдан зохион байгуулж,{" "}
        </span>
        <span id="tf_38" class="t s2_38">
          нэгтгэн илэрхийлэх нь хэний үүрэг хариуцлага вэ?{" "}
        </span>
        <span id="tg_38" class="t s3_38">
          Х. Захирлын үүрэг, хариуцлага.{" "}
        </span>
        <span id="th_38" class="t s4_38">
          Өмнө танилцуулсан менежментийн гурван давхаргат пирамид загварын{" "}
        </span>
        <span id="ti_38" class="t s4_38">
          суурийн давхаргад сургуулиуд менежментээ хэрхэн сайжруулах талаар{" "}
        </span>
        <span id="tj_38" class="t s4_38">
          тодорхой алхам, дараалал бүхий аргачлал, жишээг энэхүү хэсэгт{" "}
        </span>
        <span id="tk_38" class="t s4_38">
          танилцуулж байна. Энэ нь сургуулийн удирдлагууд санаа авч, өөр өөрийн{" "}
        </span>
        <span id="tl_38" class="t s4_38">
          сургуулийн нэг хичээлийн жилийн менежментийг хэрэгжүүлэх зураглалаа{" "}
        </span>
        <span id="tm_38" class="t s4_38">
          боловсруулахад дөхөм болно гэж найдаж байна.{" "}
        </span>
        <span id="tn_38" class="t s5_38">
          Зураг 1.11. Талуудын оролцоо{" "}
        </span>
      </div>
    </div>
  );
}
