import React from "react";
export default function OneHundredEightyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_183{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_183{left:106px;bottom:919px;letter-spacing:0.15px;word-spacing:4.28px;}
#t3_183{left:190px;bottom:919px;letter-spacing:0.11px;word-spacing:4.32px;}
#t4_183{left:106px;bottom:900px;letter-spacing:0.1px;word-spacing:2.04px;}
#t5_183{left:106px;bottom:882px;letter-spacing:0.12px;word-spacing:4.9px;}
#t6_183{left:106px;bottom:864px;letter-spacing:0.06px;word-spacing:0.08px;}
#t7_183{left:132px;bottom:845px;letter-spacing:0.05px;word-spacing:0.08px;}
#t8_183{left:132px;bottom:827px;letter-spacing:0.11px;word-spacing:0.03px;}
#t9_183{left:132px;bottom:809px;letter-spacing:0.09px;word-spacing:0.05px;}
#ta_183{left:132px;bottom:790px;letter-spacing:0.1px;word-spacing:0.04px;}
#tb_183{left:106px;bottom:759px;letter-spacing:0.12px;word-spacing:0.95px;}
#tc_183{left:274px;bottom:759px;letter-spacing:0.07px;word-spacing:1px;}
#td_183{left:625px;bottom:759px;}
#te_183{left:106px;bottom:741px;letter-spacing:0.1px;}
#tf_183{left:143px;bottom:741px;letter-spacing:0.14px;}
#tg_183{left:200px;bottom:741px;letter-spacing:0.1px;word-spacing:0.51px;}
#th_183{left:106px;bottom:722px;letter-spacing:0.11px;word-spacing:3.02px;}
#ti_183{left:382px;bottom:722px;letter-spacing:0.09px;word-spacing:3.03px;}
#tj_183{left:106px;bottom:704px;letter-spacing:0.1px;word-spacing:1.81px;}
#tk_183{left:106px;bottom:686px;letter-spacing:0.11px;word-spacing:0.02px;}
#tl_183{left:187px;bottom:657px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tm_183{left:480px;bottom:657px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tn_183{left:429px;bottom:639px;letter-spacing:-0.16px;word-spacing:0.03px;}
#to_183{left:142px;bottom:555px;letter-spacing:0.18px;}
#tp_183{left:87px;bottom:522px;letter-spacing:-0.16px;word-spacing:5.07px;}
#tq_183{left:87px;bottom:502px;letter-spacing:-0.15px;}
#tr_183{left:114px;bottom:478px;}
#ts_183{left:142px;bottom:478px;letter-spacing:-0.14px;word-spacing:-0.83px;}
#tt_183{left:142px;bottom:458px;letter-spacing:-0.2px;}
#tu_183{left:210px;bottom:458px;letter-spacing:-0.14px;word-spacing:2.86px;}
#tv_183{left:142px;bottom:437px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_183{left:415px;bottom:437px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tx_183{left:114px;bottom:413px;}
#ty_183{left:142px;bottom:413px;letter-spacing:-0.13px;word-spacing:-2.45px;}
#tz_183{left:142px;bottom:393px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t10_183{left:457px;bottom:393px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t11_183{left:114px;bottom:368px;}
#t12_183{left:142px;bottom:368px;letter-spacing:-0.13px;word-spacing:5.46px;}
#t13_183{left:330px;bottom:368px;letter-spacing:-0.19px;word-spacing:5.51px;}
#t14_183{left:142px;bottom:348px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t15_183{left:114px;bottom:324px;}
#t16_183{left:142px;bottom:324px;letter-spacing:-0.16px;word-spacing:8.15px;}
#t17_183{left:142px;bottom:303px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t18_183{left:114px;bottom:279px;}
#t19_183{left:142px;bottom:279px;letter-spacing:-0.13px;word-spacing:2.67px;}
#t1a_183{left:142px;bottom:259px;letter-spacing:-0.14px;word-spacing:-0.76px;}
#t1b_183{left:470px;bottom:259px;letter-spacing:-0.12px;word-spacing:-0.78px;}
#t1c_183{left:142px;bottom:239px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1d_183{left:87px;bottom:198px;letter-spacing:-0.11px;word-spacing:-0.46px;}
#t1e_183{left:199px;bottom:198px;letter-spacing:-0.15px;word-spacing:-0.42px;}
#t1f_183{left:87px;bottom:178px;letter-spacing:-0.16px;word-spacing:5.27px;}
#t1g_183{left:87px;bottom:158px;letter-spacing:-0.16px;word-spacing:-0.83px;}
#t1h_183{left:87px;bottom:138px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_183{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_183{font-size:15px;font-family:MogulArial-Bold_179;color:#202124;}
.s2_183{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s3_183{font-size:14px;font-family:MogulArial-Italic_17h;color:#202124;}
.s4_183{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_183{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_183{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s7_183{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s8_183{font-size:17px;font-family:MogulArial_18b;color:#000;}`}
      </style>

      <style id="fonts183" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg183" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="183/183.svg"
          type="image/svg+xml"
          id="pdf183"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_183" class="t s0_183">
          183{" "}
        </span>
        <span id="t2_183" class="t s1_183">
          Жишээ 4:{" "}
        </span>
        <span id="t3_183" class="t s2_183">
          Сургалтын хөтөлбөрийн хэрэгжилтийг сайжруулах гол арга нь{" "}
        </span>
        <span id="t4_183" class="t s2_183">
          ээлжит хичээл бүрийг чанартай заахад чиглэсэн байх хэрэгтэй гэдэгт
          багш{" "}
        </span>
        <span id="t5_183" class="t s2_183">
          нар нэгдсэн. Иймээс хичээлийн судалгааг мэргэжлийн хөгжлийн бүлгээр{" "}
        </span>
        <span id="t6_183" class="t s2_183">
          хийхэд төвлөрч{" "}
        </span>
        <span id="t7_183" class="t s2_183">
          • Хичээлийн бэлтгэл судалгаа{" "}
        </span>
        <span id="t8_183" class="t s2_183">
          • Хичээлээ заах{" "}
        </span>
        <span id="t9_183" class="t s2_183">
          • Хичээлийн хэлэлцүүлэг хийж, үнэлэх{" "}
        </span>
        <span id="ta_183" class="t s2_183">
          • Хичээлээ сайжруулахад анхаарч ажилласан.{" "}
        </span>
        <span id="tb_183" class="t s2_183">
          Үүний үр дүнд ээлжит{" "}
        </span>
        <span id="tc_183" class="t s2_183">
          хичээлийн бэлтгэл сайн хангагдаж, багш нарын{" "}
        </span>
        <span id="td_183" class="t s2_183">
          арга{" "}
        </span>
        <span id="te_183" class="t s2_183">
          зүй,{" "}
        </span>
        <span id="tf_183" class="t s2_183">
          ажлын{" "}
        </span>
        <span id="tg_183" class="t s2_183">
          хуудас сайжирч, байгалийн ухааны 20, хүмүүнлэгийн ухааны 46{" "}
        </span>
        <span id="th_183" class="t s2_183">
          багшийн нэгж хичээлийн төлөвлөлт{" "}
        </span>
        <span id="ti_183" class="t s2_183">
          сайжирч, сурагчдын сурлагын чанарт{" "}
        </span>
        <span id="tj_183" class="t s2_183">
          тодорхой өөрчлөлт гарсан байна. Манай сургуулийн багш нар Сайн хичээл{" "}
        </span>
        <span id="tk_183" class="t s2_183">
          үйл ажиллагаа-Багш сурагчдын ахиц амжилт гэдэгтэй санал нэг байгаа.{" "}
        </span>
        <span id="tl_183" class="t s3_183">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tm_183" class="t s4_183">
          сургуулийн менежментийг{" "}
        </span>
        <span id="tn_183" class="t s4_183">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="to_183" class="t s5_183">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tp_183" class="t s6_183">
          Дээр дурдсан ажлуудыг хэрхэн зохион байгуулсныг зөвлөмж байдлаар{" "}
        </span>
        <span id="tq_183" class="t s6_183">
          сийрүүлбэл,{" "}
        </span>
        <span id="tr_183" class="t s7_183">
          •{" "}
        </span>
        <span id="ts_183" class="t s6_183">
          Багш, удирдах ажилтнуудын цэвэрлэгээ хийх анги танхимын хуваарь{" "}
        </span>
        <span id="tt_183" class="t s6_183">
          гарган,{" "}
        </span>
        <span id="tu_183" class="t s6_183">
          кабинет хариуцаагүй багш, ажилчдыг сургуулийн нийтийн{" "}
        </span>
        <span id="tv_183" class="t s6_183">
          эзэмшлийн талбай, коридоруудыг{" "}
        </span>
        <span id="tw_183" class="t s6_183">
          цэвэрлэхээр зохион байгуулах{" "}
        </span>
        <span id="tx_183" class="t s8_183">
          ●{" "}
        </span>
        <span id="ty_183" class="t s6_183">
          “Хөөсний баяр”-ын үеэр дотоод узелээр хөгжим, ая сонсгон хөгжилтэй,{" "}
        </span>
        <span id="tz_183" class="t s6_183">
          тааламжтай байдлаар цэвэрлэгээ хийх{" "}
        </span>
        <span id="t10_183" class="t s6_183">
          нөхцөлийг бүрдүүлэх{" "}
        </span>
        <span id="t11_183" class="t s8_183">
          ●{" "}
        </span>
        <span id="t12_183" class="t s6_183">
          Сургуулийн захирал,{" "}
        </span>
        <span id="t13_183" class="t s6_183">
          удирдлагууд идэвх санаачилгатай, бусдыг{" "}
        </span>
        <span id="t14_183" class="t s6_183">
          уриалан манлайлж оролцох{" "}
        </span>
        <span id="t15_183" class="t s8_183">
          ●{" "}
        </span>
        <span id="t16_183" class="t s6_183">
          Хамгийн гол нь нийт багш ажилчдын саналыг авч, харилцан{" "}
        </span>
        <span id="t17_183" class="t s6_183">
          тохиролцсон, ойлголцсон тохиолдолд хэрэгжүүлэх{" "}
        </span>
        <span id="t18_183" class="t s8_183">
          ●{" "}
        </span>
        <span id="t19_183" class="t s6_183">
          Хэрэв цөөн хүн ямар нэг шалтгаанаар нэг удаагийн цэвэрлэгээнд{" "}
        </span>
        <span id="t1a_183" class="t s6_183">
          ирээгүй бол сургууийн удирдлагын зүгээс{" "}
        </span>
        <span id="t1b_183" class="t s6_183">
          ямар нэг арга хэмжээ авах{" "}
        </span>
        <span id="t1c_183" class="t s6_183">
          гэх мэт асуудал үүсгэхгүй байх хэрэгтэй.{" "}
        </span>
        <span id="t1d_183" class="t s6_183">
          Хамт олон нь{" "}
        </span>
        <span id="t1e_183" class="t s6_183">
          хамтарч ажиллаад, сургуулийн удирдлагын зүгээс урьдчилан{" "}
        </span>
        <span id="t1f_183" class="t s6_183">
          хуваарь гаргаж байгаа болохоор дараа дараагийн ажлуудад багш нар{" "}
        </span>
        <span id="t1g_183" class="t s6_183">
          урьдаас ажлаа төлөвлөж, идэвх санаачилгатай оролцохоос гадна бусад үед{" "}
        </span>
        <span id="t1h_183" class="t s6_183">
          ч орчноо цэвэр байлгах дадал хэвшилтэй болдог сайн талтай байна.{" "}
        </span>
      </div>
    </div>
  );
}
