import React from "react";
export default function FourtySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_46{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_46{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_46{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:8.1px;}
#t4_46{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:-1.82px;}
#t5_46{left:87px;bottom:901px;letter-spacing:-0.16px;word-spacing:4.31px;}
#t6_46{left:87px;bottom:880px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t7_46{left:181px;bottom:844px;letter-spacing:0.11px;word-spacing:0.02px;}
#t8_46{left:91px;bottom:806px;}
#t9_46{left:119px;bottom:816px;letter-spacing:-0.16px;}
#ta_46{left:129px;bottom:797px;letter-spacing:-0.17px;}
#tb_46{left:225px;bottom:807px;letter-spacing:-0.19px;word-spacing:0.06px;}
#tc_46{left:443px;bottom:807px;letter-spacing:-0.16px;word-spacing:0.03px;}
#td_46{left:94px;bottom:742px;}
#te_46{left:114px;bottom:751px;letter-spacing:-0.12px;}
#tf_46{left:114px;bottom:733px;letter-spacing:-0.23px;}
#tg_46{left:208px;bottom:770px;letter-spacing:-0.16px;word-spacing:0.04px;}
#th_46{left:208px;bottom:751px;letter-spacing:-0.16px;word-spacing:0.04px;}
#ti_46{left:208px;bottom:733px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tj_46{left:208px;bottom:715px;letter-spacing:-0.11px;word-spacing:-0.01px;}
#tk_46{left:435px;bottom:779px;letter-spacing:-0.18px;word-spacing:0.05px;}
#tl_46{left:435px;bottom:760px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tm_46{left:435px;bottom:742px;letter-spacing:-0.21px;word-spacing:0.09px;}
#tn_46{left:435px;bottom:724px;letter-spacing:-0.14px;word-spacing:0.02px;}
#to_46{left:435px;bottom:705px;letter-spacing:-0.19px;}
#tp_46{left:94px;bottom:659px;}
#tq_46{left:114px;bottom:659px;letter-spacing:-0.18px;}
#tr_46{left:208px;bottom:678px;letter-spacing:-0.2px;word-spacing:0.07px;}
#ts_46{left:208px;bottom:659px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tt_46{left:208px;bottom:641px;letter-spacing:-0.13px;}
#tu_46{left:435px;bottom:687px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tv_46{left:435px;bottom:668px;letter-spacing:-0.2px;word-spacing:0.08px;}
#tw_46{left:435px;bottom:650px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tx_46{left:435px;bottom:632px;letter-spacing:-0.18px;word-spacing:0.06px;}
#ty_46{left:94px;bottom:595px;}
#tz_46{left:114px;bottom:613px;letter-spacing:-0.15px;}
#t10_46{left:114px;bottom:595px;letter-spacing:-0.19px;}
#t11_46{left:114px;bottom:576px;letter-spacing:-0.22px;}
#t12_46{left:208px;bottom:604px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t13_46{left:208px;bottom:586px;letter-spacing:-0.2px;}
#t14_46{left:435px;bottom:604px;letter-spacing:-0.12px;word-spacing:-0.01px;}
#t15_46{left:435px;bottom:586px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t16_46{left:94px;bottom:550px;}
#t17_46{left:114px;bottom:550px;letter-spacing:-0.22px;}
#t18_46{left:208px;bottom:550px;letter-spacing:-0.18px;word-spacing:0.04px;}
#t19_46{left:435px;bottom:550px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t1a_46{left:94px;bottom:353px;}
#t1b_46{left:114px;bottom:353px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t1c_46{left:208px;bottom:518px;}
#t1d_46{left:230px;bottom:519px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1e_46{left:230px;bottom:501px;letter-spacing:-0.15px;}
#t1f_46{left:208px;bottom:482px;}
#t1g_46{left:230px;bottom:482px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1h_46{left:230px;bottom:464px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1i_46{left:230px;bottom:446px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t1j_46{left:208px;bottom:427px;}
#t1k_46{left:230px;bottom:427px;letter-spacing:-0.22px;word-spacing:0.1px;}
#t1l_46{left:230px;bottom:409px;letter-spacing:-0.12px;}
#t1m_46{left:230px;bottom:391px;letter-spacing:-0.2px;word-spacing:0.09px;}
#t1n_46{left:230px;bottom:372px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1o_46{left:208px;bottom:353px;}
#t1p_46{left:230px;bottom:354px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1q_46{left:230px;bottom:336px;letter-spacing:-0.21px;}
#t1r_46{left:208px;bottom:317px;}
#t1s_46{left:230px;bottom:317px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1t_46{left:230px;bottom:299px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1u_46{left:230px;bottom:281px;letter-spacing:-0.21px;word-spacing:0.08px;}
#t1v_46{left:208px;bottom:262px;}
#t1w_46{left:230px;bottom:262px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t1x_46{left:230px;bottom:244px;letter-spacing:-0.24px;}
#t1y_46{left:208px;bottom:225px;}
#t1z_46{left:230px;bottom:226px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t20_46{left:230px;bottom:207px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t21_46{left:435px;bottom:518px;}
#t22_46{left:457px;bottom:519px;letter-spacing:-0.21px;word-spacing:0.09px;}
#t23_46{left:457px;bottom:501px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t24_46{left:435px;bottom:482px;}
#t25_46{left:457px;bottom:482px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t26_46{left:457px;bottom:464px;letter-spacing:-0.21px;word-spacing:0.08px;}
#t27_46{left:435px;bottom:445px;}
#t28_46{left:457px;bottom:446px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t29_46{left:457px;bottom:427px;letter-spacing:-0.19px;word-spacing:0.06px;}
#t2a_46{left:435px;bottom:408px;}
#t2b_46{left:457px;bottom:409px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t2c_46{left:457px;bottom:391px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t2d_46{left:457px;bottom:372px;letter-spacing:-0.19px;}

.s0_46{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_46{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_46{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_46{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s4_46{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_46{font-size:15px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts46" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg46" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="46/46.svg"
          type="image/svg+xml"
          id="pdf46"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_46" class="t s1_46">
          46{" "}
        </span>
        <span id="t3_46" class="t s2_46">
          Үнэлгээ нь тухайн сургалтын байгууллагын нөхцөл байдал, зорилго,{" "}
        </span>
        <span id="t4_46" class="t s2_46">
          хэрэгцээ шаардлагаас хамаарч өөр өөр байж болох бөгөөд үйл ажиллагааны{" "}
        </span>
        <span id="t5_46" class="t s2_46">
          хэрэгжилт, үр дүнг бодитой, найдвартай хэмжихийн тулд өөрийн болон{" "}
        </span>
        <span id="t6_46" class="t s2_46">
          хөндлөнгийн үнэлгээг хослуулж хэрэглэдэг туршлага нийтлэг байдаг.{" "}
        </span>
        <span id="t7_46" class="t s3_46">
          Хүснэгт 2.2. Сургуулийн өөрийн болон хөндлөнгийн үнэлгээний ялгаа{" "}
        </span>
        <span id="t8_46" class="t s4_46">
          №{" "}
        </span>
        <span id="t9_46" class="t s4_46">
          Харьцуулах{" "}
        </span>
        <span id="ta_46" class="t s4_46">
          үзүүлэлт{" "}
        </span>
        <span id="tb_46" class="t s4_46">
          Өөрийн буюу дотоод үнэлгээ{" "}
        </span>
        <span id="tc_46" class="t s4_46">
          Хөндлөнгийн буюу гадаад үнэлгээ{" "}
        </span>
        <span id="td_46" class="t s4_46">
          1{" "}
        </span>
        <span id="te_46" class="t s4_46">
          Ерөнхий{" "}
        </span>
        <span id="tf_46" class="t s4_46">
          ойлголт{" "}
        </span>
        <span id="tg_46" class="t s4_46">
          Сургуулийн гүйцэтгэл, үр дүнтэй{" "}
        </span>
        <span id="th_46" class="t s4_46">
          байдал, сургалтын ерөнхий{" "}
        </span>
        <span id="ti_46" class="t s4_46">
          чанарыг үнэлэхийн тулд сургууль{" "}
        </span>
        <span id="tj_46" class="t s4_46">
          өөрөө хийдэг үйл явц{" "}
        </span>
        <span id="tk_46" class="t s4_46">
          Боловсролын байгууллагын{" "}
        </span>
        <span id="tl_46" class="t s4_46">
          үйл ажиллагаанд хөндлөнгийн{" "}
        </span>
        <span id="tm_46" class="t s4_46">
          байгууллага эсвэл хараат бус{" "}
        </span>
        <span id="tn_46" class="t s4_46">
          үнэлгээчдийн хийсэн үнэлгээ,{" "}
        </span>
        <span id="to_46" class="t s4_46">
          шалгалт{" "}
        </span>
        <span id="tp_46" class="t s4_46">
          2{" "}
        </span>
        <span id="tq_46" class="t s4_46">
          Зорилго{" "}
        </span>
        <span id="tr_46" class="t s4_46">
          Боловсролын байгууллага{" "}
        </span>
        <span id="ts_46" class="t s4_46">
          өөрийгөө сайжруулах, сургалтын{" "}
        </span>
        <span id="tt_46" class="t s4_46">
          чанарыг дээшлүүлэх{" "}
        </span>
        <span id="tu_46" class="t s4_46">
          Сургуулийн үйл ажиллагаа,{" "}
        </span>
        <span id="tv_46" class="t s4_46">
          тогтоосон стандарт, дүрэм журамд{" "}
        </span>
        <span id="tw_46" class="t s4_46">
          нийцэж байгаа эсэхэд бодитой,{" "}
        </span>
        <span id="tx_46" class="t s4_46">
          хараат бус үнэлгээ өгөх{" "}
        </span>
        <span id="ty_46" class="t s4_46">
          3{" "}
        </span>
        <span id="tz_46" class="t s4_46">
          Зохион{" "}
        </span>
        <span id="t10_46" class="t s4_46">
          байгуулах{" "}
        </span>
        <span id="t11_46" class="t s4_46">
          хэлбэр{" "}
        </span>
        <span id="t12_46" class="t s4_46">
          Хамт олны оролцоонд{" "}
        </span>
        <span id="t13_46" class="t s4_46">
          тулгуурласан{" "}
        </span>
        <span id="t14_46" class="t s4_46">
          Үндэсний хэмжээний мэргэжлийн{" "}
        </span>
        <span id="t15_46" class="t s4_46">
          байгууллага, үнэлээчид{" "}
        </span>
        <span id="t16_46" class="t s4_46">
          4{" "}
        </span>
        <span id="t17_46" class="t s4_46">
          Давтамж{" "}
        </span>
        <span id="t18_46" class="t s4_46">
          Жилд 2-3 удаа{" "}
        </span>
        <span id="t19_46" class="t s4_46">
          Жилд 1 удаа{" "}
        </span>
        <span id="t1a_46" class="t s4_46">
          5{" "}
        </span>
        <span id="t1b_46" class="t s4_46">
          Давуу тал{" "}
        </span>
        <span id="t1c_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1d_46" class="t s4_46">
          Тасралтгүй суралцах соёл{" "}
        </span>
        <span id="t1e_46" class="t s4_46">
          төлөвшүүлэх{" "}
        </span>
        <span id="t1f_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1g_46" class="t s4_46">
          Сургалтын үйл ажиллагааг{" "}
        </span>
        <span id="t1h_46" class="t s4_46">
          бүхэлд нь сайжруулах{" "}
        </span>
        <span id="t1i_46" class="t s4_46">
          мэдээллээр хангах{" "}
        </span>
        <span id="t1j_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1k_46" class="t s4_46">
          Сургууль өөрийн онцлог,{" "}
        </span>
        <span id="t1l_46" class="t s4_46">
          хэрэгцээнд нийцүүлэн{" "}
        </span>
        <span id="t1m_46" class="t s4_46">
          шалгуур, үзүүлэлт, үнэлгээний{" "}
        </span>
        <span id="t1n_46" class="t s4_46">
          хэлбэр, арга хэрэгслийг сонгох{" "}
        </span>
        <span id="t1o_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1p_46" class="t s4_46">
          Сургуулийн хамт олныг бүрэн{" "}
        </span>
        <span id="t1q_46" class="t s4_46">
          хамруулах{" "}
        </span>
        <span id="t1r_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1s_46" class="t s4_46">
          Үнэлгээний хэлбэр, зохион{" "}
        </span>
        <span id="t1t_46" class="t s4_46">
          байгуулах хугацаанд уян{" "}
        </span>
        <span id="t1u_46" class="t s4_46">
          хатан байх боломж{" "}
        </span>
        <span id="t1v_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1w_46" class="t s4_46">
          Өгөгдөлд суурилсан шийдвэр{" "}
        </span>
        <span id="t1x_46" class="t s4_46">
          гаргах{" "}
        </span>
        <span id="t1y_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t1z_46" class="t s4_46">
          Цаг алдалгүй сайжруулах{" "}
        </span>
        <span id="t20_46" class="t s4_46">
          зүйлсийг илрүүлэх{" "}
        </span>
        <span id="t21_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t22_46" class="t s4_46">
          Шударга, бодитой{" "}
        </span>
        <span id="t23_46" class="t s4_46">
          хүлээн зөвшөөрөгдөх байдал{" "}
        </span>
        <span id="t24_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t25_46" class="t s4_46">
          Бусад ижил төрлийн{" "}
        </span>
        <span id="t26_46" class="t s4_46">
          байгууллагатай харьцуулах{" "}
        </span>
        <span id="t27_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t28_46" class="t s4_46">
          Сургуулийн өөрийн үнэлгээний{" "}
        </span>
        <span id="t29_46" class="t s4_46">
          үр дүнг баталгаажуулах{" "}
        </span>
        <span id="t2a_46" class="t s5_46">
          •{" "}
        </span>
        <span id="t2b_46" class="t s4_46">
          Мэргэжлийн байгууллага,{" "}
        </span>
        <span id="t2c_46" class="t s4_46">
          үнэлээчдийн зүгээс өгөх{" "}
        </span>
        <span id="t2d_46" class="t s4_46">
          зөвлөмж{" "}
        </span>
      </div>
    </div>
  );
}
