import React from "react";
export default function NinetySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_96{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_96{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:1.27px;}
#t3_96{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:2.81px;}
#t4_96{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:3.42px;}
#t5_96{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t6_96{left:87px;bottom:847px;letter-spacing:-0.18px;word-spacing:7.21px;}
#t7_96{left:87px;bottom:827px;letter-spacing:-0.18px;word-spacing:6.95px;}
#t8_96{left:87px;bottom:807px;letter-spacing:-0.13px;word-spacing:-1.39px;}
#t9_96{left:87px;bottom:787px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ta_96{left:364px;bottom:756px;letter-spacing:0.12px;word-spacing:0.01px;}
#tb_96{left:90px;bottom:729px;letter-spacing:0.16px;}
#tc_96{left:389px;bottom:729px;letter-spacing:0.13px;}
#td_96{left:444px;bottom:729px;letter-spacing:0.12px;}
#te_96{left:501px;bottom:729px;letter-spacing:0.11px;}
#tf_96{left:561px;bottom:729px;letter-spacing:0.14px;}
#tg_96{left:623px;bottom:729px;letter-spacing:0.15px;}
#th_96{left:90px;bottom:699px;letter-spacing:0.11px;word-spacing:0.03px;}
#ti_96{left:90px;bottom:681px;letter-spacing:0.12px;word-spacing:0.02px;}
#tj_96{left:90px;bottom:651px;letter-spacing:0.11px;word-spacing:0.04px;}
#tk_96{left:90px;bottom:633px;letter-spacing:0.13px;word-spacing:0.01px;}
#tl_96{left:90px;bottom:603px;letter-spacing:0.13px;word-spacing:0.01px;}
#tm_96{left:90px;bottom:573px;letter-spacing:0.11px;word-spacing:0.02px;}
#tn_96{left:356px;bottom:513px;letter-spacing:-0.21px;}
#to_96{left:492px;bottom:513px;letter-spacing:-0.12px;}
#tp_96{left:606px;bottom:513px;letter-spacing:-0.14px;}
#tq_96{left:356px;bottom:492px;letter-spacing:-0.15px;}
#tr_96{left:442px;bottom:492px;letter-spacing:-0.18px;}
#ts_96{left:525px;bottom:492px;letter-spacing:-0.18px;}
#tt_96{left:623px;bottom:492px;letter-spacing:-0.09px;}
#tu_96{left:356px;bottom:472px;letter-spacing:-0.14px;}
#tv_96{left:452px;bottom:472px;letter-spacing:-0.18px;}
#tw_96{left:546px;bottom:472px;letter-spacing:-0.14px;}
#tx_96{left:600px;bottom:472px;letter-spacing:-0.09px;}
#ty_96{left:658px;bottom:472px;letter-spacing:-0.07px;}
#tz_96{left:356px;bottom:452px;letter-spacing:-0.17px;word-spacing:4.01px;}
#t10_96{left:356px;bottom:432px;letter-spacing:-0.15px;word-spacing:0.68px;}
#t11_96{left:356px;bottom:412px;letter-spacing:-0.22px;word-spacing:1.95px;}
#t12_96{left:356px;bottom:392px;letter-spacing:-0.13px;word-spacing:3.09px;}
#t13_96{left:356px;bottom:371px;letter-spacing:-0.16px;word-spacing:7.52px;}
#t14_96{left:356px;bottom:351px;letter-spacing:-0.08px;}
#t15_96{left:444px;bottom:351px;letter-spacing:-0.14px;}
#t16_96{left:553px;bottom:351px;letter-spacing:-0.07px;}
#t17_96{left:356px;bottom:331px;letter-spacing:-0.11px;word-spacing:5.77px;}
#t18_96{left:356px;bottom:311px;letter-spacing:-0.14px;}
#t19_96{left:455px;bottom:311px;letter-spacing:-0.12px;}
#t1a_96{left:532px;bottom:311px;letter-spacing:-0.08px;}
#t1b_96{left:600px;bottom:311px;letter-spacing:-0.18px;}
#t1c_96{left:356px;bottom:291px;letter-spacing:-0.13px;word-spacing:9.04px;}
#t1d_96{left:356px;bottom:271px;letter-spacing:-0.18px;word-spacing:10.53px;}
#t1e_96{left:87px;bottom:250px;letter-spacing:-0.31px;}
#t1f_96{left:87px;bottom:217px;letter-spacing:-0.16px;word-spacing:4.12px;}
#t1g_96{left:87px;bottom:197px;letter-spacing:-0.15px;word-spacing:0.66px;}
#t1h_96{left:87px;bottom:177px;letter-spacing:-0.13px;word-spacing:7.68px;}
#t1i_96{left:87px;bottom:157px;letter-spacing:-0.16px;word-spacing:3.34px;}
#t1j_96{left:87px;bottom:137px;letter-spacing:-0.09px;}
#t1k_96{left:142px;bottom:112px;}
#t1l_96{left:169px;bottom:112px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t1m_96{left:87px;bottom:520px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1n_96{left:150px;bottom:503px;letter-spacing:-0.2px;word-spacing:0.06px;}

.s0_96{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_96{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_96{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_96{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s4_96{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_96{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s6_96{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts96" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg96" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="96/96.svg"
          type="image/svg+xml"
          id="pdf96"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_96" class="t s0_96">
          96{" "}
        </span>
        <span id="t2_96" class="t s1_96">
          Хэд хэдэн судлагдахууны хоорондын агуулгын залгамж холбоог гаргахдаа{" "}
        </span>
        <span id="t3_96" class="t s1_96">
          багш нар хичээлийн жилийн эхэнд үндэсний сургалтын хөтөлбөрөөс хэд{" "}
        </span>
        <span id="t4_96" class="t s1_96">
          хэдэн хичээл судлагдахуунаар судалж (Multidisciplinary integration)
          болох{" "}
        </span>
        <span id="t5_96" class="t s1_96">
          агуулга /сэдвүүдийн түүвэр жагсаалт гаргана.{" "}
        </span>
        <span id="t6_96" class="t s1_96">
          Дараа нь юуг, хэн, хаана, хэзээ зохион байгуулахыг сургуулийн үйл{" "}
        </span>
        <span id="t7_96" class="t s1_96">
          ажиллагааны төлөвлөгөөнд тусган сургалт, үйл ажиллагааг төлөвлөж,{" "}
        </span>
        <span id="t8_96" class="t s1_96">
          тухайн агуулгыг аль хичээлээр хэн, хэрхэн эхэлж орох, хэрхэн
          үргэлжлүүлэх,{" "}
        </span>
        <span id="t9_96" class="t s1_96">
          хамтран заах боломжоо олж харна.{" "}
        </span>
        <span id="ta_96" class="t s2_96">
          Хүснэгт 3.3. Интеграци: өргөслө, гүнзгийрэл{" "}
        </span>
        <span id="tb_96" class="t s3_96">
          Бүлэг{" "}
        </span>
        <span id="tc_96" class="t s3_96">
          I анги{" "}
        </span>
        <span id="td_96" class="t s3_96">
          II анги{" "}
        </span>
        <span id="te_96" class="t s3_96">
          III анги{" "}
        </span>
        <span id="tf_96" class="t s3_96">
          IV анги{" "}
        </span>
        <span id="tg_96" class="t s3_96">
          V анги{" "}
        </span>
        <span id="th_96" class="t s4_96">
          Анги хоорондын залгамж холбоо бүхий{" "}
        </span>
        <span id="ti_96" class="t s4_96">
          суралцахуйн зорилт{" "}
        </span>
        <span id="tj_96" class="t s4_96">
          Сэдэв хоорондын залгамж холбоо бүхий{" "}
        </span>
        <span id="tk_96" class="t s4_96">
          суралцахуйн зорилт /сэдэв бичнэ/{" "}
        </span>
        <span id="tl_96" class="t s4_96">
          Өргөсөл /кодоор бичнэ/{" "}
        </span>
        <span id="tm_96" class="t s4_96">
          Гүнзгийрэл /кодоор бичнэ/{" "}
        </span>
        <span id="tn_96" class="t s1_96">
          Судлагдахуун{" "}
        </span>
        <span id="to_96" class="t s1_96">
          хоорондын{" "}
        </span>
        <span id="tp_96" class="t s1_96">
          агуулгын{" "}
        </span>
        <span id="tq_96" class="t s1_96">
          залгамж{" "}
        </span>
        <span id="tr_96" class="t s1_96">
          холбоог{" "}
        </span>
        <span id="ts_96" class="t s1_96">
          гаргахдаа{" "}
        </span>
        <span id="tt_96" class="t s1_96">
          тухайн{" "}
        </span>
        <span id="tu_96" class="t s1_96">
          хичээлийг{" "}
        </span>
        <span id="tv_96" class="t s1_96">
          судлахын{" "}
        </span>
        <span id="tw_96" class="t s1_96">
          тулд{" "}
        </span>
        <span id="tx_96" class="t s1_96">
          өмнө{" "}
        </span>
        <span id="ty_96" class="t s1_96">
          нь{" "}
        </span>
        <span id="tz_96" class="t s1_96">
          судалж, амжилттай үнэлэгдсэн агуулга,{" "}
        </span>
        <span id="t10_96" class="t s1_96">
          эсвэл тухайн хичээлийн дараа судалбал{" "}
        </span>
        <span id="t11_96" class="t s1_96">
          зохих, залгамж холбоо бүхий мэдлэг, ур{" "}
        </span>
        <span id="t12_96" class="t s1_96">
          чадварыг гүнзгийрүүлэх нягт уялдаатай{" "}
        </span>
        <span id="t13_96" class="t s1_96">
          хоёр ба түүнээс дээш судлагдахууны{" "}
        </span>
        <span id="t14_96" class="t s1_96">
          сэдэв{" "}
        </span>
        <span id="t15_96" class="t s1_96">
          агуулгыг{" "}
        </span>
        <span id="t16_96" class="t s1_96">
          (Interdisciplinary{" "}
        </span>
        <span id="t17_96" class="t s1_96">
          integration) зааж өгч болно. Ингэснээр{" "}
        </span>
        <span id="t18_96" class="t s1_96">
          мэдлэгийг{" "}
        </span>
        <span id="t19_96" class="t s1_96">
          тогтсон{" "}
        </span>
        <span id="t1a_96" class="t s1_96">
          бүтэц,{" "}
        </span>
        <span id="t1b_96" class="t s1_96">
          агуулгаар{" "}
        </span>
        <span id="t1c_96" class="t s1_96">
          хуваах буюу сэдэв бүрээр тусад нь{" "}
        </span>
        <span id="t1d_96" class="t s1_96">
          олгодог байдлыг өөрчлөх боломжийг{" "}
        </span>
        <span id="t1e_96" class="t s1_96">
          нэмэгдүүлдэг.{" "}
        </span>
        <span id="t1f_96" class="t s1_96">
          Судлагдахууны сургалтын хөтөлбөрийн агуулга, сэдвүүдийг зааж сургах{" "}
        </span>
        <span id="t1g_96" class="t s1_96">
          гэхээсээ илүүтэйгээр бусад судлагдахууны агуулгаар баяжуулж, сурагчдад{" "}
        </span>
        <span id="t1h_96" class="t s1_96">
          хэт ачаалал үүсгэхгүйгээр нэмэлт сэдвүүдийг хэрэгжүүлснээр салбар{" "}
        </span>
        <span id="t1i_96" class="t s1_96">
          хоорондын болон хамтын ажиллагааг хөгжүүлж болдог. (Transdisciplinary{" "}
        </span>
        <span id="t1j_96" class="t s1_96">
          integration) Жишээ нь:{" "}
        </span>
        <span id="t1k_96" class="t s5_96">
          •{" "}
        </span>
        <span id="t1l_96" class="t s1_96">
          Байгаль орчны боловсрол{" "}
        </span>
        <span id="t1m_96" class="t s6_96">
          Зураг 3.9. Судлагдахуун хоорондын{" "}
        </span>
        <span id="t1n_96" class="t s6_96">
          залгамж холбоо{" "}
        </span>
      </div>
    </div>
  );
}
