import React from "react";
export default function HundredSeventeen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_117{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_117{left:142px;bottom:922px;letter-spacing:0.18px;}
#t3_117{left:87px;bottom:875px;letter-spacing:-0.1px;word-spacing:-0.1px;}
#t4_117{left:87px;bottom:855px;letter-spacing:-0.1px;}
#t5_117{left:334px;bottom:855px;}
#t6_117{left:87px;bottom:822px;letter-spacing:-0.1px;word-spacing:0.56px;}
#t7_117{left:87px;bottom:802px;letter-spacing:-0.1px;word-spacing:0.46px;}
#t8_117{left:87px;bottom:782px;letter-spacing:-0.1px;word-spacing:-0.27px;}
#t9_117{left:87px;bottom:761px;letter-spacing:-0.1px;word-spacing:-0.21px;}
#ta_117{left:87px;bottom:741px;letter-spacing:-0.1px;word-spacing:3.04px;}
#tb_117{left:87px;bottom:721px;letter-spacing:-0.1px;word-spacing:4.38px;}
#tc_117{left:87px;bottom:701px;letter-spacing:-0.1px;word-spacing:-1.61px;}
#td_117{left:87px;bottom:681px;letter-spacing:-0.11px;}
#te_117{left:114px;bottom:648px;}
#tf_117{left:142px;bottom:648px;letter-spacing:-0.1px;word-spacing:7.45px;}
#tg_117{left:142px;bottom:627px;letter-spacing:-0.1px;}
#th_117{left:114px;bottom:603px;}
#ti_117{left:142px;bottom:603px;letter-spacing:-0.09px;word-spacing:0.45px;}
#tj_117{left:142px;bottom:583px;letter-spacing:-0.1px;}
#tk_117{left:114px;bottom:558px;}
#tl_117{left:142px;bottom:558px;letter-spacing:-0.1px;word-spacing:7.31px;}
#tm_117{left:142px;bottom:538px;letter-spacing:-0.09px;}
#tn_117{left:220px;bottom:538px;letter-spacing:-0.09px;}
#to_117{left:316px;bottom:538px;letter-spacing:-0.09px;}
#tp_117{left:393px;bottom:538px;letter-spacing:-0.08px;}
#tq_117{left:438px;bottom:538px;letter-spacing:-0.09px;}
#tr_117{left:544px;bottom:538px;letter-spacing:-0.09px;}
#ts_117{left:648px;bottom:538px;letter-spacing:-0.08px;}
#tt_117{left:142px;bottom:518px;letter-spacing:-0.1px;}
#tu_117{left:114px;bottom:493px;}
#tv_117{left:142px;bottom:493px;letter-spacing:-0.1px;word-spacing:6.92px;}
#tw_117{left:142px;bottom:473px;letter-spacing:-0.09px;}
#tx_117{left:87px;bottom:433px;letter-spacing:-0.13px;word-spacing:3.52px;}
#ty_117{left:87px;bottom:413px;letter-spacing:-0.14px;word-spacing:2.71px;}
#tz_117{left:87px;bottom:393px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t10_117{left:87px;bottom:352px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t11_117{left:87px;bottom:328px;letter-spacing:-0.46px;word-spacing:0.57px;}
#t12_117{left:87px;bottom:308px;letter-spacing:-0.27px;word-spacing:2.3px;}
#t13_117{left:87px;bottom:287px;letter-spacing:-0.32px;word-spacing:2.9px;}
#t14_117{left:87px;bottom:267px;letter-spacing:-0.33px;word-spacing:-0.03px;}
#t15_117{left:87px;bottom:227px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t16_117{left:114px;bottom:202px;}
#t17_117{left:142px;bottom:202px;letter-spacing:-0.12px;word-spacing:2.68px;}
#t18_117{left:142px;bottom:182px;letter-spacing:-0.14px;word-spacing:2.88px;}
#t19_117{left:314px;bottom:182px;letter-spacing:-0.19px;word-spacing:2.94px;}
#t1a_117{left:142px;bottom:162px;letter-spacing:-0.19px;word-spacing:5.5px;}
#t1b_117{left:142px;bottom:142px;letter-spacing:-0.13px;word-spacing:2.2px;}
#t1c_117{left:142px;bottom:122px;letter-spacing:-0.13px;word-spacing:0.04px;}

.s0_117{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_117{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_117{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_117{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s4_117{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_117{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts117" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg117" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="117/117.svg"
          type="image/svg+xml"
          id="pdf117"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_117" class="t s0_117">
          117{" "}
        </span>
        <span id="t2_117" class="t s1_117">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_117" class="t s2_117">
          СССХ-ийг боловсруулах нь ямар ач холбогдолтой юм бэ? Цаг үрсэн ажил{" "}
        </span>
        <span id="t4_117" class="t s2_117">
          биш үү? гэх асуулт гарч болно
        </span>
        <span id="t5_117" class="t s3_117">
          .{" "}
        </span>
        <span id="t6_117" class="t s4_117">
          СССХ-ийг хөгжүүлэх нь үндэсний сургалтын хөтөлбөрөөс салангид тусдаа{" "}
        </span>
        <span id="t7_117" class="t s4_117">
          зүйл биш, үндэсний сургалтын хөтөлбөрт суурилсан байна гэдгийг мартаж{" "}
        </span>
        <span id="t8_117" class="t s4_117">
          болохгүй. СССХ гэдэг нь сургуулийн түвшинд бүх багшийн оролцоотойгоор{" "}
        </span>
        <span id="t9_117" class="t s4_117">
          боловсруулсан, сургалтыг илүүтэй амьдралд ойртуулж, хэрэгжихэд хялбар{" "}
        </span>
        <span id="ta_117" class="t s4_117">
          дөхөм болгосон, тухайн орон нутаг, сургуулийн орчин, соёлын олон янз{" "}
        </span>
        <span id="tb_117" class="t s4_117">
          байдлыг тусгасан гэдгээрээ ялгагдах болно. Иймээс тухайн сургуулийн{" "}
        </span>
        <span id="tc_117" class="t s4_117">
          багш нар хамтраад “3W1H”-ийн зарчмаар сургалт, үйл ажиллагааг төлөвлөж{" "}
        </span>
        <span id="td_117" class="t s4_117">
          СССХ-ыг боловсруулна.{" "}
        </span>
        <span id="te_117" class="t s5_117">
          •{" "}
        </span>
        <span id="tf_117" class="t s4_117">
          -Юуг сургах (орон нутаг, сургуулийн онцлог, соёлын ялгаатай{" "}
        </span>
        <span id="tg_117" class="t s4_117">
          агуулгаар баяжуулах){" "}
        </span>
        <span id="th_117" class="t s5_117">
          •{" "}
        </span>
        <span id="ti_117" class="t s4_117">
          -Хэн оролцох (сургуулийн удирдлага, багш нарын баг, орон нутгийн{" "}
        </span>
        <span id="tj_117" class="t s4_117">
          иргэдтэй хамтран){" "}
        </span>
        <span id="tk_117" class="t s5_117">
          •{" "}
        </span>
        <span id="tl_117" class="t s4_117">
          -Хаана явуулах (сургалтын анги танхимд, туршлагын талбайд,{" "}
        </span>
        <span id="tm_117" class="t s4_117">
          музейд,{" "}
        </span>
        <span id="tn_117" class="t s4_117">
          хичээлээс{" "}
        </span>
        <span id="to_117" class="t s4_117">
          гадуурх{" "}
        </span>
        <span id="tp_117" class="t s4_117">
          үйл{" "}
        </span>
        <span id="tq_117" class="t s4_117">
          ажиллагаа,{" "}
        </span>
        <span id="tr_117" class="t s4_117">
          сургуулийн{" "}
        </span>
        <span id="ts_117" class="t s4_117">
          үйл{" "}
        </span>
        <span id="tt_117" class="t s4_117">
          ажиллагаагаар гэх мэт){" "}
        </span>
        <span id="tu_117" class="t s5_117">
          •{" "}
        </span>
        <span id="tv_117" class="t s4_117">
          -Хэрхэн хэрэгжүүлэх (орон нутгийн иргэдээс суралцах, нийгэм,{" "}
        </span>
        <span id="tw_117" class="t s4_117">
          хүрээлэн буй орчноос суралцах) гэдэгт л өөрчлөлт хийнэ гэсэн үг.{" "}
        </span>
        <span id="tx_117" class="t s4_117">
          Энэ нь багшийн бүтээлч, бие даасан үйл ажиллагааг хөгжүүлэх, ажлын{" "}
        </span>
        <span id="ty_117" class="t s4_117">
          байран дээрээ бусдаас суралцах, сургалтын арга зүйгээ “солонгоруулах”{" "}
        </span>
        <span id="tz_117" class="t s4_117">
          боломжийг нээж өгдөг сайн туршлага юм.{" "}
        </span>
        <span id="t10_117" class="t s2_117">
          СССХ-ийг турших, хэрэгжүүлэхэд юуг анхаарах вэ?{" "}
        </span>
        <span id="t11_117" class="t s4_117">
          СССХ-ийг турших, хэрэгжүүлэхдээ “хэрэгжүүлэх арга зам”, “сургалтын
          загвар-{" "}
        </span>
        <span id="t12_117" class="t s4_117">
          (learning models)”, “сургалтын арга-(Learning methods)” гэсэн нэр
          томьёоны{" "}
        </span>
        <span id="t13_117" class="t s4_117">
          талаар сургуулийн бүх багш, ажилтан нэгдсэн ойлголттой болсон байх нь{" "}
        </span>
        <span id="t14_117" class="t s4_117">
          чухал. Энэхүү зөвлөмжид дээрх ойлголтуудын талаар товч тайлбар
          оруулав.{" "}
        </span>
        <span id="t15_117" class="t s2_117">
          СССХ-ийг турших, хэрэгжүүлэх арга зам{" "}
        </span>
        <span id="t16_117" class="t s5_117">
          •{" "}
        </span>
        <span id="t17_117" class="t s2_117">
          Сургалтын хөтөлбөрийн агуулгыг орон нутгийн онцлог, ялгааг{" "}
        </span>
        <span id="t18_117" class="t s2_117">
          тусгаж баяжуулана.{" "}
        </span>
        <span id="t19_117" class="t s4_117">
          Тухайн орон нутгийн газар зүйн онцлог, түүх{" "}
        </span>
        <span id="t1a_117" class="t s4_117">
          соёлын үнэт үйлс, амьтан, ургамлын аймгийн онцлог, ТХБ гээд{" "}
        </span>
        <span id="t1b_117" class="t s4_117">
          олон шинэ агуулгаар үндэсний сургалтын хөтөлбөрийг баяжуулан,{" "}
        </span>
        <span id="t1c_117" class="t s4_117">
          агуулгыг амьдрал, хэрэглээнд ойртуулах{" "}
        </span>
      </div>
    </div>
  );
}
