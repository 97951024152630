import React from "react";
export default function Eight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_8{left:87px;bottom:58px;}
#t2_8{left:75px;bottom:59px;}
#t3_8{left:87px;bottom:936px;letter-spacing:0.14px;word-spacing:0.12px;}
#t4_8{left:87px;bottom:900px;letter-spacing:-0.46px;}
#t5_8{left:193px;bottom:900px;letter-spacing:-0.3px;}
#t6_8{left:87px;bottom:862px;letter-spacing:-0.46px;}
#t7_8{left:197px;bottom:862px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t8_8{left:87px;bottom:843px;letter-spacing:-0.24px;}
#t9_8{left:197px;bottom:843px;letter-spacing:-0.18px;word-spacing:0.08px;}
#ta_8{left:87px;bottom:824px;letter-spacing:-0.18px;}
#tb_8{left:197px;bottom:824px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tc_8{left:87px;bottom:805px;letter-spacing:-0.09px;}
#td_8{left:197px;bottom:805px;letter-spacing:-0.15px;word-spacing:0.05px;}
#te_8{left:87px;bottom:786px;letter-spacing:-0.09px;}
#tf_8{left:197px;bottom:786px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tg_8{left:87px;bottom:767px;letter-spacing:-0.26px;}
#th_8{left:197px;bottom:767px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ti_8{left:87px;bottom:747px;letter-spacing:-0.16px;}
#tj_8{left:197px;bottom:747px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_8{left:87px;bottom:728px;letter-spacing:-0.1px;}
#tl_8{left:197px;bottom:728px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tm_8{left:87px;bottom:709px;letter-spacing:-0.23px;}
#tn_8{left:197px;bottom:709px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_8{left:87px;bottom:690px;letter-spacing:-0.1px;}
#tp_8{left:197px;bottom:690px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tq_8{left:87px;bottom:671px;letter-spacing:-0.1px;}
#tr_8{left:197px;bottom:671px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_8{left:87px;bottom:652px;letter-spacing:-0.1px;}
#tt_8{left:197px;bottom:652px;letter-spacing:-0.09px;word-spacing:-0.01px;}
#tu_8{left:87px;bottom:633px;letter-spacing:-0.11px;}
#tv_8{left:199px;bottom:633px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tw_8{left:87px;bottom:614px;letter-spacing:-0.11px;}
#tx_8{left:197px;bottom:614px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ty_8{left:87px;bottom:595px;letter-spacing:-0.12px;}
#tz_8{left:197px;bottom:595px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t10_8{left:87px;bottom:576px;letter-spacing:-0.1px;}
#t11_8{left:196px;bottom:576px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t12_8{left:87px;bottom:557px;letter-spacing:-0.2px;}
#t13_8{left:197px;bottom:557px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_8{left:87px;bottom:537px;letter-spacing:-0.2px;}
#t15_8{left:197px;bottom:537px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t16_8{left:87px;bottom:518px;letter-spacing:-0.27px;}
#t17_8{left:197px;bottom:518px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t18_8{left:87px;bottom:499px;letter-spacing:-0.2px;}
#t19_8{left:197px;bottom:499px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1a_8{left:87px;bottom:480px;letter-spacing:-0.09px;}
#t1b_8{left:197px;bottom:480px;letter-spacing:-0.24px;word-spacing:0.15px;}
#t1c_8{left:87px;bottom:461px;letter-spacing:-0.22px;}
#t1d_8{left:196px;bottom:461px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1e_8{left:87px;bottom:442px;letter-spacing:-0.09px;}
#t1f_8{left:197px;bottom:442px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t1g_8{left:87px;bottom:423px;letter-spacing:-0.09px;}
#t1h_8{left:198px;bottom:423px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1i_8{left:87px;bottom:404px;letter-spacing:-0.28px;}
#t1j_8{left:197px;bottom:404px;letter-spacing:-0.14px;}
#t1k_8{left:87px;bottom:385px;letter-spacing:-0.16px;}
#t1l_8{left:199px;bottom:385px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1m_8{left:87px;bottom:366px;letter-spacing:-0.09px;}
#t1n_8{left:197px;bottom:366px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1o_8{left:87px;bottom:347px;letter-spacing:-0.22px;}
#t1p_8{left:197px;bottom:347px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1q_8{left:87px;bottom:327px;letter-spacing:-0.15px;}
#t1r_8{left:197px;bottom:327px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1s_8{left:87px;bottom:308px;letter-spacing:-0.1px;}
#t1t_8{left:197px;bottom:308px;letter-spacing:-0.11px;}
#t1u_8{left:87px;bottom:289px;letter-spacing:-0.16px;}
#t1v_8{left:197px;bottom:289px;letter-spacing:-0.23px;word-spacing:0.06px;}
#t1w_8{left:197px;bottom:270px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1x_8{left:87px;bottom:251px;letter-spacing:-0.11px;}
#t1y_8{left:197px;bottom:251px;letter-spacing:-0.09px;}
#t1z_8{left:87px;bottom:232px;letter-spacing:-0.09px;}
#t20_8{left:199px;bottom:232px;letter-spacing:-0.09px;word-spacing:-0.24px;}
#t21_8{left:87px;bottom:213px;letter-spacing:-1.03px;}
#t22_8{left:198px;bottom:213px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t23_8{left:87px;bottom:194px;letter-spacing:-0.27px;}
#t24_8{left:197px;bottom:194px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t25_8{left:87px;bottom:175px;letter-spacing:-0.11px;}
#t26_8{left:196px;bottom:175px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t27_8{left:87px;bottom:156px;letter-spacing:-0.42px;}
#t28_8{left:199px;bottom:156px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_8{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_8{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_8{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_8{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_8{font-size:17px;font-family:MogulArial_16s;color:#202122;}`}
      </style>

      <style id="fonts8" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg8" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="8/8.svg"
          type="image/svg+xml"
          id="pdf8"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_8" class="t s1_8">
          8{" "}
        </span>
        <span id="t3_8" class="t s2_8">
          ТОВЧИЛСОН ҮГИЙН ЖАГСААЛТ{" "}
        </span>
        <span id="t4_8" class="t s3_8">
          Товчлол{" "}
        </span>
        <span id="t5_8" class="t s3_8">
          Тайлбар{" "}
        </span>
        <span id="t6_8" class="t s3_8">
          АУБ{" "}
        </span>
        <span id="t7_8" class="t s3_8">
          Анги удирдсан багш{" "}
        </span>
        <span id="t8_8" class="t s3_8">
          БШУЯ{" "}
        </span>
        <span id="t9_8" class="t s3_8">
          Боловсрол Шинжлэх Ухааны Яам{" "}
        </span>
        <span id="ta_8" class="t s3_8">
          БСШУ{" "}
        </span>
        <span id="tb_8" class="t s3_8">
          Боловсрол Соёл Шинжлэх ухаан{" "}
        </span>
        <span id="tc_8" class="t s3_8">
          БҮТ{" "}
        </span>
        <span id="td_8" class="t s3_8">
          Боловсролын үнэлгээний төв{" "}
        </span>
        <span id="te_8" class="t s3_8">
          БЕГ{" "}
        </span>
        <span id="tf_8" class="t s3_8">
          Боловсролын ерөнхий газар{" "}
        </span>
        <span id="tg_8" class="t s3_8">
          БОАЖЯ{" "}
        </span>
        <span id="th_8" class="t s3_8">
          Байгаль Орчин Аялал Жуулчлалын Яам{" "}
        </span>
        <span id="ti_8" class="t s3_8">
          БСҮХ{" "}
        </span>
        <span id="tj_8" class="t s3_8">
          Боловсролын судалгааны үндэсний хүрээлэн{" "}
        </span>
        <span id="tk_8" class="t s3_8">
          БЕХ{" "}
        </span>
        <span id="tl_8" class="t s3_8">
          Боловсролын ерөнхий хууль{" "}
        </span>
        <span id="tm_8" class="t s3_8">
          ДЭЗФ{" "}
        </span>
        <span id="tn_8" class="t s3_8">
          Дэлхийн Эдийн Засгийн Форум{" "}
        </span>
        <span id="to_8" class="t s3_8">
          ЗАН{" "}
        </span>
        <span id="tp_8" class="t s3_8">
          Заах аргын нэгдэл{" "}
        </span>
        <span id="tq_8" class="t s3_8">
          ИНБ{" "}
        </span>
        <span id="tr_8" class="t s3_8">
          Иргэний нийгмийн байгууллага{" "}
        </span>
        <span id="ts_8" class="t s3_8">
          МХБ{" "}
        </span>
        <span id="tt_8" class="t s3_8">
          Мэргэжлийн хөгжлийн бүлэг{" "}
        </span>
        <span id="tu_8" class="t s3_8">
          МХХТ{" "}
        </span>
        <span id="tv_8" class="t s3_8">
          Мэдээлэл, харилцаа, холбооны технологи{" "}
        </span>
        <span id="tw_8" class="t s3_8">
          ШХА{" "}
        </span>
        <span id="tx_8" class="t s3_8">
          Швейцарын Хөгжлийн Агентлаг{" "}
        </span>
        <span id="ty_8" class="t s3_8">
          МУБИС{" "}
        </span>
        <span id="tz_8" class="t s3_8">
          Монгол Улсын Боловсролын Их сургууль{" "}
        </span>
        <span id="t10_8" class="t s3_8">
          ОБЕГ{" "}
        </span>
        <span id="t11_8" class="t s3_8">
          Онцгой байдлын ерөнхий газар{" "}
        </span>
        <span id="t12_8" class="t s3_8">
          СӨЕБХ{" "}
        </span>
        <span id="t13_8" class="t s3_8">
          Сургуулийн өмнөх болон ерөнхий боловсролын тухай хууль{" "}
        </span>
        <span id="t14_8" class="t s3_8">
          СБААХ{" "}
        </span>
        <span id="t15_8" class="t s3_8">
          Сургууль бүхлээрээ ажиллах арга хандлага{" "}
        </span>
        <span id="t16_8" class="t s3_8">
          СЗ{" "}
        </span>
        <span id="t17_8" class="t s3_8">
          Сургуулийн зөвлөл{" "}
        </span>
        <span id="t18_8" class="t s3_8">
          ССМ{" "}
        </span>
        <span id="t19_8" class="t s3_8">
          Сургуульд суурилсан менежмент{" "}
        </span>
        <span id="t1a_8" class="t s3_8">
          СҮА{" "}
        </span>
        <span id="t1b_8" class="t s3_8">
          Сургалт, үйл ажиллагаа{" "}
        </span>
        <span id="t1c_8" class="t s3_8">
          СДҮА{" "}
        </span>
        <span id="t1d_8" class="t s3_8">
          Сургалтыг дэмжих үйл ажиллагаа{" "}
        </span>
        <span id="t1e_8" class="t s3_8">
          ТХБ{" "}
        </span>
        <span id="t1f_8" class="t s3_8">
          Тогтвортой хөгжлийн боловсрол{" "}
        </span>
        <span id="t1g_8" class="t s3_8">
          ТББ{" "}
        </span>
        <span id="t1h_8" class="t s3_8">
          Төрийн бус байгууллага{" "}
        </span>
        <span id="t1i_8" class="t s3_8">
          ҮСТХ{" "}
        </span>
        <span id="t1j_8" class="t s3_8">
          Үнэлэх-Сайжруулах-Төлөвлөх-Хэрэгжүүлэх{" "}
        </span>
        <span id="t1k_8" class="t s3_8">
          ХАБЭА{" "}
        </span>
        <span id="t1l_8" class="t s3_8">
          Хөдөлмөрийн аюулгүй байдал, эрүүл ахуй{" "}
        </span>
        <span id="t1m_8" class="t s3_8">
          ХГҮА{" "}
        </span>
        <span id="t1n_8" class="t s3_8">
          Хичээлээс гадуурх үйл ажиллагаа{" "}
        </span>
        <span id="t1o_8" class="t s3_8">
          ХСҮДҮШ{" "}
        </span>
        <span id="t1p_8" class="t s3_8">
          Хичээл сургалтын үр дүнг үнэлэх шалгуур{" "}
        </span>
        <span id="t1q_8" class="t s3_8">
          ЭЗХАХБ{" "}
        </span>
        <span id="t1r_8" class="t s4_8">
          Эдийн Засгийн Хамтын Ажиллагаа ба Хөгжлийн Байгууллага{" "}
        </span>
        <span id="t1s_8" class="t s3_8">
          ЭМ{" "}
        </span>
        <span id="t1t_8" class="t s3_8">
          Эрүүл мэнд{" "}
        </span>
        <span id="t1u_8" class="t s3_8">
          ЮНЕСКО{" "}
        </span>
        <span id="t1v_8" class="t s3_8">
          Нэгдсэн Үндэсний байгууллагын Боловсрол, Шинжлэх Ухаан,{" "}
        </span>
        <span id="t1w_8" class="t s3_8">
          Соёлын байгууллага{" "}
        </span>
        <span id="t1x_8" class="t s3_8">
          OECD{" "}
        </span>
        <span id="t1y_8" class="t s3_8">
          Organisation for Economic Co-operation and Development{" "}
        </span>
        <span id="t1z_8" class="t s3_8">
          PISA{" "}
        </span>
        <span id="t20_8" class="t s3_8">
          Programme for International Student Assessment{" "}
        </span>
        <span id="t21_8" class="t s3_8">
          СХ{" "}
        </span>
        <span id="t22_8" class="t s3_8">
          Сургалтын хөтөлбөр{" "}
        </span>
        <span id="t23_8" class="t s3_8">
          СЗ{" "}
        </span>
        <span id="t24_8" class="t s3_8">
          Суралцахуйн зорилт{" "}
        </span>
        <span id="t25_8" class="t s3_8">
          СҮДШГТ{" "}
        </span>
        <span id="t26_8" class="t s3_8">
          Суралцахуйн үр дүнгийн шалгуур гүйцэтгэлийн түвшин{" "}
        </span>
        <span id="t27_8" class="t s3_8">
          СССХ{" "}
        </span>
        <span id="t28_8" class="t s3_8">
          Сургуульд суурилсан сургалтын хөтөлбөр{" "}
        </span>
      </div>
    </div>
  );
}
