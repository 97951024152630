import React from "react";
export default function OneHundredNinetyEigth() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_198{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_198{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:0.43px;}
#t3_198{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:4.18px;}
#t4_198{left:87px;bottom:901px;letter-spacing:-0.18px;word-spacing:9.12px;}
#t5_198{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:2.46px;}
#t6_198{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:-0.12px;}
#t7_198{left:87px;bottom:840px;letter-spacing:-0.15px;word-spacing:-0.24px;}
#t8_198{left:87px;bottom:820px;letter-spacing:-0.14px;word-spacing:0.34px;}
#t9_198{left:87px;bottom:800px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ta_198{left:87px;bottom:759px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tb_198{left:87px;bottom:735px;letter-spacing:-0.16px;}
#tc_198{left:197px;bottom:735px;letter-spacing:-0.12px;}
#td_198{left:330px;bottom:735px;letter-spacing:-0.12px;}
#te_198{left:435px;bottom:735px;letter-spacing:-0.11px;}
#tf_198{left:579px;bottom:735px;letter-spacing:-0.14px;}
#tg_198{left:634px;bottom:735px;letter-spacing:-0.1px;}
#th_198{left:87px;bottom:715px;letter-spacing:-0.15px;word-spacing:8.19px;}
#ti_198{left:87px;bottom:695px;letter-spacing:-0.16px;word-spacing:3.41px;}
#tj_198{left:87px;bottom:674px;letter-spacing:-0.15px;word-spacing:9.15px;}
#tk_198{left:87px;bottom:654px;letter-spacing:-0.16px;word-spacing:1.99px;}
#tl_198{left:87px;bottom:634px;letter-spacing:-0.13px;word-spacing:0.26px;}
#tm_198{left:87px;bottom:614px;letter-spacing:-0.15px;word-spacing:7.9px;}
#tn_198{left:87px;bottom:594px;letter-spacing:-0.14px;word-spacing:0.83px;}
#to_198{left:87px;bottom:574px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tp_198{left:87px;bottom:553px;letter-spacing:-0.16px;word-spacing:6.05px;}
#tq_198{left:87px;bottom:533px;letter-spacing:-0.17px;word-spacing:9.45px;}
#tr_198{left:87px;bottom:513px;letter-spacing:-0.13px;word-spacing:-0.66px;}
#ts_198{left:87px;bottom:493px;letter-spacing:-0.14px;word-spacing:1.17px;}
#tt_198{left:87px;bottom:473px;letter-spacing:-0.14px;word-spacing:-2.06px;}
#tu_198{left:87px;bottom:453px;letter-spacing:-0.18px;word-spacing:2.07px;}
#tv_198{left:87px;bottom:432px;letter-spacing:-0.16px;word-spacing:6.05px;}
#tw_198{left:87px;bottom:412px;letter-spacing:-0.19px;word-spacing:5.8px;}
#tx_198{left:87px;bottom:392px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ty_198{left:87px;bottom:352px;letter-spacing:-0.16px;word-spacing:-0.72px;}
#tz_198{left:538px;bottom:352px;letter-spacing:-0.17px;word-spacing:-0.71px;}
#t10_198{left:87px;bottom:332px;letter-spacing:-0.16px;word-spacing:-1.14px;}
#t11_198{left:302px;bottom:332px;letter-spacing:-0.16px;word-spacing:-1.14px;}
#t12_198{left:87px;bottom:311px;letter-spacing:-0.19px;}
#t13_198{left:222px;bottom:311px;letter-spacing:-0.15px;word-spacing:5.42px;}
#t14_198{left:87px;bottom:291px;letter-spacing:-0.14px;word-spacing:4.73px;}
#t15_198{left:587px;bottom:291px;letter-spacing:-0.09px;word-spacing:4.69px;}
#t16_198{left:87px;bottom:270px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t17_198{left:624px;bottom:270px;letter-spacing:-0.09px;}
#t18_198{left:87px;bottom:250px;letter-spacing:-0.13px;word-spacing:4.91px;}
#t19_198{left:634px;bottom:250px;letter-spacing:-0.13px;}
#t1a_198{left:87px;bottom:230px;letter-spacing:-0.13px;word-spacing:2.44px;}
#t1b_198{left:87px;bottom:209px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_198{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_198{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_198{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}`}
      </style>

      <style id="fonts198" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg198" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="198/198.svg"
          type="image/svg+xml"
          id="pdf198"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_198" class="t s0_198">
          198{" "}
        </span>
        <span id="t2_198" class="t s1_198">
          бөгөөд мөн зорилго нь юм. Сайн менежменттэй сургуулийг бүтээхийн тулд{" "}
        </span>
        <span id="t3_198" class="t s1_198">
          шаардлагатай нөхцөл болон аргачлалыг дээрээс доошоо чиглэлтэйгээр{" "}
        </span>
        <span id="t4_198" class="t s1_198">
          дамжуулах бус багшаас эхлээд сургуультай хамааралтай хүмүүсийн{" "}
        </span>
        <span id="t5_198" class="t s1_198">
          арга ухааныг нэгтгэн, хамтын ажиллагаагаар гаргаж ирэх нь зүйтэй. Мөн{" "}
        </span>
        <span id="t6_198" class="t s1_198">
          хичээл, сургалтын үйл ажиллагааны хэрэгжилттэй холбоотой бодит үр дүнг{" "}
        </span>
        <span id="t7_198" class="t s1_198">
          хамтрагч талууддаа мэдээлж, хуваалцаж байх нь чухал. Эерэг хандлагатай{" "}
        </span>
        <span id="t8_198" class="t s1_198">
          сургуулийн соёлыг бүрдүүлэхийн тулд энэ мэт энгийн арга хэмжээнүүд авч{" "}
        </span>
        <span id="t9_198" class="t s1_198">
          хэрэгжүүлэхийг зөвлөж байна.{" "}
        </span>
        <span id="ta_198" class="t s2_198">
          Сургуулийн менежментийн зураглалыг хэрхэн боловсруулах вэ?{" "}
        </span>
        <span id="tb_198" class="t s1_198">
          Сургуулийн{" "}
        </span>
        <span id="tc_198" class="t s1_198">
          менежментийг{" "}
        </span>
        <span id="td_198" class="t s1_198">
          амжилттай{" "}
        </span>
        <span id="te_198" class="t s1_198">
          хэрэгжүүлэхийн{" "}
        </span>
        <span id="tf_198" class="t s1_198">
          тулд{" "}
        </span>
        <span id="tg_198" class="t s1_198">
          юуны{" "}
        </span>
        <span id="th_198" class="t s1_198">
          өмнө “Сургуулийн менежментийн зураглал”-ыг боловсруулна. Энэ нь{" "}
        </span>
        <span id="ti_198" class="t s1_198">
          сургуулийн ерөнхий дүр төрхийг буулгасан нэгдсэн зураглал юм. Үүнийг{" "}
        </span>
        <span id="tj_198" class="t s1_198">
          боловсруулснаар тухайн жилийн сургуулийн зорилго, сургалтын үйл{" "}
        </span>
        <span id="tk_198" class="t s1_198">
          ажиллагааны уялдаа холбоо тодорхой болно. Ямар сурагчийг бэлтгэхийн{" "}
        </span>
        <span id="tl_198" class="t s1_198">
          тулд ямар зорилго тавьж, түүндээ хэрхэн хүрэх вэ гэдгийг энэхүү
          зураглал/{" "}
        </span>
        <span id="tm_198" class="t s1_198">
          загвараар тодорхой харуулна. Ингэснээр нийт багш, эцэн эх, асран{" "}
        </span>
        <span id="tn_198" class="t s1_198">
          хамгаалагч, харгалзан дэмжигч нь тухайн сургууль энэ жил ямар зорилгод{" "}
        </span>
        <span id="to_198" class="t s1_198">
          хүрэхээр юу хийх гэж байгааг тодорхой мэдэж сургалтын үйл ажиллагаагаа{" "}
        </span>
        <span id="tp_198" class="t s1_198">
          оновчтой явуулах боломжтой болно. Нэгдсэн зураглалаар дэвшүүлсэн{" "}
        </span>
        <span id="tq_198" class="t s1_198">
          зорилго ба үйл ажиллагаатай хамааралгүй, шаардлагагүй ажлуудыг{" "}
        </span>
        <span id="tr_198" class="t s1_198">
          хэрэгжүүлэх эсэх талаар дахин хэлэлцэх, ирэх жилийн ажил руу шилжүүлэх{" "}
        </span>
        <span id="ts_198" class="t s1_198">
          зэргээр ажил сонголтыг шалгуур болгон ашиглах боломжтой. Зөвлөмжийн{" "}
        </span>
        <span id="tt_198" class="t s1_198">
          энэ хэсэгт сургуулийн менежментийн зураглал боловсруулах аргачлал
          бүхий{" "}
        </span>
        <span id="tu_198" class="t s1_198">
          дадлага ажлын удирдамжийг орууллаа. Та бүхэн хамтын оролцоотойгоор{" "}
        </span>
        <span id="tv_198" class="t s1_198">
          менежментийн зураглал боловсруулахдаа дараах аргачлалыг ашиглаж{" "}
        </span>
        <span id="tw_198" class="t s1_198">
          болох ба сургуулийн онцлог, боломж бололцоонд суурилан баяжуулж,{" "}
        </span>
        <span id="tx_198" class="t s1_198">
          хөгжүүлж болох юм.{" "}
        </span>
        <span id="ty_198" class="t s1_198">
          Бид, “шийдэл гаргах”-д сургууль асуудлаа тодорхойлох{" "}
        </span>
        <span id="tz_198" class="t s1_198">
          асуудлуудаас аль{" "}
        </span>
        <span id="t10_198" class="t s1_198">
          нь тулгамдаж буйг тогтох{" "}
        </span>
        <span id="t11_198" class="t s1_198">
          тэдгээрт тулгуурлан хичээлийн жилийн зорилгоо{" "}
        </span>
        <span id="t12_198" class="t s1_198">
          тодорхойлох{" "}
        </span>
        <span id="t13_198" class="t s1_198">
          тухайн хичээлийн жилийн зорилтуудаа тодорхойлох{" "}
        </span>
        <span id="t14_198" class="t s1_198">
          зорилт бүрээр хэрэгжүүлэх үйл ажиллагааг тодорхойлох{" "}
        </span>
        <span id="t15_198" class="t s1_198">
          тухайн үйл{" "}
        </span>
        <span id="t16_198" class="t s1_198">
          ажиллагааг хэний ямар оролцоотой хэрэгжүүлэхдээ санал нэгдэх{" "}
        </span>
        <span id="t17_198" class="t s1_198">
          тухайн{" "}
        </span>
        <span id="t18_198" class="t s1_198">
          үйл ажиллагааг ямар хугацаанд хэрэгжүүлэхдээ санал нэгдэх{" "}
        </span>
        <span id="t19_198" class="t s1_198">
          хүрэх{" "}
        </span>
        <span id="t1a_198" class="t s1_198">
          үр дүнгээ хамтаараа тодорхойлох зэрэг сургуулийн хөгжлийн төлөөх үйл{" "}
        </span>
        <span id="t1b_198" class="t s1_198">
          ажиллагааны төлөвлөлттэй илүү холбон авч үзэхийг зөвлөж байна.{" "}
        </span>
      </div>
    </div>
  );
}
