import React from "react";
export default function FourtyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_42{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_12{left:329px;bottom:941px;letter-spacing:-0.26px;}
#t2_42{left:70px;bottom:59px;letter-spacing:0.16px;}

.s0_42{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_42{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}`}
      </style>
      <style id="fonts42" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg42" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="42/42.svg"
          type="image/svg+xml"
          id="pdf42"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_42" class="t s1_42">
          42{" "}
        </span>
        <span id="t2_12" class="t s2_12">
          ТЭМДЭГЛЭЛ{" "}
        </span>
      </div>
    </div>
  );
}
