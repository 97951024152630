import React from "react";
export default function F147() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_147{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_147{left:214px;bottom:945px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t3_147{left:87px;bottom:615px;letter-spacing:-0.14px;word-spacing:3.71px;}
#t4_147{left:87px;bottom:595px;letter-spacing:-0.14px;word-spacing:-1.04px;}
#t5_147{left:145px;bottom:564px;letter-spacing:0.12px;word-spacing:0.01px;}
#t6_147{left:575px;bottom:546px;letter-spacing:0.09px;word-spacing:0.04px;}
#t7_147{left:91px;bottom:506px;}
#t8_147{left:141.2px;bottom:406.9px;letter-spacing:0.09px;word-spacing:0.03px;}
#t9_147{left:155.9px;bottom:441.5px;letter-spacing:0.08px;}
#ta_147{left:395px;bottom:515px;letter-spacing:0.08px;}
#tb_147{left:184px;bottom:466px;letter-spacing:0.13px;word-spacing:-0.01px;}
#tc_147{left:214px;bottom:452px;letter-spacing:0.1px;}
#td_147{left:336px;bottom:466px;letter-spacing:0.19px;}
#te_147{left:332px;bottom:452px;letter-spacing:0.1px;}
#tf_147{left:421px;bottom:466px;letter-spacing:0.17px;}
#tg_147{left:425px;bottom:452px;letter-spacing:0.1px;}
#th_147{left:503px;bottom:466px;letter-spacing:0.16px;}
#ti_147{left:509px;bottom:452px;letter-spacing:0.1px;}
#tj_147{left:514px;bottom:437px;letter-spacing:-0.01px;}
#tk_147{left:595px;bottom:466px;letter-spacing:0.12px;}
#tl_147{left:594px;bottom:452px;letter-spacing:0.09px;word-spacing:0.02px;}
#tm_147{left:610px;bottom:437px;letter-spacing:0.13px;}
#tn_147{left:94px;bottom:354px;}
#to_147{left:155.9px;bottom:328.4px;letter-spacing:0.12px;}
#tp_147{left:177px;bottom:377px;letter-spacing:0.09px;word-spacing:0.01px;}
#tq_147{left:177px;bottom:362px;letter-spacing:0.06px;}
#tr_147{left:177px;bottom:348px;letter-spacing:0.13px;}
#ts_147{left:177px;bottom:333px;letter-spacing:0.09px;word-spacing:0.02px;}
#tt_147{left:177px;bottom:318px;letter-spacing:0.08px;word-spacing:0.02px;}
#tu_147{left:177px;bottom:304px;letter-spacing:0.1px;}
#tv_147{left:190px;bottom:289px;letter-spacing:0.1px;}
#tw_147{left:308px;bottom:377px;letter-spacing:0.04px;word-spacing:0.07px;}
#tx_147{left:308px;bottom:362px;letter-spacing:0.09px;}
#ty_147{left:308px;bottom:348px;letter-spacing:0.13px;}
#tz_147{left:408px;bottom:377px;letter-spacing:0.09px;}
#t10_147{left:408px;bottom:362px;letter-spacing:0.1px;}
#t11_147{left:408px;bottom:348px;letter-spacing:-0.04px;word-spacing:-0.04px;}
#t12_147{left:408px;bottom:333px;letter-spacing:0.06px;word-spacing:0.04px;}
#t13_147{left:408px;bottom:318px;letter-spacing:0.11px;}
#t14_147{left:490px;bottom:377px;letter-spacing:0.12px;word-spacing:-0.02px;}
#t15_147{left:490px;bottom:362px;letter-spacing:0.09px;}
#t16_147{left:490px;bottom:348px;letter-spacing:0.06px;}
#t17_147{left:490px;bottom:333px;letter-spacing:0.11px;}
#t18_147{left:490px;bottom:318px;letter-spacing:0.05px;}
#t19_147{left:490px;bottom:304px;letter-spacing:0.07px;}
#t1a_147{left:585px;bottom:377px;letter-spacing:0.05px;}
#t1b_147{left:585px;bottom:362px;letter-spacing:0.1px;}
#t1c_147{left:585px;bottom:348px;letter-spacing:0.03px;word-spacing:0.07px;}
#t1d_147{left:585px;bottom:333px;letter-spacing:0.1px;}
#t1e_147{left:585px;bottom:318px;letter-spacing:0.05px;}
#t1f_147{left:585px;bottom:304px;letter-spacing:0.06px;}
#t1g_147{left:94px;bottom:209px;}
#t1h_147{left:133.9px;bottom:191.4px;letter-spacing:0.08px;}
#t1i_147{left:148.6px;bottom:179.3px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1j_147{left:163.2px;bottom:204.4px;letter-spacing:0.05px;}
#t1k_147{left:177px;bottom:261px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1l_147{left:177px;bottom:246px;letter-spacing:0.05px;word-spacing:0.05px;}
#t1m_147{left:177px;bottom:232px;letter-spacing:0.07px;word-spacing:0.03px;}
#t1n_147{left:177px;bottom:217px;letter-spacing:0.03px;}
#t1o_147{left:308px;bottom:261px;letter-spacing:0.06px;}
#t1p_147{left:308px;bottom:246px;letter-spacing:0.1px;}
#t1q_147{left:308px;bottom:232px;letter-spacing:-0.04px;word-spacing:0.13px;}
#t1r_147{left:308px;bottom:217px;letter-spacing:0.09px;}
#t1s_147{left:308px;bottom:202px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1t_147{left:308px;bottom:188px;letter-spacing:0.06px;word-spacing:0.04px;}
#t1u_147{left:308px;bottom:173px;letter-spacing:0.12px;}
#t1v_147{left:408px;bottom:261px;letter-spacing:0.08px;}
#t1w_147{left:408px;bottom:246px;letter-spacing:0.11px;}
#t1x_147{left:408px;bottom:232px;letter-spacing:0.06px;}
#t1y_147{left:408px;bottom:217px;letter-spacing:0.03px;}
#t1z_147{left:408px;bottom:202px;letter-spacing:0.08px;word-spacing:0.02px;}
#t20_147{left:408px;bottom:188px;letter-spacing:0.01px;word-spacing:0.11px;}
#t21_147{left:490px;bottom:261px;letter-spacing:0.08px;}
#t22_147{left:490px;bottom:246px;letter-spacing:0.08px;word-spacing:0.02px;}
#t23_147{left:490px;bottom:232px;letter-spacing:0.06px;}
#t24_147{left:490px;bottom:217px;letter-spacing:0.03px;}
#t25_147{left:490px;bottom:202px;letter-spacing:0.01px;word-spacing:0.11px;}
#t26_147{left:585px;bottom:261px;letter-spacing:0.08px;}
#t27_147{left:585px;bottom:246px;letter-spacing:0.11px;}
#t28_147{left:585px;bottom:232px;letter-spacing:0.05px;}
#t29_147{left:585px;bottom:217px;letter-spacing:0.03px;}
#t2a_147{left:585px;bottom:202px;letter-spacing:0.04px;}

.s0_147{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_147{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_147{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_147{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_147{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}
.s5_147{font-size:12px;font-family:MogulArial_16s;color:#000;}
.t.m0_147{transform:matrix(0,-1,1,0,0,0);}`}
      </style>

      <style id="fonts147" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg147" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="147/147.svg"
          type="image/svg+xml"
          id="pdf147"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_147" class="t s0_147">
          147{" "}
        </span>
        <span id="t2_147" class="t s1_147">
          Зураг 3.23. Багшийн мэргэжлийн хөгжлийн зураглал{" "}
        </span>
        <span id="t3_147" class="t s2_147">
          Сургуулийн удирдах ажилтан нь багш өөрийн хэрэгжүүлсэн мэргэжлийн{" "}
        </span>
        <span id="t4_147" class="t s2_147">
          хөгжлийн төлөвлөгөөг үнэлэхэд дараах ажлын хуудсыг ашиглах боломжтой.{" "}
        </span>
        <span id="t5_147" class="t s3_147">
          Хүснэгт 3.21. Ажлын хуудас 2: Багшийн мэргэжлийн хөгжлийн төлөвлөгөөг{" "}
        </span>
        <span id="t6_147" class="t s3_147">
          үнэлэх рубрик{" "}
        </span>
        <span id="t7_147" class="t s4_147">
          №{" "}
        </span>
        <span id="t8_147" class="t m0_147 s4_147">
          Үнэлгээний шалгуур{" "}
        </span>
        <span id="t9_147" class="t m0_147 s4_147">
          үзүүлэлт{" "}
        </span>
        <span id="ta_147" class="t s4_147">
          Үзүүлэлт{" "}
        </span>
        <span id="tb_147" class="t s4_147">
          Хангалттай сайн{" "}
        </span>
        <span id="tc_147" class="t s4_147">
          (41-50){" "}
        </span>
        <span id="td_147" class="t s4_147">
          Сайн{" "}
        </span>
        <span id="te_147" class="t s4_147">
          (31-40){" "}
        </span>
        <span id="tf_147" class="t s4_147">
          Дундаж{" "}
        </span>
        <span id="tg_147" class="t s4_147">
          (21-30){" "}
        </span>
        <span id="th_147" class="t s4_147">
          Дунджаас{" "}
        </span>
        <span id="ti_147" class="t s4_147">
          доогуур{" "}
        </span>
        <span id="tj_147" class="t s4_147">
          (11-20){" "}
        </span>
        <span id="tk_147" class="t s4_147">
          Хангалтгүй{" "}
        </span>
        <span id="tl_147" class="t s4_147">
          (10 түүнээс{" "}
        </span>
        <span id="tm_147" class="t s4_147">
          доош){" "}
        </span>
        <span id="tn_147" class="t s4_147">
          1{" "}
        </span>
        <span id="to_147" class="t m0_147 s4_147">
          Хаяг{" "}
        </span>
        <span id="tp_147" class="t s5_147">
          1.Орон нутаг{" "}
        </span>
        <span id="tq_147" class="t s5_147">
          2.Сургууль{" "}
        </span>
        <span id="tr_147" class="t s5_147">
          3.Мэргэшил{" "}
        </span>
        <span id="ts_147" class="t s5_147">
          4.Хичээлийн жил{" "}
        </span>
        <span id="tt_147" class="t s5_147">
          5.Овог нэр{" "}
        </span>
        <span id="tu_147" class="t s5_147">
          6.Огноо бүрэн{" "}
        </span>
        <span id="tv_147" class="t s5_147">
          цэцгтэй{" "}
        </span>
        <span id="tw_147" class="t s5_147">
          Бүрэн боловч{" "}
        </span>
        <span id="tx_147" class="t s5_147">
          1-2 хэсгийг{" "}
        </span>
        <span id="ty_147" class="t s5_147">
          дутуу{" "}
        </span>
        <span id="tz_147" class="t s5_147">
          3 хэсгийг{" "}
        </span>
        <span id="t10_147" class="t s5_147">
          бичиж,{" "}
        </span>
        <span id="t11_147" class="t s5_147">
          бусдыг дутуу,{" "}
        </span>
        <span id="t12_147" class="t s5_147">
          эсвэл эмх{" "}
        </span>
        <span id="t13_147" class="t s5_147">
          цэгцгүй{" "}
        </span>
        <span id="t14_147" class="t s5_147">
          3-аас дээш{" "}
        </span>
        <span id="t15_147" class="t s5_147">
          хэсгийг{" "}
        </span>
        <span id="t16_147" class="t s5_147">
          дурдаагүй.{" "}
        </span>
        <span id="t17_147" class="t s5_147">
          Бичвэрийн{" "}
        </span>
        <span id="t18_147" class="t s5_147">
          шаардлага{" "}
        </span>
        <span id="t19_147" class="t s5_147">
          хангалтгүй{" "}
        </span>
        <span id="t1a_147" class="t s5_147">
          Эдгээрийг{" "}
        </span>
        <span id="t1b_147" class="t s5_147">
          огт бичээгүй.{" "}
        </span>
        <span id="t1c_147" class="t s5_147">
          Дутуу, дизайн{" "}
        </span>
        <span id="t1d_147" class="t s5_147">
          нь эмх цэгцгүй,{" "}
        </span>
        <span id="t1e_147" class="t s5_147">
          шаардлага{" "}
        </span>
        <span id="t1f_147" class="t s5_147">
          хангаагүй{" "}
        </span>
        <span id="t1g_147" class="t s4_147">
          2{" "}
        </span>
        <span id="t1h_147" class="t m0_147 s4_147">
          Эргэцүүлэл,{" "}
        </span>
        <span id="t1i_147" class="t m0_147 s4_147">
          өөрийн үнэлгээ,{" "}
        </span>
        <span id="t1j_147" class="t m0_147 s4_147">
          зорилго{" "}
        </span>
        <span id="t1k_147" class="t s5_147">
          Эргэцүүлэл, өөрийн{" "}
        </span>
        <span id="t1l_147" class="t s5_147">
          үнэлгээ, зорилго{" "}
        </span>
        <span id="t1m_147" class="t s5_147">
          уялдаатай бүрэн зөв{" "}
        </span>
        <span id="t1n_147" class="t s5_147">
          гаргасан{" "}
        </span>
        <span id="t1o_147" class="t s5_147">
          Эдгээрийн{" "}
        </span>
        <span id="t1p_147" class="t s5_147">
          аль нэг нь{" "}
        </span>
        <span id="t1q_147" class="t s5_147">
          дутуу, эсвэл{" "}
        </span>
        <span id="t1r_147" class="t s5_147">
          эргэцүүлэл-{" "}
        </span>
        <span id="t1s_147" class="t s5_147">
          өөрийн үнэлгээ-{" "}
        </span>
        <span id="t1t_147" class="t s5_147">
          зорилго уялдаа{" "}
        </span>
        <span id="t1u_147" class="t s5_147">
          муутай{" "}
        </span>
        <span id="t1v_147" class="t s5_147">
          Эргэцүүлэл,{" "}
        </span>
        <span id="t1w_147" class="t s5_147">
          өөрийн{" "}
        </span>
        <span id="t1x_147" class="t s5_147">
          үнэлгээ,{" "}
        </span>
        <span id="t1y_147" class="t s5_147">
          зорилго{" "}
        </span>
        <span id="t1z_147" class="t s5_147">
          нь уялдаа{" "}
        </span>
        <span id="t20_147" class="t s5_147">
          тодорхой бус{" "}
        </span>
        <span id="t21_147" class="t s5_147">
          Эргэцүүлэлгүй,{" "}
        </span>
        <span id="t22_147" class="t s5_147">
          өөрийн үнэлгээ{" "}
        </span>
        <span id="t23_147" class="t s5_147">
          хангалтгүй,{" "}
        </span>
        <span id="t24_147" class="t s5_147">
          зорилго{" "}
        </span>
        <span id="t25_147" class="t s5_147">
          тодорхой бус{" "}
        </span>
        <span id="t26_147" class="t s5_147">
          Эргэцүүлэлгүй,{" "}
        </span>
        <span id="t27_147" class="t s5_147">
          өөрийн{" "}
        </span>
        <span id="t28_147" class="t s5_147">
          үнэлгээтэй,{" "}
        </span>
        <span id="t29_147" class="t s5_147">
          зорилго{" "}
        </span>
        <span id="t2a_147" class="t s5_147">
          оновчгүй{" "}
        </span>
      </div>
    </div>
  );
}
