import React from "react";
export default function TwentyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_25{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_25{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_25{left:87px;bottom:930px;letter-spacing:0.17px;word-spacing:0.07px;}
#t4_25{left:87px;bottom:894px;letter-spacing:-0.16px;}
#t5_25{left:195px;bottom:894px;letter-spacing:-0.25px;}
#t6_25{left:293px;bottom:894px;letter-spacing:-0.1px;}
#t7_25{left:386px;bottom:894px;letter-spacing:-0.05px;}
#t8_25{left:458px;bottom:894px;letter-spacing:-0.08px;}
#t9_25{left:598px;bottom:894px;letter-spacing:-0.06px;}
#ta_25{left:87px;bottom:874px;letter-spacing:-0.15px;word-spacing:-1.25px;}
#tb_25{left:87px;bottom:854px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tc_25{left:87px;bottom:789px;word-spacing:0.21px;}
#td_25{left:87px;bottom:756px;letter-spacing:-0.15px;word-spacing:6.76px;}
#te_25{left:87px;bottom:736px;letter-spacing:-0.14px;word-spacing:5.17px;}
#tf_25{left:87px;bottom:716px;letter-spacing:-0.13px;word-spacing:-0.26px;}
#tg_25{left:87px;bottom:696px;letter-spacing:-0.13px;word-spacing:0.04px;}
#th_25{left:87px;bottom:663px;letter-spacing:-0.13px;word-spacing:1.05px;}
#ti_25{left:87px;bottom:643px;letter-spacing:-0.13px;word-spacing:2.96px;}
#tj_25{left:87px;bottom:622px;letter-spacing:-0.15px;word-spacing:5.34px;}
#tk_25{left:87px;bottom:602px;letter-spacing:-0.12px;word-spacing:2.15px;}
#tl_25{left:87px;bottom:582px;letter-spacing:-0.17px;word-spacing:3.63px;}
#tm_25{left:87px;bottom:562px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tn_25{left:100px;bottom:511px;letter-spacing:0.09px;word-spacing:1.63px;}
#to_25{left:100px;bottom:492px;letter-spacing:0.1px;word-spacing:5.55px;}
#tp_25{left:100px;bottom:474px;letter-spacing:0.08px;word-spacing:5.16px;}
#tq_25{left:100px;bottom:456px;letter-spacing:0.14px;word-spacing:7.7px;}
#tr_25{left:100px;bottom:437px;letter-spacing:0.06px;word-spacing:0.6px;}
#ts_25{left:100px;bottom:419px;letter-spacing:0.12px;word-spacing:1.72px;}
#tt_25{left:100px;bottom:401px;letter-spacing:0.1px;word-spacing:-1.26px;}
#tu_25{left:100px;bottom:382px;letter-spacing:0.12px;word-spacing:7.04px;}
#tv_25{left:100px;bottom:364px;letter-spacing:-0.01px;word-spacing:0.14px;}
#tw_25{left:87px;bottom:294px;letter-spacing:-0.13px;word-spacing:-0.17px;}
#tx_25{left:87px;bottom:274px;letter-spacing:-0.15px;word-spacing:2.62px;}
#ty_25{left:87px;bottom:254px;letter-spacing:-0.16px;word-spacing:3.29px;}
#tz_25{left:87px;bottom:234px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_25{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_25{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_25{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_25{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_25{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s5_25{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_25{font-size:15px;font-family:MogulArial_18b;color:#000;}
.t.m0_25{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts25" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg25" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="25/25.svg"
          type="image/svg+xml"
          id="pdf25"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_25" class="t s1_25">
          25{" "}
        </span>
        <span id="t3_25" class="t s2_25">
          1.2 СУРГУУЛИЙН МЕНЕЖМЕНТИЙН ЗАГВАР{" "}
        </span>
        <span id="t4_25" class="t s3_25">
          Сургуулийн{" "}
        </span>
        <span id="t5_25" class="t s3_25">
          удирдлага{" "}
        </span>
        <span id="t6_25" class="t s3_25">
          өөрсдийн{" "}
        </span>
        <span id="t7_25" class="t s3_25">
          хийсэн{" "}
        </span>
        <span id="t8_25" class="t s3_25">
          шинжилгээндээ{" "}
        </span>
        <span id="t9_25" class="t s3_25">
          үндэслэн{" "}
        </span>
        <span id="ta_25" class="t s3_25">
          сургуулийнхаа онцлог, сурагчдынхаа хэрэгцээ, хүний хөгжилдөө
          тулгуурлан{" "}
        </span>
        <span id="tb_25" class="t s3_25">
          менежментийн загвараа боловсруулан сургуулиа удирддаг.{" "}
        </span>
        <span id="tc_25" class="t s4_25">
          “ГУРВАН ДАВХАРГАТ ПИРАМИД” ЗАГВАР{" "}
        </span>
        <span id="td_25" class="t s3_25">
          Өмнөх 1.1 хэсэгт боловсрол хэрхэн хувьсан өөрчлөгдөж буй талаар,{" "}
        </span>
        <span id="te_25" class="t s3_25">
          суралцагч, багш, сургалтын хөтөлбөр, сургууль хэрхэн хөгжиж буй чиг{" "}
        </span>
        <span id="tf_25" class="t s3_25">
          хандлагыг олон улсын болон өөрийн орны харьцуулсан мэдээллийг нэгтгэн{" "}
        </span>
        <span id="tg_25" class="t s3_25">
          дүгнэж авч үзсэн билээ.{" "}
        </span>
        <span id="th_25" class="t s3_25">
          Бидний хувьд сургуулийн өнөөгийн нийтлэг байдлыг тооцож, ирээдүйн чиг{" "}
        </span>
        <span id="ti_25" class="t s3_25">
          хандлагыг баримжаалан менежментээ тасралтгүй сайжруулах үйл явцыг{" "}
        </span>
        <span id="tj_25" class="t s3_25">
          сургуулиуд ямар зарчим, арга, хандлагаар хэрэгжүүлбэл богино, дунд,{" "}
        </span>
        <span id="tk_25" class="t s3_25">
          урт хугацаанд зорьсон үр дүндээ хүрч, суралцагч, төгсөгчдөд үр өгөөжөө{" "}
        </span>
        <span id="tl_25" class="t s3_25">
          өгөх боломжтойг бодолцон энэхүү “Гурван давхаргат пирамид” загварыг{" "}
        </span>
        <span id="tm_25" class="t s3_25">
          боловсруулсан болно.{" "}
        </span>
        <span id="tn_25" class="t s5_25">
          Сургуулийн удирдлагын үр өгөөжийг нэмэгдүүлэхэд чиглэсэн өөр өөр
          загвар{" "}
        </span>
        <span id="to_25" class="t s5_25">
          хувилбарууд тухайлбал, PDCA (Plan-Do-Check-Action), R-PDCA (Research,{" "}
        </span>
        <span id="tp_25" class="t s5_25">
          Plan, Do, Check, Act)…түгээмэл хэрэглэгддэг. PDCA төстэй P-D-Study-A,{" "}
        </span>
        <span id="tq_25" class="t s5_25">
          R-PDCA, ADDIE (Analyze-Design-Develop-Implement-Evaluate) гэх мэт 3-5{" "}
        </span>
        <span id="tr_25" class="t s5_25">
          алхамтай загварууд ч мөн байдаг. Боловсролын байгууллагын менежментийн{" "}
        </span>
        <span id="ts_25" class="t s6_25">
          тогтолцоо ISO-21001/2018, MNS 21001/2021 ”Plan-Do-Check-Action”
          стандарт{" "}
        </span>
        <span id="tt_25" class="t s5_25">
          нь олон улсад хүлээн зөвшөөрөгдөж 2018 онд баталгаажсан. Харин
          Төлөвлөлт-{" "}
        </span>
        <span id="tu_25" class="t s5_25">
          Хэрэгжилт-Үнэлгээ-Сайжруулалт гэсэн стандарт Монгол улсад 2021 онд{" "}
        </span>
        <span id="tv_25" class="t s5_25">
          батлагдсан байна.{" "}
        </span>
        <span id="tw_25" class="t s3_25">
          Энэхүү загварыг байгууллагын өөрчлөлтийн менежментийн онол (Theory of{" "}
        </span>
        <span id="tx_25" class="t s3_25">
          Changes), менежментийн ТХҮС (PDCA) загвар болон шинээр батлагдсан{" "}
        </span>
        <span id="ty_25" class="t s3_25">
          Боловсролын ерөнхий хууль, одоо хэлэлцэгдэж буй сургалтын хөтөлбөр{" "}
        </span>
        <span id="tz_25" class="t s3_25">
          шинэчлэлийн үзэл баримтлал зэрэгт үндэслэн боловсруулав.{" "}
        </span>
      </div>
    </div>
  );
}
