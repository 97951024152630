import React from "react";
export default function OneHundredNinetyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_193{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_193{left:142px;bottom:926px;letter-spacing:0.18px;}
#t3_193{left:87px;bottom:878px;}
#t4_193{left:114px;bottom:878px;letter-spacing:-0.15px;word-spacing:-1.59px;}
#t5_193{left:114px;bottom:858px;letter-spacing:-0.12px;}
#t6_193{left:208px;bottom:858px;letter-spacing:-0.14px;word-spacing:-0.97px;}
#t7_193{left:114px;bottom:838px;letter-spacing:-0.18px;}
#t8_193{left:87px;bottom:813px;}
#t9_193{left:114px;bottom:813px;letter-spacing:-0.15px;word-spacing:11.19px;}
#ta_193{left:114px;bottom:793px;letter-spacing:-0.17px;word-spacing:10.05px;}
#tb_193{left:114px;bottom:773px;letter-spacing:-0.18px;word-spacing:3.23px;}
#tc_193{left:465px;bottom:773px;letter-spacing:-0.11px;word-spacing:3.17px;}
#td_193{left:114px;bottom:753px;letter-spacing:-0.13px;word-spacing:1.55px;}
#te_193{left:114px;bottom:732px;letter-spacing:-0.14px;}
#tf_193{left:87px;bottom:708px;}
#tg_193{left:114px;bottom:708px;letter-spacing:-0.14px;word-spacing:-2.86px;}
#th_193{left:114px;bottom:688px;letter-spacing:-0.14px;word-spacing:0.03px;}
#ti_193{left:87px;bottom:663px;}
#tj_193{left:114px;bottom:663px;letter-spacing:-0.14px;}
#tk_193{left:226px;bottom:663px;letter-spacing:-0.22px;}
#tl_193{left:315px;bottom:663px;letter-spacing:-0.23px;}
#tm_193{left:418px;bottom:663px;letter-spacing:-0.16px;}
#tn_193{left:489px;bottom:663px;letter-spacing:-0.17px;}
#to_193{left:622px;bottom:663px;letter-spacing:-0.2px;}
#tp_193{left:114px;bottom:643px;letter-spacing:-0.15px;word-spacing:9.9px;}
#tq_193{left:114px;bottom:623px;letter-spacing:-0.16px;word-spacing:-0.38px;}
#tr_193{left:114px;bottom:603px;letter-spacing:-0.17px;word-spacing:0.08px;}
#ts_193{left:97px;bottom:540px;letter-spacing:0.07px;word-spacing:0.13px;}
#tt_193{left:317px;bottom:514px;letter-spacing:0.08px;}
#tu_193{left:87px;bottom:474px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tv_193{left:113px;bottom:446px;letter-spacing:-0.05px;}
#tw_193{left:139px;bottom:446px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tx_193{left:113px;bottom:421px;letter-spacing:-0.05px;}
#ty_193{left:139px;bottom:421px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tz_193{left:113px;bottom:397px;letter-spacing:-0.05px;}
#t10_193{left:139px;bottom:397px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t11_193{left:113px;bottom:372px;letter-spacing:-0.05px;}
#t12_193{left:139px;bottom:372px;letter-spacing:-0.2px;word-spacing:10.31px;}
#t13_193{left:139px;bottom:352px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t14_193{left:113px;bottom:327px;letter-spacing:-0.05px;}
#t15_193{left:139px;bottom:327px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t16_193{left:87px;bottom:287px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t17_193{left:87px;bottom:267px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t18_193{left:113px;bottom:238px;letter-spacing:-0.05px;}
#t19_193{left:139px;bottom:238px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1a_193{left:113px;bottom:214px;letter-spacing:-0.05px;}
#t1b_193{left:139px;bottom:214px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_193{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_193{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_193{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_193{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_193{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s5_193{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_193{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts193" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg193" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="193/193.svg"
          type="image/svg+xml"
          id="pdf193"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_193" class="t s0_193">
          193{" "}
        </span>
        <span id="t2_193" class="t s1_193">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_193" class="t s2_193">
          •{" "}
        </span>
        <span id="t4_193" class="t s3_193">
          Тухайн орон нутгийн БШУГ болон мэргэжлийн байгууллагуудтай хамтран{" "}
        </span>
        <span id="t5_193" class="t s3_193">
          ажиллахад{" "}
        </span>
        <span id="t6_193" class="t s3_193">
          сургуулийн удирдлага манлайлал үзүүлж ажиллахыг зөвлөж{" "}
        </span>
        <span id="t7_193" class="t s3_193">
          байна.{" "}
        </span>
        <span id="t8_193" class="t s2_193">
          •{" "}
        </span>
        <span id="t9_193" class="t s3_193">
          Хувийн хэвшлийн байгууллагууд нийгмийн хариуцлагын хүрээнд{" "}
        </span>
        <span id="ta_193" class="t s3_193">
          сургуультай түншлэн ажиллах боломжтой тул санаачлагыг орон{" "}
        </span>
        <span id="tb_193" class="t s3_193">
          нутгийн засаг, захиргаа сургууль хамтран{" "}
        </span>
        <span id="tc_193" class="t s3_193">
          төлөвлөж хэрэгжүүлээрэй.{" "}
        </span>
        <span id="td_193" class="t s3_193">
          Хэрэгжилтийн үр дүнд хүүхдэд эерэг өөрчлөлт гарсан байхад анхаарч{" "}
        </span>
        <span id="te_193" class="t s3_193">
          төлөвлөөрөй.{" "}
        </span>
        <span id="tf_193" class="t s2_193">
          •{" "}
        </span>
        <span id="tg_193" class="t s3_193">
          Үндэсний болон олон улсын байгууллагуудтай хүүхэд хөгжил, хамгааллын{" "}
        </span>
        <span id="th_193" class="t s3_193">
          чиглэлээр хамтран ажиллах боломжуудыг ирэлхийлж ажиллаарай.{" "}
        </span>
        <span id="ti_193" class="t s2_193">
          •{" "}
        </span>
        <span id="tj_193" class="t s3_193">
          Түншлэлийг{" "}
        </span>
        <span id="tk_193" class="t s3_193">
          оновчтой{" "}
        </span>
        <span id="tl_193" class="t s3_193">
          удирдлага,{" "}
        </span>
        <span id="tm_193" class="t s3_193">
          зохион{" "}
        </span>
        <span id="tn_193" class="t s3_193">
          байгуулалтаар{" "}
        </span>
        <span id="to_193" class="t s3_193">
          хангаж{" "}
        </span>
        <span id="tp_193" class="t s3_193">
          ажиллахын тулд тулгамдсан асуудлаа зөв тодорхойлох, түүнийг{" "}
        </span>
        <span id="tq_193" class="t s3_193">
          шийдвэрлэх арга хэрэгсэл, бодит нөөц, боломж, бэрхшээл, давуу ба сул{" "}
        </span>
        <span id="tr_193" class="t s3_193">
          талыг судлан нэгдсэн ойлголтод хүрэх нь чухал байна.{" "}
        </span>
        <span id="ts_193" class="t s4_193">
          3.6.6 СУРГАЛТ, ҮЙЛ АЖИЛЛАГААНЫ ХЭРЭГЖИЛТИЙГ ҮНЭЛЭХ,{" "}
        </span>
        <span id="tt_193" class="t s4_193">
          САЙЖРУУЛАХ{" "}
        </span>
        <span id="tu_193" class="t s5_193">
          Сургалт, үйл ажиллагааны хэрэгжилтийг яагаад үнэлэх хэрэгтэй вэ?{" "}
        </span>
        <span id="tv_193" class="t s6_193">
          1.{" "}
        </span>
        <span id="tw_193" class="t s3_193">
          Сургуулийн зорилго, зорилтууд хэрэгжиж чадсан эсэх{" "}
        </span>
        <span id="tx_193" class="t s6_193">
          2.{" "}
        </span>
        <span id="ty_193" class="t s3_193">
          Нэгдсэн цогц төлөвлөлт бүрэн хэрэгжсэн эсэх{" "}
        </span>
        <span id="tz_193" class="t s6_193">
          3.{" "}
        </span>
        <span id="t10_193" class="t s3_193">
          Төсөөлж, хүлээсэн үр дүнд хүрсэн эсэх зэргийг тодорхойлох{" "}
        </span>
        <span id="t11_193" class="t s6_193">
          4.{" "}
        </span>
        <span id="t12_193" class="t s3_193">
          Сургалт, үйл ажиллагаа, менежментийн доголдол, сул болон{" "}
        </span>
        <span id="t13_193" class="t s3_193">
          ололттой талыг илрүүлэх{" "}
        </span>
        <span id="t14_193" class="t s6_193">
          5.{" "}
        </span>
        <span id="t15_193" class="t s3_193">
          Цаашдын чиглэл, юуг яаж сайжруулах арга замын зураглал гаргах{" "}
        </span>
        <span id="t16_193" class="t s5_193">
          Сургалт, үйл ажиллагааны хэрэгжилтийг яагаад сайжруулах{" "}
        </span>
        <span id="t17_193" class="t s5_193">
          шаардлагатай вэ?{" "}
        </span>
        <span id="t18_193" class="t s6_193">
          1.{" "}
        </span>
        <span id="t19_193" class="t s3_193">
          Боловсролын үйлчилгээний хүртээмж, чанар, үр дүнг дээшлүүлэх{" "}
        </span>
        <span id="t1a_193" class="t s6_193">
          2.{" "}
        </span>
        <span id="t1b_193" class="t s3_193">
          Хүүхэд бүрийн хөгжлийн тэгш хүртээмжтэй байдлыг хангах{" "}
        </span>
      </div>
    </div>
  );
}
