import React from "react";
export default function HundredThirteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_113{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_113{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t3_113{left:87px;bottom:908px;letter-spacing:-0.12px;word-spacing:0.11px;}
#t4_113{left:87px;bottom:888px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t5_113{left:224px;bottom:888px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t6_113{left:87px;bottom:854px;letter-spacing:-0.16px;word-spacing:9.27px;}
#t7_113{left:608px;bottom:854px;letter-spacing:-0.13px;}
#t8_113{left:87px;bottom:834px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t9_113{left:117px;bottom:801px;letter-spacing:-0.14px;word-spacing:-0.64px;}
#ta_113{left:152px;bottom:781px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tb_113{left:117px;bottom:752px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tc_113{left:117px;bottom:723px;letter-spacing:-0.16px;word-spacing:2.76px;}
#td_113{left:152px;bottom:703px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_113{left:117px;bottom:674px;letter-spacing:-0.17px;word-spacing:4.85px;}
#tf_113{left:152px;bottom:654px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tg_113{left:142px;bottom:587px;}
#th_113{left:152px;bottom:535px;letter-spacing:0.07px;}
#ti_113{left:152px;bottom:516px;letter-spacing:0.11px;}
#tj_113{left:152px;bottom:498px;letter-spacing:0.02px;}
#tk_113{left:284px;bottom:535px;}
#tl_113{left:305px;bottom:535px;letter-spacing:0.11px;word-spacing:0.04px;}
#tm_113{left:305px;bottom:516px;letter-spacing:0.12px;word-spacing:0.02px;}
#tn_113{left:284px;bottom:498px;}
#to_113{left:305px;bottom:498px;letter-spacing:0.09px;word-spacing:0.05px;}
#tp_113{left:152px;bottom:460px;letter-spacing:0.09px;}
#tq_113{left:152px;bottom:442px;letter-spacing:0.07px;word-spacing:0.06px;}
#tr_113{left:152px;bottom:424px;letter-spacing:0.06px;}
#ts_113{left:152px;bottom:405px;letter-spacing:0.02px;}
#tt_113{left:284px;bottom:470px;}
#tu_113{left:305px;bottom:470px;letter-spacing:0.06px;word-spacing:0.08px;}
#tv_113{left:284px;bottom:451px;}
#tw_113{left:305px;bottom:451px;letter-spacing:0.1px;word-spacing:0.04px;}
#tx_113{left:305px;bottom:433px;letter-spacing:0.11px;word-spacing:0.04px;}
#ty_113{left:284px;bottom:415px;}
#tz_113{left:305px;bottom:415px;letter-spacing:0.11px;word-spacing:3.43px;}
#t10_113{left:305px;bottom:396px;letter-spacing:0.07px;word-spacing:0.06px;}
#t11_113{left:152px;bottom:286px;letter-spacing:0.14px;}
#t12_113{left:152px;bottom:267px;letter-spacing:0.07px;word-spacing:0.05px;}
#t13_113{left:152px;bottom:249px;letter-spacing:0.09px;}
#t14_113{left:152px;bottom:231px;letter-spacing:0.04px;word-spacing:0.11px;}
#t15_113{left:277px;bottom:368px;}
#t16_113{left:305px;bottom:368px;letter-spacing:0.07px;word-spacing:0.33px;}
#t17_113{left:305px;bottom:349px;letter-spacing:0.08px;word-spacing:-1.89px;}
#t18_113{left:305px;bottom:331px;letter-spacing:0.15px;}
#t19_113{left:277px;bottom:308px;}
#t1a_113{left:305px;bottom:308px;letter-spacing:0.14px;}
#t1b_113{left:390px;bottom:308px;letter-spacing:0.06px;}
#t1c_113{left:460px;bottom:308px;letter-spacing:0.07px;}
#t1d_113{left:556px;bottom:308px;letter-spacing:0.1px;}
#t1e_113{left:596px;bottom:308px;letter-spacing:0.11px;}
#t1f_113{left:305px;bottom:290px;letter-spacing:0.1px;word-spacing:8.62px;}
#t1g_113{left:305px;bottom:272px;letter-spacing:0.1px;word-spacing:10.94px;}
#t1h_113{left:305px;bottom:253px;letter-spacing:0.07px;word-spacing:-0.89px;}
#t1i_113{left:305px;bottom:235px;letter-spacing:0.09px;word-spacing:0.06px;}
#t1j_113{left:277px;bottom:212px;}
#t1k_113{left:305px;bottom:212px;letter-spacing:0.11px;word-spacing:10.24px;}
#t1l_113{left:305px;bottom:194px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1m_113{left:277px;bottom:171px;}
#t1n_113{left:305px;bottom:171px;letter-spacing:0.11px;word-spacing:-1.69px;}
#t1o_113{left:277px;bottom:149px;}
#t1p_113{left:305px;bottom:149px;letter-spacing:0.13px;word-spacing:6.43px;}
#t1q_113{left:305px;bottom:130px;letter-spacing:0.07px;word-spacing:0.06px;}

.s0_113{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_113{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_113{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_113{font-size:17px;font-family:MogulArial_16s;color:#333;}
.s4_113{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_113{font-size:15px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts113" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg113" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="113/113.svg"
          type="image/svg+xml"
          id="pdf113"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_113" class="t s0_113">
          113{" "}
        </span>
        <span id="t2_113" class="t s1_113">
          Багш сургалтын арга зүйгээ өөрчлөхийг яагаад чухалчилж байна вэ?{" "}
        </span>
        <span id="t3_113" class="t s2_113">
          Мэдээллийн эрин зуунд өөрт хэрэгтэй мэдээллийг хүссэн газраасаа хүссэн{" "}
        </span>
        <span id="t4_113" class="t s2_113">
          цаг хугацаандаа{" "}
        </span>
        <span id="t5_113" class="t s2_113">
          олж авах асуудал чухал болоод байна.{" "}
        </span>
        <span id="t6_113" class="t s2_113">
          Боловсролын ерөнхий хуулийн 8 дугаар зүйлд Боловсрол{" "}
        </span>
        <span id="t7_113" class="t s3_113">
          эзэмших{" "}
        </span>
        <span id="t8_113" class="t s3_113">
          сургалтын хэлбэрийг дараах байдлаар тусгасан.{" "}
        </span>
        <span id="t9_113" class="t s3_113">
          8.1. Сургалт нь танхимын, танхимын бус, эдгээрийг хосолсон хэлбэрийн{" "}
        </span>
        <span id="ta_113" class="t s3_113">
          зохион байгуулалттай байна.{" "}
        </span>
        <span id="tb_113" class="t s2_113">
          8.2. Бага ангийн сургалтыг заавал танхимаар зохион байгуулна.{" "}
        </span>
        <span id="tc_113" class="t s2_113">
          8.3. Танхимын бус сургалтад зайн, дэмжигч болон явуулын багшийн,{" "}
        </span>
        <span id="td_113" class="t s2_113">
          цахим сургалт хамаарна.{" "}
        </span>
        <span id="te_113" class="t s2_113">
          8.4. Тусгай хэрэгцээ шаардлагатай сурагчид ганцаарчилсан сургалт{" "}
        </span>
        <span id="tf_113" class="t s2_113">
          явуулж болно.{" "}
        </span>
        <span id="tg_113" class="t s4_113">
          АСУУДАЛ{" "}
        </span>
        <span id="th_113" class="t s5_113">
          Хичээл{" "}
        </span>
        <span id="ti_113" class="t s5_113">
          төлөвлөлттэй{" "}
        </span>
        <span id="tj_113" class="t s5_113">
          холбоотой{" "}
        </span>
        <span id="tk_113" class="t s5_113">
          -{" "}
        </span>
        <span id="tl_113" class="t s5_113">
          Сургалтын хөтөлбөрөө уншиж, өөрийн болгож{" "}
        </span>
        <span id="tm_113" class="t s5_113">
          хэрэгжүүлэх үйл ажиллагаа дутмаг{" "}
        </span>
        <span id="tn_113" class="t s5_113">
          -{" "}
        </span>
        <span id="to_113" class="t s5_113">
          Багш бүрийн хичээлийн бэлтгэл хангалтгүй{" "}
        </span>
        <span id="tp_113" class="t s5_113">
          Нээлттэй{" "}
        </span>
        <span id="tq_113" class="t s5_113">
          эх сурвалж{" "}
        </span>
        <span id="tr_113" class="t s5_113">
          ашиглахтай{" "}
        </span>
        <span id="ts_113" class="t s5_113">
          холбоотой{" "}
        </span>
        <span id="tt_113" class="t s5_113">
          -{" "}
        </span>
        <span id="tu_113" class="t s5_113">
          Багшийн medle.mn нэвтрэлт, ашиглалт хангалтгүй{" "}
        </span>
        <span id="tv_113" class="t s5_113">
          -{" "}
        </span>
        <span id="tw_113" class="t s5_113">
          Багшийн хариуцлага, интернэтийн орчин, багшийн{" "}
        </span>
        <span id="tx_113" class="t s5_113">
          МХТ-ийг ашиглах чадвар сул{" "}
        </span>
        <span id="ty_113" class="t s5_113">
          -{" "}
        </span>
        <span id="tz_113" class="t s5_113">
          Бэлэн эх сурвалжуудыг (medle.mn дасгал ажлын{" "}
        </span>
        <span id="t10_113" class="t s5_113">
          хуудас, видео хичээл) шууд хэрэглэж байгаа.{" "}
        </span>
        <span id="t11_113" class="t s5_113">
          Багшлах{" "}
        </span>
        <span id="t12_113" class="t s5_113">
          арга зүйг{" "}
        </span>
        <span id="t13_113" class="t s5_113">
          сайжруулах-{" "}
        </span>
        <span id="t14_113" class="t s5_113">
          тай холбоотой{" "}
        </span>
        <span id="t15_113" class="t s5_113">
          -{" "}
        </span>
        <span id="t16_113" class="t s5_113">
          Анги дүүргэлт их багш дутагдалтай байгаа нь багш{" "}
        </span>
        <span id="t17_113" class="t s5_113">
          төвтэй, идэвхтэй бус сургалтын арга давамгайлахад{" "}
        </span>
        <span id="t18_113" class="t s5_113">
          нөлөөлсөн.{" "}
        </span>
        <span id="t19_113" class="t s5_113">
          -{" "}
        </span>
        <span id="t1a_113" class="t s5_113">
          Багшийн{" "}
        </span>
        <span id="t1b_113" class="t s5_113">
          асуулт{" "}
        </span>
        <span id="t1c_113" class="t s5_113">
          даалгавар{" "}
        </span>
        <span id="t1d_113" class="t s5_113">
          нь{" "}
        </span>
        <span id="t1e_113" class="t s5_113">
          сурагчдыг{" "}
        </span>
        <span id="t1f_113" class="t s5_113">
          сэдэлжүүлэх, мэдлэг бүтээхэд чиглэхүйц биш,{" "}
        </span>
        <span id="t1g_113" class="t s5_113">
          багшийн яриа, тайлбар давамгайлсан, бэлэн{" "}
        </span>
        <span id="t1h_113" class="t s5_113">
          мэдээлэл өгсөн, уламжлалт хандлагатай хичээлүүд{" "}
        </span>
        <span id="t1i_113" class="t s5_113">
          давамгайлж байна.{" "}
        </span>
        <span id="t1j_113" class="t s5_113">
          -{" "}
        </span>
        <span id="t1k_113" class="t s5_113">
          Ээлжит хичээлд сурагчдын ялгаатай түвшинг{" "}
        </span>
        <span id="t1l_113" class="t s5_113">
          харгалзаж хөгжүүлдэггүй{" "}
        </span>
        <span id="t1m_113" class="t s5_113">
          -{" "}
        </span>
        <span id="t1n_113" class="t s5_113">
          Бүх сурагчид сурах бичгийн даалгавар ажиллуулдаг{" "}
        </span>
        <span id="t1o_113" class="t s5_113">
          -{" "}
        </span>
        <span id="t1p_113" class="t s5_113">
          Гэрийн даалгаврыг хүүхэд бүрд ижил түвшинд{" "}
        </span>
        <span id="t1q_113" class="t s5_113">
          өгдөг хэдий ч шалгадаггүй{" "}
        </span>
      </div>
    </div>
  );
}
