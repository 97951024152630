import React from "react";
export default function HundredTen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_110{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_110{left:87px;bottom:941px;letter-spacing:-0.14px;word-spacing:8.57px;}
#t3_110{left:87px;bottom:921px;letter-spacing:-0.16px;word-spacing:4.93px;}
#t4_110{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t5_110{left:142px;bottom:843px;letter-spacing:0.18px;}
#t6_110{left:87px;bottom:806px;letter-spacing:-0.14px;word-spacing:6.85px;}
#t7_110{left:388px;bottom:806px;letter-spacing:-0.18px;word-spacing:6.9px;}
#t8_110{left:87px;bottom:785px;letter-spacing:-0.13px;word-spacing:0.31px;}
#t9_110{left:260px;bottom:785px;letter-spacing:-0.19px;word-spacing:0.36px;}
#ta_110{left:87px;bottom:765px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_110{left:87px;bottom:732px;letter-spacing:-0.13px;word-spacing:0.34px;}
#tc_110{left:362px;bottom:732px;letter-spacing:-0.15px;word-spacing:0.37px;}
#td_110{left:87px;bottom:712px;letter-spacing:-0.13px;}
#te_110{left:189px;bottom:712px;letter-spacing:-0.12px;word-spacing:-0.63px;}
#tf_110{left:87px;bottom:692px;letter-spacing:-0.14px;word-spacing:3.39px;}
#tg_110{left:87px;bottom:672px;letter-spacing:-0.16px;word-spacing:0.06px;}
#th_110{left:87px;bottom:638px;}
#ti_110{left:114px;bottom:638px;letter-spacing:-0.13px;word-spacing:-1.16px;}
#tj_110{left:114px;bottom:618px;letter-spacing:-0.22px;word-spacing:10.97px;}
#tk_110{left:114px;bottom:598px;letter-spacing:-0.16px;word-spacing:2.9px;}
#tl_110{left:114px;bottom:578px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tm_110{left:235px;bottom:543px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tn_110{left:87px;bottom:319px;letter-spacing:-0.13px;word-spacing:2.24px;}
#to_110{left:87px;bottom:299px;letter-spacing:-0.18px;word-spacing:5.57px;}
#tp_110{left:87px;bottom:279px;letter-spacing:-0.16px;word-spacing:-0.76px;}
#tq_110{left:87px;bottom:259px;letter-spacing:-0.14px;word-spacing:0.98px;}
#tr_110{left:87px;bottom:239px;letter-spacing:-0.14px;word-spacing:7.67px;}
#ts_110{left:87px;bottom:219px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tt_110{left:87px;bottom:190px;letter-spacing:-0.18px;word-spacing:3.2px;}
#tu_110{left:87px;bottom:170px;letter-spacing:-0.19px;word-spacing:2.69px;}
#tv_110{left:87px;bottom:149px;letter-spacing:-0.15px;word-spacing:4.54px;}
#tw_110{left:87px;bottom:129px;letter-spacing:-0.17px;word-spacing:4.42px;}
#tx_110{left:87px;bottom:109px;letter-spacing:-0.1px;}

.s0_110{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_110{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s2_110{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_110{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s4_110{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_110{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts110" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg110" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="110/110.svg"
          type="image/svg+xml"
          id="pdf110"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_110" class="t s0_110">
          110{" "}
        </span>
        <span id="t2_110" class="t s1_110">
          Зөвлөмжийн 1-р бүлгийн хичээлийн жилийн менежментийн зураглал{" "}
        </span>
        <span id="t3_110" class="t s1_110">
          болон 3.4-р хэсгийн сургуулийн тухайн хичээлийн жилийн сургалт, үйл{" "}
        </span>
        <span id="t4_110" class="t s1_110">
          ажиллагааны нэгдсэн төлөвлөлт сэдвээс хараарай.{" "}
        </span>
        <span id="t5_110" class="t s2_110">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t6_110" class="t s3_110">
          Жилийн төлөвлөлтийн загвар 1.{" "}
        </span>
        <span id="t7_110" class="t s4_110">
          Судлагдахууны зорилго, зорилттой{" "}
        </span>
        <span id="t8_110" class="t s4_110">
          уялдсан төлөвлөгөөг{" "}
        </span>
        <span id="t9_110" class="t s4_110">
          нэг багш хийхэд хүндрэлтэй байдаг. Иймд ЗАН эсвэл{" "}
        </span>
        <span id="ta_110" class="t s4_110">
          мэргэжлийн хөгжлийн бүлгээр хамтарч боловсруулна.{" "}
        </span>
        <span id="tb_110" class="t s3_110">
          Жилийн төлөвлөлтийн загвар 2.{" "}
        </span>
        <span id="tc_110" class="t s4_110">
          Сургуулийн зорилго, зорилттой уялдсан{" "}
        </span>
        <span id="td_110" class="t s4_110">
          төлөвлөгөөг{" "}
        </span>
        <span id="te_110" class="t s4_110">
          нэг багш хийх боломжгүй тиймд тухайн анги нэгдэл, 5-р ангийн{" "}
        </span>
        <span id="tf_110" class="t s4_110">
          бүх багш хэрэв суурь боловсрол бол 7-р ангид хичээл заадаг бүх багш{" "}
        </span>
        <span id="tg_110" class="t s4_110">
          нараараа хамтарч боловсруулна.{" "}
        </span>
        <span id="th_110" class="t s4_110">
          •{" "}
        </span>
        <span id="ti_110" class="t s4_110">
          Сургуулийн зорилго, зорилт болон сургалтын хөтөлбөрийн хэрэгжилтийг{" "}
        </span>
        <span id="tj_110" class="t s4_110">
          хэлэлцэх нэгдсэн “УУЛЗАЛТ, ХЭЛЭЛЦҮҮЛЭГ”-ийг улиралд нэгээс{" "}
        </span>
        <span id="tk_110" class="t s4_110">
          доошгүй удаа зохион байгуулахыг зөвлөж байна. Нэгдсэн уулзалтаас{" "}
        </span>
        <span id="tl_110" class="t s4_110">
          өмнө ЗАН, МХБ, анги нэгдлээрээ уулзсан байх ёстой.{" "}
        </span>
        <span id="tm_110" class="t s5_110">
          Зураг 3.14. Хэлэлцүүлгийн зохион байгуулалт{" "}
        </span>
        <span id="tn_110" class="t s4_110">
          Үүний дараа бага, дунд, ахлах ангийн түвшинд хэлэлцсэн үр дүнг (давуу{" "}
        </span>
        <span id="to_110" class="t s4_110">
          тал, тулгамдсан асуудал түүний шалтгаан, шийдэл) нэгдсэн УУЛЗАЛТ,{" "}
        </span>
        <span id="tp_110" class="t s4_110">
          ХЭЛЭЛЦҮҮЛЭГТ танилцуулж, цаашид хичээлийн жил дуусахаас өмнө эсвэл{" "}
        </span>
        <span id="tq_110" class="t s4_110">
          дараагийн улиралд юунд анхаарах, бидний хэрэглэж буй арга болж байна{" "}
        </span>
        <span id="tr_110" class="t s4_110">
          уу үгүй юу гэдгийг нарийвчлан ярилцаж бүх хүний оролцоо саналд{" "}
        </span>
        <span id="ts_110" class="t s4_110">
          үндэслэсэн шийдвэр гаргана.{" "}
        </span>
        <span id="tt_110" class="t s4_110">
          Сургуулийн удирдлага, захирал нь уулзалт, хэлэлцүүлгээс гарсан санал,{" "}
        </span>
        <span id="tu_110" class="t s4_110">
          шийдвэрт үндэслэн шаардлагатай дэмжлэг (оновчтой зохион байгуулалт,{" "}
        </span>
        <span id="tv_110" class="t s4_110">
          мэргэжлийн удирдлага, манлайлал, хичээл сургалтын орчин бүрдүүлэх,{" "}
        </span>
        <span id="tw_110" class="t s4_110">
          багшийн хөгжил, эцэг, эх олон нийтийн оролцоог хангах)-ийг тухай бүр{" "}
        </span>
        <span id="tx_110" class="t s4_110">
          үзүүлж ажиллана.{" "}
        </span>
      </div>
    </div>
  );
}
