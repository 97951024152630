import React from "react";
export default function F136() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_136{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_136{left:103px;bottom:923px;letter-spacing:0.1px;word-spacing:0.05px;}
#t3_136{left:103px;bottom:887px;}
#t4_136{left:129px;bottom:887px;letter-spacing:0.09px;word-spacing:0.56px;}
#t5_136{left:129px;bottom:868px;letter-spacing:0.11px;}
#t6_136{left:103px;bottom:846px;}
#t7_136{left:129px;bottom:846px;letter-spacing:0.11px;word-spacing:3.15px;}
#t8_136{left:129px;bottom:827px;letter-spacing:0.1px;word-spacing:0.04px;}
#t9_136{left:103px;bottom:805px;}
#ta_136{left:129px;bottom:805px;letter-spacing:0.11px;word-spacing:1.92px;}
#tb_136{left:129px;bottom:786px;letter-spacing:0.11px;word-spacing:0.03px;}
#tc_136{left:103px;bottom:764px;}
#td_136{left:129px;bottom:764px;letter-spacing:0.08px;word-spacing:0.05px;}
#te_136{left:103px;bottom:741px;}
#tf_136{left:129px;bottom:741px;letter-spacing:0.12px;word-spacing:6.39px;}
#tg_136{left:129px;bottom:723px;letter-spacing:0.11px;word-spacing:0.02px;}
#th_136{left:103px;bottom:700px;}
#ti_136{left:129px;bottom:700px;letter-spacing:0.11px;word-spacing:6.23px;}
#tj_136{left:129px;bottom:682px;letter-spacing:0.06px;word-spacing:0.08px;}
#tk_136{left:103px;bottom:659px;}
#tl_136{left:129px;bottom:659px;letter-spacing:0.09px;word-spacing:1.98px;}
#tm_136{left:129px;bottom:641px;letter-spacing:0.11px;word-spacing:0.03px;}
#tn_136{left:103px;bottom:618px;}
#to_136{left:129px;bottom:618px;letter-spacing:0.1px;word-spacing:1.27px;}
#tp_136{left:129px;bottom:600px;letter-spacing:0.08px;word-spacing:0.05px;}
#tq_136{left:103px;bottom:577px;}
#tr_136{left:129px;bottom:577px;letter-spacing:0.11px;word-spacing:2.43px;}
#ts_136{left:129px;bottom:559px;letter-spacing:0.14px;}
#tt_136{left:103px;bottom:536px;}
#tu_136{left:129px;bottom:536px;letter-spacing:0.14px;word-spacing:4.88px;}
#tv_136{left:129px;bottom:518px;letter-spacing:0.1px;word-spacing:0.04px;}
#tw_136{left:103px;bottom:437px;letter-spacing:0.13px;}
#tx_136{left:187px;bottom:437px;letter-spacing:0.1px;word-spacing:0.04px;}
#ty_136{left:103px;bottom:416px;letter-spacing:0.09px;}
#tz_136{left:166px;bottom:416px;letter-spacing:0.04px;word-spacing:0.11px;}
#t10_136{left:103px;bottom:361px;}
#t11_136{left:129px;bottom:361px;letter-spacing:0.12px;word-spacing:6.56px;}
#t12_136{left:129px;bottom:343px;letter-spacing:0.12px;word-spacing:0.01px;}
#t13_136{left:103px;bottom:320px;}
#t14_136{left:129px;bottom:320px;letter-spacing:0.1px;word-spacing:5.35px;}
#t15_136{left:129px;bottom:302px;letter-spacing:0.16px;word-spacing:-0.02px;}
#t16_136{left:103px;bottom:279px;}
#t17_136{left:129px;bottom:279px;letter-spacing:0.12px;word-spacing:4.28px;}
#t18_136{left:129px;bottom:261px;letter-spacing:0.06px;word-spacing:4.08px;}
#t19_136{left:129px;bottom:243px;letter-spacing:0.11px;word-spacing:6.89px;}
#t1a_136{left:129px;bottom:224px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1b_136{left:103px;bottom:202px;}
#t1c_136{left:129px;bottom:202px;letter-spacing:0.09px;word-spacing:2.78px;}
#t1d_136{left:129px;bottom:183px;letter-spacing:0.11px;word-spacing:6.13px;}
#t1e_136{left:129px;bottom:165px;letter-spacing:0.11px;word-spacing:1.01px;}
#t1f_136{left:129px;bottom:147px;letter-spacing:0.14px;}

.s0_136{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_136{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s2_136{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_136{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_136{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts136" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg136" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="136/136.svg"
          type="image/svg+xml"
          id="pdf136"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_136" class="t s0_136">
          136{" "}
        </span>
        <span id="t2_136" class="t s1_136">
          Шигтгээ 1: Багш хөтөлбөрөө үнэлэх товч асуулт{" "}
        </span>
        <span id="t3_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t4_136" class="t s3_136">
          Та хичээлийн төлөвлөлтөө өөрөө боловсруулж, хичээл бүртээ ашигладаг{" "}
        </span>
        <span id="t5_136" class="t s3_136">
          уу?{" "}
        </span>
        <span id="t6_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t7_136" class="t s3_136">
          Нэгж хичээл төлөвлөхдөө хамгийн түрүүнд зорилго буюу сурагчид юуг{" "}
        </span>
        <span id="t8_136" class="t s3_136">
          мэддэг чаддаг болсон байхыг тодорхойлдог уу?{" "}
        </span>
        <span id="t9_136" class="t s2_136">
          -{" "}
        </span>
        <span id="ta_136" class="t s3_136">
          Зорилгодоо хүрсэн эсэхийг илрүүлэх үнэлгээний гүйцэтгэлийн 4 түвшин{" "}
        </span>
        <span id="tb_136" class="t s3_136">
          бүхий даалгаврыг боловсруулан, түүнд чиглэн ажилладаг уу?{" "}
        </span>
        <span id="tc_136" class="t s2_136">
          -{" "}
        </span>
        <span id="td_136" class="t s3_136">
          Зорилгод хүргэх зорилтуудыг ээлжит хичээл болгон төлөвлөдөг үү?{" "}
        </span>
        <span id="te_136" class="t s2_136">
          -{" "}
        </span>
        <span id="tf_136" class="t s3_136">
          Ихэнх ээлжит хичээлээр хэрэгжүүлэх үйл ажиллагааг сурагч төвтэй{" "}
        </span>
        <span id="tg_136" class="t s3_136">
          төлөвлөж чаддаг уу?{" "}
        </span>
        <span id="th_136" class="t s2_136">
          -{" "}
        </span>
        <span id="ti_136" class="t s3_136">
          Ээлжит хичээл бүрээр зорилгод чиглэсэн танин мэдэхүйн түвшнээр{" "}
        </span>
        <span id="tj_136" class="t s3_136">
          бэлтгэсэн асуулт, асуудал ба туршилт төлөвлөдөг үү?{" "}
        </span>
        <span id="tk_136" class="t s2_136">
          -{" "}
        </span>
        <span id="tl_136" class="t s3_136">
          Ээлжит хичээл бүрээр ажлын хуудас, хэрэглэгдэхүүн, бусад эх сурвалж{" "}
        </span>
        <span id="tm_136" class="t s3_136">
          ашигладаг уу?{" "}
        </span>
        <span id="tn_136" class="t s2_136">
          -{" "}
        </span>
        <span id="to_136" class="t s3_136">
          Ээлжит хичээл бүрээр сурагчдад олгохыг зорьсон хамгийн чухал мэдлэг{" "}
        </span>
        <span id="tp_136" class="t s3_136">
          ойлголт, санаа, ур чадварыг үлдээж чадсан эсэхээ үнэлдэг үү?{" "}
        </span>
        <span id="tq_136" class="t s2_136">
          -{" "}
        </span>
        <span id="tr_136" class="t s3_136">
          Нэгж хичээлийн төгсгөлд үнэлгээний даалгаварт дүн шинжилгээ хийдэг{" "}
        </span>
        <span id="ts_136" class="t s3_136">
          үү?{" "}
        </span>
        <span id="tt_136" class="t s2_136">
          -{" "}
        </span>
        <span id="tu_136" class="t s3_136">
          Үнэлгээний дүн шинжилгээнд үндэслэн, эргэцүүлэл хийн, хөтөлбөрөө{" "}
        </span>
        <span id="tv_136" class="t s3_136">
          сайжруулдаг уу?{" "}
        </span>
        <span id="tw_136" class="t s1_136">
          Шигтгээ 2:{" "}
        </span>
        <span id="tx_136" class="t s4_136">
          Хичээл хооронд интеграцчилах, цаг, гэрийн даалгаврыг{" "}
        </span>
        <span id="ty_136" class="t s4_136">
          нэгтгэх{" "}
        </span>
        <span id="tz_136" class="t s4_136">
          товч асуулт{" "}
        </span>
        <span id="t10_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t11_136" class="t s3_136">
          Суралцахуйн зорилтууд хоорондын уялдааг хангаж интеграцичилсан{" "}
        </span>
        <span id="t12_136" class="t s3_136">
          төлөвлөлт хийдэг үү?{" "}
        </span>
        <span id="t13_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t14_136" class="t s3_136">
          Судлагдахуун хоорондын уялдааг хангаж интеграцичилсан төлөвлөлт{" "}
        </span>
        <span id="t15_136" class="t s3_136">
          хийдэг үү?{" "}
        </span>
        <span id="t16_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t17_136" class="t s3_136">
          Хичээлээс гадуурх үйл ажиллагаа буюу орон нутгийн музей, үйлдвэр{" "}
        </span>
        <span id="t18_136" class="t s3_136">
          байгууллага, байгальд ажиглалт, туршилт судалгаа хийх төсөлт ажил,{" "}
        </span>
        <span id="t19_136" class="t s3_136">
          асуудалд суурилсан, үзэгдэлд суурилсан сурагч төвтэй бие даасан{" "}
        </span>
        <span id="t1a_136" class="t s3_136">
          сургалтыг дэмжих төлөвлөлт хийдэг үү?{" "}
        </span>
        <span id="t1b_136" class="t s2_136">
          -{" "}
        </span>
        <span id="t1c_136" class="t s3_136">
          Сурагчдын ачааллыг бууруулах, оролцоог хангах, сонирхлыг татах, цаг{" "}
        </span>
        <span id="t1d_136" class="t s3_136">
          хэмнэх, гэрийн даалгаврыг бодит ахуйтай холбон нэгтгэх зорилгоор{" "}
        </span>
        <span id="t1e_136" class="t s3_136">
          судлагдахуунаар нэгтгэсэн бие даах ажил, гэрийн даалгавар төлөвлөдөг{" "}
        </span>
        <span id="t1f_136" class="t s3_136">
          үү?{" "}
        </span>
      </div>
    </div>
  );
}
