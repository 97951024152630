import React from "react";
export default function Seventeen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_17{left:676px;bottom:58px;letter-spacing:-0.08px;}
#t2_17{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_17{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:-1.02px;}
#t4_17{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:2.67px;}
#t5_17{left:87px;bottom:901px;letter-spacing:-0.18px;word-spacing:-1.69px;}
#t6_17{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.62px;}
#t7_17{left:87px;bottom:860px;letter-spacing:-0.18px;word-spacing:-0.78px;}
#t8_17{left:87px;bottom:840px;letter-spacing:-0.16px;word-spacing:0.35px;}
#t9_17{left:87px;bottom:820px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ta_17{left:492px;bottom:829px;}
#tb_17{left:502px;bottom:820px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tc_17{left:87px;bottom:787px;letter-spacing:0.02px;word-spacing:11.81px;}
#td_17{left:87px;bottom:767px;letter-spacing:0.01px;word-spacing:8.31px;}
#te_17{left:87px;bottom:746px;letter-spacing:0.06px;word-spacing:4.4px;}
#tf_17{left:241px;bottom:746px;letter-spacing:-0.17px;word-spacing:4.47px;}
#tg_17{left:87px;bottom:726px;letter-spacing:-0.16px;word-spacing:7.98px;}
#th_17{left:87px;bottom:706px;letter-spacing:-0.14px;word-spacing:1.78px;}
#ti_17{left:87px;bottom:686px;letter-spacing:-0.16px;word-spacing:3.52px;}
#tj_17{left:87px;bottom:666px;letter-spacing:-0.19px;word-spacing:0.59px;}
#tk_17{left:87px;bottom:646px;letter-spacing:-0.13px;word-spacing:0.5px;}
#tl_17{left:87px;bottom:625px;letter-spacing:-0.13px;word-spacing:-0.48px;}
#tm_17{left:87px;bottom:592px;letter-spacing:-0.13px;}
#tn_17{left:194px;bottom:592px;letter-spacing:-0.1px;}
#to_17{left:289px;bottom:592px;}
#tp_17{left:328px;bottom:592px;letter-spacing:-0.1px;}
#tq_17{left:87px;bottom:572px;letter-spacing:-0.25px;}
#tr_17{left:198px;bottom:572px;letter-spacing:-0.24px;}
#ts_17{left:259px;bottom:572px;letter-spacing:-0.09px;}
#tt_17{left:331px;bottom:572px;letter-spacing:-0.1px;}
#tu_17{left:87px;bottom:552px;letter-spacing:-0.13px;word-spacing:8.47px;}
#tv_17{left:87px;bottom:532px;letter-spacing:-0.17px;word-spacing:8.96px;}
#tw_17{left:87px;bottom:512px;letter-spacing:-0.11px;word-spacing:7.05px;}
#tx_17{left:87px;bottom:491px;letter-spacing:-0.14px;word-spacing:9.46px;}
#ty_17{left:87px;bottom:471px;letter-spacing:-0.09px;}
#tz_17{left:160px;bottom:471px;letter-spacing:-0.18px;}
#t10_17{left:235px;bottom:471px;letter-spacing:-0.08px;}
#t11_17{left:292px;bottom:471px;letter-spacing:-0.18px;}
#t12_17{left:365px;bottom:471px;letter-spacing:-0.03px;}
#t13_17{left:87px;bottom:451px;letter-spacing:-0.18px;}
#t14_17{left:208px;bottom:451px;letter-spacing:-0.2px;}
#t15_17{left:282px;bottom:451px;letter-spacing:-0.09px;}
#t16_17{left:345px;bottom:451px;letter-spacing:-0.09px;}
#t17_17{left:87px;bottom:431px;letter-spacing:-0.11px;word-spacing:5.68px;}
#t18_17{left:87px;bottom:411px;letter-spacing:-0.2px;word-spacing:10.16px;}
#t19_17{left:87px;bottom:391px;letter-spacing:-0.14px;}
#t1a_17{left:241px;bottom:391px;letter-spacing:-0.15px;}
#t1b_17{left:355px;bottom:391px;letter-spacing:-0.19px;}
#t1c_17{left:87px;bottom:370px;letter-spacing:-0.14px;word-spacing:1.82px;}
#t1d_17{left:87px;bottom:350px;letter-spacing:-0.18px;word-spacing:0.73px;}
#t1e_17{left:87px;bottom:330px;letter-spacing:-0.15px;word-spacing:2.75px;}
#t1f_17{left:87px;bottom:310px;letter-spacing:-0.08px;}
#t1g_17{left:87px;bottom:277px;letter-spacing:-0.16px;word-spacing:8.62px;}
#t1h_17{left:87px;bottom:257px;letter-spacing:-0.11px;word-spacing:2.14px;}
#t1i_17{left:87px;bottom:236px;letter-spacing:-0.17px;word-spacing:11.99px;}
#t1j_17{left:87px;bottom:216px;letter-spacing:-0.14px;word-spacing:5.9px;}
#t1k_17{left:87px;bottom:196px;letter-spacing:-0.14px;word-spacing:4.76px;}
#t1l_17{left:87px;bottom:176px;letter-spacing:-0.23px;word-spacing:0.13px;}
#t1m_17{left:87px;bottom:121px;}
#t1n_17{left:108px;bottom:121px;letter-spacing:0.08px;word-spacing:0.03px;}
#t1o_17{left:443px;bottom:410px;letter-spacing:-0.12px;}
#t1p_17{left:447px;bottom:394px;letter-spacing:-0.15px;word-spacing:0.02px;}

.s0_17{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_17{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_17{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_17{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s4_17{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s5_17{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s6_17{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_17{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts17" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg17" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="17/17.svg"
          type="image/svg+xml"
          id="pdf17"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_17" class="t s1_17">
          17{" "}
        </span>
        <span id="t3_17" class="t s2_17">
          Сургалтын хөтөлбөрийг шинэчлэх үзэл баримтлал боловсруулах үндэслэлд{" "}
        </span>
        <span id="t4_17" class="t s2_17">
          ирээдүйн суралцагч, иргэний дүр төрхийг “Чин шударга зан төлөвтэй, эх{" "}
        </span>
        <span id="t5_17" class="t s2_17">
          орноороо бахархаж эх хэл, ёс уламжлалаа өвлөн эзэмшдэг, сурах арга
          барил{" "}
        </span>
        <span id="t6_17" class="t s2_17">
          эзэмшин өөрийн авьяас чадвараа хөгжүүлдэг, бусадтай хамтран ажиллаж,{" "}
        </span>
        <span id="t7_17" class="t s2_17">
          төвөгтэй асуудлыг шийдвэрлэж чаддаг, эх байгальдаа болон бусдад
          ээлтэй,{" "}
        </span>
        <span id="t8_17" class="t s2_17">
          амьдрах эерэг хандлагатай, мэдлэг, мэдээллийн нийгэмд амь биеэ тэжээх,{" "}
        </span>
        <span id="t9_17" class="t s2_17">
          амьдрах бэлтгэлтэй байх нь шинэ зууны суралцагч”{" "}
        </span>
        <span id="ta_17" class="t s3_17">
          3{" "}
        </span>
        <span id="tb_17" class="t s2_17">
          гэж тодорхойлжээ.{" "}
        </span>
        <span id="tc_17" class="t s4_17">
          Сурагчид өөрийн суралцахуйн түүхээ бүтээж, судлагдахууны{" "}
        </span>
        <span id="td_17" class="t s4_17">
          агуулгын мэдлэгээс гадна нийгэм, сэтгэл хөдлөлийн чадвараа{" "}
        </span>
        <span id="te_17" class="t s4_17">
          хөгжүүлж байна.{" "}
        </span>
        <span id="tf_17" class="t s2_17">
          Сурагчдад судлагдахууны мэдлэгээс гадна асуудлыг{" "}
        </span>
        <span id="tg_17" class="t s2_17">
          хамтран шийдвэрлэх, сэтгэл хөдлөлөө илэрхийлэх, удирдах чадварт{" "}
        </span>
        <span id="th_17" class="t s2_17">
          суралцах нь танин мэдэхүйтэй адил чухал болж байна. Энэхүү чадварыг{" "}
        </span>
        <span id="ti_17" class="t s2_17">
          хөгжүүлэхэд гэр бүл, олон нийт, үе тэнгийнхэн болон сургууль хамтран{" "}
        </span>
        <span id="tj_17" class="t s2_17">
          үүрэг хүлээх болж байна. Сурагчид сургуулиас гадуурх урлаг, спортын
          үйл{" "}
        </span>
        <span id="tk_17" class="t s2_17">
          ажиллагаанд оролцох, олон нийтийн болон сайн дурын ажил хийх зэргээр{" "}
        </span>
        <span id="tl_17" class="t s2_17">
          олон талын харилцаанд орж, олон янзын нөхцөл байдалд суралцаж байна.{" "}
        </span>
        <span id="tm_17" class="t s4_17">
          Сурагчид{" "}
        </span>
        <span id="tn_17" class="t s4_17">
          хаанаас{" "}
        </span>
        <span id="to_17" class="t s4_17">
          ч{" "}
        </span>
        <span id="tp_17" class="t s4_17">
          суралцах{" "}
        </span>
        <span id="tq_17" class="t s4_17">
          боломжтой{" "}
        </span>
        <span id="tr_17" class="t s4_17">
          болж{" "}
        </span>
        <span id="ts_17" class="t s4_17">
          байна.{" "}
        </span>
        <span id="tt_17" class="t s2_17">
          Өнөөгийн{" "}
        </span>
        <span id="tu_17" class="t s2_17">
          сургуулийн анги танхим, суралцахуйн{" "}
        </span>
        <span id="tv_17" class="t s2_17">
          орчин өөрчлөгдөж, хамтаар суралцах{" "}
        </span>
        <span id="tw_17" class="t s2_17">
          шинэ орон зай, интерактив, дижитал,{" "}
        </span>
        <span id="tx_17" class="t s2_17">
          хөдөлгөөнт төхөөрөмж хэрэглэгдэхүүн{" "}
        </span>
        <span id="ty_17" class="t s2_17">
          бүхий{" "}
        </span>
        <span id="tz_17" class="t s2_17">
          орчин{" "}
        </span>
        <span id="t10_17" class="t s2_17">
          бий{" "}
        </span>
        <span id="t11_17" class="t s2_17">
          болж,{" "}
        </span>
        <span id="t12_17" class="t s2_17">
          кэйст{" "}
        </span>
        <span id="t13_17" class="t s2_17">
          тулгуурласан{" "}
        </span>
        <span id="t14_17" class="t s2_17">
          агуулга{" "}
        </span>
        <span id="t15_17" class="t s2_17">
          бүхий{" "}
        </span>
        <span id="t16_17" class="t s2_17">
          онлайн,{" "}
        </span>
        <span id="t17_17" class="t s2_17">
          цахим, зайн, холимог (hybrid, blended){" "}
        </span>
        <span id="t18_17" class="t s2_17">
          хэлбэрт шилжиж байна. Технологийн{" "}
        </span>
        <span id="t19_17" class="t s2_17">
          тусламжтайгаар{" "}
        </span>
        <span id="t1a_17" class="t s2_17">
          сургуулиас{" "}
        </span>
        <span id="t1b_17" class="t s2_17">
          гадуур{" "}
        </span>
        <span id="t1c_17" class="t s2_17">
          суралцах шинэ, алтернатив сувгууд бий{" "}
        </span>
        <span id="t1d_17" class="t s2_17">
          болж байна. Үүнтэй холбоотойгоор сурагчдад технологийг, суралцах шинэ,{" "}
        </span>
        <span id="t1e_17" class="t s2_17">
          алтернатив сувгууд ашиглах ур чадвар дижитал бичиг үсгийн боловсрол{" "}
        </span>
        <span id="t1f_17" class="t s2_17">
          хэрэгтэй.{" "}
        </span>
        <span id="t1g_17" class="t s2_17">
          Сурагчид технологийн боломжийг ашиглан танхимын бус хэлбэрээр,{" "}
        </span>
        <span id="t1h_17" class="t s2_17">
          зайнаас суралцах боломжтой болсон нь зөвхөн нийгмийн эрүүл мэндийн{" "}
        </span>
        <span id="t1i_17" class="t s2_17">
          шалтгаанаар төдийгүй зуд зэрэг байгалийн гамшгийн үед хичээл{" "}
        </span>
        <span id="t1j_17" class="t s2_17">
          сургуульдаа хамрагдах боломжгүй болсон сурагчдад дэмжлэг үзүүлэх,{" "}
        </span>
        <span id="t1k_17" class="t s2_17">
          тэднийг суралцахуйн хоцрогдолд орохоос сэргийлэх боломжийг бидэнд{" "}
        </span>
        <span id="t1l_17" class="t s2_17">
          олгож байна.{" "}
        </span>
        <span id="t1m_17" class="t s5_17">
          3{" "}
        </span>
        <span id="t1n_17" class="t s5_17">
          Мөнхжаргал Д., Сургалтын хөтөлбөрийг шинэчлэх үзэл баримтлал
          боловсруулах үндэслэл, 2021{" "}
        </span>
        <span id="t1o_17" class="t s6_17">
          Зураг 1.2. Ковидын үеэр гэрээсээ{" "}
        </span>
        <span id="t1p_17" class="t s6_17">
          онлайнаар суралцаж буй сурагч{" "}
        </span>
      </div>
    </div>
  );
}
