import React from "react";
export default function Hundred() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_100{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_100{left:139px;bottom:941px;}
#t3_100{left:165px;bottom:941px;letter-spacing:-0.17px;word-spacing:8.39px;}
#t4_100{left:165px;bottom:921px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t5_100{left:108px;bottom:901px;}
#t6_100{left:139px;bottom:901px;letter-spacing:-0.16px;word-spacing:-0.21px;}
#t7_100{left:139px;bottom:880px;letter-spacing:-0.18px;}
#t8_100{left:87px;bottom:814px;letter-spacing:0.15px;word-spacing:2px;}
#t9_100{left:87px;bottom:788px;letter-spacing:0.16px;word-spacing:0.03px;}
#ta_100{left:87px;bottom:750px;letter-spacing:-0.16px;word-spacing:4.46px;}
#tb_100{left:87px;bottom:730px;letter-spacing:-0.16px;word-spacing:3.6px;}
#tc_100{left:87px;bottom:710px;letter-spacing:-0.17px;word-spacing:0.08px;}
#td_100{left:132px;bottom:663px;letter-spacing:-0.01px;}
#te_100{left:139px;bottom:634px;}
#tf_100{left:165px;bottom:634px;letter-spacing:-0.16px;word-spacing:-0.05px;}
#tg_100{left:165px;bottom:614px;letter-spacing:-0.15px;word-spacing:0.05px;}
#th_100{left:139px;bottom:590px;}
#ti_100{left:165px;bottom:590px;letter-spacing:-0.17px;word-spacing:4.05px;}
#tj_100{left:165px;bottom:570px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_100{left:139px;bottom:545px;}
#tl_100{left:165px;bottom:545px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tm_100{left:139px;bottom:521px;}
#tn_100{left:165px;bottom:521px;letter-spacing:-0.23px;word-spacing:0.13px;}
#to_100{left:142px;bottom:463px;letter-spacing:0.18px;}
#tp_100{left:87px;bottom:435px;letter-spacing:-0.15px;word-spacing:-1.15px;}
#tq_100{left:87px;bottom:414px;letter-spacing:-0.11px;word-spacing:1.68px;}
#tr_100{left:87px;bottom:394px;letter-spacing:-0.22px;word-spacing:0.12px;}
#ts_100{left:92px;bottom:347px;letter-spacing:-0.01px;word-spacing:0.16px;}
#tt_100{left:109px;bottom:329px;letter-spacing:0.09px;word-spacing:0.1px;}
#tu_100{left:109px;bottom:309px;}
#tv_100{left:123px;bottom:309px;letter-spacing:0.11px;word-spacing:0.03px;}
#tw_100{left:109px;bottom:288px;}
#tx_100{left:123px;bottom:288px;letter-spacing:0.1px;word-spacing:0.04px;}
#ty_100{left:109px;bottom:268px;}
#tz_100{left:123px;bottom:268px;letter-spacing:0.08px;word-spacing:0.07px;}
#t10_100{left:109px;bottom:247px;}
#t11_100{left:123px;bottom:247px;letter-spacing:0.09px;word-spacing:0.06px;}
#t12_100{left:109px;bottom:227px;}
#t13_100{left:123px;bottom:227px;letter-spacing:0.07px;word-spacing:0.07px;}
#t14_100{left:109px;bottom:206px;}
#t15_100{left:123px;bottom:206px;letter-spacing:0.07px;}
#t16_100{left:109px;bottom:186px;}
#t17_100{left:123px;bottom:186px;letter-spacing:0.12px;word-spacing:0.02px;}
#t18_100{left:386px;bottom:360px;letter-spacing:0.05px;word-spacing:0.12px;}
#t19_100{left:403px;bottom:342px;letter-spacing:0.1px;}
#t1a_100{left:403px;bottom:319px;}
#t1b_100{left:421px;bottom:319px;letter-spacing:0.05px;word-spacing:0.09px;}
#t1c_100{left:403px;bottom:301px;}
#t1d_100{left:421px;bottom:301px;letter-spacing:0.17px;}
#t1e_100{left:403px;bottom:283px;}
#t1f_100{left:421px;bottom:283px;letter-spacing:0.09px;word-spacing:0.06px;}
#t1g_100{left:403px;bottom:264px;}
#t1h_100{left:421px;bottom:264px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1i_100{left:403px;bottom:246px;}
#t1j_100{left:421px;bottom:246px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1k_100{left:403px;bottom:228px;}
#t1l_100{left:421px;bottom:228px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1m_100{left:425px;bottom:209px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1n_100{left:403px;bottom:191px;}
#t1o_100{left:421px;bottom:191px;letter-spacing:0.11px;}

.s0_100{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_100{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_100{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s3_100{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_100{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s5_100{font-size:15px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts100" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg100" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="100/100.svg"
          type="image/svg+xml"
          id="pdf100"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_100" class="t s0_100">
          100{" "}
        </span>
        <span id="t2_100" class="t s1_100">
          -{" "}
        </span>
        <span id="t3_100" class="t s1_100">
          Багш хэрэгцээг нь ойлгож, хариуцлагатайгаар авч хэрэглэх{" "}
        </span>
        <span id="t4_100" class="t s1_100">
          боломж олгох{" "}
        </span>
        <span id="t5_100" class="t s2_100">
          •{" "}
        </span>
        <span id="t6_100" class="t s1_100">
          Улирал болон сараар ялган харуулах нь багшид баримжаа өгөх сайн{" "}
        </span>
        <span id="t7_100" class="t s1_100">
          талтай{" "}
        </span>
        <span id="t8_100" class="t s3_100">
          Сургалтын хөтөлбөрийн хэрэгжилтэд үзүүлэх дэмжлэг хамтын{" "}
        </span>
        <span id="t9_100" class="t s3_100">
          ажиллагаа, түншлэл{" "}
        </span>
        <span id="ta_100" class="t s1_100">
          Орчин үеийн сургуулиуд үр дүнтэй сургалтыг зохион байгуулахын тулд{" "}
        </span>
        <span id="tb_100" class="t s1_100">
          хамтын болон ил тод байдлаар, багаар болон мэргэжлийн байгууллага,{" "}
        </span>
        <span id="tc_100" class="t s1_100">
          нийгэмлэгүүдтэй хамтрах нь чухал байдаг.{" "}
        </span>
        <span id="td_100" class="t s3_100">
          АСУУДАЛ{" "}
        </span>
        <span id="te_100" class="t s1_100">
          -{" "}
        </span>
        <span id="tf_100" class="t s1_100">
          Багш ажилтны хамтын ажиллагаа дутмаг, үйл ажиллагааны эргэх{" "}
        </span>
        <span id="tg_100" class="t s1_100">
          холбоо уялдаа муутай байсан{" "}
        </span>
        <span id="th_100" class="t s1_100">
          -{" "}
        </span>
        <span id="ti_100" class="t s1_100">
          Судалгаат хичээл зохион байгуулдаггүйгээс багш нар хамтран{" "}
        </span>
        <span id="tj_100" class="t s1_100">
          ажиллах, харилцан суралцах чадвар сул{" "}
        </span>
        <span id="tk_100" class="t s1_100">
          -{" "}
        </span>
        <span id="tl_100" class="t s1_100">
          Багийн хамтын ажиллагаа сул{" "}
        </span>
        <span id="tm_100" class="t s1_100">
          -{" "}
        </span>
        <span id="tn_100" class="t s1_100">
          Удирдлагын баг шинэ{" "}
        </span>
        <span id="to_100" class="t s4_100">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tp_100" class="t s1_100">
          Сургалтын хөтөлбөр боловсруулах, хэрэгжүүлэхэд оролцогч талуудыг (орон{" "}
        </span>
        <span id="tq_100" class="t s1_100">
          нутгийн засаг захиргаа, иргэний нийгэм, эцэг эх, орон нутгийн хамт
          олон){" "}
        </span>
        <span id="tr_100" class="t s1_100">
          өргөнөөр оролцуулах шаардлагатай байдаг.{" "}
        </span>
        <span id="ts_100" class="t s5_100">
          1. ОРОН НУТГИЙН ЗАСАГ,{" "}
        </span>
        <span id="tt_100" class="t s5_100">
          ЗАХИРГААНЫ ТҮНШЛЭЛ{" "}
        </span>
        <span id="tu_100" class="t s5_100">
          •{" "}
        </span>
        <span id="tv_100" class="t s5_100">
          Засаг даргын тамгын газар{" "}
        </span>
        <span id="tw_100" class="t s5_100">
          •{" "}
        </span>
        <span id="tx_100" class="t s5_100">
          Иргэдийн хурал{" "}
        </span>
        <span id="ty_100" class="t s5_100">
          •{" "}
        </span>
        <span id="tz_100" class="t s5_100">
          Төрийн байгууллагууд{" "}
        </span>
        <span id="t10_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t11_100" class="t s5_100">
          Хувийн хэвшлийн байгууллагууд{" "}
        </span>
        <span id="t12_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t13_100" class="t s5_100">
          Хандивлагч байгууллагууд{" "}
        </span>
        <span id="t14_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t15_100" class="t s5_100">
          Сургууль{" "}
        </span>
        <span id="t16_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t17_100" class="t s5_100">
          Орон нутгийн иргэд{" "}
        </span>
        <span id="t18_100" class="t s5_100">
          2. СУРГУУЛИЙН ДОТООД{" "}
        </span>
        <span id="t19_100" class="t s5_100">
          ТҮНШЛЭЛ{" "}
        </span>
        <span id="t1a_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1b_100" class="t s5_100">
          Сургуулийн удирдлага{" "}
        </span>
        <span id="t1c_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1d_100" class="t s5_100">
          Багш{" "}
        </span>
        <span id="t1e_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1f_100" class="t s5_100">
          Сургуулийн бусад ажилтан{" "}
        </span>
        <span id="t1g_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1h_100" class="t s5_100">
          Хандивлагч байгууллагууд{" "}
        </span>
        <span id="t1i_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1j_100" class="t s5_100">
          Орон нутаг/олон нийт{" "}
        </span>
        <span id="t1k_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1l_100" class="t s5_100">
          Суралцагчийн эцэг эх, асран{" "}
        </span>
        <span id="t1m_100" class="t s5_100">
          хамгаалагч, харгалзан дэмжигч{" "}
        </span>
        <span id="t1n_100" class="t s5_100">
          •{" "}
        </span>
        <span id="t1o_100" class="t s5_100">
          Сурагч{" "}
        </span>
      </div>
    </div>
  );
}
