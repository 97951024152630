import React from "react";
export default function F140() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_140{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_140{left:87px;bottom:941px;letter-spacing:-0.18px;word-spacing:0.15px;}
#t3_140{left:87px;bottom:921px;letter-spacing:-0.16px;word-spacing:2.33px;}
#t4_140{left:87px;bottom:901px;letter-spacing:-0.18px;word-spacing:5.84px;}
#t5_140{left:87px;bottom:880px;letter-spacing:-0.19px;word-spacing:6.91px;}
#t6_140{left:87px;bottom:860px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_140{left:87px;bottom:827px;letter-spacing:-0.08px;}
#t8_140{left:87px;bottom:798px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t9_140{left:114px;bottom:778px;}
#ta_140{left:142px;bottom:778px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tb_140{left:114px;bottom:758px;}
#tc_140{left:142px;bottom:758px;letter-spacing:-0.16px;word-spacing:8.88px;}
#td_140{left:142px;bottom:738px;letter-spacing:-0.13px;word-spacing:0.03px;}
#te_140{left:114px;bottom:718px;}
#tf_140{left:142px;bottom:718px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_140{left:114px;bottom:697px;}
#th_140{left:142px;bottom:697px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ti_140{left:114px;bottom:677px;}
#tj_140{left:142px;bottom:677px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_140{left:87px;bottom:657px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_140{left:114px;bottom:637px;}
#tm_140{left:142px;bottom:637px;letter-spacing:-0.13px;word-spacing:6.17px;}
#tn_140{left:142px;bottom:617px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_140{left:114px;bottom:597px;}
#tp_140{left:142px;bottom:597px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tq_140{left:114px;bottom:576px;}
#tr_140{left:142px;bottom:576px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ts_140{left:114px;bottom:556px;}
#tt_140{left:142px;bottom:556px;letter-spacing:-0.15px;word-spacing:1.18px;}
#tu_140{left:142px;bottom:536px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tv_140{left:114px;bottom:516px;}
#tw_140{left:142px;bottom:516px;letter-spacing:-0.21px;word-spacing:4.82px;}
#tx_140{left:142px;bottom:496px;letter-spacing:-0.17px;}
#ty_140{left:114px;bottom:476px;}
#tz_140{left:142px;bottom:476px;letter-spacing:-0.16px;word-spacing:5.77px;}
#t10_140{left:142px;bottom:455px;letter-spacing:-0.14px;word-spacing:4.59px;}
#t11_140{left:142px;bottom:435px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t12_140{left:114px;bottom:415px;}
#t13_140{left:142px;bottom:415px;letter-spacing:-0.16px;word-spacing:4.15px;}
#t14_140{left:142px;bottom:395px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t15_140{left:114px;bottom:375px;}
#t16_140{left:142px;bottom:375px;letter-spacing:-0.15px;word-spacing:6.09px;}
#t17_140{left:142px;bottom:355px;letter-spacing:-0.2px;}
#t18_140{left:235px;bottom:355px;letter-spacing:-0.15px;}
#t19_140{left:317px;bottom:355px;letter-spacing:-0.48px;}
#t1a_140{left:393px;bottom:355px;letter-spacing:-0.14px;}
#t1b_140{left:504px;bottom:355px;letter-spacing:-0.19px;}
#t1c_140{left:619px;bottom:355px;letter-spacing:-0.09px;}
#t1d_140{left:142px;bottom:334px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1e_140{left:87px;bottom:314px;letter-spacing:-0.12px;word-spacing:3.96px;}
#t1f_140{left:87px;bottom:294px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1g_140{left:114px;bottom:274px;}
#t1h_140{left:142px;bottom:274px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1i_140{left:114px;bottom:254px;}
#t1j_140{left:142px;bottom:254px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t1k_140{left:114px;bottom:234px;}
#t1l_140{left:142px;bottom:234px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t1m_140{left:114px;bottom:213px;}
#t1n_140{left:142px;bottom:213px;letter-spacing:-0.17px;word-spacing:2.54px;}
#t1o_140{left:142px;bottom:193px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1p_140{left:114px;bottom:173px;}
#t1q_140{left:142px;bottom:173px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_140{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_140{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_140{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts140" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg140" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="140/140.svg"
          type="image/svg+xml"
          id="pdf140"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_140" class="t s0_140">
          140{" "}
        </span>
        <span id="t2_140" class="t s1_140">
          Нар хөрш зэрэгцээ сургуулийн багш нар нэгдэн МХБ байгуулж, БШУГ, багш{" "}
        </span>
        <span id="t3_140" class="t s1_140">
          бэлтгэх их сургууль, мэргэжлийн бусад байгууллагуудтай хамтран ажлын{" "}
        </span>
        <span id="t4_140" class="t s1_140">
          байранд мэргэжил дээшлүүлэх шат дараатай сургалт, үйл ажиллагааг{" "}
        </span>
        <span id="t5_140" class="t s1_140">
          зохион байгуулах боломжтой ба ЗАН, МХБ-ээр хамтран сургалт, үйл{" "}
        </span>
        <span id="t6_140" class="t s1_140">
          ажиллагааг дараах хэлбэрүүдээр зохион байгуулж болно.{" "}
        </span>
        <span id="t7_140" class="t s1_140">
          Үүнд:{" "}
        </span>
        <span id="t8_140" class="t s1_140">
          Ажлын байранд мэргэжил дээшлүүлэх сургалтад хамрагдах{" "}
        </span>
        <span id="t9_140" class="t s1_140">
          -{" "}
        </span>
        <span id="ta_140" class="t s1_140">
          Судлагдахууны онолын сургалтуудыг мэргэжилтнүүдээс авах{" "}
        </span>
        <span id="tb_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tc_140" class="t s1_140">
          Хүүхдийн хөгжил, сэтгэл судлал, тархи судлал зэрэг онолын{" "}
        </span>
        <span id="td_140" class="t s1_140">
          сургалтуудыг мэргэжилтнүүдээс авах{" "}
        </span>
        <span id="te_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tf_140" class="t s1_140">
          Онлайн курс, вебинар, подкастууд дээр хамтран суралцах{" "}
        </span>
        <span id="tg_140" class="t s1_140">
          -{" "}
        </span>
        <span id="th_140" class="t s1_140">
          Ажлын байран дээр жижиг багаар хэрэгцээт сургалтад хамрагдах{" "}
        </span>
        <span id="ti_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tj_140" class="t s1_140">
          Мэргэшсэн мэргэжлээр магистрын зэрэг олгох сургалтад хамрагдах{" "}
        </span>
        <span id="tk_140" class="t s1_140">
          Ажлын байранд мэргэжил дээшлүүлэх үйл ажиллагаанд хамрагдах{" "}
        </span>
        <span id="tl_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tm_140" class="t s1_140">
          Хамт олондоо зөвлөгөө өгөх, тэднээс зөвлөгөө авах, харилцан{" "}
        </span>
        <span id="tn_140" class="t s1_140">
          туршлага солилцох{" "}
        </span>
        <span id="to_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tp_140" class="t s1_140">
          Хичээлийн болон үйлийн судалгаа хийх{" "}
        </span>
        <span id="tq_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tr_140" class="t s1_140">
          Мэтгэлцээн хэлэлцүүлэг зохион байгуулах{" "}
        </span>
        <span id="ts_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tt_140" class="t s1_140">
          Шинэ илтгэл, сэтгүүл, нийтлэл, ном болон багшлах тухай баримтат{" "}
        </span>
        <span id="tu_140" class="t s1_140">
          киноны талаар бүтээлч харилцан ярианы клуб байгуулах{" "}
        </span>
        <span id="tv_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tw_140" class="t s1_140">
          Туршлагатай багш нар туршлага багатай багш нарыг менторлон{" "}
        </span>
        <span id="tx_140" class="t s1_140">
          дагалдуулах{" "}
        </span>
        <span id="ty_140" class="t s1_140">
          -{" "}
        </span>
        <span id="tz_140" class="t s1_140">
          Багш нар судлаач, мэргэжилтэн, багш бэлтгэх сургуулийн багш{" "}
        </span>
        <span id="t10_140" class="t s1_140">
          нараас суралцах боломжтой байхаар тусгайлан зохион бүтээсэн{" "}
        </span>
        <span id="t11_140" class="t s1_140">
          үзэсгэлэн, бага хурал, илтгэлд оролцох{" "}
        </span>
        <span id="t12_140" class="t s1_140">
          -{" "}
        </span>
        <span id="t13_140" class="t s1_140">
          Олон улсын солилцоо, багш солилцоонд оролцож, шинэлэг зүйл{" "}
        </span>
        <span id="t14_140" class="t s1_140">
          суралцах, туршлагаа түгээн дэлгэрүүлэх, туршлага судлах{" "}
        </span>
        <span id="t15_140" class="t s1_140">
          -{" "}
        </span>
        <span id="t16_140" class="t s1_140">
          Сурган хүмүүжүүлэх үйл ажиллагааны тодорхой болон ерөнхий{" "}
        </span>
        <span id="t17_140" class="t s1_140">
          талуудын{" "}
        </span>
        <span id="t18_140" class="t s1_140">
          талаарх{" "}
        </span>
        <span id="t19_140" class="t s1_140">
          мэдлэг,{" "}
        </span>
        <span id="t1a_140" class="t s1_140">
          үнэлэмжийг{" "}
        </span>
        <span id="t1b_140" class="t s1_140">
          нэмэгдүүлэх{" "}
        </span>
        <span id="t1c_140" class="t s1_140">
          өөрийн{" "}
        </span>
        <span id="t1d_140" class="t s1_140">
          эргэцүүлэл, уншлага, судалгаа хийх{" "}
        </span>
        <span id="t1e_140" class="t s1_140">
          Багшийн мэргэжлийн тасралтгүй хөгжлийн хэлбэрүүд үр дүнтэй байхын{" "}
        </span>
        <span id="t1f_140" class="t s1_140">
          тулд дараах нийтлэг шаардлагыг хангасан байх ёстой. Үүнд:{" "}
        </span>
        <span id="t1g_140" class="t s2_140">
          -{" "}
        </span>
        <span id="t1h_140" class="t s1_140">
          Багш нарын хэрэгцээ сонирхолд нийцсэн байх{" "}
        </span>
        <span id="t1i_140" class="t s2_140">
          -{" "}
        </span>
        <span id="t1j_140" class="t s1_140">
          Практик ач холбогдолтой байх{" "}
        </span>
        <span id="t1k_140" class="t s2_140">
          -{" "}
        </span>
        <span id="t1l_140" class="t s1_140">
          Тодорхой зорилго, тогтсон хугацаа, үр дүнг тооцсон байх{" "}
        </span>
        <span id="t1m_140" class="t s2_140">
          -{" "}
        </span>
        <span id="t1n_140" class="t s1_140">
          Сургалт, үйл ажиллагаа бүр эргэцүүүлэлтэй байж, дадал хэрэглээ{" "}
        </span>
        <span id="t1o_140" class="t s1_140">
          болгон хэвшүүлдэг байх{" "}
        </span>
        <span id="t1p_140" class="t s2_140">
          -{" "}
        </span>
        <span id="t1q_140" class="t s1_140">
          Хамтын сэтгэлгээ, хүчин чармайлттай байх{" "}
        </span>
      </div>
    </div>
  );
}
