import React from "react";
export default function NinetyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_92{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_92{left:219px;bottom:945px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t3_92{left:251px;bottom:925px;letter-spacing:-0.18px;word-spacing:0.01px;}
#t4_92{left:93px;bottom:910px;letter-spacing:-0.21px;word-spacing:0.06px;}
#t5_92{left:385px;bottom:910px;letter-spacing:-0.24px;}
#t6_92{left:91px;bottom:893px;letter-spacing:-0.25px;}
#t7_92{left:156px;bottom:893px;letter-spacing:-0.19px;word-spacing:6.97px;}
#t8_92{left:91px;bottom:880px;letter-spacing:0.13px;}
#t9_92{left:150px;bottom:880px;letter-spacing:0.22px;}
#ta_92{left:187px;bottom:880px;letter-spacing:0.21px;}
#tb_92{left:261px;bottom:880px;letter-spacing:0.22px;}
#tc_92{left:327px;bottom:880px;letter-spacing:0.26px;}
#td_92{left:91px;bottom:867px;letter-spacing:0.14px;word-spacing:0.12px;}
#te_92{left:383px;bottom:893px;letter-spacing:-0.25px;}
#tf_92{left:438px;bottom:893px;letter-spacing:-0.17px;word-spacing:2.03px;}
#tg_92{left:383px;bottom:880px;letter-spacing:-0.18px;word-spacing:3.2px;}
#th_92{left:383px;bottom:867px;letter-spacing:-0.29px;word-spacing:0.14px;}
#ti_92{left:91px;bottom:851px;letter-spacing:-0.16px;}
#tj_92{left:140px;bottom:851px;letter-spacing:-0.15px;}
#tk_92{left:177px;bottom:851px;letter-spacing:-0.12px;}
#tl_92{left:206px;bottom:851px;letter-spacing:-0.16px;}
#tm_92{left:261px;bottom:851px;letter-spacing:-0.2px;}
#tn_92{left:329px;bottom:851px;letter-spacing:-0.23px;}
#to_92{left:91px;bottom:838px;letter-spacing:-0.19px;word-spacing:0.92px;}
#tp_92{left:91px;bottom:825px;letter-spacing:-0.19px;word-spacing:1.71px;}
#tq_92{left:91px;bottom:812px;letter-spacing:-0.31px;}
#tr_92{left:383px;bottom:844px;letter-spacing:-0.16px;}
#ts_92{left:433px;bottom:844px;letter-spacing:-0.15px;}
#tt_92{left:470px;bottom:844px;letter-spacing:-0.23px;}
#tu_92{left:526px;bottom:844px;letter-spacing:-0.17px;}
#tv_92{left:568px;bottom:844px;letter-spacing:-0.2px;}
#tw_92{left:630px;bottom:844px;letter-spacing:-0.19px;}
#tx_92{left:383px;bottom:831px;letter-spacing:-0.21px;word-spacing:0.76px;}
#ty_92{left:383px;bottom:818px;letter-spacing:-0.34px;}
#tz_92{left:93px;bottom:795px;letter-spacing:-0.17px;}
#t10_92{left:385px;bottom:795px;letter-spacing:-0.16px;}
#t11_92{left:91px;bottom:778px;letter-spacing:-0.2px;word-spacing:4.22px;}
#t12_92{left:91px;bottom:765px;letter-spacing:-0.18px;word-spacing:0.33px;}
#t13_92{left:91px;bottom:753px;letter-spacing:-0.2px;word-spacing:0.05px;}
#t14_92{left:383px;bottom:778px;letter-spacing:-0.3px;word-spacing:2.78px;}
#t15_92{left:383px;bottom:765px;letter-spacing:-0.21px;}
#t16_92{left:405px;bottom:765px;letter-spacing:-0.28px;}
#t17_92{left:486px;bottom:765px;letter-spacing:-0.27px;}
#t18_92{left:519px;bottom:765px;letter-spacing:-0.24px;}
#t19_92{left:559px;bottom:765px;letter-spacing:-0.31px;}
#t1a_92{left:637px;bottom:765px;letter-spacing:-0.24px;}
#t1b_92{left:383px;bottom:753px;letter-spacing:-0.29px;word-spacing:0.3px;}
#t1c_92{left:383px;bottom:740px;letter-spacing:-0.27px;word-spacing:2.24px;}
#t1d_92{left:383px;bottom:727px;letter-spacing:-0.42px;word-spacing:0.18px;}
#t1e_92{left:91px;bottom:710px;letter-spacing:-0.17px;word-spacing:5.24px;}
#t1f_92{left:165px;bottom:710px;letter-spacing:-0.19px;word-spacing:5.25px;}
#t1g_92{left:91px;bottom:698px;letter-spacing:-0.19px;word-spacing:1.35px;}
#t1h_92{left:242px;bottom:698px;letter-spacing:-0.18px;word-spacing:1.33px;}
#t1i_92{left:91px;bottom:685px;letter-spacing:-0.16px;}
#t1j_92{left:143px;bottom:685px;letter-spacing:-0.29px;}
#t1k_92{left:383px;bottom:710px;letter-spacing:-0.21px;word-spacing:5.67px;}
#t1l_92{left:383px;bottom:698px;letter-spacing:-0.18px;word-spacing:3.54px;}
#t1m_92{left:383px;bottom:685px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t1n_92{left:93px;bottom:668px;letter-spacing:-0.18px;word-spacing:0.03px;}
#t1o_92{left:385px;bottom:668px;letter-spacing:-0.21px;word-spacing:0.06px;}
#t1p_92{left:91px;bottom:650px;letter-spacing:-0.53px;word-spacing:-0.72px;}
#t1q_92{left:91px;bottom:637px;letter-spacing:-0.45px;}
#t1r_92{left:128px;bottom:637px;letter-spacing:-0.5px;word-spacing:-0.25px;}
#t1s_92{left:91px;bottom:624px;letter-spacing:-0.22px;word-spacing:0.08px;}
#t1t_92{left:383px;bottom:650px;letter-spacing:-0.2px;word-spacing:-0.22px;}
#t1u_92{left:383px;bottom:637px;letter-spacing:-0.24px;word-spacing:3.2px;}
#t1v_92{left:383px;bottom:624px;letter-spacing:-0.21px;word-spacing:0.07px;}
#t1w_92{left:91px;bottom:607px;letter-spacing:-0.21px;word-spacing:7.69px;}
#t1x_92{left:91px;bottom:594px;letter-spacing:-0.2px;word-spacing:5.96px;}
#t1y_92{left:91px;bottom:581px;letter-spacing:-0.18px;word-spacing:0.39px;}
#t1z_92{left:91px;bottom:569px;letter-spacing:-0.28px;word-spacing:0.13px;}
#t20_92{left:383px;bottom:601px;letter-spacing:-0.16px;}
#t21_92{left:432px;bottom:601px;letter-spacing:-0.18px;word-spacing:2.26px;}
#t22_92{left:383px;bottom:588px;letter-spacing:-0.28px;word-spacing:0.13px;}
#t23_92{left:93px;bottom:552px;letter-spacing:-0.23px;}
#t24_92{left:385px;bottom:552px;letter-spacing:-0.2px;}
#t25_92{left:91px;bottom:536px;letter-spacing:-0.21px;word-spacing:2.69px;}
#t26_92{left:91px;bottom:524px;letter-spacing:-0.15px;}
#t27_92{left:133px;bottom:524px;letter-spacing:-0.2px;}
#t28_92{left:176px;bottom:524px;letter-spacing:-0.14px;}
#t29_92{left:237px;bottom:524px;letter-spacing:-0.14px;}
#t2a_92{left:273px;bottom:524px;letter-spacing:-0.15px;}
#t2b_92{left:316px;bottom:524px;letter-spacing:-0.2px;}
#t2c_92{left:91px;bottom:511px;letter-spacing:-0.24px;word-spacing:0.09px;}
#t2d_92{left:383px;bottom:536px;letter-spacing:-0.21px;word-spacing:2.2px;}
#t2e_92{left:383px;bottom:524px;letter-spacing:-0.19px;word-spacing:4.35px;}
#t2f_92{left:383px;bottom:511px;letter-spacing:-0.27px;word-spacing:0.13px;}
#t2g_92{left:91px;bottom:494px;letter-spacing:-0.2px;word-spacing:6.03px;}
#t2h_92{left:91px;bottom:481px;letter-spacing:-0.21px;word-spacing:5.79px;}
#t2i_92{left:91px;bottom:469px;letter-spacing:-0.18px;word-spacing:0.78px;}
#t2j_92{left:91px;bottom:456px;letter-spacing:-0.23px;word-spacing:0.09px;}
#t2k_92{left:383px;bottom:488px;letter-spacing:-0.19px;word-spacing:2.87px;}
#t2l_92{left:383px;bottom:475px;letter-spacing:-0.21px;word-spacing:6.6px;}
#t2m_92{left:383px;bottom:462px;letter-spacing:-0.21px;word-spacing:0.06px;}
#t2n_92{left:93px;bottom:439px;letter-spacing:-0.17px;word-spacing:0.02px;}
#t2o_92{left:385px;bottom:439px;letter-spacing:-0.2px;word-spacing:0.04px;}
#t2p_92{left:91px;bottom:420px;letter-spacing:-0.38px;word-spacing:6.59px;}
#t2q_92{left:91px;bottom:408px;letter-spacing:-0.26px;word-spacing:-0.76px;}
#t2r_92{left:91px;bottom:395px;letter-spacing:-0.3px;word-spacing:-0.04px;}
#t2s_92{left:381px;bottom:420px;letter-spacing:-0.18px;word-spacing:4.08px;}
#t2t_92{left:381px;bottom:408px;letter-spacing:-0.2px;word-spacing:5.66px;}
#t2u_92{left:381px;bottom:395px;letter-spacing:-0.22px;word-spacing:0.07px;}
#t2v_92{left:91px;bottom:370px;letter-spacing:-0.2px;word-spacing:4.43px;}
#t2w_92{left:91px;bottom:357px;letter-spacing:-0.21px;word-spacing:0.07px;}
#t2x_92{left:383px;bottom:376px;letter-spacing:-0.18px;word-spacing:0.31px;}
#t2y_92{left:383px;bottom:363px;letter-spacing:-0.18px;word-spacing:2.26px;}
#t2z_92{left:383px;bottom:351px;letter-spacing:-0.22px;word-spacing:0.07px;}
#t30_92{left:87px;bottom:308px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t31_92{left:87px;bottom:280px;letter-spacing:-0.2px;word-spacing:-0.54px;}
#t32_92{left:355px;bottom:280px;letter-spacing:-0.13px;word-spacing:-0.61px;}
#t33_92{left:87px;bottom:259px;letter-spacing:-0.14px;word-spacing:3.89px;}
#t34_92{left:87px;bottom:239px;letter-spacing:-0.17px;word-spacing:-0.32px;}
#t35_92{left:87px;bottom:219px;letter-spacing:-0.14px;word-spacing:-2.67px;}
#t36_92{left:87px;bottom:199px;letter-spacing:-0.12px;word-spacing:2.25px;}
#t37_92{left:87px;bottom:179px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t38_92{left:87px;bottom:154px;letter-spacing:-0.18px;word-spacing:9.43px;}
#t39_92{left:87px;bottom:134px;letter-spacing:-0.19px;word-spacing:2.3px;}
#t3a_92{left:87px;bottom:114px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_92{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_92{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_92{font-size:11px;font-family:MogulArial-Bold_179;color:#000;}
.s3_92{font-size:11px;font-family:MogulArial_16s;color:#000;}
.s4_92{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_92{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts92" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg92" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="92/92.svg"
          type="image/svg+xml"
          id="pdf92"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_92" class="t s0_92">
          92{" "}
        </span>
        <span id="t2_92" class="t s1_92">
          Зураг 3.5. Хөтөлбөрийн уялдаа холбооны стратеги{" "}
        </span>
        <span id="t3_92" class="t s2_92">
          Сургалтын хөтөлбөрийг нэгтгэх стратеги{" "}
        </span>
        <span id="t4_92" class="t s3_92">
          Хуваагдсан/ Хуваах загвар{" "}
        </span>
        <span id="t5_92" class="t s3_92">
          Холбох/Холбогдсон{" "}
        </span>
        <span id="t6_92" class="t s3_92">
          Тайлбар.{" "}
        </span>
        <span id="t7_92" class="t s3_92">
          Сэдвийн хамрах хүрээг саланги болон{" "}
        </span>
        <span id="t8_92" class="t s3_92">
          ялгаатай{" "}
        </span>
        <span id="t9_92" class="t s3_92">
          үзэл{" "}
        </span>
        <span id="ta_92" class="t s3_92">
          баримтлал,{" "}
        </span>
        <span id="tb_92" class="t s3_92">
          чиглэлээр{" "}
        </span>
        <span id="tc_92" class="t s3_92">
          хуваадаг{" "}
        </span>
        <span id="td_92" class="t s3_92">
          уламжлалт загвар{" "}
        </span>
        <span id="te_92" class="t s3_92">
          Тайлбар.{" "}
        </span>
        <span id="tf_92" class="t s3_92">
          Сэдэв тус бүрийн хамрах хүрээнд хичээлийн{" "}
        </span>
        <span id="tg_92" class="t s3_92">
          агуулга нь нэг жилийн эсвэл дараагийн жилийн сэдэв{" "}
        </span>
        <span id="th_92" class="t s3_92">
          ойлголттой холбогддог.{" "}
        </span>
        <span id="ti_92" class="t s3_92">
          Жишээ:{" "}
        </span>
        <span id="tj_92" class="t s3_92">
          Багш{" "}
        </span>
        <span id="tk_92" class="t s3_92">
          энэ{" "}
        </span>
        <span id="tl_92" class="t s3_92">
          загварыг{" "}
        </span>
        <span id="tm_92" class="t s3_92">
          Математик,{" "}
        </span>
        <span id="tn_92" class="t s3_92">
          Ш/Ухаан,{" "}
        </span>
        <span id="to_92" class="t s3_92">
          Нийгмийн ухаан, Хэл судлал эсвэл хэл/хэлний шинжлэх{" "}
        </span>
        <span id="tp_92" class="t s3_92">
          ухаан, хүн судлал, технологи ба дүрслэх урлагт тусгаж{" "}
        </span>
        <span id="tq_92" class="t s3_92">
          ашигладаг.{" "}
        </span>
        <span id="tr_92" class="t s3_92">
          Жишээ.{" "}
        </span>
        <span id="ts_92" class="t s3_92">
          Багш{" "}
        </span>
        <span id="tt_92" class="t s3_92">
          бутархай{" "}
        </span>
        <span id="tu_92" class="t s3_92">
          тооны{" "}
        </span>
        <span id="tv_92" class="t s3_92">
          ойлголтыг{" "}
        </span>
        <span id="tw_92" class="t s3_92">
          аравтын{" "}
        </span>
        <span id="tx_92" class="t s3_92">
          бутархайтай холбодог. Энэ нь эргээд мөнгө, дүн зэрэгтэй{" "}
        </span>
        <span id="ty_92" class="t s3_92">
          хамаардаг.{" "}
        </span>
        <span id="tz_92" class="t s3_92">
          Багтах/Багтсан{" "}
        </span>
        <span id="t10_92" class="t s3_92">
          Дараалсан{" "}
        </span>
        <span id="t11_92" class="t s3_92">
          Тайлбар. Хичээл бүрийн хүрээнд багш нийгмийн ур{" "}
        </span>
        <span id="t12_92" class="t s3_92">
          чадвар, сэтгэн бодох чадвар, агуулгын тусгай ур чадвар{" "}
        </span>
        <span id="t13_92" class="t s3_92">
          зэрэг олон чадварыг зорилтот түвшинд тавьдаг.{" "}
        </span>
        <span id="t14_92" class="t s3_92">
          Тайлбар. Хичээлийн сэдвүүд эсвэл нэгжүүдийг хооронд{" "}
        </span>
        <span id="t15_92" class="t s3_92">
          нь{" "}
        </span>
        <span id="t16_92" class="t s3_92">
          давхцуулахын{" "}
        </span>
        <span id="t17_92" class="t s3_92">
          тулд{" "}
        </span>
        <span id="t18_92" class="t s3_92">
          дахин{" "}
        </span>
        <span id="t19_92" class="t s3_92">
          боловсруулж,{" "}
        </span>
        <span id="t1a_92" class="t s3_92">
          төстэй{" "}
        </span>
        <span id="t1b_92" class="t s3_92">
          санаануудыг эмх цэгцтэй, дараалалд оруулсан байна. Тус{" "}
        </span>
        <span id="t1c_92" class="t s3_92">
          тусдаа өөр хичээлүүд ч гэсэн үүнтэй адил санаануудыг{" "}
        </span>
        <span id="t1d_92" class="t s3_92">
          тусгаж өгдөг.{" "}
        </span>
        <span id="t1e_92" class="t s3_92">
          Жишээ нь:{" "}
        </span>
        <span id="t1f_92" class="t s3_92">
          багш ургамлын амьдралын мөчлөгийн{" "}
        </span>
        <span id="t1g_92" class="t s3_92">
          дарааллаар олгох чадварыг{" "}
        </span>
        <span id="t1h_92" class="t s3_92">
          фотосинтезийн нэгж дээр{" "}
        </span>
        <span id="t1i_92" class="t s3_92">
          суурилан{" "}
        </span>
        <span id="t1j_92" class="t s3_92">
          боловсруулдаг.{" "}
        </span>
        <span id="t1k_92" class="t s3_92">
          Жишээ нь: англи хэлний багш тодорхой цаг үеийг{" "}
        </span>
        <span id="t1l_92" class="t s3_92">
          дүрсэлсэн түүхэн романыг толилуулж байхад түүхийн{" "}
        </span>
        <span id="t1m_92" class="t s3_92">
          багш тухайн цаг үеийг зааж байна.{" "}
        </span>
        <span id="t1n_92" class="t s3_92">
          Хуваалцсан/ дундын{" "}
        </span>
        <span id="t1o_92" class="t s3_92">
          Вебтэй холбогдсон{" "}
        </span>
        <span id="t1p_92" class="t s3_92">
          Тайлбар. Хамтарсан төлөвлөлт, сургалт нь 2 аргаар явагддаг{" "}
        </span>
        <span id="t1q_92" class="t s3_92">
          бөгөөд{" "}
        </span>
        <span id="t1r_92" class="t s3_92">
          тэдгээрт давхцаж буй үзэл баримтлал, санаанууд нь{" "}
        </span>
        <span id="t1s_92" class="t s3_92">
          зохион байгуулалтын элемент болон гарч ирдэг.{" "}
        </span>
        <span id="t1t_92" class="t s3_92">
          Тайлбар. Тухайн сэдвийг сургалтын хөтөлбөрийн агуулга,{" "}
        </span>
        <span id="t1u_92" class="t s3_92">
          хичээлийн сэдэвтэй холбож өгдөг ба тохирох ойлголт,{" "}
        </span>
        <span id="t1v_92" class="t s3_92">
          ухагдахуун, сэдэв, санаагаар нь ялгадаг.{" "}
        </span>
        <span id="t1w_92" class="t s3_92">
          Жишээ: Шинжлэх ухаан, математикийн багш нар{" "}
        </span>
        <span id="t1x_92" class="t s3_92">
          мэдээлэл цуглуулах, диаграмм хийх, график зурах{" "}
        </span>
        <span id="t1y_92" class="t s3_92">
          зэргийг багаар заах боломжтой нийтлэг дундын ойлголт{" "}
        </span>
        <span id="t1z_92" class="t s3_92">
          болгон ашигладаг.{" "}
        </span>
        <span id="t20_92" class="t s3_92">
          Жишээ:{" "}
        </span>
        <span id="t21_92" class="t s3_92">
          Багш энгийн түгээмэл сэдвийг бусад агуулгын{" "}
        </span>
        <span id="t22_92" class="t s3_92">
          хүрээтэй холбож өгдөг.{" "}
        </span>
        <span id="t23_92" class="t s3_92">
          Холбогдсон{" "}
        </span>
        <span id="t24_92" class="t s3_92">
          Нэгдсэн{" "}
        </span>
        <span id="t25_92" class="t s3_92">
          Тайлбар. Мета сургалтын арга нь олон янзын аргаар{" "}
        </span>
        <span id="t26_92" class="t s3_92">
          сэтгэн{" "}
        </span>
        <span id="t27_92" class="t s3_92">
          бодох,{" "}
        </span>
        <span id="t28_92" class="t s3_92">
          нийгмийн,{" "}
        </span>
        <span id="t29_92" class="t s3_92">
          оюун{" "}
        </span>
        <span id="t2a_92" class="t s3_92">
          ухаан,{" "}
        </span>
        <span id="t2b_92" class="t s3_92">
          технологид{" "}
        </span>
        <span id="t2c_92" class="t s3_92">
          суралцах чадварыг холбож өгдөг.{" "}
        </span>
        <span id="t2d_92" class="t s3_92">
          Тайлбар. Энэхүү салбар дундын хандлага нь тодорхой{" "}
        </span>
        <span id="t2e_92" class="t s3_92">
          сэдвүүдээр давхцаж байгаа агуулгыг нэгдсэн загварт{" "}
        </span>
        <span id="t2f_92" class="t s3_92">
          тусгаж заадаг.{" "}
        </span>
        <span id="t2g_92" class="t s3_92">
          Жишээ нь: Багш нар унших, математик, шинжлэх{" "}
        </span>
        <span id="t2h_92" class="t s3_92">
          ухааны чиглэлээр таамаглах чадварт чиглэдэг бол{" "}
        </span>
        <span id="t2i_92" class="t s3_92">
          нийгмийн ухааны багш одоогийн үйл явдлыг урьдчилан{" "}
        </span>
        <span id="t2j_92" class="t s3_92">
          таамаглахад чиглүүлдэг.{" "}
        </span>
        <span id="t2k_92" class="t s3_92">
          Жишээ нь математик, шинжлэх ухаан, нийгэм судлал,{" "}
        </span>
        <span id="t2l_92" class="t s3_92">
          дүрслэх урлагийн чиглэлээр багш нар янз бүрийн{" "}
        </span>
        <span id="t2m_92" class="t s3_92">
          загвараар дамжуулан агуулгад ханддаг.{" "}
        </span>
        <span id="t2n_92" class="t s3_92">
          Гүн орох{" "}
        </span>
        <span id="t2o_92" class="t s3_92">
          Сүлжээнд холбогдсон{" "}
        </span>
        <span id="t2p_92" class="t s3_92">
          Тодорхойлолт. Энэ хичээлүүд нь сурагчийн мэдлэг,{" "}
        </span>
        <span id="t2q_92" class="t s3_92">
          туршлага, ур чадварын нэг хэсэг болж, сурагч бүх агуулгыг{" "}
        </span>
        <span id="t2r_92" class="t s3_92">
          энэ өнцгөөр шүүж, өөрийн туршлагаар улам гүн судалдаг.{" "}
        </span>
        <span id="t2s_92" class="t s3_92">
          Тайлбар. Сурагч нь бүх зүйлсийг шинжээчийн нүдээр{" "}
        </span>
        <span id="t2t_92" class="t s3_92">
          шүүж, холбогдох салбарын мэргэжилтнүүдийн гадаад{" "}
        </span>
        <span id="t2u_92" class="t s3_92">
          сүлжээ рүү хөтлөх дотоод холболтыг хийдэг.{" "}
        </span>
        <span id="t2v_92" class="t s3_92">
          Жишээ нь сурагч бүхнийг шинжлэх сонирхолтой ба{" "}
        </span>
        <span id="t2w_92" class="t s3_92">
          мэдлэгийг энэхүү өнцөгөөр хардаг.{" "}
        </span>
        <span id="t2x_92" class="t s3_92">
          Жишээ нь архитектор программист мэдлэгийнхээ баазыг{" "}
        </span>
        <span id="t2y_92" class="t s3_92">
          өргөжүүлж CAD/CAM технологийг загварчлахад өөрийн{" "}
        </span>
        <span id="t2z_92" class="t s3_92">
          мэдлэгийн хүрээгээ ашигладаг.{" "}
        </span>
        <span id="t30_92" class="t s4_92">
          Хичээл хоорондын залгамж холбоог яагаад хийх хэрэгтэй вэ?{" "}
        </span>
        <span id="t31_92" class="t s5_92">
          Улс орнууд үндсэн судлагдахуун,{" "}
        </span>
        <span id="t32_92" class="t s5_92">
          суралцах үйл ажиллагаанаас гадна шинэ{" "}
        </span>
        <span id="t33_92" class="t s5_92">
          зууны болон цаг үеийн тулгамдсан асуудал, дэлхийн асуудлыг хөндсөн{" "}
        </span>
        <span id="t34_92" class="t s5_92">
          агуулгыг сургалтын хөтөлбөрт тусгах хандлагатай болж байна. Жишээлбэл,{" "}
        </span>
        <span id="t35_92" class="t s5_92">
          Р21 байгууллага (2009) 21 дүгээр зууны чадвар эзэмшүүлэх цөм
          судлагдахуун{" "}
        </span>
        <span id="t36_92" class="t s5_92">
          (core Subject) ба судлагдахуун хоорондын сэдэв (interdisciplinary
          themes)-{" "}
        </span>
        <span id="t37_92" class="t s5_92">
          ийг санал болгожээ.{" "}
        </span>
        <span id="t38_92" class="t s5_92">
          Сургуулийн удирдлага хичээл хоорондын агуулгын залгамж холбоог{" "}
        </span>
        <span id="t39_92" class="t s5_92">
          гаргахдаа хичээлийг гол цөм гэж үзэн сурагчдын эзэмшвэл зохих мэдлэг,{" "}
        </span>
        <span id="t3a_92" class="t s5_92">
          чадварыг ямар зохион байгуулалттайгаар олгох менежментийг хийнэ.{" "}
        </span>
      </div>
    </div>
  );
}
