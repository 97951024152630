import React from "react";
export default function ThirtyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_39{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_39{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_39{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:7.87px;}
#t4_39{left:87px;bottom:921px;letter-spacing:-0.1px;}
#t5_39{left:204px;bottom:921px;letter-spacing:-0.29px;}
#t6_39{left:303px;bottom:921px;letter-spacing:-0.15px;}
#t7_39{left:407px;bottom:921px;letter-spacing:-0.16px;}
#t8_39{left:474px;bottom:921px;letter-spacing:-0.2px;}
#t9_39{left:583px;bottom:921px;letter-spacing:-0.1px;}
#ta_39{left:87px;bottom:901px;letter-spacing:-0.16px;word-spacing:7.36px;}
#tb_39{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.61px;}
#tc_39{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:-0.34px;}
#td_39{left:87px;bottom:840px;letter-spacing:-0.17px;word-spacing:5.53px;}
#te_39{left:87px;bottom:820px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tf_39{left:87px;bottom:787px;letter-spacing:-0.14px;word-spacing:-0.05px;}
#tg_39{left:87px;bottom:767px;letter-spacing:-0.15px;word-spacing:3.83px;}
#th_39{left:87px;bottom:746px;letter-spacing:-0.12px;word-spacing:1.75px;}
#ti_39{left:87px;bottom:726px;letter-spacing:-0.15px;word-spacing:1.79px;}
#tj_39{left:87px;bottom:706px;letter-spacing:-0.13px;word-spacing:1.4px;}
#tk_39{left:87px;bottom:686px;letter-spacing:-0.17px;word-spacing:5.67px;}
#tl_39{left:87px;bottom:666px;letter-spacing:-0.16px;word-spacing:5.33px;}
#tm_39{left:87px;bottom:646px;letter-spacing:-0.18px;word-spacing:8.07px;}
#tn_39{left:87px;bottom:625px;letter-spacing:-0.16px;word-spacing:1.33px;}
#to_39{left:87px;bottom:605px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tp_39{left:431px;bottom:605px;letter-spacing:-0.32px;word-spacing:0.23px;}
#tq_39{left:87px;bottom:572px;letter-spacing:-0.14px;word-spacing:4.5px;}
#tr_39{left:87px;bottom:552px;letter-spacing:-0.15px;}
#ts_39{left:191px;bottom:552px;letter-spacing:-0.16px;}
#tt_39{left:273px;bottom:552px;letter-spacing:-0.11px;}
#tu_39{left:366px;bottom:552px;letter-spacing:-0.16px;}
#tv_39{left:463px;bottom:552px;letter-spacing:-0.19px;}
#tw_39{left:562px;bottom:552px;letter-spacing:-0.16px;}
#tx_39{left:87px;bottom:532px;letter-spacing:-0.13px;word-spacing:-0.65px;}
#ty_39{left:87px;bottom:512px;letter-spacing:-0.14px;word-spacing:5.08px;}
#tz_39{left:87px;bottom:491px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t10_39{left:87px;bottom:458px;letter-spacing:-0.15px;word-spacing:3.72px;}
#t11_39{left:87px;bottom:438px;letter-spacing:-0.15px;word-spacing:2.97px;}
#t12_39{left:87px;bottom:418px;letter-spacing:-0.14px;word-spacing:-1.71px;}
#t13_39{left:87px;bottom:398px;letter-spacing:-0.15px;word-spacing:4.84px;}
#t14_39{left:87px;bottom:378px;letter-spacing:-0.15px;word-spacing:2.86px;}
#t15_39{left:87px;bottom:357px;letter-spacing:-0.19px;word-spacing:2.46px;}
#t16_39{left:87px;bottom:337px;letter-spacing:-0.15px;word-spacing:7.45px;}
#t17_39{left:87px;bottom:317px;letter-spacing:-0.14px;word-spacing:0.05px;}

.s0_39{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_39{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_39{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_39{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts39" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg39" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="39/39.svg"
          type="image/svg+xml"
          id="pdf39"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_39" class="t s1_39">
          39{" "}
        </span>
        <span id="t3_39" class="t s2_39">
          Сургуулиуд сурагчийн хөгжил хамгаалал, багшийн хөгжил, сургалтын{" "}
        </span>
        <span id="t4_39" class="t s2_39">
          хөтөлбөрийн{" "}
        </span>
        <span id="t5_39" class="t s2_39">
          хэрэгжилт,{" "}
        </span>
        <span id="t6_39" class="t s2_39">
          сургуулийн{" "}
        </span>
        <span id="t7_39" class="t s2_39">
          орчин,{" "}
        </span>
        <span id="t8_39" class="t s2_39">
          удирдлагын{" "}
        </span>
        <span id="t9_39" class="t s2_39">
          манлайлал,{" "}
        </span>
        <span id="ta_39" class="t s2_39">
          оролцогч талуудын хамтын ажиллагаа түншлэл, сэтгэл ханамж ямар{" "}
        </span>
        <span id="tb_39" class="t s2_39">
          түвшинд байгааг байгууллагын болон багшийн гүйцэтгэлийн үнэлгээний үр{" "}
        </span>
        <span id="tc_39" class="t s2_39">
          дүнд шинжилгээ хийж, асуудлаа тодорхойлсон нь дамжиггүй. Мөн өнгөрсөн{" "}
        </span>
        <span id="td_39" class="t s2_39">
          жилийн гүйцэтгэлээ тайлагнаж, биелээгүй зорилт, шийдээгүй асуудлын{" "}
        </span>
        <span id="te_39" class="t s2_39">
          шалтгааныг ярилцаж дүгнэлт хийсэн байх учиртай.{" "}
        </span>
        <span id="tf_39" class="t s2_39">
          Суурь асуудлаа шийдвэрлэж, амжилтаа ахиулж сайжруулахын тулд зөвхөн{" "}
        </span>
        <span id="tg_39" class="t s2_39">
          тааруу гүйцэтгэлтэй багш нар илүү их хичээж, эсвэл тааруу үнэлэгдсэн{" "}
        </span>
        <span id="th_39" class="t s2_39">
          үзүүлэлтээ сайжруулж сайн үнэлгээ авахын төлөө ажиллах гэхээсээ илүү{" "}
        </span>
        <span id="ti_39" class="t s2_39">
          сургуулийн менежментээ бүхэлд нь сайжруулахын төлөө хамтарч, нэгдэж{" "}
        </span>
        <span id="tj_39" class="t s2_39">
          ажиллах нь чухал юм. Үүний тулд юуны өмнө сургууль хичээлийн жилийн{" "}
        </span>
        <span id="tk_39" class="t s2_39">
          менежментийн зураглалаа оновчтой боловсруулах шаардлагатай. Учир{" "}
        </span>
        <span id="tl_39" class="t s2_39">
          нь сургууль алсын хараагаа оновчтой тодорхойлоод орхихгүй, түүндээ{" "}
        </span>
        <span id="tm_39" class="t s2_39">
          хүрэхийн тулд урт, дунд хугацааны төлөвлөлтөө богино хугацаагаар{" "}
        </span>
        <span id="tn_39" class="t s2_39">
          нарийвчлан боловсруулж нэг зураглалд оруулснаар хүн бүрд ойлгомжтой,{" "}
        </span>
        <span id="to_39" class="t s2_39">
          өдөр тутмын СҮА-г уялдуулан чиглүүлэхэд{" "}
        </span>
        <span id="tp_39" class="t s2_39">
          хялбар болдог.{" "}
        </span>
        <span id="tq_39" class="t s2_39">
          Сургуулийн менежментийн зураглал гэдэг нь тухайн хичээлийн жилийн{" "}
        </span>
        <span id="tr_39" class="t s2_39">
          сургуулийн{" "}
        </span>
        <span id="ts_39" class="t s2_39">
          зорилго,{" "}
        </span>
        <span id="tt_39" class="t s2_39">
          тэргүүлэх{" "}
        </span>
        <span id="tu_39" class="t s2_39">
          зорилтууд{" "}
        </span>
        <span id="tv_39" class="t s2_39">
          (тодорхой,{" "}
        </span>
        <span id="tw_39" class="t s2_39">
          хэмжигдэхүйц,{" "}
        </span>
        <span id="tx_39" class="t s2_39">
          хүрэхүйц, бодитой, цаг хугацаатай) тэдгээрийг хэмжих шалгуур
          үзүүлэлтүүд{" "}
        </span>
        <span id="ty_39" class="t s2_39">
          болон түүнд хүрэхийн тулд төлөвлөж буй хоорондоо логик уялдаатай,{" "}
        </span>
        <span id="tz_39" class="t s2_39">
          оновчтой төлөвлөсөн гол үйл ажиллагааг нэг дор буулгасан зураглал юм.{" "}
        </span>
        <span id="t10_39" class="t s2_39">
          Сургуулиуд нэг хичээлийн жилийн менежментийн зураглалаа хэлбэрийн{" "}
        </span>
        <span id="t11_39" class="t s2_39">
          хувьд олон янзаар гаргасан байх боловч доторх бүрэлдэхүүн хэсгүүдийг{" "}
        </span>
        <span id="t12_39" class="t s2_39">
          нарийн анзаарвал зорилго нь хэт ерөнхий, алсын хараатайгаа
          уялдуулаагүй,{" "}
        </span>
        <span id="t13_39" class="t s2_39">
          зорилт нь хэмжигдэхүйц бус, үйл ажиллагаа нь алсын хараа, зорилго,{" "}
        </span>
        <span id="t14_39" class="t s2_39">
          зорилтоо хэрэгжүүлэхэд чиглээгүй байх зэрэг дутагдалтай зүйл түгээмэл{" "}
        </span>
        <span id="t15_39" class="t s2_39">
          ажиглагддаг. Тиймээс нэг хичээлийн жилийн буюу богино хугацаанд хүрч{" "}
        </span>
        <span id="t16_39" class="t s2_39">
          биелүүлж болох зорилго зорилтоо үндэслэлтэй, суурь түвшин, хүрэх{" "}
        </span>
        <span id="t17_39" class="t s2_39">
          түвшингээ бодитой тооцож төлөвлөх нь чухал.{" "}
        </span>
      </div>
    </div>
  );
}
