import React from "react";
export default function OneHundredSeventyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_172{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_172{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t3_172{left:113px;bottom:921px;letter-spacing:-0.05px;}
#t4_172{left:139px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t5_172{left:139px;bottom:901px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t6_172{left:113px;bottom:880px;letter-spacing:-0.05px;}
#t7_172{left:139px;bottom:880px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t8_172{left:139px;bottom:860px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t9_172{left:113px;bottom:840px;letter-spacing:-0.05px;}
#ta_172{left:139px;bottom:840px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_172{left:87px;bottom:800px;letter-spacing:-0.13px;word-spacing:3.7px;}
#tc_172{left:87px;bottom:780px;letter-spacing:-0.14px;word-spacing:0.05px;}
#td_172{left:87px;bottom:739px;letter-spacing:-0.15px;word-spacing:1.5px;}
#te_172{left:87px;bottom:719px;letter-spacing:-0.12px;word-spacing:2px;}
#tf_172{left:536px;bottom:719px;letter-spacing:-0.15px;word-spacing:1.99px;}
#tg_172{left:87px;bottom:699px;letter-spacing:-0.24px;word-spacing:0.23px;}
#th_172{left:380px;bottom:699px;letter-spacing:-0.14px;word-spacing:0.14px;}
#ti_172{left:87px;bottom:679px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_172{left:87px;bottom:638px;letter-spacing:-0.2px;word-spacing:0.09px;}
#tk_172{left:87px;bottom:610px;letter-spacing:-0.18px;word-spacing:0.05px;}
#tl_172{left:87px;bottom:589px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tm_172{left:87px;bottom:549px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tn_172{left:87px;bottom:522px;}
#to_172{left:114px;bottom:520px;letter-spacing:-0.16px;word-spacing:4.86px;}
#tp_172{left:114px;bottom:500px;letter-spacing:-0.19px;}
#tq_172{left:87px;bottom:478px;}
#tr_172{left:114px;bottom:476px;letter-spacing:-0.19px;word-spacing:0.09px;}
#ts_172{left:87px;bottom:453px;}
#tt_172{left:114px;bottom:451px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tu_172{left:87px;bottom:429px;}
#tv_172{left:114px;bottom:427px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tw_172{left:87px;bottom:404px;}
#tx_172{left:114px;bottom:402px;letter-spacing:-0.21px;word-spacing:0.12px;}
#ty_172{left:87px;bottom:362px;letter-spacing:-0.19px;word-spacing:6.53px;}
#tz_172{left:319px;bottom:362px;letter-spacing:-0.22px;word-spacing:6.55px;}
#t10_172{left:87px;bottom:342px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t11_172{left:247px;bottom:342px;letter-spacing:-0.19px;}
#t12_172{left:87px;bottom:301px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t13_172{left:87px;bottom:277px;}
#t14_172{left:114px;bottom:277px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t15_172{left:87px;bottom:252px;}
#t16_172{left:114px;bottom:252px;letter-spacing:-0.17px;word-spacing:1.98px;}
#t17_172{left:114px;bottom:232px;letter-spacing:-0.18px;}

.s0_172{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_172{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_172{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_172{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_172{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_172{font-size:12px;font-family:SymbolMT_19r;color:#000;}
.s6_172{font-size:17px;font-family:ArialMT_1lq;color:#000;}`}
      </style>

      <style id="fonts172" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg172" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="172/172.svg"
          type="image/svg+xml"
          id="pdf172"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_172" class="t s0_172">
          172{" "}
        </span>
        <span id="t2_172" class="t s1_172">
          Алсын хараа, эрхэм зорилго яагаад хэрэгтэй вэ?{" "}
        </span>
        <span id="t3_172" class="t s2_172">
          1.{" "}
        </span>
        <span id="t4_172" class="t s3_172">
          Алсын хараа, эрхэм зорилгыг хэрэгжүүлэхийн төлөө сургуулийг{" "}
        </span>
        <span id="t5_172" class="t s3_172">
          бүхэлд нь чиглүүлэх{" "}
        </span>
        <span id="t6_172" class="t s2_172">
          2.{" "}
        </span>
        <span id="t7_172" class="t s3_172">
          Аливаа шийдвэр гаргахад баримтлах гол тулгуур буюу жанжин{" "}
        </span>
        <span id="t8_172" class="t s3_172">
          шугам нь байх{" "}
        </span>
        <span id="t9_172" class="t s2_172">
          3.{" "}
        </span>
        <span id="ta_172" class="t s3_172">
          Сургуулийнхаа тухай цэгцтэй ойлголт өгөх{" "}
        </span>
        <span id="tb_172" class="t s3_172">
          Сургуулийн алсын хараа нь “бид хаана хүрэх вэ?” гэсэн урт хугацааны{" "}
        </span>
        <span id="tc_172" class="t s3_172">
          зорилт байна.{" "}
        </span>
        <span id="td_172" class="t s3_172">
          Эрхэм зорилго нь урт хугацааны зорилтоо хэрэгжүүлэхэд чиглэсэн байна.{" "}
        </span>
        <span id="te_172" class="t s3_172">
          Өөрөөр хэлбэл, нийгмийн өмнө хүлээсэн үүрэг хийгээд{" "}
        </span>
        <span id="tf_172" class="t s4_172">
          сурагчийн хүсэл{" "}
        </span>
        <span id="tg_172" class="t s4_172">
          эрмэлзлэл, сонирхолд хөтлөгдсөн{" "}
        </span>
        <span id="th_172" class="t s3_172">
          удирдлагын үйл ажиллагааны үр дүнг{" "}
        </span>
        <span id="ti_172" class="t s3_172">
          товч тодорхой, үйл үгийн ирээдүй цагийн хэлбэрээр илэрхийлсэн байна.{" "}
        </span>
        <span id="tj_172" class="t s1_172">
          Эрхэм зорилгодоо хэрхэн хүрэх вэ?{" "}
        </span>
        <span id="tk_172" class="t s3_172">
          Эрхэм зорилгодоо хүрэх зорилтуудаа тодорхойлж, зорилт бүрийг урт,
          дунд,{" "}
        </span>
        <span id="tl_172" class="t s3_172">
          богино гэсэн үе шаттайгаар төлөвлөнө.{" "}
        </span>
        <span id="tm_172" class="t s1_172">
          Нэгдсэн төлөвлөлт яагаад хэрэгтэй вэ?{" "}
        </span>
        <span id="tn_172" class="t s5_172">
          •{" "}
        </span>
        <span id="to_172" class="t s3_172">
          Алсын хараа, эрхэм зорилгод хүрэхэд баримтлан ажиллах зарчмаа{" "}
        </span>
        <span id="tp_172" class="t s3_172">
          тодорхойлох{" "}
        </span>
        <span id="tq_172" class="t s5_172">
          •{" "}
        </span>
        <span id="tr_172" class="t s3_172">
          Бодитой хэрэгжүүлэх зорилго, зорилт, арга замыг бий болгох{" "}
        </span>
        <span id="ts_172" class="t s5_172">
          •{" "}
        </span>
        <span id="tt_172" class="t s3_172">
          Хүний болон санхүүгийн нөөцийг үр дүнтэй хуваарилах{" "}
        </span>
        <span id="tu_172" class="t s5_172">
          •{" "}
        </span>
        <span id="tv_172" class="t s3_172">
          Шийдвэр гарах{" "}
        </span>
        <span id="tw_172" class="t s5_172">
          •{" "}
        </span>
        <span id="tx_172" class="t s3_172">
          Баг, хамт олны хүчийг нэгтгэх{" "}
        </span>
        <span id="ty_172" class="t s3_172">
          Сургуулийн удирдлага нь{" "}
        </span>
        <span id="tz_172" class="t s3_172">
          жил бүр сургалт, үйл ажиллагааг удирдан{" "}
        </span>
        <span id="t10_172" class="t s3_172">
          чиглүүлэх зорилгыг{" "}
        </span>
        <span id="t11_172" class="t s3_172">
          тодорхойлно.{" "}
        </span>
        <span id="t12_172" class="t s3_172">
          Зорилго нь:{" "}
        </span>
        <span id="t13_172" class="t s6_172">
          •{" "}
        </span>
        <span id="t14_172" class="t s3_172">
          Сургуулийн алсын хараа, эрхэм зорилготой нийцсэн байна.{" "}
        </span>
        <span id="t15_172" class="t s6_172">
          •{" "}
        </span>
        <span id="t16_172" class="t s3_172">
          Тодорхой, бодитой, хэмжигдэхүйц, хэрэгжихүйц, хугацааны хязгаартай{" "}
        </span>
        <span id="t17_172" class="t s3_172">
          байна.{" "}
        </span>
      </div>
    </div>
  );
}
