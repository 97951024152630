import React from "react";
export default function NinetySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_97{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_97{left:142px;bottom:941px;}
#t3_97{left:169px;bottom:941px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t4_97{left:142px;bottom:916px;}
#t5_97{left:169px;bottom:916px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_97{left:142px;bottom:892px;}
#t7_97{left:169px;bottom:892px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_97{left:142px;bottom:867px;}
#t9_97{left:169px;bottom:867px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ta_97{left:142px;bottom:843px;}
#tb_97{left:169px;bottom:843px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_97{left:142px;bottom:818px;}
#td_97{left:169px;bottom:818px;letter-spacing:-0.16px;word-spacing:0.05px;}
#te_97{left:87px;bottom:785px;letter-spacing:-0.18px;word-spacing:4.18px;}
#tf_97{left:87px;bottom:765px;letter-spacing:-0.14px;word-spacing:5.49px;}
#tg_97{left:87px;bottom:745px;letter-spacing:-0.15px;word-spacing:5.42px;}
#th_97{left:87px;bottom:725px;letter-spacing:-0.15px;word-spacing:0.27px;}
#ti_97{left:87px;bottom:705px;letter-spacing:-0.16px;word-spacing:-0.35px;}
#tj_97{left:87px;bottom:684px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tk_97{left:266px;bottom:635px;letter-spacing:0.1px;word-spacing:0.04px;}
#tl_97{left:91px;bottom:603px;letter-spacing:0.06px;word-spacing:0.07px;}
#tm_97{left:91px;bottom:577px;letter-spacing:0.07px;word-spacing:0.06px;}
#tn_97{left:118px;bottom:551px;}
#to_97{left:146px;bottom:551px;letter-spacing:0.09px;word-spacing:0.05px;}
#tp_97{left:118px;bottom:528px;}
#tq_97{left:146px;bottom:528px;letter-spacing:0.09px;word-spacing:2.77px;}
#tr_97{left:146px;bottom:510px;letter-spacing:0.1px;word-spacing:0.04px;}
#ts_97{left:118px;bottom:487px;}
#tt_97{left:146px;bottom:487px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_97{left:91px;bottom:448px;letter-spacing:0.1px;word-spacing:0.03px;}
#tv_97{left:91px;bottom:422px;letter-spacing:0.12px;word-spacing:0.03px;}
#tw_97{left:118px;bottom:396px;}
#tx_97{left:146px;bottom:396px;letter-spacing:0.05px;word-spacing:0.08px;}
#ty_97{left:118px;bottom:374px;}
#tz_97{left:146px;bottom:374px;letter-spacing:0.1px;word-spacing:2.45px;}
#t10_97{left:146px;bottom:355px;letter-spacing:0.09px;}
#t11_97{left:118px;bottom:333px;}
#t12_97{left:146px;bottom:333px;letter-spacing:0.09px;word-spacing:0.04px;}
#t13_97{left:118px;bottom:310px;}
#t14_97{left:146px;bottom:310px;letter-spacing:0.07px;word-spacing:0.06px;}
#t15_97{left:118px;bottom:287px;}
#t16_97{left:146px;bottom:287px;letter-spacing:0.1px;word-spacing:4.83px;}
#t17_97{left:146px;bottom:269px;letter-spacing:0.06px;word-spacing:0.07px;}
#t18_97{left:91px;bottom:239px;letter-spacing:0.11px;word-spacing:0.01px;}
#t19_97{left:118px;bottom:213px;}
#t1a_97{left:146px;bottom:213px;letter-spacing:0.08px;word-spacing:7.47px;}
#t1b_97{left:146px;bottom:195px;letter-spacing:0.08px;word-spacing:0.03px;}
#t1c_97{left:146px;bottom:176px;letter-spacing:0.1px;word-spacing:8.34px;}
#t1d_97{left:146px;bottom:158px;letter-spacing:0.11px;word-spacing:3.43px;}
#t1e_97{left:146px;bottom:140px;letter-spacing:0.12px;word-spacing:4.84px;}
#t1f_97{left:146px;bottom:121px;letter-spacing:0.08px;word-spacing:0.06px;}

.s0_97{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_97{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s2_97{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_97{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_97{font-size:15px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts97" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg97" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="97/97.svg"
          type="image/svg+xml"
          id="pdf97"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_97" class="t s0_97">
          97{" "}
        </span>
        <span id="t2_97" class="t s1_97">
          •{" "}
        </span>
        <span id="t3_97" class="t s2_97">
          Иргэний ёс зүй, үнэт зүйл{" "}
        </span>
        <span id="t4_97" class="t s1_97">
          •{" "}
        </span>
        <span id="t5_97" class="t s2_97">
          Эрүүл мэнд сайн сайхан байдал{" "}
        </span>
        <span id="t6_97" class="t s1_97">
          •{" "}
        </span>
        <span id="t7_97" class="t s2_97">
          Соёлын олон янз байдал{" "}
        </span>
        <span id="t8_97" class="t s1_97">
          •{" "}
        </span>
        <span id="t9_97" class="t s2_97">
          Мэдээлэл харилцаа технологийн хэрэглээний боловсрол{" "}
        </span>
        <span id="ta_97" class="t s1_97">
          •{" "}
        </span>
        <span id="tb_97" class="t s2_97">
          Жендерийн эрх тэгш байдал{" "}
        </span>
        <span id="tc_97" class="t s1_97">
          •{" "}
        </span>
        <span id="td_97" class="t s2_97">
          Ажил мэргэжлийн чиг баримжаа олгох боловсрол{" "}
        </span>
        <span id="te_97" class="t s2_97">
          Судлагдахуунуудын нийтлэг сэдэв, үзэгдэлд тулгуурлан хоёр болон хэд{" "}
        </span>
        <span id="tf_97" class="t s2_97">
          хэдэн хичээлийн агуулгыг интеграцчилан боловсруулж, хэрэгжүүлэхийн{" "}
        </span>
        <span id="tg_97" class="t s2_97">
          тулд өөр өөр судлагдахууны багш нар төсөлд суурилсан суралцахуйн{" "}
        </span>
        <span id="th_97" class="t s2_97">
          загвараар хамтарсан хичээл зохион байгуулах боломжтой. Түүнчлэн бусад{" "}
        </span>
        <span id="ti_97" class="t s2_97">
          судлагдахуунаар юу сурч, эзэмшсэн болохыг мэдэхийн тулд судлагдахууны{" "}
        </span>
        <span id="tj_97" class="t s2_97">
          агуулгын залгамж холбоог боловсруулж ашиглаж болно.{" "}
        </span>
        <span id="tk_97" class="t s3_97">
          Хүснэгт 3.4. Судлагдахуун хоорондын агуулгын хамаарал{" "}
        </span>
        <span id="tl_97" class="t s3_97">
          Газар зүй, XII анги{" "}
        </span>
        <span id="tm_97" class="t s3_97">
          Хотын төлөвлөлт{" "}
        </span>
        <span id="tn_97" class="t s4_97">
          •{" "}
        </span>
        <span id="to_97" class="t s3_97">
          Хөгжингүй ба хөгжиж буй орнуудын хот төлөвлөлтийн ялгааг харьцуулах;{" "}
        </span>
        <span id="tp_97" class="t s4_97">
          •{" "}
        </span>
        <span id="tq_97" class="t s3_97">
          Хот байгуулалтын хэлбэр (төвлөрсөн, радиус, олон цөмт)-ийг тодорхой{" "}
        </span>
        <span id="tr_97" class="t s3_97">
          жишээн дээр тайлбарлах;{" "}
        </span>
        <span id="ts_97" class="t s4_97">
          •{" "}
        </span>
        <span id="tt_97" class="t s3_97">
          Хотын дахин төлөвлөлтийн загвар боловсруулах;{" "}
        </span>
        <span id="tu_97" class="t s3_97">
          Дизайн технологи, XII анги{" "}
        </span>
        <span id="tv_97" class="t s3_97">
          Экстерьерийн төсөл боловсруулах{" "}
        </span>
        <span id="tw_97" class="t s4_97">
          •{" "}
        </span>
        <span id="tx_97" class="t s3_97">
          Тойм зураг зурах арга техникт суралцах;{" "}
        </span>
        <span id="ty_97" class="t s4_97">
          •{" "}
        </span>
        <span id="tz_97" class="t s3_97">
          Сонгосон объектын гадна талбайн план зурагт тохижилтын зохиомжийг{" "}
        </span>
        <span id="t10_97" class="t s3_97">
          хайх;{" "}
        </span>
        <span id="t11_97" class="t s4_97">
          •{" "}
        </span>
        <span id="t12_97" class="t s3_97">
          Зураасны техник ажиллагаагаар зохиомж, өнгө, гэрэл, сүүдрийг зурах;{" "}
        </span>
        <span id="t13_97" class="t s4_97">
          •{" "}
        </span>
        <span id="t14_97" class="t s3_97">
          Хүн ба хүрээлэн буй орчинг зурах;{" "}
        </span>
        <span id="t15_97" class="t s4_97">
          •{" "}
        </span>
        <span id="t16_97" class="t s3_97">
          Мод ургамал, автомашин, тавилга эдлэл, материалын шинж чанарыг{" "}
        </span>
        <span id="t17_97" class="t s3_97">
          ялган зурах;{" "}
        </span>
        <span id="t18_97" class="t s3_97">
          “Биологи” болон “Физик”, XII анги{" "}
        </span>
        <span id="t19_97" class="t s4_97">
          •{" "}
        </span>
        <span id="t1a_97" class="t s3_97">
          Агуулгын хувьд санал болгож буй нэгж хичээлтэй шууд холбогдох{" "}
        </span>
        <span id="t1b_97" class="t s3_97">
          суралцахуйн зорилт байхгүй хэдий ч өмнөх ангиудад судалсан агуулгатай{" "}
        </span>
        <span id="t1c_97" class="t s3_97">
          холбож болно. Тухайлбал, биологийн хичээлийн хувьд “хүний үйл{" "}
        </span>
        <span id="t1d_97" class="t s3_97">
          ажиллагаа амьдрах орчны доройтолд нөлөөлж буйг илрүүлэх” талаас,{" "}
        </span>
        <span id="t1e_97" class="t s3_97">
          физикийн хичээлийн хувьд “сэргээгдэх нөөцөөр эрчим хүчийг хангах”{" "}
        </span>
        <span id="t1f_97" class="t s3_97">
          талаас судалж болно.{" "}
        </span>
      </div>
    </div>
  );
}
