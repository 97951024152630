import React from "react";
export default function EightyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_89{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_89{left:87px;bottom:941px;letter-spacing:-0.14px;word-spacing:8.1px;}
#t3_89{left:87px;bottom:921px;letter-spacing:-0.17px;word-spacing:9.62px;}
#t4_89{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:0.56px;}
#t5_89{left:87px;bottom:880px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t6_89{left:87px;bottom:847px;letter-spacing:-0.12px;word-spacing:4.32px;}
#t7_89{left:87px;bottom:827px;letter-spacing:-0.17px;word-spacing:7.62px;}
#t8_89{left:87px;bottom:807px;letter-spacing:-0.12px;word-spacing:0.59px;}
#t9_89{left:87px;bottom:787px;letter-spacing:-0.13px;word-spacing:3.73px;}
#ta_89{left:87px;bottom:767px;letter-spacing:-0.14px;word-spacing:7.79px;}
#tb_89{left:87px;bottom:746px;letter-spacing:-0.13px;word-spacing:0.43px;}
#tc_89{left:87px;bottom:726px;letter-spacing:-0.15px;word-spacing:4.22px;}
#td_89{left:87px;bottom:706px;letter-spacing:-0.14px;word-spacing:1.28px;}
#te_89{left:87px;bottom:686px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tf_89{left:138px;bottom:632px;letter-spacing:0.14px;word-spacing:0.06px;}
#tg_89{left:116px;bottom:610px;letter-spacing:0.11px;word-spacing:0.1px;}
#th_89{left:311px;bottom:588px;letter-spacing:0.23px;}
#ti_89{left:87px;bottom:548px;letter-spacing:-0.65px;word-spacing:-0.97px;}
#tj_89{left:87px;bottom:528px;letter-spacing:-0.69px;word-spacing:0.09px;}
#tk_89{left:87px;bottom:495px;letter-spacing:-0.16px;word-spacing:2.07px;}
#tl_89{left:87px;bottom:475px;letter-spacing:-0.17px;word-spacing:7.55px;}
#tm_89{left:87px;bottom:455px;letter-spacing:-0.14px;word-spacing:-0.49px;}
#tn_89{left:87px;bottom:434px;letter-spacing:-0.09px;}
#to_89{left:97px;bottom:406px;}
#tp_89{left:125px;bottom:406px;letter-spacing:-0.16px;word-spacing:6.1px;}
#tq_89{left:125px;bottom:385px;letter-spacing:-0.14px;word-spacing:10.42px;}
#tr_89{left:125px;bottom:365px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ts_89{left:97px;bottom:341px;}
#tt_89{left:125px;bottom:341px;letter-spacing:-0.12px;word-spacing:1.69px;}
#tu_89{left:125px;bottom:321px;letter-spacing:-0.19px;}
#tv_89{left:97px;bottom:297px;}
#tw_89{left:125px;bottom:296px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tx_89{left:97px;bottom:272px;}
#ty_89{left:125px;bottom:272px;letter-spacing:-0.11px;word-spacing:2.81px;}
#tz_89{left:125px;bottom:251px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t10_89{left:97px;bottom:228px;}
#t11_89{left:125px;bottom:227px;letter-spacing:-0.13px;word-spacing:2.82px;}
#t12_89{left:125px;bottom:207px;letter-spacing:-0.16px;word-spacing:4.41px;}
#t13_89{left:125px;bottom:187px;letter-spacing:-0.4px;}
#t14_89{left:87px;bottom:166px;letter-spacing:-0.15px;word-spacing:-1.88px;}
#t15_89{left:87px;bottom:146px;letter-spacing:-0.11px;word-spacing:0.81px;}
#t16_89{left:204px;bottom:146px;letter-spacing:-0.14px;word-spacing:0.82px;}
#t17_89{left:87px;bottom:126px;letter-spacing:-0.19px;word-spacing:0.03px;}

.s0_89{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_89{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_89{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_89{font-size:15px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts89" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg89" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="89/89.svg"
          type="image/svg+xml"
          id="pdf89"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_89" class="t s0_89">
          89{" "}
        </span>
        <span id="t2_89" class="t s1_89">
          Нийгмийн ажилтан нь боловсролын салбарын болон хүүхэд хөгжил,{" "}
        </span>
        <span id="t3_89" class="t s1_89">
          хамгааллын чиглэлээр баримталж буй хууль тогтоомж, шийдвэрийн{" "}
        </span>
        <span id="t4_89" class="t s1_89">
          хэрэгжилтийг сургуулийн хэмжээнд төлөвлөх, зохион байгуулах тайлагнах,{" "}
        </span>
        <span id="t5_89" class="t s1_89">
          үнэлэх ажлыг удирдан зохион байгуулна.{" "}
        </span>
        <span id="t6_89" class="t s1_89">
          Сургуулийн сэтгэл зүйчийн үндсэн чиг үүрэг нь хүүхэд хөгжил, түүнтэй{" "}
        </span>
        <span id="t7_89" class="t s1_89">
          холбоотой оношилгоо хийх, багш, ажилтан, удирдлагын багт сургалт{" "}
        </span>
        <span id="t8_89" class="t s1_89">
          хөгжлийн сэтгэл зүйн талын соён гэгээрүүлэх үйл ажиллагаа явуулах,
          эцэг{" "}
        </span>
        <span id="t9_89" class="t s1_89">
          эх, асран хамгаалагч, харгалзан дэмжигчдэд хүүхдийн хөгжлийн талаар{" "}
        </span>
        <span id="ta_89" class="t s1_89">
          зөвлөн туслах, багш, ажилтанд хүүхэд хөгжлийн асуудлаар тусламж{" "}
        </span>
        <span id="tb_89" class="t s1_89">
          үзүүлэх, хүүхдэд сэтгэл зүйн үйлчилгээ хүргэх гэх мэт үүрэгтэй.
          Сурагчдын{" "}
        </span>
        <span id="tc_89" class="t s1_89">
          сургалт хөгжлийн үйл ажиллагаанд үүсэж байгаа сэтгэл зүйн бэрхшээл{" "}
        </span>
        <span id="td_89" class="t s1_89">
          болон төлөвшил, харилцаа, зан үйлийн доголдолтой уялдаатай цаг үеийн{" "}
        </span>
        <span id="te_89" class="t s1_89">
          асуудлыг шийдвэрлэхэд тэрээр голлож ажиллана.{" "}
        </span>
        <span id="tf_89" class="t s2_89">
          3.1.3 УДИРДАХ АЖИЛТНЫ ХАРИЛЦААНЫ УР ЧАДВАР{" "}
        </span>
        <span id="tg_89" class="t s2_89">
          СУРАГЧИЙН ХӨГЖИЛ, ХАМГААЛЛЫН ҮЙЛ АЖИЛЛАГААНД{" "}
        </span>
        <span id="th_89" class="t s2_89">
          НӨЛӨӨЛӨХ НЬ{" "}
        </span>
        <span id="ti_89" class="t s1_89">
          Сургуулийн үйл ажиллагааны үр ашиг буюу зорилгын биелэлтэд уг
          байгууллагын{" "}
        </span>
        <span id="tj_89" class="t s1_89">
          хамт олны сэтгэл зүйн уур амьсгал шийдвэрлэх нөлөөтэй байдаг.{" "}
        </span>
        <span id="tk_89" class="t s1_89">
          Сургуулийн сэтгэл зүйн уур амьсгал нь удирдлага, багш, ажилтан, сурагч{" "}
        </span>
        <span id="tl_89" class="t s1_89">
          хоорондын харилцан үйлчлэл, хамтын ажиллагааны нэгдмэл байдал,{" "}
        </span>
        <span id="tm_89" class="t s1_89">
          сэтгэл ханамж юм. Иймд харилцааны уур амьсгал нь хэд хэдэн үзүүлэлтээр{" "}
        </span>
        <span id="tn_89" class="t s1_89">
          илэрнэ. Үүнд:{" "}
        </span>
        <span id="to_89" class="t s3_89">
          -{" "}
        </span>
        <span id="tp_89" class="t s1_89">
          Удирдлага, багш, ажилтан, суралцагч, эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="tq_89" class="t s1_89">
          харгалзан дэмжигч, харилцан итгэлцэл, харилцан ажил хэрэгч{" "}
        </span>
        <span id="tr_89" class="t s1_89">
          шаардлага, шударга шүүмжлэл тавьдаг байх чадвар;{" "}
        </span>
        <span id="ts_89" class="t s3_89">
          -{" "}
        </span>
        <span id="tt_89" class="t s1_89">
          Багш, ажилтан, сурагчид үзэл бодол, саналаа чөлөөтэй илэрхийлдэг{" "}
        </span>
        <span id="tu_89" class="t s1_89">
          байх;{" "}
        </span>
        <span id="tv_89" class="t s3_89">
          -{" "}
        </span>
        <span id="tw_89" class="t s1_89">
          Багш, ажилтан, сурагч сургуульдаа сэтгэл хангалуун байх;{" "}
        </span>
        <span id="tx_89" class="t s3_89">
          -{" "}
        </span>
        <span id="ty_89" class="t s1_89">
          Багш, ажилтан, сурагчид харилцан бие биеэ дэмжих туслах чадвар{" "}
        </span>
        <span id="tz_89" class="t s1_89">
          сайтай байх;{" "}
        </span>
        <span id="t10_89" class="t s3_89">
          -{" "}
        </span>
        <span id="t11_89" class="t s1_89">
          Багш, ажилтан, сурагч, эцэг эх, асран хамгаалагчид сургуулийн үйл{" "}
        </span>
        <span id="t12_89" class="t s1_89">
          ажиллагааны талаар хангалттай мэдээлэл олж авдаг байх зэргээр{" "}
        </span>
        <span id="t13_89" class="t s1_89">
          илэрдэг.{" "}
        </span>
        <span id="t14_89" class="t s1_89">
          Удирдлага ба багш ажилтан хоорондын эерэг хүндэтгэлтэй харилцааны соёл{" "}
        </span>
        <span id="t15_89" class="t s1_89">
          нь нэг талаас{" "}
        </span>
        <span id="t16_89" class="t s1_89">
          сургуулийн орчин дахь сурагчийн хөгжил хамгааллын суурь{" "}
        </span>
        <span id="t17_89" class="t s1_89">
          болдог. Хэрэв багш, ажилтан удирдлагын зүгээс хүндэтгэлгүй, шударга
          бус,{" "}
        </span>
      </div>
    </div>
  );
}
