import React from "react";
export default function HundredSeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_107{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_107{left:87px;bottom:941px;letter-spacing:-0.1px;word-spacing:-2.5px;}
#t3_107{left:163px;bottom:941px;letter-spacing:-0.14px;word-spacing:-2.47px;}
#t4_107{left:87px;bottom:908px;letter-spacing:-0.14px;word-spacing:3.19px;}
#t5_107{left:388px;bottom:908px;letter-spacing:-0.14px;word-spacing:3.18px;}
#t6_107{left:87px;bottom:888px;letter-spacing:-0.15px;word-spacing:1.08px;}
#t7_107{left:87px;bottom:867px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_107{left:87px;bottom:834px;letter-spacing:-0.11px;word-spacing:-1.44px;}
#t9_107{left:87px;bottom:814px;letter-spacing:-0.16px;word-spacing:5.62px;}
#ta_107{left:87px;bottom:794px;letter-spacing:-0.16px;word-spacing:5px;}
#tb_107{left:87px;bottom:774px;letter-spacing:-0.16px;word-spacing:11.32px;}
#tc_107{left:87px;bottom:754px;letter-spacing:-0.14px;word-spacing:3.49px;}
#td_107{left:87px;bottom:733px;letter-spacing:-0.15px;word-spacing:-0.4px;}
#te_107{left:87px;bottom:713px;letter-spacing:-0.15px;word-spacing:1.72px;}
#tf_107{left:87px;bottom:693px;letter-spacing:-0.18px;word-spacing:-0.81px;}
#tg_107{left:87px;bottom:673px;letter-spacing:-0.1px;word-spacing:0.01px;}
#th_107{left:87px;bottom:640px;letter-spacing:-0.15px;word-spacing:6.76px;}
#ti_107{left:87px;bottom:620px;letter-spacing:-0.14px;word-spacing:3.95px;}
#tj_107{left:87px;bottom:599px;letter-spacing:-0.09px;}
#tk_107{left:301px;bottom:599px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_107{left:121px;bottom:564px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tm_107{left:333px;bottom:548px;letter-spacing:-0.19px;word-spacing:0.05px;}
#tn_107{left:95px;bottom:430px;letter-spacing:0.06px;word-spacing:0.05px;}
#to_107{left:208px;bottom:430px;letter-spacing:0.06px;}
#tp_107{left:310px;bottom:430px;letter-spacing:0.12px;}
#tq_107{left:349px;bottom:430px;letter-spacing:0.06px;}
#tr_107{left:475px;bottom:430px;letter-spacing:0.05px;word-spacing:0.06px;}
#ts_107{left:603px;bottom:430px;letter-spacing:0.06px;}
#tt_107{left:87px;bottom:151px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tu_107{left:98px;bottom:130px;}
#tv_107{left:125px;bottom:130px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_107{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_107{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s2_107{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_107{font-size:17px;font-family:MogulArial-Italic_17h;color:#4472C4;}
.s4_107{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_107{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s6_107{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts107" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg107" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="107/107.svg"
          type="image/svg+xml"
          id="pdf107"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_107" class="t s0_107">
          107{" "}
        </span>
        <span id="t2_107" class="t s1_107">
          Жишээ 3.{" "}
        </span>
        <span id="t3_107" class="t s2_107">
          Сургуулийн зорилго, зорилттой уялдсан ангийн нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="t4_107" class="t s2_107">
          5 дугаар ангийн нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="t5_107" class="t s3_107">
          (Тухайн ангид ордог бүх хичээлийг{" "}
        </span>
        <span id="t6_107" class="t s3_107">
          нэгтгэж уялдаа холбоог харуулна. Ингэхдээ судлагдахууныг нэгж хичээл{" "}
        </span>
        <span id="t7_107" class="t s3_107">
          бүрээр нэгтгэж болно).{" "}
        </span>
        <span id="t8_107" class="t s2_107">
          Энэхүү төлөвлөгөөнд тухайн ангийн (5-р анги) бүх хичээлийг нэгтгэж,
          уялдаа{" "}
        </span>
        <span id="t9_107" class="t s2_107">
          холбоог тэмдэглэнэ. 5-р ангид тулгамдаж буй асуудлыг шийдвэрлэхэд{" "}
        </span>
        <span id="ta_107" class="t s2_107">
          (ерөнхий асуудал- ерөнхий мэдлэг, чадвар, хандлага) дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tb_107" class="t s2_107">
          хичээлүүдийг (давтамжийг тооцох) сумаар холбох, тэмдэгт юм уу{" "}
        </span>
        <span id="tc_107" class="t s2_107">
          өнгөөр ялгаж тэмдэглэнэ. Ингэснээр сурагчдад тулгамдаж буй асуудлыг{" "}
        </span>
        <span id="td_107" class="t s2_107">
          шийдвэрлэхэд хувь нэмэр оруулах хичээл болон давтамжийг харах боломж{" "}
        </span>
        <span id="te_107" class="t s2_107">
          бүрдэнэ. Тэрхүү тулгамдаж буй асуудал нь сургуулийн зорилго зорилттой{" "}
        </span>
        <span id="tf_107" class="t s2_107">
          уялдаатай байх тул зорилго болон зорилтуудтай холбогдож буй хичээл
          2-ын{" "}
        </span>
        <span id="tg_107" class="t s2_107">
          өнгө, тэмдэгт ижил байх хэрэгтэй.{" "}
        </span>
        <span id="th_107" class="t s2_107">
          Ингэснээр 1-р зорилтын биелэлтэд хувь нэмэр оруулах хичээлүүдийг{" "}
        </span>
        <span id="ti_107" class="t s2_107">
          ялгаж харж чадна. Эдгээр хичээлийн явцаар сургуулийн зорилт бүрийн{" "}
        </span>
        <span id="tj_107" class="t s2_107">
          хэрэгжилтийн явц, үр дүнг{" "}
        </span>
        <span id="tk_107" class="t s2_107">
          улирал жилээр хянах, үнэлэх боломж бүрдэнэ.{" "}
        </span>
        <span id="tl_107" class="t s4_107">
          Зураг 3.13. Хичээлийн явцаар сургууийн зорилт бүрийн хэрэгжилт, үр
          дүнг хянах,{" "}
        </span>
        <span id="tm_107" class="t s4_107">
          үнэлэх боломж{" "}
        </span>
        <span id="tn_107" class="t s5_107">
          Сургуулийн зорилго
        </span>
        <span id="to_107" class="t s5_107">
          .................{" "}
        </span>
        <span id="tp_107" class="t s5_107">
          Зорилт{" "}
        </span>
        <span id="tq_107" class="t s5_107">
          ....................{" "}
        </span>
        <span id="tr_107" class="t s5_107">
          Судлагдахууны зорилт{" "}
        </span>
        <span id="ts_107" class="t s5_107">
          ..................{" "}
        </span>
        <span id="tt_107" class="t s2_107">
          Тус төлөвлөлтөд тусгах зүйл:{" "}
        </span>
        <span id="tu_107" class="t s6_107">
          •{" "}
        </span>
        <span id="tv_107" class="t s2_107">
          Тухайн ангийн зорилго зорилт- жилийн дараах хүрэх үр дүн{" "}
        </span>
      </div>
    </div>
  );
}
