import React from "react";
export default function NinetyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_93{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_93{left:137px;bottom:945px;letter-spacing:-0.2px;word-spacing:0.07px;}
#t3_93{left:87px;bottom:439px;letter-spacing:-0.15px;word-spacing:1.85px;}
#t4_93{left:87px;bottom:419px;letter-spacing:-0.14px;word-spacing:1.44px;}
#t5_93{left:87px;bottom:399px;letter-spacing:-0.15px;word-spacing:1.5px;}
#t6_93{left:87px;bottom:378px;letter-spacing:-0.17px;word-spacing:7.22px;}
#t7_93{left:87px;bottom:358px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t8_93{left:195px;bottom:333px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t9_93{left:264px;bottom:173px;letter-spacing:-0.09px;word-spacing:0.16px;}
#ta_93{left:264px;bottom:161px;letter-spacing:-0.07px;word-spacing:0.01px;}
#tb_93{left:264px;bottom:148px;letter-spacing:-0.09px;}
#tc_93{left:329px;bottom:148px;letter-spacing:-0.15px;}
#td_93{left:264px;bottom:136px;letter-spacing:-0.09px;word-spacing:0.15px;}
#te_93{left:264px;bottom:124px;letter-spacing:-0.09px;}
#tf_93{left:304px;bottom:124px;letter-spacing:-0.08px;}
#tg_93{left:154px;bottom:156px;letter-spacing:-0.08px;word-spacing:0.02px;}
#th_93{left:154px;bottom:144px;letter-spacing:-0.09px;word-spacing:0.1px;}
#ti_93{left:154px;bottom:132px;letter-spacing:-0.09px;word-spacing:0.11px;}
#tj_93{left:154px;bottom:120px;letter-spacing:-0.09px;}
#tk_93{left:387px;bottom:212px;letter-spacing:-0.1px;}
#tl_93{left:433px;bottom:212px;letter-spacing:-0.1px;word-spacing:0.13px;}
#tm_93{left:387px;bottom:199px;letter-spacing:-0.08px;}
#tn_93{left:387px;bottom:187px;letter-spacing:-0.08px;}
#to_93{left:387px;bottom:175px;letter-spacing:-0.08px;word-spacing:0.05px;}
#tp_93{left:387px;bottom:163px;letter-spacing:-0.08px;word-spacing:0.01px;}
#tq_93{left:387px;bottom:150px;letter-spacing:-0.09px;word-spacing:0.03px;}
#tr_93{left:387px;bottom:138px;letter-spacing:-0.08px;word-spacing:0.11px;}
#ts_93{left:387px;bottom:126px;letter-spacing:-0.08px;}
#tt_93{left:509px;bottom:235px;letter-spacing:-0.08px;word-spacing:0.06px;}
#tu_93{left:509px;bottom:223px;letter-spacing:-0.07px;}
#tv_93{left:509px;bottom:211px;letter-spacing:-0.08px;word-spacing:0.03px;}
#tw_93{left:509px;bottom:199px;letter-spacing:-0.07px;word-spacing:0.05px;}
#tx_93{left:509px;bottom:186px;letter-spacing:-0.08px;word-spacing:0.08px;}
#ty_93{left:509px;bottom:174px;letter-spacing:-0.08px;word-spacing:0.05px;}
#tz_93{left:509px;bottom:162px;letter-spacing:-0.08px;word-spacing:0.06px;}
#t10_93{left:509px;bottom:150px;letter-spacing:-0.08px;word-spacing:0.05px;}
#t11_93{left:396px;bottom:266px;letter-spacing:-0.07px;}
#t12_93{left:409px;bottom:254px;letter-spacing:-0.11px;}
#t13_93{left:513px;bottom:283px;letter-spacing:-0.09px;word-spacing:0.08px;}
#t14_93{left:283px;bottom:240px;letter-spacing:-0.06px;word-spacing:0.02px;}
#t15_93{left:271px;bottom:228px;letter-spacing:-0.07px;}
#t16_93{left:270px;bottom:216px;letter-spacing:-0.08px;word-spacing:0.02px;}
#t17_93{left:180px;bottom:221px;letter-spacing:-0.09px;}
#t18_93{left:162px;bottom:209px;letter-spacing:-0.07px;}
#t19_93{left:159px;bottom:196px;letter-spacing:-0.08px;word-spacing:0.01px;}

.s0_93{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_93{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_93{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_93{font-size:10px;font-family:Arial_o2;color:#000;}`}
      </style>

      <style id="fonts93" type="text/css">
        {`@font-face {
	font-family: Arial_o2;
	src: url("fonts/Arial_o2.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg93" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="93/93.svg"
          type="image/svg+xml"
          id="pdf93"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_93" class="t s0_93">
          93{" "}
        </span>
        <span id="t2_93" class="t s1_93">
          Зураг 3.6. Сурагчдад мэдлэг, чадвар олгох зохион байгуулалт ба
          менежмент{" "}
        </span>
        <span id="t3_93" class="t s2_93">
          Салбар хоорондын болон олон салбар дамнасан ойлголтууд нь шинжлэх{" "}
        </span>
        <span id="t4_93" class="t s2_93">
          ухааны салбаруудын хоорондын харилцаанд суурилдаг бөгөөд хичээлийн{" "}
        </span>
        <span id="t5_93" class="t s2_93">
          уялдаа холбооны талаарх ойлголтуудын ялгаа, түвшинг дараах байдлаар{" "}
        </span>
        <span id="t6_93" class="t s2_93">
          тайлбарлаж байна. Иймд сургуулийн удирдлагын баг энэхүү түвшинг{" "}
        </span>
        <span id="t7_93" class="t s2_93">
          баримтлахыг зөвлөж байна.{" "}
        </span>
        <span id="t8_93" class="t s1_93">
          Зураг 3.7. Хичээлийн уялдаа холбоо, интеграцийн түвшин{" "}
        </span>
        <span id="t9_93" class="t s3_93">
          Сурагчид нийтлэг{" "}
        </span>
        <span id="ta_93" class="t s3_93">
          сэдвийн хүрээнд{" "}
        </span>
        <span id="tb_93" class="t s3_93">
          судлагдахуун{" "}
        </span>
        <span id="tc_93" class="t s3_93">
          тус{" "}
        </span>
        <span id="td_93" class="t s3_93">
          бүрээр мэдлэг, ур{" "}
        </span>
        <span id="te_93" class="t s3_93">
          чадварт{" "}
        </span>
        <span id="tf_93" class="t s3_93">
          суралцдаг{" "}
        </span>
        <span id="tg_93" class="t s3_93">
          Сурагчид хичээл{" "}
        </span>
        <span id="th_93" class="t s3_93">
          тус бүрээр тусдаа{" "}
        </span>
        <span id="ti_93" class="t s3_93">
          мэдлэг, ур чадвар{" "}
        </span>
        <span id="tj_93" class="t s3_93">
          эзэмшдэг{" "}
        </span>
        <span id="tk_93" class="t s3_93">
          Сурагчид{" "}
        </span>
        <span id="tl_93" class="t s3_93">
          мэдлэг, ур{" "}
        </span>
        <span id="tm_93" class="t s3_93">
          чадвараа{" "}
        </span>
        <span id="tn_93" class="t s3_93">
          гүнзгийрүүлэхийн{" "}
        </span>
        <span id="to_93" class="t s3_93">
          тулд нягт уялдаатай{" "}
        </span>
        <span id="tp_93" class="t s3_93">
          хоёр ба түүнээс{" "}
        </span>
        <span id="tq_93" class="t s3_93">
          дээш судлагдууны{" "}
        </span>
        <span id="tr_93" class="t s3_93">
          мэдлэг, ур чадвар{" "}
        </span>
        <span id="ts_93" class="t s3_93">
          эзэмшдэг.{" "}
        </span>
        <span id="tt_93" class="t s3_93">
          Бодит асуудал, төсөл{" "}
        </span>
        <span id="tu_93" class="t s3_93">
          хэрэгжүүлэх замаар{" "}
        </span>
        <span id="tv_93" class="t s3_93">
          сурагчид хоёр ба{" "}
        </span>
        <span id="tw_93" class="t s3_93">
          түүнээс дээш{" "}
        </span>
        <span id="tx_93" class="t s3_93">
          чиглэлээр мэдлэг, ур{" "}
        </span>
        <span id="ty_93" class="t s3_93">
          чадвараа ашиглаж,{" "}
        </span>
        <span id="tz_93" class="t s3_93">
          суралцах туршлагыг{" "}
        </span>
        <span id="t10_93" class="t s3_93">
          бий болгодог.{" "}
        </span>
        <span id="t11_93" class="t s3_93">
          Судлагдахуун{" "}
        </span>
        <span id="t12_93" class="t s3_93">
          хооронд{" "}
        </span>
        <span id="t13_93" class="t s3_93">
          Салбар хоорондын{" "}
        </span>
        <span id="t14_93" class="t s3_93">
          Хэд хэдэн{" "}
        </span>
        <span id="t15_93" class="t s3_93">
          судлагдахууны{" "}
        </span>
        <span id="t16_93" class="t s3_93">
          нийтлэг агуулга{" "}
        </span>
        <span id="t17_93" class="t s3_93">
          Тухайн{" "}
        </span>
        <span id="t18_93" class="t s3_93">
          судлагдахууны{" "}
        </span>
        <span id="t19_93" class="t s3_93">
          агуулга хооронд{" "}
        </span>
      </div>
    </div>
  );
}
