import React from "react";
export default function SixtyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_63{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_63{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_63{left:87px;bottom:941px;letter-spacing:-0.05px;}
#t4_63{left:113px;bottom:941px;letter-spacing:-0.14px;word-spacing:-1.1px;}
#t5_63{left:113px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_63{left:113px;bottom:901px;letter-spacing:-0.05px;}
#t7_63{left:132px;bottom:901px;letter-spacing:-0.16px;word-spacing:-2.11px;}
#t8_63{left:139px;bottom:880px;letter-spacing:-0.14px;word-spacing:3.31px;}
#t9_63{left:139px;bottom:860px;letter-spacing:-0.09px;}
#ta_63{left:113px;bottom:840px;letter-spacing:-0.25px;word-spacing:7.17px;}
#tb_63{left:209px;bottom:840px;letter-spacing:-0.19px;}
#tc_63{left:349px;bottom:840px;letter-spacing:-0.09px;}
#td_63{left:412px;bottom:840px;letter-spacing:-0.18px;}
#te_63{left:490px;bottom:840px;letter-spacing:-0.09px;}
#tf_63{left:559px;bottom:840px;letter-spacing:-0.2px;}
#tg_63{left:139px;bottom:820px;letter-spacing:-0.15px;word-spacing:4.78px;}
#th_63{left:139px;bottom:800px;letter-spacing:-0.16px;word-spacing:7.09px;}
#ti_63{left:139px;bottom:780px;letter-spacing:-0.16px;word-spacing:1.37px;}
#tj_63{left:139px;bottom:759px;letter-spacing:-0.14px;word-spacing:-1.41px;}
#tk_63{left:139px;bottom:739px;letter-spacing:-0.15px;word-spacing:1.08px;}
#tl_63{left:139px;bottom:719px;letter-spacing:-0.13px;word-spacing:1.6px;}
#tm_63{left:139px;bottom:699px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tn_63{left:139px;bottom:679px;letter-spacing:-0.15px;word-spacing:-0.8px;}
#to_63{left:139px;bottom:659px;letter-spacing:-0.16px;}
#tp_63{left:257px;bottom:659px;letter-spacing:-0.1px;}
#tq_63{left:332px;bottom:659px;letter-spacing:-0.07px;}
#tr_63{left:367px;bottom:659px;letter-spacing:-0.1px;}
#ts_63{left:466px;bottom:659px;letter-spacing:-0.12px;}
#tt_63{left:567px;bottom:659px;letter-spacing:-0.16px;}
#tu_63{left:139px;bottom:638px;letter-spacing:-0.15px;word-spacing:3.98px;}
#tv_63{left:139px;bottom:618px;letter-spacing:-0.15px;word-spacing:6.02px;}
#tw_63{left:139px;bottom:598px;letter-spacing:-0.12px;word-spacing:5.95px;}
#tx_63{left:139px;bottom:578px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ty_63{left:139px;bottom:558px;letter-spacing:-0.15px;word-spacing:11.48px;}
#tz_63{left:139px;bottom:538px;letter-spacing:-0.16px;word-spacing:7.14px;}
#t10_63{left:139px;bottom:517px;letter-spacing:-0.13px;word-spacing:-0.96px;}
#t11_63{left:139px;bottom:497px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t12_63{left:139px;bottom:477px;letter-spacing:-0.16px;word-spacing:4.18px;}
#t13_63{left:139px;bottom:457px;letter-spacing:-0.14px;word-spacing:6.54px;}
#t14_63{left:139px;bottom:437px;letter-spacing:-0.15px;word-spacing:4.86px;}
#t15_63{left:139px;bottom:417px;letter-spacing:-0.15px;}
#t16_63{left:113px;bottom:396px;letter-spacing:-0.17px;word-spacing:2.85px;}
#t17_63{left:139px;bottom:376px;letter-spacing:-0.15px;word-spacing:4.24px;}
#t18_63{left:139px;bottom:356px;letter-spacing:-0.15px;word-spacing:0.22px;}
#t19_63{left:139px;bottom:336px;letter-spacing:-0.16px;word-spacing:5.75px;}
#t1a_63{left:139px;bottom:316px;letter-spacing:-0.14px;word-spacing:4.12px;}
#t1b_63{left:139px;bottom:296px;letter-spacing:-0.21px;word-spacing:0.12px;}
#t1c_63{left:87px;bottom:267px;letter-spacing:-0.13px;word-spacing:-1.04px;}
#t1d_63{left:87px;bottom:247px;letter-spacing:-0.15px;word-spacing:4.61px;}
#t1e_63{left:87px;bottom:226px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_63{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_63{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_63{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_63{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_63{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts63" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg63" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="63/63.svg"
          type="image/svg+xml"
          id="pdf63"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_63" class="t s1_63">
          63{" "}
        </span>
        <span id="t3_63" class="t s2_63">
          3.{" "}
        </span>
        <span id="t4_63" class="t s3_63">
          Судлагдахуун бүрийн хамгийн өндөр болон хамгийн доогуур үзүүлэлтийг{" "}
        </span>
        <span id="t5_63" class="t s3_63">
          нэг бүрчлэн шинжилнэ.{" "}
        </span>
        <span id="t6_63" class="t s3_63">
          а.{" "}
        </span>
        <span id="t7_63" class="t s3_63">
          Судлагдахууны гүйцэтгэлийг оноор нь харьцуулан ямар судлагдахууны{" "}
        </span>
        <span id="t8_63" class="t s3_63">
          хувьд хамгийн их болон хамгийн бага өсөлт гарсан, өөрчлөлтгүйг{" "}
        </span>
        <span id="t9_63" class="t s3_63">
          илрүүлнэ.{" "}
        </span>
        <span id="ta_63" class="t s3_63">
          б. Тухайн{" "}
        </span>
        <span id="tb_63" class="t s3_63">
          судлагдахууныг{" "}
        </span>
        <span id="tc_63" class="t s3_63">
          оноор{" "}
        </span>
        <span id="td_63" class="t s3_63">
          сонгоод{" "}
        </span>
        <span id="te_63" class="t s3_63">
          тухайн{" "}
        </span>
        <span id="tf_63" class="t s3_63">
          судлагдахууны{" "}
        </span>
        <span id="tg_63" class="t s3_63">
          хувьд боловсролын түвшнээр гүйцэтгэлийн дундаж ямар байгааг{" "}
        </span>
        <span id="th_63" class="t s3_63">
          улсын дундажтай харьцуулан харна. Жишээлбэл, А сургуулийн{" "}
        </span>
        <span id="ti_63" class="t s3_63">
          математикийн судлагдахууны хувьд 2023 оны байдлаар сургуулийн{" "}
        </span>
        <span id="tj_63" class="t s3_63">
          гүйцэтгэлийн дундаж түвшнээр авч үзвэл бүрэн дунд боловсролд 39.0{" "}
        </span>
        <span id="tk_63" class="t s3_63">
          буюу бага, суурь боловсролын түвшний үзүүлэлтээс доогуур байна.{" "}
        </span>
        <span id="tl_63" class="t s3_63">
          Үүнийг кластерын дундажтай харьцуулахад боловсролын түвшнээр{" "}
        </span>
        <span id="tm_63" class="t s3_63">
          кластерын дунджаас бага байна.{" "}
        </span>
        <span id="tn_63" class="t s3_63">
          А сургуулийн хувьд бага, суурь боловсролын 2023 оны математикийн{" "}
        </span>
        <span id="to_63" class="t s3_63">
          гүйцэтгэлийн{" "}
        </span>
        <span id="tp_63" class="t s3_63">
          дундаж{" "}
        </span>
        <span id="tq_63" class="t s3_63">
          нь{" "}
        </span>
        <span id="tr_63" class="t s3_63">
          кластерын{" "}
        </span>
        <span id="ts_63" class="t s3_63">
          дундажтай{" "}
        </span>
        <span id="tt_63" class="t s3_63">
          харьцуулахад{" "}
        </span>
        <span id="tu_63" class="t s3_63">
          анхаарал татахуйц доогуур байна. Бага боловсролын хувьд 13.6,{" "}
        </span>
        <span id="tv_63" class="t s3_63">
          суурь боловсролын хувьд 29.1-ээр бага байна. Тэгэхээр тухайн{" "}
        </span>
        <span id="tw_63" class="t s3_63">
          сургуулийн хувьд хамгийн түрүүн амжилтаа кластерын дунджид{" "}
        </span>
        <span id="tx_63" class="t s3_63">
          хэрхэн дөхүүлэх вэ гэдэг нь зорилго болно.{" "}
        </span>
        <span id="ty_63" class="t s3_63">
          Мөн бүрэн дунд боловсролын түвшинд биологи, англи хэл{" "}
        </span>
        <span id="tz_63" class="t s3_63">
          судлагдахуунаар сурагчдын гүйцэтгэл 2023 онд буурсан байна.{" "}
        </span>
        <span id="t10_63" class="t s3_63">
          Үүний шалтгаан юу болохыг сургуулийн өөрийн үнэлгээгээр илрүүлэх{" "}
        </span>
        <span id="t11_63" class="t s3_63">
          хэрэгцээ байна.{" "}
        </span>
        <span id="t12_63" class="t s3_63">
          Та бүхэн судлагдахуун бүрээр оноор нь харьцуулсан мэдээллийг{" "}
        </span>
        <span id="t13_63" class="t s3_63">
          татан авч шинжилгээ хийнэ. Excel файл дээр сургууль өөрийн{" "}
        </span>
        <span id="t14_63" class="t s3_63">
          холбогдох мэдээллийг нэмж, харьцуулалт шинжилгээ хийх бүрэн{" "}
        </span>
        <span id="t15_63" class="t s3_63">
          боломжтой.{" "}
        </span>
        <span id="t16_63" class="t s3_63">
          в. Тухайн судлагдахууныг оноор сонгоод тухайн судлагдахууны хувьд{" "}
        </span>
        <span id="t17_63" class="t s3_63">
          боловсролын түвшнээр гүйцэтгэлийн дундаж ямар байгааг улсын{" "}
        </span>
        <span id="t18_63" class="t s3_63">
          дундажтай харьцуулан харж болно. Жишээлбэл, А сургуулийн хувьд{" "}
        </span>
        <span id="t19_63" class="t s3_63">
          улсын дундажтай харьцуулахад бага боловсролын хувьд 14.4%,{" "}
        </span>
        <span id="t1a_63" class="t s3_63">
          суурь боловсролын хувьд 28.7%, бүрэн дунд боловсролын хувьд{" "}
        </span>
        <span id="t1b_63" class="t s3_63">
          11.1%-иар ялгаатай байна.{" "}
        </span>
        <span id="t1c_63" class="t s3_63">
          Цахим тоон тайлангийн системээс бичгийн шалгалтын үр дүнг судлагдахуун{" "}
        </span>
        <span id="t1d_63" class="t s3_63">
          бүрээр, агуулгаар, үнэлгээний зорилтоор харах боломжтой. Тус бүрийн{" "}
        </span>
        <span id="t1e_63" class="t s3_63">
          мэдээллийн цонхны харагдах байдлыг Зураг 2.14-2.16-д үзүүлэв.{" "}
        </span>
      </div>
    </div>
  );
}
