import React from "react";
export default function OneHundredSeventyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_173{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_173{left:142px;bottom:931px;}
#t3_173{left:139px;bottom:893px;letter-spacing:-0.16px;word-spacing:2.96px;}
#t4_173{left:139px;bottom:873px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t5_173{left:139px;bottom:848px;}
#t6_173{left:165px;bottom:848px;letter-spacing:-0.15px;word-spacing:8.52px;}
#t7_173{left:165px;bottom:828px;letter-spacing:-0.25px;word-spacing:0.15px;}
#t8_173{left:139px;bottom:804px;}
#t9_173{left:165px;bottom:804px;letter-spacing:-0.13px;word-spacing:0.21px;}
#ta_173{left:165px;bottom:783px;letter-spacing:-0.16px;word-spacing:8.78px;}
#tb_173{left:165px;bottom:763px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tc_173{left:350px;bottom:763px;letter-spacing:-0.2px;word-spacing:0.11px;}
#td_173{left:139px;bottom:739px;}
#te_173{left:165px;bottom:739px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tf_173{left:139px;bottom:714px;}
#tg_173{left:165px;bottom:714px;letter-spacing:-0.13px;word-spacing:-0.9px;}
#th_173{left:165px;bottom:694px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_173{left:139px;bottom:670px;}
#tj_173{left:165px;bottom:670px;letter-spacing:-0.15px;word-spacing:10.51px;}
#tk_173{left:165px;bottom:649px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tl_173{left:139px;bottom:625px;}
#tm_173{left:165px;bottom:625px;letter-spacing:-0.17px;}
#tn_173{left:255px;bottom:625px;letter-spacing:-0.13px;}
#to_173{left:370px;bottom:625px;letter-spacing:-0.09px;}
#tp_173{left:443px;bottom:625px;letter-spacing:-0.14px;}
#tq_173{left:500px;bottom:625px;letter-spacing:-0.19px;}
#tr_173{left:571px;bottom:625px;letter-spacing:-0.19px;}
#ts_173{left:165px;bottom:605px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tt_173{left:139px;bottom:580px;}
#tu_173{left:165px;bottom:580px;letter-spacing:-0.15px;word-spacing:1.88px;}
#tv_173{left:165px;bottom:560px;letter-spacing:-0.8px;word-spacing:-1.32px;}
#tw_173{left:139px;bottom:537px;}
#tx_173{left:165px;bottom:537px;letter-spacing:-0.13px;word-spacing:-0.55px;}
#ty_173{left:165px;bottom:519px;letter-spacing:-0.14px;word-spacing:10.16px;}
#tz_173{left:165px;bottom:501px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t10_173{left:349px;bottom:501px;}
#t11_173{left:147px;bottom:460px;letter-spacing:0.11px;word-spacing:0.12px;}
#t12_173{left:106px;bottom:414px;letter-spacing:0.11px;word-spacing:0.23px;}
#t13_173{left:106px;bottom:396px;letter-spacing:0.08px;word-spacing:0.06px;}
#t14_173{left:106px;bottom:369px;letter-spacing:0.1px;word-spacing:2.55px;}
#t15_173{left:106px;bottom:350px;letter-spacing:0.07px;word-spacing:4.45px;}
#t16_173{left:106px;bottom:332px;letter-spacing:0.08px;word-spacing:0.05px;}
#t17_173{left:106px;bottom:305px;letter-spacing:0.1px;word-spacing:5.28px;}
#t18_173{left:106px;bottom:287px;letter-spacing:0.1px;word-spacing:0.04px;}
#t19_173{left:106px;bottom:260px;letter-spacing:0.08px;word-spacing:0.99px;}
#t1a_173{left:106px;bottom:241px;letter-spacing:0.08px;word-spacing:-0.98px;}
#t1b_173{left:106px;bottom:223px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1c_173{left:106px;bottom:196px;letter-spacing:0.11px;word-spacing:4.13px;}
#t1d_173{left:106px;bottom:178px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1e_173{left:290px;bottom:144px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1f_173{left:378px;bottom:127px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_173{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_173{font-size:18px;font-family:Arial-BoldMT_1li;color:#ED7D31;}
.s2_173{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_173{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_173{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s5_173{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s6_173{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s7_173{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s8_173{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts173" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg173" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="173/173.svg"
          type="image/svg+xml"
          id="pdf173"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_173" class="t s0_173">
          173{" "}
        </span>
        <span id="t2_173" class="t s1_173">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_173" class="t s2_173">
          1, 2-р бүлэгт дурдагдсан агуулгын хүрээнд дараах асуудлууд гарч{" "}
        </span>
        <span id="t4_173" class="t s2_173">
          байна. Сургуулийн зорилго, үйл ажиллагааны төлөвлөлт хангалтгүй{" "}
        </span>
        <span id="t5_173" class="t s3_173">
          •{" "}
        </span>
        <span id="t6_173" class="t s2_173">
          Сургуулийн эрхэм зорилго, алсын хараа, үнэт зүйлс, уриа{" "}
        </span>
        <span id="t7_173" class="t s2_173">
          тодорхой бус{" "}
        </span>
        <span id="t8_173" class="t s3_173">
          •{" "}
        </span>
        <span id="t9_173" class="t s2_173">
          Сургуулийн үйл ажиллагааны болон менежментийн төлөвлөлтөд{" "}
        </span>
        <span id="ta_173" class="t s2_173">
          оролцогч талууд болох сурагч, эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="tb_173" class="t s2_173">
          харгалзан дэмжигчийн{" "}
        </span>
        <span id="tc_173" class="t s2_173">
          санал бага тусгагддаг{" "}
        </span>
        <span id="td_173" class="t s3_173">
          •{" "}
        </span>
        <span id="te_173" class="t s2_173">
          Уялдаа холбоогүй олон төлөвлөгөөтэй{" "}
        </span>
        <span id="tf_173" class="t s3_173">
          •{" "}
        </span>
        <span id="tg_173" class="t s2_173">
          Үйл ажиллагааны төлөвлөгөөг хэрэгжүүлэх, гүйцэтгэх ажилд багш{" "}
        </span>
        <span id="th_173" class="t s2_173">
          нарыг тэгш оролцуулдаггүй{" "}
        </span>
        <span id="ti_173" class="t s3_173">
          •{" "}
        </span>
        <span id="tj_173" class="t s2_173">
          Сургуулийн удирдлагын багийн бүтэц, ажлын хуваарилалт{" "}
        </span>
        <span id="tk_173" class="t s2_173">
          оновчгүй, нэгдсэн төлөвлөгөө байхгүй{" "}
        </span>
        <span id="tl_173" class="t s3_173">
          •{" "}
        </span>
        <span id="tm_173" class="t s2_173">
          Стратеги{" "}
        </span>
        <span id="tn_173" class="t s2_173">
          төлөвлөгөөг{" "}
        </span>
        <span id="to_173" class="t s2_173">
          зөвхөн{" "}
        </span>
        <span id="tp_173" class="t s2_173">
          дээд{" "}
        </span>
        <span id="tq_173" class="t s2_173">
          шатны{" "}
        </span>
        <span id="tr_173" class="t s2_173">
          байгууллагад{" "}
        </span>
        <span id="ts_173" class="t s2_173">
          харуулахаар хийдэг{" "}
        </span>
        <span id="tt_173" class="t s3_173">
          •{" "}
        </span>
        <span id="tu_173" class="t s2_173">
          Хичээлийн жилийн зорилго, зорилтыг тодорхойлохдоо оролцоог{" "}
        </span>
        <span id="tv_173" class="t s2_173">
          хангадаггүй, зөвхөн удирдлагын байр сууринаас төлөвлөн хэрэгжүүлдэг{" "}
        </span>
        <span id="tw_173" class="t s3_173">
          •{" "}
        </span>
        <span id="tx_173" class="t s4_173">
          Багш нарын дийлэнх нь ээлжит хичээл бүрийг багш төвтэй аргаар{" "}
        </span>
        <span id="ty_173" class="t s4_173">
          хэрэгжүүлж, багшийн үйл ажиллагаа давамгайлсан ээлжит{" "}
        </span>
        <span id="tz_173" class="t s4_173">
          хичээлийн хөтөлбөртэй
        </span>
        <span id="t10_173" class="t s5_173">
          .{" "}
        </span>
        <span id="t11_173" class="t s6_173">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t12_173" class="t s7_173">
          Жилийн үйл ажиллагааны төлөвлөлтөд анализ хийж, дүгнэх, ач холбогдлоор{" "}
        </span>
        <span id="t13_173" class="t s7_173">
          нь эрэмбэлэх, уялдаа холбоог илрүүлэх судалгаа хийв.{" "}
        </span>
        <span id="t14_173" class="t s7_173">
          Байгууллагын 2023 оны гүйцэтгэлийн төлөвлөгөөнд байгууллагын стратеги{" "}
        </span>
        <span id="t15_173" class="t s7_173">
          төлөвлөгөө болон үндэслэж байгаа бусад бодлогын баримт бичиг, хууль{" "}
        </span>
        <span id="t16_173" class="t s7_173">
          тогтоомжийг хэрэгжүүлэх хүрээнд 16 зорилт, 122 ажлыг тусгасан байна.{" "}
        </span>
        <span id="t17_173" class="t s7_173">
          Математик аргачлалаар тооцож үзэхэд нэг сард дунджаар 10, 7 хоногт{" "}
        </span>
        <span id="t18_173" class="t s7_173">
          дунджаар 2.5 ажил төлөвлөгдсөн байна.{" "}
        </span>
        <span id="t19_173" class="t s7_173">
          Ажлын ач холбогдол, оновчтой байдал чанар үр дүнгээр эрэмбэлэн
          нэгтгэж,{" "}
        </span>
        <span id="t1a_173" class="t s7_173">
          ачааллыг багасгах талаар захирлын зөвлөлөөр хэлэлцэн багцлахад 16
          зорилт{" "}
        </span>
        <span id="t1b_173" class="t s7_173">
          101 ажил болж төлөвлөгөөнд тодотгол хийхээр ажиллаж байна.{" "}
        </span>
        <span id="t1c_173" class="t s7_173">
          6 дугаар сарын 10 – ны үеэр гүйцэтгэлийн төлөвлөгөөний хагас жилийн{" "}
        </span>
        <span id="t1d_173" class="t s7_173">
          биелэлтийг тооцож гаргахаар ажиллаж байна.{" "}
        </span>
        <span id="t1e_173" class="t s8_173">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="t1f_173" class="t s8_173">
          менежментийг сайжруулах ажлын хүрээнд{" "}
        </span>
      </div>
    </div>
  );
}
