import React from "react";
export default function ThirtySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_36{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_36{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_36{left:269px;bottom:945px;letter-spacing:-0.24px;word-spacing:0.12px;}
#t4_36{left:87px;bottom:509px;letter-spacing:-0.13px;word-spacing:9.25px;}
#t5_36{left:87px;bottom:489px;letter-spacing:-0.26px;}
#t6_36{left:91px;bottom:465px;}
#t7_36{left:117px;bottom:465px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t8_36{left:117px;bottom:445px;letter-spacing:-0.11px;word-spacing:0.68px;}
#t9_36{left:117px;bottom:424px;letter-spacing:-0.11px;word-spacing:0.01px;}
#ta_36{left:91px;bottom:396px;}
#tb_36{left:117px;bottom:396px;letter-spacing:-0.15px;word-spacing:2.08px;}
#tc_36{left:117px;bottom:375px;letter-spacing:-0.13px;word-spacing:-1.57px;}
#td_36{left:117px;bottom:355px;letter-spacing:-0.16px;word-spacing:4.29px;}
#te_36{left:117px;bottom:335px;letter-spacing:-0.14px;word-spacing:2.58px;}
#tf_36{left:117px;bottom:315px;letter-spacing:-0.13px;word-spacing:-1.49px;}
#tg_36{left:117px;bottom:295px;letter-spacing:-0.16px;word-spacing:4.22px;}
#th_36{left:117px;bottom:275px;letter-spacing:-0.13px;word-spacing:3.6px;}
#ti_36{left:117px;bottom:254px;letter-spacing:-0.15px;word-spacing:1.42px;}
#tj_36{left:117px;bottom:234px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_36{left:91px;bottom:205px;}
#tl_36{left:117px;bottom:205px;letter-spacing:-0.32px;word-spacing:3.91px;}
#tm_36{left:117px;bottom:185px;letter-spacing:-0.33px;word-spacing:2.67px;}
#tn_36{left:117px;bottom:165px;letter-spacing:-0.3px;word-spacing:4.99px;}
#to_36{left:117px;bottom:145px;letter-spacing:-0.32px;word-spacing:-0.19px;}
#tp_36{left:117px;bottom:125px;letter-spacing:-0.33px;}

.s0_36{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_36{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_36{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_36{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts36" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg36" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="36/36.svg"
          type="image/svg+xml"
          id="pdf36"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_36" class="t s1_36">
          36{" "}
        </span>
        <span id="t3_36" class="t s2_36">
          Зураг 1.9. Талуудын үүрэг, оролцоо{" "}
        </span>
        <span id="t4_36" class="t s3_36">
          Зурагт үзүүлсэн сумаар алхам, дараалал, чиглэлийг заасан болно.{" "}
        </span>
        <span id="t5_36" class="t s3_36">
          Тухайлбал,{" "}
        </span>
        <span id="t6_36" class="t s3_36">
          -{" "}
        </span>
        <span id="t7_36" class="t s3_36">
          Алхам 1. Асуудал илрүүлэх. Оролцогч талууд тус тусын дүн шинжилгээ{" "}
        </span>
        <span id="t8_36" class="t s3_36">
          хийх бөгөөд дараа нь нэгж дээр багцалж, сургуулийн түвшинд эцэслэх{" "}
        </span>
        <span id="t9_36" class="t s3_36">
          дарааллыг нэг чигийн хэвтээ сумаар дүрслэв.{" "}
        </span>
        <span id="ta_36" class="t s3_36">
          -{" "}
        </span>
        <span id="tb_36" class="t s3_36">
          Алхам 2. Зорилго, зорилт тодорхойлох. Эхлээд талууд шийдэл санал{" "}
        </span>
        <span id="tc_36" class="t s3_36">
          болгох, дараа нь нэгжийн түвшинд багцалж, сургуулийн түвшинд нэгдсэн{" "}
        </span>
        <span id="td_36" class="t s3_36">
          зорилго, зорилт болгон тодорхойлно. Үүний дараа буцах сумуудаар{" "}
        </span>
        <span id="te_36" class="t s3_36">
          нэгж, багш, анги, сурагч тус бүр зорилго зорилтоо сургуулийнхтайгаа{" "}
        </span>
        <span id="tf_36" class="t s3_36">
          уялдуулахыг хоёр чигийн хэвтээ сумаар үзүүлсэн болно. Өөрөөр хэлбэл,{" "}
        </span>
        <span id="tg_36" class="t s3_36">
          оролцогчоос гаргасан шийдэл шууд зорилго, зорилт болж чадахгүй,{" "}
        </span>
        <span id="th_36" class="t s3_36">
          хэлэлцэж, нэгтгэсний дараа тус бүртэй уялдуулна гэсэн үг юм. Мөн{" "}
        </span>
        <span id="ti_36" class="t s3_36">
          сургуулийн зорилго, зорилтыг шууд захирал, удирдлага эсвэл дээрээс{" "}
        </span>
        <span id="tj_36" class="t s3_36">
          тодорхойлохгүй, оролцогч талуудын саналыг сонсож тусгах учиртай.{" "}
        </span>
        <span id="tk_36" class="t s3_36">
          -{" "}
        </span>
        <span id="tl_36" class="t s3_36">
          Бусад алхмыг ч мөн энэ зарчмаар зурагласан болно. Энэхүү зарчим{" "}
        </span>
        <span id="tm_36" class="t s3_36">
          нь ялангуяа төлөвлөлтийн шатанд ач холбогдолтой бөгөөд тус тусдаа{" "}
        </span>
        <span id="tn_36" class="t s3_36">
          олон ажил төлөвлөж, нөөцөө тарамдуулахгүй, сурагчдад тулгамдсан{" "}
        </span>
        <span id="to_36" class="t s3_36">
          хамгийн чухал асуудлыг шийдэхэд чиглэсэн нэгдмэл зорилгыг биелүүлэх{" "}
        </span>
        <span id="tp_36" class="t s3_36">
          хоорондоо уялдсан ажлыг төлөвлөж хэрэгжүүлэхэд ач холбогдолтой юм.{" "}
        </span>
      </div>
    </div>
  );
}
