import React from "react";
export default function F159() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_159{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_159{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:4.45px;}
#t3_159{left:87px;bottom:921px;letter-spacing:-0.16px;word-spacing:-0.98px;}
#t4_159{left:87px;bottom:901px;letter-spacing:-0.17px;word-spacing:0.22px;}
#t5_159{left:87px;bottom:880px;letter-spacing:-0.16px;word-spacing:5.51px;}
#t6_159{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:5.67px;}
#t7_159{left:87px;bottom:840px;letter-spacing:-0.21px;}
#t8_159{left:174px;bottom:805px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t9_159{left:87px;bottom:595px;letter-spacing:-0.3px;word-spacing:4.38px;}
#ta_159{left:87px;bottom:575px;letter-spacing:-0.33px;word-spacing:9.35px;}
#tb_159{left:87px;bottom:555px;letter-spacing:-0.31px;word-spacing:3.96px;}
#tc_159{left:87px;bottom:534px;letter-spacing:-0.32px;word-spacing:-0.36px;}
#td_159{left:200px;bottom:506px;letter-spacing:-0.3px;word-spacing:0.04px;}
#te_159{left:87px;bottom:277px;letter-spacing:-0.17px;}
#tf_159{left:87px;bottom:257px;letter-spacing:-0.15px;word-spacing:0.39px;}
#tg_159{left:87px;bottom:237px;letter-spacing:-0.13px;word-spacing:1.96px;}
#th_159{left:87px;bottom:217px;letter-spacing:-0.14px;word-spacing:0.05px;}
#ti_159{left:87px;bottom:192px;letter-spacing:-0.16px;word-spacing:4.46px;}
#tj_159{left:87px;bottom:172px;letter-spacing:-0.17px;word-spacing:-0.48px;}
#tk_159{left:87px;bottom:152px;letter-spacing:-0.13px;word-spacing:5.51px;}
#tl_159{left:87px;bottom:132px;letter-spacing:-0.15px;word-spacing:3.07px;}
#tm_159{left:87px;bottom:112px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_159{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_159{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_159{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_159{font-size:17px;font-family:MogulArial_18b;color:#000;}`}
      </style>

      <style id="fonts159" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");

}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg159" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="159/159.svg"
          type="image/svg+xml"
          id="pdf159"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_159" class="t s0_159">
          159{" "}
        </span>
        <span id="t2_159" class="t s1_159">
          үнэлгээг тодорхой давтамжтайгаар төлөвлөн, хэрэгжүүлэх арга хэмжээг{" "}
        </span>
        <span id="t3_159" class="t s1_159">
          тодорхой болгож, хэрэгжүүлэх хамтын ажиллагаа: оролцоог хангах,
          хамтрах{" "}
        </span>
        <span id="t4_159" class="t s1_159">
          Сургуулийн сургалтын орчныг бүрдүүлэхэд сургалтын хөтөлбөрийн онцлог,{" "}
        </span>
        <span id="t5_159" class="t s1_159">
          хэрэглэгч буюу суралцагч, багшийн хэрэгцээ, олон нийт, орон нутгийн{" "}
        </span>
        <span id="t6_159" class="t s1_159">
          хэрэгцээ, санхүүгийн нөөц боломж зэрэг олон зүйлийг харгалзан үзэх{" "}
        </span>
        <span id="t7_159" class="t s1_159">
          шаардлагатай.{" "}
        </span>
        <span id="t8_159" class="t s2_159">
          Зураг 3.29. Сургалтын орчныг бүрдүүлэхэд оролцох, хүчин зүйлс{" "}
        </span>
        <span id="t9_159" class="t s3_159">
          Prakash Nair (2014) нь багш төвтэй сургалтаас зайлсхийх, дээр дурдсан{" "}
        </span>
        <span id="ta_159" class="t s1_159">
          суралтын стратегид гарч буй өөрчлөлтөд нийцсэн сургуулийн орчны{" "}
        </span>
        <span id="tb_159" class="t s1_159">
          дизайнчлалын 4 зарчмыг санал болгосон. Бид сургалтын орчныг үнэлэх,{" "}
        </span>
        <span id="tc_159" class="t s1_159">
          төлөвлөх, бүрдүүлэх, ашиглахад энэхүү стратегийг ашиглахыг зөвлөж
          байна.{" "}
        </span>
        <span id="td_159" class="t s2_159">
          Зураг 3.30. Сургуулийн орчны дизайнд баримтлах зарчим{" "}
        </span>
        <span id="te_159" class="t s1_159">
          Сургалтын орчныг үнэлэх аргачлал өөрчлөгдөж байна. Өмнө нь сургуулийн{" "}
        </span>
        <span id="tf_159" class="t s1_159">
          барилга байгууламж, хүрээлэн буй орчны бүрдэл, чанарыг хэмждэг байсан{" "}
        </span>
        <span id="tg_159" class="t s1_159">
          бол сүүлийн үед сургалтын хөтөлбөрийн хэрэгжилтийг дэмжих сургалтын{" "}
        </span>
        <span id="th_159" class="t s1_159">
          орчны үр нөлөө талаас үнэлж байна.{" "}
        </span>
        <span id="ti_159" class="t s1_159">
          Гэтэл манай улсын хувьд сургуулиуд сургалтын орчноо үнэлэх практик{" "}
        </span>
        <span id="tj_159" class="t s1_159">
          зөвхөн эрүүл, аюулгүй орчноор хязгаарлагдаж байгаа нь ажиглагдаж
          байна.{" "}
        </span>
        <span id="tk_159" class="t s1_159">
          Харин сургалтын орчныг хэрэглэгчийн тав тух, сургалтын хөтөлбөрийг{" "}
        </span>
        <span id="tl_159" class="t s1_159">
          хэрэгжүүлэх нэг элемент талаас үнэлэх нь хангалтгүй байна. Сургалтын{" "}
        </span>
        <span id="tm_159" class="t s1_159">
          орчны үнэлгээ нь дараах циклийн дагуу, давтамжтай, тасралтгүй
          хийгдэнэ.{" "}
        </span>
      </div>
    </div>
  );
}
