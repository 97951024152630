import React from "react";
export default function EightySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_86{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_86{left:142px;bottom:922px;letter-spacing:0.18px;}
#t3_86{left:87px;bottom:880px;}
#t4_86{left:113px;bottom:880px;letter-spacing:-0.14px;word-spacing:10.76px;}
#t5_86{left:370px;bottom:880px;letter-spacing:-0.15px;word-spacing:10.77px;}
#t6_86{left:113px;bottom:860px;letter-spacing:-0.14px;word-spacing:2.46px;}
#t7_86{left:113px;bottom:840px;letter-spacing:-0.16px;word-spacing:2.66px;}
#t8_86{left:362px;bottom:840px;}
#t9_86{left:368px;bottom:840px;letter-spacing:-0.13px;word-spacing:2.63px;}
#ta_86{left:113px;bottom:820px;letter-spacing:-0.15px;word-spacing:0.63px;}
#tb_86{left:113px;bottom:799px;letter-spacing:-0.11px;word-spacing:2.01px;}
#tc_86{left:425px;bottom:799px;letter-spacing:-0.13px;word-spacing:2.02px;}
#td_86{left:113px;bottom:779px;letter-spacing:-0.11px;word-spacing:0.01px;}
#te_86{left:87px;bottom:751px;}
#tf_86{left:113px;bottom:751px;letter-spacing:-0.12px;word-spacing:5.12px;}
#tg_86{left:113px;bottom:730px;letter-spacing:-0.13px;word-spacing:0.03px;}
#th_86{left:87px;bottom:702px;}
#ti_86{left:113px;bottom:702px;letter-spacing:-0.12px;word-spacing:0.16px;}
#tj_86{left:113px;bottom:681px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_86{left:87px;bottom:653px;}
#tl_86{left:113px;bottom:653px;letter-spacing:-0.13px;word-spacing:7.09px;}
#tm_86{left:113px;bottom:632px;letter-spacing:-0.16px;word-spacing:0.09px;}
#tn_86{left:113px;bottom:612px;letter-spacing:-0.14px;word-spacing:-0.91px;}
#to_86{left:578px;bottom:612px;letter-spacing:-0.17px;}
#tp_86{left:113px;bottom:592px;letter-spacing:-0.14px;word-spacing:-0.04px;}
#tq_86{left:113px;bottom:572px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tr_86{left:87px;bottom:543px;}
#ts_86{left:113px;bottom:543px;letter-spacing:-0.14px;word-spacing:8.52px;}
#tt_86{left:113px;bottom:523px;letter-spacing:-0.12px;word-spacing:10.84px;}
#tu_86{left:113px;bottom:503px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_86{left:87px;bottom:474px;}
#tw_86{left:113px;bottom:474px;letter-spacing:-0.13px;word-spacing:0.16px;}
#tx_86{left:113px;bottom:454px;letter-spacing:-0.13px;word-spacing:7.31px;}
#ty_86{left:113px;bottom:434px;letter-spacing:-0.16px;word-spacing:0.93px;}
#tz_86{left:113px;bottom:413px;letter-spacing:-0.21px;}
#t10_86{left:87px;bottom:385px;}
#t11_86{left:113px;bottom:385px;letter-spacing:-0.14px;word-spacing:1.51px;}
#t12_86{left:113px;bottom:364px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t13_86{left:87px;bottom:336px;}
#t14_86{left:113px;bottom:336px;letter-spacing:-0.13px;word-spacing:5.54px;}
#t15_86{left:113px;bottom:315px;letter-spacing:-0.18px;word-spacing:0.08px;}

.s0_86{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_86{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_86{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_86{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_86{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts86" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg86" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="86/86.svg"
          type="image/svg+xml"
          id="pdf86"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_86" class="t s0_86">
          86{" "}
        </span>
        <span id="t2_86" class="t s1_86">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_86" class="t s2_86">
          •{" "}
        </span>
        <span id="t4_86" class="t s3_86">
          Эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="t5_86" class="t s3_86">
          харгалзан дэмжигчдэд багш нарын{" "}
        </span>
        <span id="t6_86" class="t s3_86">
          хичээл заах арга зүй, үйл явцын талаарх ойлголт өгөх олон талт үйл{" "}
        </span>
        <span id="t7_86" class="t s3_86">
          ажиллагааг зохион байгуулах{" "}
        </span>
        <span id="t8_86" class="t s3_86">
          (
        </span>
        <span id="t9_86" class="t s4_86">
          Ингэснээр эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="ta_86" class="t s4_86">
          харгалзан дэмжигчдийн сэтгэл ханамжийн түвшин нэмэгдэх, хамтын{" "}
        </span>
        <span id="tb_86" class="t s4_86">
          үйл ажиллагаа сайжрах, хүүхдийнхээ{" "}
        </span>
        <span id="tc_86" class="t s4_86">
          суралцахуй болон төлөвшлийг{" "}
        </span>
        <span id="td_86" class="t s4_86">
          дэмжих үр дүнтэй).{" "}
        </span>
        <span id="te_86" class="t s2_86">
          •{" "}
        </span>
        <span id="tf_86" class="t s3_86">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчийн санал, хүсэлтийг{" "}
        </span>
        <span id="tg_86" class="t s3_86">
          нээлттэй авч, ажилдаа тусгаж ажилладаг соёлыг дадуулах{" "}
        </span>
        <span id="th_86" class="t s2_86">
          •{" "}
        </span>
        <span id="ti_86" class="t s3_86">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчдэд хүүхдийнх нь хөгжил,{" "}
        </span>
        <span id="tj_86" class="t s3_86">
          ахиц дэвшлийн талаар мэдээлэл тогтмол өгч ажилладаг байх{" "}
        </span>
        <span id="tk_86" class="t s2_86">
          •{" "}
        </span>
        <span id="tl_86" class="t s3_86">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчидтэй хийх уулзалт{" "}
        </span>
        <span id="tm_86" class="t s3_86">
          зөвлөгөөг уламжлалт арга хэлбэрээс татгалзаж шинэлэг арга хэлбэрийг{" "}
        </span>
        <span id="tn_86" class="t s3_86">
          нэвтрүүлэх, үр дүнтэй бодит хамтын ажиллагааг бий болгох{" "}
        </span>
        <span id="to_86" class="t s4_86">
          (жишээлбэл,{" "}
        </span>
        <span id="tp_86" class="t s4_86">
          тэдний ажил мэргэжил, чадвар, боломж зэргийг харгалзан үзэж ажлыг{" "}
        </span>
        <span id="tq_86" class="t s4_86">
          төлөвлөн хэрэгжүүлдэг байх){" "}
        </span>
        <span id="tr_86" class="t s2_86">
          •{" "}
        </span>
        <span id="ts_86" class="t s3_86">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчдэд хүүхэдтэй зөв{" "}
        </span>
        <span id="tt_86" class="t s3_86">
          харилцах, хүмүүжлийн эерэг аргыг түгээн дэлгэрүүлсэн сургалт{" "}
        </span>
        <span id="tu_86" class="t s3_86">
          нөлөөллийн олон талт үйл ажиллагааг зохион байгуулах{" "}
        </span>
        <span id="tv_86" class="t s2_86">
          •{" "}
        </span>
        <span id="tw_86" class="t s3_86">
          Боловсролын тусгай хэрэгцээт суралцагчийн эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="tx_86" class="t s3_86">
          харгалзан дэмжигчдэд зөвлөн туслах ажил хийдэг байх, ингэхдээ{" "}
        </span>
        <span id="ty_86" class="t s3_86">
          ялгаварлан гадуурхсан мэт мэдрэмж төрүүлэхээс зайлсхийх чадвартай{" "}
        </span>
        <span id="tz_86" class="t s3_86">
          байх{" "}
        </span>
        <span id="t10_86" class="t s2_86">
          •{" "}
        </span>
        <span id="t11_86" class="t s3_86">
          Сургуулийн үйл ажиллагааг орон нутгийн түвшинд мэдээлэх, таниулах{" "}
        </span>
        <span id="t12_86" class="t s3_86">
          ажлыг тогтмол хийдэг байх{" "}
        </span>
        <span id="t13_86" class="t s2_86">
          •{" "}
        </span>
        <span id="t14_86" class="t s3_86">
          Сургуулийн үйл ажиллагааг төлөвлөх, төсөвлөхөд орон нутгийн аж{" "}
        </span>
        <span id="t15_86" class="t s3_86">
          ахуйн нгэж байгууллагатай хамтардаг байх{" "}
        </span>
      </div>
    </div>
  );
}
