import React from "react";
export default function OneHundredNinetySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_196{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_196{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:2.9px;}
#t3_196{left:87px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t4_196{left:396px;bottom:888px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t5_196{left:87px;bottom:867px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t6_196{left:87px;bottom:847px;letter-spacing:-0.15px;word-spacing:11.9px;}
#t7_196{left:87px;bottom:826px;letter-spacing:-0.18px;word-spacing:6.72px;}
#t8_196{left:87px;bottom:806px;letter-spacing:-0.16px;}
#t9_196{left:158px;bottom:806px;letter-spacing:-0.37px;}
#ta_196{left:264px;bottom:806px;letter-spacing:-0.09px;}
#tb_196{left:328px;bottom:806px;letter-spacing:-0.13px;}
#tc_196{left:87px;bottom:786px;letter-spacing:-0.15px;}
#td_196{left:193px;bottom:786px;letter-spacing:-0.08px;}
#te_196{left:241px;bottom:786px;letter-spacing:-0.14px;}
#tf_196{left:345px;bottom:786px;letter-spacing:-0.21px;}
#tg_196{left:87px;bottom:766px;letter-spacing:-0.2px;word-spacing:5.32px;}
#th_196{left:87px;bottom:746px;letter-spacing:-0.14px;}
#ti_196{left:214px;bottom:746px;letter-spacing:-0.16px;}
#tj_196{left:315px;bottom:746px;letter-spacing:-0.07px;}
#tk_196{left:354px;bottom:746px;letter-spacing:-0.08px;}
#tl_196{left:87px;bottom:726px;letter-spacing:-0.13px;word-spacing:2.87px;}
#tm_196{left:87px;bottom:705px;letter-spacing:-0.07px;}
#tn_196{left:168px;bottom:705px;letter-spacing:-0.17px;word-spacing:2.69px;}
#to_196{left:87px;bottom:685px;letter-spacing:-0.13px;word-spacing:11.94px;}
#tp_196{left:87px;bottom:665px;letter-spacing:-0.27px;}
#tq_196{left:129px;bottom:665px;letter-spacing:-0.23px;word-spacing:2.61px;}
#tr_196{left:87px;bottom:645px;letter-spacing:-0.16px;word-spacing:5.55px;}
#ts_196{left:87px;bottom:625px;letter-spacing:-0.13px;word-spacing:1.55px;}
#tt_196{left:87px;bottom:605px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tu_196{left:87px;bottom:571px;letter-spacing:-0.19px;word-spacing:-0.16px;}
#tv_196{left:87px;bottom:551px;letter-spacing:-0.13px;word-spacing:0.14px;}
#tw_196{left:87px;bottom:531px;letter-spacing:-0.2px;word-spacing:5.3px;}
#tx_196{left:87px;bottom:511px;letter-spacing:-0.17px;word-spacing:5.14px;}
#ty_196{left:87px;bottom:491px;letter-spacing:-0.17px;word-spacing:5.27px;}
#tz_196{left:87px;bottom:471px;letter-spacing:-0.15px;word-spacing:6.29px;}
#t10_196{left:87px;bottom:450px;letter-spacing:-0.17px;word-spacing:-1.23px;}
#t11_196{left:87px;bottom:430px;letter-spacing:-0.15px;word-spacing:2.26px;}
#t12_196{left:87px;bottom:410px;letter-spacing:-0.12px;word-spacing:0.81px;}
#t13_196{left:246px;bottom:410px;letter-spacing:-0.15px;word-spacing:0.83px;}
#t14_196{left:87px;bottom:390px;letter-spacing:-0.13px;word-spacing:4.65px;}
#t15_196{left:87px;bottom:370px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t16_196{left:87px;bottom:329px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t17_196{left:87px;bottom:305px;letter-spacing:-0.15px;word-spacing:3.67px;}
#t18_196{left:87px;bottom:285px;letter-spacing:-0.16px;word-spacing:-0.46px;}
#t19_196{left:87px;bottom:265px;letter-spacing:-0.13px;word-spacing:-0.38px;}
#t1a_196{left:87px;bottom:244px;letter-spacing:-0.15px;word-spacing:2.78px;}
#t1b_196{left:87px;bottom:224px;letter-spacing:-0.16px;}
#t1c_196{left:147px;bottom:224px;letter-spacing:-0.16px;word-spacing:-0.2px;}
#t1d_196{left:87px;bottom:204px;letter-spacing:-0.14px;word-spacing:5.9px;}
#t1e_196{left:87px;bottom:184px;letter-spacing:-0.15px;word-spacing:6.77px;}
#t1f_196{left:87px;bottom:164px;letter-spacing:-0.13px;word-spacing:-0.55px;}
#t1g_196{left:87px;bottom:144px;letter-spacing:-0.12px;word-spacing:-0.02px;}
#t1h_196{left:231px;bottom:144px;letter-spacing:-0.15px;word-spacing:-0.01px;}
#t1i_196{left:87px;bottom:123px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_196{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_196{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_196{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_196{font-size:17px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s4_196{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}`}
      </style>

      <style id="fonts196" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg196" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="196/196.svg"
          type="image/svg+xml"
          id="pdf196"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_196" class="t s0_196">
          196{" "}
        </span>
        <span id="t2_196" class="t s1_196">
          Сургуулийн менежментийг хэрэгжүүлсэн сайн сургуулийг бий болгох үйл{" "}
        </span>
        <span id="t3_196" class="t s1_196">
          явцыг дараах 5 үйл ажиллагаанд хуваан үзэж болно.{" "}
        </span>
        <span id="t4_196" class="t s2_196">
          Зураг 46. Сайн сургуулийн 5 үйл ажиллагаа{" "}
        </span>
        <span id="t5_196" class="t s3_196">
          Сургуулийн асуудлыг илрүүлэх{" "}
        </span>
        <span id="t6_196" class="t s1_196">
          Өнөөг хүртэл сургуулийг захирал{" "}
        </span>
        <span id="t7_196" class="t s1_196">
          болон сургалтын менежер удирдан{" "}
        </span>
        <span id="t8_196" class="t s1_196">
          зохион{" "}
        </span>
        <span id="t9_196" class="t s1_196">
          байгуулдаг.{" "}
        </span>
        <span id="ta_196" class="t s1_196">
          Нөгөө{" "}
        </span>
        <span id="tb_196" class="t s1_196">
          талаас{" "}
        </span>
        <span id="tc_196" class="t s1_196">
          сургуулийн{" "}
        </span>
        <span id="td_196" class="t s1_196">
          үйл{" "}
        </span>
        <span id="te_196" class="t s1_196">
          ажиллагаа{" "}
        </span>
        <span id="tf_196" class="t s1_196">
          бүрд{" "}
        </span>
        <span id="tg_196" class="t s1_196">
          оролцон, “яагаад, юуг, яаж, хэрхэн”{" "}
        </span>
        <span id="th_196" class="t s1_196">
          хэрэгжүүлбэл{" "}
        </span>
        <span id="ti_196" class="t s1_196">
          сургалтын{" "}
        </span>
        <span id="tj_196" class="t s1_196">
          үр{" "}
        </span>
        <span id="tk_196" class="t s1_196">
          дүн{" "}
        </span>
        <span id="tl_196" class="t s1_196">
          хамгийн сайн байж чадах вэ гэдгийг{" "}
        </span>
        <span id="tm_196" class="t s1_196">
          шийдэж,{" "}
        </span>
        <span id="tn_196" class="t s1_196">
          хүүхдүүдийг шууд удирдан{" "}
        </span>
        <span id="to_196" class="t s1_196">
          чиглүүлж, дэмжиж байдаг хүмүүс{" "}
        </span>
        <span id="tp_196" class="t s1_196">
          бол{" "}
        </span>
        <span id="tq_196" class="t s1_196">
          багш нар байдаг. Иймээс багш,{" "}
        </span>
        <span id="tr_196" class="t s1_196">
          удирдлагууд нягт хамтран ажиллаж{" "}
        </span>
        <span id="ts_196" class="t s1_196">
          “Сайн сургууль” -ийг бүтээхийн тулд юу юунаас илүү эхлээд “Одоо манай{" "}
        </span>
        <span id="tt_196" class="t s1_196">
          сургууль ямар байдалтай байна вэ?” гэдгийг тодруулах нь зүйтэй.{" "}
        </span>
        <span id="tu_196" class="t s1_196">
          Сургууль бүрт өөрт тохирсон асуудлууд байсаар байдаг. Асуудал нь хэр
          гүн{" "}
        </span>
        <span id="tv_196" class="t s1_196">
          гүнзгий вэ гэдэг л болохоос биш өдөр бүр тухайлсан асуудлууд ар
          араасаа{" "}
        </span>
        <span id="tw_196" class="t s1_196">
          урган гарч ирдэг. Тэдгээр тухайлсан асуудлууд нь өнөөдөртөө шийдэх{" "}
        </span>
        <span id="tx_196" class="t s1_196">
          асуудлаас эхлээд хэдэн жил сайтар арга хэмжээ авч байж шийдэгдэх{" "}
        </span>
        <span id="ty_196" class="t s1_196">
          асуудал гээд янз бүр байдаг. ‘’Сайн сургууль’’-ийг бүтээхийн тулд аль{" "}
        </span>
        <span id="tz_196" class="t s1_196">
          тухайлсан асуудал нь сургуулийн хувьд тулгамдаад байна вэ гэдгийг{" "}
        </span>
        <span id="t10_196" class="t s1_196">
          тодорхойлж, хуримтлагдсан асуудлуудыг шийдвэрлэх дарааллыг тогтоохгүй{" "}
        </span>
        <span id="t11_196" class="t s1_196">
          бол болохгүй. Ялангуяа сургууль нь жилээр сургалтын үйл ажиллагаагаа{" "}
        </span>
        <span id="t12_196" class="t s1_196">
          төлөвлөдөг учраас{" "}
        </span>
        <span id="t13_196" class="t s1_196">
          “Энэ жил шийдвэрлэх асуудал”-ыг сургуулийн асуудал{" "}
        </span>
        <span id="t14_196" class="t s1_196">
          гэж тогтоон, түүнийг шийдвэрлэхийн төлөө багш нар хамтран ажиллах{" "}
        </span>
        <span id="t15_196" class="t s1_196">
          зарчмаар бодож сэтгэж ажиллах нь зүйтэй.{" "}
        </span>
        <span id="t16_196" class="t s4_196">
          Зорилго, зорилтыг сайжруулах{" "}
        </span>
        <span id="t17_196" class="t s1_196">
          Сургуулийн асуудал нь тодорхой болонгуут дараа нь зорилго, зорилтоо{" "}
        </span>
        <span id="t18_196" class="t s1_196">
          тодорхойлох, сайжруулах шаардлага үүснэ. Гэвч сургуулиудын ихэнх нь
          нэг{" "}
        </span>
        <span id="t19_196" class="t s1_196">
          зорилгын дор нэгдэж, өдөр тутмын үйл ажиллагаанд тусгалаа олж, бодитой{" "}
        </span>
        <span id="t1a_196" class="t s1_196">
          хэрэгжиж чадахгүй байна. Сургуулиуд зорилгоо олон янзаар тодорхойлж{" "}
        </span>
        <span id="t1b_196" class="t s1_196">
          байна.{" "}
        </span>
        <span id="t1c_196" class="t s1_196">
          Уриа төдийгөөр хязгаарлагдаж бодит байдал дээр сургууль, хүүхэд,{" "}
        </span>
        <span id="t1d_196" class="t s1_196">
          гэр бүл, орон нутгийн өнөөгийн байдал болон сургуулийн асуудалтай{" "}
        </span>
        <span id="t1e_196" class="t s1_196">
          нийцээгүй байх тохиолдол бий. Сургуулийн зорилго, зорилтыг тухайн{" "}
        </span>
        <span id="t1f_196" class="t s1_196">
          сургуульд суралцаж буй хүүхэд уншаад өөрөө цаашид зорих дүрээ төсөөлж{" "}
        </span>
        <span id="t1g_196" class="t s1_196">
          чадаж байна уу?,{" "}
        </span>
        <span id="t1h_196" class="t s1_196">
          зорилт нь асуудлын шийдэл талаас бодитой болж чадаж{" "}
        </span>
        <span id="t1i_196" class="t s1_196">
          уу? гэдгийг нягтлах нь зүйтэй.{" "}
        </span>
      </div>
    </div>
  );
}
