import React from "react";
export default function F161() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        #
        {`t1_161{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_161{left:87px;bottom:941px;letter-spacing:-0.09px;word-spacing:-1.66px;}
#t3_161{left:362px;bottom:941px;letter-spacing:-0.09px;word-spacing:-1.66px;}
#t4_161{left:87px;bottom:921px;letter-spacing:-0.09px;}
#t5_161{left:87px;bottom:880px;letter-spacing:-0.1px;word-spacing:1.81px;}
#t6_161{left:87px;bottom:860px;letter-spacing:-0.09px;word-spacing:-1.82px;}
#t7_161{left:87px;bottom:840px;letter-spacing:-0.1px;}
#t8_161{left:114px;bottom:813px;}
#t9_161{left:142px;bottom:813px;letter-spacing:-0.1px;}
#ta_161{left:114px;bottom:786px;}
#tb_161{left:142px;bottom:786px;letter-spacing:-0.1px;}
#tc_161{left:114px;bottom:759px;}
#td_161{left:142px;bottom:759px;letter-spacing:-0.11px;word-spacing:9.85px;}
#te_161{left:142px;bottom:740px;letter-spacing:-0.1px;}
#tf_161{left:87px;bottom:711px;letter-spacing:-0.1px;word-spacing:-0.02px;}
#tg_161{left:87px;bottom:691px;letter-spacing:-0.1px;}
#th_161{left:87px;bottom:662px;letter-spacing:-0.1px;word-spacing:4.55px;}
#ti_161{left:87px;bottom:642px;letter-spacing:-0.09px;word-spacing:0.3px;}
#tj_161{left:87px;bottom:622px;letter-spacing:-0.1px;word-spacing:-0.89px;}
#tk_161{left:87px;bottom:602px;letter-spacing:-0.1px;word-spacing:-0.21px;}
#tl_161{left:87px;bottom:582px;letter-spacing:-0.11px;word-spacing:11.52px;}
#tm_161{left:87px;bottom:562px;letter-spacing:-0.1px;}
#tn_161{left:114px;bottom:533px;}
#to_161{left:142px;bottom:533px;letter-spacing:-0.1px;word-spacing:3.3px;}
#tp_161{left:142px;bottom:513px;letter-spacing:-0.1px;word-spacing:-2.03px;}
#tq_161{left:142px;bottom:492px;letter-spacing:-0.1px;word-spacing:-1.51px;}
#tr_161{left:142px;bottom:472px;letter-spacing:-0.1px;}
#ts_161{left:87px;bottom:443px;letter-spacing:-0.1px;word-spacing:-1.57px;}
#tt_161{left:87px;bottom:423px;letter-spacing:-0.1px;word-spacing:3.97px;}
#tu_161{left:87px;bottom:403px;letter-spacing:-0.1px;}
#tv_161{left:142px;bottom:350px;}
#tw_161{left:157px;bottom:319px;letter-spacing:-0.12px;word-spacing:0.01px;}
#tx_161{left:162px;bottom:296px;}
#ty_161{left:184px;bottom:296px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tz_161{left:162px;bottom:274px;}
#t10_161{left:184px;bottom:274px;letter-spacing:-0.18px;word-spacing:1.48px;}
#t11_161{left:184px;bottom:255px;letter-spacing:-0.15px;word-spacing:9.91px;}
#t12_161{left:184px;bottom:237px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t13_161{left:162px;bottom:214px;}
#t14_161{left:184px;bottom:214px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t15_161{left:162px;bottom:191px;}
#t16_161{left:184px;bottom:191px;letter-spacing:-0.13px;word-spacing:9.48px;}
#t17_161{left:184px;bottom:172px;letter-spacing:-0.14px;}
#t18_161{left:162px;bottom:150px;}
#t19_161{left:184px;bottom:150px;letter-spacing:-0.11px;word-spacing:0.01px;}

.s0_161{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_161{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_161{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s3_161{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_161{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_161{font-size:17px;font-family:ArialMT_1lq;color:#4472C4;}`}
      </style>

      <style id="fonts161" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg161" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="161/161.svg"
          type="image/svg+xml"
          id="pdf161"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_161" class="t s0_161">
          161{" "}
        </span>
        <span id="t2_161" class="t s1_161">
          Мөн эрүүл, аюулгүй байх талаас нь{" "}
        </span>
        <span id="t3_161" class="t s1_161">
          хэрэглэгчийн сэтгэл ханамжийн үнэлгээг{" "}
        </span>
        <span id="t4_161" class="t s1_161">
          хийж болно.{" "}
        </span>
        <span id="t5_161" class="t s1_161">
          Харин сургалтын хөтөлбөрийг хэрэгжүүлэхэд чиглэсэн тохируулгат орчин{" "}
        </span>
        <span id="t6_161" class="t s1_161">
          талаас нь үнэлэхдээ хэрэглэгчийн /багш, сурагч/ сэтгэл ханамж,
          хэрэгцээний{" "}
        </span>
        <span id="t7_161" class="t s1_161">
          үнэлгээ хийнэ. Энэ нь дараах зүйлсэд төвлөрөх болно.{" "}
        </span>
        <span id="t8_161" class="t s2_161">
          ●{" "}
        </span>
        <span id="t9_161" class="t s1_161">
          Бүх хэрэглэгч сургалтын орчныг сайн ашиглаж чадаж байна уу?{" "}
        </span>
        <span id="ta_161" class="t s2_161">
          ●{" "}
        </span>
        <span id="tb_161" class="t s1_161">
          Багш сургалтын орчинд оновчтой ажиллаж чадаж байна уу?{" "}
        </span>
        <span id="tc_161" class="t s2_161">
          ●{" "}
        </span>
        <span id="td_161" class="t s1_161">
          Сургалтын орчны сургалтын зорилго нь сурагчдын суралцах{" "}
        </span>
        <span id="te_161" class="t s1_161">
          туршлагад илэрч байна уу?{" "}
        </span>
        <span id="tf_161" class="t s1_161">
          Энэ талаар энэхүү зөвлөмжийн сургалтын орчин хэсгийн ашиглалт, зохион{" "}
        </span>
        <span id="tg_161" class="t s1_161">
          байгуулалт хэсэгт дэлгэрэнгүй тайлбарлах болно.{" "}
        </span>
        <span id="th_161" class="t s1_161">
          Сургуулийн гадаад, дотоод орчны эрүүл, аюулгүй, тав тухтай байдалд{" "}
        </span>
        <span id="ti_161" class="t s1_161">
          үнэлгээ хийж, үр дүнгийн дагуу хийх ажлыг төлөвлөн, хэрэгжүүлэхэд
          төсөв{" "}
        </span>
        <span id="tj_161" class="t s1_161">
          санхүүгийн асуудал тулгардаг нь сургуулиудын жишээнээс харагдаж байна.{" "}
        </span>
        <span id="tk_161" class="t s1_161">
          Тиймээс асуудлыг ач холбогдлын түвшнээр эрэмбэлэн, хэрхэн сайжруулах{" "}
        </span>
        <span id="tl_161" class="t s1_161">
          талаар сургуулийн үйл ажиллагааны төлөвлөлтөд тусган, төсвийн{" "}
        </span>
        <span id="tm_161" class="t s1_161">
          төлөвлөлтөд тусгах шаардлагатай.{" "}
        </span>
        <span id="tn_161" class="t s2_161">
          ●{" "}
        </span>
        <span id="to_161" class="t s1_161">
          Гамшгаас хамгаалах тухай хуулийн 51.2.-д ”Гамшгаас хамгаалах,{" "}
        </span>
        <span id="tp_161" class="t s1_161">
          эрсдэлийг бууруулах арга хэмжээний зардалд …, төрийн байгууллага{" "}
        </span>
        <span id="tq_161" class="t s1_161">
          тухайн жилийн төсвийнхөө 1.0 хувиас доошгүйг зарцуулахаар тооцон{" "}
        </span>
        <span id="tr_161" class="t s1_161">
          төсөвт тусгаж, … төлөвлөж зарцуулна.”{" "}
        </span>
        <span id="ts_161" class="t s1_161">
          Сургуулиуд гадаад, дотоод орчны тохижилтын зарим асуудлаа орон нутгийн{" "}
        </span>
        <span id="tt_161" class="t s1_161">
          захиргаа, ТББ, төсөл, хөтөлбөрүүдийн санхүү, нөөцийн тусламжтайгаар{" "}
        </span>
        <span id="tu_161" class="t s1_161">
          шийдвэрлэсэн байна.{" "}
        </span>
        <span id="tv_161" class="t s3_161">
          АСУУДАЛ{" "}
        </span>
        <span id="tw_161" class="t s4_161">
          Материаллаг нөөц, хүртээмж{" "}
        </span>
        <span id="tx_161" class="t s5_161">
          •{" "}
        </span>
        <span id="ty_161" class="t s1_161">
          Сургалт-туршилтын байгууламж, анги танхим хүрэлцээгүй{" "}
        </span>
        <span id="tz_161" class="t s5_161">
          •{" "}
        </span>
        <span id="t10_161" class="t s1_161">
          Сүүлийн үед авч байгаа сургалтын хэрэглэгдэхүүн шаардлага{" "}
        </span>
        <span id="t11_161" class="t s1_161">
          хангахгүй, туршилтын багаж хэрэгслийг ашиглах заавар,{" "}
        </span>
        <span id="t12_161" class="t s1_161">
          зөвлөмж байхгүй{" "}
        </span>
        <span id="t13_161" class="t s5_161">
          •{" "}
        </span>
        <span id="t14_161" class="t s1_161">
          Цахим орчин бүрдүүлэлт хангалтгүй, хүрэлцээгүй{" "}
        </span>
        <span id="t15_161" class="t s5_161">
          •{" "}
        </span>
        <span id="t16_161" class="t s1_161">
          Дотуур байрны хүүхэд амьдрах өрөөний ширээ, сандал{" "}
        </span>
        <span id="t17_161" class="t s1_161">
          хүрэлцээгүй{" "}
        </span>
        <span id="t18_161" class="t s5_161">
          •{" "}
        </span>
        <span id="t19_161" class="t s1_161">
          Ариун цэврийн өрөө хүрэлцээгүй{" "}
        </span>
      </div>
    </div>
  );
}
