import React from "react";
export default function TwentyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_29{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_29{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_29{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:7.98px;}
#t4_29{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:-2.57px;}
#t5_29{left:537px;bottom:921px;letter-spacing:-0.1px;word-spacing:-2.59px;}
#t6_29{left:87px;bottom:901px;letter-spacing:-0.12px;word-spacing:1.76px;}
#t7_29{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:4.62px;}
#t8_29{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:0.85px;}
#t9_29{left:87px;bottom:840px;letter-spacing:-0.14px;word-spacing:2.77px;}
#ta_29{left:87px;bottom:820px;letter-spacing:-0.15px;word-spacing:2.61px;}
#tb_29{left:87px;bottom:800px;letter-spacing:-0.14px;word-spacing:5.18px;}
#tc_29{left:87px;bottom:780px;letter-spacing:-0.18px;word-spacing:2.53px;}
#td_29{left:87px;bottom:759px;letter-spacing:-0.16px;word-spacing:0.1px;}
#te_29{left:87px;bottom:739px;letter-spacing:-0.14px;word-spacing:2.79px;}
#tf_29{left:87px;bottom:719px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_29{left:229px;bottom:684px;letter-spacing:-0.15px;word-spacing:0.02px;}
#th_29{left:461px;bottom:627px;letter-spacing:0.09px;word-spacing:0.35px;}
#ti_29{left:470px;bottom:612px;letter-spacing:0.1px;word-spacing:0.01px;}
#tj_29{left:461px;bottom:597px;letter-spacing:0.09px;word-spacing:0.27px;}
#tk_29{left:470px;bottom:583px;letter-spacing:0.09px;word-spacing:0.02px;}
#tl_29{left:470px;bottom:568px;letter-spacing:0.05px;word-spacing:0.05px;}
#tm_29{left:461px;bottom:553px;letter-spacing:0.1px;word-spacing:0.01px;}
#tn_29{left:470px;bottom:539px;letter-spacing:0.08px;word-spacing:0.03px;}
#to_29{left:470px;bottom:524px;letter-spacing:0.06px;word-spacing:0.04px;}
#tp_29{left:461px;bottom:509px;letter-spacing:0.08px;word-spacing:0.36px;}
#tq_29{left:470px;bottom:495px;letter-spacing:0.1px;}
#tr_29{left:504px;bottom:495px;word-spacing:0.1px;}
#ts_29{left:470px;bottom:480px;letter-spacing:0.09px;word-spacing:0.02px;}
#tt_29{left:461px;bottom:465px;letter-spacing:0.08px;word-spacing:0.27px;}
#tu_29{left:470px;bottom:451px;letter-spacing:0.09px;word-spacing:0.01px;}
#tv_29{left:470px;bottom:436px;letter-spacing:0.08px;word-spacing:0.03px;}
#tw_29{left:461px;bottom:421px;letter-spacing:0.1px;word-spacing:0.01px;}
#tx_29{left:470px;bottom:407px;letter-spacing:0.1px;word-spacing:0.01px;}
#ty_29{left:470px;bottom:392px;letter-spacing:0.11px;word-spacing:0.01px;}
#tz_29{left:470px;bottom:377px;letter-spacing:0.07px;word-spacing:0.04px;}
#t10_29{left:470px;bottom:363px;letter-spacing:0.08px;word-spacing:0.04px;}
#t11_29{left:470px;bottom:348px;letter-spacing:0.06px;word-spacing:0.04px;}
#t12_29{left:87px;bottom:287px;letter-spacing:-0.12px;word-spacing:-0.36px;}
#t13_29{left:87px;bottom:267px;letter-spacing:-0.13px;word-spacing:8.17px;}
#t14_29{left:87px;bottom:247px;letter-spacing:-0.13px;word-spacing:1.36px;}
#t15_29{left:87px;bottom:226px;letter-spacing:-0.14px;word-spacing:2.08px;}
#t16_29{left:87px;bottom:206px;letter-spacing:-0.13px;word-spacing:2.06px;}
#t17_29{left:87px;bottom:186px;letter-spacing:-0.16px;word-spacing:0.29px;}
#t18_29{left:87px;bottom:166px;letter-spacing:-0.14px;word-spacing:1.06px;}
#t19_29{left:87px;bottom:146px;letter-spacing:-0.13px;word-spacing:2.89px;}
#t1a_29{left:87px;bottom:126px;letter-spacing:-0.19px;word-spacing:7.46px;}

.s0_29{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_29{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_29{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_29{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_29{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_29{font-size:12px;font-family:MogulArial_16s;color:#000;}
.t.m0_29{transform:matrix(-1,0,0,1,0,0);}`}
      </style>
      <style id="fonts29" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg29" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="29/29.svg"
          type="image/svg+xml"
          id="pdf29"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_29" class="t s1_29">
          29{" "}
        </span>
        <span id="t3_29" class="t s2_29">
          Пирамидын дунд давхарга нь суурь түвшингээсээ ахиж, алсын{" "}
        </span>
        <span id="t4_29" class="t s2_29">
          хараандаа хүрэх чиг шугам тогтож, бэхжих чухал үе юм.{" "}
        </span>
        <span id="t5_29" class="t s3_29">
          Зурагт үзүүлснийг{" "}
        </span>
        <span id="t6_29" class="t s3_29">
          анзаарвал өмнө суурийн хэсэгт байсан олон жижиг хэсгүүд цөөрч мөчлөг{" "}
        </span>
        <span id="t7_29" class="t s3_29">
          бүрт дөрвөн салангид бүлэг харагдаж байгаа нь интеграцчилагдах үйл{" "}
        </span>
        <span id="t8_29" class="t s3_29">
          явц дунд хугацаандаа хамтрах түвшинд хүрснийг илтгэнэ. Өөрөөр хэлбэл,{" "}
        </span>
        <span id="t9_29" class="t s3_29">
          сурагчийн мэдлэг чадвар, хандлага төлөвшил, эрүүл мэндийн асуудлууд{" "}
        </span>
        <span id="ta_29" class="t s3_29">
          суурь түвшинд жилийн зорилго зорилтуудын хүрээнд бага багаар, алхам{" "}
        </span>
        <span id="tb_29" class="t s3_29">
          алхмаар шийдэгдэж, дунд хугацааны зорилго хэрэгжсэн бол багш нар{" "}
        </span>
        <span id="tc_29" class="t s3_29">
          бүлгээрээ, багаараа хамтарч ажилладаг, сургалтын хөтөлбөрийн агуулга,{" "}
        </span>
        <span id="td_29" class="t s3_29">
          арга зүйд ч мөн интеграцчилагдах үйл явц өрнөж, сургууль орон нутаг,
          эцэг{" "}
        </span>
        <span id="te_29" class="t s3_29">
          эх, асран хамгаалагч зэрэг талуудын оролцоо бэхжиж, хамтран ажиллах{" "}
        </span>
        <span id="tf_29" class="t s3_29">
          болсныг илэрхийлнэ.{" "}
        </span>
        <span id="tg_29" class="t s4_29">
          Зураг 1.7. Пирамидын дунд давхаргын тайлбар{" "}
        </span>
        <span id="th_29" class="t s5_29">
          • Өмнөх жилүүдийн гүйцэтгэлд{" "}
        </span>
        <span id="ti_29" class="t s5_29">
          харьцуулсан дүн шинжилгээ хийх;{" "}
        </span>
        <span id="tj_29" class="t s5_29">
          • Суурь асуудлын зарим хэсгийг{" "}
        </span>
        <span id="tk_29" class="t s5_29">
          шийдсэн байхаар дунд хугацааны{" "}
        </span>
        <span id="tl_29" class="t s5_29">
          зорилго, зорилтод тусгах;{" "}
        </span>
        <span id="tm_29" class="t s5_29">
          • Багшийн хөгжлийн хамтын ажиллагаа{" "}
        </span>
        <span id="tn_29" class="t s5_29">
          тогтворжсон байх (сургалтын шинэ{" "}
        </span>
        <span id="to_29" class="t s5_29">
          арга зүй, технологи);{" "}
        </span>
        <span id="tp_29" class="t s5_29">
          • Сургалтын хөтөлбөрийг сургууль,{" "}
        </span>
        <span id="tq_29" class="t s5_29">
          орон{" "}
        </span>
        <span id="tr_29" class="t s5_29">
          нутаг, алсын хараанд{" "}
        </span>
        <span id="ts_29" class="t s5_29">
          нийцүүлэн баяжуулах;{" "}
        </span>
        <span id="tt_29" class="t s5_29">
          • Сургуулийн нөөц, орчин сайжирсан,{" "}
        </span>
        <span id="tu_29" class="t s5_29">
          эцэг эх, орон нутгийн түншлэлийг{" "}
        </span>
        <span id="tv_29" class="t s5_29">
          бэхжүүлсэн байх;{" "}
        </span>
        <span id="tw_29" class="t s5_29">
          • Дунд хугацааны үйл ажиллагааны үр{" "}
        </span>
        <span id="tx_29" class="t s5_29">
          дүнгийн шалгуур үзүүлэлтийг өмнөх{" "}
        </span>
        <span id="ty_29" class="t s5_29">
          2-3 жилийн сурагчийн ахиц амжилт{" "}
        </span>
        <span id="tz_29" class="t s5_29">
          болон гүйцэтгэлийн үнэлгээний{" "}
        </span>
        <span id="t10_29" class="t s5_29">
          дунджаар тооцоолох тул мэдээллийг{" "}
        </span>
        <span id="t11_29" class="t s5_29">
          системтэй цуглуулах, үнэлэх;{" "}
        </span>
        <span id="t12_29" class="t s3_29">
          Дунд хугацааны менежментийн эхлэх цэг нь өмнөх жилүүдийн гүйцэтгэлийг{" "}
        </span>
        <span id="t13_29" class="t s3_29">
          харьцуулах, богино хугацааны зорилгын хэрэгжилтэд дүн шинжилгээ{" "}
        </span>
        <span id="t14_29" class="t s3_29">
          хийснээр эхэлнэ. Сайжруулах, засаж залруулах ажлыг тухай бүрт хийх нь{" "}
        </span>
        <span id="t15_29" class="t s3_29">
          богино, дунд, урт хугацааны менежментэд адил боловч суурийн түвшинд{" "}
        </span>
        <span id="t16_29" class="t s3_29">
          хийсэн, өөрчилж сайжруулах олон жижиг ажил дунд хугацаанд илүү цогц{" "}
        </span>
        <span id="t17_29" class="t s3_29">
          шинжтэй болохоороо ялгаатай. Тухайлбал, богино хугацаанд багш нар өөр{" "}
        </span>
        <span id="t18_29" class="t s3_29">
          өөрсдийн арга зүйг сайжруулах, эсвэл аль нэг ур чадвараа нэмэгдүүлэхэд{" "}
        </span>
        <span id="t19_29" class="t s3_29">
          чиглэсэн хөгжлийн төлөвлөлт хийдэг байсан бол дунд хугацаанд тэдний{" "}
        </span>
        <span id="t1a_29" class="t s3_29">
          хамтын ажиллагаа тогтмолжих, оролцогч талуудын дэмжлэг, түншлэл{" "}
        </span>
      </div>
    </div>
  );
}
