import React from "react";
export default function HundredTwelve() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_112{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_112{left:106px;bottom:928px;letter-spacing:0.12px;word-spacing:2.41px;}
#t3_112{left:106px;bottom:910px;letter-spacing:0.09px;word-spacing:4px;}
#t4_112{left:106px;bottom:891px;letter-spacing:0.1px;word-spacing:0.81px;}
#t5_112{left:106px;bottom:873px;letter-spacing:0.08px;}
#t6_112{left:187px;bottom:857px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t7_112{left:429px;bottom:841px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t8_112{left:106px;bottom:803px;letter-spacing:0.11px;word-spacing:4.07px;}
#t9_112{left:106px;bottom:785px;letter-spacing:0.09px;word-spacing:1.2px;}
#ta_112{left:106px;bottom:767px;letter-spacing:0.11px;word-spacing:0.03px;}
#tb_112{left:187px;bottom:742px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tc_112{left:429px;bottom:726px;letter-spacing:-0.16px;word-spacing:0.03px;}
#td_112{left:106px;bottom:688px;letter-spacing:0.07px;word-spacing:1.03px;}
#te_112{left:106px;bottom:670px;letter-spacing:0.12px;word-spacing:0.22px;}
#tf_112{left:106px;bottom:652px;letter-spacing:0.12px;word-spacing:2.22px;}
#tg_112{left:106px;bottom:633px;letter-spacing:0.12px;word-spacing:2.31px;}
#th_112{left:106px;bottom:615px;letter-spacing:0.13px;word-spacing:0.01px;}
#ti_112{left:187px;bottom:590px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tj_112{left:429px;bottom:574px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tk_112{left:142px;bottom:494px;letter-spacing:0.15px;}
#tl_112{left:139px;bottom:462px;letter-spacing:-0.13px;word-spacing:7.63px;}
#tm_112{left:139px;bottom:442px;letter-spacing:-0.14px;word-spacing:4.21px;}
#tn_112{left:139px;bottom:422px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#to_112{left:274px;bottom:431px;letter-spacing:-0.83px;}
#tp_112{left:114px;bottom:402px;}
#tq_112{left:142px;bottom:402px;letter-spacing:-0.16px;word-spacing:5.7px;}
#tr_112{left:142px;bottom:382px;letter-spacing:-0.14px;word-spacing:0.05px;}
#ts_112{left:114px;bottom:361px;}
#tt_112{left:142px;bottom:361px;letter-spacing:-0.14px;word-spacing:-1.28px;}
#tu_112{left:142px;bottom:341px;letter-spacing:-0.33px;}
#tv_112{left:114px;bottom:321px;}
#tw_112{left:142px;bottom:321px;letter-spacing:-0.13px;word-spacing:4.74px;}
#tx_112{left:142px;bottom:301px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ty_112{left:114px;bottom:281px;}
#tz_112{left:142px;bottom:281px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t10_112{left:114px;bottom:261px;}
#t11_112{left:142px;bottom:261px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t12_112{left:114px;bottom:240px;}
#t13_112{left:142px;bottom:240px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t14_112{left:114px;bottom:220px;}
#t15_112{left:142px;bottom:220px;letter-spacing:-0.14px;word-spacing:4.07px;}
#t16_112{left:142px;bottom:200px;letter-spacing:-0.14px;word-spacing:3.74px;}
#t17_112{left:142px;bottom:180px;letter-spacing:-0.15px;}
#t18_112{left:87px;bottom:130px;letter-spacing:-0.83px;}
#t19_112{left:108px;bottom:130px;letter-spacing:0.09px;word-spacing:0.77px;}
#t1a_112{left:108px;bottom:115px;letter-spacing:0.12px;}

.s0_112{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_112{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_112{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_112{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_112{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_112{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s6_112{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s7_112{font-size:12px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts112" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg112" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="112/112.svg"
          type="image/svg+xml"
          id="pdf112"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_112" class="t s0_112">
          112{" "}
        </span>
        <span id="t2_112" class="t s1_112">
          Өглөө бүр ном унших, уншлагын хурдыг тэмдэглэх, эхээс ойлгосон зүйлээ{" "}
        </span>
        <span id="t3_112" class="t s1_112">
          хүснэгтийн аргаар болон зурган хэлбэрээр бичиж зурах, сэтгэгдлээ ярих,{" "}
        </span>
        <span id="t4_112" class="t s1_112">
          эхэд задлан шинжилгээ тогтмол хийснээр эхийг унших, ойлгох чадварт
          ахиц{" "}
        </span>
        <span id="t5_112" class="t s1_112">
          гарсан.{" "}
        </span>
        <span id="t6_112" class="t s2_112">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="t7_112" class="t s2_112">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t8_112" class="t s1_112">
          “Байгалийн ухааны өртөөгөөр аялъя” интеграци хичээл зохин байгуулсан{" "}
        </span>
        <span id="t9_112" class="t s1_112">
          нь сурагчдыг байгалийн ухааны хичээлд дурлах, тэдний сонирхлыг татах
          үр{" "}
        </span>
        <span id="ta_112" class="t s1_112">
          дүнтэй хичээл боллоо.{" "}
        </span>
        <span id="tb_112" class="t s2_112">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tc_112" class="t s2_112">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="td_112" class="t s1_112">
          “Самбар төлөвлөлт, дэвтрийн бичиглэл” 7 хоног аян сар бүр тогтмол
          зохион{" "}
        </span>
        <span id="te_112" class="t s1_112">
          байгуулж хэвшсэнээр сурагчдын дэвтрийн үйл ажиллагаа 2 сарын хугацаанд{" "}
        </span>
        <span id="tf_112" class="t s1_112">
          30.0 хувиар сайжирсан. Самбар төлөвлөлт сайн багш нарын төлөвлөлтийг{" "}
        </span>
        <span id="tg_112" class="t s1_112">
          бүх багш, эцэг эх, асран хамгаалагч, харилцан дэмжигчдэд хүргэсэн нь
          үр{" "}
        </span>
        <span id="th_112" class="t s1_112">
          дүнтэй болсон{" "}
        </span>
        <span id="ti_112" class="t s2_112">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tj_112" class="t s2_112">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tk_112" class="t s3_112">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tl_112" class="t s4_112">
          Бие даан суралцах арга зүйг хэрэгжүүлэхийн тулд сургуулийн{" "}
        </span>
        <span id="tm_112" class="t s4_112">
          удирдлагын зүгээс дараах үйл ажиллагааг хэрэгжүүлэхэд багшид{" "}
        </span>
        <span id="tn_112" class="t s4_112">
          дэмжлэг үзүүлнэ.{" "}
        </span>
        <span id="to_112" class="t s5_112">
          11{" "}
        </span>
        <span id="tp_112" class="t s4_112">
          -{" "}
        </span>
        <span id="tq_112" class="t s4_112">
          Сурагч бүрд суралцахуйн замын зураг бий болгож, одоо хаана{" "}
        </span>
        <span id="tr_112" class="t s4_112">
          байгаа, цаашид хаана очихоо мэдэхэд туслах хэрэгтэй.{" "}
        </span>
        <span id="ts_112" class="t s4_112">
          -{" "}
        </span>
        <span id="tt_112" class="t s4_112">
          Сурагчид өөрийн суралцахуйн зорилгоо тодорхойлох, сонгох боломж{" "}
        </span>
        <span id="tu_112" class="t s4_112">
          олгох{" "}
        </span>
        <span id="tv_112" class="t s4_112">
          -{" "}
        </span>
        <span id="tw_112" class="t s4_112">
          Алхам алхмаар ядралгүй суралцах боломжийг төлөвлөн, зохион{" "}
        </span>
        <span id="tx_112" class="t s4_112">
          байгуулах. (Явцын үнэлгээнд суурилах, асуулт асуух, харилцах){" "}
        </span>
        <span id="ty_112" class="t s4_112">
          -{" "}
        </span>
        <span id="tz_112" class="t s4_112">
          Харилцааг хөгжүүлэх{" "}
        </span>
        <span id="t10_112" class="t s4_112">
          -{" "}
        </span>
        <span id="t11_112" class="t s4_112">
          Анги, гэрийн даалгаврын биелэлт, эргэх холбоог сайжруулах{" "}
        </span>
        <span id="t12_112" class="t s4_112">
          -{" "}
        </span>
        <span id="t13_112" class="t s4_112">
          Хамтын ажиллагааг дэмжих{" "}
        </span>
        <span id="t14_112" class="t s4_112">
          -{" "}
        </span>
        <span id="t15_112" class="t s4_112">
          Эргэцүүлэлт сургалтын арга хэрэглэх. Өдөр тутмын суралцахуйн{" "}
        </span>
        <span id="t16_112" class="t s4_112">
          үр дүнгийн тэмдэглэл, суралцахуйн гэрээ байгуулах гэх мэт арга{" "}
        </span>
        <span id="t17_112" class="t s4_112">
          хэрэглэх.{" "}
        </span>
        <span id="t18_112" class="t s6_112">
          11{" "}
        </span>
        <span id="t19_112" class="t s7_112">
          БХ. Сурагчийн бие даан суралцах дасгал ажлын дэвтэр. 9 цуврал бичлэг
          https://www.youtube.com/{" "}
        </span>
        <span id="t1a_112" class="t s7_112">
          watch?v=z2-_H6EIeUw{" "}
        </span>
      </div>
    </div>
  );
}
