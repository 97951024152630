import React from "react";
export default function SeventyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_72{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_72{left:87px;bottom:936px;letter-spacing:0.11px;word-spacing:1.01px;}
#t3_72{left:139px;bottom:908px;letter-spacing:0.17px;word-spacing:0.08px;}
#t4_72{left:87px;bottom:850px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t5_72{left:87px;bottom:825px;letter-spacing:-0.16px;word-spacing:5.81px;}
#t6_72{left:87px;bottom:805px;letter-spacing:-0.12px;word-spacing:0.55px;}
#t7_72{left:87px;bottom:785px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_72{left:249px;bottom:745px;letter-spacing:0.09px;word-spacing:0.02px;}
#t9_72{left:326px;bottom:745px;letter-spacing:0.12px;word-spacing:0.02px;}
#ta_72{left:137px;bottom:485px;}
#tb_72{left:139px;bottom:457px;letter-spacing:-0.14px;word-spacing:6.09px;}
#tc_72{left:139px;bottom:437px;letter-spacing:-0.09px;}
#td_72{left:223px;bottom:437px;letter-spacing:-0.28px;}
#te_72{left:309px;bottom:437px;letter-spacing:-0.16px;}
#tf_72{left:457px;bottom:437px;letter-spacing:-0.09px;}
#tg_72{left:527px;bottom:437px;letter-spacing:-0.1px;}
#th_72{left:607px;bottom:437px;letter-spacing:-0.1px;}
#ti_72{left:139px;bottom:417px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tj_72{left:583px;bottom:417px;}
#tk_72{left:139px;bottom:392px;}
#tl_72{left:165px;bottom:392px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tm_72{left:318px;bottom:392px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tn_72{left:139px;bottom:368px;}
#to_72{left:165px;bottom:368px;letter-spacing:-0.16px;}
#tp_72{left:239px;bottom:368px;letter-spacing:-0.11px;}
#tq_72{left:339px;bottom:368px;letter-spacing:-0.25px;}
#tr_72{left:383px;bottom:368px;letter-spacing:-0.07px;}
#ts_72{left:421px;bottom:368px;letter-spacing:-0.15px;}
#tt_72{left:576px;bottom:368px;letter-spacing:-0.1px;}
#tu_72{left:165px;bottom:348px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tv_72{left:139px;bottom:323px;}
#tw_72{left:165px;bottom:323px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_72{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_72{font-size:21px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_72{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_72{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_72{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_72{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_72{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s7_72{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s8_72{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts72" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg72" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="72/72.svg"
          type="image/svg+xml"
          id="pdf72"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_72" class="t s0_72">
          72{" "}
        </span>
        <span id="t2_72" class="t s1_72">
          3.1. СУРАЛЦАГЧИЙН ХӨГЖИЛ, ХАМГААЛЛЫГ ХЭРХЭН{" "}
        </span>
        <span id="t3_72" class="t s1_72">
          НЭМЭГДҮҮЛЭХ ВЭ?{" "}
        </span>
        <span id="t4_72" class="t s2_72">
          Сурагчийн хөгжил, нөлөөллийн хүчин зүйлсийг яагаад авч үздэг вэ?{" "}
        </span>
        <span id="t5_72" class="t s3_72">
          Хүүхдийн хөгжилд төрөлхийн өгөгдөл буюу биологийн онцлог, нийгэм-{" "}
        </span>
        <span id="t6_72" class="t s3_72">
          эдийн засгийн хөгжил, сургалт хөгжлийн үйл ажиллагаа, гэр бүлийн
          орчин,{" "}
        </span>
        <span id="t7_72" class="t s3_72">
          амьжиргааны түвшин, хүмүүс хоорондын харилцаа хандлага гол нөлөөтэй.{" "}
        </span>
        <span id="t8_72" class="t s4_72">
          Зураг 3.1.{" "}
        </span>
        <span id="t9_72" class="t s4_72">
          Хүүхдийн хөгжлийн бүрдэл{" "}
        </span>
        <span id="ta_72" class="t s5_72">
          АСУУДАЛ{" "}
        </span>
        <span id="tb_72" class="t s6_72">
          Сургуулийн орчинд хүүхэд чөлөөтэй хөдөлгөөн хийх (гүйж,{" "}
        </span>
        <span id="tc_72" class="t s6_72">
          харайх){" "}
        </span>
        <span id="td_72" class="t s6_72">
          боломж{" "}
        </span>
        <span id="te_72" class="t s6_72">
          хязгаарагдмал,{" "}
        </span>
        <span id="tf_72" class="t s6_72">
          чанар{" "}
        </span>
        <span id="tg_72" class="t s6_72">
          муутай{" "}
        </span>
        <span id="th_72" class="t s6_72">
          хүнсний{" "}
        </span>
        <span id="ti_72" class="t s6_72">
          бүтээгдэхүүнийг хяналтгүй хэрэглэх нөхцөл бүрдсэн
        </span>
        <span id="tj_72" class="t s3_72">
          .{" "}
        </span>
        <span id="tk_72" class="t s7_72">
          •{" "}
        </span>
        <span id="tl_72" class="t s3_72">
          Шүдний өвчлөл их{" "}
        </span>
        <span id="tm_72" class="t s8_72">
          (Олон өвчлөлийн суурь шалтгаан болдог){" "}
        </span>
        <span id="tn_72" class="t s7_72">
          •{" "}
        </span>
        <span id="to_72" class="t s3_72">
          Хүүхэд{" "}
        </span>
        <span id="tp_72" class="t s3_72">
          дэлгэцийн{" "}
        </span>
        <span id="tq_72" class="t s3_72">
          хэт{" "}
        </span>
        <span id="tr_72" class="t s3_72">
          их{" "}
        </span>
        <span id="ts_72" class="t s3_72">
          хамараалтайгаас{" "}
        </span>
        <span id="tt_72" class="t s3_72">
          хөдөлгөөний{" "}
        </span>
        <span id="tu_72" class="t s3_72">
          хомсдолтой, хөдөлгөөний дадал сул{" "}
        </span>
        <span id="tv_72" class="t s7_72">
          •{" "}
        </span>
        <span id="tw_72" class="t s3_72">
          Хүүхдийн бие бялдрын тэсвэр тэвчээртэй байдал хангалтгүй{" "}
        </span>
      </div>
    </div>
  );
}
