import React from "react";
export default function Ninety() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_90{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_90{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:0.23px;}
#t3_90{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:4.15px;}
#t4_90{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:-0.18px;}
#t5_90{left:87px;bottom:880px;letter-spacing:-0.12px;word-spacing:-1.2px;}
#t6_90{left:87px;bottom:860px;letter-spacing:-0.13px;word-spacing:-0.08px;}
#t7_90{left:87px;bottom:840px;letter-spacing:-0.25px;word-spacing:0.15px;}
#t8_90{left:97px;bottom:808px;}
#t9_90{left:125px;bottom:807px;letter-spacing:-0.49px;word-spacing:3.44px;}
#ta_90{left:125px;bottom:787px;letter-spacing:-0.49px;word-spacing:0.06px;}
#tb_90{left:97px;bottom:763px;}
#tc_90{left:125px;bottom:762px;letter-spacing:-0.45px;word-spacing:-0.67px;}
#td_90{left:125px;bottom:742px;letter-spacing:-0.49px;word-spacing:0.62px;}
#te_90{left:125px;bottom:722px;letter-spacing:-0.49px;word-spacing:0.06px;}
#tf_90{left:97px;bottom:698px;}
#tg_90{left:125px;bottom:697px;letter-spacing:-0.14px;word-spacing:2.47px;}
#th_90{left:125px;bottom:677px;letter-spacing:-0.09px;}
#ti_90{left:97px;bottom:653px;}
#tj_90{left:125px;bottom:653px;letter-spacing:-0.12px;word-spacing:0.16px;}
#tk_90{left:125px;bottom:633px;letter-spacing:-0.15px;word-spacing:-1.26px;}
#tl_90{left:125px;bottom:612px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tm_90{left:97px;bottom:589px;}
#tn_90{left:125px;bottom:588px;letter-spacing:-0.64px;word-spacing:2.24px;}
#to_90{left:125px;bottom:568px;letter-spacing:-0.65px;word-spacing:0.06px;}
#tp_90{left:87px;bottom:518px;letter-spacing:-0.04px;word-spacing:0.04px;}
#tq_90{left:87px;bottom:480px;letter-spacing:-0.17px;word-spacing:4.68px;}
#tr_90{left:87px;bottom:460px;letter-spacing:-0.13px;word-spacing:5.41px;}
#ts_90{left:87px;bottom:440px;letter-spacing:-0.16px;word-spacing:5.18px;}
#tt_90{left:87px;bottom:420px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tu_90{left:339px;bottom:420px;}
#tv_90{left:87px;bottom:379px;letter-spacing:-0.18px;word-spacing:-0.38px;}
#tw_90{left:87px;bottom:359px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tx_90{left:122px;bottom:316px;letter-spacing:0.08px;word-spacing:0.06px;}
#ty_90{left:107px;bottom:297px;letter-spacing:0.11px;word-spacing:0.03px;}
#tz_90{left:376px;bottom:316px;letter-spacing:0.09px;word-spacing:0.04px;}
#t10_90{left:409px;bottom:297px;letter-spacing:0.09px;word-spacing:0.05px;}
#t11_90{left:87px;bottom:158px;letter-spacing:0.05px;}
#t12_90{left:229px;bottom:158px;}
#t13_90{left:308px;bottom:158px;letter-spacing:0.09px;}
#t14_90{left:365px;bottom:158px;letter-spacing:0.09px;}
#t15_90{left:414px;bottom:158px;letter-spacing:0.02px;}
#t16_90{left:523px;bottom:158px;letter-spacing:-0.07px;}
#t17_90{left:597px;bottom:158px;letter-spacing:-0.12px;}
#t18_90{left:87px;bottom:138px;letter-spacing:0.03px;word-spacing:-1.38px;}
#t19_90{left:300px;bottom:138px;letter-spacing:0.02px;word-spacing:-1.38px;}
#t1a_90{left:87px;bottom:118px;letter-spacing:0.01px;word-spacing:0.06px;}

.s0_90{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_90{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_90{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_90{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_90{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_90{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts90" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg90" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="90/90.svg"
          type="image/svg+xml"
          id="pdf90"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_90" class="t s0_90">
          90{" "}
        </span>
        <span id="t2_90" class="t s1_90">
          бүдүүлэг харилцаанд өртөх аваас хөдөлмөрийн сэтгэл ханамжгүй байдалд{" "}
        </span>
        <span id="t3_90" class="t s1_90">
          орж бухимдал стрессээ ухамсрын ба ухамсрын бус түвшинд сурагчдад{" "}
        </span>
        <span id="t4_90" class="t s1_90">
          гаргах, тэдний дунд ямар нэгэн байдлаар сурагч хохирох, сургалт
          хөгжлийн{" "}
        </span>
        <span id="t5_90" class="t s1_90">
          үйл ажиллагааны чанар доголдох, ажлын байрны шилжилт хөдөлгөөн үүсэж{" "}
        </span>
        <span id="t6_90" class="t s1_90">
          мэргэжлийн багшийн хомсдолд орох зэргээр сурагчийн хөгжил хамгааллын{" "}
        </span>
        <span id="t7_90" class="t s1_90">
          асуудлыг бий болгодог. Иймээс{" "}
        </span>
        <span id="t8_90" class="t s2_90">
          -{" "}
        </span>
        <span id="t9_90" class="t s1_90">
          Шат шатны удирдлага, багш, ажилтантай харилцах ёс зүйн дүрэмтэй,{" "}
        </span>
        <span id="ta_90" class="t s1_90">
          түүнийгээ таниулан сурталчлах, мөрдөх санкцтай байх{" "}
        </span>
        <span id="tb_90" class="t s2_90">
          -{" "}
        </span>
        <span id="tc_90" class="t s1_90">
          Багш, ажилтанд нөлөөлөх, зөвлөн дэмжих үйл нь багшийг сэтгэл санааны{" "}
        </span>
        <span id="td_90" class="t s1_90">
          дарамт, зовнилд оруулахаас зайлсхийсэн, ардчилсан тэгш, хүндэтгэлтэй{" "}
        </span>
        <span id="te_90" class="t s1_90">
          харилцаанд тулгуурлахыг үйл ажиллагааны зарчим болгох{" "}
        </span>
        <span id="tf_90" class="t s2_90">
          -{" "}
        </span>
        <span id="tg_90" class="t s1_90">
          Хувь хүний эерэг чанарыг тодруулж, өөрийн үнэлэмжид нь сөрөг ул{" "}
        </span>
        <span id="th_90" class="t s1_90">
          мөр үлдээхээс сэргийлдэг байх{" "}
        </span>
        <span id="ti_90" class="t s2_90">
          -{" "}
        </span>
        <span id="tj_90" class="t s1_90">
          Хамт олны дунд багш, ажилтны нэр хүндийг ямагт эрхэмлэх, багш хүн{" "}
        </span>
        <span id="tk_90" class="t s1_90">
          олны дунд сэтгэл гонсойсон харагдах дургүй байдгийг тооцож харилцах{" "}
        </span>
        <span id="tl_90" class="t s1_90">
          чадвартай байх{" "}
        </span>
        <span id="tm_90" class="t s2_90">
          -{" "}
        </span>
        <span id="tn_90" class="t s1_90">
          Багш, ажилтан санаа бодол, туршлагаа чөлөөтэй илэрхийлэх бололцоог{" "}
        </span>
        <span id="to_90" class="t s1_90">
          бүрдүүлэх зэргийг байгууллагын соёл болгон төлөвшүүлэх шаардлагатай.{" "}
        </span>
        <span id="tp_90" class="t s3_90">
          Сурагчийн хөгжил, хамгааллын өөрийн үнэлгээ хийх хэрэгсэл{" "}
        </span>
        <span id="tq_90" class="t s1_90">
          Сурагчийн хөгжил хамгаалалтай холбоотой тулгамдаж байгаа асуудлыг{" "}
        </span>
        <span id="tr_90" class="t s1_90">
          илрүүлэн үйл ажиллагааны зорилго, зорилт болгон дэвшүүлж, түүнийг{" "}
        </span>
        <span id="ts_90" class="t s1_90">
          сайжруулахын тулд удирдлага, багш, ажилтан өөрийн үнэлгээ хийх нь{" "}
        </span>
        <span id="tt_90" class="t s1_90">
          асуудлыг олж харах гол арга юм
        </span>
        <span id="tu_90" class="t s4_90">
          .{" "}
        </span>
        <span id="tv_90" class="t s1_90">
          Үр дүнд хэд хэдэн бодомж асуудал болж байгаа бол ач холбогдлын түвшин,{" "}
        </span>
        <span id="tw_90" class="t s1_90">
          шийдвэрлэх боломжоор нь эрэмбэлж болно.{" "}
        </span>
        <span id="tx_90" class="t s2_90">
          Хүүхэд хамгааллын асуудлыг{" "}
        </span>
        <span id="ty_90" class="t s2_90">
          илрүүлэх өөрийн үнэлгээний арга{" "}
        </span>
        <span id="tz_90" class="t s2_90">
          Байгууллагын сэтгэл зүйн уур амьсгалыг{" "}
        </span>
        <span id="t10_90" class="t s2_90">
          судлах өөрийн үнэлгээний арга{" "}
        </span>
        <span id="t11_90" class="t s5_90">
          “Байгууллагын{" "}
        </span>
        <span id="t12_90" class="t s5_90">
          сэтгэл{" "}
        </span>
        <span id="t13_90" class="t s5_90">
          зүйн{" "}
        </span>
        <span id="t14_90" class="t s5_90">
          уур{" "}
        </span>
        <span id="t15_90" class="t s5_90">
          амьсгалыг{" "}
        </span>
        <span id="t16_90" class="t s5_90">
          үнэлэх{" "}
        </span>
        <span id="t17_90" class="t s5_90">
          аргачлал”{" "}
        </span>
        <span id="t18_90" class="t s1_90">
          (А.А.Урбанович, 2003) - ыг{" "}
        </span>
        <span id="t19_90" class="t s1_90">
          ашиглан сургуулийн харилцааны уур амьсгалыг{" "}
        </span>
        <span id="t1a_90" class="t s1_90">
          тодорхойлж, түүнийг сайжруулах арга хэмжээ авч болно.{" "}
        </span>
      </div>
    </div>
  );
}
