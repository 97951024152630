import React from "react";
export default function SixtySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_67{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_67{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_67{left:226px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t4_67{left:87px;bottom:622px;letter-spacing:-0.14px;word-spacing:9.57px;}
#t5_67{left:87px;bottom:602px;letter-spacing:-0.16px;word-spacing:3.46px;}
#t6_67{left:87px;bottom:582px;letter-spacing:-0.15px;word-spacing:4.11px;}
#t7_67{left:87px;bottom:562px;letter-spacing:-0.13px;word-spacing:2.51px;}
#t8_67{left:87px;bottom:542px;letter-spacing:-0.14px;word-spacing:-0.13px;}
#t9_67{left:87px;bottom:522px;letter-spacing:-0.12px;word-spacing:5.89px;}
#ta_67{left:87px;bottom:501px;letter-spacing:-0.14px;word-spacing:1.61px;}
#tb_67{left:87px;bottom:481px;letter-spacing:-0.14px;word-spacing:-0.11px;}
#tc_67{left:87px;bottom:461px;letter-spacing:-0.15px;word-spacing:0.05px;}
#td_67{left:215px;bottom:426px;letter-spacing:-0.14px;word-spacing:0.02px;}

.s0_67{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_67{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_67{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s3_67{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_67{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_67{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts67" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg67" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="67/67.svg"
          type="image/svg+xml"
          id="pdf67"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_67" class="t s1_67">
          67{" "}
        </span>
        <span id="t3_67" class="t s2_67">
          Зураг 2.18. Багшийн үнэлгээ (бусад шалгуураар){" "}
        </span>
        <span id="t4_67" class="t s3_67">
          Харин тухайн шалгуурт харгалзах үзүүлэлтийн хувьд ямар үнэлгээ{" "}
        </span>
        <span id="t5_67" class="t s3_67">
          авсныг дараагийн цонхноос харах боломжтой. (Зураг 2.19). Тодруулбал,{" "}
        </span>
        <span id="t6_67" class="t s3_67">
          сургууль тухайн шалгуурт харгалзах үзүүлэлт бүрд авсан оноогоо харж{" "}
        </span>
        <span id="t7_67" class="t s3_67">
          болно. Жишээлбэл, А сургуулийн хувьд 2023 онд “Суралцагчийн хөгжил,{" "}
        </span>
        <span id="t8_67" class="t s3_67">
          хамгаалал, төлөвшил” нь бусад шалгуураас бага үзүүлэлттэй байна. Харин{" "}
        </span>
        <span id="t9_67" class="t s3_67">
          энэ шалгуурын хувьд 2.1, 2.2, 2.3 үзүүлэлтүүдээс “Сурагч хамгаалал”{" "}
        </span>
        <span id="ta_67" class="t s3_67">
          гэсэн үзүүлэлт хамгийн бага байна. Иймд тухайн сургууль энэ шалгуурын{" "}
        </span>
        <span id="tb_67" class="t s3_67">
          хувьд хэдэн багшийн үнэлгээний үр дүнг ахиулах хэрэгцээтэйг
          тодорхойлж,{" "}
        </span>
        <span id="tc_67" class="t s3_67">
          дэмжих үйл ажиллагаагаа тодорхойлно.{" "}
        </span>
        <span id="td_67" class="t s4_67">
          Зураг 2.19. Багшийн үнэлгээ (шалгуур, үзүүлэлтээр){" "}
        </span>
      </div>
    </div>
  );
}
