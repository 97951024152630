import React from "react";
export default function EightyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_85{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_85{left:87px;bottom:941px;}
#t3_85{left:114px;bottom:941px;letter-spacing:-0.14px;word-spacing:1.81px;}
#t4_85{left:114px;bottom:921px;letter-spacing:-0.12px;word-spacing:2.8px;}
#t5_85{left:114px;bottom:901px;letter-spacing:-0.16px;word-spacing:3.79px;}
#t6_85{left:114px;bottom:880px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t7_85{left:87px;bottom:856px;}
#t8_85{left:114px;bottom:856px;letter-spacing:-0.14px;word-spacing:7.85px;}
#t9_85{left:114px;bottom:836px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ta_85{left:132px;bottom:761px;letter-spacing:-0.01px;}
#tb_85{left:139px;bottom:725px;letter-spacing:-0.14px;word-spacing:2.3px;}
#tc_85{left:139px;bottom:707px;letter-spacing:-0.1px;word-spacing:-0.01px;}
#td_85{left:139px;bottom:680px;}
#te_85{left:165px;bottom:680px;letter-spacing:-0.14px;word-spacing:10.87px;}
#tf_85{left:165px;bottom:662px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tg_85{left:139px;bottom:639px;}
#th_85{left:165px;bottom:639px;letter-spacing:-0.13px;word-spacing:7.16px;}
#ti_85{left:165px;bottom:621px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tj_85{left:139px;bottom:598px;}
#tk_85{left:165px;bottom:598px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tl_85{left:142px;bottom:530px;letter-spacing:0.11px;word-spacing:0.12px;}
#tm_85{left:106px;bottom:472px;letter-spacing:0.12px;word-spacing:-0.23px;}
#tn_85{left:106px;bottom:454px;letter-spacing:0.1px;word-spacing:-0.49px;}
#to_85{left:106px;bottom:435px;letter-spacing:0.12px;word-spacing:3.75px;}
#tp_85{left:106px;bottom:417px;letter-spacing:0.1px;word-spacing:-0.14px;}
#tq_85{left:106px;bottom:399px;letter-spacing:0.13px;word-spacing:4.66px;}
#tr_85{left:106px;bottom:380px;letter-spacing:0.11px;word-spacing:4.53px;}
#ts_85{left:106px;bottom:362px;letter-spacing:0.12px;word-spacing:1.01px;}
#tt_85{left:106px;bottom:344px;letter-spacing:0.12px;word-spacing:4.57px;}
#tu_85{left:106px;bottom:325px;letter-spacing:0.11px;word-spacing:8.77px;}
#tv_85{left:106px;bottom:307px;letter-spacing:0.03px;word-spacing:3.76px;}
#tw_85{left:106px;bottom:289px;letter-spacing:0.06px;}
#tx_85{left:201px;bottom:289px;letter-spacing:0.12px;word-spacing:1.57px;}
#ty_85{left:106px;bottom:270px;letter-spacing:0.1px;word-spacing:1.64px;}
#tz_85{left:106px;bottom:252px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_85{left:343px;bottom:232px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t11_85{left:231px;bottom:215px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t12_85{left:326px;bottom:215px;letter-spacing:-0.16px;word-spacing:0.02px;}

.s0_85{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_85{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_85{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_85{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_85{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_85{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_85{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts85" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg85" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="85/85.svg"
          type="image/svg+xml"
          id="pdf85"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_85" class="t s0_85">
          85{" "}
        </span>
        <span id="t2_85" class="t s1_85">
          •{" "}
        </span>
        <span id="t3_85" class="t s2_85">
          Сургуулийн орчин нөхцөлийг стандарт шаардлагын дагуу сайжруулах,{" "}
        </span>
        <span id="t4_85" class="t s2_85">
          тухтай орчныг бүрдүүлэн ажиллах, ялангуяа, ариун цэврийн өрөөний{" "}
        </span>
        <span id="t5_85" class="t s2_85">
          стандарт, эрүүл ахуй, ариун цэврийг тогтмол хангаж ажиллах дүрэм{" "}
        </span>
        <span id="t6_85" class="t s2_85">
          журмыг дагаж мөрддөг байх{" "}
        </span>
        <span id="t7_85" class="t s1_85">
          •{" "}
        </span>
        <span id="t8_85" class="t s2_85">
          Боловсролын тусгай хэрэгцээт сурагчийн онцлогт тохирсон орчин{" "}
        </span>
        <span id="t9_85" class="t s2_85">
          нөхцөлийг бүрдүүлэх{" "}
        </span>
        <span id="ta_85" class="t s3_85">
          АСУУДАЛ{" "}
        </span>
        <span id="tb_85" class="t s4_85">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч, сургууль, олон{" "}
        </span>
        <span id="tc_85" class="t s4_85">
          нийтийн хамтын ажиллагаа дутмаг{" "}
        </span>
        <span id="td_85" class="t s1_85">
          •{" "}
        </span>
        <span id="te_85" class="t s2_85">
          Хүүхдийн хөгжил, хамгаалалд эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="tf_85" class="t s2_85">
          харгалзан дэмжигч, олон нийтийн оролцоо хангалтгүй{" "}
        </span>
        <span id="tg_85" class="t s1_85">
          •{" "}
        </span>
        <span id="th_85" class="t s2_85">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч хүүхэдтэйгээ{" "}
        </span>
        <span id="ti_85" class="t s2_85">
          хүмүүжлийн эерэг арга барилаар ханддаг байх{" "}
        </span>
        <span id="tj_85" class="t s1_85">
          •{" "}
        </span>
        <span id="tk_85" class="t s2_85">
          Гэр бүлийн орчинд хүүхдийн эрх зөрчигдөх{" "}
        </span>
        <span id="tl_85" class="t s3_85">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tm_85" class="t s5_85">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч, эрүүл амьдралын хэв
          маягийг{" "}
        </span>
        <span id="tn_85" class="t s5_85">
          сурталчлан таниулах, хүүхэдтэйгээ насны онцлогт нь тохируулан
          хүмүүжлийн{" "}
        </span>
        <span id="to_85" class="t s5_85">
          зөв аргаар хандах болон эрүүл аюулгүй орчинд хүүхдийг өсгөх тал дээр{" "}
        </span>
        <span id="tp_85" class="t s5_85">
          зөвлөн туслах ажлыг хийж гүйцэтгэжээ. Эцэг эх, асран хамгаалагч,
          харгалзан{" "}
        </span>
        <span id="tq_85" class="t s5_85">
          дэмжигч, дуу хоолойг сонсох аян өрнүүлж, тэдний санаа бодлыг тусгаж{" "}
        </span>
        <span id="tr_85" class="t s5_85">
          ажиллах үйл ажиллагаа хийгджээ. Эцэг эх, асран хамгаалагч, харгалзан{" "}
        </span>
        <span id="ts_85" class="t s5_85">
          дэмжигч, багш нарын дунд эерэг хандлагыг буй болгох “цахим захидал”
          үйл{" "}
        </span>
        <span id="tt_85" class="t s5_85">
          ажиллагааг зохион байгуулжээ. 2023.04.15-ны өдөр мэргэжил нэгт асран{" "}
        </span>
        <span id="tu_85" class="t s5_85">
          хамгаалагчийн өдөрлөгийг 9-10-р ангийн сурагчдыг хамруулан зохион{" "}
        </span>
        <span id="tv_85" class="t s5_85">
          байгууллаа.Уг өдөрлөгөөр эмч, цэрэг, цагдаа, эдийн засагч, хуульч,
          багш,{" "}
        </span>
        <span id="tw_85" class="t s5_85">
          технологич,{" "}
        </span>
        <span id="tx_85" class="t s5_85">
          зэрэг мэргэжилтэй 8 асран хамгаалагч нар оролцон сурагчдад{" "}
        </span>
        <span id="ty_85" class="t s5_85">
          ажил мэргэжлийн онцлог, дэвшин хөгжих боломж, цалин хөлсний мэдээлэл{" "}
        </span>
        <span id="tz_85" class="t s5_85">
          зэргийг танилцуулж зөвлөгөө өгчээ.{" "}
        </span>
        <span id="t10_85" class="t s6_85">
          ... нийслэлийн ... дүүргийн ерөнхий боловсролын{" "}
        </span>
        <span id="t11_85" class="t s6_85">
          ... сургуулийн{" "}
        </span>
        <span id="t12_85" class="t s6_85">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
