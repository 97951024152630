import React from "react";
export default function FiftyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_58{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_58{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_58{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:3.44px;}
#t4_58{left:87px;bottom:921px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t5_58{left:87px;bottom:901px;letter-spacing:-0.05px;}
#t6_58{left:114px;bottom:901px;letter-spacing:-0.12px;word-spacing:-2.26px;}
#t7_58{left:114px;bottom:880px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_58{left:87px;bottom:847px;letter-spacing:-0.05px;}
#t9_58{left:114px;bottom:847px;letter-spacing:-0.13px;word-spacing:-1.15px;}
#ta_58{left:114px;bottom:827px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tb_58{left:87px;bottom:794px;letter-spacing:-0.05px;}
#tc_58{left:114px;bottom:794px;letter-spacing:-0.13px;word-spacing:2.42px;}
#td_58{left:114px;bottom:774px;letter-spacing:-0.14px;word-spacing:5.13px;}
#te_58{left:114px;bottom:754px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tf_58{left:113px;bottom:729px;letter-spacing:-0.12px;word-spacing:4.11px;}
#tg_58{left:139px;bottom:709px;letter-spacing:-0.16px;}
#th_58{left:113px;bottom:689px;letter-spacing:-0.12px;word-spacing:-1.32px;}
#ti_58{left:139px;bottom:669px;letter-spacing:-0.17px;}
#tj_58{left:113px;bottom:648px;letter-spacing:-0.11px;word-spacing:-1.12px;}
#tk_58{left:139px;bottom:628px;letter-spacing:-0.17px;}
#tl_58{left:87px;bottom:595px;letter-spacing:-0.05px;}
#tm_58{left:114px;bottom:595px;letter-spacing:-0.11px;word-spacing:1.71px;}
#tn_58{left:114px;bottom:575px;letter-spacing:-0.14px;word-spacing:1.45px;}
#to_58{left:114px;bottom:555px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tp_58{left:87px;bottom:522px;letter-spacing:-0.13px;word-spacing:4.89px;}
#tq_58{left:87px;bottom:501px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tr_58{left:249px;bottom:465px;letter-spacing:-0.17px;word-spacing:0.05px;}

.s0_58{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_58{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_58{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_58{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_58{font-size:14px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts58" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg58" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="58/58.svg"
          type="image/svg+xml"
          id="pdf58"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_58" class="t s1_58">
          58{" "}
        </span>
        <span id="t3_58" class="t s2_58">
          Сургалтын байгууллагын гүйцэтгэлийн үнэлгээтэй холбоотой мэдээллийг{" "}
        </span>
        <span id="t4_58" class="t s2_58">
          авахад дараах зүйлсийг анхаарна. Үүнд:{" "}
        </span>
        <span id="t5_58" class="t s3_58">
          1.{" "}
        </span>
        <span id="t6_58" class="t s2_58">
          Сургуулийн дунджийн өөрчлөлтийг хоёр оноор харьцуулахдаа индексийн{" "}
        </span>
        <span id="t7_58" class="t s2_58">
          үзүүлэлттэй уялдуулан хараарай.{" "}
        </span>
        <span id="t8_58" class="t s3_58">
          2.{" "}
        </span>
        <span id="t9_58" class="t s2_58">
          Сургуулийн дунджийг өөрийн сургуулийн хамаарах кластер болон улсын{" "}
        </span>
        <span id="ta_58" class="t s2_58">
          дунджийн өөрчлөлттэй хоёр оноор харьцуулан хараарай.{" "}
        </span>
        <span id="tb_58" class="t s3_58">
          3.{" "}
        </span>
        <span id="tc_58" class="t s2_58">
          Сургуулийн дунджийг шалгуур тус бүрээр, хоёр оноор харьцуулахдаа{" "}
        </span>
        <span id="td_58" class="t s2_58">
          улс болон хамаарах кластерын дундажтай харьцуулж, ялгаа болон{" "}
        </span>
        <span id="te_58" class="t s2_58">
          өөрчлөлтийг нягтална. Үүнд:{" "}
        </span>
        <span id="tf_58" class="t s2_58">
          а. Шалгуур бүрийн хувьд өссөн болон буурсан, өөрчлөлтгүй зэргийг{" "}
        </span>
        <span id="tg_58" class="t s2_58">
          нягтлах{" "}
        </span>
        <span id="th_58" class="t s2_58">
          б. Аль шалгуур дээр буурсныг харахдаа улс болон кластерын дундажтай{" "}
        </span>
        <span id="ti_58" class="t s2_58">
          харьцуулах{" "}
        </span>
        <span id="tj_58" class="t s2_58">
          в. Аль шалгуур дээр өссөнийг харахдаа улс болон кластерын дундажтай{" "}
        </span>
        <span id="tk_58" class="t s2_58">
          харьцуулах{" "}
        </span>
        <span id="tl_58" class="t s3_58">
          4.{" "}
        </span>
        <span id="tm_58" class="t s2_58">
          Шалгуур тус бүрээр улсын дундаж болон түүнээс дээгүүр үзүүлэлттэй{" "}
        </span>
        <span id="tn_58" class="t s2_58">
          болоход ямар зорилгоор, ямар үйл ажиллагааг ямар хугацаанд, хэний{" "}
        </span>
        <span id="to_58" class="t s2_58">
          оролцоотой хэрэгжүүлэх шаардлагатайг тодорхойлоорой.{" "}
        </span>
        <span id="tp_58" class="t s2_58">
          Энд үзүүлсэн ерөнхий алхмуудаас гадна мэдээллийг задалж харахдаа{" "}
        </span>
        <span id="tq_58" class="t s2_58">
          Зураг 2.9-ийг сайтар ажиглаарай.{" "}
        </span>
        <span id="tr_58" class="t s4_58">
          Зураг 2.9. Мэдээллийг татаж авах хэлбэр{" "}
        </span>
      </div>
    </div>
  );
}
