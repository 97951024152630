import React from "react";
export default function OneHundredEightySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_186{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_186{left:87px;bottom:661px;letter-spacing:-0.18px;word-spacing:4.59px;}
#t3_186{left:87px;bottom:640px;letter-spacing:-0.14px;word-spacing:0.31px;}
#t4_186{left:87px;bottom:620px;letter-spacing:-0.17px;word-spacing:6.7px;}
#t5_186{left:87px;bottom:600px;letter-spacing:-0.17px;word-spacing:11.03px;}
#t6_186{left:87px;bottom:580px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_186{left:142px;bottom:522px;letter-spacing:-0.03px;}
#t8_186{left:143px;bottom:489px;letter-spacing:-0.48px;word-spacing:0.04px;}
#t9_186{left:143px;bottom:449px;}
#ta_186{left:169px;bottom:449px;letter-spacing:-0.16px;word-spacing:11.48px;}
#tb_186{left:169px;bottom:429px;letter-spacing:-0.17px;word-spacing:1.95px;}
#tc_186{left:169px;bottom:408px;letter-spacing:-0.13px;word-spacing:0.03px;}
#td_186{left:143px;bottom:384px;}
#te_186{left:169px;bottom:384px;letter-spacing:-0.16px;word-spacing:6.14px;}
#tf_186{left:169px;bottom:364px;letter-spacing:-0.13px;word-spacing:4.57px;}
#tg_186{left:169px;bottom:344px;letter-spacing:-0.16px;word-spacing:0.06px;}
#th_186{left:143px;bottom:319px;}
#ti_186{left:169px;bottom:319px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tj_186{left:143px;bottom:295px;}
#tk_186{left:169px;bottom:295px;letter-spacing:-0.16px;word-spacing:6.8px;}
#tl_186{left:169px;bottom:274px;letter-spacing:-0.12px;}
#tm_186{left:232px;bottom:274px;letter-spacing:-0.15px;word-spacing:2.92px;}
#tn_186{left:469px;bottom:274px;letter-spacing:-0.15px;word-spacing:2.92px;}
#to_186{left:169px;bottom:254px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tp_186{left:381px;bottom:254px;letter-spacing:-0.15px;}

.s0_186{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_186{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s2_186{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_186{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s4_186{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_186{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts186" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg186" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="186/186.svg"
          type="image/svg+xml"
          id="pdf186"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_186" class="t s0_186">
          186{" "}
        </span>
        <span id="t2_186" class="t s1_186">
          Сургуулийн удирдлагуудын эв нэгдэл нь боловсролын байгууллагын үр{" "}
        </span>
        <span id="t3_186" class="t s1_186">
          дүнтэй үйл ажиллагаа, амжилтад чухал үүрэгтэй. Сургуулийн удирдлагууд,{" "}
        </span>
        <span id="t4_186" class="t s1_186">
          тэр дундаа захиргаа, багш, ажилчид, удирдах байгууллагууд хамтран{" "}
        </span>
        <span id="t5_186" class="t s1_186">
          ажиллаж чадвал сурагчдад эерэг, үр бүтээлтэй орчныг бүрдүүлж,{" "}
        </span>
        <span id="t6_186" class="t s1_186">
          байгууллагын нийт амжилтад хувь нэмрээ оруулж чадна.{" "}
        </span>
        <span id="t7_186" class="t s2_186">
          АСУУДАЛ{" "}
        </span>
        <span id="t8_186" class="t s3_186">
          Багш нарыг тогтвортой ажиллуулах бодлого барьж ажилладаггүй{" "}
        </span>
        <span id="t9_186" class="t s4_186">
          •{" "}
        </span>
        <span id="ta_186" class="t s5_186">
          Дутагдалтай багшийн сонгон шалгаруулалтын зар мэдээг{" "}
        </span>
        <span id="tb_186" class="t s5_186">
          аймаг, сумын нээлттэй группт тавьж, багшийн цагийн ачааллыг{" "}
        </span>
        <span id="tc_186" class="t s5_186">
          тэнцвэржүүлж мэдээллээр хангадаггүй{" "}
        </span>
        <span id="td_186" class="t s4_186">
          •{" "}
        </span>
        <span id="te_186" class="t s5_186">
          Өөр орон нутгаас шилжин ирж буй багш нарыг ялгаварлан{" "}
        </span>
        <span id="tf_186" class="t s5_186">
          гадуурхахгүй хүн ёсоор тэгш, хүртээмжтэй харилцан хамтран{" "}
        </span>
        <span id="tg_186" class="t s5_186">
          ажиллах талаар дутмаг. Мөн багш бүртэй гэрээ хийдэггүй{" "}
        </span>
        <span id="th_186" class="t s4_186">
          •{" "}
        </span>
        <span id="ti_186" class="t s5_186">
          Хамт олны нийгмийн асуудлыг дэмжих үйл ажиллагаа сул{" "}
        </span>
        <span id="tj_186" class="t s4_186">
          •{" "}
        </span>
        <span id="tk_186" class="t s5_186">
          Сургуулийн нэгдсэн төлөвлөлт тодорхой биш, гэнэтин ажил{" "}
        </span>
        <span id="tl_186" class="t s5_186">
          ихтэй,{" "}
        </span>
        <span id="tm_186" class="t s5_186">
          менежмент сул хийгддэгээс{" "}
        </span>
        <span id="tn_186" class="t s5_186">
          болж, багш нарын ажлын{" "}
        </span>
        <span id="to_186" class="t s5_186">
          бүтээмж буурч, стресстэж{" "}
        </span>
        <span id="tp_186" class="t s5_186">
          ядардаг{" "}
        </span>
      </div>
    </div>
  );
}
