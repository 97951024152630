import React from "react";
export default function OneHundredEightySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_187{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_187{left:147px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_187{left:106px;bottom:856px;letter-spacing:0.08px;word-spacing:3.44px;}
#t4_187{left:106px;bottom:838px;letter-spacing:0.1px;word-spacing:1.31px;}
#t5_187{left:106px;bottom:820px;letter-spacing:0.1px;word-spacing:3.23px;}
#t6_187{left:106px;bottom:801px;letter-spacing:0.05px;word-spacing:1.15px;}
#t7_187{left:106px;bottom:783px;letter-spacing:0.05px;}
#t8_187{left:230px;bottom:783px;letter-spacing:0.1px;word-spacing:2.38px;}
#t9_187{left:106px;bottom:765px;letter-spacing:0.11px;word-spacing:2.58px;}
#ta_187{left:106px;bottom:746px;letter-spacing:0.09px;word-spacing:1.95px;}
#tb_187{left:106px;bottom:728px;letter-spacing:0.1px;word-spacing:3.96px;}
#tc_187{left:106px;bottom:710px;letter-spacing:0.13px;word-spacing:0.01px;}
#td_187{left:154px;bottom:687px;letter-spacing:0.1px;word-spacing:0.03px;}
#te_187{left:480px;bottom:688px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tf_187{left:429px;bottom:671px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tg_187{left:106px;bottom:581px;letter-spacing:0.14px;word-spacing:2.41px;}
#th_187{left:209px;bottom:581px;letter-spacing:0.12px;word-spacing:2.45px;}
#ti_187{left:106px;bottom:563px;letter-spacing:0.09px;word-spacing:5.57px;}
#tj_187{left:106px;bottom:545px;letter-spacing:0.12px;word-spacing:4.29px;}
#tk_187{left:276px;bottom:545px;letter-spacing:0.09px;word-spacing:4.31px;}
#tl_187{left:106px;bottom:526px;letter-spacing:0.12px;word-spacing:-0.56px;}
#tm_187{left:274px;bottom:526px;letter-spacing:0.09px;word-spacing:-0.54px;}
#tn_187{left:106px;bottom:508px;letter-spacing:0.09px;word-spacing:3.07px;}
#to_187{left:106px;bottom:490px;letter-spacing:0.12px;word-spacing:0.02px;}
#tp_187{left:154px;bottom:463px;letter-spacing:0.1px;word-spacing:0.03px;}
#tq_187{left:480px;bottom:463px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tr_187{left:429px;bottom:447px;letter-spacing:-0.16px;word-spacing:0.03px;}
#ts_187{left:142px;bottom:365px;letter-spacing:0.18px;}
#tt_187{left:114px;bottom:327px;}
#tu_187{left:142px;bottom:327px;letter-spacing:-0.16px;word-spacing:2.24px;}
#tv_187{left:142px;bottom:307px;letter-spacing:-0.16px;word-spacing:1.08px;}
#tw_187{left:142px;bottom:287px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tx_187{left:114px;bottom:263px;}
#ty_187{left:142px;bottom:263px;letter-spacing:-0.12px;word-spacing:3.67px;}
#tz_187{left:142px;bottom:242px;letter-spacing:-0.13px;word-spacing:2.35px;}
#t10_187{left:142px;bottom:222px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_187{left:114px;bottom:198px;}
#t12_187{left:142px;bottom:198px;letter-spacing:-0.13px;word-spacing:11.06px;}
#t13_187{left:142px;bottom:178px;letter-spacing:-0.17px;word-spacing:2.94px;}
#t14_187{left:142px;bottom:157px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t15_187{left:114px;bottom:133px;}
#t16_187{left:142px;bottom:133px;letter-spacing:-0.17px;word-spacing:0.54px;}

.s0_187{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_187{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_187{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s3_187{font-size:15px;font-family:MogulArial-Italic_17h;color:#202124;}
.s4_187{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_187{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s6_187{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts187" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg187" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="187/187.svg"
          type="image/svg+xml"
          id="pdf187"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_187" class="t s0_187">
          187{" "}
        </span>
        <span id="t2_187" class="t s1_187">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_187" class="t s2_187">
          Сургуулийн удирдлага, багш нартай хамтран ажиллах, илүү таатай орчин{" "}
        </span>
        <span id="t4_187" class="t s2_187">
          бүрдүүлэх зорилгоор “Багш ажилчдаа сонсъё” уулзалтыг зохион байгуулсан{" "}
        </span>
        <span id="t5_187" class="t s2_187">
          нь тодорхой үр дүнгээ өглөө. Багш бүртэй ганцаарчлан уулзаж, нийгмийн{" "}
        </span>
        <span id="t6_187" class="t s2_187">
          асуудал, сургалт явуулахад тулгарч буй бэрхшээл, шаардлагатай
          сургалтын{" "}
        </span>
        <span id="t7_187" class="t s2_187">
          хэрэглэгдэхүүн,{" "}
        </span>
        <span id="t8_187" class="t s2_187">
          хичээл орж буй ангийн сурагч, эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="t9_187" class="t s2_187">
          харгалзан дэмжигч анги удирдсан багшийн сайн тал, дэмжлэг авах санал,{" "}
        </span>
        <span id="ta_187" class="t s2_187">
          багш бүрийн мэргэжил дээшлүүлэх төлөвлөгөө, хамт олны эерэг, эвсэг уур{" "}
        </span>
        <span id="tb_187" class="t s2_187">
          амьсгалыг бий болгоход ямар ажлыг зохион байгуулах, түүнд таны хийж{" "}
        </span>
        <span id="tc_187" class="t s2_187">
          чадах дэмжлэг юу байгаа зэргийг сонсов.{" "}
        </span>
        <span id="td_187" class="t s3_187">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="te_187" class="t s4_187">
          сургуулийн менежментийг{" "}
        </span>
        <span id="tf_187" class="t s4_187">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tg_187" class="t s2_187">
          Шинэ, залуу{" "}
        </span>
        <span id="th_187" class="t s2_187">
          багш нарт мэргэжлийнхээ сорилтыг даван туулж, сургуулийн{" "}
        </span>
        <span id="ti_187" class="t s2_187">
          хамт олонтойгоо илүү холбоотой байхад туслах зорилгоор “Залуу багш{" "}
        </span>
        <span id="tj_187" class="t s2_187">
          нарын уулзалт” - ыг{" "}
        </span>
        <span id="tk_187" class="t s2_187">
          зохион байгуулж, тэдний манлайллыг нэмэгдүүлэх,{" "}
        </span>
        <span id="tl_187" class="t s2_187">
          чадваржуулах үүднээс{" "}
        </span>
        <span id="tm_187" class="t s2_187">
          туршлагатай багшаар шинэ багшийг дэмжиж, удирдан{" "}
        </span>
        <span id="tn_187" class="t s2_187">
          чиглүүлдэг зөвлөх тогтолцоог хэрэгжүүлсэн нь багшийн тогтвортой,
          сэтгэл{" "}
        </span>
        <span id="to_187" class="t s2_187">
          тайван, айдасгүй ажиллах боломжийг нээлээ.{" "}
        </span>
        <span id="tp_187" class="t s3_187">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tq_187" class="t s4_187">
          сургуулийн менежментийг{" "}
        </span>
        <span id="tr_187" class="t s4_187">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ts_187" class="t s1_187">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tt_187" class="t s5_187">
          •{" "}
        </span>
        <span id="tu_187" class="t s6_187">
          Сургууль доторх хүний нөөцийн тогтвортой үйл ажиллагааг хангах{" "}
        </span>
        <span id="tv_187" class="t s6_187">
          нь ажлын таатай орчинг хадгалах, чадварлаг боловсон хүчнийг авч{" "}
        </span>
        <span id="tw_187" class="t s6_187">
          үлдэх, урт хугацааны амжилтад хүрэхэд зайлшгүй шаардлагатай.{" "}
        </span>
        <span id="tx_187" class="t s5_187">
          •{" "}
        </span>
        <span id="ty_187" class="t s6_187">
          Багш, захиргааны ажилтан, туслах ажилтнууд зэрэг бүх ажилтны{" "}
        </span>
        <span id="tz_187" class="t s6_187">
          ажил, амьдралын эрүүл тэнцвэрийг бий болгох. Хэт их ачааллаас{" "}
        </span>
        <span id="t10_187" class="t s6_187">
          зайлсхийж, стрессийг зохицуулахад дэмжлэг үзүүлэх.{" "}
        </span>
        <span id="t11_187" class="t s5_187">
          •{" "}
        </span>
        <span id="t12_187" class="t s6_187">
          Ажилтны байнгын мэргэжил дээшлүүлэх боломж, тухайлбал,{" "}
        </span>
        <span id="t13_187" class="t s6_187">
          семинар, сургалт, шинэ ур чадвар эзэмших боломжуудад хөрөнгө{" "}
        </span>
        <span id="t14_187" class="t s6_187">
          оруулалт хийх.{" "}
        </span>
        <span id="t15_187" class="t s5_187">
          •{" "}
        </span>
        <span id="t16_187" class="t s6_187">
          Хүлээн зөвшөөрөх, үнэлэх соёлыг бий болгох. Эерэг, урам зоригтой{" "}
        </span>
      </div>
    </div>
  );
}
