import React from "react";
export default function OneHundredEightyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_189{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_189{left:129px;bottom:923px;}
#t3_189{left:157px;bottom:923px;letter-spacing:0.12px;word-spacing:0.66px;}
#t4_189{left:440px;bottom:923px;letter-spacing:0.01px;word-spacing:0.76px;}
#t5_189{left:157px;bottom:904px;letter-spacing:0.12px;word-spacing:2.25px;}
#t6_189{left:157px;bottom:886px;letter-spacing:0.11px;word-spacing:0.03px;}
#t7_189{left:129px;bottom:859px;}
#t8_189{left:157px;bottom:859px;letter-spacing:0.11px;word-spacing:2.59px;}
#t9_189{left:450px;bottom:859px;letter-spacing:0.11px;word-spacing:2.59px;}
#ta_189{left:157px;bottom:841px;letter-spacing:0.11px;word-spacing:2.24px;}
#tb_189{left:157px;bottom:822px;letter-spacing:0.15px;}
#tc_189{left:129px;bottom:795px;}
#td_189{left:157px;bottom:795px;letter-spacing:0.14px;word-spacing:-0.71px;}
#te_189{left:328px;bottom:795px;letter-spacing:0.05px;word-spacing:-0.65px;}
#tf_189{left:157px;bottom:777px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_189{left:129px;bottom:750px;}
#th_189{left:157px;bottom:750px;letter-spacing:0.1px;word-spacing:1.15px;}
#ti_189{left:335px;bottom:750px;letter-spacing:0.09px;word-spacing:1.15px;}
#tj_189{left:157px;bottom:732px;letter-spacing:0.1px;word-spacing:0.04px;}
#tk_189{left:129px;bottom:705px;}
#tl_189{left:157px;bottom:705px;letter-spacing:0.16px;word-spacing:-0.67px;}
#tm_189{left:366px;bottom:705px;letter-spacing:0.11px;word-spacing:-0.63px;}
#tn_189{left:157px;bottom:686px;letter-spacing:0.11px;word-spacing:3.36px;}
#to_189{left:157px;bottom:668px;letter-spacing:0.06px;word-spacing:0.09px;}
#tp_189{left:129px;bottom:641px;}
#tq_189{left:157px;bottom:641px;letter-spacing:0.08px;word-spacing:-2.48px;}
#tr_189{left:341px;bottom:641px;letter-spacing:0.12px;word-spacing:-2.52px;}
#ts_189{left:157px;bottom:623px;letter-spacing:0.07px;word-spacing:-0.24px;}
#tt_189{left:157px;bottom:604px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_189{left:129px;bottom:577px;}
#tv_189{left:157px;bottom:577px;letter-spacing:0.13px;word-spacing:2.87px;}
#tw_189{left:424px;bottom:577px;letter-spacing:0.11px;word-spacing:2.86px;}
#tx_189{left:157px;bottom:559px;letter-spacing:0.1px;word-spacing:0.04px;}
#ty_189{left:129px;bottom:532px;}
#tz_189{left:157px;bottom:532px;letter-spacing:0.13px;word-spacing:-0.56px;}
#t10_189{left:385px;bottom:532px;letter-spacing:0.11px;word-spacing:-0.55px;}
#t11_189{left:157px;bottom:514px;letter-spacing:0.09px;word-spacing:0.04px;}
#t12_189{left:129px;bottom:487px;}
#t13_189{left:157px;bottom:487px;letter-spacing:0.12px;word-spacing:6.25px;}
#t14_189{left:396px;bottom:487px;letter-spacing:0.12px;word-spacing:6.23px;}
#t15_189{left:157px;bottom:468px;letter-spacing:0.07px;word-spacing:0.07px;}
#t16_189{left:129px;bottom:441px;}
#t17_189{left:157px;bottom:441px;letter-spacing:0.11px;word-spacing:9.44px;}
#t18_189{left:416px;bottom:441px;letter-spacing:0.12px;word-spacing:9.42px;}
#t19_189{left:157px;bottom:423px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1a_189{left:129px;bottom:396px;}
#t1b_189{left:157px;bottom:396px;letter-spacing:0.13px;word-spacing:2.51px;}
#t1c_189{left:356px;bottom:396px;letter-spacing:0.11px;word-spacing:2.51px;}
#t1d_189{left:157px;bottom:378px;letter-spacing:0.11px;word-spacing:7.86px;}
#t1e_189{left:157px;bottom:359px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1f_189{left:129px;bottom:332px;}
#t1g_189{left:157px;bottom:332px;letter-spacing:0.14px;word-spacing:3.78px;}
#t1h_189{left:383px;bottom:332px;letter-spacing:0.11px;word-spacing:3.79px;}
#t1i_189{left:157px;bottom:314px;letter-spacing:0.1px;word-spacing:1.37px;}
#t1j_189{left:157px;bottom:296px;letter-spacing:0.14px;}
#t1k_189{left:102px;bottom:269px;letter-spacing:0.14px;}
#t1l_189{left:179px;bottom:269px;letter-spacing:0.08px;}
#t1m_189{left:291px;bottom:269px;letter-spacing:0.07px;}
#t1n_189{left:387px;bottom:269px;letter-spacing:0.12px;}
#t1o_189{left:445px;bottom:269px;letter-spacing:0.14px;}
#t1p_189{left:521px;bottom:269px;letter-spacing:0.11px;}
#t1q_189{left:604px;bottom:269px;letter-spacing:0.09px;}
#t1r_189{left:102px;bottom:250px;letter-spacing:0.12px;word-spacing:6.79px;}
#t1s_189{left:102px;bottom:232px;letter-spacing:0.09px;word-spacing:1.38px;}
#t1t_189{left:102px;bottom:214px;letter-spacing:0.1px;word-spacing:-1.62px;}
#t1u_189{left:102px;bottom:195px;letter-spacing:0.08px;word-spacing:0.84px;}
#t1v_189{left:102px;bottom:177px;letter-spacing:0.05px;word-spacing:0.09px;}

.s0_189{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_189{font-size:15px;font-family:SymbolMT_19r;color:#202124;}
.s2_189{font-size:15px;font-family:MogulArial-Bold_179;color:#202124;}
.s3_189{font-size:15px;font-family:MogulArial_16s;color:#202124;}`}
      </style>

      <style id="fonts189" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg189" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="189/189.svg"
          type="image/svg+xml"
          id="pdf189"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_189" class="t s0_189">
          189{" "}
        </span>
        <span id="t2_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t3_189" class="t s2_189">
          Ажилд авах ба сонгон шалгаруулах:{" "}
        </span>
        <span id="t4_189" class="t s3_189">
          Ажлын байрны тодорхойлолт,{" "}
        </span>
        <span id="t5_189" class="t s3_189">
          мэргэшил, ажилд авах журам, сонгон шалгаруулах шалгуур зэргийг{" "}
        </span>
        <span id="t6_189" class="t s3_189">
          багтаасан шинэ ажилтан авах заавар{" "}
        </span>
        <span id="t7_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t8_189" class="t s2_189">
          Ажилд орох ба чиг баримжаа олгох:{" "}
        </span>
        <span id="t9_189" class="t s3_189">
          Шинэ ажилчдыг угтан авах,{" "}
        </span>
        <span id="ta_189" class="t s3_189">
          тэднийг сургуулийн соёл, үйл ажиллагаанд нэгтгэхэд нь туслах үйл{" "}
        </span>
        <span id="tb_189" class="t s3_189">
          явц{" "}
        </span>
        <span id="tc_189" class="t s1_189">
          •{" "}
        </span>
        <span id="td_189" class="t s2_189">
          Мэргэжил дээшлүүлэх
        </span>
        <span id="te_189" class="t s3_189">
          : Байнгын сургалт, зөвлөгөөн, ажилтнуудын ур{" "}
        </span>
        <span id="tf_189" class="t s3_189">
          чадвар, мэдлэгээ дээшлүүлэх боломжийг олгох бодлого.{" "}
        </span>
        <span id="tg_189" class="t s1_189">
          •{" "}
        </span>
        <span id="th_189" class="t s2_189">
          Гүйцэтгэлийн үнэлгээ:{" "}
        </span>
        <span id="ti_189" class="t s3_189">
          Ажилтны гүйцэтгэлийг үнэлэх, үнэлгээ хийх,{" "}
        </span>
        <span id="tj_189" class="t s3_189">
          санал хүсэлт өгөх, зорилгоо тодорхойлох журам{" "}
        </span>
        <span id="tk_189" class="t s1_189">
          •{" "}
        </span>
        <span id="tl_189" class="t s2_189">
          Ажилтны харилцаа, ёс зүй:{" "}
        </span>
        <span id="tm_189" class="t s3_189">
          Ажлын эерэг орчныг бүрдүүлэх, гомдлыг{" "}
        </span>
        <span id="tn_189" class="t s3_189">
          шийдвэрлэх, хүндэтгэлтэй зан үйлийг төлөвшүүлэх, зөрчилдөөнийг{" "}
        </span>
        <span id="to_189" class="t s3_189">
          зохицуулах удирдамж{" "}
        </span>
        <span id="tp_189" class="t s1_189">
          •{" "}
        </span>
        <span id="tq_189" class="t s2_189">
          Нөхөн олговор, тэтгэмж:{" "}
        </span>
        <span id="tr_189" class="t s3_189">
          Цалингийн бүтэц, урамшуулал, урамшуулал,{" "}
        </span>
        <span id="ts_189" class="t s3_189">
          тэтгэмж, чөлөө олгох бодлого болон нөхөн олговрын бусад хэлбэрийн{" "}
        </span>
        <span id="tt_189" class="t s3_189">
          талаарх дэлгэрэнгүй мэдээлэл{" "}
        </span>
        <span id="tu_189" class="t s1_189">
          •{" "}
        </span>
        <span id="tv_189" class="t s2_189">
          Олон талт байдал ба хамруулах:{" "}
        </span>
        <span id="tw_189" class="t s3_189">
          тэгш байдлыг дэмжих стратеги{" "}
        </span>
        <span id="tx_189" class="t s3_189">
          бүхий, олон талт ажлын байрыг бий болгох амлалт{" "}
        </span>
        <span id="ty_189" class="t s1_189">
          •{" "}
        </span>
        <span id="tz_189" class="t s2_189">
          Эрүүл мэнд, аюулгүй байдал:{" "}
        </span>
        <span id="t10_189" class="t s3_189">
          Ажиллагсад, оюутнуудын эрүүл мэнд,{" "}
        </span>
        <span id="t11_189" class="t s3_189">
          аюулгүй байдал, сайн сайхан байдлыг хангах арга хэмжээ.{" "}
        </span>
        <span id="t12_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t13_189" class="t s2_189">
          Ёс суртахууны хэм хэмжээ:{" "}
        </span>
        <span id="t14_189" class="t s3_189">
          Ёс суртахууны зан үйл, нууцлал,{" "}
        </span>
        <span id="t15_189" class="t s3_189">
          мэргэжлийн ёс зүйн дүрмийг дагаж мөрдөх хүлээлт.{" "}
        </span>
        <span id="t16_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t17_189" class="t s2_189">
          Оролцогч талуудын оролцоо:{" "}
        </span>
        <span id="t18_189" class="t s3_189">
          Эцэг эх, асран хамгаалагчид,{" "}
        </span>
        <span id="t19_189" class="t s3_189">
          оюутнууд болон олон нийттэй мэргэжлийн түвшинд харилцах заавар.{" "}
        </span>
        <span id="t1a_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t1b_189" class="t s2_189">
          Хуульд нийцсэн байдал:{" "}
        </span>
        <span id="t1c_189" class="t s3_189">
          Хөдөлмөрийн тухай хууль, боловсролын{" "}
        </span>
        <span id="t1d_189" class="t s3_189">
          дүрэм журам болон боловсролын байгууллагад ажилд орохтой{" "}
        </span>
        <span id="t1e_189" class="t s3_189">
          холбоотой бусад хууль эрх зүйн шаардлагыг дагаж мөрдөх.{" "}
        </span>
        <span id="t1f_189" class="t s1_189">
          •{" "}
        </span>
        <span id="t1g_189" class="t s2_189">
          Ажлаас халах ба тусгаарлах
        </span>
        <span id="t1h_189" class="t s3_189">
          : Ажилтныг ажлаас халах, тэтгэвэрт{" "}
        </span>
        <span id="t1i_189" class="t s3_189">
          гарах, зэрэг журам, үүнд гарах ярилцлага, зөвшөөрөл олгох үйл явц{" "}
        </span>
        <span id="t1j_189" class="t s3_189">
          орно.{" "}
        </span>
        <span id="t1k_189" class="t s3_189">
          Ерөнхий{" "}
        </span>
        <span id="t1l_189" class="t s3_189">
          боловсролын{" "}
        </span>
        <span id="t1m_189" class="t s3_189">
          сургуулийн{" "}
        </span>
        <span id="t1n_189" class="t s3_189">
          хүний{" "}
        </span>
        <span id="t1o_189" class="t s3_189">
          нөөцийн{" "}
        </span>
        <span id="t1p_189" class="t s3_189">
          бодлогыг{" "}
        </span>
        <span id="t1q_189" class="t s3_189">
          сайтар{" "}
        </span>
        <span id="t1r_189" class="t s3_189">
          боловсруулсан нь сургуульд ажлын эерэг орчин бүрдүүлэх, мэргэшсэн{" "}
        </span>
        <span id="t1s_189" class="t s3_189">
          боловсон хүчнийг татан оролцуулах, хадгалах, сургуулийн ерөнхий
          зорилго,{" "}
        </span>
        <span id="t1t_189" class="t s3_189">
          зорилтод хувь нэмэр оруулах хүний нөөцийн үр дүнтэй менежментийг
          хангахад{" "}
        </span>
        <span id="t1u_189" class="t s3_189">
          тусалдаг. Энэ нь мөн сургуулийн хамт олонд үүсч болзошгүй хүний
          нөөцтэй{" "}
        </span>
        <span id="t1v_189" class="t s3_189">
          холбоотой асуудал, зөрчилдөөнийг шийдвэрлэх тулах цэг болдог.{" "}
        </span>
      </div>
    </div>
  );
}
