import React from "react";
export default function F132() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_132{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_132{left:139px;bottom:941px;letter-spacing:-0.12px;}
#t3_132{left:199px;bottom:941px;letter-spacing:-0.17px;}
#t4_132{left:292px;bottom:941px;letter-spacing:-0.16px;word-spacing:0.61px;}
#t5_132{left:139px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t6_132{left:113px;bottom:896px;}
#t7_132{left:143px;bottom:896px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t8_132{left:87px;bottom:863px;letter-spacing:-0.05px;}
#t9_132{left:114px;bottom:863px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ta_132{left:111px;bottom:839px;}
#tb_132{left:139px;bottom:839px;letter-spacing:-0.17px;word-spacing:6.48px;}
#tc_132{left:139px;bottom:818px;letter-spacing:-0.09px;}
#td_132{left:211px;bottom:818px;letter-spacing:-0.11px;}
#te_132{left:111px;bottom:794px;}
#tf_132{left:139px;bottom:794px;letter-spacing:-0.17px;word-spacing:6.58px;}
#tg_132{left:139px;bottom:774px;letter-spacing:-0.17px;}
#th_132{left:111px;bottom:749px;}
#ti_132{left:139px;bottom:749px;letter-spacing:-0.16px;word-spacing:5.3px;}
#tj_132{left:283px;bottom:749px;letter-spacing:-0.13px;word-spacing:5.27px;}
#tk_132{left:139px;bottom:729px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tl_132{left:87px;bottom:680px;letter-spacing:-0.13px;word-spacing:6.52px;}
#tm_132{left:318px;bottom:680px;letter-spacing:-0.14px;word-spacing:6.53px;}
#tn_132{left:87px;bottom:660px;letter-spacing:-0.08px;}
#to_132{left:130px;bottom:660px;letter-spacing:-0.19px;}
#tp_132{left:227px;bottom:660px;letter-spacing:-0.19px;}
#tq_132{left:327px;bottom:660px;letter-spacing:-0.16px;}
#tr_132{left:423px;bottom:660px;letter-spacing:-0.27px;}
#ts_132{left:483px;bottom:660px;letter-spacing:-0.17px;}
#tt_132{left:583px;bottom:660px;letter-spacing:-0.14px;}
#tu_132{left:87px;bottom:640px;letter-spacing:-0.14px;word-spacing:0.53px;}
#tv_132{left:87px;bottom:620px;letter-spacing:-0.21px;word-spacing:0.12px;}
#tw_132{left:87px;bottom:571px;word-spacing:4.11px;}
#tx_132{left:87px;bottom:550px;letter-spacing:-0.14px;word-spacing:-1.18px;}
#ty_132{left:87px;bottom:530px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tz_132{left:311px;bottom:502px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t10_132{left:100px;bottom:463px;letter-spacing:0.12px;}
#t11_132{left:180px;bottom:470px;letter-spacing:0.09px;word-spacing:0.02px;}
#t12_132{left:196px;bottom:455px;letter-spacing:0.09px;word-spacing:0.03px;}
#t13_132{left:355px;bottom:463px;letter-spacing:0.07px;word-spacing:0.05px;}
#t14_132{left:493px;bottom:463px;letter-spacing:0.14px;}
#t15_132{left:535px;bottom:463px;letter-spacing:0.08px;word-spacing:0.04px;}
#t16_132{left:91px;bottom:324px;}
#t17_132{left:130px;bottom:324px;}
#t18_132{left:165px;bottom:419px;}
#t19_132{left:187px;bottom:419px;letter-spacing:0.12px;}
#t1a_132{left:340px;bottom:434px;letter-spacing:0.08px;word-spacing:0.04px;}
#t1b_132{left:340px;bottom:419px;letter-spacing:0.04px;word-spacing:0.06px;}
#t1c_132{left:340px;bottom:404px;letter-spacing:0.07px;}
#t1d_132{left:505px;bottom:419px;}
#t1e_132{left:534px;bottom:434px;letter-spacing:0.06px;word-spacing:0.05px;}
#t1f_132{left:534px;bottom:419px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1g_132{left:534px;bottom:404px;letter-spacing:0.08px;}
#t1h_132{left:165px;bottom:378px;}
#t1i_132{left:187px;bottom:378px;letter-spacing:0.12px;}
#t1j_132{left:340px;bottom:385px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1k_132{left:340px;bottom:371px;letter-spacing:0.07px;word-spacing:0.05px;}
#t1l_132{left:505px;bottom:308px;}
#t1m_132{left:534px;bottom:387px;letter-spacing:-0.01px;word-spacing:0.12px;}
#t1n_132{left:534px;bottom:372px;letter-spacing:0.05px;word-spacing:0.07px;}
#t1o_132{left:165px;bottom:337px;}
#t1p_132{left:187px;bottom:337px;letter-spacing:0.05px;word-spacing:0.06px;}
#t1q_132{left:340px;bottom:344px;letter-spacing:0.03px;word-spacing:0.08px;}
#t1r_132{left:340px;bottom:330px;letter-spacing:0.07px;}
#t1s_132{left:534px;bottom:352px;letter-spacing:0.08px;word-spacing:0.02px;}
#t1t_132{left:534px;bottom:337px;letter-spacing:0.08px;word-spacing:0.02px;}
#t1u_132{left:534px;bottom:322px;letter-spacing:0.06px;word-spacing:0.05px;}
#t1v_132{left:165px;bottom:293px;}
#t1w_132{left:187px;bottom:293px;letter-spacing:-0.07px;word-spacing:0.17px;}
#t1x_132{left:340px;bottom:293px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1y_132{left:534px;bottom:305px;letter-spacing:0.06px;word-spacing:0.05px;}
#t1z_132{left:534px;bottom:290px;letter-spacing:0.06px;}
#t20_132{left:165px;bottom:256px;}
#t21_132{left:187px;bottom:256px;letter-spacing:-0.06px;word-spacing:0.15px;}
#t22_132{left:340px;bottom:263px;letter-spacing:0.09px;word-spacing:0.02px;}
#t23_132{left:340px;bottom:248px;letter-spacing:0.06px;}
#t24_132{left:534px;bottom:264px;letter-spacing:0.1px;}
#t25_132{left:534px;bottom:249px;letter-spacing:0.07px;word-spacing:0.04px;}
#t26_132{left:165px;bottom:222px;}
#t27_132{left:187px;bottom:230px;letter-spacing:0.09px;word-spacing:0.02px;}
#t28_132{left:187px;bottom:215px;letter-spacing:-0.04px;word-spacing:0.14px;}
#t29_132{left:340px;bottom:230px;letter-spacing:0.06px;word-spacing:0.05px;}
#t2a_132{left:340px;bottom:215px;letter-spacing:0.06px;word-spacing:0.05px;}
#t2b_132{left:534px;bottom:230px;letter-spacing:0.09px;word-spacing:0.02px;}

.s0_132{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_132{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_132{font-size:17px;font-family:Wingdings2_1c6;color:#000;}
.s3_132{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_132{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_132{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s6_132{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s7_132{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s8_132{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts132" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}

@font-face {
	font-family: Wingdings2_1c6;
	src: url("fonts/Wingdings2_1c6.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg132" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="132/132.svg"
          type="image/svg+xml"
          id="pdf132"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_132" class="t s0_132">
          132{" "}
        </span>
        <span id="t2_132" class="t s1_132">
          дүгнэх{" "}
        </span>
        <span id="t3_132" class="t s1_132">
          асуултууд,{" "}
        </span>
        <span id="t4_132" class="t s1_132">
          туршилт эсвэл судалгаа гүйцэтгэх зааварчилгаа,{" "}
        </span>
        <span id="t5_132" class="t s1_132">
          аюулгүй ажиллагааны дүрэм байна{" "}
        </span>
        <span id="t6_132" class="t s2_132">
          {" "}
        </span>
        <span id="t7_132" class="t s1_132">
          Хариултын хэсэг{" "}
        </span>
        <span id="t8_132" class="t s3_132">
          6.{" "}
        </span>
        <span id="t9_132" class="t s1_132">
          Даалгаврын гүйцэтгэлийг үнэлэх аргачлал боловсруулах{" "}
        </span>
        <span id="ta_132" class="t s1_132">
          •{" "}
        </span>
        <span id="tb_132" class="t s1_132">
          Асуултын хариултыг үнэлэх шалгуурууд, харгалзах гүйцэтгэлийн{" "}
        </span>
        <span id="tc_132" class="t s1_132">
          түвшинг{" "}
        </span>
        <span id="td_132" class="t s1_132">
          бичнэ{" "}
        </span>
        <span id="te_132" class="t s4_132">
          •{" "}
        </span>
        <span id="tf_132" class="t s1_132">
          Сурагчдын мэдлэг, чадвар, төлөвшлийг үнэлэх, дүгнэх, үр дүнг{" "}
        </span>
        <span id="tg_132" class="t s1_132">
          хэрэглэх{" "}
        </span>
        <span id="th_132" class="t s4_132">
          •{" "}
        </span>
        <span id="ti_132" class="t s1_132">
          Нэмэлт асуулт:{" "}
        </span>
        <span id="tj_132" class="t s1_132">
          Даалгаврын гүйцэтгэлийг эргэж дүгнэх, үр дүнг{" "}
        </span>
        <span id="tk_132" class="t s1_132">
          нэгтгэх, үнэлэх асуултууд байна{" "}
        </span>
        <span id="tl_132" class="t s5_132">
          Даалгаварт анализ хийх.{" "}
        </span>
        <span id="tm_132" class="t s1_132">
          Багш нар даалгаврын гүйцэтгэлээс зөвхөн{" "}
        </span>
        <span id="tn_132" class="t s1_132">
          зөв{" "}
        </span>
        <span id="to_132" class="t s1_132">
          хариултад{" "}
        </span>
        <span id="tp_132" class="t s1_132">
          анхаарлаа{" "}
        </span>
        <span id="tq_132" class="t s1_132">
          хандуулж,{" "}
        </span>
        <span id="tr_132" class="t s1_132">
          буруу{" "}
        </span>
        <span id="ts_132" class="t s1_132">
          хариултын{" "}
        </span>
        <span id="tt_132" class="t s1_132">
          шалтгааныг{" "}
        </span>
        <span id="tu_132" class="t s1_132">
          илрүүлдэггүйгээс сурагчдад фийдбак/хариу мэдээллийг ихэвчлэн авсан ба{" "}
        </span>
        <span id="tv_132" class="t s1_132">
          алдсан оноонд суурилан өгдөг.{" "}
        </span>
        <span id="tw_132" class="t s1_132">
          Иймд даалгаврын гүйцэтгэлийг үнэлэх аргачлал алхам 6 боловсруулж{" "}
        </span>
        <span id="tx_132" class="t s1_132">
          сурах нь даалгаврын гүйцэтгэлд анализ хийх үндсэн арга зүй болдог.
          Жишээ{" "}
        </span>
        <span id="ty_132" class="t s1_132">
          болгож даалгаврын үнэлгээний схемийг орууллаа.{" "}
        </span>
        <span id="tz_132" class="t s6_132">
          Хүснэгт 3.17. Даалгавар гүйцэтгэлд анализ хийх арга зүй{" "}
        </span>
        <span id="t10_132" class="t s7_132">
          Дугаар{" "}
        </span>
        <span id="t11_132" class="t s7_132">
          Сурагчдаас ирж болох{" "}
        </span>
        <span id="t12_132" class="t s7_132">
          хариултын төрөл{" "}
        </span>
        <span id="t13_132" class="t s7_132">
          Хариултын тайлбар{" "}
        </span>
        <span id="t14_132" class="t s7_132">
          Оноо{" "}
        </span>
        <span id="t15_132" class="t s7_132">
          Шаардлагатай дэмжлэг{" "}
        </span>
        <span id="t16_132" class="t s7_132">
          4{" "}
        </span>
        <span id="t17_132" class="t s7_132">
          a{" "}
        </span>
        <span id="t18_132" class="t s7_132">
          1{" "}
        </span>
        <span id="t19_132" class="t s8_132">
          17{" "}
        </span>
        <span id="t1a_132" class="t s7_132">
          Сургуулийн хашаан{" "}
        </span>
        <span id="t1b_132" class="t s7_132">
          доторх модыг зөв{" "}
        </span>
        <span id="t1c_132" class="t s7_132">
          тоолсон.{" "}
        </span>
        <span id="t1d_132" class="t s7_132">
          1{" "}
        </span>
        <span id="t1e_132" class="t s7_132">
          Таних тэмдгийг{" "}
        </span>
        <span id="t1f_132" class="t s7_132">
          ажиглах, зөв ялган{" "}
        </span>
        <span id="t1g_132" class="t s7_132">
          таних{" "}
        </span>
        <span id="t1h_132" class="t s7_132">
          2{" "}
        </span>
        <span id="t1i_132" class="t s7_132">
          19{" "}
        </span>
        <span id="t1j_132" class="t s7_132">
          Хашааны гадна талын{" "}
        </span>
        <span id="t1k_132" class="t s7_132">
          модыг тоолсон{" "}
        </span>
        <span id="t1l_132" class="t s7_132">
          0{" "}
        </span>
        <span id="t1m_132" class="t s7_132">
          Тоолох аргачлалыг{" "}
        </span>
        <span id="t1n_132" class="t s7_132">
          санал болгох{" "}
        </span>
        <span id="t1o_132" class="t s7_132">
          3{" "}
        </span>
        <span id="t1p_132" class="t s7_132">
          36 гэх мэт{" "}
        </span>
        <span id="t1q_132" class="t s7_132">
          Гадна доторх бүх модыг{" "}
        </span>
        <span id="t1r_132" class="t s7_132">
          тоолсон.{" "}
        </span>
        <span id="t1s_132" class="t s7_132">
          Үүнд: нэг бүрчлэн,{" "}
        </span>
        <span id="t1t_132" class="t s7_132">
          багцалж, тэмдэглэгээ{" "}
        </span>
        <span id="t1u_132" class="t s7_132">
          хийж гэх мэт{" "}
        </span>
        <span id="t1v_132" class="t s7_132">
          4{" "}
        </span>
        <span id="t1w_132" class="t s7_132">
          9, 22, 31 г.м{" "}
        </span>
        <span id="t1x_132" class="t s7_132">
          Зүлгийг тоолсон{" "}
        </span>
        <span id="t1y_132" class="t s7_132">
          Таних тэмдгийг ялган{" "}
        </span>
        <span id="t1z_132" class="t s7_132">
          таних{" "}
        </span>
        <span id="t20_132" class="t s7_132">
          5{" "}
        </span>
        <span id="t21_132" class="t s7_132">
          26, 31, 67 г.м{" "}
        </span>
        <span id="t22_132" class="t s7_132">
          Зүлгийг мод болон нэмж{" "}
        </span>
        <span id="t23_132" class="t s7_132">
          оруулсан{" "}
        </span>
        <span id="t24_132" class="t s7_132">
          Харандаа, үзгийг зөв{" "}
        </span>
        <span id="t25_132" class="t s7_132">
          сонгон хэрэглэх{" "}
        </span>
        <span id="t26_132" class="t s7_132">
          6{" "}
        </span>
        <span id="t27_132" class="t s7_132">
          Алдаж тоолсон болон{" "}
        </span>
        <span id="t28_132" class="t s7_132">
          бусад /16, 18 г.м/{" "}
        </span>
        <span id="t29_132" class="t s7_132">
          Таних тэмдгийн модыг{" "}
        </span>
        <span id="t2a_132" class="t s7_132">
          оруулж тоолсон{" "}
        </span>
        <span id="t2b_132" class="t s7_132">
          Дахин нягтлах{" "}
        </span>
      </div>
    </div>
  );
}
