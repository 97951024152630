import React from "react";
export default function FiftySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_56{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_56{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_56{left:87px;bottom:939px;letter-spacing:-0.14px;word-spacing:5.74px;}
#t4_56{left:87px;bottom:919px;letter-spacing:-0.11px;word-spacing:0.03px;}
#t5_56{left:87px;bottom:883px;letter-spacing:-0.11px;}
#t6_56{left:205px;bottom:883px;letter-spacing:-0.15px;}
#t7_56{left:309px;bottom:883px;letter-spacing:-0.14px;}
#t8_56{left:87px;bottom:862px;letter-spacing:-0.12px;word-spacing:8.04px;}
#t9_56{left:87px;bottom:842px;letter-spacing:-0.15px;word-spacing:11.38px;}
#ta_56{left:87px;bottom:822px;letter-spacing:-0.15px;}
#tb_56{left:141px;bottom:822px;letter-spacing:-0.16px;}
#tc_56{left:248px;bottom:822px;letter-spacing:-0.15px;}
#td_56{left:352px;bottom:822px;letter-spacing:-0.21px;}
#te_56{left:87px;bottom:802px;letter-spacing:-0.16px;word-spacing:2.93px;}
#tf_56{left:87px;bottom:782px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tg_56{left:87px;bottom:749px;letter-spacing:-0.11px;word-spacing:3.03px;}
#th_56{left:87px;bottom:728px;letter-spacing:-0.14px;word-spacing:9.74px;}
#ti_56{left:87px;bottom:708px;letter-spacing:-0.24px;word-spacing:2.29px;}
#tj_56{left:87px;bottom:688px;letter-spacing:-0.15px;word-spacing:0.79px;}
#tk_56{left:87px;bottom:668px;letter-spacing:-0.14px;word-spacing:2.06px;}
#tl_56{left:87px;bottom:648px;letter-spacing:-0.15px;word-spacing:-1.05px;}
#tm_56{left:87px;bottom:628px;letter-spacing:-0.48px;word-spacing:0.05px;}
#tn_56{left:165px;bottom:594px;letter-spacing:-0.16px;word-spacing:0.03px;}
#to_56{left:87px;bottom:301px;letter-spacing:-0.15px;word-spacing:1.38px;}
#tp_56{left:87px;bottom:281px;letter-spacing:-0.14px;word-spacing:6.38px;}
#tq_56{left:87px;bottom:261px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tr_56{left:87px;bottom:228px;letter-spacing:-0.13px;word-spacing:-1.15px;}
#ts_56{left:376px;bottom:228px;letter-spacing:-0.16px;word-spacing:-1.1px;}
#tt_56{left:87px;bottom:207px;letter-spacing:-0.14px;word-spacing:3.39px;}
#tu_56{left:87px;bottom:187px;letter-spacing:-0.15px;word-spacing:2.24px;}
#tv_56{left:87px;bottom:167px;letter-spacing:-0.12px;word-spacing:0.72px;}
#tw_56{left:87px;bottom:147px;letter-spacing:-0.16px;word-spacing:2.65px;}
#tx_56{left:87px;bottom:127px;letter-spacing:-0.14px;word-spacing:3.49px;}
#ty_56{left:87px;bottom:107px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tz_56{left:408px;bottom:943px;letter-spacing:-0.18px;word-spacing:0.05px;}

.s0_56{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_56{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_56{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_56{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s4_56{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_56{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts56" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg56" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="56/56.svg"
          type="image/svg+xml"
          id="pdf56"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_56" class="t s1_56">
          56{" "}
        </span>
        <span id="t3_56" class="t s2_56">
          Дээрх цонхыг сонгож, “нэвтрэх нэр”,{" "}
        </span>
        <span id="t4_56" class="t s2_56">
          “нууц үг”-ийг оруулна (Зураг 2.5).{" "}
        </span>
        <span id="t5_56" class="t s2_56">
          БШУГ-ын{" "}
        </span>
        <span id="t6_56" class="t s2_56">
          үнэлгээ{" "}
        </span>
        <span id="t7_56" class="t s2_56">
          хариуцсан{" "}
        </span>
        <span id="t8_56" class="t s2_56">
          мэргэжилтнээс авсан “нэвтрэх нэр”,{" "}
        </span>
        <span id="t9_56" class="t s2_56">
          “нууц үг”-ийг хамгаалалтын тоотой{" "}
        </span>
        <span id="ta_56" class="t s2_56">
          хамт{" "}
        </span>
        <span id="tb_56" class="t s2_56">
          оруулснаар{" "}
        </span>
        <span id="tc_56" class="t s2_56">
          сургуулийн{" "}
        </span>
        <span id="td_56" class="t s2_56">
          буюу{" "}
        </span>
        <span id="te_56" class="t s2_56">
          сургалтын байгууллагын гүйцэтгэлийн{" "}
        </span>
        <span id="tf_56" class="t s2_56">
          үнэлгээний үр дүн гарч ирэх юм.{" "}
        </span>
        <span id="tg_56" class="t s2_56">
          Цахим тоон тайлангийн системээс та{" "}
        </span>
        <span id="th_56" class="t s2_56">
          бүхэнд гүйцэтгэлийн үнэлгээний үр{" "}
        </span>
        <span id="ti_56" class="t s3_56">
          дүнгээ зураг, өгөгдөл, PDF, powerpoint{" "}
        </span>
        <span id="tj_56" class="t s2_56">
          хэлбэрээр авч ашиглах боломжтой.Үүний тулд тухайн цонхны баруун дээр{" "}
        </span>
        <span id="tk_56" class="t s3_56">
          байрлах доошоо чиглэсэн сумтай дөрвөлжин дүрс бүхий “Download” товч{" "}
        </span>
        <span id="tl_56" class="t s2_56">
          дээр дарснаар мэдээллийг ямар хэлбэрээр татаж авах сонголтууд гарч
          ирнэ{" "}
        </span>
        <span id="tm_56" class="t s2_56">
          (Зураг 2.6). Та бүхэн өөрт тохирсон хэлбэрийг сонгон ашиглах боломжтой
          юм.{" "}
        </span>
        <span id="tn_56" class="t s4_56">
          Зураг 2.6. Цахим тоон системээс мэдээллийг татаж авах хэлбэрүүд{" "}
        </span>
        <span id="to_56" class="t s2_56">
          Сургалтын байгууллагын гүйцэтгэлийн үнэлгээний үр дүнгийн мэдээллийн{" "}
        </span>
        <span id="tp_56" class="t s2_56">
          үндсэн болон дэд ангилал, мэдээллийн харагдах байдал, тоон утгын{" "}
        </span>
        <span id="tq_56" class="t s2_56">
          тайлбар зэргийг жишээгээр харуулъя.{" "}
        </span>
        <span id="tr_56" class="t s5_56">
          Сургалтын байгууллагын үнэлгээ:{" "}
        </span>
        <span id="ts_56" class="t s2_56">
          Сургалтын байгууллагын гүйцэтгэлийн{" "}
        </span>
        <span id="tt_56" class="t s2_56">
          үнэлгээний ерөнхий үр дүн бүхий цонхны харагдах байдлыг Зураг 2.7-д{" "}
        </span>
        <span id="tu_56" class="t s2_56">
          үзүүлэв. Тус цонхонд тухайн сургуулийн гүйцэтгэлийн үнэлгээний дундаж{" "}
        </span>
        <span id="tv_56" class="t s2_56">
          оноо, хамаарах кластерын дундаж, улсын дунджийг хоёр оноор харуулсан{" "}
        </span>
        <span id="tw_56" class="t s2_56">
          байна. Цонхны зүүн дээд буланд байрлах “all”, “2022”, “2023”
          товчуудаас{" "}
        </span>
        <span id="tx_56" class="t s2_56">
          сонгож, мэдээллийг 2 оноор, зөвхөн 2022 оноор, 2023 оноор тодруулан{" "}
        </span>
        <span id="ty_56" class="t s2_56">
          харах боломжтой.{" "}
        </span>
        <span id="tz_56" class="t s4_56">
          Зураг 2.5. Нэвтрэх цонхны харагдах байдал{" "}
        </span>
      </div>
    </div>
  );
}
