import React from "react";
export default function NinetyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_95{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_95{left:222px;bottom:943px;letter-spacing:0.09px;word-spacing:0.05px;}
#t3_95{left:142px;bottom:495px;letter-spacing:0.18px;}
#t4_95{left:87px;bottom:453px;letter-spacing:-0.15px;word-spacing:9.87px;}
#t5_95{left:87px;bottom:433px;letter-spacing:-0.15px;word-spacing:11.57px;}
#t6_95{left:87px;bottom:412px;letter-spacing:-0.18px;word-spacing:2.58px;}
#t7_95{left:87px;bottom:392px;letter-spacing:-0.18px;word-spacing:9.35px;}
#t8_95{left:87px;bottom:372px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t9_95{left:87px;bottom:339px;letter-spacing:-0.16px;}
#ta_95{left:194px;bottom:339px;letter-spacing:-0.24px;}
#tb_95{left:308px;bottom:339px;letter-spacing:-0.07px;}
#tc_95{left:349px;bottom:339px;letter-spacing:-0.16px;}
#td_95{left:87px;bottom:319px;letter-spacing:-0.13px;word-spacing:-0.35px;}
#te_95{left:87px;bottom:299px;letter-spacing:-0.12px;word-spacing:-0.83px;}
#tf_95{left:87px;bottom:278px;letter-spacing:-0.14px;word-spacing:4.23px;}
#tg_95{left:87px;bottom:258px;letter-spacing:-0.2px;}
#th_95{left:223px;bottom:258px;letter-spacing:-0.12px;}
#ti_95{left:322px;bottom:258px;letter-spacing:-0.26px;}
#tj_95{left:372px;bottom:258px;letter-spacing:-0.08px;}
#tk_95{left:87px;bottom:238px;letter-spacing:-0.08px;}
#tl_95{left:150px;bottom:238px;letter-spacing:-0.12px;}
#tm_95{left:254px;bottom:238px;letter-spacing:-0.08px;}
#tn_95{left:326px;bottom:238px;letter-spacing:-0.07px;}
#to_95{left:87px;bottom:218px;letter-spacing:-0.11px;word-spacing:10.53px;}
#tp_95{left:87px;bottom:198px;letter-spacing:-0.07px;}
#tq_95{left:194px;bottom:198px;letter-spacing:-0.09px;}
#tr_95{left:277px;bottom:198px;letter-spacing:-0.12px;}
#ts_95{left:372px;bottom:198px;letter-spacing:-0.06px;}
#tt_95{left:87px;bottom:178px;letter-spacing:-0.07px;}
#tu_95{left:193px;bottom:178px;letter-spacing:-0.08px;}
#tv_95{left:255px;bottom:178px;letter-spacing:-0.11px;}
#tw_95{left:377px;bottom:178px;letter-spacing:-0.14px;}
#tx_95{left:87px;bottom:157px;letter-spacing:-0.15px;word-spacing:6.3px;}
#ty_95{left:87px;bottom:137px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tz_95{left:465px;bottom:343px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t10_95{left:535px;bottom:343px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t11_95{left:457px;bottom:327px;letter-spacing:-0.13px;word-spacing:0.02px;}

.s0_95{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_95{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_95{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s3_95{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_95{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts95" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg95" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="95/95.svg"
          type="image/svg+xml"
          id="pdf95"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_95" class="t s0_95">
          95{" "}
        </span>
        <span id="t2_95" class="t s1_95">
          Хүснэгт 3.2. Судлагдахуун хоорондын агуулгын залгамж холбоо{" "}
        </span>
        <span id="t3_95" class="t s2_95">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t4_95" class="t s3_95">
          Сургалтын хөтөлбөрийг хэрэгжүүлэхэд сургалтын агуулга, арга зүйг{" "}
        </span>
        <span id="t5_95" class="t s3_95">
          интеграцчилах хэрэгцээ, шаардлага үүсэж болно. Энэ тохиолдолд{" "}
        </span>
        <span id="t6_95" class="t s3_95">
          сургуулийн бүх багш хамтарч сургалт, үйл ажиллагааг төлөвлөх, турших,{" "}
        </span>
        <span id="t7_95" class="t s3_95">
          хэрэгжүүлэх, үнэлэх шаардлага тулгардаг тул сургуулийн удирдлага{" "}
        </span>
        <span id="t8_95" class="t s3_95">
          боломжит цаг хугацааг гаргаж, дэмжин ажиллах хэрэгтэй болно.{" "}
        </span>
        <span id="t9_95" class="t s3_95">
          Сургуулийн{" "}
        </span>
        <span id="ta_95" class="t s3_95">
          удирдлагууд{" "}
        </span>
        <span id="tb_95" class="t s3_95">
          нэг{" "}
        </span>
        <span id="tc_95" class="t s3_95">
          улиралд{" "}
        </span>
        <span id="td_95" class="t s3_95">
          нэгээс дээш удаа өөр өөр хичээлийн багш{" "}
        </span>
        <span id="te_95" class="t s3_95">
          нар хамтран хичээлээ заах цаг боломжийг{" "}
        </span>
        <span id="tf_95" class="t s3_95">
          олгож өгөх хэрэгтэй. Ингэхдээ дан ганц{" "}
        </span>
        <span id="tg_95" class="t s3_95">
          судлагдахууны{" "}
        </span>
        <span id="th_95" class="t s3_95">
          интеграци{" "}
        </span>
        <span id="ti_95" class="t s3_95">
          бус,{" "}
        </span>
        <span id="tj_95" class="t s3_95">
          бүлэг{" "}
        </span>
        <span id="tk_95" class="t s3_95">
          сэдэв{" "}
        </span>
        <span id="tl_95" class="t s3_95">
          хоорондын{" "}
        </span>
        <span id="tm_95" class="t s3_95">
          (strand{" "}
        </span>
        <span id="tn_95" class="t s3_95">
          integration),{" "}
        </span>
        <span id="to_95" class="t s3_95">
          бүлэг сэдвийн дарааллын (successive{" "}
        </span>
        <span id="tp_95" class="t s3_95">
          integration),{" "}
        </span>
        <span id="tq_95" class="t s3_95">
          ерөнхий{" "}
        </span>
        <span id="tr_95" class="t s3_95">
          чадварын{" "}
        </span>
        <span id="ts_95" class="t s3_95">
          (skills{" "}
        </span>
        <span id="tt_95" class="t s3_95">
          integration){" "}
        </span>
        <span id="tu_95" class="t s3_95">
          зэрэг{" "}
        </span>
        <span id="tv_95" class="t s3_95">
          суралцахуйн{" "}
        </span>
        <span id="tw_95" class="t s3_95">
          олон{" "}
        </span>
        <span id="tx_95" class="t s3_95">
          төрлийн интеграцийг хэрэглэхээс гадна{" "}
        </span>
        <span id="ty_95" class="t s3_95">
          арга зүйн интеграцийг хэрэгжүүлж болно.{" "}
        </span>
        <span id="tz_95" class="t s4_95">
          Зураг 3.8.{" "}
        </span>
        <span id="t10_95" class="t s4_95">
          Хамтарсан хичээл:{" "}
        </span>
        <span id="t11_95" class="t s4_95">
          интеграци, бүлэг сэдэв, арга зүй{" "}
        </span>
      </div>
    </div>
  );
}
