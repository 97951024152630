import React from "react";
export default function HundredEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_108{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_108{left:98px;bottom:941px;}
#t3_108{left:125px;bottom:941px;letter-spacing:-0.13px;word-spacing:1.98px;}
#t4_108{left:125px;bottom:921px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t5_108{left:98px;bottom:901px;}
#t6_108{left:125px;bottom:901px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_108{left:98px;bottom:880px;}
#t8_108{left:125px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_108{left:87px;bottom:847px;letter-spacing:-0.15px;word-spacing:-2.67px;}
#ta_108{left:87px;bottom:827px;letter-spacing:-0.12px;word-spacing:-0.47px;}
#tb_108{left:87px;bottom:807px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tc_108{left:87px;bottom:774px;letter-spacing:-0.14px;word-spacing:7.8px;}
#td_108{left:87px;bottom:754px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_108{left:471px;bottom:718px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tf_108{left:148px;bottom:681px;letter-spacing:-0.13px;}
#tg_108{left:218px;bottom:681px;letter-spacing:-0.15px;word-spacing:0.02px;}
#th_108{left:369px;bottom:681px;letter-spacing:-0.09px;word-spacing:-0.03px;}
#ti_108{left:434px;bottom:681px;letter-spacing:-0.14px;}
#tj_108{left:484px;bottom:681px;letter-spacing:-0.26px;}
#tk_108{left:541px;bottom:681px;letter-spacing:-0.17px;}
#tl_108{left:91px;bottom:656px;letter-spacing:-0.33px;}
#tm_108{left:128px;bottom:640px;letter-spacing:-0.23px;}
#tn_108{left:307px;bottom:657px;}
#to_108{left:409px;bottom:657px;letter-spacing:-0.07px;}
#tp_108{left:511px;bottom:657px;letter-spacing:-0.07px;}
#tq_108{left:615px;bottom:657px;letter-spacing:-0.12px;}
#tr_108{left:261px;bottom:637px;}
#ts_108{left:313px;bottom:637px;letter-spacing:-0.07px;}
#tt_108{left:365px;bottom:637px;letter-spacing:-1.15px;}
#tu_108{left:417px;bottom:637px;letter-spacing:-0.07px;}
#tv_108{left:469px;bottom:637px;}
#tw_108{left:521px;bottom:637px;letter-spacing:-0.07px;}
#tx_108{left:573px;bottom:637px;}
#ty_108{left:625px;bottom:637px;letter-spacing:-0.07px;}
#tz_108{left:91px;bottom:619px;letter-spacing:-0.22px;word-spacing:0.09px;}
#t10_108{left:313px;bottom:619px;}
#t11_108{left:417px;bottom:619px;}
#t12_108{left:521px;bottom:619px;}
#t13_108{left:91px;bottom:600px;letter-spacing:-0.22px;}
#t14_108{left:417px;bottom:600px;}
#t15_108{left:573px;bottom:600px;}
#t16_108{left:91px;bottom:581px;letter-spacing:-0.21px;word-spacing:0.09px;}
#t17_108{left:313px;bottom:581px;}
#t18_108{left:417px;bottom:581px;}
#t19_108{left:91px;bottom:563px;letter-spacing:-0.14px;}
#t1a_108{left:365px;bottom:563px;}
#t1b_108{left:573px;bottom:563px;}
#t1c_108{left:91px;bottom:544px;letter-spacing:-0.27px;word-spacing:0.14px;}
#t1d_108{left:261px;bottom:544px;}
#t1e_108{left:469px;bottom:544px;}
#t1f_108{left:91px;bottom:524px;letter-spacing:-0.17px;}
#t1g_108{left:157px;bottom:524px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1h_108{left:211px;bottom:502px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1i_108{left:144px;bottom:480px;}
#t1j_108{left:211px;bottom:480px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t1k_108{left:87px;bottom:440px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1l_108{left:87px;bottom:420px;}
#t1m_108{left:114px;bottom:420px;letter-spacing:-0.13px;word-spacing:8.75px;}
#t1n_108{left:114px;bottom:400px;letter-spacing:-0.15px;word-spacing:4.49px;}
#t1o_108{left:114px;bottom:380px;letter-spacing:-0.15px;word-spacing:2.86px;}
#t1p_108{left:114px;bottom:360px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1q_108{left:87px;bottom:340px;}
#t1r_108{left:114px;bottom:340px;letter-spacing:-0.14px;word-spacing:-0.73px;}
#t1s_108{left:114px;bottom:319px;letter-spacing:-0.17px;word-spacing:-1.73px;}
#t1t_108{left:114px;bottom:299px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1u_108{left:87px;bottom:259px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1v_108{left:87px;bottom:234px;letter-spacing:-0.15px;word-spacing:3.5px;}
#t1w_108{left:87px;bottom:214px;letter-spacing:-0.1px;word-spacing:2.1px;}
#t1x_108{left:87px;bottom:194px;letter-spacing:-0.14px;word-spacing:-0.82px;}
#t1y_108{left:87px;bottom:174px;letter-spacing:-0.2px;word-spacing:7.38px;}
#t1z_108{left:87px;bottom:154px;letter-spacing:-0.17px;word-spacing:-1.18px;}
#t20_108{left:87px;bottom:134px;letter-spacing:-0.15px;word-spacing:0.06px;}

.s0_108{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_108{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_108{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_108{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s4_108{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}
.s5_108{font-size:14px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s6_108{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_108{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts108" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg108" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="108/108.svg"
          type="image/svg+xml"
          id="pdf108"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_108" class="t s0_108">
          108{" "}
        </span>
        <span id="t2_108" class="t s1_108">
          •{" "}
        </span>
        <span id="t3_108" class="t s2_108">
          Нэгж хичээлийн сэдэв, эсвэл суралцахуйн зорилт (зарим сургуулийн{" "}
        </span>
        <span id="t4_108" class="t s2_108">
          хувьд ээлжит хичээлийг тусгасан байдаг){" "}
        </span>
        <span id="t5_108" class="t s1_108">
          •{" "}
        </span>
        <span id="t6_108" class="t s2_108">
          Хичээл хоорондын уялдаа холбоо{" "}
        </span>
        <span id="t7_108" class="t s1_108">
          •{" "}
        </span>
        <span id="t8_108" class="t s2_108">
          Сургуулийн зорилттой уялдсан хичээлийн давтамж{" "}
        </span>
        <span id="t9_108" class="t s2_108">
          Жишээ болгож авсан бие даан суралцах болон асуудлыг хамтарч шийдвэрлэх{" "}
        </span>
        <span id="ta_108" class="t s2_108">
          чадвар нь тухайн ангийн эсвэл бага, дунд, ахлах ангийн түвшинд
          сурагчдад{" "}
        </span>
        <span id="tb_108" class="t s2_108">
          тулгамдсан асуудал байж болох юм.{" "}
        </span>
        <span id="tc_108" class="t s2_108">
          Тэгвэл үүнийг системтэй хөгжүүлж болох зураглалыг ангийн нэгдсэн{" "}
        </span>
        <span id="td_108" class="t s2_108">
          төлөвлөлтөд тусгах боломжтой.{" "}
        </span>
        <span id="te_108" class="t s3_108">
          Хүснэгт 3.7. Төлөвлөлт (ангиар){" "}
        </span>
        <span id="tf_108" class="t s4_108">
          2023-2024{" "}
        </span>
        <span id="tg_108" class="t s3_108">
          оны хичээлийн жилийн{" "}
        </span>
        <span id="th_108" class="t s4_108">
          5 дугаар{" "}
        </span>
        <span id="ti_108" class="t s4_108">
          ангийн{" "}
        </span>
        <span id="tj_108" class="t s4_108">
          нэгдсэн{" "}
        </span>
        <span id="tk_108" class="t s3_108">
          төлөвлөгөө{" "}
        </span>
        <span id="tl_108" class="t s3_108">
          Улирал{" "}
        </span>
        <span id="tm_108" class="t s3_108">
          Судлагдахуун{" "}
        </span>
        <span id="tn_108" class="t s3_108">
          I{" "}
        </span>
        <span id="to_108" class="t s3_108">
          II{" "}
        </span>
        <span id="tp_108" class="t s3_108">
          III{" "}
        </span>
        <span id="tq_108" class="t s3_108">
          IV{" "}
        </span>
        <span id="tr_108" class="t s3_108">
          9{" "}
        </span>
        <span id="ts_108" class="t s3_108">
          ...{" "}
        </span>
        <span id="tt_108" class="t s3_108">
          11{" "}
        </span>
        <span id="tu_108" class="t s3_108">
          ...{" "}
        </span>
        <span id="tv_108" class="t s3_108">
          1{" "}
        </span>
        <span id="tw_108" class="t s3_108">
          ...{" "}
        </span>
        <span id="tx_108" class="t s3_108">
          4{" "}
        </span>
        <span id="ty_108" class="t s3_108">
          ..{" "}
        </span>
        <span id="tz_108" class="t s3_108">
          Монгол хэл{" "}
        </span>
        <span id="t10_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t11_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t12_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t13_108" class="t s3_108">
          Математик{" "}
        </span>
        <span id="t14_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t15_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t16_108" class="t s3_108">
          Хүн ба байгаль{" "}
        </span>
        <span id="t17_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t18_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t19_108" class="t s3_108">
          Эрүүл мэнд{" "}
        </span>
        <span id="t1a_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t1b_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t1c_108" class="t s3_108">
          Биеийн тамир г.м{" "}
        </span>
        <span id="t1d_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t1e_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t1f_108" class="t s5_108">
          Тайлбар:{" "}
        </span>
        <span id="t1g_108" class="t s6_108">
          Хүснэгтийн нүд бүрт ээлжит хичээлийн сэдэв юм уу СЗ байна.{" "}
        </span>
        <span id="t1h_108" class="t s3_108">
          Бие даан суралцах- гэрийн даалгавар{" "}
        </span>
        <span id="t1i_108" class="t s4_108">
          *{" "}
        </span>
        <span id="t1j_108" class="t s3_108">
          Багаар ажиллах- асуудлыг хамтарч шийдвэрлэх чадвар{" "}
        </span>
        <span id="t1k_108" class="t s2_108">
          Анхаарах зүйл{" "}
        </span>
        <span id="t1l_108" class="t s1_108">
          •{" "}
        </span>
        <span id="t1m_108" class="t s2_108">
          Багшийн жил бүр судлагдахуунаараа хийдэг төлөвлөгөөг нэгтгэж{" "}
        </span>
        <span id="t1n_108" class="t s2_108">
          сургуулийн нэгдсэн төлөвлөгөөг дээрх 2 хувилбараар гаргаж байна.{" "}
        </span>
        <span id="t1o_108" class="t s2_108">
          Иймд сургууль дээр сургалтын хоёр л төлөвлөлт байх юм байна гэж{" "}
        </span>
        <span id="t1p_108" class="t s2_108">
          ташаа ойлгож болохгүй.{" "}
        </span>
        <span id="t1q_108" class="t s1_108">
          •{" "}
        </span>
        <span id="t1r_108" class="t s2_108">
          Дээрх нэгдсэн төлөвлөгөөг захирал, сургалтын менежерийн удирдлагын{" "}
        </span>
        <span id="t1s_108" class="t s2_108">
          дагуу багш нар нэгдэж гаргана. Төлөвлөгөөний биелэлт ч мөн удирдлагад{" "}
        </span>
        <span id="t1t_108" class="t s2_108">
          шууд хамааралтай болно.{" "}
        </span>
        <span id="t1u_108" class="t s7_108">
          Хүүхэд хөгжил, хамгааллын нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="t1v_108" class="t s2_108">
          Хүүхэд хөгжил, хамгааллын нэгдсэн төлөвлөгөө нь анги удирдсан багш,{" "}
        </span>
        <span id="t1w_108" class="t s2_108">
          нийгмийн ажилтан, эмч, сэтгэл зүйч, клуб, дугуйлан, секцүүд зэрэг
          анхан{" "}
        </span>
        <span id="t1x_108" class="t s2_108">
          шатнаас гарч ирсэн хичээлээс гадуурх олон үйл ажиллагааны төлөвлөлтийг{" "}
        </span>
        <span id="t1y_108" class="t s2_108">
          хүүхэд хамгааллын баг, сургуулийн зорилго, зорилт болон сургалтын{" "}
        </span>
        <span id="t1z_108" class="t s2_108">
          хөтөлбөрөөр эзэмшүүлэх мэдлэг, чадвартай уялдуулан нэгтгэж, давхардлыг{" "}
        </span>
        <span id="t20_108" class="t s2_108">
          арилгаж гаргах нь чухал.{" "}
        </span>
      </div>
    </div>
  );
}
