import React from "react";
export default function NinetyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_91{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_91{left:87px;bottom:936px;letter-spacing:0.17px;word-spacing:0.5px;}
#t3_91{left:130px;bottom:903px;letter-spacing:0.14px;word-spacing:0.11px;}
#t4_91{left:145px;bottom:861px;letter-spacing:0.11px;word-spacing:0.09px;}
#t5_91{left:87px;bottom:829px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_91{left:114px;bottom:809px;}
#t7_91{left:142px;bottom:809px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_91{left:87px;bottom:775px;letter-spacing:-0.14px;word-spacing:5.52px;}
#t9_91{left:87px;bottom:755px;letter-spacing:-0.12px;word-spacing:6.24px;}
#ta_91{left:87px;bottom:735px;letter-spacing:-0.12px;word-spacing:1.63px;}
#tb_91{left:87px;bottom:715px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tc_91{left:87px;bottom:682px;letter-spacing:-0.54px;word-spacing:0.03px;}
#td_91{left:215px;bottom:654px;letter-spacing:-0.15px;word-spacing:0.03px;}
#te_91{left:399px;bottom:411px;letter-spacing:-0.19px;word-spacing:0.06px;}
#tf_91{left:87px;bottom:362px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tg_91{left:87px;bottom:333px;letter-spacing:-0.15px;word-spacing:11.01px;}
#th_91{left:87px;bottom:313px;letter-spacing:-0.15px;word-spacing:1.06px;}
#ti_91{left:87px;bottom:293px;letter-spacing:-0.14px;word-spacing:1.3px;}
#tj_91{left:87px;bottom:273px;letter-spacing:-0.18px;word-spacing:6.66px;}
#tk_91{left:87px;bottom:253px;letter-spacing:-0.18px;word-spacing:3.68px;}
#tl_91{left:87px;bottom:233px;letter-spacing:-0.34px;word-spacing:0.25px;}
#tm_91{left:114px;bottom:204px;}
#tn_91{left:142px;bottom:204px;letter-spacing:-0.14px;word-spacing:2.02px;}
#to_91{left:142px;bottom:184px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tp_91{left:114px;bottom:155px;}
#tq_91{left:142px;bottom:155px;letter-spacing:-0.17px;word-spacing:2.69px;}
#tr_91{left:142px;bottom:135px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_91{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_91{font-size:21px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_91{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_91{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_91{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_91{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s6_91{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_91{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s8_91{font-size:15px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts91" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg91" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="91/91.svg"
          type="image/svg+xml"
          id="pdf91"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_91" class="t s0_91">
          91{" "}
        </span>
        <span id="t2_91" class="t s1_91">
          3.2 СУРГАЛТЫН ХӨТӨЛБӨРИЙН ХЭРЭГЖИЛТЭД{" "}
        </span>
        <span id="t3_91" class="t s1_91">
          ХЭРХЭН ДЭМЖЛЭГ ҮЗҮҮЛЭХ ВЭ?{" "}
        </span>
        <span id="t4_91" class="t s2_91">
          3.2.1 СУРГАЛТЫН ХӨТӨЛБӨРИЙГ СУДЛАХ, ОЙЛГОХ{" "}
        </span>
        <span id="t5_91" class="t s3_91">
          Сургалтын хөтөлбөрийг хэрэгжүүлэхийн тулд{" "}
        </span>
        <span id="t6_91" class="t s4_91">
          -{" "}
        </span>
        <span id="t7_91" class="t s3_91">
          Яагаад нэг хэлээр ярьж, бичиж, ижил утгаар ойлгох нь чухал вэ?{" "}
        </span>
        <span id="t8_91" class="t s3_91">
          Яагаад гэвэл, сургалтын хөтөлбөрийг уншиж судалснаар дуугарах дуу{" "}
        </span>
        <span id="t9_91" class="t s3_91">
          нэг болж, өмнөх хөтөлбөрийг хэрэгжүүлсэн сайн туршлагаа харилцан{" "}
        </span>
        <span id="ta_91" class="t s3_91">
          хуваалцах, шинэ хөтөлбөрийг амжилттай хэрэгжүүлэхийн тулд ойлголтын{" "}
        </span>
        <span id="tb_91" class="t s3_91">
          зөрүүгээ арилгах болно.{" "}
        </span>
        <span id="tc_91" class="t s3_91">
          Сургалтын хөтөлбөрийг судлахдаа дараах алхамчлалыг баримтлах хэрэгтэй.{" "}
        </span>
        <span id="td_91" class="t s5_91">
          Зураг 3.4. Сургалтын хөтөлбөрийг судлах дараалал{" "}
        </span>
        <span id="te_91" class="t s6_91">
          Эх сурвалж: боловсруулсан багийн шийдэл{" "}
        </span>
        <span id="tf_91" class="t s7_91">
          Сургалтын хөтөлбөрийн уялдаа холбоо гэж юу вэ?{" "}
        </span>
        <span id="tg_91" class="t s3_91">
          Сургалтын хөтөлбөрийн уялдаа холбоо гэдэгт сургалтын хөтөлбөр{" "}
        </span>
        <span id="th_91" class="t s3_91">
          хоорондоо уялдаатай байж, сурган хүмүүжүүлэх арга зүй болон үнэлгээнд{" "}
        </span>
        <span id="ti_91" class="t s3_91">
          дэмжлэг үзүүлэхийг ойлгоно. Уялдаа холбоо нь хэрэгжилтийг нягт нямбай{" "}
        </span>
        <span id="tj_91" class="t s3_91">
          болгон чанартай үр дүнд хүрэхэд нөлөөлдөг. Сургалтын хөтөлбөрийн{" "}
        </span>
        <span id="tk_91" class="t s3_91">
          уялдаа холбоог дотоод болон гадаад уялдаа холбоонд хамааруулж авч{" "}
        </span>
        <span id="tl_91" class="t s3_91">
          үздэг. Тухайлбал,{" "}
        </span>
        <span id="tm_91" class="t s8_91">
          •{" "}
        </span>
        <span id="tn_91" class="t s3_91">
          Дотоод уялдаа холбоо нь сургалтын хөтөлбөрийн загвар (дизайн),{" "}
        </span>
        <span id="to_91" class="t s3_91">
          бүтэц, ахиц дэвшилтэй холбоотой юм.{" "}
        </span>
        <span id="tp_91" class="t s8_91">
          •{" "}
        </span>
        <span id="tq_91" class="t s3_91">
          Гадаад уялдаа холбоо нь сургалтын хөтөлбөрийн талаар нэгдмэл{" "}
        </span>
        <span id="tr_91" class="t s3_91">
          ойлголттой болгох, нийцтэй байдал, зөвшилцлийн цогц юм.{" "}
        </span>
      </div>
    </div>
  );
}
