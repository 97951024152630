import React from "react";
export default function SixtyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_61{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_61{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_61{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:4.26px;}
#t4_61{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:1.32px;}
#t5_61{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:4.35px;}
#t6_61{left:87px;bottom:880px;letter-spacing:-0.13px;word-spacing:-0.96px;}
#t7_61{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t8_61{left:123px;bottom:832px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t9_61{left:495px;bottom:689px;letter-spacing:-0.13px;}
#ta_61{left:511px;bottom:672px;letter-spacing:-0.13px;}
#tb_61{left:87px;bottom:521px;letter-spacing:-0.27px;word-spacing:-0.64px;}
#tc_61{left:87px;bottom:501px;letter-spacing:-0.28px;word-spacing:-0.79px;}
#td_61{left:87px;bottom:481px;letter-spacing:-0.3px;word-spacing:0.04px;}
#te_61{left:113px;bottom:459px;}
#tf_61{left:139px;bottom:458px;letter-spacing:0.03px;word-spacing:0.04px;}
#tg_61{left:113px;bottom:441px;}
#th_61{left:139px;bottom:440px;word-spacing:-0.3px;}
#ti_61{left:139px;bottom:422px;letter-spacing:0.03px;word-spacing:0.05px;}
#tj_61{left:113px;bottom:404px;}
#tk_61{left:139px;bottom:403px;letter-spacing:0.01px;word-spacing:9.78px;}
#tl_61{left:139px;bottom:385px;letter-spacing:0.04px;word-spacing:-0.43px;}
#tm_61{left:139px;bottom:367px;letter-spacing:0.03px;word-spacing:0.04px;}
#tn_61{left:87px;bottom:334px;letter-spacing:0.06px;word-spacing:8.48px;}
#to_61{left:87px;bottom:313px;letter-spacing:0.06px;word-spacing:3.76px;}
#tp_61{left:87px;bottom:293px;letter-spacing:0.06px;}
#tq_61{left:232px;bottom:293px;letter-spacing:0.09px;}
#tr_61{left:295px;bottom:293px;letter-spacing:0.07px;}
#ts_61{left:402px;bottom:293px;letter-spacing:0.08px;}
#tt_61{left:466px;bottom:293px;letter-spacing:0.07px;}
#tu_61{left:602px;bottom:293px;letter-spacing:0.07px;}
#tv_61{left:87px;bottom:273px;letter-spacing:0.06px;}
#tw_61{left:87px;bottom:240px;letter-spacing:-0.13px;}
#tx_61{left:222px;bottom:240px;letter-spacing:-0.15px;}
#ty_61{left:337px;bottom:240px;letter-spacing:-0.17px;}
#tz_61{left:444px;bottom:240px;letter-spacing:-0.17px;}
#t10_61{left:574px;bottom:240px;letter-spacing:-0.16px;}
#t11_61{left:87px;bottom:220px;letter-spacing:-0.12px;word-spacing:2.46px;}
#t12_61{left:618px;bottom:220px;letter-spacing:-0.13px;}
#t13_61{left:87px;bottom:200px;letter-spacing:-0.18px;word-spacing:5.48px;}
#t14_61{left:87px;bottom:179px;letter-spacing:-0.14px;word-spacing:5.7px;}
#t15_61{left:87px;bottom:159px;letter-spacing:-0.15px;word-spacing:8.14px;}
#t16_61{left:87px;bottom:139px;letter-spacing:-0.13px;word-spacing:1px;}
#t17_61{left:87px;bottom:119px;letter-spacing:-0.14px;word-spacing:4.42px;}

.s0_61{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_61{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_61{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_61{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s4_61{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_61{font-size:15px;font-family:MogulArial_18b;color:#000;}
.s6_61{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.t.m0_61{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts61" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg61" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="61/61.svg"
          type="image/svg+xml"
          id="pdf61"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_61" class="t s1_61">
          61{" "}
        </span>
        <span id="t3_61" class="t s2_61">
          Эндээс харахад А сургуулийн хувьд бага боловсролын түвшинд сурагч{" "}
        </span>
        <span id="t4_61" class="t s2_61">
          хамгааллын асуудлыг анхаарах шаардлагатай. Сурагч, эцэг эх, нийгмийн{" "}
        </span>
        <span id="t5_61" class="t s2_61">
          ажилтан, сургалтын менежер, багш нарыг хүүхдийн хөгжил хамгааллыг{" "}
        </span>
        <span id="t6_61" class="t s2_61">
          сайжруулах ямар зорилго, зорилтыг дэвшүүлэх улмаар ямар үйл ажиллагаа{" "}
        </span>
        <span id="t7_61" class="t s2_61">
          хэрэгжүүлэхдээ тэдний оролцоог хэрхэн хангах гэсэн асуулт гарч байна.{" "}
        </span>
        <span id="t8_61" class="t s3_61">
          Зураг 2.12. Сургалтын байгууллагын үнэлгээ (Багш, ажилтны тасралтгүй
          хөгжил){" "}
        </span>
        <span id="t9_61" class="t s4_61">
          Багшийн{" "}
        </span>
        <span id="ta_61" class="t s4_61">
          нэр{" "}
        </span>
        <span id="tb_61" class="t s2_61">
          “3. Багшийн тасралтгүй хөгжил” шалгуурыг “мэргэжлийн хөгжил”, “багшийн
          ёс{" "}
        </span>
        <span id="tc_61" class="t s2_61">
          зүй”, “мэргэжлийн бүлгийн хөгжил”, “технологи ашиглалт” гэсэн 4
          үзүүлэлтээр{" "}
        </span>
        <span id="td_61" class="t s2_61">
          үнэлсэн болно. Бидний жишээ болгон авсан А сургуулийн хувьд,{" "}
        </span>
        <span id="te_61" class="t s5_61">
          ●{" "}
        </span>
        <span id="tf_61" class="t s2_61">
          Багшийн ёс зүй бусад 3 үзүүлэлттэй харьцуулахад дээгүүр байна.{" "}
        </span>
        <span id="tg_61" class="t s5_61">
          ●{" "}
        </span>
        <span id="th_61" class="t s2_61">
          Технологи ашиглалтын үзүүлэлт бусад 3 үзүүлэлттэй харьцуулахад{" "}
        </span>
        <span id="ti_61" class="t s2_61">
          доогуур байна.{" "}
        </span>
        <span id="tj_61" class="t s5_61">
          ●{" "}
        </span>
        <span id="tk_61" class="t s2_61">
          Оноор нь харьцуулбал, багшийн ёс зүйн үзүүлэлт буурсан,{" "}
        </span>
        <span id="tl_61" class="t s2_61">
          мэргэжлийн бүлгийн хөгжил өссөн, технологи ашиглалтын үзүүлэлт{" "}
        </span>
        <span id="tm_61" class="t s2_61">
          өссөн байна.{" "}
        </span>
        <span id="tn_61" class="t s2_61">
          Сургалтын орчин бүрдүүлэлт, удирдлагын манлайлал, хэрэглэгчийн{" "}
        </span>
        <span id="to_61" class="t s2_61">
          сэтгэл ханамж гэсэн шалгууруудын үзүүлэлт тус бүрийн үр дүнд дээр{" "}
        </span>
        <span id="tp_61" class="t s2_61">
          зөвлөмжилсний{" "}
        </span>
        <span id="tq_61" class="t s2_61">
          дагуу{" "}
        </span>
        <span id="tr_61" class="t s2_61">
          шинжилгээ{" "}
        </span>
        <span id="ts_61" class="t s2_61">
          хийж,{" "}
        </span>
        <span id="tt_61" class="t s2_61">
          сургуулийнхаа{" "}
        </span>
        <span id="tu_61" class="t s2_61">
          асуудлыг{" "}
        </span>
        <span id="tv_61" class="t s2_61">
          тодорхойлоорой.{" "}
        </span>
        <span id="tw_61" class="t s6_61">
          Хөтөлбөрийн{" "}
        </span>
        <span id="tx_61" class="t s6_61">
          хэрэгжилт:{" "}
        </span>
        <span id="ty_61" class="t s2_61">
          Сургалтын{" "}
        </span>
        <span id="tz_61" class="t s2_61">
          байгууллагын{" "}
        </span>
        <span id="t10_61" class="t s2_61">
          гүйцэтгэлийн{" "}
        </span>
        <span id="t11_61" class="t s2_61">
          “Хөтөлбөрийн хэрэгжилт”-ийн үнэлгээний үр дүнгийн мэдээллийн{" "}
        </span>
        <span id="t12_61" class="t s2_61">
          цонхны{" "}
        </span>
        <span id="t13_61" class="t s2_61">
          харагдах байдлыг Зураг 9-д харуулав. Тус цонхонд тухайн сургуулийн{" "}
        </span>
        <span id="t14_61" class="t s2_61">
          хөтөлбөрийн хэрэгжилт буюу бичгийн шалгалтыг судлагдахуун бүрээр,{" "}
        </span>
        <span id="t15_61" class="t s2_61">
          боловсролын түвшнээр бичгийн шалгалтад хамрагдсан суралцагчдын{" "}
        </span>
        <span id="t16_61" class="t s2_61">
          гүйцэтгэлийн дундаж үзүүлэлтийг харуулсан байна. Үүний зэрэгцээ тухайн{" "}
        </span>
        <span id="t17_61" class="t s2_61">
          сургуулийн хамаарах кластерын дундаж, улсын дунджийг харуулсан нь{" "}
        </span>
      </div>
    </div>
  );
}
