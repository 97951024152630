import React from "react";
export default function EightyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_81{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_81{left:137px;bottom:928px;}
#t3_81{left:141px;bottom:897px;letter-spacing:-0.11px;}
#t4_81{left:139px;bottom:877px;}
#t5_81{left:165px;bottom:877px;letter-spacing:-0.12px;}
#t6_81{left:252px;bottom:877px;letter-spacing:-0.16px;word-spacing:-1.72px;}
#t7_81{left:165px;bottom:857px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_81{left:139px;bottom:832px;}
#t9_81{left:165px;bottom:832px;letter-spacing:-0.16px;word-spacing:0.45px;}
#ta_81{left:165px;bottom:812px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tb_81{left:139px;bottom:788px;}
#tc_81{left:165px;bottom:788px;letter-spacing:-0.12px;word-spacing:0.03px;}
#td_81{left:350px;bottom:788px;letter-spacing:-0.13px;}
#te_81{left:139px;bottom:763px;}
#tf_81{left:165px;bottom:763px;letter-spacing:-0.12px;}
#tg_81{left:262px;bottom:763px;letter-spacing:-0.15px;word-spacing:7.34px;}
#th_81{left:165px;bottom:743px;letter-spacing:-0.16px;}
#ti_81{left:139px;bottom:718px;}
#tj_81{left:165px;bottom:718px;letter-spacing:-0.15px;word-spacing:6.77px;}
#tk_81{left:165px;bottom:698px;letter-spacing:-0.13px;}
#tl_81{left:139px;bottom:674px;}
#tm_81{left:165px;bottom:674px;letter-spacing:-0.13px;word-spacing:1.29px;}
#tn_81{left:165px;bottom:654px;letter-spacing:-0.11px;word-spacing:0.02px;}
#to_81{left:139px;bottom:629px;}
#tp_81{left:165px;bottom:629px;letter-spacing:-0.14px;word-spacing:3.86px;}
#tq_81{left:165px;bottom:609px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tr_81{left:165px;bottom:589px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ts_81{left:139px;bottom:564px;}
#tt_81{left:165px;bottom:564px;letter-spacing:-0.12px;word-spacing:0.29px;}
#tu_81{left:165px;bottom:544px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tv_81{left:139px;bottom:520px;}
#tw_81{left:165px;bottom:520px;letter-spacing:-0.12px;}
#tx_81{left:255px;bottom:520px;letter-spacing:-0.14px;word-spacing:-0.54px;}
#ty_81{left:165px;bottom:499px;letter-spacing:-0.13px;word-spacing:1.92px;}
#tz_81{left:165px;bottom:479px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t10_81{left:142px;bottom:418px;letter-spacing:0.11px;word-spacing:0.12px;}
#t11_81{left:106px;bottom:371px;letter-spacing:0.1px;word-spacing:3.66px;}
#t12_81{left:106px;bottom:353px;letter-spacing:0.13px;word-spacing:8.29px;}
#t13_81{left:106px;bottom:334px;letter-spacing:0.08px;word-spacing:0.25px;}
#t14_81{left:589px;bottom:334px;letter-spacing:0.1px;word-spacing:0.22px;}
#t15_81{left:106px;bottom:316px;letter-spacing:0.1px;word-spacing:0.05px;}
#t16_81{left:141px;bottom:287px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t17_81{left:429px;bottom:271px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_81{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_81{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_81{font-size:17px;font-family:Arial-BoldMT_1li;color:#000;}
.s3_81{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_81{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_81{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_81{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts81" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg81" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="81/81.svg"
          type="image/svg+xml"
          id="pdf81"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_81" class="t s0_81">
          81{" "}
        </span>
        <span id="t2_81" class="t s1_81">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_81" class="t s2_81">
          Багш, сурагчийн харилцаа таагүй{" "}
        </span>
        <span id="t4_81" class="t s3_81">
          •{" "}
        </span>
        <span id="t5_81" class="t s4_81">
          Сурагчийн{" "}
        </span>
        <span id="t6_81" class="t s4_81">
          хөгжих, оролцох эрх зөрчигдөх, үл хайхрал, ялгаварлан{" "}
        </span>
        <span id="t7_81" class="t s4_81">
          гадуурхалд өртөхөөс бүрэн сэргийлж чаддаггүй{" "}
        </span>
        <span id="t8_81" class="t s3_81">
          •{" "}
        </span>
        <span id="t9_81" class="t s4_81">
          Хүүхэд сургуулиас завсардах, сурлагын хоцрогдол, зан төлөвийн{" "}
        </span>
        <span id="ta_81" class="t s4_81">
          доголдолд орох эрсдэлтэй.{" "}
        </span>
        <span id="tb_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tc_81" class="t s4_81">
          Үе тэнгийн дээрэлхэлт{" "}
        </span>
        <span id="td_81" class="t s4_81">
          ихтэй.{" "}
        </span>
        <span id="te_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tf_81" class="t s4_81">
          Сурагчид{" "}
        </span>
        <span id="tg_81" class="t s4_81">
          холоос хичээлд явдаг нь аливаа эрсдэлд өртөх{" "}
        </span>
        <span id="th_81" class="t s4_81">
          магадлалтай{" "}
        </span>
        <span id="ti_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tj_81" class="t s4_81">
          Хүүхэд хамгааллын ажиллах зарчмын талаар багш, эцэг эх{" "}
        </span>
        <span id="tk_81" class="t s4_81">
          мэдлэггүй.{" "}
        </span>
        <span id="tl_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tm_81" class="t s4_81">
          Сурагчид тулгамдсан асуудлаа шийдэж чадахгүй сэтгэл гутралд{" "}
        </span>
        <span id="tn_81" class="t s4_81">
          орох зэрэг эрсдэлтэй.{" "}
        </span>
        <span id="to_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tp_81" class="t s4_81">
          Ар гэрийн асуудалтай, асран хамгаалагч архины хамааралтай{" "}
        </span>
        <span id="tq_81" class="t s4_81">
          хүүхдүүд байдаг тул хичээл амрах үед сурагчдын амьдрах орчны{" "}
        </span>
        <span id="tr_81" class="t s4_81">
          аюулгүй байдал шийдвэрлэхэд төвэгтэй.{" "}
        </span>
        <span id="ts_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tt_81" class="t s4_81">
          Дугуйлан секцийн үйл ажиллагаа тогтмол бус, сурагч бүр өөрийн{" "}
        </span>
        <span id="tu_81" class="t s4_81">
          сонголтоор хамрагдах боломжгүй.{" "}
        </span>
        <span id="tv_81" class="t s3_81">
          •{" "}
        </span>
        <span id="tw_81" class="t s4_81">
          Сурагчийн{" "}
        </span>
        <span id="tx_81" class="t s4_81">
          өөрийн удирдлагатай бие даасан клуб, зөвлөлийн үйл{" "}
        </span>
        <span id="ty_81" class="t s4_81">
          ажиллагаа сул, нийгмийн харилцаанд бие даан оролцох хөгжих{" "}
        </span>
        <span id="tz_81" class="t s4_81">
          боломж эрх хязгаарлагдмал.{" "}
        </span>
        <span id="t10_81" class="t s1_81">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t11_81" class="t s5_81">
          Хүүхдийн Итгэлтэй утсыг сургуулийн орчинд тавьж өгсөн. Сэтгэл зүйчийн{" "}
        </span>
        <span id="t12_81" class="t s5_81">
          дэргэд “Сурагчдын бүлэг” байгуулан ажилласан. Сургуулийн хүүхдийн{" "}
        </span>
        <span id="t13_81" class="t s5_81">
          автобус Чингэлтэйн эцэсээс 37-р сургууль чиглэлд явж эхлэн нийт{" "}
        </span>
        <span id="t14_81" class="t s5_81">
          20 сурагч{" "}
        </span>
        <span id="t15_81" class="t s5_81">
          үйлчлүүлж байна.{" "}
        </span>
        <span id="t16_81" class="t s6_81">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн
          менежментийг{" "}
        </span>
        <span id="t17_81" class="t s6_81">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
