import React from "react";
export default function HundredTwentySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_127{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_127{left:142px;bottom:928px;letter-spacing:-0.29px;word-spacing:0.18px;}
#t3_127{left:143px;bottom:897px;}
#t4_127{left:169px;bottom:897px;letter-spacing:-0.19px;word-spacing:5.32px;}
#t5_127{left:169px;bottom:879px;letter-spacing:-0.19px;}
#t6_127{left:266px;bottom:879px;letter-spacing:-0.1px;}
#t7_127{left:143px;bottom:860px;}
#t8_127{left:169px;bottom:860px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t9_127{left:429px;bottom:860px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ta_127{left:142px;bottom:801px;letter-spacing:0.1px;word-spacing:0.12px;}
#tb_127{left:87px;bottom:760px;letter-spacing:-0.14px;word-spacing:2.06px;}
#tc_127{left:576px;bottom:760px;letter-spacing:-0.11px;}
#td_127{left:87px;bottom:739px;letter-spacing:-0.2px;word-spacing:0.1px;}
#te_127{left:355px;bottom:704px;letter-spacing:-0.14px;word-spacing:0.02px;}
#tf_127{left:166px;bottom:372px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tg_127{left:87px;bottom:330px;letter-spacing:-0.13px;word-spacing:2.89px;}
#th_127{left:87px;bottom:310px;letter-spacing:-0.17px;word-spacing:-1.9px;}
#ti_127{left:87px;bottom:290px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_127{left:87px;bottom:256px;letter-spacing:-0.16px;word-spacing:1.32px;}
#tk_127{left:87px;bottom:236px;letter-spacing:-0.15px;word-spacing:2.35px;}
#tl_127{left:87px;bottom:216px;letter-spacing:-0.13px;word-spacing:-0.02px;}
#tm_127{left:87px;bottom:196px;letter-spacing:-0.12px;word-spacing:0.02px;}

.s0_127{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_127{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_127{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s3_127{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_127{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_127{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s6_127{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts127" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg127" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="127/127.svg"
          type="image/svg+xml"
          id="pdf127"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_127" class="t s0_127">
          127{" "}
        </span>
        <span id="t2_127" class="t s1_127">
          АСУУДАЛ 3{" "}
        </span>
        <span id="t3_127" class="t s2_127">
          -{" "}
        </span>
        <span id="t4_127" class="t s2_127">
          Үнэлгээний шалгуурын тухай мэдлэг ойлголт сул, төлөвлөлт,{" "}
        </span>
        <span id="t5_127" class="t s2_127">
          даалгаварт{" "}
        </span>
        <span id="t6_127" class="t s2_127">
          тусаагүй{" "}
        </span>
        <span id="t7_127" class="t s2_127">
          -{" "}
        </span>
        <span id="t8_127" class="t s2_127">
          Даалгавар сонгох/боловсруулах{" "}
        </span>
        <span id="t9_127" class="t s2_127">
          мэдлэг чадвар, арга зүй дутмаг{" "}
        </span>
        <span id="ta_127" class="t s3_127">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tb_127" class="t s4_127">
          Энэхүү нэгжид хичээл сургалтын үр дүнг үнэлэх шалгуурыг{" "}
        </span>
        <span id="tc_127" class="t s4_127">
          суралцахуйн{" "}
        </span>
        <span id="td_127" class="t s4_127">
          зорилт, ээлжит хичээлтэйгээ уялдуулан буулгасан байна{" "}
        </span>
        <span id="te_127" class="t s5_127">
          Хүснэгт 3.14. Нэгж хичээлийн үнэлгээний шалгуур{" "}
        </span>
        <span id="tf_127" class="t s6_127">
          Эх сурвалж: ...аймгийн ...–р сургууль физикийн хичээлийн нэгжийн
          төлөвлөлт{" "}
        </span>
        <span id="tg_127" class="t s4_127">
          Энэхүү нэгжид хичээл сургалтын үр дүнг үнэлэх шалгуурыг суралцахуйн{" "}
        </span>
        <span id="th_127" class="t s4_127">
          зорилт, ээлжит хичээлтэйгээ уялдуулан, гүйцэтгэлийн түвшин бүрт
          харгалзах{" "}
        </span>
        <span id="ti_127" class="t s4_127">
          даалгавар төлөвлөсөн байна.{" "}
        </span>
        <span id="tj_127" class="t s4_127">
          Сургуулийн удирдах ажилтнуудад явцын үнэлгээний арга хэрэгслийг багш{" "}
        </span>
        <span id="tk_127" class="t s4_127">
          нартай хамтран зохион байгуулах, ур чадварыг нь хөгжүүлэх, сургуулийн{" "}
        </span>
        <span id="tl_127" class="t s4_127">
          хэмжээнд явцын үнэлгээ төлөвлөх, хэрэгжүүлэх чиглэлээр хийж буй ажлын{" "}
        </span>
        <span id="tm_127" class="t s4_127">
          туршлагыг жишээгээр өглөө.{" "}
        </span>
      </div>
    </div>
  );
}
