import React from "react";
export default function F137() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_137{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_137{left:100px;bottom:933px;letter-spacing:0.13px;}
#t3_137{left:184px;bottom:933px;letter-spacing:0.08px;word-spacing:0.07px;}
#t4_137{left:107px;bottom:902px;letter-spacing:0.1px;word-spacing:2.14px;}
#t5_137{left:100px;bottom:883px;letter-spacing:0.1px;word-spacing:0.03px;}
#t6_137{left:100px;bottom:865px;}
#t7_137{left:118px;bottom:865px;letter-spacing:0.1px;word-spacing:-0.47px;}
#t8_137{left:118px;bottom:847px;letter-spacing:0.12px;}
#t9_137{left:100px;bottom:824px;}
#ta_137{left:118px;bottom:824px;letter-spacing:0.07px;word-spacing:0.4px;}
#tb_137{left:118px;bottom:806px;letter-spacing:0.07px;word-spacing:0.06px;}
#tc_137{left:100px;bottom:783px;}
#td_137{left:118px;bottom:783px;letter-spacing:0.12px;word-spacing:-0.07px;}
#te_137{left:118px;bottom:765px;letter-spacing:0.1px;word-spacing:-0.56px;}
#tf_137{left:468px;bottom:765px;letter-spacing:0.08px;word-spacing:-0.53px;}
#tg_137{left:118px;bottom:746px;letter-spacing:0.01px;word-spacing:0.13px;}
#th_137{left:100px;bottom:724px;}
#ti_137{left:118px;bottom:724px;letter-spacing:0.12px;word-spacing:1.86px;}
#tj_137{left:118px;bottom:705px;letter-spacing:0.07px;word-spacing:0.06px;}
#tk_137{left:100px;bottom:683px;}
#tl_137{left:118px;bottom:683px;letter-spacing:0.11px;word-spacing:3.16px;}
#tm_137{left:118px;bottom:664px;letter-spacing:0.11px;word-spacing:0.02px;}
#tn_137{left:256px;bottom:664px;letter-spacing:0.07px;word-spacing:0.07px;}
#to_137{left:103px;bottom:614px;letter-spacing:0.13px;}
#tp_137{left:187px;bottom:614px;letter-spacing:0.12px;word-spacing:0.02px;}
#tq_137{left:459px;bottom:614px;letter-spacing:0.04px;word-spacing:0.11px;}
#tr_137{left:112px;bottom:582px;letter-spacing:0.11px;word-spacing:4.25px;}
#ts_137{left:103px;bottom:564px;letter-spacing:0.08px;word-spacing:-0.71px;}
#tt_137{left:103px;bottom:546px;letter-spacing:0.09px;word-spacing:0.04px;}
#tu_137{left:103px;bottom:527px;}
#tv_137{left:121px;bottom:527px;letter-spacing:0.11px;word-spacing:6.22px;}
#tw_137{left:121px;bottom:509px;letter-spacing:0.11px;word-spacing:0.03px;}
#tx_137{left:103px;bottom:486px;}
#ty_137{left:121px;bottom:486px;letter-spacing:0.1px;word-spacing:-1.64px;}
#tz_137{left:121px;bottom:468px;letter-spacing:0.09px;word-spacing:0.05px;}
#t10_137{left:103px;bottom:445px;}
#t11_137{left:121px;bottom:445px;letter-spacing:0.13px;word-spacing:-1.01px;}
#t12_137{left:327px;bottom:445px;letter-spacing:0.09px;word-spacing:-0.98px;}
#t13_137{left:595px;bottom:445px;letter-spacing:0.13px;}
#t14_137{left:121px;bottom:427px;letter-spacing:0.12px;word-spacing:0.02px;}
#t15_137{left:103px;bottom:404px;}
#t16_137{left:121px;bottom:404px;letter-spacing:0.09px;word-spacing:3.76px;}
#t17_137{left:121px;bottom:386px;letter-spacing:0.1px;word-spacing:-0.86px;}
#t18_137{left:103px;bottom:363px;}
#t19_137{left:121px;bottom:363px;letter-spacing:0.1px;word-spacing:0.58px;}
#t1a_137{left:121px;bottom:345px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1b_137{left:103px;bottom:322px;}
#t1c_137{left:121px;bottom:322px;letter-spacing:0.1px;word-spacing:3.11px;}
#t1d_137{left:121px;bottom:304px;letter-spacing:0.07px;word-spacing:0.06px;}
#t1e_137{left:103px;bottom:281px;}
#t1f_137{left:121px;bottom:281px;letter-spacing:-0.07px;word-spacing:-0.96px;}
#t1g_137{left:121px;bottom:263px;letter-spacing:-0.04px;word-spacing:0.02px;}
#t1h_137{left:103px;bottom:240px;}
#t1i_137{left:121px;bottom:240px;letter-spacing:0.12px;word-spacing:5.98px;}
#t1j_137{left:121px;bottom:222px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1k_137{left:103px;bottom:199px;}
#t1l_137{left:121px;bottom:199px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1m_137{left:103px;bottom:177px;}
#t1n_137{left:121px;bottom:177px;letter-spacing:-0.26px;word-spacing:7.54px;}
#t1o_137{left:121px;bottom:158px;letter-spacing:-0.19px;word-spacing:4.23px;}
#t1p_137{left:121px;bottom:140px;letter-spacing:-0.22px;word-spacing:-0.15px;}
#t1q_137{left:103px;bottom:117px;}
#t1r_137{left:121px;bottom:117px;letter-spacing:-0.05px;word-spacing:0.04px;}

.s0_137{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_137{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s2_137{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s3_137{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_137{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts137" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg137" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="137/137.svg"
          type="image/svg+xml"
          id="pdf137"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_137" class="t s0_137">
          137{" "}
        </span>
        <span id="t2_137" class="t s1_137">
          Шигтгээ 3:{" "}
        </span>
        <span id="t3_137" class="t s2_137">
          Өөрийгөө үнэлэх товч асуулт{" "}
        </span>
        <span id="t4_137" class="t s3_137">
          Сурагчийн гүйцэтгэлийг сайжруулахад үнэлгээний ямар төрлүүдийг хэрхэн{" "}
        </span>
        <span id="t5_137" class="t s3_137">
          ашигладаг вэ?{" "}
        </span>
        <span id="t6_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t7_137" class="t s3_137">
          Сурагчдынхаа ахиц дэвшлийг үнэлэхийн тулд танд ямар төрлийн мэдээлэл{" "}
        </span>
        <span id="t8_137" class="t s3_137">
          хэрэгтэй вэ?{" "}
        </span>
        <span id="t9_137" class="t s4_137">
          -{" "}
        </span>
        <span id="ta_137" class="t s3_137">
          Суралцахуйн үр дүн, шалгуур, үнэлгээний даалгавар ба хичээл төлөвлөлт,{" "}
        </span>
        <span id="tb_137" class="t s3_137">
          түүний арга зүй хэрхэн холбогдох вэ?{" "}
        </span>
        <span id="tc_137" class="t s4_137">
          -{" "}
        </span>
        <span id="td_137" class="t s3_137">
          Сурагч эзэмшсэн байвал зохих чадварыг эзэмшсэн эсэх, эзэмшсэн байдал{" "}
        </span>
        <span id="te_137" class="t s3_137">
          нь хэр зэрэг байгааг хэрхэн илрүүлэх вэ? Энэхүү{" "}
        </span>
        <span id="tf_137" class="t s3_137">
          мэдээлэл нь багшид ямар{" "}
        </span>
        <span id="tg_137" class="t s3_137">
          ач холбогдолтой вэ?{" "}
        </span>
        <span id="th_137" class="t s4_137">
          -{" "}
        </span>
        <span id="ti_137" class="t s3_137">
          Суралцахуйн үр дүн, шалгуур, гүйцэтгэлийн дөрвөн түвшний даалгаврын{" "}
        </span>
        <span id="tj_137" class="t s3_137">
          талаарх ойлголтоо хэрхэн үнэлэх вэ?{" "}
        </span>
        <span id="tk_137" class="t s4_137">
          -{" "}
        </span>
        <span id="tl_137" class="t s3_137">
          Зорилгодоо хүрсэн эсэхийг илрүүлэх үнэлгээний гүйцэтгэлийн 4 түвшин{" "}
        </span>
        <span id="tm_137" class="t s3_137">
          бүхий даалгаврын{" "}
        </span>
        <span id="tn_137" class="t s3_137">
          боловсруулалтдаа та сэтгэл хангалуун байдаг уу?{" "}
        </span>
        <span id="to_137" class="t s1_137">
          Шигтгээ 4:{" "}
        </span>
        <span id="tp_137" class="t s2_137">
          Арга зүйн талаар өөрийгөө үнэлэх{" "}
        </span>
        <span id="tq_137" class="t s2_137">
          товч асуулт{" "}
        </span>
        <span id="tr_137" class="t s3_137">
          Сургалтын үр нөлөөг нэмэгдүүлэхийн тулд сурагчдын хэрэгцээ, чадварт{" "}
        </span>
        <span id="ts_137" class="t s3_137">
          тохирсон сургалтын арга стратегийг боловсруулж чадаж буй гэдэгтээ
          итгэлтэй{" "}
        </span>
        <span id="tt_137" class="t s3_137">
          байдаг уу?{" "}
        </span>
        <span id="tu_137" class="t s4_137">
          -{" "}
        </span>
        <span id="tv_137" class="t s3_137">
          Сурагчдыг суралцахуйд татан оролцуулах, сонирхлыг төрүүлэх бодит{" "}
        </span>
        <span id="tw_137" class="t s3_137">
          амьдрал дээрх жишээ, зүйрлэл, нөхцөл байдлыг тусгаж төлөвлөдөг үү?{" "}
        </span>
        <span id="tx_137" class="t s4_137">
          -{" "}
        </span>
        <span id="ty_137" class="t s3_137">
          Асуултуудыг танин мэдэхүйн доод эрэмбээс дээд эрэмбийн дагуу урьдчилан{" "}
        </span>
        <span id="tz_137" class="t s3_137">
          бэлтгэдэг үү? Сурагчид хэрхэн хариулж болох хувилбаруудыг тооцдог уу?{" "}
        </span>
        <span id="t10_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t11_137" class="t s3_137">
          Хамтран ажиллах, эрж хайх,{" "}
        </span>
        <span id="t12_137" class="t s3_137">
          оновчтойг сонгох, эргэцүүлэн дүгнэж,{" "}
        </span>
        <span id="t13_137" class="t s3_137">
          өөрсдөө{" "}
        </span>
        <span id="t14_137" class="t s3_137">
          мэдлэг бүтээх танин мэдэхүйн дээд түвшний үйлээр хөтөлдөг үү?{" "}
        </span>
        <span id="t15_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t16_137" class="t s3_137">
          Сургуулийн зорилгоор тодорхойлогдсон сурагчдад тулгамдсан асуудлыг{" "}
        </span>
        <span id="t17_137" class="t s3_137">
          шийдвэрлэхэд хувь нэмэр оруулах үйл ажиллагаа, арга зүйг төлөвлөдөг
          үү?{" "}
        </span>
        <span id="t18_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t19_137" class="t s3_137">
          Сурагчдад юуг хэрхэн сайжруулах, өөрийгөө болон бусдыг үнэлэх боломж{" "}
        </span>
        <span id="t1a_137" class="t s3_137">
          олгон, түүнд өөрийн алхмыг нийцүүлдэг үү?{" "}
        </span>
        <span id="t1b_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1c_137" class="t s3_137">
          Суралцахуйг дэмжсэн, танин мэдэхүйн ач холбогдол өндөр эх сурвалж,{" "}
        </span>
        <span id="t1d_137" class="t s3_137">
          бусад хэрэглэгдэхүүн ашигладаг уу?{" "}
        </span>
        <span id="t1e_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1f_137" class="t s3_137">
          Сурагчдад юу амархан, юу хэцүү байх вэ гэдгийг бодолцон, ялгаатай
          байдлыг{" "}
        </span>
        <span id="t1g_137" class="t s3_137">
          зохицуулж, тохирсон үйл ажиллагаа, дэмжлэгийг үзүүлж чаддаг уу?{" "}
        </span>
        <span id="t1h_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1i_137" class="t s3_137">
          Хичээлийн төгсгөлд хүрсэн түвшинг үнэлж дүгнэсний дараа ялгаатай{" "}
        </span>
        <span id="t1j_137" class="t s3_137">
          байдлыг хангах, олон янзаар шийдэж болох бүтээлч даалгавар өгдөг үү?{" "}
        </span>
        <span id="t1k_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1l_137" class="t s3_137">
          Сурагчид хичээлд идэвхтэй оролцож, зорилгодоо хүрч чаддаг уу?{" "}
        </span>
        <span id="t1m_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1n_137" class="t s3_137">
          Хэлэлцүүлэг, мэтгэлцээн зохион байгуулах, бүтээлийн үзэсгэлэн гаргах,{" "}
        </span>
        <span id="t1o_137" class="t s3_137">
          өөрийн сурах барилаар даалгавар гүйцэтгэх, бие даах, хамтран ажиллах{" "}
        </span>
        <span id="t1p_137" class="t s3_137">
          зэргээр сурагч бүрт сурч мэдсэнээ илэрхийлэх боломжуудыг олгож чаддаг
          уу?{" "}
        </span>
        <span id="t1q_137" class="t s4_137">
          -{" "}
        </span>
        <span id="t1r_137" class="t s3_137">
          Анги танхимын менежментийн асуудлуудыг хэрхэн анхаарч, төлөвлөдөг вэ?{" "}
        </span>
      </div>
    </div>
  );
}
