import React from "react";
export default function OneHundredNinetyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_194{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_194{left:142px;bottom:931px;}
#t3_194{left:156px;bottom:903px;letter-spacing:-0.19px;word-spacing:0.08px;}
#t4_194{left:157px;bottom:882px;}
#t5_194{left:184px;bottom:882px;letter-spacing:-0.13px;word-spacing:1.16px;}
#t6_194{left:184px;bottom:862px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t7_194{left:157px;bottom:842px;}
#t8_194{left:184px;bottom:842px;letter-spacing:-0.21px;}
#t9_194{left:315px;bottom:842px;letter-spacing:-0.09px;}
#ta_194{left:393px;bottom:842px;letter-spacing:-0.18px;}
#tb_194{left:493px;bottom:842px;letter-spacing:-0.09px;}
#tc_194{left:570px;bottom:842px;letter-spacing:-0.09px;}
#td_194{left:632px;bottom:842px;letter-spacing:-0.08px;}
#te_194{left:184px;bottom:822px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tf_194{left:157px;bottom:802px;}
#tg_194{left:184px;bottom:802px;letter-spacing:-0.15px;word-spacing:0.06px;}
#th_194{left:157px;bottom:782px;}
#ti_194{left:184px;bottom:782px;letter-spacing:-0.19px;word-spacing:2.5px;}
#tj_194{left:184px;bottom:761px;letter-spacing:-0.07px;}
#tk_194{left:142px;bottom:711px;letter-spacing:0.18px;}
#tl_194{left:94px;bottom:675px;}
#tm_194{left:141px;bottom:675px;letter-spacing:-0.22px;}
#tn_194{left:477px;bottom:675px;letter-spacing:-0.21px;}
#to_194{left:98px;bottom:556px;}
#tp_194{left:122px;bottom:616px;letter-spacing:-0.12px;}
#tq_194{left:122px;bottom:598px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tr_194{left:122px;bottom:580px;letter-spacing:-0.32px;}
#ts_194{left:122px;bottom:561px;letter-spacing:-0.14px;}
#tt_194{left:122px;bottom:543px;letter-spacing:-0.46px;}
#tu_194{left:122px;bottom:525px;letter-spacing:-0.14px;}
#tv_194{left:122px;bottom:506px;letter-spacing:-0.2px;word-spacing:0.07px;}
#tw_194{left:240px;bottom:647px;letter-spacing:-0.16px;word-spacing:8.39px;}
#tx_194{left:375px;bottom:647px;letter-spacing:-0.07px;}
#ty_194{left:390px;bottom:647px;letter-spacing:-0.19px;word-spacing:8.43px;}
#tz_194{left:240px;bottom:629px;letter-spacing:-0.21px;word-spacing:0.86px;}
#t10_194{left:240px;bottom:611px;letter-spacing:-0.14px;word-spacing:-0.16px;}
#t11_194{left:240px;bottom:592px;letter-spacing:-0.14px;word-spacing:2.69px;}
#t12_194{left:240px;bottom:574px;letter-spacing:-0.17px;word-spacing:2.07px;}
#t13_194{left:240px;bottom:556px;letter-spacing:-0.15px;word-spacing:4.06px;}
#t14_194{left:240px;bottom:537px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t15_194{left:240px;bottom:519px;letter-spacing:-0.17px;word-spacing:2.61px;}
#t16_194{left:240px;bottom:501px;letter-spacing:-0.14px;word-spacing:-0.11px;}
#t17_194{left:240px;bottom:482px;letter-spacing:-0.16px;word-spacing:3.54px;}
#t18_194{left:240px;bottom:464px;letter-spacing:-0.12px;}
#t19_194{left:98px;bottom:397px;}
#t1a_194{left:122px;bottom:434px;letter-spacing:-0.12px;}
#t1b_194{left:122px;bottom:416px;letter-spacing:-0.17px;}
#t1c_194{left:122px;bottom:397px;letter-spacing:-0.12px;}
#t1d_194{left:122px;bottom:379px;letter-spacing:-0.12px;}
#t1e_194{left:122px;bottom:361px;letter-spacing:-0.15px;}
#t1f_194{left:245px;bottom:425px;letter-spacing:-0.19px;word-spacing:1.02px;}
#t1g_194{left:240px;bottom:407px;letter-spacing:-0.2px;word-spacing:2.02px;}
#t1h_194{left:240px;bottom:388px;letter-spacing:-0.16px;word-spacing:2.2px;}
#t1i_194{left:240px;bottom:370px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t1j_194{left:98px;bottom:228px;}
#t1k_194{left:122px;bottom:265px;letter-spacing:-0.12px;}
#t1l_194{left:122px;bottom:246px;letter-spacing:-0.2px;}
#t1m_194{left:122px;bottom:228px;letter-spacing:-0.16px;}
#t1n_194{left:122px;bottom:210px;letter-spacing:-0.2px;}
#t1o_194{left:122px;bottom:191px;letter-spacing:-0.16px;}
#t1p_194{left:240px;bottom:329px;letter-spacing:-0.18px;word-spacing:-0.46px;}
#t1q_194{left:240px;bottom:310px;letter-spacing:-0.16px;word-spacing:2.89px;}
#t1r_194{left:240px;bottom:292px;letter-spacing:-0.17px;word-spacing:2.7px;}
#t1s_194{left:240px;bottom:274px;letter-spacing:-0.12px;word-spacing:-0.91px;}
#t1t_194{left:240px;bottom:255px;letter-spacing:-0.19px;word-spacing:4.69px;}
#t1u_194{left:240px;bottom:237px;letter-spacing:-0.16px;word-spacing:1.98px;}
#t1v_194{left:240px;bottom:219px;letter-spacing:-0.15px;word-spacing:-0.74px;}
#t1w_194{left:240px;bottom:200px;letter-spacing:-0.16px;word-spacing:4.16px;}
#t1x_194{left:240px;bottom:182px;letter-spacing:-0.19px;word-spacing:2.78px;}
#t1y_194{left:240px;bottom:164px;letter-spacing:-0.16px;word-spacing:-0.99px;}
#t1z_194{left:240px;bottom:146px;letter-spacing:-0.19px;word-spacing:3.34px;}
#t20_194{left:240px;bottom:127px;letter-spacing:-0.15px;}

.s0_194{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_194{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_194{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_194{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s4_194{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_194{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}
.s6_194{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts194" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg194" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="194/194.svg"
          type="image/svg+xml"
          id="pdf194"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_194" class="t s0_194">
          194{" "}
        </span>
        <span id="t2_194" class="t s1_194">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_194" class="t s2_194">
          Сургуулийн үнэлэмж тааруу, удирдах багийн чадавх сул{" "}
        </span>
        <span id="t4_194" class="t s3_194">
          ●{" "}
        </span>
        <span id="t5_194" class="t s4_194">
          Сургалтын чанар, сургуулийн үйл ажиллагаанд эцэг эх, асран{" "}
        </span>
        <span id="t6_194" class="t s4_194">
          хамгаалагч, харгалзан дэмжигчид сэтгэл хангалуун бус{" "}
        </span>
        <span id="t7_194" class="t s3_194">
          ●{" "}
        </span>
        <span id="t8_194" class="t s4_194">
          Судлагдахуун{" "}
        </span>
        <span id="t9_194" class="t s4_194">
          бүрийн{" "}
        </span>
        <span id="ta_194" class="t s4_194">
          сургалтад{" "}
        </span>
        <span id="tb_194" class="t s4_194">
          анализ{" "}
        </span>
        <span id="tc_194" class="t s4_194">
          хийж{" "}
        </span>
        <span id="td_194" class="t s4_194">
          засан{" "}
        </span>
        <span id="te_194" class="t s4_194">
          сайжруулалтын явц, үр дүнг тооцдоггүй{" "}
        </span>
        <span id="tf_194" class="t s3_194">
          ●{" "}
        </span>
        <span id="tg_194" class="t s4_194">
          Сургалтын чанарыг сайжруулах санаачлага гаргадаггүй{" "}
        </span>
        <span id="th_194" class="t s3_194">
          ●{" "}
        </span>
        <span id="ti_194" class="t s4_194">
          Зохион байгуулалт хийх чадвар дутмаг, оновчтой менежмент{" "}
        </span>
        <span id="tj_194" class="t s4_194">
          хийдэггүй{" "}
        </span>
        <span id="tk_194" class="t s1_194">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tl_194" class="t s5_194">
          №{" "}
        </span>
        <span id="tm_194" class="t s6_194">
          Шаардлага{" "}
        </span>
        <span id="tn_194" class="t s6_194">
          Зөвлөмж{" "}
        </span>
        <span id="to_194" class="t s6_194">
          1{" "}
        </span>
        <span id="tp_194" class="t s6_194">
          Захирал нь{" "}
        </span>
        <span id="tq_194" class="t s6_194">
          албан тушаалын{" "}
        </span>
        <span id="tr_194" class="t s6_194">
          тодорхойлолт,{" "}
        </span>
        <span id="ts_194" class="t s6_194">
          сонгон{" "}
        </span>
        <span id="tt_194" class="t s6_194">
          шалгаруулалтын{" "}
        </span>
        <span id="tu_194" class="t s6_194">
          шалгуур{" "}
        </span>
        <span id="tv_194" class="t s6_194">
          хангасан байх{" "}
        </span>
        <span id="tw_194" class="t s6_194">
          БСШУ-ны сайдын{" "}
        </span>
        <span id="tx_194" class="t s6_194">
          ....
        </span>
        <span id="ty_194" class="t s6_194">
          тоот тушаалыг болон ТАЗ-ийн ... тоот{" "}
        </span>
        <span id="tz_194" class="t s6_194">
          зөвлөмжийг, мөн 2023 онд батлагдсан боловсролын хуулийн дагуу{" "}
        </span>
        <span id="t10_194" class="t s6_194">
          8-аас дээш жил багшилсан, 5-аас дээш жил сургалтын менежерээр{" "}
        </span>
        <span id="t11_194" class="t s6_194">
          ажилласан, багшлах эрхтэй, мэргэжлийн тэргүүлэх зэргээс дээш{" "}
        </span>
        <span id="t12_194" class="t s6_194">
          мэргэжлийн зэрэгтэй байж захирлын сонгон шалгаруулалтад орж{" "}
        </span>
        <span id="t13_194" class="t s6_194">
          байх, ингэхдээ “Сургууль хөгжүүлэх бодлого, мөрийн хөтөлбөр”{" "}
        </span>
        <span id="t14_194" class="t s6_194">
          боловсруулж өрсөлддөг байх{" "}
        </span>
        <span id="t15_194" class="t s6_194">
          Ингэж шатлан дэвшүүлэх зарчмаар шалгуур ханган томилогдсон{" "}
        </span>
        <span id="t16_194" class="t s6_194">
          захирал сургалтын хөтөлбөрийн хэрэгжилтийг сайжруулах, үр дүнд{" "}
        </span>
        <span id="t17_194" class="t s6_194">
          хүргэх сайн менежмент хийж, багш сурагчдыг болон сургуулийг{" "}
        </span>
        <span id="t18_194" class="t s6_194">
          хөгжүүлж чадна.{" "}
        </span>
        <span id="t19_194" class="t s6_194">
          2{" "}
        </span>
        <span id="t1a_194" class="t s6_194">
          Захирал{" "}
        </span>
        <span id="t1b_194" class="t s6_194">
          шаардлагын{" "}
        </span>
        <span id="t1c_194" class="t s6_194">
          түвшинд{" "}
        </span>
        <span id="t1d_194" class="t s6_194">
          өөрийгөө{" "}
        </span>
        <span id="t1e_194" class="t s6_194">
          хөгжүүлэх{" "}
        </span>
        <span id="t1f_194" class="t s6_194">
          Захирал өөрөө сайн багш байх, сургалтад байнга хяналт тавьдаг,{" "}
        </span>
        <span id="t1g_194" class="t s6_194">
          сургалт руу ордог байх, Захирал санаачлагатай, бүтээлч, шаргуу,{" "}
        </span>
        <span id="t1h_194" class="t s6_194">
          сайн удирдагч болох, бусдын сайн туршлагаас суралцах, ёс зүйг{" "}
        </span>
        <span id="t1i_194" class="t s6_194">
          эрхэмлэх, хариуцлагатай, зарчимч байх{" "}
        </span>
        <span id="t1j_194" class="t s6_194">
          3{" "}
        </span>
        <span id="t1k_194" class="t s6_194">
          Захирал нь{" "}
        </span>
        <span id="t1l_194" class="t s6_194">
          удирдагчийн{" "}
        </span>
        <span id="t1m_194" class="t s6_194">
          олон{" "}
        </span>
        <span id="t1n_194" class="t s6_194">
          чадваруудад{" "}
        </span>
        <span id="t1o_194" class="t s6_194">
          суралцах{" "}
        </span>
        <span id="t1p_194" class="t s6_194">
          Боловсруулах, Төлөвлөх, Гүйцэтгэлийг удирдах, Зохион байгуулалт{" "}
        </span>
        <span id="t1q_194" class="t s6_194">
          хийх, Үнэлгээний үр дүнг шинжлэн суурь асуудлыг тодорхойлох,{" "}
        </span>
        <span id="t1r_194" class="t s6_194">
          тулгамдсан асуудлыг илрүүлэн шийдвэрлэх, Шинийг эрэлхийлэх,{" "}
        </span>
        <span id="t1s_194" class="t s6_194">
          Хөгжлийн төлөвийг төсөөлөн менежмент хийх, Үр дүнд анализ хийх,{" "}
        </span>
        <span id="t1t_194" class="t s6_194">
          Сайжруулах оновчтой менежмент хийх, Ажлыг гардан удирдаж{" "}
        </span>
        <span id="t1u_194" class="t s6_194">
          удирдлагын багаа чадавхижуулах, Зөвхөн аж ахуйн даргын үүрэг{" "}
        </span>
        <span id="t1v_194" class="t s6_194">
          гүйцэтгэх биш сургуулийн үндсэн үүрэг болох сургалтын менежмент{" "}
        </span>
        <span id="t1w_194" class="t s6_194">
          хийх, IT-ийн хэрэглээний программууд дээр ажиллах, Хүүхдийн{" "}
        </span>
        <span id="t1x_194" class="t s6_194">
          хөгжил төлөвшилд санаачлагатай байж зохицуулах, Урамшуулан{" "}
        </span>
        <span id="t1y_194" class="t s6_194">
          дэмжих, Бусдад боломж олгох, Мэдрэмжтэй байх, Бусдад нөлөөлөх,{" "}
        </span>
        <span id="t1z_194" class="t s6_194">
          Бусдыг дагуулах, Нөхцөл байдалд тохируулга хийх чадваруудыг{" "}
        </span>
        <span id="t20_194" class="t s6_194">
          эзэмших{" "}
        </span>
      </div>
    </div>
  );
}
