import React from "react";
export default function OneHundredSixtyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_169{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_169{left:87px;bottom:939px;letter-spacing:0.13px;word-spacing:0.05px;}
#t3_169{left:87px;bottom:899px;letter-spacing:-0.13px;word-spacing:2.49px;}
#t4_169{left:87px;bottom:879px;letter-spacing:-0.11px;word-spacing:0.53px;}
#t5_169{left:243px;bottom:879px;letter-spacing:-0.17px;word-spacing:0.6px;}
#t6_169{left:87px;bottom:859px;letter-spacing:-0.16px;word-spacing:7.74px;}
#t7_169{left:87px;bottom:839px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t8_169{left:114px;bottom:810px;letter-spacing:-0.05px;}
#t9_169{left:142px;bottom:810px;letter-spacing:-0.15px;word-spacing:2.68px;}
#ta_169{left:142px;bottom:790px;letter-spacing:-0.19px;word-spacing:3.75px;}
#tb_169{left:142px;bottom:770px;letter-spacing:-0.16px;word-spacing:7.89px;}
#tc_169{left:142px;bottom:750px;letter-spacing:-0.16px;word-spacing:6.62px;}
#td_169{left:142px;bottom:730px;letter-spacing:-0.14px;word-spacing:11.97px;}
#te_169{left:142px;bottom:709px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tf_169{left:114px;bottom:681px;letter-spacing:-0.05px;}
#tg_169{left:142px;bottom:681px;letter-spacing:-0.18px;word-spacing:-0.84px;}
#th_169{left:142px;bottom:660px;letter-spacing:-0.14px;word-spacing:2.72px;}
#ti_169{left:142px;bottom:640px;letter-spacing:-0.09px;}
#tj_169{left:229px;bottom:640px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tk_169{left:114px;bottom:611px;letter-spacing:-0.05px;}
#tl_169{left:142px;bottom:611px;letter-spacing:-0.14px;word-spacing:2.76px;}
#tm_169{left:142px;bottom:591px;letter-spacing:-0.15px;word-spacing:8.13px;}
#tn_169{left:142px;bottom:571px;letter-spacing:-0.16px;word-spacing:0.06px;}
#to_169{left:359px;bottom:545px;letter-spacing:0.1px;word-spacing:0.04px;}
#tp_169{left:119px;bottom:509px;letter-spacing:0.11px;word-spacing:0.05px;}
#tq_169{left:91px;bottom:482px;letter-spacing:0.04px;word-spacing:-0.48px;}
#tr_169{left:231px;bottom:476px;letter-spacing:0.14px;word-spacing:0.01px;}
#ts_169{left:231px;bottom:457px;letter-spacing:0.07px;}
#tt_169{left:249px;bottom:457px;letter-spacing:0.08px;word-spacing:0.07px;}
#tu_169{left:231px;bottom:439px;letter-spacing:0.07px;}
#tv_169{left:249px;bottom:439px;letter-spacing:0.12px;}
#tw_169{left:231px;bottom:421px;letter-spacing:0.07px;}
#tx_169{left:249px;bottom:421px;letter-spacing:0.11px;word-spacing:0.04px;}
#ty_169{left:231px;bottom:388px;letter-spacing:0.12px;word-spacing:-0.76px;}
#tz_169{left:231px;bottom:369px;letter-spacing:0.07px;}
#t10_169{left:249px;bottom:369px;letter-spacing:0.11px;word-spacing:0.03px;}
#t11_169{left:231px;bottom:351px;letter-spacing:0.07px;}
#t12_169{left:249px;bottom:351px;letter-spacing:0.05px;word-spacing:0.08px;}
#t13_169{left:231px;bottom:333px;letter-spacing:0.07px;}
#t14_169{left:249px;bottom:333px;letter-spacing:0.07px;word-spacing:0.07px;}
#t15_169{left:231px;bottom:303px;letter-spacing:0.12px;word-spacing:0.02px;}
#t16_169{left:231px;bottom:285px;letter-spacing:0.13px;word-spacing:0.02px;}
#t17_169{left:231px;bottom:267px;letter-spacing:0.07px;}
#t18_169{left:249px;bottom:267px;letter-spacing:0.11px;word-spacing:0.03px;}
#t19_169{left:231px;bottom:248px;letter-spacing:0.07px;}
#t1a_169{left:249px;bottom:248px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1b_169{left:231px;bottom:230px;letter-spacing:0.07px;}
#t1c_169{left:249px;bottom:230px;letter-spacing:0.06px;word-spacing:0.08px;}

.s0_169{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_169{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_169{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_169{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_169{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_169{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s6_169{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts169" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg169" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="169/169.svg"
          type="image/svg+xml"
          id="pdf169"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_169" class="t s0_169">
          169{" "}
        </span>
        <span id="t2_169" class="t s1_169">
          Сурагч төвтэй сургалтын орчныг хэрхэн төлөвлөх вэ?{" "}
        </span>
        <span id="t3_169" class="t s2_169">
          Шийдлээ тодорхойлсны дараа сургууль төсөв санхүү, цаг хугацаа, хүний{" "}
        </span>
        <span id="t4_169" class="t s2_169">
          нөөцөөс хамааран{" "}
        </span>
        <span id="t5_169" class="t s2_169">
          урт, дунд, богино хугацааны төлөвлөгөөнд тусгаж өгнө.{" "}
        </span>
        <span id="t6_169" class="t s2_169">
          Сургуулиуд сургалтын орчныг сайжруулах төлөвлөлт хийхдээ дараах{" "}
        </span>
        <span id="t7_169" class="t s2_169">
          зүйлсийг харгалзан үзэхийг санал болгож байна. Үүнд:{" "}
        </span>
        <span id="t8_169" class="t s3_169">
          1.{" "}
        </span>
        <span id="t9_169" class="t s2_169">
          Сургалтын арга зүй, багшлахуйн загвар, суралцахуйн арга хэлбэр{" "}
        </span>
        <span id="ta_169" class="t s2_169">
          улам олон төрөл болж байгаатай холбоотой түүнд шаардлагатай{" "}
        </span>
        <span id="tb_169" class="t s2_169">
          орчныг судалж, ялгаатай байдлыг тооцох; (сурагчдыг амьдрах{" "}
        </span>
        <span id="tc_169" class="t s2_169">
          чадварт сургахын тулд өөр өөр судлагдахууныг нэгтгэж, бодит{" "}
        </span>
        <span id="td_169" class="t s2_169">
          амьдралын жишээн дээр тулгуурласан интеграци хэлбэрийн{" "}
        </span>
        <span id="te_169" class="t s2_169">
          сургалтыг зохион байгуулах гэх мэт){" "}
        </span>
        <span id="tf_169" class="t s3_169">
          2.{" "}
        </span>
        <span id="tg_169" class="t s2_169">
          Сурагчдын суралцахуйн арга барил, онцлог, ялгаатай байдлыг таньж{" "}
        </span>
        <span id="th_169" class="t s2_169">
          тогтоон, түүнд тохируулах; (стандарт анги танхимаас гадна зарим{" "}
        </span>
        <span id="ti_169" class="t s2_169">
          орон зайн{" "}
        </span>
        <span id="tj_169" class="t s2_169">
          хэлбэр, дизайн, тавилгыг өөрчлөх гэх мэт){" "}
        </span>
        <span id="tk_169" class="t s3_169">
          3.{" "}
        </span>
        <span id="tl_169" class="t s2_169">
          Хичээл, сургалтын үйл ажиллагааг МХХТ-ийн хэрэгслээр явуулах;{" "}
        </span>
        <span id="tm_169" class="t s2_169">
          (онлайн эх сурвалж, программ хангамжид нэвтрэх, бүртгүүлэх{" "}
        </span>
        <span id="tn_169" class="t s2_169">
          зардлыг төлөвлөх гэх мэт){" "}
        </span>
        <span id="to_169" class="t s4_169">
          Хүснэгт 3.23. Асуудал шийдвэрлэх хувилбар{" "}
        </span>
        <span id="tp_169" class="t s5_169">
          Асуудал шийдвэрлэсэн жишээ.{" "}
        </span>
        <span id="tq_169" class="t s4_169">
          Өгөгдөл цуглуулах{" "}
        </span>
        <span id="tr_169" class="t s5_169">
          Сурагчдад тулгамдсан асуудал: (хувиар){" "}
        </span>
        <span id="ts_169" class="t s6_169">
          1.{" "}
        </span>
        <span id="tt_169" class="t s4_169">
          Асуудал шийдвэрлэх чадвар сул{" "}
        </span>
        <span id="tu_169" class="t s6_169">
          2.{" "}
        </span>
        <span id="tv_169" class="t s4_169">
          Хариуцлагагүй{" "}
        </span>
        <span id="tw_169" class="t s6_169">
          3.{" "}
        </span>
        <span id="tx_169" class="t s4_169">
          Харааны бэрхшээлтэй{" "}
        </span>
        <span id="ty_169" class="t s5_169">
          Асуудал: (Өөрийн үнэлгээгээр хангалгүй үр дүн үзүүлсэн){" "}
        </span>
        <span id="tz_169" class="t s6_169">
          1.{" "}
        </span>
        <span id="t10_169" class="t s4_169">
          Сургалтын тоног төхөөрөмжийн хүртээмж{" "}
        </span>
        <span id="t11_169" class="t s6_169">
          2.{" "}
        </span>
        <span id="t12_169" class="t s4_169">
          Интернет орчин{" "}
        </span>
        <span id="t13_169" class="t s6_169">
          3.{" "}
        </span>
        <span id="t14_169" class="t s4_169">
          Ногоон байгууламж{" "}
        </span>
        <span id="t15_169" class="t s5_169">
          Асуудал (Хөндлөнгийн үнэлгээгээр хангалтгүй{" "}
        </span>
        <span id="t16_169" class="t s5_169">
          үр дүн үзүүлсэн){" "}
        </span>
        <span id="t17_169" class="t s6_169">
          1.{" "}
        </span>
        <span id="t18_169" class="t s4_169">
          Компьютер, тоног төхөөрөмжийн хүртээмж{" "}
        </span>
        <span id="t19_169" class="t s6_169">
          2.{" "}
        </span>
        <span id="t1a_169" class="t s4_169">
          Цэвэр усны хангамж{" "}
        </span>
        <span id="t1b_169" class="t s6_169">
          3.{" "}
        </span>
        <span id="t1c_169" class="t s4_169">
          Туршилт хийх бодис урвалж{" "}
        </span>
      </div>
    </div>
  );
}
