import React from "react";
export default function SixtyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_64{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_64{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_64{left:142px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t4_64{left:87px;bottom:622px;letter-spacing:-0.13px;word-spacing:6.16px;}
#t5_64{left:87px;bottom:602px;letter-spacing:-0.15px;word-spacing:1.36px;}
#t6_64{left:87px;bottom:582px;letter-spacing:-0.12px;word-spacing:1.24px;}
#t7_64{left:87px;bottom:562px;letter-spacing:-0.14px;}
#t8_64{left:87px;bottom:529px;letter-spacing:-0.11px;word-spacing:3.39px;}
#t9_64{left:87px;bottom:509px;letter-spacing:-0.17px;word-spacing:0.08px;}
#ta_64{left:87px;bottom:488px;letter-spacing:-0.05px;}
#tb_64{left:113px;bottom:488px;letter-spacing:-0.14px;word-spacing:3.46px;}
#tc_64{left:113px;bottom:468px;letter-spacing:-0.13px;word-spacing:0.04px;}
#td_64{left:87px;bottom:448px;letter-spacing:-0.05px;}
#te_64{left:113px;bottom:448px;letter-spacing:-0.12px;word-spacing:-1.6px;}
#tf_64{left:113px;bottom:428px;letter-spacing:-0.14px;word-spacing:5.82px;}
#tg_64{left:113px;bottom:408px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_64{left:87px;bottom:388px;letter-spacing:-0.05px;}
#ti_64{left:113px;bottom:388px;letter-spacing:-0.13px;word-spacing:3.13px;}
#tj_64{left:113px;bottom:367px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_64{left:87px;bottom:347px;letter-spacing:-0.05px;}
#tl_64{left:113px;bottom:347px;letter-spacing:-0.15px;word-spacing:9.82px;}
#tm_64{left:113px;bottom:327px;letter-spacing:-0.14px;word-spacing:6.2px;}
#tn_64{left:113px;bottom:307px;letter-spacing:-0.15px;}

.s0_64{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_64{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_64{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s3_64{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_64{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts64" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg64" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="64/64.svg"
          type="image/svg+xml"
          id="pdf64"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_64" class="t s1_64">
          64{" "}
        </span>
        <span id="t3_64" class="t s2_64">
          Зураг 2.14. Хөтөлбөрийн хэрэгжилтийн үр дүн (судлагдахуунаар, багшаар){" "}
        </span>
        <span id="t4_64" class="t s3_64">
          Сургуулийн гүйцэтгэлийн үнэлгээний хөтөлбөрийн хэрэгжилтийн хэсэгт{" "}
        </span>
        <span id="t5_64" class="t s3_64">
          дээрх 3ураг 2.14-д харагдах судлагдахууны гүйцэтгэлийн дунджийг оноор,{" "}
        </span>
        <span id="t6_64" class="t s3_64">
          кластерын болон улсын дундажтай хэрхэн харьцуулж, шинжилгээ хийхийг{" "}
        </span>
        <span id="t7_64" class="t s3_64">
          тусгасан.{" "}
        </span>
        <span id="t8_64" class="t s3_64">
          Зураг 2.14 дээрх шалгалтын үр дүнд хэрхэн шинжилгээ хийхийг дараах{" "}
        </span>
        <span id="t9_64" class="t s3_64">
          байдлаар тодотгов. Үүнд:{" "}
        </span>
        <span id="ta_64" class="t s4_64">
          1.{" "}
        </span>
        <span id="tb_64" class="t s3_64">
          “Судлагдахуун-багш” хэсэг дэх тухайн багшийн хичээл заадаг ангийн{" "}
        </span>
        <span id="tc_64" class="t s3_64">
          сурагчдын гүйцэтгэлийн дундаж үзүүлэлтийг оноор харьцуулж харна.{" "}
        </span>
        <span id="td_64" class="t s4_64">
          2.{" "}
        </span>
        <span id="te_64" class="t s3_64">
          Сүүлийн жилд тухайн багшийн заадаг хичээлийн сурагчдын гүйцэтгэлийн{" "}
        </span>
        <span id="tf_64" class="t s3_64">
          дундаж үзүүлэлтийг кластерын болон улсын дундажтай харьцуулж,{" "}
        </span>
        <span id="tg_64" class="t s3_64">
          дээгүүр эсвэл доогуур болохыг тодорхойлно.{" "}
        </span>
        <span id="th_64" class="t s4_64">
          3.{" "}
        </span>
        <span id="ti_64" class="t s3_64">
          Сургуулийн нийт багш нарын хэдэн хувь нь улсын дунджаас дээгүүр{" "}
        </span>
        <span id="tj_64" class="t s3_64">
          эсвэл доогуур, дунджид ойр байгааг сүүлийн жилээр тооцоолно.{" "}
        </span>
        <span id="tk_64" class="t s4_64">
          4.{" "}
        </span>
        <span id="tl_64" class="t s3_64">
          Шалгалтын гүйцэтгэлийн үр дүнг нэмэгдүүлэх боломжийг тухайн{" "}
        </span>
        <span id="tm_64" class="t s3_64">
          үйл ажиллагаанд хамаарах үүрэг бүхий хүмүүсийн оролцоотойгоор{" "}
        </span>
        <span id="tn_64" class="t s3_64">
          тодорхойлоорой.{" "}
        </span>
      </div>
    </div>
  );
}
