import React from "react";
export default function FourtyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        #
        {`t1_49{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_49{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_49{left:148px;bottom:930px;letter-spacing:0.14px;word-spacing:0.09px;}
#t4_49{left:87px;bottom:886px;letter-spacing:-0.13px;word-spacing:-1.35px;}
#t5_49{left:87px;bottom:866px;letter-spacing:-0.14px;word-spacing:0.17px;}
#t6_49{left:87px;bottom:846px;letter-spacing:-0.14px;word-spacing:5.41px;}
#t7_49{left:87px;bottom:826px;letter-spacing:-0.13px;word-spacing:3.52px;}
#t8_49{left:87px;bottom:805px;letter-spacing:-0.13px;word-spacing:-0.29px;}
#t9_49{left:87px;bottom:785px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ta_49{left:87px;bottom:752px;letter-spacing:-0.15px;word-spacing:5.49px;}
#tb_49{left:87px;bottom:732px;letter-spacing:-0.16px;word-spacing:2.51px;}
#tc_49{left:87px;bottom:712px;letter-spacing:-0.1px;word-spacing:-0.97px;}
#td_49{left:417px;bottom:712px;letter-spacing:-0.16px;word-spacing:-0.92px;}
#te_49{left:87px;bottom:692px;letter-spacing:-0.14px;word-spacing:10.19px;}
#tf_49{left:87px;bottom:671px;letter-spacing:-0.14px;word-spacing:6.59px;}
#tg_49{left:87px;bottom:651px;letter-spacing:-0.16px;word-spacing:0.07px;}
#th_49{left:87px;bottom:618px;letter-spacing:-0.15px;word-spacing:0.2px;}
#ti_49{left:87px;bottom:598px;letter-spacing:-0.14px;word-spacing:1.65px;}
#tj_49{left:87px;bottom:578px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tk_49{left:276px;bottom:538px;letter-spacing:0.1px;word-spacing:0.03px;}
#tl_49{left:89px;bottom:503px;}
#tm_49{left:190px;bottom:503px;letter-spacing:-0.04px;}
#tn_49{left:335px;bottom:511px;letter-spacing:-1.18px;}
#to_49{left:347px;bottom:495px;letter-spacing:-1.18px;}
#tp_49{left:416px;bottom:511px;letter-spacing:-0.06px;word-spacing:0.06px;}
#tq_49{left:502px;bottom:495px;letter-spacing:-0.13px;}
#tr_49{left:87px;bottom:466px;}
#ts_49{left:110px;bottom:466px;letter-spacing:-0.02px;word-spacing:0.02px;}
#tt_49{left:351px;bottom:466px;letter-spacing:-0.01px;}
#tu_49{left:389px;bottom:474px;letter-spacing:-0.03px;word-spacing:1.29px;}
#tv_49{left:402px;bottom:458px;letter-spacing:-0.02px;word-spacing:0.01px;}
#tw_49{left:87px;bottom:428px;}
#tx_49{left:110px;bottom:428px;letter-spacing:-0.03px;word-spacing:0.02px;}
#ty_49{left:351px;bottom:428px;letter-spacing:-0.01px;}
#tz_49{left:389px;bottom:436px;letter-spacing:-0.03px;word-spacing:1.29px;}
#t10_49{left:402px;bottom:421px;letter-spacing:-0.01px;}
#t11_49{left:87px;bottom:391px;}
#t12_49{left:110px;bottom:399px;letter-spacing:-0.01px;word-spacing:0.01px;}
#t13_49{left:110px;bottom:383px;letter-spacing:-0.01px;}
#t14_49{left:351px;bottom:391px;letter-spacing:-0.01px;}
#t15_49{left:389px;bottom:399px;letter-spacing:-0.03px;word-spacing:1.29px;}
#t16_49{left:402px;bottom:383px;letter-spacing:-0.01px;}
#t17_49{left:87px;bottom:311px;}
#t18_49{left:110px;bottom:311px;letter-spacing:-0.06px;word-spacing:0.06px;}
#t19_49{left:351px;bottom:311px;letter-spacing:-0.01px;}
#t1a_49{left:389px;bottom:358px;letter-spacing:-0.12px;word-spacing:1.37px;}
#t1b_49{left:402px;bottom:342px;letter-spacing:-0.04px;word-spacing:0.03px;}
#t1c_49{left:402px;bottom:327px;letter-spacing:-0.03px;word-spacing:0.03px;}
#t1d_49{left:402px;bottom:311px;letter-spacing:-0.05px;word-spacing:0.04px;}
#t1e_49{left:402px;bottom:296px;letter-spacing:-0.05px;word-spacing:0.05px;}
#t1f_49{left:402px;bottom:280px;letter-spacing:-0.05px;word-spacing:0.04px;}
#t1g_49{left:389px;bottom:264px;letter-spacing:-0.04px;word-spacing:1.05px;}
#t1h_49{left:87px;bottom:225px;}
#t1i_49{left:110px;bottom:233px;letter-spacing:-0.08px;word-spacing:0.07px;}
#t1j_49{left:110px;bottom:217px;letter-spacing:-0.03px;}
#t1k_49{left:351px;bottom:225px;letter-spacing:-0.01px;}
#t1l_49{left:389px;bottom:241px;letter-spacing:-0.05px;word-spacing:1.31px;}
#t1m_49{left:389px;bottom:225px;letter-spacing:-0.05px;word-spacing:1.05px;}
#t1n_49{left:402px;bottom:210px;letter-spacing:-0.04px;word-spacing:0.03px;}
#t1o_49{left:87px;bottom:169px;}
#t1p_49{left:110px;bottom:177px;letter-spacing:-0.05px;word-spacing:0.05px;}
#t1q_49{left:110px;bottom:161px;letter-spacing:-0.04px;word-spacing:0.04px;}
#t1r_49{left:355px;bottom:169px;}
#t1s_49{left:389px;bottom:184px;letter-spacing:-0.04px;word-spacing:0.22px;}
#t1t_49{left:389px;bottom:169px;letter-spacing:-0.09px;word-spacing:0.23px;}
#t1u_49{left:398px;bottom:153px;letter-spacing:-0.06px;word-spacing:0.05px;}

.s0_49{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_49{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_49{font-size:21px;font-family:MogulArial-Bold_179;color:#6D3A96;}
.s3_49{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_49{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_49{font-size:13px;font-family:MogulArial-Bold_179;color:#000;}
.s6_49{font-size:13px;font-family:MogulArial_16s;color:#000;}
.t.m0_49{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts49" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg49" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="49/49.svg"
          type="image/svg+xml"
          id="pdf49"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_49" class="t s1_49">
          49{" "}
        </span>
        <span id="t3_49" class="t s2_49">
          2.2. СУРГУУЛИЙН ГҮЙЦЭТГЭЛИЙН ҮНЭЛГЭЭ{" "}
        </span>
        <span id="t4_49" class="t s3_49">
          МУЗГ-ын 2019 оны 5 дугаар тогтоолын 3 дугаар хавсралтын 4.4.2-т
          “Ерөнхий{" "}
        </span>
        <span id="t5_49" class="t s3_49">
          боловсролын сургуулийн багшийн ажлын гүйцэтгэл, үр дүнг үнэлэх” үндсэн{" "}
        </span>
        <span id="t6_49" class="t s3_49">
          шалгуурыг баталсан. Энэхүү шалгуурт үндэслэн БШУ-ны сайдын 2022{" "}
        </span>
        <span id="t7_49" class="t s3_49">
          оны 10 дугаар сарын 26-ны өдрийн А/430 дугаар тушаалын хоёрдугаар{" "}
        </span>
        <span id="t8_49" class="t s3_49">
          хавсралтаар “ЕБС-ийн багшийн ажлыг үнэлэх, дүгнэх журам”-ыг шинэчилж,{" "}
        </span>
        <span id="t9_49" class="t s3_49">
          гүйцэтгэлийн үнэлгээг зохион байгууллаа.{" "}
        </span>
        <span id="ta_49" class="t s3_49">
          Гүйцэтгэлийн үнэлгээг зохион байгуулснаар багш, сургууль үр дүнгийн{" "}
        </span>
        <span id="tb_49" class="t s3_49">
          нэмэлт урамшуулал авах боломж бүрдсэн. БШУ-ны сайдын 2022 оны 11{" "}
        </span>
        <span id="tc_49" class="t s3_49">
          дүгээр сарын 29-ний өдрийн А/500 дугаар{" "}
        </span>
        <span id="td_49" class="t s3_49">
          “Чиглэл батлах тухай” тушаалаар{" "}
        </span>
        <span id="te_49" class="t s3_49">
          төрийн өмчийн ерөнхий боловсролын сургуулийн үйл ажиллагааны{" "}
        </span>
        <span id="tf_49" class="t s3_49">
          гүйцэтгэлийн чанар, үр дүнг үнэлж, үр дүнгийн нэмэлт урамшууллын{" "}
        </span>
        <span id="tg_49" class="t s3_49">
          зардлыг санхүүжүүлэхэд баримтлах чиглэлийг хавсралтаар баталсан.{" "}
        </span>
        <span id="th_49" class="t s3_49">
          Энэхүү чиглэлд сургалтын байгууллагын гүйцэтгэл, үр дүнг үнэлэхдээ
          доор{" "}
        </span>
        <span id="ti_49" class="t s3_49">
          дурдсан үндсэн шалгуур, арга хэрэгслийг ашиглан цуглуулсан үнэлгээний{" "}
        </span>
        <span id="tj_49" class="t s3_49">
          мэдээлэлд дүн шинжилгээ хийж, 100 хүртэлх оноогоор үнэлэхээр заасан.{" "}
        </span>
        <span id="tk_49" class="t s4_49">
          Хүснэгт 2.3. ЕБС-ийн гүйцэтгэл, үр дүнг үнэлэх шалгуур{" "}
        </span>
        <span id="tl_49" class="t s5_49">
          №{" "}
        </span>
        <span id="tm_49" class="t s5_49">
          Шалгуур{" "}
        </span>
        <span id="tn_49" class="t s5_49">
          Онооны{" "}
        </span>
        <span id="to_49" class="t s5_49">
          жин{" "}
        </span>
        <span id="tp_49" class="t s5_49">
          Үнэлгээнд ашиглах мэдээлэл, арга,{" "}
        </span>
        <span id="tq_49" class="t s5_49">
          хэрэгсэл{" "}
        </span>
        <span id="tr_49" class="t s6_49">
          1{" "}
        </span>
        <span id="ts_49" class="t s6_49">
          Сургалтын хөтөлбөрийн хэрэгжилт{" "}
        </span>
        <span id="tt_49" class="t s6_49">
          25{" "}
        </span>
        <span id="tu_49" class="t s6_49">
          - Багшийн гүйцэтгэлийн үнэлгээний шалгуур{" "}
        </span>
        <span id="tv_49" class="t s6_49">
          1-ийн 2 дахь үзүүлэлтийн үр дүн{" "}
        </span>
        <span id="tw_49" class="t s6_49">
          2{" "}
        </span>
        <span id="tx_49" class="t s6_49">
          Суралцагчийн хөгжил, хамгаалал{" "}
        </span>
        <span id="ty_49" class="t s6_49">
          20{" "}
        </span>
        <span id="tz_49" class="t s6_49">
          - Багшийн гүйцэтгэлийн үнэлгээний шалгуур{" "}
        </span>
        <span id="t10_49" class="t s6_49">
          2-ын үр дүн{" "}
        </span>
        <span id="t11_49" class="t s6_49">
          3{" "}
        </span>
        <span id="t12_49" class="t s6_49">
          Багш, ажилтны тасралтгүй{" "}
        </span>
        <span id="t13_49" class="t s6_49">
          хөгжлийг дэмжсэн үйл ажиллагаа{" "}
        </span>
        <span id="t14_49" class="t s6_49">
          20{" "}
        </span>
        <span id="t15_49" class="t s6_49">
          - Багшийн гүйцэтгэлийн үнэлгээний шалгуур{" "}
        </span>
        <span id="t16_49" class="t s6_49">
          3-ын үр дүн{" "}
        </span>
        <span id="t17_49" class="t s6_49">
          4{" "}
        </span>
        <span id="t18_49" class="t s6_49">
          Сургалтын орчин бүрдүүлэлт{" "}
        </span>
        <span id="t19_49" class="t s6_49">
          12{" "}
        </span>
        <span id="t1a_49" class="t s6_49">
          - Сургалтын орчин бүрдүүлэлт, хоол{" "}
        </span>
        <span id="t1b_49" class="t s6_49">
          үйлдвэрлэл үйлчилгээ, эрүүл ахуйн{" "}
        </span>
        <span id="t1c_49" class="t s6_49">
          болон дотуур байр, ариун цэврийн{" "}
        </span>
        <span id="t1d_49" class="t s6_49">
          байгууламжийн хүртээмж, аюулгүй байдлыг{" "}
        </span>
        <span id="t1e_49" class="t s6_49">
          хангахад чиглэсэн шаардлага, стандартын{" "}
        </span>
        <span id="t1f_49" class="t s6_49">
          хэрэгжилтийн байдлыг үнэлэх хуудас{" "}
        </span>
        <span id="t1g_49" class="t s6_49">
          - Цахим сургуульд хамрагдалтын эзлэх хувь{" "}
        </span>
        <span id="t1h_49" class="t s6_49">
          5{" "}
        </span>
        <span id="t1i_49" class="t s6_49">
          Удирдлагын манлайлал,{" "}
        </span>
        <span id="t1j_49" class="t s6_49">
          менежмент{" "}
        </span>
        <span id="t1k_49" class="t s6_49">
          15{" "}
        </span>
        <span id="t1l_49" class="t s6_49">
          - Хамт олноос авах асуулга{" "}
        </span>
        <span id="t1m_49" class="t s6_49">
          - Боловсрол, шинжлэх ухааны газрын дарга,{" "}
        </span>
        <span id="t1n_49" class="t s6_49">
          мэргэжилтнээс авах асуулга{" "}
        </span>
        <span id="t1o_49" class="t s6_49">
          6{" "}
        </span>
        <span id="t1p_49" class="t s6_49">
          Сургалтын байгууллагын талаарх{" "}
        </span>
        <span id="t1q_49" class="t s6_49">
          хэрэглэгчийн сэтгэл ханамж{" "}
        </span>
        <span id="t1r_49" class="t s6_49">
          8{" "}
        </span>
        <span id="t1s_49" class="t s6_49">
          - Суралцагчдын сэтгэл ханамжийн асуулга{" "}
        </span>
        <span id="t1t_49" class="t s6_49">
          - Эцэг, эх, асран хамгаалагчдын сэтгэл{" "}
        </span>
        <span id="t1u_49" class="t s6_49">
          ханамжийн асуулга{" "}
        </span>
      </div>
    </div>
  );
}
