import React from "react";
export default function Thirty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_30{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_30{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_30{left:86px;bottom:941px;letter-spacing:-0.15px;word-spacing:2.74px;}
#t4_30{left:86px;bottom:921px;letter-spacing:-0.13px;word-spacing:0.69px;}
#t5_30{left:86px;bottom:901px;letter-spacing:-0.12px;word-spacing:3.66px;}
#t6_30{left:86px;bottom:880px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_30{left:86px;bottom:847px;letter-spacing:-0.14px;word-spacing:2.69px;}
#t8_30{left:86px;bottom:827px;letter-spacing:-0.09px;}
#t9_30{left:155px;bottom:827px;letter-spacing:-0.26px;}
#ta_30{left:222px;bottom:827px;letter-spacing:-0.19px;}
#tb_30{left:316px;bottom:827px;letter-spacing:-0.17px;}
#tc_30{left:438px;bottom:827px;letter-spacing:-0.11px;}
#td_30{left:528px;bottom:827px;letter-spacing:-0.09px;}
#te_30{left:614px;bottom:827px;letter-spacing:-0.18px;}
#tf_30{left:86px;bottom:807px;letter-spacing:-0.15px;word-spacing:1.37px;}
#tg_30{left:86px;bottom:787px;letter-spacing:-0.16px;word-spacing:-1.89px;}
#th_30{left:86px;bottom:767px;letter-spacing:-0.15px;word-spacing:1.84px;}
#ti_30{left:86px;bottom:746px;letter-spacing:-0.13px;word-spacing:-0.67px;}
#tj_30{left:86px;bottom:726px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tk_30{left:86px;bottom:677px;letter-spacing:-0.15px;word-spacing:2.3px;}
#tl_30{left:86px;bottom:658px;letter-spacing:-0.12px;word-spacing:7.68px;}
#tm_30{left:86px;bottom:640px;letter-spacing:-0.14px;word-spacing:5.21px;}
#tn_30{left:86px;bottom:622px;letter-spacing:-0.15px;word-spacing:1.88px;}
#to_30{left:86px;bottom:603px;letter-spacing:-0.11px;word-spacing:2.21px;}
#tp_30{left:86px;bottom:585px;letter-spacing:-0.13px;word-spacing:2.63px;}
#tq_30{left:86px;bottom:567px;letter-spacing:-0.14px;word-spacing:0.33px;}
#tr_30{left:86px;bottom:548px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ts_30{left:229px;bottom:515px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tt_30{left:475px;bottom:464px;}
#tu_30{left:486px;bottom:464px;letter-spacing:0.08px;word-spacing:0.03px;}
#tv_30{left:486px;bottom:450px;letter-spacing:0.09px;word-spacing:0.02px;}
#tw_30{left:486px;bottom:435px;letter-spacing:0.11px;}
#tx_30{left:486px;bottom:420px;letter-spacing:0.05px;}
#ty_30{left:475px;bottom:406px;}
#tz_30{left:486px;bottom:406px;letter-spacing:0.1px;word-spacing:0.02px;}
#t10_30{left:486px;bottom:391px;letter-spacing:0.07px;word-spacing:0.04px;}
#t11_30{left:486px;bottom:376px;letter-spacing:0.11px;}
#t12_30{left:486px;bottom:362px;letter-spacing:0.09px;word-spacing:0.02px;}
#t13_30{left:486px;bottom:347px;letter-spacing:0.07px;word-spacing:0.04px;}
#t14_30{left:475px;bottom:332px;}
#t15_30{left:486px;bottom:332px;letter-spacing:0.07px;word-spacing:0.03px;}
#t16_30{left:486px;bottom:318px;letter-spacing:0.07px;word-spacing:0.04px;}
#t17_30{left:486px;bottom:303px;letter-spacing:0.08px;word-spacing:0.03px;}
#t18_30{left:475px;bottom:288px;}
#t19_30{left:486px;bottom:288px;letter-spacing:0.09px;word-spacing:0.03px;}
#t1a_30{left:486px;bottom:274px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1b_30{left:486px;bottom:259px;letter-spacing:0.1px;word-spacing:0.01px;}
#t1c_30{left:486px;bottom:244px;letter-spacing:0.02px;}
#t1d_30{left:475px;bottom:230px;}
#t1e_30{left:486px;bottom:230px;letter-spacing:0.06px;word-spacing:0.04px;}
#t1f_30{left:486px;bottom:215px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1g_30{left:486px;bottom:200px;letter-spacing:0.07px;word-spacing:0.04px;}

.s0_30{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_30{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_30{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_30{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_30{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s5_30{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s6_30{font-size:12px;font-family:ArialMT_1lq;color:#000;}
.s7_30{font-size:12px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts30" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg30" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="30/30.svg"
          type="image/svg+xml"
          id="pdf30"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_30" class="t s1_30">
          30{" "}
        </span>
        <span id="t3_30" class="t s2_30">
          бэхжих гэх мэт илүү чанарын түвшний өөрчлөлт гарах учиртай. Тиймээс{" "}
        </span>
        <span id="t4_30" class="t s2_30">
          дунд хугацааны зорилго зорилтод хүрсэн эсэхийг хэмжих шалгуур нь багш{" "}
        </span>
        <span id="t5_30" class="t s2_30">
          нарын хамтын ажиллагаа, сургуулийн соёл, хөтөлбөрийн баяжилт зэрэг{" "}
        </span>
        <span id="t6_30" class="t s2_30">
          дунд хугацааны үр дүнг хэмжихүйц шалгуур байх нь зохистой.{" "}
        </span>
        <span id="t7_30" class="t s3_30">
          Пирамидын дээд давхарга нь та бидний хүрэхийг хүсэж буй алсын{" "}
        </span>
        <span id="t8_30" class="t s3_30">
          хараа,{" "}
        </span>
        <span id="t9_30" class="t s3_30">
          эрхэм{" "}
        </span>
        <span id="ta_30" class="t s3_30">
          зорилгоо{" "}
        </span>
        <span id="tb_30" class="t s3_30">
          хэрэгжүүлэх{" "}
        </span>
        <span id="tc_30" class="t s3_30">
          байдлыг{" "}
        </span>
        <span id="td_30" class="t s3_30">
          илтгэнэ.{" "}
        </span>
        <span id="te_30" class="t s2_30">
          Зургаас{" "}
        </span>
        <span id="tf_30" class="t s2_30">
          пирамидын суурийн болон дунд давхаргаас хэд хэдэн ялгаа байгааг харж{" "}
        </span>
        <span id="tg_30" class="t s2_30">
          болно. Тухайлбал, сургуулийн соёл, удирдлагын манлайлал, багш хамт
          олны{" "}
        </span>
        <span id="th_30" class="t s2_30">
          үйл ажиллагаа улам бүр нэгдмэл болсныг салангид зураасууд арилснаар{" "}
        </span>
        <span id="ti_30" class="t s2_30">
          илэрхийлсэн байна. Энэ нь салангид байсан зүйлс интеграцчилагдаж бүхэл{" "}
        </span>
        <span id="tj_30" class="t s2_30">
          нэгдмэл болсныг илэрхийлнэ.{" "}
        </span>
        <span id="tk_30" class="t s4_30">
          Энэ түвшинд сургуулийн стратеги төлөвлөлтийн биелэлтийг буюу алсын{" "}
        </span>
        <span id="tl_30" class="t s4_30">
          хараанд хүрсэн эсэхийг сурагчдад илрэх үр нөлөөгөөр, тэдний XXI{" "}
        </span>
        <span id="tm_30" class="t s4_30">
          зууны иргэний чадамжийг эзэмшсэн, сурагчийнхаа сонирхол, ялгаатай{" "}
        </span>
        <span id="tn_30" class="t s4_30">
          байдал, сургуулийн онцлог, орон нутгийн хэрэгцээнд нийцсэн сургуулийн{" "}
        </span>
        <span id="to_30" class="t s4_30">
          хөтөлбөрийг боловсруулж, хэрэгжүүлсэн эсэхийг нягтална. Үүний үндсэн{" "}
        </span>
        <span id="tp_30" class="t s4_30">
          дээр дараагийн урт хугацааны алсын харааг шинээр томьёолохдоо цөм{" "}
        </span>
        <span id="tq_30" class="t s4_30">
          үнэт зүйлээ хадгалж, дараагийн 5-10 жилийн алсын хараагаа тодорхойлж,{" "}
        </span>
        <span id="tr_30" class="t s4_30">
          менежментээ боловсронгуй болох юм.{" "}
        </span>
        <span id="ts_30" class="t s5_30">
          Зураг 1.8. Пирамидын дээд давхаргын тайлбар{" "}
        </span>
        <span id="tt_30" class="t s6_30">
          •{" "}
        </span>
        <span id="tu_30" class="t s7_30">
          Сурагчдад тулгамдаж байсан{" "}
        </span>
        <span id="tv_30" class="t s7_30">
          суурь асуудлыг шийдэж, алсын{" "}
        </span>
        <span id="tw_30" class="t s7_30">
          хараа хэрэгжсэн эсэхийг{" "}
        </span>
        <span id="tx_30" class="t s7_30">
          нягтлах;{" "}
        </span>
        <span id="ty_30" class="t s6_30">
          •{" "}
        </span>
        <span id="tz_30" class="t s7_30">
          Багш нарын хамтын ажиллагаа,{" "}
        </span>
        <span id="t10_30" class="t s7_30">
          сургуулийн соёл, удирдлагын{" "}
        </span>
        <span id="t11_30" class="t s7_30">
          манлайлал төлөвшсөн,{" "}
        </span>
        <span id="t12_30" class="t s7_30">
          сургууль бүхлээрээ ажилладаг{" "}
        </span>
        <span id="t13_30" class="t s7_30">
          болсон байх;{" "}
        </span>
        <span id="t14_30" class="t s6_30">
          •{" "}
        </span>
        <span id="t15_30" class="t s7_30">
          Сургуулийн цөм үнэт зүйл{" "}
        </span>
        <span id="t16_30" class="t s7_30">
          хадгалагдаж үлдэх, дараагийн{" "}
        </span>
        <span id="t17_30" class="t s7_30">
          ирээдүйг төсөөлж тусгах{" "}
        </span>
        <span id="t18_30" class="t s6_30">
          •{" "}
        </span>
        <span id="t19_30" class="t s7_30">
          Сургалтын хөтөлбөр{" "}
        </span>
        <span id="t1a_30" class="t s7_30">
          төгөлдөржиж сургуульд{" "}
        </span>
        <span id="t1b_30" class="t s7_30">
          суурилсан хөтөлбөр болсон{" "}
        </span>
        <span id="t1c_30" class="t s7_30">
          байх;{" "}
        </span>
        <span id="t1d_30" class="t s6_30">
          •{" "}
        </span>
        <span id="t1e_30" class="t s7_30">
          Сургууль нээлттэй орчин,{" "}
        </span>
        <span id="t1f_30" class="t s7_30">
          сургалтын зангилаа талбар{" "}
        </span>
        <span id="t1g_30" class="t s7_30">
          болсон байх;{" "}
        </span>
      </div>
    </div>
  );
}
