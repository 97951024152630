import React from "react";
export default function OneHundredSixtySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_167{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_167{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:2.78px;}
#t3_167{left:365px;bottom:941px;letter-spacing:-0.15px;word-spacing:2.81px;}
#t4_167{left:87px;bottom:921px;letter-spacing:-0.17px;word-spacing:2.54px;}
#t5_167{left:356px;bottom:921px;letter-spacing:-0.12px;word-spacing:2.47px;}
#t6_167{left:87px;bottom:901px;letter-spacing:-0.11px;word-spacing:1.22px;}
#t7_167{left:87px;bottom:880px;letter-spacing:-0.16px;word-spacing:8.28px;}
#t8_167{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:3.53px;}
#t9_167{left:87px;bottom:840px;letter-spacing:-0.1px;word-spacing:0.01px;}
#ta_167{left:87px;bottom:807px;letter-spacing:-0.16px;word-spacing:2.09px;}
#tb_167{left:435px;bottom:807px;letter-spacing:-0.17px;word-spacing:2.12px;}
#tc_167{left:87px;bottom:787px;letter-spacing:-0.12px;word-spacing:3.68px;}
#td_167{left:87px;bottom:767px;letter-spacing:-0.14px;word-spacing:2.57px;}
#te_167{left:87px;bottom:746px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tf_167{left:87px;bottom:713px;letter-spacing:-0.11px;word-spacing:5.56px;}
#tg_167{left:320px;bottom:713px;letter-spacing:-0.12px;word-spacing:5.58px;}
#th_167{left:87px;bottom:693px;letter-spacing:-0.14px;word-spacing:5.18px;}
#ti_167{left:87px;bottom:673px;letter-spacing:-0.06px;}
#tj_167{left:171px;bottom:673px;letter-spacing:-0.11px;}
#tk_167{left:304px;bottom:673px;letter-spacing:-0.22px;}
#tl_167{left:361px;bottom:673px;letter-spacing:-0.09px;}
#tm_167{left:453px;bottom:673px;letter-spacing:-0.09px;}
#tn_167{left:526px;bottom:673px;letter-spacing:-0.37px;}
#to_167{left:623px;bottom:673px;letter-spacing:-0.16px;}
#tp_167{left:87px;bottom:653px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tq_167{left:87px;bottom:612px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tr_167{left:87px;bottom:592px;letter-spacing:-0.24px;word-spacing:0.13px;}
#ts_167{left:87px;bottom:559px;letter-spacing:-0.18px;word-spacing:7.97px;}
#tt_167{left:87px;bottom:539px;letter-spacing:-0.14px;word-spacing:2.93px;}
#tu_167{left:260px;bottom:539px;letter-spacing:-0.18px;word-spacing:2.94px;}
#tv_167{left:87px;bottom:519px;letter-spacing:-0.19px;word-spacing:1.77px;}
#tw_167{left:87px;bottom:499px;letter-spacing:-0.18px;word-spacing:2.81px;}
#tx_167{left:87px;bottom:478px;letter-spacing:-0.14px;word-spacing:1.8px;}
#ty_167{left:87px;bottom:458px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tz_167{left:113px;bottom:420px;letter-spacing:0.09px;word-spacing:0.05px;}
#t10_167{left:95px;bottom:387px;letter-spacing:-0.26px;}
#t11_167{left:250px;bottom:387px;letter-spacing:-0.23px;}
#t12_167{left:91px;bottom:360px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t13_167{left:250px;bottom:360px;letter-spacing:-0.15px;word-spacing:0.89px;}
#t14_167{left:250px;bottom:341px;letter-spacing:-0.2px;word-spacing:2.91px;}
#t15_167{left:250px;bottom:323px;letter-spacing:-0.14px;word-spacing:-0.59px;}
#t16_167{left:250px;bottom:305px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t17_167{left:91px;bottom:278px;letter-spacing:-0.12px;}
#t18_167{left:91px;bottom:259px;letter-spacing:-0.2px;}
#t19_167{left:250px;bottom:278px;letter-spacing:-0.17px;word-spacing:5.51px;}
#t1a_167{left:250px;bottom:259px;letter-spacing:-0.15px;word-spacing:3.34px;}
#t1b_167{left:250px;bottom:241px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1c_167{left:91px;bottom:214px;letter-spacing:-0.18px;}
#t1d_167{left:91px;bottom:195px;letter-spacing:-0.13px;}
#t1e_167{left:91px;bottom:177px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1f_167{left:250px;bottom:214px;letter-spacing:-0.16px;word-spacing:-0.51px;}
#t1g_167{left:250px;bottom:195px;letter-spacing:-0.17px;word-spacing:-1.63px;}
#t1h_167{left:250px;bottom:177px;letter-spacing:-0.19px;word-spacing:0.06px;}

.s0_167{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_167{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s2_167{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_167{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s4_167{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_167{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts167" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg167" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="167/167.svg"
          type="image/svg+xml"
          id="pdf167"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_167" class="t s0_167">
          167{" "}
        </span>
        <span id="t2_167" class="t s1_167">
          Сургалтын хөтөлбөрийг дэмжих{" "}
        </span>
        <span id="t3_167" class="t s2_167">
          Сургуулийн зорилго, зорилт сургалтын{" "}
        </span>
        <span id="t4_167" class="t s2_167">
          хөтөлбөрөөр дамжин хэрэгждэг.{" "}
        </span>
        <span id="t5_167" class="t s2_167">
          Иймээс сургуулийн анги танхим, номын{" "}
        </span>
        <span id="t6_167" class="t s2_167">
          сан болон бусад орон зайг сургалтын хөтөлбөрийн хэрэгжилтийг дэмжсэн{" "}
        </span>
        <span id="t7_167" class="t s2_167">
          байхаар зохион байгуулж, технологийн хэрэгсэл, интерактив самбар,{" "}
        </span>
        <span id="t8_167" class="t s2_167">
          боловсролын програмууд, онлайн платформууд, дижитал нөөцүүдийг үр{" "}
        </span>
        <span id="t9_167" class="t s2_167">
          дүнтэй хуваарилах нь зүйтэй.{" "}
        </span>
        <span id="ta_167" class="t s1_167">
          Сонирхол татахуйц хөтөлбөрийг дэмжих{" "}
        </span>
        <span id="tb_167" class="t s2_167">
          Сургуулийн зорилго, зорилтод{" "}
        </span>
        <span id="tc_167" class="t s2_167">
          нийцсэн, сурагчдыг идэвхтэй, урам зоригтой суралцах, төсөл хөтөлбөрт{" "}
        </span>
        <span id="td_167" class="t s2_167">
          хамрагдах, хэрэгжүүлэх, багаар хамтран ажиллах боломжийг бүрдүүлсэн{" "}
        </span>
        <span id="te_167" class="t s2_167">
          сургалтын орчин бий болгох хэрэгцээ үүссэн байна.{" "}
        </span>
        <span id="tf_167" class="t s1_167">
          Багшийн хөгжлийг дэмжих{" "}
        </span>
        <span id="tg_167" class="t s2_167">
          Багш ажлын байрандаа тасралтгүй хөгжих{" "}
        </span>
        <span id="th_167" class="t s2_167">
          боломжийг бүрдүүлэх, хамтдаа хөгжих соёл, хандлагыг төлөвшүүлэхэд{" "}
        </span>
        <span id="ti_167" class="t s2_167">
          дэмжлэг{" "}
        </span>
        <span id="tj_167" class="t s2_167">
          үзүүлэхүйцээр{" "}
        </span>
        <span id="tk_167" class="t s2_167">
          багш{" "}
        </span>
        <span id="tl_167" class="t s2_167">
          хөгжлийн{" "}
        </span>
        <span id="tm_167" class="t s2_167">
          төвийн{" "}
        </span>
        <span id="tn_167" class="t s2_167">
          ашиглалт,{" "}
        </span>
        <span id="to_167" class="t s2_167">
          зохион{" "}
        </span>
        <span id="tp_167" class="t s2_167">
          байгуулалтыг төлөвлөх нь зүйтэй.{" "}
        </span>
        <span id="tq_167" class="t s3_167">
          Сургалтын орчинд тулгамдаж буй асуудлыг хэрхэн оновчтой{" "}
        </span>
        <span id="tr_167" class="t s3_167">
          тодорхойлох вэ?{" "}
        </span>
        <span id="ts_167" class="t s2_167">
          Сургалтын орчинд тулгамдаж буй асуудлыг оновтой тодорхойлох нь{" "}
        </span>
        <span id="tt_167" class="t s2_167">
          сурагчдад чанартай{" "}
        </span>
        <span id="tu_167" class="t s2_167">
          боловсрол олгох, сургуулийн зорилгыг биелүүлэхэд{" "}
        </span>
        <span id="tv_167" class="t s2_167">
          чухал нөлөөтэй байдаг. Сургалтын орчны асуудал, бэрхшээлийг оновчтой{" "}
        </span>
        <span id="tw_167" class="t s2_167">
          тодорхойлохын тулд үнэлгээний үр дүн, баримт, нотолгоонд үндэслэх нь{" "}
        </span>
        <span id="tx_167" class="t s2_167">
          зүйтэй. Ингэхийн тулд ямар мэдээллийг хэрхэн цуглуулж болохыг дараах{" "}
        </span>
        <span id="ty_167" class="t s2_167">
          хүснэгтээр харуулья.{" "}
        </span>
        <span id="tz_167" class="t s4_167">
          Хүснэгт 3.22. Сургалтын орчны талаар мэдээлэл цуглуулах арга зүйн
          хувилбар{" "}
        </span>
        <span id="t10_167" class="t s5_167">
          Чиглэл{" "}
        </span>
        <span id="t11_167" class="t s5_167">
          Агуулга{" "}
        </span>
        <span id="t12_167" class="t s5_167">
          Ажиглах, сонсох{" "}
        </span>
        <span id="t13_167" class="t s5_167">
          Хичээл, сургалтын үйл ажиллагаа, явц, хамаарал, сурагчийн зан{" "}
        </span>
        <span id="t14_167" class="t s5_167">
          байдал, ирц, идэвх оролцоог тогтмол ажигла. Оролцоо дутмаг,{" "}
        </span>
        <span id="t15_167" class="t s5_167">
          идэвхи санаачлагагүй, хичээлдээ дургүй, эсвэл дэг журамгүй байх{" "}
        </span>
        <span id="t16_167" class="t s5_167">
          нь үндсэн асуудлуудыг илтгэж болно{" "}
        </span>
        <span id="t17_167" class="t s5_167">
          Санал хүсэлт{" "}
        </span>
        <span id="t18_167" class="t s5_167">
          цуглуулах{" "}
        </span>
        <span id="t19_167" class="t s5_167">
          Судалгаа, фокус группын ярилцлага, нээлттэй хэлэлцүүлгээр{" "}
        </span>
        <span id="t1a_167" class="t s5_167">
          сурагч, багш, эцэг эх, асран хамгаалагч, харгалзан дэмжигчид{" "}
        </span>
        <span id="t1b_167" class="t s5_167">
          болон бусад оролцогч талуудын санал хүсэлтийг идэвхтэй авах{" "}
        </span>
        <span id="t1c_167" class="t s5_167">
          Сургалтын{" "}
        </span>
        <span id="t1d_167" class="t s5_167">
          хөтөлбөрийн хэрэгжил{" "}
        </span>
        <span id="t1e_167" class="t s5_167">
          тийг нягтлах{" "}
        </span>
        <span id="t1f_167" class="t s5_167">
          Сургалтын хөтөлбөрийн агуулга, арга зүй, үнэлгээ нь сонирхолтой{" "}
        </span>
        <span id="t1g_167" class="t s5_167">
          бөгөөд бодитой, сургалтад ашиглаж буй хичээлийн хэрэглэгдэхүүн,{" "}
        </span>
        <span id="t1h_167" class="t s5_167">
          материалууд нь шаардлага хангахуйц байна уу?{" "}
        </span>
      </div>
    </div>
  );
}
