import React from "react";
export default function F153() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_153{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_153{left:106px;bottom:923px;letter-spacing:0.12px;word-spacing:1.54px;}
#t3_153{left:106px;bottom:905px;letter-spacing:0.1px;word-spacing:0.05px;}
#t4_153{left:106px;bottom:882px;}
#t5_153{left:124px;bottom:882px;letter-spacing:0.11px;word-spacing:4.32px;}
#t6_153{left:124px;bottom:864px;letter-spacing:0.07px;word-spacing:0.06px;}
#t7_153{left:106px;bottom:846px;}
#t8_153{left:124px;bottom:846px;letter-spacing:0.09px;word-spacing:0.05px;}
#t9_153{left:106px;bottom:827px;}
#ta_153{left:124px;bottom:827px;letter-spacing:0.12px;word-spacing:6.11px;}
#tb_153{left:124px;bottom:809px;letter-spacing:0.09px;word-spacing:0.05px;}
#tc_153{left:106px;bottom:791px;}
#td_153{left:124px;bottom:791px;letter-spacing:0.12px;word-spacing:0.02px;}
#te_153{left:106px;bottom:772px;}
#tf_153{left:124px;bottom:772px;letter-spacing:0.11px;word-spacing:3.88px;}
#tg_153{left:124px;bottom:754px;letter-spacing:0.08px;word-spacing:0.05px;}
#th_153{left:106px;bottom:736px;}
#ti_153{left:124px;bottom:736px;letter-spacing:0.1px;word-spacing:0.03px;}
#tj_153{left:106px;bottom:717px;}
#tk_153{left:124px;bottom:717px;letter-spacing:0.1px;word-spacing:1.32px;}
#tl_153{left:124px;bottom:699px;letter-spacing:0.09px;}
#tm_153{left:290px;bottom:674px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tn_153{left:326px;bottom:657px;letter-spacing:-0.16px;word-spacing:0.02px;}
#to_153{left:142px;bottom:572px;letter-spacing:0.18px;}
#tp_153{left:87px;bottom:535px;letter-spacing:-0.13px;word-spacing:4.39px;}
#tq_153{left:87px;bottom:515px;letter-spacing:-0.13px;}
#tr_153{left:201px;bottom:515px;letter-spacing:-0.1px;}
#ts_153{left:290px;bottom:515px;letter-spacing:-0.13px;}
#tt_153{left:405px;bottom:515px;letter-spacing:-0.1px;}
#tu_153{left:519px;bottom:515px;letter-spacing:-0.09px;}
#tv_153{left:614px;bottom:515px;letter-spacing:-0.09px;}
#tw_153{left:87px;bottom:495px;letter-spacing:-0.15px;word-spacing:2.82px;}
#tx_153{left:87px;bottom:475px;letter-spacing:-0.16px;word-spacing:-0.29px;}
#ty_153{left:87px;bottom:455px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tz_153{left:113px;bottom:422px;}
#t10_153{left:139px;bottom:422px;letter-spacing:-0.16px;word-spacing:10.04px;}
#t11_153{left:139px;bottom:401px;letter-spacing:-0.17px;word-spacing:-0.12px;}
#t12_153{left:113px;bottom:377px;}
#t13_153{left:139px;bottom:377px;letter-spacing:-0.16px;word-spacing:12.07px;}
#t14_153{left:139px;bottom:357px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t15_153{left:113px;bottom:332px;}
#t16_153{left:139px;bottom:332px;letter-spacing:-0.11px;word-spacing:-0.28px;}
#t17_153{left:139px;bottom:312px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t18_153{left:113px;bottom:288px;}
#t19_153{left:139px;bottom:288px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1a_153{left:113px;bottom:263px;}
#t1b_153{left:139px;bottom:263px;letter-spacing:-0.08px;}
#t1c_153{left:252px;bottom:263px;letter-spacing:-0.09px;}
#t1d_153{left:343px;bottom:263px;letter-spacing:-0.13px;}
#t1e_153{left:477px;bottom:263px;letter-spacing:-0.08px;}
#t1f_153{left:537px;bottom:263px;letter-spacing:-0.22px;}
#t1g_153{left:593px;bottom:263px;letter-spacing:-0.1px;}
#t1h_153{left:139px;bottom:243px;letter-spacing:-0.11px;}
#t1i_153{left:245px;bottom:243px;letter-spacing:-0.09px;}
#t1j_153{left:361px;bottom:243px;letter-spacing:-0.07px;}
#t1k_153{left:407px;bottom:243px;letter-spacing:-0.14px;}
#t1l_153{left:510px;bottom:243px;letter-spacing:-0.12px;}
#t1m_153{left:621px;bottom:243px;letter-spacing:-0.17px;}
#t1n_153{left:139px;bottom:223px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1o_153{left:87px;bottom:190px;letter-spacing:-0.14px;word-spacing:6.21px;}
#t1p_153{left:87px;bottom:169px;letter-spacing:-0.13px;word-spacing:7.75px;}
#t1q_153{left:87px;bottom:149px;letter-spacing:-0.13px;word-spacing:8.4px;}
#t1r_153{left:87px;bottom:129px;letter-spacing:-0.2px;}

.s0_153{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_153{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s2_153{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s3_153{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_153{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_153{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_153{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s7_153{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts153" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg153" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="153/153.svg"
          type="image/svg+xml"
          id="pdf153"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_153" class="t s0_153">
          153{" "}
        </span>
        <span id="t2_153" class="t s1_153">
          Багшийн ёс зүй, харилцаа, хандлагатай холбоотой асуудлыг шийдвэрлэж{" "}
        </span>
        <span id="t3_153" class="t s1_153">
          буй сургуулиудын зарим туршлагаас{" "}
        </span>
        <span id="t4_153" class="t s2_153">
          •{" "}
        </span>
        <span id="t5_153" class="t s3_153">
          Сургуулийн түвшинд багшийн ёс зүйн дүрмийг багш нарт танилцуулж,{" "}
        </span>
        <span id="t6_153" class="t s3_153">
          гарын үсэг зуруулах{" "}
        </span>
        <span id="t7_153" class="t s2_153">
          •{" "}
        </span>
        <span id="t8_153" class="t s3_153">
          Багшийн ёс зүйн талаар сургалтууд зохион байгуулах{" "}
        </span>
        <span id="t9_153" class="t s2_153">
          •{" "}
        </span>
        <span id="ta_153" class="t s3_153">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчдэд ёс зүй, харилцаа,{" "}
        </span>
        <span id="tb_153" class="t s3_153">
          хандлагын талаар сургалт зохион байгуулах{" "}
        </span>
        <span id="tc_153" class="t s2_153">
          •{" "}
        </span>
        <span id="td_153" class="t s3_153">
          Багш өөрөө цаг барих, мэндлэх зэргээр сурагчдад үлгэр дуурайл болох{" "}
        </span>
        <span id="te_153" class="t s2_153">
          •{" "}
        </span>
        <span id="tf_153" class="t s3_153">
          Багш, сурагч, эцэг эх, асран хамгаалагч, харгалзан дэмжигч гурвалсан{" "}
        </span>
        <span id="tg_153" class="t s3_153">
          гэрээ байгуулах{" "}
        </span>
        <span id="th_153" class="t s2_153">
          •{" "}
        </span>
        <span id="ti_153" class="t s3_153">
          Сургуулийн сэтгэл зүйчидтэй уулзаж зөвлөгөө авах, хамтран ажиллах{" "}
        </span>
        <span id="tj_153" class="t s2_153">
          •{" "}
        </span>
        <span id="tk_153" class="t s3_153">
          Сургуулийн ёс зүйн зөвлөлийн гишүүдийг сургалтад хамруулах, чадавхыг{" "}
        </span>
        <span id="tl_153" class="t s3_153">
          сайжруулах{" "}
        </span>
        <span id="tm_153" class="t s4_153">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="tn_153" class="t s4_153">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="to_153" class="t s5_153">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tp_153" class="t s6_153">
          Сургуулийн түвшинд багшийн ажлын төлөвлөгөө, мэргэжлийн хөгжлийн{" "}
        </span>
        <span id="tq_153" class="t s6_153">
          төлөвлөгөө,{" "}
        </span>
        <span id="tr_153" class="t s6_153">
          ЗАН-ийн{" "}
        </span>
        <span id="ts_153" class="t s6_153">
          төлөвлөгөө,{" "}
        </span>
        <span id="tt_153" class="t s6_153">
          мэргэжлийн{" "}
        </span>
        <span id="tu_153" class="t s6_153">
          хөгжлийн{" "}
        </span>
        <span id="tv_153" class="t s6_153">
          бүлгийн{" "}
        </span>
        <span id="tw_153" class="t s6_153">
          төлөвлөгөө, сургуулийн төлөвлөгөө гэх мэт олон төлөвлөгөөг уялдуулах,{" "}
        </span>
        <span id="tx_153" class="t s6_153">
          багш нарт хөгжих цаг боломж гаргах зохицуулалтыг сургуулийн удирдлагын{" "}
        </span>
        <span id="ty_153" class="t s6_153">
          зүгээс хийхийг зөвлөж байна.{" "}
        </span>
        <span id="tz_153" class="t s7_153">
          •{" "}
        </span>
        <span id="t10_153" class="t s6_153">
          Төлөвлөгөөг гаргахдаа олон үйл ажиллагаа төлөвлөхдөө бус{" "}
        </span>
        <span id="t11_153" class="t s6_153">
          зайлшгүй шаардлагатай гол үйл ажиллагаануудыг бодитой төлөвлөх{" "}
        </span>
        <span id="t12_153" class="t s7_153">
          •{" "}
        </span>
        <span id="t13_153" class="t s6_153">
          Олон төрлийн төлөвлөгөөг хооронд нь уялдуулан төлөвлөх{" "}
        </span>
        <span id="t14_153" class="t s6_153">
          (төлөвлөгөө боловсруулахдаа багш нарын оролцоог дэмжих){" "}
        </span>
        <span id="t15_153" class="t s7_153">
          •{" "}
        </span>
        <span id="t16_153" class="t s6_153">
          Багшийн мэргэжлийн хөгжлийн төлөвлөгөөнд туссан үйл ажиллагааг{" "}
        </span>
        <span id="t17_153" class="t s6_153">
          сургуулийн төлөвлөгөөнд тусгах{" "}
        </span>
        <span id="t18_153" class="t s7_153">
          •{" "}
        </span>
        <span id="t19_153" class="t s6_153">
          Батлагдсан төлөвлөгөөг хэрэгжүүлэх явцдаа сайжруулж байх{" "}
        </span>
        <span id="t1a_153" class="t s7_153">
          •{" "}
        </span>
        <span id="t1b_153" class="t s6_153">
          Мэргэжлийн{" "}
        </span>
        <span id="t1c_153" class="t s6_153">
          хөгжлийн{" "}
        </span>
        <span id="t1d_153" class="t s6_153">
          төлөвлөгөөний{" "}
        </span>
        <span id="t1e_153" class="t s6_153">
          дагуу{" "}
        </span>
        <span id="t1f_153" class="t s6_153">
          багш{" "}
        </span>
        <span id="t1g_153" class="t s6_153">
          мэргэжлээ{" "}
        </span>
        <span id="t1h_153" class="t s6_153">
          тасралтгүй{" "}
        </span>
        <span id="t1i_153" class="t s6_153">
          дээшлүүлэх{" "}
        </span>
        <span id="t1j_153" class="t s6_153">
          цаг{" "}
        </span>
        <span id="t1k_153" class="t s6_153">
          хугацааны{" "}
        </span>
        <span id="t1l_153" class="t s6_153">
          боломжоор{" "}
        </span>
        <span id="t1m_153" class="t s6_153">
          хангах,{" "}
        </span>
        <span id="t1n_153" class="t s6_153">
          зохицуулалт хийх шаардлагатай байна.{" "}
        </span>
        <span id="t1o_153" class="t s6_153">
          Багшийн хөгжих хэрэгцээнд сургалтыг нийцүүлэхэд удирдлагын зүгээс{" "}
        </span>
        <span id="t1p_153" class="t s6_153">
          манлайлахыг зөвлөж байна. Үүний тулд, багш мэргэжлийн хөгжлийн{" "}
        </span>
        <span id="t1q_153" class="t s6_153">
          хэрэгцээгээ өөрийн болон хөндлөнгийн үнэлгээгээр зөв тодорхойлох{" "}
        </span>
        <span id="t1r_153" class="t s6_153">
          шаардлагатай.{" "}
        </span>
      </div>
    </div>
  );
}
