import React from "react";
export default function OneHundredEightyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_185{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_185{left:98px;bottom:941px;letter-spacing:-0.19px;word-spacing:0.08px;}
#t3_185{left:337px;bottom:921px;letter-spacing:-0.38px;}
#t4_185{left:87px;bottom:892px;letter-spacing:-0.14px;}
#t5_185{left:87px;bottom:872px;}
#t6_185{left:114px;bottom:872px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t7_185{left:314px;bottom:872px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t8_185{left:87px;bottom:852px;}
#t9_185{left:114px;bottom:852px;letter-spacing:-0.15px;word-spacing:1.78px;}
#ta_185{left:114px;bottom:831px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tb_185{left:87px;bottom:811px;}
#tc_185{left:114px;bottom:811px;letter-spacing:-0.13px;word-spacing:0.03px;}
#td_185{left:87px;bottom:771px;letter-spacing:-0.09px;}
#te_185{left:139px;bottom:771px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tf_185{left:87px;bottom:731px;letter-spacing:-0.12px;word-spacing:3.09px;}
#tg_185{left:502px;bottom:731px;letter-spacing:-0.1px;word-spacing:3.07px;}
#th_185{left:87px;bottom:710px;letter-spacing:-0.12px;}
#ti_185{left:180px;bottom:710px;letter-spacing:-0.09px;}
#tj_185{left:238px;bottom:710px;letter-spacing:-0.08px;}
#tk_185{left:283px;bottom:710px;letter-spacing:-0.13px;}
#tl_185{left:404px;bottom:710px;letter-spacing:-0.12px;}
#tm_185{left:523px;bottom:710px;letter-spacing:-0.21px;}
#tn_185{left:624px;bottom:710px;letter-spacing:-0.16px;}
#to_185{left:87px;bottom:690px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tp_185{left:509px;bottom:690px;}
#tq_185{left:219px;bottom:619px;letter-spacing:0.15px;word-spacing:0.06px;}
#tr_185{left:87px;bottom:580px;letter-spacing:-0.14px;word-spacing:0.03px;}
#ts_185{left:87px;bottom:547px;letter-spacing:-0.14px;word-spacing:5.19px;}
#tt_185{left:87px;bottom:526px;letter-spacing:-0.14px;word-spacing:5.08px;}
#tu_185{left:87px;bottom:506px;letter-spacing:-0.11px;word-spacing:-0.28px;}
#tv_185{left:295px;bottom:506px;letter-spacing:-0.12px;word-spacing:-0.27px;}
#tw_185{left:87px;bottom:486px;letter-spacing:-0.12px;word-spacing:0.28px;}
#tx_185{left:87px;bottom:466px;letter-spacing:-0.18px;word-spacing:5.85px;}
#ty_185{left:87px;bottom:446px;letter-spacing:-0.18px;word-spacing:0.09px;}
#tz_185{left:195px;bottom:410px;letter-spacing:-0.14px;word-spacing:0.02px;}

.s0_185{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_185{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_185{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_185{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_185{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_185{font-size:17px;font-family:MogulArial_16s;color:#ED7D31;}
.s6_185{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s7_185{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#D1622B;}
.s8_185{font-size:17px;font-family:MogulArial_16s;color:#202124;}
.s9_185{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts185" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg185" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="185/185.svg"
          type="image/svg+xml"
          id="pdf185"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_185" class="t s0_185">
          185{" "}
        </span>
        <span id="t2_185" class="t s1_185">
          Сургалт, үйл ажиллагааны нэгдсэн төлөвлөгөө боловсруулахын ач{" "}
        </span>
        <span id="t3_185" class="t s1_185">
          холбогдол{" "}
        </span>
        <span id="t4_185" class="t s2_185">
          Сурагч:{" "}
        </span>
        <span id="t5_185" class="t s3_185">
          -{" "}
        </span>
        <span id="t6_185" class="t s4_185">
          Хичээлийн жилийн турш{" "}
        </span>
        <span id="t7_185" class="t s4_185">
          юу сурч эзэмшихээ мэднэ.{" "}
        </span>
        <span id="t8_185" class="t s3_185">
          -{" "}
        </span>
        <span id="t9_185" class="t s4_185">
          Ямар ямар үйл ажиллагаанд оролцож чадах, бусад хичээлтэй холбож{" "}
        </span>
        <span id="ta_185" class="t s4_185">
          хэрэглэдэг болно.{" "}
        </span>
        <span id="tb_185" class="t s3_185">
          -{" "}
        </span>
        <span id="tc_185" class="t s4_185">
          Ямар чадвараа хөгжүүлэх боломжтойг тунгаан бодно.{" "}
        </span>
        <span id="td_185" class="t s2_185">
          Багш:{" "}
        </span>
        <span id="te_185" class="t s4_185">
          Олон төлөвлөгөөг нэгдсэн байдлаар хийж ачаалал буурна.{" "}
        </span>
        <span id="tf_185" class="t s2_185">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч:{" "}
        </span>
        <span id="tg_185" class="t s4_185">
          Хүүхдээ ямар чадвар{" "}
        </span>
        <span id="th_185" class="t s4_185">
          эзэмшиж,{" "}
        </span>
        <span id="ti_185" class="t s4_185">
          ямар{" "}
        </span>
        <span id="tj_185" class="t s4_185">
          үйл{" "}
        </span>
        <span id="tk_185" class="t s4_185">
          ажиллагаанд{" "}
        </span>
        <span id="tl_185" class="t s4_185">
          хүүхэдтэйгээ{" "}
        </span>
        <span id="tm_185" class="t s4_185">
          оролцохоо{" "}
        </span>
        <span id="tn_185" class="t s4_185">
          мэдэх,{" "}
        </span>
        <span id="to_185" class="t s4_185">
          хүүхдийнхээ хөгжлийн ирээдүйг тунгаан боддог болно
        </span>
        <span id="tp_185" class="t s5_185">
          .{" "}
        </span>
        <span id="tq_185" class="t s6_185">
          3.6.3 БАЙГУУЛЛАГЫН ХҮНИЙ НӨӨЦ{" "}
        </span>
        <span id="tr_185" class="t s7_185">
          Сургалтын байгууллагын хүний нөөцийг хэрхэн бүрдүүлэх вэ?{" "}
        </span>
        <span id="ts_185" class="t s4_185">
          Сургуулийн алсын хараа, эрхэм зорилго, зорилтыг хэрэгжүүлэхэд хувь{" "}
        </span>
        <span id="tt_185" class="t s4_185">
          нэмрээ оруулах чадварлаг мэргэжилтэн, ажилтныг сонгон шалгаруулах{" "}
        </span>
        <span id="tu_185" class="t s4_185">
          замаар бүрдүүлнэ. Энэ нь{" "}
        </span>
        <span id="tv_185" class="t s8_185">
          сургуульд ажлын эерэг орчин бүрдэх, мэргэшсэн{" "}
        </span>
        <span id="tw_185" class="t s8_185">
          боловсон хүчнийг татах, хадгалах, хүний нөөцийн үр дүнтэй менежментийг{" "}
        </span>
        <span id="tx_185" class="t s8_185">
          хангахад тусалдаг. Мөн сургуулийн хамт олонд үүсч болзошгүй хүний{" "}
        </span>
        <span id="ty_185" class="t s8_185">
          нөөцтэй холбоотой асуудал, зөрчилдөөнийг шийдвэрлэх тулах цэг болдог.{" "}
        </span>
        <span id="tz_185" class="t s9_185">
          Зураг 3.34. Үйл ажиллагааны хэрэгжилтэд анхаарах зүйлс{" "}
        </span>
      </div>
    </div>
  );
}
