import React from "react";
export default function OneHundredNinetyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };

  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
transform-origin: bottom left;
z-index: 2;
position: absolute;
white-space: pre;
overflow: visible;
line-height: 1.5;
}
.text-container {
white-space: pre;
}
@supports (-webkit-touch-callout: none) {
.text-container {
white-space: normal;
}
}`}
      </style>

      <style type="text/css">
        {`#t1_199{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_199{left:117px;bottom:940px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t3_199{left:162px;bottom:924px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t4_199{left:401px;bottom:940px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t5_199{left:423px;bottom:924px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t6_199{left:395px;bottom:907px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t7_199{left:423px;bottom:891px;letter-spacing:-0.17px;word-spacing:0.04px;}

.s0_199{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_199{font-size:14px;font-family:MogulArial_16s;color:#000;}
`}
      </style>

      <style id="fonts199" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg199" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="199/199.svg"
          type="image/svg+xml"
          id="pdf199"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_199" class="t s0_199">
          199{" "}
        </span>
        <span id="t2_199" class="t s1_199">
          Сургуулийн менежментийн зураглал{" "}
        </span>
        <span id="t3_199" class="t s1_199">
          боловсруулах арга зүй{" "}
        </span>
        <span id="t4_199" class="t s1_199">
          Сургуулийн менежментийн зураглалтай{" "}
        </span>
        <span id="t5_199" class="t s1_199">
          уялдуулан хичээл сургалтын үйл{" "}
        </span>
        <span id="t6_199" class="t s1_199">
          ажиллагааг төлөвлөж хэрэгжүүлэн, үнэлж{" "}
        </span>
        <span id="t7_199" class="t s1_199">
          сайжруулахад суралцах арга зүй{" "}
        </span>
      </div>
    </div>
  );
}
