import React from "react";
export default function EightyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_88{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_88{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:2.97px;}
#t3_88{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:2.02px;}
#t4_88{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:9.39px;}
#t5_88{left:87px;bottom:880px;letter-spacing:-0.12px;word-spacing:0.3px;}
#t6_88{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_88{left:87px;bottom:831px;letter-spacing:-0.1px;}
#t8_88{left:166px;bottom:831px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t9_88{left:87px;bottom:803px;letter-spacing:-0.1px;}
#ta_88{left:166px;bottom:803px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tb_88{left:87px;bottom:774px;letter-spacing:-0.1px;}
#tc_88{left:171px;bottom:774px;letter-spacing:-0.12px;word-spacing:5.62px;}
#td_88{left:87px;bottom:754px;letter-spacing:-0.13px;word-spacing:1.29px;}
#te_88{left:476px;bottom:754px;letter-spacing:-0.19px;word-spacing:1.36px;}
#tf_88{left:87px;bottom:733px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tg_88{left:87px;bottom:705px;letter-spacing:-0.11px;}
#th_88{left:156px;bottom:705px;}
#ti_88{left:169px;bottom:705px;letter-spacing:-0.12px;word-spacing:4.57px;}
#tj_88{left:310px;bottom:705px;letter-spacing:-0.15px;word-spacing:4.59px;}
#tk_88{left:648px;bottom:705px;letter-spacing:-0.08px;}
#tl_88{left:87px;bottom:684px;letter-spacing:-0.13px;word-spacing:0.96px;}
#tm_88{left:87px;bottom:664px;letter-spacing:-0.14px;word-spacing:-0.82px;}
#tn_88{left:87px;bottom:644px;letter-spacing:-0.14px;word-spacing:0.05px;}
#to_88{left:87px;bottom:615px;letter-spacing:-0.15px;word-spacing:11.3px;}
#tp_88{left:87px;bottom:595px;letter-spacing:-0.15px;word-spacing:2.42px;}
#tq_88{left:87px;bottom:575px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tr_88{left:87px;bottom:546px;letter-spacing:-0.15px;word-spacing:4.68px;}
#ts_88{left:87px;bottom:526px;letter-spacing:-0.14px;word-spacing:6.8px;}
#tt_88{left:87px;bottom:506px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tu_88{left:96px;bottom:465px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tv_88{left:562px;bottom:202px;letter-spacing:0.09px;word-spacing:0.01px;}
#tw_88{left:540px;bottom:187px;letter-spacing:0.06px;word-spacing:0.04px;}
#tx_88{left:548px;bottom:173px;letter-spacing:0.1px;word-spacing:0.01px;}
#ty_88{left:583px;bottom:158px;letter-spacing:0.04px;}
#tz_88{left:574px;bottom:124px;letter-spacing:0.1px;word-spacing:0.02px;}

.s0_88{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_88{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_88{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_88{font-size:17px;font-family:MogulArial_16s;color:#D1622B;}
.s4_88{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_88{font-size:12px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts88" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}
`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg88" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="88/88.svg"
          type="image/svg+xml"
          id="pdf88"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_88" class="t s0_88">
          88{" "}
        </span>
        <span id="t2_88" class="t s1_88">
          гэсэн зорилго дэвшүүлсэн тохиолдолд эергээр нөлөөлөх хүчин зүйлс нь{" "}
        </span>
        <span id="t3_88" class="t s1_88">
          багшийн ажилдаа хандах эерэг хандлага, багш нарын хамтын ажиллагаа{" "}
        </span>
        <span id="t4_88" class="t s1_88">
          бол сөргөөр нөлөөлөх хүчин зүйлс нь эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="t5_88" class="t s1_88">
          харгалзан дэмжигчийн дэмжлэг сул гэж үзвэл үүнийг саармагжуулахад үйл{" "}
        </span>
        <span id="t6_88" class="t s1_88">
          ажиллагааны төлөвлөлтөө хийж болох юм.{" "}
        </span>
        <span id="t7_88" class="t s2_88">
          Алхам-5:{" "}
        </span>
        <span id="t8_88" class="t s1_88">
          Зорилго, зорилтдоо хүрэх ажлуудыг тодорхойлно.{" "}
        </span>
        <span id="t9_88" class="t s2_88">
          Алхам-6:{" "}
        </span>
        <span id="ta_88" class="t s1_88">
          Үнэлгээний шалгуур үзүүлэлтээ боловсруулна.{" "}
        </span>
        <span id="tb_88" class="t s2_88">
          Алхам-7:{" "}
        </span>
        <span id="tc_88" class="t s1_88">
          Үр дүнгийн тайлагналтаа төлөвлөнө. Сурагч, эцэг эх, асран{" "}
        </span>
        <span id="td_88" class="t s1_88">
          хамгаалагч, харгалзан дэмжигч, багш, ажилтан,{" "}
        </span>
        <span id="te_88" class="t s1_88">
          олон,нийт, аж ахуйн нэгж{" "}
        </span>
        <span id="tf_88" class="t s1_88">
          байгууллагын гэх мэт төлөөллийн оролцоог хангана.{" "}
        </span>
        <span id="tg_88" class="t s2_88">
          Алхам-8
        </span>
        <span id="th_88" class="t s3_88">
          :{" "}
        </span>
        <span id="ti_88" class="t s1_88">
          Хүүхэд хөгжил,{" "}
        </span>
        <span id="tj_88" class="t s1_88">
          хамгааллын үйл ажиллагааг сургалтын{" "}
        </span>
        <span id="tk_88" class="t s1_88">
          үйл{" "}
        </span>
        <span id="tl_88" class="t s1_88">
          ажиллагааны нэгдсэн төлөвлөгөөнд тусгана. Орон нутгийн засаг захиргаа,{" "}
        </span>
        <span id="tm_88" class="t s1_88">
          хүүхэд залуучуудын хөгжлийн газар зэрэг аж ахуйн нэгж байгууллагатай
          үйл{" "}
        </span>
        <span id="tn_88" class="t s1_88">
          ажиллагааны төлөвлөгөөгөө уялдуулан зохицуулна.{" "}
        </span>
        <span id="to_88" class="t s1_88">
          Сурагчийн хөгжил, хамгааллын асуудлыг шийдвэрлэхдээ сургалтын{" "}
        </span>
        <span id="tp_88" class="t s1_88">
          хөтөлбөрийн хэрэгжилт, багшийн хөгжил, сургалтын орчин, гэр бүл, олон{" "}
        </span>
        <span id="tq_88" class="t s1_88">
          нийтийн оролцоо, удирдлага манлайллын түвшинд цогцод нь авч үзнэ.{" "}
        </span>
        <span id="tr_88" class="t s1_88">
          Сургуулийн орчин дахь хүүхэд хөгжил, хамгааллын асуудалд нийгмийн{" "}
        </span>
        <span id="ts_88" class="t s1_88">
          ажилтан сэтгэл зүйч манлайлах үүрэгтэй ба үйл ажиллагааны бусад{" "}
        </span>
        <span id="tt_88" class="t s1_88">
          субъектуудтай нягт хамтран ажиллана.{" "}
        </span>
        <span id="tu_88" class="t s4_88">
          Зураг 3.3. Суралцагчийн хөгжил, хамгааллын албаны бүтцийн зөвлөмжилж
          буй хувилбар{" "}
        </span>
        <span id="tv_88" class="t s5_88">
          Эцэг эх, асран{" "}
        </span>
        <span id="tw_88" class="t s5_88">
          хамгаалагч, харгалзан{" "}
        </span>
        <span id="tx_88" class="t s5_88">
          дэмжигчийн зөвлөл{" "}
        </span>
        <span id="ty_88" class="t s5_88">
          холбоо{" "}
        </span>
        <span id="tz_88" class="t s5_88">
          олон нийт{" "}
        </span>
      </div>
    </div>
  );
}
