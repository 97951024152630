import React from "react";
export default function OneHundredSeventy() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_170{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_170{left:104px;bottom:936px;letter-spacing:0.12px;}
#t3_170{left:104px;bottom:918px;letter-spacing:0.15px;}
#t4_170{left:104px;bottom:899px;letter-spacing:0.13px;}
#t5_170{left:104px;bottom:881px;letter-spacing:0.08px;}
#t6_170{left:104px;bottom:863px;letter-spacing:0.01px;word-spacing:0.12px;}
#t7_170{left:104px;bottom:844px;letter-spacing:0.07px;}
#t8_170{left:231px;bottom:936px;letter-spacing:0.12px;word-spacing:6.29px;}
#t9_170{left:231px;bottom:918px;letter-spacing:0.15px;}
#ta_170{left:231px;bottom:899px;letter-spacing:0.08px;word-spacing:2.01px;}
#tb_170{left:397px;bottom:899px;letter-spacing:0.07px;word-spacing:2.04px;}
#tc_170{left:231px;bottom:881px;letter-spacing:0.1px;word-spacing:0.93px;}
#td_170{left:231px;bottom:863px;letter-spacing:0.13px;}
#te_170{left:231px;bottom:828px;letter-spacing:-0.03px;}
#tf_170{left:327px;bottom:828px;letter-spacing:0.03px;}
#tg_170{left:461px;bottom:828px;letter-spacing:0.14px;}
#th_170{left:574px;bottom:828px;letter-spacing:0.09px;}
#ti_170{left:231px;bottom:810px;letter-spacing:0.16px;word-spacing:8.3px;}
#tj_170{left:558px;bottom:810px;letter-spacing:0.12px;word-spacing:8.34px;}
#tk_170{left:231px;bottom:791px;letter-spacing:0.12px;word-spacing:0.03px;}
#tl_170{left:231px;bottom:773px;letter-spacing:0.11px;word-spacing:8.4px;}
#tm_170{left:231px;bottom:755px;letter-spacing:0.08px;word-spacing:1.11px;}
#tn_170{left:231px;bottom:736px;letter-spacing:0.09px;word-spacing:1.34px;}
#to_170{left:231px;bottom:718px;letter-spacing:0.12px;}
#tp_170{left:142px;bottom:657px;letter-spacing:0.15px;}
#tq_170{left:87px;bottom:625px;letter-spacing:-0.15px;word-spacing:2.87px;}
#tr_170{left:87px;bottom:605px;letter-spacing:-0.13px;word-spacing:-1.64px;}
#ts_170{left:548px;bottom:605px;letter-spacing:-0.13px;word-spacing:-1.63px;}
#tt_170{left:87px;bottom:585px;letter-spacing:-0.13px;word-spacing:2.49px;}
#tu_170{left:87px;bottom:564px;letter-spacing:-0.13px;word-spacing:1.4px;}
#tv_170{left:87px;bottom:544px;letter-spacing:-0.13px;word-spacing:3.05px;}
#tw_170{left:87px;bottom:524px;letter-spacing:-0.22px;word-spacing:0.13px;}
#tx_170{left:114px;bottom:500px;}
#ty_170{left:142px;bottom:500px;letter-spacing:-0.12px;word-spacing:7.36px;}
#tz_170{left:142px;bottom:479px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t10_170{left:114px;bottom:459px;}
#t11_170{left:142px;bottom:459px;letter-spacing:-0.13px;word-spacing:3.94px;}
#t12_170{left:142px;bottom:439px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t13_170{left:114px;bottom:419px;}
#t14_170{left:142px;bottom:419px;letter-spacing:-0.18px;word-spacing:4.13px;}
#t15_170{left:142px;bottom:399px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t16_170{left:87px;bottom:358px;letter-spacing:-0.15px;word-spacing:0.56px;}
#t17_170{left:87px;bottom:338px;letter-spacing:-0.14px;word-spacing:1.52px;}
#t18_170{left:87px;bottom:318px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t19_170{left:87px;bottom:278px;letter-spacing:-0.16px;word-spacing:7.74px;}
#t1a_170{left:87px;bottom:258px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1b_170{left:113px;bottom:237px;letter-spacing:-0.05px;}
#t1c_170{left:139px;bottom:237px;letter-spacing:-0.15px;word-spacing:3.11px;}
#t1d_170{left:139px;bottom:217px;letter-spacing:-0.19px;word-spacing:4.19px;}
#t1e_170{left:139px;bottom:197px;letter-spacing:-0.18px;word-spacing:4.93px;}
#t1f_170{left:139px;bottom:177px;letter-spacing:-0.18px;word-spacing:1.05px;}
#t1g_170{left:139px;bottom:157px;letter-spacing:-0.14px;word-spacing:1.06px;}
#t1h_170{left:139px;bottom:137px;letter-spacing:-0.14px;word-spacing:2.95px;}
#t1i_170{left:139px;bottom:116px;letter-spacing:-0.18px;word-spacing:0.09px;}

.s0_170{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_170{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_170{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s3_170{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s4_170{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_170{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s6_170{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts170" type="text/css">
        @
        {`font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg170" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="170/170.svg"
          type="image/svg+xml"
          id="pdf170"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_170" class="t s0_170">
          170{" "}
        </span>
        <span id="t2_170" class="t s1_170">
          Үр дүнг{" "}
        </span>
        <span id="t3_170" class="t s1_170">
          шинжилж{" "}
        </span>
        <span id="t4_170" class="t s1_170">
          нэн тэргүүнд{" "}
        </span>
        <span id="t5_170" class="t s1_170">
          сайжруулах{" "}
        </span>
        <span id="t6_170" class="t s1_170">
          гол асуудлаа{" "}
        </span>
        <span id="t7_170" class="t s1_170">
          тодорхойлох{" "}
        </span>
        <span id="t8_170" class="t s2_170">
          Сурагчдын аль асуудлыг шийдэхэд сургалтын орчин{" "}
        </span>
        <span id="t9_170" class="t s2_170">
          хамгийн их нөлөөлөх вэ?{" "}
        </span>
        <span id="ta_170" class="t s1_170">
          Багш нар хэлэлцээд{" "}
        </span>
        <span id="tb_170" class="t s1_170">
          АСУУДАЛ ШИЙДВЭРЛЭХ ЧАДВАР-ыг{" "}
        </span>
        <span id="tc_170" class="t s1_170">
          хөгжүүлэхэд сургалтын орчны асуудлын шийдлүүд чухал гэж{" "}
        </span>
        <span id="td_170" class="t s1_170">
          үзсэн.{" "}
        </span>
        <span id="te_170" class="t s2_170">
          АСУУДАЛ{" "}
        </span>
        <span id="tf_170" class="t s2_170">
          ШИЙДВЭРЛЭХ{" "}
        </span>
        <span id="tg_170" class="t s2_170">
          ЧАДВАР-ыг{" "}
        </span>
        <span id="th_170" class="t s2_170">
          хөгжүүлэхэд{" "}
        </span>
        <span id="ti_170" class="t s2_170">
          сургалтын орчны хувьд тулгамдсан{" "}
        </span>
        <span id="tj_170" class="t s2_170">
          аль асуудлыг{" "}
        </span>
        <span id="tk_170" class="t s2_170">
          шийдэх вэ?{" "}
        </span>
        <span id="tl_170" class="t s1_170">
          Хамт олноороо хэлэлцээд БУ-ны туршилт хийх орчныг{" "}
        </span>
        <span id="tm_170" class="t s1_170">
          бүрдүүлэх, багш нарын санал болгосон бодис урвалж, багаж{" "}
        </span>
        <span id="tn_170" class="t s1_170">
          хэрэгслийн хүртээмжийг нэмэгдүүлэхэд анхаарч ажиллахаар{" "}
        </span>
        <span id="to_170" class="t s1_170">
          болсон.{" "}
        </span>
        <span id="tp_170" class="t s3_170">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tq_170" class="t s4_170">
          Сургуулийн бүхий л үйл ажиллагааны гол цөм нь сурагч гэдгийг онцолж{" "}
        </span>
        <span id="tr_170" class="t s4_170">
          аливаа асуудлын шийдэл нь тэдний хэрэгцээнд нийцэж буй{" "}
        </span>
        <span id="ts_170" class="t s4_170">
          эсэхэд анхаарах{" "}
        </span>
        <span id="tt_170" class="t s4_170">
          нь зүйтэй. Өөрөөр хэлбэл, сурагч төвтэй, суралцахуйн төрөл бүрийн хэв{" "}
        </span>
        <span id="tu_170" class="t s4_170">
          маягийг дэмжсэн, тав тухтай сургалтын орчны дизайныг бий болгож, тэнд{" "}
        </span>
        <span id="tv_170" class="t s4_170">
          суралцаж буй сурагчдын хэрэгцээнд нийцсэн шийдлүүд гаргахыг зөвлөж{" "}
        </span>
        <span id="tw_170" class="t s4_170">
          байна. Тухайлбал,{" "}
        </span>
        <span id="tx_170" class="t s5_170">
          •{" "}
        </span>
        <span id="ty_170" class="t s4_170">
          Сурагчид бие биендээ тусалж, асууж, зааж, хамтарч ажиллах{" "}
        </span>
        <span id="tz_170" class="t s4_170">
          боломжтой байх,{" "}
        </span>
        <span id="t10_170" class="t s5_170">
          •{" "}
        </span>
        <span id="t11_170" class="t s4_170">
          Ялгаатай хөгжилтэй сурагчдад хэрэгтэй үед нь тусламж үзүүлэх,{" "}
        </span>
        <span id="t12_170" class="t s4_170">
          ямар нэгэн асуудал гарвал багш шууд очих боломжтой байх,{" "}
        </span>
        <span id="t13_170" class="t s5_170">
          •{" "}
        </span>
        <span id="t14_170" class="t s4_170">
          Олон төрлийн мэдээллийн эх сурвалж руу холбогдох боломжтой{" "}
        </span>
        <span id="t15_170" class="t s4_170">
          байх гэх мэт{" "}
        </span>
        <span id="t16_170" class="t s4_170">
          Сургуулийн орчны хувьд нэн тэргүүнд сайжруулах асуудлаа тодорхойлсны{" "}
        </span>
        <span id="t17_170" class="t s4_170">
          дараа тухайн асуудлыг хэрхэн арилгах шийдлээ хамт олноороо хэлэлцэж{" "}
        </span>
        <span id="t18_170" class="t s4_170">
          төсөв санхүү, хүний нөөцөө хуваарилж, үйл ажиллагаагаа төлөвлөнө.{" "}
        </span>
        <span id="t19_170" class="t s4_170">
          Сургуулиуд сургалтын орчныг сайжруулах төлөвлөлт хийхдээ дараах{" "}
        </span>
        <span id="t1a_170" class="t s4_170">
          зүйлсийг харгалзан үзэхийг санал болгож байна. Үүнд:{" "}
        </span>
        <span id="t1b_170" class="t s6_170">
          1.{" "}
        </span>
        <span id="t1c_170" class="t s4_170">
          Сургалтын арга зүй, багшлахуйн загвар, суралцахуйн арга хэлбэр{" "}
        </span>
        <span id="t1d_170" class="t s4_170">
          улам олон төрөл болж байгаатай холбоотой түүнд шаардлагатай{" "}
        </span>
        <span id="t1e_170" class="t s4_170">
          орчныг судалж, ялгаатай байдлыг тооцох; (Тухайлбал, сурагчдыг{" "}
        </span>
        <span id="t1f_170" class="t s4_170">
          өөрт тулгарсан асуудлаа шийддэг, амьдрах чадвартай иргэн болгон{" "}
        </span>
        <span id="t1g_170" class="t s4_170">
          бэлтгэхийн тулд өөр өөр судлагдахууныг нэгтгэж, бодит амьдралын{" "}
        </span>
        <span id="t1h_170" class="t s4_170">
          жишээн дээр тулгуурласан интеграци хэлбэрийн сургалтыг зохион{" "}
        </span>
        <span id="t1i_170" class="t s4_170">
          байгуулж байна){" "}
        </span>
      </div>
    </div>
  );
}
