import React from "react";
export default function OneHundredEightyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_184{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_184{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t3_184{left:114px;bottom:916px;}
#t4_184{left:141px;bottom:916px;letter-spacing:-0.16px;word-spacing:1.84px;}
#t5_184{left:141px;bottom:896px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t6_184{left:114px;bottom:872px;}
#t7_184{left:141px;bottom:872px;letter-spacing:-0.15px;word-spacing:1.41px;}
#t8_184{left:141px;bottom:852px;letter-spacing:-0.14px;word-spacing:-0.13px;}
#t9_184{left:141px;bottom:831px;letter-spacing:-0.19px;word-spacing:0.09px;}
#ta_184{left:114px;bottom:807px;}
#tb_184{left:141px;bottom:807px;letter-spacing:-0.17px;word-spacing:4.49px;}
#tc_184{left:141px;bottom:787px;letter-spacing:-0.24px;}
#td_184{left:251px;bottom:787px;letter-spacing:-0.16px;word-spacing:1.86px;}
#te_184{left:141px;bottom:767px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tf_184{left:114px;bottom:742px;}
#tg_184{left:141px;bottom:742px;letter-spacing:-0.16px;word-spacing:2.14px;}
#th_184{left:141px;bottom:722px;letter-spacing:-0.16px;word-spacing:0.05px;}
#ti_184{left:87px;bottom:682px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tj_184{left:114px;bottom:657px;letter-spacing:-0.05px;}
#tk_184{left:142px;bottom:657px;letter-spacing:-0.16px;word-spacing:11.94px;}
#tl_184{left:142px;bottom:637px;letter-spacing:-0.17px;word-spacing:11.86px;}
#tm_184{left:142px;bottom:617px;letter-spacing:-0.13px;word-spacing:0.07px;}
#tn_184{left:142px;bottom:597px;letter-spacing:-0.14px;word-spacing:0.05px;}
#to_184{left:114px;bottom:572px;letter-spacing:-0.05px;}
#tp_184{left:142px;bottom:572px;letter-spacing:-0.14px;word-spacing:5.69px;}
#tq_184{left:142px;bottom:552px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tr_184{left:114px;bottom:527px;letter-spacing:-0.05px;}
#ts_184{left:142px;bottom:527px;letter-spacing:-0.13px;word-spacing:-2.13px;}
#tt_184{left:142px;bottom:507px;letter-spacing:-0.13px;word-spacing:-1.24px;}
#tu_184{left:142px;bottom:487px;letter-spacing:-0.21px;}
#tv_184{left:114px;bottom:463px;letter-spacing:-0.05px;}
#tw_184{left:142px;bottom:463px;letter-spacing:-0.14px;word-spacing:1.12px;}
#tx_184{left:142px;bottom:442px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ty_184{left:491px;bottom:442px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tz_184{left:114px;bottom:418px;letter-spacing:-0.05px;}
#t10_184{left:142px;bottom:418px;letter-spacing:-0.14px;word-spacing:8.82px;}
#t11_184{left:142px;bottom:398px;letter-spacing:-0.14px;word-spacing:6px;}
#t12_184{left:621px;bottom:398px;letter-spacing:-0.09px;}
#t13_184{left:142px;bottom:378px;letter-spacing:-0.13px;word-spacing:1.5px;}
#t14_184{left:142px;bottom:357px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t15_184{left:114px;bottom:333px;letter-spacing:-0.05px;}
#t16_184{left:142px;bottom:333px;letter-spacing:-0.16px;word-spacing:0.39px;}
#t17_184{left:142px;bottom:313px;letter-spacing:-0.17px;word-spacing:3.8px;}
#t18_184{left:142px;bottom:293px;letter-spacing:-0.13px;word-spacing:3.89px;}
#t19_184{left:142px;bottom:272px;letter-spacing:-0.16px;word-spacing:3.83px;}
#t1a_184{left:142px;bottom:252px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1b_184{left:114px;bottom:228px;letter-spacing:-0.05px;}
#t1c_184{left:142px;bottom:228px;letter-spacing:-0.16px;word-spacing:-1.04px;}
#t1d_184{left:142px;bottom:208px;letter-spacing:-0.12px;word-spacing:0.02px;}

.s0_184{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_184{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#ED7D31;}
.s2_184{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s3_184{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_184{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts184" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg184" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="184/184.svg"
          type="image/svg+xml"
          id="pdf184"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_184" class="t s0_184">
          184{" "}
        </span>
        <span id="t2_184" class="t s1_184">
          “Сурах бичгээ судалж дуусгая.” Ажлыг зохион байгуулах алхамчлал{" "}
        </span>
        <span id="t3_184" class="t s2_184">
          ●{" "}
        </span>
        <span id="t4_184" class="t s3_184">
          Энэ ажлын ач холбогдлыг багш нарт ойлгуулах, “Хийгээд үзье” гэх{" "}
        </span>
        <span id="t5_184" class="t s3_184">
          сэтгэлийг төрүүлсэн байх{" "}
        </span>
        <span id="t6_184" class="t s2_184">
          ●{" "}
        </span>
        <span id="t7_184" class="t s3_184">
          Ажлын календарчилсан төлөвлөгөөг захирлаар батлуулахаас өмнө{" "}
        </span>
        <span id="t8_184" class="t s3_184">
          сургалтын менежерүүд багш бүрээс саналыг авч, хугацааг өөрсдөөр{" "}
        </span>
        <span id="t9_184" class="t s3_184">
          нь тодорхойлуулах{" "}
        </span>
        <span id="ta_184" class="t s2_184">
          ●{" "}
        </span>
        <span id="tb_184" class="t s3_184">
          Нэгэнт хамт олноороо хэлэлцэж, тохиролцоод хийж эхэлсэн бол{" "}
        </span>
        <span id="tc_184" class="t s3_184">
          удирдлагууд{" "}
        </span>
        <span id="td_184" class="t s3_184">
          багш нарыг хугацаандаа чанартай гүйцэтгэж байгаад{" "}
        </span>
        <span id="te_184" class="t s3_184">
          хяналт тавьж, дэмжлэг хэрэгтэй байгаа багш нарт зөвлөн туслах{" "}
        </span>
        <span id="tf_184" class="t s2_184">
          ●{" "}
        </span>
        <span id="tg_184" class="t s3_184">
          Хугацаандаа чанартай хийж, өөртөө амжилт гаргасан багш нартаа{" "}
        </span>
        <span id="th_184" class="t s3_184">
          хамт олны өмнө урамшуулах{" "}
        </span>
        <span id="ti_184" class="t s1_184">
          Нэг баг- нэг арга зүй зохион байгуулах алхамчлал{" "}
        </span>
        <span id="tj_184" class="t s4_184">
          1.{" "}
        </span>
        <span id="tk_184" class="t s3_184">
          Багш өөрийн зааж буй судлагдахууны сургалтын хөтөлбөр,{" "}
        </span>
        <span id="tl_184" class="t s3_184">
          суралцахуйн удирдамж, хичээл сургалтыг үнэлэх үнэлгээний{" "}
        </span>
        <span id="tm_184" class="t s3_184">
          шалгуур, шалгуурыг хэрэглэх зөвлөмж, сурах бичгээ сайтар судалж,{" "}
        </span>
        <span id="tn_184" class="t s3_184">
          өөрийн гэсэн мэдлэг ойлголттой болох{" "}
        </span>
        <span id="to_184" class="t s4_184">
          2.{" "}
        </span>
        <span id="tp_184" class="t s3_184">
          Хичээлийн жилийн болон нэгж, ээлжит хичээлийн төлөвлөлтийг{" "}
        </span>
        <span id="tq_184" class="t s3_184">
          секцийн багш нартайгаа зөвлөлдөж, боловсруулах{" "}
        </span>
        <span id="tr_184" class="t s4_184">
          3.{" "}
        </span>
        <span id="ts_184" class="t s3_184">
          Нэгж, ээлжит хичээлийн төлөвлөлтийг боловсруулахдаа суралцахуйн{" "}
        </span>
        <span id="tt_184" class="t s3_184">
          зорилтоо шинжилж, тухайн агуулгыг хэдэн цагаар төлөвлөхөө сайтар{" "}
        </span>
        <span id="tu_184" class="t s3_184">
          тооцох{" "}
        </span>
        <span id="tv_184" class="t s4_184">
          4.{" "}
        </span>
        <span id="tw_184" class="t s3_184">
          Төлөвлөсөн нэгж болон ээлжит хичээлээ чанартай заах, хичээлийн{" "}
        </span>
        <span id="tx_184" class="t s3_184">
          туршид хүүхэд бүрийн явцын үнэлгээ хийж,{" "}
        </span>
        <span id="ty_184" class="t s3_184">
          тэмдэглэл хөтлөх{" "}
        </span>
        <span id="tz_184" class="t s4_184">
          5.{" "}
        </span>
        <span id="t10_184" class="t s3_184">
          Багш сурагчдын явцын үнэлгээ, тэмдэглэл дээрээ тулгуурлан{" "}
        </span>
        <span id="t11_184" class="t s3_184">
          агуулгын хоцрогдлыг бүлэг сэдэв, анги, хүүхэд бүрээр{" "}
        </span>
        <span id="t12_184" class="t s3_184">
          анализ{" "}
        </span>
        <span id="t13_184" class="t s3_184">
          хийж үр дүнг тооцох, тухайн үр дүнгээс хамаарч ээлжит хичээлийн{" "}
        </span>
        <span id="t14_184" class="t s3_184">
          хөтөлбөрийг засан сайжруулах{" "}
        </span>
        <span id="t15_184" class="t s4_184">
          6.{" "}
        </span>
        <span id="t16_184" class="t s3_184">
          Ээлжит хичээлийн төлөвлөлтдөө МХТ-ийн шинэ шийдлийг (контент,{" "}
        </span>
        <span id="t17_184" class="t s3_184">
          интерактив) ашиглан орон нутаг, хэл соёлын онцлогт тохируулан{" "}
        </span>
        <span id="t18_184" class="t s3_184">
          тусгах. Тухайлбал Үндэсний цөөнх казах тува хүүхдүүдийн хувьд{" "}
        </span>
        <span id="t19_184" class="t s3_184">
          “ЧИМЭГЭ” апп-ыг сургалтад ашиглах нь хэлний ялгаатай байдал{" "}
        </span>
        <span id="t1a_184" class="t s3_184">
          арилахад дэмжлэг болохыг анхаарах{" "}
        </span>
        <span id="t1b_184" class="t s4_184">
          7.{" "}
        </span>
        <span id="t1c_184" class="t s3_184">
          Удирдах ажилтнууд шаардах, даалгавар өгөх гэхээсээ илүү , хамтарч{" "}
        </span>
        <span id="t1d_184" class="t s3_184">
          ажиллах тактикийг барих{" "}
        </span>
      </div>
    </div>
  );
}
