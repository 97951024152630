import React from "react";
export default function F149() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_149{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_149{left:142px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_149{left:106px;bottom:871px;letter-spacing:0.12px;word-spacing:8.09px;}
#t4_149{left:106px;bottom:852px;letter-spacing:0.11px;word-spacing:0.89px;}
#t5_149{left:106px;bottom:834px;letter-spacing:0.09px;word-spacing:-0.32px;}
#t6_149{left:106px;bottom:816px;letter-spacing:0.11px;word-spacing:1.7px;}
#t7_149{left:106px;bottom:797px;letter-spacing:0.1px;word-spacing:0.04px;}
#t8_149{left:336px;bottom:768px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t9_149{left:235px;bottom:752px;letter-spacing:-0.15px;word-spacing:0.02px;}
#ta_149{left:106px;bottom:688px;letter-spacing:0.12px;word-spacing:2.07px;}
#tb_149{left:106px;bottom:670px;letter-spacing:0.1px;word-spacing:-0.96px;}
#tc_149{left:106px;bottom:652px;letter-spacing:0.09px;word-spacing:-1px;}
#td_149{left:106px;bottom:633px;letter-spacing:0.09px;word-spacing:0.95px;}
#te_149{left:106px;bottom:615px;letter-spacing:0.1px;word-spacing:0.04px;}
#tf_149{left:244px;bottom:591px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tg_149{left:326px;bottom:574px;letter-spacing:-0.16px;word-spacing:0.02px;}
#th_149{left:106px;bottom:507px;letter-spacing:0.07px;word-spacing:3.53px;}
#ti_149{left:106px;bottom:489px;letter-spacing:0.1px;word-spacing:-0.87px;}
#tj_149{left:106px;bottom:470px;letter-spacing:0.13px;word-spacing:5.87px;}
#tk_149{left:106px;bottom:452px;letter-spacing:0.06px;word-spacing:2.08px;}
#tl_149{left:106px;bottom:434px;letter-spacing:0.08px;word-spacing:-0.03px;}
#tm_149{left:106px;bottom:415px;letter-spacing:0.14px;}
#tn_149{left:244px;bottom:391px;letter-spacing:-0.14px;word-spacing:0.03px;}
#to_149{left:326px;bottom:374px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tp_149{left:106px;bottom:310px;letter-spacing:0.09px;word-spacing:0.46px;}
#tq_149{left:106px;bottom:292px;letter-spacing:0.07px;word-spacing:2.52px;}
#tr_149{left:106px;bottom:273px;letter-spacing:0.12px;word-spacing:6.69px;}
#ts_149{left:106px;bottom:255px;letter-spacing:0.1px;word-spacing:0.1px;}
#tt_149{left:106px;bottom:237px;letter-spacing:0.09px;word-spacing:-1.21px;}
#tu_149{left:106px;bottom:218px;letter-spacing:0.12px;word-spacing:4.82px;}
#tv_149{left:106px;bottom:200px;letter-spacing:0.12px;word-spacing:0.48px;}
#tw_149{left:106px;bottom:182px;letter-spacing:0.1px;}
#tx_149{left:244px;bottom:166px;letter-spacing:-0.14px;word-spacing:0.03px;}
#ty_149{left:326px;bottom:149px;letter-spacing:-0.16px;word-spacing:0.02px;}

.s0_149{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_149{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_149{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_149{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_149{font-size:15px;font-family:MogulArial_18b;color:#000;}`}
      </style>

      <style id="fonts149" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg149" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="149/149.svg"
          type="image/svg+xml"
          id="pdf149"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_149" class="t s0_149">
          149{" "}
        </span>
        <span id="t2_149" class="t s1_149">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_149" class="t s2_149">
          Багш нар хичээлийн явцад сурагчдыг сэдэлжүүлэх, үнэлэх зорилгоор{" "}
        </span>
        <span id="t4_149" class="t s2_149">
          Moodle, Kahoot, Mentimeter Khan Academy, Brilliant, Plickers зэрэг
          апплейкшн{" "}
        </span>
        <span id="t5_149" class="t s2_149">
          платформын тодорхой модулийг бие даан судалж ашиглаж байна. Жишээ нь:{" "}
        </span>
        <span id="t6_149" class="t s2_149">
          Багш Plickers платформ ашиглан сурагч бүрт QR код тараан өгч сургалтын{" "}
        </span>
        <span id="t7_149" class="t s2_149">
          эхэнд болон төгсгөлд 1-2 минутын хугацаанд үнэлж болох юм.{" "}
        </span>
        <span id="t8_149" class="t s3_149">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын{" "}
        </span>
        <span id="t9_149" class="t s3_149">
          ... сургуулийн менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ta_149" class="t s2_149">
          Бүх багшид Microsoft хаяг үүсгэсэн хэдий ч багш нар төдийлэн
          ашиглахгүй{" "}
        </span>
        <span id="tb_149" class="t s2_149">
          байна. Бид энэхүү бүртгэлтэй хаягаараа дамжуулан тус платформд
          нэвтэрсэн{" "}
        </span>
        <span id="tc_149" class="t s2_149">
          тохиолдолд олон төрлийн апплейкшн ашиглан хичээлдээ хэрэглэх боломжтой{" "}
        </span>
        <span id="td_149" class="t s2_149">
          хэрэглэгдэхүүн бэлтгэх, дундын мэдээлэл солилцох, бүлэг нээж
          ажиллуулах{" "}
        </span>
        <span id="te_149" class="t s2_149">
          боломж бүрдэж байна.{" "}
        </span>
        <span id="tf_149" class="t s3_149">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="tg_149" class="t s3_149">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="th_149" class="t s2_149">
          Багш нар гар утас, таблет, нөтбүүк зэрэг хэрэгслээр дамжуулан интернэт{" "}
        </span>
        <span id="ti_149" class="t s2_149">
          орчинд олон төрлийн апплейкшн, сургалтын цахим платформ ашиглаж байна.{" "}
        </span>
        <span id="tj_149" class="t s2_149">
          Жишээ нь: Medle.mn сургалтын цахим платформд байршуулсан цагийн{" "}
        </span>
        <span id="tk_149" class="t s2_149">
          хуваарилалт, нэгж, ээлжит хичээлийн төлөвлөлт, цахим хичээл,
          интерактив{" "}
        </span>
        <span id="tl_149" class="t s2_149">
          хэрэглэгдэхүүн, виртуал лабораторийг ашиглан хичээлийн бэлтгэлээ
          бэлтгэж{" "}
        </span>
        <span id="tm_149" class="t s2_149">
          үр дүнд хүрсэн жишээ олон бий.{" "}
        </span>
        <span id="tn_149" class="t s3_149">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="to_149" class="t s3_149">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tp_149" class="t s4_149">
          Математик, физик, биологи, хими, байгалийн шинжлэх ухааны хичээлд www.{" "}
        </span>
        <span id="tq_149" class="t s2_149">
          phet.colorado.edu сайтад байршуулсан виртуал лабораториуд байдаг. Багш{" "}
        </span>
        <span id="tr_149" class="t s2_149">
          нар тус виртуал лабораторийн ажлын заавар боловсруулан хичээлдээ{" "}
        </span>
        <span id="ts_149" class="t s2_149">
          ашиглаж байна. Мөн гэрийн даалгаварт туршилт ажлыг гүйцэтгэхэд
          сурагчид{" "}
        </span>
        <span id="tt_149" class="t s2_149">
          ихэд идэвхтэй оролцдог байна. Энэ лабораториуд нь вэб технологид
          суурилан{" "}
        </span>
        <span id="tu_149" class="t s2_149">
          HTML5 дээр бичигдсэн тул хэмжээ бага, онлайн болон оффлайн орчин{" "}
        </span>
        <span id="tv_149" class="t s2_149">
          ажиллах бөгөөд дурын интернэт хөтөч ашиглан ухаалаг төхөөрөмж ажиллах{" "}
        </span>
        <span id="tw_149" class="t s2_149">
          боломжтой.{" "}
        </span>
        <span id="tx_149" class="t s3_149">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="ty_149" class="t s3_149">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
