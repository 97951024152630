import React from "react";
export default function SeventySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_76{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_76{left:142px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_76{left:107px;bottom:883px;letter-spacing:0.09px;word-spacing:-1.03px;}
#t4_76{left:107px;bottom:864px;letter-spacing:0.11px;word-spacing:6.2px;}
#t5_76{left:107px;bottom:846px;letter-spacing:0.11px;word-spacing:2.34px;}
#t6_76{left:107px;bottom:828px;letter-spacing:0.08px;word-spacing:0.06px;}
#t7_76{left:107px;bottom:797px;letter-spacing:0.1px;word-spacing:0.74px;}
#t8_76{left:107px;bottom:779px;letter-spacing:0.11px;word-spacing:5.51px;}
#t9_76{left:107px;bottom:760px;letter-spacing:0.11px;word-spacing:1.1px;}
#ta_76{left:107px;bottom:742px;letter-spacing:0.1px;word-spacing:-1.16px;}
#tb_76{left:107px;bottom:724px;letter-spacing:0.09px;word-spacing:0.39px;}
#tc_76{left:107px;bottom:705px;letter-spacing:0.1px;word-spacing:0.03px;}
#td_76{left:245px;bottom:677px;letter-spacing:-0.14px;word-spacing:0.03px;}
#te_76{left:327px;bottom:661px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tf_76{left:107px;bottom:580px;letter-spacing:0.1px;word-spacing:3.08px;}
#tg_76{left:592px;bottom:580px;letter-spacing:0.13px;}
#th_76{left:107px;bottom:561px;letter-spacing:0.1px;word-spacing:2.87px;}
#ti_76{left:107px;bottom:543px;letter-spacing:0.09px;word-spacing:-1.38px;}
#tj_76{left:107px;bottom:525px;letter-spacing:0.1px;word-spacing:-1.76px;}
#tk_76{left:107px;bottom:506px;letter-spacing:0.12px;word-spacing:-0.26px;}
#tl_76{left:107px;bottom:488px;letter-spacing:0.03px;word-spacing:7.47px;}
#tm_76{left:107px;bottom:470px;letter-spacing:0.08px;word-spacing:1.98px;}
#tn_76{left:107px;bottom:451px;letter-spacing:0.11px;word-spacing:0.8px;}
#to_76{left:107px;bottom:433px;letter-spacing:0.11px;word-spacing:0.03px;}
#tp_76{left:291px;bottom:408px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tq_76{left:323px;bottom:392px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tr_76{left:563px;bottom:392px;letter-spacing:-0.13px;}
#ts_76{left:142px;bottom:314px;letter-spacing:0.18px;}
#tt_76{left:87px;bottom:286px;}
#tu_76{left:114px;bottom:286px;letter-spacing:-0.12px;word-spacing:1.79px;}
#tv_76{left:114px;bottom:266px;letter-spacing:-0.15px;word-spacing:7.43px;}
#tw_76{left:114px;bottom:246px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tx_76{left:87px;bottom:221px;}
#ty_76{left:114px;bottom:221px;letter-spacing:-0.13px;word-spacing:3.96px;}
#tz_76{left:114px;bottom:201px;letter-spacing:-0.13px;word-spacing:4.09px;}
#t10_76{left:114px;bottom:181px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_76{left:87px;bottom:157px;}
#t12_76{left:114px;bottom:157px;letter-spacing:-0.13px;word-spacing:-0.2px;}
#t13_76{left:114px;bottom:136px;letter-spacing:-0.14px;word-spacing:-0.61px;}
#t14_76{left:114px;bottom:116px;letter-spacing:-0.13px;word-spacing:5.25px;}

.s0_76{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_76{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_76{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_76{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_76{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_76{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts76" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg76" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="76/76.svg"
          type="image/svg+xml"
          id="pdf76"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_76" class="t s0_76">
          76{" "}
        </span>
        <span id="t2_76" class="t s1_76">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_76" class="t s2_76">
          ‘”Words from the Heart’ илтгэлийн уралдаан гадаад хэлээр судалсан
          мэдлэгийг{" "}
        </span>
        <span id="t4_76" class="t s2_76">
          чадвар болгох, сурах идэвх, сонирхлыг нэмэгдүүлэх, сурагчдын чөлөөт{" "}
        </span>
        <span id="t5_76" class="t s2_76">
          сэтгэлгээг хөгжүүлэхээр 2 үе шаттай 9-12-р ангийн сурагчдын дунд
          зохион{" "}
        </span>
        <span id="t6_76" class="t s2_76">
          байгуулахад 80 гаруй сурагч илтгэлээ ирүүлж, шалгаруулалт явуулсан.{" "}
        </span>
        <span id="t7_76" class="t s2_76">
          Мөн гадаад дууны тэмцээн 6-12 ангийн нийт 494 сурагч 114 дуугаар
          (англи,{" "}
        </span>
        <span id="t8_76" class="t s2_76">
          орос, солонгос, хэл дээр) дуулж оролцлоо. Тус тэмцээн нь сурагчдыг{" "}
        </span>
        <span id="t9_76" class="t s2_76">
          урлагаар дамжуулан тухайн орны хэл соёлын мэдлэгээ дээшлүүлэх, дуулах{" "}
        </span>
        <span id="ta_76" class="t s2_76">
          бүжиглэх авьяас болон хэлний мэдлэг чадвараа хөгжүүлэх, бусдаас
          суралцах,{" "}
        </span>
        <span id="tb_76" class="t s2_76">
          өөрийгөө илэрхийлэх чадварыг хөгжүүлэх зэрэг олон талын ач
          холбогдолтой{" "}
        </span>
        <span id="tc_76" class="t s2_76">
          ажил болсон байна.{" "}
        </span>
        <span id="td_76" class="t s3_76">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="te_76" class="t s3_76">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tf_76" class="t s2_76">
          Хичээлээс гадуурх секц дугуйлангийн тоо төрлийг нэмэгдүүлэн,{" "}
        </span>
        <span id="tg_76" class="t s2_76">
          хүүхдийн{" "}
        </span>
        <span id="th_76" class="t s2_76">
          оролцоог сайжруулсан хийгээд улмаар сурагчид багштайгаа хамтран орон{" "}
        </span>
        <span id="ti_76" class="t s2_76">
          нутгийн засаг захиргааны удирдлага, эцэг эх, үе тэнгийнхэн,
          сургуулийнхандаа{" "}
        </span>
        <span id="tj_76" class="t s2_76">
          тайлант өдөрлөг хийх, үзэсгэлэн гаргах зэрэг арга хэмжээг
          хэрэгжүүлжээ. 2023-{" "}
        </span>
        <span id="tk_76" class="t s2_76">
          2024 оны хичээлийн жилд шинээр 1-р ангид элсэн орж суралцах
          цэцэрлэгийн{" "}
        </span>
        <span id="tl_76" class="t s2_76">
          бэлтгэл бүлгийн сурагчдад “СУРГУУЛЬТАЙ ТАНИЛЦАХ” өдөрлөг, түүнд{" "}
        </span>
        <span id="tm_76" class="t s2_76">
          эцэг, эх, асран хамгаалагчдыг идэвхтэй оролцуулах үйл ажиллагааг
          зохион{" "}
        </span>
        <span id="tn_76" class="t s2_76">
          байгуулсан нь хүүхдийн нийгэм сэтгэл зүйн дасан зохицох чадварыг
          дэмжих{" "}
        </span>
        <span id="to_76" class="t s2_76">
          чухал ажил болсон байна.{" "}
        </span>
        <span id="tp_76" class="t s3_76">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="tq_76" class="t s3_76">
          менежментийг сайжруулах хүрээнд{" "}
        </span>
        <span id="tr_76" class="t s3_76">
          хийсэн ажлаас{" "}
        </span>
        <span id="ts_76" class="t s1_76">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tt_76" class="t s4_76">
          •{" "}
        </span>
        <span id="tu_76" class="t s5_76">
          Сурагчдад зан төлөвийн эерэг төлөвшил, ёс суртахууныг хөгжүүлэхэд{" "}
        </span>
        <span id="tv_76" class="t s5_76">
          чиглэсэн хичээлээс гадуурх үйл ажиллагааг улирал тутам зохион{" "}
        </span>
        <span id="tw_76" class="t s5_76">
          байгуулж үр дүнг нь тооцдог байх{" "}
        </span>
        <span id="tx_76" class="t s4_76">
          •{" "}
        </span>
        <span id="ty_76" class="t s5_76">
          Хүүхэд бие даан сурах үйлээ төлөвлөх, хэрэгжүүлэх, хянах, үнэлэх,{" "}
        </span>
        <span id="tz_76" class="t s5_76">
          засан сайжруулах циклээр ээлжит хичээл, зөвлөн туслах үйлчилгээ,{" "}
        </span>
        <span id="t10_76" class="t s5_76">
          ХГҮА-г төлөвлөн хэрэгжүүлэх{" "}
        </span>
        <span id="t11_76" class="t s4_76">
          •{" "}
        </span>
        <span id="t12_76" class="t s5_76">
          Хүүхдээ хөгжүүлэх үйл ажиллагаанд эцэг эхийг татан оролцуулах, үүрэг{" "}
        </span>
        <span id="t13_76" class="t s5_76">
          хариуцлагыг нь нэмэгдүүлэхэд чиглэсэн үйл ажиллагааг тогтмолжуулах,{" "}
        </span>
        <span id="t14_76" class="t s5_76">
          ингэхдээ эцэг эх, асран хамгаалагчтай харилцан зөвшилцөх, давуу{" "}
        </span>
      </div>
    </div>
  );
}
