import React from "react";
export default function FiftyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_55{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_55{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_55{left:87px;bottom:817px;letter-spacing:-0.35px;}
#t4_55{left:187px;bottom:817px;letter-spacing:-0.15px;}
#t5_55{left:334px;bottom:817px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t6_55{left:87px;bottom:797px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_55{left:93px;bottom:771px;letter-spacing:-0.24px;word-spacing:0.08px;}
#t8_55{left:93px;bottom:755px;letter-spacing:-0.19px;word-spacing:0.03px;}
#t9_55{left:119px;bottom:738px;}
#ta_55{left:145px;bottom:738px;letter-spacing:-0.23px;}
#tb_55{left:119px;bottom:722px;}
#tc_55{left:145px;bottom:722px;letter-spacing:-0.23px;}
#td_55{left:119px;bottom:705px;}
#te_55{left:145px;bottom:705px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tf_55{left:421px;bottom:771px;letter-spacing:-0.21px;word-spacing:0.05px;}
#tg_55{left:447px;bottom:755px;}
#th_55{left:473px;bottom:755px;letter-spacing:-0.17px;}
#ti_55{left:447px;bottom:738px;}
#tj_55{left:473px;bottom:738px;letter-spacing:-0.17px;}
#tk_55{left:447px;bottom:722px;}
#tl_55{left:473px;bottom:722px;letter-spacing:-0.14px;}
#tm_55{left:87px;bottom:671px;letter-spacing:-0.16px;word-spacing:2.22px;}
#tn_55{left:87px;bottom:651px;letter-spacing:-0.11px;word-spacing:0.02px;}
#to_55{left:214px;bottom:627px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tp_55{left:87px;bottom:514px;letter-spacing:0.2px;word-spacing:5.96px;}
#tq_55{left:87px;bottom:494px;letter-spacing:0.19px;word-spacing:1.39px;}
#tr_55{left:87px;bottom:473px;letter-spacing:0.18px;word-spacing:0.21px;}
#ts_55{left:87px;bottom:453px;letter-spacing:0.15px;word-spacing:0.09px;}
#tt_55{left:87px;bottom:420px;letter-spacing:-0.15px;word-spacing:1.84px;}
#tu_55{left:87px;bottom:400px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tv_55{left:156px;bottom:372px;letter-spacing:-0.16px;word-spacing:0.04px;}

.s0_55{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_55{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_55{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_55{font-size:17px;font-family:MogulArial_18b;color:#0563C1;}
.s4_55{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_55{font-size:14px;font-family:SymbolMT_19r;color:#000;}
.s6_55{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.t.m0_55{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts55" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg55" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="55/55.svg"
          type="image/svg+xml"
          id="pdf55"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_55" class="t s1_55">
          55{" "}
        </span>
        <span id="t3_55" class="t s2_55">
          Тодруулбал,{" "}
        </span>
        <span id="t4_55" class="t s3_55">
          http://www.eec.mn/{" "}
        </span>
        <span id="t5_55" class="t s2_55">
          веб хуудасны “ҮНЭЛГЭЭНИЙ ҮР ДҮН”{" "}
        </span>
        <span id="t6_55" class="t s2_55">
          цонхонд хандаж, дараах чиглэлүүдээр мэдээллийг нарийвчлан авна. Үүнд:{" "}
        </span>
        <span id="t7_55" class="t s4_55">
          СУРГАЛТЫН БАЙГУУЛЛАГЫН ҮНЭЛГЭЭ{" "}
        </span>
        <span id="t8_55" class="t s4_55">
          ХӨТӨЛБӨРИЙН ХЭРЭГЖИЛТ{" "}
        </span>
        <span id="t9_55" class="t s5_55">
          •{" "}
        </span>
        <span id="ta_55" class="t s4_55">
          Судлагдахуун{" "}
        </span>
        <span id="tb_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tc_55" class="t s4_55">
          Агуулга{" "}
        </span>
        <span id="td_55" class="t s5_55">
          •{" "}
        </span>
        <span id="te_55" class="t s4_55">
          Үнэлгээний зорилт{" "}
        </span>
        <span id="tf_55" class="t s4_55">
          БАГШИЙН ГҮЙЦЭТГЭЛИЙН ҮНЭЛГЭЭ{" "}
        </span>
        <span id="tg_55" class="t s5_55">
          •{" "}
        </span>
        <span id="th_55" class="t s4_55">
          Шалгуур{" "}
        </span>
        <span id="ti_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tj_55" class="t s4_55">
          Үзүүлэлт{" "}
        </span>
        <span id="tk_55" class="t s5_55">
          •{" "}
        </span>
        <span id="tl_55" class="t s4_55">
          Хөгжлийн түвшин{" "}
        </span>
        <span id="tm_55" class="t s2_55">
          Боловсролын үнэлгээний төвийн цахим хаягт нэвтрэхэд цонхны харагдах{" "}
        </span>
        <span id="tn_55" class="t s2_55">
          байдлыг Зураг 2.3-д үзүүлэв.{" "}
        </span>
        <span id="to_55" class="t s6_55">
          Зураг 2.3. Цахим хуудасны цонхны харагдах байдал{" "}
        </span>
        <span id="tp_55" class="t s2_55">
          Цахим хаягаас “ҮНЭЛГЭЭНИЙ ҮР ДҮН” цонхыг сонгосноор БҮТ-өөс{" "}
        </span>
        <span id="tq_55" class="t s2_55">
          зохион байгуулдаг үнэлгээний бүх үр дүнг харах боломжтой. Эдгээрийг{" "}
        </span>
        <span id="tr_55" class="t s2_55">
          тус бүрд нь нэвтрэх эрхийн зохицуулалттайгаар шийдвэрлэсэн байдгийг{" "}
        </span>
        <span id="ts_55" class="t s2_55">
          Та мэдэх биз ээ.{" "}
        </span>
        <span id="tt_55" class="t s2_55">
          Энэ удаа ЕБС-ийн гүйцэтгэлийн үнэлгээний үр дүнг харах зорилготой тул{" "}
        </span>
        <span id="tu_55" class="t s2_55">
          “БГҮ статистик тайлангийн систем” цонхыг сонгоорой. Зураг 2.4-д
          үзүүлэв.{" "}
        </span>
        <span id="tv_55" class="t s6_55">
          Зураг 2.4. “БГҮ статистик тайлангийн систем” цонхны харагдах байдал{" "}
        </span>
      </div>
    </div>
  );
}
