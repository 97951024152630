import React from "react";
export default function OneHundredSixtyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_164{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_164{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:-1.7px;}
#t3_164{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:3.66px;}
#t4_164{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:2.03px;}
#t5_164{left:87px;bottom:880px;letter-spacing:-0.16px;word-spacing:7.77px;}
#t6_164{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_164{left:87px;bottom:799px;letter-spacing:0.18px;word-spacing:1.16px;}
#t8_164{left:126px;bottom:774px;letter-spacing:-0.05px;word-spacing:0.3px;}
#t9_164{left:87px;bottom:733px;letter-spacing:0.14px;word-spacing:0.04px;}
#ta_164{left:87px;bottom:700px;letter-spacing:-0.14px;word-spacing:7.7px;}
#tb_164{left:87px;bottom:680px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tc_164{left:87px;bottom:647px;letter-spacing:-0.17px;}
#td_164{left:188px;bottom:647px;letter-spacing:-0.15px;}
#te_164{left:306px;bottom:647px;letter-spacing:-0.18px;}
#tf_164{left:370px;bottom:647px;letter-spacing:-0.13px;}
#tg_164{left:474px;bottom:647px;letter-spacing:-0.13px;}
#th_164{left:580px;bottom:647px;letter-spacing:-0.14px;}
#ti_164{left:87px;bottom:627px;letter-spacing:-0.17px;word-spacing:2.33px;}
#tj_164{left:87px;bottom:607px;letter-spacing:-0.17px;word-spacing:4.85px;}
#tk_164{left:87px;bottom:587px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tl_164{left:87px;bottom:553px;letter-spacing:-0.13px;word-spacing:3.03px;}
#tm_164{left:87px;bottom:533px;letter-spacing:-0.13px;word-spacing:3.43px;}
#tn_164{left:87px;bottom:513px;letter-spacing:-0.17px;word-spacing:6.7px;}
#to_164{left:87px;bottom:493px;letter-spacing:-0.19px;word-spacing:7.88px;}
#tp_164{left:87px;bottom:473px;letter-spacing:-0.16px;word-spacing:3.27px;}
#tq_164{left:87px;bottom:453px;letter-spacing:-0.14px;word-spacing:-1.62px;}
#tr_164{left:87px;bottom:432px;letter-spacing:-0.16px;word-spacing:2.94px;}
#ts_164{left:87px;bottom:412px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tt_164{left:87px;bottom:379px;letter-spacing:-0.16px;word-spacing:10.1px;}
#tu_164{left:87px;bottom:359px;letter-spacing:-0.12px;word-spacing:-1.03px;}
#tv_164{left:87px;bottom:339px;letter-spacing:-0.15px;word-spacing:4.25px;}
#tw_164{left:87px;bottom:319px;letter-spacing:-0.17px;word-spacing:-3.55px;}
#tx_164{left:87px;bottom:298px;letter-spacing:-0.14px;word-spacing:4.05px;}
#ty_164{left:87px;bottom:278px;letter-spacing:-0.17px;word-spacing:5.48px;}
#tz_164{left:87px;bottom:258px;letter-spacing:-0.16px;word-spacing:3.63px;}
#t10_164{left:87px;bottom:238px;letter-spacing:-0.66px;word-spacing:4.6px;}
#t11_164{left:87px;bottom:218px;letter-spacing:-0.53px;word-spacing:4.01px;}
#t12_164{left:87px;bottom:198px;letter-spacing:-0.19px;word-spacing:3.88px;}
#t13_164{left:87px;bottom:177px;letter-spacing:-0.2px;word-spacing:5.67px;}
#t14_164{left:87px;bottom:157px;letter-spacing:-0.13px;word-spacing:3.62px;}
#t15_164{left:87px;bottom:137px;letter-spacing:-0.13px;word-spacing:2.31px;}
#t16_164{left:87px;bottom:117px;letter-spacing:-0.14px;}

.s0_164{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_164{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_164{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_164{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}`}
      </style>

      <style id="fonts164" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg164" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="164/164.svg"
          type="image/svg+xml"
          id="pdf164"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_164" class="t s0_164">
          164{" "}
        </span>
        <span id="t2_164" class="t s1_164">
          Салбар дундын болон төсөлд-суурилсан суралцахуйг бодитоор хэрэгжүүлэх{" "}
        </span>
        <span id="t3_164" class="t s1_164">
          үнэ цэнтэй орчинг бий болгохын тулд орон нутгийн олон нийтийг татан{" "}
        </span>
        <span id="t4_164" class="t s1_164">
          оролцуулах, хамтран ажиллах, засаг захиргааны байгууллагуудын тухайн{" "}
        </span>
        <span id="t5_164" class="t s1_164">
          жилийн зорилго, төлөвлөгдсөн ажилтай уялдуулан богино, дунд, урт{" "}
        </span>
        <span id="t6_164" class="t s1_164">
          хугацаанд хэрэгжүүлэх ажлын төлөвлөгөөг гаргана.{" "}
        </span>
        <span id="t7_164" class="t s2_164">
          3.5 СУРГУУЛИЙН МАТЕРИАЛЛАГ НӨӨЦИЙГ{" "}
        </span>
        <span id="t8_164" class="t s2_164">
          БҮРДҮҮЛЭХ АРГАЧЛАЛУУД{" "}
        </span>
        <span id="t9_164" class="t s3_164">
          Сургалтын багаж тоног төхөөрөмжийг хэрхэн бүрдүүлэх вэ?{" "}
        </span>
        <span id="ta_164" class="t s1_164">
          Өөрийн сургуулийн сурагчдын сурах хэрэгцээнд тулгамдсан асуудал,{" "}
        </span>
        <span id="tb_164" class="t s1_164">
          сургуулийн зорилготой нягт уялдаа холбоотой байхад анхаарна.{" "}
        </span>
        <span id="tc_164" class="t s1_164">
          Сургалтын{" "}
        </span>
        <span id="td_164" class="t s1_164">
          материаллаг{" "}
        </span>
        <span id="te_164" class="t s1_164">
          орчин{" "}
        </span>
        <span id="tf_164" class="t s1_164">
          тэдгээрийн{" "}
        </span>
        <span id="tg_164" class="t s1_164">
          ажиллагаа,{" "}
        </span>
        <span id="th_164" class="t s1_164">
          ашиглалтын{" "}
        </span>
        <span id="ti_164" class="t s1_164">
          баталгааг хангахдаа тогтвортой байдлын зарчмуудад нийцүүлэн, хүүхдэд{" "}
        </span>
        <span id="tj_164" class="t s1_164">
          ээлтэй, тодорхой зорилго, ялгаатай суралцахуйд зориулсан, уян хатан,{" "}
        </span>
        <span id="tk_164" class="t s1_164">
          аюулгүй, эрүүл орчинг бүрдүүлэхэд чиглэнэ.{" "}
        </span>
        <span id="tl_164" class="t s1_164">
          Материаллаг нөөцийг бүрдүүлэхдээ олон талаас нь хэрэглэх боломжийг{" "}
        </span>
        <span id="tm_164" class="t s1_164">
          нь харгалзан үзнэ. Жишээ нь: Байгалийн ухааны хичээлүүдээр ашиглах{" "}
        </span>
        <span id="tn_164" class="t s1_164">
          боломжтой сургалт туршилтын байгууламжийг ургамал, амьтны төрөл{" "}
        </span>
        <span id="to_164" class="t s1_164">
          зүйлтэй танилцах, улирлын өөрчлөлт, шавьж, амьтны төрөл зүйлтэй{" "}
        </span>
        <span id="tp_164" class="t s1_164">
          танилцах шийдлийг бодолцож, ногоон байгууламжийг байгуулахдаа өнгө{" "}
        </span>
        <span id="tq_164" class="t s1_164">
          үзэмж, аюулгүй байдлыг хангахын зэрэгцээ сургалтад ашиглаж болохуйцаар{" "}
        </span>
        <span id="tr_164" class="t s1_164">
          төлөвлөж гадаад орчны ногоон байгууламж, сургалт туршилтын талбайн{" "}
        </span>
        <span id="ts_164" class="t s1_164">
          асуудлыг хамт шийдвэрлэнэ.{" "}
        </span>
        <span id="tt_164" class="t s1_164">
          Ерөнхий боловсролын сургуулийн сургалтын хөтөлбөрт{" "}
        </span>
        <span id="tu_164" class="t s1_164">
          дэмжлэг үзүүлэх, хичээл сургалтын үйл ажиллагааг үр дүнтэй{" "}
        </span>
        <span id="tv_164" class="t s1_164">
          зохион байгуулах боломж нөхцөлийг бүрдүүлэх зорилгоор{" "}
        </span>
        <span id="tw_164" class="t s1_164">
          сургалтын хэрэглэгдэхүүнийг боловсруулах, сонгоход тавигдах{" "}
        </span>
        <span id="tx_164" class="t s1_164">
          шаардлагыг үндэслэн “Сургалтын хэрэглэгдэхүүний жишиг{" "}
        </span>
        <span id="ty_164" class="t s1_164">
          жагсаалт” батлагдан гарсан. Mlab 1.0 цахим{" "}
        </span>
        <span id="tz_164" class="t s1_164">
          бүртгэлийн системд өөрийн сургуульд байгаа{" "}
        </span>
        <span id="t10_164" class="t s1_164">
          хэрэглэгдэхүүний бүртгэл үүсгэснээр шаардлага{" "}
        </span>
        <span id="t11_164" class="t s1_164">
          хангасан, хангаагүй, засварлах, эд анги сэлбэх{" "}
        </span>
        <span id="t12_164" class="t s1_164">
          шаардлагатай, нэмэлтээр авах шаардлагатай{" "}
        </span>
        <span id="t13_164" class="t s1_164">
          кабинет, лабораторийн хэрэглэгдэхүүн, тоног{" "}
        </span>
        <span id="t14_164" class="t s1_164">
          төхөөрөмжийг сургуулийн захирал, сургалтын{" "}
        </span>
        <span id="t15_164" class="t s1_164">
          менежер, санхүүгийн ажилтан, багш танилцах{" "}
        </span>
        <span id="t16_164" class="t s1_164">
          боломжтой.{" "}
        </span>
      </div>
    </div>
  );
}
