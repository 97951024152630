import React from "react";
export default function F139() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_139{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_139{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:2.92px;}
#t3_139{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:1.89px;}
#t4_139{left:87px;bottom:901px;letter-spacing:-0.12px;word-spacing:1.95px;}
#t5_139{left:87px;bottom:880px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_139{left:114px;bottom:847px;}
#t7_139{left:142px;bottom:847px;letter-spacing:-0.14px;}
#t8_139{left:142px;bottom:827px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_139{left:114px;bottom:807px;}
#ta_139{left:142px;bottom:807px;letter-spacing:-0.13px;word-spacing:-0.81px;}
#tb_139{left:142px;bottom:787px;letter-spacing:-0.17px;word-spacing:2.14px;}
#tc_139{left:142px;bottom:767px;letter-spacing:-0.13px;}
#td_139{left:114px;bottom:746px;}
#te_139{left:142px;bottom:746px;letter-spacing:-0.13px;word-spacing:1.66px;}
#tf_139{left:142px;bottom:726px;letter-spacing:-0.15px;word-spacing:1.19px;}
#tg_139{left:142px;bottom:706px;letter-spacing:-0.19px;}
#th_139{left:114px;bottom:686px;}
#ti_139{left:142px;bottom:686px;letter-spacing:-0.2px;word-spacing:1.5px;}
#tj_139{left:142px;bottom:666px;letter-spacing:-0.17px;word-spacing:2.05px;}
#tk_139{left:142px;bottom:646px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tl_139{left:114px;bottom:625px;}
#tm_139{left:142px;bottom:625px;letter-spacing:-0.17px;word-spacing:6.56px;}
#tn_139{left:142px;bottom:605px;letter-spacing:-0.15px;word-spacing:5.88px;}
#to_139{left:142px;bottom:585px;letter-spacing:-0.1px;}
#tp_139{left:114px;bottom:565px;}
#tq_139{left:142px;bottom:565px;letter-spacing:-0.15px;word-spacing:7.67px;}
#tr_139{left:142px;bottom:545px;letter-spacing:-0.17px;word-spacing:3.43px;}
#ts_139{left:142px;bottom:525px;letter-spacing:-0.15px;word-spacing:1.57px;}
#tt_139{left:142px;bottom:504px;letter-spacing:-0.16px;word-spacing:4.43px;}
#tu_139{left:142px;bottom:484px;letter-spacing:-0.14px;word-spacing:0.76px;}
#tv_139{left:142px;bottom:464px;letter-spacing:-0.15px;word-spacing:3.31px;}
#tw_139{left:142px;bottom:444px;letter-spacing:-0.16px;word-spacing:6.32px;}
#tx_139{left:142px;bottom:424px;letter-spacing:-0.14px;word-spacing:-0.1px;}
#ty_139{left:142px;bottom:404px;letter-spacing:-0.13px;word-spacing:2.22px;}
#tz_139{left:142px;bottom:383px;letter-spacing:-0.15px;word-spacing:4.42px;}
#t10_139{left:142px;bottom:363px;letter-spacing:-0.15px;word-spacing:11.95px;}
#t11_139{left:142px;bottom:343px;letter-spacing:-0.13px;word-spacing:2.24px;}
#t12_139{left:142px;bottom:323px;letter-spacing:-0.12px;word-spacing:2.02px;}
#t13_139{left:142px;bottom:303px;letter-spacing:-0.12px;word-spacing:2.45px;}
#t14_139{left:142px;bottom:283px;letter-spacing:-0.13px;word-spacing:4.78px;}
#t15_139{left:142px;bottom:262px;letter-spacing:-0.12px;word-spacing:-0.24px;}
#t16_139{left:142px;bottom:242px;letter-spacing:-0.17px;word-spacing:7.43px;}
#t17_139{left:142px;bottom:222px;letter-spacing:-0.12px;word-spacing:5.41px;}
#t18_139{left:142px;bottom:202px;letter-spacing:-0.13px;word-spacing:4.81px;}
#t19_139{left:142px;bottom:182px;letter-spacing:-0.09px;}
#t1a_139{left:305px;bottom:182px;letter-spacing:-0.17px;word-spacing:0.08px;}

.s0_139{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_139{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_139{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts139" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg139" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="139/139.svg"
          type="image/svg+xml"
          id="pdf139"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_139" class="t s0_139">
          139{" "}
        </span>
        <span id="t2_139" class="t s1_139">
          багштай болохын тулд багшийн хөгжлийн сургалт, үйл ажиллагааг түүнд{" "}
        </span>
        <span id="t3_139" class="t s1_139">
          чиглүүлэн төлөвлөх, ингэхдээ ганцхан өдрөөр бус жилийн туршид хичээл{" "}
        </span>
        <span id="t4_139" class="t s1_139">
          сургалтын үйл ажиллагаанд шингээн хэрэгжүүлэх, үр дүнг тооцох зэргийг{" "}
        </span>
        <span id="t5_139" class="t s1_139">
          дараах байдлаар төлөвлөлтөд тусгах нь зүйтэй. Үүнд:{" "}
        </span>
        <span id="t6_139" class="t s2_139">
          -{" "}
        </span>
        <span id="t7_139" class="t s1_139">
          Сурагчдын бүтээлч сэтгэлгээг хөгжүүлэхэд чиглэсэн үйл ажиллагааг{" "}
        </span>
        <span id="t8_139" class="t s1_139">
          хариуцан зохион байгуулалт хийх МХБ-ыг байгуулах{" "}
        </span>
        <span id="t9_139" class="t s2_139">
          -{" "}
        </span>
        <span id="ta_139" class="t s1_139">
          Хичээлийн жилийн эхэнд сурагчдын бүтээлч сэтгэлгээ ямар түвшинд{" "}
        </span>
        <span id="tb_139" class="t s1_139">
          байгааг илрүүлэх асуулга, даалгавар, судалгаа авч, суурь түвшинг{" "}
        </span>
        <span id="tc_139" class="t s1_139">
          илрүүлэх{" "}
        </span>
        <span id="td_139" class="t s2_139">
          -{" "}
        </span>
        <span id="te_139" class="t s1_139">
          Үр дүнд шинжилгээ хийж, сургуулийн жилийн нэгдсэн төлөвлөлтөд{" "}
        </span>
        <span id="tf_139" class="t s1_139">
          бүтээлч сэтгэлгээ хөгжүүлэх үйл ажиллагаанууд төлөвлөх, шалгуур{" "}
        </span>
        <span id="tg_139" class="t s1_139">
          боловсруулах{" "}
        </span>
        <span id="th_139" class="t s2_139">
          -{" "}
        </span>
        <span id="ti_139" class="t s1_139">
          Бүтээлч сэтгэлгээг хөгжүүлэхэд нийт багшид шаардлагатай мэдлэг,{" "}
        </span>
        <span id="tj_139" class="t s1_139">
          ур чадварыг хөгжүүлэх сургалт, үйл ажиллагааг багшийн хөгжлийн{" "}
        </span>
        <span id="tk_139" class="t s1_139">
          төлөвлөгөөнд тусгах{" "}
        </span>
        <span id="tl_139" class="t s2_139">
          -{" "}
        </span>
        <span id="tm_139" class="t s1_139">
          Тус МХБ нь бүтээлч сэтгэлгээ хөгжүүлэх үйл ажиллагаануудыг{" "}
        </span>
        <span id="tn_139" class="t s1_139">
          хариуцан зохион байгуулж, төлөвлөгөөг хэрэгжүүлэх, багш нарт{" "}
        </span>
        <span id="to_139" class="t s1_139">
          дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tp_139" class="t s2_139">
          -{" "}
        </span>
        <span id="tq_139" class="t s1_139">
          Бүтээлч сэтгэлгээ хөгжүүлэх үйл ажиллагааны үр дүнг үнэлж,{" "}
        </span>
        <span id="tr_139" class="t s1_139">
          тайлан тавих. Жишээлбэл, нэгдүгээр улиралд багш нарт зориулж{" "}
        </span>
        <span id="ts_139" class="t s1_139">
          “Бүтээлч сэтгэлгээ гэж юу вэ? Бүтээлч сэтгэлгээг хэрхэн хөгжүүлэх{" "}
        </span>
        <span id="tt_139" class="t s1_139">
          вэ? гэх мэтчилэн онолын сургалт төлөвлөх, хоёрдугаар улиралд{" "}
        </span>
        <span id="tu_139" class="t s1_139">
          ЗАН бүр бүтээлч сэтгэлгээг хөгжүүлж буй нэг жишиг хичээл бэлтгэн{" "}
        </span>
        <span id="tv_139" class="t s1_139">
          хичээлийн судалгаа хийж, хөтөлбөрөө энэ чиглэлээр сайжруулах,{" "}
        </span>
        <span id="tw_139" class="t s1_139">
          үр дүнгээр хэлэлцүүлэг зохион байгуулах, гуравдугаар улиралд{" "}
        </span>
        <span id="tx_139" class="t s1_139">
          судлагдахуун хооронд 2-3 хичээлийн интеграц бүхий бүтээлч гэрийн{" "}
        </span>
        <span id="ty_139" class="t s1_139">
          даалгавар сурагчдад өгч, нэг гэрийн даалгавраар тэдгээр хичээлд{" "}
        </span>
        <span id="tz_139" class="t s1_139">
          үнэлгээ тооцох, дөрөвдүгээр улиралд багш сурагчдын бүтээлийн{" "}
        </span>
        <span id="t10_139" class="t s1_139">
          үзэсгэлэн гаргах зэргээр сургуулийн зорилгыг хэрэгжүүлэхэд{" "}
        </span>
        <span id="t11_139" class="t s1_139">
          багшийн хөгжлийг чиглүүлсэн төлөвлөлтөөр харилцан дэмжвэл үр{" "}
        </span>
        <span id="t12_139" class="t s1_139">
          дүнтэй. Түүнчлэн ХГҮА-д бүтээлч хандлагыг дэмжихүйц өөр бусад{" "}
        </span>
        <span id="t13_139" class="t s1_139">
          үйл ажиллагааг төлөвлөх, түүнийг дэмжих орчныг бүрдүүлэх, эцэг{" "}
        </span>
        <span id="t14_139" class="t s1_139">
          эх, асран хамгаалагч, харгалзан дэмжигчийг оролцуулах зэргээр{" "}
        </span>
        <span id="t15_139" class="t s1_139">
          сургуулийн зорилгыг бүрэн утгаар нь хэрэгжүүлэх цөөн тооны ажлыг{" "}
        </span>
        <span id="t16_139" class="t s1_139">
          харилцан холбоотой бүхий тодорхой хэдэн үйл ажиллагаагаар{" "}
        </span>
        <span id="t17_139" class="t s1_139">
          нэгтгэн зангидаж, үр дүнгээ тооцох хяналт шинжилгээ, үнэлгээг{" "}
        </span>
        <span id="t18_139" class="t s1_139">
          хэрэгжүүлж, засан сайжруулж явах нь сургуулийн менежментийг{" "}
        </span>
        <span id="t19_139" class="t s1_139">
          хэрэгжүүлэх үндсэн{" "}
        </span>
        <span id="t1a_139" class="t s1_139">
          зорилго юм.{" "}
        </span>
      </div>
    </div>
  );
}
