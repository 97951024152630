import React from "react";
export default function FourtyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_43{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_43{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_43{left:133px;bottom:806px;}
#t4_43{left:242px;bottom:868px;letter-spacing:-0.42px;}
#t5_43{left:445px;bottom:868px;letter-spacing:-0.26px;}
#t6_43{left:242px;bottom:835px;letter-spacing:-0.33px;}
#t7_43{left:242px;bottom:802px;letter-spacing:-0.84px;}
#t8_43{left:278px;bottom:691px;letter-spacing:0.35px;word-spacing:-0.29px;}
#t9_43{left:171px;bottom:538px;letter-spacing:0.07px;}
#ta_43{left:169px;bottom:490px;}
#tb_43{left:182px;bottom:490px;letter-spacing:-0.17px;word-spacing:0.01px;}
#tc_43{left:169px;bottom:477px;}
#td_43{left:182px;bottom:477px;letter-spacing:-0.23px;word-spacing:0.08px;}
#te_43{left:169px;bottom:464px;}
#tf_43{left:182px;bottom:464px;letter-spacing:-0.19px;word-spacing:0.04px;}
#tg_43{left:169px;bottom:451px;letter-spacing:-0.18px;word-spacing:3.16px;}
#th_43{left:169px;bottom:438px;letter-spacing:-0.15px;word-spacing:6.26px;}
#ti_43{left:169px;bottom:426px;letter-spacing:-0.22px;word-spacing:3.19px;}
#tj_43{left:414px;bottom:536px;letter-spacing:0.24px;word-spacing:0.05px;}
#tk_43{left:414px;bottom:522px;letter-spacing:0.27px;word-spacing:0.03px;}
#tl_43{left:411px;bottom:488px;letter-spacing:-0.15px;word-spacing:3.14px;}
#tm_43{left:424px;bottom:475px;letter-spacing:-0.16px;}
#tn_43{left:411px;bottom:462px;letter-spacing:-0.17px;word-spacing:3.14px;}
#to_43{left:411px;bottom:450px;}
#tp_43{left:424px;bottom:450px;letter-spacing:-0.16px;word-spacing:-0.01px;}
#tq_43{left:450px;bottom:605px;}
#tr_43{left:166px;bottom:604px;}
#ts_43{left:82px;bottom:240px;letter-spacing:0.03px;}
#tt_43{left:82px;bottom:226px;letter-spacing:0.04px;}
#tu_43{left:80px;bottom:189px;letter-spacing:-0.19px;word-spacing:3.17px;}
#tv_43{left:80px;bottom:177px;letter-spacing:-0.18px;word-spacing:3.16px;}
#tw_43{left:80px;bottom:164px;}
#tx_43{left:93px;bottom:164px;letter-spacing:-0.17px;word-spacing:0.02px;}
#ty_43{left:93px;bottom:151px;letter-spacing:-0.18px;word-spacing:0.03px;}
#tz_43{left:93px;bottom:138px;letter-spacing:-0.15px;}
#t10_43{left:115px;bottom:305px;}
#t11_43{left:293px;bottom:189px;letter-spacing:-0.19px;word-spacing:3.17px;}
#t12_43{left:293px;bottom:177px;letter-spacing:-0.18px;word-spacing:3.16px;}
#t13_43{left:293px;bottom:164px;}
#t14_43{left:306px;bottom:164px;letter-spacing:-0.17px;word-spacing:0.02px;}
#t15_43{left:306px;bottom:151px;letter-spacing:-0.19px;word-spacing:0.04px;}
#t16_43{left:306px;bottom:138px;letter-spacing:-0.15px;}
#t17_43{left:292px;bottom:240px;letter-spacing:0.07px;}
#t18_43{left:292px;bottom:226px;letter-spacing:0.11px;}
#t19_43{left:324px;bottom:302px;}
#t1a_43{left:511px;bottom:189px;letter-spacing:-0.21px;word-spacing:3.19px;}
#t1b_43{left:511px;bottom:177px;letter-spacing:-0.22px;word-spacing:3.2px;}
#t1c_43{left:511px;bottom:164px;letter-spacing:-0.17px;word-spacing:3.15px;}
#t1d_43{left:511px;bottom:240px;}
#t1e_43{left:511px;bottom:226px;letter-spacing:-0.02px;}
#t1f_43{left:536px;bottom:306px;}

.s0_43{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_43{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_43{font-size:76px;font-family:Gotham-Light_17v;color:#000;}
.s3_43{font-size:28px;font-family:Arial-BoldMT_1li;color:#6D3A96;}
.s4_43{font-size:24px;font-family:Arial-BoldMT_1li;color:#EE3A81;}
.s5_43{font-size:12px;font-family:MogulArial-Bold_179;color:#910082;}
.s6_43{font-size:11px;font-family:MogulArial_16s;color:#000;}
.s7_43{font-size:13px;font-family:MogulArial-Bold_179;color:#EE3A81;}
.s8_43{font-size:40px;font-family:Gotham-Bold_185;color:#FFF;}
.s9_43{font-size:12px;font-family:MogulArial-Bold_179;color:#F05522;}
.sa_43{font-size:12px;font-family:MogulArial-Bold_179;color:#FFA600;}
.sb_43{font-size:12px;font-family:MogulArial-Bold_179;color:#4E8EC3;}
.t.v0_43{transform:scaleX(0.969);}
.t.v1_43{transform:scaleX(0.909);}
.t.m0_43{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts43" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Gotham-Bold_185;
	src: url("fonts/Gotham-Bold_185.woff") format("woff");
}

@font-face {
	font-family: Gotham-Light_17v;
	src: url("fonts/Gotham-Light_17v.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg43" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="43/43.svg"
          type="image/svg+xml"
          id="pdf43"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_43" class="t s1_43">
          43{" "}
        </span>
        <span id="t3_43" class="t v0_43 s2_43">
          2{" "}
        </span>
        <span id="t4_43" class="t s3_43">
          ҮНЭЛГЭЭНИЙ{" "}
        </span>
        <span id="t5_43" class="t s3_43">
          ҮР ДҮНД{" "}
        </span>
        <span id="t6_43" class="t s3_43">
          ШИНЖИЛГЭЭ ХИЙХ, ШИЙДВЭР{" "}
        </span>
        <span id="t7_43" class="t s3_43">
          ГАРГАЛТ{" "}
        </span>
        <span id="t8_43" class="t v1_43 s4_43">
          II БҮЛГИЙН ТОЙМ{" "}
        </span>
        <span id="t9_43" class="t s5_43">
          ҮНЭЛГЭЭ{" "}
        </span>
        <span id="ta_43" class="t s6_43">
          •{" "}
        </span>
        <span id="tb_43" class="t s6_43">
          Өөрийн болон хөндлөнгийн үнэлгээ{" "}
        </span>
        <span id="tc_43" class="t s6_43">
          •{" "}
        </span>
        <span id="td_43" class="t s6_43">
          Үнэлгээний ач холбогдол{" "}
        </span>
        <span id="te_43" class="t s6_43">
          •{" "}
        </span>
        <span id="tf_43" class="t s6_43">
          Үнэлгээний арга хэрэгсэл{" "}
        </span>
        <span id="tg_43" class="t s6_43">
          • Шалгуур үзүүлэлт{" "}
        </span>
        <span id="th_43" class="t s6_43">
          • Индекс{" "}
        </span>
        <span id="ti_43" class="t s6_43">
          • Урамшууллын хувь{" "}
        </span>
        <span id="tj_43" class="t v1_43 s7_43">
          ГҮЙЦЭТГЭЛИЙН ҮНЭЛГЭЭНИЙ{" "}
        </span>
        <span id="tk_43" class="t v1_43 s7_43">
          СИСТЕМД ХАНДАХ{" "}
        </span>
        <span id="tl_43" class="t s6_43">
          • Гүйцэтгэлийн системд{" "}
        </span>
        <span id="tm_43" class="t s6_43">
          нэвтрэх{" "}
        </span>
        <span id="tn_43" class="t s6_43">
          • Хандах цонх{" "}
        </span>
        <span id="to_43" class="t s6_43">
          •{" "}
        </span>
        <span id="tp_43" class="t s6_43">
          Мэдээлэл авах боломжууд{" "}
        </span>
        <span id="tq_43" class="t v0_43 s8_43">
          2{" "}
        </span>
        <span id="tr_43" class="t v0_43 s8_43">
          1{" "}
        </span>
        <span id="ts_43" class="t s9_43">
          МЭДЭЭЛЭЛТЭЙ{" "}
        </span>
        <span id="tt_43" class="t s9_43">
          ТАНИЛЦАХ{" "}
        </span>
        <span id="tu_43" class="t s6_43">
          • Сургуулийн үнэлгээ{" "}
        </span>
        <span id="tv_43" class="t s6_43">
          • Багшийн үнэлгээ{" "}
        </span>
        <span id="tw_43" class="t s6_43">
          •{" "}
        </span>
        <span id="tx_43" class="t s6_43">
          Шалгалтын үр дүн{" "}
        </span>
        <span id="ty_43" class="t s6_43">
          (судлагдахуунаар, түвшнээр,{" "}
        </span>
        <span id="tz_43" class="t s6_43">
          ангиар){" "}
        </span>
        <span id="t10_43" class="t v0_43 s8_43">
          3{" "}
        </span>
        <span id="t11_43" class="t s6_43">
          • Сургуулийн үнэлгээ{" "}
        </span>
        <span id="t12_43" class="t s6_43">
          • Багшийн үнэлгээ{" "}
        </span>
        <span id="t13_43" class="t s6_43">
          •{" "}
        </span>
        <span id="t14_43" class="t s6_43">
          Шалгалтын үр дүн{" "}
        </span>
        <span id="t15_43" class="t s6_43">
          (судлагдахуунаар, багшаар,{" "}
        </span>
        <span id="t16_43" class="t s6_43">
          ангиар){" "}
        </span>
        <span id="t17_43" class="t sa_43">
          МЭДЭЭЛЛИЙГ{" "}
        </span>
        <span id="t18_43" class="t sa_43">
          АШИГЛАХ{" "}
        </span>
        <span id="t19_43" class="t v0_43 s8_43">
          4{" "}
        </span>
        <span id="t1a_43" class="t s6_43">
          • Асуудлаа илруулэх{" "}
        </span>
        <span id="t1b_43" class="t s6_43">
          • Асуудлаа эрэмбэлэх{" "}
        </span>
        <span id="t1c_43" class="t s6_43">
          • Анхаарах зүйл{" "}
        </span>
        <span id="t1d_43" class="t sb_43">
          АСУУДЛАА{" "}
        </span>
        <span id="t1e_43" class="t sb_43">
          ТОДОРХОЙЛОХ{" "}
        </span>
        <span id="t1f_43" class="t v0_43 s8_43">
          5{" "}
        </span>
      </div>
    </div>
  );
}
