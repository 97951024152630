import React from "react";
export default function Thirteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_13{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_13{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_13{left:142px;bottom:759px;}
#t4_13{left:242px;bottom:821px;letter-spacing:-0.55px;word-spacing:0.25px;}
#t5_13{left:242px;bottom:788px;letter-spacing:-0.47px;word-spacing:0.14px;}
#t6_13{left:242px;bottom:755px;letter-spacing:-0.39px;word-spacing:0.06px;}
#t7_13{left:281px;bottom:586px;letter-spacing:0.32px;word-spacing:-0.27px;}
#t8_13{left:153px;bottom:325px;letter-spacing:0.03px;word-spacing:0.1px;}
#t9_13{left:153px;bottom:310px;}
#ta_13{left:151px;bottom:276px;}
#tb_13{left:164px;bottom:276px;letter-spacing:-0.17px;word-spacing:0.02px;}
#tc_13{left:151px;bottom:263px;}
#td_13{left:164px;bottom:263px;letter-spacing:-0.2px;word-spacing:0.05px;}
#te_13{left:164px;bottom:251px;letter-spacing:-0.19px;word-spacing:0.04px;}
#tf_13{left:151px;bottom:238px;}
#tg_13{left:164px;bottom:238px;letter-spacing:-0.19px;word-spacing:0.04px;}
#th_13{left:151px;bottom:225px;}
#ti_13{left:164px;bottom:225px;letter-spacing:-0.17px;word-spacing:0.02px;}
#tj_13{left:164px;bottom:212px;letter-spacing:-0.16px;}
#tk_13{left:207px;bottom:459px;}
#tl_13{left:426px;bottom:324px;letter-spacing:0.27px;word-spacing:0.04px;}
#tm_13{left:426px;bottom:310px;letter-spacing:0.22px;word-spacing:0.06px;}
#tn_13{left:424px;bottom:276px;letter-spacing:-0.18px;word-spacing:3.16px;}
#to_13{left:437px;bottom:263px;letter-spacing:-0.19px;word-spacing:0.05px;}
#tp_13{left:424px;bottom:251px;}
#tq_13{left:437px;bottom:251px;letter-spacing:-0.19px;word-spacing:0.04px;}
#tr_13{left:437px;bottom:238px;letter-spacing:-0.19px;}
#ts_13{left:424px;bottom:225px;}
#tt_13{left:437px;bottom:225px;letter-spacing:-0.19px;word-spacing:0.03px;}
#tu_13{left:437px;bottom:212px;letter-spacing:-0.17px;}
#tv_13{left:437px;bottom:199px;letter-spacing:-0.21px;word-spacing:0.05px;}
#tw_13{left:437px;bottom:186px;letter-spacing:-0.23px;}
#tx_13{left:484px;bottom:479px;}

.s0_13{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_13{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_13{font-size:76px;font-family:Gotham-Light_17v;color:#000;}
.s3_13{font-size:28px;font-family:Arial-BoldMT_1li;color:#1D9FA6;}
.s4_13{font-size:24px;font-family:Arial-BoldMT_1li;color:#3AB5C9;}
.s5_13{font-size:12px;font-family:MogulArial-Bold_179;color:#910082;}
.s6_13{font-size:11px;font-family:MogulArial_16s;color:#000;}
.s7_13{font-size:40px;font-family:Gotham-Bold_185;color:#FFF;}
.s8_13{font-size:13px;font-family:MogulArial-Bold_179;color:#EE3A81;}
.t.v0_13{transform:scaleX(0.969);}
.t.v1_13{transform:scaleX(0.909);}
.t.m0_13{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts13" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Gotham-Bold_185;
	src: url("fonts/Gotham-Bold_185.woff") format("woff");
}

@font-face {
	font-family: Gotham-Light_17v;
	src: url("fonts/Gotham-Light_17v.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg13" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="13/13.svg"
          type="image/svg+xml"
          id="pdf13"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_13" class="t s1_13">
          13{" "}
        </span>
        <span id="t3_13" class="t v0_13 s2_13">
          1{" "}
        </span>
        <span id="t4_13" class="t s3_13">
          СУРАГЧ ТӨВТЭЙ СУРГУУЛИЙН{" "}
        </span>
        <span id="t5_13" class="t s3_13">
          МЕНЕЖМЕНТИЙГ ХЭРХЭН{" "}
        </span>
        <span id="t6_13" class="t s3_13">
          ХӨГЖҮҮЛЭХ ВЭ?{" "}
        </span>
        <span id="t7_13" class="t v1_13 s4_13">
          I БҮЛГИЙН ТОЙМ{" "}
        </span>
        <span id="t8_13" class="t s5_13">
          ХУВЬСАЖ БУЙ{" "}
        </span>
        <span id="t9_13" class="t s5_13">
          БОЛОВСРОЛ{" "}
        </span>
        <span id="ta_13" class="t s6_13">
          •{" "}
        </span>
        <span id="tb_13" class="t s6_13">
          Сурагчид юу хүсч байна вэ?{" "}
        </span>
        <span id="tc_13" class="t s6_13">
          •{" "}
        </span>
        <span id="td_13" class="t s6_13">
          Сургууль хэрхэн өөрчлөгдөж{" "}
        </span>
        <span id="te_13" class="t s6_13">
          байна вэ?{" "}
        </span>
        <span id="tf_13" class="t s6_13">
          •{" "}
        </span>
        <span id="tg_13" class="t s6_13">
          Багш хэрхэн өөрчлөгдөх вэ?{" "}
        </span>
        <span id="th_13" class="t s6_13">
          •{" "}
        </span>
        <span id="ti_13" class="t s6_13">
          Сургуулийн захирал юу хийх{" "}
        </span>
        <span id="tj_13" class="t s6_13">
          вэ?{" "}
        </span>
        <span id="tk_13" class="t v0_13 s7_13">
          1{" "}
        </span>
        <span id="tl_13" class="t v1_13 s8_13">
          СУРГУУЛИЙН МЕНЕЖМЕНТИЙГ{" "}
        </span>
        <span id="tm_13" class="t v1_13 s8_13">
          ҮР ДҮНТЭЙ ХЭРЭГЖҮҮЛЭХ ЗАГВАР{" "}
        </span>
        <span id="tn_13" class="t s6_13">
          • Сургуулийн менежментийн{" "}
        </span>
        <span id="to_13" class="t s6_13">
          гурван давхаргат загвар{" "}
        </span>
        <span id="tp_13" class="t s6_13">
          •{" "}
        </span>
        <span id="tq_13" class="t s6_13">
          Сургуулиа хувирган өөрчлөх{" "}
        </span>
        <span id="tr_13" class="t s6_13">
          алхмууд{" "}
        </span>
        <span id="ts_13" class="t s6_13">
          •{" "}
        </span>
        <span id="tt_13" class="t s6_13">
          Алсын хараа тодорхойлох{" "}
        </span>
        <span id="tu_13" class="t s6_13">
          Менежментийн жилийн{" "}
        </span>
        <span id="tv_13" class="t s6_13">
          зураглал боловсруулах{" "}
        </span>
        <span id="tw_13" class="t s6_13">
          аргачлал{" "}
        </span>
        <span id="tx_13" class="t v0_13 s7_13">
          2{" "}
        </span>
      </div>
    </div>
  );
}
