import React from "react";
export default function OneHundredSeventyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_174{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_174{left:106px;bottom:923px;letter-spacing:0.09px;word-spacing:9.39px;}
#t3_174{left:106px;bottom:905px;letter-spacing:0.1px;word-spacing:5.76px;}
#t4_174{left:106px;bottom:887px;letter-spacing:0.13px;word-spacing:2.42px;}
#t5_174{left:106px;bottom:868px;letter-spacing:0.11px;word-spacing:0.04px;}
#t6_174{left:108px;bottom:840px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t7_174{left:508px;bottom:821px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t8_174{left:106px;bottom:750px;letter-spacing:0.13px;word-spacing:-0.54px;}
#t9_174{left:106px;bottom:732px;letter-spacing:0.11px;word-spacing:4.31px;}
#ta_174{left:106px;bottom:714px;letter-spacing:0.11px;word-spacing:4.02px;}
#tb_174{left:106px;bottom:695px;letter-spacing:0.1px;word-spacing:0.04px;}
#tc_174{left:108px;bottom:666px;letter-spacing:-0.15px;word-spacing:0.03px;}
#td_174{left:508px;bottom:650px;letter-spacing:-0.14px;word-spacing:0.01px;}
#te_174{left:106px;bottom:599px;letter-spacing:0.11px;word-spacing:0.14px;}
#tf_174{left:106px;bottom:580px;letter-spacing:0.12px;word-spacing:3.74px;}
#tg_174{left:106px;bottom:562px;letter-spacing:0.09px;word-spacing:-1.04px;}
#th_174{left:106px;bottom:544px;letter-spacing:0.1px;word-spacing:0.04px;}
#ti_174{left:106px;bottom:512px;letter-spacing:0.1px;word-spacing:4.6px;}
#tj_174{left:106px;bottom:494px;letter-spacing:0.08px;word-spacing:0.05px;}
#tk_174{left:187px;bottom:143px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tl_174{left:429px;bottom:125px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_174{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_174{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_174{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_174{font-size:15px;font-family:MogulArial_16s;color:#202124;}`}
      </style>

      <style id="fonts174" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg174" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="174/174.svg"
          type="image/svg+xml"
          id="pdf174"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_174" class="t s0_174">
          174{" "}
        </span>
        <span id="t2_174" class="t s1_174">
          “Сургууль, дотуур байрны орчинд сурагчийн үе тэнгийнхний дарамт,{" "}
        </span>
        <span id="t3_174" class="t s1_174">
          ялгаварлан гадуурхалтад өртөхөөс урьдчилан сэргийлэх, хамгаалах үйл{" "}
        </span>
        <span id="t4_174" class="t s1_174">
          ажиллагааны 2023 төлөвлөгөө” – г шинэчилсэн. Дотуур байрны сурагчдын{" "}
        </span>
        <span id="t5_174" class="t s1_174">
          дунд ҮҮРЭГ АМЛАЛТЫН ДЭВТЭР аялуулж байгаа нь үр дүнгээ өгч байна.{" "}
        </span>
        <span id="t6_174" class="t s2_174">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг
          сайжруулах{" "}
        </span>
        <span id="t7_174" class="t s2_174">
          хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t8_174" class="t s1_174">
          Сумын Засаг даргын 2023 оны 5р сарын 1-ний өдрийн А/31 тоот
          захирамжаар{" "}
        </span>
        <span id="t9_174" class="t s1_174">
          Сургуулийн захирлаар ахлуулсан “Сургуулийн 2024 – 2028 оны стратеги{" "}
        </span>
        <span id="ta_174" class="t s1_174">
          төлөвлөгөө боловсруулах ажлын хэсэг” – ийг байгуулан 1 – р үе шатны{" "}
        </span>
        <span id="tb_174" class="t s1_174">
          төлөвлөгөө (SWOT шинжилгээ хийх) гарган хэрэгжүүлж эхлээд байна.{" "}
        </span>
        <span id="tc_174" class="t s2_174">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг
          сайжруулах{" "}
        </span>
        <span id="td_174" class="t s2_174">
          хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="te_174" class="t s3_174">
          Ирэх хичээлийн жилд бид бүхэн удирдлагын манлайлал, үйл ажиллагаандаа{" "}
        </span>
        <span id="tf_174" class="t s3_174">
          дүгнэлт хийж, манлайллаа дээшлүүлэх, удирдлагын хөгжлийн төлөвлөгөө{" "}
        </span>
        <span id="tg_174" class="t s3_174">
          боловсруулан ажиллахад багш, ажилтнуудын саналын авч тусган ажиллахаар{" "}
        </span>
        <span id="th_174" class="t s3_174">
          төлөвлөж байна. Бид санал асуулгын дараах хуудсыг боловсруулав.{" "}
        </span>
        <span id="ti_174" class="t s3_174">
          Бид таны санал хамгаас чухал тул товч, тодорхой илэрхийлж, идэвхтэй{" "}
        </span>
        <span id="tj_174" class="t s3_174">
          оролцоно уу!{" "}
        </span>
        <span id="tk_174" class="t s2_174">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tl_174" class="t s2_174">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
