import React from "react";
export default function SeventyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_78{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_78{left:87px;bottom:941px;letter-spacing:-0.02px;word-spacing:6.37px;}
#t3_78{left:87px;bottom:921px;letter-spacing:0.01px;word-spacing:-1.47px;}
#t4_78{left:87px;bottom:901px;letter-spacing:0.04px;word-spacing:0.97px;}
#t5_78{left:87px;bottom:880px;letter-spacing:-0.01px;word-spacing:7.54px;}
#t6_78{left:87px;bottom:860px;word-spacing:5.64px;}
#t7_78{left:87px;bottom:840px;letter-spacing:-0.13px;word-spacing:4.45px;}
#t8_78{left:87px;bottom:820px;letter-spacing:-0.29px;word-spacing:0.2px;}
#t9_78{left:87px;bottom:787px;letter-spacing:-0.16px;word-spacing:-1.15px;}
#ta_78{left:87px;bottom:767px;letter-spacing:-0.16px;word-spacing:-1.11px;}
#tb_78{left:451px;bottom:767px;letter-spacing:-0.1px;word-spacing:-1.18px;}
#tc_78{left:87px;bottom:746px;letter-spacing:-0.18px;word-spacing:-1.64px;}
#td_78{left:87px;bottom:726px;letter-spacing:-0.24px;word-spacing:0.15px;}
#te_78{left:281px;bottom:691px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tf_78{left:329px;bottom:541px;letter-spacing:-0.07px;}
#tg_78{left:360px;bottom:518px;letter-spacing:-0.21px;}
#th_78{left:344px;bottom:636px;letter-spacing:0.16px;word-spacing:-0.02px;}
#ti_78{left:351px;bottom:617px;letter-spacing:0.14px;}
#tj_78{left:369px;bottom:599px;letter-spacing:0.06px;}
#tk_78{left:469px;bottom:537px;letter-spacing:0.08px;}
#tl_78{left:507px;bottom:519px;letter-spacing:0.06px;}
#tm_78{left:346px;bottom:463px;letter-spacing:0.14px;}
#tn_78{left:339px;bottom:445px;letter-spacing:0.12px;}
#to_78{left:338px;bottom:426px;letter-spacing:0.05px;word-spacing:0.09px;}
#tp_78{left:221px;bottom:537px;letter-spacing:0.09px;}
#tq_78{left:205px;bottom:518px;letter-spacing:0.12px;word-spacing:0.02px;}
#tr_78{left:132px;bottom:371px;letter-spacing:-0.01px;}
#ts_78{left:162px;bottom:333px;letter-spacing:-0.14px;word-spacing:-0.91px;}
#tt_78{left:162px;bottom:313px;letter-spacing:-0.22px;word-spacing:0.12px;}
#tu_78{left:139px;bottom:284px;}
#tv_78{left:165px;bottom:284px;letter-spacing:-0.14px;word-spacing:11.51px;}
#tw_78{left:165px;bottom:264px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tx_78{left:139px;bottom:244px;}
#ty_78{left:165px;bottom:244px;letter-spacing:-0.13px;word-spacing:-0.15px;}
#tz_78{left:165px;bottom:223px;letter-spacing:-0.15px;}
#t10_78{left:139px;bottom:203px;}
#t11_78{left:165px;bottom:203px;letter-spacing:-0.16px;word-spacing:10.81px;}
#t12_78{left:165px;bottom:183px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t13_78{left:139px;bottom:163px;}
#t14_78{left:165px;bottom:163px;letter-spacing:-0.14px;word-spacing:0.34px;}
#t15_78{left:165px;bottom:143px;letter-spacing:-0.19px;}

.s0_78{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_78{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_78{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_78{font-size:20px;font-family:MogulArial-Bold_179;color:#000;}
.s4_78{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_78{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_78{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s7_78{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts78" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg78" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="78/78.svg"
          type="image/svg+xml"
          id="pdf78"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_78" class="t s0_78">
          78{" "}
        </span>
        <span id="t2_78" class="t s1_78">
          Монгол Улсын Боловсрол Шинжлэх Ухааны сайдын 2022 оны А/453{" "}
        </span>
        <span id="t3_78" class="t s1_78">
          Ерөнхий боловсролын сургууль, дотуур байрын орчинд хүүхэд хамгааллын{" "}
        </span>
        <span id="t4_78" class="t s1_78">
          үйлчилгээ үзүүлэх журмаар хүүхдийг үл хайхрах байдал, сэтгэл санааны{" "}
        </span>
        <span id="t5_78" class="t s1_78">
          хүчирхийлэл, үе тэнгийнхний дарамт, бие махбодын болон бэлгийн{" "}
        </span>
        <span id="t6_78" class="t s1_78">
          хүчирхийлэл, мөлжлөг, эрсдэлт нөхцөл байдлаас хамгаалж, хүүхдийн{" "}
        </span>
        <span id="t7_78" class="t s1_78">
          аюулгүй байдлыг хангахтай холбогдон үүсэх харилцааг зохицуулна гэж{" "}
        </span>
        <span id="t8_78" class="t s1_78">
          заасан байдаг.{" "}
        </span>
        <span id="t9_78" class="t s1_78">
          Монгол Улсын Боловсрол Шинжлэх Ухааны сайдын 2022 оны А/214 Ерөнхий{" "}
        </span>
        <span id="ta_78" class="t s1_78">
          боловсролын сургууль, дотуур байрын орчинд{" "}
        </span>
        <span id="tb_78" class="t s1_78">
          суралцагчийг үе тэнгийнхний{" "}
        </span>
        <span id="tc_78" class="t s1_78">
          дарамт, ялгаварлан гадуурхалтад өртөхөөс урьдчилан сэргийлэх,
          хамгаалах{" "}
        </span>
        <span id="td_78" class="t s1_78">
          үйл ажиллагааны чиглэл батлагдсан байдаг.{" "}
        </span>
        <span id="te_78" class="t s2_78">
          Зураг 3.2. Хүүхдийн үндсэн эрх{" "}
        </span>
        <span id="tf_78" class="t s3_78">
          Хүүхдийн{" "}
        </span>
        <span id="tg_78" class="t s3_78">
          эрх{" "}
        </span>
        <span id="th_78" class="t s4_78">
          Эсэн мэнд{" "}
        </span>
        <span id="ti_78" class="t s4_78">
          амьдрах{" "}
        </span>
        <span id="tj_78" class="t s4_78">
          эрх{" "}
        </span>
        <span id="tk_78" class="t s4_78">
          Хамгаалуулах{" "}
        </span>
        <span id="tl_78" class="t s4_78">
          эрх{" "}
        </span>
        <span id="tm_78" class="t s4_78">
          Нийгмийн{" "}
        </span>
        <span id="tn_78" class="t s4_78">
          харилцаанд{" "}
        </span>
        <span id="to_78" class="t s4_78">
          оролцох эрх{" "}
        </span>
        <span id="tp_78" class="t s4_78">
          Сурах{" "}
        </span>
        <span id="tq_78" class="t s4_78">
          хөгжих эрх{" "}
        </span>
        <span id="tr_78" class="t s5_78">
          АСУУДАЛ{" "}
        </span>
        <span id="ts_78" class="t s6_78">
          Хүүхдийн эрх, хамгааллын асуудалд удирдлагын манлайлал,{" "}
        </span>
        <span id="tt_78" class="t s6_78">
          зохицуулалт дэмжлэг дутмаг.{" "}
        </span>
        <span id="tu_78" class="t s7_78">
          •{" "}
        </span>
        <span id="tv_78" class="t s1_78">
          Хүүхдийн эрх, хамгааллын үйл ажиллагаа сургууль дээр{" "}
        </span>
        <span id="tw_78" class="t s1_78">
          хэрэгжихгүй байх;{" "}
        </span>
        <span id="tx_78" class="t s7_78">
          •{" "}
        </span>
        <span id="ty_78" class="t s1_78">
          Хүүхэд, хөгжил, хамгааллын талаарх бодлого, журмын хэрэгжилт{" "}
        </span>
        <span id="tz_78" class="t s1_78">
          хангалтгүй;{" "}
        </span>
        <span id="t10_78" class="t s7_78">
          •{" "}
        </span>
        <span id="t11_78" class="t s1_78">
          Мэргэжлийн бус нийгмийн ажилтнууд ажиллаж байгаагаас{" "}
        </span>
        <span id="t12_78" class="t s1_78">
          хүүхдийн хөгжил, хамгааллын ажил орхигдох;{" "}
        </span>
        <span id="t13_78" class="t s7_78">
          •{" "}
        </span>
        <span id="t14_78" class="t s1_78">
          Хүүхэд хамгааллын үйлчилгээ үзүүлэх үйл ажиллагаа хангалтгүй{" "}
        </span>
        <span id="t15_78" class="t s1_78">
          байх;{" "}
        </span>
      </div>
    </div>
  );
}
