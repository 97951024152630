import React from "react";
export default function Sixteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_16{left:87px;bottom:58px;letter-spacing:-0.09px;}
#t2_16{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_16{left:87px;bottom:939px;letter-spacing:0.07px;word-spacing:0.15px;}
#t4_16{left:87px;bottom:899px;letter-spacing:-0.01px;word-spacing:5.43px;}
#t5_16{left:634px;bottom:899px;letter-spacing:0.08px;}
#t6_16{left:87px;bottom:879px;letter-spacing:0.02px;word-spacing:1.81px;}
#t7_16{left:87px;bottom:859px;word-spacing:4.38px;}
#t8_16{left:543px;bottom:868px;}
#t9_16{left:558px;bottom:859px;letter-spacing:0.05px;word-spacing:4.34px;}
#ta_16{left:87px;bottom:839px;word-spacing:4.49px;}
#tb_16{left:87px;bottom:819px;letter-spacing:0.03px;word-spacing:5.03px;}
#tc_16{left:87px;bottom:799px;letter-spacing:-0.03px;word-spacing:0.12px;}
#td_16{left:87px;bottom:778px;letter-spacing:0.02px;word-spacing:-0.15px;}
#te_16{left:87px;bottom:758px;letter-spacing:0.02px;word-spacing:0.05px;}
#tf_16{left:87px;bottom:718px;letter-spacing:-0.18px;word-spacing:4.24px;}
#tg_16{left:87px;bottom:698px;letter-spacing:-0.17px;word-spacing:-0.16px;}
#th_16{left:87px;bottom:678px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_16{left:324px;bottom:640px;letter-spacing:0.12px;word-spacing:0.02px;}
#tj_16{left:671px;bottom:648px;}
#tk_16{left:95px;bottom:593px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tl_16{left:95px;bottom:574px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tm_16{left:230px;bottom:608px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tn_16{left:238px;bottom:589px;letter-spacing:-0.14px;word-spacing:0.02px;}
#to_16{left:230px;bottom:571px;letter-spacing:-0.14px;word-spacing:0.02px;}
#tp_16{left:238px;bottom:553px;letter-spacing:-0.2px;word-spacing:0.08px;}
#tq_16{left:230px;bottom:534px;letter-spacing:-0.15px;word-spacing:0.07px;}
#tr_16{left:238px;bottom:516px;letter-spacing:-0.14px;word-spacing:0.01px;}
#ts_16{left:230px;bottom:498px;letter-spacing:-0.24px;word-spacing:0.15px;}
#tt_16{left:238px;bottom:479px;letter-spacing:-0.1px;word-spacing:-0.01px;}
#tu_16{left:95px;bottom:439px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tv_16{left:95px;bottom:421px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tw_16{left:95px;bottom:402px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tx_16{left:95px;bottom:384px;letter-spacing:-0.13px;}
#ty_16{left:230px;bottom:451px;letter-spacing:-0.14px;word-spacing:0.09px;}
#tz_16{left:230px;bottom:433px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t10_16{left:230px;bottom:415px;letter-spacing:-0.15px;word-spacing:0.07px;}
#t11_16{left:238px;bottom:396px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t12_16{left:238px;bottom:378px;letter-spacing:-0.15px;}
#t13_16{left:230px;bottom:360px;letter-spacing:-0.15px;word-spacing:0.07px;}
#t14_16{left:238px;bottom:341px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t15_16{left:230px;bottom:323px;letter-spacing:-0.15px;word-spacing:0.1px;}
#t16_16{left:230px;bottom:305px;letter-spacing:-0.14px;word-spacing:0.15px;}
#t17_16{left:95px;bottom:262px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t18_16{left:95px;bottom:243px;letter-spacing:-0.15px;word-spacing:0.01px;}
#t19_16{left:230px;bottom:275px;letter-spacing:-0.14px;word-spacing:0.08px;}
#t1a_16{left:230px;bottom:257px;letter-spacing:-0.13px;word-spacing:0.14px;}
#t1b_16{left:230px;bottom:239px;letter-spacing:-0.12px;word-spacing:0.13px;}
#t1c_16{left:230px;bottom:220px;letter-spacing:-0.17px;word-spacing:0.09px;}
#t1d_16{left:230px;bottom:202px;letter-spacing:-0.16px;word-spacing:0.17px;}
#t1e_16{left:87px;bottom:144px;}
#t1f_16{left:108px;bottom:144px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1g_16{left:87px;bottom:130px;}
#t1h_16{left:106px;bottom:130px;letter-spacing:0.04px;word-spacing:2.86px;}
#t1i_16{left:108px;bottom:115px;letter-spacing:0.1px;word-spacing:0.01px;}

.s0_16{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_16{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_16{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_16{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_16{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_16{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s6_16{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_16{font-size:9px;font-family:MogulArial-Italic_17h;color:#000;}
.s8_16{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s9_16{font-size:12px;font-family:MogulArial_16s;color:#000;}
.sa_16{font-size:12px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts16" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg16" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="16/16.svg"
          type="image/svg+xml"
          id="pdf16"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_16" class="t s1_16">
          16{" "}
        </span>
        <span id="t3_16" class="t s2_16">
          СУРАГЧИД ЮУ ХҮСЭЖ БАЙНА ВЭ?{" "}
        </span>
        <span id="t4_16" class="t s3_16">
          Тэд эрс өөрчлөлт, гэнэтийн эргэлтийн үед амьдарч байна.{" "}
        </span>
        <span id="t5_16" class="t s4_16">
          Олон{" "}
        </span>
        <span id="t6_16" class="t s4_16">
          улс орны судлаачид ирээдүйг тогтворгүй, тодорхойгүй, нарийн төвөгтэй,{" "}
        </span>
        <span id="t7_16" class="t s4_16">
          хоёрдмол байдалтай (VUCA) гэж тодорхойлсон байна.{" "}
        </span>
        <span id="t8_16" class="t s5_16">
          1{" "}
        </span>
        <span id="t9_16" class="t s4_16">
          Гэсэн хэдий ч{" "}
        </span>
        <span id="ta_16" class="t s4_16">
          ирээдүйгээ хүмүүс өөрсдөө бүтээдэг, ирээдүй бол өнөөдөрт бүрэлдэж{" "}
        </span>
        <span id="tb_16" class="t s4_16">
          байдгийг бид мэднэ. Бидний сурагчид хараахан бий болоогүй ажлын{" "}
        </span>
        <span id="tc_16" class="t s4_16">
          байранд ажиллахыг, хараахан зохион бүтээгээгүй технологийг ашиглахыг,{" "}
        </span>
        <span id="td_16" class="t s4_16">
          эсвэл бидний төсөөлж чадахгүй байгаа нийгмийн асуудлыг шийдвэрлэхэд{" "}
        </span>
        <span id="te_16" class="t s4_16">
          суралцах шаардлага бий болжээ.{" "}
        </span>
        <span id="tf_16" class="t s4_16">
          Сурагчид эдийн засаг, байгаль орчин, нийгмийн хурдацтай өөрчлөлтийг{" "}
        </span>
        <span id="tg_16" class="t s4_16">
          хүлээн авч дасан зохицож, тодорхой бус нөхцөлд тодорхой шийдвэр гаргаж{" "}
        </span>
        <span id="th_16" class="t s4_16">
          чаддаг байхын төлөө суралцаж байна.{" "}
        </span>
        <span id="ti_16" class="t s6_16">
          Хүснэгт 1.1 Ирээдүйн нийгмийн иргэн-дүр төрх{" "}
        </span>
        <span id="tj_16" class="t s7_16">
          2{" "}
        </span>
        <span id="tk_16" class="t s8_16">
          Үнэт зүйл,{" "}
        </span>
        <span id="tl_16" class="t s8_16">
          хандлагын хувьд{" "}
        </span>
        <span id="tm_16" class="t s8_16">
          - бие махбод, танин мэдэхүй, сэтгэл хөдлөл, ёс суртахуун нэгдэж{" "}
        </span>
        <span id="tn_16" class="t s8_16">
          цогцолсон төлөвшсөн хүмүүн;{" "}
        </span>
        <span id="to_16" class="t s8_16">
          - ёс зүйтэй, хүмүүнлэг энэрэнгүй, бусдын уламжлал, итгэл үнэмшилд{" "}
        </span>
        <span id="tp_16" class="t s8_16">
          бүрэн хүндэтгэлтэй ханддаг, олон ургалч үзэлтэн;{" "}
        </span>
        <span id="tq_16" class="t s8_16">
          - энх тайван, эв нэгдлийг эрхэмлэсэн, эх дэлхийгээ хайрладаг{" "}
        </span>
        <span id="tr_16" class="t s8_16">
          дэлхийн иргэн{" "}
        </span>
        <span id="ts_16" class="t s8_16">
          - бусдын оронд өөрийгөө тавьдаг, бусдад итгэдэг, хариуцлага үүрдэг,{" "}
        </span>
        <span id="tt_16" class="t s8_16">
          энэрэн нигүүлсэгч;{" "}
        </span>
        <span id="tu_16" class="t s8_16">
          Шинжлэх ухаанч{" "}
        </span>
        <span id="tv_16" class="t s8_16">
          байдал, суралцах{" "}
        </span>
        <span id="tw_16" class="t s8_16">
          арга барилын{" "}
        </span>
        <span id="tx_16" class="t s8_16">
          хувьд{" "}
        </span>
        <span id="ty_16" class="t s8_16">
          - шинжлэх ухааныг гүнзгий ойлгогч;{" "}
        </span>
        <span id="tz_16" class="t s8_16">
          - оюун ухаанаа сэтгэл хөдлөлтэй холбон, дэлхий ертөнцөө танигч;{" "}
        </span>
        <span id="t10_16" class="t s8_16">
          - асуух, тааварлах, эргэлзэх, ажиглах, турших, мэдээллийг ангилах,{" "}
        </span>
        <span id="t11_16" class="t s8_16">
          тасралтгүй дадлага сургуулилт хийх зэргээр судлан таних арга зүйг{" "}
        </span>
        <span id="t12_16" class="t s8_16">
          эзэмшигч;{" "}
        </span>
        <span id="t13_16" class="t s8_16">
          - насан туршдаа суралцагч, суралцахуйгаа удирдан чиглүүлэгч,{" "}
        </span>
        <span id="t14_16" class="t s8_16">
          суралцахуйн намтраа өөрөө, мэдлэгийг хамтран бүтээгч;{" "}
        </span>
        <span id="t15_16" class="t s8_16">
          - мета-танин мэдэхүйн чадвараа хөгжүүлэгч;{" "}
        </span>
        <span id="t16_16" class="t s8_16">
          - хамтран суралцагч;{" "}
        </span>
        <span id="t17_16" class="t s8_16">
          Хувь хүний ур{" "}
        </span>
        <span id="t18_16" class="t s8_16">
          чадварын хувьд{" "}
        </span>
        <span id="t19_16" class="t s8_16">
          - идэвхтэй, үр бүтээлтэй оролцогч, хамтрагч;{" "}
        </span>
        <span id="t1a_16" class="t s8_16">
          - инноваци бүтээгч;{" "}
        </span>
        <span id="t1b_16" class="t s8_16">
          - цахим иргэн;{" "}
        </span>
        <span id="t1c_16" class="t s8_16">
          - тодорхой бус байдлыг даван туулагч, дасан зохицогч;{" "}
        </span>
        <span id="t1d_16" class="t s8_16">
          - ирээдүйгээ тодорхойлогч;{" "}
        </span>
        <span id="t1e_16" class="t s9_16">
          1{" "}
        </span>
        <span id="t1f_16" class="t s9_16">
          OECD, Боловсролын ирээдүйг цогцлоох нь, 2021{" "}
        </span>
        <span id="t1g_16" class="t s9_16">
          2{" "}
        </span>
        <span id="t1h_16" class="t sa_16">
          У.Туяа, М.Хүрэлтогоо, Г.Бүрэнжаргал, А.Мөнхмагнай нар, Боловсролын
          ирээдүйн чиг хандлага -{" "}
        </span>
        <span id="t1i_16" class="t sa_16">
          баримт бичгийн шинжилгээний зарим үр дүн, 2022 , БСҮХ-ийн УШ-ний цахим
          сэтгүүл{" "}
        </span>
      </div>
    </div>
  );
}
