import React from "react";
export default function F155() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_155{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_155{left:108px;bottom:941px;letter-spacing:-0.05px;}
#t3_155{left:136px;bottom:941px;letter-spacing:-0.12px;word-spacing:-1.42px;}
#t4_155{left:136px;bottom:921px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t5_155{left:108px;bottom:896px;letter-spacing:-0.05px;}
#t6_155{left:136px;bottom:896px;letter-spacing:-0.13px;word-spacing:2.26px;}
#t7_155{left:136px;bottom:876px;letter-spacing:-0.18px;word-spacing:4.13px;}
#t8_155{left:136px;bottom:856px;letter-spacing:-0.14px;word-spacing:4.71px;}
#t9_155{left:136px;bottom:836px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ta_155{left:108px;bottom:811px;letter-spacing:-0.05px;}
#tb_155{left:136px;bottom:811px;letter-spacing:-0.11px;word-spacing:0.69px;}
#tc_155{left:136px;bottom:791px;letter-spacing:-0.13px;word-spacing:4.89px;}
#td_155{left:136px;bottom:771px;letter-spacing:-0.14px;word-spacing:4.93px;}
#te_155{left:136px;bottom:751px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tf_155{left:108px;bottom:726px;letter-spacing:-0.05px;}
#tg_155{left:136px;bottom:726px;letter-spacing:-0.14px;word-spacing:5.66px;}
#th_155{left:136px;bottom:706px;letter-spacing:-0.13px;}
#ti_155{left:87px;bottom:645px;letter-spacing:0.03px;word-spacing:0.21px;}
#tj_155{left:87px;bottom:620px;letter-spacing:0.02px;word-spacing:0.22px;}
#tk_155{left:430px;bottom:588px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tl_155{left:87px;bottom:567px;letter-spacing:-0.16px;word-spacing:0.33px;}
#tm_155{left:87px;bottom:546px;letter-spacing:-0.18px;word-spacing:4.92px;}
#tn_155{left:87px;bottom:526px;letter-spacing:-0.13px;}
#to_155{left:148px;bottom:526px;letter-spacing:-0.08px;}
#tp_155{left:206px;bottom:526px;letter-spacing:-0.32px;}
#tq_155{left:314px;bottom:526px;letter-spacing:-0.19px;}
#tr_155{left:380px;bottom:526px;letter-spacing:-0.26px;}
#ts_155{left:87px;bottom:506px;letter-spacing:-0.14px;word-spacing:1.73px;}
#tt_155{left:87px;bottom:486px;letter-spacing:-0.13px;}
#tu_155{left:150px;bottom:486px;letter-spacing:-0.16px;}
#tv_155{left:220px;bottom:486px;letter-spacing:-0.11px;}
#tw_155{left:339px;bottom:486px;letter-spacing:-0.11px;}
#tx_155{left:87px;bottom:466px;letter-spacing:-0.16px;word-spacing:0.89px;}
#ty_155{left:87px;bottom:446px;letter-spacing:-0.17px;word-spacing:7.63px;}
#tz_155{left:87px;bottom:425px;letter-spacing:-0.17px;word-spacing:10.91px;}
#t10_155{left:87px;bottom:405px;letter-spacing:-0.14px;word-spacing:3.72px;}
#t11_155{left:87px;bottom:385px;letter-spacing:-0.14px;word-spacing:9.94px;}
#t12_155{left:87px;bottom:365px;letter-spacing:-0.18px;word-spacing:8.41px;}
#t13_155{left:87px;bottom:345px;letter-spacing:-0.23px;}
#t14_155{left:207px;bottom:345px;letter-spacing:-0.38px;}
#t15_155{left:328px;bottom:345px;letter-spacing:-0.16px;}
#t16_155{left:87px;bottom:325px;letter-spacing:-0.17px;}
#t17_155{left:223px;bottom:325px;letter-spacing:-0.09px;}
#t18_155{left:307px;bottom:325px;letter-spacing:-0.15px;}
#t19_155{left:87px;bottom:304px;letter-spacing:-0.14px;word-spacing:5.27px;}
#t1a_155{left:87px;bottom:284px;letter-spacing:-0.12px;word-spacing:-0.14px;}
#t1b_155{left:87px;bottom:264px;letter-spacing:-0.15px;word-spacing:6.03px;}
#t1c_155{left:87px;bottom:244px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1d_155{left:87px;bottom:211px;letter-spacing:-0.13px;word-spacing:6.98px;}
#t1e_155{left:302px;bottom:211px;}
#t1f_155{left:319px;bottom:211px;letter-spacing:-0.15px;word-spacing:7.02px;}
#t1g_155{left:87px;bottom:191px;letter-spacing:-0.12px;word-spacing:2.9px;}
#t1h_155{left:87px;bottom:170px;letter-spacing:-0.13px;word-spacing:0.24px;}
#t1i_155{left:87px;bottom:150px;letter-spacing:-0.13px;word-spacing:0.42px;}
#t1j_155{left:87px;bottom:130px;letter-spacing:-0.1px;}

.s0_155{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_155{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s2_155{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_155{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_155{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_155{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s6_155{font-size:17px;font-family:MogulArial_16s;color:#F7941E;}`}
      </style>

      <style id="fonts155" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg155" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="155/155.svg"
          type="image/svg+xml"
          id="pdf155"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_155" class="t s0_155">
          155{" "}
        </span>
        <span id="t2_155" class="t s1_155">
          1.{" "}
        </span>
        <span id="t3_155" class="t s2_155">
          Багш, ажилтныг ёс зүйн алдаа, зөрчил гаргахаас урьдчилан сэргийлэх{" "}
        </span>
        <span id="t4_155" class="t s2_155">
          сургалт, үйл ажиллагааг зохион байгуулах{" "}
        </span>
        <span id="t5_155" class="t s1_155">
          2.{" "}
        </span>
        <span id="t6_155" class="t s2_155">
          Сурагч, эцэг эх, асран хамгаалагч, харгалзан дэмжигчдэд чиглэсэн{" "}
        </span>
        <span id="t7_155" class="t s2_155">
          сургалт зохион байгуулах, мэдээлэл өгөх. Тухайлбал, тэдний эрх,{" "}
        </span>
        <span id="t8_155" class="t s2_155">
          үүргийг танилцуулж, багш, ажилтанд хүндэтгэлтэй хандах талаар{" "}
        </span>
        <span id="t9_155" class="t s2_155">
          тодорхой үйл ажиллагаа, арга хэмжээг төлөвлөх{" "}
        </span>
        <span id="ta_155" class="t s1_155">
          3.{" "}
        </span>
        <span id="tb_155" class="t s2_155">
          Багшийн ёс зүйн дүрмийн хэрэгжилтэд хяналт тавих, ёс зүйн алдаа,{" "}
        </span>
        <span id="tc_155" class="t s2_155">
          зөрчлийг цаг тухай бүрд нь илрүүлж шийдвэрлэх, тухайн алдаа,{" "}
        </span>
        <span id="td_155" class="t s2_155">
          зөрчил гарах болсон шалтгаан нөхцөлийг илрүүлж, арилгах арга{" "}
        </span>
        <span id="te_155" class="t s2_155">
          хэмжээ авч хэвших{" "}
        </span>
        <span id="tf_155" class="t s1_155">
          4.{" "}
        </span>
        <span id="tg_155" class="t s2_155">
          Хамт олны эерэг уур амьсгалыг бүрдүүлж, байгууллагыг соёлыг{" "}
        </span>
        <span id="th_155" class="t s2_155">
          хэвшүүлэх{" "}
        </span>
        <span id="ti_155" class="t s3_155">
          3.4 СУРГАЛТЫГ ЗОХИОН БАЙГУУЛАХ ТААТАЙ ОРЧНЫГ{" "}
        </span>
        <span id="tj_155" class="t s3_155">
          ХЭРХЭН БҮРДҮҮЛЭХ ВЭ?{" "}
        </span>
        <span id="tk_155" class="t s4_155">
          Зураг 3.27. Сургалтын стратеги, орчин{" "}
        </span>
        <span id="tl_155" class="t s2_155">
          Сургалтын орчин нь сурагчдын сурлагын{" "}
        </span>
        <span id="tm_155" class="t s2_155">
          амжилт, хөгжил төлөвшил, сэтгэл зүйд{" "}
        </span>
        <span id="tn_155" class="t s2_155">
          чухал{" "}
        </span>
        <span id="to_155" class="t s2_155">
          үүрэг{" "}
        </span>
        <span id="tp_155" class="t s2_155">
          гүйцэтгэдэг.{" "}
        </span>
        <span id="tq_155" class="t s2_155">
          Орчин{" "}
        </span>
        <span id="tr_155" class="t s2_155">
          үед{" "}
        </span>
        <span id="ts_155" class="t s2_155">
          сургуулийн анги танхимаас гадна гэртээ{" "}
        </span>
        <span id="tt_155" class="t s2_155">
          сурах{" "}
        </span>
        <span id="tu_155" class="t s2_155">
          орчин,{" "}
        </span>
        <span id="tv_155" class="t s2_155">
          суралцахуйн{" "}
        </span>
        <span id="tw_155" class="t s2_155">
          стадион,{" "}
        </span>
        <span id="tx_155" class="t s2_155">
          виртуал орчин гэх мэт сургалтын орчныг{" "}
        </span>
        <span id="ty_155" class="t s2_155">
          өргөн утгаар авч үзэх болсон байна.{" "}
        </span>
        <span id="tz_155" class="t s2_155">
          Монгол улсын боловсролын ерөнхий{" "}
        </span>
        <span id="t10_155" class="t s2_155">
          хуулийн 3.1.12.-т “сургалтын орчин” гэх{" "}
        </span>
        <span id="t11_155" class="t s2_155">
          сургалтын хөтөлбөрийг хэрэгжүүлэхэд{" "}
        </span>
        <span id="t12_155" class="t s2_155">
          шаардагдах, эрх бүхий байгууллагаас{" "}
        </span>
        <span id="t13_155" class="t s2_155">
          баталсан{" "}
        </span>
        <span id="t14_155" class="t s2_155">
          стандарт,{" "}
        </span>
        <span id="t15_155" class="t s2_155">
          техникийн{" "}
        </span>
        <span id="t16_155" class="t s2_155">
          зохицуулалтад{" "}
        </span>
        <span id="t17_155" class="t s2_155">
          нийцсэн{" "}
        </span>
        <span id="t18_155" class="t s2_155">
          материаллаг{" "}
        </span>
        <span id="t19_155" class="t s2_155">
          болон сэтгэл зүйн нөхцөл, сурах, сургах харилцааг” гэж тодорхойлсон{" "}
        </span>
        <span id="t1a_155" class="t s2_155">
          байна. Суралцагчийн эрх ашгийг нэн тэргүүнд тавьж, орчны эрүүл аюулгүй{" "}
        </span>
        <span id="t1b_155" class="t s2_155">
          байдал, орчин үеийн хэрэгцээ шаардлагад нийцсэн сургалтын орчныг{" "}
        </span>
        <span id="t1c_155" class="t s2_155">
          бүрдүүлэхэд сургуулийн менежмент нь чухал юм.{" "}
        </span>
        <span id="t1d_155" class="t s5_155">
          Сурагч төвт суралцахуй
        </span>
        <span id="t1e_155" class="t s6_155">
          :{" "}
        </span>
        <span id="t1f_155" class="t s2_155">
          гол зорилго нь бүх сурагчийн хувь хүний{" "}
        </span>
        <span id="t1g_155" class="t s2_155">
          хэрэгцээнд нийцүүлэн суралцах боломжийг нээх юм. Түүнчлэн ирээдүйд{" "}
        </span>
        <span id="t1h_155" class="t s2_155">
          шаардлагатай чадвар, чадамжийг эзэмших үндсийг бүрдүүлэхийн зэрэгцээ{" "}
        </span>
        <span id="t1i_155" class="t s2_155">
          сурагчид бие даан удирдах чадвараа хөгжүүлэх, нийгэм, сэтгэл хөдлөлийн{" "}
        </span>
        <span id="t1j_155" class="t s2_155">
          хөгжлийг дэмжих юм.{" "}
        </span>
      </div>
    </div>
  );
}
