import React from "react";
export default function HundredTwentyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_123{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_123{left:91px;bottom:786px;letter-spacing:-0.18px;}
#t3_123{left:91px;bottom:768px;letter-spacing:-0.16px;}
#t4_123{left:188px;bottom:673px;letter-spacing:-0.38px;}
#t5_123{left:188px;bottom:655px;letter-spacing:0.05px;}
#t6_123{left:273px;bottom:938px;letter-spacing:-0.17px;}
#t7_123{left:273px;bottom:919px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t8_123{left:273px;bottom:901px;letter-spacing:-0.17px;}
#t9_123{left:273px;bottom:883px;letter-spacing:-0.21px;word-spacing:0.09px;}
#ta_123{left:273px;bottom:864px;letter-spacing:-0.12px;}
#tb_123{left:273px;bottom:846px;letter-spacing:-0.19px;}
#tc_123{left:369px;bottom:910px;letter-spacing:-0.45px;}
#td_123{left:442px;bottom:910px;letter-spacing:-0.38px;}
#te_123{left:517px;bottom:910px;letter-spacing:-0.17px;}
#tf_123{left:619px;bottom:910px;letter-spacing:-0.12px;}
#tg_123{left:369px;bottom:892px;letter-spacing:-0.21px;}
#th_123{left:494px;bottom:892px;letter-spacing:-0.12px;}
#ti_123{left:580px;bottom:892px;letter-spacing:-0.22px;}
#tj_123{left:369px;bottom:874px;letter-spacing:-0.23px;word-spacing:0.11px;}
#tk_123{left:273px;bottom:823px;letter-spacing:-0.13px;}
#tl_123{left:273px;bottom:805px;letter-spacing:-0.17px;}
#tm_123{left:273px;bottom:786px;letter-spacing:-0.12px;}
#tn_123{left:369px;bottom:823px;letter-spacing:-0.13px;}
#to_123{left:474px;bottom:823px;letter-spacing:-0.15px;}
#tp_123{left:574px;bottom:823px;letter-spacing:-0.12px;}
#tq_123{left:639px;bottom:823px;letter-spacing:-0.11px;}
#tr_123{left:369px;bottom:805px;letter-spacing:-0.2px;word-spacing:-0.04px;}
#ts_123{left:369px;bottom:786px;letter-spacing:-0.16px;}
#tt_123{left:273px;bottom:690px;letter-spacing:-0.18px;}
#tu_123{left:273px;bottom:672px;letter-spacing:-0.15px;}
#tv_123{left:369px;bottom:763px;letter-spacing:-0.14px;word-spacing:-1.34px;}
#tw_123{left:369px;bottom:745px;letter-spacing:-0.2px;word-spacing:8.1px;}
#tx_123{left:369px;bottom:727px;letter-spacing:-0.15px;}
#ty_123{left:423px;bottom:727px;letter-spacing:-0.21px;}
#tz_123{left:487px;bottom:727px;letter-spacing:-0.2px;}
#t10_123{left:585px;bottom:727px;letter-spacing:-0.22px;}
#t11_123{left:369px;bottom:708px;letter-spacing:-0.14px;word-spacing:3.14px;}
#t12_123{left:369px;bottom:690px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t13_123{left:369px;bottom:672px;letter-spacing:-0.16px;word-spacing:0.73px;}
#t14_123{left:369px;bottom:653px;letter-spacing:-0.14px;}
#t15_123{left:439px;bottom:653px;letter-spacing:-0.23px;}
#t16_123{left:530px;bottom:653px;letter-spacing:-0.15px;}
#t17_123{left:598px;bottom:653px;letter-spacing:-0.15px;}
#t18_123{left:369px;bottom:635px;letter-spacing:-0.17px;word-spacing:2.58px;}
#t19_123{left:570px;bottom:635px;letter-spacing:-0.12px;word-spacing:2.53px;}
#t1a_123{left:369px;bottom:617px;letter-spacing:-0.14px;word-spacing:4.3px;}
#t1b_123{left:369px;bottom:598px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1c_123{left:91px;bottom:500px;letter-spacing:-0.15px;}
#t1d_123{left:91px;bottom:481px;letter-spacing:-0.22px;}
#t1e_123{left:273px;bottom:566px;letter-spacing:-0.16px;}
#t1f_123{left:273px;bottom:548px;letter-spacing:-0.15px;}
#t1g_123{left:273px;bottom:530px;letter-spacing:-0.2px;}
#t1h_123{left:369px;bottom:575px;letter-spacing:-0.16px;word-spacing:0.61px;}
#t1i_123{left:369px;bottom:557px;letter-spacing:-0.14px;word-spacing:-1.55px;}
#t1j_123{left:369px;bottom:539px;letter-spacing:-0.21px;word-spacing:2.89px;}
#t1k_123{left:369px;bottom:520px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t1l_123{left:273px;bottom:452px;letter-spacing:-0.13px;}
#t1m_123{left:273px;bottom:433px;letter-spacing:-0.16px;}
#t1n_123{left:369px;bottom:497px;letter-spacing:-0.1px;}
#t1o_123{left:448px;bottom:497px;letter-spacing:-0.12px;}
#t1p_123{left:521px;bottom:497px;letter-spacing:-0.14px;}
#t1q_123{left:604px;bottom:497px;letter-spacing:-0.08px;}
#t1r_123{left:641px;bottom:497px;letter-spacing:-0.15px;}
#t1s_123{left:369px;bottom:479px;letter-spacing:-0.19px;word-spacing:-0.9px;}
#t1t_123{left:369px;bottom:461px;letter-spacing:-0.18px;word-spacing:1.82px;}
#t1u_123{left:369px;bottom:442px;letter-spacing:-0.15px;word-spacing:-1.03px;}
#t1v_123{left:369px;bottom:424px;letter-spacing:-0.15px;word-spacing:5.75px;}
#t1w_123{left:369px;bottom:406px;letter-spacing:-0.21px;}
#t1x_123{left:425px;bottom:406px;letter-spacing:-0.15px;word-spacing:0.99px;}
#t1y_123{left:369px;bottom:387px;letter-spacing:-0.17px;word-spacing:0.03px;}
#t1z_123{left:87px;bottom:347px;letter-spacing:-0.14px;word-spacing:3.48px;}
#t20_123{left:87px;bottom:327px;letter-spacing:-0.14px;word-spacing:-0.09px;}
#t21_123{left:87px;bottom:306px;letter-spacing:-0.15px;word-spacing:11.38px;}
#t22_123{left:87px;bottom:286px;letter-spacing:-0.15px;word-spacing:5.92px;}
#t23_123{left:87px;bottom:266px;letter-spacing:-0.12px;word-spacing:0.02px;}

.s0_123{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_123{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_123{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s3_123{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts123" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg123" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="123/123.svg"
          type="image/svg+xml"
          id="pdf123"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_123" class="t s0_123">
          123{" "}
        </span>
        <span id="t2_123" class="t s1_123">
          Гүйцэтгэлийн{" "}
        </span>
        <span id="t3_123" class="t s1_123">
          үнэлгээ{" "}
        </span>
        <span id="t4_123" class="t s2_123">
          Хөндлөнгийн{" "}
        </span>
        <span id="t5_123" class="t s2_123">
          үнэлгээ{" "}
        </span>
        <span id="t6_123" class="t s1_123">
          Сургууль,{" "}
        </span>
        <span id="t7_123" class="t s1_123">
          орон нутгаас{" "}
        </span>
        <span id="t8_123" class="t s1_123">
          зохион{" "}
        </span>
        <span id="t9_123" class="t s1_123">
          байгуулж буй{" "}
        </span>
        <span id="ta_123" class="t s1_123">
          хөндлөнгийн{" "}
        </span>
        <span id="tb_123" class="t s1_123">
          шалгалт{" "}
        </span>
        <span id="tc_123" class="t s1_123">
          Аймаг,{" "}
        </span>
        <span id="td_123" class="t s1_123">
          дүүрэг,{" "}
        </span>
        <span id="te_123" class="t s1_123">
          сургуулийн{" "}
        </span>
        <span id="tf_123" class="t s1_123">
          түвшинг{" "}
        </span>
        <span id="tg_123" class="t s1_123">
          зорилготойгоор{" "}
        </span>
        <span id="th_123" class="t s1_123">
          илрүүлж,{" "}
        </span>
        <span id="ti_123" class="t s1_123">
          шаардлагатай{" "}
        </span>
        <span id="tj_123" class="t s1_123">
          дэмжлэг, чиглүүлэг, үйл ажиллагааг төлөвлөх{" "}
        </span>
        <span id="tk_123" class="t s1_123">
          Чанарын{" "}
        </span>
        <span id="tl_123" class="t s1_123">
          шалгалтын{" "}
        </span>
        <span id="tm_123" class="t s1_123">
          анализ{" "}
        </span>
        <span id="tn_123" class="t s1_123">
          Хөтөлбөрийн{" "}
        </span>
        <span id="to_123" class="t s1_123">
          хэрэгжилтэд{" "}
        </span>
        <span id="tp_123" class="t s1_123">
          анализ{" "}
        </span>
        <span id="tq_123" class="t s1_123">
          хийж{" "}
        </span>
        <span id="tr_123" class="t s1_123">
          тохируулга, чиглүүлэг, дэмжлэг үйл ажиллагааг{" "}
        </span>
        <span id="ts_123" class="t s1_123">
          төлөвлөх{" "}
        </span>
        <span id="tt_123" class="t s1_123">
          Гүйцэтгэлийн{" "}
        </span>
        <span id="tu_123" class="t s1_123">
          үнэлгээний{" "}
        </span>
        <span id="tv_123" class="t s1_123">
          Сурагчид ямар ай, ямар мэдлэг чадварын хувьд{" "}
        </span>
        <span id="tw_123" class="t s1_123">
          сул байгааг илрүүлэх, агуулгын хоцрогдол{" "}
        </span>
        <span id="tx_123" class="t s1_123">
          хаана{" "}
        </span>
        <span id="ty_123" class="t s1_123">
          байгааг{" "}
        </span>
        <span id="tz_123" class="t s1_123">
          тодорхойлох{" "}
        </span>
        <span id="t10_123" class="t s1_123">
          судлагдахуун{" "}
        </span>
        <span id="t11_123" class="t s1_123">
          тус бүрээр улс, бүс, аймгийн түвшинд хаана{" "}
        </span>
        <span id="t12_123" class="t s1_123">
          яваагаа харьцуулах дүгнэлт хийж сайжруулах{" "}
        </span>
        <span id="t13_123" class="t s1_123">
          Хөтөлбөрийн хэрэгжилтэд сургалтын, багшийн{" "}
        </span>
        <span id="t14_123" class="t s1_123">
          чадвар,{" "}
        </span>
        <span id="t15_123" class="t s1_123">
          удирдлага,{" "}
        </span>
        <span id="t16_123" class="t s1_123">
          хамтын{" "}
        </span>
        <span id="t17_123" class="t s1_123">
          ажиллагаа,{" "}
        </span>
        <span id="t18_123" class="t s1_123">
          оролцогчдын сэтгэл ханамж,{" "}
        </span>
        <span id="t19_123" class="t s1_123">
          зэрэг нөлөөлөх{" "}
        </span>
        <span id="t1a_123" class="t s1_123">
          бүхий л хүчин зүйлийг илрүүлэн сургуулийн{" "}
        </span>
        <span id="t1b_123" class="t s1_123">
          сургалт үйл ажиллагааг сайжруулах{" "}
        </span>
        <span id="t1c_123" class="t s1_123">
          Жагсаан{" "}
        </span>
        <span id="t1d_123" class="t s1_123">
          эрэмбэлэх{" "}
        </span>
        <span id="t1e_123" class="t s1_123">
          Олимпиадын{" "}
        </span>
        <span id="t1f_123" class="t s1_123">
          үнэлгээ,{" "}
        </span>
        <span id="t1g_123" class="t s1_123">
          оролцоо{" "}
        </span>
        <span id="t1h_123" class="t s1_123">
          Эзэлсэн байр, медаль гэж харахаас илүү багш{" "}
        </span>
        <span id="t1i_123" class="t s1_123">
          болон сурагчийн ахисан түвшний даалгавар үйл{" "}
        </span>
        <span id="t1j_123" class="t s1_123">
          ажиллагааг гүйцэтгэх мэдлэг, чадвар, багшид{" "}
        </span>
        <span id="t1k_123" class="t s1_123">
          шаардлагатай ур чадварыг тодорхойлох{" "}
        </span>
        <span id="t1l_123" class="t s1_123">
          ЭЕШ-ийн{" "}
        </span>
        <span id="t1m_123" class="t s1_123">
          үнэлгээ{" "}
        </span>
        <span id="t1n_123" class="t s1_123">
          Үндэсний{" "}
        </span>
        <span id="t1o_123" class="t s1_123">
          түвшинд{" "}
        </span>
        <span id="t1p_123" class="t s1_123">
          сурагчдыг{" "}
        </span>
        <span id="t1q_123" class="t s1_123">
          их,{" "}
        </span>
        <span id="t1r_123" class="t s1_123">
          дээд{" "}
        </span>
        <span id="t1s_123" class="t s1_123">
          сургуульд эрэмбэлэн элсүүлэх зорилготой багш{" "}
        </span>
        <span id="t1t_123" class="t s1_123">
          сургуулийг үнэлэх зорилгогүй ч сургууль багш{" "}
        </span>
        <span id="t1u_123" class="t s1_123">
          ЭЕШ үр дүнгээс сонгон судлах хичээлийн чанар{" "}
        </span>
        <span id="t1v_123" class="t s1_123">
          хөтөлбөрийн хэрэгжилт үр дүнг харьцуулан{" "}
        </span>
        <span id="t1w_123" class="t s1_123">
          судалж{" "}
        </span>
        <span id="t1x_123" class="t s1_123">
          сонгон судлах хичээлийн хэрэгжилтээ{" "}
        </span>
        <span id="t1y_123" class="t s1_123">
          сайжруулан ажиллах боломжтой{" "}
        </span>
        <span id="t1z_123" class="t s3_123">
          Дээрх хүснэгтээс бидний хийж байгаа олон төрлийн үнэлгээний үр дүнг{" "}
        </span>
        <span id="t20_123" class="t s3_123">
          зөвхөн оноо, жагсаалт гэхээс илүү маш олон төрлийн мэдээллийг цуглуулж{" "}
        </span>
        <span id="t21_123" class="t s3_123">
          багшлахуй болон суралцахуй, сургалт үйл ажиллагааг сайжруулах{" "}
        </span>
        <span id="t22_123" class="t s3_123">
          боломжтой. Иймд сургуулийн менежментийн чухал нэгэн асуудал бол{" "}
        </span>
        <span id="t23_123" class="t s3_123">
          үнэлгээний үр дүнд анализ хийж сурах явдал юм.{" "}
        </span>
      </div>
    </div>
  );
}
