import React from "react";
export default function OneHundredSeventyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_179{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_179{left:89px;bottom:943px;letter-spacing:0.11px;word-spacing:0.03px;}
#t3_179{left:352px;bottom:924px;letter-spacing:0.11px;word-spacing:0.03px;}
#t4_179{left:113px;bottom:888px;letter-spacing:0.02px;word-spacing:0.12px;}
#t5_179{left:97px;bottom:870px;letter-spacing:0.1px;word-spacing:0.04px;}
#t6_179{left:279px;bottom:888px;letter-spacing:0.12px;}
#t7_179{left:301px;bottom:870px;letter-spacing:0.02px;}
#t8_179{left:366px;bottom:879px;letter-spacing:0.07px;}
#t9_179{left:494px;bottom:879px;letter-spacing:0.01px;word-spacing:0.14px;}
#ta_179{left:168px;bottom:833px;}
#tb_179{left:179px;bottom:831px;letter-spacing:-0.05px;}
#tc_179{left:287px;bottom:833px;letter-spacing:0.14px;}
#td_179{left:388px;bottom:833px;}
#te_179{left:443px;bottom:842px;letter-spacing:0.07px;word-spacing:0.06px;}
#tf_179{left:443px;bottom:824px;letter-spacing:0.12px;}
#tg_179{left:168px;bottom:790px;}
#th_179{left:179px;bottom:788px;letter-spacing:-0.05px;}
#ti_179{left:292px;bottom:790px;letter-spacing:0.14px;}
#tj_179{left:388px;bottom:790px;}
#tk_179{left:443px;bottom:799px;letter-spacing:0.08px;word-spacing:0.05px;}
#tl_179{left:443px;bottom:781px;letter-spacing:0.12px;}
#tm_179{left:168px;bottom:746px;}
#tn_179{left:179px;bottom:744px;letter-spacing:-0.05px;}
#to_179{left:287px;bottom:746px;letter-spacing:0.14px;}
#tp_179{left:388px;bottom:746px;}
#tq_179{left:443px;bottom:756px;letter-spacing:0.12px;word-spacing:0.02px;}
#tr_179{left:443px;bottom:737px;letter-spacing:0.06px;word-spacing:0.08px;}
#ts_179{left:170px;bottom:712px;}
#tt_179{left:182px;bottom:710px;}
#tu_179{left:287px;bottom:712px;letter-spacing:0.14px;}
#tv_179{left:388px;bottom:712px;}
#tw_179{left:443px;bottom:712px;letter-spacing:0.1px;word-spacing:0.04px;}
#tx_179{left:170px;bottom:677px;}
#ty_179{left:182px;bottom:675px;}
#tz_179{left:287px;bottom:677px;letter-spacing:0.14px;}
#t10_179{left:388px;bottom:677px;}
#t11_179{left:443px;bottom:686px;letter-spacing:0.14px;}
#t12_179{left:443px;bottom:668px;letter-spacing:0.12px;}
#t13_179{left:168px;bottom:643px;}
#t14_179{left:179px;bottom:640px;letter-spacing:-0.54px;}
#t15_179{left:287px;bottom:643px;letter-spacing:0.14px;}
#t16_179{left:388px;bottom:643px;}
#t17_179{left:443px;bottom:643px;letter-spacing:0.07px;word-spacing:0.08px;}
#t18_179{left:168px;bottom:608px;}
#t19_179{left:179px;bottom:606px;letter-spacing:-0.05px;}
#t1a_179{left:287px;bottom:608px;letter-spacing:0.14px;}
#t1b_179{left:388px;bottom:608px;}
#t1c_179{left:443px;bottom:617px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1d_179{left:443px;bottom:599px;letter-spacing:0.09px;word-spacing:0.06px;}
#t1e_179{left:168px;bottom:573px;}
#t1f_179{left:179px;bottom:571px;letter-spacing:-0.05px;}
#t1g_179{left:287px;bottom:573px;letter-spacing:0.14px;}
#t1h_179{left:388px;bottom:573px;}
#t1i_179{left:443px;bottom:573px;letter-spacing:0.14px;word-spacing:0.01px;}
#t1j_179{left:168px;bottom:547px;}
#t1k_179{left:179px;bottom:545px;letter-spacing:-0.05px;}
#t1l_179{left:287px;bottom:547px;letter-spacing:0.14px;}
#t1m_179{left:388px;bottom:547px;}
#t1n_179{left:443px;bottom:547px;letter-spacing:0.11px;word-spacing:0.05px;}
#t1o_179{left:168px;bottom:512px;}
#t1p_179{left:179px;bottom:510px;letter-spacing:-0.05px;}
#t1q_179{left:287px;bottom:512px;letter-spacing:0.14px;}
#t1r_179{left:384px;bottom:512px;letter-spacing:0.15px;}
#t1s_179{left:443px;bottom:521px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1t_179{left:443px;bottom:503px;letter-spacing:0.12px;}
#t1u_179{left:170px;bottom:476px;}
#t1v_179{left:182px;bottom:474px;}
#t1w_179{left:287px;bottom:476px;letter-spacing:0.14px;}
#t1x_179{left:384px;bottom:476px;letter-spacing:-0.69px;}
#t1y_179{left:443px;bottom:476px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1z_179{left:168px;bottom:450px;}
#t20_179{left:179px;bottom:448px;letter-spacing:-0.05px;}
#t21_179{left:287px;bottom:450px;letter-spacing:0.14px;}
#t22_179{left:384px;bottom:450px;letter-spacing:0.15px;}
#t23_179{left:443px;bottom:450px;letter-spacing:0.1px;word-spacing:0.04px;}
#t24_179{left:170px;bottom:417px;}
#t25_179{left:182px;bottom:415px;}
#t26_179{left:287px;bottom:417px;letter-spacing:0.14px;}
#t27_179{left:384px;bottom:417px;letter-spacing:0.15px;}
#t28_179{left:443px;bottom:426px;letter-spacing:0.07px;word-spacing:0.06px;}
#t29_179{left:443px;bottom:408px;letter-spacing:0.12px;}
#t2a_179{left:170px;bottom:384px;}
#t2b_179{left:182px;bottom:382px;}
#t2c_179{left:287px;bottom:384px;letter-spacing:0.14px;}
#t2d_179{left:384px;bottom:384px;letter-spacing:0.15px;}
#t2e_179{left:443px;bottom:384px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2f_179{left:170px;bottom:358px;}
#t2g_179{left:182px;bottom:356px;}
#t2h_179{left:287px;bottom:358px;letter-spacing:0.14px;}
#t2i_179{left:384px;bottom:358px;letter-spacing:0.15px;}
#t2j_179{left:443px;bottom:358px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2k_179{left:170px;bottom:323px;}
#t2l_179{left:182px;bottom:321px;}
#t2m_179{left:287px;bottom:323px;letter-spacing:0.14px;}
#t2n_179{left:384px;bottom:323px;letter-spacing:0.15px;}
#t2o_179{left:443px;bottom:333px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2p_179{left:443px;bottom:314px;letter-spacing:0.12px;}
#t2q_179{left:170px;bottom:280px;}
#t2r_179{left:182px;bottom:278px;}
#t2s_179{left:287px;bottom:280px;letter-spacing:0.14px;}
#t2t_179{left:384px;bottom:280px;letter-spacing:0.15px;}
#t2u_179{left:443px;bottom:280px;letter-spacing:0.09px;word-spacing:0.05px;}
#t2v_179{left:170px;bottom:237px;}
#t2w_179{left:182px;bottom:235px;}
#t2x_179{left:311px;bottom:237px;}
#t2y_179{left:384px;bottom:237px;letter-spacing:0.15px;}
#t2z_179{left:443px;bottom:246px;letter-spacing:0.1px;word-spacing:0.04px;}
#t30_179{left:443px;bottom:228px;letter-spacing:0.12px;}
#t31_179{left:87px;bottom:187px;letter-spacing:-0.14px;word-spacing:-0.15px;}
#t32_179{left:87px;bottom:167px;letter-spacing:-0.16px;word-spacing:4.64px;}
#t33_179{left:87px;bottom:147px;letter-spacing:-0.17px;word-spacing:2.97px;}
#t34_179{left:87px;bottom:127px;letter-spacing:-0.14px;word-spacing:2.58px;}

.s0_179{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_179{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_179{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s3_179{font-size:15px;font-family:Arial-BoldMT_1lt;color:#000;}
.s4_179{font-size:9px;font-family:Arial-BoldMT_1li;color:#000;}
.s5_179{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s6_179{font-size:9px;font-family:Arial-BoldMT_1lt;color:#000;}
.s7_179{font-size:15px;font-family:ArialMT_1lq;color:#000;}
.s8_179{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts179" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_1lt;
	src: url("fonts/Arial-BoldMT_1lt.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg179" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="179/179.svg"
          type="image/svg+xml"
          id="pdf179"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_179" class="t s0_179">
          179{" "}
        </span>
        <span id="t2_179" class="t s1_179">
          Хүснэгт 3.26. Ерөнхий боловсролын зарим сургуулийн удирдах ажилтны
          чадварыг{" "}
        </span>
        <span id="t3_179" class="t s1_179">
          багш нарын чадварт үндэслэн эрэмбэлсэн нь{" "}
        </span>
        <span id="t4_179" class="t s2_179">
          Удирдах чадварыг{" "}
        </span>
        <span id="t5_179" class="t s2_179">
          товчилсон тэмдэглэгээ{" "}
        </span>
        <span id="t6_179" class="t s2_179">
          Эрэмбийн{" "}
        </span>
        <span id="t7_179" class="t s2_179">
          утга{" "}
        </span>
        <span id="t8_179" class="t s2_179">
          Эрэмбэ{" "}
        </span>
        <span id="t9_179" class="t s2_179">
          Удирдах чадвар{" "}
        </span>
        <span id="ta_179" class="t s3_179">
          A{" "}
        </span>
        <span id="tb_179" class="t s4_179">
          12{" "}
        </span>
        <span id="tc_179" class="t s2_179">
          0.10716{" "}
        </span>
        <span id="td_179" class="t s5_179">
          1{" "}
        </span>
        <span id="te_179" class="t s2_179">
          Зохицуулан зохион байгуулах{" "}
        </span>
        <span id="tf_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="tg_179" class="t s3_179">
          A{" "}
        </span>
        <span id="th_179" class="t s4_179">
          17{" "}
        </span>
        <span id="ti_179" class="t s2_179">
          0.1021{" "}
        </span>
        <span id="tj_179" class="t s5_179">
          2{" "}
        </span>
        <span id="tk_179" class="t s2_179">
          Гүйцэтгэлийн үр дүнг тооцох{" "}
        </span>
        <span id="tl_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="tm_179" class="t s3_179">
          A{" "}
        </span>
        <span id="tn_179" class="t s4_179">
          15{" "}
        </span>
        <span id="to_179" class="t s2_179">
          0.09368{" "}
        </span>
        <span id="tp_179" class="t s5_179">
          3{" "}
        </span>
        <span id="tq_179" class="t s2_179">
          Өөрийгөө илэрхийлэх,{" "}
        </span>
        <span id="tr_179" class="t s2_179">
          удирдах чадвар{" "}
        </span>
        <span id="ts_179" class="t s3_179">
          A{" "}
        </span>
        <span id="tt_179" class="t s6_179">
          9{" "}
        </span>
        <span id="tu_179" class="t s2_179">
          0.08014{" "}
        </span>
        <span id="tv_179" class="t s5_179">
          4{" "}
        </span>
        <span id="tw_179" class="t s2_179">
          Үр дүнг үнэлэх чадвар{" "}
        </span>
        <span id="tx_179" class="t s3_179">
          A{" "}
        </span>
        <span id="ty_179" class="t s6_179">
          8{" "}
        </span>
        <span id="tz_179" class="t s2_179">
          0.06588{" "}
        </span>
        <span id="t10_179" class="t s5_179">
          5{" "}
        </span>
        <span id="t11_179" class="t s2_179">
          Санал, санаачлагыг дэмжих{" "}
        </span>
        <span id="t12_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="t13_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t14_179" class="t s4_179">
          11{" "}
        </span>
        <span id="t15_179" class="t s2_179">
          0.06333{" "}
        </span>
        <span id="t16_179" class="t s3_179">
          6{" "}
        </span>
        <span id="t17_179" class="t s2_179">
          Бусдад боломж олгох чадвар{" "}
        </span>
        <span id="t18_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t19_179" class="t s4_179">
          16{" "}
        </span>
        <span id="t1a_179" class="t s2_179">
          0.06277{" "}
        </span>
        <span id="t1b_179" class="t s5_179">
          7{" "}
        </span>
        <span id="t1c_179" class="t s2_179">
          Бусдын хичээл зүтгэлийг{" "}
        </span>
        <span id="t1d_179" class="t s2_179">
          мэдрэх чадвар{" "}
        </span>
        <span id="t1e_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t1f_179" class="t s4_179">
          10{" "}
        </span>
        <span id="t1g_179" class="t s2_179">
          0.06005{" "}
        </span>
        <span id="t1h_179" class="t s3_179">
          8{" "}
        </span>
        <span id="t1i_179" class="t s2_179">
          Хамтран ажиллах чадвар{" "}
        </span>
        <span id="t1j_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t1k_179" class="t s4_179">
          18{" "}
        </span>
        <span id="t1l_179" class="t s2_179">
          0.05497{" "}
        </span>
        <span id="t1m_179" class="t s3_179">
          9{" "}
        </span>
        <span id="t1n_179" class="t s2_179">
          Урамшуулан дэмжих чадвар{" "}
        </span>
        <span id="t1o_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t1p_179" class="t s4_179">
          14{" "}
        </span>
        <span id="t1q_179" class="t s2_179">
          0.05442{" "}
        </span>
        <span id="t1r_179" class="t s5_179">
          10{" "}
        </span>
        <span id="t1s_179" class="t s2_179">
          Бусдыг хүндэтгэх, дэмжих{" "}
        </span>
        <span id="t1t_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="t1u_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t1v_179" class="t s4_179">
          1{" "}
        </span>
        <span id="t1w_179" class="t s2_179">
          0.04458{" "}
        </span>
        <span id="t1x_179" class="t s5_179">
          11{" "}
        </span>
        <span id="t1y_179" class="t s2_179">
          Ирээдүйг угтан дүгнэх чадвар{" "}
        </span>
        <span id="t1z_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t20_179" class="t s4_179">
          13{" "}
        </span>
        <span id="t21_179" class="t s2_179">
          0.04258{" "}
        </span>
        <span id="t22_179" class="t s5_179">
          12{" "}
        </span>
        <span id="t23_179" class="t s2_179">
          Бусдыг сонсох, ойлгох чадвар{" "}
        </span>
        <span id="t24_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t25_179" class="t s4_179">
          3{" "}
        </span>
        <span id="t26_179" class="t s2_179">
          0.03942{" "}
        </span>
        <span id="t27_179" class="t s5_179">
          13{" "}
        </span>
        <span id="t28_179" class="t s2_179">
          Нийтлэг зорилгод нэгтгэх{" "}
        </span>
        <span id="t29_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="t2a_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t2b_179" class="t s4_179">
          5{" "}
        </span>
        <span id="t2c_179" class="t s2_179">
          0.02177{" "}
        </span>
        <span id="t2d_179" class="t s5_179">
          14{" "}
        </span>
        <span id="t2e_179" class="t s2_179">
          Бусдад нөлөөлөх чадвар{" "}
        </span>
        <span id="t2f_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t2g_179" class="t s4_179">
          7{" "}
        </span>
        <span id="t2h_179" class="t s2_179">
          0.01559{" "}
        </span>
        <span id="t2i_179" class="t s5_179">
          15{" "}
        </span>
        <span id="t2j_179" class="t s2_179">
          Шинийг эрэлхийлэх чадвар{" "}
        </span>
        <span id="t2k_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t2l_179" class="t s4_179">
          2{" "}
        </span>
        <span id="t2m_179" class="t s2_179">
          0.00641{" "}
        </span>
        <span id="t2n_179" class="t s5_179">
          16{" "}
        </span>
        <span id="t2o_179" class="t s2_179">
          Нөхцөл байдлыг системчлэх{" "}
        </span>
        <span id="t2p_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="t2q_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t2r_179" class="t s6_179">
          6{" "}
        </span>
        <span id="t2s_179" class="t s2_179">
          0.00415{" "}
        </span>
        <span id="t2t_179" class="t s5_179">
          17{" "}
        </span>
        <span id="t2u_179" class="t s2_179">
          Бусдыг дагуулах чадвар{" "}
        </span>
        <span id="t2v_179" class="t s3_179">
          A{" "}
        </span>
        <span id="t2w_179" class="t s4_179">
          4{" "}
        </span>
        <span id="t2x_179" class="t s7_179">
          0{" "}
        </span>
        <span id="t2y_179" class="t s5_179">
          18{" "}
        </span>
        <span id="t2z_179" class="t s2_179">
          Өөрийгөө байнга хөгжүүлэх{" "}
        </span>
        <span id="t30_179" class="t s2_179">
          чадвар{" "}
        </span>
        <span id="t31_179" class="t s8_179">
          Дээрх хүснэгт 12 –оос харахад тухайн удирдах ажилтны зохицуулан зохион{" "}
        </span>
        <span id="t32_179" class="t s8_179">
          байгуулах, гүйцэтгэлийн үр дүнг тооцох, өөрийгөө илэрхийлэх, удирдах{" "}
        </span>
        <span id="t33_179" class="t s8_179">
          чадвар бусад чадваруудаас дээгүүр эрэмбэлэгдсэн бол өөрийгөө байнга{" "}
        </span>
        <span id="t34_179" class="t s8_179">
          хөгжүүлэх, бусдыг дагуулах, нөхцөл байдлыг системчлэх чадвар доогуур{" "}
        </span>
      </div>
    </div>
  );
}
