import React from "react";
export default function FiftyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_59{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_59{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_59{left:87px;bottom:941px;letter-spacing:0.07px;word-spacing:3.08px;}
#t4_59{left:87px;bottom:921px;letter-spacing:0.06px;word-spacing:8.24px;}
#t5_59{left:87px;bottom:901px;letter-spacing:0.06px;word-spacing:2.69px;}
#t6_59{left:87px;bottom:880px;letter-spacing:0.07px;}
#t7_59{left:159px;bottom:852px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t8_59{left:87px;bottom:477px;letter-spacing:0.07px;}
#t9_59{left:190px;bottom:477px;letter-spacing:0.07px;}
#ta_59{left:317px;bottom:477px;letter-spacing:0.08px;}
#tb_59{left:436px;bottom:477px;letter-spacing:0.11px;}
#tc_59{left:473px;bottom:477px;letter-spacing:0.07px;}
#td_59{left:573px;bottom:477px;letter-spacing:0.07px;}
#te_59{left:87px;bottom:457px;letter-spacing:0.06px;word-spacing:8.31px;}
#tf_59{left:87px;bottom:437px;letter-spacing:0.07px;word-spacing:5.29px;}
#tg_59{left:87px;bottom:416px;letter-spacing:0.07px;word-spacing:3.32px;}
#th_59{left:87px;bottom:396px;letter-spacing:0.07px;word-spacing:-1.24px;}
#ti_59{left:87px;bottom:376px;letter-spacing:0.06px;word-spacing:5.3px;}
#tj_59{left:87px;bottom:356px;letter-spacing:0.04px;word-spacing:4.96px;}
#tk_59{left:653px;bottom:356px;letter-spacing:0.1px;}
#tl_59{left:87px;bottom:336px;letter-spacing:0.06px;word-spacing:2.91px;}
#tm_59{left:406px;bottom:336px;letter-spacing:0.07px;word-spacing:2.9px;}
#tn_59{left:87px;bottom:316px;letter-spacing:0.07px;}

.s0_59{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_59{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_59{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_59{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s4_59{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.t.m0_59{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts59" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg59" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="59/59.svg"
          type="image/svg+xml"
          id="pdf59"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_59" class="t s1_59">
          59{" "}
        </span>
        <span id="t3_59" class="t s2_59">
          Үр дүнд нь сонгосон мэдээлэл “Excel” файлаар татагдаж гарах бөгөөд{" "}
        </span>
        <span id="t4_59" class="t s2_59">
          түүнийг хуулж авснаар тухайн мэдээллийг ямар хүснэгтээр хэрхэн{" "}
        </span>
        <span id="t5_59" class="t s2_59">
          ашиглахыгаа Та бүхэн шийдэж, түүнийг ямар мэдээлэлтэй харьцуулах,{" "}
        </span>
        <span id="t6_59" class="t s2_59">
          ямар шинжилгээ хийх талаараа хамтдаа зөвлөлдөөрэй.{" "}
        </span>
        <span id="t7_59" class="t s3_59">
          Зураг 2.10. Сургалтын байгууллагын үнэлгээний үр дүн (үзүүлэлтээр){" "}
        </span>
        <span id="t8_59" class="t s2_59">
          Сургалтын{" "}
        </span>
        <span id="t9_59" class="t s2_59">
          байгууллагын{" "}
        </span>
        <span id="ta_59" class="t s2_59">
          гүйцэтгэлийг{" "}
        </span>
        <span id="tb_59" class="t s2_59">
          “1.{" "}
        </span>
        <span id="tc_59" class="t s2_59">
          сургалтын{" "}
        </span>
        <span id="td_59" class="t s2_59">
          хөтөлбөрийн{" "}
        </span>
        <span id="te_59" class="t s2_59">
          хэрэгжилт”, “2. суралцагчийн хөгжил хамгаалал” “3. Багш ажилтны{" "}
        </span>
        <span id="tf_59" class="t s2_59">
          тасралтгүй хөгжил”, “4. Сургалтын орчин бүрдүүлэлт”, “5. Удирдлага,{" "}
        </span>
        <span id="tg_59" class="t s2_59">
          манлайлал”, “6. Хэрэглэгчдийн сэтгэл ханамж” гэсэн шалгуур, шалгуур{" "}
        </span>
        <span id="th_59" class="t s2_59">
          бүрд хамаарах 13 үзүүлэлтээр үнэлсэн бөгөөд та бүхэн өөрийн сургуулийн{" "}
        </span>
        <span id="ti_59" class="t s2_59">
          шалгуур, үзүүлэлт тус бүрээр авсан оноог хоёр оноор, боловсролын{" "}
        </span>
        <span id="tj_59" class="t s2_59">
          түвшнээр, багшаар харьцуулан шинжлэх боломжтой. Зураг 2.11-д{" "}
        </span>
        <span id="tk_59" class="t s4_59">
          “2.{" "}
        </span>
        <span id="tl_59" class="t s4_59">
          Суралцагчийн хөгжил, хамгаалал”,{" "}
        </span>
        <span id="tm_59" class="t s2_59">
          түүнд хамаарах 3 үзүүлэлт бүрд{" "}
        </span>
        <span id="tn_59" class="t s2_59">
          тухайн сургуулийн үнэлгээний үр дүнг харуулсан байна.{" "}
        </span>
      </div>
    </div>
  );
}
