import React from "react";
export default function HundredTwentyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_124{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_124{left:87px;bottom:936px;letter-spacing:0.19px;word-spacing:13.53px;}
#t3_124{left:87px;bottom:910px;letter-spacing:0.17px;word-spacing:0.03px;}
#t4_124{left:87px;bottom:879px;letter-spacing:-0.16px;word-spacing:-0.65px;}
#t5_124{left:87px;bottom:859px;letter-spacing:-0.12px;word-spacing:4.45px;}
#t6_124{left:87px;bottom:838px;letter-spacing:-0.11px;word-spacing:3.95px;}
#t7_124{left:87px;bottom:818px;letter-spacing:-0.13px;word-spacing:-0.37px;}
#t8_124{left:87px;bottom:798px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_124{left:87px;bottom:765px;letter-spacing:-0.13px;word-spacing:0.02px;}
#ta_124{left:87px;bottom:745px;letter-spacing:-0.17px;word-spacing:-0.9px;}
#tb_124{left:87px;bottom:725px;letter-spacing:-0.16px;word-spacing:2.63px;}
#tc_124{left:87px;bottom:704px;letter-spacing:-0.12px;word-spacing:-0.89px;}
#td_124{left:87px;bottom:684px;letter-spacing:-0.13px;word-spacing:0.65px;}
#te_124{left:87px;bottom:664px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tf_124{left:142px;bottom:604px;letter-spacing:-0.03px;}
#tg_124{left:143px;bottom:577px;letter-spacing:-0.18px;word-spacing:9.49px;}
#th_124{left:143px;bottom:559px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ti_124{left:142px;bottom:500px;letter-spacing:0.11px;word-spacing:0.12px;}
#tj_124{left:104px;bottom:371px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tk_124{left:142px;bottom:292px;letter-spacing:0.18px;}
#tl_124{left:87px;bottom:259px;letter-spacing:-0.12px;word-spacing:5.54px;}
#tm_124{left:87px;bottom:239px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tn_124{left:87px;bottom:210px;letter-spacing:-0.16px;word-spacing:3.37px;}
#to_124{left:87px;bottom:190px;letter-spacing:-0.11px;word-spacing:5.02px;}
#tp_124{left:87px;bottom:170px;letter-spacing:-0.16px;word-spacing:6.41px;}
#tq_124{left:87px;bottom:150px;letter-spacing:-0.15px;word-spacing:8.96px;}
#tr_124{left:87px;bottom:129px;letter-spacing:-0.12px;word-spacing:-0.86px;}

.s0_124{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_124{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_124{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_124{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s4_124{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s5_124{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_124{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts124" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg124" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="124/124.svg"
          type="image/svg+xml"
          id="pdf124"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_124" class="t s0_124">
          124{" "}
        </span>
        <span id="t2_124" class="t s1_124">
          Эцсийн үнэлгээнээс илүү явцын үнэлгээг яагаад{" "}
        </span>
        <span id="t3_124" class="t s1_124">
          чухалчлах ёстой вэ?{" "}
        </span>
        <span id="t4_124" class="t s2_124">
          Бид сургалт, үйл ажиллагааны дараа үр дүнг хэмжиж, тоон утгаар
          эрэмбэлж{" "}
        </span>
        <span id="t5_124" class="t s2_124">
          жагсаахаас илүүтэй явцын үр дүнг хэмжиж, суралцах явцад нь алдааг{" "}
        </span>
        <span id="t6_124" class="t s2_124">
          нь засахад дэмжлэг үзүүлэх, ахиц дэвшлийг урамшуулан цаашдын үйл{" "}
        </span>
        <span id="t7_124" class="t s2_124">
          ажиллагаанд нь урам өгч сэдэлжүүлэх замаар суралцахуйг сайжруулж үзэл{" "}
        </span>
        <span id="t8_124" class="t s2_124">
          баримтлал түлхүү хэрэгжих байна.{" "}
        </span>
        <span id="t9_124" class="t s2_124">
          Хүн хэдийгээр олон давтамжит үйл ажиллагааны үр дүнд чадвар, хандлага{" "}
        </span>
        <span id="ta_124" class="t s2_124">
          төлөвшин тогтдог хэдий ч нэгэнт буруу тогтсон мэдлэг, чадвар дадлыг
          араас{" "}
        </span>
        <span id="tb_124" class="t s2_124">
          нь засахад хүндрэлтэй, цаг хугацаа их шаарддаг. Иймд сурагч яг тухайн{" "}
        </span>
        <span id="tc_124" class="t s2_124">
          үйлийг гүйцэтгэж байх явцад нь алдааг нь засаж, аргад нь дадуулан, зөв
          рүү{" "}
        </span>
        <span id="td_124" class="t s2_124">
          нь чиглүүлж өгснөөр сурагчийн сурах идэвх урам нэмэгдэн, мэдлэг чадвар{" "}
        </span>
        <span id="te_124" class="t s2_124">
          богино хугацаанд зөв бүрэлдэх, боломжтой болдог.{" "}
        </span>
        <span id="tf_124" class="t s3_124">
          АСУУДАЛ{" "}
        </span>
        <span id="tg_124" class="t s4_124">
          Үр дүнгийн буюу эцсийн үнэлгээнд түлхүү анхаардаг, явцын{" "}
        </span>
        <span id="th_124" class="t s4_124">
          үнэлгээний ач холбогдлыг ойлгодоггүй явцын үнэлгээг сул хийдэг{" "}
        </span>
        <span id="ti_124" class="t s5_124">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tj_124" class="t s6_124">
          Явцын үнэлгээг ирц идэвх оролцоог ерөнхий байдлаар оноожуулан үнэлж
          байна.{" "}
        </span>
        <span id="tk_124" class="t s5_124">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tl_124" class="t s2_124">
          Багшлахуй ба суралцахуйн үйл ажиллагааны үндсэн хэсэг бол явцын{" "}
        </span>
        <span id="tm_124" class="t s2_124">
          үнэлгээ юм.{" "}
        </span>
        <span id="tn_124" class="t s2_124">
          Явцын үнэлгээг анги танхимд хэрэгжүүлнэ гэдэг нь хичээл, сургалт, үйл{" "}
        </span>
        <span id="to_124" class="t s2_124">
          ажиллагааг сурагч бүрийн хэрэгцээнд нийцүүлнэ гэсэн үе юм. Өөрөөр{" "}
        </span>
        <span id="tp_124" class="t s2_124">
          хэлбэл, багш хичээл үйл ажиллагааны үед сурагчийн даалгавар, үйл{" "}
        </span>
        <span id="tq_124" class="t s2_124">
          ажиллагааны гүйцэтгэлд байнга ажиглалт тэмдэглээгээ хийж алдааг{" "}
        </span>
        <span id="tr_124" class="t s2_124">
          засахад чиглүүлэх ахицыг урамшуулан дэмжих замаар суралцахуйг дэмжих{" "}
        </span>
      </div>
    </div>
  );
}
