import React from "react";
export default function OneHundredSixtyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_163{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_163{left:87px;bottom:941px;letter-spacing:-0.14px;word-spacing:4.5px;}
#t3_163{left:87px;bottom:923px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t4_163{left:91px;bottom:880px;letter-spacing:-0.17px;word-spacing:0.6px;}
#t5_163{left:91px;bottom:865px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t6_163{left:120px;bottom:838px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t7_163{left:162px;bottom:823px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t8_163{left:412px;bottom:838px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t9_163{left:411px;bottom:823px;letter-spacing:-0.17px;word-spacing:0.04px;}
#ta_163{left:104px;bottom:796px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tb_163{left:196px;bottom:780px;letter-spacing:-0.13px;}
#tc_163{left:409px;bottom:796px;letter-spacing:-0.12px;word-spacing:-0.01px;}
#td_163{left:486px;bottom:780px;letter-spacing:-0.13px;}
#te_163{left:100px;bottom:636px;letter-spacing:-0.2px;word-spacing:0.08px;}
#tf_163{left:113px;bottom:621px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tg_163{left:123px;bottom:606px;letter-spacing:-0.17px;word-spacing:0.04px;}
#th_163{left:394px;bottom:636px;letter-spacing:-0.19px;word-spacing:0.07px;}
#ti_163{left:403px;bottom:621px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tj_163{left:437px;bottom:606px;letter-spacing:-0.19px;word-spacing:0.05px;}
#tk_163{left:91px;bottom:578px;letter-spacing:-0.15px;word-spacing:0.01px;}
#tl_163{left:216px;bottom:563px;letter-spacing:-0.13px;}
#tm_163{left:409px;bottom:578px;letter-spacing:-0.12px;word-spacing:-0.01px;}
#tn_163{left:486px;bottom:563px;letter-spacing:-0.13px;}
#to_163{left:192px;bottom:420px;letter-spacing:-0.18px;word-spacing:0.05px;}
#tp_163{left:209px;bottom:393px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tq_163{left:87px;bottom:238px;letter-spacing:-0.17px;word-spacing:11.8px;}
#tr_163{left:87px;bottom:217px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ts_163{left:87px;bottom:184px;letter-spacing:-0.14px;word-spacing:2.68px;}
#tt_163{left:87px;bottom:164px;letter-spacing:-0.15px;word-spacing:2.81px;}
#tu_163{left:87px;bottom:144px;letter-spacing:-0.15px;word-spacing:4.73px;}
#tv_163{left:87px;bottom:124px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_163{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_163{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s2_163{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_163{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts163" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg163" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="163/163.svg"
          type="image/svg+xml"
          id="pdf163"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_163" class="t s0_163">
          163{" "}
        </span>
        <span id="t2_163" class="t s1_163">
          Сургалтын орчин дахь материаллаг нөөц, хүртээмжийн өөрийн үнэлгээ{" "}
        </span>
        <span id="t3_163" class="t s1_163">
          хийхдээ дараах үнэлгээний хэрэгсэл, зөвлөмжийг ашиглаж болно. Үүнд:{" "}
        </span>
        <span id="t4_163" class="t s2_163">
          Сургалтын орчинд зайлшгүй шаардлагатай тоног төхөөрөмж, сургалтын
          хэрэглэгдэхүүний{" "}
        </span>
        <span id="t5_163" class="t s2_163">
          жагсаалтад тавигдах шаардлага, түүнд баримжаалсан хяналтын хуудас{" "}
        </span>
        <span id="t6_163" class="t s2_163">
          Сургалтын хэрэглэгдэхүүний жишиг{" "}
        </span>
        <span id="t7_163" class="t s2_163">
          жагсаалт батлах тухай{" "}
        </span>
        <span id="t8_163" class="t s2_163">
          Ерөнхий боловсрол эзэмшүүлэх үйл{" "}
        </span>
        <span id="t9_163" class="t s2_163">
          ажиллагааг шалгах хяналтын хуудас{" "}
        </span>
        <span id="ta_163" class="t s2_163">
          БСҮХ-ийн захирлын тушаал. 2022.10.28.{" "}
        </span>
        <span id="tb_163" class="t s2_163">
          Дугаар А/70{" "}
        </span>
        <span id="tc_163" class="t s2_163">
          БШУ-ны Сайдын тушаал. 2023.03.28.{" "}
        </span>
        <span id="td_163" class="t s2_163">
          Дугаар А/127{" "}
        </span>
        <span id="te_163" class="t s2_163">
          Ерөнхий боловсролын сургуульд кабинет,{" "}
        </span>
        <span id="tf_163" class="t s2_163">
          лаборатори, цахим сургалтын танхим{" "}
        </span>
        <span id="tg_163" class="t s2_163">
          байгуулах, ашиглах нийтлэг журам{" "}
        </span>
        <span id="th_163" class="t s2_163">
          Цахим сурах бичиг, ерөнхий боловсролын{" "}
        </span>
        <span id="ti_163" class="t s2_163">
          сургалтад ашиглах сургалтын нээлттэй{" "}
        </span>
        <span id="tj_163" class="t s2_163">
          материалд тавих шаардлага{" "}
        </span>
        <span id="tk_163" class="t s2_163">
          БШУ-ны Сайдын тушаал. 2022.11.14. Дугаар{" "}
        </span>
        <span id="tl_163" class="t s2_163">
          А/465{" "}
        </span>
        <span id="tm_163" class="t s2_163">
          БШУ-ны Сайдын тушаал. 2021.05.21.{" "}
        </span>
        <span id="tn_163" class="t s2_163">
          Дугаар А/193{" "}
        </span>
        <span id="to_163" class="t s2_163">
          Хувьсах зардлын дундаж норматив, аргачлал батлах тухай{" "}
        </span>
        <span id="tp_163" class="t s2_163">
          МУ-ын Засгийн газрын тогтоол. 2022.03.02. Дугаар 91{" "}
        </span>
        <span id="tq_163" class="t s3_163">
          Тухайн сургуулийн онцлогоос хамаарч өөрийн үнэлгээний хуудсыг{" "}
        </span>
        <span id="tr_163" class="t s3_163">
          сайжруулан боловсруулж болно.{" "}
        </span>
        <span id="ts_163" class="t s3_163">
          Сургууль өөрийн үнэлгээгээр илрүүлсэн материаллаг нөөцтэй холбоотой{" "}
        </span>
        <span id="tt_163" class="t s3_163">
          асуудлуудыг ямар хугацаанд хэрхэн яаж шийдвэрлэх талаар төлөвлөгөө{" "}
        </span>
        <span id="tu_163" class="t s3_163">
          гарган, дотоод нөөц бололцоо, бие даасан төсөл хөтөлбөрөөр шийдэх{" "}
        </span>
        <span id="tv_163" class="t s3_163">
          боломжтой юу гэх мэтээр шинжилгээ хийнэ.{" "}
        </span>
      </div>
    </div>
  );
}
