import React from "react";
export default function F150() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_150{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_150{left:142px;bottom:926px;letter-spacing:0.18px;}
#t3_150{left:87px;bottom:898px;letter-spacing:-0.17px;word-spacing:7.24px;}
#t4_150{left:87px;bottom:878px;letter-spacing:-0.1px;}
#t5_150{left:87px;bottom:853px;}
#t6_150{left:114px;bottom:853px;letter-spacing:-0.15px;word-spacing:2.24px;}
#t7_150{left:114px;bottom:833px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t8_150{left:87px;bottom:809px;}
#t9_150{left:114px;bottom:809px;letter-spacing:-0.15px;word-spacing:6.49px;}
#ta_150{left:114px;bottom:789px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tb_150{left:87px;bottom:764px;}
#tc_150{left:114px;bottom:764px;letter-spacing:-0.12px;word-spacing:2.14px;}
#td_150{left:114px;bottom:744px;letter-spacing:-0.14px;word-spacing:0.04px;}
#te_150{left:87px;bottom:719px;}
#tf_150{left:114px;bottom:719px;letter-spacing:-0.18px;word-spacing:4.17px;}
#tg_150{left:114px;bottom:699px;letter-spacing:-0.12px;word-spacing:0.02px;}
#th_150{left:87px;bottom:675px;}
#ti_150{left:114px;bottom:675px;letter-spacing:-0.15px;word-spacing:-1.2px;}
#tj_150{left:114px;bottom:655px;letter-spacing:-0.14px;word-spacing:-0.17px;}
#tk_150{left:114px;bottom:634px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_150{left:87px;bottom:610px;}
#tm_150{left:114px;bottom:610px;letter-spacing:-0.09px;}
#tn_150{left:172px;bottom:610px;letter-spacing:-0.09px;}
#to_150{left:239px;bottom:610px;letter-spacing:-0.17px;}
#tp_150{left:399px;bottom:610px;letter-spacing:-0.13px;}
#tq_150{left:482px;bottom:610px;letter-spacing:-0.18px;}
#tr_150{left:610px;bottom:610px;letter-spacing:-0.16px;}
#ts_150{left:114px;bottom:590px;letter-spacing:-0.14px;word-spacing:-0.72px;}
#tt_150{left:114px;bottom:570px;letter-spacing:-0.14px;word-spacing:4.96px;}
#tu_150{left:114px;bottom:549px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_150{left:87px;bottom:525px;}
#tw_150{left:114px;bottom:525px;letter-spacing:-0.21px;word-spacing:-1.61px;}
#tx_150{left:240px;bottom:525px;letter-spacing:-0.15px;word-spacing:-1.67px;}
#ty_150{left:114px;bottom:505px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tz_150{left:87px;bottom:480px;}
#t10_150{left:114px;bottom:480px;letter-spacing:-0.14px;word-spacing:-0.44px;}
#t11_150{left:114px;bottom:460px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t12_150{left:87px;bottom:436px;}
#t13_150{left:114px;bottom:436px;letter-spacing:-0.13px;word-spacing:4.75px;}
#t14_150{left:114px;bottom:415px;letter-spacing:-0.15px;word-spacing:6.55px;}
#t15_150{left:457px;bottom:415px;}
#t16_150{left:477px;bottom:415px;letter-spacing:-0.19px;word-spacing:6.59px;}
#t17_150{left:114px;bottom:395px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t18_150{left:87px;bottom:352px;letter-spacing:-0.14px;word-spacing:3.62px;}
#t19_150{left:87px;bottom:332px;letter-spacing:-0.15px;word-spacing:-0.73px;}
#t1a_150{left:87px;bottom:312px;letter-spacing:-0.15px;word-spacing:3.48px;}
#t1b_150{left:87px;bottom:292px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1c_150{left:87px;bottom:252px;letter-spacing:-0.35px;}
#t1d_150{left:146px;bottom:252px;letter-spacing:-0.22px;}
#t1e_150{left:239px;bottom:252px;letter-spacing:-0.09px;}
#t1f_150{left:347px;bottom:252px;letter-spacing:-0.22px;}
#t1g_150{left:457px;bottom:252px;letter-spacing:-0.25px;}
#t1h_150{left:87px;bottom:231px;letter-spacing:-0.15px;word-spacing:0.89px;}
#t1i_150{left:87px;bottom:211px;letter-spacing:-0.17px;}
#t1j_150{left:209px;bottom:211px;letter-spacing:-0.16px;}
#t1k_150{left:321px;bottom:211px;letter-spacing:-0.17px;}
#t1l_150{left:436px;bottom:211px;letter-spacing:-0.09px;}
#t1m_150{left:87px;bottom:191px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1n_150{left:308px;bottom:133px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1o_150{left:352px;bottom:116px;letter-spacing:-0.16px;word-spacing:0.04px;}

.s0_150{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_150{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_150{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_150{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_150{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_150{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_150{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts150" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg150" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="150/150.svg"
          type="image/svg+xml"
          id="pdf150"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_150" class="t s0_150">
          150{" "}
        </span>
        <span id="t2_150" class="t s1_150">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_150" class="t s2_150">
          Удирдлагын багаас дараах зохион байгуулалтыг хийхийг зөвлөж{" "}
        </span>
        <span id="t4_150" class="t s2_150">
          байна.{" "}
        </span>
        <span id="t5_150" class="t s3_150">
          •{" "}
        </span>
        <span id="t6_150" class="t s4_150">
          Танхим болон цахим технологи ашиглан мэргэжлийн болон заах арга{" "}
        </span>
        <span id="t7_150" class="t s4_150">
          зүйгээ хөгжүүлэхэд орчин нөхцөлөөр дэмжих{" "}
        </span>
        <span id="t8_150" class="t s3_150">
          •{" "}
        </span>
        <span id="t9_150" class="t s4_150">
          Уулзалтын цагийг харгалзан үзэж хичээлийн хуваарьт тухайн цагт{" "}
        </span>
        <span id="ta_150" class="t s4_150">
          хичээл тавихгүй байх{" "}
        </span>
        <span id="tb_150" class="t s3_150">
          •{" "}
        </span>
        <span id="tc_150" class="t s4_150">
          Багш нар хэсэг бүлгээрээ нэгдсэн платформ дээр суурилан харилцах{" "}
        </span>
        <span id="td_150" class="t s4_150">
          талбар үүсгэн зайн сургалтаар мэргэжлээ дээшлүүлэх боломж олгох{" "}
        </span>
        <span id="te_150" class="t s3_150">
          •{" "}
        </span>
        <span id="tf_150" class="t s4_150">
          Мэдээлэл нэгтгэх, өөрийн эргэлзээтэй ойлголтоо бататгах зорилгоор{" "}
        </span>
        <span id="tg_150" class="t s4_150">
          онлайн уулзалтыг хийх{" "}
        </span>
        <span id="th_150" class="t s3_150">
          •{" "}
        </span>
        <span id="ti_150" class="t s4_150">
          Онлайн хэлбэрийн уулзалтаар бие даан судалсан агуулгаараа мэдээлэл{" "}
        </span>
        <span id="tj_150" class="t s4_150">
          солилцох, санал бодлоо нэгтгэх, шинэ мэдлэг ойлголт өгөх танилцуулга{" "}
        </span>
        <span id="tk_150" class="t s4_150">
          хийх зэрэг арга хэлбэрээ чанаржуулах{" "}
        </span>
        <span id="tl_150" class="t s3_150">
          •{" "}
        </span>
        <span id="tm_150" class="t s4_150">
          Багш{" "}
        </span>
        <span id="tn_150" class="t s4_150">
          цахим{" "}
        </span>
        <span id="to_150" class="t s4_150">
          хэрэгдэгдэхүүнийг{" "}
        </span>
        <span id="tp_150" class="t s4_150">
          хамтран{" "}
        </span>
        <span id="tq_150" class="t s4_150">
          боловсруулан{" "}
        </span>
        <span id="tr_150" class="t s4_150">
          бэлтгэж,{" "}
        </span>
        <span id="ts_150" class="t s4_150">
          мэдээллийн технологийн болон мэргэжлийн мэдлэг чадвараа хөгжүүлэх{" "}
        </span>
        <span id="tt_150" class="t s4_150">
          боломж бүрдүүлэхийн зэрэгцээ хичээл, сургалтын үйл ажиллагаанд{" "}
        </span>
        <span id="tu_150" class="t s4_150">
          ашиглах дундын хэрэглэгдэхүүний сан үүсгэх{" "}
        </span>
        <span id="tv_150" class="t s3_150">
          •{" "}
        </span>
        <span id="tw_150" class="t s4_150">
          Танин мэдүүлэх{" "}
        </span>
        <span id="tx_150" class="t s4_150">
          (мультимедиа, интерактив технологийг эзэмших, түүгээр{" "}
        </span>
        <span id="ty_150" class="t s4_150">
          дамжуулан мэргэжлийн мэдлэг чадвраа дээшлүүлэх){" "}
        </span>
        <span id="tz_150" class="t s3_150">
          •{" "}
        </span>
        <span id="t10_150" class="t s4_150">
          Үйл ажиллагаанд хөтлөх (мэдээлэлд шинжилгээ хийх арга, хайх систем,{" "}
        </span>
        <span id="t11_150" class="t s4_150">
          машин болон мэдээлэл харилцааны технологийг эзэмших){" "}
        </span>
        <span id="t12_150" class="t s3_150">
          •{" "}
        </span>
        <span id="t13_150" class="t s4_150">
          Бие хүнийг хөгжүүлэх(түүний аналитик систем, харилцах ба холбоо{" "}
        </span>
        <span id="t14_150" class="t s4_150">
          тогтоох чадвар, хувирамтгай байдал) –{" "}
        </span>
        <span id="t15_150" class="t s5_150">
          д{" "}
        </span>
        <span id="t16_150" class="t s4_150">
          мэдээллийн технолгоийг{" "}
        </span>
        <span id="t17_150" class="t s4_150">
          оновчтой хэрэглэх арга зүйг боловсруулахад дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t18_150" class="t s4_150">
          Сургалтын цахим хэрэглэгдэхүүн нь сурагчийн сурах сэдэл төрүүлэхүйц{" "}
        </span>
        <span id="t19_150" class="t s4_150">
          байдал, агуулга нь бодит амьдралын асуудалд суурилсан байдал, тодорхой{" "}
        </span>
        <span id="t1a_150" class="t s4_150">
          үйл ажиллагаанд хөтөлсөн байдал, өргөн сонголттой байхыг шаардлага{" "}
        </span>
        <span id="t1b_150" class="t s4_150">
          болгон чиглүүлээрэй.{" "}
        </span>
        <span id="t1c_150" class="t s4_150">
          Таны{" "}
        </span>
        <span id="t1d_150" class="t s4_150">
          мэдээлэл{" "}
        </span>
        <span id="t1e_150" class="t s4_150">
          давхцаагүй{" "}
        </span>
        <span id="t1f_150" class="t s4_150">
          тохиолдолд{" "}
        </span>
        <span id="t1g_150" class="t s4_150">
          та{" "}
        </span>
        <span id="t1h_150" class="t s4_150">
          сургуулийн менежерт хандан өөрийн мэдээллийг{" "}
        </span>
        <span id="t1i_150" class="t s4_150">
          боловсролын{" "}
        </span>
        <span id="t1j_150" class="t s4_150">
          мэдээллийн{" "}
        </span>
        <span id="t1k_150" class="t s4_150">
          технологийн{" "}
        </span>
        <span id="t1l_150" class="t s4_150">
          ESIS{" "}
        </span>
        <span id="t1m_150" class="t s4_150">
          системд бүртгүүлнэ үү.{" "}
        </span>
        <span id="t1n_150" class="t s6_150">
          Зураг 3.25. ESIS системд{" "}
        </span>
        <span id="t1o_150" class="t s6_150">
          бүртгүүлэх заавар{" "}
        </span>
      </div>
    </div>
  );
}
