import React from "react";
export default function ThirtyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_35{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_35{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_35{left:108.8px;bottom:824.3px;letter-spacing:0.11px;}
#t4_35{left:123.4px;bottom:766px;letter-spacing:0.09px;word-spacing:0.03px;}
#t5_35{left:138.1px;bottom:840.9px;letter-spacing:0.11px;}
#t6_35{left:143px;bottom:937px;letter-spacing:0.06px;}
#t7_35{left:143px;bottom:923px;letter-spacing:0.09px;word-spacing:0.01px;}
#t8_35{left:143px;bottom:908px;letter-spacing:0.01px;}
#t9_35{left:143px;bottom:893px;letter-spacing:0.09px;}
#ta_35{left:143px;bottom:879px;letter-spacing:0.09px;word-spacing:0.01px;}
#tb_35{left:143px;bottom:864px;letter-spacing:0.1px;}
#tc_35{left:143px;bottom:849px;letter-spacing:0.09px;word-spacing:0.02px;}
#td_35{left:143px;bottom:835px;letter-spacing:0.08px;}
#te_35{left:143px;bottom:820px;letter-spacing:0.07px;}
#tf_35{left:143px;bottom:805px;letter-spacing:0.07px;}
#tg_35{left:230px;bottom:937px;letter-spacing:0.06px;}
#th_35{left:230px;bottom:923px;letter-spacing:0.03px;}
#ti_35{left:230px;bottom:908px;letter-spacing:0.01px;}
#tj_35{left:230px;bottom:893px;letter-spacing:0.08px;}
#tk_35{left:230px;bottom:879px;letter-spacing:0.11px;}
#tl_35{left:230px;bottom:864px;letter-spacing:0.09px;}
#tm_35{left:230px;bottom:849px;letter-spacing:0.1px;}
#tn_35{left:230px;bottom:835px;letter-spacing:0.03px;}
#to_35{left:230px;bottom:820px;letter-spacing:0.08px;}
#tp_35{left:230px;bottom:805px;letter-spacing:0.11px;}
#tq_35{left:230px;bottom:791px;letter-spacing:0.08px;}
#tr_35{left:230px;bottom:776px;letter-spacing:0.07px;}
#ts_35{left:322px;bottom:937px;letter-spacing:0.06px;}
#tt_35{left:322px;bottom:923px;letter-spacing:0.03px;}
#tu_35{left:322px;bottom:908px;letter-spacing:0.01px;}
#tv_35{left:322px;bottom:893px;letter-spacing:0.09px;word-spacing:0.01px;}
#tw_35{left:322px;bottom:879px;letter-spacing:-0.28px;}
#tx_35{left:322px;bottom:864px;letter-spacing:0.08px;}
#ty_35{left:322px;bottom:849px;letter-spacing:0.1px;}
#tz_35{left:322px;bottom:835px;letter-spacing:0.03px;}
#t10_35{left:404px;bottom:937px;letter-spacing:0.11px;}
#t11_35{left:404px;bottom:923px;letter-spacing:0.09px;}
#t12_35{left:404px;bottom:908px;letter-spacing:0.03px;word-spacing:0.07px;}
#t13_35{left:404px;bottom:893px;letter-spacing:0.11px;}
#t14_35{left:404px;bottom:879px;letter-spacing:0.08px;}
#t15_35{left:404px;bottom:864px;letter-spacing:0.03px;}
#t16_35{left:404px;bottom:849px;letter-spacing:0.08px;}
#t17_35{left:404px;bottom:835px;letter-spacing:0.12px;}
#t18_35{left:404px;bottom:820px;letter-spacing:0.09px;}
#t19_35{left:404px;bottom:805px;letter-spacing:0.04px;}
#t1a_35{left:404px;bottom:791px;letter-spacing:0.07px;}
#t1b_35{left:495px;bottom:937px;letter-spacing:0.08px;word-spacing:0.01px;}
#t1c_35{left:495px;bottom:923px;letter-spacing:0.1px;}
#t1d_35{left:495px;bottom:908px;letter-spacing:0.06px;}
#t1e_35{left:495px;bottom:893px;letter-spacing:0.11px;}
#t1f_35{left:495px;bottom:879px;letter-spacing:0.09px;}
#t1g_35{left:495px;bottom:864px;letter-spacing:0.08px;word-spacing:0.02px;}
#t1h_35{left:495px;bottom:849px;letter-spacing:0.08px;word-spacing:0.02px;}
#t1i_35{left:495px;bottom:835px;letter-spacing:0.09px;}
#t1j_35{left:495px;bottom:820px;letter-spacing:0.04px;}
#t1k_35{left:495px;bottom:805px;letter-spacing:0.07px;}
#t1l_35{left:593px;bottom:937px;letter-spacing:0.09px;}
#t1m_35{left:593px;bottom:923px;letter-spacing:0.13px;}
#t1n_35{left:593px;bottom:908px;letter-spacing:0.11px;}
#t1o_35{left:593px;bottom:893px;letter-spacing:0.09px;}
#t1p_35{left:593px;bottom:879px;letter-spacing:0.06px;}
#t1q_35{left:593px;bottom:864px;letter-spacing:0.07px;}
#t1r_35{left:593px;bottom:849px;letter-spacing:0.11px;}
#t1s_35{left:593px;bottom:835px;letter-spacing:0.06px;}
#t1t_35{left:593px;bottom:820px;letter-spacing:0.09px;}
#t1u_35{left:593px;bottom:805px;letter-spacing:0.09px;}
#t1v_35{left:593px;bottom:791px;letter-spacing:0.08px;}
#t1w_35{left:593px;bottom:776px;letter-spacing:0.04px;}
#t1x_35{left:593px;bottom:761px;letter-spacing:0.07px;}
#t1y_35{left:108.8px;bottom:626.7px;letter-spacing:0.09px;}
#t1z_35{left:123.4px;bottom:607px;letter-spacing:0.06px;word-spacing:0.05px;}
#t20_35{left:138.1px;bottom:636.8px;letter-spacing:0.09px;}
#t21_35{left:143px;bottom:735px;letter-spacing:0.13px;word-spacing:-0.02px;}
#t22_35{left:143px;bottom:720px;letter-spacing:0.07px;word-spacing:0.03px;}
#t23_35{left:143px;bottom:706px;letter-spacing:0.11px;}
#t24_35{left:143px;bottom:691px;letter-spacing:0.13px;}
#t25_35{left:143px;bottom:676px;letter-spacing:0.11px;}
#t26_35{left:143px;bottom:662px;}
#t27_35{left:143px;bottom:647px;letter-spacing:0.11px;}
#t28_35{left:143px;bottom:632px;letter-spacing:0.04px;}
#t29_35{left:143px;bottom:618px;letter-spacing:0.1px;}
#t2a_35{left:143px;bottom:603px;letter-spacing:0.05px;}
#t2b_35{left:143px;bottom:588px;letter-spacing:0.05px;}
#t2c_35{left:230px;bottom:735px;letter-spacing:0.13px;word-spacing:-0.02px;}
#t2d_35{left:230px;bottom:720px;letter-spacing:0.05px;}
#t2e_35{left:230px;bottom:706px;letter-spacing:0.05px;}
#t2f_35{left:230px;bottom:691px;letter-spacing:0.07px;}
#t2g_35{left:230px;bottom:676px;letter-spacing:0.13px;word-spacing:-0.02px;}
#t2h_35{left:230px;bottom:662px;letter-spacing:0.08px;}
#t2i_35{left:230px;bottom:647px;letter-spacing:0.09px;}
#t2j_35{left:230px;bottom:632px;letter-spacing:0.07px;word-spacing:0.03px;}
#t2k_35{left:230px;bottom:618px;letter-spacing:0.05px;}
#t2l_35{left:230px;bottom:603px;letter-spacing:0.04px;}
#t2m_35{left:322px;bottom:735px;letter-spacing:-0.04px;}
#t2n_35{left:322px;bottom:720px;letter-spacing:0.04px;}
#t2o_35{left:322px;bottom:706px;letter-spacing:0.09px;}
#t2p_35{left:322px;bottom:691px;letter-spacing:0.09px;}
#t2q_35{left:322px;bottom:676px;letter-spacing:0.07px;}
#t2r_35{left:322px;bottom:662px;letter-spacing:0.06px;}
#t2s_35{left:322px;bottom:647px;letter-spacing:0.11px;}
#t2t_35{left:322px;bottom:632px;letter-spacing:0.08px;}
#t2u_35{left:322px;bottom:618px;letter-spacing:0.1px;}
#t2v_35{left:322px;bottom:603px;letter-spacing:0.13px;}
#t2w_35{left:322px;bottom:588px;letter-spacing:0.09px;}
#t2x_35{left:404px;bottom:735px;letter-spacing:0.12px;}
#t2y_35{left:404px;bottom:720px;letter-spacing:0.1px;}
#t2z_35{left:404px;bottom:706px;letter-spacing:0.07px;}
#t30_35{left:404px;bottom:691px;letter-spacing:0.11px;}
#t31_35{left:404px;bottom:676px;letter-spacing:0.09px;}
#t32_35{left:404px;bottom:662px;letter-spacing:0.09px;}
#t33_35{left:404px;bottom:647px;letter-spacing:0.09px;}
#t34_35{left:404px;bottom:632px;letter-spacing:0.02px;}
#t35_35{left:404px;bottom:618px;letter-spacing:0.09px;}
#t36_35{left:404px;bottom:603px;letter-spacing:0.1px;}
#t37_35{left:404px;bottom:588px;letter-spacing:0.16px;}
#t38_35{left:495px;bottom:735px;letter-spacing:0.07px;word-spacing:0.03px;}
#t39_35{left:495px;bottom:720px;letter-spacing:0.1px;}
#t3a_35{left:495px;bottom:706px;letter-spacing:0.1px;}
#t3b_35{left:495px;bottom:691px;letter-spacing:0.08px;}
#t3c_35{left:495px;bottom:676px;letter-spacing:0.11px;}
#t3d_35{left:495px;bottom:662px;letter-spacing:0.09px;}
#t3e_35{left:495px;bottom:647px;letter-spacing:-0.03px;word-spacing:0.12px;}
#t3f_35{left:495px;bottom:632px;letter-spacing:0.06px;word-spacing:0.05px;}
#t3g_35{left:495px;bottom:618px;letter-spacing:0.13px;}
#t3h_35{left:495px;bottom:603px;letter-spacing:0.03px;}
#t3i_35{left:495px;bottom:588px;letter-spacing:0.1px;}
#t3j_35{left:597px;bottom:735px;letter-spacing:0.04px;}
#t3k_35{left:593px;bottom:720px;letter-spacing:0.08px;}
#t3l_35{left:593px;bottom:706px;letter-spacing:0.06px;}
#t3m_35{left:593px;bottom:691px;letter-spacing:0.11px;}
#t3n_35{left:593px;bottom:676px;letter-spacing:0.07px;}
#t3o_35{left:593px;bottom:662px;letter-spacing:0.11px;}
#t3p_35{left:593px;bottom:647px;letter-spacing:0.09px;}
#t3q_35{left:593px;bottom:632px;letter-spacing:0.09px;}
#t3r_35{left:593px;bottom:618px;letter-spacing:0.05px;}
#t3s_35{left:593px;bottom:603px;letter-spacing:0.03px;}
#t3t_35{left:116.1px;bottom:420.9px;letter-spacing:0.04px;word-spacing:0.11px;}
#t3u_35{left:130.8px;bottom:407.7px;letter-spacing:0.1px;word-spacing:0.01px;}
#t3v_35{left:143px;bottom:562px;letter-spacing:0.11px;}
#t3w_35{left:143px;bottom:548px;letter-spacing:0.11px;}
#t3x_35{left:143px;bottom:533px;letter-spacing:0.03px;}
#t3y_35{left:143px;bottom:518px;letter-spacing:0.04px;}
#t3z_35{left:143px;bottom:504px;letter-spacing:0.07px;}
#t40_35{left:143px;bottom:489px;letter-spacing:0.13px;}
#t41_35{left:143px;bottom:474px;letter-spacing:0.11px;}
#t42_35{left:143px;bottom:460px;letter-spacing:0.05px;}
#t43_35{left:143px;bottom:445px;letter-spacing:0.09px;}
#t44_35{left:143px;bottom:430px;letter-spacing:0.06px;}
#t45_35{left:230px;bottom:562px;letter-spacing:0.08px;}
#t46_35{left:230px;bottom:548px;letter-spacing:0.02px;}
#t47_35{left:230px;bottom:533px;letter-spacing:0.08px;word-spacing:0.02px;}
#t48_35{left:230px;bottom:518px;letter-spacing:0.1px;}
#t49_35{left:230px;bottom:504px;letter-spacing:0.1px;}
#t4a_35{left:230px;bottom:489px;letter-spacing:0.07px;}
#t4b_35{left:230px;bottom:474px;letter-spacing:0.09px;}
#t4c_35{left:230px;bottom:460px;letter-spacing:0.09px;word-spacing:0.01px;}
#t4d_35{left:322px;bottom:562px;letter-spacing:0.11px;}
#t4e_35{left:322px;bottom:548px;letter-spacing:0.07px;}
#t4f_35{left:322px;bottom:533px;letter-spacing:0.11px;}
#t4g_35{left:322px;bottom:518px;letter-spacing:0.07px;}
#t4h_35{left:322px;bottom:504px;letter-spacing:0.03px;}
#t4i_35{left:322px;bottom:489px;letter-spacing:0.06px;}
#t4j_35{left:322px;bottom:474px;letter-spacing:0.11px;}
#t4k_35{left:322px;bottom:460px;word-spacing:0.1px;}
#t4l_35{left:404px;bottom:562px;letter-spacing:0.11px;}
#t4m_35{left:404px;bottom:548px;letter-spacing:0.08px;}
#t4n_35{left:404px;bottom:533px;letter-spacing:0.1px;}
#t4o_35{left:404px;bottom:518px;letter-spacing:0.07px;word-spacing:0.04px;}
#t4p_35{left:404px;bottom:504px;letter-spacing:0.08px;word-spacing:0.02px;}
#t4q_35{left:404px;bottom:489px;letter-spacing:0.06px;}
#t4r_35{left:404px;bottom:474px;letter-spacing:0.06px;}
#t4s_35{left:404px;bottom:460px;letter-spacing:0.05px;word-spacing:0.06px;}
#t4t_35{left:498px;bottom:562px;letter-spacing:0.06px;}
#t4u_35{left:495px;bottom:548px;letter-spacing:0.08px;}
#t4v_35{left:495px;bottom:533px;letter-spacing:0.01px;}
#t4w_35{left:495px;bottom:518px;letter-spacing:0.07px;word-spacing:0.02px;}
#t4x_35{left:495px;bottom:504px;letter-spacing:0.05px;word-spacing:0.06px;}
#t4y_35{left:495px;bottom:489px;letter-spacing:0.09px;}
#t4z_35{left:495px;bottom:474px;letter-spacing:0.09px;word-spacing:0.01px;}
#t50_35{left:495px;bottom:460px;letter-spacing:0.06px;}
#t51_35{left:495px;bottom:445px;letter-spacing:0.09px;word-spacing:0.02px;}
#t52_35{left:495px;bottom:430px;letter-spacing:0.11px;}
#t53_35{left:593px;bottom:562px;letter-spacing:0.1px;word-spacing:-0.42px;}
#t54_35{left:593px;bottom:548px;letter-spacing:0.11px;}
#t55_35{left:593px;bottom:533px;letter-spacing:0.07px;}
#t56_35{left:593px;bottom:518px;letter-spacing:0.03px;}
#t57_35{left:593px;bottom:504px;letter-spacing:0.11px;}
#t58_35{left:593px;bottom:489px;letter-spacing:0.14px;}
#t59_35{left:593px;bottom:474px;letter-spacing:0.1px;}
#t5a_35{left:593px;bottom:460px;letter-spacing:0.05px;}
#t5b_35{left:593px;bottom:445px;letter-spacing:0.03px;}
#t5c_35{left:593px;bottom:430px;letter-spacing:0.11px;}
#t5d_35{left:593px;bottom:416px;letter-spacing:0.08px;}
#t5e_35{left:593px;bottom:401px;letter-spacing:0.06px;word-spacing:0.05px;}
#t5f_35{left:87px;bottom:343px;letter-spacing:-0.16px;word-spacing:2.57px;}
#t5g_35{left:87px;bottom:322px;letter-spacing:-0.19px;word-spacing:5.15px;}
#t5h_35{left:87px;bottom:302px;letter-spacing:-0.14px;}
#t5i_35{left:194px;bottom:302px;letter-spacing:-0.19px;}
#t5j_35{left:258px;bottom:302px;letter-spacing:-0.14px;}
#t5k_35{left:331px;bottom:302px;letter-spacing:-0.15px;}
#t5l_35{left:407px;bottom:302px;letter-spacing:-0.15px;}
#t5m_35{left:525px;bottom:302px;letter-spacing:-0.18px;}
#t5n_35{left:605px;bottom:302px;letter-spacing:-0.14px;}
#t5o_35{left:87px;bottom:282px;letter-spacing:-0.16px;word-spacing:2.13px;}
#t5p_35{left:87px;bottom:262px;letter-spacing:-0.14px;word-spacing:-0.35px;}
#t5q_35{left:87px;bottom:242px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_35{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_35{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_35{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}
.s3_35{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s4_35{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_35{transform:matrix(-1,0,0,1,0,0);}
.t.m1_35{transform:matrix(0,-1,1,0,0,0);}`}
      </style>

      <style id="fonts35" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg35" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="35/35.svg"
          type="image/svg+xml"
          id="pdf35"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_35" class="t s1_35">
          35{" "}
        </span>
        <span id="t3_35" class="t m1_35 s2_35">
          ТӨЛӨВЛӨХ{" "}
        </span>
        <span id="t4_35" class="t m1_35 s2_35">
          (Сургалтын гол үйл ажиллагаа{" "}
        </span>
        <span id="t5_35" class="t m1_35 s2_35">
          (СҮА)){" "}
        </span>
        <span id="t6_35" class="t s3_35">
          Сургуулийн{" "}
        </span>
        <span id="t7_35" class="t s3_35">
          болон ангийн{" "}
        </span>
        <span id="t8_35" class="t s3_35">
          зорилгод{" "}
        </span>
        <span id="t9_35" class="t s3_35">
          хүрэхийн{" "}
        </span>
        <span id="ta_35" class="t s3_35">
          тулд өөрийн{" "}
        </span>
        <span id="tb_35" class="t s3_35">
          зүгээс хийх{" "}
        </span>
        <span id="tc_35" class="t s3_35">
          алхам, үйл{" "}
        </span>
        <span id="td_35" class="t s3_35">
          ажиллагаа{" "}
        </span>
        <span id="te_35" class="t s3_35">
          төлөвлөх,{" "}
        </span>
        <span id="tf_35" class="t s3_35">
          уялдуулах{" "}
        </span>
        <span id="tg_35" class="t s3_35">
          Сургуулийн{" "}
        </span>
        <span id="th_35" class="t s3_35">
          нэгдсэн{" "}
        </span>
        <span id="ti_35" class="t s3_35">
          зорилгод{" "}
        </span>
        <span id="tj_35" class="t s3_35">
          чиглэсэн{" "}
        </span>
        <span id="tk_35" class="t s3_35">
          СҮА-ны{" "}
        </span>
        <span id="tl_35" class="t s3_35">
          төлөвлөгөөнд{" "}
        </span>
        <span id="tm_35" class="t s3_35">
          санал өгөх,{" "}
        </span>
        <span id="tn_35" class="t s3_35">
          нэгдсэн{" "}
        </span>
        <span id="to_35" class="t s3_35">
          төлөвлөлттэй{" "}
        </span>
        <span id="tp_35" class="t s3_35">
          өөрийн{" "}
        </span>
        <span id="tq_35" class="t s3_35">
          төлөвлөлтөө{" "}
        </span>
        <span id="tr_35" class="t s3_35">
          уялдуулах{" "}
        </span>
        <span id="ts_35" class="t s3_35">
          Сургуулийн{" "}
        </span>
        <span id="tt_35" class="t s3_35">
          нэгдсэн{" "}
        </span>
        <span id="tu_35" class="t s3_35">
          зорилгод{" "}
        </span>
        <span id="tv_35" class="t s3_35">
          чиглэсэн үйл{" "}
        </span>
        <span id="tw_35" class="t s3_35">
          ажиллагааны{" "}
        </span>
        <span id="tx_35" class="t s3_35">
          төлөвлөлтөд{" "}
        </span>
        <span id="ty_35" class="t s3_35">
          санал өгч{" "}
        </span>
        <span id="tz_35" class="t s3_35">
          оролцох{" "}
        </span>
        <span id="t10_35" class="t s3_35">
          СҮА-ны{" "}
        </span>
        <span id="t11_35" class="t s3_35">
          төлөвлөгөөнд{" "}
        </span>
        <span id="t12_35" class="t s3_35">
          өгсөн багш{" "}
        </span>
        <span id="t13_35" class="t s3_35">
          нарын саналыг{" "}
        </span>
        <span id="t14_35" class="t s3_35">
          нягталж,{" "}
        </span>
        <span id="t15_35" class="t s3_35">
          нэгдсэн{" "}
        </span>
        <span id="t16_35" class="t s3_35">
          төлөвлөгөө,{" "}
        </span>
        <span id="t17_35" class="t s3_35">
          ЗАН, МХБ{" "}
        </span>
        <span id="t18_35" class="t s3_35">
          төлөвлөгөөнд{" "}
        </span>
        <span id="t19_35" class="t s3_35">
          тусгах,{" "}
        </span>
        <span id="t1a_35" class="t s3_35">
          уялдуулах{" "}
        </span>
        <span id="t1b_35" class="t s3_35">
          Эцэг эх, орон{" "}
        </span>
        <span id="t1c_35" class="t s3_35">
          нутгийн зүгээс{" "}
        </span>
        <span id="t1d_35" class="t s3_35">
          сургуулийн{" "}
        </span>
        <span id="t1e_35" class="t s3_35">
          СҮА-ны{" "}
        </span>
        <span id="t1f_35" class="t s3_35">
          төлөвлөгөөнд{" "}
        </span>
        <span id="t1g_35" class="t s3_35">
          өгсөн саналыг{" "}
        </span>
        <span id="t1h_35" class="t s3_35">
          нэгтгэх, СЗ-ийн{" "}
        </span>
        <span id="t1i_35" class="t s3_35">
          төлөвлөгөөнд{" "}
        </span>
        <span id="t1j_35" class="t s3_35">
          тусгах,{" "}
        </span>
        <span id="t1k_35" class="t s3_35">
          уялдуулах{" "}
        </span>
        <span id="t1l_35" class="t s3_35">
          Сурагчдаас{" "}
        </span>
        <span id="t1m_35" class="t s3_35">
          ирүүлсэн{" "}
        </span>
        <span id="t1n_35" class="t s3_35">
          саналыг{" "}
        </span>
        <span id="t1o_35" class="t s3_35">
          нэгтгэж,{" "}
        </span>
        <span id="t1p_35" class="t s3_35">
          сургуулийн{" "}
        </span>
        <span id="t1q_35" class="t s3_35">
          болон{" "}
        </span>
        <span id="t1r_35" class="t s3_35">
          өөрсдийн{" "}
        </span>
        <span id="t1s_35" class="t s3_35">
          байгууллагын{" "}
        </span>
        <span id="t1t_35" class="t s3_35">
          үйл{" "}
        </span>
        <span id="t1u_35" class="t s3_35">
          ажиллагааны{" "}
        </span>
        <span id="t1v_35" class="t s3_35">
          төлөвлөлтөд{" "}
        </span>
        <span id="t1w_35" class="t s3_35">
          тусгах,{" "}
        </span>
        <span id="t1x_35" class="t s3_35">
          уялдуулах{" "}
        </span>
        <span id="t1y_35" class="t m1_35 s2_35">
          ХЭРЭГЖҮҮЛЭХ{" "}
        </span>
        <span id="t1z_35" class="t m1_35 s2_35">
          (Тогтвортой, тууштай{" "}
        </span>
        <span id="t20_35" class="t m1_35 s2_35">
          хэвшүүлэх){" "}
        </span>
        <span id="t21_35" class="t s3_35">
          Өдөр тутмын{" "}
        </span>
        <span id="t22_35" class="t s3_35">
          хичээл, сурал-{" "}
        </span>
        <span id="t23_35" class="t s3_35">
          цахуйдаа{" "}
        </span>
        <span id="t24_35" class="t s3_35">
          идэвх{" "}
        </span>
        <span id="t25_35" class="t s3_35">
          чармайлт{" "}
        </span>
        <span id="t26_35" class="t s3_35">
          гаргаж,{" "}
        </span>
        <span id="t27_35" class="t s3_35">
          өөрийн{" "}
        </span>
        <span id="t28_35" class="t s3_35">
          зорилго,{" "}
        </span>
        <span id="t29_35" class="t s3_35">
          ангийн{" "}
        </span>
        <span id="t2a_35" class="t s3_35">
          зорилгоо{" "}
        </span>
        <span id="t2b_35" class="t s3_35">
          биелүүлэх{" "}
        </span>
        <span id="t2c_35" class="t s3_35">
          Өдөр тутмын{" "}
        </span>
        <span id="t2d_35" class="t s3_35">
          хичээл,{" "}
        </span>
        <span id="t2e_35" class="t s3_35">
          сургалтын{" "}
        </span>
        <span id="t2f_35" class="t s3_35">
          болон{" "}
        </span>
        <span id="t2g_35" class="t s3_35">
          дэмжих үйл{" "}
        </span>
        <span id="t2h_35" class="t s3_35">
          ажиллагааг{" "}
        </span>
        <span id="t2i_35" class="t s3_35">
          төлөвлөлтийн{" "}
        </span>
        <span id="t2j_35" class="t s3_35">
          дагуу чанартай{" "}
        </span>
        <span id="t2k_35" class="t s3_35">
          зохион{" "}
        </span>
        <span id="t2l_35" class="t s3_35">
          байгуулах{" "}
        </span>
        <span id="t2m_35" class="t s3_35">
          Сургуультай{" "}
        </span>
        <span id="t2n_35" class="t s3_35">
          байгуулсан{" "}
        </span>
        <span id="t2o_35" class="t s3_35">
          гэрээг{" "}
        </span>
        <span id="t2p_35" class="t s3_35">
          хэрэгжүүлэх{" "}
        </span>
        <span id="t2q_35" class="t s3_35">
          болон{" "}
        </span>
        <span id="t2r_35" class="t s3_35">
          сургуулийн{" "}
        </span>
        <span id="t2s_35" class="t s3_35">
          СҮА-ны{" "}
        </span>
        <span id="t2t_35" class="t s3_35">
          төлөвлөл-{" "}
        </span>
        <span id="t2u_35" class="t s3_35">
          тийн дагуу{" "}
        </span>
        <span id="t2v_35" class="t s3_35">
          дэмжлэг{" "}
        </span>
        <span id="t2w_35" class="t s3_35">
          үзүүлэх{" "}
        </span>
        <span id="t2x_35" class="t s3_35">
          ЗАН, МХБ-{" "}
        </span>
        <span id="t2y_35" class="t s3_35">
          ийн хүрээнд{" "}
        </span>
        <span id="t2z_35" class="t s3_35">
          хариуцсан{" "}
        </span>
        <span id="t30_35" class="t s3_35">
          ажлыг{" "}
        </span>
        <span id="t31_35" class="t s3_35">
          төлөвлөлтийн{" "}
        </span>
        <span id="t32_35" class="t s3_35">
          дагуу{" "}
        </span>
        <span id="t33_35" class="t s3_35">
          хэрэгжүүлэх,{" "}
        </span>
        <span id="t34_35" class="t s3_35">
          багш,{" "}
        </span>
        <span id="t35_35" class="t s3_35">
          сурагчийн{" "}
        </span>
        <span id="t36_35" class="t s3_35">
          хөгжлийг{" "}
        </span>
        <span id="t37_35" class="t s3_35">
          дэмжих{" "}
        </span>
        <span id="t38_35" class="t s3_35">
          СЗ-ийн эрх{" "}
        </span>
        <span id="t39_35" class="t s3_35">
          үүргийн{" "}
        </span>
        <span id="t3a_35" class="t s3_35">
          хүрээнд,{" "}
        </span>
        <span id="t3b_35" class="t s3_35">
          төлөвлөсөн{" "}
        </span>
        <span id="t3c_35" class="t s3_35">
          ажлыг{" "}
        </span>
        <span id="t3d_35" class="t s3_35">
          хэрэгжүүлэх,{" "}
        </span>
        <span id="t3e_35" class="t s3_35">
          орон нутаг, эцэг{" "}
        </span>
        <span id="t3f_35" class="t s3_35">
          эхийн оролцоо{" "}
        </span>
        <span id="t3g_35" class="t s3_35">
          дэмжлэгийг{" "}
        </span>
        <span id="t3h_35" class="t s3_35">
          нэмэгдүүлэхэд{" "}
        </span>
        <span id="t3i_35" class="t s3_35">
          анхаарах{" "}
        </span>
        <span id="t3j_35" class="t s3_35">
          Нэгдсэн{" "}
        </span>
        <span id="t3k_35" class="t s3_35">
          төлөвлөлтөд{" "}
        </span>
        <span id="t3l_35" class="t s3_35">
          тусгасан,{" "}
        </span>
        <span id="t3m_35" class="t s3_35">
          өөрсдийн{" "}
        </span>
        <span id="t3n_35" class="t s3_35">
          хариуцсан{" "}
        </span>
        <span id="t3o_35" class="t s3_35">
          ажлыг{" "}
        </span>
        <span id="t3p_35" class="t s3_35">
          хэрэгжүүлэх,{" "}
        </span>
        <span id="t3q_35" class="t s3_35">
          сурагчдын{" "}
        </span>
        <span id="t3r_35" class="t s3_35">
          оролцоог{" "}
        </span>
        <span id="t3s_35" class="t s3_35">
          хангах{" "}
        </span>
        <span id="t3t_35" class="t m1_35 s2_35">
          ҮНЭЛЭХ, САЙЖРУУЛАХ{" "}
        </span>
        <span id="t3u_35" class="t m1_35 s3_35">
          (няглах, дүн шинжилгээ хийх){" "}
        </span>
        <span id="t3v_35" class="t s3_35">
          Өөрийн ахиц{" "}
        </span>
        <span id="t3w_35" class="t s3_35">
          амжилтыг{" "}
        </span>
        <span id="t3x_35" class="t s3_35">
          тогтмол{" "}
        </span>
        <span id="t3y_35" class="t s3_35">
          үнэлж,{" "}
        </span>
        <span id="t3z_35" class="t s3_35">
          ойлгоогүй{" "}
        </span>
        <span id="t40_35" class="t s3_35">
          дутуу{" "}
        </span>
        <span id="t41_35" class="t s3_35">
          эзэмшсэн{" "}
        </span>
        <span id="t42_35" class="t s3_35">
          мэдлэг{" "}
        </span>
        <span id="t43_35" class="t s3_35">
          чадвараа{" "}
        </span>
        <span id="t44_35" class="t s3_35">
          ахиулах{" "}
        </span>
        <span id="t45_35" class="t s3_35">
          Хичээлийн{" "}
        </span>
        <span id="t46_35" class="t s3_35">
          судалгаа,{" "}
        </span>
        <span id="t47_35" class="t s3_35">
          явцын үнэлгээг{" "}
        </span>
        <span id="t48_35" class="t s3_35">
          хийж, тухай{" "}
        </span>
        <span id="t49_35" class="t s3_35">
          бүр нь засаж{" "}
        </span>
        <span id="t4a_35" class="t s3_35">
          сайжруулах,{" "}
        </span>
        <span id="t4b_35" class="t s3_35">
          харилцан{" "}
        </span>
        <span id="t4c_35" class="t s3_35">
          хяналт тавих{" "}
        </span>
        <span id="t4d_35" class="t s3_35">
          Өөрийн{" "}
        </span>
        <span id="t4e_35" class="t s3_35">
          болон{" "}
        </span>
        <span id="t4f_35" class="t s3_35">
          хөндлөнгийн{" "}
        </span>
        <span id="t4g_35" class="t s3_35">
          үнэлгээнд{" "}
        </span>
        <span id="t4h_35" class="t s3_35">
          оролцож,{" "}
        </span>
        <span id="t4i_35" class="t s3_35">
          сайжруулах{" "}
        </span>
        <span id="t4j_35" class="t s3_35">
          саналаа{" "}
        </span>
        <span id="t4k_35" class="t s3_35">
          өгдөг байх{" "}
        </span>
        <span id="t4l_35" class="t s3_35">
          Хөтөлбөрийн{" "}
        </span>
        <span id="t4m_35" class="t s3_35">
          хэрэгжилтэд{" "}
        </span>
        <span id="t4n_35" class="t s3_35">
          хяналт{" "}
        </span>
        <span id="t4o_35" class="t s3_35">
          тавьж, хичээл{" "}
        </span>
        <span id="t4p_35" class="t s3_35">
          ажиглалт хийх,{" "}
        </span>
        <span id="t4q_35" class="t s3_35">
          сайжруулах{" "}
        </span>
        <span id="t4r_35" class="t s3_35">
          зөвлөгөө,{" "}
        </span>
        <span id="t4s_35" class="t s3_35">
          чиглэл өгөх{" "}
        </span>
        <span id="t4t_35" class="t s3_35">
          Сургуулийн{" "}
        </span>
        <span id="t4u_35" class="t s3_35">
          тайланд{" "}
        </span>
        <span id="t4v_35" class="t s3_35">
          үнэлэлт{" "}
        </span>
        <span id="t4w_35" class="t s3_35">
          дүгнэлт өгөх,{" "}
        </span>
        <span id="t4x_35" class="t s3_35">
          удирдлагын үйл{" "}
        </span>
        <span id="t4y_35" class="t s3_35">
          ажиллагаанд{" "}
        </span>
        <span id="t4z_35" class="t s3_35">
          хяналт тавих,{" "}
        </span>
        <span id="t50_35" class="t s3_35">
          сайжруулах{" "}
        </span>
        <span id="t51_35" class="t s3_35">
          санал зөвлөмж{" "}
        </span>
        <span id="t52_35" class="t s3_35">
          өгөх{" "}
        </span>
        <span id="t53_35" class="t s3_35">
          Өөрийн болон{" "}
        </span>
        <span id="t54_35" class="t s3_35">
          хөндлөнгийн{" "}
        </span>
        <span id="t55_35" class="t s3_35">
          үнэлгээнд{" "}
        </span>
        <span id="t56_35" class="t s3_35">
          оролцох,{" "}
        </span>
        <span id="t57_35" class="t s3_35">
          өөрсдийн{" "}
        </span>
        <span id="t58_35" class="t s3_35">
          шийдэж{" "}
        </span>
        <span id="t59_35" class="t s3_35">
          чадахгүй{" "}
        </span>
        <span id="t5a_35" class="t s3_35">
          асуудлаа{" "}
        </span>
        <span id="t5b_35" class="t s3_35">
          зохих{" "}
        </span>
        <span id="t5c_35" class="t s3_35">
          түвшинд{" "}
        </span>
        <span id="t5d_35" class="t s3_35">
          уламжилж,{" "}
        </span>
        <span id="t5e_35" class="t s3_35">
          санал гаргах{" "}
        </span>
        <span id="t5f_35" class="t s4_35">
          Сургуулийн тухайн жилийн зорилго, зорилт, үйл ажиллагааг төлөвлөхөөс{" "}
        </span>
        <span id="t5g_35" class="t s4_35">
          өмнө орон нутгийн төрийн холбогдох байгууллагууд, тухайлбал, БШУГ,{" "}
        </span>
        <span id="t5h_35" class="t s4_35">
          Цагдаагийн{" "}
        </span>
        <span id="t5i_35" class="t s4_35">
          газар,{" "}
        </span>
        <span id="t5j_35" class="t s4_35">
          хэлтэс,{" "}
        </span>
        <span id="t5k_35" class="t s4_35">
          Хүүхэд,{" "}
        </span>
        <span id="t5l_35" class="t s4_35">
          залуучуудын{" "}
        </span>
        <span id="t5m_35" class="t s4_35">
          асуудал{" "}
        </span>
        <span id="t5n_35" class="t s4_35">
          эрхэлсэн{" "}
        </span>
        <span id="t5o_35" class="t s4_35">
          байгууллагуудтай мэдээллээ хуваалцаж, сургуулийнхаа зорилго зорилтыг{" "}
        </span>
        <span id="t5p_35" class="t s4_35">
          ойлгуулах, тэдний хэрэгжүүлж буй үйл ажиллагаа, төлөвлөж буй ажлуудтай{" "}
        </span>
        <span id="t5q_35" class="t s4_35">
          уялдуулах хэрэгтэй.{" "}
        </span>
      </div>
    </div>
  );
}
