import React from "react";
export default function HundredTwentySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_126{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_126{left:361px;bottom:945px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t3_126{left:117px;bottom:902px;letter-spacing:-0.12px;}
#t4_126{left:245px;bottom:902px;letter-spacing:-0.27px;word-spacing:0.15px;}
#t5_126{left:428px;bottom:902px;letter-spacing:-0.18px;word-spacing:-0.54px;}
#t6_126{left:91px;bottom:780px;}
#t7_126{left:117px;bottom:879px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t8_126{left:117px;bottom:863px;letter-spacing:-0.14px;}
#t9_126{left:117px;bottom:846px;letter-spacing:-0.18px;word-spacing:0.06px;}
#ta_126{left:117px;bottom:830px;letter-spacing:-0.16px;}
#tb_126{left:117px;bottom:813px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tc_126{left:117px;bottom:797px;letter-spacing:-0.12px;}
#td_126{left:117px;bottom:780px;letter-spacing:-0.16px;word-spacing:0.04px;}
#te_126{left:117px;bottom:764px;letter-spacing:-0.13px;}
#tf_126{left:245px;bottom:879px;}
#tg_126{left:257px;bottom:879px;letter-spacing:-0.15px;word-spacing:0.02px;}
#th_126{left:257px;bottom:863px;letter-spacing:-0.09px;word-spacing:-0.03px;}
#ti_126{left:402px;bottom:863px;}
#tj_126{left:257px;bottom:846px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tk_126{left:257px;bottom:830px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tl_126{left:257px;bottom:813px;letter-spacing:-0.08px;}
#tm_126{left:245px;bottom:797px;}
#tn_126{left:257px;bottom:797px;letter-spacing:-0.14px;word-spacing:0.02px;}
#to_126{left:257px;bottom:780px;letter-spacing:-0.13px;}
#tp_126{left:245px;bottom:764px;}
#tq_126{left:257px;bottom:764px;letter-spacing:-0.13px;}
#tr_126{left:245px;bottom:747px;}
#ts_126{left:257px;bottom:747px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tt_126{left:257px;bottom:731px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tu_126{left:245px;bottom:714px;}
#tv_126{left:257px;bottom:714px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tw_126{left:257px;bottom:698px;letter-spacing:-0.21px;word-spacing:0.1px;}
#tx_126{left:245px;bottom:681px;}
#ty_126{left:278px;bottom:681px;letter-spacing:-0.07px;}
#tz_126{left:428px;bottom:879px;letter-spacing:-0.14px;}
#t10_126{left:496px;bottom:879px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t11_126{left:428px;bottom:863px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t12_126{left:428px;bottom:846px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t13_126{left:428px;bottom:830px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t14_126{left:428px;bottom:813px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t15_126{left:428px;bottom:797px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t16_126{left:428px;bottom:780px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t17_126{left:428px;bottom:764px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t18_126{left:428px;bottom:747px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t19_126{left:428px;bottom:731px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t1a_126{left:428px;bottom:714px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t1b_126{left:428px;bottom:698px;letter-spacing:-0.22px;word-spacing:0.09px;}
#t1c_126{left:178px;bottom:627px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t1d_126{left:91px;bottom:594px;letter-spacing:-0.13px;}
#t1e_126{left:230px;bottom:594px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1f_126{left:91px;bottom:567px;letter-spacing:-0.13px;}
#t1g_126{left:91px;bottom:509px;letter-spacing:-0.15px;}
#t1h_126{left:139px;bottom:509px;letter-spacing:-0.07px;}
#t1i_126{left:330px;bottom:509px;letter-spacing:-0.09px;}
#t1j_126{left:558.5px;bottom:489.8px;letter-spacing:-0.12px;}
#t1k_126{left:594.7px;bottom:489.8px;letter-spacing:-0.14px;}
#t1l_126{left:630.9px;bottom:489.8px;letter-spacing:-0.22px;}
#t1m_126{left:667.3px;bottom:489.8px;letter-spacing:-0.19px;}
#t1n_126{left:91px;bottom:454px;letter-spacing:-0.16px;word-spacing:1.82px;}
#t1o_126{left:91px;bottom:438px;letter-spacing:-0.13px;}
#t1p_126{left:91px;bottom:417px;letter-spacing:-0.18px;word-spacing:7.58px;}
#t1q_126{left:91px;bottom:401px;letter-spacing:-0.31px;}
#t1r_126{left:91px;bottom:379px;letter-spacing:-0.24px;word-spacing:0.12px;}
#t1s_126{left:91px;bottom:356px;letter-spacing:-0.19px;word-spacing:5.4px;}
#t1t_126{left:91px;bottom:340px;letter-spacing:-0.28px;word-spacing:0.15px;}
#t1u_126{left:91px;bottom:317px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1v_126{left:91px;bottom:291px;letter-spacing:-0.13px;}
#t1w_126{left:91px;bottom:265px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1x_126{left:91px;bottom:239px;letter-spacing:-0.23px;word-spacing:0.11px;}
#t1y_126{left:91px;bottom:213px;letter-spacing:-0.22px;word-spacing:0.1px;}
#t1z_126{left:91px;bottom:187px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t20_126{left:91px;bottom:161px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t21_126{left:293px;bottom:131px;letter-spacing:-0.17px;word-spacing:0.05px;}

.s0_126{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_126{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_126{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_126{font-size:14px;font-family:SymbolMT_19r;color:#000;}
.t.m0_126{transform:matrix(0,-1,1,0,0,0);}`}
      </style>

      <style id="fonts126" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg126" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="126/126.svg"
          type="image/svg+xml"
          id="pdf126"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_126" class="t s0_126">
          126{" "}
        </span>
        <span id="t2_126" class="t s1_126">
          Хүснэгт 3.12. Сурагчид бие биеэсээ асуулт асуух{" "}
        </span>
        <span id="t3_126" class="t s2_126">
          Аргын санаа{" "}
        </span>
        <span id="t4_126" class="t s2_126">
          Гол үр дүн{" "}
        </span>
        <span id="t5_126" class="t s2_126">
          Хичээлийн аль хэсэгт хэрэглэж болох{" "}
        </span>
        <span id="t6_126" class="t s1_126">
          1{" "}
        </span>
        <span id="t7_126" class="t s1_126">
          Сурагчид бусад{" "}
        </span>
        <span id="t8_126" class="t s1_126">
          сурагчдын{" "}
        </span>
        <span id="t9_126" class="t s1_126">
          асуулт тайлбар,{" "}
        </span>
        <span id="ta_126" class="t s1_126">
          танилцуулгыг{" "}
        </span>
        <span id="tb_126" class="t s1_126">
          сонсож ойлгоогүй{" "}
        </span>
        <span id="tc_126" class="t s1_126">
          зүйлийнхээ{" "}
        </span>
        <span id="td_126" class="t s1_126">
          талаар асуулт{" "}
        </span>
        <span id="te_126" class="t s1_126">
          тавихыг дэмжих{" "}
        </span>
        <span id="tf_126" class="t s3_126">
          •{" "}
        </span>
        <span id="tg_126" class="t s1_126">
          Хамтын ажиллагаа{" "}
        </span>
        <span id="th_126" class="t s1_126">
          гэсэн санааг дэмждэг{" "}
        </span>
        <span id="ti_126" class="t s1_126">
          –{" "}
        </span>
        <span id="tj_126" class="t s1_126">
          ганцаараа бодсоноос{" "}
        </span>
        <span id="tk_126" class="t s1_126">
          хоёулаа бодсон илүү{" "}
        </span>
        <span id="tl_126" class="t s1_126">
          дээр{" "}
        </span>
        <span id="tm_126" class="t s3_126">
          •{" "}
        </span>
        <span id="tn_126" class="t s1_126">
          Өөрийн ойлгоогүйгээ{" "}
        </span>
        <span id="to_126" class="t s1_126">
          эргэцүүлнэ{" "}
        </span>
        <span id="tp_126" class="t s3_126">
          •{" "}
        </span>
        <span id="tq_126" class="t s1_126">
          Бие биеэсээ суралцана{" "}
        </span>
        <span id="tr_126" class="t s3_126">
          •{" "}
        </span>
        <span id="ts_126" class="t s1_126">
          Алдаа, болон дутуугаа{" "}
        </span>
        <span id="tt_126" class="t s1_126">
          нягтална, засна{" "}
        </span>
        <span id="tu_126" class="t s3_126">
          •{" "}
        </span>
        <span id="tv_126" class="t s1_126">
          ‘Асуулт асуух эргэцүүлэн{" "}
        </span>
        <span id="tw_126" class="t s1_126">
          бодоход сургана.{" "}
        </span>
        <span id="tx_126" class="t s3_126">
          •{" "}
        </span>
        <span id="ty_126" class="t s1_126">
          .....{" "}
        </span>
        <span id="tz_126" class="t s1_126">
          Сурагчид{" "}
        </span>
        <span id="t10_126" class="t s1_126">
          даалгаврыг гүйцэтгэн,{" "}
        </span>
        <span id="t11_126" class="t s1_126">
          өөр хувилбаруудыг судлах ба ач{" "}
        </span>
        <span id="t12_126" class="t s1_126">
          холбогдол, давуу болон сул талын{" "}
        </span>
        <span id="t13_126" class="t s1_126">
          тал, бэрхшээл, үр дүнгийн талаар{" "}
        </span>
        <span id="t14_126" class="t s1_126">
          богино танилцуулга хийнэ.{" "}
        </span>
        <span id="t15_126" class="t s1_126">
          Сурагчид тэмдэглэл хөтөлж, асуух{" "}
        </span>
        <span id="t16_126" class="t s1_126">
          асуултаа бэлтгэнэ.{" "}
        </span>
        <span id="t17_126" class="t s1_126">
          Багш чиглүүлэгчийн үүрэг гүйцэтгэж,{" "}
        </span>
        <span id="t18_126" class="t s1_126">
          ангийн бусад гишүүдээс асуулт{" "}
        </span>
        <span id="t19_126" class="t s1_126">
          авч, танилцуулга хийж буй багийн{" "}
        </span>
        <span id="t1a_126" class="t s1_126">
          гишүүдээс хариулт авна.{" "}
        </span>
        <span id="t1b_126" class="t s1_126">
          Оролцоог жигд хангах{" "}
        </span>
        <span id="t1c_126" class="t s1_126">
          Хүснэгт 3.13. Сурагчийн багаар ажиллах чадварыг үнэлэх ажиглалтын
          хуудас{" "}
        </span>
        <span id="t1d_126" class="t s1_126">
          Огноо:{" "}
        </span>
        <span id="t1e_126" class="t s1_126">
          Хичээл/Үйл ажиллагааны нэр:{" "}
        </span>
        <span id="t1f_126" class="t s1_126">
          Багийн гишүүд:{" "}
        </span>
        <span id="t1g_126" class="t s1_126">
          Сурагч{" "}
        </span>
        <span id="t1h_126" class="t s1_126">
          .................................................{" "}
        </span>
        <span id="t1i_126" class="t s1_126">
          нь{" "}
        </span>
        <span id="t1j_126" class="t m0_126 s1_126">
          Огт гүй{" "}
        </span>
        <span id="t1k_126" class="t m0_126 s1_126">
          Хааяа{" "}
        </span>
        <span id="t1l_126" class="t m0_126 s1_126">
          Ихэвчлэн{" "}
        </span>
        <span id="t1m_126" class="t m0_126 s1_126">
          Үргэлж{" "}
        </span>
        <span id="t1n_126" class="t s1_126">
          Өөрийн дотнын найз нөхдөөсөө гадна, олон сурагчтай хамтарч{" "}
        </span>
        <span id="t1o_126" class="t s1_126">
          ажилладаг{" "}
        </span>
        <span id="t1p_126" class="t s1_126">
          Өөрийн материал, хэрэглэгдэхүүн, санаа, бодлыг бусадтай{" "}
        </span>
        <span id="t1q_126" class="t s1_126">
          хуваалцдаг.{" "}
        </span>
        <span id="t1r_126" class="t s1_126">
          Бусдын санал, бодлыг сонсдог, харгалзаж үздэг, хүндэтгэдэг.{" "}
        </span>
        <span id="t1s_126" class="t s1_126">
          Тухайн ажил, үйл ажиллагааг гүйцэтгэхэд баримтлах дүрэм,{" "}
        </span>
        <span id="t1t_126" class="t s1_126">
          журмыг дагадаг.{" "}
        </span>
        <span id="t1u_126" class="t s1_126">
          Бусдад зааж тусалдаг{" "}
        </span>
        <span id="t1v_126" class="t s1_126">
          Манлайлдаг{" "}
        </span>
        <span id="t1w_126" class="t s1_126">
          Шаардлагатай үед бусдаас дэмжлэг хүсэж алдаагаа засдаг{" "}
        </span>
        <span id="t1x_126" class="t s1_126">
          Баг доторх өөрийн үүрэг, хариуцлагаа биелүүлдэг.{" "}
        </span>
        <span id="t1y_126" class="t s1_126">
          Багийн хэлэлцүүлэгт оролцдог.{" "}
        </span>
        <span id="t1z_126" class="t s1_126">
          Багийн хэлэлцүүлэгт хувь нэмрээ оруулдаг.{" "}
        </span>
        <span id="t20_126" class="t s1_126">
          Дүгнэлт сурагчид өгөх зөвлөмж:{" "}
        </span>
        <span id="t21_126" class="t s2_126">
          Эх сурвалж: Мусиал, Д, Гаяла, Н., Жэй, T., &amp; Кэй, Б. (2009).{" "}
        </span>
      </div>
    </div>
  );
}
