import React from "react";
export default function OneHundredSixtyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_168{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_168{left:91px;bottom:947px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t3_168{left:91px;bottom:929px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t4_168{left:250px;bottom:947px;letter-spacing:-0.18px;}
#t5_168{left:334px;bottom:947px;letter-spacing:-0.19px;}
#t6_168{left:386px;bottom:947px;letter-spacing:-0.09px;}
#t7_168{left:417px;bottom:947px;letter-spacing:-0.15px;}
#t8_168{left:468px;bottom:947px;letter-spacing:-0.44px;}
#t9_168{left:521px;bottom:947px;letter-spacing:-0.18px;}
#ta_168{left:604px;bottom:947px;letter-spacing:-0.16px;}
#tb_168{left:250px;bottom:929px;letter-spacing:-0.17px;word-spacing:5.57px;}
#tc_168{left:250px;bottom:910px;letter-spacing:-0.17px;word-spacing:-1.14px;}
#td_168{left:250px;bottom:892px;letter-spacing:-0.21px;word-spacing:0.08px;}
#te_168{left:91px;bottom:865px;letter-spacing:-0.24px;}
#tf_168{left:91px;bottom:846px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tg_168{left:250px;bottom:865px;letter-spacing:-0.19px;word-spacing:2.63px;}
#th_168{left:250px;bottom:846px;letter-spacing:-0.19px;word-spacing:1.08px;}
#ti_168{left:250px;bottom:828px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tj_168{left:91px;bottom:801px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tk_168{left:91px;bottom:782px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tl_168{left:250px;bottom:801px;letter-spacing:-0.44px;word-spacing:7.71px;}
#tm_168{left:250px;bottom:782px;letter-spacing:-0.45px;word-spacing:-1.03px;}
#tn_168{left:250px;bottom:764px;letter-spacing:-0.41px;word-spacing:0.02px;}
#to_168{left:91px;bottom:737px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tp_168{left:91px;bottom:718px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tq_168{left:250px;bottom:737px;letter-spacing:-0.16px;word-spacing:2.79px;}
#tr_168{left:250px;bottom:718px;letter-spacing:-0.17px;word-spacing:6.52px;}
#ts_168{left:250px;bottom:700px;letter-spacing:-0.14px;word-spacing:3.85px;}
#tt_168{left:250px;bottom:682px;letter-spacing:-0.17px;word-spacing:0.03px;}
#tu_168{left:91px;bottom:654px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tv_168{left:91px;bottom:636px;letter-spacing:-0.13px;}
#tw_168{left:250px;bottom:654px;letter-spacing:-0.17px;word-spacing:5.86px;}
#tx_168{left:250px;bottom:636px;letter-spacing:-0.15px;word-spacing:0.11px;}
#ty_168{left:250px;bottom:618px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tz_168{left:91px;bottom:590px;letter-spacing:-0.12px;}
#t10_168{left:91px;bottom:572px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t11_168{left:91px;bottom:554px;letter-spacing:-0.18px;}
#t12_168{left:91px;bottom:535px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t13_168{left:250px;bottom:590px;letter-spacing:-0.16px;word-spacing:0.52px;}
#t14_168{left:250px;bottom:572px;letter-spacing:-0.15px;word-spacing:0.88px;}
#t15_168{left:250px;bottom:554px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t16_168{left:91px;bottom:508px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t17_168{left:91px;bottom:490px;letter-spacing:-0.22px;}
#t18_168{left:250px;bottom:508px;letter-spacing:-0.17px;word-spacing:-1.9px;}
#t19_168{left:250px;bottom:490px;letter-spacing:-0.16px;word-spacing:1.56px;}
#t1a_168{left:250px;bottom:471px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1b_168{left:87px;bottom:428px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1c_168{left:87px;bottom:408px;letter-spacing:-0.15px;word-spacing:2.83px;}
#t1d_168{left:87px;bottom:388px;letter-spacing:-0.15px;word-spacing:4.02px;}
#t1e_168{left:87px;bottom:368px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1f_168{left:87px;bottom:327px;letter-spacing:-0.17px;word-spacing:2.79px;}
#t1g_168{left:87px;bottom:307px;letter-spacing:-0.13px;word-spacing:5.16px;}
#t1h_168{left:87px;bottom:287px;letter-spacing:-0.15px;word-spacing:-0.59px;}
#t1i_168{left:87px;bottom:267px;letter-spacing:-0.13px;word-spacing:1.08px;}
#t1j_168{left:87px;bottom:247px;letter-spacing:-0.13px;word-spacing:2.03px;}
#t1k_168{left:87px;bottom:227px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1l_168{left:114px;bottom:206px;}
#t1m_168{left:142px;bottom:206px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1n_168{left:114px;bottom:186px;}
#t1o_168{left:142px;bottom:186px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1p_168{left:114px;bottom:166px;}
#t1q_168{left:142px;bottom:166px;letter-spacing:-0.16px;word-spacing:2.7px;}
#t1r_168{left:142px;bottom:146px;letter-spacing:-0.11px;}
#t1s_168{left:114px;bottom:126px;}
#t1t_168{left:142px;bottom:126px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_168{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_168{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_168{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts168" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg168" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="168/168.svg"
          type="image/svg+xml"
          id="pdf168"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_168" class="t s0_168">
          168{" "}
        </span>
        <span id="t2_168" class="t s1_168">
          Нөөцийн бэлэн{" "}
        </span>
        <span id="t3_168" class="t s1_168">
          байдлыг нягтлах{" "}
        </span>
        <span id="t4_168" class="t s1_168">
          Сургалтын{" "}
        </span>
        <span id="t5_168" class="t s1_168">
          орчин{" "}
        </span>
        <span id="t6_168" class="t s1_168">
          нь{" "}
        </span>
        <span id="t7_168" class="t s1_168">
          сурах{" "}
        </span>
        <span id="t8_168" class="t s1_168">
          бичиг,{" "}
        </span>
        <span id="t9_168" class="t s1_168">
          технологи,{" "}
        </span>
        <span id="ta_168" class="t s1_168">
          хичээлийн{" "}
        </span>
        <span id="tb_168" class="t s1_168">
          хэрэглэгдэхүүн, туслах ажилтан зэрэг шаардлагатай нөөцөөр{" "}
        </span>
        <span id="tc_168" class="t s1_168">
          хангагдсан эсэхийг үнэлнэ. Нөөцийн хомсдол нь хичээл, сургалтын{" "}
        </span>
        <span id="td_168" class="t s1_168">
          чанар, үр дүнд шууд нөлөөлдөг.{" "}
        </span>
        <span id="te_168" class="t s1_168">
          Технологийн{" "}
        </span>
        <span id="tf_168" class="t s1_168">
          ашиглалтыг нягтлах{" "}
        </span>
        <span id="tg_168" class="t s1_168">
          Технологийг сургалтын орчинд хэрхэн ашиглаж байгааг үнэлэх.{" "}
        </span>
        <span id="th_168" class="t s1_168">
          Суралцахад саад болох техникийн асуудал байна уу? Технологи{" "}
        </span>
        <span id="ti_168" class="t s1_168">
          нь сургалтыг дэмжиж байна уу эсвэл саад болж байна уу?{" "}
        </span>
        <span id="tj_168" class="t s1_168">
          Анги доторх уур{" "}
        </span>
        <span id="tk_168" class="t s1_168">
          амьсгалыг ажиглах{" "}
        </span>
        <span id="tl_168" class="t s1_168">
          Ангиудын ерөнхий уур амьсгалыг анхаарч үзээрэй. Сурагчид{" "}
        </span>
        <span id="tm_168" class="t s1_168">
          хоорондоо эерэг харилцаатай, нэгдмэл зорилготой, хамтарч ажиллах{" "}
        </span>
        <span id="tn_168" class="t s1_168">
          сонирхолтой, бие биенээ дэмжсэн эерэг хандлага байна уу?{" "}
        </span>
        <span id="to_168" class="t s1_168">
          Үнэлгээ ба санал{" "}
        </span>
        <span id="tp_168" class="t s1_168">
          хүсэлтийн стратеги{" "}
        </span>
        <span id="tq_168" class="t s1_168">
          Үнэлгээг хэрхэн хийж, санал хүсэлтийг хэрхэн өгч, авч байгааг{" "}
        </span>
        <span id="tr_168" class="t s1_168">
          нягтлаарай. Үнэлгээ нь сургалтын үр дүнтэй нийцэж байна{" "}
        </span>
        <span id="ts_168" class="t s1_168">
          уу? Санал хүсэлт нь цаг үеэ олсон, бүтээмжтэй, хэрэгжүүлэх{" "}
        </span>
        <span id="tt_168" class="t s1_168">
          боломжтой юу?{" "}
        </span>
        <span id="tu_168" class="t s1_168">
          Зорилгодоо хүрэх{" "}
        </span>
        <span id="tv_168" class="t s1_168">
          явцыг хянах{" "}
        </span>
        <span id="tw_168" class="t s1_168">
          Сургуулийн зорилго, зорилтод хүрэх ахиц дэвшлийг тогтмол{" "}
        </span>
        <span id="tx_168" class="t s1_168">
          хянах. Хэрэв төлөвлөсөн үр дүнд хүрэхгүй байгаад байвал энэ нь{" "}
        </span>
        <span id="ty_168" class="t s1_168">
          сургалтын орчинд асуудал үүсч болзошгүйг илтгэнэ.{" "}
        </span>
        <span id="tz_168" class="t s1_168">
          Өмч хөрөнгийг{" "}
        </span>
        <span id="t10_168" class="t s1_168">
          зөв, зохистой{" "}
        </span>
        <span id="t11_168" class="t s1_168">
          хуваарилагдсан{" "}
        </span>
        <span id="t12_168" class="t s1_168">
          эсэхийг нягтлах{" "}
        </span>
        <span id="t13_168" class="t s1_168">
          Бүх сурагч суралцах боломж, нөөцийг тэгш хүртэж байгаа эсэхэд{" "}
        </span>
        <span id="t14_168" class="t s1_168">
          дүн шинжилгээ хийх. Сурагчдын дунд ялгаатай байдал үүсгэхгүй{" "}
        </span>
        <span id="t15_168" class="t s1_168">
          байхад анхаарах{" "}
        </span>
        <span id="t16_168" class="t s1_168">
          Гадаад хүчин зүйлсийг{" "}
        </span>
        <span id="t17_168" class="t s1_168">
          судлах{" "}
        </span>
        <span id="t18_168" class="t s1_168">
          Нийгэм, эдийн засгийн сорилт, олон нийтийн асуудал, боловсролын{" "}
        </span>
        <span id="t19_168" class="t s1_168">
          бодлогын өөрчлөлт зэрэг сургалтын орчинд нөлөөлж болзошгүй{" "}
        </span>
        <span id="t1a_168" class="t s1_168">
          гадны хүчин зүйлсийг анхаарч үзээрэй.{" "}
        </span>
        <span id="t1b_168" class="t s2_168">
          Сургуулийн зорилго, зорилттой нийцсэн суралцахуйн орчныг бүрдүүлэхийн{" "}
        </span>
        <span id="t1c_168" class="t s2_168">
          тулд бид орчны чиглэлээр хийгдсэн судалгаа, үнэлгээ, шинжилгээний үр{" "}
        </span>
        <span id="t1d_168" class="t s2_168">
          дүнгүүдтэй танилцаж тэдгээрийн давхцал, харилцан хамаарал, хазайлт,{" "}
        </span>
        <span id="t1e_168" class="t s2_168">
          шалтгааныг тогтоох хэрэгтэй.{" "}
        </span>
        <span id="t1f_168" class="t s2_168">
          Ингэснээр сургалтын орчинд тулгамдаж буй асуудлыг бодитой, оновчтой{" "}
        </span>
        <span id="t1g_168" class="t s2_168">
          илрүүлэх нөхцөл бүрдэнэ. Асуудлаа илрүүлсний дараа хамт олноороо{" "}
        </span>
        <span id="t1h_168" class="t s2_168">
          хэлэлцүүлж тухайн хичээлийн жилд сайжруулах гол ажил, шийдлээ тохирох{" "}
        </span>
        <span id="t1i_168" class="t s2_168">
          нь зүйтэй. Мөн сургалтын орчны чиглэлээр хийж хэрэгжүүлэхээр сонгосон{" "}
        </span>
        <span id="t1j_168" class="t s2_168">
          ажлууд тань дараах шаардлагыг хангасан эсэхийг дахин нягталж үзэхийг{" "}
        </span>
        <span id="t1k_168" class="t s2_168">
          зөвлөж байна. Үүнд:{" "}
        </span>
        <span id="t1l_168" class="t s2_168">
          -{" "}
        </span>
        <span id="t1m_168" class="t s2_168">
          Сургуулийн зорилго, зорилтод чиглэсэн байх{" "}
        </span>
        <span id="t1n_168" class="t s2_168">
          -{" "}
        </span>
        <span id="t1o_168" class="t s2_168">
          Сурагчийн хэрэгцээнд нийцсэн байх{" "}
        </span>
        <span id="t1p_168" class="t s2_168">
          -{" "}
        </span>
        <span id="t1q_168" class="t s2_168">
          Суралцагч төвтэй сургалт, суралцахуйн төрөл бүрийн хэв маягийг{" "}
        </span>
        <span id="t1r_168" class="t s2_168">
          дэмжсэн байх{" "}
        </span>
        <span id="t1s_168" class="t s2_168">
          -{" "}
        </span>
        <span id="t1t_168" class="t s2_168">
          Сурагчдын ялгаатай байдлыг тооцсон байх{" "}
        </span>
      </div>
    </div>
  );
}
