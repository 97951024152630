import React from "react";
export default function SixtyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_68{left:87px;bottom:58px;letter-spacing:-0.12px;}
#t2_68{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_68{left:87px;bottom:941px;letter-spacing:-0.14px;word-spacing:8.82px;}
#t4_68{left:87px;bottom:921px;letter-spacing:-0.2px;word-spacing:9.19px;}
#t5_68{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:-1.96px;}
#t6_68{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_68{left:222px;bottom:845px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t8_68{left:87px;bottom:492px;letter-spacing:-0.14px;word-spacing:-1px;}
#t9_68{left:87px;bottom:472px;letter-spacing:-0.12px;word-spacing:2.29px;}
#ta_68{left:87px;bottom:452px;letter-spacing:-0.1px;}
#tb_68{left:114px;bottom:432px;letter-spacing:-0.05px;}
#tc_68{left:142px;bottom:432px;letter-spacing:-0.13px;word-spacing:3.14px;}
#td_68{left:142px;bottom:411px;letter-spacing:-0.17px;word-spacing:0.07px;}
#te_68{left:114px;bottom:391px;letter-spacing:-0.05px;}
#tf_68{left:142px;bottom:391px;letter-spacing:-0.14px;word-spacing:4.74px;}
#tg_68{left:142px;bottom:371px;letter-spacing:-0.15px;word-spacing:0.05px;}
#th_68{left:114px;bottom:351px;letter-spacing:-0.05px;}
#ti_68{left:142px;bottom:351px;letter-spacing:-0.12px;word-spacing:3.16px;}
#tj_68{left:142px;bottom:331px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_68{left:130px;bottom:275px;letter-spacing:0.18px;word-spacing:0.05px;}
#tl_68{left:202px;bottom:250px;letter-spacing:-0.02px;word-spacing:0.26px;}
#tm_68{left:295px;bottom:224px;letter-spacing:0.01px;word-spacing:0.23px;}
#tn_68{left:87px;bottom:197px;letter-spacing:-0.16px;}
#to_68{left:195px;bottom:197px;letter-spacing:-0.12px;}
#tp_68{left:327px;bottom:197px;letter-spacing:-0.17px;}
#tq_68{left:457px;bottom:197px;letter-spacing:-0.16px;}
#tr_68{left:558px;bottom:197px;letter-spacing:-0.26px;}
#ts_68{left:604px;bottom:197px;letter-spacing:-0.17px;}
#tt_68{left:87px;bottom:177px;letter-spacing:-0.19px;word-spacing:3.6px;}
#tu_68{left:87px;bottom:157px;letter-spacing:-0.13px;}
#tv_68{left:194px;bottom:157px;letter-spacing:-0.12px;}
#tw_68{left:300px;bottom:157px;letter-spacing:-0.08px;}
#tx_68{left:362px;bottom:157px;letter-spacing:-0.17px;}
#ty_68{left:510px;bottom:157px;letter-spacing:-0.17px;}
#tz_68{left:601px;bottom:157px;letter-spacing:-0.2px;}
#t10_68{left:87px;bottom:137px;letter-spacing:-0.16px;word-spacing:4.19px;}
#t11_68{left:87px;bottom:117px;letter-spacing:-0.08px;}

.s0_68{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_68{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_68{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_68{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s4_68{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s5_68{font-size:21px;font-family:MogulArial-Bold_179;color:#6D3A96;}`}
      </style>

      <style id="fonts68" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg68" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="68/68.svg"
          type="image/svg+xml"
          id="pdf68"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_68" class="t s1_68">
          68{" "}
        </span>
        <span id="t3_68" class="t s2_68">
          Та бүхэн сургуулийнхаа багш нарын хөгжлийн түвшнийг индексээр,{" "}
        </span>
        <span id="t4_68" class="t s2_68">
          судлагдахуунаар, багш бүрээр харах боломжтой. Тодруулбал, танай{" "}
        </span>
        <span id="t5_68" class="t s2_68">
          сургуулийн хэдэн багш ямар индекс, хөгжлийн хэддүгээр түвшинд
          хамрагдаж{" "}
        </span>
        <span id="t6_68" class="t s2_68">
          буй болон тэднийг судлагдахуун болон ангиар нь нэгтгэн харж болно.{" "}
        </span>
        <span id="t7_68" class="t s3_68">
          Зураг 2.20. Багшийн үнэлгээ (хөгжлийн түвшнээр){" "}
        </span>
        <span id="t8_68" class="t s2_68">
          Сургууль багш нарынхаа хөгжлийн түвшнийг ангиар, судлагдахуун, багшаар{" "}
        </span>
        <span id="t9_68" class="t s2_68">
          харах боломжтой. Ингэхдээ хөгжлийн түвшнийг оноор харьцуулан харна.{" "}
        </span>
        <span id="ta_68" class="t s2_68">
          Үүнд:{" "}
        </span>
        <span id="tb_68" class="t s4_68">
          1.{" "}
        </span>
        <span id="tc_68" class="t s2_68">
          Сургуулийн нийт багш нарын хэдэн хувь ямар хөгжлийн түвшинд{" "}
        </span>
        <span id="td_68" class="t s2_68">
          хамаарч байгааг тодорхойлоорой.{" "}
        </span>
        <span id="te_68" class="t s4_68">
          2.{" "}
        </span>
        <span id="tf_68" class="t s2_68">
          Ямар судлагдахууны хувьд хамгийн доогуур, ямар анги хамгийн{" "}
        </span>
        <span id="tg_68" class="t s2_68">
          доогуур түвшинд байгааг тогтоогоорой.{" "}
        </span>
        <span id="th_68" class="t s4_68">
          3.{" "}
        </span>
        <span id="ti_68" class="t s2_68">
          Түвшин ахих боломжийг тухайн үйл ажиллагаанд хамаарах үүрэг{" "}
        </span>
        <span id="tj_68" class="t s2_68">
          бүхий хүмүүсийн оролцоотойгоор тодорхойлоорой.{" "}
        </span>
        <span id="tk_68" class="t s5_68">
          2.4. ГҮЙЦЭТГЭЛИЙН ҮНЭЛГЭЭНИЙ ҮР ДҮНГЭЭС{" "}
        </span>
        <span id="tl_68" class="t s5_68">
          ТУЛГАМДАЖ БУЙ АСУУДЛЫГ ХЭРХЭН{" "}
        </span>
        <span id="tm_68" class="t s5_68">
          ТОДОРХОЙЛОХ ВЭ?{" "}
        </span>
        <span id="tn_68" class="t s2_68">
          Сургуулийн{" "}
        </span>
        <span id="to_68" class="t s2_68">
          менежментийг{" "}
        </span>
        <span id="tp_68" class="t s2_68">
          сайжруулахад{" "}
        </span>
        <span id="tq_68" class="t s2_68">
          тулгамдаж{" "}
        </span>
        <span id="tr_68" class="t s2_68">
          буй{" "}
        </span>
        <span id="ts_68" class="t s2_68">
          асуудлыг{" "}
        </span>
        <span id="tt_68" class="t s2_68">
          тодорхойлох нь чухал алхам юм. Тулгамдаж буй асуудлыг гүйцэтгэлийн{" "}
        </span>
        <span id="tu_68" class="t s2_68">
          үнэлгээний{" "}
        </span>
        <span id="tv_68" class="t s2_68">
          шалгуурын{" "}
        </span>
        <span id="tw_68" class="t s2_68">
          дагуу{" "}
        </span>
        <span id="tx_68" class="t s2_68">
          тодорхойлохдоо{" "}
        </span>
        <span id="ty_68" class="t s2_68">
          оролцогч{" "}
        </span>
        <span id="tz_68" class="t s2_68">
          талуудын{" "}
        </span>
        <span id="t10_68" class="t s2_68">
          төлөөллийг бүрэн байлгаж нээлттэй хэлэлцэх замаар зохион байгуулах{" "}
        </span>
        <span id="t11_68" class="t s2_68">
          хэрэгтэй.{" "}
        </span>
      </div>
    </div>
  );
}
