import React from "react";
export default function Twohundred() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_12{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_12{left:329px;bottom:941px;letter-spacing:-0.26px;}

.s0_12{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_12{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_12{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts200" type="text/css" >

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg12" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="12/12.svg"
          type="image/svg+xml"
          id="pdf12"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_12" class="t s0_12">
          12{" "}
        </span>
        <span id="t2_12" class="t s2_12">
          ТЭМДЭГЛЭЛ{" "}
        </span>
      </div>
    </div>
  );
}
