import React from "react";
export default function F151() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_151{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_151{left:140px;bottom:941px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t3_151{left:417px;bottom:941px;letter-spacing:-0.11px;}
#t4_151{left:515px;bottom:941px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t5_151{left:87px;bottom:680px;letter-spacing:-0.13px;word-spacing:2.57px;}
#t6_151{left:87px;bottom:660px;letter-spacing:-0.16px;}
#t7_151{left:144px;bottom:660px;letter-spacing:-0.22px;word-spacing:0.11px;}
#t8_151{left:263px;bottom:660px;letter-spacing:-0.22px;word-spacing:0.13px;}
#t9_151{left:87px;bottom:627px;letter-spacing:-0.37px;}
#ta_151{left:146px;bottom:627px;letter-spacing:-0.09px;}
#tb_151{left:203px;bottom:627px;letter-spacing:-0.17px;}
#tc_151{left:307px;bottom:627px;letter-spacing:-0.15px;}
#td_151{left:364px;bottom:627px;letter-spacing:-0.19px;}
#te_151{left:483px;bottom:627px;letter-spacing:-0.14px;}
#tf_151{left:584px;bottom:627px;letter-spacing:-0.22px;}
#tg_151{left:87px;bottom:605px;letter-spacing:-0.17px;word-spacing:0.08px;}
#th_151{left:87px;bottom:379px;letter-spacing:0.15px;word-spacing:3.34px;}
#ti_151{left:87px;bottom:357px;letter-spacing:0.11px;word-spacing:0.07px;}
#tj_151{left:250px;bottom:357px;}
#tk_151{left:142px;bottom:310px;}
#tl_151{left:143px;bottom:268px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tm_151{left:143px;bottom:240px;}
#tn_151{left:169px;bottom:240px;letter-spacing:-0.12px;word-spacing:2.12px;}
#to_151{left:169px;bottom:219px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tp_151{left:143px;bottom:195px;}
#tq_151{left:169px;bottom:195px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tr_151{left:143px;bottom:170px;}
#ts_151{left:169px;bottom:170px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tt_151{left:143px;bottom:146px;}
#tu_151{left:169px;bottom:146px;letter-spacing:-0.13px;word-spacing:3.18px;}
#tv_151{left:169px;bottom:126px;letter-spacing:-0.1px;}

.s0_151{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_151{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_151{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_151{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_151{font-size:18px;font-family:MogulArial_16s;color:#D1622B;}
.s5_151{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts151" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg151" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="151/151.svg"
          type="image/svg+xml"
          id="pdf151"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_151" class="t s0_151">
          151{" "}
        </span>
        <span id="t2_151" class="t s1_151">
          Та өөрийн и-мэйл хаягийг ашиглан{" "}
        </span>
        <span id="t3_151" class="t s2_151">
          Aplus.mn-д{" "}
        </span>
        <span id="t4_151" class="t s1_151">
          нэвтэрч орно.{" "}
        </span>
        <span id="t5_151" class="t s1_151">
          Баруун дээд буланд миний профайл цэс дээр дараад хувийн мэдээллээ{" "}
        </span>
        <span id="t6_151" class="t s1_151">
          бөглөн{" "}
        </span>
        <span id="t7_151" class="t s2_151">
          “Багш болох”{" "}
        </span>
        <span id="t8_151" class="t s1_151">
          хүсэлт дээр овог, нэр, РД бичнэ.{" "}
        </span>
        <span id="t9_151" class="t s1_151">
          Таны{" "}
        </span>
        <span id="ta_151" class="t s2_151">
          ESIS{" "}
        </span>
        <span id="tb_151" class="t s1_151">
          бүртгэлтэй{" "}
        </span>
        <span id="tc_151" class="t s1_151">
          таны{" "}
        </span>
        <span id="td_151" class="t s1_151">
          мэдээлэлтэй{" "}
        </span>
        <span id="te_151" class="t s1_151">
          давхацсан{" "}
        </span>
        <span id="tf_151" class="t s1_151">
          тохиолдолд{" "}
        </span>
        <span id="tg_151" class="t s1_151">
          баталгаажиж үндсэн сургалтад хамрагдах эрхтэй болно.{" "}
        </span>
        <span id="th_151" class="t s3_151">
          Багшийн мэргэжлийн хөгжлийг сургуулийн удирдлагын зүгээс{" "}
        </span>
        <span id="ti_151" class="t s3_151">
          хэрхэн дэмжих вэ
        </span>
        <span id="tj_151" class="t s4_151">
          ?{" "}
        </span>
        <span id="tk_151" class="t s3_151">
          АСУУДАЛ{" "}
        </span>
        <span id="tl_151" class="t s1_151">
          Төлөвлөгөөнүүд уялдаа холбоогүй{" "}
        </span>
        <span id="tm_151" class="t s5_151">
          •{" "}
        </span>
        <span id="tn_151" class="t s1_151">
          Багшийн ажлын төлөвлөгөө, мэргэжлийн хөгжлийн төлөвлөгөө,{" "}
        </span>
        <span id="to_151" class="t s1_151">
          сургуулийн төлөвлөгөө хоорондоо уялдаа холбоо бага{" "}
        </span>
        <span id="tp_151" class="t s5_151">
          •{" "}
        </span>
        <span id="tq_151" class="t s1_151">
          Олон төрлийн төлөвлөгөө нь цаасны ажлыг нэмэгдүүлдэг{" "}
        </span>
        <span id="tr_151" class="t s5_151">
          •{" "}
        </span>
        <span id="ts_151" class="t s1_151">
          Төлөвлөгөөнүүдийг уялдуулах маш төвөгтэй{" "}
        </span>
        <span id="tt_151" class="t s5_151">
          •{" "}
        </span>
        <span id="tu_151" class="t s1_151">
          Олон үйл ажиллагааг төлөвлөдөг тул төлөвлөсөн ажлаа хийж{" "}
        </span>
        <span id="tv_151" class="t s1_151">
          амждаггүй{" "}
        </span>
      </div>
    </div>
  );
}
