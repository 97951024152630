import React from "react";
export default function Ten() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_10{left:87px;bottom:58px;letter-spacing:-0.09px;}
#t2_10{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_10{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:8.21px;}
#t4_10{left:421px;bottom:941px;letter-spacing:-0.12px;word-spacing:8.22px;}
#t5_10{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t6_10{left:87px;bottom:888px;letter-spacing:-0.13px;word-spacing:5.32px;}
#t7_10{left:592px;bottom:888px;letter-spacing:-0.17px;word-spacing:5.38px;}
#t8_10{left:87px;bottom:867px;letter-spacing:-0.17px;}
#t9_10{left:291px;bottom:867px;letter-spacing:-0.15px;word-spacing:2.31px;}
#ta_10{left:87px;bottom:847px;letter-spacing:-0.09px;}
#tb_10{left:87px;bottom:814px;letter-spacing:-0.16px;word-spacing:0.72px;}
#tc_10{left:239px;bottom:814px;letter-spacing:-0.15px;word-spacing:0.72px;}
#td_10{left:87px;bottom:794px;letter-spacing:-0.21px;word-spacing:6.28px;}
#te_10{left:87px;bottom:774px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tf_10{left:87px;bottom:741px;letter-spacing:-0.2px;word-spacing:0.98px;}
#tg_10{left:318px;bottom:741px;letter-spacing:-0.14px;word-spacing:0.95px;}
#th_10{left:87px;bottom:720px;letter-spacing:-0.14px;word-spacing:1.57px;}
#ti_10{left:87px;bottom:700px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tj_10{left:87px;bottom:667px;letter-spacing:-0.17px;word-spacing:2.05px;}
#tk_10{left:249px;bottom:667px;letter-spacing:-0.16px;word-spacing:2.05px;}
#tl_10{left:87px;bottom:647px;letter-spacing:-0.13px;word-spacing:2.17px;}
#tm_10{left:87px;bottom:627px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tn_10{left:87px;bottom:594px;letter-spacing:-0.15px;word-spacing:-0.24px;}
#to_10{left:305px;bottom:594px;letter-spacing:-0.14px;word-spacing:-0.24px;}
#tp_10{left:87px;bottom:573px;letter-spacing:-0.14px;word-spacing:9.34px;}
#tq_10{left:87px;bottom:553px;letter-spacing:-0.14px;word-spacing:0.88px;}
#tr_10{left:87px;bottom:533px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ts_10{left:87px;bottom:500px;letter-spacing:-0.2px;word-spacing:3.29px;}
#tt_10{left:319px;bottom:500px;letter-spacing:-0.17px;word-spacing:3.28px;}
#tu_10{left:87px;bottom:480px;letter-spacing:-0.17px;word-spacing:8.28px;}
#tv_10{left:87px;bottom:460px;letter-spacing:-0.13px;word-spacing:-0.39px;}
#tw_10{left:87px;bottom:439px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tx_10{left:87px;bottom:406px;letter-spacing:-0.17px;word-spacing:2.58px;}
#ty_10{left:291px;bottom:406px;letter-spacing:-0.16px;word-spacing:2.58px;}
#tz_10{left:87px;bottom:386px;letter-spacing:-0.19px;word-spacing:3.08px;}
#t10_10{left:87px;bottom:366px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t11_10{left:87px;bottom:333px;letter-spacing:-0.15px;}
#t12_10{left:183px;bottom:333px;letter-spacing:-0.13px;word-spacing:2.38px;}
#t13_10{left:87px;bottom:313px;letter-spacing:-0.13px;word-spacing:-0.61px;}
#t14_10{left:87px;bottom:293px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t15_10{left:87px;bottom:259px;letter-spacing:-0.14px;word-spacing:2.12px;}
#t16_10{left:375px;bottom:259px;}
#t17_10{left:387px;bottom:259px;letter-spacing:-0.14px;word-spacing:2.13px;}
#t18_10{left:87px;bottom:239px;letter-spacing:-0.15px;word-spacing:1.83px;}
#t19_10{left:87px;bottom:219px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1a_10{left:87px;bottom:186px;letter-spacing:-0.14px;word-spacing:3.41px;}
#t1b_10{left:335px;bottom:186px;letter-spacing:-0.19px;word-spacing:3.48px;}
#t1c_10{left:87px;bottom:166px;letter-spacing:-0.17px;word-spacing:1.24px;}
#t1d_10{left:87px;bottom:146px;letter-spacing:-0.14px;word-spacing:0.05px;}

.s0_10{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_10{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_10{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_10{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts10" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg10" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="10/10.svg"
          type="image/svg+xml"
          id="pdf10"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_10" class="t s1_10">
          10{" "}
        </span>
        <span id="t3_10" class="t s2_10">
          Судлагдахуун дамнасан интеграци{" "}
        </span>
        <span id="t4_10" class="t s2_10">
          (Transdisciplinary integration)-{" "}
        </span>
        <span id="t5_10" class="t s3_10">
          Судлагдахуунуудын агуулгаас давсан, өргөн хүрээг хамарсан интеграци;{" "}
        </span>
        <span id="t6_10" class="t s2_10">
          Хичээл хоорондын агуулга/сэдэв (Cross-cutting themes)-{" "}
        </span>
        <span id="t7_10" class="t s3_10">
          Хэд хэдэн{" "}
        </span>
        <span id="t8_10" class="t s3_10">
          хичээл/судлагдахуунаар{" "}
        </span>
        <span id="t9_10" class="t s3_10">
          судалдаг сургалтын хөтөлбөрийн чухал агуулга/{" "}
        </span>
        <span id="ta_10" class="t s3_10">
          сэдэв;{" "}
        </span>
        <span id="tb_10" class="t s2_10">
          Сургалтын орчин{" "}
        </span>
        <span id="tc_10" class="t s3_10">
          гэж сургалтын хөтөлбөрийг хэрэгжүүлэхэд шаардагдах,{" "}
        </span>
        <span id="td_10" class="t s3_10">
          эрх бүхий байгууллагаас баталсан стандарт, техникийн зохицуулалтад{" "}
        </span>
        <span id="te_10" class="t s3_10">
          нийцсэн материаллаг болон сэтгэл зүйн нөхцөл, сурах, сургах харилцааг;{" "}
        </span>
        <span id="tf_10" class="t s2_10">
          Сургалтын хэрэглэгдэхүүн{" "}
        </span>
        <span id="tg_10" class="t s3_10">
          - сургалтын арга зүйг хэрэгжүүлэхэд ашиглах{" "}
        </span>
        <span id="th_10" class="t s3_10">
          техник хэрэгсэл, багаж төхөөрөмж, урвалж бодис, загвар, үзүүлэн,
          тараах{" "}
        </span>
        <span id="ti_10" class="t s3_10">
          материал, сурах бичиг, гарын авлага, цахим хэрэглэгдэхүүнийг
          хамруулна;{" "}
        </span>
        <span id="tj_10" class="t s2_10">
          Сургуулийн орчин{" "}
        </span>
        <span id="tk_10" class="t s3_10">
          - ерөнхий боловсролын сургуулийн хоол үйлдвэрлэл,{" "}
        </span>
        <span id="tl_10" class="t s3_10">
          үйлчилгээний тухай хуульд заасан сургуульд эзэмшүүлсэн газар, түүнээс{" "}
        </span>
        <span id="tm_10" class="t s3_10">
          эргэн тойронд 150 метр хүртэлх газар;{" "}
        </span>
        <span id="tn_10" class="t s2_10">
          Сургуулийн гадаад орчин{" "}
        </span>
        <span id="to_10" class="t s3_10">
          - суралцагчийн суралцах, хөгжих, хамгаалагдах{" "}
        </span>
        <span id="tp_10" class="t s3_10">
          нөхцөлийг бүрдүүлж, стандартын дагуу байгуулсан тоглоом, биеийн{" "}
        </span>
        <span id="tq_10" class="t s3_10">
          тамир, туршилтын болон задгай талбай, ногоон байгууламж, аж ахуйн үйл{" "}
        </span>
        <span id="tr_10" class="t s3_10">
          ажиллагааны бүс бүхий орчин;{" "}
        </span>
        <span id="ts_10" class="t s2_10">
          Сургуулийн дотоод орчин{" "}
        </span>
        <span id="tt_10" class="t s3_10">
          - олон нийт, иргэний барилгын норм дүрэм,{" "}
        </span>
        <span id="tu_10" class="t s3_10">
          стандартын дагуу барьсан сургуулийн барилга байгууламжийн дотор{" "}
        </span>
        <span id="tv_10" class="t s3_10">
          суралцагчийн суралцах, хөгжих, хамгаалагдах, тэгш боломжийг бүрдүүлсэн{" "}
        </span>
        <span id="tw_10" class="t s3_10">
          материаллаг орчин;{" "}
        </span>
        <span id="tx_10" class="t s2_10">
          Танин мэдэхүйн хөгжил
        </span>
        <span id="ty_10" class="t s3_10">
          - Танин мэдэх үйл ажиллагааны бүрдэл болсон{" "}
        </span>
        <span id="tz_10" class="t s3_10">
          хүртэхүй, ой тогтоолт, ухагдахуун төлөвшил, асуудлыг шийдвэрлэх, учир{" "}
        </span>
        <span id="t10_10" class="t s3_10">
          шалтгаанд нь сэтгэх үйл явцын хөгжил;{" "}
        </span>
        <span id="t11_10" class="t s2_10">
          Нийгэмшил
        </span>
        <span id="t12_10" class="t s3_10">
          - Хувь хүн нь бүлэг хүмүүстэй буюу нийгэмд зохицохын тулд{" "}
        </span>
        <span id="t13_10" class="t s3_10">
          нийгмийн хэм хэмжээ, үзэл баримтлал, эерэг хандлага, харилцааны чадвар{" "}
        </span>
        <span id="t14_10" class="t s3_10">
          эзэмших тасралтгүй үйл явц;{" "}
        </span>
        <span id="t15_10" class="t s2_10">
          Хүүхдийн эсэн мэнд амьдрах эрх
        </span>
        <span id="t16_10" class="t s3_10">
          -{" "}
        </span>
        <span id="t17_10" class="t s3_10">
          Хүүхэд төрмөгцөө нэр авах, Монгол{" "}
        </span>
        <span id="t18_10" class="t s3_10">
          Улсын харьяат байх, эрүүл өсч бойжих, аюулгүй орчинд амьдрах, аливаа{" "}
        </span>
        <span id="t19_10" class="t s3_10">
          хүчирхийллээс ангид байх;{" "}
        </span>
        <span id="t1a_10" class="t s2_10">
          Хүүхдийн хамгаалуулах эрх{" "}
        </span>
        <span id="t1b_10" class="t s3_10">
          - Хүүхэд гэмт хэрэг, зөрчил, хүчирхийлэл,{" "}
        </span>
        <span id="t1c_10" class="t s3_10">
          бие махбодын шийтгэл, сэтгэл санааны дарамт, үл хайхрах байдал болон{" "}
        </span>
        <span id="t1d_10" class="t s3_10">
          мөлжлөгийн аливаа хэлбэрээс нийгмийн бүх орчинд хамгаалагдах;{" "}
        </span>
      </div>
    </div>
  );
}
