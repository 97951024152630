import React from "react";
export default function HundredEighteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_118{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_118{left:114px;bottom:941px;}
#t3_118{left:142px;bottom:941px;letter-spacing:-0.13px;word-spacing:9.54px;}
#t4_118{left:553px;bottom:941px;letter-spacing:-0.13px;word-spacing:9.55px;}
#t5_118{left:142px;bottom:921px;letter-spacing:-0.15px;word-spacing:5.06px;}
#t6_118{left:142px;bottom:901px;letter-spacing:-0.11px;word-spacing:1.86px;}
#t7_118{left:142px;bottom:880px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_118{left:114px;bottom:856px;}
#t9_118{left:142px;bottom:856px;letter-spacing:-0.18px;word-spacing:-0.43px;}
#ta_118{left:388px;bottom:856px;letter-spacing:-0.13px;word-spacing:-0.47px;}
#tb_118{left:142px;bottom:836px;letter-spacing:-0.15px;word-spacing:1.96px;}
#tc_118{left:142px;bottom:816px;letter-spacing:-0.11px;word-spacing:1.88px;}
#td_118{left:142px;bottom:795px;letter-spacing:-0.16px;word-spacing:-0.52px;}
#te_118{left:142px;bottom:775px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tf_118{left:87px;bottom:751px;letter-spacing:-0.13px;word-spacing:0.6px;}
#tg_118{left:87px;bottom:731px;letter-spacing:-0.19px;word-spacing:7.96px;}
#th_118{left:87px;bottom:710px;letter-spacing:-0.19px;word-spacing:4.15px;}
#ti_118{left:87px;bottom:690px;letter-spacing:-0.13px;word-spacing:-0.03px;}
#tj_118{left:87px;bottom:670px;letter-spacing:-0.13px;word-spacing:6.02px;}
#tk_118{left:87px;bottom:650px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tl_118{left:87px;bottom:617px;letter-spacing:-0.13px;word-spacing:-1.16px;}
#tm_118{left:87px;bottom:597px;letter-spacing:-0.14px;word-spacing:2.57px;}
#tn_118{left:87px;bottom:576px;letter-spacing:-0.2px;word-spacing:6.51px;}
#to_118{left:87px;bottom:556px;letter-spacing:-0.18px;}
#tp_118{left:129px;bottom:526px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tq_118{left:87px;bottom:164px;letter-spacing:-0.09px;}
#tr_118{left:161px;bottom:164px;letter-spacing:-0.12px;}
#ts_118{left:258px;bottom:164px;letter-spacing:-0.14px;}
#tt_118{left:400px;bottom:164px;letter-spacing:-0.26px;}
#tu_118{left:487px;bottom:164px;letter-spacing:-0.1px;}
#tv_118{left:562px;bottom:164px;letter-spacing:-0.14px;}
#tw_118{left:87px;bottom:144px;letter-spacing:-0.14px;word-spacing:4.45px;}
#tx_118{left:87px;bottom:123px;letter-spacing:-0.21px;word-spacing:9.05px;}
#ty_118{left:87px;bottom:103px;letter-spacing:-0.15px;word-spacing:3.38px;}

.s0_118{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_118{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_118{font-size:17px;font-family:Arial-ItalicMT_18u;color:#000;}
.s3_118{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s4_118{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_118{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts118" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg118" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="118/118.svg"
          type="image/svg+xml"
          id="pdf118"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_118" class="t s0_118">
          118{" "}
        </span>
        <span id="t2_118" class="t s1_118">
          •{" "}
        </span>
        <span id="t3_118" class="t s2_118">
          Орон нутгийн иргэдийн оролцоонд суурилах.{" "}
        </span>
        <span id="t4_118" class="t s3_118">
          Орон нутагтаа{" "}
        </span>
        <span id="t5_118" class="t s3_118">
          ажиллаж, хөдөлмөрлөж байгаа иргэдийг хичээлдээ урих, тэдний{" "}
        </span>
        <span id="t6_118" class="t s3_118">
          ажил, амьдралын түүхэн замнал туршлага, шинэ санаа, бүтээлээс{" "}
        </span>
        <span id="t7_118" class="t s3_118">
          суралцах боломж нөхцөлийг бүрдүүлэх{" "}
        </span>
        <span id="t8_118" class="t s1_118">
          •{" "}
        </span>
        <span id="t9_118" class="t s2_118">
          Орчинд нь сургалтыг явуулах.{" "}
        </span>
        <span id="ta_118" class="t s3_118">
          Сургалтыг танхимаас гаргах, практик{" "}
        </span>
        <span id="tb_118" class="t s3_118">
          хэрэглээтэй уялдуулах, сургалтын агуулга, арга зүйг интеграцчилж{" "}
        </span>
        <span id="tc_118" class="t s3_118">
          хүний нөөцийг чадавхжуулах, сурагчийн эрэлт хэрэгцээнд нийцсэн{" "}
        </span>
        <span id="td_118" class="t s3_118">
          шинэлэг сургалтын технологи, арга хэрэгслийг сургалтад нэвтрүүлэх{" "}
        </span>
        <span id="te_118" class="t s3_118">
          зэргийг багтаан ойлгоно{" "}
        </span>
        <span id="tf_118" class="t s4_118">
          Сургалтын загвар (learning models) гэж шинэ ур чадвар, мэдлэг
          мэдээлэлд{" "}
        </span>
        <span id="tg_118" class="t s4_118">
          суралцах аливаа үйл ажиллагааны үе шат, хэлбэрийг хэлнэ. Орчин{" "}
        </span>
        <span id="th_118" class="t s4_118">
          үед сургалтын загвар нь сургалтын хэв маяг, онол арга зүй, сургалтын{" "}
        </span>
        <span id="ti_118" class="t s4_118">
          стратегийг дагаж олон төрөл, ангиллаар хөгжиж байна. Жишээ нь: Асуудал{" "}
        </span>
        <span id="tj_118" class="t s4_118">
          шийдвэрлэх сургалтын загвар (Problem-Based Learning - PBL), төсөлд{" "}
        </span>
        <span id="tk_118" class="t s4_118">
          суурилсан сургалтын загвар (Project-Based Learning -PrBL) гэх мэт.{" "}
        </span>
        <span id="tl_118" class="t s4_118">
          СССХ-ийг төсөлд суурилсан сургалтын загвараар хэрэгжүүлнэ гэвэл дараах{" "}
        </span>
        <span id="tm_118" class="t s4_118">
          үе шатаар сургалтыг төлөвлөж, хийж сурах зарчимд суурилсан идэвхтэй{" "}
        </span>
        <span id="tn_118" class="t s4_118">
          сургалтын аргуудыг ашиглан сургалт, үйл ажиллагааг удирдан зохион{" "}
        </span>
        <span id="to_118" class="t s4_118">
          байгуулна.{" "}
        </span>
        <span id="tp_118" class="t s5_118">
          Зураг 3.15. Төсөлд суурилсан сургалтын заавар: төлөвлөлт зохион
          байгуулалт{" "}
        </span>
        <span id="tq_118" class="t s4_118">
          Төсөлд{" "}
        </span>
        <span id="tr_118" class="t s4_118">
          суурилсан{" "}
        </span>
        <span id="ts_118" class="t s4_118">
          суралцахуйгаар{" "}
        </span>
        <span id="tt_118" class="t s4_118">
          СССХ-ыг{" "}
        </span>
        <span id="tu_118" class="t s4_118">
          туршин{" "}
        </span>
        <span id="tv_118" class="t s4_118">
          хэрэгжүүлэхэд{" "}
        </span>
        <span id="tw_118" class="t s4_118">
          сургалтын агуулга, арга зүйг интеграцчилах хэрэгцээ, шаардлага үүсэж{" "}
        </span>
        <span id="tx_118" class="t s4_118">
          болно. Ийм тохиолдолд сургуулийн бүх багш хамтарч сургалт, үйл{" "}
        </span>
        <span id="ty_118" class="t s4_118">
          ажиллагааг төлөвлөх, турших, хэрэгжүүлэх, үнэлэх шаардлага тулгардаг{" "}
        </span>
      </div>
    </div>
  );
}
