import React from "react";
export default function FiftyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_53{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_53{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_53{left:141px;bottom:943px;letter-spacing:0.12px;word-spacing:0.03px;}
#t4_53{left:157px;bottom:901px;letter-spacing:0.19px;}
#t5_53{left:296px;bottom:910px;letter-spacing:0.07px;word-spacing:0.07px;}
#t6_53{left:227px;bottom:892px;}
#t7_53{left:231px;bottom:892px;letter-spacing:0.12px;word-spacing:0.03px;}
#t8_53{left:607px;bottom:892px;}
#t9_53{left:179px;bottom:870px;}
#ta_53{left:429px;bottom:870px;letter-spacing:0.2px;}
#tb_53{left:177px;bottom:850px;letter-spacing:0.08px;}
#tc_53{left:429px;bottom:850px;letter-spacing:0.2px;}
#td_53{left:175px;bottom:830px;letter-spacing:0.08px;}
#te_53{left:429px;bottom:830px;letter-spacing:0.2px;}
#tf_53{left:174px;bottom:810px;letter-spacing:0.13px;}
#tg_53{left:429px;bottom:810px;letter-spacing:0.2px;}
#th_53{left:87px;bottom:773px;letter-spacing:-0.14px;word-spacing:4.05px;}
#ti_53{left:87px;bottom:754px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_53{left:103px;bottom:705px;letter-spacing:0.09px;word-spacing:5.03px;}
#tk_53{left:103px;bottom:686px;letter-spacing:0.08px;word-spacing:-1.76px;}
#tl_53{left:103px;bottom:668px;letter-spacing:0.09px;word-spacing:0.07px;}
#tm_53{left:103px;bottom:650px;letter-spacing:0.1px;word-spacing:0.04px;}
#tn_53{left:87px;bottom:587px;letter-spacing:-0.43px;}
#to_53{left:200px;bottom:587px;letter-spacing:-0.08px;}
#tp_53{left:276px;bottom:587px;letter-spacing:-0.14px;}
#tq_53{left:87px;bottom:567px;letter-spacing:-0.16px;}
#tr_53{left:206px;bottom:567px;letter-spacing:-0.13px;}
#ts_53{left:341px;bottom:567px;letter-spacing:-0.17px;}
#tt_53{left:87px;bottom:547px;letter-spacing:-0.12px;word-spacing:-0.88px;}
#tu_53{left:87px;bottom:527px;letter-spacing:-0.26px;word-spacing:8.91px;}
#tv_53{left:87px;bottom:507px;letter-spacing:-0.16px;word-spacing:0.11px;}
#tw_53{left:87px;bottom:487px;letter-spacing:-0.13px;}
#tx_53{left:180px;bottom:487px;letter-spacing:-0.15px;}
#ty_53{left:294px;bottom:487px;letter-spacing:-0.12px;}
#tz_53{left:87px;bottom:466px;letter-spacing:-0.21px;word-spacing:3.26px;}
#t10_53{left:87px;bottom:446px;letter-spacing:-0.15px;word-spacing:2.04px;}
#t11_53{left:87px;bottom:426px;letter-spacing:-0.14px;word-spacing:-0.83px;}
#t12_53{left:87px;bottom:406px;letter-spacing:-0.14px;word-spacing:6.36px;}
#t13_53{left:87px;bottom:386px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t14_53{left:140px;bottom:321px;letter-spacing:-0.2px;}
#t15_53{left:120px;bottom:295px;letter-spacing:-0.25px;word-spacing:0.06px;}
#t16_53{left:304px;bottom:269px;letter-spacing:-0.23px;word-spacing:0.06px;}
#t17_53{left:87px;bottom:225px;letter-spacing:-0.15px;word-spacing:-0.84px;}
#t18_53{left:87px;bottom:205px;letter-spacing:-0.14px;word-spacing:-1.47px;}
#t19_53{left:87px;bottom:185px;letter-spacing:-0.13px;}
#t1a_53{left:193px;bottom:185px;letter-spacing:-0.09px;}
#t1b_53{left:266px;bottom:185px;letter-spacing:-0.09px;}
#t1c_53{left:334px;bottom:185px;letter-spacing:-0.14px;}
#t1d_53{left:388px;bottom:185px;letter-spacing:-0.11px;}
#t1e_53{left:87px;bottom:164px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1f_53{left:87px;bottom:144px;letter-spacing:-0.01px;}
#t1g_53{left:159px;bottom:144px;letter-spacing:-0.14px;}
#t1h_53{left:215px;bottom:144px;letter-spacing:-0.11px;}
#t1i_53{left:324px;bottom:144px;letter-spacing:-0.13px;}
#t1j_53{left:400px;bottom:144px;letter-spacing:-0.12px;}
#t1k_53{left:459px;bottom:144px;letter-spacing:-0.07px;}
#t1l_53{left:87px;bottom:124px;letter-spacing:-0.19px;word-spacing:4.27px;}

.s0_53{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_53{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_53{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s3_53{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s4_53{font-size:15px;font-family:ArialMT_1lq;color:#000;}
.s5_53{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s6_53{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s7_53{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s8_53{font-size:21px;font-family:MogulArial-Bold_179;color:#6D3A96;}
.t.m0_53{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts53" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg53" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="53/53.svg"
          type="image/svg+xml"
          id="pdf53"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_53" class="t s1_53">
          53{" "}
        </span>
        <span id="t3_53" class="t s2_53">
          Хүснэгт 2.5. Индекст хамаарах сургуулийн нэмэлт урамшууллын хэмжээ{" "}
        </span>
        <span id="t4_53" class="t s3_53">
          Индекс{" "}
        </span>
        <span id="t5_53" class="t s3_53">
          Нэмэлт урамшуулал олгох хязгаар{" "}
        </span>
        <span id="t6_53" class="t s3_53">
          /
        </span>
        <span id="t7_53" class="t s2_53">
          тухайн жилийн нийт хувьсах зардлаас тооцох хувь
        </span>
        <span id="t8_53" class="t s3_53">
          /{" "}
        </span>
        <span id="t9_53" class="t s4_53">
          I{" "}
        </span>
        <span id="ta_53" class="t s4_53">
          6%{" "}
        </span>
        <span id="tb_53" class="t s4_53">
          II{" "}
        </span>
        <span id="tc_53" class="t s4_53">
          4%{" "}
        </span>
        <span id="td_53" class="t s4_53">
          III{" "}
        </span>
        <span id="te_53" class="t s4_53">
          3%{" "}
        </span>
        <span id="tf_53" class="t s4_53">
          IV{" "}
        </span>
        <span id="tg_53" class="t s4_53">
          2%{" "}
        </span>
        <span id="th_53" class="t s5_53">
          Сургууль нь нэмэлт урамшууллын 35-аас доошгүй хувийг байгууллагын{" "}
        </span>
        <span id="ti_53" class="t s5_53">
          менежментийг сайжруулахад зарцуулна.{" "}
        </span>
        <span id="tj_53" class="t s6_53">
          Сургалтын байгууллагын гүйцэтгэлийн үнэлгээг шударга, үнэн зөв зохион{" "}
        </span>
        <span id="tk_53" class="t s6_53">
          байгуулах нь сургуулиуд ахиц дэвшлээ тогтмол хянах, сайжруулах
          чиглэлүүдийг{" "}
        </span>
        <span id="tl_53" class="t s6_53">
          тодорхойлох, суралцагчдад чанартай боловсрол эзэмших, оролцогч
          талуудын{" "}
        </span>
        <span id="tm_53" class="t s6_53">
          сэтгэл ханамжийг нэмэгдүүлэх зэрэг эерэг өөрчлөлтийг бий болгоно.{" "}
        </span>
        <span id="tn_53" class="t s7_53">
          Сургалт,{" "}
        </span>
        <span id="to_53" class="t s7_53">
          үйл{" "}
        </span>
        <span id="tp_53" class="t s7_53">
          ажиллагааныхаа{" "}
        </span>
        <span id="tq_53" class="t s7_53">
          гүйцэтгэлээ{" "}
        </span>
        <span id="tr_53" class="t s7_53">
          үнэлүүлснээр{" "}
        </span>
        <span id="ts_53" class="t s7_53">
          сургууль{" "}
        </span>
        <span id="tt_53" class="t s7_53">
          хамаарах кластертаа өөрийн үр дүнг сум,{" "}
        </span>
        <span id="tu_53" class="t s7_53">
          дүүрэг, аймаг, нийслэлтэй харьцуулах{" "}
        </span>
        <span id="tv_53" class="t s7_53">
          боломжтой болно. Ингэснээр нотолгоонд{" "}
        </span>
        <span id="tw_53" class="t s7_53">
          суурилж{" "}
        </span>
        <span id="tx_53" class="t s7_53">
          сургуулийн{" "}
        </span>
        <span id="ty_53" class="t s7_53">
          менежментийг{" "}
        </span>
        <span id="tz_53" class="t s7_53">
          тодорхойлох, сургалт, үйл ажиллагааны{" "}
        </span>
        <span id="t10_53" class="t s7_53">
          чанарыг сайжруулахад шаардлагатай менежментийг хэрэгжүүлэх боломж{" "}
        </span>
        <span id="t11_53" class="t s7_53">
          нэмэгдэнэ. Гүйцэтгэлийн үнэлгээнд багш, эцэг эх, асран хамгаалагч,
          хүүхэд,{" "}
        </span>
        <span id="t12_53" class="t s7_53">
          БШУГ-ын мэргэжилтэн бүр хамрагдсанаар сургуулийн хөгжилд тэдний{" "}
        </span>
        <span id="t13_53" class="t s7_53">
          оролцоо, хамтын ажиллагаа илүү тодорхой болно.{" "}
        </span>
        <span id="t14_53" class="t s8_53">
          2.3 ГҮЙЦЭТГЭЛИЙН ҮНЭЛГЭЭНИЙ ҮР ДҮНГИЙН{" "}
        </span>
        <span id="t15_53" class="t s8_53">
          ТАЛААРХ МЭДЭЭЛЛИЙГ ХААНААС АВАХ, ХЭРХЭН{" "}
        </span>
        <span id="t16_53" class="t s8_53">
          АШИГЛАХ ВЭ?{" "}
        </span>
        <span id="t17_53" class="t s7_53">
          Сургалтын байгууллагын гүйцэтгэлийн үнэлгээний{" "}
        </span>
        <span id="t18_53" class="t s7_53">
          үр дүнгийн талаарх тоон мэдээллийг Боловсролын{" "}
        </span>
        <span id="t19_53" class="t s7_53">
          үнэлгээний{" "}
        </span>
        <span id="t1a_53" class="t s7_53">
          төвийн{" "}
        </span>
        <span id="t1b_53" class="t s7_53">
          цахим{" "}
        </span>
        <span id="t1c_53" class="t s7_53">
          тоон{" "}
        </span>
        <span id="t1d_53" class="t s7_53">
          тайлангийн{" "}
        </span>
        <span id="t1e_53" class="t s7_53">
          системээс авна.{" "}
        </span>
        <span id="t1f_53" class="t s7_53">
          Цахим{" "}
        </span>
        <span id="t1g_53" class="t s7_53">
          тоон{" "}
        </span>
        <span id="t1h_53" class="t s7_53">
          тайлангийн{" "}
        </span>
        <span id="t1i_53" class="t s7_53">
          систем{" "}
        </span>
        <span id="t1j_53" class="t s7_53">
          гэдэг{" "}
        </span>
        <span id="t1k_53" class="t s7_53">
          нь{" "}
        </span>
        <span id="t1l_53" class="t s7_53">
          тодорхой давтамжтайгаар хуримтлагдан, зохион{" "}
        </span>
      </div>
    </div>
  );
}
