import React from "react";
export default function Fifty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_50{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_50{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_50{left:87px;bottom:941px;letter-spacing:0.01px;word-spacing:7.8px;}
#t4_50{left:87px;bottom:921px;letter-spacing:0.01px;word-spacing:10.57px;}
#t5_50{left:87px;bottom:901px;letter-spacing:-0.02px;}
#t6_50{left:157px;bottom:872px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t7_50{left:87px;bottom:549px;letter-spacing:0.03px;word-spacing:0.06px;}
#t8_50{left:87px;bottom:529px;word-spacing:4.4px;}
#t9_50{left:87px;bottom:509px;letter-spacing:0.04px;word-spacing:-1.54px;}
#ta_50{left:87px;bottom:489px;letter-spacing:0.01px;word-spacing:0.89px;}
#tb_50{left:87px;bottom:469px;letter-spacing:0.01px;word-spacing:0.34px;}
#tc_50{left:87px;bottom:448px;letter-spacing:0.04px;word-spacing:-3.39px;}
#td_50{left:87px;bottom:428px;letter-spacing:-0.01px;word-spacing:-0.3px;}
#te_50{left:87px;bottom:408px;letter-spacing:-0.02px;word-spacing:0.52px;}
#tf_50{left:87px;bottom:388px;letter-spacing:0.02px;word-spacing:0.04px;}
#tg_50{left:87px;bottom:355px;letter-spacing:-0.14px;word-spacing:-1.74px;}
#th_50{left:87px;bottom:335px;letter-spacing:-0.11px;word-spacing:0.94px;}
#ti_50{left:87px;bottom:314px;letter-spacing:-0.17px;word-spacing:0.09px;}
#tj_50{left:244px;bottom:293px;letter-spacing:0.11px;word-spacing:0.02px;}
#tk_50{left:126px;bottom:259px;letter-spacing:0.19px;}
#tl_50{left:261px;bottom:259px;letter-spacing:0.09px;word-spacing:0.04px;}
#tm_50{left:607px;bottom:258px;}
#tn_50{left:618px;bottom:259px;}
#to_50{left:150px;bottom:234px;}
#tp_50{left:148px;bottom:210px;letter-spacing:0.08px;}
#tq_50{left:146px;bottom:186px;letter-spacing:0.08px;}
#tr_50{left:145px;bottom:162px;letter-spacing:0.13px;}
#ts_50{left:147px;bottom:139px;}

.s0_50{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_50{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_50{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_50{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s4_50{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_50{font-size:17px;font-family:TimesNewRomanPS-ItalicMT_do;color:#000;}
.s6_50{font-size:15px;font-family:ArialMT_1lq;color:#000;}`}
      </style>

      <style id="fonts50" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPS-ItalicMT_do;
	src: url("fonts/TimesNewRomanPS-ItalicMT_do.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg50" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="50/50.svg"
          type="image/svg+xml"
          id="pdf50"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_50" class="t s1_50">
          50{" "}
        </span>
        <span id="t3_50" class="t s2_50">
          Сургалтын байгууллагын гүйцэтгэл, үр дүнгийн үнэлгээ нь багшийн{" "}
        </span>
        <span id="t4_50" class="t s2_50">
          үнэлгээтэй салшгүй холбоотой бөгөөд үүнийг дараах зураглалаар{" "}
        </span>
        <span id="t5_50" class="t s2_50">
          харууллаа.{" "}
        </span>
        <span id="t6_50" class="t s3_50">
          Зураг 2.1. Сургалтын байгууллагын гүйцэтгэлийн үнэлгээний зураглал{" "}
        </span>
        <span id="t7_50" class="t s2_50">
          Өөрийн болон хөндлөнгийн үнэлгээ хийснээр сургуулиуд давуу болон сул{" "}
        </span>
        <span id="t8_50" class="t s2_50">
          талаа илрүүлэх, сайжруулах шаардлагатай чиглэлүүдээ тодорхойлох,{" "}
        </span>
        <span id="t9_50" class="t s2_50">
          боловсролын үйлчилгээнийхээ үр дүнг сайжруулах нөхцөл бүрдсэн. Үүнээс{" "}
        </span>
        <span id="ta_50" class="t s2_50">
          гадна сургуулийн түвшинд нотолгоонд суурилсан шийдвэр гаргахын тулд{" "}
        </span>
        <span id="tb_50" class="t s2_50">
          системтэйгээр мэдээлэл цуглуулж, түүнд тулгуурлан сургуулиа хөгжүүлэх{" "}
        </span>
        <span id="tc_50" class="t s2_50">
          менежментийг төлөвлөх, хэрэгжүүлэх боломжийг бүрдүүллээ. Гүйцэтгэлийн{" "}
        </span>
        <span id="td_50" class="t s2_50">
          үнэлгээ нь сургуулийн менежментийн сайжруулалт, нотолгоонд суурилсан{" "}
        </span>
        <span id="te_50" class="t s2_50">
          шийдвэр гаргалт, сургалтын үйл ажиллагаанд оролцогч талуудын хамтын{" "}
        </span>
        <span id="tf_50" class="t s2_50">
          ажиллагааг дэмжих хэрэгсэл болж байна.{" "}
        </span>
        <span id="tg_50" class="t s2_50">
          Улсын хэмжээнд гүйцэтгэлийн үнэлгээний үр дүнг нийслэл, аймгийн төв,
          сум-{" "}
        </span>
        <span id="th_50" class="t s2_50">
          баг гэсэн гурван байршлаар кластер үүсгэн, кластер тус бүрд 5
          индексээр{" "}
        </span>
        <span id="ti_50" class="t s2_50">
          авч үзсэн.{" "}
        </span>
        <span id="tj_50" class="t s4_50">
          Хүснэгт 2.4. Сургалтын байгууллагын индекс, эрэмбийн хувь{" "}
        </span>
        <span id="tk_50" class="t s4_50">
          Индекс{" "}
        </span>
        <span id="tl_50" class="t s4_50">
          Тухайн кластер дахь сургуулийн эрэмбийн хувь /
        </span>
        <span id="tm_50" class="t s5_50">
          N
        </span>
        <span id="tn_50" class="t s4_50">
          /{" "}
        </span>
        <span id="to_50" class="t s6_50">
          I{" "}
        </span>
        <span id="tp_50" class="t s6_50">
          II{" "}
        </span>
        <span id="tq_50" class="t s6_50">
          III{" "}
        </span>
        <span id="tr_50" class="t s6_50">
          IV{" "}
        </span>
        <span id="ts_50" class="t s6_50">
          V{" "}
        </span>
      </div>
    </div>
  );
}
