import React from "react";
export default function SixtyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_62{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_62{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_62{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:6.45px;}
#t4_62{left:87px;bottom:921px;letter-spacing:-0.21px;word-spacing:0.12px;}
#t5_62{left:87px;bottom:888px;letter-spacing:-0.14px;word-spacing:0.54px;}
#t6_62{left:87px;bottom:867px;letter-spacing:-0.15px;word-spacing:0.89px;}
#t7_62{left:87px;bottom:847px;letter-spacing:-0.13px;word-spacing:6.27px;}
#t8_62{left:87px;bottom:827px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_62{left:124px;bottom:781px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ta_62{left:239px;bottom:764px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tb_62{left:87px;bottom:484px;letter-spacing:-0.13px;word-spacing:8.48px;}
#tc_62{left:87px;bottom:464px;letter-spacing:-0.11px;word-spacing:0.38px;}
#td_62{left:87px;bottom:444px;letter-spacing:-0.14px;word-spacing:0.29px;}
#te_62{left:87px;bottom:424px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_62{left:87px;bottom:390px;letter-spacing:-0.13px;word-spacing:0.22px;}
#tg_62{left:87px;bottom:370px;letter-spacing:-0.11px;word-spacing:0.01px;}
#th_62{left:87px;bottom:337px;letter-spacing:-0.05px;}
#ti_62{left:113px;bottom:337px;letter-spacing:-0.12px;word-spacing:-0.53px;}
#tj_62{left:113px;bottom:317px;letter-spacing:-0.15px;word-spacing:4.47px;}
#tk_62{left:113px;bottom:297px;letter-spacing:-0.14px;word-spacing:7.06px;}
#tl_62{left:113px;bottom:277px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tm_62{left:87px;bottom:252px;letter-spacing:-0.05px;}
#tn_62{left:113px;bottom:252px;letter-spacing:-0.13px;word-spacing:5.84px;}
#to_62{left:113px;bottom:232px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tp_62{left:113px;bottom:212px;letter-spacing:-0.05px;}
#tq_62{left:138px;bottom:212px;letter-spacing:-0.14px;word-spacing:0.93px;}
#tr_62{left:139px;bottom:192px;letter-spacing:-0.1px;word-spacing:5.01px;}
#ts_62{left:310px;bottom:192px;letter-spacing:-0.14px;word-spacing:5.05px;}
#tt_62{left:139px;bottom:171px;letter-spacing:-0.11px;}
#tu_62{left:113px;bottom:151px;letter-spacing:-0.14px;word-spacing:8.56px;}
#tv_62{left:139px;bottom:131px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_62{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_62{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_62{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_62{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s4_62{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts62" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg62" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="62/62.svg"
          type="image/svg+xml"
          id="pdf62"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_62" class="t s1_62">
          62{" "}
        </span>
        <span id="t3_62" class="t s2_62">
          сургуульд өөрийн гүйцэтгэлийг бусадтай харьцуулах боломжийг олгож{" "}
        </span>
        <span id="t4_62" class="t s2_62">
          байгаа юм.{" "}
        </span>
        <span id="t5_62" class="t s2_62">
          Зурагт 2.13-д жишээ болгож харуулсан А сургуулийн ахлах ангийн бичгийн{" "}
        </span>
        <span id="t6_62" class="t s2_62">
          шалгалтын үр дүн бага болон дунд ангийнхаас бага байна. Эдгээр үр дүнг{" "}
        </span>
        <span id="t7_62" class="t s2_62">
          мөн өөрийн хамаарах дүүргийн, нийслэл (бусад дүүргийн)-ийн, улсын{" "}
        </span>
        <span id="t8_62" class="t s2_62">
          дундаж (нийт 3)-тай харьцуулан харах нь тодорхой.{" "}
        </span>
        <span id="t9_62" class="t s3_62">
          Зураг 2.13. Хөтөлбөрийн хэрэгжилтийн үр дүнгийн мэдээллийн харагдах
          байдал{" "}
        </span>
        <span id="ta_62" class="t s3_62">
          (боловсролын түвшин, судлагдахуун, оноор){" "}
        </span>
        <span id="tb_62" class="t s2_62">
          Зураг 2.13-д сургуулийн сургалтын хөтөлбөрийн хэрэгжилтийг улсын{" "}
        </span>
        <span id="tc_62" class="t s2_62">
          дундаж, кластерын дундаж, тухайн сургуулийн орон нутгийн дундаж болон{" "}
        </span>
        <span id="td_62" class="t s2_62">
          судлагдахуун бүрээр суралцагчаас авсан бичгийн шалгалтын гүйцэтгэлийн{" "}
        </span>
        <span id="te_62" class="t s2_62">
          дундаж үзүүлэлтийг харуулсан.{" "}
        </span>
        <span id="tf_62" class="t s2_62">
          Хөтөлбөрийн хэрэгжилттэй холбоотой мэдээллийг авахад дараах зүйлсийг{" "}
        </span>
        <span id="tg_62" class="t s2_62">
          анхаарна. Үүнд:{" "}
        </span>
        <span id="th_62" class="t s4_62">
          1.{" "}
        </span>
        <span id="ti_62" class="t s2_62">
          Сургуулийнхаа хөтөлбөрийн хэрэгжилтийн үр дүнг боловсролын түвшин{" "}
        </span>
        <span id="tj_62" class="t s2_62">
          болон хоёр оноор харьцуулж харна. Боловсролын аль түвшинд сул{" "}
        </span>
        <span id="tk_62" class="t s2_62">
          үзүүлэлттэй байна, тухайн түвшинд оноор харьцуулахад өөрчлөлт{" "}
        </span>
        <span id="tl_62" class="t s2_62">
          гарсан эсэхийг нягтлаарай.{" "}
        </span>
        <span id="tm_62" class="t s4_62">
          2.{" "}
        </span>
        <span id="tn_62" class="t s2_62">
          Сургуулийн дунджийг улсын болон хамаарах кластерын дундажтай{" "}
        </span>
        <span id="to_62" class="t s2_62">
          харьцуулан харна.{" "}
        </span>
        <span id="tp_62" class="t s2_62">
          а.{" "}
        </span>
        <span id="tq_62" class="t s2_62">
          Сургуулийнхаа сул үзүүлэлттэй боловсролын түвшнийг тухайн орон{" "}
        </span>
        <span id="tr_62" class="t s2_62">
          нутгийн кластерын{" "}
        </span>
        <span id="ts_62" class="t s2_62">
          дундаж болон улсын дундажтай харьцуулан{" "}
        </span>
        <span id="tt_62" class="t s2_62">
          хараарай.{" "}
        </span>
        <span id="tu_62" class="t s2_62">
          б. Суралцагчдын гүйцэтгэлийг оноор нь харьцуулж, өсөлт болон{" "}
        </span>
        <span id="tv_62" class="t s2_62">
          бууралтыг өөрийн сургуулийнхаа үзүүлэлттэй харьцуулан хараарай.{" "}
        </span>
      </div>
    </div>
  );
}
