import React from "react";
export default function NinetyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_98{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_98{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:6.07px;}
#t3_98{left:87px;bottom:921px;letter-spacing:-0.12px;}
#t4_98{left:190px;bottom:921px;letter-spacing:-0.53px;}
#t5_98{left:268px;bottom:921px;letter-spacing:-0.09px;}
#t6_98{left:358px;bottom:921px;letter-spacing:-0.16px;}
#t7_98{left:429px;bottom:921px;letter-spacing:-0.12px;}
#t8_98{left:532px;bottom:921px;letter-spacing:-0.15px;}
#t9_98{left:614px;bottom:921px;letter-spacing:-0.18px;}
#ta_98{left:87px;bottom:901px;letter-spacing:-0.16px;word-spacing:4.36px;}
#tb_98{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:-1.31px;}
#tc_98{left:87px;bottom:860px;letter-spacing:-0.17px;word-spacing:6.09px;}
#td_98{left:522px;bottom:860px;}
#te_98{left:537px;bottom:860px;letter-spacing:-0.13px;word-spacing:6.05px;}
#tf_98{left:87px;bottom:840px;letter-spacing:-0.15px;word-spacing:10.19px;}
#tg_98{left:87px;bottom:820px;letter-spacing:-0.15px;word-spacing:-1.06px;}
#th_98{left:87px;bottom:800px;letter-spacing:-0.2px;word-spacing:-0.98px;}
#ti_98{left:87px;bottom:780px;letter-spacing:-0.2px;word-spacing:0.64px;}
#tj_98{left:87px;bottom:759px;letter-spacing:-0.14px;word-spacing:-0.44px;}
#tk_98{left:87px;bottom:739px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tl_98{left:194px;bottom:678px;letter-spacing:-0.15px;}
#tm_98{left:237px;bottom:678px;letter-spacing:-0.35px;}
#tn_98{left:275px;bottom:678px;letter-spacing:-0.17px;word-spacing:0.05px;}
#to_98{left:87px;bottom:328px;letter-spacing:-0.16px;word-spacing:7.98px;}
#tp_98{left:87px;bottom:308px;letter-spacing:-0.14px;word-spacing:-0.05px;}
#tq_98{left:87px;bottom:288px;letter-spacing:-0.16px;word-spacing:0.17px;}
#tr_98{left:87px;bottom:267px;letter-spacing:-0.12px;word-spacing:0.03px;}

.s0_98{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_98{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_98{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_98{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts98" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg98" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="98/98.svg"
          type="image/svg+xml"
          id="pdf98"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_98" class="t s0_98">
          98{" "}
        </span>
        <span id="t2_98" class="t s1_98">
          Энэ нь сурагчдын чадваруудыг хөгжүүлж, асуудалд илүү гүн оролцох{" "}
        </span>
        <span id="t3_98" class="t s1_98">
          боломжийг{" "}
        </span>
        <span id="t4_98" class="t s1_98">
          олгодог.{" "}
        </span>
        <span id="t5_98" class="t s1_98">
          Түүнчлэн{" "}
        </span>
        <span id="t6_98" class="t s1_98">
          хичээл{" "}
        </span>
        <span id="t7_98" class="t s1_98">
          хоорондын{" "}
        </span>
        <span id="t8_98" class="t s1_98">
          залгамж{" "}
        </span>
        <span id="t9_98" class="t s1_98">
          холбоог{" "}
        </span>
        <span id="ta_98" class="t s1_98">
          хэрэгжүүлэхэд сургууль, багш нарт олон талын дэмжлэг шаардлагатай.{" "}
        </span>
        <span id="tb_98" class="t s1_98">
          Тухайлбал, өөр өөр хичээлийн агуулгыг уялдуулах, хичээл хооронд
          ашиглаж{" "}
        </span>
        <span id="tc_98" class="t s1_98">
          болохуйц сургалтын нэмэлт материал боловсруулах
        </span>
        <span id="td_98" class="t s2_98">
          ,{" "}
        </span>
        <span id="te_98" class="t s1_98">
          нэмэлт хөтөлбөр{" "}
        </span>
        <span id="tf_98" class="t s1_98">
          хэрэгжүүлэх, хичээл хоорондын уялдааг хангасан сургалтыг зохион{" "}
        </span>
        <span id="tg_98" class="t s1_98">
          байгуулахад шаардагдах хэрэгцээт эх үүсвэрүүдийг нэвтрүүлэх гэх мэт
          олон{" "}
        </span>
        <span id="th_98" class="t s1_98">
          талын дэмжлэг, туслалцаа байж болно. Жишээ нь: Газар зүйн хичээлээр
          хот,{" "}
        </span>
        <span id="ti_98" class="t s1_98">
          хотжилт, хот байгуулалтын загварууд гэх мэт суурь ойлголтуудыг
          судалсан{" "}
        </span>
        <span id="tj_98" class="t s1_98">
          гэж үзээд хот төлөвлөлтийн асуудлыг газар зүй, биологи, дизайн
          технологи,{" "}
        </span>
        <span id="tk_98" class="t s1_98">
          физикийн хичээлүүдийн хүрээнд нэгтгэж судлах хичээлийн санааг үзүүлэв.{" "}
        </span>
        <span id="tl_98" class="t s3_98">
          Зураг{" "}
        </span>
        <span id="tm_98" class="t s3_98">
          3.11.{" "}
        </span>
        <span id="tn_98" class="t s3_98">
          Хэд хэдэн хичээлийн агуулгын уялдаа холбоо{" "}
        </span>
        <span id="to_98" class="t s1_98">
          Энэ нэгж хичээлээр дамжуулан сурагч хот төлөвлөх шаардлага, ач{" "}
        </span>
        <span id="tp_98" class="t s1_98">
          холбогдол, үндсэн зарчмуудыг мэдэж, өөрсдийн хэмжээнд төлөвлөлт хийх,{" "}
        </span>
        <span id="tq_98" class="t s1_98">
          ээлтэй хотыг бүтээхэд хүн бүрийн оролцоо чухал гэдгийг ойлгодог
          болоход{" "}
        </span>
        <span id="tr_98" class="t s1_98">
          хамтын хичээлийн үр дүн байна гэж үзсэн.{" "}
        </span>
      </div>
    </div>
  );
}
