import React from "react";
export default function F133() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_133{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_133{left:130px;bottom:856px;}
#t3_133{left:165px;bottom:905px;}
#t4_133{left:187px;bottom:912px;letter-spacing:0.03px;word-spacing:0.07px;}
#t5_133{left:187px;bottom:898px;letter-spacing:0.08px;}
#t6_133{left:340px;bottom:942px;letter-spacing:0.08px;word-spacing:0.02px;}
#t7_133{left:448px;bottom:942px;}
#t8_133{left:340px;bottom:927px;letter-spacing:0.04px;word-spacing:0.06px;}
#t9_133{left:340px;bottom:912px;letter-spacing:0.06px;}
#ta_133{left:406px;bottom:912px;letter-spacing:0.08px;}
#tb_133{left:340px;bottom:898px;letter-spacing:0.06px;word-spacing:0.07px;}
#tc_133{left:340px;bottom:883px;letter-spacing:0.09px;word-spacing:0.01px;}
#td_133{left:340px;bottom:868px;letter-spacing:0.1px;word-spacing:0.02px;}
#te_133{left:505px;bottom:905px;}
#tf_133{left:534px;bottom:942px;letter-spacing:0.06px;word-spacing:0.04px;}
#tg_133{left:534px;bottom:927px;letter-spacing:0.1px;word-spacing:0.01px;}
#th_133{left:534px;bottom:912px;letter-spacing:0.08px;word-spacing:0.02px;}
#ti_133{left:534px;bottom:898px;letter-spacing:0.05px;word-spacing:0.05px;}
#tj_133{left:534px;bottom:883px;letter-spacing:0.04px;word-spacing:0.06px;}
#tk_133{left:534px;bottom:868px;letter-spacing:0.12px;}
#tl_133{left:187px;bottom:850px;letter-spacing:0.1px;word-spacing:0.01px;}
#tm_133{left:505px;bottom:850px;}
#tn_133{left:165px;bottom:825px;}
#to_133{left:187px;bottom:832px;letter-spacing:0.1px;word-spacing:0.01px;}
#tp_133{left:187px;bottom:817px;letter-spacing:0.1px;}
#tq_133{left:408px;bottom:825px;}
#tr_133{left:505px;bottom:801px;}
#ts_133{left:165px;bottom:785px;}
#tt_133{left:187px;bottom:800px;letter-spacing:0.09px;word-spacing:0.02px;}
#tu_133{left:187px;bottom:785px;letter-spacing:0.09px;word-spacing:0.02px;}
#tv_133{left:187px;bottom:770px;letter-spacing:0.09px;}
#tw_133{left:395px;bottom:785px;letter-spacing:0.02px;}
#tx_133{left:131px;bottom:662px;}
#ty_133{left:165px;bottom:738px;}
#tz_133{left:187px;bottom:753px;letter-spacing:0.07px;word-spacing:0.04px;}
#t10_133{left:187px;bottom:738px;letter-spacing:0.06px;word-spacing:0.05px;}
#t11_133{left:187px;bottom:723px;letter-spacing:0.08px;word-spacing:0.03px;}
#t12_133{left:505px;bottom:738px;}
#t13_133{left:534px;bottom:753px;letter-spacing:0.06px;word-spacing:0.05px;}
#t14_133{left:534px;bottom:738px;letter-spacing:0.04px;word-spacing:0.06px;}
#t15_133{left:534px;bottom:723px;letter-spacing:0.08px;}
#t16_133{left:165px;bottom:677px;}
#t17_133{left:187px;bottom:706px;letter-spacing:0.09px;word-spacing:0.02px;}
#t18_133{left:187px;bottom:691px;letter-spacing:0.06px;word-spacing:0.05px;}
#t19_133{left:187px;bottom:677px;letter-spacing:0.1px;}
#t1a_133{left:187px;bottom:662px;letter-spacing:0.05px;word-spacing:0.06px;}
#t1b_133{left:187px;bottom:647px;letter-spacing:0.05px;word-spacing:0.07px;}
#t1c_133{left:398px;bottom:677px;letter-spacing:0.12px;}
#t1d_133{left:505px;bottom:677px;}
#t1e_133{left:534px;bottom:706px;letter-spacing:0.06px;word-spacing:0.04px;}
#t1f_133{left:534px;bottom:691px;letter-spacing:0.09px;word-spacing:0.01px;}
#t1g_133{left:534px;bottom:677px;letter-spacing:0.06px;word-spacing:0.05px;}
#t1h_133{left:534px;bottom:662px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1i_133{left:534px;bottom:647px;letter-spacing:0.07px;word-spacing:0.03px;}
#t1j_133{left:534px;bottom:633px;letter-spacing:0.06px;}
#t1k_133{left:187px;bottom:629px;letter-spacing:0.1px;word-spacing:0.02px;}
#t1l_133{left:392px;bottom:629px;letter-spacing:0.04px;}
#t1m_133{left:505px;bottom:593px;}
#t1n_133{left:165px;bottom:603px;}
#t1o_133{left:187px;bottom:610px;letter-spacing:0.08px;word-spacing:0.02px;}
#t1p_133{left:187px;bottom:596px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1q_133{left:384px;bottom:603px;letter-spacing:0.03px;word-spacing:0.08px;}
#t1r_133{left:165px;bottom:575px;}
#t1s_133{left:187px;bottom:575px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1t_133{left:87px;bottom:530px;letter-spacing:-0.12px;word-spacing:1.59px;}
#t1u_133{left:87px;bottom:510px;letter-spacing:-0.19px;word-spacing:2.51px;}
#t1v_133{left:87px;bottom:490px;letter-spacing:-0.14px;word-spacing:2.62px;}
#t1w_133{left:87px;bottom:470px;letter-spacing:-0.14px;word-spacing:0.72px;}
#t1x_133{left:419px;bottom:470px;letter-spacing:-0.16px;word-spacing:0.74px;}
#t1y_133{left:87px;bottom:450px;letter-spacing:-0.21px;word-spacing:0.12px;}
#t1z_133{left:87px;bottom:387px;letter-spacing:0.14px;}
#t20_133{left:128px;bottom:387px;letter-spacing:0.2px;word-spacing:0.07px;}
#t21_133{left:109px;bottom:338px;letter-spacing:0.12px;word-spacing:0.09px;}
#t22_133{left:142px;bottom:295px;letter-spacing:-0.03px;}
#t23_133{left:139px;bottom:262px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t24_133{left:139px;bottom:238px;}
#t25_133{left:165px;bottom:238px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t26_133{left:139px;bottom:213px;}
#t27_133{left:165px;bottom:213px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t28_133{left:139px;bottom:189px;}
#t29_133{left:165px;bottom:189px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t2a_133{left:139px;bottom:164px;}
#t2b_133{left:165px;bottom:164px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t2c_133{left:139px;bottom:140px;}
#t2d_133{left:165px;bottom:140px;letter-spacing:-0.14px;word-spacing:9.17px;}
#t2e_133{left:165px;bottom:119px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_133{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_133{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s2_133{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}
.s3_133{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_133{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_133{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s6_133{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s7_133{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts133" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg133" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="133/133.svg"
          type="image/svg+xml"
          id="pdf133"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_133" class="t s0_133">
          133{" "}
        </span>
        <span id="t2_133" class="t s1_133">
          b{" "}
        </span>
        <span id="t3_133" class="t s1_133">
          1{" "}
        </span>
        <span id="t4_133" class="t s1_133">
          3 ба баруун эсвэл баруун{" "}
        </span>
        <span id="t5_133" class="t s1_133">
          хойд{" "}
        </span>
        <span id="t6_133" class="t s1_133">
          Зүг чигийг зөв олж{" "}
        </span>
        <span id="t7_133" class="t s2_133">
          3{" "}
        </span>
        <span id="t8_133" class="t s1_133">
          дугаартай байшин,{" "}
        </span>
        <span id="t9_133" class="t s1_133">
          сургуулийн{" "}
        </span>
        <span id="ta_133" class="t s2_133">
          баруун{" "}
        </span>
        <span id="tb_133" class="t s2_133">
          эсвэл баруун хойд{" "}
        </span>
        <span id="tc_133" class="t s1_133">
          талд гэж зөв нөхөж{" "}
        </span>
        <span id="td_133" class="t s1_133">
          бичсэн байх{" "}
        </span>
        <span id="te_133" class="t s1_133">
          2{" "}
        </span>
        <span id="tf_133" class="t s1_133">
          План зурагт буй зүг{" "}
        </span>
        <span id="tg_133" class="t s1_133">
          чигийн тэмдэг ашиглаж{" "}
        </span>
        <span id="th_133" class="t s1_133">
          болно. Баруун, зүүн,{" "}
        </span>
        <span id="ti_133" class="t s1_133">
          хойд, урд гэдгийг зөв{" "}
        </span>
        <span id="tj_133" class="t s1_133">
          хэлэх/ард, хажууд гэж{" "}
        </span>
        <span id="tk_133" class="t s1_133">
          биш/{" "}
        </span>
        <span id="tl_133" class="t s1_133">
          Аль нэгийг зөв бичсэн бол{" "}
        </span>
        <span id="tm_133" class="t s1_133">
          1{" "}
        </span>
        <span id="tn_133" class="t s1_133">
          2{" "}
        </span>
        <span id="to_133" class="t s1_133">
          Өмнө хойд зүгийн{" "}
        </span>
        <span id="tp_133" class="t s1_133">
          андуурсан{" "}
        </span>
        <span id="tq_133" class="t s1_133">
          5{" "}
        </span>
        <span id="tr_133" class="t s1_133">
          0{" "}
        </span>
        <span id="ts_133" class="t s1_133">
          3{" "}
        </span>
        <span id="tt_133" class="t s1_133">
          Зүг чигийг тэмдэглэх{" "}
        </span>
        <span id="tu_133" class="t s1_133">
          таних тэмдгийг ашиглаж{" "}
        </span>
        <span id="tv_133" class="t s1_133">
          сураагүй.{" "}
        </span>
        <span id="tw_133" class="t s1_133">
          бусад{" "}
        </span>
        <span id="tx_133" class="t s1_133">
          c{" "}
        </span>
        <span id="ty_133" class="t s1_133">
          1{" "}
        </span>
        <span id="tz_133" class="t s1_133">
          Сургуулийн хаалга голлоогүй учир зүүн талаар{" "}
        </span>
        <span id="t10_133" class="t s1_133">
          явна. Эсвэл Зүүн тал нь ойрхон харагдаж байгаа{" "}
        </span>
        <span id="t11_133" class="t s1_133">
          учир гэх мэт шалтгааныг зөв бичсэн бол{" "}
        </span>
        <span id="t12_133" class="t s1_133">
          2{" "}
        </span>
        <span id="t13_133" class="t s1_133">
          Тайлбар бичихгүйгээр{" "}
        </span>
        <span id="t14_133" class="t s1_133">
          зурсан байх{" "}
        </span>
        <span id="t15_133" class="t s1_133">
          магадлалтай{" "}
        </span>
        <span id="t16_133" class="t s1_133">
          2{" "}
        </span>
        <span id="t17_133" class="t s1_133">
          Учир шалтгаан{" "}
        </span>
        <span id="t18_133" class="t s1_133">
          тайлбарлаагүй зөвхөн{" "}
        </span>
        <span id="t19_133" class="t s1_133">
          зүг бичсэн болон дөт{" "}
        </span>
        <span id="t1a_133" class="t s1_133">
          харагдах хэсгийг олж{" "}
        </span>
        <span id="t1b_133" class="t s1_133">
          зурсан бол{" "}
        </span>
        <span id="t1c_133" class="t s1_133">
          Зүүн{" "}
        </span>
        <span id="t1d_133" class="t s1_133">
          1{" "}
        </span>
        <span id="t1e_133" class="t s1_133">
          Зурахдаа дөт харагдах{" "}
        </span>
        <span id="t1f_133" class="t s1_133">
          эсэхийг ажиглахгүйгээр{" "}
        </span>
        <span id="t1g_133" class="t s1_133">
          сургуулийн байшин{" "}
        </span>
        <span id="t1h_133" class="t s1_133">
          дээгүүр давуулж зурж{" "}
        </span>
        <span id="t1i_133" class="t s1_133">
          болохгүйг ойлгуулах{" "}
        </span>
        <span id="t1j_133" class="t s1_133">
          чухал{" "}
        </span>
        <span id="t1k_133" class="t s1_133">
          Зүг чигийн баримжаагүй.{" "}
        </span>
        <span id="t1l_133" class="t s1_133">
          баруун{" "}
        </span>
        <span id="t1m_133" class="t s1_133">
          0{" "}
        </span>
        <span id="t1n_133" class="t s1_133">
          3{" "}
        </span>
        <span id="t1o_133" class="t s1_133">
          Хаалга гэдэг тэмдгийг{" "}
        </span>
        <span id="t1p_133" class="t s1_133">
          мэдэхгүй байна.{" "}
        </span>
        <span id="t1q_133" class="t s1_133">
          Хойд, урд{" "}
        </span>
        <span id="t1r_133" class="t s1_133">
          4{" "}
        </span>
        <span id="t1s_133" class="t s1_133">
          Хариулаагүй. Тайлбар бичээгүй{" "}
        </span>
        <span id="t1t_133" class="t s3_133">
          Багш даалгаврын шинжилгээнээс “0” оноо буюу алдааны шалтгаанд илүү{" "}
        </span>
        <span id="t1u_133" class="t s3_133">
          төвлөрөн ажилласнаар сурагчийн алдаатай ойлголт, агуулгын хоцрогдол,{" "}
        </span>
        <span id="t1v_133" class="t s3_133">
          дэмжлэг шаардлагатай сул хөгжиж буй чадварыг илрүүлэх нь дараагийн{" "}
        </span>
        <span id="t1w_133" class="t s3_133">
          нэгж, ээлжит хичээлийн оношилгоо болж{" "}
        </span>
        <span id="t1x_133" class="t s3_133">
          ээлжит хичээлийн төлөвлөлтдөө{" "}
        </span>
        <span id="t1y_133" class="t s3_133">
          тусган сайжруулах боломж бүрддэг.{" "}
        </span>
        <span id="t1z_133" class="t s4_133">
          3.3{" "}
        </span>
        <span id="t20_133" class="t s4_133">
          БАГШИЙН ХӨГЖИЛ{" "}
        </span>
        <span id="t21_133" class="t s5_133">
          3.3.1 БАГШИЙН ХӨГЖЛИЙН ХЭРЭГЦЭЭГ ТОДОРХОЙЛОХ НЬ{" "}
        </span>
        <span id="t22_133" class="t s6_133">
          АСУУДАЛ{" "}
        </span>
        <span id="t23_133" class="t s3_133">
          Багш ажлын байранд хамтдаа сурч хөгжих хэрэгцээ хангагддаггүй{" "}
        </span>
        <span id="t24_133" class="t s7_133">
          •{" "}
        </span>
        <span id="t25_133" class="t s3_133">
          Сурагчийн үзэл хандлага, сонирхол хэрэгцээг тооцдоггүй{" "}
        </span>
        <span id="t26_133" class="t s7_133">
          •{" "}
        </span>
        <span id="t27_133" class="t s3_133">
          Сурагчийн ялгаатай байдалд нийцсэн арга зүй, төлөвлөлтгүй{" "}
        </span>
        <span id="t28_133" class="t s7_133">
          •{" "}
        </span>
        <span id="t29_133" class="t s3_133">
          Цахим ур чадвараа дээшлүүлэх боломж бага{" "}
        </span>
        <span id="t2a_133" class="t s7_133">
          •{" "}
        </span>
        <span id="t2b_133" class="t s3_133">
          Үр дүнтэй хамтран ажиллаж чаддаггүй, ачаалал их{" "}
        </span>
        <span id="t2c_133" class="t s7_133">
          •{" "}
        </span>
        <span id="t2d_133" class="t s3_133">
          Багш мэргэжлийн хөгжлийн хэрэгцээгээ баримт нотолгоонд{" "}
        </span>
        <span id="t2e_133" class="t s3_133">
          суурилж тодорхойлдоггүй{" "}
        </span>
      </div>
    </div>
  );
}
