import React from "react";
export default function HundredSixteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_116{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_116{left:87px;bottom:939px;letter-spacing:0.15px;word-spacing:0.04px;}
#t3_116{left:87px;bottom:894px;letter-spacing:-0.19px;word-spacing:2.88px;}
#t4_116{left:87px;bottom:874px;letter-spacing:-0.13px;word-spacing:11.08px;}
#t5_116{left:87px;bottom:854px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t6_116{left:87px;bottom:821px;letter-spacing:-0.13px;word-spacing:5.61px;}
#t7_116{left:87px;bottom:800px;letter-spacing:-0.18px;word-spacing:-1.65px;}
#t8_116{left:87px;bottom:780px;letter-spacing:-0.18px;word-spacing:4.97px;}
#t9_116{left:87px;bottom:760px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ta_116{left:142px;bottom:698px;letter-spacing:-0.34px;}
#tb_116{left:157px;bottom:660px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tc_116{left:157px;bottom:635px;letter-spacing:-0.13px;word-spacing:0.04px;}
#td_116{left:142px;bottom:567px;letter-spacing:0.1px;word-spacing:0.12px;}
#te_116{left:106px;bottom:522px;letter-spacing:0.12px;word-spacing:5.5px;}
#tf_116{left:106px;bottom:504px;letter-spacing:0.12px;word-spacing:1.34px;}
#tg_116{left:106px;bottom:485px;letter-spacing:0.11px;word-spacing:8.26px;}
#th_116{left:106px;bottom:467px;letter-spacing:0.11px;word-spacing:-0.74px;}
#ti_116{left:106px;bottom:449px;letter-spacing:0.11px;}
#tj_116{left:124px;bottom:426px;}
#tk_116{left:150px;bottom:426px;letter-spacing:0.12px;word-spacing:4.99px;}
#tl_116{left:150px;bottom:408px;letter-spacing:0.07px;word-spacing:2.43px;}
#tm_116{left:150px;bottom:389px;letter-spacing:0.12px;word-spacing:4.51px;}
#tn_116{left:150px;bottom:371px;letter-spacing:0.05px;word-spacing:4.06px;}
#to_116{left:150px;bottom:353px;letter-spacing:0.1px;word-spacing:-0.45px;}
#tp_116{left:150px;bottom:334px;letter-spacing:0.13px;word-spacing:0.01px;}
#tq_116{left:124px;bottom:312px;}
#tr_116{left:150px;bottom:312px;letter-spacing:0.11px;word-spacing:-0.03px;}
#ts_116{left:150px;bottom:293px;letter-spacing:0.07px;word-spacing:0.06px;}
#tt_116{left:124px;bottom:271px;}
#tu_116{left:150px;bottom:271px;letter-spacing:0.11px;word-spacing:4.34px;}
#tv_116{left:150px;bottom:253px;letter-spacing:0.12px;word-spacing:4.42px;}
#tw_116{left:150px;bottom:234px;letter-spacing:0.12px;word-spacing:0.02px;}
#tx_116{left:187px;bottom:205px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ty_116{left:429px;bottom:189px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_116{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_116{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_116{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_116{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_116{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_116{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s6_116{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts116" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg116" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="116/116.svg"
          type="image/svg+xml"
          id="pdf116"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_116" class="t s0_116">
          116{" "}
        </span>
        <span id="t2_116" class="t s1_116">
          Сургуульд суурилсан сургалтын хөтөлбөр{" "}
        </span>
        <span id="t3_116" class="t s2_116">
          Монгол улсын нутаг дэвсгэр дээр сургалт, үйл ажиллагаа явуулж байгаа{" "}
        </span>
        <span id="t4_116" class="t s2_116">
          төрийн болон хувийн хэвшлийн бүх сургууль үндэсний сургалтын{" "}
        </span>
        <span id="t5_116" class="t s2_116">
          хөтөлбөрийг хэрэгжүүлдэг.{" "}
        </span>
        <span id="t6_116" class="t s2_116">
          Үндэсний сургалтын хөтөлбөрт тусгасан суралцахуйн зорилтыг өөрийн{" "}
        </span>
        <span id="t7_116" class="t s2_116">
          аймаг орон нутаг, сургуулийн онцлог, ялгаатай соёлд нийцүүлэн
          нутагшуулах{" "}
        </span>
        <span id="t8_116" class="t s2_116">
          нь бусад сургуулиас ялгагдах онцлог болж байгаа тул СССХ-ийг далд{" "}
        </span>
        <span id="t9_116" class="t s2_116">
          сургалтын хөтөлбөр гэж нэрлэх болжээ.{" "}
        </span>
        <span id="ta_116" class="t s3_116">
          АСУУДАЛ{" "}
        </span>
        <span id="tb_116" class="t s2_116">
          Сургуулийн нэгдсэн сургалтын хөтөлбөргүй.{" "}
        </span>
        <span id="tc_116" class="t s2_116">
          Төлөвлөлтийн дагуу хичээл ордоггүй.{" "}
        </span>
        <span id="td_116" class="t s1_116">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="te_116" class="t s4_116">
          Тогтвортой хөгжлийн “Эрүүл мэндийг дэмжих” зорилгын хүрээнд “Эрүүл{" "}
        </span>
        <span id="tf_116" class="t s4_116">
          хүнс -Энх амьдрал” төслийг санаачлан хэрэгжүүлж, бага, суурь, бүрэн
          дунд{" "}
        </span>
        <span id="tg_116" class="t s4_116">
          боловсролын сургалтын хөтөлбөрт интеграцчилж, сургуульд суурилсан{" "}
        </span>
        <span id="th_116" class="t s4_116">
          сургалтын хөтөлбөр боловсруулж хэрэгжүүлснээр дараах үр дүнд хүрч
          байна.{" "}
        </span>
        <span id="ti_116" class="t s4_116">
          Үүнд:{" "}
        </span>
        <span id="tj_116" class="t s5_116">
          •{" "}
        </span>
        <span id="tk_116" class="t s4_116">
          Сурагчид, асран хамгаалагчид, харгалзан дэмжигчид олон нийтийг{" "}
        </span>
        <span id="tl_116" class="t s4_116">
          сургалтад хамруулснаар эрүүл зөв хооллолт, хүний биед хор хөнөөл{" "}
        </span>
        <span id="tm_116" class="t s4_116">
          үзүүлдэг хүнсний бүтээгдэхүүний талаарх мэдлэгтэй болж, хүнсний{" "}
        </span>
        <span id="tn_116" class="t s4_116">
          бүтээгдэхүүний илчлэг норм, шошго, хаяг, сав баглаа боодол, хоол{" "}
        </span>
        <span id="to_116" class="t s4_116">
          хүнс хадгалах сав, хүнсний ногооны эрүүлжүүлэх ач тусыг ойлгож, өдөр{" "}
        </span>
        <span id="tp_116" class="t s4_116">
          тутмын амьдралдаа хэрэгжүүлэх зөв дадалд суралцаж байна.{" "}
        </span>
        <span id="tq_116" class="t s5_116">
          •{" "}
        </span>
        <span id="tr_116" class="t s4_116">
          Сурагчид хоол хүнсийг зөв сонгон хэрэглэх дадал хэвшилтэй болсноор{" "}
        </span>
        <span id="ts_116" class="t s4_116">
          сургуулийн хог хаягдлын хэмжээ багассан.{" "}
        </span>
        <span id="tt_116" class="t s5_116">
          •{" "}
        </span>
        <span id="tu_116" class="t s4_116">
          Сургуульд суурилсан сургалтын хөтөлбөрийн дагуу хичээл, сургалт{" "}
        </span>
        <span id="tv_116" class="t s4_116">
          явуулснаар багш, сурагчдын 60.0-аас дээш хувь нь өөрийн гараар{" "}
        </span>
        <span id="tw_116" class="t s4_116">
          бэлтгэсэн үдийн хоолыг идэж заншсан.{" "}
        </span>
        <span id="tx_116" class="t s6_116">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="ty_116" class="t s6_116">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
