import React from "react";
export default function F138() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_138{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_138{left:224px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t3_138{left:87px;bottom:523px;letter-spacing:-0.14px;word-spacing:-1.2px;}
#t4_138{left:87px;bottom:503px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t5_138{left:190px;bottom:459px;letter-spacing:0.16px;word-spacing:0.04px;}
#t6_138{left:275px;bottom:433px;letter-spacing:0.17px;word-spacing:0.06px;}
#t7_138{left:142px;bottom:381px;}
#t8_138{left:139px;bottom:351px;}
#t9_138{left:166px;bottom:351px;letter-spacing:-0.16px;}
#ta_138{left:275px;bottom:351px;letter-spacing:-0.16px;}
#tb_138{left:359px;bottom:351px;letter-spacing:-0.16px;}
#tc_138{left:444px;bottom:351px;letter-spacing:-0.18px;}
#td_138{left:508px;bottom:351px;letter-spacing:-0.13px;}
#te_138{left:620px;bottom:351px;letter-spacing:-0.2px;}
#tf_138{left:166px;bottom:332px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_138{left:139px;bottom:308px;}
#th_138{left:166px;bottom:308px;letter-spacing:-0.16px;}
#ti_138{left:276px;bottom:308px;letter-spacing:-0.08px;}
#tj_138{left:324px;bottom:308px;letter-spacing:-0.14px;}
#tk_138{left:449px;bottom:308px;letter-spacing:-0.13px;}
#tl_138{left:516px;bottom:308px;letter-spacing:-0.16px;}
#tm_138{left:603px;bottom:308px;letter-spacing:-0.09px;}
#tn_138{left:166px;bottom:288px;letter-spacing:-0.16px;word-spacing:7.13px;}
#to_138{left:166px;bottom:268px;letter-spacing:-0.15px;word-spacing:2.28px;}
#tp_138{left:166px;bottom:247px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tq_138{left:142px;bottom:199px;letter-spacing:0.15px;}
#tr_138{left:87px;bottom:166px;letter-spacing:-0.15px;word-spacing:1.46px;}
#ts_138{left:87px;bottom:146px;letter-spacing:-0.16px;word-spacing:-1.86px;}
#tt_138{left:87px;bottom:126px;letter-spacing:-0.17px;word-spacing:5.44px;}

.s0_138{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_138{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_138{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s3_138{font-size:18px;font-family:MogulArial-Bold_179;color:#F90;}
.s4_138{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_138{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s6_138{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts138" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg138" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="138/138.svg"
          type="image/svg+xml"
          id="pdf138"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_138" class="t s0_138">
          138{" "}
        </span>
        <span id="t2_138" class="t s1_138">
          Зураг 3.20. Багшийн өөрийн үнэлгээний зураглал{" "}
        </span>
        <span id="t3_138" class="t s2_138">
          Багш эдгээрийг ганцаарчлан гүйцэтгэж, хариултын хуудасны дагуу
          өөрийгөө{" "}
        </span>
        <span id="t4_138" class="t s2_138">
          үнэлж, хөгжлийн хэрэгцээгээ үндэслэлтэйгээр тодорхойлно.{" "}
        </span>
        <span id="t5_138" class="t s3_138">
          3.3.2 БАГШИЙН ХӨГЖЛИЙН ХЭРЭГЦЭЭНД{" "}
        </span>
        <span id="t6_138" class="t s3_138">
          ҮНДЭСЛЭН ТӨЛӨВЛӨХ{" "}
        </span>
        <span id="t7_138" class="t s4_138">
          АСУУДАЛ{" "}
        </span>
        <span id="t8_138" class="t s5_138">
          •{" "}
        </span>
        <span id="t9_138" class="t s2_138">
          Сургуулийн{" "}
        </span>
        <span id="ta_138" class="t s2_138">
          зорилго,{" "}
        </span>
        <span id="tb_138" class="t s2_138">
          багшийн{" "}
        </span>
        <span id="tc_138" class="t s2_138">
          бодит{" "}
        </span>
        <span id="td_138" class="t s2_138">
          хэрэгцээтэй{" "}
        </span>
        <span id="te_138" class="t s2_138">
          холбоо{" "}
        </span>
        <span id="tf_138" class="t s2_138">
          хамааралгүй олон ажлыг нэг нэг өдрөөр төлөвлөх нь түгээмэл{" "}
        </span>
        <span id="tg_138" class="t s6_138">
          •{" "}
        </span>
        <span id="th_138" class="t s6_138">
          Сургуулийн{" "}
        </span>
        <span id="ti_138" class="t s6_138">
          үйл{" "}
        </span>
        <span id="tj_138" class="t s6_138">
          ажиллагааны{" "}
        </span>
        <span id="tk_138" class="t s6_138">
          болон{" "}
        </span>
        <span id="tl_138" class="t s6_138">
          багшийн{" "}
        </span>
        <span id="tm_138" class="t s6_138">
          хөгжлийн{" "}
        </span>
        <span id="tn_138" class="t s6_138">
          төлөвлөгөөнд сурагчдад тулгамдаж буй асуудал, сургуулийн{" "}
        </span>
        <span id="to_138" class="t s6_138">
          зорилго болон багшийн бодит хэрэгцээтэй холбоо хамааралгүй{" "}
        </span>
        <span id="tp_138" class="t s6_138">
          олон тооны ажлыг нэг нэг өдрөөр төлөвлөх нь түгээмэл байна.{" "}
        </span>
        <span id="tq_138" class="t s4_138">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tr_138" class="t s6_138">
          Сургуулийн тухайн жилийн зорилго буюу сурагчдад юу тулгамдаж байгаа,{" "}
        </span>
        <span id="ts_138" class="t s6_138">
          тэд юуг, хэрхэн сурахыг хүсч буйд нь нөлөөлдөг багшийн үүргийг
          тодорхойлж,{" "}
        </span>
        <span id="tt_138" class="t s6_138">
          сурагчдыг бүтээлчээр сэтгэдэг, хамтдаа суралцах чадварыг хөгжүүлдэг{" "}
        </span>
      </div>
    </div>
  );
}
