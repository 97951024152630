import React from "react";
export default function OneHundredSixtyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_162{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_162{left:147px;bottom:938px;letter-spacing:-0.25px;word-spacing:0.11px;}
#t3_162{left:106px;bottom:887px;letter-spacing:0.07px;word-spacing:6.94px;}
#t4_162{left:106px;bottom:868px;letter-spacing:0.1px;word-spacing:0.61px;}
#t5_162{left:106px;bottom:850px;letter-spacing:0.11px;word-spacing:3.27px;}
#t6_162{left:106px;bottom:832px;letter-spacing:0.1px;word-spacing:4.38px;}
#t7_162{left:106px;bottom:813px;letter-spacing:0.11px;word-spacing:0.03px;}
#t8_162{left:123px;bottom:784px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t9_162{left:429px;bottom:768px;letter-spacing:-0.16px;word-spacing:0.03px;}
#ta_162{left:106px;bottom:691px;letter-spacing:0.1px;word-spacing:2.64px;}
#tb_162{left:106px;bottom:673px;letter-spacing:0.12px;word-spacing:-1.04px;}
#tc_162{left:106px;bottom:655px;letter-spacing:0.07px;word-spacing:0.07px;}
#td_162{left:106px;bottom:628px;letter-spacing:0.13px;word-spacing:4.06px;}
#te_162{left:106px;bottom:609px;letter-spacing:0.13px;word-spacing:-0.44px;}
#tf_162{left:106px;bottom:591px;letter-spacing:0.12px;word-spacing:1.59px;}
#tg_162{left:106px;bottom:573px;letter-spacing:0.11px;word-spacing:-0.65px;}
#th_162{left:106px;bottom:554px;letter-spacing:0.09px;word-spacing:0.06px;}
#ti_162{left:106px;bottom:527px;letter-spacing:0.11px;word-spacing:4.91px;}
#tj_162{left:106px;bottom:509px;letter-spacing:0.1px;word-spacing:0.04px;}
#tk_162{left:123px;bottom:484px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tl_162{left:429px;bottom:468px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tm_162{left:87px;bottom:409px;letter-spacing:-0.17px;word-spacing:5.19px;}
#tn_162{left:87px;bottom:389px;letter-spacing:-0.16px;word-spacing:4.93px;}
#to_162{left:87px;bottom:369px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tp_162{left:142px;bottom:312px;letter-spacing:-0.22px;}
#tq_162{left:87px;bottom:273px;letter-spacing:-0.14px;word-spacing:2.55px;}
#tr_162{left:87px;bottom:253px;letter-spacing:-0.13px;word-spacing:1.03px;}
#ts_162{left:87px;bottom:233px;letter-spacing:-0.18px;word-spacing:-1.18px;}
#tt_162{left:87px;bottom:213px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tu_162{left:87px;bottom:173px;letter-spacing:-0.16px;word-spacing:2.43px;}
#tv_162{left:87px;bottom:152px;letter-spacing:-0.15px;word-spacing:1.62px;}
#tw_162{left:87px;bottom:132px;letter-spacing:-0.16px;word-spacing:4.13px;}
#tx_162{left:87px;bottom:112px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_162{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_162{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_162{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_162{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_162{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_162{font-size:17px;font-family:Arial-BoldMT_1li;color:#D1622B;}`}
      </style>

      <style id="fonts162" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg162" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="162/162.svg"
          type="image/svg+xml"
          id="pdf162"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_162" class="t s0_162">
          162{" "}
        </span>
        <span id="t2_162" class="t s1_162">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_162" class="t s2_162">
          Сургуулийн сургалтын орчныг сайжруулах чиглэлээр БШУЯ, БЕГ, орон{" "}
        </span>
        <span id="t4_162" class="t s2_162">
          нутгийн төрийн болон төрийн бус байгууллага, аж ахуйн нэгж, эцэг эх,
          асран{" "}
        </span>
        <span id="t5_162" class="t s2_162">
          хамгаалагчийн дэмжлэг оролцоотойгоор 350 орчим сая төгрөгийн хөрөнгө{" "}
        </span>
        <span id="t6_162" class="t s2_162">
          оруулалтыг авч багш, сурагчдын ая тухай орчин нөхцөлд ажиллах, сурч{" "}
        </span>
        <span id="t7_162" class="t s2_162">
          боловсрох орчин нөхцөлийг бүрдүүлсэн.{" "}
        </span>
        <span id="t8_162" class="t s3_162">
          ………..аймгийн …….. сумын….. ерөнхий боловсролын сургуулийн менежментийг{" "}
        </span>
        <span id="t9_162" class="t s3_162">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ta_162" class="t s2_162">
          Дэлхийн Зөн олон улсын байгууллагаар 20,000,000 төгрөгийн “Стандартад{" "}
        </span>
        <span id="tb_162" class="t s2_162">
          нийцсэн сэтгэл зүйчийн өрөө тохижуулах” төслийг дэмжүүлэн засварын
          ажлыг{" "}
        </span>
        <span id="tc_162" class="t s2_162">
          эхлүүлээд 90.0 хувьтай ажиллаж байна.{" "}
        </span>
        <span id="td_162" class="t s2_162">
          Зөгийхөн төслийн хүрээнд Гүүд Нэйборс Монгол ОУ-ын ТББ-ын Мангирт{" "}
        </span>
        <span id="te_162" class="t s2_162">
          ОНХХ-ийн дэргэдэх Хүүхдийн эрхийн клубын гишүүдийн санаачлагаар манай{" "}
        </span>
        <span id="tf_162" class="t s2_162">
          сургуулийн дунд, ахлах ангийн сурагчдын хэрэгцээнд зориулсан комьпютер{" "}
        </span>
        <span id="tg_162" class="t s2_162">
          болон принтерийг сургуулийн номын санд байршуулж сурагчид үнэ
          төлбөргүй{" "}
        </span>
        <span id="th_162" class="t s2_162">
          ашиглаж байна.{" "}
        </span>
        <span id="ti_162" class="t s2_162">
          “Гоё сургууль” төслийг аймгийн орон нутгийг хөгжүүлэх сангийн хөрөнгө{" "}
        </span>
        <span id="tj_162" class="t s2_162">
          оруулалтаар хэрэгжүүлж, 95.0 хувийн гүйцэтгэлтэй явж байна.{" "}
        </span>
        <span id="tk_162" class="t s3_162">
          ………..аймгийн …….. сумын….. ерөнхий боловсролын сургуулийн менежментийг{" "}
        </span>
        <span id="tl_162" class="t s3_162">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tm_162" class="t s4_162">
          Сургалтын орчны материаллаг нөөц, хүртээмжтэй холбоотой асуудлын{" "}
        </span>
        <span id="tn_162" class="t s4_162">
          ихэнх нь санхүүжилт буюу нөөцийн хуваарилалт, хамтын ажиллагааны{" "}
        </span>
        <span id="to_162" class="t s4_162">
          хүрээнд шийдвэрлэгдсэн байна.{" "}
        </span>
        <span id="tp_162" class="t s5_162">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tq_162" class="t s4_162">
          Бидний 1-р бүлэгт санал болгож буй сургуулийн менежментийн пирамид{" "}
        </span>
        <span id="tr_162" class="t s4_162">
          загварыг ашиглан богино, дунд, урт хугацаанд бүхлээр нь харж сургалтын{" "}
        </span>
        <span id="ts_162" class="t s4_162">
          орчинд шаардагдах материаллаг нөөцийг тодорхойлох, ингэхдээ орон
          нутаг,{" "}
        </span>
        <span id="tt_162" class="t s4_162">
          талуудын оролцоог хангахад анхаарна.{" "}
        </span>
        <span id="tu_162" class="t s4_162">
          Сургалтын орчинд зайлшгүй шаардлагатай материаллаг нөөц, сургалтын{" "}
        </span>
        <span id="tv_162" class="t s4_162">
          хэрэглэгдэхүүн, тоног төхөөрөмж нь тухайн сургуульд хүрэлцээтэй байгаа{" "}
        </span>
        <span id="tw_162" class="t s4_162">
          эсэхэд хяналт тавих зорилгоор сургуулийн гадаад, дотоод материаллаг{" "}
        </span>
        <span id="tx_162" class="t s4_162">
          орчныг үнэлэх өөрийн үнэлгээний хуудсыг боловсруулна.{" "}
        </span>
      </div>
    </div>
  );
}
