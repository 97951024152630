import React from "react";
export default function F158() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_158{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_158{left:147px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_158{left:106px;bottom:881px;letter-spacing:0.11px;word-spacing:-0.6px;}
#t4_158{left:106px;bottom:863px;letter-spacing:0.1px;word-spacing:0.04px;}
#t5_158{left:106px;bottom:835px;letter-spacing:0.06px;word-spacing:-1.64px;}
#t6_158{left:106px;bottom:817px;letter-spacing:0.12px;word-spacing:0.02px;}
#t7_158{left:106px;bottom:789px;letter-spacing:0.09px;word-spacing:0.51px;}
#t8_158{left:106px;bottom:771px;letter-spacing:0.09px;word-spacing:0.04px;}
#t9_158{left:106px;bottom:743px;letter-spacing:0.11px;word-spacing:3.94px;}
#ta_158{left:106px;bottom:725px;letter-spacing:0.13px;word-spacing:0.01px;}
#tb_158{left:106px;bottom:698px;letter-spacing:0.12px;word-spacing:0.03px;}
#tc_158{left:227px;bottom:669px;letter-spacing:-0.16px;word-spacing:0.03px;}
#td_158{left:326px;bottom:652px;letter-spacing:-0.16px;word-spacing:0.02px;}
#te_158{left:142px;bottom:592px;letter-spacing:0.18px;}
#tf_158{left:87px;bottom:556px;letter-spacing:-0.17px;}
#tg_158{left:188px;bottom:556px;letter-spacing:-0.1px;}
#th_158{left:306px;bottom:556px;letter-spacing:-0.09px;}
#ti_158{left:428px;bottom:556px;letter-spacing:-0.07px;}
#tj_158{left:463px;bottom:556px;letter-spacing:-0.09px;}
#tk_158{left:533px;bottom:556px;letter-spacing:-0.16px;}
#tl_158{left:604px;bottom:556px;letter-spacing:-0.2px;}
#tm_158{left:87px;bottom:535px;letter-spacing:-0.17px;word-spacing:7.13px;}
#tn_158{left:87px;bottom:515px;letter-spacing:-0.15px;word-spacing:-1.6px;}
#to_158{left:87px;bottom:495px;letter-spacing:-0.14px;word-spacing:1.56px;}
#tp_158{left:87px;bottom:475px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tq_158{left:87px;bottom:446px;letter-spacing:-0.19px;word-spacing:7.59px;}
#tr_158{left:87px;bottom:426px;letter-spacing:-0.14px;word-spacing:5.56px;}
#ts_158{left:87px;bottom:406px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tt_158{left:420px;bottom:391px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tu_158{left:87px;bottom:369px;letter-spacing:-0.11px;}
#tv_158{left:247px;bottom:369px;letter-spacing:-0.08px;}
#tw_158{left:337px;bottom:369px;letter-spacing:-0.13px;}
#tx_158{left:87px;bottom:349px;letter-spacing:-0.11px;word-spacing:2.48px;}
#ty_158{left:87px;bottom:329px;letter-spacing:-0.16px;word-spacing:2.03px;}
#tz_158{left:87px;bottom:309px;letter-spacing:-0.36px;word-spacing:8.77px;}
#t10_158{left:87px;bottom:288px;letter-spacing:-0.65px;word-spacing:6.97px;}
#t11_158{left:87px;bottom:268px;letter-spacing:-0.63px;word-spacing:5.77px;}
#t12_158{left:87px;bottom:248px;letter-spacing:-0.61px;word-spacing:0.02px;}
#t13_158{left:121px;bottom:224px;letter-spacing:-0.89px;word-spacing:4.19px;}
#t14_158{left:121px;bottom:199px;}
#t15_158{left:139px;bottom:199px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t16_158{left:121px;bottom:175px;}
#t17_158{left:139px;bottom:175px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t18_158{left:139px;bottom:154px;letter-spacing:-0.21px;}
#t19_158{left:121px;bottom:130px;}
#t1a_158{left:139px;bottom:130px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t1b_158{left:139px;bottom:110px;letter-spacing:-0.19px;}

.s0_158{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_158{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_158{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_158{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_158{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_158{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s6_158{font-size:17px;font-family:ArialMT_1lq;color:#000;}`}
      </style>

      <style id="fonts158" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg158" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="158/158.svg"
          type="image/svg+xml"
          id="pdf158"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_158" class="t s0_158">
          158{" "}
        </span>
        <span id="t2_158" class="t s1_158">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_158" class="t s2_158">
          Орчны үнэлгээг хийж, эрсдэлтэй нөхцөл байдлыг илрүүлж, сургуулийн
          гадаад{" "}
        </span>
        <span id="t4_158" class="t s2_158">
          болон дотоод орчинд эрсдэлтэй зүйлсийг засан сайжруулсан.{" "}
        </span>
        <span id="t5_158" class="t s2_158">
          Орон нутаг болон мэргэжлийн байгууллагуудтай хамтран зөөлөвч,
          хамгаалалт,{" "}
        </span>
        <span id="t6_158" class="t s2_158">
          аюулгүйн гарцын тэмдэг тэмдэглэгээ гэрэлтүүлгийг хийсэн{" "}
        </span>
        <span id="t7_158" class="t s2_158">
          Сургууль өөрийн дотоод нөөц бололцоог ашиглан багш, ажилчид, сурагчдын{" "}
        </span>
        <span id="t8_158" class="t s2_158">
          санаачилгаар мод, бут тарих Эко клуб байгуулсан.{" "}
        </span>
        <span id="t9_158" class="t s2_158">
          Ногоон байгууламж, камержуулалтыг шийдэхэд сургуулийн төсвөөс гадна{" "}
        </span>
        <span id="ta_158" class="t s2_158">
          ТББ, төсөл, хөтөлбөрүүдэд төсөл бичиж санхүүжилт авсан.{" "}
        </span>
        <span id="tb_158" class="t s2_158">
          Багш, сурагчдын хүрээнд зорилтот аяныг өрнүүлэн асуудлыг шийдвэрлэсэн.{" "}
        </span>
        <span id="tc_158" class="t s3_158">
          ………..аймгийн …….. сумын….. ерөнхий боловсролын сургуулийн{" "}
        </span>
        <span id="td_158" class="t s3_158">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="te_158" class="t s1_158">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tf_158" class="t s4_158">
          Сургалтын{" "}
        </span>
        <span id="tg_158" class="t s4_158">
          хөтөлбөрийн{" "}
        </span>
        <span id="th_158" class="t s4_158">
          хэрэгжилтийг{" "}
        </span>
        <span id="ti_158" class="t s4_158">
          үр{" "}
        </span>
        <span id="tj_158" class="t s4_158">
          дүнтэй{" "}
        </span>
        <span id="tk_158" class="t s4_158">
          зохион{" "}
        </span>
        <span id="tl_158" class="t s4_158">
          байгуулж{" "}
        </span>
        <span id="tm_158" class="t s4_158">
          боловсролын зорилгод хүрэхэд сургуулийн орчин чухал гэдгийг өмнө{" "}
        </span>
        <span id="tn_158" class="t s4_158">
          дурдсан. Сүүлийн үеийн боловсролын орчинд гарч буй өөрчлөлтөөс харахад{" "}
        </span>
        <span id="to_158" class="t s4_158">
          сургуулийн орчин нь эрүүл, аюулгүй байхаас гадна хэрэглэгчийн ая тухыг{" "}
        </span>
        <span id="tp_158" class="t s4_158">
          хангахыг шаардаж байна.{" "}
        </span>
        <span id="tq_158" class="t s4_158">
          Сургуулийн эрүүл, аюулгүй орчныг бүрдүүлэхэд сургууль, олон нийт,{" "}
        </span>
        <span id="tr_158" class="t s4_158">
          мэргэжлийн байгууллагын хамтын ажиллагаа, оролцоо чухал үүрэгтэй.{" "}
        </span>
        <span id="ts_158" class="t s4_158">
          Тиймээс үүнд дараах стратеги чухал. Үүнд:{" "}
        </span>
        <span id="tt_158" class="t s5_158">
          Зураг 3.28. Орчны менежментийн бүтэц{" "}
        </span>
        <span id="tu_158" class="t s4_158">
          Менежментийн{" "}
        </span>
        <span id="tv_158" class="t s4_158">
          бүтэц:{" "}
        </span>
        <span id="tw_158" class="t s4_158">
          ХАБЭА-н{" "}
        </span>
        <span id="tx_158" class="t s4_158">
          ажилтан эрсдэлийн үнэлгээ хийнэ гэсэн{" "}
        </span>
        <span id="ty_158" class="t s4_158">
          нийтлэг буруу ойлголт байдаг ч үнэндээ{" "}
        </span>
        <span id="tz_158" class="t s4_158">
          эрсдэлийн үнэлгээг ХАБЭА-н ажилтан,{" "}
        </span>
        <span id="t10_158" class="t s4_158">
          шугаман удирдлага, мэргэшсэн ажилтны{" "}
        </span>
        <span id="t11_158" class="t s4_158">
          төлөөллөөс бүрдсэн баг хийх урьдчилан{" "}
        </span>
        <span id="t12_158" class="t s4_158">
          сэргийлэх үйл ажиллагаа:{" "}
        </span>
        <span id="t13_158" class="t s4_158">
          • юуг, яаж{" "}
        </span>
        <span id="t14_158" class="t s6_158">
          •{" "}
        </span>
        <span id="t15_158" class="t s4_158">
          эрсдэлийн үнэлгээ хийх{" "}
        </span>
        <span id="t16_158" class="t s6_158">
          •{" "}
        </span>
        <span id="t17_158" class="t s4_158">
          хэрэглэгчийн сэтгэл ханамжийг{" "}
        </span>
        <span id="t18_158" class="t s4_158">
          судлах{" "}
        </span>
        <span id="t19_158" class="t s6_158">
          •{" "}
        </span>
        <span id="t1a_158" class="t s4_158">
          хэрэглэгчийн хэрэгцээг{" "}
        </span>
        <span id="t1b_158" class="t s4_158">
          тодорхойлох{" "}
        </span>
      </div>
    </div>
  );
}
