import React from "react";
export default function SeventyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_75{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_75{left:113px;bottom:941px;letter-spacing:-0.15px;word-spacing:4.61px;}
#t3_75{left:113px;bottom:921px;letter-spacing:-0.22px;}
#t4_75{left:165px;bottom:921px;}
#t5_75{left:87px;bottom:896px;}
#t6_75{left:113px;bottom:896px;letter-spacing:-0.16px;word-spacing:-0.06px;}
#t7_75{left:113px;bottom:876px;letter-spacing:-0.14px;word-spacing:2.24px;}
#t8_75{left:113px;bottom:856px;letter-spacing:-0.14px;word-spacing:2.87px;}
#t9_75{left:113px;bottom:836px;letter-spacing:-0.11px;}
#ta_75{left:87px;bottom:811px;}
#tb_75{left:113px;bottom:811px;letter-spacing:-0.13px;word-spacing:0.42px;}
#tc_75{left:113px;bottom:791px;letter-spacing:-0.16px;word-spacing:8.82px;}
#td_75{left:113px;bottom:771px;letter-spacing:-0.15px;word-spacing:2.34px;}
#te_75{left:207px;bottom:771px;letter-spacing:-0.16px;word-spacing:2.35px;}
#tf_75{left:113px;bottom:751px;letter-spacing:-0.17px;word-spacing:7.97px;}
#tg_75{left:113px;bottom:731px;letter-spacing:-0.15px;word-spacing:0.05px;}
#th_75{left:429px;bottom:731px;}
#ti_75{left:87px;bottom:706px;}
#tj_75{left:113px;bottom:706px;letter-spacing:-0.12px;word-spacing:4.04px;}
#tk_75{left:113px;bottom:686px;letter-spacing:-0.14px;}
#tl_75{left:221px;bottom:686px;letter-spacing:-0.12px;}
#tm_75{left:312px;bottom:686px;letter-spacing:-0.14px;}
#tn_75{left:411px;bottom:686px;letter-spacing:-0.13px;}
#to_75{left:475px;bottom:686px;letter-spacing:-0.14px;}
#tp_75{left:571px;bottom:686px;letter-spacing:-0.21px;}
#tq_75{left:648px;bottom:686px;letter-spacing:-0.08px;}
#tr_75{left:113px;bottom:666px;letter-spacing:-0.15px;word-spacing:0.72px;}
#ts_75{left:113px;bottom:646px;letter-spacing:-0.15px;word-spacing:0.2px;}
#tt_75{left:113px;bottom:625px;letter-spacing:-0.12px;}
#tu_75{left:137px;bottom:558px;letter-spacing:-0.03px;}
#tv_75{left:162px;bottom:530px;letter-spacing:-0.2px;word-spacing:8.8px;}
#tw_75{left:162px;bottom:510px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tx_75{left:139px;bottom:481px;}
#ty_75{left:165px;bottom:481px;letter-spacing:-0.14px;word-spacing:0.82px;}
#tz_75{left:165px;bottom:461px;letter-spacing:-0.13px;word-spacing:0.29px;}
#t10_75{left:570px;bottom:461px;letter-spacing:-0.17px;word-spacing:0.33px;}
#t11_75{left:165px;bottom:440px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t12_75{left:548px;bottom:440px;}
#t13_75{left:139px;bottom:416px;}
#t14_75{left:165px;bottom:416px;letter-spacing:-0.1px;}
#t15_75{left:139px;bottom:391px;}
#t16_75{left:165px;bottom:391px;letter-spacing:-0.14px;word-spacing:8.29px;}
#t17_75{left:165px;bottom:371px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t18_75{left:139px;bottom:347px;}
#t19_75{left:165px;bottom:347px;letter-spacing:-0.12px;word-spacing:6.07px;}
#t1a_75{left:165px;bottom:327px;letter-spacing:-0.15px;}
#t1b_75{left:139px;bottom:302px;}
#t1c_75{left:165px;bottom:302px;letter-spacing:-0.14px;word-spacing:6.64px;}
#t1d_75{left:165px;bottom:282px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1e_75{left:139px;bottom:257px;}
#t1f_75{left:165px;bottom:257px;letter-spacing:-0.13px;word-spacing:6.33px;}
#t1g_75{left:165px;bottom:237px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1h_75{left:139px;bottom:213px;}
#t1i_75{left:165px;bottom:213px;letter-spacing:-0.11px;word-spacing:1.84px;}
#t1j_75{left:165px;bottom:193px;letter-spacing:-0.12px;word-spacing:0.02px;}

.s0_75{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_75{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s2_75{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_75{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_75{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_75{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts75" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg75" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="75/75.svg"
          type="image/svg+xml"
          id="pdf75"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_75" class="t s0_75">
          75{" "}
        </span>
        <span id="t2_75" class="t s1_75">
          нөхцөлийн гол шинж, уялдаа холбоог нь ойлгож дүгнэлт гаргахад{" "}
        </span>
        <span id="t3_75" class="t s1_75">
          чиглэх
        </span>
        <span id="t4_75" class="t s2_75">
          ){" "}
        </span>
        <span id="t5_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t6_75" class="t s2_75">
          Хичээл сургалтын үйл ажиллагаагаар сурагчдын анхаарлын төвлөрлийг{" "}
        </span>
        <span id="t7_75" class="t s2_75">
          нэмэгдүүлэхэд багш нарт арга зүйн дэмжлэгийг үзүүлэх, энэ тал дээр{" "}
        </span>
        <span id="t8_75" class="t s2_75">
          мэргэжлийн байгууллага, мэргэжилтнүүдийн зөвлөгөө дэмжлэгийг авч{" "}
        </span>
        <span id="t9_75" class="t s2_75">
          ажиллах{" "}
        </span>
        <span id="ta_75" class="t s3_75">
          •{" "}
        </span>
        <span id="tb_75" class="t s2_75">
          Сурагчдаар гүйцэтгүүлэх гэрийн даалгаврын зохистой хэмжээ, агуулгыг{" "}
        </span>
        <span id="tc_75" class="t s2_75">
          багш нар хоорондоо хамтран үр дүнтэй болгоход менежментийг{" "}
        </span>
        <span id="td_75" class="t s2_75">
          чиглүүлэх, (
        </span>
        <span id="te_75" class="t s1_75">
          гэрийн даалгавар хэт их байх нь анхаарлын төвлөрлийг{" "}
        </span>
        <span id="tf_75" class="t s1_75">
          бууруулан залхаах, суралцахуйн амжилтыг бууруулах,сурах хүсэл{" "}
        </span>
        <span id="tg_75" class="t s1_75">
          эрмэлзэлд сөргөөр нөлөөлөх эрсдэлтэй
        </span>
        <span id="th_75" class="t s2_75">
          ){" "}
        </span>
        <span id="ti_75" class="t s3_75">
          •{" "}
        </span>
        <span id="tj_75" class="t s2_75">
          Хүүхэд логик дэс дараалалтайгаар санаа бодлоо бүтэн өгүүлбэрээр{" "}
        </span>
        <span id="tk_75" class="t s2_75">
          илэрхийлэх{" "}
        </span>
        <span id="tl_75" class="t s2_75">
          чадварыг{" "}
        </span>
        <span id="tm_75" class="t s2_75">
          хичээлийн{" "}
        </span>
        <span id="tn_75" class="t s2_75">
          болон{" "}
        </span>
        <span id="to_75" class="t s2_75">
          хичээлээс{" "}
        </span>
        <span id="tp_75" class="t s2_75">
          гадуурх{" "}
        </span>
        <span id="tq_75" class="t s2_75">
          үйл{" "}
        </span>
        <span id="tr_75" class="t s2_75">
          ажиллагаагаар дэмжих олон талт арга хэмжээг зохион байгуулдаг байх{" "}
        </span>
        <span id="ts_75" class="t s1_75">
          (сэтгэхүй, анхаарал, ой тогтоолт, хэл ярианы чадварыг дэмжихэд үр{" "}
        </span>
        <span id="tt_75" class="t s1_75">
          дүнтэй){" "}
        </span>
        <span id="tu_75" class="t s4_75">
          АСУУДАЛ{" "}
        </span>
        <span id="tv_75" class="t s5_75">
          Хүүхэд сурах хүсэл эрмэлзэл, сонирхолгүй, зорилгогүй,{" "}
        </span>
        <span id="tw_75" class="t s5_75">
          цаашилбал сургуулиас зайлсхийх, бие даан сурах чадваргүй{" "}
        </span>
        <span id="tx_75" class="t s3_75">
          •{" "}
        </span>
        <span id="ty_75" class="t s2_75">
          Хүүхдийн өөртөө итгэх итгэл, бие даан сурах арга барил, багаар{" "}
        </span>
        <span id="tz_75" class="t s2_75">
          хамтран ажиллах өөрийгөө илэрхийлэх чадвар сул{" "}
        </span>
        <span id="t10_75" class="t s1_75">
          (мета танин{" "}
        </span>
        <span id="t11_75" class="t s1_75">
          мэдэхүйн чадвар, сурлагын амжилт хангалтгүй
        </span>
        <span id="t12_75" class="t s2_75">
          ){" "}
        </span>
        <span id="t13_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t14_75" class="t s2_75">
          Бие биеэ хүндэтгэж харилцах хэвшил, соёл дутмаг{" "}
        </span>
        <span id="t15_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t16_75" class="t s2_75">
          Харилцааны чадваргүй, бусдад өөрийгөө ойлгуулах, бусдыг{" "}
        </span>
        <span id="t17_75" class="t s2_75">
          ойлгох, сэтгэлийн хөдөлгөөнөө хянах удирдах чадваргүй{" "}
        </span>
        <span id="t18_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t19_75" class="t s2_75">
          Багшийн хүүхдэд үлгэр дуурайл үзүүлэх ёс зүйн манлайлал{" "}
        </span>
        <span id="t1a_75" class="t s2_75">
          хангалтгүй{" "}
        </span>
        <span id="t1b_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t1c_75" class="t s2_75">
          Анги удирдсан багш хүүхдийн хөгжил төлөвшлийг дэмжихэд{" "}
        </span>
        <span id="t1d_75" class="t s2_75">
          зарцуулах цаг хомс, анги өрөө тасалгаа дутмаг{" "}
        </span>
        <span id="t1e_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t1f_75" class="t s2_75">
          Сурагчаа, ангиа таних, хөгжүүлэх сурган хүмүүжүүлэх нөлөө{" "}
        </span>
        <span id="t1g_75" class="t s2_75">
          үзүүлэх нь сул{" "}
        </span>
        <span id="t1h_75" class="t s3_75">
          •{" "}
        </span>
        <span id="t1i_75" class="t s2_75">
          Шинээр элссэн, шилжиж ирсэн, бага ангиас дунд ангид дэвшин{" "}
        </span>
        <span id="t1j_75" class="t s2_75">
          суралцаж буй сурагчдад үзүүлэх дэмжлэг дутмаг{" "}
        </span>
      </div>
    </div>
  );
}
