import React from "react";
export default function SeventyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_74{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_74{left:137px;bottom:928px;letter-spacing:-0.01px;}
#t3_74{left:139px;bottom:900px;}
#t4_74{left:154px;bottom:900px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t5_74{left:139px;bottom:867px;}
#t6_74{left:165px;bottom:867px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t7_74{left:139px;bottom:843px;}
#t8_74{left:165px;bottom:843px;letter-spacing:-0.13px;word-spacing:7.6px;}
#t9_74{left:165px;bottom:823px;letter-spacing:-0.13px;word-spacing:4.95px;}
#ta_74{left:165px;bottom:802px;letter-spacing:-0.12px;}
#tb_74{left:139px;bottom:778px;}
#tc_74{left:165px;bottom:778px;letter-spacing:-0.16px;word-spacing:0.06px;}
#td_74{left:139px;bottom:753px;}
#te_74{left:165px;bottom:753px;letter-spacing:-0.13px;word-spacing:0.85px;}
#tf_74{left:165px;bottom:733px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tg_74{left:142px;bottom:692px;letter-spacing:0.11px;word-spacing:0.12px;}
#th_74{left:107px;bottom:648px;letter-spacing:0.12px;word-spacing:10.63px;}
#ti_74{left:107px;bottom:630px;letter-spacing:0.12px;word-spacing:9.12px;}
#tj_74{left:107px;bottom:612px;letter-spacing:0.1px;word-spacing:-0.45px;}
#tk_74{left:107px;bottom:593px;letter-spacing:0.11px;word-spacing:1.41px;}
#tl_74{left:107px;bottom:575px;letter-spacing:0.1px;word-spacing:0.04px;}
#tm_74{left:107px;bottom:544px;letter-spacing:0.13px;word-spacing:0.02px;}
#tn_74{left:245px;bottom:515px;letter-spacing:-0.14px;word-spacing:0.03px;}
#to_74{left:327px;bottom:498px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tp_74{left:142px;bottom:413px;letter-spacing:0.15px;}
#tq_74{left:87px;bottom:385px;}
#tr_74{left:113px;bottom:385px;letter-spacing:-0.15px;word-spacing:-0.79px;}
#ts_74{left:559px;bottom:385px;letter-spacing:-0.11px;}
#tt_74{left:113px;bottom:364px;letter-spacing:-0.16px;word-spacing:4.38px;}
#tu_74{left:113px;bottom:344px;letter-spacing:-0.12px;word-spacing:1.75px;}
#tv_74{left:113px;bottom:324px;letter-spacing:-0.19px;word-spacing:0.1px;}
#tw_74{left:87px;bottom:300px;}
#tx_74{left:113px;bottom:300px;letter-spacing:-0.14px;word-spacing:7.1px;}
#ty_74{left:113px;bottom:279px;letter-spacing:-0.17px;word-spacing:4.17px;}
#tz_74{left:113px;bottom:259px;letter-spacing:-0.14px;word-spacing:6.4px;}
#t10_74{left:113px;bottom:239px;letter-spacing:-0.15px;word-spacing:7.76px;}
#t11_74{left:113px;bottom:219px;letter-spacing:-0.13px;word-spacing:2.65px;}
#t12_74{left:520px;bottom:219px;}
#t13_74{left:525px;bottom:219px;letter-spacing:-0.12px;word-spacing:2.63px;}
#t14_74{left:113px;bottom:199px;letter-spacing:-0.12px;word-spacing:2.02px;}
#t15_74{left:215px;bottom:199px;letter-spacing:-0.12px;word-spacing:2.01px;}
#t16_74{left:113px;bottom:179px;letter-spacing:-0.16px;word-spacing:0.25px;}
#t17_74{left:113px;bottom:158px;letter-spacing:-0.13px;word-spacing:0.25px;}
#t18_74{left:113px;bottom:138px;letter-spacing:-0.17px;word-spacing:3.12px;}
#t19_74{left:113px;bottom:118px;letter-spacing:-0.13px;word-spacing:-0.54px;}

.s0_74{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_74{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s2_74{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s3_74{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_74{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_74{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_74{font-size:15px;font-family:MogulArial_16s;color:#050505;}
.s7_74{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s8_74{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts74" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg74" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="74/74.svg"
          type="image/svg+xml"
          id="pdf74"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_74" class="t s0_74">
          74{" "}
        </span>
        <span id="t2_74" class="t s1_74">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_74" class="t s2_74">
          •{" "}
        </span>
        <span id="t4_74" class="t s3_74">
          Хүүхдийн цогц хөгжил, сурлагын амжилт хангалтгүй{" "}
        </span>
        <span id="t5_74" class="t s4_74">
          •{" "}
        </span>
        <span id="t6_74" class="t s3_74">
          Сурагчдын математик сэтгэлгээний чадвар сул{" "}
        </span>
        <span id="t7_74" class="t s4_74">
          •{" "}
        </span>
        <span id="t8_74" class="t s3_74">
          Сурагчдын сэтгэхүйн задлан шинжлэх, харьцуулах, ангилах,{" "}
        </span>
        <span id="t9_74" class="t s3_74">
          нэгтгэх, учир шалтгаанд нь сэтгэх, системд нь сэтгэх чадвар{" "}
        </span>
        <span id="ta_74" class="t s3_74">
          доогуур{" "}
        </span>
        <span id="tb_74" class="t s4_74">
          •{" "}
        </span>
        <span id="tc_74" class="t s3_74">
          Сурагчдын анхаарлын төвлөрөл сул{" "}
        </span>
        <span id="td_74" class="t s4_74">
          •{" "}
        </span>
        <span id="te_74" class="t s3_74">
          Сурагчдын уншаад ойлгох, ойлгосноо илэрхийлэх, зохион бичиж{" "}
        </span>
        <span id="tf_74" class="t s3_74">
          найруулах чадвар сул{" "}
        </span>
        <span id="tg_74" class="t s5_74">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="th_74" class="t s6_74">
          Сургуулийн 1-12 дугаар ангийн бүх сурагчаас судлагдахуун бүрээр{" "}
        </span>
        <span id="ti_74" class="t s6_74">
          блюпринтийн дагуу боловсруулсан даалгавраар явцын үнэлгээ бүхий{" "}
        </span>
        <span id="tj_74" class="t s6_74">
          шалгалтыг зохион байгуулж, үр дүнд шинжилгээ хийж, багш нэг бүрт
          зөвлөгөө{" "}
        </span>
        <span id="tk_74" class="t s6_74">
          өгсөн. Гүйцэтгэлийн дундаж хувь суурь түвшинтэй харьцуулахад 5.8
          хувиар{" "}
        </span>
        <span id="tl_74" class="t s6_74">
          ахисан үзүүлэлттэй гарсан байна.{" "}
        </span>
        <span id="tm_74" class="t s6_74">
          Энэ нь сурагчдын танин мэдэхүйн хөгжлийг дэмжих ажил болжээ.{" "}
        </span>
        <span id="tn_74" class="t s7_74">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="to_74" class="t s7_74">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tp_74" class="t s5_74">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tq_74" class="t s4_74">
          •{" "}
        </span>
        <span id="tr_74" class="t s3_74">
          Судлагдахууны онцлогоос хамааран хичээлийн агуулгыг{" "}
        </span>
        <span id="ts_74" class="t s3_74">
          интеграцчилан{" "}
        </span>
        <span id="tt_74" class="t s3_74">
          ХГҮА-тай холбон танхимын бус сургалтыг зохион байгуулахдаа эцэг{" "}
        </span>
        <span id="tu_74" class="t s3_74">
          эх, асран хамгаалагч, харгалзан дэмжигч, орон нутгийн аж ахуйн нэгж{" "}
        </span>
        <span id="tv_74" class="t s3_74">
          байгууллагуудыг татан оролцуулах{" "}
        </span>
        <span id="tw_74" class="t s4_74">
          •{" "}
        </span>
        <span id="tx_74" class="t s3_74">
          Сургалтын заах арга зүй нь сурагчдын танин мэдэхүйн чадварыг{" "}
        </span>
        <span id="ty_74" class="t s3_74">
          хөгжүүлэх, суурь чадварыг эзэмшүүлэх ба ингэснээр мэдлэг, бүтээх,{" "}
        </span>
        <span id="tz_74" class="t s3_74">
          хувиргах чадвар бий болно. Үүний тулд хүүхдийн танин мэдэхүйн{" "}
        </span>
        <span id="t10_74" class="t s3_74">
          буюу суралцахуйн түвшинг нь үнэлж арга зүйгээ боловсруулахад{" "}
        </span>
        <span id="t11_74" class="t s3_74">
          багш нарыг мэргэжил арга зүйн хувьд чиглүүлэх{" "}
        </span>
        <span id="t12_74" class="t s3_74">
          (
        </span>
        <span id="t13_74" class="t s8_74">
          Жишээ нь: Юмсыг{" "}
        </span>
        <span id="t14_74" class="t s8_74">
          хооронд нь{" "}
        </span>
        <span id="t15_74" class="t s8_74">
          тоо болон чанарын хувьд харьцуулах, ангилах үйлдлийг{" "}
        </span>
        <span id="t16_74" class="t s8_74">
          хичээлийн элементэд түлхүү оруулах, тухайлбал, зориулалт, дотоод{" "}
        </span>
        <span id="t17_74" class="t s8_74">
          нийтлэг онцлогоор ойлголтыг харьцуулах, ангилах гэх мэт үйлдлийг{" "}
        </span>
        <span id="t18_74" class="t s8_74">
          судлагдахууны онцлогт тохируулан ээлжит хичээлдээ сонирхолтой{" "}
        </span>
        <span id="t19_74" class="t s8_74">
          байдлаар тусгах, хичээл сургалтын үед хүүхдийн хийх үйл нь өгөгдсөн{" "}
        </span>
      </div>
    </div>
  );
}
