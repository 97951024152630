import React from "react";
export default function HundredFifteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_115{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_115{left:87px;bottom:941px;letter-spacing:-0.18px;word-spacing:5.11px;}
#t3_115{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:2.25px;}
#t4_115{left:87px;bottom:901px;letter-spacing:-0.21px;}
#t5_115{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:6.27px;}
#t6_115{left:87px;bottom:860px;letter-spacing:-0.15px;word-spacing:-0.22px;}
#t7_115{left:87px;bottom:840px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_115{left:230px;bottom:849px;letter-spacing:-0.11px;}
#t9_115{left:108px;bottom:820px;letter-spacing:-0.13px;word-spacing:0.41px;}
#ta_115{left:130px;bottom:800px;letter-spacing:-0.11px;}
#tb_115{left:108px;bottom:775px;letter-spacing:-0.13px;word-spacing:0.46px;}
#tc_115{left:130px;bottom:755px;letter-spacing:-0.1px;}
#td_115{left:108px;bottom:731px;letter-spacing:-0.61px;word-spacing:0.58px;}
#te_115{left:130px;bottom:710px;letter-spacing:-0.59px;}
#tf_115{left:108px;bottom:686px;letter-spacing:-0.11px;word-spacing:0.44px;}
#tg_115{left:130px;bottom:666px;letter-spacing:-0.08px;}
#th_115{left:108px;bottom:641px;letter-spacing:-0.16px;word-spacing:0.44px;}
#ti_115{left:108px;bottom:617px;letter-spacing:-0.15px;word-spacing:0.56px;}
#tj_115{left:108px;bottom:592px;letter-spacing:-0.13px;word-spacing:6.41px;}
#tk_115{left:130px;bottom:572px;letter-spacing:-0.07px;}
#tl_115{left:108px;bottom:548px;letter-spacing:-0.19px;word-spacing:3.05px;}
#tm_115{left:268px;bottom:548px;letter-spacing:-0.16px;}
#tn_115{left:367px;bottom:548px;letter-spacing:-0.2px;}
#to_115{left:420px;bottom:548px;letter-spacing:-0.07px;}
#tp_115{left:464px;bottom:548px;letter-spacing:-0.08px;}
#tq_115{left:524px;bottom:548px;letter-spacing:-0.19px;}
#tr_115{left:626px;bottom:548px;letter-spacing:-0.19px;}
#ts_115{left:130px;bottom:527px;letter-spacing:-0.13px;word-spacing:0.02px;}
#tt_115{left:87px;bottom:499px;letter-spacing:-0.17px;word-spacing:0.87px;}
#tu_115{left:87px;bottom:478px;letter-spacing:-0.17px;word-spacing:3.14px;}
#tv_115{left:87px;bottom:458px;letter-spacing:-0.14px;word-spacing:-0.59px;}
#tw_115{left:87px;bottom:438px;letter-spacing:-0.15px;word-spacing:-2.14px;}
#tx_115{left:87px;bottom:418px;letter-spacing:-0.13px;word-spacing:-0.42px;}
#ty_115{left:87px;bottom:398px;letter-spacing:-0.14px;word-spacing:3.92px;}
#tz_115{left:87px;bottom:378px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t10_115{left:581px;bottom:386px;letter-spacing:-0.11px;}
#t11_115{left:596px;bottom:378px;letter-spacing:-0.08px;}
#t12_115{left:114px;bottom:351px;letter-spacing:-0.05px;}
#t13_115{left:142px;bottom:351px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t14_115{left:114px;bottom:328px;letter-spacing:-0.05px;}
#t15_115{left:142px;bottom:328px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t16_115{left:114px;bottom:305px;letter-spacing:-0.05px;}
#t17_115{left:142px;bottom:305px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t18_115{left:114px;bottom:283px;letter-spacing:-0.05px;}
#t19_115{left:142px;bottom:283px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t1a_115{left:114px;bottom:260px;letter-spacing:-0.05px;}
#t1b_115{left:142px;bottom:260px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t1c_115{left:114px;bottom:237px;letter-spacing:-0.05px;}
#t1d_115{left:142px;bottom:237px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1e_115{left:114px;bottom:215px;letter-spacing:-0.05px;}
#t1f_115{left:142px;bottom:215px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1g_115{left:87px;bottom:181px;letter-spacing:0.08px;}
#t1h_115{left:108px;bottom:180px;letter-spacing:-0.3px;word-spacing:2.68px;}
#t1i_115{left:108px;bottom:164px;letter-spacing:-0.28px;word-spacing:0.02px;}
#t1j_115{left:87px;bottom:148px;letter-spacing:0.08px;}
#t1k_115{left:108px;bottom:147px;letter-spacing:-0.18px;word-spacing:5.74px;}
#t1l_115{left:108px;bottom:131px;letter-spacing:-0.17px;word-spacing:4.53px;}
#t1m_115{left:108px;bottom:114px;letter-spacing:-0.14px;}

.s0_115{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_115{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_115{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s3_115{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_115{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s5_115{font-size:14px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts115" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg115" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="115/115.svg"
          type="image/svg+xml"
          id="pdf115"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_115" class="t s0_115">
          115{" "}
        </span>
        <span id="t2_115" class="t s1_115">
          ачааллаа бууруулахын тулд хичээлийн бэлтгэл судалгаа болон хичээл{" "}
        </span>
        <span id="t3_115" class="t s1_115">
          төлөвлөлтийг боловсруулахад хамтран ажиллаж, цаг зарцуулах нь чухал{" "}
        </span>
        <span id="t4_115" class="t s1_115">
          юм.{" "}
        </span>
        <span id="t5_115" class="t s1_115">
          Бэлэн арга зүйгээр Workshop, хэлэлцүлэг өрнүүлэх ба сурагч төвтэй,{" "}
        </span>
        <span id="t6_115" class="t s1_115">
          оролцоонд суурилсан бүтээлч арга зүйн загваруудыг дараах эх сурвалжаас{" "}
        </span>
        <span id="t7_115" class="t s1_115">
          уншиж судлаарай.{" "}
        </span>
        <span id="t8_115" class="t s2_115">
          12{" "}
        </span>
        <span id="t9_115" class="t s1_115">
          1. Асуудал шийдвэрлэх сургалтын арга зүй (Problem-Based Learning -{" "}
        </span>
        <span id="ta_115" class="t s1_115">
          PBL){" "}
        </span>
        <span id="tb_115" class="t s1_115">
          2. Үзэгдэлд суурилсан сургалтын арга зүй (Phenomenon-Based Learning{" "}
        </span>
        <span id="tc_115" class="t s1_115">
          -PhBL){" "}
        </span>
        <span id="td_115" class="t s1_115">
          3. Хэл агуулгыг интеграцчилах арга зүй (Content Language Integrated{" "}
        </span>
        <span id="te_115" class="t s1_115">
          Learning -CLIL){" "}
        </span>
        <span id="tf_115" class="t s1_115">
          4. Төсөлд суурилсан сургалтын арга зүй (Project-Based Learning{" "}
        </span>
        <span id="tg_115" class="t s1_115">
          -PrBL){" "}
        </span>
        <span id="th_115" class="t s1_115">
          5. Даалгаварт суурилсан сургалтын арга зүй (Task based learning){" "}
        </span>
        <span id="ti_115" class="t s1_115">
          6. Тонгоруу сургалт (Flipped learning)-ын арга зүй{" "}
        </span>
        <span id="tj_115" class="t s1_115">
          7. Ялгаатай суралцахуйн арга зүй (Different learning - Differentiated{" "}
        </span>
        <span id="tk_115" class="t s1_115">
          instruction){" "}
        </span>
        <span id="tl_115" class="t s1_115">
          8. Ганцаарчилсан{" "}
        </span>
        <span id="tm_115" class="t s1_115">
          сургалтын{" "}
        </span>
        <span id="tn_115" class="t s1_115">
          арга{" "}
        </span>
        <span id="to_115" class="t s1_115">
          зүй{" "}
        </span>
        <span id="tp_115" class="t s1_115">
          /тэгш{" "}
        </span>
        <span id="tq_115" class="t s1_115">
          хамруулан{" "}
        </span>
        <span id="tr_115" class="t s1_115">
          сургах{" "}
        </span>
        <span id="ts_115" class="t s1_115">
          боловсролын жишээн дээр/{" "}
        </span>
        <span id="tt_115" class="t s1_115">
          Мэдээллийн технологийн хөгжлийг ашиглан чанартай, хэрэгцээтэй мэдлэг,{" "}
        </span>
        <span id="tu_115" class="t s1_115">
          боловсрол олгох, эзэмшихийн тулд боловсрол олж авах хэлбэр нь орон{" "}
        </span>
        <span id="tv_115" class="t s1_115">
          зай, цаг хугацаанаас үл хамааран, чөлөөтэй сонгон судлах бололцоог
          олгох{" "}
        </span>
        <span id="tw_115" class="t s1_115">
          шаардлага тавигдаж байна. Сургууль бүр өөрийн сургуулийн сурагч,
          багшийн{" "}
        </span>
        <span id="tx_115" class="t s1_115">
          тоо, анги танхимын хүрэлцээ зэргийг харгалзан хосолсон сургалтын
          дараах{" "}
        </span>
        <span id="ty_115" class="t s1_115">
          загваруудаас өөрийн сургуулийн онцлогт тохируулан сонгон хэрэгжүүлж{" "}
        </span>
        <span id="tz_115" class="t s1_115">
          тогтсон загварыг сургалтад нэвтрүүлэх нь чухал болоод байна.{" "}
        </span>
        <span id="t10_115" class="t s2_115">
          13{" "}
        </span>
        <span id="t11_115" class="t s1_115">
          Үүнд:{" "}
        </span>
        <span id="t12_115" class="t s3_115">
          1.{" "}
        </span>
        <span id="t13_115" class="t s1_115">
          “Буудал сэлгэх загвар”{" "}
        </span>
        <span id="t14_115" class="t s3_115">
          2.{" "}
        </span>
        <span id="t15_115" class="t s1_115">
          “Лаб сэлгэх загвар”{" "}
        </span>
        <span id="t16_115" class="t s3_115">
          3.{" "}
        </span>
        <span id="t17_115" class="t s1_115">
          “Ганцаарчилсан сэлгэлт хийх загвар”{" "}
        </span>
        <span id="t18_115" class="t s3_115">
          4.{" "}
        </span>
        <span id="t19_115" class="t s1_115">
          “Тонгоруу сургалтын загвар”{" "}
        </span>
        <span id="t1a_115" class="t s3_115">
          5.{" "}
        </span>
        <span id="t1b_115" class="t s1_115">
          “Уян хатан сургалтын загвар”{" "}
        </span>
        <span id="t1c_115" class="t s3_115">
          6.{" "}
        </span>
        <span id="t1d_115" class="t s1_115">
          “Сонголтын загвар”{" "}
        </span>
        <span id="t1e_115" class="t s3_115">
          7.{" "}
        </span>
        <span id="t1f_115" class="t s1_115">
          “Өргөжүүлсэн виртуал сургалтын загвар”{" "}
        </span>
        <span id="t1g_115" class="t s4_115">
          12{" "}
        </span>
        <span id="t1h_115" class="t s5_115">
          БШУЯ, Сурагч төвтэй, тэгш хамруулах, оролцоонд суурилсан бүтээлч арга
          зүй. Цахим{" "}
        </span>
        <span id="t1i_115" class="t s5_115">
          сургалтын модуль
          https://s3.ap-southeast-1.amazonaws.com/assets.aplus.mn/h/2-12-new.pdf{" "}
        </span>
        <span id="t1j_115" class="t s4_115">
          13{" "}
        </span>
        <span id="t1k_115" class="t s5_115">
          БХ. ЕБС-ийн сурагчдын хичээл, сурлагын хоцрогдлыг нөхөх, арилгах
          төлөвлөгөөг{" "}
        </span>
        <span id="t1l_115" class="t s5_115">
          хэрэгжүүлэх сургалт, үйл ажиллагааны удирдамж. 2021 он.
          https://s3.ap-southeast-1.{" "}
        </span>
        <span id="t1m_115" class="t s5_115">
          amazonaws.com/assets.aplus.mn/h/1-1-new.pdf{" "}
        </span>
      </div>
    </div>
  );
}
