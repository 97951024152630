import React from "react";
export default function HundredOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_101{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_101{left:87px;bottom:939px;letter-spacing:0.15px;word-spacing:12.18px;}
#t3_101{left:87px;bottom:917px;letter-spacing:0.11px;word-spacing:0.08px;}
#t4_101{left:87px;bottom:889px;letter-spacing:-0.18px;word-spacing:2.56px;}
#t5_101{left:87px;bottom:869px;letter-spacing:-0.14px;word-spacing:0.91px;}
#t6_101{left:87px;bottom:849px;letter-spacing:-0.17px;word-spacing:2.61px;}
#t7_101{left:87px;bottom:829px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t8_101{left:108px;bottom:800px;letter-spacing:-0.12px;word-spacing:4.67px;}
#t9_101{left:468px;bottom:800px;letter-spacing:-0.15px;word-spacing:3.03px;}
#ta_101{left:130px;bottom:780px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tb_101{left:108px;bottom:751px;}
#tc_101{left:130px;bottom:751px;letter-spacing:-0.13px;word-spacing:0.4px;}
#td_101{left:130px;bottom:731px;letter-spacing:-0.12px;word-spacing:-1.06px;}
#te_101{left:130px;bottom:710px;letter-spacing:-0.15px;word-spacing:-1.24px;}
#tf_101{left:130px;bottom:690px;letter-spacing:-0.14px;word-spacing:6.19px;}
#tg_101{left:130px;bottom:670px;letter-spacing:-0.17px;word-spacing:-0.01px;}
#th_101{left:130px;bottom:650px;letter-spacing:-0.17px;word-spacing:3.53px;}
#ti_101{left:130px;bottom:630px;letter-spacing:-0.16px;word-spacing:5.88px;}
#tj_101{left:130px;bottom:610px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_101{left:108px;bottom:581px;letter-spacing:-0.17px;word-spacing:7.83px;}
#tl_101{left:476px;bottom:581px;letter-spacing:-0.12px;word-spacing:6.57px;}
#tm_101{left:130px;bottom:561px;letter-spacing:-0.17px;word-spacing:2.32px;}
#tn_101{left:130px;bottom:540px;letter-spacing:-0.13px;word-spacing:-0.44px;}
#to_101{left:130px;bottom:520px;letter-spacing:-0.14px;word-spacing:1.02px;}
#tp_101{left:130px;bottom:500px;letter-spacing:-0.12px;word-spacing:2.19px;}
#tq_101{left:130px;bottom:480px;letter-spacing:-0.14px;word-spacing:7.35px;}
#tr_101{left:130px;bottom:460px;letter-spacing:-0.15px;word-spacing:1.12px;}
#ts_101{left:130px;bottom:440px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tt_101{left:108px;bottom:411px;letter-spacing:-0.14px;word-spacing:5.21px;}
#tu_101{left:417px;bottom:411px;letter-spacing:-0.15px;word-spacing:3.69px;}
#tv_101{left:130px;bottom:391px;letter-spacing:-0.14px;word-spacing:0.4px;}
#tw_101{left:130px;bottom:370px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tx_101{left:130px;bottom:346px;letter-spacing:-0.11px;word-spacing:-0.57px;}
#ty_101{left:152px;bottom:326px;letter-spacing:-0.42px;}
#tz_101{left:130px;bottom:301px;letter-spacing:-0.33px;word-spacing:0.72px;}
#t10_101{left:152px;bottom:281px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t11_101{left:130px;bottom:257px;letter-spacing:-0.17px;word-spacing:2.31px;}
#t12_101{left:152px;bottom:236px;letter-spacing:-0.16px;word-spacing:2.78px;}
#t13_101{left:152px;bottom:216px;letter-spacing:-0.16px;word-spacing:-0.22px;}
#t14_101{left:152px;bottom:196px;letter-spacing:-0.17px;word-spacing:-1.12px;}
#t15_101{left:152px;bottom:176px;letter-spacing:-0.15px;word-spacing:1.74px;}
#t16_101{left:152px;bottom:156px;letter-spacing:-0.13px;word-spacing:-0.32px;}
#t17_101{left:152px;bottom:136px;letter-spacing:-0.15px;word-spacing:2.03px;}
#t18_101{left:152px;bottom:115px;letter-spacing:-0.33px;word-spacing:0.24px;}

.s0_101{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_101{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_101{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_101{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts101" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg101" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="101/101.svg"
          type="image/svg+xml"
          id="pdf101"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_101" class="t s0_101">
          101{" "}
        </span>
        <span id="t2_101" class="t s1_101">
          Сургалтын хөтөлбөрийн хэрэгжилтийг дэмжихэд талуудыг{" "}
        </span>
        <span id="t3_101" class="t s1_101">
          хэрхэн оролцуулах вэ?{" "}
        </span>
        <span id="t4_101" class="t s2_101">
          Оролцогч талуудыг тулгарч буй асуудал, бэрхшээлийг даван туулах арга{" "}
        </span>
        <span id="t5_101" class="t s2_101">
          замыг хайх, шийдвэр гаргах үйл явцад татан оролцуулах нь нэн тэргүүний{" "}
        </span>
        <span id="t6_101" class="t s2_101">
          асуудал юм. Иймд оролцогч талуудыг татан оролцуулах хэд хэдэн аргыг{" "}
        </span>
        <span id="t7_101" class="t s2_101">
          санал болгож байна. Үүнд:{" "}
        </span>
        <span id="t8_101" class="t s3_101">
          - Орон нутгийн болон үндэсний түвшний{" "}
        </span>
        <span id="t9_101" class="t s2_101">
          бодлогын шийдвэр гаргах{" "}
        </span>
        <span id="ta_101" class="t s3_101">
          албан тушаалтнуудыг оролцуулах{" "}
        </span>
        <span id="tb_101" class="t s2_101">
          -{" "}
        </span>
        <span id="tc_101" class="t s2_101">
          Мөн бүлэгт эрдэмтэн судлаачид, ТББ, улс төрийн нам, шашны болон{" "}
        </span>
        <span id="td_101" class="t s2_101">
          эцэг эх, асран хамгаалагч, харгалзан дэмжигчийн бүлгүүд, багш нарын{" "}
        </span>
        <span id="te_101" class="t s2_101">
          холбоо, сургуулийн төлөөлөл багтаж болно. Эдгээр нөлөө бүхий албан{" "}
        </span>
        <span id="tf_101" class="t s2_101">
          тушаалтнуудад орон нутаг сургуулийнхаа сурагчдыг гүйцэтгэлийн{" "}
        </span>
        <span id="tg_101" class="t s2_101">
          үнэлгээний үр дүнг мэдээлэх, танилцуулах, сургалт, үйл ажиллагааны{" "}
        </span>
        <span id="th_101" class="t s2_101">
          талаар шуудан, цахим шуудангаар холбоо барих, мэдээлэл, санал{" "}
        </span>
        <span id="ti_101" class="t s2_101">
          бодлоо солилцох зорилгоор зөвлөлдөх уулзалтад урих байдлаар{" "}
        </span>
        <span id="tj_101" class="t s2_101">
          оролцооны түвшинг нэмэгдүүлж болно.{" "}
        </span>
        <span id="tk_101" class="t s3_101">
          - Хэвлэл, мэдээллийг татан оролцуулах:{" "}
        </span>
        <span id="tl_101" class="t s2_101">
          Олон нийтийг хамарсан{" "}
        </span>
        <span id="tm_101" class="t s2_101">
          арга хэмжээ, товч танилцуулга, хэвлэл мэдээллийн хэрэгсэл, эрдэм{" "}
        </span>
        <span id="tn_101" class="t s2_101">
          шинжилгээний сэтгүүл гэх мэт харилцаа холбооны төрөл бүрийн арга,{" "}
        </span>
        <span id="to_101" class="t s2_101">
          хэлбэрээр дамжуулан илүү өргөн хүрээнд үйл ажиллагаагаа явуулах{" "}
        </span>
        <span id="tp_101" class="t s2_101">
          нь ашиг тустай байж болно. Түүнчлэн нийт ажилтан, эцэг эх, асран{" "}
        </span>
        <span id="tq_101" class="t s2_101">
          хамгаалагч, харгалзан дэмжигчид, сурагчдыг мэдээллээр хангах,{" "}
        </span>
        <span id="tr_101" class="t s2_101">
          үйл ажиллагаанд татан оролцуулах нь сургуулийн амьдралд үзүүлэх{" "}
        </span>
        <span id="ts_101" class="t s2_101">
          нөлөөллийг нэмэгдүүлэх боломжтой.{" "}
        </span>
        <span id="tt_101" class="t s3_101">
          - Багш нарыг татан оролцуулах: Х
        </span>
        <span id="tu_101" class="t s2_101">
          амгийн чухал оролцогч тал бол{" "}
        </span>
        <span id="tv_101" class="t s2_101">
          багш юм. Сургалтын хөтөлбөр хэрэгжүүлэх үйл явцад шууд оролцдог{" "}
        </span>
        <span id="tw_101" class="t s2_101">
          багшийн оролцоо дараах онцлогтой. Үүнд:{" "}
        </span>
        <span id="tx_101" class="t s2_101">
          1. Хамтран ажиллаж, мэдлэгээ хуваалцаж, үйл явцад тэгш хувь нэмэр{" "}
        </span>
        <span id="ty_101" class="t s2_101">
          оруулдаг.{" "}
        </span>
        <span id="tz_101" class="t s2_101">
          2. Багш сургалтын хөтөлбөрийг хэрэгжүүлэхэд ямар мэдлэг, туршлага,{" "}
        </span>
        <span id="t10_101" class="t s2_101">
          ур чадвар хэрэгтэй вэ гэдгийг ойлгохын тулд оролцдог.{" "}
        </span>
        <span id="t11_101" class="t s2_101">
          3. Багшийн оролцоо улам бүр түгээмэл болж байгаа боловч тэдний{" "}
        </span>
        <span id="t12_101" class="t s2_101">
          цаг хугацаа хязгаарлагдмал байдаг тул нэмэлт үүрэг хариуцлага{" "}
        </span>
        <span id="t13_101" class="t s2_101">
          хүлээхэд ажлын ачаалал ихэсдэг, сургалтын хөтөлбөр хэрэгжүүлэх{" "}
        </span>
        <span id="t14_101" class="t s2_101">
          бусад үйл ажиллагаанд саад болдог захиргааны дарамт, шахалттай{" "}
        </span>
        <span id="t15_101" class="t s2_101">
          тулгардаг зэрэгтэй холбоотой юм. Инновац туршилтын төслүүдэд{" "}
        </span>
        <span id="t16_101" class="t s2_101">
          оролцож буй багш нар хувь хүний болон мэргэжлийн хувьд хөгжиж,{" "}
        </span>
        <span id="t17_101" class="t s2_101">
          санал болгож буй өөрчлөлтүүдийн талаар гүн гүнзгий ойлголттой{" "}
        </span>
        <span id="t18_101" class="t s2_101">
          болж чаддаг.{" "}
        </span>
      </div>
    </div>
  );
}
