import React from "react";
export default function HundredNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_109{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_109{left:154px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t3_109{left:87px;bottom:566px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t4_109{left:87px;bottom:542px;}
#t5_109{left:113px;bottom:542px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t6_109{left:87px;bottom:522px;}
#t7_109{left:113px;bottom:522px;letter-spacing:-0.15px;word-spacing:0.55px;}
#t8_109{left:113px;bottom:501px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t9_109{left:87px;bottom:481px;}
#ta_109{left:113px;bottom:481px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tb_109{left:87px;bottom:461px;}
#tc_109{left:113px;bottom:461px;letter-spacing:-0.15px;word-spacing:3.59px;}
#td_109{left:113px;bottom:441px;letter-spacing:-0.13px;word-spacing:0.04px;}
#te_109{left:87px;bottom:421px;}
#tf_109{left:113px;bottom:421px;letter-spacing:-0.13px;word-spacing:1.42px;}
#tg_109{left:113px;bottom:401px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_109{left:87px;bottom:380px;}
#ti_109{left:113px;bottom:380px;letter-spacing:-0.17px;word-spacing:7.29px;}
#tj_109{left:113px;bottom:360px;letter-spacing:-0.15px;word-spacing:6.49px;}
#tk_109{left:113px;bottom:340px;letter-spacing:-0.16px;word-spacing:5.97px;}
#tl_109{left:113px;bottom:320px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_109{left:87px;bottom:300px;}
#tn_109{left:113px;bottom:300px;letter-spacing:-0.17px;word-spacing:8.55px;}
#to_109{left:113px;bottom:280px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tp_109{left:87px;bottom:239px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tq_109{left:87px;bottom:215px;letter-spacing:-0.14px;word-spacing:9.41px;}
#tr_109{left:87px;bottom:195px;letter-spacing:-0.15px;word-spacing:4.04px;}
#ts_109{left:87px;bottom:174px;letter-spacing:-0.16px;word-spacing:3.4px;}
#tt_109{left:87px;bottom:154px;letter-spacing:-0.14px;word-spacing:7.68px;}
#tu_109{left:87px;bottom:134px;letter-spacing:-0.13px;}
#tv_109{left:197px;bottom:134px;letter-spacing:-0.13px;word-spacing:3.04px;}
#tw_109{left:87px;bottom:114px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tx_109{left:232px;bottom:114px;letter-spacing:-0.19px;word-spacing:0.09px;}

.s0_109{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_109{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_109{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_109{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_109{font-size:17px;font-family:Arial-BoldMT_1li;color:#000;}
.s5_109{font-size:17px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts109" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg109" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="109/109.svg"
          type="image/svg+xml"
          id="pdf109"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_109" class="t s0_109">
          109{" "}
        </span>
        <span id="t2_109" class="t s1_109">
          Хүснэгт 3.8. Хүүхэд хөгжил хамгааллын нэгдсэн төлөвлөгөөний уялдаа{" "}
        </span>
        <span id="t3_109" class="t s2_109">
          Тус төлөвлөлтийг боловсруулахдаа{" "}
        </span>
        <span id="t4_109" class="t s3_109">
          •{" "}
        </span>
        <span id="t5_109" class="t s2_109">
          Сургуулийн зорилго, зорилттой уялдуулах{" "}
        </span>
        <span id="t6_109" class="t s3_109">
          •{" "}
        </span>
        <span id="t7_109" class="t s2_109">
          Хүүхэд хөгжил болон хүүхэд хамгааллын асуудлыг цогцоор нь авч үзэж{" "}
        </span>
        <span id="t8_109" class="t s2_109">
          сургалтын хөтөлбөрөөр эзэмших мэдлэг чадвартай уялдуулах{" "}
        </span>
        <span id="t9_109" class="t s3_109">
          •{" "}
        </span>
        <span id="ta_109" class="t s2_109">
          Сурагчийн санал санаачилгад тулгуурлах, оролцоог жигд хангах{" "}
        </span>
        <span id="tb_109" class="t s3_109">
          •{" "}
        </span>
        <span id="tc_109" class="t s2_109">
          Анхан шатнаас гарч ирсэн төлөвлөлтийн давхардлыг арилгаж, багш,{" "}
        </span>
        <span id="td_109" class="t s2_109">
          сурагчдын ачааллыг тооцох, тэнцвэржүүлэх{" "}
        </span>
        <span id="te_109" class="t s3_109">
          •{" "}
        </span>
        <span id="tf_109" class="t s2_109">
          Олон талын оролцоог хангах, дэмжлэг авах, эцэг эх, асран хамгаалах,{" "}
        </span>
        <span id="tg_109" class="t s2_109">
          харгалзан дэмжигч тогтмол оролцуулах, хөгжүүлэх{" "}
        </span>
        <span id="th_109" class="t s3_109">
          •{" "}
        </span>
        <span id="ti_109" class="t s2_109">
          Бусад байгууллагуудын /цагдаа, эрүүл мэндийн газар, хүүхэд, гэр{" "}
        </span>
        <span id="tj_109" class="t s2_109">
          бүлийн хөгжлийн газар, боловсрол шинжлэх ухааны газар, төрийн{" "}
        </span>
        <span id="tk_109" class="t s2_109">
          бус байгууллагууд.../ төлөвлөлтүүдийг хичээлийн жилийн эхэнд авч{" "}
        </span>
        <span id="tl_109" class="t s2_109">
          сургуулийн төлөвлөгөөнд тусгадаг байх{" "}
        </span>
        <span id="tm_109" class="t s3_109">
          •{" "}
        </span>
        <span id="tn_109" class="t s2_109">
          Үйл ажиллагаа бүрийн дараа оролцогчдоос судалгаа авч байнга{" "}
        </span>
        <span id="to_109" class="t s2_109">
          сайжруулдаг байх{" "}
        </span>
        <span id="tp_109" class="t s4_109">
          Сургуулийн нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="tq_109" class="t s5_109">
          Сургуулийн менежментийн зураглалын дагуу сургуулиас дэвшүүлсэн{" "}
        </span>
        <span id="tr_109" class="t s5_109">
          сургалтын зорилго, зорилтуудын хэрэгжилтийг хангахын тулд сургалтын{" "}
        </span>
        <span id="ts_109" class="t s5_109">
          хөтөлбөрийн хэрэгжилт, хүүхэд хөгжил, хамгааллын нэгдсэн төлөвлөгөө,{" "}
        </span>
        <span id="tt_109" class="t s5_109">
          сургуулийн үйл ажиллагааны төлөвлөгөөг нэгтгэн сургуулийн нэгдсэн{" "}
        </span>
        <span id="tu_109" class="t s5_109">
          төлөвлөгөөг{" "}
        </span>
        <span id="tv_109" class="t s5_109">
          Засгийн газрын Хэрэг эрхлэх газрын даргын 2020 оны 100{" "}
        </span>
        <span id="tw_109" class="t s5_109">
          дугаар тушаалын{" "}
        </span>
        <span id="tx_109" class="t s5_109">
          4 дүгээр хавсралтын дагуу боловсруулдаг.{" "}
        </span>
      </div>
    </div>
  );
}
