import React from "react";
export default function Sixty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_60{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_60{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_60{left:100px;bottom:945px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t4_60{left:87px;bottom:610px;letter-spacing:0.06px;word-spacing:9.65px;}
#t5_60{left:87px;bottom:589px;letter-spacing:0.05px;word-spacing:10.33px;}
#t6_60{left:87px;bottom:569px;letter-spacing:0.07px;word-spacing:2.71px;}
#t7_60{left:87px;bottom:549px;letter-spacing:0.07px;word-spacing:2.54px;}
#t8_60{left:87px;bottom:529px;letter-spacing:0.07px;}
#t9_60{left:209px;bottom:529px;letter-spacing:0.08px;}
#ta_60{left:279px;bottom:529px;letter-spacing:0.07px;}
#tb_60{left:395px;bottom:529px;letter-spacing:0.08px;}
#tc_60{left:485px;bottom:529px;letter-spacing:0.08px;}
#td_60{left:551px;bottom:529px;}
#te_60{left:579px;bottom:529px;letter-spacing:0.08px;}
#tf_60{left:87px;bottom:509px;letter-spacing:0.06px;word-spacing:5.5px;}
#tg_60{left:87px;bottom:489px;letter-spacing:0.07px;}
#th_60{left:87px;bottom:455px;letter-spacing:-0.11px;word-spacing:8.21px;}
#ti_60{left:87px;bottom:435px;letter-spacing:-0.1px;word-spacing:4.56px;}
#tj_60{left:87px;bottom:415px;letter-spacing:-0.09px;}
#tk_60{left:87px;bottom:382px;letter-spacing:-0.64px;word-spacing:-0.26px;}
#tl_60{left:87px;bottom:349px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tm_60{left:91px;bottom:313px;letter-spacing:0.12px;word-spacing:0.02px;}
#tn_60{left:91px;bottom:295px;letter-spacing:0.11px;word-spacing:0.03px;}
#to_60{left:404px;bottom:313px;letter-spacing:0.09px;}
#tp_60{left:404px;bottom:295px;letter-spacing:0.09px;}
#tq_60{left:495px;bottom:313px;letter-spacing:0.11px;word-spacing:0.02px;}
#tr_60{left:495px;bottom:295px;letter-spacing:0.07px;}
#ts_60{left:588px;bottom:323px;letter-spacing:0.09px;}
#tt_60{left:588px;bottom:304px;letter-spacing:0.05px;word-spacing:0.07px;}
#tu_60{left:588px;bottom:286px;letter-spacing:0.11px;}
#tv_60{left:91px;bottom:266px;letter-spacing:0.09px;word-spacing:0.05px;}
#tw_60{left:91px;bottom:247px;letter-spacing:0.08px;word-spacing:0.06px;}
#tx_60{left:91px;bottom:226px;letter-spacing:0.08px;word-spacing:0.06px;}
#ty_60{left:91px;bottom:208px;letter-spacing:0.08px;word-spacing:0.06px;}
#tz_60{left:87px;bottom:170px;letter-spacing:-0.48px;word-spacing:6.15px;}
#t10_60{left:87px;bottom:150px;letter-spacing:-0.48px;word-spacing:-1.09px;}
#t11_60{left:87px;bottom:130px;letter-spacing:-0.48px;word-spacing:0.05px;}

.s0_60{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_60{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_60{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s3_60{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_60{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_60{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts60" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg60" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="60/60.svg"
          type="image/svg+xml"
          id="pdf60"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_60" class="t s1_60">
          60{" "}
        </span>
        <span id="t3_60" class="t s2_60">
          Зураг 2.11. Сургалтын байгууллагын үнэлгээ (Суралцагчийн хөгжил
          хамгааллын үр дүн){" "}
        </span>
        <span id="t4_60" class="t s3_60">
          Дээрх зургаас “2. Суралцагчийн хөгжил хамгаалал”-тай холбоотой{" "}
        </span>
        <span id="t5_60" class="t s3_60">
          үзүүлэлтэд Та бүхэн анхаарлаа хандуулах ёстой. Бидний жишээ{" "}
        </span>
        <span id="t6_60" class="t s3_60">
          болгон тусгасан зураг дахь А сургуулийн хувьд сурагч хамгаалал, эцэг{" "}
        </span>
        <span id="t7_60" class="t s3_60">
          эхийн оролцоо, хичээлээс гадуурх үйл ажиллагаа гэсэн үзүүлэлтүүдийг{" "}
        </span>
        <span id="t8_60" class="t s3_60">
          харьцуулбал{" "}
        </span>
        <span id="t9_60" class="t s3_60">
          сурагч{" "}
        </span>
        <span id="ta_60" class="t s3_60">
          хамгааллын{" "}
        </span>
        <span id="tb_60" class="t s3_60">
          үзүүлэлт{" "}
        </span>
        <span id="tc_60" class="t s3_60">
          бусад{" "}
        </span>
        <span id="td_60" class="t s3_60">
          2{" "}
        </span>
        <span id="te_60" class="t s3_60">
          үзүүлэлттэй{" "}
        </span>
        <span id="tf_60" class="t s3_60">
          харьцуулахад сул байна. Харин хичээлээс гадуурх үйл ажиллагааны{" "}
        </span>
        <span id="tg_60" class="t s3_60">
          үзүүлэлт бусад 2 үзүүлэлттэй харьцуулахад өндөр байна.{" "}
        </span>
        <span id="th_60" class="t s3_60">
          Суралцагчийн хөгжил хамгааллын шалгуурыг боловсролын түвшнөөр{" "}
        </span>
        <span id="ti_60" class="t s3_60">
          авч үзэхэд, бага боловсролын түвшний үзүүлэлт суурь, бүрэн дундтай{" "}
        </span>
        <span id="tj_60" class="t s3_60">
          харьцуулахад сул байна.{" "}
        </span>
        <span id="tk_60" class="t s3_60">
          Суралцагчийн хөгжил хамгааллын шалгуурыг Та багш бүрээр харах
          боломжтой.{" "}
        </span>
        <span id="tl_60" class="t s3_60">
          Сургуулийнхаа хувьд:{" "}
        </span>
        <span id="tm_60" class="t s4_60">
          Багшийн эзлэх хувь”Суралцагчийн хөгжил,{" "}
        </span>
        <span id="tn_60" class="t s4_60">
          хамгаалал” шалгуурын үзүүлэлтүүд{" "}
        </span>
        <span id="to_60" class="t s4_60">
          Сурагч{" "}
        </span>
        <span id="tp_60" class="t s4_60">
          хамгаалал{" "}
        </span>
        <span id="tq_60" class="t s4_60">
          Эцэг эхийн{" "}
        </span>
        <span id="tr_60" class="t s4_60">
          оролцоо{" "}
        </span>
        <span id="ts_60" class="t s4_60">
          Хичээлээс{" "}
        </span>
        <span id="tt_60" class="t s4_60">
          гадуурх үйл{" "}
        </span>
        <span id="tu_60" class="t s4_60">
          ажиллагаа{" "}
        </span>
        <span id="tv_60" class="t s4_60">
          Улсын дунджаас дээгүүр үнэлгээтэй{" "}
        </span>
        <span id="tw_60" class="t s4_60">
          багшийн эзлэх хувь{" "}
        </span>
        <span id="tx_60" class="t s4_60">
          Улсын дунджаас доогуур үнэлгээтэй{" "}
        </span>
        <span id="ty_60" class="t s4_60">
          багшийн эзлэх хувь{" "}
        </span>
        <span id="tz_60" class="t s5_60">
          Дээрх хүснэгтийн нэгтгэлийг гаргаж авснаар улсын дунджаас доогуур{" "}
        </span>
        <span id="t10_60" class="t s5_60">
          бөгөөд багшийн эзлэх хувь нь бусад 2-оос их байгаа үзүүлэлт дээрээ
          төвлөрч,{" "}
        </span>
        <span id="t11_60" class="t s5_60">
          сургуулийн …. бүлгийн хүрээнд авах арга хэмжээг тодорхойлж болох юм.{" "}
        </span>
      </div>
    </div>
  );
}
