import React from "react";
export default function Nineteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_19{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_19{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_19{left:100px;bottom:928px;letter-spacing:0.12px;word-spacing:4.54px;}
#t4_19{left:113px;bottom:910px;letter-spacing:-0.03px;word-spacing:0.16px;}
#t5_19{left:100px;bottom:891px;letter-spacing:0.09px;word-spacing:5.05px;}
#t6_19{left:113px;bottom:873px;letter-spacing:0.1px;word-spacing:0.03px;}
#t7_19{left:205px;bottom:847px;letter-spacing:0.09px;word-spacing:0.02px;}
#t8_19{left:87px;bottom:783px;letter-spacing:-0.16px;word-spacing:0.96px;}
#t9_19{left:578px;bottom:783px;letter-spacing:-0.11px;word-spacing:0.94px;}
#ta_19{left:87px;bottom:762px;letter-spacing:-0.13px;word-spacing:-0.48px;}
#tb_19{left:87px;bottom:742px;letter-spacing:-0.15px;word-spacing:-1.23px;}
#tc_19{left:87px;bottom:722px;letter-spacing:-0.13px;word-spacing:0.42px;}
#td_19{left:87px;bottom:702px;letter-spacing:-0.11px;word-spacing:6.13px;}
#te_19{left:87px;bottom:682px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tf_19{left:87px;bottom:649px;letter-spacing:-0.47px;word-spacing:-0.75px;}
#tg_19{left:87px;bottom:628px;letter-spacing:-0.15px;word-spacing:1.51px;}
#th_19{left:87px;bottom:608px;letter-spacing:-0.32px;}
#ti_19{left:184px;bottom:608px;letter-spacing:-0.09px;}
#tj_19{left:244px;bottom:608px;letter-spacing:-0.19px;}
#tk_19{left:289px;bottom:608px;letter-spacing:-0.23px;}
#tl_19{left:339px;bottom:608px;letter-spacing:-0.21px;}
#tm_19{left:87px;bottom:588px;letter-spacing:-0.15px;word-spacing:3.22px;}
#tn_19{left:87px;bottom:568px;letter-spacing:-0.13px;word-spacing:1.7px;}
#to_19{left:87px;bottom:548px;letter-spacing:-0.13px;word-spacing:5.17px;}
#tp_19{left:87px;bottom:528px;letter-spacing:-0.12px;word-spacing:7.71px;}
#tq_19{left:87px;bottom:507px;letter-spacing:-0.15px;}
#tr_19{left:165px;bottom:507px;letter-spacing:-0.09px;}
#ts_19{left:217px;bottom:507px;letter-spacing:-0.09px;}
#tt_19{left:310px;bottom:507px;letter-spacing:-0.09px;}
#tu_19{left:374px;bottom:507px;letter-spacing:-0.08px;}
#tv_19{left:87px;bottom:487px;letter-spacing:-0.12px;}
#tw_19{left:194px;bottom:487px;letter-spacing:-0.19px;}
#tx_19{left:296px;bottom:487px;letter-spacing:-0.14px;}
#ty_19{left:354px;bottom:487px;letter-spacing:-0.09px;}
#tz_19{left:87px;bottom:467px;letter-spacing:-0.09px;word-spacing:-0.36px;}
#t10_19{left:87px;bottom:447px;letter-spacing:-0.13px;word-spacing:9.35px;}
#t11_19{left:87px;bottom:427px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t12_19{left:87px;bottom:394px;letter-spacing:-0.17px;word-spacing:4.96px;}
#t13_19{left:87px;bottom:373px;letter-spacing:-0.1px;}
#t14_19{left:147px;bottom:373px;letter-spacing:-0.14px;word-spacing:1.06px;}
#t15_19{left:87px;bottom:353px;letter-spacing:-0.13px;word-spacing:0.12px;}
#t16_19{left:87px;bottom:333px;letter-spacing:-0.11px;word-spacing:-0.25px;}
#t17_19{left:87px;bottom:313px;letter-spacing:-0.14px;word-spacing:2.7px;}
#t18_19{left:87px;bottom:293px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t19_19{left:100px;bottom:233px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1a_19{left:100px;bottom:202px;letter-spacing:0.1px;word-spacing:-0.23px;}
#t1b_19{left:100px;bottom:183px;letter-spacing:0.08px;word-spacing:1.43px;}
#t1c_19{left:100px;bottom:165px;letter-spacing:0.11px;word-spacing:4.86px;}
#t1d_19{left:100px;bottom:147px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1e_19{left:432px;bottom:474px;letter-spacing:-0.13px;word-spacing:-0.1px;}
#t1f_19{left:436px;bottom:458px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1g_19{left:460px;bottom:441px;letter-spacing:-0.13px;}

.s0_19{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_19{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_19{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_19{font-size:12px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_19{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s5_19{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s6_19{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_19{font-size:15px;font-family:MogulArial_16s;color:#333;}
.s8_19{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_19{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts19" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg19" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="19/19.svg"
          type="image/svg+xml"
          id="pdf19"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_19" class="t s1_19">
          19{" "}
        </span>
        <span id="t3_19" class="t s2_19">
          - Чөлөөт суралцахуй (Learning as you go) - Боловсрол хаана ч, хэзээ ч{" "}
        </span>
        <span id="t4_19" class="t s2_19">
          тасралтгүй явагддаг.{" "}
        </span>
        <span id="t5_19" class="t s2_19">
          - Техник технологид суурилсан нийгэмд шилжсэнээр албан ба албан бус{" "}
        </span>
        <span id="t6_19" class="t s2_19">
          сургалтын ялгаа арилж, үгүй болсон.{" "}
        </span>
        <span id="t7_19" class="t s3_19">
          Эх сурвалж. Four OECD Scenarios for the Future of schooling, 2020,
          www.oecd.org{" "}
        </span>
        <span id="t8_19" class="t s4_19">
          Сургууль бол сурагчдын суралцах орон зай, боломж юм.{" "}
        </span>
        <span id="t9_19" class="t s5_19">
          Тэдэнд илүү{" "}
        </span>
        <span id="ta_19" class="t s5_19">
          их орон зай боломж бий болж байна. Үүний тод жишээ КОВИД-19 цар тахал{" "}
        </span>
        <span id="tb_19" class="t s5_19">
          байлаа. Цар тахал бидэнд сургууль нь тусгаарлагдсан орчин биш юм
          гэдгийг{" "}
        </span>
        <span id="tc_19" class="t s5_19">
          ойлгуулж, сургууль болон багш нар суралцахуйн өөр бусад хүчин зүйлстэй{" "}
        </span>
        <span id="td_19" class="t s5_19">
          харилцаанд орж, хүүхдүүдийн суралцахуйг дэмжиж, хамтран ажиллаж{" "}
        </span>
        <span id="te_19" class="t s5_19">
          болохыг харуулсан.{" "}
        </span>
        <span id="tf_19" class="t s4_19">
          Гэр бүлийн дэмжлэг, туслалцаа, хувь хүн{" "}
        </span>
        <span id="tg_19" class="t s4_19">
          хоорондын харилцаа холбоо суралцах{" "}
        </span>
        <span id="th_19" class="t s4_19">
          томоохон{" "}
        </span>
        <span id="ti_19" class="t s4_19">
          орон{" "}
        </span>
        <span id="tj_19" class="t s4_19">
          зай{" "}
        </span>
        <span id="tk_19" class="t s4_19">
          юм.{" "}
        </span>
        <span id="tl_19" class="t s5_19">
          Танхимын{" "}
        </span>
        <span id="tm_19" class="t s5_19">
          сургалт цуцлагдсан үед суралцах үйлийг{" "}
        </span>
        <span id="tn_19" class="t s5_19">
          тасалдуулахгүй, амжилттай үргэлжлүүлэх{" "}
        </span>
        <span id="to_19" class="t s5_19">
          цор ганц, хамгийн чухал хүчин зүйл нь{" "}
        </span>
        <span id="tp_19" class="t s5_19">
          эцэг эх, асран хамгаалагчийн дэмжлэг{" "}
        </span>
        <span id="tq_19" class="t s5_19">
          байсан.{" "}
        </span>
        <span id="tr_19" class="t s5_19">
          Мөн{" "}
        </span>
        <span id="ts_19" class="t s5_19">
          нийгмийн{" "}
        </span>
        <span id="tt_19" class="t s5_19">
          эрүүл{" "}
        </span>
        <span id="tu_19" class="t s5_19">
          мэнд,{" "}
        </span>
        <span id="tv_19" class="t s5_19">
          халамжийн{" "}
        </span>
        <span id="tw_19" class="t s5_19">
          тогтолцоо,{" "}
        </span>
        <span id="tx_19" class="t s5_19">
          олон{" "}
        </span>
        <span id="ty_19" class="t s5_19">
          нийтийн{" "}
        </span>
        <span id="tz_19" class="t s5_19">
          сүлжээ зэрэг нийгмийн институт нь хүнийг{" "}
        </span>
        <span id="t10_19" class="t s5_19">
          нийгэмшүүлэх, суралцах орон зайгаар{" "}
        </span>
        <span id="t11_19" class="t s5_19">
          хангахад чухал үүрэгтэй болж байна.{" "}
        </span>
        <span id="t12_19" class="t s4_19">
          Сургууль улам бүр нээлттэй болж түншлэлийг хэрэгжүүлэгч болж{" "}
        </span>
        <span id="t13_19" class="t s4_19">
          байна.{" "}
        </span>
        <span id="t14_19" class="t s5_19">
          Сургууль бүр гадаад ертөнцтэй илүү сайн хамтарч ажиллах цаг үе{" "}
        </span>
        <span id="t15_19" class="t s5_19">
          ирсэн байна. Сургууль болон музей, театр, номын сан, спорт клубууд
          зэрэг{" "}
        </span>
        <span id="t16_19" class="t s5_19">
          нийгэм соёлын байгууллага, орон нутгийн бизнес аж ахуйн нэгж хоорондын{" "}
        </span>
        <span id="t17_19" class="t s5_19">
          хамтын ажиллагааг бий болгож, суралцаж болох орон зайг нээж өгөх нь{" "}
        </span>
        <span id="t18_19" class="t s5_19">
          сурагчдад чухал ач холбогдолтой.{" "}
        </span>
        <span id="t19_19" class="t s6_19">
          Сургуулийн өмнөх болон ерөнхий боловсролын хууль (2023){" "}
        </span>
        <span id="t1a_19" class="t s7_19">
          3.3.Хүүхдийн цэцэрлэг /цаашид “цэцэрлэг” гэх/, ерөнхий боловсролын
          сургууль{" "}
        </span>
        <span id="t1b_19" class="t s7_19">
          энэ хуулийн 3.1, 3.2-т заасан боловсролын зорилгыг хүүхдийн эцэг, эх,
          асран{" "}
        </span>
        <span id="t1c_19" class="t s7_19">
          хамгаалагч, харгалзан дэмжигч, нутгийн өөрөө удирдах болон захиргааны{" "}
        </span>
        <span id="t1d_19" class="t s7_19">
          байгууллага, иргэний нийгмийн байгууллагатай хамтран хэрэгжүүлнэ.{" "}
        </span>
        <span id="t1e_19" class="t s8_19">
          Зураг 1.4. Интерактив цахим хичээлээ{" "}
        </span>
        <span id="t1f_19" class="t s8_19">
          үзэж буй ээж, хүү хоёр. Зургийг НҮБ-{" "}
        </span>
        <span id="t1g_19" class="t s8_19">
          ийн Хүүхдийн сан &amp; Gogo.mn{" "}
        </span>
      </div>
    </div>
  );
}
