import React from "react";
export default function F143() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_143{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_143{left:106px;bottom:923px;letter-spacing:0.1px;word-spacing:1.56px;}
#t3_143{left:106px;bottom:905px;letter-spacing:0.11px;word-spacing:3.16px;}
#t4_143{left:106px;bottom:887px;letter-spacing:0.09px;word-spacing:0.04px;}
#t5_143{left:106px;bottom:864px;letter-spacing:0.07px;}
#t6_143{left:128px;bottom:864px;letter-spacing:0.11px;word-spacing:-0.91px;}
#t7_143{left:128px;bottom:846px;letter-spacing:0.13px;word-spacing:0.01px;}
#t8_143{left:106px;bottom:827px;letter-spacing:0.07px;}
#t9_143{left:128px;bottom:827px;letter-spacing:0.11px;word-spacing:2.93px;}
#ta_143{left:128px;bottom:809px;letter-spacing:0.09px;word-spacing:0.05px;}
#tb_143{left:106px;bottom:791px;letter-spacing:0.07px;}
#tc_143{left:128px;bottom:791px;letter-spacing:0.11px;word-spacing:0.03px;}
#td_143{left:106px;bottom:772px;letter-spacing:0.07px;}
#te_143{left:128px;bottom:772px;letter-spacing:0.09px;word-spacing:1.91px;}
#tf_143{left:128px;bottom:754px;letter-spacing:0.16px;}
#tg_143{left:106px;bottom:736px;letter-spacing:0.07px;}
#th_143{left:128px;bottom:736px;letter-spacing:0.11px;word-spacing:-1.43px;}
#ti_143{left:128px;bottom:717px;letter-spacing:0.1px;word-spacing:0.04px;}
#tj_143{left:106px;bottom:699px;letter-spacing:0.07px;}
#tk_143{left:128px;bottom:699px;letter-spacing:0.07px;word-spacing:0.24px;}
#tl_143{left:128px;bottom:681px;letter-spacing:0.13px;word-spacing:0.01px;}
#tm_143{left:106px;bottom:662px;letter-spacing:0.07px;}
#tn_143{left:128px;bottom:662px;letter-spacing:0.08px;word-spacing:-0.71px;}
#to_143{left:128px;bottom:644px;letter-spacing:0.11px;word-spacing:0.02px;}
#tp_143{left:106px;bottom:626px;letter-spacing:-0.39px;}
#tq_143{left:128px;bottom:626px;letter-spacing:-0.37px;word-spacing:-0.63px;}
#tr_143{left:106px;bottom:607px;letter-spacing:0.07px;}
#ts_143{left:128px;bottom:607px;letter-spacing:0.1px;word-spacing:1.8px;}
#tt_143{left:128px;bottom:589px;letter-spacing:0.14px;}
#tu_143{left:106px;bottom:571px;letter-spacing:0.09px;}
#tv_143{left:128px;bottom:571px;letter-spacing:0.13px;word-spacing:6.25px;}
#tw_143{left:128px;bottom:552px;letter-spacing:0.1px;}
#tx_143{left:214px;bottom:552px;letter-spacing:0.2px;word-spacing:-2.71px;}
#ty_143{left:302px;bottom:552px;letter-spacing:0.12px;word-spacing:-2.63px;}
#tz_143{left:537px;bottom:552px;letter-spacing:0.13px;}
#t10_143{left:128px;bottom:534px;letter-spacing:0.14px;word-spacing:0.01px;}
#t11_143{left:333px;bottom:534px;letter-spacing:0.08px;word-spacing:0.06px;}
#t12_143{left:187px;bottom:510px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t13_143{left:429px;bottom:493px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t14_143{left:106px;bottom:413px;letter-spacing:0.1px;word-spacing:-1.68px;}
#t15_143{left:106px;bottom:394px;letter-spacing:0.11px;word-spacing:1.25px;}
#t16_143{left:106px;bottom:376px;letter-spacing:0.08px;word-spacing:1.88px;}
#t17_143{left:106px;bottom:358px;letter-spacing:0.11px;word-spacing:0.87px;}
#t18_143{left:106px;bottom:339px;letter-spacing:0.13px;word-spacing:3.88px;}
#t19_143{left:106px;bottom:321px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1a_143{left:106px;bottom:294px;letter-spacing:0.14px;word-spacing:2.56px;}
#t1b_143{left:106px;bottom:276px;letter-spacing:0.11px;word-spacing:0.6px;}
#t1c_143{left:106px;bottom:257px;letter-spacing:0.12px;word-spacing:-0.42px;}
#t1d_143{left:106px;bottom:239px;letter-spacing:0.11px;word-spacing:-1.16px;}
#t1e_143{left:106px;bottom:221px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1f_143{left:187px;bottom:196px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1g_143{left:429px;bottom:180px;letter-spacing:-0.15px;word-spacing:0.03px;}

.s0_143{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_143{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_143{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_143{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_143{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts143" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg143" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="143/143.svg"
          type="image/svg+xml"
          id="pdf143"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_143" class="t s0_143">
          143{" "}
        </span>
        <span id="t2_143" class="t s1_143">
          ... түүх, нийгмийн ухааны багш нэгдэн судлагдахууны мэргэжлийн
          хөгжлийн{" "}
        </span>
        <span id="t3_143" class="t s1_143">
          бүлгээр ажиллаж сурлагын амжилт тодорхой хувиар ахисан, багш бүрийн{" "}
        </span>
        <span id="t4_143" class="t s1_143">
          арга зүй хөгжсөн байна.{" "}
        </span>
        <span id="t5_143" class="t s2_143">
          1.{" "}
        </span>
        <span id="t6_143" class="t s1_143">
          Аймгийн бүх багш онлайн уулзалт хийж, 2022 оны гүйцэтгэлийн үнэлгээний{" "}
        </span>
        <span id="t7_143" class="t s1_143">
          үр дүнд анализ хийн ярилцсан.{" "}
        </span>
        <span id="t8_143" class="t s2_143">
          2.{" "}
        </span>
        <span id="t9_143" class="t s1_143">
          Түүх, нийгэм судлал хичээлийн 2 дэд бүлэгт хуваагдан бүлгийн ажлын{" "}
        </span>
        <span id="ta_143" class="t s1_143">
          төлөвлөгөө боловсруулсан.{" "}
        </span>
        <span id="tb_143" class="t s2_143">
          3.{" "}
        </span>
        <span id="tc_143" class="t s1_143">
          Бүлгийн ахлагчаа сонгон ажиллах дүрмээ боловсруулан баталсан.{" "}
        </span>
        <span id="td_143" class="t s2_143">
          4.{" "}
        </span>
        <span id="te_143" class="t s1_143">
          Анги бүрээр агуулгын гүйцэтгэл гарган ямар агуулга дээр алдаа байгааг{" "}
        </span>
        <span id="tf_143" class="t s1_143">
          илрүүлсэн.{" "}
        </span>
        <span id="tg_143" class="t s2_143">
          5.{" "}
        </span>
        <span id="th_143" class="t s1_143">
          Гүйцэтгэлийн үнэлгээний даалгавар/суралцахуйн зорилт шинжлэн дийлэнх{" "}
        </span>
        <span id="ti_143" class="t s1_143">
          сурагчдын сул гүйцэтгэсэн даалгаврын алдааны шалтгааныг тодруулсан.{" "}
        </span>
        <span id="tj_143" class="t s2_143">
          6.{" "}
        </span>
        <span id="tk_143" class="t s1_143">
          Гүйцэтгэл сул шалгагдсан агуулгаар хичээл төлөвлөлтөө сайжруулах арга{" "}
        </span>
        <span id="tl_143" class="t s1_143">
          замаа эрэлхийлсэн{" "}
        </span>
        <span id="tm_143" class="t s2_143">
          7.{" "}
        </span>
        <span id="tn_143" class="t s1_143">
          Багш бүр дэд багуудад хуваагдан нэгж хичээлийн төлөвлөлт боловсруулан{" "}
        </span>
        <span id="to_143" class="t s1_143">
          хичээл заасан үр дүнгээ онлайн уулзалтаар ярилцсан.{" "}
        </span>
        <span id="tp_143" class="t s2_143">
          8.{" "}
        </span>
        <span id="tq_143" class="t s1_143">
          Заах, үр дүнг хэлэлцэх, сурагчдын даалгаврын гүйцэтгэлийг тооцон
          ажилласан.{" "}
        </span>
        <span id="tr_143" class="t s2_143">
          9.{" "}
        </span>
        <span id="ts_143" class="t s1_143">
          Хичээлийн арга зүйн санаа, туршлага болгон бичих, бусдад түгээх ажил{" "}
        </span>
        <span id="tt_143" class="t s1_143">
          эхлүүлсэн.{" "}
        </span>
        <span id="tu_143" class="t s2_143">
          10.{" "}
        </span>
        <span id="tv_143" class="t s1_143">
          Бүлгийн ажлын үр дүнд Нийгэм судлал хичээлийн агуулгаар жишиг{" "}
        </span>
        <span id="tw_143" class="t s1_143">
          даалгаврын{" "}
        </span>
        <span id="tx_143" class="t s3_143">
          “Цахим ном“{" "}
        </span>
        <span id="ty_143" class="t s1_143">
          ажлын дэвтэр, Түүхийн хичээлээр{" "}
        </span>
        <span id="tz_143" class="t s3_143">
          “Хялбаршуулсан{" "}
        </span>
        <span id="t10_143" class="t s3_143">
          цахим арга зүйн материал”{" "}
        </span>
        <span id="t11_143" class="t s1_143">
          боловсруулан хэрэглэж байна.{" "}
        </span>
        <span id="t12_143" class="t s4_143">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="t13_143" class="t s4_143">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t14_143" class="t s1_143">
          Бүрэн дунд боловсролын сонгон судлах сургалтын хөтөлбөрийг
          хэрэгжүүлэхэд{" "}
        </span>
        <span id="t15_143" class="t s1_143">
          арга зүйн дэмжлэг үзүүлэх зорилго бүхий ахлах багшийг ажиллуулж,
          эдгээр{" "}
        </span>
        <span id="t16_143" class="t s1_143">
          багш цахим сургуулийн судлагдахууны багш нартай хамтран сонгон судлах{" "}
        </span>
        <span id="t17_143" class="t s1_143">
          хөтөлбөрийн дэлгэрэнгүй төлөвлөлт боловсруулах, цахим хэрэгсэл ашиглан{" "}
        </span>
        <span id="t18_143" class="t s1_143">
          нэгж хичээл төлөвлөн хэрэгжүүлэх зэргээр мэргэжлийн хөгжлийн бүлгээр{" "}
        </span>
        <span id="t19_143" class="t s1_143">
          хамтран ажиллаж байна.{" "}
        </span>
        <span id="t1a_143" class="t s1_143">
          Мэргэжлийн хөгжлийн бүлгээр ажиллана гэдэг нь багшлахуй, суралцахуйд{" "}
        </span>
        <span id="t1b_143" class="t s1_143">
          тулгамдсан асуудлаа ажлын байран дээрээ багаар хамтран шийдэх хамгийн{" "}
        </span>
        <span id="t1c_143" class="t s1_143">
          зөв гарц мөн бөгөөд багшлахуйн ажлаас тусдаа бие даасан ажил биш
          гэдгийг{" "}
        </span>
        <span id="t1d_143" class="t s1_143">
          багш нар ярилцлагын тэмдэглэлд дурьджээ. Мөн харилцан суралцах,
          хамтран{" "}
        </span>
        <span id="t1e_143" class="t s1_143">
          ажиллах соёлд суралцсан нь бидэнд маш их үр дүнтэй байлаа гэжээ..{" "}
        </span>
        <span id="t1f_143" class="t s4_143">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="t1g_143" class="t s4_143">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
