import React from "react";
export default function OneHundredSixtySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_166{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_166{left:142px;bottom:931px;}
#t3_166{left:143px;bottom:896px;letter-spacing:-0.22px;word-spacing:0.12px;}
#t4_166{left:143px;bottom:869px;letter-spacing:-0.14px;word-spacing:0.88px;}
#t5_166{left:143px;bottom:851px;letter-spacing:-0.13px;word-spacing:1px;}
#t6_166{left:147px;bottom:787px;letter-spacing:0.11px;word-spacing:0.12px;}
#t7_166{left:106px;bottom:738px;letter-spacing:0.09px;word-spacing:-0.76px;}
#t8_166{left:106px;bottom:719px;letter-spacing:-0.01px;word-spacing:0.14px;}
#t9_166{left:106px;bottom:692px;letter-spacing:0.09px;word-spacing:2.01px;}
#ta_166{left:106px;bottom:674px;letter-spacing:0.12px;word-spacing:3.83px;}
#tb_166{left:106px;bottom:656px;letter-spacing:0.08px;word-spacing:0.05px;}
#tc_166{left:106px;bottom:629px;letter-spacing:0.1px;word-spacing:0.51px;}
#td_166{left:106px;bottom:610px;letter-spacing:0.1px;word-spacing:1.34px;}
#te_166{left:106px;bottom:592px;letter-spacing:0.08px;word-spacing:0.06px;}
#tf_166{left:106px;bottom:565px;letter-spacing:0.1px;word-spacing:1.37px;}
#tg_166{left:106px;bottom:547px;letter-spacing:0.11px;word-spacing:-0.53px;}
#th_166{left:106px;bottom:528px;letter-spacing:0.08px;word-spacing:0.96px;}
#ti_166{left:106px;bottom:510px;letter-spacing:0.11px;word-spacing:0.03px;}
#tj_166{left:142px;bottom:431px;letter-spacing:0.18px;}
#tk_166{left:87px;bottom:398px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tl_166{left:87px;bottom:378px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tm_166{left:87px;bottom:345px;letter-spacing:-0.16px;word-spacing:4.6px;}
#tn_166{left:87px;bottom:325px;letter-spacing:-0.11px;word-spacing:-1.05px;}
#to_166{left:227px;bottom:325px;letter-spacing:-0.22px;word-spacing:-0.95px;}
#tp_166{left:87px;bottom:304px;letter-spacing:-0.15px;word-spacing:2.96px;}
#tq_166{left:87px;bottom:284px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tr_166{left:87px;bottom:251px;letter-spacing:-0.14px;word-spacing:-1.61px;}
#ts_166{left:396px;bottom:251px;letter-spacing:-0.13px;word-spacing:-1.61px;}
#tt_166{left:87px;bottom:231px;letter-spacing:-0.15px;word-spacing:2.73px;}
#tu_166{left:87px;bottom:211px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tv_166{left:87px;bottom:178px;letter-spacing:-0.15px;word-spacing:-2.42px;}
#tw_166{left:226px;bottom:178px;letter-spacing:-0.15px;word-spacing:-2.42px;}
#tx_166{left:87px;bottom:157px;letter-spacing:-0.15px;word-spacing:1.57px;}
#ty_166{left:87px;bottom:137px;letter-spacing:-0.14px;word-spacing:5.05px;}
#tz_166{left:87px;bottom:117px;letter-spacing:-0.15px;word-spacing:-0.04px;}
#t10_166{left:87px;bottom:97px;letter-spacing:-0.31px;word-spacing:0.21px;}

.s0_166{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_166{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_166{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_166{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_166{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_166{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s6_166{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts166" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg166" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="166/166.svg"
          type="image/svg+xml"
          id="pdf166"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_166" class="t s0_166">
          166{" "}
        </span>
        <span id="t2_166" class="t s1_166">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_166" class="t s2_166">
          Ашиглалт, зохион байгуулалт{" "}
        </span>
        <span id="t4_166" class="t s3_166">
          • Ахлах ангийн сурагчид цахим хичээлд хамрагдах боломж хомс{" "}
        </span>
        <span id="t5_166" class="t s3_166">
          • Номын сангийн үйл ажиллагаа хангалтгүй, баяжилт хийгддэггүй{" "}
        </span>
        <span id="t6_166" class="t s1_166">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t7_166" class="t s4_166">
          Орон нутагт хүсэлт гаргаж цахим танхим байгуулж, нөхөн хангалтын
          зардлаас{" "}
        </span>
        <span id="t8_166" class="t s4_166">
          таблет авсан.{" "}
        </span>
        <span id="t9_166" class="t s4_166">
          Хичээлийн жилийн эхэнд сургуулийн сурагчдад “Уншихаа боливол сэтгэхээ{" "}
        </span>
        <span id="ta_166" class="t s4_166">
          болино” аян өрнүүлж сурагч бүрийг сүүлийн үеийн уран зохиолын болон{" "}
        </span>
        <span id="tb_166" class="t s4_166">
          хүүхдийн ном бэлгэлэх зуны даалгавар өгсөн.{" "}
        </span>
        <span id="tc_166" class="t s4_166">
          “Орчноо номоор гоё”-1 сарын аянг зохион байгуулж сурагчдын нас
          сэтгэхүйд{" "}
        </span>
        <span id="td_166" class="t s4_166">
          тохирсон тэдний оюун бодлыг тэлэх номын хандивын аянг зохион байгуулж{" "}
        </span>
        <span id="te_166" class="t s4_166">
          Хэрлэн 4-р сургуулийн багш нар хамрагдсан.{" "}
        </span>
        <span id="tf_166" class="t s4_166">
          Хамт олон сургуулийн орчныг сайжруулан сурагчдын амрах, хичээл давтах,{" "}
        </span>
        <span id="tg_166" class="t s4_166">
          ном унших таатай орчныг бүрдүүлэн ажиллаж байна. “НОМ ХАНДИВЛАХ ҮЙЛ{" "}
        </span>
        <span id="th_166" class="t s4_166">
          АЖИЛЛАГАА”-г санаачлан үлгэрийн, уран зохиолын, арга зүйн гарын авлага{" "}
        </span>
        <span id="ti_166" class="t s4_166">
          зөвлөмж зэрэг нийт 2208 ширхэг номыг цуглууллаа.{" "}
        </span>
        <span id="tj_166" class="t s1_166">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tk_166" class="t s5_166">
          Сургуулийн зорилго, зорилтыг биелүүлэхэд чиглэсэн сургалтын{" "}
        </span>
        <span id="tl_166" class="t s5_166">
          орчныг хэрхэн бүрдүүлэх вэ?{" "}
        </span>
        <span id="tm_166" class="t s3_166">
          Сургуулийн зорилго, зорилтод нийцсэн сургалтын орчныг бүрдүүлэхийн{" "}
        </span>
        <span id="tn_166" class="t s3_166">
          тулд маш нарийн{" "}
        </span>
        <span id="to_166" class="t s3_166">
          төлөвлөлт, цогц арга барилыг шаарддаг. Иймээс сургууль,{" "}
        </span>
        <span id="tp_166" class="t s3_166">
          хамт олон сургалтын орчны асуудлуудыг шийдэхдээ дараах 5 чиглэлийг{" "}
        </span>
        <span id="tq_166" class="t s3_166">
          харгалзан үзэхийг санал болгож байна. Үүнд:{" "}
        </span>
        <span id="tr_166" class="t s6_166">
          Сургуулийн зорилго, зорилтыг ойлгох:{" "}
        </span>
        <span id="ts_166" class="t s3_166">
          Сургуулийн алсын хараа, хичээлийн{" "}
        </span>
        <span id="tt_166" class="t s3_166">
          жилийн зорилго, зорилт нь танай сургуулийн сургалтын орчинг төлөвлөх{" "}
        </span>
        <span id="tu_166" class="t s3_166">
          үндэс суурь болно.{" "}
        </span>
        <span id="tv_166" class="t s6_166">
          Оролцоог хангах:{" "}
        </span>
        <span id="tw_166" class="t s3_166">
          Сургуулийн зорилготой нийцсэн орчныг бүрдүүлэхийн тулд{" "}
        </span>
        <span id="tx_166" class="t s3_166">
          багш, сурагч, эцэг эх, асран хамгааагч, харгалзан дэмжигч
          удирдлагуудыг{" "}
        </span>
        <span id="ty_166" class="t s3_166">
          оролцуулах нь зүйтэй. Энэ нь тэдэнд ойлголтоо нэгтгэх, нэг зорилгын{" "}
        </span>
        <span id="tz_166" class="t s3_166">
          төлөө хамтарч ажиллах, санал санаачлага гаргах, дэмжих, баталгаажуулах{" "}
        </span>
        <span id="t10_166" class="t s3_166">
          боломжийг олгодог.{" "}
        </span>
      </div>
    </div>
  );
}
