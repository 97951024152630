import React from "react";
export default function OneHundredNinetyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_192{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_192{left:147px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_192{left:106px;bottom:868px;letter-spacing:0.08px;word-spacing:1.76px;}
#t4_192{left:106px;bottom:849px;letter-spacing:0.11px;word-spacing:1.29px;}
#t5_192{left:106px;bottom:831px;letter-spacing:0.11px;word-spacing:1.08px;}
#t6_192{left:106px;bottom:813px;letter-spacing:0.11px;word-spacing:2.28px;}
#t7_192{left:106px;bottom:794px;letter-spacing:0.11px;word-spacing:3.67px;}
#t8_192{left:106px;bottom:776px;letter-spacing:0.1px;word-spacing:0.54px;}
#t9_192{left:106px;bottom:758px;letter-spacing:0.12px;word-spacing:0.02px;}
#ta_192{left:421px;bottom:758px;}
#tb_192{left:187px;bottom:729px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tc_192{left:480px;bottom:729px;letter-spacing:-0.15px;word-spacing:0.02px;}
#td_192{left:429px;bottom:712px;letter-spacing:-0.16px;word-spacing:0.03px;}
#te_192{left:106px;bottom:629px;letter-spacing:0.1px;word-spacing:1.03px;}
#tf_192{left:106px;bottom:611px;letter-spacing:0.11px;word-spacing:6.92px;}
#tg_192{left:106px;bottom:592px;letter-spacing:0.09px;word-spacing:4.69px;}
#th_192{left:106px;bottom:574px;letter-spacing:0.06px;word-spacing:0.08px;}
#ti_192{left:309px;bottom:545px;letter-spacing:-0.13px;word-spacing:0.01px;}
#tj_192{left:491px;bottom:529px;letter-spacing:-0.14px;word-spacing:0.02px;}
#tk_192{left:106px;bottom:444px;letter-spacing:0.13px;word-spacing:4.53px;}
#tl_192{left:106px;bottom:426px;letter-spacing:0.11px;word-spacing:4.1px;}
#tm_192{left:106px;bottom:408px;letter-spacing:0.18px;word-spacing:5.59px;}
#tn_192{left:567px;bottom:408px;letter-spacing:0.1px;word-spacing:5.64px;}
#to_192{left:106px;bottom:389px;letter-spacing:0.1px;word-spacing:0.04px;}
#tp_192{left:187px;bottom:360px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tq_192{left:480px;bottom:360px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tr_192{left:429px;bottom:344px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ts_192{left:106px;bottom:256px;letter-spacing:0.1px;word-spacing:0.38px;}
#tt_192{left:106px;bottom:238px;letter-spacing:0.1px;word-spacing:1.66px;}
#tu_192{left:106px;bottom:220px;letter-spacing:0.1px;word-spacing:5.31px;}
#tv_192{left:106px;bottom:201px;letter-spacing:0.15px;}
#tw_192{left:187px;bottom:185px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tx_192{left:480px;bottom:185px;letter-spacing:-0.15px;word-spacing:0.02px;}
#ty_192{left:429px;bottom:169px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_192{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_192{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_192{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s3_192{font-size:15px;font-family:MogulArial-Italic_17h;color:#202124;}
.s4_192{font-size:14px;font-family:MogulArial-Italic_17h;color:#202124;}
.s5_192{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts192" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg192" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="192/192.svg"
          type="image/svg+xml"
          id="pdf192"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_192" class="t s0_192">
          192{" "}
        </span>
        <span id="t2_192" class="t s1_192">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_192" class="t s2_192">
          Сургуулийн удирдлагын багийн сургуулийн зорилгыг тодорхойлох, зорилгод{" "}
        </span>
        <span id="t4_192" class="t s2_192">
          хүрэх үйл ажиллагааг төлөвлөх, хэрэгжүүлэх бүхий л шатанд багш ажилтан{" "}
        </span>
        <span id="t5_192" class="t s2_192">
          сурагч, эцэг эх асран хамгаалагч, харгалзан дэмжигчид болон бусад
          төрийн{" "}
        </span>
        <span id="t6_192" class="t s2_192">
          болон ТББ-ийн оролцоог хангах, идэвхи санаачлагыг өрнүүлэх, сургуулийн{" "}
        </span>
        <span id="t7_192" class="t s2_192">
          зорилгод хүрэхэд бүх нийтээр түншлэн хамтран ажиллах арга хандлагын{" "}
        </span>
        <span id="t8_192" class="t s2_192">
          талаар мэдлэг ойлголтыг нэмэгдүүлсэн бөгөөд уг мэдлэг ойлголтыг
          практикт{" "}
        </span>
        <span id="t9_192" class="t s2_192">
          хэрэгжүүлж ажиллах эхлэлийг тавьж чадсан
        </span>
        <span id="ta_192" class="t s3_192">
          .{" "}
        </span>
        <span id="tb_192" class="t s4_192">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tc_192" class="t s5_192">
          сургуулийн менежментийг{" "}
        </span>
        <span id="td_192" class="t s5_192">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="te_192" class="t s2_192">
          Сургууль нь нутгийн зөвлөлтэй хамтран, ногоон байгууламж сэргээх,
          засвар{" "}
        </span>
        <span id="tf_192" class="t s2_192">
          үйлчилгээ хийх, ургамлыг хамгаалах, усны бохирдлоос сэргийлэх арга{" "}
        </span>
        <span id="tg_192" class="t s2_192">
          хэрэгсэлд хүүхдүүдийг сургах, сургуулийн гадаад орчныг аюулгүй таатай{" "}
        </span>
        <span id="th_192" class="t s2_192">
          байлгах, эрсдэл, аюулыг арилгахад хамтран ажилладаг.{" "}
        </span>
        <span id="ti_192" class="t s4_192">
          Эх сурвалж: Local Government and School Partnerships{" "}
        </span>
        <span id="tj_192" class="t s4_192">
          State Government Vectoria{" "}
        </span>
        <span id="tk_192" class="t s2_192">
          НТБ - ын төвийн багш нартай хамтран “Багшийн ёс зүй”-н манлайллын{" "}
        </span>
        <span id="tl_192" class="t s2_192">
          талаар багш нартай нээлттэй хэлэлцүүлэг өрнүүлж, БАГШИЙН ЁС ЗҮЙН{" "}
        </span>
        <span id="tm_192" class="t s2_192">
          МАНЛАЙЛЛЫН ТАЛААР ЖИШЭЭ – КЕЙС дээр ажиллаж,{" "}
        </span>
        <span id="tn_192" class="t s2_192">
          багш нарын{" "}
        </span>
        <span id="to_192" class="t s2_192">
          хандлагад нөлөө үзүүлэхүйц ажил зохион байгуулсан.{" "}
        </span>
        <span id="tp_192" class="t s4_192">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tq_192" class="t s5_192">
          сургуулийн менежментийг{" "}
        </span>
        <span id="tr_192" class="t s5_192">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ts_192" class="t s2_192">
          Хүүхэд ирээдүйн баялаг ТББ болон Лингорс ХХК хамтран зохион байгуулсан{" "}
        </span>
        <span id="tt_192" class="t s2_192">
          “GURU” 4 цагийн Сэтгэл зүйд суурилсан Анги удирдсан багшийн хөтөлбөрт{" "}
        </span>
        <span id="tu_192" class="t s2_192">
          БАЗАН - аас 17 багш хамрагдаж, сургалтын үйл ажиллагаандаа тусган{" "}
        </span>
        <span id="tv_192" class="t s2_192">
          ажиллаа.{" "}
        </span>
        <span id="tw_192" class="t s4_192">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tx_192" class="t s5_192">
          сургуулийн менежментийг{" "}
        </span>
        <span id="ty_192" class="t s5_192">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
