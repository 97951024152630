import React from "react";
export default function HundredTwenty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_120{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_120{left:142px;bottom:928px;letter-spacing:-0.03px;}
#t3_120{left:139px;bottom:900px;}
#t4_120{left:165px;bottom:900px;letter-spacing:-0.18px;word-spacing:2.03px;}
#t5_120{left:165px;bottom:879px;letter-spacing:-0.12px;word-spacing:1px;}
#t6_120{left:165px;bottom:859px;letter-spacing:-0.16px;}
#t7_120{left:139px;bottom:835px;}
#t8_120{left:165px;bottom:835px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t9_120{left:142px;bottom:771px;letter-spacing:0.11px;word-spacing:0.12px;}
#ta_120{left:106px;bottom:717px;letter-spacing:0.08px;}
#tb_120{left:128px;bottom:718px;letter-spacing:-0.18px;word-spacing:7.1px;}
#tc_120{left:128px;bottom:701px;letter-spacing:-0.16px;word-spacing:2.78px;}
#td_120{left:128px;bottom:685px;letter-spacing:-0.15px;word-spacing:3.26px;}
#te_120{left:128px;bottom:668px;letter-spacing:-0.16px;word-spacing:-1.05px;}
#tf_120{left:128px;bottom:652px;letter-spacing:-0.16px;word-spacing:0.96px;}
#tg_120{left:128px;bottom:635px;letter-spacing:-0.15px;word-spacing:3.94px;}
#th_120{left:128px;bottom:619px;letter-spacing:-0.13px;word-spacing:0.01px;}
#ti_120{left:106px;bottom:593px;letter-spacing:0.08px;}
#tj_120{left:128px;bottom:594px;letter-spacing:-0.16px;word-spacing:4.94px;}
#tk_120{left:128px;bottom:577px;letter-spacing:-0.16px;word-spacing:1.72px;}
#tl_120{left:128px;bottom:561px;letter-spacing:-0.16px;word-spacing:-0.67px;}
#tm_120{left:128px;bottom:544px;letter-spacing:-0.16px;word-spacing:-0.35px;}
#tn_120{left:128px;bottom:528px;letter-spacing:-0.15px;word-spacing:0.03px;}
#to_120{left:110px;bottom:427px;letter-spacing:-0.19px;word-spacing:0.03px;}
#tp_120{left:417px;bottom:427px;letter-spacing:-0.24px;word-spacing:0.08px;}
#tq_120{left:142px;bottom:361px;letter-spacing:0.18px;}
#tr_120{left:213px;bottom:334px;letter-spacing:-0.16px;word-spacing:0.04px;}

.s0_120{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_120{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_120{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_120{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s4_120{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts120" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg120" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="120/120.svg"
          type="image/svg+xml"
          id="pdf120"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_120" class="t s0_120">
          120{" "}
        </span>
        <span id="t2_120" class="t s1_120">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_120" class="t s2_120">
          -{" "}
        </span>
        <span id="t4_120" class="t s2_120">
          Үнэлгээг зөвхөн үр дүнгээ дүгнэх байдлаар ойлгодог, сургалтын{" "}
        </span>
        <span id="t5_120" class="t s2_120">
          үйл ажиллагааг дэмжих тасралтгүй цогц үйл ажиллагаа болохыг{" "}
        </span>
        <span id="t6_120" class="t s2_120">
          ойлгодоггүй{" "}
        </span>
        <span id="t7_120" class="t s2_120">
          -{" "}
        </span>
        <span id="t8_120" class="t s2_120">
          Үнэлгээний төлөвлөлт хийдэггүй, сурагчдад танилцуулдаггүй{" "}
        </span>
        <span id="t9_120" class="t s1_120">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="ta_120" class="t s3_120">
          1.{" "}
        </span>
        <span id="tb_120" class="t s4_120">
          “ХАМТДАА ХӨГЖИЖ-ҮРСЭЭ ДЭМЖЬЕ” сурагч, эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="tc_120" class="t s4_120">
          харгалзан дэмжигчид уулзалт зөвлөгөөнийг улирал бүрийн эхний долоо
          хоногт{" "}
        </span>
        <span id="td_120" class="t s4_120">
          хийж тухайн улиралд сурагчдын үзэж судлах агуулгын хүрээнд эцэг эх,
          асран{" "}
        </span>
        <span id="te_120" class="t s4_120">
          хамгаалагч, харгалзан дэмжигчид зөвлөгөө өгч, үнэлгээний аргачлалыг
          тайлбарлан{" "}
        </span>
        <span id="tf_120" class="t s4_120">
          сургалтыг хийдэг. Үүний үр дүнд эцэг эх, асран хамгаалагч, харгалзан
          дэмжигчид{" "}
        </span>
        <span id="tg_120" class="t s4_120">
          хүүхдээ гэрийн даалгавар хийхэд нь дэмжлэг үзүүлэх, хараа хяналт
          сайжирч{" "}
        </span>
        <span id="th_120" class="t s4_120">
          хүүхдээ ойлгох, дэмжих зэргээр эерэг үр дүн гарсан.{" "}
        </span>
        <span id="ti_120" class="t s3_120">
          2.{" "}
        </span>
        <span id="tj_120" class="t s4_120">
          Суралцахуйд суурилсан мэргэжлийн бүлгээр ажиллан “ИНТЕГРАЦИ ГЭРИЙН{" "}
        </span>
        <span id="tk_120" class="t s4_120">
          ДААЛГАВАР”-ыг туршин хэрэгжүүлсэн. Ингэсээр сурагчдын ачааллыг
          бууруулах,{" "}
        </span>
        <span id="tl_120" class="t s4_120">
          даалгаврыг сонирхолтой өгөөжтэй болгох, сурагчид хоорондоо болон эцэг
          эхтэйгээ{" "}
        </span>
        <span id="tm_120" class="t s4_120">
          хамтран ажиллах, өөрийгөө үнэлэх, боломжийг олгоод зогсохгүй багш нар
          хамтран{" "}
        </span>
        <span id="tn_120" class="t s4_120">
          ажиллах агуулга арга зүйгээ нэгтгэх боломж бүрдсэн өгөөжтэй ажил
          байлаа.{" "}
        </span>
        <span id="to_120" class="t s4_120">
          “ХАМТДАА ХӨГЖИЖ-ҮРСЭЭ ДЭМЖЬЕ”{" "}
        </span>
        <span id="tp_120" class="t s4_120">
          “ИНТЕГРАЦИ ГЭРИЙН ДААЛГАВАР”{" "}
        </span>
        <span id="tq_120" class="t s1_120">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tr_120" class="t s4_120">
          Зураг 3.16. Сургалт ба үнэлгээ хоорондын хамаарал{" "}
        </span>
      </div>
    </div>
  );
}
