import React from "react";
export default function EightyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_82{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_82{left:106px;bottom:920px;letter-spacing:0.1px;word-spacing:2.78px;}
#t3_82{left:106px;bottom:902px;letter-spacing:0.14px;word-spacing:10.14px;}
#t4_82{left:106px;bottom:884px;letter-spacing:0.07px;word-spacing:-1.62px;}
#t5_82{left:106px;bottom:865px;letter-spacing:0.07px;word-spacing:-0.78px;}
#t6_82{left:106px;bottom:847px;letter-spacing:0.09px;word-spacing:1.13px;}
#t7_82{left:106px;bottom:829px;letter-spacing:0.13px;word-spacing:1.49px;}
#t8_82{left:174px;bottom:829px;letter-spacing:0.08px;word-spacing:1.54px;}
#t9_82{left:106px;bottom:810px;letter-spacing:0.1px;word-spacing:1.44px;}
#ta_82{left:106px;bottom:792px;letter-spacing:0.06px;word-spacing:4.47px;}
#tb_82{left:106px;bottom:774px;letter-spacing:0.1px;word-spacing:0.91px;}
#tc_82{left:510px;bottom:774px;letter-spacing:0.15px;word-spacing:0.87px;}
#td_82{left:106px;bottom:755px;letter-spacing:0.13px;word-spacing:5.75px;}
#te_82{left:106px;bottom:737px;letter-spacing:0.11px;word-spacing:1.41px;}
#tf_82{left:106px;bottom:719px;letter-spacing:0.11px;word-spacing:3.93px;}
#tg_82{left:106px;bottom:700px;letter-spacing:0.12px;word-spacing:0.02px;}
#th_82{left:382px;bottom:680px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ti_82{left:235px;bottom:664px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tj_82{left:142px;bottom:556px;letter-spacing:0.18px;}
#tk_82{left:87px;bottom:510px;}
#tl_82{left:114px;bottom:510px;letter-spacing:0.2px;word-spacing:1.16px;}
#tm_82{left:114px;bottom:489px;letter-spacing:0.19px;word-spacing:-0.84px;}
#tn_82{left:114px;bottom:469px;letter-spacing:0.17px;word-spacing:-0.7px;}
#to_82{left:114px;bottom:449px;letter-spacing:0.17px;word-spacing:5.89px;}
#tp_82{left:114px;bottom:429px;letter-spacing:0.15px;word-spacing:0.08px;}
#tq_82{left:87px;bottom:404px;}
#tr_82{left:114px;bottom:404px;letter-spacing:-0.13px;word-spacing:0.48px;}
#ts_82{left:114px;bottom:384px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tt_82{left:114px;bottom:364px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tu_82{left:87px;bottom:340px;}
#tv_82{left:114px;bottom:340px;letter-spacing:-0.14px;word-spacing:-1.13px;}
#tw_82{left:114px;bottom:319px;letter-spacing:-0.21px;}
#tx_82{left:87px;bottom:295px;}
#ty_82{left:114px;bottom:295px;letter-spacing:-0.14px;word-spacing:5.64px;}
#tz_82{left:114px;bottom:275px;letter-spacing:-0.16px;word-spacing:2.03px;}
#t10_82{left:114px;bottom:255px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t11_82{left:87px;bottom:230px;}
#t12_82{left:114px;bottom:230px;letter-spacing:-0.16px;word-spacing:10.49px;}
#t13_82{left:114px;bottom:210px;letter-spacing:-0.15px;word-spacing:0.01px;}
#t14_82{left:114px;bottom:190px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t15_82{left:87px;bottom:165px;}
#t16_82{left:114px;bottom:165px;letter-spacing:-0.13px;word-spacing:11.58px;}
#t17_82{left:114px;bottom:145px;letter-spacing:-0.18px;}

.s0_82{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_82{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_82{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_82{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_82{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_82{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts82" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg82" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="82/82.svg"
          type="image/svg+xml"
          id="pdf82"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_82" class="t s0_82">
          82{" "}
        </span>
        <span id="t2_82" class="t s1_82">
          Сургуулийн хүүхэд хамгааллын багийн санаачилгаар сургуулийн хэмжээнд{" "}
        </span>
        <span id="t3_82" class="t s1_82">
          “Захиралдаа захидал бичье” захидлын хайрцгийг сургуулийн орчинд{" "}
        </span>
        <span id="t4_82" class="t s1_82">
          тулгамдаж буй асуудал, сургуулийн удирдлага болон багш ажилчдад
          хүргүүлэх{" "}
        </span>
        <span id="t5_82" class="t s1_82">
          талархал, багш, ажилчдын харилцаа хандлага, анги бүлэг, сурагчдын
          хооронд{" "}
        </span>
        <span id="t6_82" class="t s1_82">
          үүсэж буй зөрчил гэх мэт асуудлуудыг илрүүлэх зорилгоор ажиллуулсан.
          Үр{" "}
        </span>
        <span id="t7_82" class="t s1_82">
          дүнд нь{" "}
        </span>
        <span id="t8_82" class="t s1_82">
          сургуулийн орчинд тулгамдаж буй асуудал, сургуульд болон багш,{" "}
        </span>
        <span id="t9_82" class="t s1_82">
          ажилтнуудад хүргүүлэх талархал, багш, ажилчдын харилцаа хандлага, анги{" "}
        </span>
        <span id="ta_82" class="t s1_82">
          бүлэг, сурагчдын хооронд үүсэж буй зөрчил гэх мэт асуудлууд тодорхой{" "}
        </span>
        <span id="tb_82" class="t s1_82">
          хэмжээгээр илэрч, хариу арга хэмжээ авч ажиллажээ.{" "}
        </span>
        <span id="tc_82" class="t s1_82">
          Аяны хүрээнд ирсэн{" "}
        </span>
        <span id="td_82" class="t s1_82">
          захидал тус бүрийн хариу арга хэмжээг авч, ирүүлсэн захидлын дагуу{" "}
        </span>
        <span id="te_82" class="t s1_82">
          хэрэгжүүлж буй үйл ажиллагааг захидлын эзэнд хариу болгон хүргэх ажлыг{" "}
        </span>
        <span id="tf_82" class="t s1_82">
          зохион байгуулснаар энэхүү үйл ажиллагаа цаашид тогтвортой, идэвхтэй{" "}
        </span>
        <span id="tg_82" class="t s1_82">
          хэрэгжих юм.{" "}
        </span>
        <span id="th_82" class="t s2_82">
          ... аймгийн ... сумын ерөнхий боловсролын{" "}
        </span>
        <span id="ti_82" class="t s2_82">
          ... сургуулийн менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tj_82" class="t s3_82">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tk_82" class="t s4_82">
          •{" "}
        </span>
        <span id="tl_82" class="t s5_82">
          Багш сургуулийн ажилтан бүр сурагчийн санаа бодлыг анхааралтай{" "}
        </span>
        <span id="tm_82" class="t s5_82">
          сонсох, сонсож байгаагаа үг яриа үйлдлээрээ илэрхийлдэг, сурагчийн{" "}
        </span>
        <span id="tn_82" class="t s5_82">
          хувийн болон соёлын онцлог, ялгаатай байдалд хүндэтгэлтэй хандах,{" "}
        </span>
        <span id="to_82" class="t s5_82">
          хүүхдийг шийтгэх янз бүрийн арга хэрэгслээс зайлсхийх мэдлэг,{" "}
        </span>
        <span id="tp_82" class="t s5_82">
          чадвар, хандлагатай байх{" "}
        </span>
        <span id="tq_82" class="t s4_82">
          •{" "}
        </span>
        <span id="tr_82" class="t s5_82">
          Сурагчдын биед зохисгүйгээр хүрэх, гэмтээх, сэтгэл санааны дарамтад{" "}
        </span>
        <span id="ts_82" class="t s5_82">
          оруулж зохисгүй үг хэллэг хэрэглэж харилцах, үл хайхрахыг хориглосон{" "}
        </span>
        <span id="tt_82" class="t s5_82">
          дүрэм журмыг чанд дагаж мөрддөг байх{" "}
        </span>
        <span id="tu_82" class="t s4_82">
          •{" "}
        </span>
        <span id="tv_82" class="t s5_82">
          Сурагчдаас сэтгэл ханамжийн судалгаа авч үйл ажиллагаа хэрэгжүүлдэг{" "}
        </span>
        <span id="tw_82" class="t s5_82">
          байх{" "}
        </span>
        <span id="tx_82" class="t s4_82">
          •{" "}
        </span>
        <span id="ty_82" class="t s5_82">
          Сурагчаа сонсох өдрийг тогтмол болгож санал хүсэлтийн нээлттэй{" "}
        </span>
        <span id="tz_82" class="t s5_82">
          хайрцаг, итгэлтэй утас зэргийг ажиллуулж хэвшиж, хариу арга хэмжээ{" "}
        </span>
        <span id="t10_82" class="t s5_82">
          авдаг байх{" "}
        </span>
        <span id="t11_82" class="t s4_82">
          •{" "}
        </span>
        <span id="t12_82" class="t s5_82">
          Үе тэнгийн дээрэлхэлтийн тохиолдлыг таних, мэдээлэх талаарх{" "}
        </span>
        <span id="t13_82" class="t s5_82">
          ойлголт хандлагыг сурагч, багш, ажилтан, эцэг эхчүүдэд бий болгох үйл{" "}
        </span>
        <span id="t14_82" class="t s5_82">
          ажиллагааг зохион байгуулдаг байх{" "}
        </span>
        <span id="t15_82" class="t s4_82">
          •{" "}
        </span>
        <span id="t16_82" class="t s5_82">
          Сургууль, ангийн түвшинд дээрэлхэлтийн эсрэг дүрэм, журмыг{" "}
        </span>
        <span id="t17_82" class="t s5_82">
          боловсруулах{" "}
        </span>
      </div>
    </div>
  );
}
