import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";

export default function SideBar({ setPaperIndex, paperIndex }) {
  const menu = [
    { name: "Өмнөх үг ", paper: 3, pages: 1, path: "" },
    {
      name: "Товчилсон үгийн жагсаалт",
      paper: 8,
      pages: 1,
      path: "author",
    },
    { name: "Нэр томьёоны тайлбар", paper: 9, pages: 1, path: 1 },
  ];

  return (
    <List>
      {menu.map((element, index) => (
        <ListItem
          // sx={{
          //   backgroundColor:
          //     paperIndex === element.paper && "rgba(59,130,246,0.5)",
          // }}
          key={index}
          disablePadding
        >
          <a href={`#${element.paper}`} style={{ textDecoration: "none" }}>
            <ListItemButton
              onClick={() => setPaperIndex(element.paper)}
              variant="contained"
            >
              <Grid container spacing={2}>
                <Grid xs={10} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 500,
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={element.name}
                  />
                </Grid>
                <Grid xs={2} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={`(${element.paper})`}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </a>
        </ListItem>
      ))}
    </List>
  );
}
