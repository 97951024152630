import React from "react";
export default function OneHundredNinetySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_197{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_197{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t3_197{left:87px;bottom:912px;letter-spacing:-0.14px;word-spacing:6.69px;}
#t4_197{left:87px;bottom:892px;letter-spacing:-0.13px;word-spacing:-1.38px;}
#t5_197{left:87px;bottom:872px;letter-spacing:-0.15px;word-spacing:-1.07px;}
#t6_197{left:87px;bottom:852px;letter-spacing:-0.16px;word-spacing:4.18px;}
#t7_197{left:87px;bottom:831px;letter-spacing:-0.15px;word-spacing:2.87px;}
#t8_197{left:87px;bottom:811px;letter-spacing:-0.13px;word-spacing:2.61px;}
#t9_197{left:87px;bottom:791px;letter-spacing:-0.14px;word-spacing:8.01px;}
#ta_197{left:87px;bottom:771px;letter-spacing:-0.15px;word-spacing:8.32px;}
#tb_197{left:87px;bottom:751px;letter-spacing:-0.13px;word-spacing:2.87px;}
#tc_197{left:87px;bottom:731px;letter-spacing:-0.15px;word-spacing:4.33px;}
#td_197{left:87px;bottom:710px;letter-spacing:-0.13px;word-spacing:1.54px;}
#te_197{left:87px;bottom:690px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tf_197{left:87px;bottom:641px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tg_197{left:87px;bottom:612px;letter-spacing:-0.18px;word-spacing:2.17px;}
#th_197{left:87px;bottom:592px;letter-spacing:-0.18px;word-spacing:3.39px;}
#ti_197{left:87px;bottom:572px;letter-spacing:-0.14px;word-spacing:-0.35px;}
#tj_197{left:87px;bottom:552px;letter-spacing:-0.14px;word-spacing:1.85px;}
#tk_197{left:87px;bottom:532px;letter-spacing:-0.13px;word-spacing:3.81px;}
#tl_197{left:87px;bottom:512px;letter-spacing:-0.14px;word-spacing:6.34px;}
#tm_197{left:87px;bottom:491px;letter-spacing:-0.11px;word-spacing:0.4px;}
#tn_197{left:87px;bottom:471px;letter-spacing:-0.13px;word-spacing:-0.66px;}
#to_197{left:87px;bottom:451px;letter-spacing:-0.14px;word-spacing:3.52px;}
#tp_197{left:87px;bottom:431px;letter-spacing:-0.16px;word-spacing:4.04px;}
#tq_197{left:87px;bottom:411px;letter-spacing:-0.13px;word-spacing:-0.6px;}
#tr_197{left:87px;bottom:391px;letter-spacing:-0.17px;word-spacing:3.69px;}
#ts_197{left:263px;bottom:391px;letter-spacing:-0.15px;word-spacing:3.69px;}
#tt_197{left:87px;bottom:370px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tu_197{left:87px;bottom:337px;letter-spacing:-0.13px;word-spacing:0.02px;}
#tv_197{left:87px;bottom:313px;letter-spacing:-0.19px;word-spacing:1.63px;}
#tw_197{left:87px;bottom:293px;letter-spacing:-0.14px;word-spacing:-1.97px;}
#tx_197{left:87px;bottom:272px;letter-spacing:-0.16px;word-spacing:0.79px;}
#ty_197{left:87px;bottom:252px;letter-spacing:-0.16px;word-spacing:0.93px;}
#tz_197{left:87px;bottom:232px;letter-spacing:-0.13px;word-spacing:2.99px;}
#t10_197{left:87px;bottom:212px;letter-spacing:-0.17px;word-spacing:3.88px;}
#t11_197{left:87px;bottom:192px;letter-spacing:-0.12px;word-spacing:-2.09px;}
#t12_197{left:87px;bottom:172px;letter-spacing:-0.12px;word-spacing:0.08px;}
#t13_197{left:87px;bottom:151px;letter-spacing:-0.12px;word-spacing:0.61px;}
#t14_197{left:87px;bottom:131px;letter-spacing:-0.15px;word-spacing:1.6px;}

.s0_197{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_197{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_197{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts197" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg197" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="197/197.svg"
          type="image/svg+xml"
          id="pdf197"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_197" class="t s0_197">
          197{" "}
        </span>
        <span id="t2_197" class="t s1_197">
          Сургалт, үйл ажиллагааны төлөвлөлт хэрэгжилт{" "}
        </span>
        <span id="t3_197" class="t s2_197">
          Хамт олноороо хэлэлцэж тухайн хичээлийн жилдээ хүрэх боломжтой{" "}
        </span>
        <span id="t4_197" class="t s2_197">
          зорилго, зорилтоо тодорхойлсны дараа түүндээ үндэслэн хичээл,
          сургалтын{" "}
        </span>
        <span id="t5_197" class="t s2_197">
          үйл ажиллагаануудаа зураглах үе шатанд орно. Үүний тулд, эхлээд
          сургууль{" "}
        </span>
        <span id="t6_197" class="t s2_197">
          дээр явагдаж байгаа сургалтын үйл ажиллагаануудын чанарыг шалгаж,{" "}
        </span>
        <span id="t7_197" class="t s2_197">
          сургуулийнхаа сургалтын зорилго, зорилттой уялдуулан сайжруулах, цар{" "}
        </span>
        <span id="t8_197" class="t s2_197">
          хүрээг нь хумих, зарим тохиолдолд бүр хасах тухай эргэцүүлэх хэрэгтэй{" "}
        </span>
        <span id="t9_197" class="t s2_197">
          бөгөөд өөр хоорондоо уялдаа бүхий сургуулийн нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="ta_197" class="t s2_197">
          боловсруулах нь зүйтэй. Ихэнх сургуулийн хувьд хүүхэд хамгаалал,{" "}
        </span>
        <span id="tb_197" class="t s2_197">
          хичээлээс гадуурх үйл ажиллагаа, сонгон, дугуйлан секцийн гэх мэт өөр{" "}
        </span>
        <span id="tc_197" class="t s2_197">
          өөрийн зорилго бүхий салангад олон төлөвлөгөө боловсруулдаг байна.{" "}
        </span>
        <span id="td_197" class="t s2_197">
          Энэ нь сурагчийн ачааллыг нэмэгдүүлэх, үйл ажиллагааны чанар, идэвхи{" "}
        </span>
        <span id="te_197" class="t s2_197">
          оролцоог бууруулах зэрэг сөрөг үр дагавар үзүүлдэг байх талтай.{" "}
        </span>
        <span id="tf_197" class="t s1_197">
          Үр дүнгийн үнэлгээ болон сайжруулалт{" "}
        </span>
        <span id="tg_197" class="t s2_197">
          Тодорхой хугацаанд хичээл, сургалтын үйл ажиллагаа зохион байгуулсан{" "}
        </span>
        <span id="th_197" class="t s2_197">
          бол түүнийхээ үр дүнг үнэлэх нь шаардлагатай байдаг. Бид ямар ч үйл{" "}
        </span>
        <span id="ti_197" class="t s2_197">
          ажиллагаа хэрэгжүүлсэн бол түүнийгээ үнэлээд, сайжруулалттай холбох нь{" "}
        </span>
        <span id="tj_197" class="t s2_197">
          зүйтэй. Гэхдээ тэрхүү үнэлгээ нь “ Үнэлгээний төлөөх үнэлгээ”
          болчихвол{" "}
        </span>
        <span id="tk_197" class="t s2_197">
          багшид хий дэмий ажилласан мэт сэтгэгдэл үлдээхээс цаашгүй. Нэгэнт{" "}
        </span>
        <span id="tl_197" class="t s2_197">
          хүч хөдөлмөр, цаг зав зарцуулан үнэлгээ хийсэн тохиолдолд хичээл,{" "}
        </span>
        <span id="tm_197" class="t s2_197">
          сургалтын үйл ажиллагаа нь сурагчдад ямар ахиц, эерэг нөлөө үзүүлснийг{" "}
        </span>
        <span id="tn_197" class="t s2_197">
          бодитой, үнэн зөвөөр илрүүлж, цаашдын хичээл, сургалтын үйл ажиллагааг{" "}
        </span>
        <span id="to_197" class="t s2_197">
          сайжруулахад үр дүнтэй ашиглаад явах нь зөв. “Хичээл, сургалтын үйл{" "}
        </span>
        <span id="tp_197" class="t s2_197">
          ажиллагааг ямар хэлбэрээр зохион байгуулсан бэ?” гэдэгтэй холбоотой{" "}
        </span>
        <span id="tq_197" class="t s2_197">
          үнэлгээ, “сурагчдад ямар хөгжил, эерэг өөрчлөлт ажиглагдсан бэ?”
          гэдэгтэй{" "}
        </span>
        <span id="tr_197" class="t s2_197">
          холбоотой (outcome{" "}
        </span>
        <span id="ts_197" class="t s2_197">
          үнэлгээ)-г явуулж байхын тулд дараах мэдээллийг{" "}
        </span>
        <span id="tt_197" class="t s2_197">
          сургууль дотроо цуглуулж үнэлгээ хийж болох юм.{" "}
        </span>
        <span id="tu_197" class="t s1_197">
          Бүх талын оролцоог хангах{" "}
        </span>
        <span id="tv_197" class="t s2_197">
          Сургуулиуд шийдвэр гаргах, асуудал шийдвэрлэх, сургалт, үйл ажиллагаа{" "}
        </span>
        <span id="tw_197" class="t s2_197">
          зохион байгуулахдаа талуудын оролцоог хангах соёлыг хэвшүүлэх нь
          зүйтэй.{" "}
        </span>
        <span id="tx_197" class="t s2_197">
          Сургуулийн соёл гэдэг бол сургуулийн удирдлага, багш, сурагч, хамт
          олны{" "}
        </span>
        <span id="ty_197" class="t s2_197">
          нэгдмэл итгэл үнэмшил, үзэл санаа, үнэт зүйл, бахархал, хандлага
          хэмээх{" "}
        </span>
        <span id="tz_197" class="t s2_197">
          далд орших чанарууд, түүний тусгал, дадал зуршил, хэм хэмжээ, дүрэм{" "}
        </span>
        <span id="t10_197" class="t s2_197">
          журам, сахилга бат, эмх цэгцийг илтгэсэн цогц тогтолцоо юм. Тэгэхээр,{" "}
        </span>
        <span id="t11_197" class="t s2_197">
          “Хичээл зүтгэл хэчнээн гаргалаа ч сайшаагдахгүй” “Юу ч хийж, яаж ч
          хичээсэн{" "}
        </span>
        <span id="t12_197" class="t s2_197">
          үр дүнгүй”... гэх мэт хүнд нөхцөл байдалд итгэл найдвараа алдсан
          багшийг{" "}
        </span>
        <span id="t13_197" class="t s2_197">
          дэмжиж “Юу ч болсон хийгээд үзье”, “Хийгээд үзэхээр сурагчдад өөрчлөлт{" "}
        </span>
        <span id="t14_197" class="t s2_197">
          гарч байна шүү” гэж урагш тэмүүлж чаддаг болгох нь менежментийн арга{" "}
        </span>
      </div>
    </div>
  );
}
