import React from "react";
export default function FourtyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_48{left:87px;bottom:58px;letter-spacing:-0.12px;}
#t2_48{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_48{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t4_48{left:87px;bottom:908px;letter-spacing:-0.16px;word-spacing:6.71px;}
#t5_48{left:87px;bottom:888px;letter-spacing:-0.17px;word-spacing:1.97px;}
#t6_48{left:87px;bottom:867px;letter-spacing:-0.18px;word-spacing:-0.03px;}
#t7_48{left:87px;bottom:847px;letter-spacing:-0.15px;word-spacing:3.72px;}
#t8_48{left:87px;bottom:827px;letter-spacing:-0.19px;word-spacing:-0.25px;}
#t9_48{left:87px;bottom:807px;letter-spacing:-0.14px;word-spacing:-0.52px;}
#ta_48{left:87px;bottom:787px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tb_48{left:87px;bottom:754px;letter-spacing:-0.15px;word-spacing:1.01px;}
#tc_48{left:87px;bottom:733px;letter-spacing:-0.15px;word-spacing:4.53px;}
#td_48{left:87px;bottom:713px;letter-spacing:-0.19px;word-spacing:6.13px;}
#te_48{left:87px;bottom:693px;letter-spacing:-0.13px;word-spacing:7.93px;}
#tf_48{left:87px;bottom:673px;letter-spacing:-0.16px;word-spacing:9.31px;}
#tg_48{left:87px;bottom:653px;letter-spacing:-0.14px;word-spacing:0.11px;}
#th_48{left:87px;bottom:633px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_48{left:87px;bottom:601px;letter-spacing:-0.16px;word-spacing:10.48px;}
#tj_48{left:87px;bottom:583px;letter-spacing:-0.13px;}
#tk_48{left:98px;bottom:557px;letter-spacing:0.11px;}
#tl_48{left:98px;bottom:542px;letter-spacing:0.08px;}
#tm_48{left:98px;bottom:528px;letter-spacing:0.02px;word-spacing:0.1px;}
#tn_48{left:215px;bottom:557px;letter-spacing:0.11px;}
#to_48{left:215px;bottom:542px;letter-spacing:0.08px;}
#tp_48{left:215px;bottom:528px;letter-spacing:0.02px;word-spacing:0.1px;}
#tq_48{left:332px;bottom:557px;letter-spacing:0.11px;}
#tr_48{left:332px;bottom:542px;letter-spacing:0.08px;}
#ts_48{left:332px;bottom:528px;letter-spacing:0.02px;word-spacing:0.1px;}
#tt_48{left:449px;bottom:557px;letter-spacing:0.11px;}
#tu_48{left:449px;bottom:542px;letter-spacing:0.08px;}
#tv_48{left:449px;bottom:528px;letter-spacing:0.02px;word-spacing:0.1px;}
#tw_48{left:566px;bottom:557px;letter-spacing:0.11px;}
#tx_48{left:566px;bottom:542px;letter-spacing:0.08px;}
#ty_48{left:566px;bottom:528px;letter-spacing:0.02px;word-spacing:0.1px;}

.s0_48{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_48{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_48{font-size:17px;font-family:MogulArial_16s;color:#8E2577;}
.s3_48{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_48{font-size:17px;font-family:ArialMT_1lm;color:#963E97;}
.s5_48{font-size:12px;font-family:MogulArial_16s;color:#B812BD;}
.s6_48{font-size:12px;font-family:MogulArial-Bold_179;color:#B812BD;}`}
      </style>

      <style id="fonts48" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg48" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="48/48.svg"
          type="image/svg+xml"
          id="pdf48"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_48" class="t s1_48">
          48{" "}
        </span>
        <span id="t3_48" class="t s2_48">
          Сургуулийн өөрийн үнэлгээний мэдээлэл цуглуулах хэрэгсэл{" "}
        </span>
        <span id="t4_48" class="t s3_48">
          Та бүхэнд сургууль дээрээ өөрийн үнэлгээ хийхтэй холбоотой зарим{" "}
        </span>
        <span id="t5_48" class="t s3_48">
          хэрэгслийг санал болгож байна. Үнэлгээний мэдээлэл цуглуулах хэрэгсэл{" "}
        </span>
        <span id="t6_48" class="t s3_48">
          гэдэг нь шаардлага хангасан, бодит, үнэн зөв мэдээллийг олох,
          илрүүлэхэд{" "}
        </span>
        <span id="t7_48" class="t s3_48">
          туслах асуулга, ажиглалт болон ярилцлагын, тэмдэглэлийн хуудас байх{" "}
        </span>
        <span id="t8_48" class="t s3_48">
          юм. Тухайлбал, асуултууд бүхий асуулга, хичээл ажиглах үзүүлэлт,
          үнэлгээ,{" "}
        </span>
        <span id="t9_48" class="t s3_48">
          тэмдэглэл хийх талбар бүхий ажиглалтын хуудас, бүртгэх тэмдэглэх
          заавар{" "}
        </span>
        <span id="ta_48" class="t s3_48">
          бүхий маягт, чиглүүлэх асуулт бүхий ярилцлагын хөтөч зэрэг болно.{" "}
        </span>
        <span id="tb_48" class="t s3_48">
          Та бүхэнд танилцуулж буй үнэлгээний хэрэгсэл нь зарим улс оронд болон{" "}
        </span>
        <span id="tc_48" class="t s3_48">
          манай улсад туршигдаж, хэрэглэгдэж үр дүнгээ өгч байсныг тэмдэглэж{" "}
        </span>
        <span id="td_48" class="t s3_48">
          байна. Та удирдлагын багтайгаа зөвшилцөн эдгээр санал болгож буй{" "}
        </span>
        <span id="te_48" class="t s3_48">
          хувилбаруудаас өөрийн сургуулийн хөгжлийн түвшин, онцлог зэргийг{" "}
        </span>
        <span id="tf_48" class="t s3_48">
          харгалзан сонгож, өөрчилж сайжруулан хэрэглэж болно. Үнэлгээний{" "}
        </span>
        <span id="tg_48" class="t s3_48">
          шалгуур үзүүлэлт болон бодомж, асуулт зэрэг нь зорилгоосоо хамаарч өөр{" "}
        </span>
        <span id="th_48" class="t s3_48">
          өөр байдгийг сануулах юун.{" "}
        </span>
        <span id="ti_48" class="t s4_48">
          Сургуулийн өөрийн үнэлгээний хуудсыг дараах линкээр татаж авч{" "}
        </span>
        <span id="tj_48" class="t s4_48">
          ашиглаарай.{" "}
        </span>
        <span id="tk_48" class="t s5_48">
          Өөрийн{" "}
        </span>
        <span id="tl_48" class="t s5_48">
          үнэлгээний{" "}
        </span>
        <span id="tm_48" class="t s6_48">
          хэрэгсэл 1{" "}
        </span>
        <span id="tn_48" class="t s5_48">
          Өөрийн{" "}
        </span>
        <span id="to_48" class="t s5_48">
          үнэлгээний{" "}
        </span>
        <span id="tp_48" class="t s6_48">
          хэрэгсэл 2{" "}
        </span>
        <span id="tq_48" class="t s5_48">
          Өөрийн{" "}
        </span>
        <span id="tr_48" class="t s5_48">
          үнэлгээний{" "}
        </span>
        <span id="ts_48" class="t s6_48">
          хэрэгсэл 3{" "}
        </span>
        <span id="tt_48" class="t s5_48">
          Өөрийн{" "}
        </span>
        <span id="tu_48" class="t s5_48">
          үнэлгээний{" "}
        </span>
        <span id="tv_48" class="t s6_48">
          хэрэгсэл 4{" "}
        </span>
        <span id="tw_48" class="t s5_48">
          Өөрийн{" "}
        </span>
        <span id="tx_48" class="t s5_48">
          үнэлгээний{" "}
        </span>
        <span id="ty_48" class="t s6_48">
          хэрэгсэл 5{" "}
        </span>
      </div>
    </div>
  );
}
