import React from "react";
export default function OneHundredNinetyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_195{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_195{left:91px;bottom:818px;}
#t3_195{left:122px;bottom:885px;letter-spacing:-0.26px;word-spacing:0.14px;}
#t4_195{left:122px;bottom:866px;letter-spacing:-0.14px;}
#t5_195{left:240px;bottom:928px;letter-spacing:-0.25px;}
#t6_195{left:314px;bottom:928px;letter-spacing:-0.19px;word-spacing:4.57px;}
#t7_195{left:472px;bottom:928px;letter-spacing:-0.17px;word-spacing:4.54px;}
#t8_195{left:240px;bottom:910px;letter-spacing:-0.18px;}
#t9_195{left:282px;bottom:910px;letter-spacing:-0.18px;}
#ta_195{left:390px;bottom:910px;letter-spacing:-0.16px;}
#tb_195{left:457px;bottom:910px;letter-spacing:-0.15px;word-spacing:0.27px;}
#tc_195{left:240px;bottom:891px;letter-spacing:-0.18px;word-spacing:-0.05px;}
#td_195{left:240px;bottom:873px;letter-spacing:-0.15px;word-spacing:-0.46px;}
#te_195{left:240px;bottom:855px;letter-spacing:-0.18px;word-spacing:8.78px;}
#tf_195{left:240px;bottom:836px;letter-spacing:-0.16px;word-spacing:8.2px;}
#tg_195{left:240px;bottom:818px;letter-spacing:-0.17px;word-spacing:2.86px;}
#th_195{left:240px;bottom:800px;letter-spacing:-0.16px;word-spacing:-0.32px;}
#ti_195{left:240px;bottom:781px;letter-spacing:-0.16px;word-spacing:4.72px;}
#tj_195{left:240px;bottom:763px;letter-spacing:-0.1px;}
#tk_195{left:284px;bottom:763px;letter-spacing:-0.18px;word-spacing:1.42px;}
#tl_195{left:586px;bottom:763px;letter-spacing:-0.12px;word-spacing:1.35px;}
#tm_195{left:240px;bottom:745px;letter-spacing:-0.15px;}
#tn_195{left:309px;bottom:745px;letter-spacing:-0.18px;word-spacing:-1.02px;}
#to_195{left:572px;bottom:745px;letter-spacing:-0.12px;word-spacing:-1.08px;}
#tp_195{left:240px;bottom:726px;letter-spacing:-0.14px;word-spacing:5.04px;}
#tq_195{left:354px;bottom:726px;letter-spacing:-0.19px;word-spacing:5.09px;}
#tr_195{left:240px;bottom:708px;letter-spacing:-0.18px;word-spacing:0.05px;}
#ts_195{left:91px;bottom:524px;}
#tt_195{left:122px;bottom:623px;letter-spacing:-0.18px;}
#tu_195{left:122px;bottom:604px;letter-spacing:-0.13px;}
#tv_195{left:122px;bottom:586px;letter-spacing:-0.12px;}
#tw_195{left:122px;bottom:568px;letter-spacing:-0.17px;}
#tx_195{left:122px;bottom:549px;letter-spacing:-0.16px;word-spacing:0.04px;}
#ty_195{left:122px;bottom:531px;letter-spacing:-0.16px;}
#tz_195{left:122px;bottom:513px;letter-spacing:-0.14px;}
#t10_195{left:122px;bottom:494px;letter-spacing:-0.19px;}
#t11_195{left:240px;bottom:666px;letter-spacing:-0.15px;word-spacing:6.26px;}
#t12_195{left:240px;bottom:647px;letter-spacing:-0.18px;word-spacing:8.87px;}
#t13_195{left:240px;bottom:629px;letter-spacing:-0.17px;word-spacing:8.41px;}
#t14_195{left:240px;bottom:611px;letter-spacing:-0.14px;word-spacing:-0.52px;}
#t15_195{left:240px;bottom:592px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t16_195{left:240px;bottom:574px;letter-spacing:-0.16px;word-spacing:2.47px;}
#t17_195{left:240px;bottom:556px;letter-spacing:-0.13px;}
#t18_195{left:298px;bottom:556px;letter-spacing:-0.23px;word-spacing:0.11px;}
#t19_195{left:240px;bottom:529px;letter-spacing:-0.17px;word-spacing:5.49px;}
#t1a_195{left:240px;bottom:510px;letter-spacing:-0.16px;word-spacing:4.41px;}
#t1b_195{left:628px;bottom:510px;letter-spacing:-0.17px;}
#t1c_195{left:240px;bottom:492px;letter-spacing:-0.15px;word-spacing:-0.45px;}
#t1d_195{left:240px;bottom:474px;letter-spacing:-0.19px;word-spacing:0.57px;}
#t1e_195{left:240px;bottom:455px;letter-spacing:-0.19px;word-spacing:0.25px;}
#t1f_195{left:240px;bottom:437px;letter-spacing:-0.15px;word-spacing:1.18px;}
#t1g_195{left:240px;bottom:419px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1h_195{left:87px;bottom:348px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1i_195{left:100px;bottom:302px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1j_195{left:176px;bottom:302px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1k_195{left:100px;bottom:274px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1l_195{left:176px;bottom:274px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1m_195{left:100px;bottom:245px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1n_195{left:176px;bottom:245px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1o_195{left:100px;bottom:217px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1p_195{left:176px;bottom:217px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1q_195{left:176px;bottom:198px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1r_195{left:100px;bottom:170px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1s_195{left:176px;bottom:170px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1t_195{left:211px;bottom:152px;}
#t1u_195{left:231px;bottom:152px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1v_195{left:211px;bottom:133px;}
#t1w_195{left:231px;bottom:133px;letter-spacing:0.1px;word-spacing:0.04px;}

.s0_195{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_195{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_195{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_195{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_195{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts195" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg195" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="195/195.svg"
          type="image/svg+xml"
          id="pdf195"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_195" class="t s0_195">
          195{" "}
        </span>
        <span id="t2_195" class="t s1_195">
          4{" "}
        </span>
        <span id="t3_195" class="t s1_195">
          Удирдлагын баг{" "}
        </span>
        <span id="t4_195" class="t s1_195">
          чадавхжих{" "}
        </span>
        <span id="t5_195" class="t s1_195">
          Нэгдмэл,{" "}
        </span>
        <span id="t6_195" class="t s1_195">
          Дотоод ардчилалтай,{" "}
        </span>
        <span id="t7_195" class="t s1_195">
          Эрх мэдлийн хуваарилалттай{" "}
        </span>
        <span id="t8_195" class="t s1_195">
          байх,{" "}
        </span>
        <span id="t9_195" class="t s1_195">
          Хариуцлагатай,{" "}
        </span>
        <span id="ta_195" class="t s1_195">
          Зарчимч,{" "}
        </span>
        <span id="tb_195" class="t s1_195">
          Манлайлал сайтай байх, Онолын{" "}
        </span>
        <span id="tc_195" class="t s1_195">
          мэдлэг, заах арга зүй,хариуцлага, санаачлага, харилцаа хандлагын{" "}
        </span>
        <span id="td_195" class="t s1_195">
          хувьд багш нараас илүү өндөр түвшинд байх, Сургуулийн бодлогын{" "}
        </span>
        <span id="te_195" class="t s1_195">
          баримт бичгүүдийг боловсруулах, хамт олноор хэлэлцүүлэх,{" "}
        </span>
        <span id="tf_195" class="t s1_195">
          саналыг нь тусган албажуулах, тэдгээрийг хэрэгжүүлэх, зөв{" "}
        </span>
        <span id="tg_195" class="t s1_195">
          төлөвлөх, оновчтой менежмент хийх, Сургуулийн үйл ажиллагаа{" "}
        </span>
        <span id="th_195" class="t s1_195">
          бүрт сайн зохион байгуулалт хийх, Хүн бүрийн оролцоог чухалчлах,{" "}
        </span>
        <span id="ti_195" class="t s1_195">
          Бүтээлч сэтгэлгээг хөгжүүлэх, Нөхцөл байдлын дүн шинжилгээ{" "}
        </span>
        <span id="tj_195" class="t s1_195">
          хийх,{" "}
        </span>
        <span id="tk_195" class="t s1_195">
          Оновчтой шийдвэр гаргах чадварт суралцах,{" "}
        </span>
        <span id="tl_195" class="t s1_195">
          Ямагт хүнийг{" "}
        </span>
        <span id="tm_195" class="t s1_195">
          эрхэмлэх,{" "}
        </span>
        <span id="tn_195" class="t s1_195">
          Нийгмийн асуудалд санаачлагатай байх,{" "}
        </span>
        <span id="to_195" class="t s1_195">
          Хүний хөгжлийг{" "}
        </span>
        <span id="tp_195" class="t s1_195">
          дэмжигч байх,{" "}
        </span>
        <span id="tq_195" class="t s1_195">
          Сургалтын боломжит таатай орчин бүрдүүлэх,{" "}
        </span>
        <span id="tr_195" class="t s1_195">
          Хурдтай, шаргуу ажиллах{" "}
        </span>
        <span id="ts_195" class="t s1_195">
          5{" "}
        </span>
        <span id="tt_195" class="t s1_195">
          Сургалтын{" "}
        </span>
        <span id="tu_195" class="t s1_195">
          хөтөлбөрийн{" "}
        </span>
        <span id="tv_195" class="t s1_195">
          хэрэгжилтийг{" "}
        </span>
        <span id="tw_195" class="t s1_195">
          сайжруулах,{" "}
        </span>
        <span id="tx_195" class="t s1_195">
          сургуулийн үйл{" "}
        </span>
        <span id="ty_195" class="t s1_195">
          ажиллагааны{" "}
        </span>
        <span id="tz_195" class="t s1_195">
          менежментийг{" "}
        </span>
        <span id="t10_195" class="t s1_195">
          сайжруулах{" "}
        </span>
        <span id="t11_195" class="t s1_195">
          Бид төлөвлөж, хэрэгжүүлж, үр дүнгээ тооцдог ч Төлөвлөгөөг{" "}
        </span>
        <span id="t12_195" class="t s1_195">
          цогцоор нь хийдэггүй, хэрэгжихүйц, оновчтой гол ажлуудаа{" "}
        </span>
        <span id="t13_195" class="t s1_195">
          төлөвлөхдөө учир дутагдалтай, Хэрэгжүүлэхдээ сайн зохион{" "}
        </span>
        <span id="t14_195" class="t s1_195">
          байгуулалт хийж, ажил бүрийг эзэнжүүлдэггүй, Үр дүнгээ тооцохдоо{" "}
        </span>
        <span id="t15_195" class="t s1_195">
          зорилго зорилтууд биелэгдсэн эсэхэд анхаардаггүй, нэгтгэл анализ{" "}
        </span>
        <span id="t16_195" class="t s1_195">
          харьцуулалт хийдэггүй, гүйцэтгэл бүртээ үнэлгээ өгдөггүй бөгөөд{" "}
        </span>
        <span id="t17_195" class="t s1_195">
          цаашид{" "}
        </span>
        <span id="t18_195" class="t s1_195">
          сайжруулалт хийхийг орхигдуулдаг.{" "}
        </span>
        <span id="t19_195" class="t s1_195">
          Иймээс зохион байгуулсан ажил бүрийн гүйцэтгэлийн үр дүнг{" "}
        </span>
        <span id="t1a_195" class="t s1_195">
          тооцохдоо цаашид хэрхэн сайжруулах, яаж илүү сайн{" "}
        </span>
        <span id="t1b_195" class="t s1_195">
          зохион{" "}
        </span>
        <span id="t1c_195" class="t s1_195">
          байгуулах, яавал үр дүнг дээшлүүлж болох талаар хэлэлцэн тухайн{" "}
        </span>
        <span id="t1d_195" class="t s1_195">
          ажлын зохион байгуулалт, хэрэгжилтийг гүйцэтгэх арга хэлбэр, хүн{" "}
        </span>
        <span id="t1e_195" class="t s1_195">
          бүрийн оролцоо, үүрэг, хариуцлага, гүйцэтгэл, үнэлгээг сайжруулах{" "}
        </span>
        <span id="t1f_195" class="t s1_195">
          дараагийн төлөвлөгөөг боловсруулан хэрэгжүүлж үр дүнгээ дахин{" "}
        </span>
        <span id="t1g_195" class="t s1_195">
          тооцож, дараагийн сайжруулалтыг хийж байхыг зөвлөж байна{" "}
        </span>
        <span id="t1h_195" class="t s2_195">
          Хяналт шинжилгээ, үнэлгээг дараах 5 алхамтай хийнэ.{" "}
        </span>
        <span id="t1i_195" class="t s3_195">
          Алхам 1{" "}
        </span>
        <span id="t1j_195" class="t s3_195">
          Хяналт шинжилгээ, үнэлгээ хийх хүрээгээ тодорхойлох{" "}
        </span>
        <span id="t1k_195" class="t s3_195">
          Алхам 2{" "}
        </span>
        <span id="t1l_195" class="t s3_195">
          Үнэлгээ хийх хэрэгслээ боловсруулах{" "}
        </span>
        <span id="t1m_195" class="t s3_195">
          Алхам 3{" "}
        </span>
        <span id="t1n_195" class="t s3_195">
          Үнэлгээг хэн, хэзээ хийх төлөвлөгөө гаргах{" "}
        </span>
        <span id="t1o_195" class="t s3_195">
          Алхам 4{" "}
        </span>
        <span id="t1p_195" class="t s3_195">
          Мэдээлэл цуглуулах, боловсруулж, нэгтгэх, баримтад дүн шинжилгээ{" "}
        </span>
        <span id="t1q_195" class="t s3_195">
          хийх, үр дүн гаргах{" "}
        </span>
        <span id="t1r_195" class="t s3_195">
          Алхам 5{" "}
        </span>
        <span id="t1s_195" class="t s3_195">
          Тайлагнах, сайжруулах арга замаа тодорхойлох{" "}
        </span>
        <span id="t1t_195" class="t s4_195">
          •{" "}
        </span>
        <span id="t1u_195" class="t s3_195">
          Хэн хэнд тайлагнах вэ?{" "}
        </span>
        <span id="t1v_195" class="t s4_195">
          •{" "}
        </span>
        <span id="t1w_195" class="t s3_195">
          Хэрхэн сайжруулах вэ?{" "}
        </span>
      </div>
    </div>
  );
}
