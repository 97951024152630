import React from "react";
export default function TwentySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_26{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_26{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_26{left:146px;bottom:945px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t4_26{left:97px;bottom:905px;letter-spacing:-0.18px;word-spacing:0.02px;}
#t5_26{left:97px;bottom:892px;letter-spacing:-0.17px;word-spacing:0.02px;}
#t6_26{left:97px;bottom:880px;letter-spacing:-0.18px;word-spacing:0.03px;}
#t7_26{left:97px;bottom:862px;letter-spacing:-0.18px;word-spacing:0.02px;}
#t8_26{left:97px;bottom:850px;letter-spacing:-0.2px;word-spacing:0.05px;}
#t9_26{left:97px;bottom:832px;letter-spacing:-0.19px;word-spacing:0.03px;}
#ta_26{left:97px;bottom:820px;letter-spacing:-0.16px;word-spacing:0.01px;}
#tb_26{left:97px;bottom:807px;letter-spacing:-0.21px;}
#tc_26{left:97px;bottom:790px;letter-spacing:-0.18px;word-spacing:0.02px;}
#td_26{left:97px;bottom:777px;letter-spacing:-0.17px;word-spacing:0.03px;}
#te_26{left:97px;bottom:764px;letter-spacing:-0.17px;word-spacing:0.01px;}
#tf_26{left:520px;bottom:905px;letter-spacing:-0.19px;word-spacing:0.02px;}
#tg_26{left:520px;bottom:888px;letter-spacing:-0.16px;word-spacing:0.01px;}
#th_26{left:520px;bottom:875px;letter-spacing:-0.17px;word-spacing:0.02px;}
#ti_26{left:520px;bottom:858px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#tj_26{left:520px;bottom:845px;letter-spacing:-0.17px;word-spacing:0.03px;}
#tk_26{left:520px;bottom:832px;letter-spacing:-0.17px;word-spacing:0.02px;}
#tl_26{left:520px;bottom:820px;letter-spacing:-0.22px;word-spacing:0.07px;}
#tm_26{left:520px;bottom:807px;letter-spacing:-0.18px;word-spacing:0.02px;}
#tn_26{left:520px;bottom:790px;letter-spacing:-0.15px;}
#to_26{left:520px;bottom:777px;letter-spacing:-0.24px;word-spacing:0.09px;}
#tp_26{left:520px;bottom:764px;letter-spacing:-0.17px;word-spacing:0.03px;}
#tq_26{left:520px;bottom:751px;letter-spacing:-0.15px;}
#tr_26{left:235.2px;bottom:708.2px;letter-spacing:-0.17px;}
#ts_26{left:282.6px;bottom:781.1px;letter-spacing:-0.28px;}
#tt_26{left:87px;bottom:544px;letter-spacing:-0.15px;}
#tu_26{left:179px;bottom:544px;letter-spacing:-0.07px;}
#tv_26{left:221px;bottom:544px;letter-spacing:-0.16px;}
#tw_26{left:319px;bottom:544px;letter-spacing:-0.15px;}
#tx_26{left:436px;bottom:544px;letter-spacing:-0.21px;}
#ty_26{left:539px;bottom:544px;letter-spacing:-0.09px;}
#tz_26{left:606px;bottom:544px;letter-spacing:-0.17px;}
#t10_26{left:87px;bottom:523px;letter-spacing:-0.12px;word-spacing:0.5px;}
#t11_26{left:87px;bottom:503px;letter-spacing:-0.15px;word-spacing:-1.32px;}
#t12_26{left:87px;bottom:483px;letter-spacing:-0.14px;word-spacing:0.21px;}
#t13_26{left:87px;bottom:463px;letter-spacing:-0.12px;word-spacing:1.29px;}
#t14_26{left:87px;bottom:443px;letter-spacing:-0.14px;word-spacing:2.78px;}
#t15_26{left:87px;bottom:423px;letter-spacing:-0.26px;word-spacing:0.16px;}
#t16_26{left:87px;bottom:389px;letter-spacing:-0.12px;word-spacing:3.95px;}
#t17_26{left:87px;bottom:369px;letter-spacing:-0.13px;word-spacing:3.24px;}
#t18_26{left:87px;bottom:349px;letter-spacing:-0.14px;word-spacing:3.74px;}
#t19_26{left:87px;bottom:329px;letter-spacing:-0.15px;word-spacing:-0.29px;}
#t1a_26{left:87px;bottom:309px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t1b_26{left:87px;bottom:276px;letter-spacing:-0.12px;word-spacing:-1.87px;}
#t1c_26{left:87px;bottom:255px;letter-spacing:-0.14px;word-spacing:0.76px;}
#t1d_26{left:87px;bottom:235px;letter-spacing:-0.15px;word-spacing:5.36px;}
#t1e_26{left:87px;bottom:215px;letter-spacing:-0.18px;word-spacing:4.93px;}
#t1f_26{left:87px;bottom:195px;letter-spacing:-0.17px;word-spacing:4.72px;}
#t1g_26{left:87px;bottom:175px;letter-spacing:-0.15px;word-spacing:1.99px;}
#t1h_26{left:87px;bottom:155px;letter-spacing:-0.14px;word-spacing:1.24px;}
#t1i_26{left:87px;bottom:134px;letter-spacing:-0.12px;word-spacing:-0.61px;}
#t1j_26{left:87px;bottom:114px;letter-spacing:-0.13px;word-spacing:-1.14px;}

.s0_26{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_26{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_26{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_26{font-size:11px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_26{font-size:11px;font-family:MogulArial_16s;color:#000;}
.s5_26{font-size:11px;font-family:MogulArial-Bold_179;color:#000;}
.s6_26{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_26{transform:matrix(0.544,-0.84,0.84,0.54,0,0);}`}
      </style>
      <style id="fonts26" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg26" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="26/26.svg"
          type="image/svg+xml"
          id="pdf26"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_26" class="t s1_26">
          26{" "}
        </span>
        <span id="t3_26" class="t s2_26">
          Зураг 1.5. Сургуулийн менежментийн “Гурван давхаргат Пирамид” загвар{" "}
        </span>
        <span id="t4_26" class="t s3_26">
          Суралцагчийн дүр төрх -{" "}
        </span>
        <span id="t5_26" class="t s4_26">
          суралцахуйгаа өөрөө{" "}
        </span>
        <span id="t6_26" class="t s4_26">
          удирдагч, хөгжигч сурагч{" "}
        </span>
        <span id="t7_26" class="t s3_26">
          Багшийн дүр төрх -{" "}
        </span>
        <span id="t8_26" class="t s4_26">
          өөрчлөгдөн хөгжигч багш{" "}
        </span>
        <span id="t9_26" class="t s3_26">
          Сургуулийн дүр төрх -{" "}
        </span>
        <span id="ta_26" class="t s4_26">
          суралцахуйн зангилаа{" "}
        </span>
        <span id="tb_26" class="t s4_26">
          талбар{" "}
        </span>
        <span id="tc_26" class="t s3_26">
          Сургалтын хөтөлбөр -{" "}
        </span>
        <span id="td_26" class="t s4_26">
          сургуульд суурилсан{" "}
        </span>
        <span id="te_26" class="t s4_26">
          сугуулийн хөтөлбөр{" "}
        </span>
        <span id="tf_26" class="t s5_26">
          Хэмжих үзүүлэлтүүд:{" "}
        </span>
        <span id="tg_26" class="t s3_26">
          Урт хугацаанд -{" "}
        </span>
        <span id="th_26" class="t s4_26">
          Суралцагчид гарах үр нөлөө{" "}
        </span>
        <span id="ti_26" class="t s3_26">
          Дунд хугацаанд -{" "}
        </span>
        <span id="tj_26" class="t s4_26">
          Сургуулийн соёл, багш{" "}
        </span>
        <span id="tk_26" class="t s4_26">
          нарын хамтын ажиллагаа,{" "}
        </span>
        <span id="tl_26" class="t s4_26">
          хөтөлбөрийн баяжилт, орон{" "}
        </span>
        <span id="tm_26" class="t s4_26">
          нутгийн түншлэл{" "}
        </span>
        <span id="tn_26" class="t s3_26">
          Богино хугацаанд -{" "}
        </span>
        <span id="to_26" class="t s4_26">
          Хичээлийн сайжруулалт,{" "}
        </span>
        <span id="tp_26" class="t s4_26">
          багшийн арга зүй, сурагчийн{" "}
        </span>
        <span id="tq_26" class="t s4_26">
          ахиц, хөтөлбөрийн хэрэгжилт{" "}
        </span>
        <span id="tr_26" class="t m0_26 s4_26">
          Хамтарсан{" "}
        </span>
        <span id="ts_26" class="t m0_26 s4_26">
          нэгдмэл{" "}
        </span>
        <span id="tt_26" class="t s6_26">
          Ирээдүйн{" "}
        </span>
        <span id="tu_26" class="t s6_26">
          чиг{" "}
        </span>
        <span id="tv_26" class="t s6_26">
          хандлагад{" "}
        </span>
        <span id="tw_26" class="t s6_26">
          баримжаалж{" "}
        </span>
        <span id="tx_26" class="t s6_26">
          сургуулиуд{" "}
        </span>
        <span id="ty_26" class="t s6_26">
          алсын{" "}
        </span>
        <span id="tz_26" class="t s6_26">
          хараагаа{" "}
        </span>
        <span id="t10_26" class="t s6_26">
          тодорхойлох учраас менежментийн пирамид загварын оройн хэсэгт алсын{" "}
        </span>
        <span id="t11_26" class="t s6_26">
          харааг дүрслэв. Алсын хараа гэдэг бол сургууль бүрийн онцлог, үнэт
          зүйлийг{" "}
        </span>
        <span id="t12_26" class="t s6_26">
          болон сурагчдынхаа ирээдүйн дүр төрхийг харуулсан, урт хугацааны хүчин{" "}
        </span>
        <span id="t13_26" class="t s6_26">
          чармайлтын үр дүнд хүрэх цэг бөгөөд танай хамт олныг чиглүүлж нэгтгэж{" "}
        </span>
        <span id="t14_26" class="t s6_26">
          байдаг “Алтан гадас од” нь байх ёстой юм. Алсын харааг ихэнхдээ 5-10{" "}
        </span>
        <span id="t15_26" class="t s6_26">
          жилээр тодорхойлдог.{" "}
        </span>
        <span id="t16_26" class="t s6_26">
          Алсын хараанд хүрсэн эсэхээ хэмжих үзүүлэлт нь дунд, урт хугацааны{" "}
        </span>
        <span id="t17_26" class="t s6_26">
          үр дүнг хэмжих үзүүлэлтийг бодвол илүү чанарын шинжтэй, цогц, өргөн{" "}
        </span>
        <span id="t18_26" class="t s6_26">
          хүрээтэй ухагдахуунаар илэрхийлэгдсэн, үр өгөөжийг хэмжихүйц байхад{" "}
        </span>
        <span id="t19_26" class="t s6_26">
          анхаарах хэрэгтэй. Алсын харааг хэрхэн оновчтой тодорхойлох аргачлалыг{" "}
        </span>
        <span id="t1a_26" class="t s6_26">
          дараагийн хэсэгт авч үзэх болно.{" "}
        </span>
        <span id="t1b_26" class="t s6_26">
          Пирамид загварын суурийн дөрвөн өнцөгт боловсролын үндсэн бүрэлдэхүүн{" "}
        </span>
        <span id="t1c_26" class="t s6_26">
          болох сурагч, багш, сургалтын хөтөлбөр, сургуулийг оруулсан бөгөөд
          учир{" "}
        </span>
        <span id="t1d_26" class="t s6_26">
          нь сургуулийн гол үйлчлүүлэгчид нь сурагчид, боловсролын үйлчилгээ{" "}
        </span>
        <span id="t1e_26" class="t s6_26">
          үзүүлэгч гол субъект нь багш нар, сурагчийн эзэмших мэдлэг, чадвар,{" "}
        </span>
        <span id="t1f_26" class="t s6_26">
          төлөвшил, зорилго, зорилт, үнэлгээ, арга зүй, орчин, хэрэглэгдэхүүнийг{" "}
        </span>
        <span id="t1g_26" class="t s6_26">
          тусгасан цогц баримт бичиг нь сургалтын хөтөлбөр, боловсрол олгох үйл{" "}
        </span>
        <span id="t1h_26" class="t s6_26">
          явц өрнөдөг гол зангилаа талбар нь (learning hub) сургууль юм. Эдгээр
          нь{" "}
        </span>
        <span id="t1i_26" class="t s6_26">
          хоорондоо салшгүй холбоотой, нэг нь нөгөөтэйгөө уялдсан, нэг нь
          нөгөөдөө{" "}
        </span>
        <span id="t1j_26" class="t s6_26">
          багтсан шинжтэй байдаг учраас пирамидын суурийн зангилаа цэгүүд болгон{" "}
        </span>
      </div>
    </div>
  );
}
