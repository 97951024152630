import React from "react";
export default function F157() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_157{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_157{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:2.49px;}
#t3_157{left:87px;bottom:921px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t4_157{left:491px;bottom:921px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t5_157{left:113px;bottom:901px;}
#t6_157{left:130px;bottom:901px;letter-spacing:-0.17px;word-spacing:5.25px;}
#t7_157{left:130px;bottom:880px;letter-spacing:-0.14px;word-spacing:0.13px;}
#t8_157{left:130px;bottom:860px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t9_157{left:87px;bottom:827px;letter-spacing:-0.12px;word-spacing:-0.27px;}
#ta_157{left:571px;bottom:827px;letter-spacing:-0.13px;}
#tb_157{left:87px;bottom:807px;letter-spacing:-0.17px;}
#tc_157{left:113px;bottom:787px;}
#td_157{left:130px;bottom:787px;letter-spacing:-0.13px;word-spacing:0.77px;}
#te_157{left:130px;bottom:767px;letter-spacing:-0.14px;word-spacing:10.88px;}
#tf_157{left:130px;bottom:746px;letter-spacing:-0.16px;word-spacing:0.36px;}
#tg_157{left:130px;bottom:726px;letter-spacing:-0.16px;word-spacing:-1.36px;}
#th_157{left:130px;bottom:706px;letter-spacing:-0.19px;word-spacing:0.09px;}
#ti_157{left:87px;bottom:666px;letter-spacing:-0.15px;word-spacing:4.57px;}
#tj_157{left:87px;bottom:646px;letter-spacing:-0.13px;word-spacing:2.31px;}
#tk_157{left:87px;bottom:625px;letter-spacing:-0.13px;word-spacing:4.1px;}
#tl_157{left:87px;bottom:605px;letter-spacing:-0.13px;word-spacing:-0.46px;}
#tm_157{left:87px;bottom:585px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tn_157{left:142px;bottom:522px;}
#to_157{left:139px;bottom:496px;letter-spacing:-0.16px;}
#tp_157{left:246px;bottom:496px;letter-spacing:-0.12px;}
#tq_157{left:376px;bottom:496px;letter-spacing:-0.16px;}
#tr_157{left:484px;bottom:496px;letter-spacing:-0.16px;}
#ts_157{left:555px;bottom:496px;letter-spacing:-0.09px;}
#tt_157{left:624px;bottom:496px;letter-spacing:-0.1px;}
#tu_157{left:139px;bottom:477px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_157{left:139px;bottom:459px;letter-spacing:-0.17px;word-spacing:0.27px;}
#tw_157{left:139px;bottom:440px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_157{left:139px;bottom:416px;letter-spacing:-0.14px;word-spacing:2.39px;}
#ty_157{left:139px;bottom:396px;letter-spacing:-0.13px;word-spacing:3.56px;}
#tz_157{left:139px;bottom:375px;letter-spacing:-0.23px;word-spacing:2.48px;}
#t10_157{left:157px;bottom:335px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t11_157{left:157px;bottom:311px;}
#t12_157{left:184px;bottom:311px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t13_157{left:157px;bottom:290px;}
#t14_157{left:184px;bottom:290px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t15_157{left:157px;bottom:270px;}
#t16_157{left:184px;bottom:270px;letter-spacing:-0.14px;word-spacing:-1.19px;}
#t17_157{left:184px;bottom:250px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t18_157{left:157px;bottom:230px;}
#t19_157{left:184px;bottom:230px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1a_157{left:157px;bottom:210px;}
#t1b_157{left:184px;bottom:210px;letter-spacing:-0.18px;word-spacing:3.07px;}
#t1c_157{left:184px;bottom:190px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1d_157{left:157px;bottom:165px;}
#t1e_157{left:184px;bottom:165px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1f_157{left:157px;bottom:141px;}
#t1g_157{left:184px;bottom:141px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_157{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_157{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_157{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_157{font-size:17px;font-family:MyriadPro-Regular_1al;color:#000;}
.s4_157{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_157{font-size:17px;font-family:MyriadPro-Regular_1al;color:#4472C4;}`}
      </style>

      <style id="fonts157" type="text/css">
        @
        {`font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MyriadPro-Regular_1al;
	src: url("fonts/MyriadPro-Regular_1al.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg157" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="157/157.svg"
          type="image/svg+xml"
          id="pdf157"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_157" class="t s0_157">
          157{" "}
        </span>
        <span id="t2_157" class="t s1_157">
          Нөөц ба орон зайн төлөвлөлт, ашиглалт, менежментийн хувьд орон{" "}
        </span>
        <span id="t3_157" class="t s1_157">
          зайг илүү үр ашигтай ашиглах боломжийг олгох{" "}
        </span>
        <span id="t4_157" class="t s2_157">
          (үр ашигтай байдал){" "}
        </span>
        <span id="t5_157" class="t s3_157">
          »{" "}
        </span>
        <span id="t6_157" class="t s2_157">
          Боловсролын үр ашиг: боловсролын зорилтуудыг аль болох бага{" "}
        </span>
        <span id="t7_157" class="t s2_157">
          зардлаар биелүүлэх. Өөрөөр хэлбэл, үр ашиг гэдэг нь үр дүнтэй байх{" "}
        </span>
        <span id="t8_157" class="t s2_157">
          ба үүнд хамгийн бага зардлаар хүрэх нэмэлт шаардлага юм.{" "}
        </span>
        <span id="t9_157" class="t s1_157">
          Хэрэглэгчдийн тав тух, хүртээмж, эрүүл, аюулгүй байдал{" "}
        </span>
        <span id="ta_157" class="t s2_157">
          (хүртээмжтэй{" "}
        </span>
        <span id="tb_157" class="t s2_157">
          байдал){" "}
        </span>
        <span id="tc_157" class="t s3_157">
          »{" "}
        </span>
        <span id="td_157" class="t s2_157">
          Боловсролын хүрэлцээ: Хүн ам зүй, нийгэм, улс төрийн өөрчлөлтийн{" "}
        </span>
        <span id="te_157" class="t s2_157">
          үед суралцагчийн суралцах чадварт хамгийн их нөлөөлөхүйц{" "}
        </span>
        <span id="tf_157" class="t s2_157">
          боломж(жишээлбэл, аюулгүй байдал, ус, байгалийн гэрэл, эрчим хүч,{" "}
        </span>
        <span id="tg_157" class="t s2_157">
          дулаан, технологийн хүртээмж ...)-оор хангахад шаардлагатай барилга{" "}
        </span>
        <span id="th_157" class="t s2_157">
          байгууламжийн суурь бүрэлдэхүүн хэсэг.{" "}
        </span>
        <span id="ti_157" class="t s2_157">
          Эндээс харахад сургуулийн материаллаг орчин нь сурагчдын сурлагын{" "}
        </span>
        <span id="tj_157" class="t s2_157">
          амжилт төдийгүй тэдний сэтгэл хөдлөлийн чадвар зэрэг сургуулийн нийт{" "}
        </span>
        <span id="tk_157" class="t s2_157">
          амьдралд нөлөөлдөг бөгөөд чухал хүчин зүйл болдгийг харж болохоор{" "}
        </span>
        <span id="tl_157" class="t s2_157">
          байна. Мөн хүн ам зүй, нийгэм, эдийн засгийн өөрчлөлтийг даган
          сургалтын{" "}
        </span>
        <span id="tm_157" class="t s2_157">
          орчныг түүнд нийцүүлэн өөрчилж байх шаардлагатай байна.{" "}
        </span>
        <span id="tn_157" class="t s4_157">
          АСУУДАЛ{" "}
        </span>
        <span id="to_157" class="t s2_157">
          Сургуулийн{" "}
        </span>
        <span id="tp_157" class="t s2_157">
          менежментийг{" "}
        </span>
        <span id="tq_157" class="t s2_157">
          сайжруулах{" "}
        </span>
        <span id="tr_157" class="t s2_157">
          зөвлөн{" "}
        </span>
        <span id="ts_157" class="t s2_157">
          туслах{" "}
        </span>
        <span id="tt_157" class="t s2_157">
          ажлын{" "}
        </span>
        <span id="tu_157" class="t s2_157">
          хүрээнд хийгдсэн үйл ажиллагааны тайлан, үр дүнгээс сургуулиудад{" "}
        </span>
        <span id="tv_157" class="t s2_157">
          тулгамдаж буй орчинтой холбоотой асуудлыг дараах гурван чиглэлд{" "}
        </span>
        <span id="tw_157" class="t s2_157">
          ангилж байна. Үүнд:{" "}
        </span>
        <span id="tx_157" class="t s2_157">
          - Эрүүл, аюулгүй байдал{" "}
        </span>
        <span id="ty_157" class="t s2_157">
          - Нөөц, хүртээмж{" "}
        </span>
        <span id="tz_157" class="t s2_157">
          - Зохион байгуулалт, ашиглалт{" "}
        </span>
        <span id="t10_157" class="t s1_157">
          Орчны эрүүл, аюулгүй байдал{" "}
        </span>
        <span id="t11_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t12_157" class="t s2_157">
          Анги танхим, орчны аюулгүй байдал хангалтгүй{" "}
        </span>
        <span id="t13_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t14_157" class="t s2_157">
          Спорт заалны аюулгүй байдал хангалтгүй{" "}
        </span>
        <span id="t15_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t16_157" class="t s2_157">
          Дотуур байрны сурагчид, үдийн хоол, хүнсний аюулгүй байдалд{" "}
        </span>
        <span id="t17_157" class="t s2_157">
          хяналт тавьж ажиллах{" "}
        </span>
        <span id="t18_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t19_157" class="t s2_157">
          Сургууль орчмын ногоон байгууламж хангалтгүй{" "}
        </span>
        <span id="t1a_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t1b_157" class="t s2_157">
          Бохирын систем, 00-ын үнэр ойр орчмын ангиудад үнэртдэг,{" "}
        </span>
        <span id="t1c_157" class="t s2_157">
          салхитай үед сургууль нийтээрээ 00-ын үнэр үнэртдэг{" "}
        </span>
        <span id="t1d_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t1e_157" class="t s2_157">
          Замын хөдөлгөөний аюулгүй байдал{" "}
        </span>
        <span id="t1f_157" class="t s5_157">
          »{" "}
        </span>
        <span id="t1g_157" class="t s2_157">
          Цахилгааны болон галын аюулгүй байдал{" "}
        </span>
      </div>
    </div>
  );
}
