import React from "react";
export default function HundredEleven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_111{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_111{left:88px;bottom:936px;letter-spacing:0.07px;word-spacing:0.16px;}
#t3_111{left:311px;bottom:910px;letter-spacing:0.19px;}
#t4_111{left:87px;bottom:872px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t5_111{left:87px;bottom:831px;letter-spacing:-0.18px;word-spacing:-0.78px;}
#t6_111{left:87px;bottom:811px;letter-spacing:-0.17px;word-spacing:6.07px;}
#t7_111{left:87px;bottom:791px;letter-spacing:-0.12px;word-spacing:3.01px;}
#t8_111{left:279px;bottom:791px;letter-spacing:-0.14px;word-spacing:3.04px;}
#t9_111{left:87px;bottom:771px;letter-spacing:-0.16px;word-spacing:10.15px;}
#ta_111{left:87px;bottom:751px;letter-spacing:-0.17px;word-spacing:-0.26px;}
#tb_111{left:87px;bottom:730px;letter-spacing:-0.16px;word-spacing:-0.14px;}
#tc_111{left:87px;bottom:710px;letter-spacing:-0.12px;word-spacing:-1.4px;}
#td_111{left:87px;bottom:690px;letter-spacing:-0.15px;word-spacing:5.29px;}
#te_111{left:87px;bottom:670px;letter-spacing:-0.14px;word-spacing:3.78px;}
#tf_111{left:87px;bottom:650px;letter-spacing:-0.21px;word-spacing:0.12px;}
#tg_111{left:142px;bottom:601px;}
#th_111{left:143px;bottom:568px;}
#ti_111{left:169px;bottom:568px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_111{left:143px;bottom:548px;}
#tk_111{left:169px;bottom:548px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_111{left:143px;bottom:528px;}
#tm_111{left:169px;bottom:528px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tn_111{left:143px;bottom:508px;}
#to_111{left:169px;bottom:508px;letter-spacing:-0.17px;word-spacing:5.78px;}
#tp_111{left:169px;bottom:487px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tq_111{left:143px;bottom:467px;}
#tr_111{left:169px;bottom:467px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ts_111{left:142px;bottom:404px;letter-spacing:0.11px;word-spacing:0.12px;}
#tt_111{left:106px;bottom:356px;letter-spacing:0.12px;word-spacing:0.08px;}
#tu_111{left:106px;bottom:337px;letter-spacing:0.11px;word-spacing:0.02px;}
#tv_111{left:134px;bottom:319px;letter-spacing:0.07px;}
#tw_111{left:161px;bottom:319px;letter-spacing:0.09px;word-spacing:0.04px;}
#tx_111{left:134px;bottom:301px;letter-spacing:0.07px;}
#ty_111{left:161px;bottom:301px;letter-spacing:0.12px;word-spacing:0.02px;}
#tz_111{left:134px;bottom:282px;letter-spacing:0.07px;}
#t10_111{left:161px;bottom:282px;letter-spacing:0.06px;word-spacing:0.07px;}
#t11_111{left:134px;bottom:264px;letter-spacing:0.07px;}
#t12_111{left:161px;bottom:264px;letter-spacing:0.1px;word-spacing:0.05px;}
#t13_111{left:106px;bottom:233px;letter-spacing:0.12px;word-spacing:-1.03px;}
#t14_111{left:518px;bottom:233px;letter-spacing:0.16px;word-spacing:-1.04px;}
#t15_111{left:106px;bottom:214px;letter-spacing:0.12px;word-spacing:0.61px;}
#t16_111{left:190px;bottom:214px;letter-spacing:0.11px;word-spacing:0.64px;}
#t17_111{left:106px;bottom:196px;letter-spacing:0.12px;word-spacing:0.01px;}
#t18_111{left:106px;bottom:178px;letter-spacing:0.11px;word-spacing:0.03px;}
#t19_111{left:187px;bottom:149px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1a_111{left:429px;bottom:132px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_111{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_111{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_111{font-size:17px;font-family:MogulArial_16s;color:#D1622B;}
.s3_111{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_111{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_111{font-size:17px;font-family:CourierNewPSMT_rm;color:#000;}
.s6_111{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s7_111{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s8_111{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s9_111{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts111" type="text/css">
        {`@font-face {
	font-family: CourierNewPSMT_rm;
	src: url("fonts/CourierNewPSMT_rm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg111" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="111/111.svg"
          type="image/svg+xml"
          id="pdf111"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_111" class="t s0_111">
          111{" "}
        </span>
        <span id="t2_111" class="t s1_111">
          3.2.3 СУРАЛЦАХУЙ БА БАГШЛАХУЙ (СУРГАЛТЫН АРГА{" "}
        </span>
        <span id="t3_111" class="t s1_111">
          ТЕХНОЛОГИ){" "}
        </span>
        <span id="t4_111" class="t s2_111">
          Сурагчийн бие даан суралцахуйг дэмжих нь яагаад чухал вэ?{" "}
        </span>
        <span id="t5_111" class="t s3_111">
          Бие даан суралцахуйтай холбоотой олон янзын нэр томьёо, ойлголт
          байдаг.{" "}
        </span>
        <span id="t6_111" class="t s3_111">
          Эдгээрээс хамгийн ерөнхий хэрэглэгдэж буй нь “өөрийн удирдлагатай{" "}
        </span>
        <span id="t7_111" class="t s3_111">
          суралцахуй” юм. Үүнд{" "}
        </span>
        <span id="t8_111" class="t s3_111">
          сурагч суралцахуйгаа ойлгох, суралцахуйд сэдэл{" "}
        </span>
        <span id="t9_111" class="t s3_111">
          тэмүүлэлтэй, хариуцлагатай оролцох, багштай хамтран суралцахуйн{" "}
        </span>
        <span id="ta_111" class="t s3_111">
          орчныг бүтэцчилхэд оролцох зэргийг багтаадаг. Бие даан суралцах гэдэг
          нь{" "}
        </span>
        <span id="tb_111" class="t s3_111">
          сурагч ганцаараа суралцана гэсэн утга биш юм. Сурагч мэдлэг,
          мэдээллийг{" "}
        </span>
        <span id="tc_111" class="t s3_111">
          идэвхгүй хүлээн авагч, багшийн зааврыг хүлээгч, багшаас хараат
          байдалтай{" "}
        </span>
        <span id="td_111" class="t s3_111">
          суралцах бус харин өөрийн суралцахуйг төлөвлөх, удирдан чиглүүлэх,{" "}
        </span>
        <span id="te_111" class="t s3_111">
          зохицуулах, өөрийгөө үнэлэх, өөрийн суралцах үйл ажиллагаагаа хянах{" "}
        </span>
        <span id="tf_111" class="t s3_111">
          чадварт суралцах нь чухал байдаг.{" "}
        </span>
        <span id="tg_111" class="t s4_111">
          АСУУДАЛ{" "}
        </span>
        <span id="th_111" class="t s5_111">
          •{" "}
        </span>
        <span id="ti_111" class="t s3_111">
          Сурлагын амжилт хангалтгүй (48,7% ){" "}
        </span>
        <span id="tj_111" class="t s5_111">
          •{" "}
        </span>
        <span id="tk_111" class="t s3_111">
          Сурагчийн бие даан суралцах чадвар сул{" "}
        </span>
        <span id="tl_111" class="t s5_111">
          •{" "}
        </span>
        <span id="tm_111" class="t s3_111">
          Бага ангийн сурагчдын ойлгон унших чадвар сул{" "}
        </span>
        <span id="tn_111" class="t s5_111">
          •{" "}
        </span>
        <span id="to_111" class="t s3_111">
          Бага, дунд, ахлах ангийн монгол хэл, математик, байгалийн{" "}
        </span>
        <span id="tp_111" class="t s3_111">
          ухааны хичээлийн сурлагын амжилт сул{" "}
        </span>
        <span id="tq_111" class="t s5_111">
          •{" "}
        </span>
        <span id="tr_111" class="t s3_111">
          Сурагчийн дэвтрийн тэмдэглэл хангалтгүй, үлдэц бага{" "}
        </span>
        <span id="ts_111" class="t s4_111">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tt_111" class="t s6_111">
          “Үсгэн өрөг”-ийн дугуйланг тусгай хуваарийн дагуу хичээллүүлж эхэлсэн.
          Энэ{" "}
        </span>
        <span id="tu_111" class="t s6_111">
          дугуйланд 1-5 дугаар ангийн бүх сурагч хамрагдсан. Үүний үр дүнд:{" "}
        </span>
        <span id="tv_111" class="t s7_111">
          1.{" "}
        </span>
        <span id="tw_111" class="t s6_111">
          Үгийн баялаг нэмэгдсэн.{" "}
        </span>
        <span id="tx_111" class="t s7_111">
          2.{" "}
        </span>
        <span id="ty_111" class="t s6_111">
          Шинэ үгийг тогтоох чадвар сайжирсан.{" "}
        </span>
        <span id="tz_111" class="t s7_111">
          3.{" "}
        </span>
        <span id="t10_111" class="t s6_111">
          Хурдтай сэтгэдэг болсон.{" "}
        </span>
        <span id="t11_111" class="t s7_111">
          4.{" "}
        </span>
        <span id="t12_111" class="t s6_111">
          Шинийг эрэлхийлэх сонирхол нэмэгдсэн.{" "}
        </span>
        <span id="t13_111" class="t s6_111">
          Сурагчдын цээжээр бичих чадварыг хөгжүүлэх зорилгоор “
        </span>
        <span id="t14_111" class="t s8_111">
          НАНДИН” сонсдог{" "}
        </span>
        <span id="t15_111" class="t s6_111">
          картыг 1-5{" "}
        </span>
        <span id="t16_111" class="t s6_111">
          ангийн нийт сурагч хичээлдээ болон гэрийн даалгаварт ашиглаж{" "}
        </span>
        <span id="t17_111" class="t s6_111">
          хэвшсэн. Ингэснээр сурагчид ухаалаг утсыг зөв ашиглах, бие даагаад
          гэрийн{" "}
        </span>
        <span id="t18_111" class="t s6_111">
          даалгавраа хийх боломжтой болсон.{" "}
        </span>
        <span id="t19_111" class="t s9_111">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="t1a_111" class="t s9_111">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
