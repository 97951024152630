import React from "react";
export default function HundredThirty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_130{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_130{left:108px;bottom:941px;letter-spacing:-0.15px;word-spacing:2.38px;}
#t3_130{left:108px;bottom:921px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t4_130{left:87px;bottom:896px;letter-spacing:-0.05px;}
#t5_130{left:108px;bottom:896px;letter-spacing:-0.16px;word-spacing:1.91px;}
#t6_130{left:108px;bottom:876px;letter-spacing:-0.16px;word-spacing:3.04px;}
#t7_130{left:108px;bottom:856px;letter-spacing:-0.15px;word-spacing:4.4px;}
#t8_130{left:108px;bottom:836px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t9_130{left:246px;bottom:836px;letter-spacing:-0.14px;word-spacing:0.05px;}
#ta_130{left:87px;bottom:795px;letter-spacing:-0.32px;word-spacing:6.41px;}
#tb_130{left:526px;bottom:795px;letter-spacing:-0.25px;}
#tc_130{left:87px;bottom:775px;letter-spacing:-0.34px;word-spacing:-2.32px;}
#td_130{left:87px;bottom:732px;letter-spacing:0.16px;word-spacing:10.8px;}
#te_130{left:87px;bottom:710px;letter-spacing:0.13px;word-spacing:0.04px;}
#tf_130{left:87px;bottom:678px;letter-spacing:-0.15px;word-spacing:2.16px;}
#tg_130{left:182px;bottom:678px;letter-spacing:-0.18px;word-spacing:2.18px;}
#th_130{left:87px;bottom:658px;letter-spacing:-0.13px;word-spacing:-0.5px;}
#ti_130{left:87px;bottom:638px;letter-spacing:-0.16px;word-spacing:-1.13px;}
#tj_130{left:87px;bottom:617px;letter-spacing:-0.13px;word-spacing:-1.06px;}
#tk_130{left:87px;bottom:597px;letter-spacing:-0.15px;word-spacing:5.27px;}
#tl_130{left:87px;bottom:577px;letter-spacing:-0.13px;word-spacing:3.62px;}
#tm_130{left:87px;bottom:557px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tn_130{left:142px;bottom:510px;letter-spacing:-0.03px;}
#to_130{left:142px;bottom:481px;}
#tp_130{left:169px;bottom:481px;letter-spacing:-0.18px;word-spacing:0.7px;}
#tq_130{left:169px;bottom:461px;letter-spacing:-0.19px;}
#tr_130{left:267px;bottom:461px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ts_130{left:142px;bottom:441px;}
#tt_130{left:169px;bottom:441px;letter-spacing:-0.14px;word-spacing:6px;}
#tu_130{left:169px;bottom:421px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tv_130{left:142px;bottom:354px;letter-spacing:0.18px;}
#tw_130{left:87px;bottom:325px;letter-spacing:-0.13px;word-spacing:-0.42px;}
#tx_130{left:87px;bottom:305px;letter-spacing:-0.19px;word-spacing:1.71px;}
#ty_130{left:87px;bottom:285px;letter-spacing:-0.13px;word-spacing:-0.5px;}
#tz_130{left:87px;bottom:265px;letter-spacing:-0.14px;word-spacing:1.33px;}
#t10_130{left:87px;bottom:245px;letter-spacing:-0.15px;word-spacing:5.11px;}
#t11_130{left:87px;bottom:225px;letter-spacing:-0.12px;word-spacing:2.93px;}
#t12_130{left:87px;bottom:204px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_130{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_130{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_130{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_130{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_130{font-size:17px;font-family:MogulArial_16s;color:#00F;}
.s5_130{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_130{font-size:17px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts130" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg130" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="130/130.svg"
          type="image/svg+xml"
          id="pdf130"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_130" class="t s0_130">
          130{" "}
        </span>
        <span id="t2_130" class="t s1_130">
          оролцоотой хөтлөх, үнэлгээний хэрэгслээр дамжуулан үйл ажиллагааг{" "}
        </span>
        <span id="t3_130" class="t s1_130">
          дэмжих чиглүүлэх боломж бүрдэнэ{" "}
        </span>
        <span id="t4_130" class="t s2_130">
          7.{" "}
        </span>
        <span id="t5_130" class="t s1_130">
          Тухайн нэгжтэй холбоотой нэмэлт /СДҮА/ үйл ажиллагааг төлөвлөсөөр{" "}
        </span>
        <span id="t6_130" class="t s1_130">
          сургалтын болон хичээлээс гадуурх үйл ажиллагааны холбоог хангах,{" "}
        </span>
        <span id="t7_130" class="t s1_130">
          хичээлээс гадуурх үйл ажиллагаагаар сургалтыг дэмжих олон талын{" "}
        </span>
        <span id="t8_130" class="t s1_130">
          боломж бүрдэнэ.{" "}
        </span>
        <span id="t9_130" class="t s3_130">
          /нэгж бүрээр заавал гарах албагүй/{" "}
        </span>
        <span id="ta_130" class="t s1_130">
          Бүх судлагдахууны үнэлгээний шалгуур, зөвлөмжийг{" "}
        </span>
        <span id="tb_130" class="t s4_130">
          http://ebook.eec.mn/{" "}
        </span>
        <span id="tc_130" class="t s1_130">
          холбоосоор татан авч төлөвлөлт, даалгавар, үнэлгээнд тусган
          хэрэгжүүлээрэй.{" "}
        </span>
        <span id="td_130" class="t s5_130">
          Суралцахуйг дэмжих даалгавар боловсруулах, даалгаврын{" "}
        </span>
        <span id="te_130" class="t s5_130">
          гүйцэтгэлд анализ хийх{" "}
        </span>
        <span id="tf_130" class="t s1_130">
          Багш нарт{" "}
        </span>
        <span id="tg_130" class="t s1_130">
          тулгамдаж буй бас нэг асуудал бол даалгавар боловсруулах/{" "}
        </span>
        <span id="th_130" class="t s1_130">
          сонгох чадвар болон сурагчийн гүйцэтгэсэн даалгаварт анализ хийх
          чадвар{" "}
        </span>
        <span id="ti_130" class="t s1_130">
          юм. Энэ нь хичээл ихэвчлэн агуулга төвтэй, багшийн үнэлгээ голчлон
          мэдлэг{" "}
        </span>
        <span id="tj_130" class="t s1_130">
          шалгадаг тул чадварын хөгжил, цогц чадамж, сурах арга барилтөлөвшүүлэх{" "}
        </span>
        <span id="tk_130" class="t s1_130">
          хөтөлбөр бодитой хэрэгжихэд асуудал үүсэж байна. Иймд удирдлагын{" "}
        </span>
        <span id="tl_130" class="t s1_130">
          зүгээс даалгавар боловсруулах анализ хийх арга зүйд дэмжлэг үзүүлэн{" "}
        </span>
        <span id="tm_130" class="t s1_130">
          ажилласнаар хөтөлбөрийн хэрэгжилтийг хангах боломж бүрдэнэ.{" "}
        </span>
        <span id="tn_130" class="t s5_130">
          АСУУДАЛ{" "}
        </span>
        <span id="to_130" class="t s6_130">
          -{" "}
        </span>
        <span id="tp_130" class="t s6_130">
          Даалгавар сонгох/боловсруулах мэдлэг чадвар, арга зүй дутмаг,{" "}
        </span>
        <span id="tq_130" class="t s6_130">
          даалгаварт{" "}
        </span>
        <span id="tr_130" class="t s6_130">
          үнэлгээний шалгуурын тусаагүй{" "}
        </span>
        <span id="ts_130" class="t s6_130">
          -{" "}
        </span>
        <span id="tt_130" class="t s6_130">
          Даалгаврын гүйцэтгэлд тоон анализаас хэтэрдэггүй чанарын{" "}
        </span>
        <span id="tu_130" class="t s6_130">
          буюу шалгуурт суурилсан чадвар, хандлагын анализ хийдэггүй{" "}
        </span>
        <span id="tv_130" class="t s5_130">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tw_130" class="t s1_130">
          Багш даалгаврыг сургалтын төгсгөлд авдаг шалгалтын нэг хэсэг гэж
          ойлгож{" "}
        </span>
        <span id="tx_130" class="t s1_130">
          ач холбогдол өгөх нь сул байдаг. Сурагчдынхаа сэтгэн бодох,
          эргэцүүлэх,{" "}
        </span>
        <span id="ty_130" class="t s1_130">
          бусадтай харилцах болон насан туршдаа суралцах чадварыг бэхжүүлэхийн{" "}
        </span>
        <span id="tz_130" class="t s1_130">
          тулд эдгээр чадварыг илрүүлэхүйц даалгаврыг зохиож/сонгож хичээл, үйл{" "}
        </span>
        <span id="t10_130" class="t s1_130">
          ажиллагаагаар хэрэгжүүлж гүйцэтгэлд дүгнэлт хийн дээрх чадваруудыг{" "}
        </span>
        <span id="t11_130" class="t s1_130">
          дэмжих нь хөтөлбөрийг бодитой хэрэгжүүлэх үндсэн зам юм. Даалгавар{" "}
        </span>
        <span id="t12_130" class="t s1_130">
          боловсруулахад таксономын тухай ойлголт чухал.{" "}
        </span>
      </div>
    </div>
  );
}
