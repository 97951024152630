import React from "react";
export default function Twenty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_20{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_20{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_20{left:88px;bottom:921px;letter-spacing:-0.15px;word-spacing:2.91px;}
#t4_20{left:88px;bottom:901px;letter-spacing:-0.19px;word-spacing:3.44px;}
#t5_20{left:557px;bottom:901px;letter-spacing:-0.21px;word-spacing:3.47px;}
#t6_20{left:88px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t7_20{left:100px;bottom:838px;letter-spacing:0.11px;word-spacing:0.03px;}
#t8_20{left:100px;bottom:807px;letter-spacing:0.11px;word-spacing:0.03px;}
#t9_20{left:100px;bottom:788px;letter-spacing:0.12px;word-spacing:3.04px;}
#ta_20{left:100px;bottom:770px;letter-spacing:0.1px;word-spacing:6.96px;}
#tb_20{left:100px;bottom:752px;letter-spacing:0.14px;word-spacing:-0.01px;}
#tc_20{left:100px;bottom:733px;letter-spacing:0.13px;word-spacing:2.5px;}
#td_20{left:100px;bottom:715px;letter-spacing:0.01px;}
#te_20{left:100px;bottom:697px;letter-spacing:0.09px;word-spacing:-1.92px;}
#tf_20{left:100px;bottom:678px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_20{left:100px;bottom:660px;letter-spacing:0.11px;word-spacing:0.03px;}
#th_20{left:100px;bottom:642px;letter-spacing:0.08px;word-spacing:0.06px;}
#ti_20{left:88px;bottom:581px;letter-spacing:-0.14px;word-spacing:3.1px;}
#tj_20{left:88px;bottom:561px;letter-spacing:-0.18px;word-spacing:9.87px;}
#tk_20{left:588px;bottom:561px;letter-spacing:-0.17px;word-spacing:9.88px;}
#tl_20{left:88px;bottom:540px;letter-spacing:-0.15px;word-spacing:11.63px;}
#tm_20{left:88px;bottom:520px;letter-spacing:-0.14px;word-spacing:5.75px;}
#tn_20{left:88px;bottom:500px;letter-spacing:-0.19px;word-spacing:1.98px;}
#to_20{left:88px;bottom:480px;letter-spacing:-0.16px;word-spacing:7.69px;}
#tp_20{left:88px;bottom:460px;letter-spacing:-0.12px;word-spacing:2.41px;}
#tq_20{left:88px;bottom:440px;letter-spacing:-0.13px;word-spacing:3.39px;}
#tr_20{left:88px;bottom:419px;letter-spacing:-0.13px;word-spacing:4.96px;}
#ts_20{left:88px;bottom:399px;letter-spacing:-0.13px;word-spacing:2.61px;}
#tt_20{left:88px;bottom:379px;letter-spacing:-0.16px;word-spacing:4.21px;}
#tu_20{left:88px;bottom:359px;letter-spacing:-0.18px;word-spacing:0.07px;}
#tv_20{left:282px;bottom:368px;}
#tw_20{left:88px;bottom:326px;letter-spacing:-0.12px;word-spacing:-1.29px;}
#tx_20{left:88px;bottom:306px;letter-spacing:-0.18px;word-spacing:2.86px;}
#ty_20{left:88px;bottom:285px;letter-spacing:-0.14px;word-spacing:2.17px;}
#tz_20{left:88px;bottom:265px;letter-spacing:-0.14px;word-spacing:-0.86px;}
#t10_20{left:88px;bottom:245px;letter-spacing:-0.12px;word-spacing:-0.02px;}
#t11_20{left:88px;bottom:225px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t12_20{left:88px;bottom:115px;}
#t13_20{left:113px;bottom:115px;letter-spacing:0.07px;word-spacing:0.04px;}

.s0_20{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_20{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_20{font-size:17px;font-family:MogulArial_16s;color:#231F20;}
.s3_20{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_20{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_20{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_20{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s7_20{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s8_20{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s9_20{font-size:12px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts20" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg20" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="20/20.svg"
          type="image/svg+xml"
          id="pdf20"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_20" class="t s1_20">
          20{" "}
        </span>
        <span id="t3_20" class="t s2_20">
          Эдгээр үйл ажиллагааг зохицуулах эрх зүйн орчин ч боловсронгуй болж{" "}
        </span>
        <span id="t4_20" class="t s2_20">
          байна. Тухайлбал, Монгол Улсын Соёлын тухай хуулийн{" "}
        </span>
        <span id="t5_20" class="t s3_20">
          11 дүгээр зүйл{" "}
        </span>
        <span id="t6_20" class="t s3_20">
          бүхэлдээ “Соёлын боловсрол олгох үйл ажиллагаа”-г зохицуулсан байна.{" "}
        </span>
        <span id="t7_20" class="t s4_20">
          Соёлын тухай хууль (2021){" "}
        </span>
        <span id="t8_20" class="t s5_20">
          4 дүгээр зүйл. Төрөөс соёлын талаар баримтлах бодлого{" "}
        </span>
        <span id="t9_20" class="t s5_20">
          4.1.6. хувь хүний бүтээлч хөгжил, авьяас, ур чадварыг хөгжүүлэхэд
          соёлын{" "}
        </span>
        <span id="ta_20" class="t s5_20">
          гүйцэтгэх үүргийг нэмэгдүүлэх, соёлын боловсрол олгох үйл ажиллагааг{" "}
        </span>
        <span id="tb_20" class="t s5_20">
          дэмжих, иргэнийг соён гэгээрүүлэх;{" "}
        </span>
        <span id="tc_20" class="t s5_20">
          8 дугаар зүйл. Төрөөс тусгайлан дэмжлэг үзүүлэх соёлын үйл ажиллагааны{" "}
        </span>
        <span id="td_20" class="t s5_20">
          чиглэл{" "}
        </span>
        <span id="te_20" class="t s5_20">
          8.6. Хүүхэд, залуучуудын авьяас, ур чадвар, бүтээлч үйл ажиллагааг
          урамшуулж,{" "}
        </span>
        <span id="tf_20" class="t s5_20">
          соёлын боловсрол олгох арга хэмжээг дэмжинэ.{" "}
        </span>
        <span id="tg_20" class="t s5_20">
          9 дүгээр зүйл. Соёлын үйл ажиллагааны чиглэл{" "}
        </span>
        <span id="th_20" class="t s5_20">
          9.1.12.соёлын боловсрол олгохтой холбоотой үйл ажиллагаа;{" "}
        </span>
        <span id="ti_20" class="t s6_20">
          Сургууль бүхлээрээ ажиллах арга хандлага (СБААХ) нь сургуулийн{" "}
        </span>
        <span id="tj_20" class="t s6_20">
          менежментийг дэмжиж буй нэг хэлбэр болж байна.{" "}
        </span>
        <span id="tk_20" class="t s3_20">
          СБААХ нь{" "}
        </span>
        <span id="tl_20" class="t s3_20">
          тогтвортой байдлыг сургуулийн амьдралын бүхий л хүрээнд, үйл{" "}
        </span>
        <span id="tm_20" class="t s3_20">
          ажиллагаа бүрд нэвтрүүлж, хэрэгжүүлэхийг илэрхийлсэн хандлага юм.{" "}
        </span>
        <span id="tn_20" class="t s3_20">
          Тухайлбал, сургуулийн засаглал, менежмент, зохион байгуулалтын бүтэц,{" "}
        </span>
        <span id="to_20" class="t s3_20">
          үйл ажиллагааг зохицуулах механизмууд, судалгаа шинжилгээ, багш,{" "}
        </span>
        <span id="tp_20" class="t s3_20">
          хүний нөөцийн хөгжил болон сургалтын хөтөлбөр, сургуулийн дэд бүтэц,{" "}
        </span>
        <span id="tq_20" class="t s3_20">
          сурагчдын оролцоо, олон нийттэй харилцах харилцаа, түншлэл, хамтын{" "}
        </span>
        <span id="tr_20" class="t s3_20">
          ажиллагаа, байгууллагын соёл, үнэт зүйлс зэрэг бүх зүйлийг хамарна.{" "}
        </span>
        <span id="ts_20" class="t s3_20">
          Өөрөөр хэлбэл, сурагчдын сурах хөгжих, нийгмийн сайн сайхан байдлыг{" "}
        </span>
        <span id="tt_20" class="t s3_20">
          сайжруулахын тулд сургуулийн хамт олны нэгдмэл, тасралтгүй, хамтын{" "}
        </span>
        <span id="tu_20" class="t s3_20">
          түншлэл бол СБААХ юм.{" "}
        </span>
        <span id="tv_20" class="t s7_20">
          4{" "}
        </span>
        <span id="tw_20" class="t s3_20">
          Сургууль бүр өөр өөр хэрэгцээ, эхлэх цэгтэй байдаг бөгөөд сургуулиа
          энэхүү{" "}
        </span>
        <span id="tx_20" class="t s3_20">
          арга хандлагаар удирдахын тулд одоо байгаа зүйлийг үнэлэх, дутагдлыг{" "}
        </span>
        <span id="ty_20" class="t s3_20">
          арилгах төлөвлөгөө гаргах, ахиц дэвшлийн арга хэмжээг тодорхойлохоос{" "}
        </span>
        <span id="tz_20" class="t s3_20">
          эхлэх хэрэгтэй. Сургуулийг бүхэлд нь өөрчлөх эхний алхам бол
          сургуульдаа{" "}
        </span>
        <span id="t10_20" class="t s3_20">
          эерэг уур амьсгал, соёлыг бий болгох явдал бөгөөд энэ нь сургуулийн
          нийт{" "}
        </span>
        <span id="t11_20" class="t s3_20">
          хамт олны хамтын хүчин чармайлтыг шаарддаг.{" "}
        </span>
        <span id="t12_20" class="t s8_20">
          4{" "}
        </span>
        <span id="t13_20" class="t s9_20">
          2020. БОМСТ-ийн Сургууль бүхлээрээ ажиллах арга хандлага гарын авлага{" "}
        </span>
      </div>
    </div>
  );
}
