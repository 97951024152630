import React from "react";
export default function Twohundred() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_3{left:326px;bottom:937px;letter-spacing:0.25px;}
#t2_3{left:260px;bottom:906px;letter-spacing:-0.11px;word-spacing:1.37px;}
#t3_3{left:234px;bottom:889px;letter-spacing:-0.04px;word-spacing:-1.72px;}
#t4_3{left:234px;bottom:872px;letter-spacing:-0.11px;word-spacing:0.28px;}
#t5_3{left:234px;bottom:855px;letter-spacing:-0.08px;word-spacing:6.75px;}
#t6_3{left:234px;bottom:839px;letter-spacing:-0.04px;}
#t7_3{left:307px;bottom:839px;letter-spacing:-0.1px;}
#t8_3{left:396px;bottom:839px;letter-spacing:-0.02px;}
#t9_3{left:446px;bottom:839px;letter-spacing:-0.01px;}
#ta_3{left:515px;bottom:839px;letter-spacing:-0.03px;}
#tb_3{left:600px;bottom:839px;letter-spacing:-0.01px;}
#tc_3{left:234px;bottom:822px;letter-spacing:-0.06px;word-spacing:-0.78px;}
#td_3{left:234px;bottom:805px;letter-spacing:-0.06px;word-spacing:3.65px;}
#te_3{left:234px;bottom:788px;letter-spacing:-0.01px;word-spacing:-0.01px;}
#tf_3{left:260px;bottom:770px;letter-spacing:-0.05px;}
#tg_3{left:329px;bottom:770px;letter-spacing:-0.01px;}
#th_3{left:415px;bottom:770px;letter-spacing:-0.03px;}
#ti_3{left:502px;bottom:770px;letter-spacing:-0.04px;}
#tj_3{left:586px;bottom:770px;letter-spacing:-0.03px;}
#tk_3{left:234px;bottom:753px;letter-spacing:-0.06px;word-spacing:8.89px;}
#tl_3{left:234px;bottom:736px;letter-spacing:-0.04px;word-spacing:5px;}
#tm_3{left:87px;bottom:720px;letter-spacing:-0.05px;word-spacing:5.14px;}
#tn_3{left:87px;bottom:703px;letter-spacing:-0.04px;word-spacing:2.68px;}
#to_3{left:87px;bottom:686px;letter-spacing:-0.06px;word-spacing:3.48px;}
#tp_3{left:87px;bottom:669px;letter-spacing:-0.09px;word-spacing:0.53px;}
#tq_3{left:87px;bottom:652px;letter-spacing:-0.05px;word-spacing:0.38px;}
#tr_3{left:87px;bottom:636px;letter-spacing:-0.08px;word-spacing:1.84px;}
#ts_3{left:87px;bottom:619px;letter-spacing:-0.07px;word-spacing:2.54px;}
#tt_3{left:87px;bottom:602px;letter-spacing:-0.09px;word-spacing:1.72px;}
#tu_3{left:87px;bottom:585px;letter-spacing:-0.05px;word-spacing:0.03px;}
#tv_3{left:113px;bottom:567px;letter-spacing:-0.08px;word-spacing:3.47px;}
#tw_3{left:87px;bottom:550px;letter-spacing:-0.07px;word-spacing:0.06px;}
#tx_3{left:113px;bottom:534px;}
#ty_3{left:142px;bottom:534px;letter-spacing:-0.05px;word-spacing:0.03px;}
#tz_3{left:113px;bottom:517px;}
#t10_3{left:142px;bottom:517px;letter-spacing:-0.04px;word-spacing:0.02px;}
#t11_3{left:113px;bottom:500px;}
#t12_3{left:142px;bottom:500px;letter-spacing:-0.02px;word-spacing:0.01px;}
#t13_3{left:113px;bottom:483px;}
#t14_3{left:142px;bottom:483px;letter-spacing:-0.02px;word-spacing:0.01px;}
#t15_3{left:113px;bottom:466px;}
#t16_3{left:142px;bottom:466px;letter-spacing:-0.05px;word-spacing:0.03px;}
#t17_3{left:113px;bottom:450px;}
#t18_3{left:142px;bottom:450px;letter-spacing:-0.05px;word-spacing:0.04px;}
#t19_3{left:113px;bottom:431px;letter-spacing:-0.06px;word-spacing:4.14px;}
#t1a_3{left:87px;bottom:415px;letter-spacing:-0.04px;word-spacing:5.53px;}
#t1b_3{left:87px;bottom:398px;letter-spacing:-0.04px;word-spacing:-1.91px;}
#t1c_3{left:87px;bottom:381px;letter-spacing:-0.04px;word-spacing:2.91px;}
#t1d_3{left:87px;bottom:364px;letter-spacing:-0.05px;word-spacing:3.25px;}
#t1e_3{left:87px;bottom:347px;letter-spacing:-0.03px;word-spacing:0.3px;}
#t1f_3{left:380px;bottom:347px;letter-spacing:-0.04px;word-spacing:0.31px;}
#t1g_3{left:87px;bottom:331px;letter-spacing:-0.05px;word-spacing:0.04px;}
#t1h_3{left:113px;bottom:313px;letter-spacing:-0.08px;word-spacing:5.69px;}
#t1i_3{left:87px;bottom:296px;letter-spacing:-0.06px;word-spacing:3.43px;}
#t1j_3{left:87px;bottom:279px;letter-spacing:-0.06px;word-spacing:0.57px;}
#t1k_3{left:87px;bottom:262px;letter-spacing:-0.04px;word-spacing:4.17px;}
#t1l_3{left:87px;bottom:245px;letter-spacing:-0.06px;word-spacing:2.68px;}
#t1m_3{left:87px;bottom:229px;letter-spacing:-0.09px;word-spacing:7.29px;}
#t1n_3{left:87px;bottom:212px;letter-spacing:-0.04px;word-spacing:0.03px;}
#t1o_3{left:113px;bottom:194px;letter-spacing:-0.06px;word-spacing:1.54px;}
#t1p_3{left:87px;bottom:177px;letter-spacing:-0.04px;word-spacing:3.83px;}
#t1q_3{left:87px;bottom:160px;letter-spacing:-0.02px;word-spacing:0.01px;}
#t1r_3{left:229px;bottom:115px;letter-spacing:-0.31px;}
#t1s_3{left:323px;bottom:115px;letter-spacing:-0.21px;word-spacing:0.05px;}
#t1t_3{left:564px;bottom:115px;letter-spacing:-0.21px;}
#t1u_3{left:679px;bottom:59px;}

.s0_3{font-size:21px;font-family:MogulArial-Bold_17d;color:#1D9FA6;}
.s1_3{font-size:15px;font-family:MogulArial_170;color:#000;}
.s2_3{font-size:14px;font-family:MogulArial_170;color:#000;}
.s3_3{font-size:18px;font-family:MinionPro-Regular_17c;color:#FFF;}`}
      </style>

      <style id="fonts3" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_17c;
	src: url("fonts/MinionPro-Regular_17c.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_17d;
	src: url("fonts/MogulArial-Bold_17d.woff") format("woff");
}

@font-face {
	font-family: MogulArial_170;
	src: url("fonts/MogulArial_170.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg3" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_3" class="t s0_3">
          ӨМНӨХ ҮГ{" "}
        </span>
        <span id="t2_3" class="t s1_3">
          Монгол Улсын Их Хурлаар батлагдсан “Алсын хараа-2050”{" "}
        </span>
        <span id="t3_3" class="t s1_3">
          урт хугацааны хөгжлийн бодлогыг хэрэгжүүлэх үйл ажиллагааны{" "}
        </span>
        <span id="t4_3" class="t s1_3">
          зорилтод “Цэцэрлэг, ерөнхий боловсролын сургуулийн удирдах{" "}
        </span>
        <span id="t5_3" class="t s1_3">
          ажилтанд тавигдах шаардлага, удирдах ажилтныг бэлтгэх,{" "}
        </span>
        <span id="t6_3" class="t s1_3">
          томилох{" "}
        </span>
        <span id="t7_3" class="t s1_3">
          тогтолцоо,{" "}
        </span>
        <span id="t8_3" class="t s1_3">
          ажил{" "}
        </span>
        <span id="t9_3" class="t s1_3">
          үүргийн{" "}
        </span>
        <span id="ta_3" class="t s1_3">
          хуваарийг{" "}
        </span>
        <span id="tb_3" class="t s1_3">
          шинэчилж,{" "}
        </span>
        <span id="tc_3" class="t s1_3">
          сургалтын чанарыг хангах чиг үүргийг нэмэгдүүлж, гүйцэтгэлийн{" "}
        </span>
        <span id="td_3" class="t s1_3">
          үнэлгээг сайжруулан сургалтын байгууллагын менежментийн{" "}
        </span>
        <span id="te_3" class="t s1_3">
          чадавхыг дээшлүүлнэ” гэж заасан.{" "}
        </span>
        <span id="tf_3" class="t s1_3">
          Энэхүү{" "}
        </span>
        <span id="tg_3" class="t s1_3">
          хөгжлийн{" "}
        </span>
        <span id="th_3" class="t s1_3">
          бодлогын{" "}
        </span>
        <span id="ti_3" class="t s1_3">
          зорилтыг{" "}
        </span>
        <span id="tj_3" class="t s1_3">
          хэрэгжүүлэх,{" "}
        </span>
        <span id="tk_3" class="t s1_3">
          суралцагч бүрд чанартай боловсролыг эзэмшүүлэх, тэгш{" "}
        </span>
        <span id="tl_3" class="t s1_3">
          боломжийг бүрдүүлэх, боловсролыг хувь хүний хөгжил, гэр{" "}
        </span>
        <span id="tm_3" class="t s1_3">
          бүлийн амьдралын баталгаа, улс орны хөгжлийн суурь болгон насан
          туршдаа{" "}
        </span>
        <span id="tn_3" class="t s1_3">
          суралцаж, ажиллах, ёс суртахуунтай, сайн зан чанартай иргэнийг
          төлөвшүүлэхэд{" "}
        </span>
        <span id="to_3" class="t s1_3">
          сургуулийн удирдах ажилтан, багш, хамт олон хамгийн чухал үүрэг
          хариуцлагыг{" "}
        </span>
        <span id="tp_3" class="t s1_3">
          хүлээнэ. Ялангуяа технологийн хурдтай өөрчлөлт, байгаль орчин, нийгэмд
          гарч буй,{" "}
        </span>
        <span id="tq_3" class="t s1_3">
          цаашид тулгарах сорил, бэрхшээлүүдийг даван туулах, зохицон амьдрах
          чадвартай{" "}
        </span>
        <span id="tr_3" class="t s1_3">
          хүүхэд, залуу үеийг төлөвшүүлэхэд сургуулиуд өмнө байгаагүй шинэ
          үүрэг, зохион{" "}
        </span>
        <span id="ts_3" class="t s1_3">
          байгуулалттай болох шаардлагатай болж байна. Энэ саад бэрхшээлийг шинэ
          чиг{" "}
        </span>
        <span id="tt_3" class="t s1_3">
          үүрэг, зохион байгуулалтаар даван туулахад боловсрол өөрөө хувьсан
          өөрчлөгдөх{" "}
        </span>
        <span id="tu_3" class="t s1_3">
          хэрэгтэй байгааг дэлхий нийтээр хүлээн зөвшөөрч байна.{" "}
        </span>
        <span id="tv_3" class="t s1_3">
          Монгол Улсын боловсролын хуулиуд багцаараа шинэчлэгдсэн бөгөөд дараах{" "}
        </span>
        <span id="tw_3" class="t s1_3">
          өөрчлөлт, шинэчлэлийг хэрэгжүүлэх боломжтой болсон. Үүнд:{" "}
        </span>
        <span id="tx_3" class="t s1_3">
          -{" "}
        </span>
        <span id="ty_3" class="t s1_3">
          Сургалтын хөтөлбөрийн шинэчлэл{" "}
        </span>
        <span id="tz_3" class="t s1_3">
          -{" "}
        </span>
        <span id="t10_3" class="t s1_3">
          Гүйцэтгэл, үр дүнд суурилсан санхүүжилт{" "}
        </span>
        <span id="t11_3" class="t s1_3">
          -{" "}
        </span>
        <span id="t12_3" class="t s1_3">
          Багшийн мэргэжлийн хөгжлийн шинэчлэл{" "}
        </span>
        <span id="t13_3" class="t s1_3">
          -{" "}
        </span>
        <span id="t14_3" class="t s1_3">
          Олон улсын жишигт хүрсэн үндэсний үнэлгээ{" "}
        </span>
        <span id="t15_3" class="t s1_3">
          -{" "}
        </span>
        <span id="t16_3" class="t s1_3">
          Боловсролын цахим шилжилт{" "}
        </span>
        <span id="t17_3" class="t s1_3">
          -{" "}
        </span>
        <span id="t18_3" class="t s1_3">
          Сургуулийн менежмент{" "}
        </span>
        <span id="t19_3" class="t s1_3">
          Дээрх шинэчлэлийг хэрэгжүүлэх сургуулийн удирдах ажилтнуудад зориулсан{" "}
        </span>
        <span id="t1a_3" class="t s1_3">
          Ерөнхий боловсролын сургуулийн менежментийг дэмжих арга зүйн зөвлөмжид{" "}
        </span>
        <span id="t1b_3" class="t s1_3">
          суралцагч төвтэй сургуулийн менежментийг хөгжүүлэх, үнэлгээний үр дүнд
          шинжилгээ{" "}
        </span>
        <span id="t1c_3" class="t s1_3">
          хийж, түүнд үндэслэн зөв шийдвэр гаргах, сургуулийн менежментийг
          сайжруулах{" "}
        </span>
        <span id="t1d_3" class="t s1_3">
          арга зам, боломж, ижил төстэй байгууллагуудын туршлагыг багтаасан нь
          ажлын{" "}
        </span>
        <span id="t1e_3" class="t s1_3">
          байрандаа тасралтгүй суралцах, хөгжих,{" "}
        </span>
        <span id="t1f_3" class="t s1_3">
          хамт олноо манлайлан зөв менежментээр{" "}
        </span>
        <span id="t1g_3" class="t s1_3">
          багаа удирдах, ур чадвараа дээшлүүлэх боломжтой болсноороо онцлогтой.{" "}
        </span>
        <span id="t1h_3" class="t s1_3">
          Боловсролын сэргэлтээр хот, хөдөөгийн болоод хувийн, төрийн сургуулийн{" "}
        </span>
        <span id="t1i_3" class="t s1_3">
          чанарын ялгааг арилгаж, хамгийн сайн сургууль, цэцэрлэг гэрт ойрхон
          сургууль,{" "}
        </span>
        <span id="t1j_3" class="t s1_3">
          цэцэрлэгийн тогтолцоотой болох энэ том үйлс та бүхний сэтгэл, зүтгэл,
          мэргэжлийн{" "}
        </span>
        <span id="t1k_3" class="t s1_3">
          манлайлалаас шалтгаалах болно. Энэхүү зөвлөмжийг амжилттай
          хэрэгжүүлэхэд{" "}
        </span>
        <span id="t1l_3" class="t s1_3">
          орон нутгийн боловсролын асуудал эрхэлсэн төрийн захиргааны төв
          байгууллага{" "}
        </span>
        <span id="t1m_3" class="t s1_3">
          нэгдсэн удирдлага, зохион байгуулалтаар ханган, боловсролын
          байгууллагууд{" "}
        </span>
        <span id="t1n_3" class="t s1_3">
          харилцан уялдаатай хамтран ажиллана гэдэгт итгэж байна.{" "}
        </span>
        <span id="t1o_3" class="t s1_3">
          Та бүхний удирдахуйн ур чадвар, манлайллаас байгууллагын соёл, хамт
          олон,{" "}
        </span>
        <span id="t1p_3" class="t s1_3">
          хүүхдийн хөгжилд гарах ахиц өөрчлөлт хамаарах тул цаг үргэлж
          менежменттэй{" "}
        </span>
        <span id="t1q_3" class="t s1_3">
          манлайлагч байхыг хүсэн ерөөе.{" "}
        </span>
        <span id="t1r_3" class="t s2_3">
          БОЛОВСРОЛ{" "}
        </span>
        <span id="t1s_3" class="t s2_3">
          ШИНЖЛЭХ УХААНЫ САЙД{" "}
        </span>
        <span id="t1t_3" class="t s2_3">
          Л.ЭНХ-АМГАЛАН{" "}
        </span>
        <span id="t1u_3" class="t s3_3">
          3{" "}
        </span>
      </div>
    </div>
  );
}
