import React from "react";
export default function FourtySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_47{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_47{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_47{left:94px;bottom:807px;}
#t4_47{left:114px;bottom:807px;letter-spacing:-0.22px;word-spacing:0.09px;}
#t5_47{left:208px;bottom:935px;}
#t6_47{left:230px;bottom:935px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t7_47{left:208px;bottom:916px;}
#t8_47{left:230px;bottom:917px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t9_47{left:208px;bottom:898px;}
#ta_47{left:230px;bottom:899px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tb_47{left:230px;bottom:881px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tc_47{left:230px;bottom:862px;letter-spacing:-0.24px;}
#td_47{left:208px;bottom:843px;}
#te_47{left:230px;bottom:844px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tf_47{left:230px;bottom:826px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tg_47{left:208px;bottom:806px;}
#th_47{left:230px;bottom:807px;letter-spacing:-0.16px;word-spacing:0.04px;}
#ti_47{left:230px;bottom:789px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tj_47{left:230px;bottom:771px;letter-spacing:-0.19px;}
#tk_47{left:208px;bottom:751px;}
#tl_47{left:230px;bottom:752px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tm_47{left:230px;bottom:734px;letter-spacing:-0.18px;word-spacing:0.06px;}
#tn_47{left:208px;bottom:715px;}
#to_47{left:230px;bottom:715px;letter-spacing:-0.14px;word-spacing:0.01px;}
#tp_47{left:230px;bottom:697px;letter-spacing:-0.18px;word-spacing:0.05px;}
#tq_47{left:435px;bottom:935px;}
#tr_47{left:457px;bottom:935px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ts_47{left:435px;bottom:916px;}
#tt_47{left:457px;bottom:917px;letter-spacing:-0.2px;word-spacing:-0.22px;}
#tu_47{left:435px;bottom:898px;}
#tv_47{left:457px;bottom:899px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tw_47{left:457px;bottom:881px;letter-spacing:-0.18px;}
#tx_47{left:435px;bottom:861px;}
#ty_47{left:457px;bottom:862px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tz_47{left:457px;bottom:844px;letter-spacing:-0.19px;}
#t10_47{left:435px;bottom:825px;}
#t11_47{left:457px;bottom:825px;letter-spacing:-0.2px;word-spacing:0.07px;}
#t12_47{left:457px;bottom:807px;letter-spacing:-0.23px;word-spacing:0.1px;}
#t13_47{left:435px;bottom:788px;}
#t14_47{left:457px;bottom:789px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t15_47{left:457px;bottom:771px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t16_47{left:457px;bottom:752px;letter-spacing:-0.24px;word-spacing:0.11px;}
#t17_47{left:87px;bottom:636px;letter-spacing:-0.17px;word-spacing:4.42px;}
#t18_47{left:87px;bottom:616px;letter-spacing:-0.15px;word-spacing:8.88px;}
#t19_47{left:87px;bottom:596px;letter-spacing:-0.16px;word-spacing:3.49px;}
#t1a_47{left:87px;bottom:576px;letter-spacing:-0.17px;word-spacing:6.84px;}
#t1b_47{left:87px;bottom:556px;letter-spacing:-0.16px;word-spacing:5.66px;}
#t1c_47{left:87px;bottom:535px;letter-spacing:-0.16px;word-spacing:2.2px;}
#t1d_47{left:87px;bottom:515px;letter-spacing:-0.18px;word-spacing:0.76px;}
#t1e_47{left:87px;bottom:495px;letter-spacing:-0.15px;word-spacing:-0.64px;}
#t1f_47{left:87px;bottom:475px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t1g_47{left:87px;bottom:455px;letter-spacing:-0.14px;word-spacing:3.96px;}
#t1h_47{left:87px;bottom:435px;letter-spacing:-0.17px;word-spacing:8.39px;}
#t1i_47{left:87px;bottom:414px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1j_47{left:87px;bottom:381px;letter-spacing:-0.14px;word-spacing:-1.21px;}
#t1k_47{left:87px;bottom:361px;letter-spacing:-0.17px;word-spacing:0.16px;}
#t1l_47{left:87px;bottom:341px;letter-spacing:-0.14px;word-spacing:-0.3px;}
#t1m_47{left:87px;bottom:321px;letter-spacing:-0.13px;word-spacing:5.56px;}
#t1n_47{left:87px;bottom:301px;letter-spacing:-0.14px;word-spacing:0.23px;}
#t1o_47{left:87px;bottom:280px;letter-spacing:-0.18px;word-spacing:5.57px;}
#t1p_47{left:87px;bottom:260px;letter-spacing:-0.17px;word-spacing:9.13px;}
#t1q_47{left:87px;bottom:240px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1r_47{left:260px;bottom:240px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1s_47{left:87px;bottom:207px;letter-spacing:-0.17px;word-spacing:0.85px;}
#t1t_47{left:87px;bottom:187px;letter-spacing:-0.14px;word-spacing:6.23px;}
#t1u_47{left:87px;bottom:167px;letter-spacing:-0.13px;word-spacing:6.97px;}
#t1v_47{left:205px;bottom:167px;letter-spacing:-0.19px;word-spacing:7.04px;}
#t1w_47{left:87px;bottom:146px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_47{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_47{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_47{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_47{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s4_47{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_47{font-size:17px;font-family:MogulArial_16s;color:#963E97;}
.t.m0_47{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts47" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg47" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="47/47.svg"
          type="image/svg+xml"
          id="pdf47"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_47" class="t s1_47">
          47{" "}
        </span>
        <span id="t3_47" class="t s2_47">
          6{" "}
        </span>
        <span id="t4_47" class="t s2_47">
          Сул тал{" "}
        </span>
        <span id="t5_47" class="t s3_47">
          •{" "}
        </span>
        <span id="t6_47" class="t s2_47">
          Субьектив байдал илрэх{" "}
        </span>
        <span id="t7_47" class="t s3_47">
          •{" "}
        </span>
        <span id="t8_47" class="t s2_47">
          Цаг хугацаа, нөөц их шаардах{" "}
        </span>
        <span id="t9_47" class="t s3_47">
          •{" "}
        </span>
        <span id="ta_47" class="t s2_47">
          Анхаарал хандуулах{" "}
        </span>
        <span id="tb_47" class="t s2_47">
          шаардлагатай зүйлсийг{" "}
        </span>
        <span id="tc_47" class="t s2_47">
          орхигдуулах{" "}
        </span>
        <span id="td_47" class="t s3_47">
          •{" "}
        </span>
        <span id="te_47" class="t s2_47">
          Үнэлгээний хэлбэр, арга{" "}
        </span>
        <span id="tf_47" class="t s2_47">
          хэрэгсэл тохирохгүй байх{" "}
        </span>
        <span id="tg_47" class="t s3_47">
          •{" "}
        </span>
        <span id="th_47" class="t s2_47">
          Гүйцэтгэлийн шалгуур,{" "}
        </span>
        <span id="ti_47" class="t s2_47">
          үзүүлэлтэд хэт анхаарал{" "}
        </span>
        <span id="tj_47" class="t s2_47">
          хандуулах{" "}
        </span>
        <span id="tk_47" class="t s3_47">
          •{" "}
        </span>
        <span id="tl_47" class="t s2_47">
          Хүлээн зөвшөөрөгдөх байдал{" "}
        </span>
        <span id="tm_47" class="t s2_47">
          хангалтгүй байх{" "}
        </span>
        <span id="tn_47" class="t s3_47">
          •{" "}
        </span>
        <span id="to_47" class="t s2_47">
          Өөрчлөлтийг эсэргүүцэх{" "}
        </span>
        <span id="tp_47" class="t s2_47">
          хандлага илрэх{" "}
        </span>
        <span id="tq_47" class="t s3_47">
          •{" "}
        </span>
        <span id="tr_47" class="t s2_47">
          Зардал, нөөц их зарцуулах{" "}
        </span>
        <span id="ts_47" class="t s3_47">
          •{" "}
        </span>
        <span id="tt_47" class="t s2_47">
          Хугацааны хувьд хязгаарлагдмал{" "}
        </span>
        <span id="tu_47" class="t s3_47">
          •{" "}
        </span>
        <span id="tv_47" class="t s2_47">
          Сургуулийн үйл ажиллагааг{" "}
        </span>
        <span id="tw_47" class="t s2_47">
          тасалдуулах{" "}
        </span>
        <span id="tx_47" class="t s3_47">
          •{" "}
        </span>
        <span id="ty_47" class="t s2_47">
          Нэг удаагийн агшин зуурын дүр{" "}
        </span>
        <span id="tz_47" class="t s2_47">
          зураг{" "}
        </span>
        <span id="t10_47" class="t s3_47">
          •{" "}
        </span>
        <span id="t11_47" class="t s2_47">
          Оролцогч талуудын оролцоо{" "}
        </span>
        <span id="t12_47" class="t s2_47">
          хязгаарлагдмал байх{" "}
        </span>
        <span id="t13_47" class="t s3_47">
          •{" "}
        </span>
        <span id="t14_47" class="t s2_47">
          Хөндлөнгийн үнэлгээний{" "}
        </span>
        <span id="t15_47" class="t s2_47">
          шалгуур, үзүүлэлтэд хэтэрхий ач{" "}
        </span>
        <span id="t16_47" class="t s2_47">
          холбогдол өгөх{" "}
        </span>
        <span id="t17_47" class="t s4_47">
          Өөрийн болон хөндлөнгийн үнэлгээг хослуулснаар сургууль нь сургалт,{" "}
        </span>
        <span id="t18_47" class="t s4_47">
          үйл ажиллагаа, үр дүн, нөлөөлж байгаа хүчин зүйлстэй холбоотой{" "}
        </span>
        <span id="t19_47" class="t s4_47">
          цогц мэдээлэлтэй болох юм. Үнэлгээ хийх нь сургууль давуу болон сул{" "}
        </span>
        <span id="t1a_47" class="t s4_47">
          талаа илрүүлэх, сайжруулах шаардлагатай чиглэлүүдийг тодорхойлох,{" "}
        </span>
        <span id="t1b_47" class="t s4_47">
          боловсролын үйлчилгээний үр дүнг сайжруулах, нотолгоонд суурилсан{" "}
        </span>
        <span id="t1c_47" class="t s4_47">
          шийдвэр гаргахын тулд системтэйгээр мэдээлэл цуглуулж буй явдал юм.{" "}
        </span>
        <span id="t1d_47" class="t s4_47">
          Өөрөөр хэлбэл, сургуулийн гүйцэтгэлийн үнэлгээ нь байнгын сайжруулалт,{" "}
        </span>
        <span id="t1e_47" class="t s4_47">
          нотолгоонд суурилсан шийдвэр гаргах, хариуцлагын түвшнийг нэмэгдүүлэх,{" "}
        </span>
        <span id="t1f_47" class="t s4_47">
          оролцогч талуудын оролцоог хангах чухал хэрэгсэл юм.{" "}
        </span>
        <span id="t1g_47" class="t s4_47">
          Боловсролын чанарын үнэлгээ, гүйцэтгэлийн үнэлгээ зэрэг хөндлөнгийн{" "}
        </span>
        <span id="t1h_47" class="t s4_47">
          үнэлгээнд сургуулиуд хамрагдаж туршлагатай болсон. Харин өөрийн{" "}
        </span>
        <span id="t1i_47" class="t s4_47">
          үнэлгээний хувьд сургуулиудын туршлага ялгаатай байна.{" "}
        </span>
        <span id="t1j_47" class="t s4_47">
          Өнөөгийн байдлаар хүчин төгөлдөр мөрдөгдөж буй БСШУС-ын сайдын 2019{" "}
        </span>
        <span id="t1k_47" class="t s4_47">
          оны А/803 тушаалаар баталсан “Цэцэрлэг, ЕБС-ийн үйл ажиллагааг үнэлэх{" "}
        </span>
        <span id="t1l_47" class="t s4_47">
          журам”-д сургууль нь жил бүр батлагдсан шалгуурын дагуу өөрийн
          үнэлгээг{" "}
        </span>
        <span id="t1m_47" class="t s4_47">
          хийхээр тусгажээ. Энэхүү журамд “өөрийн үнэлгээг сургууль, цэцэрлэг{" "}
        </span>
        <span id="t1n_47" class="t s4_47">
          өөрийн зорилгодоо хүрч буй эсэх, хэрэгжүүлж буй үйл ажиллагааны чанар,{" "}
        </span>
        <span id="t1o_47" class="t s4_47">
          нийцийг баримт нотолгоонд тулгуурлан хамтын оролцоотойгоор үнэлж,{" "}
        </span>
        <span id="t1p_47" class="t s4_47">
          байгууллагын түвшинд сайжруулах шаардлагатай асуудлыг илрүүлэх{" "}
        </span>
        <span id="t1q_47" class="t s4_47">
          оношилгоог ойлгоно”{" "}
        </span>
        <span id="t1r_47" class="t s4_47">
          гэж тодорхойлсон байна.{" "}
        </span>
        <span id="t1s_47" class="t s5_47">
          Үнэлгээ нь сургалт, үйл ажиллагааны явц, үр дүнгийн мэдээлэл
          цуглуулах,{" "}
        </span>
        <span id="t1t_47" class="t s5_47">
          шинжилгээ хийх, дүгнэх, баталгаажуулах, тайлагнах зэргийг багтаасан{" "}
        </span>
        <span id="t1u_47" class="t s5_47">
          үйл явц юм.{" "}
        </span>
        <span id="t1v_47" class="t s4_47">
          Тодорхой үзүүлэлтийн дагуу бодит өгөгдөл, мэдээллийг{" "}
        </span>
        <span id="t1w_47" class="t s4_47">
          цуглуулснаар та бүхэн шинжлэн судлах материалтай болно.{" "}
        </span>
      </div>
    </div>
  );
}
