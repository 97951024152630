import React from "react";
export default function F154() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_154{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_154{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:11.86px;}
#t3_154{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:3.38px;}
#t4_154{left:87px;bottom:901px;letter-spacing:-0.15px;word-spacing:-0.98px;}
#t5_154{left:87px;bottom:880px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t6_154{left:156px;bottom:843px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t7_154{left:87px;bottom:578px;letter-spacing:-0.16px;word-spacing:2.56px;}
#t8_154{left:87px;bottom:558px;letter-spacing:-0.14px;word-spacing:5.27px;}
#t9_154{left:87px;bottom:537px;letter-spacing:-0.12px;word-spacing:0.56px;}
#ta_154{left:87px;bottom:517px;letter-spacing:-0.15px;word-spacing:-0.56px;}
#tb_154{left:87px;bottom:497px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tc_154{left:87px;bottom:457px;letter-spacing:-0.15px;word-spacing:-1.83px;}
#td_154{left:87px;bottom:437px;letter-spacing:-0.13px;word-spacing:2.99px;}
#te_154{left:87px;bottom:416px;letter-spacing:-0.09px;}
#tf_154{left:108px;bottom:396px;}
#tg_154{left:141px;bottom:396px;letter-spacing:-0.15px;word-spacing:6.11px;}
#th_154{left:141px;bottom:376px;letter-spacing:-0.13px;word-spacing:6.43px;}
#ti_154{left:141px;bottom:356px;letter-spacing:-0.11px;}
#tj_154{left:108px;bottom:336px;}
#tk_154{left:141px;bottom:336px;letter-spacing:-0.12px;word-spacing:1.41px;}
#tl_154{left:141px;bottom:316px;letter-spacing:-0.26px;}
#tm_154{left:108px;bottom:295px;}
#tn_154{left:141px;bottom:295px;letter-spacing:-0.14px;word-spacing:0.04px;}
#to_154{left:108px;bottom:275px;}
#tp_154{left:141px;bottom:275px;letter-spacing:-0.16px;word-spacing:1.94px;}
#tq_154{left:141px;bottom:255px;letter-spacing:-0.1px;word-spacing:0.01px;}
#tr_154{left:108px;bottom:235px;}
#ts_154{left:141px;bottom:235px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tt_154{left:141px;bottom:215px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tu_154{left:108px;bottom:195px;}
#tv_154{left:141px;bottom:195px;letter-spacing:-0.16px;word-spacing:7.17px;}
#tw_154{left:141px;bottom:174px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tx_154{left:87px;bottom:134px;letter-spacing:-0.16px;word-spacing:4.09px;}
#ty_154{left:87px;bottom:114px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_154{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_154{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_154{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_154{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts154" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg154" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="154/154.svg"
          type="image/svg+xml"
          id="pdf154"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_154" class="t s0_154">
          154{" "}
        </span>
        <span id="t2_154" class="t s1_154">
          Сургуулийн менежментийг сайжруулах чиглэлээр ажилласан зарим{" "}
        </span>
        <span id="t3_154" class="t s1_154">
          сургуулиудын багш нарын төлөөллөөс мэргэжлийн хөгжилтэй холбоотой{" "}
        </span>
        <span id="t4_154" class="t s1_154">
          асуулгын судалгаа авч багшийг мэргэжлийн хөгжлийн хэрэгцээг тодорхойлж{" "}
        </span>
        <span id="t5_154" class="t s1_154">
          болохыг зөвлөж байна.{" "}
        </span>
        <span id="t6_154" class="t s2_154">
          Зураг 3.26. Багшийн хөгжих хэрэгцээг тодорхойлох агуулгын хувилбар{" "}
        </span>
        <span id="t7_154" class="t s1_154">
          Үндсэн, зорилтот болон сонголтот сургалтаас гадна сургуульд суурилсан{" "}
        </span>
        <span id="t8_154" class="t s1_154">
          багшийн мэргэжлийн хөгжлийн үйл ажиллагаа болох үйлийн судалгаа,{" "}
        </span>
        <span id="t9_154" class="t s1_154">
          хичээлийн судалгаа, мэргэжлийн хөгжлийн бүлгийн үйл ажиллагаа, ахмад,{" "}
        </span>
        <span id="ta_154" class="t s1_154">
          туршлагатай багшийг дагалдан сурах, бусад багш нарын хичээлд суух
          зэрэг{" "}
        </span>
        <span id="tb_154" class="t s1_154">
          хэлбэрүүдийг өргөн ашиглаарай.{" "}
        </span>
        <span id="tc_154" class="t s1_154">
          Мөн сургуулийн удирдлагын зүгээс залшгүй хамрагдахаас бусад мэргэжлийн{" "}
        </span>
        <span id="td_154" class="t s1_154">
          хөгжлийн үйл ажиллагааг багш өөрөө сонгох боломж нөхцөлөөр хангаж,{" "}
        </span>
        <span id="te_154" class="t s1_154">
          дэмжээрэй. Ялангуяа,{" "}
        </span>
        <span id="tf_154" class="t s3_154">
          •{" "}
        </span>
        <span id="tg_154" class="t s1_154">
          Мэргэжил дээшлүүлэх сургалтад хамрагдсаны дараа багш сурч{" "}
        </span>
        <span id="th_154" class="t s1_154">
          мэдсэн зүйлээ ажилдаа туршин хэрэгжүүлэх, үр дүнг бусадтай{" "}
        </span>
        <span id="ti_154" class="t s1_154">
          хуваалцах,{" "}
        </span>
        <span id="tj_154" class="t s3_154">
          •{" "}
        </span>
        <span id="tk_154" class="t s1_154">
          Шинээр сурч мэдсэн зүйлсээ хэрэгжүүлж, туршихад нь цаг боломж{" "}
        </span>
        <span id="tl_154" class="t s1_154">
          олгох,{" "}
        </span>
        <span id="tm_154" class="t s3_154">
          •{" "}
        </span>
        <span id="tn_154" class="t s1_154">
          Сургалтын дараа ажлын ачааллаар хэт дарахгүй байх,{" "}
        </span>
        <span id="to_154" class="t s3_154">
          •{" "}
        </span>
        <span id="tp_154" class="t s1_154">
          Сурч мэдсэн зүйлээ хэрэгжүүлэхэд нь тулгарч буй бэрхшээлийг нь{" "}
        </span>
        <span id="tq_154" class="t s1_154">
          сонсох, зөвлөн туслах,{" "}
        </span>
        <span id="tr_154" class="t s3_154">
          •{" "}
        </span>
        <span id="ts_154" class="t s1_154">
          Бусад сургуулийн багш нартай хамтран суралцах боломж бүрдүүлж,{" "}
        </span>
        <span id="tt_154" class="t s1_154">
          холбоо тогтоож өгөх{" "}
        </span>
        <span id="tu_154" class="t s3_154">
          •{" "}
        </span>
        <span id="tv_154" class="t s1_154">
          Сургуулийн дотоод нөөц боломжоо ашиглан багшийн хөгжлийг{" "}
        </span>
        <span id="tw_154" class="t s1_154">
          дэмжих орчныг бий болгох гэх мэт{" "}
        </span>
        <span id="tx_154" class="t s1_154">
          Мөн багшийн ёс зүй, харилцаа, хандлагатай холбоотой дараах нийтлэг{" "}
        </span>
        <span id="ty_154" class="t s1_154">
          зүйлсэд сургуулиудын удирдлага анхаараарай. Үүнд:{" "}
        </span>
      </div>
    </div>
  );
}
