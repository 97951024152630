import React from "react";
export default function F135() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_135{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_135{left:142px;bottom:926px;letter-spacing:0.18px;}
#t3_135{left:87px;bottom:894px;letter-spacing:-0.12px;word-spacing:6.16px;}
#t4_135{left:87px;bottom:874px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_135{left:87px;bottom:833px;letter-spacing:-0.49px;word-spacing:0.05px;}
#t6_135{left:114px;bottom:809px;}
#t7_135{left:142px;bottom:809px;letter-spacing:-0.13px;word-spacing:2.78px;}
#t8_135{left:142px;bottom:789px;letter-spacing:-0.13px;word-spacing:2.1px;}
#t9_135{left:142px;bottom:768px;letter-spacing:-0.19px;word-spacing:3.29px;}
#ta_135{left:142px;bottom:748px;letter-spacing:-0.13px;word-spacing:2.41px;}
#tb_135{left:142px;bottom:728px;letter-spacing:-0.15px;word-spacing:-1.07px;}
#tc_135{left:142px;bottom:708px;letter-spacing:-0.13px;word-spacing:0.03px;}
#td_135{left:114px;bottom:679px;}
#te_135{left:142px;bottom:679px;letter-spacing:-0.14px;word-spacing:8.74px;}
#tf_135{left:142px;bottom:659px;letter-spacing:-0.14px;word-spacing:3.97px;}
#tg_135{left:142px;bottom:639px;letter-spacing:-0.12px;word-spacing:1.45px;}
#th_135{left:142px;bottom:619px;letter-spacing:-0.15px;word-spacing:2.05px;}
#ti_135{left:142px;bottom:598px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tj_135{left:114px;bottom:570px;}
#tk_135{left:142px;bottom:570px;letter-spacing:-0.14px;word-spacing:8.55px;}
#tl_135{left:142px;bottom:549px;letter-spacing:-0.14px;word-spacing:1.53px;}
#tm_135{left:142px;bottom:529px;letter-spacing:-0.15px;word-spacing:6.95px;}
#tn_135{left:142px;bottom:509px;letter-spacing:-0.15px;word-spacing:2.11px;}
#to_135{left:142px;bottom:489px;letter-spacing:-0.15px;word-spacing:2.95px;}
#tp_135{left:142px;bottom:469px;letter-spacing:-0.16px;word-spacing:2.71px;}
#tq_135{left:142px;bottom:449px;letter-spacing:-0.08px;}
#tr_135{left:87px;bottom:408px;letter-spacing:-0.16px;word-spacing:9.55px;}
#ts_135{left:87px;bottom:388px;letter-spacing:-0.49px;word-spacing:1.4px;}
#tt_135{left:87px;bottom:368px;letter-spacing:-0.47px;word-spacing:0.03px;}
#tu_135{left:87px;bottom:328px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tv_135{left:87px;bottom:307px;letter-spacing:-0.12px;word-spacing:-0.58px;}
#tw_135{left:87px;bottom:287px;letter-spacing:-0.12px;word-spacing:2.6px;}
#tx_135{left:87px;bottom:267px;letter-spacing:-0.16px;word-spacing:0.07px;}

.s0_135{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_135{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s2_135{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_135{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s4_135{font-size:17px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts135" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg135" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="135/135.svg"
          type="image/svg+xml"
          id="pdf135"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_135" class="t s0_135">
          135{" "}
        </span>
        <span id="t2_135" class="t s1_135">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_135" class="t s2_135">
          Сурагчдын гүйцэтгэлд (хөндлөнгийн үнэлгээ) хийсэн дүн шинжилгээнд{" "}
        </span>
        <span id="t4_135" class="t s2_135">
          үндэслэн багшийн хөгжих хэрэгцээг тодорхойлоход дэмжлэг үзүүлэх{" "}
        </span>
        <span id="t5_135" class="t s3_135">
          Багшийн өөрийн үнэлгээнд тулгуурлан хөгжих хэрэгцээгээ тодорхойлох{" "}
        </span>
        <span id="t6_135" class="t s4_135">
          •{" "}
        </span>
        <span id="t7_135" class="t s2_135">
          Багш өөрийгөө үнэлж, багшлахуйдаа эргэцүүлэл хийх нь багшийн{" "}
        </span>
        <span id="t8_135" class="t s2_135">
          мэргэжлийн тасралтгүй хөгжлийн нэг гол стратеги бөгөөд багшийн{" "}
        </span>
        <span id="t9_135" class="t s2_135">
          мэдлэг, гүйцэтгэл, итгэл үнэмшил, үр дүнг шударгаар үнэлэх, үйл{" "}
        </span>
        <span id="ta_135" class="t s2_135">
          ажиллагаандаа шүүмжлэлтэй хандах, өөрийн давуу тал, хөгжлийн{" "}
        </span>
        <span id="tb_135" class="t s2_135">
          зорилтоо таних, ажлын арга барилаа хөгжүүлэхэд мэдээлэлтэй байх,{" "}
        </span>
        <span id="tc_135" class="t s2_135">
          идэвхтэй үүрэг гүйцэтгэж сайжруулахад ихээхэн тустай.{" "}
        </span>
        <span id="td_135" class="t s4_135">
          •{" "}
        </span>
        <span id="te_135" class="t s2_135">
          Өөрийгөө үнэлэх нь хичээл дээр болон ажлын байран дээр{" "}
        </span>
        <span id="tf_135" class="t s2_135">
          тохиолдсон зүйлсийг бүртгэх, цуглуулах, тэдгээрт дүн шинжилгээ{" "}
        </span>
        <span id="tg_135" class="t s2_135">
          хийх, эргэцүүлэл хийх үйл явцаас эхлэх бөгөөд өөрийн үнэлгээний{" "}
        </span>
        <span id="th_135" class="t s2_135">
          зарим арга хэрэгсэлтэй ажиллаж, багшлахуйгаа сайжруулах бодит{" "}
        </span>
        <span id="ti_135" class="t s2_135">
          хэрэгцээгээ олж мэдэхэд чиглэнэ.{" "}
        </span>
        <span id="tj_135" class="t s4_135">
          •{" "}
        </span>
        <span id="tk_135" class="t s2_135">
          Сурагчдын сул үзүүлэлттэй суралцахуйн зорилтуудын хүрээнд{" "}
        </span>
        <span id="tl_135" class="t s2_135">
          багш өөрийн үнэлгээ, эргэцүүлэл хийхээс гадна сургуулиуд өөрийн{" "}
        </span>
        <span id="tm_135" class="t s2_135">
          харьяа БШУГ болон мэргэжлийн байгууллагууд, ББИДС, бусад{" "}
        </span>
        <span id="tn_135" class="t s2_135">
          сургуулиудтай хамтран багшийн өөрийн үнэлгээний санг бүрдүүлж{" "}
        </span>
        <span id="to_135" class="t s2_135">
          болно. Өөрийн үнэлгээний энэхүү хуудаст судлагдахууны хүрээнд{" "}
        </span>
        <span id="tp_135" class="t s2_135">
          багшийн зайлшгүй мэдэж байвал зохих агуулгуудыг багтаах нь үр{" "}
        </span>
        <span id="tq_135" class="t s2_135">
          дүнтэй.{" "}
        </span>
        <span id="tr_135" class="t s2_135">
          Багшийн үнэлгээний хуудсыг боловсруулахад хичээл сургалтын үйл{" "}
        </span>
        <span id="ts_135" class="t s2_135">
          ажиллагаа, арга зүй төлөвлөлттэй холбоотой байж болох зарим
          асуултуудыг{" "}
        </span>
        <span id="tt_135" class="t s2_135">
          жишээгээр орууллаа.{" "}
        </span>
        <span id="tu_135" class="t s2_135">
          Хичээлийн төлөвлөлтийн талаар өөрийгөө үнэлэх асуултуудын жишээ:{" "}
        </span>
        <span id="tv_135" class="t s2_135">
          Хичээлийн төлөвлөгөө гэдэг нь сурагчид юу сурах ёстой, түүнийг
          хичээлийн{" "}
        </span>
        <span id="tw_135" class="t s2_135">
          цагаар хэрхэн үр дүнтэйгээр хэрэгжүүлэх, зорилгодоо хүрсэн эсэхээ яаж{" "}
        </span>
        <span id="tx_135" class="t s2_135">
          үнэлэх тухай зураглал юм.{" "}
        </span>
      </div>
    </div>
  );
}
