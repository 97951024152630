import React from "react";
export default function F145() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_145{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_145{left:142px;bottom:926px;letter-spacing:0.18px;}
#t3_145{left:139px;bottom:894px;letter-spacing:-0.13px;word-spacing:11.06px;}
#t4_145{left:139px;bottom:874px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t5_145{left:135px;bottom:845px;letter-spacing:-0.05px;}
#t6_145{left:162px;bottom:845px;letter-spacing:-0.12px;word-spacing:5.07px;}
#t7_145{left:162px;bottom:825px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t8_145{left:135px;bottom:800px;letter-spacing:-0.05px;}
#t9_145{left:162px;bottom:800px;letter-spacing:-0.15px;word-spacing:2.52px;}
#ta_145{left:162px;bottom:780px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tb_145{left:135px;bottom:755px;letter-spacing:-0.05px;}
#tc_145{left:162px;bottom:755px;letter-spacing:-0.12px;word-spacing:7.79px;}
#td_145{left:162px;bottom:735px;letter-spacing:-0.13px;word-spacing:0.03px;}
#te_145{left:186px;bottom:702px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tf_145{left:87px;bottom:421px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tg_145{left:265px;bottom:395px;letter-spacing:0.14px;}
#th_145{left:91px;bottom:364px;letter-spacing:-0.16px;}
#ti_145{left:190px;bottom:364px;letter-spacing:-0.14px;}
#tj_145{left:461px;bottom:364px;letter-spacing:-0.17px;}
#tk_145{left:91px;bottom:343px;letter-spacing:-0.17px;}
#tl_145{left:151px;bottom:343px;letter-spacing:-0.2px;word-spacing:-0.58px;}
#tm_145{left:305px;bottom:343px;letter-spacing:-0.17px;word-spacing:-0.28px;}
#tn_145{left:305px;bottom:327px;letter-spacing:-0.16px;word-spacing:0.05px;}
#to_145{left:91px;bottom:304px;letter-spacing:-0.32px;}
#tp_145{left:151px;bottom:304px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tq_145{left:305px;bottom:304px;letter-spacing:-0.16px;word-spacing:0.32px;}
#tr_145{left:305px;bottom:287px;letter-spacing:-0.26px;}
#ts_145{left:91px;bottom:265px;letter-spacing:-0.39px;}
#tt_145{left:151px;bottom:265px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tu_145{left:151px;bottom:248px;letter-spacing:-0.18px;}
#tv_145{left:305px;bottom:265px;letter-spacing:-0.18px;word-spacing:4.49px;}
#tw_145{left:305px;bottom:248px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tx_145{left:91px;bottom:227px;letter-spacing:-0.18px;}
#ty_145{left:151px;bottom:227px;letter-spacing:-0.13px;}
#tz_145{left:151px;bottom:211px;letter-spacing:-0.15px;}
#t10_145{left:151px;bottom:194px;letter-spacing:-0.2px;}
#t11_145{left:305px;bottom:227px;letter-spacing:-0.19px;word-spacing:7.41px;}
#t12_145{left:305px;bottom:211px;letter-spacing:-0.16px;word-spacing:3.39px;}
#t13_145{left:305px;bottom:194px;letter-spacing:-0.2px;}
#t14_145{left:91px;bottom:170px;letter-spacing:-0.39px;}
#t15_145{left:151px;bottom:170px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t16_145{left:151px;bottom:154px;letter-spacing:-0.14px;}
#t17_145{left:305px;bottom:170px;letter-spacing:-0.16px;word-spacing:2.54px;}
#t18_145{left:305px;bottom:154px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t19_145{left:91px;bottom:133px;letter-spacing:-0.37px;}
#t1a_145{left:151px;bottom:133px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t1b_145{left:305px;bottom:133px;letter-spacing:-0.16px;word-spacing:8.12px;}
#t1c_145{left:305px;bottom:116px;letter-spacing:-0.17px;word-spacing:-0.65px;}

.s0_145{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_145{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s2_145{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_145{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_145{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_145{font-size:17px;font-family:Arial-BoldMT_1li;color:#F7941E;}
.s6_145{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s7_145{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts145" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg145" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="145/145.svg"
          type="image/svg+xml"
          id="pdf145"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_145" class="t s0_145">
          145{" "}
        </span>
        <span id="t2_145" class="t s1_145">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_145" class="t s2_145">
          Багшийн манлайлал, хамтын ажиллагаа, соёлыг хөгжүүлэхэд{" "}
        </span>
        <span id="t4_145" class="t s2_145">
          мэргэжлийн хөгжлийн бүлгээр яагаад хамтарч ажиллах хэрэгтэй вэ?{" "}
        </span>
        <span id="t5_145" class="t s3_145">
          1.{" "}
        </span>
        <span id="t6_145" class="t s2_145">
          Багш, мэргэжлийн хөгжлийн бүлэгт нэгдсэнээр ажлын байран{" "}
        </span>
        <span id="t7_145" class="t s2_145">
          дээрээ хөгжих боломж нээгдэнэ.{" "}
        </span>
        <span id="t8_145" class="t s3_145">
          2.{" "}
        </span>
        <span id="t9_145" class="t s2_145">
          Багшлахуй болон суралцахуйд тулгамдсан асуудалд анхаарлаа{" "}
        </span>
        <span id="ta_145" class="t s2_145">
          төвлөрүүлэн ажиллах нөхцөл бүрдэнэ.{" "}
        </span>
        <span id="tb_145" class="t s3_145">
          3.{" "}
        </span>
        <span id="tc_145" class="t s2_145">
          Багш нар ажлын байран дээрээ хамтран хөгжих, харилцан{" "}
        </span>
        <span id="td_145" class="t s2_145">
          суралцах соёлыг бий болгоно.{" "}
        </span>
        <span id="te_145" class="t s4_145">
          Зураг 3.22. Мэргэжлийн хөгжлийн бүлгийн бүдүүвч* зураглал{" "}
        </span>
        <span id="tf_145" class="t s5_145">
          Мэргэжлийн хөгжлийн бүлэгт хэрхэн нэгдэн ажиллах вэ?{" "}
        </span>
        <span id="tg_145" class="t s6_145">
          Хүснэгт 3.19. Мэргэжлийн хөгжлийн бүлэг ажиллах алхам{" "}
        </span>
        <span id="th_145" class="t s7_145">
          Алхам{" "}
        </span>
        <span id="ti_145" class="t s4_145">
          Хийх ажил{" "}
        </span>
        <span id="tj_145" class="t s7_145">
          Агуулга{" "}
        </span>
        <span id="tk_145" class="t s4_145">
          НЭГ{" "}
        </span>
        <span id="tl_145" class="t s4_145">
          Асуудлаа тодорхойлох{" "}
        </span>
        <span id="tm_145" class="t s4_145">
          Гүйцэтгэлийн үнэлгээний үр дүн, судалгаа, санал асуулга{" "}
        </span>
        <span id="tn_145" class="t s4_145">
          гэх мэтээр тогтооно.{" "}
        </span>
        <span id="to_145" class="t s4_145">
          ХОЁР{" "}
        </span>
        <span id="tp_145" class="t s4_145">
          Бүлэг байгуулах{" "}
        </span>
        <span id="tq_145" class="t s4_145">
          Сонирхол, тулгамдсан асуудлаа шийдэх хэрэгцээ, дүрэм{" "}
        </span>
        <span id="tr_145" class="t s4_145">
          батлана.{" "}
        </span>
        <span id="ts_145" class="t s4_145">
          ГУРАВ{" "}
        </span>
        <span id="tt_145" class="t s4_145">
          Асуудлын шалтгааныг{" "}
        </span>
        <span id="tu_145" class="t s4_145">
          эрэлхийлэх{" "}
        </span>
        <span id="tv_145" class="t s4_145">
          Багшлах арга зүй, сурах арга барил, оролцоо, бусад{" "}
        </span>
        <span id="tw_145" class="t s4_145">
          хүчин зүйлийн нөлөөллийг гаргана.{" "}
        </span>
        <span id="tx_145" class="t s4_145">
          ДӨРӨВ{" "}
        </span>
        <span id="ty_145" class="t s4_145">
          Бүлгийн ажлын{" "}
        </span>
        <span id="tz_145" class="t s4_145">
          төлөвлөгөө{" "}
        </span>
        <span id="t10_145" class="t s4_145">
          боловсруулах{" "}
        </span>
        <span id="t11_145" class="t s4_145">
          Асуудал шийдэхэд чиглэсэн, уян хатан, зорилгодоо{" "}
        </span>
        <span id="t12_145" class="t s4_145">
          нийцсэн, ямар арга замаар асуудал шийдэх вэ гэдгээ{" "}
        </span>
        <span id="t13_145" class="t s4_145">
          тодорхойлно.{" "}
        </span>
        <span id="t14_145" class="t s4_145">
          ТАВ{" "}
        </span>
        <span id="t15_145" class="t s4_145">
          Төлөвлөгөөний дагуу{" "}
        </span>
        <span id="t16_145" class="t s4_145">
          ажиллах{" "}
        </span>
        <span id="t17_145" class="t s4_145">
          Явц, үр дүнг хэлэлцэнэ, сайжруулна. Хамтын оролцоо,{" "}
        </span>
        <span id="t18_145" class="t s4_145">
          манлайллыг чухалчилна{" "}
        </span>
        <span id="t19_145" class="t s4_145">
          ЗУРГАА{" "}
        </span>
        <span id="t1a_145" class="t s4_145">
          Үр дүнг хэлэлцэх{" "}
        </span>
        <span id="t1b_145" class="t s4_145">
          Бүлгийн ажлын үр дүнд асуудлаа хэрхэн шийдэв,{" "}
        </span>
        <span id="t1c_145" class="t s4_145">
          бэрхшээл, сургамж, цаашид анхаарах зүйлийг тодруулна.{" "}
        </span>
      </div>
    </div>
  );
}
