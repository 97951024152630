import React from "react";
export default function Two() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_1{left:330px;bottom:120px;letter-spacing:-0.31px;}
#t2_1{left:362px;bottom:102px;letter-spacing:-0.11px;}
#t3_1{left:153px;bottom:639px;letter-spacing:0.86px;}
#t4_1{left:196px;bottom:616px;letter-spacing:-0.34px;}
#t5_1{left:155px;bottom:730px;letter-spacing:-0.46px;word-spacing:0.2px;}
#t6_1{left:269px;bottom:426px;letter-spacing:-0.59px;word-spacing:0.41px;}
#t7_1{left:274px;bottom:371px;letter-spacing:-0.43px;}

.s0_1{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s1_1{font-size:17px;font-family:ArialMT_1lq;color:#000;}
.s2_1{font-size:67px;font-family:Arial-BoldMT_1li;color:#20BEC6;}
.s3_1{font-size:28px;font-family:Arial-BoldMT_1li;color:#A6A9AC;}
.s4_1{font-size:34px;font-family:Arial-BoldMT_1li;color:#1D9FA6;}
.s5_1{font-size:46px;font-family:Arial-BoldMT_1li;color:#1D9FA6;}`}
      </style>

      <style id="fonts1" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg1" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_1" class="t s0_1">
          Улаанбаатар{" "}
        </span>
        <span id="t2_1" class="t s1_1">
          2023{" "}
        </span>
        <span id="t3_1" class="t s2_1">
          СУРГУУЛИЙН{" "}
        </span>
        <span id="t4_1" class="t s3_1">
          МЕНЕЖМЕНТИЙГ ДЭМЖИХ{" "}
        </span>
        <span id="t5_1" class="t s4_1">
          ЕРӨНХИЙ БОЛОВСРОЛЫН{" "}
        </span>
        <span id="t6_1" class="t s5_1">
          АРГА ЗҮЙН{" "}
        </span>
        <span id="t7_1" class="t s5_1">
          ЗӨВЛӨМЖ{" "}
        </span>
      </div>
    </div>
  );
}
