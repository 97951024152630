import React from "react";
export default function OneHundredSeventyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_171{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_171{left:113px;bottom:941px;letter-spacing:-0.05px;}
#t3_171{left:139px;bottom:941px;letter-spacing:-0.11px;word-spacing:7.18px;}
#t4_171{left:139px;bottom:921px;letter-spacing:-0.1px;word-spacing:1.13px;}
#t5_171{left:139px;bottom:901px;letter-spacing:-0.1px;}
#t6_171{left:113px;bottom:872px;letter-spacing:-0.05px;}
#t7_171{left:139px;bottom:872px;letter-spacing:-0.1px;word-spacing:2.82px;}
#t8_171{left:139px;bottom:852px;letter-spacing:-0.11px;word-spacing:7.99px;}
#t9_171{left:139px;bottom:831px;letter-spacing:-0.1px;word-spacing:0.64px;}
#ta_171{left:139px;bottom:811px;letter-spacing:-0.11px;}
#tb_171{left:87px;bottom:782px;letter-spacing:0.07px;word-spacing:0.79px;}
#tc_171{left:87px;bottom:762px;letter-spacing:0.07px;word-spacing:-2.2px;}
#td_171{left:87px;bottom:742px;letter-spacing:0.07px;word-spacing:-0.43px;}
#te_171{left:87px;bottom:722px;letter-spacing:0.07px;}
#tf_171{left:87px;bottom:667px;letter-spacing:0.09px;word-spacing:0.15px;}
#tg_171{left:87px;bottom:624px;letter-spacing:-0.15px;}
#th_171{left:194px;bottom:624px;letter-spacing:-0.16px;word-spacing:0.05px;}
#ti_171{left:113px;bottom:600px;letter-spacing:-0.05px;}
#tj_171{left:139px;bottom:600px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tk_171{left:113px;bottom:575px;letter-spacing:-0.05px;}
#tl_171{left:139px;bottom:575px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tm_171{left:113px;bottom:551px;letter-spacing:-0.05px;}
#tn_171{left:139px;bottom:551px;letter-spacing:-0.13px;word-spacing:-0.23px;}
#to_171{left:139px;bottom:531px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tp_171{left:87px;bottom:497px;letter-spacing:-0.17px;word-spacing:0.06px;}
#tq_171{left:108px;bottom:473px;letter-spacing:-0.05px;}
#tr_171{left:139px;bottom:473px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ts_171{left:108px;bottom:448px;letter-spacing:-0.05px;}
#tt_171{left:139px;bottom:448px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tu_171{left:108px;bottom:424px;letter-spacing:-0.05px;}
#tv_171{left:139px;bottom:424px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tw_171{left:108px;bottom:399px;letter-spacing:-0.05px;}
#tx_171{left:139px;bottom:399px;letter-spacing:-0.11px;word-spacing:0.02px;}
#ty_171{left:108px;bottom:375px;letter-spacing:-0.05px;}
#tz_171{left:139px;bottom:375px;letter-spacing:-0.16px;word-spacing:5.65px;}
#t10_171{left:249px;bottom:375px;letter-spacing:-0.16px;word-spacing:5.66px;}
#t11_171{left:139px;bottom:355px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t12_171{left:108px;bottom:330px;letter-spacing:-0.05px;}
#t13_171{left:139px;bottom:330px;letter-spacing:-0.15px;word-spacing:-0.33px;}
#t14_171{left:139px;bottom:310px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t15_171{left:101px;bottom:255px;letter-spacing:0.12px;}
#t16_171{left:152px;bottom:255px;letter-spacing:0.12px;word-spacing:0.09px;}
#t17_171{left:323px;bottom:229px;letter-spacing:0.18px;}
#t18_171{left:87px;bottom:191px;letter-spacing:-0.15px;word-spacing:7.95px;}
#t19_171{left:87px;bottom:171px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t1a_171{left:87px;bottom:151px;letter-spacing:-0.1px;}
#t1b_171{left:156px;bottom:151px;letter-spacing:-0.1px;word-spacing:1.63px;}
#t1c_171{left:87px;bottom:131px;letter-spacing:-0.09px;}
#t1d_171{left:187px;bottom:131px;letter-spacing:-0.09px;}
#t1e_171{left:294px;bottom:131px;letter-spacing:-0.09px;}
#t1f_171{left:443px;bottom:131px;letter-spacing:-0.1px;}
#t1g_171{left:580px;bottom:131px;letter-spacing:-0.09px;}
#t1h_171{left:87px;bottom:111px;letter-spacing:-0.1px;}

.s0_171{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_171{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s2_171{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_171{font-size:21px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s4_171{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_171{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#292829;}
.s6_171{font-size:17px;font-family:MogulArial_16s;color:#292829;}
.s7_171{font-size:18px;font-family:MogulArial-Bold_179;color:#ED7D31;}`}
      </style>

      <style id="fonts171" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg171" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="171/171.svg"
          type="image/svg+xml"
          id="pdf171"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_171" class="t s0_171">
          171{" "}
        </span>
        <span id="t2_171" class="t s1_171">
          2.{" "}
        </span>
        <span id="t3_171" class="t s2_171">
          Сурагчдын суралцахуйн арга барил, онцлог, ялгаатай байдлыг{" "}
        </span>
        <span id="t4_171" class="t s2_171">
          таньж тогтоон, түүнд тохируулах, Тухайлбал, стандарт анги танхим{" "}
        </span>
        <span id="t5_171" class="t s2_171">
          гэхээсээ илүү хэлбэр, дизайн, тавилгыг өөрчилж байна{" "}
        </span>
        <span id="t6_171" class="t s1_171">
          3.{" "}
        </span>
        <span id="t7_171" class="t s2_171">
          Хичээл, сургалтын үйл ажиллагааг МХХТ-ийн хэрэгслээр явуулах,{" "}
        </span>
        <span id="t8_171" class="t s2_171">
          Тухайлбал цаасны хэрэглээ эрс багасаж, онлайн эх сурвалж,{" "}
        </span>
        <span id="t9_171" class="t s2_171">
          программ хангамжид нэвтрэх, бүртгүүлэх зардал төлөвлөх хэрэгтэй{" "}
        </span>
        <span id="ta_171" class="t s2_171">
          болж байна{" "}
        </span>
        <span id="tb_171" class="t s2_171">
          Сурагчдад тулгамдаж буй асуудлыг арилгахад хамгийн их нөлөө үзүүлж,{" "}
        </span>
        <span id="tc_171" class="t s2_171">
          хувь нэмэр оруулах сургалтын орчны шийдэл, төлөвлөлтийг хэрэгжүүлэхэд{" "}
        </span>
        <span id="td_171" class="t s2_171">
          сургуулийн удирдлага, багш, сурагч, эцэг эх, асран хамгаалагч,
          харгалзан{" "}
        </span>
        <span id="te_171" class="t s2_171">
          дэмжигч оролцоо жигд байх нь чухал байдаг.{" "}
        </span>
        <span id="tf_171" class="t s3_171">
          3.6 СУРГУУЛИЙН УДИРДЛАГА, МАНЛАЙЛАЛ{" "}
        </span>
        <span id="tg_171" class="t s4_171">
          Сургуулийн{" "}
        </span>
        <span id="th_171" class="t s4_171">
          удирдлага яагаад хэрэгтэй вэ?{" "}
        </span>
        <span id="ti_171" class="t s5_171">
          1.{" "}
        </span>
        <span id="tj_171" class="t s6_171">
          Зорилго, зорилтыг томъёолох{" "}
        </span>
        <span id="tk_171" class="t s5_171">
          2.{" "}
        </span>
        <span id="tl_171" class="t s6_171">
          Зорилгодоо хүрэх арга замыг нарийвчлан тодорхойлох{" "}
        </span>
        <span id="tm_171" class="t s5_171">
          3.{" "}
        </span>
        <span id="tn_171" class="t s6_171">
          Хүний болон санхүүгийн нөөцийн хуваарилалт ашиглалтыг оновчтой{" "}
        </span>
        <span id="to_171" class="t s6_171">
          зохион байгуулах{" "}
        </span>
        <span id="tp_171" class="t s4_171">
          Удирдлагын манлайлал яагаад хэрэгтэй вэ?{" "}
        </span>
        <span id="tq_171" class="t s5_171">
          1.{" "}
        </span>
        <span id="tr_171" class="t s6_171">
          Идэвхжүүлэх, сэдэлжүүлэх{" "}
        </span>
        <span id="ts_171" class="t s5_171">
          2.{" "}
        </span>
        <span id="tt_171" class="t s6_171">
          Ажлын үр дүнг бодитой үнэлэх,{" "}
        </span>
        <span id="tu_171" class="t s5_171">
          3.{" "}
        </span>
        <span id="tv_171" class="t s6_171">
          Урамшуулах, анхааруулах{" "}
        </span>
        <span id="tw_171" class="t s5_171">
          4.{" "}
        </span>
        <span id="tx_171" class="t s6_171">
          Хяналт тавих{" "}
        </span>
        <span id="ty_171" class="t s5_171">
          5.{" "}
        </span>
        <span id="tz_171" class="t s6_171">
          Хамт олны{" "}
        </span>
        <span id="t10_171" class="t s6_171">
          үнэт зүйл, итгэл үнэмшлийг хамгаалах, таатай уур{" "}
        </span>
        <span id="t11_171" class="t s6_171">
          амьсгал бүрдүүлэх{" "}
        </span>
        <span id="t12_171" class="t s5_171">
          6.{" "}
        </span>
        <span id="t13_171" class="t s6_171">
          Инноваци нэвтрүүлэх (шинэ санаачлагыг бодит болгох/бүтээгдэхүүн,{" "}
        </span>
        <span id="t14_171" class="t s6_171">
          амьдралд хэрэгжүүлэх, шинэ үнэ цэнийг бий болгох){" "}
        </span>
        <span id="t15_171" class="t s7_171">
          3.6.1{" "}
        </span>
        <span id="t16_171" class="t s7_171">
          СУРГУУЛИЙН ЗОРИЛГО, ҮЙЛ АЖИЛЛАГААНЫ НЭГДСЭН{" "}
        </span>
        <span id="t17_171" class="t s7_171">
          ТӨЛӨВЛӨЛТ{" "}
        </span>
        <span id="t18_171" class="t s2_171">
          Нэгдсэн төлөвлөгөөг хийхтэй 1-р бүлгийн гурван давхаргат пирамид{" "}
        </span>
        <span id="t19_171" class="t s2_171">
          загвартай холбон хийх хэрэгтэй.{" "}
        </span>
        <span id="t1a_171" class="t s2_171">
          Нэгдсэн{" "}
        </span>
        <span id="t1b_171" class="t s2_171">
          төлөвлөлт нь хэмжигдэхүйц зорилго, зорилтуудыг үе шаттайгаар{" "}
        </span>
        <span id="t1c_171" class="t s2_171">
          тодорхой{" "}
        </span>
        <span id="t1d_171" class="t s2_171">
          хугацаанд{" "}
        </span>
        <span id="t1e_171" class="t s2_171">
          хэрэгжүүлэхээр{" "}
        </span>
        <span id="t1f_171" class="t s2_171">
          тодорхойлсон{" "}
        </span>
        <span id="t1g_171" class="t s2_171">
          төлөвлөгөөг{" "}
        </span>
        <span id="t1h_171" class="t s2_171">
          боловсруулах үйл ажиллагаа гэж ойлгоно.{" "}
        </span>
      </div>
    </div>
  );
}
