import React from "react";
export default function F146() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_146{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_146{left:87px;bottom:941px;letter-spacing:-0.18px;word-spacing:7.25px;}
#t3_146{left:87px;bottom:921px;letter-spacing:-0.12px;word-spacing:2.78px;}
#t4_146{left:87px;bottom:901px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t5_146{left:296px;bottom:865px;letter-spacing:0.09px;word-spacing:0.04px;}
#t6_146{left:272px;bottom:831px;letter-spacing:0.06px;}
#t7_146{left:560px;bottom:831px;letter-spacing:0.16px;word-spacing:-0.02px;}
#t8_146{left:91px;bottom:805px;letter-spacing:0.11px;word-spacing:0.04px;}
#t9_146{left:91px;bottom:781px;letter-spacing:0.1px;word-spacing:0.04px;}
#ta_146{left:91px;bottom:760px;letter-spacing:0.13px;word-spacing:9.58px;}
#tb_146{left:91px;bottom:742px;letter-spacing:0.05px;word-spacing:0.09px;}
#tc_146{left:91px;bottom:721px;letter-spacing:0.1px;word-spacing:0.04px;}
#td_146{left:91px;bottom:700px;letter-spacing:0.1px;word-spacing:0.04px;}
#te_146{left:91px;bottom:676px;letter-spacing:0.09px;word-spacing:2.65px;}
#tf_146{left:91px;bottom:658px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_146{left:91px;bottom:634px;letter-spacing:0.11px;word-spacing:5.11px;}
#th_146{left:91px;bottom:615px;letter-spacing:0.13px;word-spacing:0.01px;}
#ti_146{left:91px;bottom:592px;letter-spacing:0.1px;word-spacing:1.54px;}
#tj_146{left:91px;bottom:573px;letter-spacing:0.09px;word-spacing:0.04px;}
#tk_146{left:87px;bottom:533px;letter-spacing:-0.17px;word-spacing:8.03px;}
#tl_146{left:87px;bottom:513px;letter-spacing:-0.14px;word-spacing:9.91px;}
#tm_146{left:87px;bottom:493px;letter-spacing:-0.14px;word-spacing:3.65px;}
#tn_146{left:87px;bottom:473px;letter-spacing:-0.12px;word-spacing:0.03px;}
#to_146{left:87px;bottom:453px;}
#tp_146{left:114px;bottom:453px;letter-spacing:-0.17px;word-spacing:1.98px;}
#tq_146{left:114px;bottom:433px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tr_146{left:87px;bottom:412px;}
#ts_146{left:114px;bottom:412px;letter-spacing:-0.16px;word-spacing:8.29px;}
#tt_146{left:114px;bottom:392px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tu_146{left:87px;bottom:372px;}
#tv_146{left:114px;bottom:372px;letter-spacing:-0.14px;word-spacing:5.28px;}
#tw_146{left:114px;bottom:352px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tx_146{left:87px;bottom:312px;letter-spacing:-0.13px;word-spacing:6.01px;}
#ty_146{left:87px;bottom:291px;letter-spacing:-0.13px;word-spacing:6.27px;}
#tz_146{left:87px;bottom:271px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t10_146{left:87px;bottom:205px;letter-spacing:0.14px;word-spacing:0.05px;}
#t11_146{left:87px;bottom:169px;letter-spacing:-0.15px;word-spacing:6.1px;}
#t12_146{left:87px;bottom:150px;letter-spacing:-0.13px;word-spacing:8.39px;}
#t13_146{left:87px;bottom:132px;letter-spacing:-0.17px;word-spacing:0.07px;}

.s0_146{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_146{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_146{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_146{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_146{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_146{font-size:17px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts146" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg146" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="146/146.svg"
          type="image/svg+xml"
          id="pdf146"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_146" class="t s0_146">
          146{" "}
        </span>
        <span id="t2_146" class="t s1_146">
          Дээрх хүснэгтэд дурдсан асуудлаа тодорхойлоход хэрэглэгдэх ажлын{" "}
        </span>
        <span id="t3_146" class="t s1_146">
          хуудсыг та бүхэнд хүргэж байна. Мэргэжлийн хөгжлийн бүлгээр хамтран{" "}
        </span>
        <span id="t4_146" class="t s1_146">
          ажиллахдаа шийдвэрлэх асуудлаа бодитой тодорхойлохыг хичээгээрэй.{" "}
        </span>
        <span id="t5_146" class="t s2_146">
          Хүснэгт 3.20. Ажлын хуудас 1: Асуудлыг тодорхойлох{" "}
        </span>
        <span id="t6_146" class="t s2_146">
          Асуултууд{" "}
        </span>
        <span id="t7_146" class="t s2_146">
          Бичих хэсэг{" "}
        </span>
        <span id="t8_146" class="t s2_146">
          Шийдвэрлэхээр сонгосон асуудлаа бичих{" "}
        </span>
        <span id="t9_146" class="t s2_146">
          Энэ асуудал яагаад чухал вэ?{" "}
        </span>
        <span id="ta_146" class="t s2_146">
          Асуудлын шалтгаан юу вэ? Ямар аргаар шалтгааныг{" "}
        </span>
        <span id="tb_146" class="t s2_146">
          тодруулах вэ?{" "}
        </span>
        <span id="tc_146" class="t s2_146">
          Энэ асуудал хэнд хамгийн их хамаатай вэ?{" "}
        </span>
        <span id="td_146" class="t s2_146">
          Хэн энэ асуудлыг илүү их анхаарах шаардлагатай вэ?{" "}
        </span>
        <span id="te_146" class="t s2_146">
          Уг асуудлыг ямар арга замаар шийдэх боломж байгаа вэ?{" "}
        </span>
        <span id="tf_146" class="t s2_146">
          Түүний давуу тал{" "}
        </span>
        <span id="tg_146" class="t s2_146">
          Асуудал шийдэгдсэний дараа гарах үр дүн юу байх вэ?{" "}
        </span>
        <span id="th_146" class="t s2_146">
          Хэрхэн хэмжих вэ?{" "}
        </span>
        <span id="ti_146" class="t s2_146">
          Асуудлыг сургуулийн үйл ажиллагааны төлөвлөлтөд хэрхэн{" "}
        </span>
        <span id="tj_146" class="t s2_146">
          тусгах вэ?{" "}
        </span>
        <span id="tk_146" class="t s1_146">
          Боловсролын салбарт хийгдэж буй бодлого, хууль эрх зүй, төсвийн{" "}
        </span>
        <span id="tl_146" class="t s1_146">
          шинэчлэлийн хүрээнд багшийн мэргэжил дээшлүүлэх тогтолцооноос{" "}
        </span>
        <span id="tm_146" class="t s1_146">
          багшийн тасралтгүй хөгжлийн тогтолцоонд шилжиж дараах өөрчлөлтийг{" "}
        </span>
        <span id="tn_146" class="t s1_146">
          хийж байна. Үүнд:{" "}
        </span>
        <span id="to_146" class="t s3_146">
          •{" "}
        </span>
        <span id="tp_146" class="t s1_146">
          танхим төвт төвлөрсөн сургалт, үйл ажиллагаанаас-цахим платформд{" "}
        </span>
        <span id="tq_146" class="t s1_146">
          суурилсан ажлын байран дахь сургалт, үйл ажиллагаанд{" "}
        </span>
        <span id="tr_146" class="t s3_146">
          •{" "}
        </span>
        <span id="ts_146" class="t s1_146">
          батлагдсан нэг хөтөлбөрт сургалтаас-багш хэрэгцээндээ үндэслэн{" "}
        </span>
        <span id="tt_146" class="t s1_146">
          сонгон суралцдаг “Модуль” сургалтад{" "}
        </span>
        <span id="tu_146" class="t s3_146">
          •{" "}
        </span>
        <span id="tv_146" class="t s1_146">
          Суралцахуйд болон судлагдахуунд суурилсан мэргэжлийн хөгжлийн{" "}
        </span>
        <span id="tw_146" class="t s1_146">
          бүлгийн хамтын үйл ажиллагаанд шилжиж байна.{" "}
        </span>
        <span id="tx_146" class="t s1_146">
          Дээрх чиг хандлагын хүрээнд багшийн тасралтгүй хөгжлийг сургуульд{" "}
        </span>
        <span id="ty_146" class="t s1_146">
          суурилсан менежментэд тусган хэрэгжүүлэх зөвлөмжийг энэхүү хэсэгт{" "}
        </span>
        <span id="tz_146" class="t s1_146">
          тусгасан болно.{" "}
        </span>
        <span id="t10_146" class="t s4_146">
          Багшийн мэргэжлийн хөгжлийн зураглал{" "}
        </span>
        <span id="t11_146" class="t s5_146">
          Сургуулийн удирдлага дараах зураглалын дагуу мэргэжлийн хөгжлийн{" "}
        </span>
        <span id="t12_146" class="t s5_146">
          хэрэгцээг тодорхойлох, төлөвлөх, мэргэжлийн хөгжлийн бүлгийн үйл{" "}
        </span>
        <span id="t13_146" class="t s5_146">
          ажиллагааг удирдан явуулах боломжтой.{" "}
        </span>
      </div>
    </div>
  );
}
