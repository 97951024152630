import React from "react";
export default function OneHundredSeventySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_176{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_176{left:113px;bottom:941px;letter-spacing:-0.06px;}
#t3_176{left:139px;bottom:941px;letter-spacing:-0.1px;}
#t4_176{left:113px;bottom:919px;letter-spacing:-0.06px;}
#t5_176{left:139px;bottom:919px;letter-spacing:-0.1px;}
#t6_176{left:87px;bottom:876px;letter-spacing:-0.15px;word-spacing:3.99px;}
#t7_176{left:87px;bottom:856px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t8_176{left:87px;bottom:836px;letter-spacing:-0.14px;word-spacing:5.03px;}
#t9_176{left:87px;bottom:816px;letter-spacing:-0.13px;word-spacing:0.39px;}
#ta_176{left:87px;bottom:796px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tb_176{left:148px;bottom:758px;letter-spacing:0.08px;word-spacing:0.06px;}
#tc_176{left:87px;bottom:429px;letter-spacing:-0.14px;word-spacing:4.54px;}
#td_176{left:430px;bottom:429px;letter-spacing:-0.2px;word-spacing:4.58px;}
#te_176{left:87px;bottom:409px;letter-spacing:-0.23px;word-spacing:-2.26px;}
#tf_176{left:277px;bottom:409px;letter-spacing:-0.1px;word-spacing:-2.36px;}
#tg_176{left:87px;bottom:389px;letter-spacing:-0.14px;word-spacing:0.05px;}
#th_176{left:87px;bottom:356px;letter-spacing:-0.13px;word-spacing:-0.14px;}
#ti_176{left:87px;bottom:335px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tj_176{left:100px;bottom:311px;letter-spacing:-0.12px;word-spacing:2.25px;}
#tk_176{left:117px;bottom:291px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tl_176{left:100px;bottom:266px;}
#tm_176{left:117px;bottom:266px;letter-spacing:-0.13px;word-spacing:-0.62px;}
#tn_176{left:117px;bottom:246px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_176{left:490px;bottom:246px;letter-spacing:-0.16px;}
#tp_176{left:100px;bottom:222px;}
#tq_176{left:117px;bottom:222px;letter-spacing:-0.47px;word-spacing:-1.38px;}
#tr_176{left:117px;bottom:201px;letter-spacing:-0.15px;word-spacing:-1.19px;}
#ts_176{left:498px;bottom:201px;letter-spacing:-0.15px;word-spacing:-1.19px;}
#tt_176{left:87px;bottom:161px;letter-spacing:-0.15px;word-spacing:1.13px;}
#tu_176{left:87px;bottom:141px;letter-spacing:-0.12px;word-spacing:-1.41px;}

.s0_176{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_176{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s2_176{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_176{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_176{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts176" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg176" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="176/176.svg"
          type="image/svg+xml"
          id="pdf176"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_176" class="t s0_176">
          176{" "}
        </span>
        <span id="t2_176" class="t s1_176">
          13.{" "}
        </span>
        <span id="t3_176" class="t s2_176">
          Оролцогч талуудын хариуцлага (хүлээсэн үүргээ биелүүлэх){" "}
        </span>
        <span id="t4_176" class="t s1_176">
          14.{" "}
        </span>
        <span id="t5_176" class="t s2_176">
          Нэгтгэн тайлагнах{" "}
        </span>
        <span id="t6_176" class="t s2_176">
          Ингэснээр сургуулийн зорилго, үйл ажиллагааны талаар тулгамдаж буй{" "}
        </span>
        <span id="t7_176" class="t s2_176">
          асуудлыг цэгцлэхэд эергээр нөлөөлнө.{" "}
        </span>
        <span id="t8_176" class="t s2_176">
          Багшийн харьяалагдах анхан шатны нэгж нь багш мэргэжлээрээ өсөх,{" "}
        </span>
        <span id="t9_176" class="t s2_176">
          хөгжих боломжийг бүрдүүлэх, мэргэжлийн, арга зүйн дэмжлэг авах, багаар{" "}
        </span>
        <span id="ta_176" class="t s2_176">
          ажиллах таатай уур амьсгалыг бий болгохыг зорино.{" "}
        </span>
        <span id="tb_176" class="t s3_176">
          Зураг 3.33. Сургуулийн зорилгоо тодорхойлж, төлөвлөтөө нэгдэх зураглал{" "}
        </span>
        <span id="tc_176" class="t s2_176">
          Зураглалын дагуу зорилго, төлөвлөлтийг{" "}
        </span>
        <span id="td_176" class="t s4_176">
          сурагчийн хүсэл эрмэлзлэл,{" "}
        </span>
        <span id="te_176" class="t s4_176">
          сонирхолд хөтлөгдсөн{" "}
        </span>
        <span id="tf_176" class="t s2_176">
          дээрээс доошоо, доороос дээшээ чиглэх тэнцвэрийг{" "}
        </span>
        <span id="tg_176" class="t s2_176">
          ханган алхамчилна.{" "}
        </span>
        <span id="th_176" class="t s2_176">
          Анхан шатны нэгж – тухайн сургуулийн онцлогоос хамаарч заах аргын секц{" "}
        </span>
        <span id="ti_176" class="t s2_176">
          эсвэл заах аргын нэгдэл байж болно.{" "}
        </span>
        <span id="tj_176" class="t s2_176">
          • Дунд шатны нэгж – тухайн сургуулийн онцлогоос хамаарч заах аргын{" "}
        </span>
        <span id="tk_176" class="t s2_176">
          нэгдэл эсвэл сургалтын менежер байж болно.{" "}
        </span>
        <span id="tl_176" class="t s2_176">
          •{" "}
        </span>
        <span id="tm_176" class="t s2_176">
          Хэрвээ тухайн сургуулийн дунд шатны нэгж нь заах аргын нэдэл байвал{" "}
        </span>
        <span id="tn_176" class="t s2_176">
          дараагийн шатны нэгж нь сургалтын менежер{" "}
        </span>
        <span id="to_176" class="t s2_176">
          байна.{" "}
        </span>
        <span id="tp_176" class="t s2_176">
          •{" "}
        </span>
        <span id="tq_176" class="t s2_176">
          Дэмжих баг нь сургуулийн үйлчилгээний тухайлбал, сургуулийн
          цэвэрлэгээ,{" "}
        </span>
        <span id="tr_176" class="t s2_176">
          засвар үйлчилгээ, харуул хамгаалалт хариуцсан{" "}
        </span>
        <span id="ts_176" class="t s2_176">
          ажилтан гэх мэт байна.{" "}
        </span>
        <span id="tt_176" class="t s2_176">
          Санал хүсэлт хүлээн авах баг нь сургуулийн үйл ажиллагаатай холбоотой{" "}
        </span>
        <span id="tu_176" class="t s2_176">
          бичгээр ирүүлсэн санал хүсэлтийг хүлээн авч нэгтгээд, боловсруулалт
          хийж,{" "}
        </span>
      </div>
    </div>
  );
}
