import React from "react";
export default function EightyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_84{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_84{left:142px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_84{left:106px;bottom:869px;letter-spacing:0.1px;word-spacing:1.08px;}
#t4_84{left:106px;bottom:851px;letter-spacing:0.16px;word-spacing:-0.01px;}
#t5_84{left:106px;bottom:819px;letter-spacing:0.13px;word-spacing:2.82px;}
#t6_84{left:106px;bottom:801px;letter-spacing:0.11px;word-spacing:5.83px;}
#t7_84{left:106px;bottom:783px;letter-spacing:0.09px;word-spacing:3.65px;}
#t8_84{left:106px;bottom:764px;letter-spacing:0.11px;word-spacing:-0.26px;}
#t9_84{left:106px;bottom:746px;letter-spacing:0.12px;word-spacing:3.12px;}
#ta_84{left:106px;bottom:728px;letter-spacing:0.13px;word-spacing:3.65px;}
#tb_84{left:106px;bottom:709px;letter-spacing:0.11px;word-spacing:-1.42px;}
#tc_84{left:148px;bottom:680px;letter-spacing:-0.15px;word-spacing:0.03px;}
#td_84{left:429px;bottom:664px;letter-spacing:-0.16px;word-spacing:0.03px;}
#te_84{left:106px;bottom:568px;letter-spacing:0.11px;word-spacing:3.62px;}
#tf_84{left:106px;bottom:550px;letter-spacing:0.11px;word-spacing:6.7px;}
#tg_84{left:106px;bottom:531px;letter-spacing:0.15px;word-spacing:7.14px;}
#th_84{left:106px;bottom:513px;letter-spacing:0.12px;word-spacing:0.02px;}
#ti_84{left:183px;bottom:488px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tj_84{left:556px;bottom:488px;letter-spacing:-0.15px;}
#tk_84{left:429px;bottom:472px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tl_84{left:142px;bottom:365px;letter-spacing:0.18px;}
#tm_84{left:87px;bottom:333px;}
#tn_84{left:113px;bottom:333px;letter-spacing:-0.16px;word-spacing:2.94px;}
#to_84{left:113px;bottom:313px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tp_84{left:87px;bottom:288px;}
#tq_84{left:113px;bottom:288px;letter-spacing:-0.15px;word-spacing:-0.66px;}
#tr_84{left:113px;bottom:268px;letter-spacing:-0.13px;word-spacing:0.04px;}
#ts_84{left:87px;bottom:244px;}
#tt_84{left:113px;bottom:244px;letter-spacing:-0.16px;word-spacing:4.78px;}
#tu_84{left:113px;bottom:223px;letter-spacing:-0.15px;word-spacing:5.96px;}
#tv_84{left:113px;bottom:203px;letter-spacing:-0.16px;word-spacing:3.69px;}
#tw_84{left:113px;bottom:183px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tx_84{left:87px;bottom:159px;}
#ty_84{left:113px;bottom:159px;letter-spacing:-0.14px;word-spacing:0.64px;}
#tz_84{left:113px;bottom:138px;letter-spacing:-0.14px;word-spacing:2.79px;}
#t10_84{left:113px;bottom:118px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_84{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_84{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_84{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_84{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_84{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_84{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts84" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg84" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="84/84.svg"
          type="image/svg+xml"
          id="pdf84"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_84" class="t s0_84">
          84{" "}
        </span>
        <span id="t2_84" class="t s1_84">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_84" class="t s2_84">
          Хөгжлийн бэрхшээлтэй хүүхэд орох шатыг налуу болгож янзалсан, 00 өрөөг{" "}
        </span>
        <span id="t4_84" class="t s2_84">
          шинээр хийсэн.{" "}
        </span>
        <span id="t5_84" class="t s2_84">
          Нийтийн 00-ын өрөөнүүд болон нийтийн эзэмшлийн коридор, хонгилуудад{" "}
        </span>
        <span id="t6_84" class="t s2_84">
          их цэвэрлэгээ хийлгэж, цэвэрлэгээг дутуу хийдгийг болиулах зорилгоор{" "}
        </span>
        <span id="t7_84" class="t s2_84">
          их цэвэрлэгээг тогтмолжуулж цэвэрлэгээний өдөртэй болгож, 7 хоног бүр{" "}
        </span>
        <span id="t8_84" class="t s2_84">
          цэвэрлэгээ хийж хэвших чиглэл өгсөн. 2022-2023 оны хичээлийн жилд
          “Үдийн{" "}
        </span>
        <span id="t9_84" class="t s2_84">
          хоол ” хөтөлбөр хэрэгжүүлэхэд хяналт тавих, хяналтын комисс сургалтын{" "}
        </span>
        <span id="ta_84" class="t s2_84">
          менежер, сургуулийн эмч, нийгмийн ажилтан, секцийн ахлагч, эцэг эхийн{" "}
        </span>
        <span id="tb_84" class="t s2_84">
          төлөөллийн бүрэлдэхүүнтэйгээр захирлын тушаалаар батлан ажиллаж байна.{" "}
        </span>
        <span id="tc_84" class="t s3_84">
          ... нийслэлийн ... дүүргийн ерөнхий боловсролын ... сургуулийн
          менежментийг{" "}
        </span>
        <span id="td_84" class="t s3_84">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="te_84" class="t s2_84">
          Анги танхимд цэвэрлэгээ, агааржуулалт хийх хуваарь гаргаж, тоосжилтыг{" "}
        </span>
        <span id="tf_84" class="t s2_84">
          багасгах нано шүүлтүүр байршуулж цэвэрлэгээ үйлчилгээний хяналтын{" "}
        </span>
        <span id="tg_84" class="t s2_84">
          график хөтөлж хэвшсэн. Өдөр тутамд хоолны амт чанар, орц, норм,{" "}
        </span>
        <span id="th_84" class="t s2_84">
          технологид хяналт тавьж ажилласан нь үр дүнтэй ажил болжээ.{" "}
        </span>
        <span id="ti_84" class="t s3_84">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="tj_84" class="t s3_84">
          менежментийг{" "}
        </span>
        <span id="tk_84" class="t s3_84">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tl_84" class="t s1_84">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tm_84" class="t s4_84">
          •{" "}
        </span>
        <span id="tn_84" class="t s5_84">
          Анги танхимд агаар сэлгэлт, тоосжилтгүй нөхцөлийг боломж нөөцдөө{" "}
        </span>
        <span id="to_84" class="t s5_84">
          тулгуурлан бий болгох{" "}
        </span>
        <span id="tp_84" class="t s4_84">
          •{" "}
        </span>
        <span id="tq_84" class="t s5_84">
          Гал тогоо нь эрүүл ахуйн шаардлагыг хангахуйц хүнсний агуулах савтай,{" "}
        </span>
        <span id="tr_84" class="t s5_84">
          тоног төхөөрөмж нь аюулгүй байх{" "}
        </span>
        <span id="ts_84" class="t s4_84">
          •{" "}
        </span>
        <span id="tt_84" class="t s5_84">
          Дотуур байрны хүртээмж хангалтгүй тохиолдолд сурагчдын эцэг эх,{" "}
        </span>
        <span id="tu_84" class="t s5_84">
          асран хамгаалагчтай биечлэн уулзаж, албан ёсны гурвалсан гэрээ{" "}
        </span>
        <span id="tv_84" class="t s5_84">
          байгуулж, хэрэгжилтэд АУБ, Хүүхэд хөгжил хамгааллын алба хяналт{" "}
        </span>
        <span id="tw_84" class="t s5_84">
          тавьж ажиллах дүрэм журам боловсруулан мөрдөх{" "}
        </span>
        <span id="tx_84" class="t s4_84">
          •{" "}
        </span>
        <span id="ty_84" class="t s5_84">
          Үдийн хоол хүнсний бүтээгдэхүүн, түүхий эд нь аюулгүй, хоолны цэс нь{" "}
        </span>
        <span id="tz_84" class="t s5_84">
          амин дэм, илчлэгийн шаардлага хангахуйц олон төрөл байхад дүрэм{" "}
        </span>
        <span id="t10_84" class="t s5_84">
          журмыг дагаж мөрдөх{" "}
        </span>
      </div>
    </div>
  );
}
