import React from "react";
export default function F156() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_156{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_156{left:87px;bottom:941px;letter-spacing:-0.11px;word-spacing:-1.39px;}
#t3_156{left:345px;bottom:941px;}
#t4_156{left:353px;bottom:941px;letter-spacing:-0.2px;word-spacing:-1.28px;}
#t5_156{left:87px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.1px;}
#t6_156{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:2.13px;}
#t7_156{left:87px;bottom:880px;letter-spacing:-0.11px;word-spacing:0.12px;}
#t8_156{left:87px;bottom:860px;letter-spacing:-0.11px;word-spacing:-0.36px;}
#t9_156{left:87px;bottom:840px;letter-spacing:-0.15px;word-spacing:-0.13px;}
#ta_156{left:87px;bottom:820px;letter-spacing:-0.14px;word-spacing:5.09px;}
#tb_156{left:87px;bottom:800px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tc_156{left:87px;bottom:767px;letter-spacing:-0.14px;word-spacing:0.33px;}
#td_156{left:241px;bottom:767px;}
#te_156{left:251px;bottom:767px;letter-spacing:-0.12px;word-spacing:0.32px;}
#tf_156{left:87px;bottom:746px;letter-spacing:-0.15px;word-spacing:-1.07px;}
#tg_156{left:87px;bottom:726px;letter-spacing:-0.15px;word-spacing:0.12px;}
#th_156{left:87px;bottom:706px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_156{left:87px;bottom:673px;letter-spacing:-0.27px;}
#tj_156{left:203px;bottom:673px;letter-spacing:-0.11px;}
#tk_156{left:278px;bottom:673px;}
#tl_156{left:287px;bottom:673px;letter-spacing:-0.17px;word-spacing:0.28px;}
#tm_156{left:87px;bottom:653px;letter-spacing:-0.15px;word-spacing:7.22px;}
#tn_156{left:87px;bottom:633px;letter-spacing:-0.16px;word-spacing:5.22px;}
#to_156{left:87px;bottom:612px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tp_156{left:87px;bottom:579px;letter-spacing:-0.16px;word-spacing:-1.84px;}
#tq_156{left:236px;bottom:579px;letter-spacing:-0.14px;word-spacing:-1.86px;}
#tr_156{left:87px;bottom:559px;letter-spacing:-0.14px;word-spacing:1.11px;}
#ts_156{left:87px;bottom:539px;letter-spacing:-0.14px;word-spacing:2.99px;}
#tt_156{left:87px;bottom:519px;letter-spacing:-0.15px;word-spacing:-0.49px;}
#tu_156{left:87px;bottom:499px;letter-spacing:-0.15px;word-spacing:4.68px;}
#tv_156{left:87px;bottom:478px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_156{left:87px;bottom:445px;letter-spacing:-0.13px;word-spacing:4.53px;}
#tx_156{left:335px;bottom:445px;}
#ty_156{left:349px;bottom:445px;letter-spacing:-0.22px;word-spacing:4.63px;}
#tz_156{left:87px;bottom:425px;letter-spacing:-0.14px;word-spacing:0.09px;}
#t10_156{left:87px;bottom:405px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t11_156{left:87px;bottom:372px;letter-spacing:-0.13px;word-spacing:-0.81px;}
#t12_156{left:87px;bottom:352px;letter-spacing:-0.13px;word-spacing:4.86px;}
#t13_156{left:87px;bottom:331px;letter-spacing:-0.15px;word-spacing:-0.61px;}
#t14_156{left:87px;bottom:311px;letter-spacing:-0.13px;word-spacing:-0.07px;}
#t15_156{left:87px;bottom:291px;letter-spacing:-0.17px;word-spacing:4.16px;}
#t16_156{left:87px;bottom:271px;letter-spacing:-0.2px;word-spacing:0.45px;}
#t17_156{left:87px;bottom:251px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t18_156{left:87px;bottom:218px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t19_156{left:428px;bottom:218px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t1a_156{left:113px;bottom:197px;}
#t1b_156{left:130px;bottom:197px;letter-spacing:-0.17px;}
#t1c_156{left:219px;bottom:197px;letter-spacing:-0.17px;}
#t1d_156{left:281px;bottom:197px;letter-spacing:-0.15px;}
#t1e_156{left:386px;bottom:197px;letter-spacing:-0.21px;}
#t1f_156{left:481px;bottom:197px;letter-spacing:-0.17px;}
#t1g_156{left:604px;bottom:197px;letter-spacing:-0.14px;}
#t1h_156{left:130px;bottom:177px;letter-spacing:-0.15px;word-spacing:5.37px;}
#t1i_156{left:130px;bottom:157px;letter-spacing:-0.15px;word-spacing:3px;}
#t1j_156{left:130px;bottom:137px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_156{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_156{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_156{font-size:17px;font-family:MogulArial_16s;color:#F7941E;}
.s3_156{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_156{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_156{font-size:17px;font-family:MyriadPro-Regular_1al;color:#000;}`}
      </style>

      <style id="fonts156" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MyriadPro-Regular_1al;
	src: url("fonts/MyriadPro-Regular_1al.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg156" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="156/156.svg"
          type="image/svg+xml"
          id="pdf156"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_156" class="t s0_156">
          156{" "}
        </span>
        <span id="t2_156" class="t s1_156">
          Багш нарын хамтын ажиллагаа
        </span>
        <span id="t3_156" class="t s2_156">
          :{" "}
        </span>
        <span id="t4_156" class="t s3_156">
          2011 оны Стэнфордын судалгаагаар багш{" "}
        </span>
        <span id="t5_156" class="t s3_156">
          нарын хамтын ажиллагаа их байх тусам суралцагчийн амжилт сайн байдаг{" "}
        </span>
        <span id="t6_156" class="t s3_156">
          болохыг тогтоожээ. Багш хамтран ажиллах олон боломж бий болсон үед{" "}
        </span>
        <span id="t7_156" class="t s3_156">
          бий болдог “нийгмийн капитал” нь гадны мэргэжилтнүүдийн мэргэжлийн ур{" "}
        </span>
        <span id="t8_156" class="t s3_156">
          чадварыг дээшлүүлэх “хүний капитал”-аас илүү үр дүнтэй гэж
          судалгаагаар{" "}
        </span>
        <span id="t9_156" class="t s3_156">
          дүгнэжээ. Нэмж дурдахад, багшийн хамтын ажиллагаагаар сургуулиуд 21-р{" "}
        </span>
        <span id="ta_156" class="t s3_156">
          зууны боловсролын зорилгыг дэмжсэн салбар дундын төслүүд, багаар{" "}
        </span>
        <span id="tb_156" class="t s3_156">
          хичээллэх, блок хуваарийг санал болгох боломжтой.{" "}
        </span>
        <span id="tc_156" class="t s1_156">
          Эерэг уур амьсгал
        </span>
        <span id="td_156" class="t s2_156">
          :{" "}
        </span>
        <span id="te_156" class="t s3_156">
          Сургуулийн эерэг уур амьсгал нь нийгэм, төлөвшлийн{" "}
        </span>
        <span id="tf_156" class="t s3_156">
          хөтөлбөртэй холбоотой. Энэ төрлийн хөтөлбөр нь сэтгэл хөдлөлөө
          удирдах,{" "}
        </span>
        <span id="tg_156" class="t s3_156">
          илэрхийлэх, зөрчилдөөнийг шийдвэрлэх, хариуцлагатай шийдвэр гаргах ур{" "}
        </span>
        <span id="th_156" class="t s3_156">
          чадварыг эзэмших, хөгжүүлэх, дадлагажуулахад оршино.{" "}
        </span>
        <span id="ti_156" class="t s1_156">
          Технологийн{" "}
        </span>
        <span id="tj_156" class="t s1_156">
          интеграц
        </span>
        <span id="tk_156" class="t s2_156">
          :{" "}
        </span>
        <span id="tl_156" class="t s3_156">
          Компьютерын лабораторитой хэдий ч интернэтэд{" "}
        </span>
        <span id="tm_156" class="t s3_156">
          нэвтрэх боломж хязгаарлагдмал, зөөврийн төхөөрөмжүүдийн хэрэглээ{" "}
        </span>
        <span id="tn_156" class="t s3_156">
          хязгаарлагдмал, анги танхим дахь технологийн бага хэрэглээ зэрэг нь{" "}
        </span>
        <span id="to_156" class="t s3_156">
          технологийн интеграцчлалын хувьд цаг үеэсээ хол хоцорсон болно.{" "}
        </span>
        <span id="tp_156" class="t s4_156">
          Уян хатан хуваарь
        </span>
        <span id="tq_156" class="t s3_156">
          : Ердийн хичээлийн өдрийн хуваариар сургалтын зорилго{" "}
        </span>
        <span id="tr_156" class="t s3_156">
          болон хичээлийн явц хоёрын хоорондын холбоог хангах нь тийм ч хялбар{" "}
        </span>
        <span id="ts_156" class="t s3_156">
          биш. Өөрөөр хэлбэл, хичээлийн өдрийг 40 минутын хичээлийн цагуудад{" "}
        </span>
        <span id="tt_156" class="t s3_156">
          жигд хуваах нь сургалтын хөтөлбөрийг хүргэх, сургалтын
          хэрэглэгдэхүүнийг{" "}
        </span>
        <span id="tu_156" class="t s3_156">
          хамруулах үр дүнтэй арга боловч сурагчдын жинхэнэ оролцоо, гүнзгий{" "}
        </span>
        <span id="tv_156" class="t s3_156">
          ойлголтоор хэмжигдэх бодит суралцахад үр дүнтэй байж чадахгүй.{" "}
        </span>
        <span id="tw_156" class="t s1_156">
          Хамтын ажиллагаа, түншлэл
        </span>
        <span id="tx_156" class="t s2_156">
          :{" "}
        </span>
        <span id="ty_156" class="t s3_156">
          Хүрээлэн буй орчин, олон нийт, глобал{" "}
        </span>
        <span id="tz_156" class="t s3_156">
          хамтын ажиллагаа, түншлэл нь боловсролд туйлын чухал биш ч сургалтын{" "}
        </span>
        <span id="t10_156" class="t s3_156">
          орчныг бүрдүүлэхэд чухалд тооцогдоно.{" "}
        </span>
        <span id="t11_156" class="t s3_156">
          OECD-ийн санал болгосон Сургалтын орчны үнэлгээний хөтөлбөр (Learning{" "}
        </span>
        <span id="t12_156" class="t s3_156">
          Environments Evaluation Programme) сургуулийн сургалтын материаллаг{" "}
        </span>
        <span id="t13_156" class="t s3_156">
          орчин боловсрол, суралцахуйд хэрхэн дэмжлэг үзүүлдэг, сургалтын үр
          дүнд{" "}
        </span>
        <span id="t14_156" class="t s3_156">
          нөлөөлөх чухал хүчин зүйлүүдийг тодруулахдаа дараах 3 хэмжигдэхүүнээр{" "}
        </span>
        <span id="t15_156" class="t s3_156">
          үнэлдэг. Эдгээрийн цаана 1) аюулгүй, тав тухтай байдал, 2) орон зайн{" "}
        </span>
        <span id="t16_156" class="t s3_156">
          ашиглалт, орон зайн зохион байгуулалт, 3) сургуулийн талаарх
          мэдээллийг{" "}
        </span>
        <span id="t17_156" class="t s3_156">
          илрүүлэхийг зорьдог. Үүнд:{" "}
        </span>
        <span id="t18_156" class="t s1_156">
          Сургалтын үр дүнтэй орчныг бүрдүүлэх{" "}
        </span>
        <span id="t19_156" class="t s3_156">
          (үр дүнтэй байдал){" "}
        </span>
        <span id="t1a_156" class="t s5_156">
          »{" "}
        </span>
        <span id="t1b_156" class="t s3_156">
          Сургууль{" "}
        </span>
        <span id="t1c_156" class="t s3_156">
          эсвэл{" "}
        </span>
        <span id="t1d_156" class="t s3_156">
          сургуулийн{" "}
        </span>
        <span id="t1e_156" class="t s3_156">
          тогтолцоо{" "}
        </span>
        <span id="t1f_156" class="t s3_156">
          боловсролын{" "}
        </span>
        <span id="t1g_156" class="t s3_156">
          зорилтоо{" "}
        </span>
        <span id="t1h_156" class="t s3_156">
          зохих ёсоор биелүүлэх чадвар. Боловсролын үр дүнтэй байдлын{" "}
        </span>
        <span id="t1i_156" class="t s3_156">
          судалгаанууд нь тодорхой нөөцийн орцууд нь үр дүнд эерэг нөлөө{" "}
        </span>
        <span id="t1j_156" class="t s3_156">
          үзүүлж байгаа эсэхэд дүн шинжилгээ хийдэг, өргөн хүрээ{" "}
        </span>
      </div>
    </div>
  );
}
