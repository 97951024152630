import React from "react";
export default function OneHundredEighty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_180{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_180{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:9.6px;}
#t3_180{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:2.14px;}
#t4_180{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t5_180{left:87px;bottom:860px;letter-spacing:-0.35px;}
#t6_180{left:166px;bottom:860px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t7_180{left:534px;bottom:860px;letter-spacing:-0.09px;}
#t8_180{left:569px;bottom:860px;letter-spacing:-0.16px;}
#t9_180{left:644px;bottom:860px;}
#ta_180{left:91px;bottom:817px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_180{left:176px;bottom:817px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tc_180{left:91px;bottom:797px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_180{left:176px;bottom:797px;letter-spacing:-0.19px;word-spacing:0.09px;}
#te_180{left:91px;bottom:776px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tf_180{left:176px;bottom:776px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tg_180{left:91px;bottom:755px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_180{left:176px;bottom:755px;letter-spacing:-0.17px;word-spacing:0.07px;}
#ti_180{left:91px;bottom:734px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tj_180{left:176px;bottom:734px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tk_180{left:112px;bottom:657px;letter-spacing:0.05px;word-spacing:0.15px;}
#tl_180{left:87px;bottom:617px;letter-spacing:-0.17px;word-spacing:0.06px;}
#tm_180{left:87px;bottom:588px;letter-spacing:-0.2px;word-spacing:3.66px;}
#tn_180{left:87px;bottom:568px;letter-spacing:-0.11px;word-spacing:3.01px;}
#to_180{left:333px;bottom:568px;letter-spacing:-0.14px;word-spacing:3.06px;}
#tp_180{left:560px;bottom:568px;letter-spacing:-0.13px;word-spacing:3.06px;}
#tq_180{left:87px;bottom:548px;letter-spacing:-0.12px;word-spacing:0.9px;}
#tr_180{left:87px;bottom:528px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_180{left:87px;bottom:488px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tt_180{left:87px;bottom:459px;letter-spacing:-0.16px;word-spacing:5.2px;}
#tu_180{left:496px;bottom:459px;letter-spacing:-0.18px;word-spacing:5.2px;}
#tv_180{left:87px;bottom:439px;letter-spacing:-0.16px;word-spacing:0.79px;}
#tw_180{left:87px;bottom:418px;letter-spacing:-0.11px;}
#tx_180{left:121px;bottom:418px;letter-spacing:-0.11px;word-spacing:0.03px;}
#ty_180{left:192px;bottom:418px;letter-spacing:-0.33px;word-spacing:0.24px;}
#tz_180{left:142px;bottom:348px;}
#t10_180{left:143px;bottom:311px;}
#t11_180{left:165px;bottom:311px;letter-spacing:-0.16px;}
#t12_180{left:272px;bottom:311px;letter-spacing:-0.09px;}
#t13_180{left:358px;bottom:311px;letter-spacing:-0.16px;}
#t14_180{left:455px;bottom:311px;letter-spacing:-0.12px;}
#t15_180{left:569px;bottom:311px;letter-spacing:-0.42px;}
#t16_180{left:648px;bottom:311px;letter-spacing:-0.08px;}
#t17_180{left:165px;bottom:290px;letter-spacing:-0.14px;word-spacing:6.91px;}
#t18_180{left:330px;bottom:290px;letter-spacing:-0.17px;word-spacing:6.94px;}
#t19_180{left:165px;bottom:270px;letter-spacing:-0.14px;word-spacing:7.14px;}
#t1a_180{left:165px;bottom:250px;letter-spacing:-0.14px;word-spacing:10.1px;}
#t1b_180{left:165px;bottom:230px;letter-spacing:-0.15px;word-spacing:9.23px;}
#t1c_180{left:165px;bottom:210px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1d_180{left:143px;bottom:190px;}
#t1e_180{left:165px;bottom:190px;letter-spacing:-0.19px;word-spacing:11.6px;}
#t1f_180{left:165px;bottom:169px;letter-spacing:-0.21px;word-spacing:6.58px;}
#t1g_180{left:165px;bottom:149px;letter-spacing:-0.11px;}
#t1h_180{left:143px;bottom:129px;}
#t1i_180{left:165px;bottom:129px;letter-spacing:-0.2px;word-spacing:-0.11px;}
#t1j_180{left:494px;bottom:129px;letter-spacing:-0.21px;word-spacing:-0.11px;}

.s0_180{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_180{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_180{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_180{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_180{font-size:17px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s5_180{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s6_180{font-size:17px;font-family:MogulArial_18b;color:#000;}`}
      </style>

      <style id="fonts180" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg180" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="180/180.svg"
          type="image/svg+xml"
          id="pdf180"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_180" class="t s0_180">
          180{" "}
        </span>
        <span id="t2_180" class="t s1_180">
          эрэмбэлэгдсэн байна. Иймд удирдах ажилтны хөгжлийн төлөвлөгөө{" "}
        </span>
        <span id="t3_180" class="t s1_180">
          боловсруулах, түүндээ доогуур эрэмбэлэгдэж байгаа чадвараа хөгжүүлэх{" "}
        </span>
        <span id="t4_180" class="t s1_180">
          зорилго, үйл ажиллагаагаа төлөвлөх зэргээр ажиллахыг зөвлөж байна.{" "}
        </span>
        <span id="t5_180" class="t s2_180">
          Удирдах{" "}
        </span>
        <span id="t6_180" class="t s2_180">
          чадварыг эрэмбэлэх судалгааны аргачлал{" "}
        </span>
        <span id="t7_180" class="t s2_180">
          (QR{" "}
        </span>
        <span id="t8_180" class="t s1_180">
          уншуулах
        </span>
        <span id="t9_180" class="t s2_180">
          ){" "}
        </span>
        <span id="ta_180" class="t s1_180">
          Алхам 1{" "}
        </span>
        <span id="tb_180" class="t s1_180">
          Асуулгын хуудас боловсруулах{" "}
        </span>
        <span id="tc_180" class="t s1_180">
          Алхам 2{" "}
        </span>
        <span id="td_180" class="t s1_180">
          Мэдээлэл цуглуулах{" "}
        </span>
        <span id="te_180" class="t s1_180">
          Алхам 3{" "}
        </span>
        <span id="tf_180" class="t s1_180">
          Ажиглалтын тархалтыг олох{" "}
        </span>
        <span id="tg_180" class="t s1_180">
          Алхам 4{" "}
        </span>
        <span id="th_180" class="t s1_180">
          Онолын тархалтыг олох{" "}
        </span>
        <span id="ti_180" class="t s1_180">
          Алхам 5{" "}
        </span>
        <span id="tj_180" class="t s1_180">
          Онолын тархалтыг үндэслэн эрэмбэлэх{" "}
        </span>
        <span id="tk_180" class="t s3_180">
          3.6.2 СУРГАЛТ, ҮЙЛ АЖИЛЛАГААНЫ ЗОХИОН БАЙГУУЛАЛТ{" "}
        </span>
        <span id="tl_180" class="t s4_180">
          Сургалт, үйл ажиллагааны зохион байгуулалт юунд чиглэх вэ?{" "}
        </span>
        <span id="tm_180" class="t s1_180">
          Сургалт, үйл ажиллагаа нь тодорхой зорилго, зохион байгуулалттайгаар{" "}
        </span>
        <span id="tn_180" class="t s1_180">
          сурагчийн хөгжилд нөлөөлж,{" "}
        </span>
        <span id="to_180" class="t s1_180">
          тэдний хэрэгцээг хангахад{" "}
        </span>
        <span id="tp_180" class="t s1_180">
          чиглэнэ. Иймд{" "}
        </span>
        <span id="tq_180" class="t s1_180">
          багш нэг бүрийг чиглүүлэх, дэмжих, чадавхижуулах нөхцөлийг бүрдүүлсэн{" "}
        </span>
        <span id="tr_180" class="t s1_180">
          зохион байгуулалт хийх нь чухал юм.{" "}
        </span>
        <span id="ts_180" class="t s4_180">
          Сургалт, үйл ажиллагааг яагаад зохион байгуулдаг вэ?{" "}
        </span>
        <span id="tt_180" class="t s1_180">
          Сургалтын үйл ажиллагааг зохион байгуулснаар{" "}
        </span>
        <span id="tu_180" class="t s5_180">
          “Юуг? хэн? хэрхэн?{" "}
        </span>
        <span id="tv_180" class="t s5_180">
          яаж? хэнтэй зохион байгуулж, үр дүнгээ дүгнэж, хэрхэн сайжруулах{" "}
        </span>
        <span id="tw_180" class="t s5_180">
          вэ?{" "}
        </span>
        <span id="tx_180" class="t s1_180">
          гэдэг нь{" "}
        </span>
        <span id="ty_180" class="t s1_180">
          тодорхой болдог.{" "}
        </span>
        <span id="tz_180" class="t s3_180">
          АСУУДАЛ{" "}
        </span>
        <span id="t10_180" class="t s6_180">
          ●{" "}
        </span>
        <span id="t11_180" class="t s1_180">
          Сургуулийн{" "}
        </span>
        <span id="t12_180" class="t s1_180">
          захирал,{" "}
        </span>
        <span id="t13_180" class="t s1_180">
          сургалтын{" "}
        </span>
        <span id="t14_180" class="t s1_180">
          менежерүүд{" "}
        </span>
        <span id="t15_180" class="t s1_180">
          сургалт,{" "}
        </span>
        <span id="t16_180" class="t s1_180">
          үйл{" "}
        </span>
        <span id="t17_180" class="t s1_180">
          ажиллагаа болон{" "}
        </span>
        <span id="t18_180" class="t s1_180">
          дотоод зохион байгуулалтын аливаа үйл{" "}
        </span>
        <span id="t19_180" class="t s1_180">
          ажиллагаанд манлайлан оролцдоггүй, сургалтын төлөвлөгөө,{" "}
        </span>
        <span id="t1a_180" class="t s1_180">
          хөтөлбөрийн хэрэгжилтийг мэргэжлийн удирдлагаар хангах,{" "}
        </span>
        <span id="t1b_180" class="t s1_180">
          шилдэг арга зүй, технологийг сургалтад нэвтрүүлэх ажлыг{" "}
        </span>
        <span id="t1c_180" class="t s1_180">
          дэмжих, багш нарт зөвлөн туслах, хамтран ажиллах чадвар сул{" "}
        </span>
        <span id="t1d_180" class="t s6_180">
          ●{" "}
        </span>
        <span id="t1e_180" class="t s1_180">
          Сургуулийн захирал, удирдлагууд хамт олноо ялгаварлаж{" "}
        </span>
        <span id="t1f_180" class="t s1_180">
          харьцдаг, өөрийн хүрээлэл үүсгэдэг, урамшуулдаггүй, үр дүн{" "}
        </span>
        <span id="t1g_180" class="t s1_180">
          тооцдоггүй.{" "}
        </span>
        <span id="t1h_180" class="t s6_180">
          ●{" "}
        </span>
        <span id="t1i_180" class="t s1_180">
          Удирдлагын баг нэгдсэн ойлголттой биш,{" "}
        </span>
        <span id="t1j_180" class="t s1_180">
          хамтын ажиллагаа муу,{" "}
        </span>
      </div>
    </div>
  );
}
