import React from "react";
export default function TwentyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_21{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_21{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_21{left:87px;bottom:937px;letter-spacing:0.09px;word-spacing:0.13px;}
#t4_21{left:87px;bottom:886px;letter-spacing:0.07px;}
#t5_21{left:150px;bottom:886px;letter-spacing:0.07px;}
#t6_21{left:229px;bottom:886px;letter-spacing:0.06px;}
#t7_21{left:312px;bottom:886px;letter-spacing:0.08px;}
#t8_21{left:387px;bottom:886px;letter-spacing:0.06px;}
#t9_21{left:538px;bottom:886px;letter-spacing:0.07px;}
#ta_21{left:611px;bottom:886px;letter-spacing:0.07px;}
#tb_21{left:87px;bottom:866px;letter-spacing:0.06px;word-spacing:3.77px;}
#tc_21{left:87px;bottom:846px;letter-spacing:0.05px;word-spacing:7.35px;}
#td_21{left:210px;bottom:846px;letter-spacing:0.06px;word-spacing:7.35px;}
#te_21{left:87px;bottom:826px;letter-spacing:0.06px;word-spacing:8.03px;}
#tf_21{left:87px;bottom:805px;letter-spacing:0.06px;word-spacing:5.7px;}
#tg_21{left:87px;bottom:785px;letter-spacing:0.05px;word-spacing:9.93px;}
#th_21{left:87px;bottom:765px;letter-spacing:0.07px;word-spacing:2.45px;}
#ti_21{left:87px;bottom:745px;letter-spacing:0.07px;word-spacing:1.31px;}
#tj_21{left:87px;bottom:725px;letter-spacing:0.06px;word-spacing:3.13px;}
#tk_21{left:87px;bottom:705px;letter-spacing:0.06px;word-spacing:2.96px;}
#tl_21{left:87px;bottom:684px;letter-spacing:0.06px;word-spacing:3.19px;}
#tm_21{left:87px;bottom:664px;letter-spacing:0.07px;word-spacing:0.96px;}
#tn_21{left:87px;bottom:644px;letter-spacing:0.07px;}
#to_21{left:87px;bottom:611px;letter-spacing:-0.1px;word-spacing:0.19px;}
#tp_21{left:87px;bottom:591px;letter-spacing:-0.11px;word-spacing:3.16px;}
#tq_21{left:281px;bottom:591px;letter-spacing:-0.1px;word-spacing:3.16px;}
#tr_21{left:87px;bottom:571px;letter-spacing:-0.1px;word-spacing:5.09px;}
#ts_21{left:87px;bottom:550px;letter-spacing:-0.1px;word-spacing:1.53px;}
#tt_21{left:87px;bottom:530px;letter-spacing:-0.1px;word-spacing:1.63px;}
#tu_21{left:87px;bottom:510px;letter-spacing:-0.1px;word-spacing:4.47px;}
#tv_21{left:237px;bottom:510px;letter-spacing:-0.1px;word-spacing:4.48px;}
#tw_21{left:87px;bottom:490px;letter-spacing:-0.1px;}
#tx_21{left:87px;bottom:457px;letter-spacing:-0.11px;word-spacing:7.92px;}
#ty_21{left:87px;bottom:437px;letter-spacing:-0.11px;word-spacing:5.6px;}
#tz_21{left:87px;bottom:416px;letter-spacing:-0.11px;word-spacing:2.91px;}
#t10_21{left:400px;bottom:416px;letter-spacing:-0.1px;word-spacing:2.92px;}
#t11_21{left:87px;bottom:396px;letter-spacing:-0.1px;word-spacing:1.1px;}
#t12_21{left:87px;bottom:376px;letter-spacing:-0.1px;word-spacing:4.85px;}
#t13_21{left:87px;bottom:356px;letter-spacing:-0.1px;word-spacing:0.7px;}
#t14_21{left:87px;bottom:336px;letter-spacing:-0.1px;word-spacing:7.54px;}
#t15_21{left:87px;bottom:316px;letter-spacing:-0.1px;word-spacing:-2.33px;}
#t16_21{left:87px;bottom:295px;letter-spacing:-0.11px;word-spacing:7.78px;}
#t17_21{left:87px;bottom:275px;letter-spacing:-0.1px;}
#t18_21{left:87px;bottom:242px;letter-spacing:-0.61px;word-spacing:1.81px;}
#t19_21{left:87px;bottom:222px;letter-spacing:-0.11px;word-spacing:4.67px;}
#t1a_21{left:313px;bottom:222px;letter-spacing:-0.1px;word-spacing:4.67px;}
#t1b_21{left:87px;bottom:202px;letter-spacing:-0.1px;word-spacing:4.69px;}
#t1c_21{left:87px;bottom:182px;letter-spacing:-0.1px;word-spacing:3.61px;}
#t1d_21{left:87px;bottom:161px;letter-spacing:-0.1px;word-spacing:6.85px;}
#t1e_21{left:87px;bottom:141px;letter-spacing:-0.1px;}

.s0_21{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_21{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_21{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_21{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_21{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_21{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts21" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg21" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="21/21.svg"
          type="image/svg+xml"
          id="pdf21"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_21" class="t s1_21">
          21{" "}
        </span>
        <span id="t3_21" class="t s2_21">
          БАГШ ХЭРХЭН ӨӨРЧЛӨГДӨХ ВЭ?{" "}
        </span>
        <span id="t4_21" class="t s3_21">
          Багш{" "}
        </span>
        <span id="t5_21" class="t s3_21">
          зөвхөн{" "}
        </span>
        <span id="t6_21" class="t s3_21">
          өөрийн{" "}
        </span>
        <span id="t7_21" class="t s3_21">
          заадаг{" "}
        </span>
        <span id="t8_21" class="t s3_21">
          судлагдахууны{" "}
        </span>
        <span id="t9_21" class="t s3_21">
          өндөр{" "}
        </span>
        <span id="ta_21" class="t s3_21">
          мэдлэг,{" "}
        </span>
        <span id="tb_21" class="t s3_21">
          туршлагатай байхаас гадна олон чадвараа хөгжүүлэх хэрэгцээтэй{" "}
        </span>
        <span id="tc_21" class="t s3_21">
          болж байна.{" "}
        </span>
        <span id="td_21" class="t s4_21">
          Учир нь тогтворгүй, тодорхойгүй, ээдрээтэй ирээдүйд{" "}
        </span>
        <span id="te_21" class="t s4_21">
          сурагчдаа бэлтгэх шаардлага үүссэн. Багш нарын өмнө “Одоогоор{" "}
        </span>
        <span id="tf_21" class="t s4_21">
          байхгүй ажлын байранд сурагчид маань амжилттай ажиллахын тулд{" "}
        </span>
        <span id="tg_21" class="t s4_21">
          ямар чадвараа, хэрхэн хөгжүүлэж, Би тэдэнд хэрхэн туслах вэ?{" "}
        </span>
        <span id="th_21" class="t s4_21">
          Зохиогоогүй байгаа техник технологийг эзэмшихэд маань ямар бэлтгэл{" "}
        </span>
        <span id="ti_21" class="t s4_21">
          хэрэгтэй вэ?, эсхүл Төсөөлөөгүй нийгмийн асуудал тулгарахад хүүхдүүд{" "}
        </span>
        <span id="tj_21" class="t s4_21">
          хэрхэн шийдэхийг яаж заах вэ?” гэдэг олон асуулт байна. ЭЗХАХБ-ын{" "}
        </span>
        <span id="tk_21" class="t s4_21">
          боловсролын салбарт хэрэгжүүлэх стратегиа тодорхойлохдоо “Хувьсан{" "}
        </span>
        <span id="tl_21" class="t s4_21">
          өөрчлөгдөж буй дэлхийд хөгжиж цэцэглэхийн тулд сурагч арга хэмжээ{" "}
        </span>
        <span id="tm_21" class="t s4_21">
          авах, үйлдэл хийх хэрэгслүүдийг эзэмшиж (agency) сурагч багш хамтран{" "}
        </span>
        <span id="tn_21" class="t s4_21">
          ажиллах (co-agency) хэрэгтэй”-г тэмдэглэжээ.{" "}
        </span>
        <span id="to_21" class="t s3_21">
          “Нэг заах арга бүх сурагчид тохирно” гэдэг арга хандлагаас татгалзах{" "}
        </span>
        <span id="tp_21" class="t s3_21">
          хэрэгтэй болж байна.{" "}
        </span>
        <span id="tq_21" class="t s4_21">
          Танин мэдэхүйн сэтгэл зүй, тархи судлал болон{" "}
        </span>
        <span id="tr_21" class="t s4_21">
          нийгмийн сэтгэл зүй зэрэг салбарыг багтаасан “Суралцахуйн шинжлэх{" "}
        </span>
        <span id="ts_21" class="t s4_21">
          ухаан” шинээр хөгжсөнөөр бид хүн хэрхэн суралцдаг, хүний тархи хэрхэн{" "}
        </span>
        <span id="tt_21" class="t s4_21">
          ажилладаг талаар илүү ойлголттой болж байна. Мөн багш нар сурагчийн{" "}
        </span>
        <span id="tu_21" class="t s4_21">
          ялгаатай байдал,{" "}
        </span>
        <span id="tv_21" class="t s4_21">
          хувь хүний хэрэгцээг хангахад чиглэсэн сургалт, үйл{" "}
        </span>
        <span id="tw_21" class="t s4_21">
          ажиллагааг зохион байгуулахад суралцах хэрэгтэй болжээ.{" "}
        </span>
        <span id="tx_21" class="t s3_21">
          Сурагч өөрийн суралцахуйн замналыг (learning trajectory) өөрөө{" "}
        </span>
        <span id="ty_21" class="t s3_21">
          дизайнчилж, хариуцлага хүлээж чаддаг болоход дэмжлэг үзүүлэх{" "}
        </span>
        <span id="tz_21" class="t s3_21">
          шинэ үүрэг багшид ногдох боллоо.{" "}
        </span>
        <span id="t10_21" class="t s4_21">
          Суралцахуй болон сургуульд гарч{" "}
        </span>
        <span id="t11_21" class="t s4_21">
          буй өөрчлөлт нь багшийн ажлын байрны тодорхойлолтыг өөрчилж байна.{" "}
        </span>
        <span id="t12_21" class="t s4_21">
          Ойрын ирээдүйд сурагч нийгмийн харилцаанд идэвхтэй оролцож, хувь{" "}
        </span>
        <span id="t13_21" class="t s4_21">
          нэмрээ оруулах үүргээ ухамсарлаж, зорилгодоо хүрэхийн тулд ирээдүйгээ{" "}
        </span>
        <span id="t14_21" class="t s4_21">
          тодорхойлж, түүндээ чиглэсэн арга хэмжээ авч, өөрийн суралцахуйг{" "}
        </span>
        <span id="t15_21" class="t s4_21">
          чиглүүлэх чадварт суралцах шаардлагатай болж байна. Өөрийн суралцахуй,{" "}
        </span>
        <span id="t16_21" class="t s4_21">
          болон амьдралыг удирдан чиглүүлэх чадварыг эзэмшсэн байдал нь{" "}
        </span>
        <span id="t17_21" class="t s4_21">
          боловсролын үр дүнг илэрхийлнэ.{" "}
        </span>
        <span id="t18_21" class="t s3_21">
          Хэдийгээр ирээдүй өөрчлөгдөж байгаа ч багшийн дэмжлэг, чиглүүлгийн{" "}
        </span>
        <span id="t19_21" class="t s3_21">
          ач холбогдол буурахгүй.{" "}
        </span>
        <span id="t1a_21" class="t s4_21">
          Суралцахуй нь сурагч бусад оролцогчидтой{" "}
        </span>
        <span id="t1b_21" class="t s4_21">
          (үе тэнгийнхэн, багш, сурган хүмүүжүүлэгч, дасгалжуулагч, ментор гм.){" "}
        </span>
        <span id="t1c_21" class="t s4_21">
          нийгмийн харилцаанд орох үйл явц байдаг. Багш гэдэг нь суралцахуйн{" "}
        </span>
        <span id="t1d_21" class="t s4_21">
          орчныг бүрдүүлж, суралцахуйг дизайнчилж өгдөг мэргэжилтэн бөгөөд{" "}
        </span>
        <span id="t1e_21" class="t s4_21">
          мэргэжлийн олон янзын арга барилаар суралцахуйд дэмжлэг үзүүлнэ.{" "}
        </span>
      </div>
    </div>
  );
}
