import React from "react";
export default function Two() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_2{left:87px;bottom:206px;letter-spacing:0.1px;word-spacing:0.05px;}
#t2_2{left:87px;bottom:169px;letter-spacing:0.09px;word-spacing:0.05px;}
#t3_2{left:170px;bottom:151px;letter-spacing:0.1px;word-spacing:0.05px;}
#t4_2{left:170px;bottom:132px;letter-spacing:0.1px;word-spacing:0.04px;}
#tt4_2{left:170px;bottom:113px;letter-spacing:0.1px;word-spacing:0.04px;}
#t5_2{left:100px;bottom:832px;letter-spacing:-0.21px;}
#t6_2{left:75px;bottom:823px;letter-spacing:-0.26px;}
#t7_2{left:209px;bottom:832px;letter-spacing:-0.23px;}
#t8_2{left:206px;bottom:823px;letter-spacing:-0.23px;}
#t9_2{left:303px;bottom:832px;letter-spacing:-0.24px;}
#ta_2{left:318px;bottom:823px;letter-spacing:-0.24px;}
#tt9_2{left:579px;bottom:832px;letter-spacing:-0.24px;}
#tta_2{left:563px;bottom:823px;letter-spacing:-0.24px;}
#tb_2{left:455px;bottom:832px;letter-spacing:-0.23px;}
#tc_2{left:450px;bottom:823px;letter-spacing:-0.23px;}

.s0_2{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s1_2{font-size:8px;font-family:MogulFreeset-Bold_3;color:#193576;}`}
      </style>

      <style id="fonts2" type="text/css">
        {`@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset-Bold_3;
	src: url("fonts/MogulFreeset-Bold_3.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg2" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_2" class="t s0_2">
          Захиалагч: Боловсрол Шинжлэх Ухааны Яам{" "}
        </span>
        <span id="t2_2" class="t s0_2">
          Гүйцэтгэгч: Боловсролын ерөнхий газар{" "}
        </span>
        <span id="t3_2" class="t s0_2">
          Боловсролын судалгааны үндэсний хүрээлэн{" "}
        </span>
        <span id="t4_2" class="t s0_2">
          Боловсролын үнэлгээний төв{" "}
        </span>
        <span id="tt4_2" class="t s0_2">
          Нийслэлийн боловсролын газар{" "}
        </span>
        <span id="t5_2" class="t s1_2">
          БОЛОВСРОЛ,{" "}
        </span>
        <span id="t6_2" class="t s1_2">
          ШИНЖЛЭХ УХААНЫ ЯАМ{" "}
        </span>
        <span id="t7_2" class="t s1_2">
          БОЛОВСРОЛЫН{" "}
        </span>
        <span id="t8_2" class="t s1_2">
          ЕРӨНХИЙ ГАЗАР{" "}
        </span>
        <span id="t9_2" class="t s1_2">
          БОЛОВСРОЛЫН СУДАЛГААНЫ{" "}
        </span>
        <span id="ta_2" class="t s1_2">
          ҮНДЭСНИЙ ХҮРЭЭЛЭН{" "}
        </span>
        <span id="tb_2" class="t s1_2">
          БОЛОВСРОЛЫН{" "}
        </span>
        <span id="tc_2" class="t s1_2">
          ҮНЭЛГЭЭНИЙ ТӨВ{" "}
        </span>
        <span id="tt9_2" class="t s1_2">
          НИЙСЛЭЛИЙН{" "}
        </span>
        <span id="tta_2" class="t s1_2">
          БОЛОВСРОЛЫН ГАЗАР{" "}
        </span>
      </div>
    </div>
  );
}
