import React from "react";
export default function NinetyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_94{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_94{left:132px;bottom:928px;letter-spacing:-0.01px;}
#t3_94{left:134px;bottom:900px;}
#t4_94{left:160px;bottom:900px;letter-spacing:-0.16px;word-spacing:8.02px;}
#t5_94{left:160px;bottom:879px;letter-spacing:-0.18px;word-spacing:7.95px;}
#t6_94{left:160px;bottom:859px;letter-spacing:-0.17px;word-spacing:2.47px;}
#t7_94{left:160px;bottom:839px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_94{left:134px;bottom:819px;}
#t9_94{left:160px;bottom:819px;letter-spacing:-0.15px;word-spacing:0.06px;}
#ta_94{left:134px;bottom:799px;}
#tb_94{left:160px;bottom:799px;letter-spacing:-0.15px;word-spacing:-0.84px;}
#tc_94{left:160px;bottom:779px;letter-spacing:-0.13px;word-spacing:0.04px;}
#td_94{left:142px;bottom:716px;letter-spacing:0.11px;word-spacing:0.12px;}
#te_94{left:87px;bottom:683px;letter-spacing:-0.16px;word-spacing:-0.09px;}
#tf_94{left:87px;bottom:663px;letter-spacing:-0.13px;word-spacing:1.16px;}
#tg_94{left:87px;bottom:643px;letter-spacing:-0.13px;word-spacing:1.24px;}
#th_94{left:87px;bottom:623px;letter-spacing:-0.15px;word-spacing:3.69px;}
#ti_94{left:87px;bottom:602px;letter-spacing:-0.14px;word-spacing:4.62px;}
#tj_94{left:87px;bottom:582px;letter-spacing:-0.14px;word-spacing:4.07px;}
#tk_94{left:87px;bottom:562px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tl_94{left:90px;bottom:527px;letter-spacing:-0.34px;}
#tm_94{left:149px;bottom:527px;letter-spacing:-0.33px;}

.s0_94{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_94{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s2_94{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_94{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_94{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_94{font-size:15px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts94" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}
`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg94" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="94/94.svg"
          type="image/svg+xml"
          id="pdf94"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_94" class="t s0_94">
          94{" "}
        </span>
        <span id="t2_94" class="t s1_94">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_94" class="t s2_94">
          •{" "}
        </span>
        <span id="t4_94" class="t s3_94">
          Багш нар хөтөлбөр боловсруулахдаа бусдын боловсруулсан{" "}
        </span>
        <span id="t5_94" class="t s3_94">
          бэлэн төлөвлөлтийг хуулж, менежерээр батлуулах, харуулах{" "}
        </span>
        <span id="t6_94" class="t s3_94">
          зорилгоор ашигладаг. Хүнээс авсан хөтөлбөрөө уншиж, судалж,{" "}
        </span>
        <span id="t7_94" class="t s3_94">
          өөрийн болгож хэрэгжүүлэх үйл ажиллагаа дутмаг{" "}
        </span>
        <span id="t8_94" class="t s2_94">
          •{" "}
        </span>
        <span id="t9_94" class="t s3_94">
          Хөтөлбөрийн агуулга их, цаг бага{" "}
        </span>
        <span id="ta_94" class="t s2_94">
          •{" "}
        </span>
        <span id="tb_94" class="t s3_94">
          Агуулгын залгамж холбоо, нэгж, ээлжит хичээлийн уялдаа холбоог{" "}
        </span>
        <span id="tc_94" class="t s3_94">
          тогтмол хийж, хичээлийг үр дүнтэй болгох тал дээр дутмаг{" "}
        </span>
        <span id="td_94" class="t s4_94">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="te_94" class="t s3_94">
          Сургуулийн удирдлагууд агуулгын залгамж холбоог тухайн ангид болон хэд{" "}
        </span>
        <span id="tf_94" class="t s3_94">
          хэдэн анги дамнан заах агуулгаар гаргаж ирсэн бөгөөд хичээлийн жилийн{" "}
        </span>
        <span id="tg_94" class="t s3_94">
          төлөвлөгөө, нэгжийн төлөвлөгөө боловсруулах, мөн сурагчийн үнэлгээний{" "}
        </span>
        <span id="th_94" class="t s3_94">
          үр дүнд шинжилгээ хийхдээ багш нар хэрэглэж байна. Тухайлбал, нэгж{" "}
        </span>
        <span id="ti_94" class="t s3_94">
          хичээлийн төлөвлөлтөд “энэ нэгж хичээл хүртэл юу сурав?”, “энэ нэгж{" "}
        </span>
        <span id="tj_94" class="t s3_94">
          хичээлийн дараа юу сурах вэ? гэдгийг харахын тулд агуулгын залгамж{" "}
        </span>
        <span id="tk_94" class="t s3_94">
          холбооны хүснэгтийг ашигладаг байна.{" "}
        </span>
        <span id="tl_94" class="t s5_94">
          Хүснэгт{" "}
        </span>
        <span id="tm_94" class="t s5_94">
          3.1. I-V ангийн математикийн хичээлийн Тоо тоолол агуулгын залгамж
          холбоо{" "}
        </span>
      </div>
    </div>
  );
}
