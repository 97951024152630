import React from "react";
export default function HundredSix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_106{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_106{left:87px;bottom:944px;letter-spacing:0.01px;word-spacing:4.69px;}
#t3_106{left:87px;bottom:924px;letter-spacing:0.02px;word-spacing:0.55px;}
#t4_106{left:87px;bottom:904px;letter-spacing:-0.01px;word-spacing:0.08px;}
#t5_106{left:87px;bottom:871px;letter-spacing:0.01px;word-spacing:7.48px;}
#t6_106{left:87px;bottom:850px;letter-spacing:0.01px;word-spacing:-1.07px;}
#t7_106{left:87px;bottom:830px;letter-spacing:0.03px;word-spacing:0.04px;}
#t8_106{left:365px;bottom:802px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t9_106{left:603px;bottom:802px;letter-spacing:-0.17px;}
#ta_106{left:96px;bottom:368px;letter-spacing:0.14px;word-spacing:10.09px;}
#tb_106{left:553px;bottom:368px;letter-spacing:0.08px;}
#tc_106{left:96px;bottom:349px;letter-spacing:0.11px;}
#td_106{left:180px;bottom:349px;letter-spacing:0.06px;word-spacing:0.44px;}
#te_106{left:634px;bottom:349px;letter-spacing:0.12px;}
#tf_106{left:96px;bottom:331px;letter-spacing:0.16px;word-spacing:0.18px;}
#tg_106{left:237px;bottom:331px;letter-spacing:0.09px;word-spacing:0.24px;}
#th_106{left:96px;bottom:313px;letter-spacing:0.1px;word-spacing:0.05px;}
#ti_106{left:87px;bottom:265px;letter-spacing:-0.15px;word-spacing:4.84px;}
#tj_106{left:87px;bottom:245px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tk_106{left:495px;bottom:245px;letter-spacing:-0.1px;}
#tl_106{left:87px;bottom:220px;letter-spacing:-0.18px;word-spacing:0.07px;}
#tm_106{left:98px;bottom:196px;letter-spacing:-0.05px;}
#tn_106{left:125px;bottom:196px;letter-spacing:-0.19px;word-spacing:1.4px;}
#to_106{left:125px;bottom:175px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tp_106{left:98px;bottom:151px;letter-spacing:-0.19px;word-spacing:3.02px;}
#tq_106{left:98px;bottom:126px;letter-spacing:-0.05px;}
#tr_106{left:125px;bottom:126px;letter-spacing:-0.19px;word-spacing:0.55px;}
#ts_106{left:125px;bottom:106px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_106{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_106{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_106{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_106{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s4_106{font-size:15px;font-family:Arial-BoldItalicMT_qt;color:#000;}
.s5_106{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts106" type="text/css">
        {`@font-face {
	font-family: Arial-BoldItalicMT_qt;
	src: url("fonts/Arial-BoldItalicMT_qt.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg106" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="106/106.svg"
          type="image/svg+xml"
          id="pdf106"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_106" class="t s0_106">
          106{" "}
        </span>
        <span id="t2_106" class="t s1_106">
          Жишээ болгож авсан илэрхийлэх чадвар болон аливаад нягт нямбай{" "}
        </span>
        <span id="t3_106" class="t s1_106">
          хандах хандлага нь тухайн сургуулийн сурагчдад тааруу хангалтгүй байж{" "}
        </span>
        <span id="t4_106" class="t s1_106">
          болох юм.{" "}
        </span>
        <span id="t5_106" class="t s1_106">
          Тэгвэл үүнийг системтэй хөгжүүлж болох зураглалыг судлагдахууны{" "}
        </span>
        <span id="t6_106" class="t s1_106">
          нэгдсэн төлөвлөлтөд тусгах боломжтой. Дээрх төлөвлөлтийг судлагдахуун{" "}
        </span>
        <span id="t7_106" class="t s1_106">
          бүрээр боловсролын түвшингээр нэгтгэн хийх шаардлагатай.{" "}
        </span>
        <span id="t8_106" class="t s2_106">
          Хүснэгт 3.6. Судлагдахууны нэгдсэн{" "}
        </span>
        <span id="t9_106" class="t s2_106">
          төлөвлөгөө{" "}
        </span>
        <span id="ta_106" class="t s3_106">
          Мэдлэг, чадвар, хандлагаараа уялдсан хичээлүүд дээр{" "}
        </span>
        <span id="tb_106" class="t s4_106">
          судлагдахуунд{" "}
        </span>
        <span id="tc_106" class="t s4_106">
          суурилсан{" "}
        </span>
        <span id="td_106" class="t s3_106">
          “ХИЧЭЭЛИЙН СУДАЛГАА”-г хийж тухайн чадварыг хөгжүүлэх{" "}
        </span>
        <span id="te_106" class="t s4_106">
          заах{" "}
        </span>
        <span id="tf_106" class="t s4_106">
          аргын шинэ санаа
        </span>
        <span id="tg_106" class="t s3_106">
          г бий болгох бүрэн боломжтой. Энэ нь тухайн жил хийгдэх{" "}
        </span>
        <span id="th_106" class="t s3_106">
          хичээлийн судалгааны “СЭДЭВ” болдог.{" "}
        </span>
        <span id="ti_106" class="t s1_106">
          Төлөвлөгөөний загварууд нь сургууль бүрд ижил байх албагүй. Гэхдээ{" "}
        </span>
        <span id="tj_106" class="t s1_106">
          ерөнхийдөө дараах зүйлсийг багтаасан байх ёстой.{" "}
        </span>
        <span id="tk_106" class="t s1_106">
          Үүнд:{" "}
        </span>
        <span id="tl_106" class="t s5_106">
          Тус төлөвлөлтөд тусгах зүйл{" "}
        </span>
        <span id="tm_106" class="t s1_106">
          1.{" "}
        </span>
        <span id="tn_106" class="t s1_106">
          Тухайн ангийн судлагдахууны суралцахуйн зорилт, ээлжит хичээлийн{" "}
        </span>
        <span id="to_106" class="t s1_106">
          сэдвүүдийг хичээл орох хугацаанд харгалзуулан байршуулах{" "}
        </span>
        <span id="tp_106" class="t s1_106">
          2. Холбоо хамаарлыг харуулах{" "}
        </span>
        <span id="tq_106" class="t s1_106">
          3.{" "}
        </span>
        <span id="tr_106" class="t s1_106">
          Тухайн судлагдахуунаар илүү хөгжүүлэхийг хүсэж буй мэдлэг, чадвар,{" "}
        </span>
        <span id="ts_106" class="t s1_106">
          хандлагын уялдааг ялган тэмдэглэх{" "}
        </span>
      </div>
    </div>
  );
}
