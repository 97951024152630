import React from "react";
export default function FiftySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_57{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_57{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_57{left:87px;bottom:944px;letter-spacing:-0.16px;word-spacing:4.16px;}
#t4_57{left:630px;bottom:944px;letter-spacing:-0.09px;}
#t5_57{left:87px;bottom:924px;letter-spacing:-0.12px;word-spacing:5.74px;}
#t6_57{left:87px;bottom:903px;letter-spacing:-0.14px;word-spacing:6.38px;}
#t7_57{left:87px;bottom:883px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t8_57{left:132px;bottom:850px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t9_57{left:87px;bottom:570px;letter-spacing:-0.14px;word-spacing:3.61px;}
#ta_57{left:87px;bottom:550px;letter-spacing:-0.13px;word-spacing:1.88px;}
#tb_57{left:87px;bottom:529px;letter-spacing:-0.16px;word-spacing:5px;}
#tc_57{left:87px;bottom:509px;letter-spacing:-0.14px;word-spacing:-0.44px;}
#td_57{left:87px;bottom:489px;letter-spacing:-0.18px;word-spacing:7.77px;}
#te_57{left:87px;bottom:469px;letter-spacing:-0.13px;word-spacing:2.65px;}
#tf_57{left:87px;bottom:449px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tg_57{left:148px;bottom:415px;letter-spacing:-0.14px;word-spacing:0.02px;}

.s0_57{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_57{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_57{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_57{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s4_57{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_57{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.t.m0_57{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts57" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg57" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="57/57.svg"
          type="image/svg+xml"
          id="pdf57"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_57" class="t s1_57">
          57{" "}
        </span>
        <span id="t3_57" class="t s2_57">
          Зурагт 2.7-д жишээ болгож харуулсан А сургууль нь нийслэлийн{" "}
        </span>
        <span id="t4_57" class="t s3_57">
          нэгэн{" "}
        </span>
        <span id="t5_57" class="t s2_57">
          дүүргийн сургууль бөгөөд дундаж оноогоо өөрийн хамаарах дүүргийн,{" "}
        </span>
        <span id="t6_57" class="t s2_57">
          нийслэл (бусад дүүргийн)-ийн, улсын дундаж (нийт 3)-тай харьцуулах{" "}
        </span>
        <span id="t7_57" class="t s2_57">
          боломжтой байна.{" "}
        </span>
        <span id="t8_57" class="t s4_57">
          Зураг 2.7. Гүйцэтгэлийн үнэлгээний ерөнхий үр дүн (он, кластерын
          дунджаар){" "}
        </span>
        <span id="t9_57" class="t s2_57">
          Сургалтын байгууллагын гүйцэтгэлийг үнэлсэн 6 шалгуур тус бүрийн үр{" "}
        </span>
        <span id="ta_57" class="t s2_57">
          дүнг та бүхэн авах боломжтой бөгөөд жишээ болгож Зураг 2.8-д үзүүлэв.{" "}
        </span>
        <span id="tb_57" class="t s2_57">
          Тухайн цонхноос сургуулийн гүйцэтгэл шалгуур бүрд ямар үзүүлэлттэй{" "}
        </span>
        <span id="tc_57" class="t s2_57">
          үнэлэгдсэнийг харна. Шалгуур бүрийн үр дүнг улсын дундажтай харьцуулах{" "}
        </span>
        <span id="td_57" class="t s2_57">
          боломжтой. Баруун талын 1-6 тоогоор дугаарлагдсан товч бүр дээр{" "}
        </span>
        <span id="te_57" class="t s2_57">
          дарж тухайн шалгуураар үнэлсэн үр дүнг дэд үзүүлэлтээр, боловсролын{" "}
        </span>
        <span id="tf_57" class="t s2_57">
          түвшнээр, багш бүрээр харна.{" "}
        </span>
        <span id="tg_57" class="t s5_57">
          Зураг 2.8. Гүйцэтгэлийн үнэлгээний ерөнхий үр дүн (шалгуур тус бүрээр){" "}
        </span>
      </div>
    </div>
  );
}
