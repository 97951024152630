import React from "react";
export default function TwentyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_22{left:87px;bottom:58px;letter-spacing:-0.1px;}
#t2_22{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_22{left:87px;bottom:944px;letter-spacing:-0.19px;word-spacing:3.33px;}
#t4_22{left:87px;bottom:924px;letter-spacing:-0.13px;word-spacing:7.6px;}
#t5_22{left:87px;bottom:904px;letter-spacing:-0.18px;word-spacing:5.22px;}
#t6_22{left:87px;bottom:884px;letter-spacing:-0.18px;word-spacing:4.46px;}
#t7_22{left:87px;bottom:864px;letter-spacing:-0.16px;word-spacing:1.86px;}
#t8_22{left:87px;bottom:843px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t9_22{left:240px;bottom:852px;}
#ta_22{left:250px;bottom:843px;letter-spacing:-0.21px;}
#tb_22{left:102px;bottom:787px;letter-spacing:0.07px;word-spacing:0.08px;}
#tc_22{left:102px;bottom:732px;letter-spacing:0.11px;word-spacing:0.03px;}
#td_22{left:102px;bottom:714px;letter-spacing:0.14px;}
#te_22{left:120px;bottom:714px;letter-spacing:0.08px;word-spacing:-1.1px;}
#tf_22{left:102px;bottom:695px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_22{left:102px;bottom:659px;letter-spacing:0.13px;word-spacing:0.02px;}
#th_22{left:102px;bottom:640px;letter-spacing:0.14px;}
#ti_22{left:134px;bottom:640px;letter-spacing:0.12px;}
#tj_22{left:178px;bottom:640px;letter-spacing:0.13px;}
#tk_22{left:232px;bottom:640px;letter-spacing:0.01px;}
#tl_22{left:284px;bottom:640px;letter-spacing:0.1px;}
#tm_22{left:379px;bottom:640px;letter-spacing:0.01px;}
#tn_22{left:431px;bottom:640px;letter-spacing:0.13px;}
#to_22{left:498px;bottom:640px;letter-spacing:-0.06px;}
#tp_22{left:539px;bottom:640px;letter-spacing:0.08px;}
#tq_22{left:596px;bottom:640px;letter-spacing:0.13px;}
#tr_22{left:102px;bottom:622px;letter-spacing:0.04px;word-spacing:-1.17px;}
#ts_22{left:102px;bottom:604px;letter-spacing:0.03px;word-spacing:4.91px;}
#tt_22{left:102px;bottom:585px;letter-spacing:0.08px;word-spacing:0.05px;}
#tu_22{left:102px;bottom:549px;letter-spacing:0.13px;word-spacing:0.02px;}
#tv_22{left:102px;bottom:530px;letter-spacing:0.14px;}
#tw_22{left:119px;bottom:530px;letter-spacing:0.06px;word-spacing:-2.04px;}
#tx_22{left:102px;bottom:512px;letter-spacing:0.11px;word-spacing:-0.52px;}
#ty_22{left:102px;bottom:494px;letter-spacing:0.09px;word-spacing:2.89px;}
#tz_22{left:102px;bottom:475px;letter-spacing:0.12px;word-spacing:2.79px;}
#t10_22{left:102px;bottom:457px;letter-spacing:0.09px;word-spacing:0.05px;}
#t11_22{left:102px;bottom:420px;letter-spacing:0.12px;word-spacing:0.02px;}
#t12_22{left:102px;bottom:402px;letter-spacing:0.14px;}
#t13_22{left:126px;bottom:402px;letter-spacing:0.09px;word-spacing:4.65px;}
#t14_22{left:102px;bottom:384px;letter-spacing:0.11px;word-spacing:-1.59px;}
#t15_22{left:102px;bottom:365px;letter-spacing:0.11px;word-spacing:2.28px;}
#t16_22{left:102px;bottom:347px;letter-spacing:0.12px;word-spacing:1.99px;}
#t17_22{left:102px;bottom:329px;letter-spacing:0.12px;word-spacing:4.89px;}
#t18_22{left:102px;bottom:310px;letter-spacing:0.09px;word-spacing:0.44px;}
#t19_22{left:102px;bottom:292px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1a_22{left:350px;bottom:260px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t1b_22{left:392px;bottom:243px;letter-spacing:-0.11px;}
#t1c_22{left:87px;bottom:133px;}
#t1d_22{left:108px;bottom:133px;letter-spacing:0.08px;word-spacing:-0.04px;}
#t1e_22{left:108px;bottom:118px;letter-spacing:0.11px;}
#t1f_22{left:188px;bottom:118px;letter-spacing:0.06px;word-spacing:0.05px;}

.s0_22{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_22{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_22{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_22{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s4_22{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s5_22{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s6_22{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s7_22{font-size:14px;font-family:Arial-ItalicMT_18u;color:#000;}
.s8_22{font-size:12px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts22" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg22" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="22/22.svg"
          type="image/svg+xml"
          id="pdf22"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_22" class="t s1_22">
          22{" "}
        </span>
        <span id="t3_22" class="t s2_22">
          Ирээдүйн багш бол “Бусдаас болон орчноос хүлээн авсан шинэ мэдлэг,{" "}
        </span>
        <span id="t4_22" class="t s2_22">
          мэдээлэл, соёлын зүйлийг танин мэдэж ухаарсны үндсэн дээр үзэл{" "}
        </span>
        <span id="t5_22" class="t s2_22">
          бодлоо өөрчлөн өөрийн болгож хувирган, шинэ үнэт зүйлийг бүтээдэг,{" "}
        </span>
        <span id="t6_22" class="t s2_22">
          эзэмшсэн мэдлэг, чадвараа ашиглан ээдрээтэй асуудлыг шийдэх аргаа{" "}
        </span>
        <span id="t7_22" class="t s2_22">
          өөрчлөн хэрэглэж чаддаг, насан туршдаа тасралтгүй хөгжих хандлагатай,{" "}
        </span>
        <span id="t8_22" class="t s2_22">
          суралцахуйн хөтөч”{" "}
        </span>
        <span id="t9_22" class="t s3_22">
          5{" "}
        </span>
        <span id="ta_22" class="t s2_22">
          юм.{" "}
        </span>
        <span id="tb_22" class="t s4_22">
          Шигтгээ 1: Товч асуулт хариулт{" "}
        </span>
        <span id="tc_22" class="t s5_22">
          А: XXI зууны шинэ багш хэн бэ?{" "}
        </span>
        <span id="td_22" class="t s4_22">
          Х:{" "}
        </span>
        <span id="te_22" class="t s6_22">
          XXI зууны шинэ багш бол үзэл баримтлал юм. Энэ бол технологи,
          инноваци,{" "}
        </span>
        <span id="tf_22" class="t s6_22">
          сурагч төвтэй боловсролын хандлагыг хүлээн зөвшөөрдөг багш юм.{" "}
        </span>
        <span id="tg_22" class="t s5_22">
          А: Энэ шинэ багш уламжлалт багш нараас юугаараа ялгаатай вэ?{" "}
        </span>
        <span id="th_22" class="t s4_22">
          Х:{" "}
        </span>
        <span id="ti_22" class="t s6_22">
          Энэ{" "}
        </span>
        <span id="tj_22" class="t s6_22">
          шинэ{" "}
        </span>
        <span id="tk_22" class="t s6_22">
          багш{" "}
        </span>
        <span id="tl_22" class="t s6_22">
          уламжлалт{" "}
        </span>
        <span id="tm_22" class="t s6_22">
          багш{" "}
        </span>
        <span id="tn_22" class="t s6_22">
          нараас{" "}
        </span>
        <span id="to_22" class="t s6_22">
          хэд{" "}
        </span>
        <span id="tp_22" class="t s6_22">
          хэдэн{" "}
        </span>
        <span id="tq_22" class="t s6_22">
          зүйлээр{" "}
        </span>
        <span id="tr_22" class="t s6_22">
          ялгаатай. Нэгдүгээрт, тэд технологийг анхаарал сарниулах бус харин
          суралцах{" "}
        </span>
        <span id="ts_22" class="t s6_22">
          чадварыг сайжруулах хэрэгсэл гэж үздэг. Хоёрдугаарт, тэд сурагчдынхаа{" "}
        </span>
        <span id="tt_22" class="t s6_22">
          хэрэгцээ, сонирхлыг нэн тэргүүнд тавьж, хичээлээ төлөвлөдөг.{" "}
        </span>
        <span id="tu_22" class="t s5_22">
          А: Шинэ багш ямар ур чадвартай байх ёстой вэ?{" "}
        </span>
        <span id="tv_22" class="t s4_22">
          Х:{" "}
        </span>
        <span id="tw_22" class="t s6_22">
          Шинэ багш нь технологийн бичиг үсэг, дасан зохицох чадвар, бүтээлч
          байдал,{" "}
        </span>
        <span id="tx_22" class="t s6_22">
          харилцааны хүчтэй ур чадвар зэрэг олон төрлийн ур чадварыг эзэмшсэн
          байх{" "}
        </span>
        <span id="ty_22" class="t s6_22">
          шаардлагатай. Тэд бусад багш, боловсролын мэргэжилтнүүдтэй үр дүнтэй{" "}
        </span>
        <span id="tz_22" class="t s6_22">
          хамтран ажиллах, үргэлж өөрчлөлтөд нээлттэй, шинэ, шинэлэг заах аргыг{" "}
        </span>
        <span id="t10_22" class="t s6_22">
          эрэлхийлдэг чадвартай байх ёстой.{" "}
        </span>
        <span id="t11_22" class="t s5_22">
          А: Сургуулиуд энэ шинэ багшийн хөгжлийг хэрхэн дэмжих вэ?{" "}
        </span>
        <span id="t12_22" class="t s4_22">
          Х:{" "}
        </span>
        <span id="t13_22" class="t s6_22">
          Технологи, заах шинэлэг арга барилыг ашиглахад чиглэсэн мэргэжил{" "}
        </span>
        <span id="t14_22" class="t s6_22">
          дээшлүүлэх байнгын боломжоор хангах замаар энэхүү шинэ багшийн
          хөгжлийг{" "}
        </span>
        <span id="t15_22" class="t s6_22">
          дэмжих боломжтой. Нэмж дурдахад сургуулиуд хамтран ажиллах, туршилт{" "}
        </span>
        <span id="t16_22" class="t s6_22">
          хийх соёлыг бий болгож, багш нар санаа бодлоо хуваалцаж, шинэ зүйлийг{" "}
        </span>
        <span id="t17_22" class="t s6_22">
          туршиж үзэхийг дэмждэг байх хэрэгтэй. Эцэст нь, сургуулиуд сургалтын{" "}
        </span>
        <span id="t18_22" class="t s6_22">
          шинэ хандлагыг хэрэгжүүлэхэд шаардлагатай технологи, нөөц бололцоогоор{" "}
        </span>
        <span id="t19_22" class="t s6_22">
          хангахад анхаарах нь чухал.{" "}
        </span>
        <span id="t1a_22" class="t s7_22">
          Эх сурвалж. https://parentingalpha.com/discover-{" "}
        </span>
        <span id="t1b_22" class="t s7_22">
          the-6-qualities-of-the-21st-century-teacher/{" "}
        </span>
        <span id="t1c_22" class="t s8_22">
          5{" "}
        </span>
        <span id="t1d_22" class="t s8_22">
          МУБИС-ийн захирлын 2023 оны 6 дугаар сарын 30-ны өдрийн тушаалаар
          батлагдсан “Багш бэлтгэх{" "}
        </span>
        <span id="t1e_22" class="t s8_22">
          хөтөлбөрийн{" "}
        </span>
        <span id="t1f_22" class="t s8_22">
          үзэл баримтлал”-аас{" "}
        </span>
      </div>
    </div>
  );
}
