import React from "react";
export default function F148() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_148{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_148{left:94px;bottom:929px;}
#t3_148{left:148.6px;bottom:863.7px;letter-spacing:0.06px;word-spacing:0.05px;}
#t4_148{left:177px;bottom:937px;letter-spacing:0.06px;word-spacing:0.05px;}
#t5_148{left:177px;bottom:923px;letter-spacing:0.09px;word-spacing:0.02px;}
#t6_148{left:177px;bottom:908px;letter-spacing:0.05px;word-spacing:0.05px;}
#t7_148{left:177px;bottom:893px;letter-spacing:0.06px;word-spacing:0.04px;}
#t8_148{left:177px;bottom:879px;letter-spacing:0.09px;}
#t9_148{left:308px;bottom:937px;letter-spacing:0.1px;}
#ta_148{left:308px;bottom:923px;letter-spacing:0.08px;word-spacing:0.03px;}
#tb_148{left:308px;bottom:908px;letter-spacing:0.04px;word-spacing:0.06px;}
#tc_148{left:308px;bottom:893px;letter-spacing:0.11px;}
#td_148{left:408px;bottom:937px;letter-spacing:0.12px;word-spacing:-0.01px;}
#te_148{left:408px;bottom:923px;letter-spacing:0.09px;}
#tf_148{left:490px;bottom:937px;letter-spacing:0.05px;}
#tg_148{left:490px;bottom:923px;letter-spacing:0.08px;word-spacing:0.02px;}
#th_148{left:490px;bottom:908px;letter-spacing:0.04px;}
#ti_148{left:490px;bottom:893px;letter-spacing:0.04px;}
#tj_148{left:585px;bottom:937px;letter-spacing:0.09px;}
#tk_148{left:585px;bottom:923px;letter-spacing:0.08px;}
#tl_148{left:585px;bottom:908px;letter-spacing:0.08px;}
#tm_148{left:585px;bottom:893px;letter-spacing:0.08px;}
#tn_148{left:585px;bottom:879px;letter-spacing:0.06px;word-spacing:0.05px;}
#to_148{left:585px;bottom:864px;letter-spacing:0.06px;}
#tp_148{left:94px;bottom:814px;}
#tq_148{left:141.2px;bottom:767.4px;letter-spacing:0.05px;word-spacing:0.06px;}
#tr_148{left:155.9px;bottom:771.6px;letter-spacing:0.11px;}
#ts_148{left:177px;bottom:837px;letter-spacing:0.1px;word-spacing:0.01px;}
#tt_148{left:177px;bottom:822px;letter-spacing:0.08px;word-spacing:0.03px;}
#tu_148{left:177px;bottom:807px;letter-spacing:0.06px;word-spacing:0.05px;}
#tv_148{left:177px;bottom:793px;letter-spacing:0.05px;word-spacing:0.06px;}
#tw_148{left:177px;bottom:778px;letter-spacing:0.09px;}
#tx_148{left:308px;bottom:837px;letter-spacing:0.1px;}
#ty_148{left:308px;bottom:822px;letter-spacing:0.09px;}
#tz_148{left:308px;bottom:807px;letter-spacing:0.03px;word-spacing:0.07px;}
#t10_148{left:308px;bottom:793px;letter-spacing:0.02px;word-spacing:0.08px;}
#t11_148{left:308px;bottom:778px;letter-spacing:0.11px;word-spacing:-0.01px;}
#t12_148{left:308px;bottom:763px;letter-spacing:-0.01px;word-spacing:0.11px;}
#t13_148{left:308px;bottom:749px;letter-spacing:0.1px;}
#t14_148{left:408px;bottom:837px;letter-spacing:0.09px;}
#t15_148{left:408px;bottom:822px;letter-spacing:0.08px;}
#t16_148{left:408px;bottom:807px;letter-spacing:0.09px;}
#t17_148{left:408px;bottom:793px;letter-spacing:0.03px;word-spacing:0.07px;}
#t18_148{left:408px;bottom:778px;letter-spacing:0.03px;}
#t19_148{left:408px;bottom:763px;letter-spacing:0.15px;}
#t1a_148{left:490px;bottom:837px;letter-spacing:0.05px;word-spacing:0.05px;}
#t1b_148{left:490px;bottom:822px;letter-spacing:0.06px;}
#t1c_148{left:490px;bottom:807px;letter-spacing:0.02px;}
#t1d_148{left:490px;bottom:793px;letter-spacing:0.03px;}
#t1e_148{left:490px;bottom:778px;letter-spacing:-0.07px;word-spacing:0.16px;}
#t1f_148{left:585px;bottom:837px;letter-spacing:0.1px;}
#t1g_148{left:585px;bottom:822px;letter-spacing:0.09px;}
#t1h_148{left:585px;bottom:807px;letter-spacing:0.04px;word-spacing:0.05px;}
#t1i_148{left:585px;bottom:793px;letter-spacing:0.06px;}
#t1j_148{left:585px;bottom:778px;letter-spacing:0.05px;}
#t1k_148{left:585px;bottom:763px;letter-spacing:0.09px;}
#t1l_148{left:94px;bottom:706px;}
#t1m_148{left:133.9px;bottom:693.1px;letter-spacing:0.11px;}
#t1n_148{left:148.6px;bottom:682.9px;letter-spacing:0.09px;}
#t1o_148{left:163.2px;bottom:677.2px;letter-spacing:0.15px;}
#t1p_148{left:177px;bottom:729px;letter-spacing:0.05px;word-spacing:0.06px;}
#t1q_148{left:177px;bottom:714px;letter-spacing:0.13px;}
#t1r_148{left:250px;bottom:714px;letter-spacing:0.09px;}
#t1s_148{left:177px;bottom:700px;letter-spacing:0.07px;word-spacing:0.04px;}
#t1t_148{left:308px;bottom:729px;letter-spacing:0.1px;}
#t1u_148{left:308px;bottom:714px;letter-spacing:0.13px;}
#t1v_148{left:308px;bottom:700px;letter-spacing:0.1px;}
#t1w_148{left:408px;bottom:729px;letter-spacing:0.1px;}
#t1x_148{left:408px;bottom:714px;letter-spacing:0.13px;}
#t1y_148{left:408px;bottom:700px;letter-spacing:0.08px;}
#t1z_148{left:408px;bottom:685px;letter-spacing:0.03px;word-spacing:0.07px;}
#t20_148{left:408px;bottom:670px;letter-spacing:0.1px;word-spacing:0.01px;}
#t21_148{left:490px;bottom:729px;letter-spacing:0.1px;}
#t22_148{left:490px;bottom:714px;letter-spacing:0.13px;}
#t23_148{left:490px;bottom:700px;letter-spacing:0.01px;}
#t24_148{left:490px;bottom:685px;letter-spacing:0.05px;}
#t25_148{left:490px;bottom:670px;letter-spacing:0.02px;word-spacing:0.09px;}
#t26_148{left:585px;bottom:729px;letter-spacing:0.1px;word-spacing:0.02px;}
#t27_148{left:585px;bottom:714px;letter-spacing:0.13px;}
#t28_148{left:585px;bottom:700px;letter-spacing:0.06px;}
#t29_148{left:94px;bottom:620px;}
#t2a_148{left:141.2px;bottom:577.3px;letter-spacing:0.07px;}
#t2b_148{left:155.9px;bottom:591.2px;letter-spacing:-0.01px;}
#t2c_148{left:177px;bottom:642px;letter-spacing:0.09px;word-spacing:0.02px;}
#t2d_148{left:177px;bottom:628px;letter-spacing:0.05px;word-spacing:-0.03px;}
#t2e_148{left:177px;bottom:613px;letter-spacing:0.1px;word-spacing:0.02px;}
#t2f_148{left:308px;bottom:642px;letter-spacing:0.11px;}
#t2g_148{left:308px;bottom:628px;letter-spacing:0.04px;word-spacing:0.06px;}
#t2h_148{left:308px;bottom:613px;letter-spacing:0.03px;}
#t2i_148{left:408px;bottom:642px;letter-spacing:0.11px;}
#t2j_148{left:408px;bottom:628px;letter-spacing:-0.02px;}
#t2k_148{left:408px;bottom:613px;letter-spacing:0.03px;}
#t2l_148{left:408px;bottom:598px;letter-spacing:0.13px;}
#t2m_148{left:490px;bottom:642px;letter-spacing:-0.01px;}
#t2n_148{left:490px;bottom:628px;letter-spacing:0.02px;word-spacing:0.08px;}
#t2o_148{left:585px;bottom:642px;letter-spacing:0.06px;}
#t2p_148{left:585px;bottom:628px;letter-spacing:0.01px;word-spacing:0.08px;}
#t2q_148{left:585px;bottom:613px;letter-spacing:0.08px;}
#t2r_148{left:585px;bottom:598px;letter-spacing:-0.04px;}
#t2s_148{left:123px;bottom:511px;letter-spacing:0.12px;}
#t2t_148{left:174px;bottom:511px;letter-spacing:-0.01px;word-spacing:0.22px;}
#t2u_148{left:300px;bottom:485px;letter-spacing:0.18px;word-spacing:0.04px;}
#t2v_148{left:87px;bottom:448px;letter-spacing:-0.16px;word-spacing:8.58px;}
#t2w_148{left:87px;bottom:427px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t2x_148{left:87px;bottom:403px;}
#t2y_148{left:114px;bottom:403px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t2z_148{left:87px;bottom:378px;}
#t30_148{left:114px;bottom:378px;letter-spacing:-0.33px;word-spacing:9.99px;}
#t31_148{left:114px;bottom:358px;letter-spacing:-0.3px;word-spacing:2.1px;}
#t32_148{left:114px;bottom:338px;letter-spacing:-0.28px;word-spacing:0.01px;}
#t33_148{left:142px;bottom:285px;letter-spacing:-0.03px;}
#t34_148{left:139px;bottom:257px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t35_148{left:139px;bottom:237px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t36_148{left:139px;bottom:212px;}
#t37_148{left:165px;bottom:212px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t38_148{left:139px;bottom:188px;}
#t39_148{left:165px;bottom:188px;letter-spacing:-0.14px;word-spacing:-1.34px;}
#t3a_148{left:165px;bottom:168px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t3b_148{left:139px;bottom:143px;}
#t3c_148{left:165px;bottom:143px;letter-spacing:-0.16px;word-spacing:7.67px;}
#t3d_148{left:165px;bottom:123px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t3e_148{left:360px;bottom:123px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_148{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_148{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}
.s2_148{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s3_148{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s4_148{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_148{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s6_148{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.t.m0_148{transform:matrix(0,-1,1,0,0,0);}`}
      </style>

      <style id="fonts148" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg148" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="148/148.svg"
          type="image/svg+xml"
          id="pdf148"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_148" class="t s0_148">
          148{" "}
        </span>
        <span id="t2_148" class="t s1_148">
          3{" "}
        </span>
        <span id="t3_148" class="t m0_148 s1_148">
          Үе шат, хугацаа{" "}
        </span>
        <span id="t4_148" class="t s2_148">
          Зорилгодоо хүрэх{" "}
        </span>
        <span id="t5_148" class="t s2_148">
          үйл ажиллагааг{" "}
        </span>
        <span id="t6_148" class="t s2_148">
          бүрэн оновчтой,{" "}
        </span>
        <span id="t7_148" class="t s2_148">
          үе шатаар нь{" "}
        </span>
        <span id="t8_148" class="t s2_148">
          төлөвлөсөн.{" "}
        </span>
        <span id="t9_148" class="t s2_148">
          Үйлийг{" "}
        </span>
        <span id="ta_148" class="t s2_148">
          төлөвлөсөн ч{" "}
        </span>
        <span id="tb_148" class="t s2_148">
          бүтээлч үйл{" "}
        </span>
        <span id="tc_148" class="t s2_148">
          биш{" "}
        </span>
        <span id="td_148" class="t s2_148">
          Үйлийг дутуу{" "}
        </span>
        <span id="te_148" class="t s2_148">
          төлөвлөсөн.{" "}
        </span>
        <span id="tf_148" class="t s2_148">
          Зорилгодоо{" "}
        </span>
        <span id="tg_148" class="t s2_148">
          хүрэх үйл{" "}
        </span>
        <span id="th_148" class="t s2_148">
          тодорхойгүй,{" "}
        </span>
        <span id="ti_148" class="t s2_148">
          оновчгүй{" "}
        </span>
        <span id="tj_148" class="t s2_148">
          Үйл{" "}
        </span>
        <span id="tk_148" class="t s2_148">
          ажиллагааг{" "}
        </span>
        <span id="tl_148" class="t s2_148">
          төлөвлөөгүй,{" "}
        </span>
        <span id="tm_148" class="t s2_148">
          төлөвлөсөн{" "}
        </span>
        <span id="tn_148" class="t s2_148">
          нь шаардлага{" "}
        </span>
        <span id="to_148" class="t s2_148">
          хангахгүй{" "}
        </span>
        <span id="tp_148" class="t s1_148">
          4{" "}
        </span>
        <span id="tq_148" class="t m0_148 s1_148">
          Арга, зохион{" "}
        </span>
        <span id="tr_148" class="t m0_148 s1_148">
          байгуулалт{" "}
        </span>
        <span id="ts_148" class="t s2_148">
          Сонгосон үйлээ{" "}
        </span>
        <span id="tt_148" class="t s2_148">
          хэрэгжүүлэх арга,{" "}
        </span>
        <span id="tu_148" class="t s2_148">
          зохион байгуулалтыг{" "}
        </span>
        <span id="tv_148" class="t s2_148">
          оновчтой бүрэн{" "}
        </span>
        <span id="tw_148" class="t s2_148">
          төлөвлөсөн.{" "}
        </span>
        <span id="tx_148" class="t s2_148">
          Үйлээ{" "}
        </span>
        <span id="ty_148" class="t s2_148">
          хэрэгжүүлэх{" "}
        </span>
        <span id="tz_148" class="t s2_148">
          арга барил{" "}
        </span>
        <span id="t10_148" class="t s2_148">
          оновчтой ч{" "}
        </span>
        <span id="t11_148" class="t s2_148">
          зарим хэсэг нь{" "}
        </span>
        <span id="t12_148" class="t s2_148">
          дутуу, ойлгомж{" "}
        </span>
        <span id="t13_148" class="t s2_148">
          муутай{" "}
        </span>
        <span id="t14_148" class="t s2_148">
          Үйл{" "}
        </span>
        <span id="t15_148" class="t s2_148">
          ажиллагааг{" "}
        </span>
        <span id="t16_148" class="t s2_148">
          хэрэгжүүлэх{" "}
        </span>
        <span id="t17_148" class="t s2_148">
          арга хэлбэр{" "}
        </span>
        <span id="t18_148" class="t s2_148">
          оновчгүй,{" "}
        </span>
        <span id="t19_148" class="t s2_148">
          дутуу{" "}
        </span>
        <span id="t1a_148" class="t s2_148">
          Арга, зохион{" "}
        </span>
        <span id="t1b_148" class="t s2_148">
          байгуулалтын{" "}
        </span>
        <span id="t1c_148" class="t s2_148">
          хэлбэр{" "}
        </span>
        <span id="t1d_148" class="t s2_148">
          оновчгүй,{" "}
        </span>
        <span id="t1e_148" class="t s2_148">
          дутуу, буруу{" "}
        </span>
        <span id="t1f_148" class="t s2_148">
          Үйлээ{" "}
        </span>
        <span id="t1g_148" class="t s2_148">
          хэрэгжүүлэх{" "}
        </span>
        <span id="t1h_148" class="t s2_148">
          арга, зохион{" "}
        </span>
        <span id="t1i_148" class="t s2_148">
          байгуулалтын{" "}
        </span>
        <span id="t1j_148" class="t s2_148">
          хэлбэрээ{" "}
        </span>
        <span id="t1k_148" class="t s2_148">
          сонгоогүй.{" "}
        </span>
        <span id="t1l_148" class="t s1_148">
          5{" "}
        </span>
        <span id="t1m_148" class="t m0_148 s1_148">
          Нөөц,{" "}
        </span>
        <span id="t1n_148" class="t m0_148 s1_148">
          дэмжлэг{" "}
        </span>
        <span id="t1o_148" class="t m0_148 s1_148">
          туслалцаа{" "}
        </span>
        <span id="t1p_148" class="t s2_148">
          Шаардлагатай нөөц,{" "}
        </span>
        <span id="t1q_148" class="t s2_148">
          дэмжлэгийг{" "}
        </span>
        <span id="t1r_148" class="t s2_148">
          зөв{" "}
        </span>
        <span id="t1s_148" class="t s2_148">
          оновчтой сонгосон{" "}
        </span>
        <span id="t1t_148" class="t s2_148">
          Нөөц,{" "}
        </span>
        <span id="t1u_148" class="t s2_148">
          дэмжлэгийг{" "}
        </span>
        <span id="t1v_148" class="t s2_148">
          дутуу тооцсон{" "}
        </span>
        <span id="t1w_148" class="t s2_148">
          Нөөц,{" "}
        </span>
        <span id="t1x_148" class="t s2_148">
          дэмжлэгийг{" "}
        </span>
        <span id="t1y_148" class="t s2_148">
          тогтоосон{" "}
        </span>
        <span id="t1z_148" class="t s2_148">
          ч оновчгүй{" "}
        </span>
        <span id="t20_148" class="t s2_148">
          эсвэл дутуу{" "}
        </span>
        <span id="t21_148" class="t s2_148">
          Нөөц,{" "}
        </span>
        <span id="t22_148" class="t s2_148">
          дэмжлэгийг{" "}
        </span>
        <span id="t23_148" class="t s2_148">
          оновчтой{" "}
        </span>
        <span id="t24_148" class="t s2_148">
          тогтоогоогүй{" "}
        </span>
        <span id="t25_148" class="t s2_148">
          эсвэл буруу{" "}
        </span>
        <span id="t26_148" class="t s2_148">
          Нөөц болон{" "}
        </span>
        <span id="t27_148" class="t s2_148">
          дэмжлэгийг{" "}
        </span>
        <span id="t28_148" class="t s2_148">
          тогтоогоогүй{" "}
        </span>
        <span id="t29_148" class="t s1_148">
          6{" "}
        </span>
        <span id="t2a_148" class="t m0_148 s1_148">
          Гүйцэтгэлийн{" "}
        </span>
        <span id="t2b_148" class="t m0_148 s1_148">
          нотолгоо{" "}
        </span>
        <span id="t2c_148" class="t s2_148">
          Хөгжсөн байдлаа{" "}
        </span>
        <span id="t2d_148" class="t s2_148">
          юугаар яаж нотлохоо{" "}
        </span>
        <span id="t2e_148" class="t s2_148">
          урьдчилан тооцсон.{" "}
        </span>
        <span id="t2f_148" class="t s2_148">
          Хөгжлийн{" "}
        </span>
        <span id="t2g_148" class="t s2_148">
          нотолгоог дутуу{" "}
        </span>
        <span id="t2h_148" class="t s2_148">
          гаргасан{" "}
        </span>
        <span id="t2i_148" class="t s2_148">
          Хөгжлийн{" "}
        </span>
        <span id="t2j_148" class="t s2_148">
          нотолгоог{" "}
        </span>
        <span id="t2k_148" class="t s2_148">
          оновчгүй,{" "}
        </span>
        <span id="t2l_148" class="t s2_148">
          дутуу{" "}
        </span>
        <span id="t2m_148" class="t s2_148">
          Нотолгоог{" "}
        </span>
        <span id="t2n_148" class="t s2_148">
          оновчгүй, буруу{" "}
        </span>
        <span id="t2o_148" class="t s2_148">
          Гүйцэтгэлийн{" "}
        </span>
        <span id="t2p_148" class="t s2_148">
          нотолгоог огт{" "}
        </span>
        <span id="t2q_148" class="t s2_148">
          төлөвлөөгүй,{" "}
        </span>
        <span id="t2r_148" class="t s2_148">
          буруу{" "}
        </span>
        <span id="t2s_148" class="t s3_148">
          3.3.4{" "}
        </span>
        <span id="t2t_148" class="t s3_148">
          БАГШИЙН ӨӨРИЙН УДИРДЛАГАТАЙ СУРАЛЦАХУЙ,{" "}
        </span>
        <span id="t2u_148" class="t s3_148">
          ТЕХНОЛОГИЙН ЧАДВАР{" "}
        </span>
        <span id="t2v_148" class="t s4_148">
          Багш яагаад өөрийн удирдлагатай суралцахуйг дэмжиж технологийн{" "}
        </span>
        <span id="t2w_148" class="t s4_148">
          чадвараа хөгжүүлэх хэрэгтэй вэ?{" "}
        </span>
        <span id="t2x_148" class="t s5_148">
          •{" "}
        </span>
        <span id="t2y_148" class="t s4_148">
          Багш мэдээлэл технологийн мэдлэг, чадвараа дээшлүүлэх{" "}
        </span>
        <span id="t2z_148" class="t s5_148">
          •{" "}
        </span>
        <span id="t30_148" class="t s4_148">
          Хичээл сургалтын үйл ажиллагаанд технологи ашиглах болсноор{" "}
        </span>
        <span id="t31_148" class="t s4_148">
          сурагчдын сонирхол, танин мэдэх үйл ажиллагаа идэвхжиж, мэдлэгийн{" "}
        </span>
        <span id="t32_148" class="t s4_148">
          чанар дээшилж, мэдээллийн соёлыг эзэмшихэд дэмжлэг үзүүлнэ.{" "}
        </span>
        <span id="t33_148" class="t s6_148">
          АСУУДАЛ{" "}
        </span>
        <span id="t34_148" class="t s4_148">
          Багш мэдээллийн технологийн мэдлэг, чадвараа дээшлүүлэх{" "}
        </span>
        <span id="t35_148" class="t s4_148">
          хэрэгцээ шаардлага хангагддаггүй{" "}
        </span>
        <span id="t36_148" class="t s5_148">
          •{" "}
        </span>
        <span id="t37_148" class="t s4_148">
          Сургалтын цаг болон хичээлийн цаг давхцдаг{" "}
        </span>
        <span id="t38_148" class="t s5_148">
          •{" "}
        </span>
        <span id="t39_148" class="t s4_148">
          Мэргэжлийн багш нартай хамтран нэгж, ээлжит хичээлийн бэлтгэл{" "}
        </span>
        <span id="t3a_148" class="t s4_148">
          хангах боломж байдаггүй{" "}
        </span>
        <span id="t3b_148" class="t s5_148">
          •{" "}
        </span>
        <span id="t3c_148" class="t s4_148">
          Гадаад хэлний мэдлэг болон сурагчдын сонирхол, түвшинд{" "}
        </span>
        <span id="t3d_148" class="t s4_148">
          нийцсэн хэрэглэгдэхүүн{" "}
        </span>
        <span id="t3e_148" class="t s4_148">
          боловсруулах арга зүй дутмаг{" "}
        </span>
      </div>
    </div>
  );
}
