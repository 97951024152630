import React from "react";
export default function HundredFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_105{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_105{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:0.32px;}
#t3_105{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:-0.27px;}
#t4_105{left:87px;bottom:901px;letter-spacing:-0.16px;word-spacing:1.5px;}
#t5_105{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:2.25px;}
#t6_105{left:87px;bottom:860px;letter-spacing:-0.15px;word-spacing:7.74px;}
#t7_105{left:87px;bottom:840px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t8_105{left:87px;bottom:807px;letter-spacing:-0.14px;word-spacing:6.69px;}
#t9_105{left:87px;bottom:787px;letter-spacing:-0.17px;word-spacing:0.84px;}
#ta_105{left:87px;bottom:767px;letter-spacing:-0.17px;word-spacing:4.15px;}
#tb_105{left:544px;bottom:767px;letter-spacing:-0.19px;word-spacing:4.16px;}
#tc_105{left:87px;bottom:746px;letter-spacing:-0.15px;word-spacing:2.65px;}
#td_105{left:284px;bottom:746px;letter-spacing:-0.15px;word-spacing:2.67px;}
#te_105{left:87px;bottom:726px;letter-spacing:-0.13px;word-spacing:9.61px;}
#tf_105{left:87px;bottom:706px;letter-spacing:-0.16px;word-spacing:-0.76px;}
#tg_105{left:87px;bottom:686px;letter-spacing:-0.13px;word-spacing:0.04px;}
#th_105{left:87px;bottom:646px;letter-spacing:-0.1px;}
#ti_105{left:168px;bottom:646px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tj_105{left:87px;bottom:605px;letter-spacing:-0.15px;word-spacing:-0.45px;}
#tk_105{left:87px;bottom:585px;letter-spacing:-0.13px;}
#tl_105{left:186px;bottom:585px;letter-spacing:-0.17px;word-spacing:6.56px;}
#tm_105{left:87px;bottom:565px;letter-spacing:-0.15px;word-spacing:5.35px;}
#tn_105{left:87px;bottom:545px;letter-spacing:-0.14px;word-spacing:0.03px;}
#to_105{left:87px;bottom:512px;letter-spacing:-0.16px;word-spacing:-0.25px;}
#tp_105{left:87px;bottom:491px;letter-spacing:-0.12px;word-spacing:2.86px;}
#tq_105{left:87px;bottom:471px;letter-spacing:-0.13px;word-spacing:2.19px;}
#tr_105{left:87px;bottom:451px;letter-spacing:-0.16px;word-spacing:7.54px;}
#ts_105{left:87px;bottom:431px;letter-spacing:-0.13px;word-spacing:-0.18px;}
#tt_105{left:87px;bottom:411px;letter-spacing:-0.11px;}
#tu_105{left:243px;bottom:383px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tv_105{left:112px;bottom:343px;letter-spacing:-0.13px;}
#tw_105{left:181px;bottom:343px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tx_105{left:332px;bottom:343px;letter-spacing:-0.12px;}
#ty_105{left:356px;bottom:343px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tz_105{left:449px;bottom:343px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t10_105{left:284px;bottom:327px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t11_105{left:91px;bottom:305px;letter-spacing:-0.33px;}
#t12_105{left:134px;bottom:288px;letter-spacing:-0.13px;}
#t13_105{left:266px;bottom:306px;}
#t14_105{left:384px;bottom:306px;letter-spacing:-0.07px;}
#t15_105{left:502px;bottom:306px;letter-spacing:-0.07px;}
#t16_105{left:614px;bottom:306px;letter-spacing:-0.12px;}
#t17_105{left:215px;bottom:287px;}
#t18_105{left:270px;bottom:287px;letter-spacing:-0.07px;}
#t19_105{left:329px;bottom:287px;letter-spacing:-1.15px;}
#t1a_105{left:396px;bottom:287px;letter-spacing:-0.07px;}
#t1b_105{left:455px;bottom:287px;}
#t1c_105{left:510px;bottom:287px;letter-spacing:-0.07px;}
#t1d_105{left:569px;bottom:287px;}
#t1e_105{left:624px;bottom:287px;letter-spacing:-0.07px;}
#t1f_105{left:147px;bottom:269px;}
#t1g_105{left:270px;bottom:269px;}
#t1h_105{left:396px;bottom:269px;}
#t1i_105{left:510px;bottom:269px;}
#t1j_105{left:145px;bottom:250px;letter-spacing:-0.07px;}
#t1k_105{left:396px;bottom:250px;}
#t1l_105{left:569px;bottom:250px;}
#t1m_105{left:143px;bottom:231px;letter-spacing:-0.07px;}
#t1n_105{left:270px;bottom:231px;}
#t1o_105{left:396px;bottom:231px;}
#t1p_105{left:142px;bottom:213px;letter-spacing:-0.12px;}
#t1q_105{left:329px;bottom:213px;}
#t1r_105{left:569px;bottom:213px;}
#t1s_105{left:144px;bottom:194px;}
#t1t_105{left:215px;bottom:194px;}
#t1u_105{left:455px;bottom:194px;}
#t1v_105{left:91px;bottom:171px;letter-spacing:-0.17px;}
#t1w_105{left:156px;bottom:171px;letter-spacing:-0.14px;word-spacing:-0.84px;}
#t1x_105{left:146px;bottom:147px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1y_105{left:110px;bottom:126px;}
#t1z_105{left:146px;bottom:126px;letter-spacing:-0.18px;word-spacing:0.05px;}

.s0_105{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_105{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_105{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_105{font-size:17px;font-family:MogulArial-Italic_17h;color:#4472C4;}
.s4_105{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_105{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}
.s6_105{font-size:14px;font-family:MogulArial-Bold_qk;color:#000;}
.s7_105{font-size:14px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s8_105{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts105" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_qk;
	src: url("fonts/MogulArial-Bold_qk.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg105" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="105/105.svg"
          type="image/svg+xml"
          id="pdf105"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_105" class="t s0_105">
          105{" "}
        </span>
        <span id="t2_105" class="t s1_105">
          нэгтгэнэ. Ингэхдээ ЗАН-ийн түвшинд судлагдахууны босоо болон хөндлөн,{" "}
        </span>
        <span id="t3_105" class="t s1_105">
          анги нэгдлийн түвшинд тухайн ангийн хичээлүүдийн залгамж холбоог,
          клуб,{" "}
        </span>
        <span id="t4_105" class="t s1_105">
          дугуйлан секцийн төлөвлөлт, хичээлээс гадуурх үйл ажиллагааг нийгмийн{" "}
        </span>
        <span id="t5_105" class="t s1_105">
          ажилтан анхан шатнаас гарч ирсэн саналын давхардлыг арилгах замаар{" "}
        </span>
        <span id="t6_105" class="t s1_105">
          сургуулийн зорилт болон сургалтын хөтөлбөрөөр эзэмшүүлэх мэдлэг{" "}
        </span>
        <span id="t7_105" class="t s1_105">
          чадвартай уялдуулан нэгтгэн гаргах нь чухал.{" "}
        </span>
        <span id="t8_105" class="t s1_105">
          Нэгтгэж харахыг хүсэж байгаа бүх зүйлийг нэг төлөвлөгөөнд буулгах{" "}
        </span>
        <span id="t9_105" class="t s1_105">
          амаргүй юм. Тиймд сургалт, үйл ажиллагааны нэгдсэн төлөвлөгөөг дараах{" "}
        </span>
        <span id="ta_105" class="t s1_105">
          2 хэлбэрээр боловсруулж болно. Эдгээр төлөвлөлт нь{" "}
        </span>
        <span id="tb_105" class="t s2_105">
          шинээр нэмэлт{" "}
        </span>
        <span id="tc_105" class="t s2_105">
          ажил хийж байгаа бус{" "}
        </span>
        <span id="td_105" class="t s1_105">
          багш бүрд тус тусдаа хавтсанд байдаг салангид{" "}
        </span>
        <span id="te_105" class="t s1_105">
          төлөвлөлтүүдийг зорилго, чиглэлээр нь нэгтгэж, хүүхдийг хөгжүүлэх{" "}
        </span>
        <span id="tf_105" class="t s1_105">
          боломжийг уялдаанд нь авч үзэхэд дөхөм болгож буй явдал юм. Үүнийг
          бага{" "}
        </span>
        <span id="tg_105" class="t s1_105">
          боловсролын жишээн дээр үзүүлбэл,{" "}
        </span>
        <span id="th_105" class="t s2_105">
          Жишээ 2.{" "}
        </span>
        <span id="ti_105" class="t s1_105">
          Судлагдахууны зорилго, зорилттой уялдсан төлөвлөгөө{" "}
        </span>
        <span id="tj_105" class="t s1_105">
          Хүн ба орчин, хүн ба байгаль хичээлийн 1-5 дугаар ангийн агуулгын
          нэгдсэн{" "}
        </span>
        <span id="tk_105" class="t s1_105">
          төлөвлөгөө{" "}
        </span>
        <span id="tl_105" class="t s3_105">
          (Тухайн судлагдахууны жилийн төлөвлөгөөг боловсролын{" "}
        </span>
        <span id="tm_105" class="t s3_105">
          түвшингээр нэгтгэн харуулна. Ингэхдээ ээлжит хичээл бүрээр эсвэл{" "}
        </span>
        <span id="tn_105" class="t s3_105">
          суралцахуйн зорилтоор нэгтгэж болно).{" "}
        </span>
        <span id="to_105" class="t s1_105">
          Энэхүү төлөвлөгөөнд тухайн судлагдахууны (Хүн ба орчин, Хүн ба
          байгаль){" "}
        </span>
        <span id="tp_105" class="t s1_105">
          1-5 дугаар ангийн бүх хичээлийг нэгтгэж, уялдаа холбоог тэмдэгт юм уу{" "}
        </span>
        <span id="tq_105" class="t s1_105">
          өнгөөр ялгаж тэмдэглэнэ. Ингэснээр сурагчдад байгалийн ухааны хичээл{" "}
        </span>
        <span id="tr_105" class="t s1_105">
          дээр тулгамдаж буй асуудлыг шийдвэрлэхэд (МЧХ) дэмжлэг үзүүлэх{" "}
        </span>
        <span id="ts_105" class="t s1_105">
          хичээлүүдийг (тухайн МЧХ-ыг хөгжүүлэх давтамжийг тооцох) харах боломж{" "}
        </span>
        <span id="tt_105" class="t s1_105">
          бүрдэнэ.{" "}
        </span>
        <span id="tu_105" class="t s4_105">
          Хүснэгт 3.5 Судлагдахууны зорилго, зорилттой уялдсан төлөвлөгөө{" "}
        </span>
        <span id="tv_105" class="t s5_105">
          2023-2024{" "}
        </span>
        <span id="tw_105" class="t s4_105">
          оны хичээлийн жилийн{" "}
        </span>
        <span id="tx_105" class="t s5_105">
          1-5{" "}
        </span>
        <span id="ty_105" class="t s4_105">
          дугаар ангийн{" "}
        </span>
        <span id="tz_105" class="t s5_105">
          Хүн ба орчин, Хүн ба байгаль{" "}
        </span>
        <span id="t10_105" class="t s4_105">
          хичээлийн нэгдсэн төлөвлөгөө{" "}
        </span>
        <span id="t11_105" class="t s4_105">
          Улирал{" "}
        </span>
        <span id="t12_105" class="t s4_105">
          Анги{" "}
        </span>
        <span id="t13_105" class="t s4_105">
          I{" "}
        </span>
        <span id="t14_105" class="t s4_105">
          II{" "}
        </span>
        <span id="t15_105" class="t s4_105">
          III{" "}
        </span>
        <span id="t16_105" class="t s4_105">
          IV{" "}
        </span>
        <span id="t17_105" class="t s4_105">
          9{" "}
        </span>
        <span id="t18_105" class="t s4_105">
          ...{" "}
        </span>
        <span id="t19_105" class="t s4_105">
          11{" "}
        </span>
        <span id="t1a_105" class="t s4_105">
          ...{" "}
        </span>
        <span id="t1b_105" class="t s4_105">
          1{" "}
        </span>
        <span id="t1c_105" class="t s4_105">
          ...{" "}
        </span>
        <span id="t1d_105" class="t s4_105">
          4{" "}
        </span>
        <span id="t1e_105" class="t s4_105">
          ..{" "}
        </span>
        <span id="t1f_105" class="t s4_105">
          I{" "}
        </span>
        <span id="t1g_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1h_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1i_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1j_105" class="t s4_105">
          II{" "}
        </span>
        <span id="t1k_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1l_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1m_105" class="t s4_105">
          III{" "}
        </span>
        <span id="t1n_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1o_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1p_105" class="t s4_105">
          IV{" "}
        </span>
        <span id="t1q_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1r_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1s_105" class="t s4_105">
          V{" "}
        </span>
        <span id="t1t_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1u_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1v_105" class="t s7_105">
          Тайлбар:{" "}
        </span>
        <span id="t1w_105" class="t s8_105">
          Хүснэгтийн нүд бүрд ээлжит хичээлийн сэдэв юм уу суралцахуйн зорилт
          байна.{" "}
        </span>
        <span id="t1x_105" class="t s4_105">
          Зургаар илэрхийлэх чадвар{" "}
        </span>
        <span id="t1y_105" class="t s6_105">
          ∆{" "}
        </span>
        <span id="t1z_105" class="t s4_105">
          Нягт нямбай хандах байдал{" "}
        </span>
      </div>
    </div>
  );
}
