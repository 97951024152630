import React from "react";
export default function F141() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_141{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_141{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:4.14px;}
#t3_141{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:0.5px;}
#t4_141{left:87px;bottom:901px;letter-spacing:-0.2px;word-spacing:1.04px;}
#t5_141{left:87px;bottom:880px;letter-spacing:-0.13px;word-spacing:-0.61px;}
#t6_141{left:87px;bottom:860px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t7_141{left:241px;bottom:832px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t8_141{left:116px;bottom:593px;letter-spacing:-0.18px;word-spacing:0.05px;}
#t9_141{left:387px;bottom:593px;letter-spacing:-0.15px;word-spacing:0.01px;}
#ta_141{left:647px;bottom:593px;letter-spacing:-0.17px;}
#tb_141{left:177px;bottom:532px;letter-spacing:0.13px;word-spacing:0.01px;}

.s0_141{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_141{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s2_141{font-size:14px;font-family:ArialMT_1lm;color:#000;}
.s3_141{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}`}
      </style>

      <style id="fonts141" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg141" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="141/141.svg"
          type="image/svg+xml"
          id="pdf141"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_141" class="t s0_141">
          141{" "}
        </span>
        <span id="t2_141" class="t s1_141">
          Багш нарын хэрэгцээг ЗАН, МХБ, сургалтын менежер хамтран нэгтгэж,{" "}
        </span>
        <span id="t3_141" class="t s1_141">
          сургуулийн хэмжээнд багш нарын хөгжлийн хэрэгцээг тодорхойлж, түүнийг{" "}
        </span>
        <span id="t4_141" class="t s1_141">
          хангах сургалт, үйл ажиллагааг бие даан болон БШУГ, орон нутгийн бусад{" "}
        </span>
        <span id="t5_141" class="t s1_141">
          сургуультай хамтран, БЕГ-ын даргын 2022 оны 9 дүгээр сарын 19-ний
          А/210{" "}
        </span>
        <span id="t6_141" class="t s1_141">
          тоот тушаалаар тодорхой зааж өгсний дагуу төлөвлөнө.{" "}
        </span>
        <span id="t7_141" class="t s2_141">
          Зураг 3.21. ЗАН болон МХБ-ийн төлөвлөгөө{" "}
        </span>
        <span id="t8_141" class="t s2_141">
          Судлагдахуунд суурилсан МХБ{" "}
        </span>
        <span id="t9_141" class="t s2_141">
          Суралцахуйд суурилсан МХБ{" "}
        </span>
        <span id="ta_141" class="t s2_141">
          ЗАН{" "}
        </span>
        <span id="tb_141" class="t s3_141">
          Зураг ... Багшийн хөгжлийн хэрэгцээг нэгтгэсэн байдал{" "}
        </span>
      </div>
    </div>
  );
}
