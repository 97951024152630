import React from "react";
export default function Eleven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_11{left:676px;bottom:58px;letter-spacing:-0.07px;}
#t2_11{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_11{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:5.66px;}
#t4_11{left:275px;bottom:941px;letter-spacing:-0.12px;word-spacing:5.68px;}
#t5_11{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:3.2px;}
#t6_11{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:-0.34px;}
#t7_11{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:1.89px;}
#t8_11{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:2.03px;}
#t9_11{left:87px;bottom:840px;letter-spacing:-0.15px;word-spacing:1.08px;}
#ta_11{left:87px;bottom:820px;letter-spacing:-0.13px;word-spacing:0.19px;}
#tb_11{left:87px;bottom:800px;letter-spacing:-0.15px;word-spacing:4.67px;}
#tc_11{left:87px;bottom:780px;letter-spacing:-0.11px;word-spacing:0.02px;}
#td_11{left:87px;bottom:746px;letter-spacing:-0.18px;word-spacing:4.29px;}
#te_11{left:284px;bottom:746px;}
#tf_11{left:308px;bottom:746px;letter-spacing:-0.12px;word-spacing:4.25px;}
#tg_11{left:87px;bottom:726px;letter-spacing:-0.17px;word-spacing:7.2px;}
#th_11{left:640px;bottom:726px;letter-spacing:-0.2px;}
#ti_11{left:87px;bottom:706px;letter-spacing:-0.16px;word-spacing:5.67px;}
#tj_11{left:87px;bottom:686px;letter-spacing:-0.13px;word-spacing:3px;}
#tk_11{left:87px;bottom:666px;letter-spacing:-0.13px;word-spacing:1.8px;}
#tl_11{left:87px;bottom:646px;letter-spacing:-0.14px;word-spacing:2.31px;}
#tm_11{left:87px;bottom:625px;letter-spacing:-0.16px;word-spacing:5.45px;}
#tn_11{left:87px;bottom:605px;letter-spacing:-0.15px;word-spacing:0.06px;}
#to_11{left:87px;bottom:572px;letter-spacing:-0.14px;word-spacing:-0.32px;}
#tp_11{left:579px;bottom:572px;letter-spacing:-0.14px;word-spacing:-0.29px;}
#tq_11{left:87px;bottom:552px;letter-spacing:-0.1px;}
#tr_11{left:170px;bottom:552px;letter-spacing:-0.16px;}
#ts_11{left:267px;bottom:552px;letter-spacing:-0.09px;}
#tt_11{left:382px;bottom:552px;letter-spacing:-0.17px;}
#tu_11{left:511px;bottom:552px;letter-spacing:-0.1px;}
#tv_11{left:629px;bottom:552px;letter-spacing:-0.18px;}
#tw_11{left:87px;bottom:532px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_11{left:87px;bottom:499px;letter-spacing:-0.14px;word-spacing:6.2px;}
#ty_11{left:330px;bottom:499px;letter-spacing:-0.15px;word-spacing:6.23px;}
#tz_11{left:87px;bottom:478px;letter-spacing:-0.13px;word-spacing:-0.52px;}
#t10_11{left:87px;bottom:458px;letter-spacing:-0.18px;word-spacing:2.79px;}
#t11_11{left:603px;bottom:458px;letter-spacing:-0.09px;}
#t12_11{left:87px;bottom:438px;letter-spacing:-0.21px;}
#t13_11{left:87px;bottom:405px;letter-spacing:-0.14px;word-spacing:3.47px;}
#t14_11{left:232px;bottom:405px;letter-spacing:-0.14px;word-spacing:3.5px;}
#t15_11{left:87px;bottom:385px;letter-spacing:-0.13px;word-spacing:3.17px;}
#t16_11{left:87px;bottom:365px;letter-spacing:-0.18px;word-spacing:0.02px;}
#t17_11{left:87px;bottom:344px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t18_11{left:87px;bottom:311px;letter-spacing:-0.14px;word-spacing:5.44px;}
#t19_11{left:283px;bottom:311px;letter-spacing:-0.14px;word-spacing:5.48px;}
#t1a_11{left:87px;bottom:291px;letter-spacing:-0.21px;word-spacing:11.03px;}
#t1b_11{left:87px;bottom:271px;letter-spacing:-0.16px;word-spacing:11.9px;}
#t1c_11{left:87px;bottom:251px;letter-spacing:-0.16px;word-spacing:0.07px;}

.s0_11{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_11{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_11{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_11{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_11{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts11" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg11" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="11/11.svg"
          type="image/svg+xml"
          id="pdf11"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_11" class="t s1_11">
          11{" "}
        </span>
        <span id="t3_11" class="t s2_11">
          Хүүхдийн хөгжих эрх
        </span>
        <span id="t4_11" class="t s3_11">
          - Хүүхдийн бие махбод, оюун санаа, ой ухаан,{" "}
        </span>
        <span id="t5_11" class="t s3_11">
          сэтгэц, зан байдал, харилцаанд гарч байгаа ахиц өөрчлөлтийг хүүхдийн{" "}
        </span>
        <span id="t6_11" class="t s3_11">
          хөгжил гэж үзэх бөгөөд хүүхэд дараах эрх эдэлнэ: хүүхэд бага, суурь,
          бүрэн{" "}
        </span>
        <span id="t7_11" class="t s3_11">
          дунд боловсролыг үнэ төлбөргүй эзэмших; хүүхэд төрөлх хэл, бичиг үсэг,{" "}
        </span>
        <span id="t8_11" class="t s3_11">
          ёс заншил, түүх соёлын уламжлалыг өвлөн эзэмших; хүүхэд оюун ухаан,{" "}
        </span>
        <span id="t9_11" class="t s3_11">
          авьяас билэг, бие бялдар, ур чадвараа хөгжүүлэхэд төрөөс дэмжлэг авах;{" "}
        </span>
        <span id="ta_11" class="t s3_11">
          хүүхэд шашин шүтэх, эс шүтэх; хүүхэд чөлөөтэй бодож сэтгэх, өөрөө
          эсхүл{" "}
        </span>
        <span id="tb_11" class="t s3_11">
          бусдаар дамжуулан үзэл бодлоо чөлөөтэй илэрхийлэх, мэдээлэл хайх,{" "}
        </span>
        <span id="tc_11" class="t s3_11">
          хүлээн авах; хүүхэд гэр бүл, нийгмийн орчинд өөрийгөө хөгжүүлэх;{" "}
        </span>
        <span id="td_11" class="t s2_11">
          Хүүхдийн оролцох эрх
        </span>
        <span id="te_11" class="t s3_11">
          -{" "}
        </span>
        <span id="tf_11" class="t s3_11">
          хүүхэд нас, бие, сэтгэхүйн онцлогт тохирсон{" "}
        </span>
        <span id="tg_11" class="t s3_11">
          хуулиар хориглоогүй арга хэмжээнд оролцох, эвлэлдэн нэгдэх{" "}
        </span>
        <span id="th_11" class="t s3_11">
          өөрт{" "}
        </span>
        <span id="ti_11" class="t s3_11">
          хамаарах аливаа асуудлаар шийдвэр гаргах, үнэлэлт өгөхөд оролцох;{" "}
        </span>
        <span id="tj_11" class="t s3_11">
          хүүхэд өөрийн бүтээсэн зохиогчийн эрх, шинэ бүтээл, оновчтой саналыг{" "}
        </span>
        <span id="tk_11" class="t s3_11">
          хууль тогтоомжид заасны дагуу өмчлөх, түүний үр шимийг хүртэх; хүүхэд{" "}
        </span>
        <span id="tl_11" class="t s3_11">
          хуульд заасан үндэслэл журмын дагуу өөрт ногдох эд хөрөнгийг өмчлөх,{" "}
        </span>
        <span id="tm_11" class="t s3_11">
          өвлөх, ажил, үйлчилгээний хөлс, урамшуулал авах; хуулиар олгогдсон{" "}
        </span>
        <span id="tn_11" class="t s3_11">
          эрхээ эдлэх, шаардах;{" "}
        </span>
        <span id="to_11" class="t s2_11">
          Сургуульд суурилсан сургалтын хөтөлбөрийн хөгжүүлэлт{" "}
        </span>
        <span id="tp_11" class="t s3_11">
          - сургуулийн{" "}
        </span>
        <span id="tq_11" class="t s3_11">
          түвшинд{" "}
        </span>
        <span id="tr_11" class="t s3_11">
          сургалтын{" "}
        </span>
        <span id="ts_11" class="t s3_11">
          хөтөлбөрийг{" "}
        </span>
        <span id="tt_11" class="t s3_11">
          боловсруулах,{" "}
        </span>
        <span id="tu_11" class="t s3_11">
          хэрэгжүүлэх,{" "}
        </span>
        <span id="tv_11" class="t s3_11">
          үнэлж{" "}
        </span>
        <span id="tw_11" class="t s3_11">
          шинэчлэх үйл явц юм.{" "}
        </span>
        <span id="tx_11" class="t s2_11">
          Далд сургалтын хөтөлбөр{" "}
        </span>
        <span id="ty_11" class="t s3_11">
          - бичигдсэн болон төлөвлөсөн сургалтын{" "}
        </span>
        <span id="tz_11" class="t s3_11">
          хөтөлбөрт тусгагдаагүй сургуулийн орчин, соёлын нөлөөгөөр суралцагчдын{" "}
        </span>
        <span id="t10_11" class="t s3_11">
          эзэмших мэдлэг, чадвар, үнэт зүйлсийг илэрхийлэх сургалтын{" "}
        </span>
        <span id="t11_11" class="t s3_11">
          хөтөлбөр{" "}
        </span>
        <span id="t12_11" class="t s3_11">
          юм.{" "}
        </span>
        <span id="t13_11" class="t s2_11">
          Өөрийн үнэлгээ{" "}
        </span>
        <span id="t14_11" class="t s3_11">
          нь сургууль тухайн жилийн зорилго, зорилтдоо хүрсэн{" "}
        </span>
        <span id="t15_11" class="t s3_11">
          эсэх, хэрэгжүүлсэн үйл ажиллагааны чанар, нийцийг баримт нотолгоонд{" "}
        </span>
        <span id="t16_11" class="t s3_11">
          тулгуурлан хамтын оролцоотой үнэлж, сайжруулах шаардлагатай асуудлыг{" "}
        </span>
        <span id="t17_11" class="t s3_11">
          илрүүлэх оношлох үнэлгээ юм.{" "}
        </span>
        <span id="t18_11" class="t s2_11">
          Хөндлөнгийн үнэлгээ{" "}
        </span>
        <span id="t19_11" class="t s3_11">
          нь сургуулийн өөрийн үнэлгээ бодитой эсэхийг{" "}
        </span>
        <span id="t1a_11" class="t s3_11">
          магадлан баталгаажуулах, сургалт, үйл ажиллагаагаа сайжруулахад{" "}
        </span>
        <span id="t1b_11" class="t s3_11">
          шаардлагатай дэмжлэгийг тодорхойлох зорилго бүхий хөндлөнгийн{" "}
        </span>
        <span id="t1c_11" class="t s3_11">
          байгууллагаас хийж буй үнэлгээ юм.{" "}
        </span>
      </div>
    </div>
  );
}
