import React from "react";
export default function HundredThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_103{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_103{left:156px;bottom:936px;word-spacing:0.22px;}
#t3_103{left:295px;bottom:910px;letter-spacing:0.21px;word-spacing:0.05px;}
#t4_103{left:127px;bottom:865px;letter-spacing:-0.03px;}
#t5_103{left:139px;bottom:818px;letter-spacing:-0.17px;word-spacing:0.06px;}
#t6_103{left:139px;bottom:798px;letter-spacing:-0.16px;word-spacing:0.05px;}
#t7_103{left:142px;bottom:769px;letter-spacing:-0.15px;word-spacing:-0.59px;}
#t8_103{left:142px;bottom:749px;letter-spacing:-0.19px;word-spacing:11.1px;}
#t9_103{left:142px;bottom:729px;letter-spacing:-0.18px;word-spacing:0.09px;}
#ta_103{left:142px;bottom:687px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_103{left:153px;bottom:662px;}
#tc_103{left:180px;bottom:662px;letter-spacing:-0.16px;word-spacing:5.26px;}
#td_103{left:180px;bottom:642px;letter-spacing:-0.18px;word-spacing:0.09px;}
#te_103{left:153px;bottom:618px;}
#tf_103{left:180px;bottom:618px;letter-spacing:-0.16px;word-spacing:5.85px;}
#tg_103{left:180px;bottom:598px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_103{left:153px;bottom:573px;}
#ti_103{left:180px;bottom:573px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tj_103{left:142px;bottom:540px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tk_103{left:153px;bottom:515px;}
#tl_103{left:180px;bottom:515px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tm_103{left:153px;bottom:491px;}
#tn_103{left:180px;bottom:491px;letter-spacing:-0.13px;word-spacing:4.82px;}
#to_103{left:180px;bottom:471px;letter-spacing:-0.17px;word-spacing:2.13px;}
#tp_103{left:180px;bottom:451px;letter-spacing:-0.18px;word-spacing:4.92px;}
#tq_103{left:180px;bottom:430px;letter-spacing:-0.21px;word-spacing:0.12px;}
#tr_103{left:142px;bottom:397px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ts_103{left:153px;bottom:373px;}
#tt_103{left:180px;bottom:373px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tu_103{left:153px;bottom:348px;}
#tv_103{left:180px;bottom:348px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tw_103{left:153px;bottom:324px;}
#tx_103{left:180px;bottom:324px;letter-spacing:-0.14px;word-spacing:0.68px;}
#ty_103{left:180px;bottom:304px;letter-spacing:-0.2px;}

.s0_103{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_103{font-size:21px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_103{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_103{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_103{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_103{font-size:17px;font-family:MogulArial_16s;color:#F7941E;}
.s6_103{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s7_103{font-size:17px;font-family:MogulArial_16s;color:#F90;}`}
      </style>

      <style id="fonts103" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg103" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="103/103.svg"
          type="image/svg+xml"
          id="pdf103"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_103" class="t s0_103">
          103{" "}
        </span>
        <span id="t2_103" class="t s1_103">
          3.2.2 СУРГАЛТ, ҮЙЛ АЖИЛЛАГААГ ХЭРХЭН{" "}
        </span>
        <span id="t3_103" class="t s1_103">
          ТӨЛӨВЛӨХ ВЭ?{" "}
        </span>
        <span id="t4_103" class="t s2_103">
          АСУУДАЛ{" "}
        </span>
        <span id="t5_103" class="t s3_103">
          Сургуулийн нэгдсэн төлөвлөгөө боловсруулахад тулгамдаж{" "}
        </span>
        <span id="t6_103" class="t s3_103">
          буй нийтлэг асуудал{" "}
        </span>
        <span id="t7_103" class="t s4_103">
          Сургалтын жилийн төлөвлөгөөг боловсруулахдаа ихэвчлэн агуулгын{" "}
        </span>
        <span id="t8_103" class="t s4_103">
          хуваарилалтыг хийж байна. Тодруулбал, ямар агуулга, нэгж{" "}
        </span>
        <span id="t9_103" class="t s4_103">
          хичээлийг хэзээ, хэдэн цагаар орохыг л төлөвлөдөг.{" "}
        </span>
        <span id="ta_103" class="t s5_103">
          Төлөвлөхийн өмнөх нийтлэг асуудал:{" "}
        </span>
        <span id="tb_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tc_103" class="t s4_103">
          Сургалтын хөтөлбөр уншсан боловч бүрэн судлаагүй, багш{" "}
        </span>
        <span id="td_103" class="t s4_103">
          нарын ойлголт ялгаатай{" "}
        </span>
        <span id="te_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tf_103" class="t s4_103">
          Хүүхдэд тулгамдаж буй асуудлыг нэгтгэх (судлагдахуунаар,{" "}
        </span>
        <span id="tg_103" class="t s4_103">
          ангиар, боловсролын түвшингээр) байдал сул{" "}
        </span>
        <span id="th_103" class="t s6_103">
          •{" "}
        </span>
        <span id="ti_103" class="t s4_103">
          СҮДШ-ын талаарх ойлголт сул (үнэлгээ){" "}
        </span>
        <span id="tj_103" class="t s7_103">
          Төлөвлөхөд гарч буй нийтлэг асуудал:{" "}
        </span>
        <span id="tk_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tl_103" class="t s4_103">
          Багш, ажилтан, удирдлагын хамтын ажиллагаа сул{" "}
        </span>
        <span id="tm_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tn_103" class="t s4_103">
          Сургалтын хөтөлбөрт суурилсан хүүхдийн хөгжлийг цогцоор{" "}
        </span>
        <span id="to_103" class="t s4_103">
          нь дэмжсэн сургуулийн зорилготой уялдаа бүхий сургалт, үйл{" "}
        </span>
        <span id="tp_103" class="t s4_103">
          ажиллагааны төлөвлөлт ховор (Сургуулийн зорилго, зорилт,{" "}
        </span>
        <span id="tq_103" class="t s4_103">
          төлөвлөлт, хэрэгжилт, үнэлгээний уялдаа алдагдсан){" "}
        </span>
        <span id="tr_103" class="t s7_103">
          Хичээлээс гадуурх үйл ажиллагааны төлөвлөгөө{" "}
        </span>
        <span id="ts_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tt_103" class="t s4_103">
          Үйл ажиллагаа бүр өөр өөр зорилготой, салангид{" "}
        </span>
        <span id="tu_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tv_103" class="t s4_103">
          Сургуулийн зорилготой уялдсан эсэх нь бүрхэг{" "}
        </span>
        <span id="tw_103" class="t s6_103">
          •{" "}
        </span>
        <span id="tx_103" class="t s4_103">
          ХГҮА болон СҮА-ны уялдаа сул, хамтарч гаргаагүй, тус тусдаа{" "}
        </span>
        <span id="ty_103" class="t s4_103">
          төлөвлөгдсөн{" "}
        </span>
      </div>
    </div>
  );
}
