import React from "react";
export default function FourtyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_45{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_45{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_45{left:87px;bottom:941px;letter-spacing:-0.18px;word-spacing:6.15px;}
#t4_45{left:87px;bottom:921px;letter-spacing:-0.23px;word-spacing:2.13px;}
#t5_45{left:310px;bottom:921px;letter-spacing:-0.19px;word-spacing:2.07px;}
#t6_45{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:-1.41px;}
#t7_45{left:585px;bottom:901px;letter-spacing:-0.16px;}
#t8_45{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:3.4px;}
#t9_45{left:87px;bottom:860px;letter-spacing:-0.17px;word-spacing:3.65px;}
#ta_45{left:87px;bottom:840px;letter-spacing:-0.18px;word-spacing:3.78px;}
#tb_45{left:87px;bottom:820px;letter-spacing:-0.16px;word-spacing:3.46px;}
#tc_45{left:87px;bottom:800px;letter-spacing:-0.15px;word-spacing:2.53px;}
#td_45{left:87px;bottom:780px;letter-spacing:-0.14px;word-spacing:0.81px;}
#te_45{left:87px;bottom:759px;letter-spacing:-0.12px;word-spacing:2.26px;}
#tf_45{left:87px;bottom:739px;letter-spacing:-0.12px;word-spacing:5.77px;}
#tg_45{left:87px;bottom:719px;letter-spacing:-0.12px;word-spacing:0.03px;}
#th_45{left:100px;bottom:674px;letter-spacing:0.1px;word-spacing:0.04px;}
#ti_45{left:100px;bottom:642px;letter-spacing:0.1px;word-spacing:7.84px;}
#tj_45{left:100px;bottom:624px;letter-spacing:0.12px;word-spacing:0.02px;}
#tk_45{left:100px;bottom:606px;letter-spacing:0.09px;word-spacing:0.56px;}
#tl_45{left:100px;bottom:587px;letter-spacing:0.13px;word-spacing:0.01px;}
#tm_45{left:87px;bottom:525px;letter-spacing:-0.14px;word-spacing:10.58px;}
#tn_45{left:87px;bottom:505px;letter-spacing:-0.15px;word-spacing:4.1px;}
#to_45{left:87px;bottom:485px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tp_45{left:87px;bottom:452px;letter-spacing:-0.15px;word-spacing:1.13px;}
#tq_45{left:87px;bottom:432px;letter-spacing:-0.14px;word-spacing:2.33px;}
#tr_45{left:87px;bottom:411px;letter-spacing:-0.15px;word-spacing:1.37px;}
#ts_45{left:87px;bottom:391px;letter-spacing:-0.17px;word-spacing:2.02px;}
#tt_45{left:87px;bottom:371px;letter-spacing:-0.16px;word-spacing:1.25px;}
#tu_45{left:87px;bottom:351px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tv_45{left:100px;bottom:299px;letter-spacing:0.1px;word-spacing:0.04px;}
#tw_45{left:100px;bottom:268px;letter-spacing:0.07px;word-spacing:-0.14px;}
#tx_45{left:100px;bottom:249px;letter-spacing:0.13px;}
#ty_45{left:100px;bottom:231px;letter-spacing:0.1px;word-spacing:1.15px;}
#tz_45{left:100px;bottom:213px;letter-spacing:0.12px;word-spacing:3.54px;}
#t10_45{left:100px;bottom:194px;letter-spacing:0.08px;word-spacing:0.05px;}
#t11_45{left:100px;bottom:176px;letter-spacing:0.12px;word-spacing:7.19px;}
#t12_45{left:100px;bottom:158px;letter-spacing:0.08px;word-spacing:1.76px;}
#t13_45{left:100px;bottom:139px;letter-spacing:0.13px;word-spacing:-1.06px;}

.s0_45{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_45{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_45{font-size:17px;font-family:MogulArial_16s;color:#B812BD;}
.s3_45{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_45{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s5_45{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.t.m0_45{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts45" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg45" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="45/45.svg"
          type="image/svg+xml"
          id="pdf45"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_45" class="t s1_45">
          45{" "}
        </span>
        <span id="t3_45" class="t s2_45">
          Үнэлгээг эрх бүхий байгууллагаас батлан гаргасан хууль тогтоомжийн{" "}
        </span>
        <span id="t4_45" class="t s2_45">
          хүрээнд зохион байгуулдаг.{" "}
        </span>
        <span id="t5_45" class="t s3_45">
          Монгол Улсын Их хурлаас 2023 оны 7 дугаар{" "}
        </span>
        <span id="t6_45" class="t s3_45">
          сарын 7-нд баталсан Боловсролын ерөнхий хууль (БЕХ)-ийн 35,{" "}
        </span>
        <span id="t7_45" class="t s3_45">
          Сургуулийн{" "}
        </span>
        <span id="t8_45" class="t s3_45">
          өмнөх болон ерөнхий боловсролын тухай хууль (СӨЕБХ)-ийн 26 дугаар{" "}
        </span>
        <span id="t9_45" class="t s3_45">
          зүйлээр “Боловсролын хяналт шалгалт, чанарын үнэлгээ”-тэй холбоотой{" "}
        </span>
        <span id="ta_45" class="t s3_45">
          зохицуулалтыг хийсэн байна. Тодруулбал, БЕХ-д Боловсролын чанарын{" "}
        </span>
        <span id="tb_45" class="t s3_45">
          болон гүйцэтгэлийн үнэлгээг холбогдох журмын дагуу зохион байгуулах,{" "}
        </span>
        <span id="tc_45" class="t s3_45">
          Боловсролын чанарыг 3 жил тутам олон улсын эрх бүхий байгууллагаар{" "}
        </span>
        <span id="td_45" class="t s3_45">
          үнэлүүлэх зэргийг заасан бол СӨЕБХ-д ерөнхий боловсролын сургууль нь{" "}
        </span>
        <span id="te_45" class="t s3_45">
          үйл ажиллагаандаа өөрийн болон хөндлөнгийн үнэлгээ хийж, үнэлгээний{" "}
        </span>
        <span id="tf_45" class="t s3_45">
          үр дүнд үндэслэн байгууллагын үйл ажиллагаа, суралцагчийн хөгжил,{" "}
        </span>
        <span id="tg_45" class="t s3_45">
          сурлагын амжилтын чанар, үр дүнг тасралтгүй сайжруулна гэж заажээ.{" "}
        </span>
        <span id="th_45" class="t s4_45">
          Боловсролын ерөнхий хууль (2023){" "}
        </span>
        <span id="ti_45" class="t s5_45">
          35.4. Боловсролын сургалтын байгууллага, багшийн ажлын гүйцэтгэлийг{" "}
        </span>
        <span id="tj_45" class="t s5_45">
          хөндлөнгөөс үнэлнэ.{" "}
        </span>
        <span id="tk_45" class="t s5_45">
          35.5 Боловсролын үнэлгээг цахимаар зохион байгуулж болох ба
          суралцагчийн{" "}
        </span>
        <span id="tl_45" class="t s5_45">
          ахиц дэвшилд дүн шинжилгээ хийх, үр дүнг тайлагнах цахим системтэй
          байна.{" "}
        </span>
        <span id="tm_45" class="t s3_45">
          Үнэлгээг ерөнхий утгаар нь тодорхой хугацаанд хэрэгжүүлсэн үйл{" "}
        </span>
        <span id="tn_45" class="t s3_45">
          ажиллагааны хүрсэн үр дүнг үнэлэх, хэмжихийн тулд мэдээлэл, нотлох{" "}
        </span>
        <span id="to_45" class="t s3_45">
          баримт цуглуулах системтэй үйл явц гэж тодорхойлж болно.{" "}
        </span>
        <span id="tp_45" class="t s3_45">
          Сургалтын байгууллагын үнэлгээ нь боловсролын байгууллагын үр нөлөө,{" "}
        </span>
        <span id="tq_45" class="t s3_45">
          чанар, үр ашгийг үнэлэх системтэй үйл явц юм. Үнэлгээний мэдээлэл нь{" "}
        </span>
        <span id="tr_45" class="t s3_45">
          сургалтын хөтөлбөрийн хэрэгжилт, сургалтын чанар, багшийн мэргэжлийн{" "}
        </span>
        <span id="ts_45" class="t s3_45">
          хөгжилд чухал шийдвэрлэх үүргийг гүйцэтгэдэг. Энэхүү шийдвэр гаргахад{" "}
        </span>
        <span id="tt_45" class="t s3_45">
          шаардлагатай мэдээллийг системтэй цуглуулахад үнэлгээний олон төрөл,{" "}
        </span>
        <span id="tu_45" class="t s3_45">
          хэлбэр, арга хэрэгслийг ашиглаж болно.{" "}
        </span>
        <span id="tv_45" class="t s4_45">
          Боловсролын ерөнхий хууль (2023){" "}
        </span>
        <span id="tw_45" class="t s5_45">
          35.7. Боловсролын сургалтын байгууллага дотоод чанарын
          баталгаажуулалтыг{" "}
        </span>
        <span id="tx_45" class="t s5_45">
          хэрэгжүүлнэ.{" "}
        </span>
        <span id="ty_45" class="t s5_45">
          35.8 Боловсролын сургалтын байгууллага энэ хуулийн 35.7-д заасан
          чанарын{" "}
        </span>
        <span id="tz_45" class="t s5_45">
          баталгаажуулалтын үр дүнд үндэслэж, чанарын сайжруулалтын төлөвлөгөө{" "}
        </span>
        <span id="t10_45" class="t s5_45">
          баталж, хэрэгжүүлнэ.{" "}
        </span>
        <span id="t11_45" class="t s5_45">
          35.9 Энэ хуулийн 35.8-д заасан төлөвлөгөө нь боловсролын сургалтын{" "}
        </span>
        <span id="t12_45" class="t s5_45">
          байгууллагын гүйцэтгэх удирдлагатай байгуулах гэрээний хэсэг болох
          бөгөөд{" "}
        </span>
        <span id="t13_45" class="t s5_45">
          түүний ажлын үр дүнг хэмжих шалгуур үзүүлэлт нь чанарын ахиц дэвшил
          байна.{" "}
        </span>
      </div>
    </div>
  );
}
