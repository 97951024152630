import React from "react";
export default function FiftyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_54{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_54{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_54{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:0.31px;}
#t4_54{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:1.79px;}
#t5_54{left:87px;bottom:901px;letter-spacing:-0.12px;word-spacing:-0.63px;}
#t6_54{left:87px;bottom:880px;letter-spacing:-0.17px;word-spacing:3.27px;}
#t7_54{left:87px;bottom:860px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t8_54{left:87px;bottom:827px;letter-spacing:-0.15px;word-spacing:8.78px;}
#t9_54{left:87px;bottom:807px;letter-spacing:-0.14px;word-spacing:-0.35px;}
#ta_54{left:87px;bottom:787px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tb_54{left:103px;bottom:745px;letter-spacing:0.12px;word-spacing:4.5px;}
#tc_54{left:102px;bottom:727px;letter-spacing:0.11px;word-spacing:4.87px;}
#td_54{left:102px;bottom:708px;letter-spacing:0.07px;}
#te_54{left:212px;bottom:708px;letter-spacing:0.09px;}
#tf_54{left:301px;bottom:708px;letter-spacing:0.03px;}
#tg_54{left:396px;bottom:708px;letter-spacing:0.14px;}
#th_54{left:477px;bottom:708px;}
#ti_54{left:556px;bottom:708px;letter-spacing:0.04px;}
#tj_54{left:102px;bottom:690px;letter-spacing:0.08px;word-spacing:0.06px;}
#tk_54{left:87px;bottom:629px;letter-spacing:-0.11px;word-spacing:-1.87px;}
#tl_54{left:87px;bottom:609px;letter-spacing:-0.15px;word-spacing:-0.45px;}
#tm_54{left:87px;bottom:589px;letter-spacing:-0.12px;word-spacing:0.38px;}
#tn_54{left:87px;bottom:569px;letter-spacing:-0.15px;word-spacing:0.78px;}
#to_54{left:87px;bottom:548px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tp_54{left:102px;bottom:503px;letter-spacing:0.11px;}
#tq_54{left:102px;bottom:484px;letter-spacing:0.09px;word-spacing:7.03px;}
#tr_54{left:102px;bottom:466px;letter-spacing:0.1px;word-spacing:1.79px;}
#ts_54{left:102px;bottom:448px;letter-spacing:0.1px;word-spacing:0.04px;}
#tt_54{left:102px;bottom:429px;letter-spacing:0.11px;word-spacing:3.42px;}
#tu_54{left:102px;bottom:411px;letter-spacing:0.1px;word-spacing:0.04px;}
#tv_54{left:87px;bottom:356px;letter-spacing:-0.13px;word-spacing:5.6px;}
#tw_54{left:87px;bottom:336px;letter-spacing:-0.14px;word-spacing:4.01px;}
#tx_54{left:87px;bottom:315px;letter-spacing:-0.15px;word-spacing:1.29px;}
#ty_54{left:87px;bottom:295px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tz_54{left:196px;bottom:262px;letter-spacing:-0.14px;word-spacing:0.02px;}

.s0_54{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_54{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_54{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_54{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s4_54{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s5_54{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_54{font-size:14px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts54" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg54" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="54/54.svg"
          type="image/svg+xml"
          id="pdf54"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_54" class="t s1_54">
          54{" "}
        </span>
        <span id="t3_54" class="t s2_54">
          байгуулалттайгаар хадгалагддаг мэдээллүүдэд автоматаар дүн шинжилгээ{" "}
        </span>
        <span id="t4_54" class="t s2_54">
          хийхэд тусалдаг аналитикийн хэрэгсэл юм. Өөрөөр хэлбэл, олон төрлийн{" "}
        </span>
        <span id="t5_54" class="t s2_54">
          өгөгдлийг задлан шинжилж, үр дүнг энгийн, ойлгомжтой дүрслэл графикаар{" "}
        </span>
        <span id="t6_54" class="t s2_54">
          хэрэглэгчид харуулах, байгууллага дотор мэдээлэл солилцох зэрэг олон{" "}
        </span>
        <span id="t7_54" class="t s2_54">
          ажлыг богино хугацаанд харуулах чадвартай систем юм.{" "}
        </span>
        <span id="t8_54" class="t s2_54">
          Энэ системийн зорилго нь сургалтын байгууллагын үйл ажиллагааг{" "}
        </span>
        <span id="t9_54" class="t s2_54">
          тасралтгүй сайжруулах, бусдаас суралцах замаар үр нөлөөг нэмэгдүүлэхэд{" "}
        </span>
        <span id="ta_54" class="t s2_54">
          чиглэсэн шийдвэр гаргахад чухал нөлөө үзүүлдэг.{" "}
        </span>
        <span id="tb_54" class="t s3_54">
          БҮТ-ийн цахим тоон тайлангийн систем нь технологид суурилсан бөгөөд{" "}
        </span>
        <span id="tc_54" class="t s3_54">
          сургуулийн захирал, сургалтын менежерүүд болон бусад албан хаагчдад{" "}
        </span>
        <span id="td_54" class="t s3_54">
          гүйцэтгэлийн{" "}
        </span>
        <span id="te_54" class="t s3_54">
          үнэлгээнд{" "}
        </span>
        <span id="tf_54" class="t s3_54">
          тулгуурлан{" "}
        </span>
        <span id="tg_54" class="t s3_54">
          шийдвэр{" "}
        </span>
        <span id="th_54" class="t s3_54">
          гаргахад{" "}
        </span>
        <span id="ti_54" class="t s3_54">
          шаардлагатай{" "}
        </span>
        <span id="tj_54" class="t s3_54">
          мэдээллээр хангана.{" "}
        </span>
        <span id="tk_54" class="t s2_54">
          Цахим тоон тайлангийн системд сургууль бүр өөрийн “нэвтрэх нэр”, “нууц
          үг”-{" "}
        </span>
        <span id="tl_54" class="t s2_54">
          ээр нэвтрэх бөгөөд үүнийг өөрийн харьяа дүүрэг, аймгийн БШУГ-ын
          үнэлгээ{" "}
        </span>
        <span id="tm_54" class="t s2_54">
          хариуцсан мэргэжилтнээс авсан байна. “Нэвтрэх нэр”, “нууц үг”-ээ
          авснаар{" "}
        </span>
        <span id="tn_54" class="t s2_54">
          тухайн сургууль болон багш гүйцэтгэлийн үнэлгээний үр дүнтэй холбоотой{" "}
        </span>
        <span id="to_54" class="t s2_54">
          тоон мэдээллийг харж, үр дүндээ шинжилгээ хийх боломж бүрдэнэ.{" "}
        </span>
        <span id="tp_54" class="t s4_54">
          Анхааруулга:{" "}
        </span>
        <span id="tq_54" class="t s5_54">
          Та системд нэвтрэх бүрдээ “НЭВТРЭХ НЭР”, “НУУЦ ҮГ”-ийг ашиглана.{" "}
        </span>
        <span id="tr_54" class="t s5_54">
          Мэдээллийн нууцлалаа хадгалахын тулд ЗӨВХӨН та л мэдэж байхаар нууц{" "}
        </span>
        <span id="ts_54" class="t s5_54">
          үгээ сольж болно.{" "}
        </span>
        <span id="tt_54" class="t s5_54">
          Хамгийн гол нь нууц үгээ хэзээ ч мартаж болохгүй. Иймд нууц үг авмагц/{" "}
        </span>
        <span id="tu_54" class="t s5_54">
          сольмогц мартахгүй байх арга хэмжээг ЗААВАЛ аваарай.{" "}
        </span>
        <span id="tv_54" class="t s2_54">
          Цахим тоон тайлангийн системээс ЕБС-ийн багшийн болон сургалтын{" "}
        </span>
        <span id="tw_54" class="t s2_54">
          байгууллагын гүйцэтгэлийн үнэлгээний үр дүнг хоёр оноор харьцуулсан{" "}
        </span>
        <span id="tx_54" class="t s2_54">
          мэдээллийг авах болно. “ҮНЭЛГЭЭНИЙ ҮР ДҮН” цонх руу нэвтэрч өөрийн{" "}
        </span>
        <span id="ty_54" class="t s2_54">
          сургуулийн гүйцэтгэлийг дараах алхмуудаар харна (Зураг 2.2).{" "}
        </span>
        <span id="tz_54" class="t s6_54">
          Зураг 2.2. Цахим тоон тайлангийн системд нэвтрэх алхам{" "}
        </span>
      </div>
    </div>
  );
}
