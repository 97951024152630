import React from "react";
export default function EightyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_83{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_83{left:87px;bottom:941px;}
#t3_83{left:114px;bottom:941px;letter-spacing:-0.12px;word-spacing:1.37px;}
#t4_83{left:114px;bottom:921px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t5_83{left:87px;bottom:896px;}
#t6_83{left:114px;bottom:896px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t7_83{left:87px;bottom:872px;}
#t8_83{left:114px;bottom:872px;letter-spacing:-0.13px;word-spacing:8.72px;}
#t9_83{left:114px;bottom:852px;letter-spacing:-0.15px;word-spacing:8.41px;}
#ta_83{left:114px;bottom:831px;letter-spacing:-0.14px;word-spacing:2.62px;}
#tb_83{left:114px;bottom:811px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tc_83{left:87px;bottom:787px;}
#td_83{left:114px;bottom:787px;letter-spacing:-0.12px;word-spacing:2.5px;}
#te_83{left:114px;bottom:767px;letter-spacing:-0.15px;word-spacing:7.01px;}
#tf_83{left:114px;bottom:746px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_83{left:595px;bottom:755px;}
#th_83{left:600px;bottom:746px;}
#ti_83{left:87px;bottom:722px;}
#tj_83{left:114px;bottom:722px;letter-spacing:-0.17px;word-spacing:6.18px;}
#tk_83{left:114px;bottom:702px;letter-spacing:-0.28px;}
#tl_83{left:87px;bottom:677px;}
#tm_83{left:114px;bottom:677px;letter-spacing:-0.15px;word-spacing:9.85px;}
#tn_83{left:114px;bottom:657px;letter-spacing:-0.12px;word-spacing:2.15px;}
#to_83{left:114px;bottom:637px;letter-spacing:-0.09px;word-spacing:-0.02px;}
#tp_83{left:137px;bottom:567px;}
#tq_83{left:139px;bottom:534px;letter-spacing:-0.15px;word-spacing:-0.53px;}
#tr_83{left:139px;bottom:514px;letter-spacing:-0.13px;word-spacing:0.02px;}
#ts_83{left:139px;bottom:485px;}
#tt_83{left:165px;bottom:485px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tu_83{left:139px;bottom:461px;}
#tv_83{left:165px;bottom:461px;letter-spacing:-0.15px;word-spacing:6.8px;}
#tw_83{left:165px;bottom:441px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tx_83{left:139px;bottom:416px;}
#ty_83{left:165px;bottom:416px;letter-spacing:-0.15px;word-spacing:6.43px;}
#tz_83{left:165px;bottom:396px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t10_83{left:139px;bottom:372px;}
#t11_83{left:165px;bottom:372px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t12_83{left:139px;bottom:347px;}
#t13_83{left:165px;bottom:347px;letter-spacing:-0.16px;word-spacing:-0.96px;}
#t14_83{left:139px;bottom:323px;}
#t15_83{left:165px;bottom:323px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t16_83{left:137px;bottom:294px;letter-spacing:-0.14px;word-spacing:0.35px;}
#t17_83{left:137px;bottom:274px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t18_83{left:87px;bottom:144px;}
#t19_83{left:108px;bottom:144px;letter-spacing:0.1px;word-spacing:3.81px;}
#t1a_83{left:108px;bottom:130px;letter-spacing:0.09px;word-spacing:1.67px;}
#t1b_83{left:108px;bottom:115px;letter-spacing:0.05px;word-spacing:0.07px;}

.s0_83{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_83{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_83{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_83{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s4_83{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_83{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s6_83{font-size:12px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts83" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg83" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="83/83.svg"
          type="image/svg+xml"
          id="pdf83"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_83" class="t s0_83">
          83{" "}
        </span>
        <span id="t2_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t3_83" class="t s2_83">
          Урьдчилан сэргийлэх, үе тэнгийнхний зөв харилцаа хандлагын талаар{" "}
        </span>
        <span id="t4_83" class="t s2_83">
          уулзалт ярилцлага хийж хэвших{" "}
        </span>
        <span id="t5_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t6_83" class="t s2_83">
          Хол гэртэй суралцагчдад сургуулийн автобусны үйлчилгээг нэвтрүүлэх{" "}
        </span>
        <span id="t7_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t8_83" class="t s2_83">
          Эцэг эх, асран хамгаалагчдад хүүхэдтэйгээ харилцах, эрхийг нь{" "}
        </span>
        <span id="t9_83" class="t s2_83">
          зөрчихгүй байх, шийтгэлийн алив хэлбэрээс зайлсхийх, асуудлыг{" "}
        </span>
        <span id="ta_83" class="t s2_83">
          үр дүнтэй шийдвэрлэх чадвар дадал олгох олон талт нөлөөллин үйл{" "}
        </span>
        <span id="tb_83" class="t s2_83">
          ажиллагааг зохион байгуулдаг байх{" "}
        </span>
        <span id="tc_83" class="t s1_83">
          •{" "}
        </span>
        <span id="td_83" class="t s2_83">
          Багш нар өөрийн давуу талаа хөгжүүлэн секц, дугуйлан хичээллүүлж{" "}
        </span>
        <span id="te_83" class="t s2_83">
          хүүхэд бүрийг тэгш хамруулахад чиглэсэн үйл ажиллагааг зохион{" "}
        </span>
        <span id="tf_83" class="t s2_83">
          байгуулах нь хүүхдийн хөгжлийг дэмжихэд үр дүнтэй арга юм{" "}
        </span>
        <span id="tg_83" class="t s3_83">
          9{" "}
        </span>
        <span id="th_83" class="t s2_83">
          .{" "}
        </span>
        <span id="ti_83" class="t s1_83">
          •{" "}
        </span>
        <span id="tj_83" class="t s2_83">
          Төлөвшүүлэхэд чиглэсэн сургалт, зөвлөгөөг өгч ажиллахыг хэвшил{" "}
        </span>
        <span id="tk_83" class="t s2_83">
          болгох{" "}
        </span>
        <span id="tl_83" class="t s1_83">
          •{" "}
        </span>
        <span id="tm_83" class="t s2_83">
          Суралцагчийн оролцоонд тулгуурласан үйл ажиллагааг төлөвлөн{" "}
        </span>
        <span id="tn_83" class="t s2_83">
          хэрэгжүүлэх. Ялангуяа, суралцагч бие даан шийдвэр гаргах чадварыг{" "}
        </span>
        <span id="to_83" class="t s2_83">
          дэмжин ажиллах{" "}
        </span>
        <span id="tp_83" class="t s4_83">
          АСУУДАЛ{" "}
        </span>
        <span id="tq_83" class="t s5_83">
          Стандартын бус анги танхим, зориулалтын бус гал тогоо, цайны{" "}
        </span>
        <span id="tr_83" class="t s5_83">
          газар нь сурагчийн эрүүл мэнд, аюулгүй байдалд эрсдэлтэй{" "}
        </span>
        <span id="ts_83" class="t s1_83">
          •{" "}
        </span>
        <span id="tt_83" class="t s2_83">
          Дотуур байрны хүртээмж дутагдалтай{" "}
        </span>
        <span id="tu_83" class="t s1_83">
          •{" "}
        </span>
        <span id="tv_83" class="t s2_83">
          Сурагчид үл хайхрах, бие махбод, бэлгийн, сэтгэл санааны{" "}
        </span>
        <span id="tw_83" class="t s2_83">
          хүчирхийлэлд өртөх эрсдэлтэй{" "}
        </span>
        <span id="tx_83" class="t s1_83">
          •{" "}
        </span>
        <span id="ty_83" class="t s2_83">
          Үдийн хоол, дотуур байрын хүүхдийн хоол хүнсний аюулгүй{" "}
        </span>
        <span id="tz_83" class="t s2_83">
          байдал хангалтгүй{" "}
        </span>
        <span id="t10_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t11_83" class="t s2_83">
          Сургалтын орчин нөхцөл тааруухан{" "}
        </span>
        <span id="t12_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t13_83" class="t s2_83">
          00 хүрэлцээгүй, орчин муугаас халдварт өвчин гарах магадлалтай{" "}
        </span>
        <span id="t14_83" class="t s1_83">
          •{" "}
        </span>
        <span id="t15_83" class="t s2_83">
          Хүүхдийн эрүүл эсэн мэнд амьдрах эрх зөрчигдөх{" "}
        </span>
        <span id="t16_83" class="t s2_83">
          Эдгээр асуудал нь сурах идэвх сонирхол, эцэг эх, асран хамгаалагч,{" "}
        </span>
        <span id="t17_83" class="t s2_83">
          харгалзан дэмжигчийн сэтгэл ханамжид сөргөөр нөлөөлөх{" "}
        </span>
        <span id="t18_83" class="t s6_83">
          9{" "}
        </span>
        <span id="t19_83" class="t s6_83">
          Ерөнхий боловсролын сургуульд суралцагчдын авьяас чадварыг нээн
          хөгжүүлэх буюу хөгжих{" "}
        </span>
        <span id="t1a_83" class="t s6_83">
          эрхийг дэмжих хүрээнд сургуульд секц, дугуйлан зохион байгуулах,
          санхүүжүүлэх аргачлал 2019{" "}
        </span>
        <span id="t1b_83" class="t s6_83">
          онд батлагдан хэрэгжиж байна.{" "}
        </span>
      </div>
    </div>
  );
}
