import React from "react";
export default function F131() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_131{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_131{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:5.37px;}
#t3_131{left:316px;bottom:941px;letter-spacing:-0.15px;word-spacing:5.37px;}
#t4_131{left:87px;bottom:923px;letter-spacing:-0.1px;}
#t5_131{left:256px;bottom:907px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t6_131{left:87px;bottom:726px;letter-spacing:-0.05px;}
#t7_131{left:114px;bottom:726px;letter-spacing:-0.16px;word-spacing:1.5px;}
#t8_131{left:344px;bottom:726px;letter-spacing:-0.12px;word-spacing:1.46px;}
#t9_131{left:114px;bottom:706px;letter-spacing:-0.11px;word-spacing:0.02px;}
#ta_131{left:113px;bottom:682px;}
#tb_131{left:139px;bottom:682px;letter-spacing:-0.2px;}
#tc_131{left:234px;bottom:682px;letter-spacing:-0.14px;word-spacing:7.79px;}
#td_131{left:139px;bottom:662px;letter-spacing:-0.19px;}
#te_131{left:113px;bottom:637px;}
#tf_131{left:139px;bottom:637px;letter-spacing:-0.1px;}
#tg_131{left:210px;bottom:637px;letter-spacing:-0.13px;word-spacing:4.35px;}
#th_131{left:139px;bottom:617px;letter-spacing:-0.06px;}
#ti_131{left:113px;bottom:592px;}
#tj_131{left:139px;bottom:592px;letter-spacing:-0.1px;word-spacing:2.72px;}
#tk_131{left:247px;bottom:592px;letter-spacing:-0.16px;word-spacing:2.78px;}
#tl_131{left:139px;bottom:572px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tm_131{left:87px;bottom:539px;letter-spacing:-0.05px;}
#tn_131{left:114px;bottom:539px;letter-spacing:-0.15px;word-spacing:4.66px;}
#to_131{left:114px;bottom:519px;letter-spacing:-0.14px;word-spacing:-0.48px;}
#tp_131{left:114px;bottom:499px;letter-spacing:-0.18px;word-spacing:9.7px;}
#tq_131{left:114px;bottom:479px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tr_131{left:441px;bottom:479px;letter-spacing:-0.05px;}
#ts_131{left:87px;bottom:445px;letter-spacing:-0.05px;}
#tt_131{left:114px;bottom:445px;letter-spacing:-0.14px;word-spacing:3.2px;}
#tu_131{left:114px;bottom:425px;letter-spacing:-0.13px;}
#tv_131{left:113px;bottom:401px;}
#tw_131{left:139px;bottom:401px;letter-spacing:-0.19px;word-spacing:0.1px;}
#tx_131{left:113px;bottom:376px;}
#ty_131{left:139px;bottom:376px;letter-spacing:-0.23px;word-spacing:0.14px;}
#tz_131{left:113px;bottom:352px;}
#t10_131{left:139px;bottom:352px;letter-spacing:-0.14px;word-spacing:4.22px;}
#t11_131{left:139px;bottom:332px;letter-spacing:-0.33px;word-spacing:0.24px;}
#t12_131{left:87px;bottom:298px;letter-spacing:-0.05px;}
#t13_131{left:114px;bottom:298px;letter-spacing:-0.13px;word-spacing:-0.35px;}
#t14_131{left:280px;bottom:298px;letter-spacing:-0.16px;word-spacing:-0.33px;}
#t15_131{left:114px;bottom:278px;letter-spacing:-0.17px;word-spacing:3.26px;}
#t16_131{left:114px;bottom:258px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t17_131{left:87px;bottom:225px;letter-spacing:-0.05px;}
#t18_131{left:114px;bottom:225px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t19_131{left:113px;bottom:200px;}
#t1a_131{left:139px;bottom:200px;letter-spacing:-0.16px;}
#t1b_131{left:239px;bottom:200px;letter-spacing:-0.11px;}
#t1c_131{left:336px;bottom:200px;letter-spacing:-0.13px;}
#t1d_131{left:441px;bottom:200px;letter-spacing:-0.18px;}
#t1e_131{left:520px;bottom:200px;letter-spacing:-0.08px;}
#t1f_131{left:574px;bottom:200px;letter-spacing:-0.16px;}
#t1g_131{left:139px;bottom:180px;letter-spacing:-0.17px;word-spacing:3.28px;}
#t1h_131{left:139px;bottom:160px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1i_131{left:113px;bottom:136px;}
#t1j_131{left:139px;bottom:136px;letter-spacing:-0.19px;word-spacing:5.91px;}
#t1k_131{left:139px;bottom:115px;letter-spacing:-0.17px;word-spacing:6.17px;}

.s0_131{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_131{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s2_131{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_131{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s4_131{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_131{font-size:17px;font-family:Wingdings2_1c6;color:#000;}
.s6_131{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}`}
      </style>

      <style id="fonts131" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}

@font-face {
	font-family: Wingdings2_1c6;
	src: url("fonts/Wingdings2_1c6.woff") format("woff");
}
`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg131" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="131/131.svg"
          type="image/svg+xml"
          id="pdf131"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_131" class="t s0_131">
          131{" "}
        </span>
        <span id="t2_131" class="t s1_131">
          Даалгаврыг боловсруулах{" "}
        </span>
        <span id="t3_131" class="t s1_131">
          эсвэл сонгоход багш дараах алхмын дагуу{" "}
        </span>
        <span id="t4_131" class="t s1_131">
          ажиллана.{" "}
        </span>
        <span id="t5_131" class="t s2_131">
          Зураг 3.19. Даалгавар, даалгаврын сан{" "}
        </span>
        <span id="t6_131" class="t s3_131">
          1.{" "}
        </span>
        <span id="t7_131" class="t s4_131">
          Үнэлгээний төрлийг тогтоох{" "}
        </span>
        <span id="t8_131" class="t s4_131">
          (түвшин тогтоох, оношлох, явцын, эцсийн{" "}
        </span>
        <span id="t9_131" class="t s4_131">
          үнэлгээний аль нь вэ? ){" "}
        </span>
        <span id="ta_131" class="t s5_131">
          {" "}
        </span>
        <span id="tb_131" class="t s6_131">
          Оношлох-{" "}
        </span>
        <span id="tc_131" class="t s4_131">
          Өмнөх мэдлэг чадварыг илрүүлэх, хаанаас эхлэхээ{" "}
        </span>
        <span id="td_131" class="t s4_131">
          тодорхойлох{" "}
        </span>
        <span id="te_131" class="t s5_131">
          {" "}
        </span>
        <span id="tf_131" class="t s6_131">
          Явцын-{" "}
        </span>
        <span id="tg_131" class="t s4_131">
          Мэдлэг бүтээх- шалгуур, гүйцэтгэлд үндэслэн чиглүүлэх,{" "}
        </span>
        <span id="th_131" class="t s4_131">
          дэмжих{" "}
        </span>
        <span id="ti_131" class="t s5_131">
          {" "}
        </span>
        <span id="tj_131" class="t s6_131">
          Үр дүнгийн-{" "}
        </span>
        <span id="tk_131" class="t s4_131">
          Суралцахуйн зорилт, суралцахуйн үр дүн, шалгуураа{" "}
        </span>
        <span id="tl_131" class="t s4_131">
          хангаж буй эсэх{" "}
        </span>
        <span id="tm_131" class="t s3_131">
          2.{" "}
        </span>
        <span id="tn_131" class="t s4_131">
          Үнэлэх ажлын зорилгыг тодорхойлох (юуг үнэлэх вэ?) Гүйцэтгэлийн{" "}
        </span>
        <span id="to_131" class="t s4_131">
          ямар шалгуур эсвэл шалгуурын багцын эзэмшилтийг үнэлэх гэж байгааг{" "}
        </span>
        <span id="tp_131" class="t s4_131">
          тодорхойлж бичнэ. Суралцахуйн зорилт, агуулгын хамрах хүрээ,{" "}
        </span>
        <span id="tq_131" class="t s4_131">
          эзэмшилтийн түвшин, илрүүлэх чадвар,{" "}
        </span>
        <span id="tr_131" class="t s4_131">
          .....{" "}
        </span>
        <span id="ts_131" class="t s3_131">
          3.{" "}
        </span>
        <span id="tt_131" class="t s4_131">
          Зорилгын хэрэгжилтийг үнэлэх хэлбэрийг сонгох (юугаар үнэлэх вэ?){" "}
        </span>
        <span id="tu_131" class="t s4_131">
          тухайлбал,{" "}
        </span>
        <span id="tv_131" class="t s5_131">
          {" "}
        </span>
        <span id="tw_131" class="t s4_131">
          Баримтын болон ухагдахууны мэдлэг-олон сонголтот даалгавар г.м.{" "}
        </span>
        <span id="tx_131" class="t s5_131">
          {" "}
        </span>
        <span id="ty_131" class="t s4_131">
          Үйлийн мэдлэг, чадвар-богино хариултат асуулт г.м.{" "}
        </span>
        <span id="tz_131" class="t s5_131">
          {" "}
        </span>
        <span id="t10_131" class="t s4_131">
          Хэрэглэх болон задлан шинжлэх чадвар- аман, бичгийн, бүтээлч{" "}
        </span>
        <span id="t11_131" class="t s4_131">
          ажил гэх мэт.{" "}
        </span>
        <span id="t12_131" class="t s3_131">
          4.{" "}
        </span>
        <span id="t13_131" class="t s4_131">
          Үнэлгээний шалгуур:{" "}
        </span>
        <span id="t14_131" class="t s4_131">
          Даалгаврын гүйцэтгэл, асуултын хариултыг үнэлэх{" "}
        </span>
        <span id="t15_131" class="t s4_131">
          шалгууруудыг шаардлагатай бол харгалзах гүйцэтгэлийн хамт бичнэ.{" "}
        </span>
        <span id="t16_131" class="t s4_131">
          Даалгаврын блюкринт боловсруулах, даалгаврын тоог тогтоох{" "}
        </span>
        <span id="t17_131" class="t s3_131">
          5.{" "}
        </span>
        <span id="t18_131" class="t s4_131">
          Даалгавар боловсруулах:{" "}
        </span>
        <span id="t19_131" class="t s5_131">
          {" "}
        </span>
        <span id="t1a_131" class="t s4_131">
          Даалгавар{" "}
        </span>
        <span id="t1b_131" class="t s4_131">
          НӨХЦӨЛ-{" "}
        </span>
        <span id="t1c_131" class="t s4_131">
          үнэлгээний{" "}
        </span>
        <span id="t1d_131" class="t s4_131">
          зорилго{" "}
        </span>
        <span id="t1e_131" class="t s4_131">
          дахь{" "}
        </span>
        <span id="t1f_131" class="t s4_131">
          гүйцэтгэлийн{" "}
        </span>
        <span id="t1g_131" class="t s4_131">
          шалгуурын мэдлэг, чадвар, хандлагыг илрүүлэх бодит амьдралын{" "}
        </span>
        <span id="t1h_131" class="t s4_131">
          асуудлыг тусгасан байна{" "}
        </span>
        <span id="t1i_131" class="t s5_131">
          {" "}
        </span>
        <span id="t1j_131" class="t s4_131">
          Гол хэсэг-АСУУДАЛ, хариултын хувилбар зааварчилгаа, аюулгүй{" "}
        </span>
        <span id="t1k_131" class="t s4_131">
          ажиллагааны дүрэм, үр дүнг нэгтгэх хэсэг, гүйцэтгэлийг үнэлэх,{" "}
        </span>
      </div>
    </div>
  );
}
