import {
  Box,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import SideBar from "./DrawerMenu";
import SideBar1 from "./pages/oneSection";
import SideBar2 from "./pages/twoSection";
import One from "./pages/one";
import Two from "./pages/two";
import Three from "./pages/three";
import Four from "./pages/4";
import Five from "./pages/5";
import Six from "./pages/6";
import Seven from "./pages/7";
import Naim from "./pages/8";
import Yus from "./pages/9";
import Ten from "./pages/10";
import OneTen from "./pages/11";
import TwoTen from "./pages/12";
import ThreeTen from "./pages/13";
import FourTen from "./pages/14";
import SixOne from "./pages/61";
import SixTwo from "./pages/62";
import SixZero from "./pages/60";
import FiveNine from "./pages/59";
import FiveEight from "./pages/58";
import FiveSeven from "./pages/57";
import FiveSix from "./pages/56";
import FiveFive from "./pages/55";
import FiveFour from "./pages/54";
import FiveThree from "./pages/53";
import FiveTwo from "./pages/52";
import FiveOne from "./pages/51";
import FiveZero from "./pages/50";
import FourNine from "./pages/49";
import FourEight from "./pages/48";
import FourSeven from "./pages/47";
import FourSix from "./pages/46";
import FourFive from "./pages/45";
import FourFour from "./pages/44";
import FourThree from "./pages/43";
import FourTwo from "./pages/42";
import FourOne from "./pages/41";
import FourZero from "./pages/40";
import ThreeNine from "./pages/39";
import ThirtyEight from "./pages/38";
import ThirtySeven from "./pages/37";
import ThirtySix from "./pages/36";
import ThirtyFive from "./pages/35";
import ThirtyFour from "./pages/34";
import ThirtyThree from "./pages/33";
import ThirtyTwo from "./pages/32";
import ThirtyOne from "./pages/31";
import Thirty from "./pages/30";
import TwentyNine from "./pages/29";
import TwentyEight from "./pages/28";
import TwentySeven from "./pages/27";
import TwentySix from "./pages/26";
import TwentyFive from "./pages/25";
import TwentyFour from "./pages/24";
import TwentyThree from "./pages/23";
import TwentyTwo from "./pages/22";
import TwentyOne from "./pages/21";
import Twenty from "./pages/20";
import Nineteen from "./pages/19";
import Eighteen from "./pages/18";
import SevenTen from "./pages/17";
import FiveTen from "./pages/15";
import SixTen from "./pages/16";
import SixThree from "./pages/63";
import SixFour from "./pages/64";
import SixFive from "./pages/65";
import SixtySix from "./pages/66";
import SixtySeven from "./pages/67";
import SixtyEigth from "./pages/68";
import SixtyNine from "./pages/69";
import Seventy from "./pages/70";
import SeventyOne from "./pages/71";
import SeventyTwo from "./pages/72";
import SeventyThree from "./pages/73";
import SeventyFour from "./pages/74";
import SeventyFive from "./pages/75";
import SeventySix from "./pages/76";
import SeventySeven from "./pages/77";
import SeventyEight from "./pages/78";
import SeventyNine from "./pages/79";
import Eigthy from "./pages/80";
import EigthyOne from "./pages/81";
import EigthyTwo from "./pages/82";
import EigthyThree from "./pages/83";
import EigthyFour from "./pages/84";
import EigthyFive from "./pages/85";
import EigthySix from "./pages/86";
import EigthySeven from "./pages/87";
import EigthyEigth from "./pages/88";
import EigthyNine from "./pages/89";
import Ninety from "./pages/90";
import NinetyOne from "./pages/91";
import NinetyTwo from "./pages/92";
import NinetyThree from "./pages/93";
import NinetyFour from "./pages/94";
import NinetyFive from "./pages/95";
import NinetySix from "./pages/96";
import NinetySeven from "./pages/97";
import NinetyEigth from "./pages/98";
import NinetyNine from "./pages/99";
import F100 from "./pages/100";
import F101 from "./pages/101";
import F102 from "./pages/102";
import F103 from "./pages/103";
import F104 from "./pages/104";
import F105 from "./pages/105";
import F106 from "./pages/106";
import F107 from "./pages/107";
import F108 from "./pages/108";
import F109 from "./pages/109";
import F110 from "./pages/110";
import F111 from "./pages/111";
import F112 from "./pages/112";
import F113 from "./pages/113";
import F114 from "./pages/114";
import F115 from "./pages/115";
import F116 from "./pages/116";
import F117 from "./pages/117";
import F118 from "./pages/118";
import F119 from "./pages/119";
import F120 from "./pages/120";
import F121 from "./pages/121";
import F122 from "./pages/122";
import F123 from "./pages/123";
import F124 from "./pages/124";
import F125 from "./pages/125";
import F126 from "./pages/126";
import F127 from "./pages/127";
import F128 from "./pages/128";
import F129 from "./pages/129";
import F130 from "./pages/130";
import F131 from "./pages/131";
import F132 from "./pages/132";
import F133 from "./pages/133";
import F134 from "./pages/134";
import F135 from "./pages/135";
import F136 from "./pages/136";
import F137 from "./pages/137";
import F138 from "./pages/138";
import F139 from "./pages/139";
import F140 from "./pages/140";
import F141 from "./pages/141";
import F142 from "./pages/142";
import F143 from "./pages/143";
import F144 from "./pages/144";
import F145 from "./pages/145";
import F146 from "./pages/146";
import F147 from "./pages/147";
import F148 from "./pages/148";
import F149 from "./pages/149";
import F150 from "./pages/150";
import F151 from "./pages/151";
import F152 from "./pages/152";
import F153 from "./pages/153";
import F154 from "./pages/154";
import F155 from "./pages/155";
import F156 from "./pages/156";
import F157 from "./pages/157";
import F158 from "./pages/158";
import F159 from "./pages/159";
import F160 from "./pages/160";
import F161 from "./pages/161";
import F162 from "./pages/162";
import F163 from "./pages/163";
import F164 from "./pages/164";
import F165 from "./pages/165";
import F166 from "./pages/166";
import F167 from "./pages/167";
import F168 from "./pages/168";
import F169 from "./pages/169";
import F170 from "./pages/170";
import F171 from "./pages/171";
import F172 from "./pages/172";
import F173 from "./pages/173";
import F174 from "./pages/174";
import F175 from "./pages/175";
import F176 from "./pages/176";
import F177 from "./pages/177";
import F178 from "./pages/178";
import F179 from "./pages/179";
import F180 from "./pages/180";
import F181 from "./pages/181";
import F182 from "./pages/182";
import F183 from "./pages/183";
import F184 from "./pages/184";
import F185 from "./pages/185";
import F186 from "./pages/186";
import F187 from "./pages/187";
import F188 from "./pages/188";
import F189 from "./pages/189";
import F190 from "./pages/190";
import F191 from "./pages/191";
import F192 from "./pages/192";
import F193 from "./pages/193";
import F194 from "./pages/194";
import F195 from "./pages/195";
import F196 from "./pages/196";
import F197 from "./pages/197";
import F198 from "./pages/198";
import F199 from "./pages/199";
import F200 from "./pages/200";
import { useEffect, useState } from "react";
// import { useRouter } from "next/router";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ViewChange from "./pdf";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useParams } from "react-router-dom";
import SideBar3 from "./pages/threeSection";

const defaultDrawerWidth = 340;
const menu = [
  {
    name: 1,
    value: 1,
  },
  {
    name: 2,
    value: 2,
  },
  {
    name: 3,
    value: 3,
  },
  {
    name: 4,
    value: 4,
  },
  {
    name: 5,
    value: 5,
  },
  {
    name: 6,
    value: 6,
  },
  {
    name: 7,
    value: 7,
  },
  {
    name: 8,
    value: 8,
  },
  {
    name: 9,
    value: 9,
  },
  {
    name: 10,
    value: 10,
  },
  {
    name: 11,
    value: 11,
  },
  {
    name: 12,
    value: 12,
  },
  {
    name: 13,
    value: 13,
  },
  {
    name: 14,
    value: 14,
  },
  {
    name: 15,
    value: 15,
  },
  {
    name: 16,
    value: 16,
  },
  {
    name: 17,
    value: 17,
  },
  {
    name: 18,
    value: 18,
  },
  {
    name: 19,
    value: 19,
  },
  {
    name: 20,
    value: 20,
  },
  {
    name: 21,
    value: 21,
  },
  {
    name: 22,
    value: 22,
  },
  {
    name: 23,
    value: 23,
  },
  {
    name: 24,
    value: 24,
  },
  {
    name: 25,
    value: 25,
  },
  {
    name: 26,
    value: 26,
  },
  {
    name: 27,
    value: 27,
  },
  {
    name: 28,
    value: 28,
  },
  {
    name: 29,
    value: 29,
  },
  {
    name: 30,
    value: 30,
  },
  {
    name: 31,
    value: 31,
  },
  {
    name: 32,
    value: 32,
  },
  {
    name: 33,
    value: 33,
  },
  {
    name: 34,
    value: 34,
  },
  {
    name: 35,
    value: 35,
  },
  {
    name: 36,
    value: 36,
  },
  {
    name: 37,
    value: 37,
  },
  {
    name: 38,
    value: 38,
  },
  {
    name: 39,
    value: 39,
  },
  {
    name: 40,
    value: 40,
  },
  {
    name: 41,
    value: 41,
  },
  {
    name: 42,
    value: 42,
  },
  {
    name: 43,
    value: 43,
  },
  {
    name: 44,
    value: 44,
  },
  {
    name: 45,
    value: 45,
  },
  {
    name: 46,
    value: 46,
  },
  {
    name: 47,
    value: 47,
  },
  {
    name: 48,
    value: 48,
  },
  {
    name: 49,
    value: 49,
  },
  {
    name: 50,
    value: 50,
  },
  {
    name: 51,
    value: 51,
  },
  {
    name: 52,
    value: 52,
  },
  {
    name: 53,
    value: 53,
  },
  {
    name: 54,
    value: 54,
  },
  {
    name: 55,
    value: 55,
  },
  {
    name: 56,
    value: 56,
  },
  {
    name: 57,
    value: 57,
  },
  {
    name: 58,
    value: 58,
  },
  {
    name: 59,
    value: 59,
  },
  {
    name: 60,
    value: 60,
  },
  {
    name: 61,
    value: 61,
  },
  {
    name: 62,
    value: 62,
  },
  {
    name: 63,
    value: 63,
  },
  {
    name: 64,
    value: 64,
  },
  {
    name: 65,
    value: 65,
  },
  {
    name: 66,
    value: 66,
  },
  {
    name: 67,
    value: 67,
  },
  {
    name: 68,
    value: 68,
  },
  {
    name: 69,
    value: 69,
  },
  {
    name: 70,
    value: 70,
  },
  {
    name: 71,
    value: 72,
  },
  {
    name: 73,
    value: 73,
  },
  {
    name: 74,
    value: 74,
  },
  {
    name: 75,
    value: 75,
  },
  {
    name: 76,
    value: 76,
  },
  {
    name: 77,
    value: 77,
  },
  {
    name: 78,
    value: 78,
  },
  {
    name: 79,
    value: 79,
  },
  {
    name: 80,
    value: 80,
  },
  {
    name: 81,
    value: 81,
  },
  {
    name: 82,
    value: 82,
  },
  {
    name: 83,
    value: 83,
  },
  {
    name: 84,
    value: 84,
  },
  {
    name: 85,
    value: 85,
  },
  {
    name: 86,
    value: 86,
  },
  {
    name: 87,
    value: 87,
  },
  {
    name: 88,
    value: 88,
  },
  {
    name: 89,
    value: 89,
  },
  {
    name: 90,
    value: 90,
  },
  {
    name: 91,
    value: 91,
  },
  {
    name: 92,
    value: 92,
  },
  {
    name: 93,
    value: 93,
  },
  {
    name: 94,
    value: 94,
  },
  {
    name: 95,
    value: 95,
  },
  {
    name: 96,
    value: 96,
  },
  {
    name: 97,
    value: 97,
  },
  {
    name: 98,
    value: 98,
  },
  {
    name: 99,
    value: 99,
  },
  {
    name: 100,
    value: 100,
  },
  {
    name: 101,
    value: 101,
  },
  {
    name: 102,
    value: 102,
  },
  {
    name: 103,
    value: 103,
  },
  {
    name: 104,
    value: 104,
  },
  {
    name: 105,
    value: 105,
  },
  {
    name: 106,
    value: 106,
  },
  {
    name: 107,
    value: 107,
  },
  {
    name: 108,
    value: 108,
  },
  {
    name: 109,
    value: 109,
  },
  {
    name: 110,
    value: 110,
  },
  {
    name: 111,
    value: 111,
  },
  {
    name: 112,
    value: 112,
  },
  {
    name: 113,
    value: 113,
  },
  {
    name: 114,
    value: 114,
  },
  {
    name: 115,
    value: 115,
  },
  {
    name: 116,
    value: 116,
  },
  {
    name: 117,
    value: 117,
  },
  {
    name: 118,
    value: 118,
  },
  {
    name: 119,
    value: 119,
  },
  {
    name: 120,
    value: 120,
  },
  {
    name: 121,
    value: 121,
  },
  {
    name: 122,
    value: 122,
  },
  {
    name: 123,
    value: 123,
  },
  {
    name: 124,
    value: 124,
  },
  {
    name: 125,
    value: 125,
  },
  {
    name: 126,
    value: 126,
  },
  {
    name: 127,
    value: 127,
  },
  {
    name: 128,
    value: 128,
  },
  {
    name: 129,
    value: 129,
  },
  {
    name: 130,
    value: 130,
  },
  {
    name: 131,
    value: 131,
  },
  {
    name: 132,
    value: 132,
  },
  {
    name: 133,
    value: 133,
  },
  {
    name: 134,
    value: 134,
  },
  {
    name: 135,
    value: 135,
  },
  {
    name: 136,
    value: 136,
  },
  {
    name: 137,
    value: 137,
  },
  {
    name: 138,
    value: 138,
  },
  {
    name: 139,
    value: 139,
  },
  {
    name: 140,
    value: 140,
  },
  {
    name: 141,
    value: 141,
  },
  {
    name: 142,
    value: 142,
  },
  {
    name: 143,
    value: 143,
  },
  {
    name: 144,
    value: 144,
  },
  {
    name: 145,
    value: 145,
  },
  {
    name: 146,
    value: 146,
  },
  {
    name: 147,
    value: 147,
  },
  {
    name: 148,
    value: 148,
  },
  {
    name: 149,
    value: 149,
  },
  {
    name: 150,
    value: 150,
  },
  {
    name: 151,
    value: 151,
  },
  {
    name: 152,
    value: 152,
  },
  {
    name: 153,
    value: 153,
  },
  {
    name: 154,
    value: 154,
  },
  {
    name: 155,
    value: 155,
  },
  {
    name: 156,
    value: 156,
  },
  {
    name: 157,
    value: 157,
  },
  {
    name: 158,
    value: 158,
  },
  {
    name: 159,
    value: 159,
  },
  {
    name: 160,
    value: 160,
  },
  {
    name: 161,
    value: 161,
  },
  {
    name: 162,
    value: 162,
  },
  {
    name: 163,
    value: 163,
  },
  {
    name: 164,
    value: 164,
  },
  {
    name: 165,
    value: 165,
  },
  {
    name: 166,
    value: 166,
  },
  {
    name: 167,
    value: 167,
  },
  {
    name: 168,
    value: 168,
  },
  {
    name: 169,
    value: 169,
  },
  {
    name: 170,
    value: 170,
  },
  {
    name: 171,
    value: 171,
  },
  {
    name: 172,
    value: 172,
  },
  {
    name: 173,
    value: 173,
  },
  {
    name: 174,
    value: 174,
  },
  {
    name: 175,
    value: 175,
  },
  {
    name: 176,
    value: 176,
  },
  {
    name: 177,
    value: 177,
  },
  {
    name: 178,
    value: 178,
  },
  {
    name: 179,
    value: 179,
  },
  {
    name: 180,
    value: 180,
  },
  {
    name: 181,
    value: 181,
  },
  {
    name: 182,
    value: 182,
  },
  {
    name: 183,
    value: 183,
  },
  {
    name: 184,
    value: 184,
  },
  {
    name: 185,
    value: 185,
  },
  {
    name: 186,
    value: 186,
  },
  {
    name: 187,
    value: 187,
  },
  {
    name: 188,
    value: 188,
  },
  {
    name: 189,
    value: 189,
  },

  {
    name: 190,
    value: 190,
  },
  {
    name: 191,
    value: 191,
  },
  {
    name: 192,
    value: 192,
  },
  {
    name: 193,
    value: 193,
  },
  {
    name: 194,
    value: 194,
  },
  {
    name: 195,
    value: 195,
  },
  {
    name: 196,
    value: 196,
  },
  {
    name: 197,
    value: 197,
  },
  {
    name: 198,
    value: 198,
  },
  {
    name: 199,
    value: 199,
  },
  {
    name: 200,
    value: 200,
  },
];

export default function Home() {
  const [paperIndex, setPaperIndex] = useState(2);

  const [pdf, setPdf] = useState(true);

  const RR = (e) => {
    if (e) {
      const newIndex = e.value;
      setPaperIndex(newIndex);
      window.location.href = `/#${newIndex}`;
    } else window.location.href = `/#0`;
  };
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "100%",
        }}
      >
        <Drawer
          anchor="left"
          sx={{
            width: defaultDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: defaultDrawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
        >
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              bgcolor: "#20bec6",
              textAlign: "center", // Center the text horizontally
              color: "white",
              fontWeight: 600,
            }}
          >
            СУРГУУЛИЙН МЕНЕЖМЕНТИЙГ ДЭМЖИХ ЕРӨНХИЙ БОЛОВСРОЛЫН АРГА ЗҮЙН ЗӨВЛӨМЖ
          </Typography>

          <Box sx={{ ml: 3, mt: 3 }}>
            <Grid container>
              <Grid item xs={10}>
                <Autocomplete
                  id="multiple-limit-tags"
                  options={menu}
                  disableCloseOnSelect
                  onChange={(e, a) => {
                    RR(a);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField {...params} label="Хуудсаар хайх:" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  sx={{ mx: 1 }}
                  color="inherit"
                  onClick={() => {
                    setPdf(!pdf);
                  }}
                >
                  {pdf ? <PictureAsPdfIcon /> : <MenuBookIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 3 }} />

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontWeight: "bold" }}>Өмнөх үг</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SideBar
                  setPaperIndex={setPaperIndex}
                  paperIndex={paperIndex}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography style={{ fontWeight: "bold" }}>
                  1-р бүлэг
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SideBar1
                  setPaperIndex={setPaperIndex}
                  paperIndex={paperIndex}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded} onChange={handleChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography style={{ fontWeight: "bold" }}>
                  2-р бүлэг
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SideBar2
                  setPaperIndex={setPaperIndex}
                  paperIndex={paperIndex}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography style={{ fontWeight: "bold" }}>
                  3-р бүлэг
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SideBar3
                  setPaperIndex={setPaperIndex}
                  paperIndex={paperIndex}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </Drawer>
        <Box
          sx={{
            width: "762px",
            // mt: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ width: "762px", display: "flex", justifyContent: "center" }}
          >
            {pdf ? (
              <Box>
                <div id="1">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <One />
                  </Box>
                </div>
                <div id="2">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Two />
                  </Box>
                </div>
                <div id="3">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Three />
                  </Box>
                </div>
                <div id="4">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Four />
                  </Box>
                </div>
                <div id="5">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Five />
                  </Box>
                </div>
                <div id="6">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Six />
                  </Box>
                </div>
                <div id="7">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Seven />
                  </Box>
                </div>
                <div id="8">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Naim />
                  </Box>
                </div>
                <div id="9">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Yus />
                  </Box>
                </div>
                <div id="10">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Ten />
                  </Box>
                </div>
                <div id="11">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <OneTen />
                  </Box>
                </div>
                <div id="12">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwoTen />
                  </Box>
                </div>
                <div id="13">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThreeTen />
                  </Box>
                </div>
                <div id="14">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourTen />
                  </Box>
                </div>
                <div id="15">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveTen />
                  </Box>
                </div>
                <div id="16">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixTen />
                  </Box>
                </div>
                <div id="17">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SevenTen />
                  </Box>
                </div>
                <div id="18">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Eighteen />
                  </Box>
                </div>
                <div id="19">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Nineteen />
                  </Box>
                </div>
                <div id="20">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Twenty />
                  </Box>
                </div>
                <div id="21">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyOne />
                  </Box>
                </div>
                <div id="22">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyTwo />
                  </Box>
                </div>
                <div id="23">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyThree />
                  </Box>
                </div>
                <div id="24">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyFour />
                  </Box>
                </div>
                <div id="25">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyFive />
                  </Box>
                </div>
                <div id="26">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentySix />
                  </Box>
                </div>
                <div id="27">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentySeven />
                  </Box>
                </div>
                <div id="28">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyEight />
                  </Box>
                </div>
                <div id="29">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <TwentyNine />
                  </Box>
                </div>
                <div id="30">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Thirty />
                  </Box>
                </div>
                <div id="31">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyOne />
                  </Box>
                </div>
                <div id="32">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyTwo />
                  </Box>
                </div>
                <div id="33">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyThree />
                  </Box>
                </div>
                <div id="34">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyFour />
                  </Box>
                </div>
                <div id="35">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyFive />
                  </Box>
                </div>
                <div id="36">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtySix />
                  </Box>
                </div>
                <div id="37">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtySeven />
                  </Box>
                </div>
                <div id="38">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThirtyEight />
                  </Box>
                </div>
                <div id="39">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <ThreeNine />
                  </Box>
                </div>
                <div id="40">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourZero />
                  </Box>
                </div>
                <div id="41">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourOne />
                  </Box>
                </div>
                <div id="42">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourTwo />
                  </Box>
                </div>
                <div id="43">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourThree />
                  </Box>
                </div>
                <div id="44">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourFour />
                  </Box>
                </div>
                <div id="45">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourFive />
                  </Box>
                </div>
                <div id="46">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourSix />
                  </Box>
                </div>
                <div id="47">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourSeven />
                  </Box>
                </div>
                <div id="48">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourEight />
                  </Box>
                </div>
                <div id="49">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FourNine />
                  </Box>
                </div>
                <div id="50">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveZero />
                  </Box>
                </div>
                <div id="51">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveOne />
                  </Box>
                </div>
                <div id="52">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveTwo />
                  </Box>
                </div>
                <div id="53">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveThree />
                  </Box>
                </div>
                <div id="54">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveFour />
                  </Box>
                </div>
                <div id="55">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveFive />
                  </Box>
                </div>
                <div id="56">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveSix />
                  </Box>
                </div>
                <div id="57">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveSeven />
                  </Box>
                </div>
                <div id="58">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveEight />
                  </Box>
                </div>
                <div id="59">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <FiveNine />
                  </Box>
                </div>
                <div id="60">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixZero />
                  </Box>
                </div>
                <div id="61">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixOne />
                  </Box>
                </div>
                <div id="62">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixTwo />
                  </Box>
                </div>
                <div id="63">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixThree />
                  </Box>
                </div>
                <div id="64">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixFour />
                  </Box>
                </div>
                <div id="65">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixFive />
                  </Box>
                </div>
                <div id="66">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixtySix />
                  </Box>
                </div>
                <div id="67">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixtySeven />
                  </Box>
                </div>
                <div id="68">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixtyEigth />
                  </Box>
                </div>
                <div id="69">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SixtyNine />
                  </Box>
                </div>
                <div id="70">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Seventy />
                  </Box>
                </div>
                <div id="71">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyOne />
                  </Box>
                </div>
                <div id="72">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyTwo />
                  </Box>
                </div>
                <div id="73">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyThree />
                  </Box>
                </div>
                <div id="74">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyFour />
                  </Box>
                </div>
                <div id="75">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyFive />
                  </Box>
                </div>
                <div id="76">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventySix />
                  </Box>
                </div>
                <div id="77">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventySeven />
                  </Box>
                </div>
                <div id="78">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyEight />
                  </Box>
                </div>
                <div id="79">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <SeventyNine />
                  </Box>
                </div>
                <div id="80">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Eigthy />
                  </Box>
                </div>
                <div id="81">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyOne />
                  </Box>
                </div>
                <div id="82">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyTwo />
                  </Box>
                </div>
                <div id="83">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyThree />
                  </Box>
                </div>
                <div id="84">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyFour />
                  </Box>
                </div>
                <div id="85">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyFive />
                  </Box>
                </div>
                <div id="86">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthySix />
                  </Box>
                </div>
                <div id="87">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthySeven />
                  </Box>
                </div>
                <div id="88">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyEigth />
                  </Box>
                </div>
                <div id="89">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <EigthyNine />
                  </Box>
                </div>
                <div id="90">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <Ninety />
                  </Box>
                </div>
                <div id="91">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyOne />
                  </Box>
                </div>
                <div id="92">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyTwo />
                  </Box>
                </div>
                <div id="93">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyThree />
                  </Box>
                </div>
                <div id="94">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyFour />
                  </Box>
                </div>
                <div id="95">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyFive />
                  </Box>
                </div>
                <div id="96">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetySix />
                  </Box>
                </div>
                <div id="97">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetySeven />
                  </Box>
                </div>
                <div id="98">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyEigth />
                  </Box>
                </div>
                <div id="99">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <NinetyNine />
                  </Box>
                </div>
                <div id="100">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F100 />
                  </Box>
                </div>
                <div id="101">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F101 />
                  </Box>
                </div>
                <div id="102">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F102 />
                  </Box>
                </div>
                <div id="103">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F103 />
                  </Box>
                </div>
                <div id="104">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F104 />
                  </Box>
                </div>
                <div id="105">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F105 />
                  </Box>
                </div>
                <div id="106">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F106 />
                  </Box>
                </div>
                <div id="107">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F107 />
                  </Box>
                </div>
                <div id="108">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F108 />
                  </Box>
                </div>
                <div id="109">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F109 />
                  </Box>
                </div>
                <div id="110">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F110 />
                  </Box>
                </div>
                <div id="111">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F111 />
                  </Box>
                </div>
                <div id="112">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F112 />
                  </Box>
                </div>
                <div id="113">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F113 />
                  </Box>
                </div>
                <div id="114">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F114 />
                  </Box>
                </div>
                <div id="115">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F115 />
                  </Box>
                </div>
                <div id="116">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F116 />
                  </Box>
                </div>
                <div id="117">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F117 />
                  </Box>
                </div>
                <div id="118">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F118 />
                  </Box>
                </div>
                <div id="119">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F119 />
                  </Box>
                </div>
                <div id="120">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F120 />
                  </Box>
                </div>
                <div id="121">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F121 />
                  </Box>
                </div>
                <div id="122">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F122 />
                  </Box>
                </div>
                <div id="123">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F123 />
                  </Box>
                </div>
                <div id="124">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F124 />
                  </Box>
                </div>
                <div id="125">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F125 />
                  </Box>
                </div>
                <div id="126">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F126 />
                  </Box>
                </div>
                <div id="127">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F127 />
                  </Box>
                </div>
                <div id="128">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F128 />
                  </Box>
                </div>
                <div id="129">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F129 />
                  </Box>
                </div>
                <div id="130">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F130 />
                  </Box>
                </div>
                <div id="131">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F131 />
                  </Box>
                </div>
                <div id="132">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F132 />
                  </Box>
                </div>
                <div id="133">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F133 />
                  </Box>
                </div>
                <div id="134">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F134 />
                  </Box>
                </div>
                <div id="135">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F135 />
                  </Box>
                </div>
                <div id="136">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F136 />
                  </Box>
                </div>
                <div id="137">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F137 />
                  </Box>
                </div>
                <div id="138">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F138 />
                  </Box>
                </div>
                <div id="139">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F139 />
                  </Box>
                </div>
                <div id="140">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F140 />
                  </Box>
                </div>
                <div id="141">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F141 />
                  </Box>
                </div>
                <div id="142">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F142 />
                  </Box>
                </div>
                <div id="143">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F143 />
                  </Box>
                </div>
                <div id="144">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F144 />
                  </Box>
                </div>
                <div id="145">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F145 />
                  </Box>
                </div>
                <div id="146">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F146 />
                  </Box>
                </div>
                <div id="147">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F147 />
                  </Box>
                </div>
                <div id="148">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F148 />
                  </Box>
                </div>
                <div id="149">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F149 />
                  </Box>
                </div>
                <div id="150">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F150 />
                  </Box>
                </div>
                <div id="151">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F151 />
                  </Box>
                </div>
                <div id="152">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F152 />
                  </Box>
                </div>
                <div id="153">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F153 />
                  </Box>
                </div>
                <div id="154">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F154 />
                  </Box>
                </div>
                <div id="155">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F155 />
                  </Box>
                </div>
                <div id="156">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F156 />
                  </Box>
                </div>
                <div id="157">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F157 />
                  </Box>
                </div>
                <div id="158">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F158 />
                  </Box>
                </div>
                <div id="159">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F159 />
                  </Box>
                </div>
                <div id="160">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F160 />
                  </Box>
                </div>
                <div id="161">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F161 />
                  </Box>
                </div>
                <div id="162">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F162 />
                  </Box>
                </div>
                <div id="163">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F163 />
                  </Box>
                </div>
                <div id="164">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F164 />
                  </Box>
                </div>
                <div id="165">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F165 />
                  </Box>
                </div>
                <div id="166">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F166 />
                  </Box>
                </div>
                <div id="167">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F167 />
                  </Box>
                </div>
                <div id="168">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F168 />
                  </Box>
                </div>
                <div id="169">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F169 />
                  </Box>
                </div>
                <div id="170">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F170 />
                  </Box>
                </div>
                <div id="171">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F171 />
                  </Box>
                </div>
                <div id="172">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F172 />
                  </Box>
                </div>
                <div id="173">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F173 />
                  </Box>
                </div>
                <div id="174">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F174 />
                  </Box>
                </div>
                <div id="175">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F175 />
                  </Box>
                </div>
                <div id="176">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F176 />
                  </Box>
                </div>
                <div id="177">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F177 />
                  </Box>
                </div>
                <div id="178">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F178 />
                  </Box>
                </div>
                <div id="179">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F179 />
                  </Box>
                </div>
                <div id="180">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F180 />
                  </Box>
                </div>
                <div id="181">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F181 />
                  </Box>
                </div>
                <div id="182">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F182 />
                  </Box>
                </div>
                <div id="183">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F183 />
                  </Box>
                </div>
                <div id="184">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F184 />
                  </Box>
                </div>
                <div id="185">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F185 />
                  </Box>
                </div>
                <div id="186">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F186 />
                  </Box>
                </div>
                <div id="187">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F187 />
                  </Box>
                </div>
                <div id="188">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F188 />
                  </Box>
                </div>
                <div id="189">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F189 />
                  </Box>
                </div>
                <div id="190">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F190 />
                  </Box>
                </div>
                <div id="191">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F191 />
                  </Box>
                </div>
                <div id="192">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F192 />
                  </Box>
                </div>
                <div id="193">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F193 />
                  </Box>
                </div>
                <div id="194">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F194 />
                  </Box>
                </div>
                <div id="195">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F195 />
                  </Box>
                </div>
                <div id="196">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F196 />
                  </Box>
                </div>
                <div id="197">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F197 />
                  </Box>
                </div>
                <div id="198">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F198 />
                  </Box>
                </div>
                <div id="199">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F199 />
                  </Box>
                </div>
                <div id="200">
                  <Box
                    sx={{
                      width: "762px",
                      my: 1,
                      boxShadow:
                        "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    }}
                  >
                    <F200 />
                  </Box>
                </div>
              </Box>
            ) : (
              <Box sx={{ width: "200%" }}>
                <ViewChange />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
