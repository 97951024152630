import React from "react";
export default function TwentyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_24{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_24{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_24{left:109px;bottom:916px;letter-spacing:0.1px;word-spacing:-0.51px;}
#t4_24{left:109px;bottom:897px;letter-spacing:0.08px;word-spacing:-2.07px;}
#t5_24{left:109px;bottom:879px;letter-spacing:0.12px;word-spacing:2.77px;}
#t6_24{left:109px;bottom:861px;letter-spacing:0.13px;word-spacing:0.86px;}
#t7_24{left:109px;bottom:842px;letter-spacing:0.13px;word-spacing:0.01px;}
#t8_24{left:101px;bottom:764px;letter-spacing:0.13px;word-spacing:0.03px;}
#t9_24{left:101px;bottom:724px;letter-spacing:0.12px;word-spacing:4.84px;}
#ta_24{left:101px;bottom:705px;letter-spacing:0.1px;word-spacing:0.04px;}
#tb_24{left:123px;bottom:683px;letter-spacing:0.08px;}
#tc_24{left:144px;bottom:683px;letter-spacing:0.04px;word-spacing:0.09px;}
#td_24{left:144px;bottom:664px;}
#te_24{left:157px;bottom:664px;letter-spacing:0.09px;word-spacing:-1.76px;}
#tf_24{left:157px;bottom:646px;letter-spacing:0.1px;word-spacing:0.04px;}
#tg_24{left:144px;bottom:623px;letter-spacing:0.08px;word-spacing:-0.14px;}
#th_24{left:144px;bottom:601px;letter-spacing:0.1px;word-spacing:-0.44px;}
#ti_24{left:157px;bottom:582px;letter-spacing:0.13px;word-spacing:0.02px;}
#tj_24{left:123px;bottom:560px;letter-spacing:0.08px;}
#tk_24{left:144px;bottom:560px;letter-spacing:0.09px;word-spacing:0.04px;}
#tl_24{left:144px;bottom:541px;letter-spacing:0.08px;word-spacing:4.08px;}
#tm_24{left:157px;bottom:523px;letter-spacing:0.09px;word-spacing:0.05px;}
#tn_24{left:144px;bottom:500px;letter-spacing:0.1px;word-spacing:2.85px;}
#to_24{left:157px;bottom:482px;letter-spacing:0.12px;word-spacing:0.03px;}
#tp_24{left:144px;bottom:459px;letter-spacing:0.1px;word-spacing:1.94px;}
#tq_24{left:157px;bottom:441px;letter-spacing:0.11px;word-spacing:0.02px;}
#tr_24{left:160px;bottom:418px;letter-spacing:0.06px;word-spacing:0.96px;}
#ts_24{left:157px;bottom:400px;letter-spacing:0.11px;word-spacing:0.04px;}
#tt_24{left:144px;bottom:377px;letter-spacing:0.07px;word-spacing:4.2px;}
#tu_24{left:157px;bottom:359px;letter-spacing:0.1px;word-spacing:0.03px;}
#tv_24{left:383px;bottom:359px;letter-spacing:-0.83px;}
#tw_24{left:123px;bottom:336px;letter-spacing:0.08px;}
#tx_24{left:144px;bottom:336px;word-spacing:0.14px;}
#ty_24{left:144px;bottom:318px;letter-spacing:0.11px;word-spacing:3.4px;}
#tz_24{left:157px;bottom:300px;letter-spacing:0.1px;word-spacing:0.04px;}
#t10_24{left:144px;bottom:277px;letter-spacing:0.04px;word-spacing:0.44px;}
#t11_24{left:144px;bottom:254px;letter-spacing:0.11px;word-spacing:4.18px;}
#t12_24{left:157px;bottom:236px;letter-spacing:0.1px;word-spacing:0.03px;}
#t13_24{left:144px;bottom:213px;letter-spacing:0.11px;word-spacing:3.12px;}
#t14_24{left:157px;bottom:195px;letter-spacing:0.09px;word-spacing:0.05px;}

.s0_24{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_24{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_24{font-size:15px;font-family:MogulArial_16s;color:#333;}
.s3_24{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s4_24{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_24{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts24" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg24" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="24/24.svg"
          type="image/svg+xml"
          id="pdf24"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_24" class="t s1_24">
          24{" "}
        </span>
        <span id="t3_24" class="t s2_24">
          33.2.2. боловсролын сургалтын байгууллагад тасралтгүй 10-аас доошгүй
          жил,{" "}
        </span>
        <span id="t4_24" class="t s2_24">
          үүнээс боловсролын сургалтын байгууллагад багшаар зургаагаас доошгүй
          жил,{" "}
        </span>
        <span id="t5_24" class="t s2_24">
          тэнхимийн эрхлэгч, албаны дарга, сургалтын менежер, нийгмийн ажилтан,{" "}
        </span>
        <span id="t6_24" class="t s2_24">
          арга зүйчээр дөрвөөс доошгүй жил ажилласан, мэргэжлийн тэргүүлэх болон{" "}
        </span>
        <span id="t7_24" class="t s2_24">
          түүнээс дээш зэрэгтэй байх.{" "}
        </span>
        <span id="t8_24" class="t s3_24">
          Шигтгээ 2: Захирлын хариулах асуулт{" "}
        </span>
        <span id="t9_24" class="t s4_24">
          Сургуулийн захирал өөрийн хийх ёстой гол гурван ажлаа хэр хэмжээнд{" "}
        </span>
        <span id="ta_24" class="t s4_24">
          гүйцэтгэдгийг дараах асуултад хариулах байдлаар сонжоод үзээрэй.{" "}
        </span>
        <span id="tb_24" class="t s5_24">
          1){" "}
        </span>
        <span id="tc_24" class="t s4_24">
          Мониторинг, үнэлгээ, хяналт{" "}
        </span>
        <span id="td_24" class="t s4_24">
          -{" "}
        </span>
        <span id="te_24" class="t s4_24">
          Сургууль дотор болж буй үйл явдлыг хэр сайн хянаж, үүнийг тасралтгүй{" "}
        </span>
        <span id="tf_24" class="t s4_24">
          сайжруулахад ашигладаг вэ?{" "}
        </span>
        <span id="tg_24" class="t s4_24">
          - Танай сургуулийн алсын хараа, зорилго, зорилт хэр биелэж байна вэ?{" "}
        </span>
        <span id="th_24" class="t s4_24">
          - Багшийг хөгжүүлэх чиглэлээр хийж буй ажил тань сурагчдын сурлагын{" "}
        </span>
        <span id="ti_24" class="t s4_24">
          амжилтад нөлөөлж байна уу?{" "}
        </span>
        <span id="tj_24" class="t s5_24">
          2){" "}
        </span>
        <span id="tk_24" class="t s4_24">
          Зорилго, зорилт{" "}
        </span>
        <span id="tl_24" class="t s4_24">
          - Зөв зорилт тавьж, дагаж мөрддөг үү? Хэрэв зорилт, үр дүн хоёр{" "}
        </span>
        <span id="tm_24" class="t s4_24">
          зөрчилтэй байвал зохих арга хэмжээ авах уу?{" "}
        </span>
        <span id="tn_24" class="t s4_24">
          - Танай сургуулийн зорилго, зорилт сурагчийн хүлээж буй үр дүнтэй{" "}
        </span>
        <span id="to_24" class="t s4_24">
          нийцэж байна уу?{" "}
        </span>
        <span id="tp_24" class="t s4_24">
          - Сургуулийн зорилго, зорилтоо тодорхойлохдоо сурагчийн хүсэж буй{" "}
        </span>
        <span id="tq_24" class="t s4_24">
          зүйл, багшийн ажилд{" "}
        </span>
        <span id="tr_24" class="t s4_24">
          болон сургуулийн үйл ажиллагаанд гарч буй өөрчлөлт, ирээдүйн чиг{" "}
        </span>
        <span id="ts_24" class="t s4_24">
          хандлагыг судалсан уу?{" "}
        </span>
        <span id="tt_24" class="t s4_24">
          - Сургуулийнхаа зорилго, зорилтыг сурагч, багш, ажилтан, эцэг, эх,{" "}
        </span>
        <span id="tu_24" class="t s4_24">
          асран хамгаалагчид мэдэх үү?{" "}
        </span>
        <span id="tv_24" class="t s4_24">
          г.м{" "}
        </span>
        <span id="tw_24" class="t s5_24">
          3){" "}
        </span>
        <span id="tx_24" class="t s4_24">
          Дэмжлэг, урамшуулал{" "}
        </span>
        <span id="ty_24" class="t s4_24">
          - Сургуулийн хамт олон (сурагч, багш, ажилтан) болон хамтрагчдаа{" "}
        </span>
        <span id="tz_24" class="t s4_24">
          хэрхэн урамшуулдаг вэ?{" "}
        </span>
        <span id="t10_24" class="t s4_24">
          - Та ойрын үед хэнийг, хэрхэн урамшуулсан бэ?{" "}
        </span>
        <span id="t11_24" class="t s4_24">
          - Танай сургуульд сурагч болон бусад хэн нэгэнд үзүүлэх дэмжлэг{" "}
        </span>
        <span id="t12_24" class="t s4_24">
          тодорхой байдаг уу? Хэн, хаанаас дэмжлэг авахаа мэддэг үү?{" "}
        </span>
        <span id="t13_24" class="t s4_24">
          - Дэмжлэг үзүүлэх арга зам, энэ чиглэлээр үүрэг хариуцлага хүлээх{" "}
        </span>
        <span id="t14_24" class="t s4_24">
          хүмүүс тодорхой юу?{" "}
        </span>
      </div>
    </div>
  );
}
