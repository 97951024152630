import React from "react";
export default function NinetyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_99{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_99{left:87px;bottom:939px;letter-spacing:0.11px;word-spacing:0.07px;}
#t3_99{left:87px;bottom:899px;letter-spacing:-0.17px;word-spacing:7.01px;}
#t4_99{left:87px;bottom:879px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t5_99{left:87px;bottom:846px;letter-spacing:-0.12px;word-spacing:-0.39px;}
#t6_99{left:159px;bottom:846px;letter-spacing:-0.19px;word-spacing:-0.32px;}
#t7_99{left:87px;bottom:826px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_99{left:87px;bottom:793px;letter-spacing:-0.12px;word-spacing:0.3px;}
#t9_99{left:161px;bottom:793px;letter-spacing:-0.17px;word-spacing:0.36px;}
#ta_99{left:87px;bottom:773px;letter-spacing:-0.23px;word-spacing:11.22px;}
#tb_99{left:87px;bottom:753px;letter-spacing:-0.14px;}
#tc_99{left:87px;bottom:719px;letter-spacing:-0.12px;word-spacing:0.08px;}
#td_99{left:160px;bottom:719px;letter-spacing:-0.15px;word-spacing:0.12px;}
#te_99{left:87px;bottom:699px;letter-spacing:-0.13px;word-spacing:-0.7px;}
#tf_99{left:87px;bottom:679px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tg_99{left:113px;bottom:659px;letter-spacing:-0.13px;word-spacing:5.18px;}
#th_99{left:134px;bottom:639px;letter-spacing:-0.14px;word-spacing:2.43px;}
#ti_99{left:134px;bottom:619px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tj_99{left:113px;bottom:598px;letter-spacing:-0.19px;word-spacing:5.03px;}
#tk_99{left:134px;bottom:578px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tl_99{left:113px;bottom:558px;letter-spacing:-0.15px;word-spacing:6.36px;}
#tm_99{left:134px;bottom:538px;letter-spacing:-0.18px;word-spacing:-0.15px;}
#tn_99{left:134px;bottom:518px;letter-spacing:-0.17px;word-spacing:0.08px;}
#to_99{left:87px;bottom:485px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tp_99{left:592px;bottom:485px;}
#tq_99{left:113px;bottom:461px;}
#tr_99{left:139px;bottom:460px;letter-spacing:-0.14px;word-spacing:0.67px;}
#ts_99{left:139px;bottom:440px;letter-spacing:-0.14px;word-spacing:3.79px;}
#tt_99{left:139px;bottom:420px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tu_99{left:113px;bottom:396px;}
#tv_99{left:139px;bottom:395px;letter-spacing:-0.16px;word-spacing:0.38px;}
#tw_99{left:139px;bottom:375px;letter-spacing:-0.16px;word-spacing:0.05px;}
#tx_99{left:113px;bottom:351px;}
#ty_99{left:139px;bottom:351px;letter-spacing:-0.17px;word-spacing:4.04px;}
#tz_99{left:139px;bottom:330px;letter-spacing:-0.22px;word-spacing:2.56px;}
#t10_99{left:139px;bottom:310px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t11_99{left:113px;bottom:286px;}
#t12_99{left:139px;bottom:286px;letter-spacing:-0.15px;word-spacing:2.82px;}
#t13_99{left:139px;bottom:266px;letter-spacing:-0.19px;word-spacing:6.17px;}
#t14_99{left:139px;bottom:245px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t15_99{left:113px;bottom:222px;}
#t16_99{left:139px;bottom:221px;letter-spacing:-0.18px;}
#t17_99{left:246px;bottom:221px;letter-spacing:-0.12px;}
#t18_99{left:350px;bottom:221px;letter-spacing:-0.15px;}
#t19_99{left:453px;bottom:221px;letter-spacing:-0.16px;}
#t1a_99{left:535px;bottom:221px;letter-spacing:-0.12px;}
#t1b_99{left:623px;bottom:221px;letter-spacing:-0.12px;}
#t1c_99{left:139px;bottom:201px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t1d_99{left:113px;bottom:177px;}
#t1e_99{left:139px;bottom:176px;letter-spacing:-0.14px;word-spacing:-0.68px;}
#t1f_99{left:139px;bottom:152px;}
#t1g_99{left:162px;bottom:152px;letter-spacing:-0.21px;word-spacing:0.11px;}
#t1h_99{left:139px;bottom:132px;}
#t1i_99{left:165px;bottom:132px;letter-spacing:-0.13px;word-spacing:0.03px;}

.s0_99{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_99{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_99{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_99{font-size:17px;font-family:MogulArial_16s;color:#F7941E;}
.s4_99{font-size:17px;font-family:MogulArial-Italic_17h;color:#F00;}
.s5_99{font-size:15px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts99" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg99" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="99/99.svg"
          type="image/svg+xml"
          id="pdf99"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_99" class="t s0_99">
          99{" "}
        </span>
        <span id="t2_99" class="t s1_99">
          Агуулгын залгамж холбоог хэрхэн хийх вэ?{" "}
        </span>
        <span id="t3_99" class="t s2_99">
          Судлагдахууны агуулгын залгамж холбооны матриц гаргахдаа дараах{" "}
        </span>
        <span id="t4_99" class="t s2_99">
          алхмаар хийхийг санал болгож байна.{" "}
        </span>
        <span id="t5_99" class="t s3_99">
          Алхам 1.{" "}
        </span>
        <span id="t6_99" class="t s2_99">
          Тухайн нэг судлагдахууны агуулгын давхардал, давтагдах байдал,{" "}
        </span>
        <span id="t7_99" class="t s2_99">
          өргөсөл, гүнзгийрлийг ялгана.{" "}
        </span>
        <span id="t8_99" class="t s3_99">
          Алхам 2.{" "}
        </span>
        <span id="t9_99" class="t s2_99">
          Орон нутгийн онцлог, орчин, хэрэглэгдэхүүнээ урьдчилан тооцож,{" "}
        </span>
        <span id="ta_99" class="t s2_99">
          ажиглалт, туршилт, танхимаас гадуурх сургалтын хугацаа, улирлыг{" "}
        </span>
        <span id="tb_99" class="t s2_99">
          төлөвлөнө.{" "}
        </span>
        <span id="tc_99" class="t s3_99">
          Алхам 3.{" "}
        </span>
        <span id="td_99" class="t s2_99">
          Агуулга, арга зүйн нэгдлийг харж хичээл, үйл ажиллагааг хамтран{" "}
        </span>
        <span id="te_99" class="t s2_99">
          төлөвлөнө. Агуулгын залгамж холбооны матрицыг хийхдээ дараах зүйлсийг{" "}
        </span>
        <span id="tf_99" class="t s2_99">
          анхаараарай. Үүнд:{" "}
        </span>
        <span id="tg_99" class="t s2_99">
          a) Харагдахуйц байдлыг тооцон ай (хамрах хүрээ) тус бүрийг нэг{" "}
        </span>
        <span id="th_99" class="t s2_99">
          нүүрэнд багтааж, мөрийн дагуу судлах анги, баганын дагуу улирал{" "}
        </span>
        <span id="ti_99" class="t s2_99">
          болон сараар агуулгуудыг ангилна.{" "}
        </span>
        <span id="tj_99" class="t s2_99">
          б) Агуулга тус бүрийг Text Box-д аль болох энгийн, товч, тодорхой{" "}
        </span>
        <span id="tk_99" class="t s2_99">
          байхаар томьёолж бичнэ.{" "}
        </span>
        <span id="tl_99" class="t s2_99">
          в) Онцгой хамаарал бүхий агуулгуудыг сумаар холбоно. Холбосон{" "}
        </span>
        <span id="tm_99" class="t s2_99">
          агуулга давтагдаж байгаа бол ногооноор, өргөсөж байгаа бол хөхөөр,{" "}
        </span>
        <span id="tn_99" class="t s2_99">
          гүнзгийрч байгаа бол улаанаар текстийг будна гэх мэт{" "}
        </span>
        <span id="to_99" class="t s2_99">
          Агуулгын залгамж холбоог гаргаснаар дараах үр дүн гарна. Үүнд
        </span>
        <span id="tp_99" class="t s4_99">
          :{" "}
        </span>
        <span id="tq_99" class="t s5_99">
          •{" "}
        </span>
        <span id="tr_99" class="t s2_99">
          Хичээл хоорондын залгамж холбоог гаргаснаар тухайн агуулгыг аль{" "}
        </span>
        <span id="ts_99" class="t s2_99">
          хичээлээр хэн, хэрхэн эхэлж орох, хэрхэн үргэлжлүүлэх, хамтран{" "}
        </span>
        <span id="tt_99" class="t s2_99">
          заах боломжоо олж харна.{" "}
        </span>
        <span id="tu_99" class="t s5_99">
          •{" "}
        </span>
        <span id="tv_99" class="t s2_99">
          Агуулгын давхардал, давтагдах байдал, өргөсөл, гүнзгийрлийг ялгах{" "}
        </span>
        <span id="tw_99" class="t s2_99">
          боломжтой болно.{" "}
        </span>
        <span id="tx_99" class="t s5_99">
          •{" "}
        </span>
        <span id="ty_99" class="t s2_99">
          Орон нутгийн онцлог, орчин, хэрэглэгдэхүүнээ урьдчилан тооцож,{" "}
        </span>
        <span id="tz_99" class="t s2_99">
          ажиглалт, туршилт, танхимаас гадуурх сургалтын хугацаа, улирлыг{" "}
        </span>
        <span id="t10_99" class="t s2_99">
          төлөвлөх боломжтой болно.{" "}
        </span>
        <span id="t11_99" class="t s5_99">
          •{" "}
        </span>
        <span id="t12_99" class="t s2_99">
          Агуулга, арга зүйн нэгдлийг харж хичээл, үйл ажиллагааг хамтран{" "}
        </span>
        <span id="t13_99" class="t s2_99">
          төлөвлөснөөр багш нар нэгдсэн ойлголт, нэг үг хэллэгээр ярих{" "}
        </span>
        <span id="t14_99" class="t s2_99">
          боломжтой болж, сурагчдын ачаалал буурна.{" "}
        </span>
        <span id="t15_99" class="t s5_99">
          •{" "}
        </span>
        <span id="t16_99" class="t s2_99">
          Сургалтаар{" "}
        </span>
        <span id="t17_99" class="t s2_99">
          дамжуулан{" "}
        </span>
        <span id="t18_99" class="t s2_99">
          сургуулийн{" "}
        </span>
        <span id="t19_99" class="t s2_99">
          зорилго,{" "}
        </span>
        <span id="t1a_99" class="t s2_99">
          зорилтыг{" "}
        </span>
        <span id="t1b_99" class="t s2_99">
          хэрхэн{" "}
        </span>
        <span id="t1c_99" class="t s2_99">
          хэрэгжүүлэхээ хамтран төлөвлөнө.{" "}
        </span>
        <span id="t1d_99" class="t s5_99">
          •{" "}
        </span>
        <span id="t1e_99" class="t s2_99">
          Үндэсний хөтөлбөрийг судалж агуулгын залгамж холбоог гаргаснаар:{" "}
        </span>
        <span id="t1f_99" class="t s2_99">
          -{" "}
        </span>
        <span id="t1g_99" class="t s2_99">
          Улирлын онцлогт тохируулах{" "}
        </span>
        <span id="t1h_99" class="t s2_99">
          -{" "}
        </span>
        <span id="t1i_99" class="t s2_99">
          Төсвийн нөхцөл байдлыг харгалзаж үзэх{" "}
        </span>
      </div>
    </div>
  );
}
