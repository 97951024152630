import React from "react";
export default function F144() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_144{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_144{left:106px;bottom:923px;letter-spacing:0.1px;word-spacing:1.94px;}
#t3_144{left:106px;bottom:905px;letter-spacing:0.11px;word-spacing:1.8px;}
#t4_144{left:106px;bottom:887px;letter-spacing:0.12px;word-spacing:4.08px;}
#t5_144{left:106px;bottom:868px;letter-spacing:0.13px;word-spacing:2.91px;}
#t6_144{left:106px;bottom:850px;letter-spacing:0.13px;word-spacing:0.01px;}
#t7_144{left:122px;bottom:823px;letter-spacing:0.07px;}
#t8_144{left:150px;bottom:823px;letter-spacing:0.1px;word-spacing:2.31px;}
#t9_144{left:150px;bottom:805px;letter-spacing:0.08px;}
#ta_144{left:122px;bottom:786px;letter-spacing:0.07px;}
#tb_144{left:150px;bottom:786px;letter-spacing:0.09px;word-spacing:-1.06px;}
#tc_144{left:150px;bottom:768px;letter-spacing:0.11px;word-spacing:0.03px;}
#td_144{left:122px;bottom:737px;letter-spacing:0.07px;}
#te_144{left:150px;bottom:737px;letter-spacing:0.11px;word-spacing:5.14px;}
#tf_144{left:150px;bottom:719px;letter-spacing:0.12px;word-spacing:10.63px;}
#tg_144{left:150px;bottom:701px;letter-spacing:0.11px;word-spacing:0.11px;}
#th_144{left:150px;bottom:682px;letter-spacing:0.11px;word-spacing:7.27px;}
#ti_144{left:150px;bottom:664px;letter-spacing:0.13px;word-spacing:0.65px;}
#tj_144{left:150px;bottom:646px;letter-spacing:0.11px;word-spacing:0.63px;}
#tk_144{left:150px;bottom:627px;letter-spacing:0.11px;word-spacing:0.04px;}
#tl_144{left:106px;bottom:600px;letter-spacing:0.11px;word-spacing:0.18px;}
#tm_144{left:106px;bottom:582px;letter-spacing:0.1px;word-spacing:1.08px;}
#tn_144{left:106px;bottom:564px;letter-spacing:0.13px;word-spacing:0.02px;}
#to_144{left:201px;bottom:539px;letter-spacing:-0.15px;word-spacing:0.01px;}
#tp_144{left:366px;bottom:523px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tq_144{left:106px;bottom:426px;letter-spacing:0.14px;word-spacing:7.13px;}
#tr_144{left:106px;bottom:407px;letter-spacing:0.13px;word-spacing:5.18px;}
#ts_144{left:106px;bottom:389px;letter-spacing:0.09px;word-spacing:0.05px;}
#tt_144{left:106px;bottom:362px;letter-spacing:0.08px;word-spacing:9.86px;}
#tu_144{left:124px;bottom:344px;letter-spacing:0.08px;}
#tv_144{left:106px;bottom:325px;}
#tw_144{left:124px;bottom:325px;letter-spacing:0.09px;word-spacing:0.61px;}
#tx_144{left:124px;bottom:307px;letter-spacing:0.12px;word-spacing:3.1px;}
#ty_144{left:124px;bottom:289px;letter-spacing:0.1px;word-spacing:0.03px;}
#tz_144{left:106px;bottom:270px;letter-spacing:0.08px;word-spacing:3.19px;}
#t10_144{left:124px;bottom:252px;letter-spacing:0.11px;word-spacing:0.02px;}
#t11_144{left:106px;bottom:234px;}
#t12_144{left:124px;bottom:234px;letter-spacing:0.12px;word-spacing:0.03px;}
#t13_144{left:106px;bottom:207px;letter-spacing:0.04px;word-spacing:-0.21px;}
#t14_144{left:106px;bottom:188px;letter-spacing:0.1px;word-spacing:-0.78px;}
#t15_144{left:106px;bottom:170px;letter-spacing:0.1px;word-spacing:0.4px;}
#t16_144{left:106px;bottom:152px;letter-spacing:0.11px;word-spacing:0.03px;}

.s0_144{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_144{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s2_144{font-size:15px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_144{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts144" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg144" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="144/144.svg"
          type="image/svg+xml"
          id="pdf144"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_144" class="t s0_144">
          144{" "}
        </span>
        <span id="t2_144" class="t s1_144">
          “Ментор багш” хөтөлбөр БЕГ-ын цахим хуудсаар зарлагдсаныг олж мэдээд{" "}
        </span>
        <span id="t3_144" class="t s1_144">
          хамрагдах хүсэлтээ өгөгдсөн асуултын дагуу цахимаар илэрхийлсэн. Үүний{" "}
        </span>
        <span id="t4_144" class="t s1_144">
          дараа багшийн хөгжлийн талаар эсээ бичсэнээр уг хөтөлбөрт хамрагдах{" "}
        </span>
        <span id="t5_144" class="t s1_144">
          багшийн шалгуурт тэнцэж 2022-2023 оны хичээлийн 9 сарын турш ажлын{" "}
        </span>
        <span id="t6_144" class="t s1_144">
          байран дээрээсээ суралцан хөгжиж байна.{" "}
        </span>
        <span id="t7_144" class="t s2_144">
          1.{" "}
        </span>
        <span id="t8_144" class="t s1_144">
          Чанартай арга зүйн зарчимуудыг судалж ажилдаа тусган хэрэгжүүлж{" "}
        </span>
        <span id="t9_144" class="t s1_144">
          эхэлсэн.{" "}
        </span>
        <span id="ta_144" class="t s2_144">
          2.{" "}
        </span>
        <span id="tb_144" class="t s1_144">
          Энэхүү хөтөлбөрт хамрагдаж байгаа нэг ангийн Зөвлөх багш нарын заах{" "}
        </span>
        <span id="tc_144" class="t s1_144">
          арга зүй, бодит кейсүүдийг хэрхэн шийдвэрлэснээс суралцаж байна.{" "}
        </span>
        <span id="td_144" class="t s2_144">
          3.{" "}
        </span>
        <span id="te_144" class="t s1_144">
          Ээлжит хичээл бүрдээ суралцахуйд суурилсан сургалтын аргуудыг{" "}
        </span>
        <span id="tf_144" class="t s1_144">
          хэрэглэх, сэдэлжүүлэх бодлогыг хэрэглээнд суурилах, нээлттэй{" "}
        </span>
        <span id="tg_144" class="t s1_144">
          асуултаар асуудлын учир шалтгааныг илрүүлэн сурагчдын эргэцүүлэн,{" "}
        </span>
        <span id="th_144" class="t s1_144">
          бүтээлчээр сэтгэн бодох чадварыг хөгжүүлэхийн зэрэгцээ багаар{" "}
        </span>
        <span id="ti_144" class="t s1_144">
          хамтран ажиллах, өөрийгөө илэрхийлэх чадварыг хөгжүүлэхийн төлөө{" "}
        </span>
        <span id="tj_144" class="t s1_144">
          эцэг эх, асран хамгаалагч, харгалзан дэмжигч олон нийтийг сургалтын{" "}
        </span>
        <span id="tk_144" class="t s1_144">
          үйл ажиллагаанд оролцуулж хамтран ажиллаж байна.{" "}
        </span>
        <span id="tl_144" class="t s1_144">
          Боловсролын ерөнхий газраас зарлаж буй багшийн хөгжлийг дэмжсэн төсөл,{" "}
        </span>
        <span id="tm_144" class="t s1_144">
          хөтөлбөрийн талаарх мэдээллийг цаг алдалгүй авч хамрагдан хөгжих бүрэн{" "}
        </span>
        <span id="tn_144" class="t s1_144">
          боломжтой. Багшийн хөгжил надаас шалтгаална.{" "}
        </span>
        <span id="to_144" class="t s3_144">
          2022-2023 оны хичээлийн жилд Ментор багш хөтөлбөрт хамрагдан{" "}
        </span>
        <span id="tp_144" class="t s3_144">
          өөрийгөө хөгжүүлж буй багшийн сэтгэгдэл{" "}
        </span>
        <span id="tq_144" class="t s1_144">
          Багш өөрийн мэргэжил, арга зүйгээ хөгжүүлэх нэг боломж нь хөгжих{" "}
        </span>
        <span id="tr_144" class="t s1_144">
          хэрэгцээндээ үндэслэн сонгон суралцдаг “Модуль”сургалт бөгөөд энэхүү{" "}
        </span>
        <span id="ts_144" class="t s1_144">
          сургалтад хамрагдсан нэгэн багшийн жишээг танилцуулъя.{" "}
        </span>
        <span id="tt_144" class="t s1_144">
          • Сургалтын модуль зарлагдмагц сургалт бүрийн товч танилцуулгыг{" "}
        </span>
        <span id="tu_144" class="t s1_144">
          судалсан.{" "}
        </span>
        <span id="tv_144" class="t s1_144">
          •{" "}
        </span>
        <span id="tw_144" class="t s1_144">
          Математикийн тооцоололд суралцуулах хялбар аргуудыг эзэмших, хичээл{" "}
        </span>
        <span id="tx_144" class="t s1_144">
          төлөвлөлтөд тусган хэрэгжүүлэх хэрэгцээ надад үүссэн тул энэ модуль{" "}
        </span>
        <span id="ty_144" class="t s1_144">
          сургалтыг сонгон бүртгүүлсэн.{" "}
        </span>
        <span id="tz_144" class="t s1_144">
          • Medle.mn цахим хаягаар сургалтын бүртгэл баталгаажсан, сургалт хэзээ{" "}
        </span>
        <span id="t10_144" class="t s1_144">
          эхлэх, хэрхэн суралцах тухай цахим шуудан ирсэн.{" "}
        </span>
        <span id="t11_144" class="t s1_144">
          •{" "}
        </span>
        <span id="t12_144" class="t s1_144">
          АPLUS цахим сургалтын платформд суралцсан.{" "}
        </span>
        <span id="t13_144" class="t s1_144">
          Сургалтаар “Тоо тоолол” айн агуулга, арга зүй, арав, зуу, мянга, олон
          оронтой{" "}
        </span>
        <span id="t14_144" class="t s1_144">
          тооны дугаарлалыг таниулах, 10, 100-ийн дотор нэмэх, хасах үйлдлийг
          энгийн{" "}
        </span>
        <span id="t15_144" class="t s1_144">
          хялбар аргаар таниулах агуулгыг сонгон суралцсанаар миний заах арга
          зүйд{" "}
        </span>
        <span id="t16_144" class="t s1_144">
          дараах өөрчлөлт гарсан.{" "}
        </span>
      </div>
    </div>
  );
}
