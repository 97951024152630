import React from "react";
export default function OneHundredNinetyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_191{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_191{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:7.33px;}
#t3_191{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:1.74px;}
#t4_191{left:87px;bottom:901px;letter-spacing:-0.2px;word-spacing:-0.96px;}
#t5_191{left:532px;bottom:901px;letter-spacing:-0.15px;word-spacing:-1px;}
#t6_191{left:87px;bottom:880px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t7_191{left:113px;bottom:860px;}
#t8_191{left:139px;bottom:860px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t9_191{left:113px;bottom:840px;}
#ta_191{left:139px;bottom:840px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_191{left:113px;bottom:820px;}
#tc_191{left:139px;bottom:820px;letter-spacing:-0.1px;}
#td_191{left:113px;bottom:800px;}
#te_191{left:139px;bottom:800px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_191{left:87px;bottom:759px;letter-spacing:-0.13px;word-spacing:1.06px;}
#tg_191{left:87px;bottom:739px;letter-spacing:-0.15px;word-spacing:7.1px;}
#th_191{left:87px;bottom:719px;letter-spacing:-0.15px;word-spacing:7.84px;}
#ti_191{left:87px;bottom:699px;letter-spacing:-0.14px;word-spacing:3.82px;}
#tj_191{left:87px;bottom:679px;letter-spacing:-0.18px;word-spacing:0.09px;}
#tk_191{left:87px;bottom:646px;letter-spacing:-0.11px;word-spacing:-0.52px;}
#tl_191{left:87px;bottom:625px;letter-spacing:-0.13px;word-spacing:3.18px;}
#tm_191{left:87px;bottom:605px;letter-spacing:-0.14px;word-spacing:4.74px;}
#tn_191{left:87px;bottom:585px;letter-spacing:-0.13px;word-spacing:0.03px;}
#to_191{left:87px;bottom:552px;letter-spacing:-0.14px;word-spacing:0.2px;}
#tp_191{left:87px;bottom:532px;letter-spacing:-0.12px;word-spacing:1.96px;}
#tq_191{left:87px;bottom:512px;letter-spacing:-0.14px;word-spacing:2.05px;}
#tr_191{left:87px;bottom:491px;letter-spacing:-0.13px;word-spacing:1.41px;}
#ts_191{left:87px;bottom:471px;letter-spacing:-0.15px;word-spacing:4.26px;}
#tt_191{left:87px;bottom:451px;letter-spacing:-0.29px;}
#tu_191{left:142px;bottom:380px;}
#tv_191{left:143px;bottom:352px;}
#tw_191{left:169px;bottom:352px;letter-spacing:-0.14px;word-spacing:0.61px;}
#tx_191{left:169px;bottom:331px;letter-spacing:-0.2px;word-spacing:-0.56px;}
#ty_191{left:169px;bottom:311px;letter-spacing:-0.12px;word-spacing:-0.7px;}
#tz_191{left:539px;bottom:311px;letter-spacing:-0.13px;word-spacing:-0.68px;}
#t10_191{left:169px;bottom:291px;letter-spacing:-0.13px;word-spacing:3.53px;}
#t11_191{left:169px;bottom:271px;letter-spacing:-0.17px;word-spacing:-0.81px;}
#t12_191{left:169px;bottom:251px;letter-spacing:-0.15px;word-spacing:1.29px;}
#t13_191{left:169px;bottom:231px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t14_191{left:322px;bottom:231px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t15_191{left:143px;bottom:202px;}
#t16_191{left:169px;bottom:202px;letter-spacing:-0.15px;word-spacing:9.5px;}
#t17_191{left:169px;bottom:182px;letter-spacing:-0.15px;word-spacing:-1.43px;}
#t18_191{left:169px;bottom:161px;letter-spacing:-0.14px;word-spacing:5.98px;}
#t19_191{left:169px;bottom:141px;letter-spacing:-0.2px;word-spacing:0.1px;}

.s0_191{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_191{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_191{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_191{font-size:17px;font-family:MogulArial_18b;color:#000;}`}
      </style>

      <style id="fonts191" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg191" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="191/191.svg"
          type="image/svg+xml"
          id="pdf191"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_191" class="t s0_191">
          191{" "}
        </span>
        <span id="t2_191" class="t s1_191">
          Ерөнхий боловсролын сургуулийн түншлэлийг сургуулийн дотоод үйл{" "}
        </span>
        <span id="t3_191" class="t s1_191">
          ажиллагаа дахь хамтын ажиллагаа, түншлэл, гадаад үйл ажиллагаа дахь{" "}
        </span>
        <span id="t4_191" class="t s1_191">
          хамтын ажиллагаа түншлэлээр тодорхойлдог. Энэ удаад{" "}
        </span>
        <span id="t5_191" class="t s1_191">
          сургуулийн гадаад{" "}
        </span>
        <span id="t6_191" class="t s1_191">
          үйл ажиллагаа дахь хамтын ажиллагаа, түншлэлийн{" "}
        </span>
        <span id="t7_191" class="t s1_191">
          -{" "}
        </span>
        <span id="t8_191" class="t s1_191">
          Орон нутгийн засаг захиргаа{" "}
        </span>
        <span id="t9_191" class="t s1_191">
          -{" "}
        </span>
        <span id="ta_191" class="t s1_191">
          Төрийн болон хувийн хэвшлийн байгууллага{" "}
        </span>
        <span id="tb_191" class="t s1_191">
          -{" "}
        </span>
        <span id="tc_191" class="t s1_191">
          Олон нийт{" "}
        </span>
        <span id="td_191" class="t s1_191">
          -{" "}
        </span>
        <span id="te_191" class="t s1_191">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчийн хүрээнд авч үзнэ.{" "}
        </span>
        <span id="tf_191" class="t s1_191">
          Боловсрол нь бүх нийтийн үйл хэрэг бөгөөд Монгол улсад салбар дундын{" "}
        </span>
        <span id="tg_191" class="t s1_191">
          хамтын ажиллагаа хангалтгүй байна. Сургууль нь олон талт хамтын{" "}
        </span>
        <span id="th_191" class="t s1_191">
          ажиллагааны түншлэлээр үндэсний, олон улсын байгууллагууд, орон{" "}
        </span>
        <span id="ti_191" class="t s1_191">
          нутгийн төрийн болон хувийн хэвшлийн байгууллагыг татан оролцуулах{" "}
        </span>
        <span id="tj_191" class="t s1_191">
          замаар хамтран ажиллах боломжтой байдаг.{" "}
        </span>
        <span id="tk_191" class="t s1_191">
          Орон нутгийн засаг захиргаа, сургууль нь эхний зөвшилцөлд хүрсний
          дараа{" "}
        </span>
        <span id="tl_191" class="t s1_191">
          зорилгоо хэрэгжүүлэх стратегийг төлөвлөнө. Орон нутгийн музей, театр,{" "}
        </span>
        <span id="tm_191" class="t s1_191">
          номын сан, спорт клубууд нийгэм соёлын байгууллагууд, орон нутгийн{" "}
        </span>
        <span id="tn_191" class="t s1_191">
          бизнес аж ахуйн нэгжтэй хамтран ажиллах зорилгуудыг төлөвлөж болно.{" "}
        </span>
        <span id="to_191" class="t s1_191">
          Сургууль нь боловсрол эзэмшүүлэх, соён гэгээрүүлэх байгууллага тул
          эцэг{" "}
        </span>
        <span id="tp_191" class="t s1_191">
          эх, асран хамгаалагч, харгалзан дэмжигч олон нийттэй түншлэн ажиллах{" "}
        </span>
        <span id="tq_191" class="t s1_191">
          замаар орон нутгийн тулгамдсан асуудлыг шийдвэрлэх боломжтой. Орон{" "}
        </span>
        <span id="tr_191" class="t s1_191">
          нутгийн засаг захиргаа, сургууль, олон нийт багаа зөв бүрдүүлэн
          түншлэх{" "}
        </span>
        <span id="ts_191" class="t s1_191">
          нь үр дүнд чиглэсэн амжилттай түншлэлийг бий болгоход чухал нөлөө{" "}
        </span>
        <span id="tt_191" class="t s1_191">
          үзүүүлдэг.{" "}
        </span>
        <span id="tu_191" class="t s2_191">
          АСУУДАЛ{" "}
        </span>
        <span id="tv_191" class="t s3_191">
          ●{" "}
        </span>
        <span id="tw_191" class="t s1_191">
          Сургуулиас зохион байгуулсан аливаа үйл ажиллагаанд эцэг эх,{" "}
        </span>
        <span id="tx_191" class="t s1_191">
          асран хамгаалагч, оролцоо муу. Хүүхдээ сургуульд хэт даатгадаг,{" "}
        </span>
        <span id="ty_191" class="t s1_191">
          өөрсдийн үүрэг хариуцлагыг ухамсарладаггүй,{" "}
        </span>
        <span id="tz_191" class="t s1_191">
          гэрийн даалгавар{" "}
        </span>
        <span id="t10_191" class="t s1_191">
          хийх боломж, орчин нөхцөлийг нь бүрдүүлж өгдөггүй, хүүхдээ{" "}
        </span>
        <span id="t11_191" class="t s1_191">
          хичээлд явуулдаггүй, үг хэлээрээ багш нарыг доромжилдог, багш,{" "}
        </span>
        <span id="t12_191" class="t s1_191">
          сургуулийн удирдлагуудтай ярилцдаггүй, ойлголцохыг хүсдэггүй{" "}
        </span>
        <span id="t13_191" class="t s1_191">
          сөрөг хандлагатай{" "}
        </span>
        <span id="t14_191" class="t s1_191">
          эцэг эхчүүд цөөнгүй байна.{" "}
        </span>
        <span id="t15_191" class="t s3_191">
          ●{" "}
        </span>
        <span id="t16_191" class="t s1_191">
          Тайлангийн асуудал хэсэгт түншлэл, хамтын ажиллагааны{" "}
        </span>
        <span id="t17_191" class="t s1_191">
          талаар тодорхой дурьдаагүй байгаа бөгөөд түншлэлийн асуудлыг{" "}
        </span>
        <span id="t18_191" class="t s1_191">
          илрүүлж харах нь цаг хугацааны хувьд болоогүй байгаа нь{" "}
        </span>
        <span id="t19_191" class="t s1_191">
          харагдаж байна.{" "}
        </span>
      </div>
    </div>
  );
}
