import React from "react";
export default function SixtyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_69{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_69{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_69{left:87px;bottom:941px;letter-spacing:-0.19px;word-spacing:2.7px;}
#t4_69{left:87px;bottom:921px;letter-spacing:-0.14px;word-spacing:-1.13px;}
#t5_69{left:87px;bottom:901px;letter-spacing:-0.19px;word-spacing:-1.47px;}
#t6_69{left:87px;bottom:880px;letter-spacing:-0.18px;word-spacing:2.14px;}
#t7_69{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:-0.53px;}
#t8_69{left:87px;bottom:840px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t9_69{left:95px;bottom:790px;letter-spacing:0.1px;word-spacing:2.62px;}
#ta_69{left:95px;bottom:772px;letter-spacing:0.11px;word-spacing:4.73px;}
#tb_69{left:95px;bottom:753px;letter-spacing:0.11px;word-spacing:4.69px;}
#tc_69{left:95px;bottom:735px;letter-spacing:0.08px;word-spacing:0.22px;}
#td_69{left:95px;bottom:717px;letter-spacing:0.11px;word-spacing:2.16px;}
#te_69{left:95px;bottom:698px;letter-spacing:0.03px;word-spacing:0.1px;}
#tf_69{left:87px;bottom:650px;letter-spacing:-0.16px;word-spacing:3.32px;}
#tg_69{left:87px;bottom:630px;letter-spacing:-0.08px;}
#th_69{left:87px;bottom:609px;}
#ti_69{left:113px;bottom:609px;letter-spacing:-0.13px;word-spacing:0.41px;}
#tj_69{left:113px;bottom:589px;letter-spacing:-0.15px;word-spacing:3.24px;}
#tk_69{left:113px;bottom:569px;letter-spacing:-0.19px;}
#tl_69{left:87px;bottom:549px;}
#tm_69{left:113px;bottom:549px;letter-spacing:-0.17px;word-spacing:1.31px;}
#tn_69{left:113px;bottom:529px;letter-spacing:-0.13px;word-spacing:4.95px;}
#to_69{left:113px;bottom:509px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tp_69{left:87px;bottom:488px;}
#tq_69{left:113px;bottom:488px;letter-spacing:-0.16px;word-spacing:10.38px;}
#tr_69{left:113px;bottom:468px;letter-spacing:-0.15px;word-spacing:5.54px;}
#ts_69{left:113px;bottom:448px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tt_69{left:87px;bottom:428px;}
#tu_69{left:113px;bottom:428px;letter-spacing:-0.16px;word-spacing:-1.06px;}
#tv_69{left:113px;bottom:408px;letter-spacing:-0.15px;word-spacing:0.19px;}
#tw_69{left:113px;bottom:388px;letter-spacing:-0.14px;word-spacing:7.65px;}
#tx_69{left:113px;bottom:367px;letter-spacing:-0.18px;word-spacing:0.08px;}
#ty_69{left:87px;bottom:347px;}
#tz_69{left:113px;bottom:347px;letter-spacing:-0.49px;word-spacing:2.43px;}
#t10_69{left:113px;bottom:327px;letter-spacing:-0.46px;word-spacing:-2.37px;}
#t11_69{left:113px;bottom:307px;letter-spacing:-0.48px;word-spacing:6.2px;}
#t12_69{left:113px;bottom:287px;letter-spacing:-0.44px;word-spacing:2.1px;}
#t13_69{left:113px;bottom:267px;letter-spacing:-0.49px;word-spacing:0.06px;}
#t14_69{left:87px;bottom:246px;}
#t15_69{left:113px;bottom:246px;letter-spacing:-0.13px;word-spacing:5.93px;}
#t16_69{left:113px;bottom:226px;letter-spacing:-0.16px;word-spacing:10.77px;}
#t17_69{left:113px;bottom:206px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t18_69{left:87px;bottom:186px;}
#t19_69{left:113px;bottom:186px;letter-spacing:-0.15px;word-spacing:0.35px;}
#t1a_69{left:113px;bottom:166px;letter-spacing:-0.12px;word-spacing:0.81px;}
#t1b_69{left:113px;bottom:146px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1c_69{left:87px;bottom:125px;letter-spacing:-0.15px;word-spacing:1.72px;}
#t1d_69{left:391px;bottom:125px;letter-spacing:-0.12px;word-spacing:1.68px;}

.s0_69{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_69{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_69{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_69{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_69{font-size:17px;font-family:MyriadPro-Regular_1al;color:#000;}
.s5_69{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.t.m0_69{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts69" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: MyriadPro-Regular_1al;
	src: url("fonts/MyriadPro-Regular_1al.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg69" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="69/69.svg"
          type="image/svg+xml"
          id="pdf69"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_69" class="t s1_69">
          69{" "}
        </span>
        <span id="t3_69" class="t s2_69">
          Асуудал гэдэг Танд яаж ойлгогдож байна вэ? Сайжруулах шаардлагатай{" "}
        </span>
        <span id="t4_69" class="t s2_69">
          зүйл үү, болохгүй байгаа зүйл үү, магадгүй танай сургуулийн алдаа
          дутагдал{" "}
        </span>
        <span id="t5_69" class="t s2_69">
          уу, сул тал уу. Энэ үг бидэнд ихэвчлэн “сайжруулах шаардлагатай зүйл”
          гэсэн{" "}
        </span>
        <span id="t6_69" class="t s2_69">
          утгаар ойлгодог. Бид ч энэхүү зөвлөмжид энэ утгаар нь авч үзлээ. Харин{" "}
        </span>
        <span id="t7_69" class="t s2_69">
          “тулгамдаж байгаа” гэсэн үгийг цаг хугацааны хувьд тухайн хичээлийн
          жилд{" "}
        </span>
        <span id="t8_69" class="t s2_69">
          хамааруулан авч үзэв.{" "}
        </span>
        <span id="t9_69" class="t s3_69">
          Гүйцэтгэлийн үнэлгээний мэдээллийн сангаас танай сургууль шалгуур
          бүрээр{" "}
        </span>
        <span id="ta_69" class="t s3_69">
          ямар үнэлгээтэй байгаа, хоёр оноор харьцуулахад аль шалгуур дээр ямар{" "}
        </span>
        <span id="tb_69" class="t s3_69">
          өөрчлөлтүүд гарсан зэрэг нь сургуулийн хөгжлийн тулгамдаж буй асуудлыг{" "}
        </span>
        <span id="tc_69" class="t s3_69">
          тодорхойлох эх сурвалж болно. Өөрөөр хэлбэл, гүйцэтгэлийн үнэлгээний
          үр дүн{" "}
        </span>
        <span id="td_69" class="t s3_69">
          нь давуу сул талаа тодорхойлж түүнд үндэслэн багш нар илүү сайн
          багшлах,{" "}
        </span>
        <span id="te_69" class="t s3_69">
          сургуулиуд илүү сайн ажиллахад нь баталгаатай мэдээллээр хангаж өгдөг.{" "}
        </span>
        <span id="tf_69" class="t s2_69">
          Тулгамдаж буй асуудлыг тодорхойлохдоо дараах зүйлсийг анхаараарай.{" "}
        </span>
        <span id="tg_69" class="t s2_69">
          Үүнд:{" "}
        </span>
        <span id="th_69" class="t s4_69">
          »{" "}
        </span>
        <span id="ti_69" class="t s2_69">
          Сургуулийн захирал, сургалтын менежерүүд “БГҮ статистик тайлангийн{" "}
        </span>
        <span id="tj_69" class="t s2_69">
          систем”-д зөв нэвтэрч, мэдээлэлтэй нэг бүрчлэн танилцах чадвартай{" "}
        </span>
        <span id="tk_69" class="t s2_69">
          байх;{" "}
        </span>
        <span id="tl_69" class="t s4_69">
          »{" "}
        </span>
        <span id="tm_69" class="t s2_69">
          Систем дэх гүйцэтгэлийн үнэлгээтэй холбогдох мэдээллийг зөвлөмжид{" "}
        </span>
        <span id="tn_69" class="t s2_69">
          өгсөн зааврын дагуу нягталж, сургуулийн хэмжээний нэгтгэлийг бүх{" "}
        </span>
        <span id="to_69" class="t s2_69">
          боломжит харьцуулалтыг хийх;{" "}
        </span>
        <span id="tp_69" class="t s4_69">
          »{" "}
        </span>
        <span id="tq_69" class="t s2_69">
          Сургуулийн удирдлагууд “БГҮ статистик тайлангийн систем” дэх{" "}
        </span>
        <span id="tr_69" class="t s2_69">
          бүхий л мэдээллийг багш, ажилтнуудад үр дүнгийн бодит байдлыг{" "}
        </span>
        <span id="ts_69" class="t s2_69">
          алдагдуулахгүйгээр танилцуулах;{" "}
        </span>
        <span id="tt_69" class="t s4_69">
          »{" "}
        </span>
        <span id="tu_69" class="t s2_69">
          Өмнөх хичээлийн жилийн зорилго, зорилт, үйл ажиллагааны төлөвлөгөө,{" "}
        </span>
        <span id="tv_69" class="t s2_69">
          биелэлтийн байдлын талаар сургуулийн хамт олон (захирал, сургалтын{" "}
        </span>
        <span id="tw_69" class="t s2_69">
          менежер, нийгмийн ажилтан, багш, сэтгэл зүйч, ажилтнууд) ижил{" "}
        </span>
        <span id="tx_69" class="t s2_69">
          мэдээлэл, нэгдсэн ойлголттой болсон байх;{" "}
        </span>
        <span id="ty_69" class="t s4_69">
          »{" "}
        </span>
        <span id="tz_69" class="t s2_69">
          Сургуульд тухайн хичээлийн жилд тулгамдаж буй асуудлыг тодорхойлох{" "}
        </span>
        <span id="t10_69" class="t s2_69">
          хэлэлцүүлгийг сургуулийн хамт олны гишүүн бүр хүүхдийн хөгжил,
          амжилтын{" "}
        </span>
        <span id="t11_69" class="t s2_69">
          нийтлэг эрх ашигт нэгдэх, өөрийн байр сууриа нээлттэй илэрхийлэх,{" "}
        </span>
        <span id="t12_69" class="t s2_69">
          харилцан бие биенээ сонсох, харилцан биенээ хүлээн зөвшөөрөх, цагаа{" "}
        </span>
        <span id="t13_69" class="t s2_69">
          зохицуулах, идэвхтэй, хариуцлагатай байхаар зохион байгуулах;{" "}
        </span>
        <span id="t14_69" class="t s4_69">
          »{" "}
        </span>
        <span id="t15_69" class="t s2_69">
          Асуудлаа задалж, тухайн зүйл нь танай сургуулийн хувьд үнэхээр{" "}
        </span>
        <span id="t16_69" class="t s2_69">
          асуудал мөн эсэхийг тодорхойлох, асуудал задлах аргачлалтай{" "}
        </span>
        <span id="t17_69" class="t s2_69">
          холбоотой мэдээллийг QR -ийг уншуулан аваарай.{" "}
        </span>
        <span id="t18_69" class="t s4_69">
          »{" "}
        </span>
        <span id="t19_69" class="t s5_69">
          Асуудлаа тулгамдаж буй цаг хугацаагаар нь энэ хичээлийн{" "}
        </span>
        <span id="t1a_69" class="t s5_69">
          жилийн хувьд, ирэх хичээлийн жилийн хувьд, ойрын таван{" "}
        </span>
        <span id="t1b_69" class="t s5_69">
          жилдээ гэх мэтээр эрэмбэлэх,{" "}
        </span>
        <span id="t1c_69" class="t s2_69">
          Сургуулийн гүйцэтгэлийн үнэлгээний{" "}
        </span>
        <span id="t1d_69" class="t s2_69">
          цахим тоон тайлангийн{" "}
        </span>
      </div>
    </div>
  );
}
