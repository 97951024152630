import React from "react";
export default function ThirtyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_32{left:87px;bottom:58px;letter-spacing:-0.1px;}
#t2_32{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_32{left:91px;bottom:867px;letter-spacing:-0.28px;}
#t4_32{left:91px;bottom:849px;letter-spacing:-0.13px;}
#t5_32{left:194px;bottom:936px;letter-spacing:-0.14px;}
#t6_32{left:399px;bottom:933px;}
#t7_32{left:511px;bottom:933px;}
#t8_32{left:618px;bottom:933px;}
#t9_32{left:194px;bottom:910px;letter-spacing:-0.18px;word-spacing:0.04px;}
#ta_32{left:399px;bottom:907px;}
#tb_32{left:511px;bottom:907px;}
#tc_32{left:618px;bottom:907px;}
#td_32{left:194px;bottom:884px;letter-spacing:-0.15px;word-spacing:0.01px;}
#te_32{left:399px;bottom:881px;}
#tf_32{left:511px;bottom:881px;}
#tg_32{left:618px;bottom:881px;}
#th_32{left:194px;bottom:858px;letter-spacing:-0.2px;}
#ti_32{left:399px;bottom:855px;}
#tj_32{left:511px;bottom:855px;}
#tk_32{left:618px;bottom:855px;}
#tl_32{left:194px;bottom:832px;letter-spacing:-0.19px;}
#tm_32{left:399px;bottom:829px;}
#tn_32{left:511px;bottom:829px;}
#to_32{left:618px;bottom:829px;}
#tp_32{left:194px;bottom:806px;letter-spacing:-0.15px;}
#tq_32{left:399px;bottom:803px;}
#tr_32{left:511px;bottom:803px;}
#ts_32{left:618px;bottom:803px;}
#tt_32{left:194px;bottom:780px;letter-spacing:-0.24px;}
#tu_32{left:399px;bottom:777px;}
#tv_32{left:511px;bottom:777px;}
#tw_32{left:618px;bottom:777px;}
#tx_32{left:91px;bottom:645px;letter-spacing:-0.2px;}
#ty_32{left:194px;bottom:754px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tz_32{left:395px;bottom:751px;letter-spacing:-0.14px;}
#t10_32{left:507px;bottom:751px;letter-spacing:-0.14px;}
#t11_32{left:615px;bottom:751px;letter-spacing:-0.14px;}
#t12_32{left:194px;bottom:728px;letter-spacing:-0.19px;}
#t13_32{left:399px;bottom:725px;}
#t14_32{left:511px;bottom:725px;}
#t15_32{left:618px;bottom:725px;}
#t16_32{left:194px;bottom:702px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t17_32{left:399px;bottom:699px;}
#t18_32{left:511px;bottom:699px;}
#t19_32{left:618px;bottom:699px;}
#t1a_32{left:194px;bottom:676px;letter-spacing:-0.26px;word-spacing:0.14px;}
#t1b_32{left:399px;bottom:673px;}
#t1c_32{left:511px;bottom:673px;}
#t1d_32{left:618px;bottom:673px;}
#t1e_32{left:194px;bottom:650px;letter-spacing:-0.13px;}
#t1f_32{left:399px;bottom:647px;}
#t1g_32{left:511px;bottom:647px;}
#t1h_32{left:618px;bottom:647px;}
#t1i_32{left:194px;bottom:624px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1j_32{left:399px;bottom:621px;}
#t1k_32{left:511px;bottom:621px;}
#t1l_32{left:618px;bottom:621px;}
#t1m_32{left:194px;bottom:598px;letter-spacing:-0.22px;}
#t1n_32{left:387px;bottom:595px;letter-spacing:-0.14px;}
#t1o_32{left:499px;bottom:595px;letter-spacing:-0.14px;}
#t1p_32{left:607px;bottom:595px;letter-spacing:-0.14px;}
#t1q_32{left:194px;bottom:572px;letter-spacing:-0.19px;word-spacing:0.05px;}
#t1r_32{left:399px;bottom:569px;}
#t1s_32{left:511px;bottom:569px;}
#t1t_32{left:618px;bottom:569px;}
#t1u_32{left:194px;bottom:550px;letter-spacing:-0.19px;}
#t1v_32{left:194px;bottom:532px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1w_32{left:449px;bottom:532px;}
#t1x_32{left:557px;bottom:532px;}
#t1y_32{left:664px;bottom:532px;}
#t1z_32{left:345px;bottom:496px;letter-spacing:0.09px;word-spacing:0.06px;}
#t20_32{left:87px;bottom:457px;letter-spacing:-0.23px;word-spacing:10.21px;}
#t21_32{left:87px;bottom:436px;letter-spacing:-0.15px;word-spacing:5.72px;}
#t22_32{left:87px;bottom:416px;letter-spacing:-0.17px;word-spacing:7.97px;}
#t23_32{left:87px;bottom:396px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t24_32{left:87px;bottom:356px;letter-spacing:-0.14px;word-spacing:6.13px;}
#t25_32{left:87px;bottom:336px;letter-spacing:-0.14px;}
#t26_32{left:196px;bottom:336px;letter-spacing:-0.15px;word-spacing:10.33px;}
#t27_32{left:87px;bottom:315px;letter-spacing:-0.15px;word-spacing:0.1px;}
#t28_32{left:410px;bottom:315px;letter-spacing:-0.18px;word-spacing:0.13px;}
#t29_32{left:87px;bottom:295px;letter-spacing:-0.14px;word-spacing:-0.32px;}
#t2a_32{left:87px;bottom:275px;letter-spacing:-0.15px;word-spacing:1.59px;}
#t2b_32{left:87px;bottom:255px;letter-spacing:-0.14px;word-spacing:5.76px;}
#t2c_32{left:87px;bottom:235px;letter-spacing:-0.12px;}
#t2d_32{left:220px;bottom:235px;letter-spacing:-0.1px;}
#t2e_32{left:345px;bottom:235px;letter-spacing:-0.17px;}
#t2f_32{left:450px;bottom:235px;letter-spacing:-0.09px;}
#t2g_32{left:506px;bottom:235px;letter-spacing:-0.1px;}
#t2h_32{left:623px;bottom:235px;letter-spacing:-0.12px;}
#t2i_32{left:87px;bottom:215px;letter-spacing:-0.2px;word-spacing:4.6px;}
#t2j_32{left:87px;bottom:194px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t2k_32{left:87px;bottom:154px;letter-spacing:-0.14px;word-spacing:4.53px;}
#t2l_32{left:87px;bottom:134px;letter-spacing:-0.13px;word-spacing:5.36px;}
#t2m_32{left:87px;bottom:114px;letter-spacing:-0.17px;word-spacing:3.34px;}

.s0_32{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_32{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_32{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_32{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s4_32{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_32{font-size:17px;font-family:MogulArial_16s;color:#20BEC6;}`}
      </style>
      <style id="fonts32" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg32" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="32/32.svg"
          type="image/svg+xml"
          id="pdf32"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_32" class="t s1_32">
          32{" "}
        </span>
        <span id="t3_32" class="t s2_32">
          Удирдлагын{" "}
        </span>
        <span id="t4_32" class="t s2_32">
          бүтэц{" "}
        </span>
        <span id="t5_32" class="t s2_32">
          Захирал{" "}
        </span>
        <span id="t6_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t7_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t8_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t9_32" class="t s2_32">
          Сургалтын менежер{" "}
        </span>
        <span id="ta_32" class="t s2_32">
          5{" "}
        </span>
        <span id="tb_32" class="t s2_32">
          3{" "}
        </span>
        <span id="tc_32" class="t s2_32">
          2{" "}
        </span>
        <span id="td_32" class="t s2_32">
          Нийгмийн ажилтан{" "}
        </span>
        <span id="te_32" class="t s2_32">
          3{" "}
        </span>
        <span id="tf_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tg_32" class="t s2_32">
          1{" "}
        </span>
        <span id="th_32" class="t s2_32">
          Сэтгэлзүйч{" "}
        </span>
        <span id="ti_32" class="t s2_32">
          2{" "}
        </span>
        <span id="tj_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tk_32" class="t s2_32">
          0{" "}
        </span>
        <span id="tl_32" class="t s2_32">
          Нягтлан{" "}
        </span>
        <span id="tm_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tn_32" class="t s2_32">
          1{" "}
        </span>
        <span id="to_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tp_32" class="t s2_32">
          Нярав{" "}
        </span>
        <span id="tq_32" class="t s2_32">
          2{" "}
        </span>
        <span id="tr_32" class="t s2_32">
          1{" "}
        </span>
        <span id="ts_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tt_32" class="t s2_32">
          Эмч{" "}
        </span>
        <span id="tu_32" class="t s2_32">
          2{" "}
        </span>
        <span id="tv_32" class="t s2_32">
          1{" "}
        </span>
        <span id="tw_32" class="t s2_32">
          0{" "}
        </span>
        <span id="tx_32" class="t s2_32">
          Орчин{" "}
        </span>
        <span id="ty_32" class="t s2_32">
          Анги танхимын тоо{" "}
        </span>
        <span id="tz_32" class="t s2_32">
          45{" "}
        </span>
        <span id="t10_32" class="t s2_32">
          39{" "}
        </span>
        <span id="t11_32" class="t s2_32">
          17{" "}
        </span>
        <span id="t12_32" class="t s2_32">
          Лаборатори{" "}
        </span>
        <span id="t13_32" class="t s2_32">
          3{" "}
        </span>
        <span id="t14_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t15_32" class="t s2_32">
          0{" "}
        </span>
        <span id="t16_32" class="t s2_32">
          Спорт заал{" "}
        </span>
        <span id="t17_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t18_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t19_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1a_32" class="t s2_32">
          Урлаг заал{" "}
        </span>
        <span id="t1b_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1c_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1d_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1e_32" class="t s2_32">
          Багш нарын өрөө{" "}
        </span>
        <span id="t1f_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1g_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1h_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1i_32" class="t s2_32">
          Цайны газар{" "}
        </span>
        <span id="t1j_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1k_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1l_32" class="t s2_32">
          1{" "}
        </span>
        <span id="t1m_32" class="t s2_32">
          Интернет{" "}
        </span>
        <span id="t1n_32" class="t s2_32">
          тийм{" "}
        </span>
        <span id="t1o_32" class="t s2_32">
          тийм{" "}
        </span>
        <span id="t1p_32" class="t s2_32">
          тийм{" "}
        </span>
        <span id="t1q_32" class="t s2_32">
          Ээлжийн тоо{" "}
        </span>
        <span id="t1r_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1s_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1t_32" class="t s2_32">
          2{" "}
        </span>
        <span id="t1u_32" class="t s2_32">
          Кабинетаар{" "}
        </span>
        <span id="t1v_32" class="t s2_32">
          хичээллэдэг эсэх{" "}
        </span>
        <span id="t1w_32" class="t s2_32">
          0{" "}
        </span>
        <span id="t1x_32" class="t s2_32">
          5{" "}
        </span>
        <span id="t1y_32" class="t s2_32">
          3{" "}
        </span>
        <span id="t1z_32" class="t s3_32">
          Эх сурвалж: Сургуулийн захирлын мэдээллээс{" "}
        </span>
        <span id="t20_32" class="t s4_32">
          Хүснэгтээр харуулсан ялгаанаас гадна сум баг, аймаг, нийслэлийн{" "}
        </span>
        <span id="t21_32" class="t s4_32">
          сургуулиудын сурагчдын хэрэгцээ сонирхол, хүсэл мөрөөдөл, асуудал,{" "}
        </span>
        <span id="t22_32" class="t s4_32">
          асуудлын шалтгаан ч ялгаатай учраас сургуулиудын зорилго зорилт{" "}
        </span>
        <span id="t23_32" class="t s4_32">
          харилцан адилгүй байх нь ойлгомжтой.{" "}
        </span>
        <span id="t24_32" class="t s5_32">
          Гүйцэтгэлийн үнэлгээний хувьд аль кластерт байх, сургуулийн онцлог{" "}
        </span>
        <span id="t25_32" class="t s5_32">
          ялгаанаас{" "}
        </span>
        <span id="t26_32" class="t s5_32">
          үл хамааран менежментээ сайжруулахад сургууль бүр{" "}
        </span>
        <span id="t27_32" class="t s5_32">
          анхаарлаа хандуулах нийтлэг зүйлс бий.{" "}
        </span>
        <span id="t28_32" class="t s4_32">
          Тухайлбал, сурагчдад тулгамдсан{" "}
        </span>
        <span id="t29_32" class="t s4_32">
          суурь асуудлыг хэрхэн илрүүлэх, тэдгээрийг шийдвэрлэх зорилго,
          зорилтоо{" "}
        </span>
        <span id="t2a_32" class="t s4_32">
          хэрхэн тодорхойлох, зорилгоо хэрэгжүүлэх төлөвлөлтийг хэрхэн оновчтой{" "}
        </span>
        <span id="t2b_32" class="t s4_32">
          боловсруулах, энэ үйл явцад хэн, хэрхэн оролцох, хичээлийн жилийн{" "}
        </span>
        <span id="t2c_32" class="t s4_32">
          менежментийн{" "}
        </span>
        <span id="t2d_32" class="t s4_32">
          хэрэгжилтийн{" "}
        </span>
        <span id="t2e_32" class="t s4_32">
          зураглалаа{" "}
        </span>
        <span id="t2f_32" class="t s4_32">
          ямар{" "}
        </span>
        <span id="t2g_32" class="t s4_32">
          дарааллаар,{" "}
        </span>
        <span id="t2h_32" class="t s4_32">
          хэрхэн{" "}
        </span>
        <span id="t2i_32" class="t s4_32">
          боловсруулах талаар сургууль бүр зохих ойлголт, аргачлалтай байх нь{" "}
        </span>
        <span id="t2j_32" class="t s4_32">
          захирал, сургуулийн удирдах ажилтнуудад ач холбогдолтой юм.{" "}
        </span>
        <span id="t2k_32" class="t s4_32">
          Сурагчдад тулгамдсан асуудлыг илрүүлнэ гэдэг нь таамаг төдий, багш{" "}
        </span>
        <span id="t2l_32" class="t s4_32">
          нар нэг өдөр сууж хэлэлцээд шийдчихдэг хялбар үйл явц биш харин{" "}
        </span>
        <span id="t2m_32" class="t s4_32">
          мэдээлэл нотолгоо, үнэлгээний үр дүнд суурилж, бүх талын оролцоотой{" "}
        </span>
      </div>
    </div>
  );
}
