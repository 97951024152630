import React from "react";
export default function Five() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_5{left:676px;bottom:58px;}
#t2_5{left:679px;bottom:59px;}
#t3_5{left:87px;bottom:889px;letter-spacing:-0.09px;}
#t4_5{left:87px;bottom:860px;letter-spacing:-0.21px;word-spacing:0.09px;}
#t5_5{left:479px;bottom:860px;letter-spacing:-0.05px;}
#t6_5{left:656px;bottom:860px;letter-spacing:-0.11px;}
#t7_5{left:104px;bottom:827px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t8_5{left:600px;bottom:827px;letter-spacing:4.57px;}
#t9_5{left:656px;bottom:827px;letter-spacing:-0.11px;}
#ta_5{left:130px;bottom:806px;letter-spacing:-0.09px;}
#tb_5{left:572px;bottom:806px;letter-spacing:4.57px;}
#tc_5{left:656px;bottom:806px;letter-spacing:-0.11px;}
#td_5{left:130px;bottom:786px;letter-spacing:-0.1px;}
#te_5{left:569px;bottom:786px;letter-spacing:-0.09px;}
#tf_5{left:172px;bottom:766px;letter-spacing:-0.1px;}
#tg_5{left:413px;bottom:766px;letter-spacing:-0.05px;}
#th_5{left:656px;bottom:766px;letter-spacing:-0.11px;}
#ti_5{left:130px;bottom:746px;letter-spacing:-0.1px;}
#tj_5{left:172px;bottom:726px;letter-spacing:-0.1px;}
#tk_5{left:516px;bottom:726px;letter-spacing:4.57px;}
#tl_5{left:656px;bottom:726px;letter-spacing:-0.11px;}
#tm_5{left:104px;bottom:693px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tn_5{left:132px;bottom:672px;letter-spacing:-0.09px;}
#to_5{left:301px;bottom:672px;letter-spacing:4.57px;}
#tp_5{left:656px;bottom:672px;letter-spacing:-0.11px;}
#tq_5{left:130px;bottom:652px;letter-spacing:-0.1px;}
#tr_5{left:488px;bottom:652px;letter-spacing:4.57px;}
#ts_5{left:656px;bottom:652px;letter-spacing:-0.11px;}
#tt_5{left:130px;bottom:632px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tu_5{left:535px;bottom:632px;letter-spacing:4.57px;}
#tv_5{left:647px;bottom:632px;letter-spacing:-0.11px;}
#tw_5{left:130px;bottom:612px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tx_5{left:600px;bottom:612px;letter-spacing:4.57px;}
#ty_5{left:647px;bottom:612px;letter-spacing:-0.11px;}
#tz_5{left:130px;bottom:592px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t10_5{left:544px;bottom:592px;letter-spacing:4.57px;}
#t11_5{left:647px;bottom:592px;letter-spacing:-0.11px;}
#t12_5{left:104px;bottom:559px;letter-spacing:-0.09px;}
#t13_5{left:264px;bottom:559px;letter-spacing:4.57px;}
#t14_5{left:647px;bottom:559px;letter-spacing:-0.11px;}
#t15_5{left:130px;bottom:538px;letter-spacing:-0.1px;}
#t16_5{left:525px;bottom:538px;letter-spacing:-0.05px;}
#t17_5{left:647px;bottom:538px;letter-spacing:-0.11px;}
#t18_5{left:130px;bottom:518px;letter-spacing:-0.1px;}
#t19_5{left:572px;bottom:518px;letter-spacing:4.57px;}
#t1a_5{left:647px;bottom:518px;letter-spacing:-0.11px;}
#t1b_5{left:130px;bottom:498px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t1c_5{left:172px;bottom:478px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t1d_5{left:441px;bottom:478px;letter-spacing:4.57px;}
#t1e_5{left:647px;bottom:478px;letter-spacing:-0.11px;}
#t1f_5{left:130px;bottom:458px;letter-spacing:-0.1px;}
#t1g_5{left:172px;bottom:438px;letter-spacing:-0.1px;}
#t1h_5{left:329px;bottom:438px;letter-spacing:-0.05px;}
#t1i_5{left:647px;bottom:438px;letter-spacing:-0.11px;}
#t1j_5{left:104px;bottom:404px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1k_5{left:132px;bottom:384px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1l_5{left:245px;bottom:384px;letter-spacing:-0.05px;}
#t1m_5{left:647px;bottom:384px;letter-spacing:-0.11px;}
#t1n_5{left:104px;bottom:351px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1o_5{left:581px;bottom:351px;letter-spacing:-0.05px;}
#t1p_5{left:647px;bottom:351px;letter-spacing:-0.11px;}
#t1q_5{left:104px;bottom:311px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t1r_5{left:404px;bottom:311px;letter-spacing:-0.05px;}
#t1s_5{left:647px;bottom:311px;letter-spacing:-0.11px;}
#t1t_5{left:130px;bottom:286px;letter-spacing:-0.07px;}
#t1u_5{left:177px;bottom:286px;letter-spacing:-0.1px;}
#t1v_5{left:177px;bottom:266px;letter-spacing:-0.1px;}
#t1w_5{left:329px;bottom:266px;letter-spacing:4.57px;}
#t1x_5{left:647px;bottom:266px;letter-spacing:-0.11px;}
#t1y_5{left:130px;bottom:246px;letter-spacing:-0.07px;}
#t1z_5{left:177px;bottom:246px;letter-spacing:-0.1px;}
#t20_5{left:544px;bottom:246px;letter-spacing:4.57px;}
#t21_5{left:647px;bottom:246px;letter-spacing:-0.11px;}
#t22_5{left:130px;bottom:226px;letter-spacing:-0.07px;}
#t23_5{left:177px;bottom:226px;letter-spacing:-0.1px;}
#t24_5{left:385px;bottom:226px;letter-spacing:4.57px;}
#t25_5{left:647px;bottom:226px;letter-spacing:-0.11px;}
#t26_5{left:130px;bottom:206px;letter-spacing:-0.07px;}
#t27_5{left:177px;bottom:206px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t28_5{left:423px;bottom:206px;letter-spacing:4.57px;}
#t29_5{left:647px;bottom:206px;letter-spacing:-0.11px;}
#t2a_5{left:130px;bottom:186px;letter-spacing:-0.07px;}
#t2b_5{left:176px;bottom:186px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t2c_5{left:404px;bottom:186px;letter-spacing:-0.05px;}
#t2d_5{left:647px;bottom:186px;letter-spacing:-0.11px;}
#t2e_5{left:130px;bottom:165px;letter-spacing:-0.07px;}
#t2f_5{left:176px;bottom:165px;letter-spacing:-0.18px;word-spacing:0.08px;}
#t2g_5{left:176px;bottom:145px;letter-spacing:-0.16px;}
#t2h_5{left:273px;bottom:145px;letter-spacing:4.57px;}
#t2i_5{left:647px;bottom:145px;letter-spacing:-0.11px;}

.s0_5{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_5{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_5{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_5{font-size:17px;font-family:MogulArial_16s;color:#20BEC6;}
.s4_5{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_5{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts5" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg5" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="5/5.svg"
          type="image/svg+xml"
          id="pdf5"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_5" class="t s1_5">
          5{" "}
        </span>
        <span id="t3_5" class="t s2_5">
          III БҮЛЭГ{" "}
        </span>
        <span id="t4_5" class="t s3_5">
          СУРГУУЛИЙН МЕНЕЖМЕНТ, АСУУДАЛ ШИЙДЭЛ
        </span>
        <span id="t5_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t6_5" class="t s4_5">
          71{" "}
        </span>
        <span id="t7_5" class="t s4_5">
          3.1 Суралцагчийн хөгжил, хамгааллыг хэрхэн нэмэгдүүлэх вэ?{" "}
        </span>
        <span id="t8_5" class="t s4_5">
          ......{" "}
        </span>
        <span id="t9_5" class="t s4_5">
          72{" "}
        </span>
        <span id="ta_5" class="t s4_5">
          3.1.1 Хүүхэд хамгаалал, хүүхдийн эрх яагаад чухал вэ?{" "}
        </span>
        <span id="tb_5" class="t s4_5">
          .........{" "}
        </span>
        <span id="tc_5" class="t s4_5">
          77{" "}
        </span>
        <span id="td_5" class="t s4_5">
          3.1.2 Сурагчийн хөгжил, хамгааллын үйл ажиллагааны{" "}
        </span>
        <span id="te_5" class="t s4_5">
          бүтэц,{" "}
        </span>
        <span id="tf_5" class="t s4_5">
          зохион байгуулалт менежмент{" "}
        </span>
        <span id="tg_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="th_5" class="t s4_5">
          87{" "}
        </span>
        <span id="ti_5" class="t s4_5">
          3.1.3 Удирдах ажилтны харилцааны ур чадвар сурагчийн хөгжил,{" "}
        </span>
        <span id="tj_5" class="t s4_5">
          хамгааллын үйл ажиллагаанд нөлөөлөх нь{" "}
        </span>
        <span id="tk_5" class="t s4_5">
          ...............{" "}
        </span>
        <span id="tl_5" class="t s4_5">
          89{" "}
        </span>
        <span id="tm_5" class="t s4_5">
          3.2 Сургалтын хөтөлбөрийн хэрэгжилтэд хэрхэн{" "}
        </span>
        <span id="tn_5" class="t s4_5">
          дэмжлэг үзүүлэх вэ?{" "}
        </span>
        <span id="to_5" class="t s4_5">
          ......................................{" "}
        </span>
        <span id="tp_5" class="t s4_5">
          91{" "}
        </span>
        <span id="tq_5" class="t s4_5">
          3.2.1 Сургалтын хөтөлбөрийг судлах, ойлгох{" "}
        </span>
        <span id="tr_5" class="t s4_5">
          ..................{" "}
        </span>
        <span id="ts_5" class="t s4_5">
          91{" "}
        </span>
        <span id="tt_5" class="t s4_5">
          3.2.2 Сургалт, үйл ажиллагааг хэрхэн төлөвлөх вэ?{" "}
        </span>
        <span id="tu_5" class="t s4_5">
          ............{" "}
        </span>
        <span id="tv_5" class="t s4_5">
          103{" "}
        </span>
        <span id="tw_5" class="t s4_5">
          3.2.3 Суралцахуй ба багшлахуй (сургалтын арга технологи){" "}
        </span>
        <span id="tx_5" class="t s4_5">
          .....{" "}
        </span>
        <span id="ty_5" class="t s4_5">
          111{" "}
        </span>
        <span id="tz_5" class="t s4_5">
          3.2.4 Сургалтын хөтөлбөрийн хэрэгжилтийн үнэлгээ{" "}
        </span>
        <span id="t10_5" class="t s4_5">
          ...........{" "}
        </span>
        <span id="t11_5" class="t s4_5">
          119{" "}
        </span>
        <span id="t12_5" class="t s4_5">
          3.3 Багшийн хөгжил{" "}
        </span>
        <span id="t13_5" class="t s4_5">
          .........................................{" "}
        </span>
        <span id="t14_5" class="t s4_5">
          133{" "}
        </span>
        <span id="t15_5" class="t s4_5">
          3.3.1 Багшийн хөгжлийн хэрэгцээг тодорхойлох нь{" "}
        </span>
        <span id="t16_5" class="t s4_5">
          . . . . . . . . . . . . .{" "}
        </span>
        <span id="t17_5" class="t s4_5">
          133{" "}
        </span>
        <span id="t18_5" class="t s4_5">
          3.3.2 Багшийн хөгжлийн хэрэгцээнд үндэслэн төлөвлөх{" "}
        </span>
        <span id="t19_5" class="t s4_5">
          ........{" "}
        </span>
        <span id="t1a_5" class="t s4_5">
          134{" "}
        </span>
        <span id="t1b_5" class="t s4_5">
          3.3.3 Багшийн манлайлал, хамтын ажиллагаа, соёл,{" "}
        </span>
        <span id="t1c_5" class="t s4_5">
          өөрийн удирдлагатай суралцахуй{" "}
        </span>
        <span id="t1d_5" class="t s4_5">
          ......................{" "}
        </span>
        <span id="t1e_5" class="t s4_5">
          142{" "}
        </span>
        <span id="t1f_5" class="t s4_5">
          3.3.4 Багшийн өөрийн удирдлагатай суралцахуй,{" "}
        </span>
        <span id="t1g_5" class="t s4_5">
          технологийн чадвар
        </span>
        <span id="t1h_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t1i_5" class="t s4_5">
          148{" "}
        </span>
        <span id="t1j_5" class="t s4_5">
          3.4 Сургалтыг зохион байгуулах таатай орчныг хэрхэн{" "}
        </span>
        <span id="t1k_5" class="t s4_5">
          бүрдүүлэх вэ?{" "}
        </span>
        <span id="t1l_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . . . . . . .{" "}
        </span>
        <span id="t1m_5" class="t s4_5">
          155{" "}
        </span>
        <span id="t1n_5" class="t s4_5">
          3.5 Сургуулийн материаллаг нөөцийг бүрдүүлэх аргачлалууд
        </span>
        <span id="t1o_5" class="t s4_5">
          . . . . . . .{" "}
        </span>
        <span id="t1p_5" class="t s4_5">
          164{" "}
        </span>
        <span id="t1q_5" class="t s4_5">
          3.6 Сургуулийн удирдлага, манлайлал
        </span>
        <span id="t1r_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t1s_5" class="t s4_5">
          171{" "}
        </span>
        <span id="t1t_5" class="t s4_5">
          3.6.1{" "}
        </span>
        <span id="t1u_5" class="t s4_5">
          Сургуулийн зорилго, үйл ажиллагааны{" "}
        </span>
        <span id="t1v_5" class="t s4_5">
          нэгдсэн төлөвлөлт{" "}
        </span>
        <span id="t1w_5" class="t s4_5">
          ..................................{" "}
        </span>
        <span id="t1x_5" class="t s4_5">
          171{" "}
        </span>
        <span id="t1y_5" class="t s4_5">
          3.6.2{" "}
        </span>
        <span id="t1z_5" class="t s4_5">
          Сургалт, үйл ажиллагааны зохион байгуулалт{" "}
        </span>
        <span id="t20_5" class="t s4_5">
          ...........{" "}
        </span>
        <span id="t21_5" class="t s4_5">
          180{" "}
        </span>
        <span id="t22_5" class="t s4_5">
          3.6.3{" "}
        </span>
        <span id="t23_5" class="t s4_5">
          Байгууллагын хүний нөөц{" "}
        </span>
        <span id="t24_5" class="t s4_5">
          ............................{" "}
        </span>
        <span id="t25_5" class="t s4_5">
          185{" "}
        </span>
        <span id="t26_5" class="t s4_5">
          3.6.4{" "}
        </span>
        <span id="t27_5" class="t s4_5">
          Сургуулийн төсөв, санхүүжилт{" "}
        </span>
        <span id="t28_5" class="t s4_5">
          ........................{" "}
        </span>
        <span id="t29_5" class="t s4_5">
          190{" "}
        </span>
        <span id="t2a_5" class="t s4_5">
          3.6.5{" "}
        </span>
        <span id="t2b_5" class="t s4_5">
          Хамтын ажиллагаа, түншлэл{" "}
        </span>
        <span id="t2c_5" class="t s4_5">
          . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t2d_5" class="t s4_5">
          190{" "}
        </span>
        <span id="t2e_5" class="t s4_5">
          3.6.6{" "}
        </span>
        <span id="t2f_5" class="t s4_5">
          Сургалт, үйл ажиллагааны хэрэгжилтийг үнэлэх,{" "}
        </span>
        <span id="t2g_5" class="t s4_5">
          сайжруулах{" "}
        </span>
        <span id="t2h_5" class="t s4_5">
          ........................................{" "}
        </span>
        <span id="t2i_5" class="t s4_5">
          193{" "}
        </span>
      </div>
    </div>
  );
}
