import React from "react";
export default function Eighty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_80{left:70px;bottom:59px;letter-spacing:0.16px;}
#t2_80{left:137px;bottom:928px;}
#t3_80{left:162px;bottom:890px;letter-spacing:-0.16px;word-spacing:11.83px;}
#t4_80{left:162px;bottom:870px;letter-spacing:-0.15px;word-spacing:1.99px;}
#t5_80{left:162px;bottom:850px;letter-spacing:-0.21px;word-spacing:0.1px;}
#t6_80{left:139px;bottom:821px;}
#t7_80{left:165px;bottom:821px;letter-spacing:-0.11px;word-spacing:0.02px;}
#t8_80{left:139px;bottom:796px;}
#t9_80{left:165px;bottom:796px;letter-spacing:-0.16px;word-spacing:2.37px;}
#ta_80{left:165px;bottom:776px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tb_80{left:139px;bottom:752px;}
#tc_80{left:165px;bottom:752px;letter-spacing:-0.16px;}
#td_80{left:237px;bottom:752px;letter-spacing:-0.16px;}
#te_80{left:349px;bottom:752px;letter-spacing:-0.15px;}
#tf_80{left:430px;bottom:752px;letter-spacing:-0.16px;}
#tg_80{left:499px;bottom:752px;letter-spacing:-0.14px;}
#th_80{left:631px;bottom:752px;letter-spacing:-0.04px;}
#ti_80{left:165px;bottom:731px;letter-spacing:-0.15px;word-spacing:10.26px;}
#tj_80{left:165px;bottom:711px;letter-spacing:-0.12px;}
#tk_80{left:142px;bottom:648px;letter-spacing:0.11px;word-spacing:0.12px;}
#tl_80{left:106px;bottom:596px;letter-spacing:0.12px;word-spacing:0.24px;}
#tm_80{left:106px;bottom:577px;letter-spacing:0.1px;word-spacing:-0.71px;}
#tn_80{left:106px;bottom:559px;letter-spacing:0.09px;word-spacing:0.05px;}
#to_80{left:117px;bottom:536px;}
#tp_80{left:145px;bottom:536px;letter-spacing:0.12px;word-spacing:0.69px;}
#tq_80{left:145px;bottom:518px;letter-spacing:0.12px;word-spacing:4.33px;}
#tr_80{left:145px;bottom:500px;letter-spacing:0.12px;word-spacing:0.02px;}
#ts_80{left:117px;bottom:477px;}
#tt_80{left:145px;bottom:477px;letter-spacing:0.1px;word-spacing:0.04px;}
#tu_80{left:117px;bottom:454px;}
#tv_80{left:149px;bottom:454px;letter-spacing:0.1px;}
#tw_80{left:145px;bottom:436px;letter-spacing:0.08px;word-spacing:0.05px;}
#tx_80{left:117px;bottom:414px;}
#ty_80{left:145px;bottom:414px;letter-spacing:0.12px;word-spacing:4.89px;}
#tz_80{left:145px;bottom:395px;letter-spacing:0.1px;word-spacing:0.04px;}
#t10_80{left:117px;bottom:373px;}
#t11_80{left:145px;bottom:373px;letter-spacing:0.08px;word-spacing:-0.07px;}
#t12_80{left:145px;bottom:354px;letter-spacing:0.12px;word-spacing:0.03px;}
#t13_80{left:290px;bottom:334px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t14_80{left:326px;bottom:318px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t15_80{left:142px;bottom:233px;letter-spacing:0.18px;}
#t16_80{left:87px;bottom:205px;}
#t17_80{left:114px;bottom:205px;letter-spacing:-0.15px;word-spacing:-1.64px;}
#t18_80{left:114px;bottom:185px;letter-spacing:-0.17px;word-spacing:6.41px;}
#t19_80{left:114px;bottom:164px;letter-spacing:-0.16px;word-spacing:3.35px;}
#t1a_80{left:114px;bottom:144px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t1b_80{left:87px;bottom:116px;}
#t1c_80{left:114px;bottom:115px;letter-spacing:-0.15px;word-spacing:-0.37px;}

.s0_80{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_80{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_80{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s3_80{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s4_80{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_80{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s6_80{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_80{font-size:15px;font-family:SymbolMT_19r;color:#000;}`}
      </style>

      <style id="fonts80" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg80" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="80/80.svg"
          type="image/svg+xml"
          id="pdf80"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_80" class="t s0_80">
          80{" "}
        </span>
        <span id="t2_80" class="t s1_80">
          АСУУДАЛ{" "}
        </span>
        <span id="t3_80" class="t s2_80">
          Хүүхдийн хамгаалуулах эрх зөрчигдөх, багш хүүхдийг{" "}
        </span>
        <span id="t4_80" class="t s2_80">
          хүчирхийллээс урьдчилан сэргийлэх, хүчирхийлэлд өртсөн{" "}
        </span>
        <span id="t5_80" class="t s2_80">
          тохиолдолд хариу арга хэмжээ авах боломж хомс{" "}
        </span>
        <span id="t6_80" class="t s3_80">
          •{" "}
        </span>
        <span id="t7_80" class="t s4_80">
          Сурагчдад хөгжил, хамгааллын үйлчилгээг үзүүлдэггүй.{" "}
        </span>
        <span id="t8_80" class="t s3_80">
          •{" "}
        </span>
        <span id="t9_80" class="t s4_80">
          Хүүхэд хамгааллын үйлчилгээний зардлыг анги удирдсан багш,{" "}
        </span>
        <span id="ta_80" class="t s4_80">
          нийгмийн ажилтан, сэтгэл зүйчид хувиасаа гаргадаг.{" "}
        </span>
        <span id="tb_80" class="t s3_80">
          •{" "}
        </span>
        <span id="tc_80" class="t s4_80">
          Хүүхэд{" "}
        </span>
        <span id="td_80" class="t s4_80">
          хамгааллын{" "}
        </span>
        <span id="te_80" class="t s4_80">
          зардлыг{" "}
        </span>
        <span id="tf_80" class="t s4_80">
          төсөвт{" "}
        </span>
        <span id="tg_80" class="t s4_80">
          суулгаагүйгээс{" "}
        </span>
        <span id="th_80" class="t s4_80">
          үүдэн{" "}
        </span>
        <span id="ti_80" class="t s4_80">
          сургуулийн орчинд хүүхэд хамгааллын үйлчилгээ хэрэгжих{" "}
        </span>
        <span id="tj_80" class="t s4_80">
          боломжгүй.{" "}
        </span>
        <span id="tk_80" class="t s1_80">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tl_80" class="t s5_80">
          Сургуулийн багш ажилтны төлөөллөөс бүрдсэн, хүүхдийн хөгжил, хамгаалал{" "}
        </span>
        <span id="tm_80" class="t s5_80">
          гэсэн 2 баг байгуулж асуудлаа илрүүлж цаашид хийх үйл ажиллагааны
          чиглэл{" "}
        </span>
        <span id="tn_80" class="t s5_80">
          гаргаж дараах үйл ажиллагаануудыг хийж гүйцэтгэлээ.{" "}
        </span>
        <span id="to_80" class="t s5_80">
          -{" "}
        </span>
        <span id="tp_80" class="t s5_80">
          7 хоног бүрийн 3 дахь өдрийг “Нийгмийн ажилтны өдөр” болгон сурагч,{" "}
        </span>
        <span id="tq_80" class="t s5_80">
          эцэг эх, асран хамгаалагч, харгалзан дэмжигчдийн санал, асуудлыг{" "}
        </span>
        <span id="tr_80" class="t s5_80">
          сонсож үйл ажиллагаандаа тусган хэрэгжүүлж байна.{" "}
        </span>
        <span id="ts_80" class="t s5_80">
          -{" "}
        </span>
        <span id="tt_80" class="t s5_80">
          Хүүхдийн хүчирхийллийн мэдээлэл хүлээн авах зураглалтай болсон.{" "}
        </span>
        <span id="tu_80" class="t s5_80">
          -{" "}
        </span>
        <span id="tv_80" class="t s5_80">
          Дэлгэрэнгүй судалгааны маягттай болж маягтын дагуу сургуулийн нийт{" "}
        </span>
        <span id="tw_80" class="t s5_80">
          хүүхдийн судалгаа авч мэдээллийн нэгдсэн сан бүрдүүлсэн.{" "}
        </span>
        <span id="tx_80" class="t s5_80">
          -{" "}
        </span>
        <span id="ty_80" class="t s5_80">
          Сургуулийн хажуугийн засмал замын гарцыг сэргээн будаж, тэмдэг{" "}
        </span>
        <span id="tz_80" class="t s5_80">
          тэмдэглэгээ байршуулсан.{" "}
        </span>
        <span id="t10_80" class="t s5_80">
          -{" "}
        </span>
        <span id="t11_80" class="t s5_80">
          ХГҮА нэгдсэн хөтөлбөр гаргаж, сургуулийн нэгдсэн хөтөлбөрт тусгахаар{" "}
        </span>
        <span id="t12_80" class="t s5_80">
          ажиллаж байна.{" "}
        </span>
        <span id="t13_80" class="t s6_80">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="t14_80" class="t s6_80">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t15_80" class="t s1_80">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t16_80" class="t s7_80">
          •{" "}
        </span>
        <span id="t17_80" class="t s4_80">
          Хүүхэд хөгжил, хамгааллын алба, хүүхэд хамгааллын баг хамтран хүүхэд{" "}
        </span>
        <span id="t18_80" class="t s4_80">
          хамгааллын зөрчлийг хэрхэн таних, зөрчигдсөн тохиолдолд хэрхэн{" "}
        </span>
        <span id="t19_80" class="t s4_80">
          мэдээлэх, хариу арга хэмжээ авах зураглалтай байх, багш, сурагчид{" "}
        </span>
        <span id="t1a_80" class="t s4_80">
          мэддэг байх.{" "}
        </span>
        <span id="t1b_80" class="t s7_80">
          •{" "}
        </span>
        <span id="t1c_80" class="t s4_80">
          Хүүхэд хамгааллын үйл ажиллагааны зардлыг төсөвт тусгайлан суулгах{" "}
        </span>
      </div>
    </div>
  );
}
