import React from "react";
export default function ThirtySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_37{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_37{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_37{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:8.99px;}
#t4_37{left:87px;bottom:921px;letter-spacing:-0.15px;word-spacing:-0.08px;}
#t5_37{left:87px;bottom:901px;letter-spacing:-0.2px;word-spacing:1.63px;}
#t6_37{left:87px;bottom:880px;letter-spacing:-0.15px;word-spacing:0.26px;}
#t7_37{left:87px;bottom:860px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t8_37{left:249px;bottom:827px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t9_37{left:89px;bottom:613px;letter-spacing:0.07px;word-spacing:0.02px;}
#ta_37{left:89px;bottom:602px;letter-spacing:0.04px;word-spacing:0.04px;}
#tb_37{left:89px;bottom:591px;letter-spacing:0.06px;word-spacing:0.02px;}
#tc_37{left:89px;bottom:580px;letter-spacing:0.05px;word-spacing:0.04px;}
#td_37{left:207px;bottom:719px;letter-spacing:0.06px;word-spacing:0.02px;}
#te_37{left:207px;bottom:708px;letter-spacing:0.08px;}
#tf_37{left:207px;bottom:697px;letter-spacing:0.08px;word-spacing:0.01px;}
#tg_37{left:207px;bottom:686px;letter-spacing:0.03px;word-spacing:0.05px;}
#th_37{left:207px;bottom:675px;letter-spacing:0.08px;}
#ti_37{left:216px;bottom:641px;letter-spacing:0.04px;word-spacing:0.05px;}
#tj_37{left:216px;bottom:630px;letter-spacing:0.05px;}
#tk_37{left:216px;bottom:619px;letter-spacing:0.07px;word-spacing:0.02px;}
#tl_37{left:338px;bottom:741px;letter-spacing:0.03px;word-spacing:0.05px;}
#tm_37{left:338px;bottom:730px;letter-spacing:-0.12px;}
#tn_37{left:338px;bottom:719px;letter-spacing:0.05px;word-spacing:0.03px;}
#to_37{left:338px;bottom:708px;letter-spacing:0.02px;word-spacing:0.06px;}
#tp_37{left:345px;bottom:663px;letter-spacing:0.03px;word-spacing:0.05px;}
#tq_37{left:345px;bottom:652px;letter-spacing:0.05px;}
#tr_37{left:345px;bottom:641px;letter-spacing:0.01px;}
#ts_37{left:345px;bottom:630px;letter-spacing:0.05px;}
#tt_37{left:459px;bottom:765px;letter-spacing:0.08px;}
#tu_37{left:459px;bottom:754px;letter-spacing:0.03px;word-spacing:0.04px;}
#tv_37{left:459px;bottom:743px;letter-spacing:0.02px;}
#tw_37{left:459px;bottom:732px;letter-spacing:0.04px;}
#tx_37{left:472px;bottom:687px;letter-spacing:0.07px;word-spacing:0.02px;}
#ty_37{left:472px;bottom:676px;letter-spacing:0.06px;word-spacing:0.02px;}
#tz_37{left:472px;bottom:665px;letter-spacing:0.09px;}
#t10_37{left:593px;bottom:791px;letter-spacing:0.07px;word-spacing:0.01px;}
#t11_37{left:593px;bottom:780px;letter-spacing:0.05px;word-spacing:0.03px;}
#t12_37{left:593px;bottom:769px;letter-spacing:0.05px;word-spacing:0.03px;}
#t13_37{left:593px;bottom:758px;letter-spacing:-0.02px;}
#t14_37{left:599px;bottom:712px;letter-spacing:0.06px;word-spacing:-0.37px;}
#t15_37{left:599px;bottom:701px;letter-spacing:0.07px;word-spacing:0.02px;}
#t16_37{left:599px;bottom:690px;letter-spacing:0.04px;word-spacing:0.04px;}
#t17_37{left:75px;bottom:700px;letter-spacing:-0.14px;}
#t18_37{left:75px;bottom:690px;letter-spacing:-0.18px;}
#t19_37{left:75px;bottom:679px;letter-spacing:-0.18px;word-spacing:0.04px;}
#t1a_37{left:75px;bottom:669px;letter-spacing:-0.15px;word-spacing:0.01px;}
#t1b_37{left:75px;bottom:658px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1c_37{left:75px;bottom:648px;letter-spacing:-0.2px;}
#t1d_37{left:103px;bottom:503px;letter-spacing:0.07px;word-spacing:0.08px;}
#t1e_37{left:103px;bottom:458px;letter-spacing:0.13px;}
#t1f_37{left:122px;bottom:458px;letter-spacing:0.1px;word-spacing:0.05px;}
#t1g_37{left:103px;bottom:440px;letter-spacing:0.11px;word-spacing:6.08px;}
#t1h_37{left:103px;bottom:421px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1i_37{left:324px;bottom:421px;letter-spacing:0.04px;word-spacing:0.1px;}
#t1j_37{left:103px;bottom:385px;letter-spacing:0.13px;}
#t1k_37{left:122px;bottom:385px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1l_37{left:103px;bottom:366px;letter-spacing:0.12px;word-spacing:0.01px;}
#t1m_37{left:131px;bottom:348px;}
#t1n_37{left:158px;bottom:348px;letter-spacing:0.08px;word-spacing:5.45px;}
#t1o_37{left:158px;bottom:330px;letter-spacing:0.05px;word-spacing:0.09px;}
#t1p_37{left:131px;bottom:311px;}
#t1q_37{left:158px;bottom:311px;letter-spacing:0.11px;word-spacing:3.38px;}
#t1r_37{left:158px;bottom:293px;letter-spacing:-0.08px;}
#t1s_37{left:131px;bottom:275px;}
#t1t_37{left:158px;bottom:275px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1u_37{left:131px;bottom:256px;}
#t1v_37{left:158px;bottom:256px;letter-spacing:0.06px;word-spacing:0.08px;}
#t1w_37{left:131px;bottom:238px;}
#t1x_37{left:158px;bottom:238px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1y_37{left:483px;bottom:238px;}
#t1z_37{left:103px;bottom:201px;letter-spacing:0.08px;word-spacing:0.06px;}
#t20_37{left:103px;bottom:183px;letter-spacing:0.11px;word-spacing:-0.57px;}
#t21_37{left:103px;bottom:165px;letter-spacing:0.07px;word-spacing:0.07px;}

.s0_37{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_37{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_37{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_37{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s4_37{font-size:9px;font-family:MogulArial_16s;color:#000;}
.s5_37{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s6_37{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_37{font-size:15px;font-family:MogulArial_16s;color:#000;}
.t.m0_37{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts37" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg37" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="37/37.svg"
          type="image/svg+xml"
          id="pdf37"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_37" class="t s1_37">
          37{" "}
        </span>
        <span id="t3_37" class="t s2_37">
          Зөвхөн богино хугацааны төлөвлөлт, гүйцэтгэлдээ анхаараад алсын{" "}
        </span>
        <span id="t4_37" class="t s2_37">
          хараа, холын зорилгоо орхигдуулбал сургуулийн хөгжил дээшээ биш дороо{" "}
        </span>
        <span id="t5_37" class="t s2_37">
          эргэлдэх хөдөлгөөн болж хувирдаг. Тиймээс богино хугацааны төлөвлөлт,{" "}
        </span>
        <span id="t6_37" class="t s2_37">
          урт хугацааны хөгжлийн төлөвлөлтөө тэнцвэртэй байхад анхаарч, хооронд{" "}
        </span>
        <span id="t7_37" class="t s2_37">
          нь уялдуулах хэрэгтэй.{" "}
        </span>
        <span id="t8_37" class="t s3_37">
          Зураг 1.10. Алсын харааг тодорхойлох нь{" "}
        </span>
        <span id="t9_37" class="t s4_37">
          Сурагчдын суурь{" "}
        </span>
        <span id="ta_37" class="t s4_37">
          асуудлыг судалж,{" "}
        </span>
        <span id="tb_37" class="t s4_37">
          төсөөлж буй дүр{" "}
        </span>
        <span id="tc_37" class="t s4_37">
          төрхийг тодорхойлох{" "}
        </span>
        <span id="td_37" class="t s4_37">
          Сурагч, эцэг эх,{" "}
        </span>
        <span id="te_37" class="t s4_37">
          орон нутгийн{" "}
        </span>
        <span id="tf_37" class="t s4_37">
          түншлэгч, хамт{" "}
        </span>
        <span id="tg_37" class="t s4_37">
          олны хүлээлтэд{" "}
        </span>
        <span id="th_37" class="t s4_37">
          нийцүүлэх{" "}
        </span>
        <span id="ti_37" class="t s4_37">
          Оролцогч талуудад{" "}
        </span>
        <span id="tj_37" class="t s4_37">
          танилцуулж,{" "}
        </span>
        <span id="tk_37" class="t s4_37">
          саналыг тусгах{" "}
        </span>
        <span id="tl_37" class="t s4_37">
          Бүгдийг биш{" "}
        </span>
        <span id="tm_37" class="t s4_37">
          чухлыг,{" "}
        </span>
        <span id="tn_37" class="t s4_37">
          гоёыг биш{" "}
        </span>
        <span id="to_37" class="t s4_37">
          голыг нь олох{" "}
        </span>
        <span id="tp_37" class="t s4_37">
          Нэгдмэл санааг{" "}
        </span>
        <span id="tq_37" class="t s4_37">
          ойлгомжтой,{" "}
        </span>
        <span id="tr_37" class="t s4_37">
          оновчтой{" "}
        </span>
        <span id="ts_37" class="t s4_37">
          томьёолох{" "}
        </span>
        <span id="tt_37" class="t s4_37">
          Үг бүрийг ижил{" "}
        </span>
        <span id="tu_37" class="t s4_37">
          утгаар ойлгохоор{" "}
        </span>
        <span id="tv_37" class="t s4_37">
          тайлбарлах,{" "}
        </span>
        <span id="tw_37" class="t s4_37">
          таниулах{" "}
        </span>
        <span id="tx_37" class="t s4_37">
          Хамт олны гишүүн{" "}
        </span>
        <span id="ty_37" class="t s4_37">
          бүрийн итгэл{" "}
        </span>
        <span id="tz_37" class="t s4_37">
          үнэмшил{" "}
        </span>
        <span id="t10_37" class="t s4_37">
          Хоосон уриа,{" "}
        </span>
        <span id="t11_37" class="t s4_37">
          ханын гоёл биш{" "}
        </span>
        <span id="t12_37" class="t s4_37">
          бодит үйлдэл{" "}
        </span>
        <span id="t13_37" class="t s4_37">
          болгох{" "}
        </span>
        <span id="t14_37" class="t s4_37">
          Зорилго, төлөвлөгөө,{" "}
        </span>
        <span id="t15_37" class="t s4_37">
          үйл ажиллагаанд{" "}
        </span>
        <span id="t16_37" class="t s4_37">
          буулгаж хэрэгжүүлэх{" "}
        </span>
        <span id="t17_37" class="t s4_37">
          Зөвхөн{" "}
        </span>
        <span id="t18_37" class="t s4_37">
          байгууллагын{" "}
        </span>
        <span id="t19_37" class="t s4_37">
          ирээдүйг бус төгсөн{" "}
        </span>
        <span id="t1a_37" class="t s4_37">
          гарах хүүхдийн дүр{" "}
        </span>
        <span id="t1b_37" class="t s4_37">
          төрхийг тод томруун{" "}
        </span>
        <span id="t1c_37" class="t s4_37">
          тодорхойлох{" "}
        </span>
        <span id="t1d_37" class="t s5_37">
          Шигтгээ 3: Товч асуулт хариулт{" "}
        </span>
        <span id="t1e_37" class="t s6_37">
          А:{" "}
        </span>
        <span id="t1f_37" class="t s6_37">
          Алсын хараанд юуг тусгах хэрэгтэй бол?{" "}
        </span>
        <span id="t1g_37" class="t s7_37">
          Х: Сургууль нь урт хугацаандаа сурагчдаа ямар хүн болгож хөгжүүлж,{" "}
        </span>
        <span id="t1h_37" class="t s7_37">
          төлөвшүүлэхийг хүсэж буйгаа{" "}
        </span>
        <span id="t1i_37" class="t s7_37">
          тод томруун, товч тодорхой тусгана.{" "}
        </span>
        <span id="t1j_37" class="t s6_37">
          А:{" "}
        </span>
        <span id="t1k_37" class="t s6_37">
          Алсын хараа яагаад чухал вэ?{" "}
        </span>
        <span id="t1l_37" class="t s7_37">
          Х: Алсын хараа нь{" "}
        </span>
        <span id="t1m_37" class="t s7_37">
          -{" "}
        </span>
        <span id="t1n_37" class="t s7_37">
          Өөр өөр үзэл санаа, үнэт зүйлтэй сурагч, багш, эцэг, эх, асран{" "}
        </span>
        <span id="t1o_37" class="t s7_37">
          хамгаалагч, сургуулийн ажилтнуудыг нэгтгэдэг.{" "}
        </span>
        <span id="t1p_37" class="t s7_37">
          -{" "}
        </span>
        <span id="t1q_37" class="t s7_37">
          Хамт олны шийдвэр, сургуулийн бодлого, үйл ажиллагааг нэг зүгт{" "}
        </span>
        <span id="t1r_37" class="t s7_37">
          чиглүүлдэг.{" "}
        </span>
        <span id="t1s_37" class="t s7_37">
          -{" "}
        </span>
        <span id="t1t_37" class="t s7_37">
          Хүн бүрийн ажил үүргийн утга учир, үнэ цэнийг илэрхийлдэг.{" "}
        </span>
        <span id="t1u_37" class="t s7_37">
          -{" "}
        </span>
        <span id="t1v_37" class="t s7_37">
          Ажиллах эрч хүч, урмыг төрүүлдэг.{" "}
        </span>
        <span id="t1w_37" class="t s7_37">
          -{" "}
        </span>
        <span id="t1x_37" class="t s7_37">
          Гадаад орчинд танай сургуулийг илэрхийлдэг
        </span>
        <span id="t1y_37" class="t s5_37">
          .{" "}
        </span>
        <span id="t1z_37" class="t s6_37">
          А. Алсын харааг тодорхойлоход хэн оролцох вэ?{" "}
        </span>
        <span id="t20_37" class="t s7_37">
          Х. Сурагч, багш бүр, ЗАН, МХБ нэгж, сургуулийн түвшинд хамт олноороо,
          эцэг{" "}
        </span>
        <span id="t21_37" class="t s7_37">
          эх, Сургуулийн зөвлөлийн оролцоотойгоор тодорхойлно.{" "}
        </span>
      </div>
    </div>
  );
}
