import React from "react";
export default function ThirtyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_34{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_34{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_34{left:100px;bottom:909px;letter-spacing:0.06px;word-spacing:0.08px;}
#t4_34{left:100px;bottom:891px;letter-spacing:-0.06px;}
#t5_34{left:222px;bottom:891px;letter-spacing:0.13px;}
#t6_34{left:299px;bottom:891px;letter-spacing:0.08px;}
#t7_34{left:414px;bottom:891px;letter-spacing:0.07px;}
#t8_34{left:511px;bottom:891px;letter-spacing:0.13px;}
#t9_34{left:581px;bottom:891px;letter-spacing:0.04px;}
#ta_34{left:100px;bottom:873px;letter-spacing:0.12px;word-spacing:0.02px;}
#tb_34{left:100px;bottom:846px;letter-spacing:0.05px;word-spacing:4.07px;}
#tc_34{left:100px;bottom:827px;letter-spacing:0.12px;word-spacing:-1.04px;}
#td_34{left:100px;bottom:809px;letter-spacing:-0.04px;}
#te_34{left:229px;bottom:809px;letter-spacing:0.13px;}
#tf_34{left:305px;bottom:809px;letter-spacing:0.08px;}
#tg_34{left:416px;bottom:809px;letter-spacing:0.05px;}
#th_34{left:494px;bottom:809px;letter-spacing:0.11px;}
#ti_34{left:582px;bottom:809px;letter-spacing:0.1px;}
#tj_34{left:100px;bottom:791px;letter-spacing:0.09px;word-spacing:0.04px;}
#tk_34{left:133px;bottom:700px;letter-spacing:0.11px;word-spacing:0.04px;}
#tl_34{left:89px;bottom:626px;letter-spacing:0.22px;}
#tm_34{left:159px;bottom:669px;letter-spacing:0.1px;word-spacing:0.02px;}
#tn_34{left:457px;bottom:669px;letter-spacing:0.1px;word-spacing:0.02px;}
#to_34{left:147px;bottom:628px;letter-spacing:0.11px;}
#tp_34{left:231px;bottom:642px;letter-spacing:0.1px;word-spacing:0.01px;}
#tq_34{left:238px;bottom:628px;letter-spacing:0.06px;word-spacing:0.04px;}
#tr_34{left:234px;bottom:613px;letter-spacing:0.08px;word-spacing:0.02px;}
#ts_34{left:264px;bottom:598px;letter-spacing:-0.41px;}
#tt_34{left:338px;bottom:642px;letter-spacing:0.07px;word-spacing:0.02px;}
#tu_34{left:344px;bottom:628px;letter-spacing:0.1px;}
#tv_34{left:339px;bottom:613px;letter-spacing:0.04px;}
#tw_34{left:341px;bottom:598px;letter-spacing:0.12px;}
#tx_34{left:418px;bottom:628px;letter-spacing:0.13px;}
#ty_34{left:509px;bottom:642px;letter-spacing:0.06px;}
#tz_34{left:534px;bottom:628px;letter-spacing:0.06px;}
#t10_34{left:603px;bottom:642px;letter-spacing:0.1px;}
#t11_34{left:599px;bottom:628px;letter-spacing:0.03px;}
#t12_34{left:103.2px;bottom:422.7px;letter-spacing:0.05px;word-spacing:0.09px;}
#t13_34{left:117.8px;bottom:404.4px;letter-spacing:0.1px;word-spacing:0.01px;}
#t14_34{left:132.5px;bottom:388.9px;letter-spacing:0.02px;word-spacing:-0.1px;}
#t15_34{left:143px;bottom:572px;letter-spacing:0.04px;}
#t16_34{left:143px;bottom:558px;letter-spacing:0.09px;}
#t17_34{left:143px;bottom:543px;letter-spacing:0.1px;}
#t18_34{left:143px;bottom:528px;letter-spacing:0.03px;}
#t19_34{left:143px;bottom:514px;letter-spacing:0.11px;}
#t1a_34{left:143px;bottom:499px;letter-spacing:-0.02px;}
#t1b_34{left:143px;bottom:484px;}
#t1c_34{left:230px;bottom:572px;letter-spacing:0.09px;}
#t1d_34{left:230px;bottom:558px;letter-spacing:0.03px;}
#t1e_34{left:230px;bottom:543px;}
#t1f_34{left:230px;bottom:528px;letter-spacing:0.11px;}
#t1g_34{left:230px;bottom:514px;letter-spacing:0.09px;}
#t1h_34{left:262px;bottom:514px;letter-spacing:0.1px;}
#t1i_34{left:230px;bottom:499px;letter-spacing:0.07px;}
#t1j_34{left:230px;bottom:484px;letter-spacing:0.11px;}
#t1k_34{left:230px;bottom:470px;letter-spacing:0.06px;}
#t1l_34{left:230px;bottom:455px;letter-spacing:-0.1px;}
#t1m_34{left:230px;bottom:440px;letter-spacing:-0.05px;word-spacing:0.16px;}
#t1n_34{left:322px;bottom:572px;letter-spacing:0.11px;}
#t1o_34{left:322px;bottom:558px;letter-spacing:-0.2px;}
#t1p_34{left:322px;bottom:543px;letter-spacing:0.08px;}
#t1q_34{left:322px;bottom:528px;letter-spacing:0.11px;}
#t1r_34{left:322px;bottom:514px;letter-spacing:0.1px;word-spacing:0.01px;}
#t1s_34{left:322px;bottom:499px;letter-spacing:0.06px;}
#t1t_34{left:322px;bottom:484px;letter-spacing:0.04px;word-spacing:0.07px;}
#t1u_34{left:322px;bottom:470px;letter-spacing:0.11px;}
#t1v_34{left:322px;bottom:455px;}
#t1w_34{left:322px;bottom:440px;letter-spacing:0.13px;}
#t1x_34{left:322px;bottom:426px;letter-spacing:0.05px;}
#t1y_34{left:404px;bottom:572px;letter-spacing:0.11px;}
#t1z_34{left:404px;bottom:558px;letter-spacing:0.13px;}
#t20_34{left:404px;bottom:543px;letter-spacing:0.03px;}
#t21_34{left:404px;bottom:528px;letter-spacing:0.04px;}
#t22_34{left:404px;bottom:514px;letter-spacing:0.02px;}
#t23_34{left:404px;bottom:499px;letter-spacing:0.03px;word-spacing:0.06px;}
#t24_34{left:404px;bottom:484px;letter-spacing:0.1px;}
#t25_34{left:404px;bottom:470px;letter-spacing:0.11px;}
#t26_34{left:404px;bottom:455px;letter-spacing:0.06px;}
#t27_34{left:495px;bottom:572px;letter-spacing:0.09px;word-spacing:0.01px;}
#t28_34{left:495px;bottom:558px;letter-spacing:0.08px;}
#t29_34{left:495px;bottom:543px;letter-spacing:0.13px;}
#t2a_34{left:495px;bottom:528px;letter-spacing:0.09px;word-spacing:0.01px;}
#t2b_34{left:495px;bottom:514px;letter-spacing:0.06px;}
#t2c_34{left:495px;bottom:499px;letter-spacing:0.1px;}
#t2d_34{left:495px;bottom:484px;letter-spacing:0.06px;}
#t2e_34{left:495px;bottom:470px;letter-spacing:0.05px;}
#t2f_34{left:495px;bottom:455px;letter-spacing:0.02px;}
#t2g_34{left:495px;bottom:440px;letter-spacing:0.03px;}
#t2h_34{left:593px;bottom:572px;letter-spacing:0.09px;}
#t2i_34{left:593px;bottom:558px;letter-spacing:0.13px;}
#t2j_34{left:593px;bottom:543px;letter-spacing:0.1px;}
#t2k_34{left:593px;bottom:528px;letter-spacing:0.08px;}
#t2l_34{left:593px;bottom:514px;letter-spacing:0.09px;}
#t2m_34{left:593px;bottom:499px;letter-spacing:0.08px;}
#t2n_34{left:593px;bottom:484px;letter-spacing:0.06px;}
#t2o_34{left:593px;bottom:470px;letter-spacing:0.11px;}
#t2p_34{left:593px;bottom:455px;letter-spacing:0.05px;}
#t2q_34{left:593px;bottom:440px;letter-spacing:0.03px;}
#t2r_34{left:593px;bottom:426px;letter-spacing:0.03px;}
#t2s_34{left:593px;bottom:411px;letter-spacing:0.03px;}
#t2t_34{left:103.2px;bottom:233px;letter-spacing:0.09px;word-spacing:0.04px;}
#t2u_34{left:117.8px;bottom:243.6px;letter-spacing:-0.02px;}
#t2v_34{left:132.5px;bottom:241.5px;letter-spacing:0.04px;word-spacing:0.08px;}
#t2w_34{left:143px;bottom:361px;letter-spacing:0.09px;word-spacing:0.01px;}
#t2x_34{left:143px;bottom:347px;letter-spacing:0.06px;}
#t2y_34{left:143px;bottom:332px;letter-spacing:0.04px;}
#t2z_34{left:143px;bottom:317px;letter-spacing:0.06px;word-spacing:0.05px;}
#t30_34{left:143px;bottom:303px;letter-spacing:0.03px;}
#t31_34{left:143px;bottom:288px;letter-spacing:0.03px;}
#t32_34{left:143px;bottom:273px;letter-spacing:0.04px;}
#t33_34{left:143px;bottom:259px;letter-spacing:0.11px;}
#t34_34{left:143px;bottom:244px;letter-spacing:0.04px;}
#t35_34{left:143px;bottom:229px;letter-spacing:0.07px;}
#t36_34{left:143px;bottom:215px;letter-spacing:0.07px;}
#t37_34{left:230px;bottom:361px;letter-spacing:0.09px;}
#t38_34{left:230px;bottom:347px;letter-spacing:0.06px;}
#t39_34{left:230px;bottom:332px;letter-spacing:0.05px;}
#t3a_34{left:230px;bottom:317px;letter-spacing:0.08px;}
#t3b_34{left:230px;bottom:303px;letter-spacing:0.08px;}
#t3c_34{left:230px;bottom:288px;letter-spacing:0.09px;}
#t3d_34{left:230px;bottom:273px;letter-spacing:0.04px;}
#t3e_34{left:230px;bottom:259px;letter-spacing:0.09px;}
#t3f_34{left:230px;bottom:244px;letter-spacing:0.05px;word-spacing:0.07px;}
#t3g_34{left:322px;bottom:361px;letter-spacing:0.09px;word-spacing:0.01px;}
#t3h_34{left:322px;bottom:347px;letter-spacing:0.06px;}
#t3i_34{left:322px;bottom:332px;letter-spacing:0.03px;}
#t3j_34{left:322px;bottom:317px;letter-spacing:0.04px;}
#t3k_34{left:322px;bottom:303px;letter-spacing:0.06px;}
#t3l_34{left:322px;bottom:288px;letter-spacing:0.03px;}
#t3m_34{left:322px;bottom:273px;letter-spacing:0.1px;}
#t3n_34{left:322px;bottom:259px;letter-spacing:0.11px;}
#t3o_34{left:322px;bottom:244px;}
#t3p_34{left:404px;bottom:361px;letter-spacing:0.11px;}
#t3q_34{left:404px;bottom:347px;letter-spacing:0.02px;}
#t3r_34{left:404px;bottom:332px;letter-spacing:0.12px;}
#t3s_34{left:404px;bottom:317px;letter-spacing:0.04px;}
#t3t_34{left:404px;bottom:303px;letter-spacing:0.06px;}
#t3u_34{left:404px;bottom:288px;letter-spacing:0.03px;}
#t3v_34{left:404px;bottom:273px;letter-spacing:0.07px;}
#t3w_34{left:495px;bottom:361px;letter-spacing:0.08px;word-spacing:0.01px;}
#t3x_34{left:495px;bottom:347px;letter-spacing:0.1px;}
#t3y_34{left:495px;bottom:332px;letter-spacing:0.06px;}
#t3z_34{left:495px;bottom:317px;letter-spacing:0.03px;}
#t40_34{left:495px;bottom:303px;letter-spacing:0.02px;}
#t41_34{left:495px;bottom:288px;letter-spacing:0.08px;word-spacing:0.02px;}
#t42_34{left:495px;bottom:273px;letter-spacing:0.07px;}
#t43_34{left:495px;bottom:259px;letter-spacing:0.06px;}
#t44_34{left:495px;bottom:244px;letter-spacing:0.08px;}
#t45_34{left:495px;bottom:229px;letter-spacing:0.1px;}
#t46_34{left:495px;bottom:215px;letter-spacing:0.02px;}
#t47_34{left:495px;bottom:200px;letter-spacing:0.03px;}
#t48_34{left:593px;bottom:361px;letter-spacing:0.1px;}
#t49_34{left:593px;bottom:347px;letter-spacing:0.09px;word-spacing:0.01px;}
#t4a_34{left:593px;bottom:332px;letter-spacing:0.08px;}
#t4b_34{left:593px;bottom:317px;letter-spacing:0.09px;}
#t4c_34{left:593px;bottom:303px;letter-spacing:0.06px;}
#t4d_34{left:593px;bottom:288px;letter-spacing:0.08px;}
#t4e_34{left:593px;bottom:273px;letter-spacing:0.1px;}
#t4f_34{left:593px;bottom:259px;letter-spacing:0.03px;}
#t4g_34{left:593px;bottom:244px;letter-spacing:0.03px;}
#t4h_34{left:593px;bottom:229px;letter-spacing:0.03px;}

.s0_34{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_34{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_34{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s3_34{font-size:15px;font-family:ArialMT_1lm;color:#333;}
.s4_34{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_34{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s6_34{font-size:12px;font-family:MogulArial-Bold_179;color:#000;}
.t.m0_34{transform:matrix(0,-1,1,0,0,0);}`}
      </style>

      <style id="fonts34" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg34" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="34/34.svg"
          type="image/svg+xml"
          id="pdf34"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_34" class="t s1_34">
          34{" "}
        </span>
        <span id="t3_34" class="t s2_34">
          23 дугаар зүйл.Цэцэрлэг, ерөнхий боловсролын сургуулийн өөрийн
          удирдлага{" "}
        </span>
        <span id="t4_34" class="t s3_34">
          23.1.Цэцэрлэг,{" "}
        </span>
        <span id="t5_34" class="t s3_34">
          ерөнхий{" "}
        </span>
        <span id="t6_34" class="t s3_34">
          боловсролын{" "}
        </span>
        <span id="t7_34" class="t s3_34">
          сургуулийн{" "}
        </span>
        <span id="t8_34" class="t s3_34">
          өөрийн{" "}
        </span>
        <span id="t9_34" class="t s3_34">
          удирдлагыг{" "}
        </span>
        <span id="ta_34" class="t s3_34">
          Боловсролын ерөнхий хуулийн 34.2-т заасан зөвлөл хэрэгжүүлнэ.{" "}
        </span>
        <span id="tb_34" class="t s3_34">
          23.2.Зөвлөл нь цэцэрлэг, сургуулийн сургалт, үйл ажиллагаа,
          суралцагчийн{" "}
        </span>
        <span id="tc_34" class="t s3_34">
          хөгжилд дэмжлэг үзүүлэх зорилготой ба дараах үндсэн чиг үүргийг
          хэрэгжүүлнэ:{" "}
        </span>
        <span id="td_34" class="t s3_34">
          23.2.1.цэцэрлэг,{" "}
        </span>
        <span id="te_34" class="t s3_34">
          ерөнхий{" "}
        </span>
        <span id="tf_34" class="t s3_34">
          боловсролын{" "}
        </span>
        <span id="tg_34" class="t s3_34">
          сургууль{" "}
        </span>
        <span id="th_34" class="t s3_34">
          хөгжүүлэх{" "}
        </span>
        <span id="ti_34" class="t s3_34">
          төлөвлөгөө{" "}
        </span>
        <span id="tj_34" class="t s3_34">
          боловсруулах, хэрэгжүүлэх явцад санал, дүгнэлт гаргах;{" "}
        </span>
        <span id="tk_34" class="t s4_34">
          Хүснэгт 1.3. Сургуулийн менежментийг хэрэгжүүлэхэд талуудын оролцоо{" "}
        </span>
        <span id="tl_34" class="t s5_34">
          АЛХАМ{" "}
        </span>
        <span id="tm_34" class="t s5_34">
          Сургуулийн СҮА-ны үндсэн оролцогчид{" "}
        </span>
        <span id="tn_34" class="t s5_34">
          Сургуулийн бүтцийн нэгжүүд{" "}
        </span>
        <span id="to_34" class="t s5_34">
          Суралцагчид{" "}
        </span>
        <span id="tp_34" class="t s5_34">
          Багш, ажилтан{" "}
        </span>
        <span id="tq_34" class="t s5_34">
          (сэтгэл зүйч,{" "}
        </span>
        <span id="tr_34" class="t s5_34">
          эмч, үйлчлэгч{" "}
        </span>
        <span id="ts_34" class="t s5_34">
          г.м){" "}
        </span>
        <span id="tt_34" class="t s5_34">
          Эцэг эх,{" "}
        </span>
        <span id="tu_34" class="t s5_34">
          асран{" "}
        </span>
        <span id="tv_34" class="t s5_34">
          хамгаа-{" "}
        </span>
        <span id="tw_34" class="t s5_34">
          лагчид{" "}
        </span>
        <span id="tx_34" class="t s5_34">
          ЗАН, МХБ{" "}
        </span>
        <span id="ty_34" class="t s5_34">
          Сургуулийн{" "}
        </span>
        <span id="tz_34" class="t s5_34">
          зөвлөл{" "}
        </span>
        <span id="t10_34" class="t s5_34">
          Сурагчдын{" "}
        </span>
        <span id="t11_34" class="t s5_34">
          байгууллага{" "}
        </span>
        <span id="t12_34" class="t m0_34 s6_34">
          АСУУДЛАА ИЛРҮҮЛЭХ{" "}
        </span>
        <span id="t13_34" class="t m0_34 s5_34">
          (Үнэлгээний үр дүнд инжилгээ{" "}
        </span>
        <span id="t14_34" class="t m0_34 s5_34">
          хийх, баримт, нотолгоонд үндэслэх){" "}
        </span>
        <span id="t15_34" class="t s5_34">
          Асуудал{" "}
        </span>
        <span id="t16_34" class="t s5_34">
          илрүүлэх{" "}
        </span>
        <span id="t17_34" class="t s5_34">
          үйл явцад{" "}
        </span>
        <span id="t18_34" class="t s5_34">
          оролцох,{" "}
        </span>
        <span id="t19_34" class="t s5_34">
          өөрийн санал,{" "}
        </span>
        <span id="t1a_34" class="t s5_34">
          нотолгоог{" "}
        </span>
        <span id="t1b_34" class="t s5_34">
          гаргах{" "}
        </span>
        <span id="t1c_34" class="t s5_34">
          Сурагчийн{" "}
        </span>
        <span id="t1d_34" class="t s5_34">
          гүйцэтгэл,{" "}
        </span>
        <span id="t1e_34" class="t s5_34">
          нотолгоонд{" "}
        </span>
        <span id="t1f_34" class="t s5_34">
          дүн шинжилгээ{" "}
        </span>
        <span id="t1g_34" class="t s5_34">
          хийх{" "}
        </span>
        <span id="t1h_34" class="t s5_34">
          (Явцын,{" "}
        </span>
        <span id="t1i_34" class="t s5_34">
          эцсийн,{" "}
        </span>
        <span id="t1j_34" class="t s5_34">
          хөндлөнгийн{" "}
        </span>
        <span id="t1k_34" class="t s5_34">
          үнэлгээ,{" "}
        </span>
        <span id="t1l_34" class="t s5_34">
          ажиглалт,{" "}
        </span>
        <span id="t1m_34" class="t s5_34">
          тэмдэглэл г.м){" "}
        </span>
        <span id="t1n_34" class="t s5_34">
          Хүүхдийн{" "}
        </span>
        <span id="t1o_34" class="t s5_34">
          мэдлэг,{" "}
        </span>
        <span id="t1p_34" class="t s5_34">
          чадвар{" "}
        </span>
        <span id="t1q_34" class="t s5_34">
          төлөвшил,{" "}
        </span>
        <span id="t1r_34" class="t s5_34">
          эрүүл мэндэд{" "}
        </span>
        <span id="t1s_34" class="t s5_34">
          тулгамдаж{" "}
        </span>
        <span id="t1t_34" class="t s5_34">
          буй асуудлыг{" "}
        </span>
        <span id="t1u_34" class="t s5_34">
          өөрсдийн{" "}
        </span>
        <span id="t1v_34" class="t s5_34">
          нотолгоонд{" "}
        </span>
        <span id="t1w_34" class="t s5_34">
          үндэслэн{" "}
        </span>
        <span id="t1x_34" class="t s5_34">
          тодорхойлох{" "}
        </span>
        <span id="t1y_34" class="t s5_34">
          Багш нараас{" "}
        </span>
        <span id="t1z_34" class="t s5_34">
          ирүүлсэн{" "}
        </span>
        <span id="t20_34" class="t s5_34">
          асуудлуудыг{" "}
        </span>
        <span id="t21_34" class="t s5_34">
          эрэмбэлж,{" "}
        </span>
        <span id="t22_34" class="t s5_34">
          судлагдахуун,{" "}
        </span>
        <span id="t23_34" class="t s5_34">
          бага, дунд,{" "}
        </span>
        <span id="t24_34" class="t s5_34">
          ахлах ангийн{" "}
        </span>
        <span id="t25_34" class="t s5_34">
          түвшинд{" "}
        </span>
        <span id="t26_34" class="t s5_34">
          багцлах{" "}
        </span>
        <span id="t27_34" class="t s5_34">
          Эцэг эх, асран{" "}
        </span>
        <span id="t28_34" class="t s5_34">
          хамгаалагчдаас{" "}
        </span>
        <span id="t29_34" class="t s5_34">
          ирүүлсэн{" "}
        </span>
        <span id="t2a_34" class="t s5_34">
          саналыг нэгтгэх,{" "}
        </span>
        <span id="t2b_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t2c_34" class="t s5_34">
          хүрээнд{" "}
        </span>
        <span id="t2d_34" class="t s5_34">
          тулгамдсан{" "}
        </span>
        <span id="t2e_34" class="t s5_34">
          асуудлыг{" "}
        </span>
        <span id="t2f_34" class="t s5_34">
          тодорхойлоход{" "}
        </span>
        <span id="t2g_34" class="t s5_34">
          оролцох{" "}
        </span>
        <span id="t2h_34" class="t s5_34">
          Сурагчдаас{" "}
        </span>
        <span id="t2i_34" class="t s5_34">
          ирүүлсэн{" "}
        </span>
        <span id="t2j_34" class="t s5_34">
          санал{" "}
        </span>
        <span id="t2k_34" class="t s5_34">
          хүсэлтийг{" "}
        </span>
        <span id="t2l_34" class="t s5_34">
          нэгтгэж,{" "}
        </span>
        <span id="t2m_34" class="t s5_34">
          уламжлах,{" "}
        </span>
        <span id="t2n_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t2o_34" class="t s5_34">
          түвшний{" "}
        </span>
        <span id="t2p_34" class="t s5_34">
          асуудлыг{" "}
        </span>
        <span id="t2q_34" class="t s5_34">
          тодор-{" "}
        </span>
        <span id="t2r_34" class="t s5_34">
          хойлоход{" "}
        </span>
        <span id="t2s_34" class="t s5_34">
          оролцох{" "}
        </span>
        <span id="t2t_34" class="t m0_34 s6_34">
          ЗОРИЛГО, ЗОРИЛТ{" "}
        </span>
        <span id="t2u_34" class="t m0_34 s6_34">
          ТОДОРХОЙЛОХ{" "}
        </span>
        <span id="t2v_34" class="t m0_34 s5_34">
          (SMART байлгах){" "}
        </span>
        <span id="t2w_34" class="t s5_34">
          Ангийн болон{" "}
        </span>
        <span id="t2x_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t2y_34" class="t s5_34">
          зорилго,{" "}
        </span>
        <span id="t2z_34" class="t s5_34">
          зорилт тодор-{" "}
        </span>
        <span id="t30_34" class="t s5_34">
          хойлоход{" "}
        </span>
        <span id="t31_34" class="t s5_34">
          оролцож,{" "}
        </span>
        <span id="t32_34" class="t s5_34">
          тэдгээртэй{" "}
        </span>
        <span id="t33_34" class="t s5_34">
          өөрийн{" "}
        </span>
        <span id="t34_34" class="t s5_34">
          зорилго,{" "}
        </span>
        <span id="t35_34" class="t s5_34">
          зорилтоо{" "}
        </span>
        <span id="t36_34" class="t s5_34">
          уялдуулах{" "}
        </span>
        <span id="t37_34" class="t s5_34">
          Сурагчдад{" "}
        </span>
        <span id="t38_34" class="t s5_34">
          тулгамдсан{" "}
        </span>
        <span id="t39_34" class="t s5_34">
          асуудлыг{" "}
        </span>
        <span id="t3a_34" class="t s5_34">
          шийдэхэд{" "}
        </span>
        <span id="t3b_34" class="t s5_34">
          чиглэсэн{" "}
        </span>
        <span id="t3c_34" class="t s5_34">
          шийдэл,{" "}
        </span>
        <span id="t3d_34" class="t s5_34">
          зорилго,{" "}
        </span>
        <span id="t3e_34" class="t s5_34">
          зорилтыг{" "}
        </span>
        <span id="t3f_34" class="t s5_34">
          санал болгох{" "}
        </span>
        <span id="t3g_34" class="t s5_34">
          Ангийн болон{" "}
        </span>
        <span id="t3h_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t3i_34" class="t s5_34">
          зорилго{" "}
        </span>
        <span id="t3j_34" class="t s5_34">
          тодорхойлох{" "}
        </span>
        <span id="t3k_34" class="t s5_34">
          хэлэлцүүлэгт{" "}
        </span>
        <span id="t3l_34" class="t s5_34">
          оролцож,{" "}
        </span>
        <span id="t3m_34" class="t s5_34">
          санал,{" "}
        </span>
        <span id="t3n_34" class="t s5_34">
          шийдлээ{" "}
        </span>
        <span id="t3o_34" class="t s5_34">
          гаргах{" "}
        </span>
        <span id="t3p_34" class="t s5_34">
          Багш нарын{" "}
        </span>
        <span id="t3q_34" class="t s5_34">
          гаргасан{" "}
        </span>
        <span id="t3r_34" class="t s5_34">
          шийдлийн{" "}
        </span>
        <span id="t3s_34" class="t s5_34">
          хувилбаруудыг{" "}
        </span>
        <span id="t3t_34" class="t s5_34">
          харьцуулж,{" "}
        </span>
        <span id="t3u_34" class="t s5_34">
          эрэмбэлэх,{" "}
        </span>
        <span id="t3v_34" class="t s5_34">
          багцлах{" "}
        </span>
        <span id="t3w_34" class="t s5_34">
          Эцэг эх, орон{" "}
        </span>
        <span id="t3x_34" class="t s5_34">
          нутгийн зүгээс{" "}
        </span>
        <span id="t3y_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t3z_34" class="t s5_34">
          зорилго{" "}
        </span>
        <span id="t40_34" class="t s5_34">
          тодорхойлоход{" "}
        </span>
        <span id="t41_34" class="t s5_34">
          өгсөн саналыг{" "}
        </span>
        <span id="t42_34" class="t s5_34">
          нэгтгэх,{" "}
        </span>
        <span id="t43_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t44_34" class="t s5_34">
          зорилгыг{" "}
        </span>
        <span id="t45_34" class="t s5_34">
          эцэслэн{" "}
        </span>
        <span id="t46_34" class="t s5_34">
          тодорхойлоход{" "}
        </span>
        <span id="t47_34" class="t s5_34">
          оролцох{" "}
        </span>
        <span id="t48_34" class="t s5_34">
          Сурагчдын{" "}
        </span>
        <span id="t49_34" class="t s5_34">
          болон ангийн{" "}
        </span>
        <span id="t4a_34" class="t s5_34">
          зорилгыг{" "}
        </span>
        <span id="t4b_34" class="t s5_34">
          нэгтгэж,{" "}
        </span>
        <span id="t4c_34" class="t s5_34">
          сургуулийн{" "}
        </span>
        <span id="t4d_34" class="t s5_34">
          зорилгыг{" "}
        </span>
        <span id="t4e_34" class="t s5_34">
          эцэслэн{" "}
        </span>
        <span id="t4f_34" class="t s5_34">
          тодор-{" "}
        </span>
        <span id="t4g_34" class="t s5_34">
          хойлоход{" "}
        </span>
        <span id="t4h_34" class="t s5_34">
          оролцох{" "}
        </span>
      </div>
    </div>
  );
}
