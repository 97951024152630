import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";

export default function SideBar3({ setPaperIndex, paperIndex }) {
  const menu = [
    {
      name: "Сургуулийн менежмент, асуудал шийдэл",
      paper: 71,
      pages: 3,
    },
    {
      name: "3.1 Суралцагчийн хөгжил, хамгааллыг хэрхэн нэмэгдүүлэх вэ?",
      paper: 72,
      pages: 3,
    },
    {
      name: "3.2 Сургалтын хөтөлбөрийн хэрэгжилтэд хэрхэн дэмжлэг үзүүлэх вэ? ",
      paper: 91,
      pages: 3,
    },
    {
      name: "3.3 Багшийн хөгжил",
      paper: 133,
      pages: 3,
      path: 1,
    },
    {
      name: "3.4 Сургалтыг зохион байгуулах таатай орчныг хэрхэн бүрдүүлэх вэ?",
      paper: 155,
      pages: 3,
      path: 1,
    },
    {
      name: "3.5 Сургуулийн материаллаг нөөцийг бүрдүүлэх аргачлалууд",
      paper: 164,
      pages: 3,
      path: 1,
    },
    {
      name: "3.6 Сургуулийн удирдлага, манлайлал",
      paper: 171,
      pages: 3,
      path: 1,
    },
  ];

  return (
    <List>
      {menu.map((element, index) => (
        <ListItem
          // sx={{
          //   backgroundColor:
          //     paperIndex === element.paper && "rgba(59,130,246,0.5)",
          // }}
          key={index}
          disablePadding
        >
          <a href={`#${element.paper}`} style={{ textDecoration: "none" }}>
            <ListItemButton
              onClick={() => setPaperIndex(element.paper)}
              variant="contained"
            >
              <Grid container spacing={2}>
                <Grid xs={10} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 500,
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={element.name}
                  />
                </Grid>
                <Grid xs={2} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={`(${element.paper})`}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </a>
        </ListItem>
      ))}
    </List>
  );
}
