import React from "react";
export default function SeventyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_73{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_73{left:142px;bottom:923px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_73{left:107px;bottom:879px;letter-spacing:0.07px;word-spacing:-0.29px;}
#t4_73{left:107px;bottom:861px;letter-spacing:0.1px;word-spacing:6.47px;}
#t5_73{left:107px;bottom:843px;letter-spacing:0.13px;word-spacing:1.35px;}
#t6_73{left:107px;bottom:824px;letter-spacing:0.12px;word-spacing:-0.24px;}
#t7_73{left:107px;bottom:806px;letter-spacing:0.09px;word-spacing:3.16px;}
#t8_73{left:107px;bottom:788px;letter-spacing:0.13px;word-spacing:3.58px;}
#t9_73{left:435px;bottom:788px;letter-spacing:0.14px;word-spacing:3.57px;}
#ta_73{left:107px;bottom:769px;letter-spacing:0.08px;word-spacing:0.58px;}
#tb_73{left:389px;bottom:769px;letter-spacing:0.1px;word-spacing:0.58px;}
#tc_73{left:565px;bottom:769px;letter-spacing:0.06px;}
#td_73{left:107px;bottom:751px;letter-spacing:0.11px;word-spacing:5.24px;}
#te_73{left:107px;bottom:733px;letter-spacing:0.13px;}
#tf_73{left:107px;bottom:701px;letter-spacing:0.29px;word-spacing:6.71px;}
#tg_73{left:107px;bottom:683px;letter-spacing:0.25px;word-spacing:7.68px;}
#th_73{left:377px;bottom:683px;letter-spacing:0.26px;word-spacing:7.67px;}
#ti_73{left:107px;bottom:665px;letter-spacing:0.27px;word-spacing:3.79px;}
#tj_73{left:107px;bottom:646px;letter-spacing:0.27px;word-spacing:0.02px;}
#tk_73{left:142px;bottom:618px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tl_73{left:430px;bottom:601px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tm_73{left:142px;bottom:514px;letter-spacing:0.18px;}
#tn_73{left:87px;bottom:481px;}
#to_73{left:113px;bottom:481px;letter-spacing:-0.14px;word-spacing:5.18px;}
#tp_73{left:113px;bottom:461px;letter-spacing:-0.13px;word-spacing:6.1px;}
#tq_73{left:113px;bottom:441px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tr_73{left:87px;bottom:416px;}
#ts_73{left:113px;bottom:416px;letter-spacing:-0.14px;word-spacing:0.54px;}
#tt_73{left:113px;bottom:396px;letter-spacing:-0.17px;word-spacing:2.36px;}
#tu_73{left:113px;bottom:376px;letter-spacing:-0.14px;word-spacing:1.6px;}
#tv_73{left:113px;bottom:356px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tw_73{left:87px;bottom:331px;}
#tx_73{left:113px;bottom:331px;letter-spacing:-0.11px;word-spacing:0.02px;}
#ty_73{left:87px;bottom:307px;}
#tz_73{left:113px;bottom:307px;letter-spacing:-0.12px;word-spacing:-1.88px;}
#t10_73{left:228px;bottom:307px;letter-spacing:-0.14px;word-spacing:-1.88px;}
#t11_73{left:113px;bottom:287px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t12_73{left:87px;bottom:262px;}
#t13_73{left:113px;bottom:262px;letter-spacing:-0.14px;word-spacing:3.61px;}
#t14_73{left:113px;bottom:242px;letter-spacing:-0.17px;word-spacing:0.07px;}

.s0_73{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_73{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_73{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_73{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_73{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_73{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s6_73{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts73" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg73" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="73/73.svg"
          type="image/svg+xml"
          id="pdf73"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_73" class="t s0_73">
          73{" "}
        </span>
        <span id="t2_73" class="t s1_73">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_73" class="t s2_73">
          Нийслэлийн зарим сургууль усан бассейнд үйлчлүүлдэг, тогтмол
          хичээллэдэг{" "}
        </span>
        <span id="t4_73" class="t s2_73">
          хүүхдийн тоог нэмэгдүүлж чадсан байна. Усан спорт нь хүүхдийн бие{" "}
        </span>
        <span id="t5_73" class="t s2_73">
          бялдрын олон чадварыг өв тэгш хөгжүүлэхээс гадна эрүүл мэндийг дэмжих{" "}
        </span>
        <span id="t6_73" class="t s2_73">
          чухал арга зам юм. Мөн хүүхдүүдийн дунд биеийн тамирын спорт
          тэмцээнийг{" "}
        </span>
        <span id="t7_73" class="t s2_73">
          үе шаттайгаар зохион байгуулах, оролцуулах ажлыг хийжээ. Үүнээс гадна{" "}
        </span>
        <span id="t8_73" class="t s2_73">
          хүүхдийн эрүүл мэндийг дэмжих чиглэлээр{" "}
        </span>
        <span id="t9_73" class="t s3_73">
          “Шүдний эрүүл ахуйг дэмжих”{" "}
        </span>
        <span id="ta_73" class="t s2_73">
          ажлууыг хийж гүйцэтгэжээ. Тухайлбал,{" "}
        </span>
        <span id="tb_73" class="t s3_73">
          “Шүд угаалтын аварга”{" "}
        </span>
        <span id="tc_73" class="t s2_73">
          шалгаруулах{" "}
        </span>
        <span id="td_73" class="t s2_73">
          тэмцээнийг хүүхдүүдийн дунд зохион байгуулсан туршлага сургуулиудад{" "}
        </span>
        <span id="te_73" class="t s2_73">
          хэрэгжжээ.{" "}
        </span>
        <span id="tf_73" class="t s2_73">
          Тэрчлэн хүүхдийн эрүүл мэндийг дэмжих, хөдөлгөөний эвсэл суулгах{" "}
        </span>
        <span id="tg_73" class="t s3_73">
          чиглэлээр “Монгол бий биелгээ”{" "}
        </span>
        <span id="th_73" class="t s2_73">
          завсарлагааны хөдөлгөөнт дасгалыг{" "}
        </span>
        <span id="ti_73" class="t s2_73">
          хийж хэвшин, эцэг эх асран хамгаалагч нартайгаа хамтран хийж түгээн{" "}
        </span>
        <span id="tj_73" class="t s2_73">
          дэлгэрүүлсэн байна.{" "}
        </span>
        <span id="tk_73" class="t s4_73">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн
          менежментийг{" "}
        </span>
        <span id="tl_73" class="t s4_73">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tm_73" class="t s1_73">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tn_73" class="t s5_73">
          •{" "}
        </span>
        <span id="to_73" class="t s6_73">
          Сурагчдын амны хөндийн эрүүл ахуй, халдварт өвчнөөс урьдчилан{" "}
        </span>
        <span id="tp_73" class="t s6_73">
          сэргийлэх үйл ажиллагааг мэргэжлийн байгууллага, эцэг эх, асран{" "}
        </span>
        <span id="tq_73" class="t s6_73">
          хамгаалагч, харгалзан дэмжигчидтэй хамтран зохион байгуулах;{" "}
        </span>
        <span id="tr_73" class="t s5_73">
          •{" "}
        </span>
        <span id="ts_73" class="t s6_73">
          Сурагчдын бие бялдрын өсөлт хөгжлийн үнэлгээг сургуулийн эмч, АУБ,{" "}
        </span>
        <span id="tt_73" class="t s6_73">
          биеийн тамирын багштай хамтарч хийдэг, хөгжлийн ахиц, өөрчлөлтөд{" "}
        </span>
        <span id="tu_73" class="t s6_73">
          үнэлгээ өгдөг байх, энэ талаар эцэг эх, асран хамгаалагч, дунд, ахлах{" "}
        </span>
        <span id="tv_73" class="t s6_73">
          ангийн сурагчдад мэдээлэл, зөвлөгөө өгдөг байх;{" "}
        </span>
        <span id="tw_73" class="t s5_73">
          •{" "}
        </span>
        <span id="tx_73" class="t s6_73">
          Сургууль дээр хөдөлгөөнт дасгал хийж хэвших;{" "}
        </span>
        <span id="ty_73" class="t s5_73">
          •{" "}
        </span>
        <span id="tz_73" class="t s6_73">
          Сургууль дээр{" "}
        </span>
        <span id="t10_73" class="t s6_73">
          эрүүл ахуйн шаардлага хангасан хүнсний бүтээгдэхүүнээр{" "}
        </span>
        <span id="t11_73" class="t s6_73">
          үйлчлэх дүрэм журмыг мөрдөж ажиллах;{" "}
        </span>
        <span id="t12_73" class="t s5_73">
          •{" "}
        </span>
        <span id="t13_73" class="t s6_73">
          Сургуулийн биеийн тамирын заал, биеийн тамирын гадна талбай нь{" "}
        </span>
        <span id="t14_73" class="t s6_73">
          стандарт шаардлага хангахуйц байхаар тохижуулах;{" "}
        </span>
      </div>
    </div>
  );
}
