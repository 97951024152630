import React from "react";
export default function EightySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_87{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_87{left:91px;bottom:939px;letter-spacing:0.12px;word-spacing:0.09px;}
#t3_87{left:180px;bottom:912px;letter-spacing:0.14px;word-spacing:0.07px;}
#t4_87{left:87px;bottom:861px;letter-spacing:-0.14px;word-spacing:11.55px;}
#t5_87{left:87px;bottom:841px;letter-spacing:-0.17px;word-spacing:2.61px;}
#t6_87{left:87px;bottom:821px;letter-spacing:-0.15px;word-spacing:6.03px;}
#t7_87{left:87px;bottom:800px;letter-spacing:-0.14px;word-spacing:-0.28px;}
#t8_87{left:87px;bottom:780px;letter-spacing:-0.16px;word-spacing:2.39px;}
#t9_87{left:87px;bottom:760px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ta_87{left:87px;bottom:731px;letter-spacing:-0.12px;word-spacing:5.27px;}
#tb_87{left:87px;bottom:711px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tc_87{left:87px;bottom:682px;letter-spacing:-0.11px;}
#td_87{left:168px;bottom:682px;letter-spacing:-0.14px;word-spacing:-1.29px;}
#te_87{left:87px;bottom:662px;letter-spacing:-0.13px;word-spacing:5.19px;}
#tf_87{left:87px;bottom:642px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tg_87{left:87px;bottom:613px;letter-spacing:-0.13px;word-spacing:0.66px;}
#th_87{left:87px;bottom:593px;letter-spacing:-0.16px;}
#ti_87{left:87px;bottom:564px;letter-spacing:-0.11px;word-spacing:3.32px;}
#tj_87{left:247px;bottom:564px;letter-spacing:-0.14px;word-spacing:3.36px;}
#tk_87{left:87px;bottom:544px;letter-spacing:-0.21px;word-spacing:4.93px;}
#tl_87{left:87px;bottom:524px;letter-spacing:-0.14px;word-spacing:1.54px;}
#tm_87{left:87px;bottom:504px;letter-spacing:-0.14px;word-spacing:5.06px;}
#tn_87{left:87px;bottom:483px;letter-spacing:-0.11px;word-spacing:0.02px;}
#to_87{left:87px;bottom:455px;letter-spacing:-0.1px;}
#tp_87{left:166px;bottom:455px;letter-spacing:-0.13px;word-spacing:0.54px;}
#tq_87{left:87px;bottom:434px;letter-spacing:-0.16px;word-spacing:0.4px;}
#tr_87{left:87px;bottom:414px;letter-spacing:-0.16px;word-spacing:-0.3px;}
#ts_87{left:87px;bottom:394px;letter-spacing:-0.13px;word-spacing:0.42px;}
#tt_87{left:87px;bottom:374px;letter-spacing:-0.15px;word-spacing:0.2px;}
#tu_87{left:87px;bottom:354px;letter-spacing:-0.19px;word-spacing:8.47px;}
#tv_87{left:87px;bottom:334px;letter-spacing:-0.14px;word-spacing:3.38px;}
#tw_87{left:87px;bottom:313px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tx_87{left:87px;bottom:285px;letter-spacing:-0.1px;}
#ty_87{left:168px;bottom:285px;letter-spacing:-0.17px;word-spacing:1.92px;}
#tz_87{left:87px;bottom:264px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t10_87{left:87px;bottom:236px;letter-spacing:-0.13px;word-spacing:0.17px;}
#t11_87{left:87px;bottom:215px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t12_87{left:347px;bottom:224px;letter-spacing:-0.11px;}
#t13_87{left:358px;bottom:215px;}
#t14_87{left:87px;bottom:187px;letter-spacing:-0.1px;}
#t15_87{left:175px;bottom:187px;letter-spacing:-0.13px;word-spacing:9.41px;}
#t16_87{left:87px;bottom:166px;letter-spacing:-0.12px;word-spacing:-0.52px;}
#t17_87{left:87px;bottom:146px;letter-spacing:-0.15px;word-spacing:0.56px;}
#t18_87{left:87px;bottom:115px;letter-spacing:0.12px;}
#t19_87{left:107px;bottom:115px;letter-spacing:0.09px;word-spacing:0.02px;}

.s0_87{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_87{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_87{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_87{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_87{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s5_87{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_87{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s7_87{font-size:12px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts87" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg87" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="87/87.svg"
          type="image/svg+xml"
          id="pdf87"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_87" class="t s0_87">
          87{" "}
        </span>
        <span id="t2_87" class="t s1_87">
          3.1.2 СУРАГЧИЙН ХӨГЖИЛ, ХАМГААЛЛЫН ҮЙЛ АЖИЛЛАГААНЫ{" "}
        </span>
        <span id="t3_87" class="t s1_87">
          БҮТЭЦ, ЗОХИОН БАЙГУУЛАЛТ МЕНЕЖМЕНТ{" "}
        </span>
        <span id="t4_87" class="t s2_87">
          Сургууль нь үйл ажиллагааны зорилго, зорилтоо дэвшүүлэн, үйл{" "}
        </span>
        <span id="t5_87" class="t s2_87">
          ажиллагааны төлөвлөгөө боловсруулдаг. Байгууллагын үйл ажиллагааны{" "}
        </span>
        <span id="t6_87" class="t s2_87">
          зорилго, төлөвлөгөө нь сурагчдын хөгжил, хамгаалалд тулгамдаж буй{" "}
        </span>
        <span id="t7_87" class="t s2_87">
          асуудалд суурилсан, түүнийг шийдвэрлэх бодит алхмуудыг тусгасан байна.{" "}
        </span>
        <span id="t8_87" class="t s2_87">
          Энэ нь сургуулийн үйл ажиллагааны төлөвлөлт, хэрэгжилтийг сайжруулж{" "}
        </span>
        <span id="t9_87" class="t s2_87">
          хяналт үнэлгээний механизм боловсронгуй болох үр дүнтэй.{" "}
        </span>
        <span id="ta_87" class="t s2_87">
          Сурагчийн хөгжил, хамгааллын үйл ажиллагааг дараах алхын хүрээнд{" "}
        </span>
        <span id="tb_87" class="t s2_87">
          зохион байгуулж болно.{" "}
        </span>
        <span id="tc_87" class="t s3_87">
          Алхам-1:{" "}
        </span>
        <span id="td_87" class="t s2_87">
          Сурагчийн хөгжил хамгааллын алба байгуулж хамтын ажиллагааг{" "}
        </span>
        <span id="te_87" class="t s2_87">
          сайжруулах нь хөгжил хамгааллын үйл ажиллагаа үр дүнтэй хэрэгжих{" "}
        </span>
        <span id="tf_87" class="t s2_87">
          боломж юм.{" "}
        </span>
        <span id="tg_87" class="t s2_87">
          Сургуулийн бүхий л үйл ажиллагааны төв нь сурагчийн хөгжил, хамгаалал{" "}
        </span>
        <span id="th_87" class="t s2_87">
          байна.{" "}
        </span>
        <span id="ti_87" class="t s2_87">
          Сурагчийн хөгжил{" "}
        </span>
        <span id="tj_87" class="t s2_87">
          хамгааллын алба нь сургуулийн болон орон нутгийн{" "}
        </span>
        <span id="tk_87" class="t s2_87">
          хүүхэд хамгааллын баг, сургуулийн удирдах зөвлөл, багш, багш нарын{" "}
        </span>
        <span id="tl_87" class="t s2_87">
          бүлэг, бие даасан клуб, эцэг эх, асран хамгаалагч, харгалзан
          дэмжигчийн{" "}
        </span>
        <span id="tm_87" class="t s2_87">
          төлөөлөл, орон нутгийн засаг захиргаа, аж ахуйн нэгж байгууллагатай{" "}
        </span>
        <span id="tn_87" class="t s2_87">
          хамтран ажиллах чиг үүрэгтэй байна.{" "}
        </span>
        <span id="to_87" class="t s3_87">
          Алхам-2:{" "}
        </span>
        <span id="tp_87" class="t s2_87">
          Сурагчийн хөгжил хамгааллын алба нь юуны түрүүнд тулгамдаж{" "}
        </span>
        <span id="tq_87" class="t s2_87">
          буй асуудлыг тодорхойлно. Үүнд байгууллагын гүйцэтгэлийн үнэлгээний үр{" "}
        </span>
        <span id="tr_87" class="t s2_87">
          дүн, ажиглалт, асуулга, ярилцлага, баримт бичгийн шинжилгээний
          аргуудыг{" "}
        </span>
        <span id="ts_87" class="t s2_87">
          хэрэглэх боломжтой ба ингэснээр сурагчийн хөгжил, хамгаалалд зайлшгүй{" "}
        </span>
        <span id="tt_87" class="t s2_87">
          шийдвэрлэх шаардлагатай асуудлыг илрүүлнэ. Хэрэв нэгээс дээш асуудал{" "}
        </span>
        <span id="tu_87" class="t s2_87">
          байвал тэдгээрийг шийдвэрлэх бодит боломж болон ач холбогдлын{" "}
        </span>
        <span id="tv_87" class="t s2_87">
          түвшингээр нь эрэмбэлэн сонголт хийж болно. Энд оролцогчдын санал,{" "}
        </span>
        <span id="tw_87" class="t s2_87">
          хамтын үйл ажиллагаа чухал ач холбогдолтой.{" "}
        </span>
        <span id="tx_87" class="t s3_87">
          Алхам-3:{" "}
        </span>
        <span id="ty_87" class="t s2_87">
          Тулгамдсан асуудалд тулгуурлан тухайн хичээлийн жилийн үйл{" "}
        </span>
        <span id="tz_87" class="t s2_87">
          ажиллагааны зорилго, зорилтоо SMART зарчимд нийцүүлэн дэвшүүлнэ.{" "}
        </span>
        <span id="t10_87" class="t s2_87">
          Сургуулийн эрхэм зорилго, алсын хараа нь хүүхдийн эрхийн тухай хуулийн{" "}
        </span>
        <span id="t11_87" class="t s2_87">
          үндсэн зарчимд суурилсан байна{" "}
        </span>
        <span id="t12_87" class="t s4_87">
          10{" "}
        </span>
        <span id="t13_87" class="t s2_87">
          .{" "}
        </span>
        <span id="t14_87" class="t s3_87">
          Алхам-4:{" "}
        </span>
        <span id="t15_87" class="t s2_87">
          Үйл ажиллагааны зорилго, зорилтын хэрэгжилтэд эергээр{" "}
        </span>
        <span id="t16_87" class="t s2_87">
          нөлөөлөх хүчин зүйлс ба саад болох хүчин зүйлсийг илрүүлнэ. Жишээлбэл,{" "}
        </span>
        <span id="t17_87" class="t s5_87">
          “Сурагчийн задлан шинжлэх, асуудлыг шийдвэрлэх чадварыг сайжруулах”{" "}
        </span>
        <span id="t18_87" class="t s6_87">
          10{" "}
        </span>
        <span id="t19_87" class="t s7_87">
          Хүүхдийн эрхийн тухай хууль{" "}
        </span>
      </div>
    </div>
  );
}
