import React from "react";
export default function TwentyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_28{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_28{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_28{left:87px;bottom:941px;letter-spacing:-0.14px;word-spacing:0.57px;}
#t4_28{left:87px;bottom:921px;letter-spacing:-0.06px;}
#t5_28{left:87px;bottom:888px;letter-spacing:-0.13px;word-spacing:4.98px;}
#t6_28{left:87px;bottom:867px;letter-spacing:-0.12px;word-spacing:2.57px;}
#t7_28{left:87px;bottom:847px;letter-spacing:-0.13px;word-spacing:3.8px;}
#t8_28{left:87px;bottom:827px;letter-spacing:-0.14px;word-spacing:0.92px;}
#t9_28{left:87px;bottom:807px;letter-spacing:-0.2px;word-spacing:0.1px;}
#ta_28{left:87px;bottom:774px;letter-spacing:-0.14px;word-spacing:4.96px;}
#tb_28{left:638px;bottom:774px;letter-spacing:-0.09px;}
#tc_28{left:87px;bottom:754px;letter-spacing:-0.18px;word-spacing:2.9px;}
#td_28{left:87px;bottom:733px;letter-spacing:-0.16px;word-spacing:6.1px;}
#te_28{left:87px;bottom:713px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tf_28{left:87px;bottom:680px;letter-spacing:-0.16px;word-spacing:2.97px;}
#tg_28{left:87px;bottom:660px;letter-spacing:-0.13px;word-spacing:1.09px;}
#th_28{left:87px;bottom:640px;letter-spacing:-0.13px;word-spacing:6.11px;}
#ti_28{left:87px;bottom:620px;letter-spacing:-0.16px;word-spacing:4.58px;}
#tj_28{left:87px;bottom:599px;letter-spacing:-0.15px;word-spacing:0.83px;}
#tk_28{left:87px;bottom:579px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_28{left:87px;bottom:559px;letter-spacing:-0.12px;word-spacing:-2.5px;}
#tm_28{left:87px;bottom:539px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tn_28{left:223px;bottom:509px;letter-spacing:-0.15px;word-spacing:0.02px;}
#to_28{left:482px;bottom:470px;}
#tp_28{left:493px;bottom:470px;letter-spacing:0.1px;word-spacing:0.01px;}
#tq_28{left:493px;bottom:455px;letter-spacing:0.03px;word-spacing:0.08px;}
#tr_28{left:493px;bottom:440px;letter-spacing:0.08px;word-spacing:0.03px;}
#ts_28{left:493px;bottom:426px;letter-spacing:0.1px;word-spacing:0.01px;}
#tt_28{left:493px;bottom:411px;letter-spacing:0.09px;word-spacing:0.02px;}
#tu_28{left:493px;bottom:396px;letter-spacing:0.05px;word-spacing:0.05px;}
#tv_28{left:482px;bottom:382px;}
#tw_28{left:493px;bottom:382px;letter-spacing:0.11px;}
#tx_28{left:493px;bottom:367px;letter-spacing:0.08px;word-spacing:0.03px;}
#ty_28{left:493px;bottom:352px;letter-spacing:0.05px;}
#tz_28{left:482px;bottom:338px;}
#t10_28{left:493px;bottom:338px;letter-spacing:0.09px;word-spacing:0.02px;}
#t11_28{left:493px;bottom:323px;letter-spacing:0.08px;word-spacing:0.03px;}
#t12_28{left:493px;bottom:308px;letter-spacing:0.08px;word-spacing:0.04px;}
#t13_28{left:493px;bottom:294px;letter-spacing:0.08px;word-spacing:0.02px;}
#t14_28{left:493px;bottom:279px;letter-spacing:0.06px;word-spacing:0.05px;}
#t15_28{left:482px;bottom:264px;}
#t16_28{left:493px;bottom:264px;letter-spacing:0.1px;word-spacing:0.02px;}
#t17_28{left:493px;bottom:250px;letter-spacing:0.09px;word-spacing:0.02px;}
#t18_28{left:493px;bottom:235px;letter-spacing:0.05px;word-spacing:0.06px;}
#t19_28{left:493px;bottom:220px;letter-spacing:0.1px;}
#t1a_28{left:482px;bottom:206px;}
#t1b_28{left:493px;bottom:206px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1c_28{left:493px;bottom:191px;letter-spacing:0.1px;word-spacing:0.02px;}
#t1d_28{left:493px;bottom:176px;letter-spacing:0.09px;word-spacing:0.02px;}
#t1e_28{left:493px;bottom:162px;letter-spacing:0.03px;word-spacing:0.08px;}
#t1f_28{left:493px;bottom:147px;letter-spacing:0.03px;word-spacing:0.07px;}
#t1g_28{left:493px;bottom:132px;letter-spacing:0.07px;word-spacing:0.04px;}

.s0_28{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_28{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_28{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_28{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_28{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_28{font-size:12px;font-family:ArialMT_1lq;color:#000;}
.s6_28{font-size:12px;font-family:MogulArial_16s;color:#000;}`}
      </style>
      <style id="fonts28" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lq;
	src: url("fonts/ArialMT_1lq.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg28" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="28/28.svg"
          type="image/svg+xml"
          id="pdf28"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_28" class="t s1_28">
          28{" "}
        </span>
        <span id="t3_28" class="t s2_28">
          ялгаатай учраас уг загварт пирамидын суурийг гурван давхаргаар дүрслэн{" "}
        </span>
        <span id="t4_28" class="t s2_28">
          үзүүлсэн.{" "}
        </span>
        <span id="t5_28" class="t s2_28">
          Сургуулийн хөгжлийг богино, дунд, урт хугацаанд нь харж нөөцөө зөв{" "}
        </span>
        <span id="t6_28" class="t s2_28">
          хуваарилах, үр дүнгээ эргэж нягтлан, орчин нөхцөл, цаг үеэ зөв мэдэрч,{" "}
        </span>
        <span id="t7_28" class="t s2_28">
          дотроосоо өөрчлөгдөн, тасралтгүй хөгжихийн тулд юуг хэрхэн анхаарах{" "}
        </span>
        <span id="t8_28" class="t s2_28">
          вэ? гэдгийг илүү тодорхой болгох үүднээс пирамидын давхарга тус бүрийг{" "}
        </span>
        <span id="t9_28" class="t s2_28">
          тайлбарлаж байна.{" "}
        </span>
        <span id="ta_28" class="t s3_28">
          Суурийн давхарга бол та бидний одоо байгаа орон зай юм.{" "}
        </span>
        <span id="tb_28" class="t s2_28">
          Дээр{" "}
        </span>
        <span id="tc_28" class="t s2_28">
          тайлбарласанчлан мөчлөг бүрт багш нар салангид байдлаар ажилладаг,{" "}
        </span>
        <span id="td_28" class="t s2_28">
          хамтын ажиллагаа учир дутагдалтай, байдгийг хавтайг олон хуваасан{" "}
        </span>
        <span id="te_28" class="t s2_28">
          зураасуудаар дүрслэв.{" "}
        </span>
        <span id="tf_28" class="t s2_28">
          Суурийн гол хэсэгт байгаа том дугуй дүрс бол урт хугацаанд шийдэгдэх{" "}
        </span>
        <span id="tg_28" class="t s2_28">
          суурь асуудлыг илэрхийлж буй бөгөөд түүний гол хэсэгт байх жижиг дугуй{" "}
        </span>
        <span id="th_28" class="t s2_28">
          нь тухайн суурь асуудлаас нэг хичээлийн жилд хэрэгжих боломжтойг{" "}
        </span>
        <span id="ti_28" class="t s2_28">
          нь сургуулийн богино хугацааны буюу жилийн зорилго болгож бодитой{" "}
        </span>
        <span id="tj_28" class="t s2_28">
          тодорхойлохыг илэрхийлсэн болно. Өөрөөр хэлбэл, сурагчдад тулгамдсан{" "}
        </span>
        <span id="tk_28" class="t s2_28">
          асуудлыг илрүүлэхийг “үнэлэх” мөчлөг дээр эхэлж, цаг алдалгүй
          залруулах{" "}
        </span>
        <span id="tl_28" class="t s2_28">
          ажлыг багш өөрийн түвшинд хийхээс гадна тухайн жилийн турш
          сургуулиараа{" "}
        </span>
        <span id="tm_28" class="t s2_28">
          анхаарал хандуулах зорилго болгон тодорхойлно гэсэн үг юм.{" "}
        </span>
        <span id="tn_28" class="t s4_28">
          Зураг 1.6. Пирамидын суурийн давхаргын тайлбар{" "}
        </span>
        <span id="to_28" class="t s5_28">
          •{" "}
        </span>
        <span id="tp_28" class="t s6_28">
          Өмнөх хичээлийн жилийн{" "}
        </span>
        <span id="tq_28" class="t s6_28">
          сургалт, үйл ажиллагааны болон{" "}
        </span>
        <span id="tr_28" class="t s6_28">
          гүйцэтгэлийн үнэлгээний үр дүнд{" "}
        </span>
        <span id="ts_28" class="t s6_28">
          шинжилгээ хийж, боломжтойг нь{" "}
        </span>
        <span id="tt_28" class="t s6_28">
          даруй залруулах, тухайн жилийн{" "}
        </span>
        <span id="tu_28" class="t s6_28">
          зорилго, зорилтод тусгах;{" "}
        </span>
        <span id="tv_28" class="t s5_28">
          •{" "}
        </span>
        <span id="tw_28" class="t s6_28">
          Багшийн хөгжлийн хэрэгцээ,{" "}
        </span>
        <span id="tx_28" class="t s6_28">
          шийдэл, зорилго зорилтыг{" "}
        </span>
        <span id="ty_28" class="t s6_28">
          тодорхойлох;{" "}
        </span>
        <span id="tz_28" class="t s5_28">
          •{" "}
        </span>
        <span id="t10_28" class="t s6_28">
          Сургалтын хөтөлбөрийг{" "}
        </span>
        <span id="t11_28" class="t s6_28">
          хэрэгжүүлэх сургалт үйл{" "}
        </span>
        <span id="t12_28" class="t s6_28">
          ажиллагааны нэгдсэн төлөвлөлт{" "}
        </span>
        <span id="t13_28" class="t s6_28">
          хийх, бага, дунд, ахлах ангийн{" "}
        </span>
        <span id="t14_28" class="t s6_28">
          түвшинд буулгаж уялдуулах;{" "}
        </span>
        <span id="t15_28" class="t s5_28">
          •{" "}
        </span>
        <span id="t16_28" class="t s6_28">
          Жилийн төлөвлөлтийг{" "}
        </span>
        <span id="t17_28" class="t s6_28">
          хэрэгжүүлэх нөөц, орчинг{" "}
        </span>
        <span id="t18_28" class="t s6_28">
          бүрдүүлэх, талуудын оролцоотой{" "}
        </span>
        <span id="t19_28" class="t s6_28">
          хэрэгжүүлэх;{" "}
        </span>
        <span id="t1a_28" class="t s5_28">
          •{" "}
        </span>
        <span id="t1b_28" class="t s6_28">
          Богино хугацааны үйл{" "}
        </span>
        <span id="t1c_28" class="t s6_28">
          ажиллагааны үр дүнгийн шалгуур{" "}
        </span>
        <span id="t1d_28" class="t s6_28">
          үзүүлэлт нь сурагчийн ахиц{" "}
        </span>
        <span id="t1e_28" class="t s6_28">
          амжилт, гүйцэтгэлийн үнэлгээний{" "}
        </span>
        <span id="t1f_28" class="t s6_28">
          өгөгдөл, мэдээлэл байх тул{" "}
        </span>
        <span id="t1g_28" class="t s6_28">
          мэдээллийг системтэй цуглуулах;{" "}
        </span>
      </div>
    </div>
  );
}
