import React from "react";
export default function OneHundredSeventySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_177{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_177{left:87px;bottom:942px;letter-spacing:-0.14px;word-spacing:4.69px;}
#t3_177{left:87px;bottom:922px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t4_177{left:153px;bottom:873px;letter-spacing:0.08px;word-spacing:0.06px;}
#t5_177{left:597px;bottom:855px;letter-spacing:0.14px;}
#t6_177{left:91px;bottom:822px;letter-spacing:-0.16px;}
#t7_177{left:91px;bottom:803px;letter-spacing:-0.13px;}
#t8_177{left:144px;bottom:822px;letter-spacing:-0.18px;word-spacing:1.88px;}
#t9_177{left:144px;bottom:803px;letter-spacing:-0.15px;word-spacing:-0.78px;}
#ta_177{left:144px;bottom:785px;letter-spacing:-0.16px;word-spacing:1.35px;}
#tb_177{left:144px;bottom:767px;letter-spacing:-0.11px;}
#tc_177{left:91px;bottom:731px;letter-spacing:-0.16px;}
#td_177{left:91px;bottom:713px;letter-spacing:-0.19px;}
#te_177{left:144px;bottom:731px;letter-spacing:-0.16px;word-spacing:3.11px;}
#tf_177{left:144px;bottom:713px;letter-spacing:-0.16px;word-spacing:2.29px;}
#tg_177{left:144px;bottom:695px;letter-spacing:-0.16px;word-spacing:0.03px;}
#th_177{left:91px;bottom:658px;letter-spacing:-0.16px;}
#ti_177{left:91px;bottom:639px;letter-spacing:-0.13px;}
#tj_177{left:144px;bottom:658px;letter-spacing:-0.14px;word-spacing:0.02px;}
#tk_177{left:507px;bottom:658px;letter-spacing:-0.2px;word-spacing:0.09px;}
#tl_177{left:144px;bottom:639px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tm_177{left:144px;bottom:621px;letter-spacing:-0.16px;word-spacing:0.04px;}
#tn_177{left:144px;bottom:603px;letter-spacing:-0.17px;word-spacing:6.05px;}
#to_177{left:144px;bottom:584px;letter-spacing:-0.17px;word-spacing:1.56px;}
#tp_177{left:144px;bottom:566px;letter-spacing:-0.18px;word-spacing:-1.56px;}
#tq_177{left:144px;bottom:548px;letter-spacing:-0.15px;word-spacing:0.94px;}
#tr_177{left:144px;bottom:529px;letter-spacing:-0.14px;word-spacing:0.01px;}
#ts_177{left:404px;bottom:529px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tt_177{left:91px;bottom:484px;letter-spacing:-0.16px;}
#tu_177{left:91px;bottom:466px;letter-spacing:-0.14px;}
#tv_177{left:144px;bottom:484px;letter-spacing:-0.18px;word-spacing:6.27px;}
#tw_177{left:144px;bottom:466px;letter-spacing:-0.22px;}
#tx_177{left:87px;bottom:414px;letter-spacing:-0.05px;word-spacing:0.02px;}
#ty_177{left:87px;bottom:393px;letter-spacing:0.03px;word-spacing:5.79px;}
#tz_177{left:379px;bottom:393px;letter-spacing:0.02px;word-spacing:5.82px;}
#t10_177{left:87px;bottom:373px;letter-spacing:-0.01px;word-spacing:6.79px;}
#t11_177{left:87px;bottom:353px;letter-spacing:0.03px;word-spacing:0.04px;}

.s0_177{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_177{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_177{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_177{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_177{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts177" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg177" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="177/177.svg"
          type="image/svg+xml"
          id="pdf177"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_177" class="t s0_177">
          177{" "}
        </span>
        <span id="t2_177" class="t s1_177">
          сургуулийн зорилго, зорилтод тусгах саналыг оруулах эсэхийг шийдэж,{" "}
        </span>
        <span id="t3_177" class="t s1_177">
          хүүхэд хөгжил, хамгааллын албад хүргүүлнэ.{" "}
        </span>
        <span id="t4_177" class="t s2_177">
          Хүснэгт 3.24. Сургуулийн сургалт, үйл ажиллагааны төлөвлөлтийг нэгтгэх{" "}
        </span>
        <span id="t5_177" class="t s2_177">
          алхамчлал{" "}
        </span>
        <span id="t6_177" class="t s3_177">
          Алхам{" "}
        </span>
        <span id="t7_177" class="t s3_177">
          нэг{" "}
        </span>
        <span id="t8_177" class="t s4_177">
          Сурагчийн гүйцэтгэл, хүсэл сонирхолд тулгуурлаж багш зорилгоо
          тодорхойлоод,{" "}
        </span>
        <span id="t9_177" class="t s4_177">
          анхан шатны нэгжийн (заах аргын секц эсвэл заах аргын нэгдэл) хүрээнд
          хэлэлцэж,{" "}
        </span>
        <span id="ta_177" class="t s4_177">
          нэгтгээд дараагийн шатны нэгжид (заах аргын нэгдэл эсвэл сургалтын
          менежер){" "}
        </span>
        <span id="tb_177" class="t s4_177">
          хүргүүлнэ.{" "}
        </span>
        <span id="tc_177" class="t s3_177">
          Алхам{" "}
        </span>
        <span id="td_177" class="t s3_177">
          хоёр{" "}
        </span>
        <span id="te_177" class="t s4_177">
          Анхан шатны нэгжүүдийн саналыг* нэгтгэж, дараагийн шатанд
          хэлэлцүүлэхээр{" "}
        </span>
        <span id="tf_177" class="t s4_177">
          хүргүүлнэ. *Жишээ нь: Шаардлагатай гэж үзвэл анхан шатны нэгжээс
          ирүүлсэн{" "}
        </span>
        <span id="tg_177" class="t s4_177">
          саналд зөвлөн тусалж, засаж сайжруулах чиглэл өгч болно.{" "}
        </span>
        <span id="th_177" class="t s3_177">
          Алхам{" "}
        </span>
        <span id="ti_177" class="t s3_177">
          гурав{" "}
        </span>
        <span id="tj_177" class="t s4_177">
          Ирүүлсэн саналыг сургуулийн менежментийн баг* - аар{" "}
        </span>
        <span id="tk_177" class="t s4_177">
          хэлэлцэж, батална.{" "}
        </span>
        <span id="tl_177" class="t s4_177">
          *Жишээ нь: Шаардлагатай гэж үзвэл дунд шатны нэгжээс ирүүлсэн саналд
          зөвлөн{" "}
        </span>
        <span id="tm_177" class="t s4_177">
          тусалж, засаж сайжруулах чиглэл өгч болно.{" "}
        </span>
        <span id="tn_177" class="t s4_177">
          Баталсан зорилго, төлөвлөлтийг оролцогч талуудын төлөөллийн 10.0 хувьд{" "}
        </span>
        <span id="to_177" class="t s4_177">
          хэлэлцүүлж, саналыг харгалзан сайжруулалт хийнэ. Тухайлбал, сургуулийн
          нийт{" "}
        </span>
        <span id="tp_177" class="t s4_177">
          сурагчдын 10.0 хувь гэх мэт. Сайжруулж баталсан зорилго, төлөвлөлтийг
          сургуулийн{" "}
        </span>
        <span id="tq_177" class="t s4_177">
          захиралд хүргүүлнэ. Хэлэлцүүлгийн үе шатанд захирал саналаа хүргүүлж
          болно.{" "}
        </span>
        <span id="tr_177" class="t s4_177">
          Мөн захирал нэгжүүдэд зөвлөн тусалж,{" "}
        </span>
        <span id="ts_177" class="t s4_177">
          саналаа тусгаж болно.{" "}
        </span>
        <span id="tt_177" class="t s3_177">
          Алхам{" "}
        </span>
        <span id="tu_177" class="t s3_177">
          дөрөв{" "}
        </span>
        <span id="tv_177" class="t s4_177">
          Менежментийн багаас баталсан зорилго, төлөвлөтлтийг сургуулийн захирал{" "}
        </span>
        <span id="tw_177" class="t s4_177">
          баталгажуулна.{" "}
        </span>
        <span id="tx_177" class="t s1_177">
          Менежментийн баг батлагдсан сургалт, үйл ажиллагааны зорилго, зорилт,{" "}
        </span>
        <span id="ty_177" class="t s1_177">
          үйл ажиллагааны төлөвлөгөөгөө{" "}
        </span>
        <span id="tz_177" class="t s1_177">
          дараах хүснэгтэн хэлбэрт оруулна.{" "}
        </span>
        <span id="t10_177" class="t s1_177">
          Мөн нэгдсэн “Сургуулийн сургалт, үйл ажиллагааны календарчилсан{" "}
        </span>
        <span id="t11_177" class="t s1_177">
          төлөвлөгөө” – г хийж нээлттэй тавьж болно.{" "}
        </span>
      </div>
    </div>
  );
}
