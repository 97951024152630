import React from "react";
export default function HundredTwentyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_122{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_122{left:87px;bottom:941px;letter-spacing:-0.1px;word-spacing:0.97px;}
#t3_122{left:87px;bottom:923px;letter-spacing:-0.09px;}
#t4_122{left:87px;bottom:889px;letter-spacing:-0.1px;word-spacing:7.42px;}
#t5_122{left:87px;bottom:869px;letter-spacing:-0.1px;word-spacing:5.47px;}
#t6_122{left:87px;bottom:849px;letter-spacing:-0.1px;word-spacing:5.26px;}
#t7_122{left:87px;bottom:829px;letter-spacing:-0.1px;}
#t8_122{left:274px;bottom:798px;letter-spacing:0.1px;word-spacing:0.04px;}
#t9_122{left:109px;bottom:761px;letter-spacing:-0.18px;}
#ta_122{left:202px;bottom:761px;letter-spacing:-0.15px;}
#tb_122{left:294px;bottom:761px;letter-spacing:-0.23px;}
#tc_122{left:455px;bottom:761px;letter-spacing:-0.21px;word-spacing:0.08px;}
#td_122{left:91px;bottom:695px;letter-spacing:-0.15px;}
#te_122{left:188px;bottom:511px;letter-spacing:-0.13px;word-spacing:0.02px;}
#tf_122{left:188px;bottom:493px;letter-spacing:-0.14px;word-spacing:0.02px;}
#tg_122{left:188px;bottom:475px;letter-spacing:-0.17px;}
#th_122{left:188px;bottom:456px;letter-spacing:-0.16px;}
#ti_122{left:273px;bottom:704px;letter-spacing:-0.13px;}
#tj_122{left:273px;bottom:686px;letter-spacing:-0.16px;}
#tk_122{left:369px;bottom:731px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tl_122{left:369px;bottom:713px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tm_122{left:369px;bottom:695px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tn_122{left:369px;bottom:676px;letter-spacing:-0.14px;word-spacing:0.02px;}
#to_122{left:369px;bottom:658px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tp_122{left:91px;bottom:598px;letter-spacing:-0.14px;}
#tq_122{left:91px;bottom:580px;letter-spacing:-0.11px;}
#tr_122{left:273px;bottom:589px;letter-spacing:-0.13px;}
#ts_122{left:273px;bottom:571px;letter-spacing:-0.17px;}
#tt_122{left:369px;bottom:635px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tu_122{left:369px;bottom:617px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tv_122{left:369px;bottom:598px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tw_122{left:369px;bottom:580px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tx_122{left:369px;bottom:562px;letter-spacing:-0.19px;word-spacing:0.07px;}
#ty_122{left:369px;bottom:543px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tz_122{left:369px;bottom:525px;letter-spacing:-0.2px;word-spacing:0.08px;}
#t10_122{left:91px;bottom:465px;letter-spacing:-0.12px;}
#t11_122{left:91px;bottom:447px;letter-spacing:-0.22px;}
#t12_122{left:273px;bottom:484px;letter-spacing:-0.12px;}
#t13_122{left:273px;bottom:465px;letter-spacing:-0.15px;}
#t14_122{left:273px;bottom:447px;letter-spacing:-0.25px;}
#t15_122{left:273px;bottom:429px;letter-spacing:-0.16px;}
#t16_122{left:369px;bottom:502px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t17_122{left:369px;bottom:484px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t18_122{left:369px;bottom:465px;letter-spacing:-0.2px;word-spacing:0.07px;}
#t19_122{left:369px;bottom:447px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t1a_122{left:369px;bottom:429px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1b_122{left:369px;bottom:410px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t1c_122{left:91px;bottom:369px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1d_122{left:91px;bottom:351px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1e_122{left:273px;bottom:387px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1f_122{left:273px;bottom:369px;letter-spacing:-0.1px;word-spacing:-0.02px;}
#t1g_122{left:273px;bottom:351px;letter-spacing:-0.17px;}
#t1h_122{left:273px;bottom:332px;letter-spacing:-0.17px;}
#t1i_122{left:369px;bottom:387px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t1j_122{left:369px;bottom:369px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t1k_122{left:369px;bottom:351px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t1l_122{left:369px;bottom:332px;letter-spacing:-0.2px;word-spacing:0.06px;}
#t1m_122{left:91px;bottom:300px;letter-spacing:-0.15px;}
#t1n_122{left:91px;bottom:282px;letter-spacing:-0.14px;}
#t1o_122{left:91px;bottom:264px;letter-spacing:-0.12px;}
#t1p_122{left:91px;bottom:245px;letter-spacing:-0.09px;}
#t1q_122{left:273px;bottom:300px;letter-spacing:-0.31px;word-spacing:0.2px;}
#t1r_122{left:273px;bottom:282px;letter-spacing:-0.16px;}
#t1s_122{left:273px;bottom:264px;letter-spacing:-0.14px;}
#t1t_122{left:273px;bottom:245px;letter-spacing:-0.16px;}
#t1u_122{left:369px;bottom:310px;letter-spacing:-0.12px;}
#t1v_122{left:369px;bottom:291px;letter-spacing:-0.12px;}
#t1w_122{left:369px;bottom:273px;letter-spacing:-0.13px;}
#t1x_122{left:369px;bottom:255px;letter-spacing:-0.13px;}
#t1y_122{left:369px;bottom:236px;letter-spacing:-0.12px;}

.s0_122{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_122{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_122{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_122{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts122" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg122" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="122/122.svg"
          type="image/svg+xml"
          id="pdf122"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_122" class="t s0_122">
          122{" "}
        </span>
        <span id="t2_122" class="t s1_122">
          Гэвч эдгээр үнэлгээнээс бид зөвхөн сул гүйцэтгэлтэй болон хоцрогдолтой{" "}
        </span>
        <span id="t3_122" class="t s1_122">
          байгаа агуулгыг л ялган харж чаддаг.{" "}
        </span>
        <span id="t4_122" class="t s1_122">
          Тэгвэл эдгээр олон төрлийн үнэлгээний зорилго, хэлбэрээс хамаарч{" "}
        </span>
        <span id="t5_122" class="t s1_122">
          сургууль, багш, сурагч, эцэг эх, асран хамгаалагч, харгалзан дэмжигч{" "}
        </span>
        <span id="t6_122" class="t s1_122">
          дараах олон мэдээллийг цуглуулж багшлахуй суралцахуйг сайжруулах{" "}
        </span>
        <span id="t7_122" class="t s1_122">
          боломж байгааг багцлан тоймлон орууллаа.{" "}
        </span>
        <span id="t8_122" class="t s2_122">
          Хүснэгт 3.10. Үнэлгээний үр дүнгээс цуглуулах мэдээлэл{" "}
        </span>
        <span id="t9_122" class="t s3_122">
          Зорилго{" "}
        </span>
        <span id="ta_122" class="t s3_122">
          Түвшин{" "}
        </span>
        <span id="tb_122" class="t s3_122">
          Хэлбэр{" "}
        </span>
        <span id="tc_122" class="t s3_122">
          Цуглуулах мэдээлэл{" "}
        </span>
        <span id="td_122" class="t s3_122">
          Оношлох{" "}
        </span>
        <span id="te_122" class="t s3_122">
          Анги танхи{" "}
        </span>
        <span id="tf_122" class="t s3_122">
          дахь болон{" "}
        </span>
        <span id="tg_122" class="t s3_122">
          сургуулийн{" "}
        </span>
        <span id="th_122" class="t s3_122">
          үнэлгээ{" "}
        </span>
        <span id="ti_122" class="t s3_122">
          Оношлох{" "}
        </span>
        <span id="tj_122" class="t s3_122">
          үнэлгээ{" "}
        </span>
        <span id="tk_122" class="t s3_122">
          Гүйцэтгэлд үндэслэн хоцрогдсон болон ахисан{" "}
        </span>
        <span id="tl_122" class="t s3_122">
          түвшинд тохируулан агуулгаа хаанаас эхлэх{" "}
        </span>
        <span id="tm_122" class="t s3_122">
          зорилгоо тодорхойлох , чадварын гүйцэтгэлд{" "}
        </span>
        <span id="tn_122" class="t s3_122">
          үндэслэн арга зүй, даалгавар, үйл ажиллагаа,{" "}
        </span>
        <span id="to_122" class="t s3_122">
          арга зүйгээ хэрхэн төлөвлөх{" "}
        </span>
        <span id="tp_122" class="t s3_122">
          Суралцахуйг{" "}
        </span>
        <span id="tq_122" class="t s3_122">
          дэмжих{" "}
        </span>
        <span id="tr_122" class="t s3_122">
          Явцын{" "}
        </span>
        <span id="ts_122" class="t s3_122">
          үнэлгээ{" "}
        </span>
        <span id="tt_122" class="t s3_122">
          Төлөвлөсөн даалгавар үйл ажиллагааг{" "}
        </span>
        <span id="tu_122" class="t s3_122">
          хэрэгжүүлэх гүйцэтгэж буй явцад нь сурагчдын{" "}
        </span>
        <span id="tv_122" class="t s3_122">
          алдаа болон ахицыг ажиглан зөвлөх{" "}
        </span>
        <span id="tw_122" class="t s3_122">
          чиглүүлэх, суралцахуйг дэмжих, ялгаатай{" "}
        </span>
        <span id="tx_122" class="t s3_122">
          байдалд үндэслэн арга зүй, төлөвлөлт,{" "}
        </span>
        <span id="ty_122" class="t s3_122">
          даалгаврын тавил, хэрэглэгдэхүүнд өөрчлөлт{" "}
        </span>
        <span id="tz_122" class="t s3_122">
          оруулан тохируулах{" "}
        </span>
        <span id="t10_122" class="t s3_122">
          Ахицыг{" "}
        </span>
        <span id="t11_122" class="t s3_122">
          тооцох{" "}
        </span>
        <span id="t12_122" class="t s3_122">
          Нэгжийн{" "}
        </span>
        <span id="t13_122" class="t s3_122">
          болон{" "}
        </span>
        <span id="t14_122" class="t s3_122">
          улирлын{" "}
        </span>
        <span id="t15_122" class="t s3_122">
          үнэлгээ{" "}
        </span>
        <span id="t16_122" class="t s3_122">
          Сургалтын эхэнд ба төгсгөлд хийж харьцуулж{" "}
        </span>
        <span id="t17_122" class="t s3_122">
          ахиц өөрчлөлтийг тооцдог{" "}
        </span>
        <span id="t18_122" class="t s3_122">
          Суралцахуйн зорилт, шалгуур болон{" "}
        </span>
        <span id="t19_122" class="t s3_122">
          суралцахуйн үр дүнд хэрхэн хүрч буй байдлыг{" "}
        </span>
        <span id="t1a_122" class="t s3_122">
          илрүүлэх, гүйцэтгэлд үндэслэн дараагийн{" "}
        </span>
        <span id="t1b_122" class="t s3_122">
          нэгж/улирлын төлөвлөлтөд тохируулга хийх{" "}
        </span>
        <span id="t1c_122" class="t s3_122">
          Сургалтын үр{" "}
        </span>
        <span id="t1d_122" class="t s3_122">
          дүнг тооцох{" "}
        </span>
        <span id="t1e_122" class="t s3_122">
          Төгсөх болон{" "}
        </span>
        <span id="t1f_122" class="t s3_122">
          анги дэвших{" "}
        </span>
        <span id="t1g_122" class="t s3_122">
          шалгалтын{" "}
        </span>
        <span id="t1h_122" class="t s3_122">
          үнэлгээ{" "}
        </span>
        <span id="t1i_122" class="t s3_122">
          Суралцахуйн зорилт, шалгуур, суралцахуйн үр{" "}
        </span>
        <span id="t1j_122" class="t s3_122">
          дүнд хүрсэн байдлыг илрүүлэх, хөтөлбөрийн{" "}
        </span>
        <span id="t1k_122" class="t s3_122">
          хэрэгжилтийн дунд хугацааны үр дүнг{" "}
        </span>
        <span id="t1l_122" class="t s3_122">
          тодорхойлж сайжруулах{" "}
        </span>
        <span id="t1m_122" class="t s3_122">
          Метакогнитив{" "}
        </span>
        <span id="t1n_122" class="t s3_122">
          чадвар,{" "}
        </span>
        <span id="t1o_122" class="t s3_122">
          хөгжлийг{" "}
        </span>
        <span id="t1p_122" class="t s3_122">
          дэмжих{" "}
        </span>
        <span id="t1q_122" class="t s3_122">
          Сургалт, үйл{" "}
        </span>
        <span id="t1r_122" class="t s3_122">
          ажиллагааны{" "}
        </span>
        <span id="t1s_122" class="t s3_122">
          тайлан,{" "}
        </span>
        <span id="t1t_122" class="t s3_122">
          дүгнэлт{" "}
        </span>
        <span id="t1u_122" class="t s3_122">
          Хичээлээс гадуурх болон сургалтыг дэмжих{" "}
        </span>
        <span id="t1v_122" class="t s3_122">
          үйл ажиллагааны үр дүнг тооцох сургалтын{" "}
        </span>
        <span id="t1w_122" class="t s3_122">
          хөтөлбөртэй уялдуулан СДҮА-г хамтран{" "}
        </span>
        <span id="t1x_122" class="t s3_122">
          төлөвлөх, хөтөлбөрийн хэрэгжилтийг дэмжих{" "}
        </span>
        <span id="t1y_122" class="t s3_122">
          сайжруулах{" "}
        </span>
      </div>
    </div>
  );
}
