import React from "react";
export default function HundredTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_102{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_102{left:130px;bottom:941px;letter-spacing:-0.14px;word-spacing:2.81px;}
#t3_102{left:152px;bottom:921px;letter-spacing:-0.13px;word-spacing:-0.06px;}
#t4_102{left:152px;bottom:901px;letter-spacing:-0.25px;word-spacing:0.16px;}
#t5_102{left:87px;bottom:848px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t6_102{left:114px;bottom:828px;}
#t7_102{left:142px;bottom:828px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t8_102{left:114px;bottom:808px;}
#t9_102{left:142px;bottom:808px;letter-spacing:-0.15px;word-spacing:6.82px;}
#ta_102{left:142px;bottom:788px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_102{left:114px;bottom:767px;}
#tc_102{left:142px;bottom:767px;letter-spacing:-0.14px;word-spacing:-1.33px;}
#td_102{left:142px;bottom:747px;letter-spacing:-0.12px;}
#te_102{left:114px;bottom:727px;}
#tf_102{left:142px;bottom:727px;letter-spacing:-0.17px;word-spacing:6.53px;}
#tg_102{left:142px;bottom:707px;letter-spacing:-0.15px;word-spacing:0.06px;}
#th_102{left:114px;bottom:687px;}
#ti_102{left:142px;bottom:687px;letter-spacing:-0.15px;word-spacing:9.2px;}
#tj_102{left:142px;bottom:667px;letter-spacing:-0.13px;word-spacing:0.02px;}
#tk_102{left:114px;bottom:646px;}
#tl_102{left:142px;bottom:646px;letter-spacing:-0.14px;word-spacing:6.21px;}
#tm_102{left:142px;bottom:626px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tn_102{left:114px;bottom:606px;}
#to_102{left:142px;bottom:606px;letter-spacing:-0.13px;word-spacing:0.82px;}
#tp_102{left:142px;bottom:586px;letter-spacing:-0.17px;word-spacing:2.85px;}
#tq_102{left:142px;bottom:566px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tr_102{left:87px;bottom:528px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ts_102{left:114px;bottom:504px;}
#tt_102{left:142px;bottom:504px;letter-spacing:-0.11px;word-spacing:1.3px;}
#tu_102{left:142px;bottom:484px;letter-spacing:-0.17px;word-spacing:-0.64px;}
#tv_102{left:142px;bottom:463px;letter-spacing:-0.14px;word-spacing:0.3px;}
#tw_102{left:142px;bottom:443px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tx_102{left:114px;bottom:419px;}
#ty_102{left:142px;bottom:419px;letter-spacing:-0.13px;word-spacing:11.57px;}
#tz_102{left:142px;bottom:399px;letter-spacing:-0.12px;word-spacing:-0.43px;}
#t10_102{left:114px;bottom:374px;}
#t11_102{left:142px;bottom:374px;letter-spacing:-0.18px;word-spacing:5.83px;}
#t12_102{left:142px;bottom:354px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t13_102{left:114px;bottom:329px;}
#t14_102{left:142px;bottom:329px;letter-spacing:-0.13px;word-spacing:4.47px;}
#t15_102{left:142px;bottom:309px;letter-spacing:-0.12px;word-spacing:4.97px;}
#t16_102{left:142px;bottom:289px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t17_102{left:114px;bottom:265px;}
#t18_102{left:142px;bottom:265px;letter-spacing:-0.13px;word-spacing:-0.97px;}
#t19_102{left:142px;bottom:244px;letter-spacing:-0.13px;word-spacing:1.32px;}
#t1a_102{left:142px;bottom:224px;letter-spacing:-0.15px;word-spacing:0.06px;}

.s0_102{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_102{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_102{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts102" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg102" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="102/102.svg"
          type="image/svg+xml"
          id="pdf102"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_102" class="t s0_102">
          102{" "}
        </span>
        <span id="t2_102" class="t s1_102">
          4. Санал болгож буй сургалтын хөтөлбөрийн өөрчлөлтийн үнэ цэн,{" "}
        </span>
        <span id="t3_102" class="t s1_102">
          сурагчдын оруулах хувь нэмрийг тэдэнд ойлгуулахад албан болон{" "}
        </span>
        <span id="t4_102" class="t s1_102">
          албан бус хэлбэрээр таниулдаг.{" "}
        </span>
        <span id="t5_102" class="t s2_102">
          Сургуулийн удирдлага, багшийн хамтын ажиллагаанд анхаарах зүйлс:{" "}
        </span>
        <span id="t6_102" class="t s1_102">
          -{" "}
        </span>
        <span id="t7_102" class="t s1_102">
          Сурагчдын үнэлгээг багш хамтран хийх менежментийг хэрэгжүүлэх;{" "}
        </span>
        <span id="t8_102" class="t s1_102">
          -{" "}
        </span>
        <span id="t9_102" class="t s1_102">
          Үр дүнд шинжилгээ хийхэд хамтарч хичээл заах, цаг ашиглах{" "}
        </span>
        <span id="ta_102" class="t s1_102">
          боломжийг бүрдүүлэх;{" "}
        </span>
        <span id="tb_102" class="t s1_102">
          -{" "}
        </span>
        <span id="tc_102" class="t s1_102">
          Багш боломжтой цагаараа бусад багшийн хичээл, сургалтад дэмжлэг{" "}
        </span>
        <span id="td_102" class="t s1_102">
          үзүүлэх;{" "}
        </span>
        <span id="te_102" class="t s1_102">
          -{" "}
        </span>
        <span id="tf_102" class="t s1_102">
          Сургалтын хэрэглэгдэхүүнийг хамтран бэлтгэх, хэрэглэгдэхүүний{" "}
        </span>
        <span id="tg_102" class="t s1_102">
          нэгдсэн сан бүрдүүлэх;{" "}
        </span>
        <span id="th_102" class="t s1_102">
          -{" "}
        </span>
        <span id="ti_102" class="t s1_102">
          Бүлэг сэдвийн үнэлгээний даалгаврын сан бүрдүүлэхэд багш{" "}
        </span>
        <span id="tj_102" class="t s1_102">
          хамтран ажиллах нөхцөл бүрдүүлэх;{" "}
        </span>
        <span id="tk_102" class="t s1_102">
          -{" "}
        </span>
        <span id="tl_102" class="t s1_102">
          Дотуур байранд амьдардаг сурагчдад зориулсан хөгжүүлэх үйл{" "}
        </span>
        <span id="tm_102" class="t s1_102">
          ажиллагаанд багшийг татан оролцуулах;{" "}
        </span>
        <span id="tn_102" class="t s1_102">
          -{" "}
        </span>
        <span id="to_102" class="t s1_102">
          Багш, эцэг эхчүүдийн хамтын ажиллагааг үүссэн нөхцөл байдалтай{" "}
        </span>
        <span id="tp_102" class="t s1_102">
          холбоотойгоор тусгайлан авч үзэж, зөвлөмжид тусгасан чиглэлийг{" "}
        </span>
        <span id="tq_102" class="t s1_102">
          хэрэгжүүлэх нөхцөлийг бүрдүүлэх зэрэг болно.{" "}
        </span>
        <span id="tr_102" class="t s2_102">
          Багш, эцэг эхийн хамтын үйл ажиллагаанд анхаарах зүйлс:{" "}
        </span>
        <span id="ts_102" class="t s1_102">
          -{" "}
        </span>
        <span id="tt_102" class="t s1_102">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигчийн эерэг туршлагыг{" "}
        </span>
        <span id="tu_102" class="t s1_102">
          судалж, цаашид хүүхдийн сурах арга барил, мэдлэг, чадвар эзэмших{" "}
        </span>
        <span id="tv_102" class="t s1_102">
          үйл ажиллагаанд гэр бүл, олон нийтийн оролцоо, сургууль, багштай{" "}
        </span>
        <span id="tw_102" class="t s1_102">
          хамтран ажиллах боломжийг бүрдүүлж ажиллах{" "}
        </span>
        <span id="tx_102" class="t s1_102">
          -{" "}
        </span>
        <span id="ty_102" class="t s1_102">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч хүүхдийнхээ{" "}
        </span>
        <span id="tz_102" class="t s1_102">
          боловсрол, хөгжлийг гэр бүлийн орчинд дэмжих чадварыг хөгжүүлэх{" "}
        </span>
        <span id="t10_102" class="t s1_102">
          -{" "}
        </span>
        <span id="t11_102" class="t s1_102">
          Сурагчдын суурь чадвар, мэдлэг, чадварын түвшин, сурах арга{" "}
        </span>
        <span id="t12_102" class="t s1_102">
          барилын онцлог ямар байгаа талаар мэдээллээр хангах{" "}
        </span>
        <span id="t13_102" class="t s1_102">
          -{" "}
        </span>
        <span id="t14_102" class="t s1_102">
          Сурагчдын сурлагын амжилтыг дээшлүүлэхэд хүүхэддээ эцэг эх,{" "}
        </span>
        <span id="t15_102" class="t s1_102">
          асран хамгаалагч, харгалзан дэмжигч гэр бүлийн нөхцөлд ямар{" "}
        </span>
        <span id="t16_102" class="t s1_102">
          дэмжлэг үзүүлэх талаар багш хамтран зөвлөгөө өгөх{" "}
        </span>
        <span id="t17_102" class="t s1_102">
          -{" "}
        </span>
        <span id="t18_102" class="t s1_102">
          Эцэг эх, асран хамгаалагч, харгалзан дэмжигч хурлыг хичээлийн жил{" "}
        </span>
        <span id="t19_102" class="t s1_102">
          эхэлмэгц, мөн сарын дараа сурагчийн хөгжилд ямар ахиц гаргасан{" "}
        </span>
        <span id="t1a_102" class="t s1_102">
          талаар мэдээлэл өгөх хурлыг дахин хийх{" "}
        </span>
      </div>
    </div>
  );
}
