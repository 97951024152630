import React from "react";
export default function F142() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_142{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_142{left:107px;bottom:939px;letter-spacing:0.15px;word-spacing:0.05px;}
#t3_142{left:201px;bottom:913px;letter-spacing:-0.04px;word-spacing:0.26px;}
#t4_142{left:142px;bottom:870px;}
#t5_142{left:143px;bottom:839px;letter-spacing:-0.11px;word-spacing:-0.48px;}
#t6_142{left:143px;bottom:821px;letter-spacing:-0.06px;}
#t7_142{left:139px;bottom:794px;}
#t8_142{left:165px;bottom:794px;letter-spacing:-0.12px;word-spacing:6.55px;}
#t9_142{left:165px;bottom:775px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ta_142{left:139px;bottom:753px;}
#tb_142{left:165px;bottom:753px;letter-spacing:-0.14px;word-spacing:0.48px;}
#tc_142{left:165px;bottom:734px;letter-spacing:-0.12px;word-spacing:0.03px;}
#td_142{left:139px;bottom:712px;}
#te_142{left:165px;bottom:712px;letter-spacing:-0.18px;word-spacing:7.2px;}
#tf_142{left:165px;bottom:693px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tg_142{left:139px;bottom:671px;}
#th_142{left:165px;bottom:671px;letter-spacing:-0.16px;word-spacing:1.82px;}
#ti_142{left:165px;bottom:652px;letter-spacing:-0.1px;}
#tj_142{left:139px;bottom:630px;}
#tk_142{left:165px;bottom:630px;letter-spacing:-0.15px;word-spacing:6.43px;}
#tl_142{left:671px;bottom:630px;}
#tm_142{left:165px;bottom:611px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tn_142{left:139px;bottom:589px;}
#to_142{left:165px;bottom:589px;letter-spacing:-0.13px;word-spacing:5.18px;}
#tp_142{left:165px;bottom:570px;letter-spacing:-0.21px;word-spacing:-0.01px;}
#tq_142{left:165px;bottom:552px;letter-spacing:-0.19px;word-spacing:0.09px;}
#tr_142{left:139px;bottom:529px;}
#ts_142{left:165px;bottom:529px;letter-spacing:-0.19px;word-spacing:5.31px;}
#tt_142{left:165px;bottom:511px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tu_142{left:139px;bottom:488px;}
#tv_142{left:165px;bottom:488px;letter-spacing:-0.12px;word-spacing:3.06px;}
#tw_142{left:165px;bottom:470px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tx_142{left:139px;bottom:447px;}
#ty_142{left:165px;bottom:447px;letter-spacing:-0.12px;word-spacing:4.41px;}
#tz_142{left:165px;bottom:429px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t10_142{left:142px;bottom:384px;letter-spacing:0.11px;word-spacing:0.12px;}
#t11_142{left:106px;bottom:328px;letter-spacing:0.1px;word-spacing:4.45px;}
#t12_142{left:128px;bottom:309px;letter-spacing:0.09px;word-spacing:0.05px;}
#t13_142{left:106px;bottom:291px;letter-spacing:0.11px;word-spacing:5.61px;}
#t14_142{left:128px;bottom:273px;letter-spacing:0.11px;word-spacing:0.03px;}
#t15_142{left:106px;bottom:254px;letter-spacing:0.11px;word-spacing:3.58px;}
#t16_142{left:128px;bottom:236px;letter-spacing:0.11px;}
#t17_142{left:106px;bottom:209px;letter-spacing:0.12px;word-spacing:-1.26px;}
#t18_142{left:106px;bottom:191px;letter-spacing:0.1px;word-spacing:1.16px;}
#t19_142{left:106px;bottom:172px;letter-spacing:0.16px;}
#t1a_142{left:290px;bottom:157px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1b_142{left:326px;bottom:140px;letter-spacing:-0.16px;word-spacing:0.02px;}

.s0_142{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_142{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_142{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s3_142{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s4_142{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s5_142{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s6_142{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_142{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s8_142{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts142" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg142" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="142/142.svg"
          type="image/svg+xml"
          id="pdf142"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_142" class="t s0_142">
          142{" "}
        </span>
        <span id="t2_142" class="t s1_142">
          3.3.3 БАГШИЙН МАНЛАЙЛАЛ, ХАМТЫН АЖИЛЛАГАА, СОЁЛ,{" "}
        </span>
        <span id="t3_142" class="t s1_142">
          ӨӨРИЙН УДИРДЛАГАТАЙ СУРАЛЦАХУЙ{" "}
        </span>
        <span id="t4_142" class="t s2_142">
          АСУУДАЛ{" "}
        </span>
        <span id="t5_142" class="t s3_142">
          Багшийн манлайлал, хамтын ажиллагаа, хамтран ажиллах соёл{" "}
        </span>
        <span id="t6_142" class="t s3_142">
          дутмаг{" "}
        </span>
        <span id="t7_142" class="t s4_142">
          •{" "}
        </span>
        <span id="t8_142" class="t s5_142">
          Багшлахуйн бүхий л ажлаа зөвхөн мэргэжлийн багш нарын{" "}
        </span>
        <span id="t9_142" class="t s5_142">
          хүрээнд Заах аргын нэгдэл, секцийн ажлаар ярилцдаг{" "}
        </span>
        <span id="ta_142" class="t s4_142">
          •{" "}
        </span>
        <span id="tb_142" class="t s5_142">
          Сурагчдын даалгаврын гүйцэтгэл, суралцаж буй байдалд анализ{" "}
        </span>
        <span id="tc_142" class="t s5_142">
          хийх, хичээлээ хамтран төлөвлөх ажил дутмаг{" "}
        </span>
        <span id="td_142" class="t s4_142">
          •{" "}
        </span>
        <span id="te_142" class="t s5_142">
          Сургууль дээр судлагдахууны МХБ байгуулсан боловч ЗАН,{" "}
        </span>
        <span id="tf_142" class="t s5_142">
          секцийн ажлаа орхигдуулсан{" "}
        </span>
        <span id="tg_142" class="t s4_142">
          •{" "}
        </span>
        <span id="th_142" class="t s5_142">
          Нэгдсэн төлөвлөлт багатай учир аливаа ажилд оролцохдоо бие{" "}
        </span>
        <span id="ti_142" class="t s5_142">
          биенээ хүлээж цаг их алддаг{" "}
        </span>
        <span id="tj_142" class="t s4_142">
          •{" "}
        </span>
        <span id="tk_142" class="t s5_142">
          2 ээлжинд хичээллэдэг болохоор хамтран ажиллахад хэцүү
        </span>
        <span id="tl_142" class="t s6_142">
          ,{" "}
        </span>
        <span id="tm_142" class="t s5_142">
          бүлгээрээ уулзах цаг зав гардаггүй{" "}
        </span>
        <span id="tn_142" class="t s4_142">
          •{" "}
        </span>
        <span id="to_142" class="t s5_142">
          Олон жил ажилласан багш нар залуу багш нарыгаа бүлгийн{" "}
        </span>
        <span id="tp_142" class="t s5_142">
          ажлаа хий гэдэг, тухайн асуудлаар багш нар санал, бодол зөрдөг,{" "}
        </span>
        <span id="tq_142" class="t s5_142">
          маргалддаг, зөвшилцөлд хүрч чадахгүй тардаг{" "}
        </span>
        <span id="tr_142" class="t s4_142">
          •{" "}
        </span>
        <span id="ts_142" class="t s5_142">
          Орон нутгийн боловсролын байгууллагын чиглүүлэг, сургууль,{" "}
        </span>
        <span id="tt_142" class="t s5_142">
          сургалтын менежерийн зөвлөгөө, манлайлал дутмаг{" "}
        </span>
        <span id="tu_142" class="t s4_142">
          •{" "}
        </span>
        <span id="tv_142" class="t s5_142">
          Багш нар сонирхлоороо болон өөрийн багшлахуйд тулгамдсан{" "}
        </span>
        <span id="tw_142" class="t s5_142">
          асуудлаа шийдэхийн тулд бүлэгт нэгдээгүй{" "}
        </span>
        <span id="tx_142" class="t s4_142">
          •{" "}
        </span>
        <span id="ty_142" class="t s5_142">
          Багш ажлын байран дээрээ өөрийгөө хөгжүүлэх боломжуудыг{" "}
        </span>
        <span id="tz_142" class="t s5_142">
          эрэлхийлэх, бие даан суралцах чадвар сул{" "}
        </span>
        <span id="t10_142" class="t s2_142">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t11_142" class="t s7_142">
          1. Нэг бодлогыг олон аргаар бодох, өгүүлбэртэй бодлого хялбар аргаар{" "}
        </span>
        <span id="t12_142" class="t s7_142">
          бодох аргачлалд суралцан хичээлдээ ашиглаж байна.{" "}
        </span>
        <span id="t13_142" class="t s7_142">
          2. Хүрд хэрэглэхгүй үржүүлэх үйлдэл хялбар хийх арга зүйгээр хичээл{" "}
        </span>
        <span id="t14_142" class="t s7_142">
          төлөвлөн зааснаар сурагчдад сонирхолтой хичээл болсон.{" "}
        </span>
        <span id="t15_142" class="t s7_142">
          3. Ээлжит хичээлийн төлөвлөлтийг чадварт суурилан төлөвлөх арга зүйд{" "}
        </span>
        <span id="t16_142" class="t s7_142">
          суралцсан.{" "}
        </span>
        <span id="t17_142" class="t s7_142">
          Үр дүн: Ажлын байран дээрээ бие даан суралцсанаар ажлаа төлөвлөх,
          өөрийн{" "}
        </span>
        <span id="t18_142" class="t s7_142">
          удирдлагатай суралцахын чухлыг ойлгосны зэрэгцээ технологийн чадамжаа{" "}
        </span>
        <span id="t19_142" class="t s7_142">
          хөгжүүлсэн.{" "}
        </span>
        <span id="t1a_142" class="t s8_142">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="t1b_142" class="t s8_142">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
