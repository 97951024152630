import React from "react";
export default function HundredTwentyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_129{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_129{left:87px;bottom:941px;letter-spacing:-0.13px;word-spacing:4.65px;}
#t3_129{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:2.67px;}
#t4_129{left:87px;bottom:901px;letter-spacing:-0.16px;word-spacing:3.05px;}
#t5_129{left:622px;bottom:901px;letter-spacing:-0.16px;}
#t6_129{left:87px;bottom:880px;letter-spacing:-0.17px;word-spacing:0.07px;}
#t7_129{left:162px;bottom:850px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t8_129{left:205px;bottom:816px;letter-spacing:-0.19px;}
#t9_129{left:304px;bottom:816px;letter-spacing:-0.05px;}
#ta_129{left:350px;bottom:816px;letter-spacing:-0.14px;}
#tb_129{left:436px;bottom:816px;letter-spacing:-0.05px;}
#tc_129{left:474px;bottom:816px;letter-spacing:-0.12px;word-spacing:0.02px;}
#td_129{left:87px;bottom:484px;letter-spacing:-0.05px;}
#te_129{left:108px;bottom:484px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tf_129{left:87px;bottom:460px;letter-spacing:-0.05px;}
#tg_129{left:108px;bottom:460px;letter-spacing:-0.13px;word-spacing:3.6px;}
#th_129{left:108px;bottom:439px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_129{left:87px;bottom:415px;letter-spacing:-0.05px;}
#tj_129{left:108px;bottom:415px;letter-spacing:-0.11px;word-spacing:-1.15px;}
#tk_129{left:108px;bottom:395px;letter-spacing:-0.15px;word-spacing:6.93px;}
#tl_129{left:108px;bottom:375px;letter-spacing:-0.14px;}
#tm_129{left:87px;bottom:350px;letter-spacing:-0.05px;}
#tn_129{left:108px;bottom:350px;letter-spacing:-0.12px;word-spacing:-0.26px;}
#to_129{left:108px;bottom:330px;letter-spacing:-0.14px;word-spacing:0.13px;}
#tp_129{left:108px;bottom:310px;letter-spacing:-0.12px;word-spacing:8.21px;}
#tq_129{left:108px;bottom:290px;letter-spacing:-0.12px;word-spacing:-2.05px;}
#tr_129{left:108px;bottom:269px;letter-spacing:-0.14px;word-spacing:-0.27px;}
#ts_129{left:108px;bottom:249px;letter-spacing:-0.15px;word-spacing:2.53px;}
#tt_129{left:108px;bottom:229px;letter-spacing:-0.48px;}
#tu_129{left:87px;bottom:205px;letter-spacing:-0.05px;}
#tv_129{left:108px;bottom:205px;letter-spacing:-0.15px;word-spacing:5.62px;}
#tw_129{left:108px;bottom:184px;letter-spacing:-0.14px;word-spacing:0.84px;}
#tx_129{left:108px;bottom:164px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ty_129{left:87px;bottom:140px;letter-spacing:-0.05px;}
#tz_129{left:108px;bottom:140px;letter-spacing:-0.15px;word-spacing:7.02px;}
#t10_129{left:108px;bottom:120px;letter-spacing:-0.14px;word-spacing:0.44px;}

.s0_129{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_129{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_129{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_129{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}`}
      </style>

      <style id="fonts129" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg129" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="129/129.svg"
          type="image/svg+xml"
          id="pdf129"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_129" class="t s0_129">
          129{" "}
        </span>
        <span id="t2_129" class="t s1_129">
          Багш нарын хувьд “үнэлгээ”-г шалгуурт суурилсан байдлаар үр дүнтэй{" "}
        </span>
        <span id="t3_129" class="t s1_129">
          хийхийн тулд үнэлгээний шалгуур болон шалгуурт суурилсан үнэлгээний{" "}
        </span>
        <span id="t4_129" class="t s1_129">
          талаар гүнзгийрүүлэн судалж жилийн болон нэгжийн төлөвлөлт,{" "}
        </span>
        <span id="t5_129" class="t s1_129">
          хичээл{" "}
        </span>
        <span id="t6_129" class="t s1_129">
          даалгавартаа тусган хэрэгжүүлэх шаардлагатай.{" "}
        </span>
        <span id="t7_129" class="t s2_129">
          Хүснэгт 3.16. Үнэлгээний шалгуурд суурилсан жилийн болон нэгжийн
          төлөвлөлт{" "}
        </span>
        <span id="t8_129" class="t s1_129">
          Хувилбар:{" "}
        </span>
        <span id="t9_129" class="t s1_129">
          ..........
        </span>
        <span id="ta_129" class="t s1_129">
          хичээлийн{" "}
        </span>
        <span id="tb_129" class="t s1_129">
          ........
        </span>
        <span id="tc_129" class="t s1_129">
          ангийн жилийн төлөвлөлт{" "}
        </span>
        <span id="td_129" class="t s3_129">
          1.{" "}
        </span>
        <span id="te_129" class="t s1_129">
          Энэхүү төлөвлөлтийг мэргэжлийн багш анги бүрээр боловсруулна{" "}
        </span>
        <span id="tf_129" class="t s3_129">
          2.{" "}
        </span>
        <span id="tg_129" class="t s1_129">
          Сургалтын хөтөлбөрөөс тухайн хичээлийн бүх суралцахуйн зорилтын{" "}
        </span>
        <span id="th_129" class="t s1_129">
          жагсаан бичнэ{" "}
        </span>
        <span id="ti_129" class="t s3_129">
          3.{" "}
        </span>
        <span id="tj_129" class="t s1_129">
          Үнэлгээний нэгж, суралцахуйн үр дүн, суралцахуйн үр дүнгийн шалгуурыг{" "}
        </span>
        <span id="tk_129" class="t s1_129">
          хичээл, сургалтын үр дүнг үнэлэх шалгуурыг хэрэглэх зөвлөмжөөс{" "}
        </span>
        <span id="tl_129" class="t s1_129">
          тусгаарай.{" "}
        </span>
        <span id="tm_129" class="t s3_129">
          4.{" "}
        </span>
        <span id="tn_129" class="t s1_129">
          Ээлжит хичээлийн үндсэн үйл ажиллагааг суралцахуйн үр дүн, шалгуурт{" "}
        </span>
        <span id="to_129" class="t s1_129">
          үндэслэн, суралцахуйн удирдамж дахь сурах үйл ажиллагаанаас голлох{" "}
        </span>
        <span id="tp_129" class="t s1_129">
          үйл ажиллагаанд үндэслэн бичнэ. Ингэж ээлжит хичээлийг сэдэв{" "}
        </span>
        <span id="tq_129" class="t s1_129">
          тавихаас илүү шалгуурт үндэслэн суралцахуйн үндсэн үйл ажиллагаагаар{" "}
        </span>
        <span id="tr_129" class="t s1_129">
          төлөвлөснөөр ээлжит хичээлийг агуулга төвтэй биш сурагчдын гүйцэтгэх{" "}
        </span>
        <span id="ts_129" class="t s1_129">
          үйл ажиллагаа төвтэй даалгавар үйл ажиллагаа төлөвлөхөд тодорхой{" "}
        </span>
        <span id="tt_129" class="t s1_129">
          болдог.{" "}
        </span>
        <span id="tu_129" class="t s3_129">
          5.{" "}
        </span>
        <span id="tv_129" class="t s1_129">
          Гүйцэтгэлийн түвшинг хичээл, сургалтын үр дүнг үнэлэх шалгуурын{" "}
        </span>
        <span id="tw_129" class="t s1_129">
          харгалзах түвшинд баримжаалан голлох үйл ажиллагаандаа нийцүүлэн{" "}
        </span>
        <span id="tx_129" class="t s1_129">
          нарийвчлах боломжтой{" "}
        </span>
        <span id="ty_129" class="t s3_129">
          6.{" "}
        </span>
        <span id="tz_129" class="t s1_129">
          Хэрэглэгдэхүүн, үнэлгээний хэрэгслийг нэгж, ээлжит хичээл бүрээр{" "}
        </span>
        <span id="t10_129" class="t s1_129">
          төлөвлөснөөр багш хичээлийг чадварт суурилсан бодитой, хүртээмжтэй{" "}
        </span>
      </div>
    </div>
  );
}
