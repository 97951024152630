import React from "react";
export default function HundredFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_104{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_104{left:208px;bottom:945px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t3_104{left:87px;bottom:532px;letter-spacing:0.14px;word-spacing:0.04px;}
#t4_104{left:87px;bottom:499px;letter-spacing:-0.14px;word-spacing:7.73px;}
#t5_104{left:87px;bottom:479px;letter-spacing:-0.16px;word-spacing:4.21px;}
#t6_104{left:87px;bottom:459px;letter-spacing:-0.15px;word-spacing:4.69px;}
#t7_104{left:87px;bottom:439px;letter-spacing:-0.16px;word-spacing:8.01px;}
#t8_104{left:87px;bottom:419px;letter-spacing:-0.14px;word-spacing:0.39px;}
#t9_104{left:87px;bottom:399px;letter-spacing:-0.14px;word-spacing:7.26px;}
#ta_104{left:87px;bottom:378px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tb_104{left:230px;bottom:378px;letter-spacing:-0.1px;}
#tc_104{left:376px;bottom:378px;letter-spacing:-0.43px;}
#td_104{left:87px;bottom:345px;letter-spacing:-0.14px;word-spacing:3.08px;}
#te_104{left:87px;bottom:325px;letter-spacing:-0.15px;word-spacing:7.92px;}
#tf_104{left:87px;bottom:305px;letter-spacing:-0.13px;word-spacing:3.81px;}
#tg_104{left:87px;bottom:285px;letter-spacing:-0.16px;word-spacing:2.09px;}
#th_104{left:87px;bottom:265px;letter-spacing:-0.12px;word-spacing:2.18px;}
#ti_104{left:87px;bottom:244px;letter-spacing:-0.15px;word-spacing:1.32px;}
#tj_104{left:87px;bottom:224px;letter-spacing:-0.2px;word-spacing:0.1px;}
#tk_104{left:87px;bottom:191px;letter-spacing:-0.15px;word-spacing:4.93px;}
#tl_104{left:602px;bottom:191px;letter-spacing:-0.09px;}
#tm_104{left:87px;bottom:171px;letter-spacing:-0.13px;word-spacing:8.19px;}
#tn_104{left:87px;bottom:151px;letter-spacing:-0.19px;}
#to_104{left:179px;bottom:151px;letter-spacing:-0.13px;word-spacing:10.08px;}
#tp_104{left:87px;bottom:131px;letter-spacing:-0.13px;word-spacing:-0.46px;}

.s0_104{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_104{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_104{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_104{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_104{font-size:17px;font-family:MogulArial_16s;color:#1C231E;}
.s5_104{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts104" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg104" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="104/104.svg"
          type="image/svg+xml"
          id="pdf104"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_104" class="t s0_104">
          104{" "}
        </span>
        <span id="t2_104" class="t s1_104">
          Зураг 3.12. Сургуулийнн төлөвлөлтийн уялдаа холбоо{" "}
        </span>
        <span id="t3_104" class="t s2_104">
          Төлөвлөлтийг хэрхэн хийх вэ?{" "}
        </span>
        <span id="t4_104" class="t s3_104">
          Сургууль менежментийн зураглалд үндэслэн тухайн жилийн зорилго,{" "}
        </span>
        <span id="t5_104" class="t s3_104">
          зорилтыг хэрэгжүүлэх төлөвлөгөөг дээрх бүдүүвчийн дагуу сургалт, үйл{" "}
        </span>
        <span id="t6_104" class="t s3_104">
          ажиллагаанд оролцогч бүх талууд өөрсдөдөө холбоотой ажлыг түвшин{" "}
        </span>
        <span id="t7_104" class="t s3_104">
          бүрдээ нэгтгэн, сургуулийн удирдлага, менежментийн баг сургуулийн{" "}
        </span>
        <span id="t8_104" class="t s3_104">
          жилийн нэгдсэн төлөвлөгөөг гаргана. Нэгдсэн төлөвлөгөөний хэрэгжилтийг{" "}
        </span>
        <span id="t9_104" class="t s3_104">
          хянах, зохицуулах зорилгоор календарчилсан төлөвлөгөөг жил болон{" "}
        </span>
        <span id="ta_104" class="t s3_104">
          сараар уян хатан,{" "}
        </span>
        <span id="tb_104" class="t s4_104">
          хөдөлгөөнтэйгөөр{" "}
        </span>
        <span id="tc_104" class="t s3_104">
          гаргадаг.{" "}
        </span>
        <span id="td_104" class="t s3_104">
          Дээрх бүдүүвчийн дагуу нэг ба хоёр дугаар түвшний төлөвлөлтүүд сайн{" "}
        </span>
        <span id="te_104" class="t s3_104">
          хийгддэг ба харин дунд шатны нэгжүүдэд төлөвлөлтийг нэгтгэх үйл{" "}
        </span>
        <span id="tf_104" class="t s3_104">
          ажиллагаа дутуу хийгддэгээс сургууль дээр олон саланги төлөвлөлтүүд{" "}
        </span>
        <span id="tg_104" class="t s3_104">
          хоорондоо уялдаагүй хэрэгжиж багш сурагчдад хиймэл ачаалал үүсгэдэг.{" "}
        </span>
        <span id="th_104" class="t s3_104">
          Дунд шатны болон нэгдсэн төлөвлөлтийг сайн хийх нь олон саланги үйл{" "}
        </span>
        <span id="ti_104" class="t s3_104">
          ажиллагааг уялдуулан нэгтгэх, багш сурагчдын ачаалал буурах, ачааллыг{" "}
        </span>
        <span id="tj_104" class="t s3_104">
          тэнцвэржүүлэх боломж бүрддэг.{" "}
        </span>
        <span id="tk_104" class="t s3_104">
          Анхан шатнаас гарч ирсэн /судлагдахуун тус бүрийн жилийн{" "}
        </span>
        <span id="tl_104" class="t s5_104">
          (Хүснэгт{" "}
        </span>
        <span id="tm_104" class="t s5_104">
          1 буюу 94-р хуудасны судлагдахууны нэг ангийн агуулгын жилийн{" "}
        </span>
        <span id="tn_104" class="t s5_104">
          төлөвлөлт
        </span>
        <span id="to_104" class="t s3_104">
          ), сурагч, анги удирдсан багшийн, клуб, дугуйлан секц,{" "}
        </span>
        <span id="tp_104" class="t s3_104">
          ажилтны.. /төлөвлөлтийг ЗАН, анги нэгдэл болон аж ахуйн албаны түвшинд{" "}
        </span>
      </div>
    </div>
  );
}
