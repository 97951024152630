import React from "react";
export default function OneHundredNinety() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_190{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_190{left:194px;bottom:937px;letter-spacing:0.15px;word-spacing:0.05px;}
#t3_190{left:142px;bottom:871px;}
#t4_190{left:143px;bottom:839px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t5_190{left:143px;bottom:810px;letter-spacing:-0.14px;word-spacing:7.04px;}
#t6_190{left:169px;bottom:790px;letter-spacing:-0.2px;}
#t7_190{left:143px;bottom:765px;letter-spacing:-0.17px;word-spacing:8.43px;}
#t8_190{left:169px;bottom:745px;letter-spacing:-0.18px;word-spacing:0.09px;}
#t9_190{left:143px;bottom:721px;}
#ta_190{left:169px;bottom:721px;letter-spacing:-0.16px;word-spacing:-0.69px;}
#tb_190{left:169px;bottom:700px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_190{left:143px;bottom:676px;}
#td_190{left:169px;bottom:676px;letter-spacing:-0.19px;word-spacing:1.28px;}
#te_190{left:169px;bottom:656px;letter-spacing:-0.15px;word-spacing:0.61px;}
#tf_190{left:169px;bottom:636px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tg_190{left:143px;bottom:611px;letter-spacing:-0.28px;word-spacing:11.33px;}
#th_190{left:281px;bottom:611px;letter-spacing:-0.08px;}
#ti_190{left:342px;bottom:611px;letter-spacing:-0.09px;}
#tj_190{left:447px;bottom:611px;letter-spacing:-0.16px;}
#tk_190{left:519px;bottom:611px;letter-spacing:-0.19px;}
#tl_190{left:620px;bottom:611px;letter-spacing:-0.42px;}
#tm_190{left:169px;bottom:591px;letter-spacing:-0.16px;word-spacing:5.71px;}
#tn_190{left:169px;bottom:571px;letter-spacing:-0.18px;word-spacing:0.09px;}
#to_190{left:142px;bottom:516px;letter-spacing:0.18px;}
#tp_190{left:143px;bottom:488px;}
#tq_190{left:169px;bottom:488px;letter-spacing:-0.21px;word-spacing:2.05px;}
#tr_190{left:169px;bottom:467px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ts_190{left:143px;bottom:443px;}
#tt_190{left:169px;bottom:443px;letter-spacing:-0.19px;word-spacing:1.28px;}
#tu_190{left:169px;bottom:423px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tv_190{left:143px;bottom:398px;letter-spacing:-0.18px;word-spacing:10.12px;}
#tw_190{left:169px;bottom:378px;letter-spacing:-0.1px;}
#tx_190{left:143px;bottom:354px;}
#ty_190{left:169px;bottom:354px;letter-spacing:-0.15px;word-spacing:-1.41px;}
#tz_190{left:169px;bottom:333px;letter-spacing:-0.16px;word-spacing:2.34px;}
#t10_190{left:169px;bottom:313px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t11_190{left:205px;bottom:264px;letter-spacing:0.12px;}
#t12_190{left:250px;bottom:264px;letter-spacing:0.14px;word-spacing:0.08px;}
#t13_190{left:87px;bottom:225px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t14_190{left:87px;bottom:196px;letter-spacing:-0.16px;word-spacing:0.86px;}
#t15_190{left:87px;bottom:176px;letter-spacing:-0.15px;word-spacing:6.06px;}
#t16_190{left:87px;bottom:155px;letter-spacing:-0.11px;word-spacing:0.03px;}

.s0_190{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_190{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s2_190{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_190{font-size:17px;font-family:Arial-BoldMT_1li;color:#000;}
.s4_190{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s5_190{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s6_190{font-size:18px;font-family:Arial-BoldMT_1li;color:#F7941E;}
.s7_190{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s8_190{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts190" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg190" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="190/190.svg"
          type="image/svg+xml"
          id="pdf190"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_190" class="t s0_190">
          190{" "}
        </span>
        <span id="t2_190" class="t s1_190">
          3.6.4 СУРГУУЛИЙН ТӨСӨВ, САНХҮҮЖИЛТ{" "}
        </span>
        <span id="t3_190" class="t s2_190">
          АСУУДАЛ{" "}
        </span>
        <span id="t4_190" class="t s3_190">
          Сургуулийн санхүүжилт, төсвийн зарцуулалт хаалттай{" "}
        </span>
        <span id="t5_190" class="t s4_190">
          ● Төсвийн хүрэлцээ (хувьсах зардал) – ний талаар мэдээлэл{" "}
        </span>
        <span id="t6_190" class="t s4_190">
          өгдөггүй{" "}
        </span>
        <span id="t7_190" class="t s4_190">
          ● Төсвийн шинэчлэлээр олгож буй зардлуудын зарцуулалтын{" "}
        </span>
        <span id="t8_190" class="t s4_190">
          талаарх мэдээлэл нээлттэй бус{" "}
        </span>
        <span id="t9_190" class="t s4_190">
          ●{" "}
        </span>
        <span id="ta_190" class="t s4_190">
          Боловсролын мэдээллийн системийн дата үнэн зөв, бодитой бус,{" "}
        </span>
        <span id="tb_190" class="t s4_190">
          өөрчлөлтийн мэдээллийг шинэчлэх ажил хийгддэггүй{" "}
        </span>
        <span id="tc_190" class="t s4_190">
          ●{" "}
        </span>
        <span id="td_190" class="t s4_190">
          Холбогдох эрх зүйн баримт бичиг, дүрэм журам, зохицуулалтын{" "}
        </span>
        <span id="te_190" class="t s4_190">
          аргачлал, зааврыг судалж, ашиглахдаа дутагдалтай /санхүүгийн{" "}
        </span>
        <span id="tf_190" class="t s4_190">
          ажилтанд шууд найддаг/{" "}
        </span>
        <span id="tg_190" class="t s4_190">
          ● Удирдлагын{" "}
        </span>
        <span id="th_190" class="t s4_190">
          төсөв{" "}
        </span>
        <span id="ti_190" class="t s4_190">
          санхүүгийн{" "}
        </span>
        <span id="tj_190" class="t s4_190">
          мэдлэг{" "}
        </span>
        <span id="tk_190" class="t s4_190">
          боловсрол{" "}
        </span>
        <span id="tl_190" class="t s4_190">
          дутмаг,{" "}
        </span>
        <span id="tm_190" class="t s4_190">
          холбогдох хууль тогтоомж, дүрэм журмыг уншиж судлаагүйн{" "}
        </span>
        <span id="tn_190" class="t s4_190">
          улмаас хэрэгжилт, зохион байгуулалтад алдаа их гаргадаг{" "}
        </span>
        <span id="to_190" class="t s5_190">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tp_190" class="t s4_190">
          ●{" "}
        </span>
        <span id="tq_190" class="t s4_190">
          Төсвийн талаарх ойлголт, олгож буй зардлуудын зарцуулалтын{" "}
        </span>
        <span id="tr_190" class="t s4_190">
          талаарх мэдээллийг нээлттэй шилэн болгох{" "}
        </span>
        <span id="ts_190" class="t s4_190">
          ●{" "}
        </span>
        <span id="tt_190" class="t s4_190">
          Холбогдох эрх зүйн баримт бичиг, дүрэм журам, зохицуулалтын{" "}
        </span>
        <span id="tu_190" class="t s4_190">
          аргачлал, зааврыг судалж, ашиглалтыг сайжруулах{" "}
        </span>
        <span id="tv_190" class="t s4_190">
          ● Удирдах ажилтнууд төсөв санхүүгийн мэдлэг боловсролоо{" "}
        </span>
        <span id="tw_190" class="t s4_190">
          дээшлүүлэх{" "}
        </span>
        <span id="tx_190" class="t s4_190">
          ●{" "}
        </span>
        <span id="ty_190" class="t s4_190">
          Төсвийг тооцон нийт багш нарт танилцуулж хэвших, зарцуулагдах{" "}
        </span>
        <span id="tz_190" class="t s4_190">
          хэрэглээний сонголтыг сургалтын үйл ажиллагаатай уялдуулан{" "}
        </span>
        <span id="t10_190" class="t s4_190">
          сонголт хийх боломжийг бүрдүүлж ажиллах{" "}
        </span>
        <span id="t11_190" class="t s1_190">
          3.6.5{" "}
        </span>
        <span id="t12_190" class="t s6_190">
          ХАМТЫН АЖИЛЛАГАА, ТҮНШЛЭЛ{" "}
        </span>
        <span id="t13_190" class="t s7_190">
          Хамтын ажиллагаа, түншлэл яагаад хэрэгтэй вэ?{" "}
        </span>
        <span id="t14_190" class="t s8_190">
          Түншлэл нь нийгэмд тулгарч буй гол сорилтуудын шинэлэг шийдлийг олж,{" "}
        </span>
        <span id="t15_190" class="t s8_190">
          өөрчлөлт хийхэд харилцан ашигтай хамтран ажиллахаар тохиролцсон{" "}
        </span>
        <span id="t16_190" class="t s8_190">
          гэрээ юм.{" "}
        </span>
      </div>
    </div>
  );
}
