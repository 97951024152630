import React from "react";
export default function SixtySix() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_66{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_66{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_66{left:87px;bottom:941px;letter-spacing:-0.05px;}
#t4_66{left:113px;bottom:941px;letter-spacing:-0.17px;word-spacing:3.37px;}
#t5_66{left:113px;bottom:921px;letter-spacing:-0.13px;word-spacing:2.89px;}
#t6_66{left:113px;bottom:901px;letter-spacing:-0.14px;word-spacing:0.06px;}
#t7_66{left:113px;bottom:880px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_66{left:87px;bottom:860px;letter-spacing:-0.05px;}
#t9_66{left:113px;bottom:860px;letter-spacing:-0.14px;word-spacing:4.64px;}
#ta_66{left:113px;bottom:840px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tb_66{left:94px;bottom:798px;letter-spacing:0.11px;word-spacing:2.18px;}
#tc_66{left:94px;bottom:779px;letter-spacing:0.09px;word-spacing:2.77px;}
#td_66{left:94px;bottom:761px;letter-spacing:0.09px;word-spacing:-0.34px;}
#te_66{left:94px;bottom:743px;letter-spacing:0.09px;word-spacing:2.48px;}
#tf_66{left:94px;bottom:724px;letter-spacing:0.07px;word-spacing:0.07px;}
#tg_66{left:87px;bottom:678px;letter-spacing:-0.15px;word-spacing:0.44px;}
#th_66{left:356px;bottom:678px;letter-spacing:-0.13px;word-spacing:0.44px;}
#ti_66{left:87px;bottom:658px;letter-spacing:-0.14px;word-spacing:4.88px;}
#tj_66{left:87px;bottom:638px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tk_66{left:102px;bottom:592px;letter-spacing:0.18px;}
#tl_66{left:102px;bottom:574px;letter-spacing:0.12px;word-spacing:3.17px;}
#tm_66{left:102px;bottom:555px;letter-spacing:0.08px;}
#tn_66{left:219px;bottom:555px;letter-spacing:0.13px;}
#to_66{left:312px;bottom:555px;letter-spacing:0.05px;}
#tp_66{left:458px;bottom:555px;letter-spacing:0.04px;}
#tq_66{left:586px;bottom:555px;letter-spacing:0.05px;}
#tr_66{left:102px;bottom:537px;letter-spacing:0.09px;word-spacing:-0.68px;}
#ts_66{left:102px;bottom:519px;letter-spacing:0.12px;word-spacing:5.58px;}
#tt_66{left:102px;bottom:500px;letter-spacing:0.06px;word-spacing:2.41px;}
#tu_66{left:269px;bottom:500px;letter-spacing:0.09px;word-spacing:2.4px;}
#tv_66{left:102px;bottom:482px;letter-spacing:0.12px;word-spacing:0.02px;}
#tw_66{left:288px;bottom:431px;letter-spacing:-0.17px;word-spacing:0.05px;}
#tx_66{left:87px;bottom:400px;letter-spacing:-0.32px;}
#ty_66{left:203px;bottom:400px;letter-spacing:-0.15px;}
#tz_66{left:87px;bottom:380px;letter-spacing:-0.16px;}
#t10_66{left:211px;bottom:380px;letter-spacing:-0.14px;}
#t11_66{left:87px;bottom:360px;letter-spacing:-0.2px;}
#t12_66{left:223px;bottom:360px;letter-spacing:-0.16px;}
#t13_66{left:87px;bottom:340px;letter-spacing:-0.11px;word-spacing:2.44px;}
#t14_66{left:87px;bottom:320px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t15_66{left:87px;bottom:287px;letter-spacing:-0.17px;word-spacing:-0.77px;}
#t16_66{left:87px;bottom:266px;letter-spacing:-0.12px;word-spacing:4.2px;}
#t17_66{left:87px;bottom:246px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t18_66{left:87px;bottom:213px;letter-spacing:-0.16px;word-spacing:2.13px;}
#t19_66{left:87px;bottom:193px;letter-spacing:-0.14px;word-spacing:3.54px;}
#t1a_66{left:87px;bottom:173px;letter-spacing:-0.13px;word-spacing:3.39px;}
#t1b_66{left:87px;bottom:153px;letter-spacing:-0.13px;word-spacing:0.52px;}
#t1c_66{left:87px;bottom:133px;letter-spacing:-0.16px;word-spacing:0.12px;}
#t1d_66{left:87px;bottom:112px;letter-spacing:-0.14px;word-spacing:0.04px;}

.s0_66{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_66{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_66{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s3_66{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_66{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_66{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s6_66{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s7_66{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s8_66{font-size:15px;font-family:MogulArial_18b;color:#000;}
.s9_66{font-size:14px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts66" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg66" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="66/66.svg"
          type="image/svg+xml"
          id="pdf66"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_66" class="t s1_66">
          66{" "}
        </span>
        <span id="t3_66" class="t s2_66">
          3.{" "}
        </span>
        <span id="t4_66" class="t s3_66">
          Тодорхой нэг судлагдахууны хувьд нэг ангиар, үнэлгээний зорилтоор{" "}
        </span>
        <span id="t5_66" class="t s3_66">
          гүйцэтгэлийн үр дүнг харна. Энэ үзүүлэлт нэг жилээр гарах тул 2022{" "}
        </span>
        <span id="t6_66" class="t s3_66">
          эсвэл 2023 оноор харах нь сургуулийн удирдлагын шинжилгээ хийж буй{" "}
        </span>
        <span id="t7_66" class="t s3_66">
          зорилгоос хамаарна.{" "}
        </span>
        <span id="t8_66" class="t s2_66">
          4.{" "}
        </span>
        <span id="t9_66" class="t s3_66">
          Гүйцэтгэлийг ахиулах боломжийг тухайн үйл ажиллагаанд хамаарах{" "}
        </span>
        <span id="ta_66" class="t s3_66">
          үүрэг бүхий хүмүүсийн оролцоотойгоор тодорхойлоорой.{" "}
        </span>
        <span id="tb_66" class="t s4_66">
          А сургуулийн хувьд сургалтын хөтөлбөрийн хэрэгжилт болон сургалтын
          орчин{" "}
        </span>
        <span id="tc_66" class="t s4_66">
          бүрдүүлэлтийн шалгуур бусад шалгууртай харьцуулахад доогуур
          үзүүлэлттэй.{" "}
        </span>
        <span id="td_66" class="t s4_66">
          Иймээс сургуулийн удирдлага сул үзүүлэлтийн учир шалтгааныг өөрийн
          үнэлгээг{" "}
        </span>
        <span id="te_66" class="t s4_66">
          хийхдээ тодруулан, сайжруулах арга замаа хамт олноороо зөвлөн
          сургуулийн{" "}
        </span>
        <span id="tf_66" class="t s4_66">
          сургалт, үйл ажиллагааны нэгдсэн төлөвлөгөөндөө тусгаарай.{" "}
        </span>
        <span id="tg_66" class="t s5_66">
          Багшийн гүйцэтгэлийн үнэлгээ.{" "}
        </span>
        <span id="th_66" class="t s3_66">
          Багшийн гүйцэтгэлийг шалгуур, үзүүлэлт{" "}
        </span>
        <span id="ti_66" class="t s3_66">
          тус бүрийн үнэлгээ, хамрагдаж буй хөгжлийн түвшнээр нь цахим тоон{" "}
        </span>
        <span id="tj_66" class="t s3_66">
          тайлангийн системээс харж болно.{" "}
        </span>
        <span id="tk_66" class="t s6_66">
          Санамж:{" "}
        </span>
        <span id="tl_66" class="t s7_66">
          Та бүхэн хөтөлбөрийн хэрэгжилт буюу бичгийн шалгалтын үр дүнг ангиар,{" "}
        </span>
        <span id="tm_66" class="t s7_66">
          боловсролын{" "}
        </span>
        <span id="tn_66" class="t s7_66">
          түвшнээр,{" "}
        </span>
        <span id="to_66" class="t s7_66">
          судлагдахуунаар,{" "}
        </span>
        <span id="tp_66" class="t s7_66">
          судлагдахууны{" "}
        </span>
        <span id="tq_66" class="t s7_66">
          агуулгаар,{" "}
        </span>
        <span id="tr_66" class="t s7_66">
          үнэлгээний зорилтоор, оноор нь харьцуулсан мэдээллийг татан авч
          шинжилгээ{" "}
        </span>
        <span id="ts_66" class="t s8_66">
          хийгээрэй. “Download” “Crosstab” сонголтоор мэдээллийг “Excel” файлаар{" "}
        </span>
        <span id="tt_66" class="t s7_66">
          татаж, сургуулийнхаа{" "}
        </span>
        <span id="tu_66" class="t s7_66">
          холбогдох мэдээллийг нэмж, харьцуулалт шинжилгээ{" "}
        </span>
        <span id="tv_66" class="t s7_66">
          хийх бүрэн боломжтой.{" "}
        </span>
        <span id="tw_66" class="t s9_66">
          Зураг 2.17. Гүйцэтгэлийн үнэлгээнд хамрагдсан багшийн тоо{" "}
        </span>
        <span id="tx_66" class="t s3_66">
          Тухайн{" "}
        </span>
        <span id="ty_66" class="t s3_66">
          сургуулийн{" "}
        </span>
        <span id="tz_66" class="t s3_66">
          гүйцэтгэлийн{" "}
        </span>
        <span id="t10_66" class="t s3_66">
          үнэлгээнд{" "}
        </span>
        <span id="t11_66" class="t s3_66">
          хамрагдсан{" "}
        </span>
        <span id="t12_66" class="t s3_66">
          багшийн{" "}
        </span>
        <span id="t13_66" class="t s3_66">
          тоог он тус бүрээр харах{" "}
        </span>
        <span id="t14_66" class="t s3_66">
          боломжтой (Зураг 2.17).{" "}
        </span>
        <span id="t15_66" class="t s3_66">
          Жишээлбэл, А сургуулийн{" "}
        </span>
        <span id="t16_66" class="t s3_66">
          хувьд 2022 онд 65 багш{" "}
        </span>
        <span id="t17_66" class="t s3_66">
          үнэлгээнд хамрагдаж байсан бол 2023 онд энэ тоо 53 болсон байна.{" "}
        </span>
        <span id="t18_66" class="t s3_66">
          Тухайн сургуулийн багш нарын гүйцэтгэлийн үр дүнг бичгийн шалгалтаас{" "}
        </span>
        <span id="t19_66" class="t s3_66">
          бусад шалгуурын хувьд харуулах цонхыг Зураг 2.18-д үзүүлэв. Шалгуур{" "}
        </span>
        <span id="t1a_66" class="t s3_66">
          бүрийн үнэлгээний үр дүнг багш бүрээр, оноор харах боломжтой. Зураг{" "}
        </span>
        <span id="t1b_66" class="t s3_66">
          дээрх шалгуур бүрд харгалзах нүд гүн ногоон болох тусам тухайн багшийн{" "}
        </span>
        <span id="t1c_66" class="t s3_66">
          үнэлгээний хувь ихсэж байгааг харуулна. Улаан руу ойртох тусам
          анхаарах{" "}
        </span>
        <span id="t1d_66" class="t s3_66">
          шаардлагатай шалгуур гэсэн дохио өгнө.{" "}
        </span>
      </div>
    </div>
  );
}
