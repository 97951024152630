import React from "react";
export default function Fifteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_15{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_15{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_15{left:87px;bottom:941px;}
#t4_15{left:108px;bottom:941px;letter-spacing:-0.14px;word-spacing:0.22px;}
#t5_15{left:108px;bottom:921px;letter-spacing:-0.17px;word-spacing:-0.53px;}
#t6_15{left:108px;bottom:901px;letter-spacing:-0.13px;word-spacing:-0.09px;}
#t7_15{left:108px;bottom:880px;letter-spacing:-0.13px;word-spacing:5.77px;}
#t8_15{left:108px;bottom:860px;letter-spacing:-0.15px;word-spacing:1.22px;}
#t9_15{left:108px;bottom:840px;letter-spacing:-0.13px;word-spacing:4.57px;}
#ta_15{left:108px;bottom:820px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tb_15{left:87px;bottom:787px;letter-spacing:-0.17px;word-spacing:10.14px;}
#tc_15{left:108px;bottom:767px;letter-spacing:-0.13px;word-spacing:0.93px;}
#td_15{left:108px;bottom:746px;letter-spacing:-0.12px;word-spacing:2.72px;}
#te_15{left:108px;bottom:726px;letter-spacing:-0.13px;word-spacing:-0.41px;}
#tf_15{left:108px;bottom:706px;letter-spacing:-0.15px;word-spacing:4.77px;}
#tg_15{left:108px;bottom:686px;letter-spacing:-0.14px;word-spacing:1.13px;}
#th_15{left:108px;bottom:666px;letter-spacing:-0.15px;word-spacing:0.05px;}
#ti_15{left:87px;bottom:633px;letter-spacing:-0.2px;}
#tj_15{left:165px;bottom:633px;letter-spacing:-0.37px;}
#tk_15{left:236px;bottom:633px;letter-spacing:-0.17px;}
#tl_15{left:363px;bottom:633px;letter-spacing:-0.21px;}
#tm_15{left:87px;bottom:612px;letter-spacing:-0.12px;word-spacing:5.79px;}
#tn_15{left:87px;bottom:592px;letter-spacing:-0.17px;word-spacing:9.77px;}
#to_15{left:87px;bottom:572px;letter-spacing:-0.12px;word-spacing:-0.88px;}
#tp_15{left:87px;bottom:552px;letter-spacing:-0.16px;}
#tq_15{left:197px;bottom:552px;letter-spacing:-0.09px;}
#tr_15{left:265px;bottom:552px;letter-spacing:-0.13px;}
#ts_15{left:333px;bottom:552px;letter-spacing:-0.09px;}
#tt_15{left:87px;bottom:532px;letter-spacing:-0.17px;}
#tu_15{left:212px;bottom:532px;letter-spacing:-0.08px;}
#tv_15{left:275px;bottom:532px;letter-spacing:-0.18px;}
#tw_15{left:339px;bottom:532px;letter-spacing:-0.2px;}
#tx_15{left:87px;bottom:512px;letter-spacing:-0.08px;}
#ty_15{left:214px;bottom:512px;letter-spacing:-0.16px;}
#tz_15{left:337px;bottom:512px;letter-spacing:-0.09px;}
#t10_15{left:87px;bottom:491px;letter-spacing:-0.2px;word-spacing:2.68px;}
#t11_15{left:87px;bottom:471px;letter-spacing:-0.14px;word-spacing:2.7px;}
#t12_15{left:87px;bottom:451px;letter-spacing:-0.19px;word-spacing:6.76px;}
#t13_15{left:87px;bottom:431px;letter-spacing:-0.16px;word-spacing:4.7px;}
#t14_15{left:87px;bottom:411px;letter-spacing:-0.17px;}
#t15_15{left:164px;bottom:411px;letter-spacing:-0.19px;}
#t16_15{left:271px;bottom:411px;letter-spacing:-0.13px;}
#t17_15{left:362px;bottom:411px;letter-spacing:-0.08px;}
#t18_15{left:87px;bottom:391px;letter-spacing:-0.14px;}
#t19_15{left:169px;bottom:391px;letter-spacing:-0.17px;}
#t1a_15{left:246px;bottom:391px;letter-spacing:-0.09px;}
#t1b_15{left:339px;bottom:391px;letter-spacing:-0.09px;}
#t1c_15{left:87px;bottom:370px;letter-spacing:-0.12px;word-spacing:3.43px;}
#t1d_15{left:87px;bottom:350px;letter-spacing:-0.17px;}
#t1e_15{left:174px;bottom:350px;letter-spacing:-0.15px;}
#t1f_15{left:293px;bottom:350px;letter-spacing:-0.17px;}
#t1g_15{left:87px;bottom:330px;letter-spacing:-0.15px;word-spacing:11.44px;}
#t1h_15{left:87px;bottom:310px;letter-spacing:-0.17px;word-spacing:10.1px;}
#t1i_15{left:87px;bottom:290px;letter-spacing:-0.11px;}
#t1j_15{left:205px;bottom:290px;letter-spacing:-0.09px;}
#t1k_15{left:304px;bottom:290px;letter-spacing:-0.1px;}
#t1l_15{left:87px;bottom:270px;letter-spacing:-0.15px;word-spacing:1.2px;}
#t1m_15{left:87px;bottom:249px;letter-spacing:-0.16px;word-spacing:0.96px;}
#t1n_15{left:87px;bottom:229px;letter-spacing:-0.22px;}
#t1o_15{left:175px;bottom:229px;letter-spacing:-0.17px;}
#t1p_15{left:290px;bottom:229px;letter-spacing:-0.06px;}
#t1q_15{left:365px;bottom:229px;letter-spacing:-0.09px;}
#t1r_15{left:87px;bottom:209px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t1s_15{left:421px;bottom:275px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1t_15{left:422px;bottom:258px;letter-spacing:-0.17px;word-spacing:0.04px;}
#t1u_15{left:511px;bottom:242px;letter-spacing:-0.21px;}

.s0_15{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_15{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_15{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_15{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_15{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts15" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg15" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="15/15.svg"
          type="image/svg+xml"
          id="pdf15"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_15" class="t s1_15">
          15{" "}
        </span>
        <span id="t3_15" class="t s2_15">
          -{" "}
        </span>
        <span id="t4_15" class="t s2_15">
          Монгол Улсыг 2021-2025 онд хөгжүүлэх таван жилийн үндсэн чиглэлд (i){" "}
        </span>
        <span id="t5_15" class="t s2_15">
          Монгол хүний онцлог, давуу тал, үнэт зүйлийг тодорхойлох, (ii)
          сургалтын{" "}
        </span>
        <span id="t6_15" class="t s2_15">
          хөтөлбөрийг монголын түүх, эх хэл, зан заншил, эх оронч үзэл хандлага,{" "}
        </span>
        <span id="t7_15" class="t s2_15">
          үндэсний өв уламжлалын агуулгаар баяжуулах, (iii) бүх нийтийн эх{" "}
        </span>
        <span id="t8_15" class="t s2_15">
          хэлний боловсролыг дээшлүүлэх, хоол, хүнсний тухай боловсрол олгох{" "}
        </span>
        <span id="t9_15" class="t s2_15">
          (iv) сургалтын цахим платформ хөгжүүлэх, (v) боловсролын чанарыг{" "}
        </span>
        <span id="ta_15" class="t s2_15">
          олон улсад үнэлүүлэх асуудал туссан байна;{" "}
        </span>
        <span id="tb_15" class="t s2_15">
          - Монгол Улсын Засгийн газрын 2020-2024 оны үйл ажиллагааны{" "}
        </span>
        <span id="tc_15" class="t s2_15">
          хөтөлбөр нь (i) цахим сургалтын платформ (агуулга, хөтөлбөр, хичээл)-{" "}
        </span>
        <span id="td_15" class="t s2_15">
          ыг хөгжүүлэх, (ii) сургалтын хөтөлбөрийг монголын түүх, эх хэл, соёл,{" "}
        </span>
        <span id="te_15" class="t s2_15">
          зан заншил, эх оронч үзэл хандлага, үндэсний өв уламжлалын агуулгаар{" "}
        </span>
        <span id="tf_15" class="t s2_15">
          баяжуулах; (iii) бүх нийтэд харилцаа холбоо, мэдээллийн технологи,{" "}
        </span>
        <span id="tg_15" class="t s2_15">
          хүний эрх, жендэрийн боловсрол олгох, (iv) боловсролын чанарыг олон{" "}
        </span>
        <span id="th_15" class="t s2_15">
          улсад үнэлүүлэх асуудалд анхаарсан байна.{" "}
        </span>
        <span id="ti_15" class="t s2_15">
          Монгол{" "}
        </span>
        <span id="tj_15" class="t s2_15">
          Улсын{" "}
        </span>
        <span id="tk_15" class="t s2_15">
          Боловсролын{" "}
        </span>
        <span id="tl_15" class="t s2_15">
          багц{" "}
        </span>
        <span id="tm_15" class="t s2_15">
          хуулийг шинэчилж, хэрэгжүүлэх ажил{" "}
        </span>
        <span id="tn_15" class="t s2_15">
          эхэлж байна. Боловсролын ерөнхий{" "}
        </span>
        <span id="to_15" class="t s2_15">
          хуулийн төсөлтэй хамт өргөн мэдүүлсэн{" "}
        </span>
        <span id="tp_15" class="t s2_15">
          Сургуулийн{" "}
        </span>
        <span id="tq_15" class="t s2_15">
          өмнөх{" "}
        </span>
        <span id="tr_15" class="t s2_15">
          болон{" "}
        </span>
        <span id="ts_15" class="t s2_15">
          ерөнхий{" "}
        </span>
        <span id="tt_15" class="t s2_15">
          боловсролын{" "}
        </span>
        <span id="tu_15" class="t s2_15">
          тухай{" "}
        </span>
        <span id="tv_15" class="t s2_15">
          хууль{" "}
        </span>
        <span id="tw_15" class="t s2_15">
          СӨЕБХ{" "}
        </span>
        <span id="tx_15" class="t s2_15">
          шинэчилсэн{" "}
        </span>
        <span id="ty_15" class="t s2_15">
          найруулгын{" "}
        </span>
        <span id="tz_15" class="t s2_15">
          төслийг{" "}
        </span>
        <span id="t10_15" class="t s2_15">
          Улсын Их Хурлын 2023 оны 07 дугаар{" "}
        </span>
        <span id="t11_15" class="t s2_15">
          сарын 07-ны өдрийн чуулганы нэгдсэн{" "}
        </span>
        <span id="t12_15" class="t s2_15">
          хуралдаанаар хэлэлцэн баталлаа. Уг{" "}
        </span>
        <span id="t13_15" class="t s2_15">
          хуульд сурагч төвтэй тэгш хамруулан{" "}
        </span>
        <span id="t14_15" class="t s2_15">
          сургах,{" "}
        </span>
        <span id="t15_15" class="t s2_15">
          боловсрол{" "}
        </span>
        <span id="t16_15" class="t s2_15">
          эзэмших{" "}
        </span>
        <span id="t17_15" class="t s2_15">
          тэгш{" "}
        </span>
        <span id="t18_15" class="t s2_15">
          боломж{" "}
        </span>
        <span id="t19_15" class="t s2_15">
          хангах,{" "}
        </span>
        <span id="t1a_15" class="t s2_15">
          хүүхдийн{" "}
        </span>
        <span id="t1b_15" class="t s2_15">
          хөгжил,{" "}
        </span>
        <span id="t1c_15" class="t s2_15">
          төлөвшлийг дэмжих, аюулгүй байдлыг{" "}
        </span>
        <span id="t1d_15" class="t s2_15">
          хангах,{" "}
        </span>
        <span id="t1e_15" class="t s2_15">
          хамгаалах,{" "}
        </span>
        <span id="t1f_15" class="t s2_15">
          боловсролын{" "}
        </span>
        <span id="t1g_15" class="t s2_15">
          агуулга, сургалтын хөтөлбөр, сурах{" "}
        </span>
        <span id="t1h_15" class="t s2_15">
          бичгийн тогтвортой байдлыг хангах,{" "}
        </span>
        <span id="t1i_15" class="t s2_15">
          сурагчийн{" "}
        </span>
        <span id="t1j_15" class="t s2_15">
          хөгжил,{" "}
        </span>
        <span id="t1k_15" class="t s2_15">
          төлөвшлийг{" "}
        </span>
        <span id="t1l_15" class="t s2_15">
          дэмжихэд сургуулийн хамт олон, бусад{" "}
        </span>
        <span id="t1m_15" class="t s2_15">
          талын оролцоог хангах зэрэг чиглэлээр{" "}
        </span>
        <span id="t1n_15" class="t s2_15">
          тодорхой{" "}
        </span>
        <span id="t1o_15" class="t s2_15">
          зохицуулалт{" "}
        </span>
        <span id="t1p_15" class="t s2_15">
          хийсэн.{" "}
        </span>
        <span id="t1q_15" class="t s2_15">
          Мөн{" "}
        </span>
        <span id="t1r_15" class="t s2_15">
          боловсролын сургалтын байгууллагын эрх хэмжээг тогтоосон байна.{" "}
        </span>
        <span id="t1s_15" class="t s3_15">
          Төрийн мэдээлэл эмхэтгэлий 2023 оны{" "}
        </span>
        <span id="t1t_15" class="t s3_15">
          №27 (1275)-д Боловсролын багц хууль{" "}
        </span>
        <span id="t1u_15" class="t s3_15">
          хэвлэгдсэн{" "}
        </span>
      </div>
    </div>
  );
}
