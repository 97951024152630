import React from "react";
export default function Fourty() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_40{left:87px;bottom:58px;letter-spacing:-0.12px;}
#t2_40{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_40{left:108px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t4_40{left:332px;bottom:928px;letter-spacing:-0.13px;word-spacing:0.01px;}
#t5_40{left:111px;bottom:324px;letter-spacing:0.19px;}
#t6_40{left:118px;bottom:308px;letter-spacing:0.17px;}
#t7_40{left:119px;bottom:291px;letter-spacing:0.21px;}
#t8_40{left:218px;bottom:325px;letter-spacing:0.18px;word-spacing:-0.03px;}
#t9_40{left:218px;bottom:308px;letter-spacing:0.18px;word-spacing:-0.02px;}
#ta_40{left:218px;bottom:291px;letter-spacing:0.19px;}
#tb_40{left:275px;bottom:291px;letter-spacing:0.19px;word-spacing:-0.03px;}
#tc_40{left:546px;bottom:291px;}
#td_40{left:218px;bottom:903px;letter-spacing:0.2px;word-spacing:5.89px;}
#te_40{left:218px;bottom:887px;letter-spacing:0.18px;word-spacing:0.17px;}
#tf_40{left:431px;bottom:887px;}
#tg_40{left:438px;bottom:887px;letter-spacing:0.18px;word-spacing:0.19px;}
#th_40{left:218px;bottom:870px;letter-spacing:0.18px;word-spacing:0.54px;}
#ti_40{left:314px;bottom:870px;letter-spacing:0.18px;word-spacing:0.53px;}
#tj_40{left:218px;bottom:853px;letter-spacing:0.2px;word-spacing:5.25px;}
#tk_40{left:387px;bottom:853px;letter-spacing:0.19px;word-spacing:5.22px;}
#tl_40{left:218px;bottom:837px;letter-spacing:0.19px;word-spacing:-0.02px;}
#tm_40{left:658px;bottom:837px;}
#tn_40{left:111px;bottom:894px;letter-spacing:0.18px;}
#to_40{left:102px;bottom:878px;letter-spacing:0.19px;}
#tp_40{left:118px;bottom:861px;letter-spacing:0.18px;}
#tq_40{left:126px;bottom:844px;letter-spacing:0.19px;}
#tr_40{left:133px;bottom:692px;letter-spacing:0.16px;}
#ts_40{left:102px;bottom:676px;letter-spacing:0.18px;}
#tt_40{left:117px;bottom:659px;letter-spacing:0.2px;}
#tu_40{left:216px;bottom:697px;}
#tv_40{left:225px;bottom:697px;letter-spacing:0.18px;word-spacing:-0.02px;}
#tw_40{left:225px;bottom:681px;letter-spacing:0.19px;word-spacing:-0.05px;}
#tx_40{left:473px;bottom:681px;}
#ty_40{left:216px;bottom:664px;}
#tz_40{left:225px;bottom:664px;letter-spacing:0.18px;word-spacing:-0.01px;}
#t10_40{left:225px;bottom:647px;letter-spacing:0.19px;word-spacing:-0.07px;}
#t11_40{left:315px;bottom:647px;letter-spacing:0.19px;word-spacing:0.02px;}
#t12_40{left:556px;bottom:647px;letter-spacing:0.22px;}
#t13_40{left:608px;bottom:647px;}
#t14_40{left:119px;bottom:787px;letter-spacing:0.18px;}
#t15_40{left:100px;bottom:770px;letter-spacing:0.17px;}
#t16_40{left:107px;bottom:754px;letter-spacing:0.2px;}
#t17_40{left:218px;bottom:791px;}
#t18_40{left:226px;bottom:791px;letter-spacing:0.18px;word-spacing:-0.03px;}
#t19_40{left:226px;bottom:774px;letter-spacing:0.19px;}
#t1a_40{left:580px;bottom:774px;}
#t1b_40{left:218px;bottom:758px;}
#t1c_40{left:226px;bottom:758px;letter-spacing:0.18px;word-spacing:-0.02px;}
#t1d_40{left:226px;bottom:741px;letter-spacing:0.19px;}
#t1e_40{left:485px;bottom:741px;}
#t1f_40{left:116px;bottom:602px;letter-spacing:0.17px;}
#t1g_40{left:108px;bottom:586px;letter-spacing:0.18px;}
#t1h_40{left:108px;bottom:569px;letter-spacing:0.2px;}
#t1i_40{left:216px;bottom:602px;letter-spacing:0.19px;}
#t1j_40{left:259px;bottom:602px;letter-spacing:0.19px;word-spacing:-0.01px;}
#t1k_40{left:490px;bottom:602px;letter-spacing:0.18px;word-spacing:-0.01px;}
#t1l_40{left:216px;bottom:586px;letter-spacing:0.18px;word-spacing:-0.01px;}
#t1m_40{left:216px;bottom:569px;letter-spacing:0.19px;word-spacing:-0.01px;}
#t1n_40{left:647px;bottom:569px;}
#t1o_40{left:109px;bottom:509px;letter-spacing:0.18px;}
#t1p_40{left:117px;bottom:492px;letter-spacing:0.17px;}
#t1q_40{left:106px;bottom:475px;letter-spacing:0.2px;}
#t1r_40{left:217px;bottom:513px;letter-spacing:0.19px;word-spacing:2.93px;}
#t1s_40{left:217px;bottom:497px;letter-spacing:0.19px;word-spacing:6.14px;}
#t1t_40{left:217px;bottom:480px;letter-spacing:0.2px;word-spacing:6.29px;}
#t1u_40{left:217px;bottom:464px;letter-spacing:0.18px;}
#t1v_40{left:506px;bottom:464px;}
#t1w_40{left:105px;bottom:416px;letter-spacing:0.21px;}
#t1x_40{left:161px;bottom:416px;letter-spacing:0.16px;}
#t1y_40{left:121px;bottom:399px;letter-spacing:0.15px;}
#t1z_40{left:107px;bottom:382px;letter-spacing:0.2px;}
#t20_40{left:218px;bottom:418px;}
#t21_40{left:226px;bottom:418px;letter-spacing:0.18px;word-spacing:-0.03px;}
#t22_40{left:226px;bottom:402px;letter-spacing:0.18px;word-spacing:-0.01px;}
#t23_40{left:373px;bottom:402px;letter-spacing:0.19px;}
#t24_40{left:582px;bottom:402px;}
#t25_40{left:218px;bottom:385px;}
#t26_40{left:226px;bottom:385px;letter-spacing:0.17px;}
#t27_40{left:585px;bottom:385px;letter-spacing:0.16px;}
#t28_40{left:226px;bottom:368px;letter-spacing:0.19px;word-spacing:-0.03px;}
#t29_40{left:622px;bottom:368px;}
#t2a_40{left:87px;bottom:234px;letter-spacing:-0.15px;word-spacing:5.24px;}
#t2b_40{left:87px;bottom:214px;letter-spacing:-0.17px;word-spacing:0.47px;}
#t2c_40{left:87px;bottom:194px;letter-spacing:-0.14px;word-spacing:-1.28px;}
#t2d_40{left:87px;bottom:174px;letter-spacing:-0.16px;word-spacing:2.16px;}
#t2e_40{left:87px;bottom:154px;letter-spacing:-0.19px;word-spacing:10.39px;}
#t2f_40{left:87px;bottom:133px;letter-spacing:-0.14px;word-spacing:0.05px;}

.s0_40{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_40{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_40{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s3_40{font-size:13px;font-family:Arial_93;color:#000;}
.s4_40{font-size:13px;font-family:ArialMT_18r;color:#000;}
.s5_40{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.v0_40{transform:scaleX(0.889);}`}
      </style>

      <style id="fonts40" type="text/css">
        {`@font-face {
	font-family: ArialMT_18r;
	src: url("fonts/ArialMT_18r.woff") format("woff");
}

@font-face {
	font-family: Arial_93;
	src: url("fonts/Arial_93.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg40" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="40/40.svg"
          type="image/svg+xml"
          id="pdf40"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_40" class="t s1_40">
          40{" "}
        </span>
        <span id="t3_40" class="t s2_40">
          Зураг 1.12. Менежментийн зураглал (нэг хичээлийн жилээр) боловсруулах
          дараалал,{" "}
        </span>
        <span id="t4_40" class="t s2_40">
          анхаарах зүйлс{" "}
        </span>
        <span id="t5_40" class="t v0_40 s3_40">
          Тулгамдсан{" "}
        </span>
        <span id="t6_40" class="t v0_40 s3_40">
          асуудлыг{" "}
        </span>
        <span id="t7_40" class="t v0_40 s3_40">
          илрүүлэх{" "}
        </span>
        <span id="t8_40" class="t v0_40 s3_40">
          Сурагчдын гүйцэтгэлд дүн шинжилгээ хийж, суралцахуй, хандлага, эрүүл{" "}
        </span>
        <span id="t9_40" class="t v0_40 s3_40">
          мэндтэй холбоотой асуудлыг тэнцвэртэй авч үзэх, сургуулийн суурь
          түвшний{" "}
        </span>
        <span id="ta_40" class="t v0_40 s3_40">
          асуудлыг{" "}
        </span>
        <span id="tb_40" class="t v0_40 s3_40">
          баримт нотолгоонд үндэслэн тодорхойлсон байх
        </span>
        <span id="tc_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="td_40" class="t v0_40 s3_40">
          Алхмуудыг нэгтгэн менежментийн зураглал хийж, багш хамт олон, сурагч{" "}
        </span>
        <span id="te_40" class="t v0_40 s3_40">
          хүүхдүүд, эцэг эх, асран хамгаалагчид
        </span>
        <span id="tf_40" class="t v0_40 s4_40">
          ,{" "}
        </span>
        <span id="tg_40" class="t v0_40 s3_40">
          орон нутгийн түншлэгч байгууллага зэрэг{" "}
        </span>
        <span id="th_40" class="t v0_40 s3_40">
          оролцогч талууд{" "}
        </span>
        <span id="ti_40" class="t v0_40 s3_40">
          нэгдсэн цэгцтэй ойлголт өгч, дараагийн шатны төлөвлөлтүүдээ{" "}
        </span>
        <span id="tj_40" class="t v0_40 s3_40">
          уялдуулах, төлөвлөсөн үйл{" "}
        </span>
        <span id="tk_40" class="t v0_40 s3_40">
          ажиллагаагаа хэрэгжүүлж, биелэлт, гүйцэтгэлээ{" "}
        </span>
        <span id="tl_40" class="t v0_40 s3_40">
          шалгуурын дагуу нягталж, гол үйл ажиллагаандаа нөөцөө төвлөрүүлж
          ажиллах
        </span>
        <span id="tm_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="tn_40" class="t v0_40 s3_40">
          Сургуулийн{" "}
        </span>
        <span id="to_40" class="t v0_40 s3_40">
          менежментийн{" "}
        </span>
        <span id="tp_40" class="t v0_40 s3_40">
          зураглал{" "}
        </span>
        <span id="tq_40" class="t v0_40 s3_40">
          гаргах{" "}
        </span>
        <span id="tr_40" class="t v0_40 s3_40">
          Үйл{" "}
        </span>
        <span id="ts_40" class="t v0_40 s3_40">
          ажиллагаагааг{" "}
        </span>
        <span id="tt_40" class="t v0_40 s3_40">
          төлөвлөх{" "}
        </span>
        <span id="tu_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="tv_40" class="t v0_40 s3_40">
          Тэргүүлэх зорилтуудын хүрээнд ямар ямар үйл ажиллагааг хэрэгжүүлэхээ
          дэс{" "}
        </span>
        <span id="tw_40" class="t v0_40 s3_40">
          дараалалтай, харилцан уялдаатай төлөвлөх
        </span>
        <span id="tx_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="ty_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="tz_40" class="t v0_40 s3_40">
          Хэт олон, салангид үйл ажиллагаанууд биш цөөн, чанартай, тогтвортой үр{" "}
        </span>
        <span id="t10_40" class="t v0_40 s3_40">
          дүнд хүргэх үйл{" "}
        </span>
        <span id="t11_40" class="t v0_40 s3_40">
          ажиллагааг нөөц боломжиндоо тулгуурлан{" "}
        </span>
        <span id="t12_40" class="t v0_40 s3_40">
          төлөвлөх
        </span>
        <span id="t13_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t14_40" class="t v0_40 s3_40">
          Шалгуур{" "}
        </span>
        <span id="t15_40" class="t v0_40 s3_40">
          үзүүлэлтүүдийг{" "}
        </span>
        <span id="t16_40" class="t v0_40 s3_40">
          тодорхойлох{" "}
        </span>
        <span id="t17_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="t18_40" class="t v0_40 s3_40">
          Зорилтдоо хүрсэн эсэхийг ямар шалгуур үзүүлэлтээр хэмжихийг тодорхой{" "}
        </span>
        <span id="t19_40" class="t v0_40 s3_40">
          болгож тоон болон чанарын үзүүлэлтийг оновчтой тодорхойлох
        </span>
        <span id="t1a_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t1b_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="t1c_40" class="t v0_40 s3_40">
          Богино хугацаанд гарах үр дүнг хэмжих шалгуур, дунд, урт хугацааны үр
          дүнг{" "}
        </span>
        <span id="t1d_40" class="t v0_40 s3_40">
          хэмжих шалгуураас ялгаатай байхыг анхаарах
        </span>
        <span id="t1e_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t1f_40" class="t v0_40 s3_40">
          Тэргүүлэх{" "}
        </span>
        <span id="t1g_40" class="t v0_40 s3_40">
          зорилтуудыг{" "}
        </span>
        <span id="t1h_40" class="t v0_40 s3_40">
          тодорхойлох{" "}
        </span>
        <span id="t1i_40" class="t v0_40 s3_40">
          Тухайн{" "}
        </span>
        <span id="t1j_40" class="t v0_40 s3_40">
          жилийн зорилгод хүрэхийн тулд сургууль{" "}
        </span>
        <span id="t1k_40" class="t v0_40 s3_40">
          ямар ямар тэргүүлэх чиглэлээр{" "}
        </span>
        <span id="t1l_40" class="t v0_40 s3_40">
          ажиллахаа тодорхой, хэмжигдэхүйц, биелэгдэхүйц, бодитой, цаг
          хугацаатай{" "}
        </span>
        <span id="t1m_40" class="t v0_40 s3_40">
          (SMART) зорилтууд болгон дэвшүүлэх. Ингэхдээ логик уялдаатай,
          эрэмбэлэх
        </span>
        <span id="t1n_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t1o_40" class="t v0_40 s3_40">
          Сургуулийн{" "}
        </span>
        <span id="t1p_40" class="t v0_40 s3_40">
          зорилгыг{" "}
        </span>
        <span id="t1q_40" class="t v0_40 s3_40">
          тодорхойлох{" "}
        </span>
        <span id="t1r_40" class="t v0_40 s3_40">
          Алсын хараанд хүрэхийн тулд өмнөх жил шийдэж чадалгүй орхисон энэ жил{" "}
        </span>
        <span id="t1s_40" class="t v0_40 s3_40">
          шийдвэрлэхээр сонгож авсан асуудлыг сургуулийн зорилго болгон нэгтгэн{" "}
        </span>
        <span id="t1t_40" class="t v0_40 s3_40">
          томьёолох. Ингэхдээ төрөөс баримталж буй боловсролын бодлого, улсын{" "}
        </span>
        <span id="t1u_40" class="t v0_40 s3_40">
          хөтөлбөр болон орон нутгийн хэрэгцээнд нийцүүлэх
        </span>
        <span id="t1v_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t1w_40" class="t v0_40 s3_40">
          Хүүхдийн{" "}
        </span>
        <span id="t1x_40" class="t v0_40 s3_40">
          дүр{" "}
        </span>
        <span id="t1y_40" class="t v0_40 s3_40">
          төрхийг{" "}
        </span>
        <span id="t1z_40" class="t v0_40 s3_40">
          тодорхойлох{" "}
        </span>
        <span id="t20_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="t21_40" class="t v0_40 s3_40">
          Сурагчдад тулгамдсан асуудал нь нэг жилийн дараа хэрхэн шийдэгдсэн{" "}
        </span>
        <span id="t22_40" class="t v0_40 s3_40">
          байхыг хүүхдийн дүр төрх{" "}
        </span>
        <span id="t23_40" class="t v0_40 s3_40">
          болгон энгийн ойлгомжтой томьёолох
        </span>
        <span id="t24_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t25_40" class="t v0_40 s4_40">
          -{" "}
        </span>
        <span id="t26_40" class="t v0_40 s3_40">
          Тодорхойлсон дүр төрхөө сурагч, эцэг эхэд ойлгуулж таниулах,{" "}
        </span>
        <span id="t27_40" class="t v0_40 s3_40">
          үүрэг{" "}
        </span>
        <span id="t28_40" class="t v0_40 s3_40">
          хариуцлагаа ойлгож ухамсарлах, урам зориг авах арга хэрэгсэл болгох
        </span>
        <span id="t29_40" class="t v0_40 s4_40">
          ;{" "}
        </span>
        <span id="t2a_40" class="t s5_40">
          Сургуулиуд олон салангид төлөвлөгөө биш нэгдсэн цэгцтэй төлөвлөлт{" "}
        </span>
        <span id="t2b_40" class="t s5_40">
          хийж түүнийгээ нэг хавтгайд зураглал болгон буулгаснаар оролцогч
          талууд{" "}
        </span>
        <span id="t2c_40" class="t s5_40">
          нэгдмэл ойлголттой болж, тус тусын үүрэг хариуцлагын хүрээнд хувь
          нэмрээ{" "}
        </span>
        <span id="t2d_40" class="t s5_40">
          оруулах, харилцан уялдаатай ажиллах, нөөцөө гол зорилгодоо чиглүүлэх{" "}
        </span>
        <span id="t2e_40" class="t s5_40">
          боломж нэмэгддэг. Тиймээс дээрх дарааллын дагуу боловсруулсан{" "}
        </span>
        <span id="t2f_40" class="t s5_40">
          бүрэлдэхүүн хэсгүүдийг нэг хавтгайд буулгах нь дараагийн чухал ажил
          юм.{" "}
        </span>
      </div>
    </div>
  );
}
