import React from "react";
export default function FiftyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_51{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_51{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_51{left:87px;bottom:941px;letter-spacing:-0.17px;word-spacing:7.47px;}
#t4_51{left:87px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t5_51{left:87px;bottom:884px;letter-spacing:-0.1px;}
#t6_51{left:87px;bottom:855px;}
#t7_51{left:127px;bottom:855px;letter-spacing:-0.15px;word-spacing:9.97px;}
#t8_51{left:87px;bottom:835px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_51{left:87px;bottom:709px;letter-spacing:-0.14px;word-spacing:10.91px;}
#ta_51{left:87px;bottom:689px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_51{left:87px;bottom:559px;}
#tc_51{left:103px;bottom:559px;letter-spacing:-0.14px;word-spacing:-2.17px;}
#td_51{left:87px;bottom:539px;letter-spacing:-0.13px;word-spacing:0.03px;}
#te_51{left:87px;bottom:388px;letter-spacing:-0.14px;word-spacing:7.77px;}
#tf_51{left:87px;bottom:368px;letter-spacing:-0.15px;word-spacing:2.72px;}
#tg_51{left:87px;bottom:348px;letter-spacing:-0.14px;word-spacing:0.72px;}
#th_51{left:87px;bottom:328px;letter-spacing:-0.15px;word-spacing:0.93px;}
#ti_51{left:87px;bottom:308px;letter-spacing:-0.15px;word-spacing:1.12px;}
#tj_51{left:87px;bottom:287px;letter-spacing:-0.13px;word-spacing:-0.96px;}
#tk_51{left:87px;bottom:267px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tl_51{left:87px;bottom:234px;letter-spacing:-0.13px;word-spacing:-0.74px;}
#tm_51{left:87px;bottom:214px;letter-spacing:-0.18px;word-spacing:0.08px;}

.s0_51{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_51{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_51{font-size:17px;font-family:ArialMT_1lm;color:#000;}
.s3_51{font-size:17px;font-family:Arial-ItalicMT_18u;color:#0070C0;}
.s4_51{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_51{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts51" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg51" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="51/51.svg"
          type="image/svg+xml"
          id="pdf51"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_51" class="t s1_51">
          51{" "}
        </span>
        <span id="t3_51" class="t s2_51">
          Жишээ болгож 2022 онд зохион байгуулсан сургуулийн гүйцэтгэлийн{" "}
        </span>
        <span id="t4_51" class="t s2_51">
          үнэлгээний үр дүнг кластер тус бүрээр харуулъя.{" "}
        </span>
        <span id="t5_51" class="t s3_51">
          Жишээ 1.{" "}
        </span>
        <span id="t6_51" class="t s2_51">
          А{" "}
        </span>
        <span id="t7_51" class="t s2_51">
          сургууль нь нийслэлийн кластерт хамаарах бөгөөд сургуулийн{" "}
        </span>
        <span id="t8_51" class="t s2_51">
          гүйцэтгэлийн үнэлгээгээр дараах үзүүлэлттэй байна.{" "}
        </span>
        <span id="t9_51" class="t s2_51">
          Б сургууль аймгийн төвийн кластерт хамаарах бөгөөд сургуулийн{" "}
        </span>
        <span id="ta_51" class="t s2_51">
          гүйцэтгэлийн үнэлгээгээр дараах үзүүлэлттэй байна.{" "}
        </span>
        <span id="tb_51" class="t s2_51">
          В{" "}
        </span>
        <span id="tc_51" class="t s2_51">
          сургуулийн сум-багийн кластерт хамаарах бөгөөд сургуулийн гүйцэтгэлийн{" "}
        </span>
        <span id="td_51" class="t s2_51">
          үнэлгээгээр дараах үзүүлэлттэй байна.{" "}
        </span>
        <span id="te_51" class="t s4_51">
          Дээрх гурван тохиолдлын хувьд сургууль тус бүрийн анхны онооны{" "}
        </span>
        <span id="tf_51" class="t s4_51">
          (авбал зохих 100) дундаж ижил боловч стандарт оноо, эрэмбийн хувийн{" "}
        </span>
        <span id="tg_51" class="t s4_51">
          онооноос шалтгаалж сургууль бүрийн индекс ялгаатай байна. Нийслэлийн{" "}
        </span>
        <span id="th_51" class="t s4_51">
          кластер дахь А сургууль 76.62 анхны оноотой III индекст, Б сургууль
          76.62{" "}
        </span>
        <span id="ti_51" class="t s4_51">
          анхны оноотой II индекст, В сургууль 76.57 буюу нийслэл, аймгийн
          төвийн{" "}
        </span>
        <span id="tj_51" class="t s4_51">
          кластераас доогуур анхны оноотой ч тэр нь тухайн кластертаа өндөр
          байгаа{" "}
        </span>
        <span id="tk_51" class="t s4_51">
          тул I индекст байна.{" "}
        </span>
        <span id="tl_51" class="t s4_51">
          Анхны оноо, стандарт оноо, эрэмбийн хувь, индекс гэх ойлголтуудын
          ялгааг{" "}
        </span>
        <span id="tm_51" class="t s4_51">
          доор тайлбарлав.{" "}
        </span>
      </div>
    </div>
  );
}
