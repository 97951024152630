import React from "react";
export default function ThirtyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_33{left:676px;bottom:58px;letter-spacing:-0.11px;}
#t2_33{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_33{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:-0.67px;}
#t4_33{left:87px;bottom:921px;letter-spacing:-0.13px;word-spacing:-0.2px;}
#t5_33{left:87px;bottom:901px;letter-spacing:-0.12px;word-spacing:-1.56px;}
#t6_33{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:4.54px;}
#t7_33{left:87px;bottom:860px;letter-spacing:-0.15px;word-spacing:7.15px;}
#t8_33{left:87px;bottom:840px;letter-spacing:-0.18px;word-spacing:4.16px;}
#t9_33{left:87px;bottom:820px;letter-spacing:-0.14px;word-spacing:5.44px;}
#ta_33{left:87px;bottom:800px;letter-spacing:-0.15px;word-spacing:0.06px;}
#tb_33{left:87px;bottom:759px;letter-spacing:-0.28px;word-spacing:4.07px;}
#tc_33{left:87px;bottom:739px;letter-spacing:-0.31px;word-spacing:3.17px;}
#td_33{left:87px;bottom:719px;letter-spacing:-0.3px;word-spacing:-2.13px;}
#te_33{left:87px;bottom:699px;letter-spacing:-0.32px;word-spacing:-0.54px;}
#tf_33{left:87px;bottom:679px;letter-spacing:-0.3px;word-spacing:2px;}
#tg_33{left:87px;bottom:659px;letter-spacing:-0.31px;word-spacing:4.28px;}
#th_33{left:87px;bottom:638px;letter-spacing:-0.32px;word-spacing:0.56px;}
#ti_33{left:87px;bottom:618px;letter-spacing:-0.3px;word-spacing:4.38px;}
#tj_33{left:87px;bottom:598px;letter-spacing:-0.29px;word-spacing:-0.88px;}
#tk_33{left:87px;bottom:578px;letter-spacing:-0.29px;word-spacing:0.4px;}
#tl_33{left:87px;bottom:558px;letter-spacing:-0.35px;word-spacing:0.09px;}
#tm_33{left:87px;bottom:517px;letter-spacing:-0.16px;word-spacing:1.61px;}
#tn_33{left:87px;bottom:497px;letter-spacing:-0.15px;word-spacing:-1.32px;}
#to_33{left:87px;bottom:477px;letter-spacing:-0.13px;word-spacing:-0.57px;}
#tp_33{left:87px;bottom:457px;letter-spacing:-0.17px;word-spacing:-0.08px;}
#tq_33{left:87px;bottom:437px;letter-spacing:-0.13px;word-spacing:2.49px;}
#tr_33{left:87px;bottom:417px;letter-spacing:-0.16px;word-spacing:2.12px;}
#ts_33{left:87px;bottom:396px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tt_33{left:100px;bottom:336px;letter-spacing:0.1px;word-spacing:0.04px;}
#tu_33{left:100px;bottom:299px;letter-spacing:0.09px;word-spacing:0.05px;}
#tv_33{left:100px;bottom:281px;letter-spacing:0.07px;word-spacing:8.17px;}
#tw_33{left:100px;bottom:262px;letter-spacing:0.09px;word-spacing:3.25px;}
#tx_33{left:100px;bottom:244px;letter-spacing:0.1px;word-spacing:10.25px;}
#ty_33{left:100px;bottom:226px;letter-spacing:0.1px;word-spacing:0.04px;}
#tz_33{left:100px;bottom:207px;letter-spacing:0.08px;word-spacing:2.87px;}
#t10_33{left:100px;bottom:189px;letter-spacing:0.1px;word-spacing:2.81px;}
#t11_33{left:100px;bottom:171px;letter-spacing:0.08px;word-spacing:0.06px;}

.s0_33{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_33{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_33{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_33{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s4_33{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s5_33{font-size:15px;font-family:ArialMT_1lm;color:#333;}
.t.m0_33{transform:matrix(-1,0,0,1,0,0);}`}
      </style>
      <style id="fonts33" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg33" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="33/33.svg"
          type="image/svg+xml"
          id="pdf33"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_33" class="t s1_33">
          33{" "}
        </span>
        <span id="t3_33" class="t s2_33">
          дүн шинжилгээ хийж, суурь түвшин, хамрах хүрээ, шалтгааныг тодорхойлох{" "}
        </span>
        <span id="t4_33" class="t s2_33">
          тодорхой үе шаттай цогц үйл явц юм. Юун түрүүнд сурагчийн ахиц дэвшил,{" "}
        </span>
        <span id="t5_33" class="t s2_33">
          хөгжлийн үнэлгээг бодитой хийдэг байх, үнэлгээний шалгуур нь
          хөндлөнгийн{" "}
        </span>
        <span id="t6_33" class="t s2_33">
          үнэлгээний шалгууртай нийцдэг байх, зөвхөн сурлагын ахиц, амжилтыг{" "}
        </span>
        <span id="t7_33" class="t s2_33">
          үнэлээд зогсохгүй, хандлага төлөвшлийг үнэлэхэд анхаарах хэрэгтэй.{" "}
        </span>
        <span id="t8_33" class="t s2_33">
          Шаардлагатай тохиолдолд нэмэлт мэдээлэл цуглуулж, шинжлэх замаар{" "}
        </span>
        <span id="t9_33" class="t s2_33">
          тулгамдаж буй асуудал, түүний шалтгаан, нөлөөлж буй хүчин зүйлийг{" "}
        </span>
        <span id="ta_33" class="t s2_33">
          тодруулах нь зүйтэй.{" "}
        </span>
        <span id="tb_33" class="t s2_33">
          Сурагчийг суралцахуйн түүхээ өөрөө бүтээгч, СҮА-ны идэвхтэй оролцогч{" "}
        </span>
        <span id="tc_33" class="t s2_33">
          гэж үзэж сургуулийн менежментийн бүхий л үе шатад үр дүнтэй оролцох{" "}
        </span>
        <span id="td_33" class="t s2_33">
          боломжоор хангах нь чухал. Мөн багшаас гадна нийгмийн ажилтан,
          сургуулийн{" "}
        </span>
        <span id="te_33" class="t s2_33">
          сэтгэл зүйч, эмч, үйлчилгээний ажилтнууд, эцэг, эх, асран хамгаалагчид
          зэрэг{" "}
        </span>
        <span id="tf_33" class="t s2_33">
          сурагчдын асуудлыг ажиглаж, мэдэрдэг бүхий л хүмүүс тэдэнд тулгамдсан{" "}
        </span>
        <span id="tg_33" class="t s2_33">
          асуудлуудыг илрүүлэхээс эхлээд сургуулийн менежментийн хэрэгжилтийг{" "}
        </span>
        <span id="th_33" class="t s2_33">
          боловсруулахад оролцдог байх нь тэднийг алсын хараа, зорилго,
          зорилтдоо{" "}
        </span>
        <span id="ti_33" class="t s2_33">
          нэгтгэхэд тустай. Эцэг эх, асран хамгаалагчдын хувьд болон сургуулийн{" "}
        </span>
        <span id="tj_33" class="t s2_33">
          зөвлөл (СЗ)-ийн оролцоо нь тэдний хувьд хуулиар хүлээсэн чиг үүрэг юм.
          Энэ{" "}
        </span>
        <span id="tk_33" class="t s2_33">
          бүх үйл явцыг чиглүүлж, үр дүнтэй зохион байгуулах нь сургуулийн
          захирал,{" "}
        </span>
        <span id="tl_33" class="t s2_33">
          удирдлагын гол үүрэг байх учиртай.{" "}
        </span>
        <span id="tm_33" class="t s2_33">
          Шийдвэр гаргах, хэрэгжүүлэх үйл явцад талуудын оролцоо хангагдсанаар{" "}
        </span>
        <span id="tn_33" class="t s2_33">
          тэд тус бүртээ өөр өөрийн үүрэгтэй байж, асуудал бодитой, зорилго
          нэгдмэл,{" "}
        </span>
        <span id="to_33" class="t s2_33">
          төлөвлөлт оновчтой, хэрэгжилт үр дүнтэй, хяналт олон талын,
          сайжруулалт{" "}
        </span>
        <span id="tp_33" class="t s2_33">
          тухай бүрт хийгдэх боломж бүрдэх юм. Гол нь тэд оролцоо, шийдвэрийнхээ{" "}
        </span>
        <span id="tq_33" class="t s2_33">
          төлөө хариуцлага хүлээх, сурагч, сургуулиа тасралтгүй хөгжүүлэхэд хувь{" "}
        </span>
        <span id="tr_33" class="t s2_33">
          нэмрээ оруулах санаачилга сайжирч, улмаар нөөцөө оновчтой зарцуулах{" "}
        </span>
        <span id="ts_33" class="t s2_33">
          үр дүнтэй менежментийг хэрэгжүүлэх боломж бүрдэнэ.{" "}
        </span>
        <span id="tt_33" class="t s3_33">
          Боловсролын ерөнхий хууль (2023){" "}
        </span>
        <span id="tu_33" class="t s4_33">
          40 дүгээр зүйл.Эцэг, эх, асран хамгаалагч, харгалзан дэмжигчийн эрх,
          үүрэг{" "}
        </span>
        <span id="tv_33" class="t s5_33">
          40.2.8.цэцэрлэг, ерөнхий боловсролын сургуулиас зохион байгуулах үйл{" "}
        </span>
        <span id="tw_33" class="t s5_33">
          ажиллагаанд оролцох, байгууллагын өөрийн удирдлага, багш, суралцагчийн{" "}
        </span>
        <span id="tx_33" class="t s5_33">
          болон эцэг, эх, асран хамгаалагч, харгалзан дэмжигчийн төлөөллийн{" "}
        </span>
        <span id="ty_33" class="t s5_33">
          байгууллагатай хамтран ажиллах, дэмжин туслах;{" "}
        </span>
        <span id="tz_33" class="t s5_33">
          40.3.Эцэг, эх, асран хамгаалагч, харгалзан дэмжигч боловсролын
          сургалтын{" "}
        </span>
        <span id="t10_33" class="t s5_33">
          байгууллагатай гэрээ байгуулан хамтран ажиллах бөгөөд гэрээний
          загварыг{" "}
        </span>
        <span id="t11_33" class="t s5_33">
          боловсролын асуудал хариуцсан төрийн захиргааны байгууллага батална.{" "}
        </span>
      </div>
    </div>
  );
}
