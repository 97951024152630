import React from "react";
export default function Eighteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_18{left:87px;bottom:58px;letter-spacing:-0.09px;}
#t2_18{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_18{left:87px;bottom:937px;letter-spacing:0.1px;word-spacing:0.12px;}
#t4_18{left:87px;bottom:892px;letter-spacing:-0.17px;word-spacing:6.91px;}
#t5_18{left:87px;bottom:872px;letter-spacing:-0.1px;}
#t6_18{left:149px;bottom:872px;letter-spacing:-0.13px;word-spacing:3.78px;}
#t7_18{left:87px;bottom:851px;letter-spacing:-0.13px;}
#t8_18{left:210px;bottom:851px;letter-spacing:-0.18px;}
#t9_18{left:278px;bottom:851px;letter-spacing:-0.15px;}
#ta_18{left:374px;bottom:851px;letter-spacing:-0.08px;}
#tb_18{left:87px;bottom:831px;letter-spacing:-0.18px;word-spacing:-1.48px;}
#tc_18{left:87px;bottom:811px;letter-spacing:-0.09px;}
#td_18{left:166px;bottom:811px;letter-spacing:-0.13px;}
#te_18{left:232px;bottom:811px;letter-spacing:-0.23px;}
#tf_18{left:296px;bottom:811px;letter-spacing:-0.16px;}
#tg_18{left:87px;bottom:791px;letter-spacing:-0.12px;word-spacing:5.14px;}
#th_18{left:87px;bottom:771px;letter-spacing:-0.09px;}
#ti_18{left:164px;bottom:771px;letter-spacing:-0.33px;}
#tj_18{left:284px;bottom:771px;letter-spacing:-0.12px;}
#tk_18{left:380px;bottom:771px;letter-spacing:-0.08px;}
#tl_18{left:87px;bottom:751px;letter-spacing:-0.13px;word-spacing:7.19px;}
#tm_18{left:87px;bottom:730px;letter-spacing:-0.15px;word-spacing:-2.07px;}
#tn_18{left:87px;bottom:710px;letter-spacing:-0.16px;}
#to_18{left:87px;bottom:677px;letter-spacing:-0.2px;word-spacing:1.31px;}
#tp_18{left:184px;bottom:677px;letter-spacing:-0.13px;word-spacing:1.25px;}
#tq_18{left:87px;bottom:657px;letter-spacing:-0.16px;word-spacing:3.52px;}
#tr_18{left:87px;bottom:637px;letter-spacing:-0.18px;word-spacing:-0.63px;}
#ts_18{left:242px;bottom:637px;letter-spacing:-0.14px;word-spacing:-0.66px;}
#tt_18{left:87px;bottom:617px;letter-spacing:-0.18px;word-spacing:2.3px;}
#tu_18{left:87px;bottom:596px;letter-spacing:-0.15px;word-spacing:-0.9px;}
#tv_18{left:87px;bottom:576px;letter-spacing:-0.14px;word-spacing:4.56px;}
#tw_18{left:87px;bottom:556px;letter-spacing:-0.13px;word-spacing:0.27px;}
#tx_18{left:87px;bottom:536px;letter-spacing:-0.2px;word-spacing:5.67px;}
#ty_18{left:87px;bottom:516px;letter-spacing:-0.11px;word-spacing:0.05px;}
#tz_18{left:87px;bottom:496px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t10_18{left:121px;bottom:449px;letter-spacing:0.13px;}
#t11_18{left:229px;bottom:449px;letter-spacing:0.09px;}
#t12_18{left:312px;bottom:449px;letter-spacing:0.07px;}
#t13_18{left:408px;bottom:449px;}
#t14_18{left:434px;bottom:449px;letter-spacing:0.11px;}
#t15_18{left:524px;bottom:449px;letter-spacing:0.13px;}
#t16_18{left:592px;bottom:449px;letter-spacing:0.09px;}
#t17_18{left:100px;bottom:431px;letter-spacing:0.07px;word-spacing:0.08px;}
#t18_18{left:100px;bottom:412px;letter-spacing:-0.21px;word-spacing:3.43px;}
#t19_18{left:113px;bottom:394px;letter-spacing:-0.2px;word-spacing:0.75px;}
#t1a_18{left:113px;bottom:376px;letter-spacing:-0.2px;word-spacing:0.92px;}
#t1b_18{left:113px;bottom:357px;letter-spacing:-0.24px;}
#t1c_18{left:100px;bottom:339px;letter-spacing:0.13px;word-spacing:7.58px;}
#t1d_18{left:113px;bottom:321px;letter-spacing:0.09px;word-spacing:4.31px;}
#t1e_18{left:113px;bottom:302px;letter-spacing:0.07px;word-spacing:0.51px;}
#t1f_18{left:113px;bottom:284px;letter-spacing:0.05px;word-spacing:0.09px;}
#t1g_18{left:100px;bottom:266px;letter-spacing:0.09px;word-spacing:-1.46px;}
#t1h_18{left:113px;bottom:247px;letter-spacing:0.09px;word-spacing:1.47px;}
#t1i_18{left:113px;bottom:229px;letter-spacing:0.09px;word-spacing:-1.33px;}
#t1j_18{left:113px;bottom:211px;letter-spacing:0.07px;word-spacing:2.97px;}
#t1k_18{left:113px;bottom:192px;letter-spacing:0.13px;word-spacing:0.02px;}
#t1l_18{left:433px;bottom:706px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1m_18{left:519px;bottom:689px;letter-spacing:-0.14px;}

.s0_18{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_18{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_18{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_18{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_18{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_18{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_18{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s7_18{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts18" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg18" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="18/18.svg"
          type="image/svg+xml"
          id="pdf18"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_18" class="t s1_18">
          18{" "}
        </span>
        <span id="t3_18" class="t s2_18">
          СУРГУУЛЬ ХЭРХЭН ӨӨРЧЛӨГДӨЖ БАЙНА ВЭ?{" "}
        </span>
        <span id="t4_18" class="t s3_18">
          Сургууль нь хаалттай бус, бүхэл эко системийн нэг хэсэг болж{" "}
        </span>
        <span id="t5_18" class="t s3_18">
          байна.{" "}
        </span>
        <span id="t6_18" class="t s4_18">
          Сургууль нь нийтээрээ танхимд{" "}
        </span>
        <span id="t7_18" class="t s4_18">
          суралцахаас{" "}
        </span>
        <span id="t8_18" class="t s4_18">
          гадна{" "}
        </span>
        <span id="t9_18" class="t s4_18">
          сургууль,{" "}
        </span>
        <span id="ta_18" class="t s4_18">
          анги{" "}
        </span>
        <span id="tb_18" class="t s4_18">
          танхимаас гадуур суралцахыг, сонирхлын{" "}
        </span>
        <span id="tc_18" class="t s4_18">
          бүлгүүд{" "}
        </span>
        <span id="td_18" class="t s4_18">
          болон{" "}
        </span>
        <span id="te_18" class="t s4_18">
          бусад{" "}
        </span>
        <span id="tf_18" class="t s4_18">
          байгууллагын,{" "}
        </span>
        <span id="tg_18" class="t s4_18">
          нийгмийн сүлжээг ашиглан суралцахыг{" "}
        </span>
        <span id="th_18" class="t s4_18">
          хүлээн{" "}
        </span>
        <span id="ti_18" class="t s4_18">
          зөвшөөрдөг,{" "}
        </span>
        <span id="tj_18" class="t s4_18">
          суралцах{" "}
        </span>
        <span id="tk_18" class="t s4_18">
          үйл{" "}
        </span>
        <span id="tl_18" class="t s4_18">
          ажиллагааг илүү өргөн, нээлттэй, уян{" "}
        </span>
        <span id="tm_18" class="t s4_18">
          хатан орон зай болон хувиран өөрчлөгдөж{" "}
        </span>
        <span id="tn_18" class="t s4_18">
          байна.{" "}
        </span>
        <span id="to_18" class="t s4_18">
          Судлаачид “
        </span>
        <span id="tp_18" class="t s5_18">
          XXI зууны суралцагчийг ХХ{" "}
        </span>
        <span id="tq_18" class="t s5_18">
          зууны сургалтын арга зүйгээр, ХIX зууны сургууль, сургалтын орчинд{" "}
        </span>
        <span id="tr_18" class="t s5_18">
          бэлтгэх боломжгүй
        </span>
        <span id="ts_18" class="t s4_18">
          ” гэдгийг тэмдэглэж байгаа бөгөөд XXI зуунд сургуулийн{" "}
        </span>
        <span id="tt_18" class="t s4_18">
          талаарх ойлголт, хэв шинж, менежмент ихээр өөрчлөгдөж байна. Ямар ч{" "}
        </span>
        <span id="tu_18" class="t s4_18">
          тохиолдолд сурагчийн хувьд суралцах үйлийг нь хөнгөвчилж, баялаг
          болгож{" "}
        </span>
        <span id="tv_18" class="t s4_18">
          байдаг сургууль чухал. Учир нь суралцахуйн гол мөн чанар нь зөвхөн{" "}
        </span>
        <span id="tw_18" class="t s4_18">
          мэдлэг эзэмших үйл явц бус сургууль болон түүнийг тойрсон олон нийтийн{" "}
        </span>
        <span id="tx_18" class="t s4_18">
          харилцаа юм. Бид бусадтайгаа хамт болон бусдаас суралцаж байдаг.{" "}
        </span>
        <span id="ty_18" class="t s4_18">
          Үүнээс гадна зөвхөн суралцах зорилгыг өмнөө тавьж, нэг цаг хугацаа,
          орон{" "}
        </span>
        <span id="tz_18" class="t s4_18">
          зайд төвлөрсөн нөхцөлд хүн хамгийн сайн суралцдаг байна.{" "}
        </span>
        <span id="t10_18" class="t s6_18">
          ЭЗХАХБ-аас{" "}
        </span>
        <span id="t11_18" class="t s6_18">
          ирээдүйн{" "}
        </span>
        <span id="t12_18" class="t s6_18">
          сургуулийн{" "}
        </span>
        <span id="t13_18" class="t s6_18">
          4{" "}
        </span>
        <span id="t14_18" class="t s6_18">
          сценарийг{" "}
        </span>
        <span id="t15_18" class="t s6_18">
          дараах{" "}
        </span>
        <span id="t16_18" class="t s6_18">
          байдлаар{" "}
        </span>
        <span id="t17_18" class="t s6_18">
          тодорхойлоод байна.{" "}
        </span>
        <span id="t18_18" class="t s6_18">
          - Сургуулийн өргөжүүлэлт (Schooling extend) - Албан боловсролд
          хамрагдалт{" "}
        </span>
        <span id="t19_18" class="t s6_18">
          нэмэгдсээр байна. Олон улсын хамтын ажиллагаа, технологийн дэвшил нь
          бие{" "}
        </span>
        <span id="t1a_18" class="t s6_18">
          дааж суралцах арга барилыг дэмжиж байна. Сургуулийн бүтэц, үйл явц
          хэвээр{" "}
        </span>
        <span id="t1b_18" class="t s6_18">
          байна.{" "}
        </span>
        <span id="t1c_18" class="t s6_18">
          - Боловсролын аутсорсинг (Education outsourced) - Нийгмээс иргэдийг{" "}
        </span>
        <span id="t1d_18" class="t s6_18">
          боловсролтой болгож, чадавхжуулах арга хэмжээнд шууд оролцох тусам{" "}
        </span>
        <span id="t1e_18" class="t s6_18">
          уламжлалт сургуулийн тогтолцоонд өөрчлөлт ордог. Суралцахуй нь дижитал{" "}
        </span>
        <span id="t1f_18" class="t s6_18">
          технологийг ашиглан илүү олон талт, уян хатан зохицуулалттай болж
          байна.{" "}
        </span>
        <span id="t1g_18" class="t s6_18">
          - Сургууль суралцахуйн зангилаа талбар (Schools as learning hubs) –
          Сургуулиуд{" "}
        </span>
        <span id="t1h_18" class="t s6_18">
          хэвээр байгаа ч олон талт байдал, лаборатори туршилт нь нийтлэг
          үзэгдэл{" "}
        </span>
        <span id="t1i_18" class="t s6_18">
          болсон. “Сургуулийн хана”-г нээх буюу сургуулиуд илүү нээлттэй
          ажилласнаар{" "}
        </span>
        <span id="t1j_18" class="t s6_18">
          нийгэмтэй холбогдож, байнга хувьсан өөрчлөгддөг сургалтын арга хэлбэр,{" "}
        </span>
        <span id="t1k_18" class="t s6_18">
          иргэний оролцоо, нийгмийн инновацыг дэмжих боломж бүрдэнэ.{" "}
        </span>
        <span id="t1l_18" class="t s7_18">
          Зураг 1.3. Интерактив цахим хичээл{" "}
        </span>
        <span id="t1m_18" class="t s7_18">
          Medle.mn{" "}
        </span>
      </div>
    </div>
  );
}
