import React from "react";
export default function TwentyThree() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_23{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_23{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_23{left:87px;bottom:936px;letter-spacing:0.12px;word-spacing:0.09px;}
#t4_23{left:87px;bottom:895px;letter-spacing:-0.14px;word-spacing:6.65px;}
#t5_23{left:87px;bottom:875px;letter-spacing:-0.13px;word-spacing:4.62px;}
#t6_23{left:87px;bottom:854px;letter-spacing:-0.21px;}
#t7_23{left:176px;bottom:854px;letter-spacing:-0.13px;word-spacing:0.95px;}
#t8_23{left:87px;bottom:834px;letter-spacing:-0.17px;word-spacing:4.39px;}
#t9_23{left:87px;bottom:814px;letter-spacing:-0.12px;word-spacing:-0.73px;}
#ta_23{left:87px;bottom:794px;letter-spacing:-0.2px;word-spacing:0.11px;}
#tb_23{left:87px;bottom:761px;letter-spacing:-0.17px;word-spacing:10.9px;}
#tc_23{left:87px;bottom:741px;letter-spacing:-0.15px;word-spacing:3.44px;}
#td_23{left:87px;bottom:720px;letter-spacing:-0.14px;word-spacing:4.97px;}
#te_23{left:87px;bottom:700px;letter-spacing:-0.13px;word-spacing:-2.13px;}
#tf_23{left:318px;bottom:700px;letter-spacing:-0.19px;word-spacing:-2.07px;}
#tg_23{left:589px;bottom:700px;letter-spacing:-0.15px;}
#th_23{left:87px;bottom:680px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ti_23{left:586px;bottom:689px;}
#tj_23{left:87px;bottom:647px;letter-spacing:-0.16px;word-spacing:9.13px;}
#tk_23{left:87px;bottom:627px;letter-spacing:-0.26px;}
#tl_23{left:191px;bottom:627px;letter-spacing:-0.09px;}
#tm_23{left:258px;bottom:627px;letter-spacing:-0.09px;}
#tn_23{left:347px;bottom:627px;letter-spacing:-0.08px;}
#to_23{left:474px;bottom:627px;letter-spacing:-0.17px;}
#tp_23{left:596px;bottom:627px;letter-spacing:-0.16px;}
#tq_23{left:87px;bottom:607px;letter-spacing:-0.17px;}
#tr_23{left:217px;bottom:607px;letter-spacing:-0.18px;}
#ts_23{left:284px;bottom:607px;letter-spacing:-0.12px;}
#tt_23{left:378px;bottom:607px;letter-spacing:-0.11px;}
#tu_23{left:506px;bottom:607px;letter-spacing:-0.25px;}
#tv_23{left:594px;bottom:607px;letter-spacing:-0.15px;}
#tw_23{left:87px;bottom:586px;letter-spacing:-0.12px;word-spacing:1.09px;}
#tx_23{left:87px;bottom:566px;letter-spacing:-0.18px;word-spacing:7.67px;}
#ty_23{left:87px;bottom:546px;letter-spacing:-0.12px;word-spacing:0.44px;}
#tz_23{left:87px;bottom:526px;letter-spacing:-0.17px;word-spacing:1.7px;}
#t10_23{left:87px;bottom:506px;letter-spacing:-0.13px;word-spacing:1.22px;}
#t11_23{left:670px;bottom:514px;}
#t12_23{left:87px;bottom:486px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t13_23{left:100px;bottom:443px;letter-spacing:0.1px;word-spacing:0.04px;}
#t14_23{left:100px;bottom:406px;letter-spacing:0.11px;word-spacing:6.19px;}
#t15_23{left:100px;bottom:388px;letter-spacing:0.13px;}
#t16_23{left:100px;bottom:370px;letter-spacing:0.1px;word-spacing:2.44px;}
#t17_23{left:100px;bottom:351px;letter-spacing:0.13px;word-spacing:0.01px;}
#t18_23{left:100px;bottom:315px;letter-spacing:0.14px;word-spacing:9px;}
#t19_23{left:100px;bottom:296px;letter-spacing:0.08px;}
#t1a_23{left:211px;bottom:296px;letter-spacing:0.07px;}
#t1b_23{left:301px;bottom:296px;letter-spacing:0.05px;}
#t1c_23{left:419px;bottom:296px;}
#t1d_23{left:477px;bottom:296px;letter-spacing:0.08px;}
#t1e_23{left:588px;bottom:296px;letter-spacing:0.07px;}
#t1f_23{left:100px;bottom:278px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1g_23{left:110px;bottom:260px;letter-spacing:0.09px;word-spacing:1.57px;}
#t1h_23{left:110px;bottom:241px;letter-spacing:0.09px;word-spacing:-0.92px;}
#t1i_23{left:110px;bottom:223px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1j_23{left:87px;bottom:133px;}
#t1k_23{left:108px;bottom:133px;letter-spacing:0.09px;word-spacing:-0.04px;}
#t1l_23{left:87px;bottom:118px;}
#t1m_23{left:108px;bottom:118px;letter-spacing:0.08px;word-spacing:0.03px;}

.s0_23{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_23{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_23{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_23{font-size:17px;font-family:MogulArial-Bold_179;color:#3AB5C9;}
.s4_23{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_23{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s6_23{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s7_23{font-size:17px;font-family:MogulArial_16s;color:#333;}
.s8_23{font-size:10px;font-family:MogulArial_16s;color:#333;}
.s9_23{font-size:15px;font-family:MogulArial-Italic_17h;color:#333;}
.sa_23{font-size:15px;font-family:MogulArial_16s;color:#333;}
.sb_23{font-size:15px;font-family:ArialMT_1lm;color:#333;}
.sc_23{font-size:12px;font-family:MogulArial_18b;color:#000;}
.sd_23{font-size:12px;font-family:MogulArial_16s;color:#000;}
.t.m0_23{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts23" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg23" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="23/23.svg"
          type="image/svg+xml"
          id="pdf23"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_23" class="t s1_23">
          23{" "}
        </span>
        <span id="t3_23" class="t s2_23">
          СУРГУУЛИЙН ЗАХИРАЛ ЮУ ХИЙХ ВЭ?{" "}
        </span>
        <span id="t4_23" class="t s3_23">
          Сургуулийн ажлын үр дүн нь сурагчдын сурлагын ахиц, хөгжил{" "}
        </span>
        <span id="t5_23" class="t s3_23">
          төлөвшлөөр илэрнэ. Харин сургуулийн ажлыг сургуулийн захирал{" "}
        </span>
        <span id="t6_23" class="t s3_23">
          удирдана.{" "}
        </span>
        <span id="t7_23" class="t s4_23">
          Захирал сургуулийн алсыг харааг тодорхойлох, түүнд чиглэсэн{" "}
        </span>
        <span id="t8_23" class="t s4_23">
          сургалт, үйл ажиллагааны богино, дунд, урт хугацааны үйл ажиллагааг{" "}
        </span>
        <span id="t9_23" class="t s4_23">
          төлөвлөх, хэрэгжүүлэх ажлыг зохион байгуулах, хянах, санхүүгийн
          шийдвэр{" "}
        </span>
        <span id="ta_23" class="t s4_23">
          гаргах байдлаар сургуулийг удирддаг. Тэд бол манлайлагчид юм.{" "}
        </span>
        <span id="tb_23" class="t s4_23">
          Сургуулийн удирдагчид хөгжиж, удирдах арга барилаа дээшлүүлж,{" "}
        </span>
        <span id="tc_23" class="t s4_23">
          бүтээмжээ нэмэгдүүлэх нь сурагчдын сурлагын түвшинг дээшлүүлэх гол{" "}
        </span>
        <span id="td_23" class="t s4_23">
          хүчин зүйл бөгөөд багшийн удирдлага зөвхөн түүний зааж буй ангийн{" "}
        </span>
        <span id="te_23" class="t s4_23">
          сурагчдын шалгалтын оноонд{" "}
        </span>
        <span id="tf_23" class="t s4_23">
          хамаарах бол захирлын удирдлага{" "}
        </span>
        <span id="tg_23" class="t s4_23">
          сургуулийн{" "}
        </span>
        <span id="th_23" class="t s4_23">
          бүх сурагчдын оноонд нөлөөлдгийг судлаачид тогтоосон байна.{" "}
        </span>
        <span id="ti_23" class="t s5_23">
          6{" "}
        </span>
        <span id="tj_23" class="t s6_23">
          Захирал нь боловсролын багц хуулиар хүлээсэн чиг үүрэгтэй.{" "}
        </span>
        <span id="tk_23" class="t s7_23">
          Тухайлбал,{" "}
        </span>
        <span id="tl_23" class="t s7_23">
          БЕХ-д{" "}
        </span>
        <span id="tm_23" class="t s7_23">
          зааснаар{" "}
        </span>
        <span id="tn_23" class="t s7_23">
          “33.3.3.тухайн{" "}
        </span>
        <span id="to_23" class="t s7_23">
          боловсролын{" "}
        </span>
        <span id="tp_23" class="t s7_23">
          сургалтын{" "}
        </span>
        <span id="tq_23" class="t s7_23">
          байгууллагын{" "}
        </span>
        <span id="tr_23" class="t s7_23">
          багш,{" "}
        </span>
        <span id="ts_23" class="t s7_23">
          ажилтан,{" "}
        </span>
        <span id="tt_23" class="t s7_23">
          суралцагчийг{" "}
        </span>
        <span id="tu_23" class="t s7_23">
          удирдан{" "}
        </span>
        <span id="tv_23" class="t s7_23">
          чиглүүлэх,{" "}
        </span>
        <span id="tw_23" class="t s7_23">
          тасралтгүй хөгжлийг хангах, үнэлэх, дэмжлэг үзүүлэх;” СӨЕБХ-д зааснаар{" "}
        </span>
        <span id="tx_23" class="t s7_23">
          “17.1.1.цэцэрлэг, ерөнхий боловсролын сургуулийг хөгжүүлэх бодлого,{" "}
        </span>
        <span id="ty_23" class="t s7_23">
          төлөвлөгөө, дүрэм, журам боловсруулах, түүний хэрэгжилтийг мэргэжлийн{" "}
        </span>
        <span id="tz_23" class="t s7_23">
          түвшинд зохион байгуулахад багш, суралцагч, эцэг, эх, асран
          хамгаалагч,{" "}
        </span>
        <span id="t10_23" class="t s7_23">
          харгалзан дэмжигч, олон нийтийн оролцоо, түншлэлийг хангаж ажиллах;”{" "}
        </span>
        <span id="t11_23" class="t s8_23">
          7{" "}
        </span>
        <span id="t12_23" class="t s7_23">
          нь таны үндсэн чиг үүрэг болно.{" "}
        </span>
        <span id="t13_23" class="t s9_23">
          Боловсролын ерөнхий хууль (2023){" "}
        </span>
        <span id="t14_23" class="t sa_23">
          33.3. Боловсролын сургалтын байгууллагын захирал, эрхлэгч дараах чиг{" "}
        </span>
        <span id="t15_23" class="t sa_23">
          үүргийг хэрэгжүүлнэ:{" "}
        </span>
        <span id="t16_23" class="t sa_23">
          33.3.2. тухайн боловсролын сургалтын байгууллагын багш, ажилтныг
          хуульд{" "}
        </span>
        <span id="t17_23" class="t sa_23">
          заасны дагуу томилж, чөлөөлөх;{" "}
        </span>
        <span id="t18_23" class="t sb_23">
          33.2.Төрийн болон орон нутгийн өмчийн мэргэжлийн болон техникийн{" "}
        </span>
        <span id="t19_23" class="t sb_23">
          боловсролын{" "}
        </span>
        <span id="t1a_23" class="t sb_23">
          сургалтын{" "}
        </span>
        <span id="t1b_23" class="t sb_23">
          байгууллагаас{" "}
        </span>
        <span id="t1c_23" class="t sb_23">
          бусад{" "}
        </span>
        <span id="t1d_23" class="t sb_23">
          боловсролын{" "}
        </span>
        <span id="t1e_23" class="t sb_23">
          сургалтын{" "}
        </span>
        <span id="t1f_23" class="t sb_23">
          байгууллагын захирал, эрхлэгч дараах тусгай шаардлагыг хангасан байна:{" "}
        </span>
        <span id="t1g_23" class="t sb_23">
          33.2.1. багш мэргэжлээр их, дээд сургууль төгссөн боловсролын
          удирдлага,{" "}
        </span>
        <span id="t1h_23" class="t sb_23">
          сургалтын агуулга, арга зүй, үнэлгээний чиглэлээр энэ хуулийн 27.1.2-т
          заасан{" "}
        </span>
        <span id="t1i_23" class="t sb_23">
          мэргэшүүлэх сургалтад хамрагдаж, төгссөн байх;{" "}
        </span>
        <span id="t1j_23" class="t sc_23">
          6{" "}
        </span>
        <span id="t1k_23" class="t sc_23">
          CGD, Improving School Management in Low- and Middle-Income Countries:
          A Systematic Review, 2023{" "}
        </span>
        <span id="t1l_23" class="t sd_23">
          7{" "}
        </span>
        <span id="t1m_23" class="t sd_23">
          Бусад чиг үүргийг БЕХ-ийн 33, СӨЕБтХ-ийн 17 дугаар зүйлээс дэлгэрүүлэн
          харна уу.{" "}
        </span>
      </div>
    </div>
  );
}
