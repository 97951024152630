import React from "react";
export default function FourtyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_41{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_41{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_41{left:89px;bottom:945px;letter-spacing:-0.15px;word-spacing:0.02px;}

.s0_41{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_41{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_41{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_41{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts41" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg41" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="41/41.svg"
          type="image/svg+xml"
          id="pdf41"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_41" class="t s1_41">
          41{" "}
        </span>
        <span id="t3_41" class="t s2_41">
          Зураг 1.13. Сургуулийн менежментийн зураглалын жишээ (202...-202...
          оны хичээлийн жил){" "}
        </span>
      </div>
    </div>
  );
}
