import React from "react";
export default function FourtyFour() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_44{left:87px;bottom:58px;letter-spacing:-0.12px;}
#t2_44{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_44{left:87px;bottom:936px;letter-spacing:0.19px;word-spacing:0.06px;}
#t4_44{left:117px;bottom:903px;letter-spacing:0.1px;word-spacing:0.14px;}
#t5_44{left:87px;bottom:864px;letter-spacing:0.02px;word-spacing:-0.15px;}
#t6_44{left:87px;bottom:844px;letter-spacing:0.03px;word-spacing:6.09px;}
#t7_44{left:87px;bottom:824px;letter-spacing:0.02px;word-spacing:0.94px;}
#t8_44{left:87px;bottom:804px;letter-spacing:0.02px;word-spacing:8.38px;}
#t9_44{left:87px;bottom:784px;letter-spacing:-0.02px;word-spacing:2.92px;}
#ta_44{left:87px;bottom:763px;letter-spacing:0.02px;word-spacing:8.2px;}
#tb_44{left:87px;bottom:743px;letter-spacing:-0.01px;word-spacing:0.09px;}
#tc_44{left:87px;bottom:723px;letter-spacing:-0.16px;word-spacing:9.02px;}
#td_44{left:670px;bottom:732px;}
#te_44{left:87px;bottom:703px;letter-spacing:-0.33px;word-spacing:3.55px;}
#tf_44{left:178px;bottom:703px;letter-spacing:-0.19px;word-spacing:3.4px;}
#tg_44{left:87px;bottom:683px;letter-spacing:-0.18px;word-spacing:2.72px;}
#th_44{left:87px;bottom:663px;letter-spacing:-0.15px;word-spacing:2.11px;}
#ti_44{left:87px;bottom:642px;letter-spacing:-0.16px;word-spacing:2.48px;}
#tj_44{left:87px;bottom:622px;letter-spacing:-0.14px;word-spacing:-0.12px;}
#tk_44{left:87px;bottom:602px;letter-spacing:-0.14px;word-spacing:0.46px;}
#tl_44{left:87px;bottom:582px;letter-spacing:-0.15px;word-spacing:-1.89px;}
#tm_44{left:87px;bottom:562px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tn_44{left:87px;bottom:521px;letter-spacing:-0.16px;word-spacing:2.9px;}
#to_44{left:87px;bottom:501px;letter-spacing:-0.14px;word-spacing:3.03px;}
#tp_44{left:87px;bottom:481px;letter-spacing:-0.13px;word-spacing:-1.31px;}
#tq_44{left:87px;bottom:461px;letter-spacing:-0.14px;word-spacing:2.69px;}
#tr_44{left:87px;bottom:441px;letter-spacing:-0.14px;word-spacing:-0.66px;}
#ts_44{left:87px;bottom:421px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tt_44{left:172px;bottom:383px;letter-spacing:0.1px;word-spacing:0.04px;}
#tu_44{left:162px;bottom:347px;letter-spacing:0.1px;word-spacing:0.05px;}
#tv_44{left:438px;bottom:347px;letter-spacing:0.1px;word-spacing:0.04px;}
#tw_44{left:95px;bottom:317px;}
#tx_44{left:113px;bottom:317px;letter-spacing:0.1px;word-spacing:0.04px;}
#ty_44{left:113px;bottom:299px;letter-spacing:0.13px;word-spacing:0.01px;}
#tz_44{left:112px;bottom:281px;letter-spacing:0.11px;word-spacing:0.02px;}
#t10_44{left:95px;bottom:262px;}
#t11_44{left:113px;bottom:262px;letter-spacing:0.05px;word-spacing:0.08px;}
#t12_44{left:113px;bottom:244px;letter-spacing:0.09px;word-spacing:0.05px;}
#t13_44{left:95px;bottom:226px;}
#t14_44{left:113px;bottom:226px;letter-spacing:0.08px;word-spacing:0.06px;}
#t15_44{left:95px;bottom:207px;}
#t16_44{left:113px;bottom:207px;letter-spacing:0.13px;word-spacing:0.02px;}
#t17_44{left:113px;bottom:189px;letter-spacing:0.11px;word-spacing:0.03px;}
#t18_44{left:436px;bottom:317px;}
#t19_44{left:453px;bottom:317px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1a_44{left:453px;bottom:299px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1b_44{left:436px;bottom:281px;}
#t1c_44{left:453px;bottom:281px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1d_44{left:453px;bottom:262px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1e_44{left:436px;bottom:244px;}
#t1f_44{left:453px;bottom:244px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1g_44{left:453px;bottom:226px;letter-spacing:0.09px;word-spacing:0.04px;}
#t1h_44{left:436px;bottom:207px;}
#t1i_44{left:453px;bottom:207px;letter-spacing:0.11px;word-spacing:0.04px;}
#t1j_44{left:453px;bottom:189px;letter-spacing:0.08px;}
#t1k_44{left:436px;bottom:171px;}
#t1l_44{left:453px;bottom:171px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1m_44{left:87px;bottom:115px;}
#t1n_44{left:108px;bottom:114px;letter-spacing:-0.15px;word-spacing:0.02px;}

.s0_44{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_44{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_44{font-size:21px;font-family:MogulArial-Bold_179;color:#6D3A96;}
.s3_44{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_44{font-size:17px;font-family:MogulArial-Bold_179;color:#963E97;}
.s5_44{font-size:10px;font-family:MogulArial-Bold_179;color:#963E97;}
.s6_44{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s7_44{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s8_44{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s9_44{font-size:12px;font-family:MogulArial_16s;color:#000;}
.sa_44{font-size:14px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts44" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg44" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="44/44.svg"
          type="image/svg+xml"
          id="pdf44"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_44" class="t s1_44">
          44{" "}
        </span>
        <span id="t3_44" class="t s2_44">
          2.1 МЕНЕЖМЕНТИЙГ САЙЖРУУЛАХ АЖЛЫГ ХААНААС{" "}
        </span>
        <span id="t4_44" class="t s2_44">
          ЭХЛЭХ НЬ ОНОВЧТОЙ ВЭ?{" "}
        </span>
        <span id="t5_44" class="t s3_44">
          Монгол Улсын хэмжээнд 2021-2022 онд ерөнхий боловсролын сургуулийн{" "}
        </span>
        <span id="t6_44" class="t s3_44">
          үйл ажиллагааны гүйцэтгэлийн чанар, үр дүнгийн үнэлгээг анх удаа{" "}
        </span>
        <span id="t7_44" class="t s3_44">
          хөндлөнгөөс зохион байгуулсан. Сургуулийн үйл ажиллагааны гүйцэтгэл,{" "}
        </span>
        <span id="t8_44" class="t s3_44">
          үр дүнг хөндлөнгөөс үнэлснээр сургуулийн менежментийг оновчтой{" "}
        </span>
        <span id="t9_44" class="t s3_44">
          төлөвлөх, хэрэгжүүлэхэд хэрэгцээтэй мэдээллийг бий болгодог. Иймээс{" "}
        </span>
        <span id="ta_44" class="t s3_44">
          сургуулийн тасралтгүй хөгжлийг хангах менежментэд өөрийн болон{" "}
        </span>
        <span id="tb_44" class="t s3_44">
          хөндлөнгийн үнэлгээ чухал байр суурийг эзэлдэг.{" "}
        </span>
        <span id="tc_44" class="t s4_44">
          Менежментийн ухаанд хэмжиж чадахгүй бол удирдаж чадахгүй{" "}
        </span>
        <span id="td_44" class="t s5_44">
          8{" "}
        </span>
        <span id="te_44" class="t s4_44">
          гэж үздэг.{" "}
        </span>
        <span id="tf_44" class="t s3_44">
          Өөрөөр хэлбэл, бид аливаа сургалт, үйл ажиллагааныхаа үр{" "}
        </span>
        <span id="tg_44" class="t s3_44">
          дүнг тодорхой үзүүлэлтээр хэмжиж, хянаж байж удирдаж чадна гэсэн үг.{" "}
        </span>
        <span id="th_44" class="t s3_44">
          Сургуулийн удирдлагын багийн зүгээс сургуулийнхаа алсын хараа, эрхэм{" "}
        </span>
        <span id="ti_44" class="t s3_44">
          зорилго, зорилтыг хэрэгжүүлж, сурагчдынхаа сурлагын амжилт, хөгжлийн{" "}
        </span>
        <span id="tj_44" class="t s3_44">
          төлөө зохион байгуулж буй бүхий л үйл ажиллагааг зохих цаг хугацаанд
          нь,{" "}
        </span>
        <span id="tk_44" class="t s3_44">
          тодорхой шалгуур үзүүлэлтээр хэмжиж, үнэлснээр үйл ажиллагааныхаа үр{" "}
        </span>
        <span id="tl_44" class="t s3_44">
          дүн, алдаа оноог олж харах, цаашид юуг, хэрхэн өөрчилж, засаж
          залруулахаа{" "}
        </span>
        <span id="tm_44" class="t s3_44">
          оношлох боломжтой болно. Энэ талаар 1.2-т дэлгэрэнгүй танилцуулсан.{" "}
        </span>
        <span id="tn_44" class="t s3_44">
          Аливаа үйл ажиллагааг өөрөө болон хөндлөнгөөс үнэлдэг. Боловсролын{" "}
        </span>
        <span id="to_44" class="t s3_44">
          салбарт “хөндлөнгийн үнэлгээ” гэж эрх бүхий мэргэжлийн байгууллагаас{" "}
        </span>
        <span id="tp_44" class="t s3_44">
          үнэлэх үнэлгээг ойлгоно. БҮТ-өөс 2021-2022 оны хичээлийн жилээс
          багшийн{" "}
        </span>
        <span id="tq_44" class="t s3_44">
          болон сургалтын байгууллагын гүйцэтгэлийн үнэлгээг хийж ирсэн. Харин{" "}
        </span>
        <span id="tr_44" class="t s3_44">
          энд та бүхэнд сургуулийн өөрийн үнэлгээ хийхэд ашиглаж болох зарим
          арга{" "}
        </span>
        <span id="ts_44" class="t s3_44">
          хэрэгсэл болон мэдээллийг танилцуулж байна.{" "}
        </span>
        <span id="tt_44" class="t s6_44">
          Хүснэгт 2.1. Сургуулийн түвшинд хийгддэг үнэлгээний нийтлэг төрлүүд{" "}
        </span>
        <span id="tu_44" class="t s6_44">
          Өөрийн үнэлгээний чиглэл{" "}
        </span>
        <span id="tv_44" class="t s6_44">
          Хөндлөнгийн үнэлгээний чиглэл{" "}
        </span>
        <span id="tw_44" class="t s7_44">
          •{" "}
        </span>
        <span id="tx_44" class="t s8_44">
          Үйл ажиллагааны /гүйцэтгэлийн/{" "}
        </span>
        <span id="ty_44" class="t s8_44">
          төлөвлөгөөний хэрэгжилтийн явц{" "}
        </span>
        <span id="tz_44" class="t s8_44">
          /ахиц/-ын үнэлгээ{" "}
        </span>
        <span id="t10_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t11_44" class="t s8_44">
          Суралцагч, эцэг, эх, асран хамгаалагчийн{" "}
        </span>
        <span id="t12_44" class="t s8_44">
          сэтгэл ханамжийн үнэлгээ{" "}
        </span>
        <span id="t13_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t14_44" class="t s8_44">
          Сургуулийн дотоод уур амьсгалын үнэлгээ{" "}
        </span>
        <span id="t15_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t16_44" class="t s8_44">
          Сургалтын хөтөлбөрийн хэрэгжилтийн{" "}
        </span>
        <span id="t17_44" class="t s8_44">
          явцын үнэлгээ{" "}
        </span>
        <span id="t18_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t19_44" class="t s8_44">
          Сурагчийн гүйцэтгэл, үр{" "}
        </span>
        <span id="t1a_44" class="t s8_44">
          дүнгийн үнэлгээ{" "}
        </span>
        <span id="t1b_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t1c_44" class="t s8_44">
          Багшийн гүйцэтгэл, үр{" "}
        </span>
        <span id="t1d_44" class="t s8_44">
          дүнгийн үнэлгээ{" "}
        </span>
        <span id="t1e_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t1f_44" class="t s8_44">
          Сургалтын байгууллагын{" "}
        </span>
        <span id="t1g_44" class="t s8_44">
          гүйцэтгэл, үр дүнгийн үнэлгээ{" "}
        </span>
        <span id="t1h_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t1i_44" class="t s8_44">
          Боловсролын чанарын{" "}
        </span>
        <span id="t1j_44" class="t s8_44">
          үнэлгээ{" "}
        </span>
        <span id="t1k_44" class="t s7_44">
          •{" "}
        </span>
        <span id="t1l_44" class="t s8_44">
          Санхүүгийн үнэлгээ{" "}
        </span>
        <span id="t1m_44" class="t s9_44">
          8{" "}
        </span>
        <span id="t1n_44" class="t sa_44">
          Питер Друкер{" "}
        </span>
      </div>
    </div>
  );
}
