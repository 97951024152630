import React from "react";
export default function Nine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_9{left:676px;bottom:58px;}
#t2_9{left:679px;bottom:59px;}
#t3_9{left:87px;bottom:936px;letter-spacing:0.1px;word-spacing:0.16px;}
#t4_9{left:87px;bottom:899px;letter-spacing:-0.13px;word-spacing:0.82px;}
#t5_9{left:270px;bottom:899px;letter-spacing:-0.11px;word-spacing:0.81px;}
#t6_9{left:87px;bottom:879px;letter-spacing:-0.14px;word-spacing:-1.28px;}
#t7_9{left:87px;bottom:859px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t8_9{left:87px;bottom:826px;letter-spacing:-0.17px;word-spacing:2.88px;}
#t9_9{left:505px;bottom:826px;letter-spacing:-0.14px;word-spacing:2.87px;}
#ta_9{left:87px;bottom:805px;letter-spacing:-0.14px;word-spacing:-1.97px;}
#tb_9{left:87px;bottom:785px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tc_9{left:87px;bottom:752px;letter-spacing:-0.13px;word-spacing:1.84px;}
#td_9{left:317px;bottom:752px;letter-spacing:-0.12px;word-spacing:1.85px;}
#te_9{left:87px;bottom:732px;letter-spacing:-0.14px;word-spacing:7.03px;}
#tf_9{left:87px;bottom:712px;letter-spacing:-0.12px;word-spacing:-1.29px;}
#tg_9{left:87px;bottom:692px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_9{left:87px;bottom:658px;letter-spacing:-0.14px;word-spacing:0.63px;}
#ti_9{left:277px;bottom:658px;letter-spacing:-0.16px;word-spacing:0.67px;}
#tj_9{left:87px;bottom:638px;letter-spacing:-0.17px;}
#tk_9{left:87px;bottom:605px;letter-spacing:-0.21px;word-spacing:-0.75px;}
#tl_9{left:174px;bottom:605px;letter-spacing:-0.13px;word-spacing:-0.82px;}
#tm_9{left:87px;bottom:585px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tn_9{left:87px;bottom:552px;letter-spacing:-0.16px;}
#to_9{left:196px;bottom:552px;letter-spacing:-0.13px;}
#tp_9{left:304px;bottom:552px;letter-spacing:-0.14px;}
#tq_9{left:418px;bottom:552px;letter-spacing:-0.17px;}
#tr_9{left:467px;bottom:552px;}
#ts_9{left:494px;bottom:552px;letter-spacing:-0.16px;}
#tt_9{left:595px;bottom:552px;letter-spacing:-0.12px;}
#tu_9{left:87px;bottom:532px;letter-spacing:-0.17px;word-spacing:11.86px;}
#tv_9{left:87px;bottom:511px;letter-spacing:-0.19px;word-spacing:4.27px;}
#tw_9{left:87px;bottom:491px;letter-spacing:-0.12px;}
#tx_9{left:196px;bottom:491px;letter-spacing:-0.09px;}
#ty_9{left:259px;bottom:491px;letter-spacing:-0.12px;}
#tz_9{left:366px;bottom:491px;letter-spacing:-0.09px;}
#t10_9{left:440px;bottom:491px;letter-spacing:-0.17px;}
#t11_9{left:528px;bottom:491px;letter-spacing:-0.2px;}
#t12_9{left:619px;bottom:491px;letter-spacing:-0.39px;}
#t13_9{left:87px;bottom:471px;letter-spacing:-0.15px;word-spacing:-0.58px;}
#t14_9{left:87px;bottom:451px;letter-spacing:-0.24px;word-spacing:0.15px;}
#t15_9{left:87px;bottom:418px;letter-spacing:-0.12px;word-spacing:5.32px;}
#t16_9{left:87px;bottom:398px;letter-spacing:-0.15px;word-spacing:2.14px;}
#t17_9{left:87px;bottom:377px;letter-spacing:-0.16px;word-spacing:3.75px;}
#t18_9{left:87px;bottom:357px;letter-spacing:-0.16px;word-spacing:1.89px;}
#t19_9{left:87px;bottom:337px;letter-spacing:-0.14px;word-spacing:5.2px;}
#t1a_9{left:87px;bottom:317px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t1b_9{left:87px;bottom:284px;letter-spacing:-0.11px;word-spacing:1.11px;}
#t1c_9{left:584px;bottom:284px;letter-spacing:-0.15px;word-spacing:1.17px;}
#t1d_9{left:87px;bottom:264px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1e_9{left:87px;bottom:231px;letter-spacing:-0.13px;word-spacing:1.82px;}
#t1f_9{left:622px;bottom:231px;letter-spacing:-0.32px;}
#t1g_9{left:87px;bottom:210px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t1h_9{left:87px;bottom:177px;letter-spacing:-0.13px;word-spacing:9.24px;}
#t1i_9{left:87px;bottom:157px;letter-spacing:-0.15px;word-spacing:-0.59px;}

.s0_9{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_9{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_9{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_9{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s4_9{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_9{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts9" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg9" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="9/9.svg"
          type="image/svg+xml"
          id="pdf9"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_9" class="t s1_9">
          9{" "}
        </span>
        <span id="t3_9" class="t s2_9">
          НЭР ТОМЬЁОНЫ ТАЙЛБАР{" "}
        </span>
        <span id="t4_9" class="t s3_9">
          Бие бялдрын хөгжил{" "}
        </span>
        <span id="t5_9" class="t s4_9">
          – Хүний хөгжлийн суурь болсон биеийн өндөр жин,{" "}
        </span>
        <span id="t6_9" class="t s4_9">
          харьцаа, биеийн чанар (хурд, хүч, авхаалж самбаа, уян хатан чанар),
          дотоод{" "}
        </span>
        <span id="t7_9" class="t s4_9">
          эрхтэн тогтолцоо, физиологийн хөгжил;{" "}
        </span>
        <span id="t8_9" class="t s3_9">
          Ерөнхий боловсролын сургуулийн дотуур байр{" "}
        </span>
        <span id="t9_9" class="t s4_9">
          - Ерөнхий боловсрол{" "}
        </span>
        <span id="ta_9" class="t s4_9">
          эзэмшихийн тулд оршин суудаг газраасаа сургуульд тогтмол явах
          боломжгүй{" "}
        </span>
        <span id="tb_9" class="t s4_9">
          суралцагчдын амьдрах зориулалт бүхий сургуулийн бүрэлдэхүүн хэсэг;{" "}
        </span>
        <span id="tc_9" class="t s3_9">
          Дотуур байрын үйлчилгээ{" "}
        </span>
        <span id="td_9" class="t s4_9">
          - суралцагчийн эцэг эх, асран хамгаалагчтай{" "}
        </span>
        <span id="te_9" class="t s4_9">
          гэрээлсний үндсэн дээр суралцагчдыг байрлуулан тэдний амьдралын{" "}
        </span>
        <span id="tf_9" class="t s4_9">
          хэвийн, аюулгүй нөхцөлийг бүрдүүлэх, асран хамгаалах, эрүүл мэнд,
          хөгжил{" "}
        </span>
        <span id="tg_9" class="t s4_9">
          хамгаалал, хоол хүнсний үйлчилгээ үзүүлэх цогц арга хэмжээ;{" "}
        </span>
        <span id="th_9" class="t s3_9">
          Дотуур байрын орчин{" "}
        </span>
        <span id="ti_9" class="t s4_9">
          - дотуур байрын үйлчилгээг үзүүлэхэд шаардагдах{" "}
        </span>
        <span id="tj_9" class="t s4_9">
          орчин;{" "}
        </span>
        <span id="tk_9" class="t s3_9">
          СБААХ нь{" "}
        </span>
        <span id="tl_9" class="t s4_9">
          тогтвортой байдлыг сургуулийн амьдралын бүхий л хүрээнд, үйл{" "}
        </span>
        <span id="tm_9" class="t s4_9">
          ажиллагаа бүрд нэвтрүүлж, хэрэгжүүлэхийг илэрхийлсэн хандлага;{" "}
        </span>
        <span id="tn_9" class="t s3_9">
          Сургуульд{" "}
        </span>
        <span id="to_9" class="t s3_9">
          суурилсан{" "}
        </span>
        <span id="tp_9" class="t s3_9">
          менежмент{" "}
        </span>
        <span id="tq_9" class="t s3_9">
          (ССМ)
        </span>
        <span id="tr_9" class="t s4_9">
          -{" "}
        </span>
        <span id="ts_9" class="t s4_9">
          Сургуульд{" "}
        </span>
        <span id="tt_9" class="t s4_9">
          суурилсан{" "}
        </span>
        <span id="tu_9" class="t s4_9">
          менежментийг боловсролын төвлөрлийг сааруулах нэг хэлбэр гэж{" "}
        </span>
        <span id="tv_9" class="t s4_9">
          тодорхойлдог. Энэ нь шийдвэр гаргах эрх мэдлийг сургуулийн түвшинд{" "}
        </span>
        <span id="tw_9" class="t s4_9">
          шилжүүлэх,{" "}
        </span>
        <span id="tx_9" class="t s4_9">
          дахин{" "}
        </span>
        <span id="ty_9" class="t s4_9">
          хуваарилах{" "}
        </span>
        <span id="tz_9" class="t s4_9">
          замаар{" "}
        </span>
        <span id="t10_9" class="t s4_9">
          оролцогч{" "}
        </span>
        <span id="t11_9" class="t s4_9">
          талуудын{" "}
        </span>
        <span id="t12_9" class="t s4_9">
          хяналт,{" "}
        </span>
        <span id="t13_9" class="t s4_9">
          хариуцлагыг нэмэгдүүлж сургуулийн менежментийг сайжруулах, тогтвортой{" "}
        </span>
        <span id="t14_9" class="t s4_9">
          байлгах чухал хэрэгсэл болдог.{" "}
        </span>
        <span id="t15_9" class="t s3_9">
          Сургууль суралцахуйн зангилаа талбар (Schools as learning hubs){" "}
        </span>
        <span id="t16_9" class="t s4_9">
          – Сургуулиуд хэвээр байгаа ч олон талт байдал, лаборатори туршилт нь{" "}
        </span>
        <span id="t17_9" class="t s4_9">
          нийтлэг үзэгдэл болсон. “Сургуулийн хана”-г нээх буюу сургуулиуд илүү{" "}
        </span>
        <span id="t18_9" class="t s4_9">
          нээлттэй ажилласнаар нийгэмтэй холбогдож, байнга хувьсан өөрчлөгддөг{" "}
        </span>
        <span id="t19_9" class="t s4_9">
          сургалтын арга хэлбэр, иргэний оролцоо, нийгмийн инновацыг дэмжих{" "}
        </span>
        <span id="t1a_9" class="t s4_9">
          боломж бүрдэнэ.{" "}
        </span>
        <span id="t1b_9" class="t s3_9">
          Сургалтын хөтөлбөрийн интеграци (Curriculum integration)
        </span>
        <span id="t1c_9" class="t s4_9">
          - сургалтын{" "}
        </span>
        <span id="t1d_9" class="t s4_9">
          агуулгыг нэгтгэх/тодорхойлох үйл явц;{" "}
        </span>
        <span id="t1e_9" class="t s3_9">
          Судлагдахууны дотоод интеграци (Interdisciplinary integration)-
        </span>
        <span id="t1f_9" class="t s4_9">
          Тухайн{" "}
        </span>
        <span id="t1g_9" class="t s4_9">
          судлагдахуунд багтсан агуулга хоорондын интеграци;{" "}
        </span>
        <span id="t1h_9" class="t s3_9">
          Судлагдахуун хоорондын интеграци (Multidisciplinary integration)-{" "}
        </span>
        <span id="t1i_9" class="t s4_9">
          Судлагдахуунуудын агуулгыг нийтлэг сэдэв, үзэгдэлд зангидсан интеграци
          ;{" "}
        </span>
      </div>
    </div>
  );
}
