import React from "react";
export default function Fourteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_14{left:87px;bottom:58px;letter-spacing:-0.09px;}
#t2_14{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_14{left:87px;bottom:930px;letter-spacing:0.1px;word-spacing:0.14px;}
#t4_14{left:87px;bottom:886px;letter-spacing:-0.19px;word-spacing:8.55px;}
#t5_14{left:87px;bottom:866px;letter-spacing:-0.18px;word-spacing:8.11px;}
#t6_14{left:570px;bottom:866px;letter-spacing:-0.17px;}
#t7_14{left:87px;bottom:846px;letter-spacing:-0.14px;word-spacing:0.65px;}
#t8_14{left:87px;bottom:826px;letter-spacing:-0.16px;word-spacing:2.11px;}
#t9_14{left:87px;bottom:805px;letter-spacing:-0.16px;word-spacing:0.6px;}
#ta_14{left:87px;bottom:785px;letter-spacing:-0.13px;word-spacing:1.8px;}
#tb_14{left:87px;bottom:765px;letter-spacing:-0.14px;}
#tc_14{left:87px;bottom:745px;letter-spacing:-0.14px;word-spacing:0.04px;}
#td_14{left:87px;bottom:705px;letter-spacing:-0.14px;word-spacing:7.64px;}
#te_14{left:87px;bottom:684px;letter-spacing:-0.16px;word-spacing:1.97px;}
#tf_14{left:87px;bottom:664px;letter-spacing:-0.14px;word-spacing:2.72px;}
#tg_14{left:87px;bottom:644px;letter-spacing:-0.11px;word-spacing:0.11px;}
#th_14{left:87px;bottom:624px;letter-spacing:-0.11px;word-spacing:3.12px;}
#ti_14{left:87px;bottom:604px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_14{left:109px;bottom:405px;letter-spacing:-0.02px;}
#tk_14{left:150px;bottom:405px;}
#tl_14{left:154px;bottom:405px;letter-spacing:-0.02px;}
#tm_14{left:94px;bottom:392px;letter-spacing:-0.02px;}
#tn_14{left:85px;bottom:379px;letter-spacing:-0.02px;word-spacing:0.09px;}
#to_14{left:86px;bottom:366px;letter-spacing:-0.02px;word-spacing:0.05px;}
#tp_14{left:93px;bottom:353px;letter-spacing:-0.02px;word-spacing:0.2px;}
#tq_14{left:100px;bottom:340px;letter-spacing:-0.02px;word-spacing:0.07px;}
#tr_14{left:116px;bottom:327px;letter-spacing:-0.03px;}
#ts_14{left:103px;bottom:471px;letter-spacing:-0.05px;}
#tt_14{left:108px;bottom:457px;letter-spacing:-0.12px;word-spacing:0.1px;}
#tu_14{left:104px;bottom:444px;letter-spacing:-0.07px;}
#tv_14{left:102px;bottom:431px;letter-spacing:-0.06px;}
#tw_14{left:211px;bottom:403px;letter-spacing:-0.03px;word-spacing:0.06px;}
#tx_14{left:218px;bottom:390px;letter-spacing:-0.02px;}
#ty_14{left:217px;bottom:377px;letter-spacing:-0.02px;}
#tz_14{left:217px;bottom:364px;letter-spacing:-0.02px;word-spacing:0.09px;}
#t10_14{left:232px;bottom:351px;letter-spacing:-0.02px;}
#t11_14{left:224px;bottom:338px;letter-spacing:-0.02px;word-spacing:0.08px;}
#t12_14{left:232px;bottom:325px;letter-spacing:-0.02px;}
#t13_14{left:214px;bottom:311px;letter-spacing:-0.02px;word-spacing:-0.01px;}
#t14_14{left:229px;bottom:470px;letter-spacing:-0.03px;}
#t15_14{left:233px;bottom:457px;letter-spacing:-0.02px;word-spacing:-0.05px;}
#t16_14{left:224px;bottom:444px;letter-spacing:-0.03px;}
#t17_14{left:346px;bottom:401px;letter-spacing:-0.02px;}
#t18_14{left:338px;bottom:388px;letter-spacing:-0.02px;word-spacing:0.05px;}
#t19_14{left:359px;bottom:375px;letter-spacing:-0.02px;}
#t1a_14{left:349px;bottom:361px;letter-spacing:-0.02px;}
#t1b_14{left:344px;bottom:348px;letter-spacing:-0.02px;word-spacing:0.04px;}
#t1c_14{left:350px;bottom:335px;letter-spacing:-0.02px;word-spacing:0.04px;}
#t1d_14{left:360px;bottom:322px;letter-spacing:-0.02px;}
#t1e_14{left:329px;bottom:470px;letter-spacing:-0.03px;word-spacing:-0.04px;}
#t1f_14{left:335px;bottom:457px;letter-spacing:-0.03px;word-spacing:0.01px;}
#t1g_14{left:331px;bottom:444px;letter-spacing:-0.03px;}
#t1h_14{left:343px;bottom:431px;letter-spacing:-0.04px;}
#t1i_14{left:467px;bottom:400px;letter-spacing:-0.02px;}
#t1j_14{left:461px;bottom:386px;letter-spacing:-0.01px;word-spacing:-0.01px;}
#t1k_14{left:466px;bottom:373px;letter-spacing:-0.03px;word-spacing:0.01px;}
#t1l_14{left:466px;bottom:360px;letter-spacing:-0.03px;word-spacing:0.08px;}
#t1m_14{left:475px;bottom:347px;letter-spacing:-0.02px;word-spacing:-0.06px;}
#t1n_14{left:484px;bottom:334px;letter-spacing:-0.03px;}
#t1o_14{left:476px;bottom:472px;letter-spacing:-0.03px;}
#t1p_14{left:480px;bottom:459px;letter-spacing:-0.02px;word-spacing:-0.05px;}
#t1q_14{left:469px;bottom:446px;letter-spacing:-0.04px;}
#t1r_14{left:476px;bottom:433px;letter-spacing:-0.03px;word-spacing:0.01px;}
#t1s_14{left:488px;bottom:420px;letter-spacing:-0.02px;}
#t1t_14{left:599px;bottom:400px;letter-spacing:-0.03px;word-spacing:0.07px;}
#t1u_14{left:588px;bottom:386px;letter-spacing:-0.02px;word-spacing:0.14px;}
#t1v_14{left:600px;bottom:373px;letter-spacing:-0.03px;}
#t1w_14{left:612px;bottom:360px;letter-spacing:-0.02px;}
#t1x_14{left:595px;bottom:347px;letter-spacing:-0.03px;}
#t1y_14{left:606px;bottom:472px;letter-spacing:-0.03px;}
#t1z_14{left:594px;bottom:458px;letter-spacing:-0.02px;word-spacing:-0.03px;}
#t20_14{left:601px;bottom:445px;letter-spacing:-0.03px;}
#t21_14{left:624px;bottom:432px;letter-spacing:-0.01px;}
#t22_14{left:163px;bottom:266px;letter-spacing:-0.19px;word-spacing:0.07px;}
#t23_14{left:331px;bottom:249px;letter-spacing:-0.17px;word-spacing:0.02px;}
#t24_14{left:87px;bottom:208px;letter-spacing:-0.21px;word-spacing:-0.17px;}
#t25_14{left:471px;bottom:208px;letter-spacing:-0.14px;word-spacing:-0.22px;}
#t26_14{left:87px;bottom:187px;letter-spacing:-0.13px;word-spacing:1.74px;}
#t27_14{left:486px;bottom:187px;letter-spacing:-0.16px;word-spacing:1.77px;}
#t28_14{left:87px;bottom:167px;letter-spacing:-0.14px;word-spacing:10.07px;}
#t29_14{left:87px;bottom:147px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_14{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_14{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_14{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_14{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s4_14{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_14{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s6_14{font-size:11px;font-family:Arial_5s;color:#4E4E50;}
.s7_14{font-size:11px;font-family:ArialMT_18r;color:#4E4E50;}
.s8_14{font-size:11px;font-family:Arial-Bold_5z;color:#0070C0;}
.s9_14{font-size:11px;font-family:Arial-Bold_5z;color:#C55A11;}
.sa_14{font-size:11px;font-family:Arial-Bold_5z;color:#002060;}
.sb_14{font-size:11px;font-family:Arial-Bold_5z;color:#DE0B7A;}
.sc_14{font-size:11px;font-family:Arial-Bold_5z;color:#C00CC4;}
.sd_14{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts14" type="text/css">
        {`@font-face {
	font-family: Arial-Bold_5z;
	src: url("fonts/Arial-Bold_5z.woff") format("woff");
}

@font-face {
	font-family: ArialMT_18r;
	src: url("fonts/ArialMT_18r.woff") format("woff");
}

@font-face {
	font-family: Arial_5s;
	src: url("fonts/Arial_5s.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg14" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="14/14.svg"
          type="image/svg+xml"
          id="pdf14"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_14" class="t s1_14">
          14{" "}
        </span>
        <span id="t3_14" class="t s2_14">
          1.1 СУРАЛЦАХУЙД ГАРЧ БУЙ ӨӨРЧЛӨЛТ{" "}
        </span>
        <span id="t4_14" class="t s3_14">
          Дэлхий дахин Тогтвортой хөгжлийн боловсролын үзэл санааны{" "}
        </span>
        <span id="t5_14" class="t s3_14">
          хувьсаж буй боловсролын талаар хэлэлцэж байна.{" "}
        </span>
        <span id="t6_14" class="t s4_14">
          Боловсролын{" "}
        </span>
        <span id="t7_14" class="t s4_14">
          тогтолцоо улам бүр боловсронгуй болж хувь хүмүүс, улс орны эдийн засаг{" "}
        </span>
        <span id="t8_14" class="t s4_14">
          хөгжин цэцэглэх гол хөдөлгүүр нь болсныг бид мэднэ. Гэвч боловсролын{" "}
        </span>
        <span id="t9_14" class="t s4_14">
          чухал хүчин зүйлс болох мэдлэг, чадвар, хандлага, үнэ цэн ирээдүйд
          ямар{" "}
        </span>
        <span id="ta_14" class="t s4_14">
          байх, амьдралынхаа туршид суралцахад нь ямар орчныг бүрдүүлж, ямар{" "}
        </span>
        <span id="tb_14" class="t s4_14">
          боломжоор хангах талаар нухацтай бодох, энэ тухай хэлэлцүүлэг өрнүүлэх{" "}
        </span>
        <span id="tc_14" class="t s4_14">
          цаг үе ирээд байгааг дэлхий дахин хүлээн зөвшөөрч байна.{" "}
        </span>
        <span id="td_14" class="t s4_14">
          Сүүлийн жилүүдэд олон улс орон, үүний дотор НҮБ-ын гишүүн улс{" "}
        </span>
        <span id="te_14" class="t s4_14">
          орнууд болон ЮНЕСКО, ЭЗХАХБ, ДЭЗФ зэрэг олон улсын байгууллагаас{" "}
        </span>
        <span id="tf_14" class="t s4_14">
          “Боловсролын ирээдүйг бүтээх нь”, “Ирээдүйгээ хамтдаа дахин төсөөлөх{" "}
        </span>
        <span id="tg_14" class="t s5_14" data-mappings='[[63,"fi"]]'>
          нь: Боловсролын нийгмийн шинэ гэрээ”, “Schools of the Future Deﬁning
          New{" "}
        </span>
        <span id="th_14" class="t s4_14">
          Models of Education for the Fourth Industrial Revolution” зэрэг
          судалгааны{" "}
        </span>
        <span id="ti_14" class="t s4_14">
          болон бодлогын баримт бичгийг боловсруулан хэлэлцэж байна.{" "}
        </span>
        <span id="tj_14" class="t s6_14">
          ТХ, ТХБ
        </span>
        <span id="tk_14" class="t s7_14">
          -
        </span>
        <span id="tl_14" class="t s6_14">
          той{" "}
        </span>
        <span id="tm_14" class="t s6_14">
          холбоотой дэлхий{" "}
        </span>
        <span id="tn_14" class="t s6_14">
          нийтийн, бүс нутгийн,{" "}
        </span>
        <span id="to_14" class="t s6_14">
          үндэсний болон орон{" "}
        </span>
        <span id="tp_14" class="t s6_14">
          нутгийн бодлогууд{" "}
        </span>
        <span id="tq_14" class="t s6_14">
          нэгдмэл байхыг{" "}
        </span>
        <span id="tr_14" class="t s6_14">
          анхаарах{" "}
        </span>
        <span id="ts_14" class="t s8_14">
          Тэргүүлэх{" "}
        </span>
        <span id="tt_14" class="t s8_14">
          чиглэл 1{" "}
        </span>
        <span id="tu_14" class="t s8_14">
          Бодлогыг{" "}
        </span>
        <span id="tv_14" class="t s8_14">
          уялдуулах{" "}
        </span>
        <span id="tw_14" class="t s6_14">
          Бид амьдарч байж{" "}
        </span>
        <span id="tx_14" class="t s6_14">
          суралцдаг, сурч{" "}
        </span>
        <span id="ty_14" class="t s6_14">
          байж амьдардаг{" "}
        </span>
        <span id="tz_14" class="t s6_14">
          тул байгууллага{" "}
        </span>
        <span id="t10_14" class="t s6_14">
          бүхлээрээ{" "}
        </span>
        <span id="t11_14" class="t s6_14">
          ажиллах арга{" "}
        </span>
        <span id="t12_14" class="t s6_14">
          хандлагыг{" "}
        </span>
        <span id="t13_14" class="t s6_14">
          хөхиүлэн дэмжих{" "}
        </span>
        <span id="t14_14" class="t s9_14">
          Тэргүүлэх{" "}
        </span>
        <span id="t15_14" class="t s9_14">
          чиглэл 2{" "}
        </span>
        <span id="t16_14" class="t s9_14">
          Суралцахуй{" "}
        </span>
        <span id="t17_14" class="t s6_14">
          Тэдэнд мэдлэг,{" "}
        </span>
        <span id="t18_14" class="t s6_14">
          чадвар, үнэт зүйл,{" "}
        </span>
        <span id="t19_14" class="t s6_14">
          хандлагыг{" "}
        </span>
        <span id="t1a_14" class="t s6_14">
          эзэмшихэд нь{" "}
        </span>
        <span id="t1b_14" class="t s6_14">
          тэдэнд дэмжлэг{" "}
        </span>
        <span id="t1c_14" class="t s6_14">
          үзүүлж, итгэл{" "}
        </span>
        <span id="t1d_14" class="t s6_14">
          хүлээлгэх{" "}
        </span>
        <span id="t1e_14" class="t sa_14">
          Тэргүүлэх чиглэл 3{" "}
        </span>
        <span id="t1f_14" class="t sa_14">
          Багш нар, сурган{" "}
        </span>
        <span id="t1g_14" class="t sa_14">
          хүмүүжүүлэгчдийг{" "}
        </span>
        <span id="t1h_14" class="t sa_14">
          чадавхжуулах{" "}
        </span>
        <span id="t1i_14" class="t s6_14">
          Харилцан уялдаа{" "}
        </span>
        <span id="t1j_14" class="t s6_14">
          бүхий үйл явцуудад{" "}
        </span>
        <span id="t1k_14" class="t s6_14">
          оролцож шийдвэр{" "}
        </span>
        <span id="t1l_14" class="t s6_14">
          гаргах гол тоглогч{" "}
        </span>
        <span id="t1m_14" class="t s6_14">
          хэмээн хүлээн{" "}
        </span>
        <span id="t1n_14" class="t s6_14">
          зөвшөөрөх{" "}
        </span>
        <span id="t1o_14" class="t sb_14">
          Тэргүүлэх{" "}
        </span>
        <span id="t1p_14" class="t sb_14">
          чиглэл 4{" "}
        </span>
        <span id="t1q_14" class="t sb_14">
          Залуучуудад{" "}
        </span>
        <span id="t1r_14" class="t sb_14">
          эрх мэдэл{" "}
        </span>
        <span id="t1s_14" class="t sb_14">
          олгох{" "}
        </span>
        <span id="t1t_14" class="t s6_14">
          Орон нутгийн{" "}
        </span>
        <span id="t1u_14" class="t s6_14">
          олон нийтийн үйл{" "}
        </span>
        <span id="t1v_14" class="t s6_14">
          ажиллагаанд{" "}
        </span>
        <span id="t1w_14" class="t s6_14">
          чухал ач{" "}
        </span>
        <span id="t1x_14" class="t s6_14">
          холбогдол өгөх{" "}
        </span>
        <span id="t1y_14" class="t sc_14">
          Тэргүүлэх{" "}
        </span>
        <span id="t1z_14" class="t sc_14">
          чиглэл 5 Орон{" "}
        </span>
        <span id="t20_14" class="t sc_14">
          нутгийн үйл{" "}
        </span>
        <span id="t21_14" class="t sc_14">
          явц{" "}
        </span>
        <span id="t22_14" class="t sd_14">
          Зураг. 1.1 ТХБ-ын Замын зураглалд тодорхойлсон тэргүүлэх чиглэл,{" "}
        </span>
        <span id="t23_14" class="t sd_14">
          ЮНЕСКО, 2020{" "}
        </span>
        <span id="t24_14" class="t s3_14">
          Монгол Улс ч энэ хэлэлцүүлэгт нэгдэж байна.{" "}
        </span>
        <span id="t25_14" class="t s4_14">
          Манай улс урт болон дунд{" "}
        </span>
        <span id="t26_14" class="t s4_14">
          хугацааны хөгжлийн бодлого Алсын хараа-2050,{" "}
        </span>
        <span id="t27_14" class="t s4_14">
          Боловсролын салбарын{" "}
        </span>
        <span id="t28_14" class="t s4_14">
          дунд хугацааны төлөвлөгөө 2021-2030 зэргийг батлан, 4-5 жилийн{" "}
        </span>
        <span id="t29_14" class="t s4_14">
          төлөвлөгөөтэйгээр хэрэгжүүлж байна. Тухайлбал,{" "}
        </span>
      </div>
    </div>
  );
}
