import React from "react";
export default function SeventySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_77{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_77{left:114px;bottom:941px;letter-spacing:-0.14px;word-spacing:6.23px;}
#t3_77{left:114px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t4_77{left:87px;bottom:896px;}
#t5_77{left:114px;bottom:896px;letter-spacing:-0.16px;word-spacing:-1.85px;}
#t6_77{left:114px;bottom:876px;letter-spacing:-0.14px;word-spacing:4.93px;}
#t7_77{left:114px;bottom:856px;letter-spacing:-0.13px;word-spacing:-1.89px;}
#t8_77{left:513px;bottom:856px;letter-spacing:-0.14px;word-spacing:-1.88px;}
#t9_77{left:114px;bottom:836px;letter-spacing:-0.14px;word-spacing:0.31px;}
#ta_77{left:114px;bottom:816px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tb_77{left:87px;bottom:791px;}
#tc_77{left:114px;bottom:791px;letter-spacing:-0.13px;word-spacing:-0.1px;}
#td_77{left:114px;bottom:771px;letter-spacing:-0.16px;word-spacing:0.06px;}
#te_77{left:87px;bottom:746px;}
#tf_77{left:114px;bottom:746px;letter-spacing:-0.64px;word-spacing:0.59px;}
#tg_77{left:114px;bottom:726px;letter-spacing:-0.62px;word-spacing:5.28px;}
#th_77{left:114px;bottom:706px;letter-spacing:-0.6px;}
#ti_77{left:92px;bottom:667px;letter-spacing:0.14px;word-spacing:0.06px;}
#tj_77{left:87px;bottom:629px;letter-spacing:-0.14px;word-spacing:0.22px;}
#tk_77{left:87px;bottom:609px;letter-spacing:-0.14px;word-spacing:1.9px;}
#tl_77{left:87px;bottom:589px;letter-spacing:-0.14px;word-spacing:2.66px;}
#tm_77{left:87px;bottom:569px;letter-spacing:-0.09px;}
#tn_77{left:87px;bottom:535px;letter-spacing:-0.18px;word-spacing:4.15px;}
#to_77{left:87px;bottom:515px;letter-spacing:-0.16px;word-spacing:1.21px;}
#tp_77{left:87px;bottom:495px;letter-spacing:-0.14px;word-spacing:1.69px;}
#tq_77{left:87px;bottom:475px;letter-spacing:-0.1px;}
#tr_77{left:87px;bottom:442px;letter-spacing:-0.16px;word-spacing:3.03px;}
#ts_77{left:87px;bottom:422px;letter-spacing:-0.13px;word-spacing:0.81px;}
#tt_77{left:87px;bottom:401px;letter-spacing:-0.12px;word-spacing:0.75px;}
#tu_77{left:87px;bottom:381px;letter-spacing:-0.17px;word-spacing:-0.78px;}
#tv_77{left:87px;bottom:361px;letter-spacing:-0.16px;word-spacing:-0.44px;}
#tw_77{left:95px;bottom:238px;letter-spacing:0.06px;word-spacing:2.19px;}
#tx_77{left:95px;bottom:220px;letter-spacing:0.12px;word-spacing:8.39px;}
#ty_77{left:95px;bottom:201px;letter-spacing:0.14px;}
#tz_77{left:174px;bottom:201px;letter-spacing:0.08px;}
#t10_77{left:288px;bottom:201px;letter-spacing:0.06px;}
#t11_77{left:95px;bottom:183px;letter-spacing:0.06px;}
#t12_77{left:163px;bottom:183px;letter-spacing:0.07px;}
#t13_77{left:236px;bottom:183px;letter-spacing:0.06px;}
#t14_77{left:306px;bottom:183px;letter-spacing:0.06px;}
#t15_77{left:95px;bottom:165px;letter-spacing:0.08px;}
#t16_77{left:205px;bottom:165px;letter-spacing:0.09px;}
#t17_77{left:299px;bottom:165px;letter-spacing:0.1px;}
#t18_77{left:95px;bottom:146px;letter-spacing:0.16px;}
#t19_77{left:370px;bottom:240px;letter-spacing:0.04px;}
#t1a_77{left:441px;bottom:240px;letter-spacing:-0.12px;}
#t1b_77{left:505px;bottom:240px;letter-spacing:0.06px;}
#t1c_77{left:602px;bottom:240px;letter-spacing:0.12px;}
#t1d_77{left:370px;bottom:222px;letter-spacing:0.12px;word-spacing:1.59px;}
#t1e_77{left:370px;bottom:203px;letter-spacing:0.09px;word-spacing:7.68px;}
#t1f_77{left:370px;bottom:185px;letter-spacing:0.06px;}
#t1g_77{left:445px;bottom:185px;letter-spacing:0.14px;word-spacing:7.9px;}
#t1h_77{left:370px;bottom:167px;letter-spacing:-0.16px;}
#t1i_77{left:457px;bottom:167px;letter-spacing:0.02px;}
#t1j_77{left:572px;bottom:167px;letter-spacing:0.04px;}
#t1k_77{left:370px;bottom:148px;letter-spacing:0.11px;word-spacing:-1.35px;}
#t1l_77{left:370px;bottom:130px;letter-spacing:0.09px;word-spacing:0.05px;}

.s0_77{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_77{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_77{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_77{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_77{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s5_77{font-size:15px;font-family:ArialMT_1lm;color:#000;}`}
      </style>

      <style id="fonts77" type="text/css">
        {`@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg77" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="77/77.svg"
          type="image/svg+xml"
          id="pdf77"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_77" class="t s0_77">
          77{" "}
        </span>
        <span id="t2_77" class="t s1_77">
          талд нь тулгуурлах, харилцан хүндэтгэлтэй хандах, эцэг эх асран{" "}
        </span>
        <span id="t3_77" class="t s1_77">
          хамгаалагчдын санаа бодлыг хүлээн зөвшөөрөх соёлыг төлөвшүүлэх{" "}
        </span>
        <span id="t4_77" class="t s2_77">
          •{" "}
        </span>
        <span id="t5_77" class="t s1_77">
          Нэгдүгээр ангид элсэн суралцаж байгаа болон багаас суурь боловсролын{" "}
        </span>
        <span id="t6_77" class="t s1_77">
          түвшинд шилжин суралцаж буй сурагчдын дасан зохицох чадварыг{" "}
        </span>
        <span id="t7_77" class="t s1_77">
          дэмжих олон талт үйл ажиллагааг зохион байгуулах{" "}
        </span>
        <span id="t8_77" class="t s3_77">
          (сурагчийн сурлагын{" "}
        </span>
        <span id="t9_77" class="t s3_77">
          амжилт, ахиц өөрчлөлт, хичээлийн оролцоо идэвх, сэтгэл ханамжийн{" "}
        </span>
        <span id="ta_77" class="t s3_77">
          эерэг түвшинд чухлаар нөлөөлнө){" "}
        </span>
        <span id="tb_77" class="t s2_77">
          •{" "}
        </span>
        <span id="tc_77" class="t s1_77">
          Бусдыг хүндэтгэж харилцах соёлыг төлөвшүүлэхийн тулд дотоод дүрэм{" "}
        </span>
        <span id="td_77" class="t s1_77">
          журам боловсруулан мөрдөх{" "}
        </span>
        <span id="te_77" class="t s2_77">
          •{" "}
        </span>
        <span id="tf_77" class="t s1_77">
          Багш бүр сурагчидтай харилцахдаа элэгсэг нээлттэй, тэвчээртэй,
          сэтгэлийн{" "}
        </span>
        <span id="tg_77" class="t s1_77">
          хөдөлгөөний сөрөг илрэлээс зайлсхийх ёс зүйн дүрэм журмыг мөрдөж{" "}
        </span>
        <span id="th_77" class="t s1_77">
          ажиллах{" "}
        </span>
        <span id="ti_77" class="t s4_77">
          3.1.1 ХҮҮХЭД ХАМГААЛАЛ, ХҮҮХДИЙН ЭРХ ЯАГААД ЧУХАЛ ВЭ?{" "}
        </span>
        <span id="tj_77" class="t s1_77">
          Хүүхэд бүр алив хүчирхийллээс хамгаалагдах эрхтэй гэдгийг дэлхийн олон{" "}
        </span>
        <span id="tk_77" class="t s1_77">
          улс орон хүлээн зөвшөөрсөнчлөн Монгол Улс 1990 онд НҮБ-ын хүүхдийн{" "}
        </span>
        <span id="tl_77" class="t s1_77">
          эрхийн тухай конвенцод нэгдэн орсноор хүүхдийн эрх хамгааллыг албан{" "}
        </span>
        <span id="tm_77" class="t s1_77">
          ёсоор хүлээн зөвшөөрч үүрэг хүлээсэн.{" "}
        </span>
        <span id="tn_77" class="t s1_77">
          Хүүхэд гэмт хэрэг, зөрчил, хүчирхийлэл, бие махбодын шийтгэл, сэтгэл{" "}
        </span>
        <span id="to_77" class="t s1_77">
          санааны дарамт, үл хайхрах байдал болон мөлжлөгийн аливаа хэлбэрээс{" "}
        </span>
        <span id="tp_77" class="t s1_77">
          нийгмийн бүх орчинд хамгаалагдах эрхтэй (Хүүхдийн эрхийн тухай хууль,{" "}
        </span>
        <span id="tq_77" class="t s1_77">
          2016){" "}
        </span>
        <span id="tr_77" class="t s1_77">
          Монгол Улс нь Боловсролын тухай хууль, Хүүхдийн эрхийн тухай хууль,{" "}
        </span>
        <span id="ts_77" class="t s1_77">
          Хүүхэд хамгааллын тухай хууль, Гэр бүлийн хүчирхийлэлтэй тэмцэх тухай{" "}
        </span>
        <span id="tt_77" class="t s1_77">
          хууль, Хүүхдийн төлөө үндэсний зөвлөлийн 2018 оны “Хүүхэд хамгааллын{" "}
        </span>
        <span id="tu_77" class="t s1_77">
          чиглэлээр баримтлах бодлого, чиглэл батлах тухай” тогтоол зэрэгтээ
          хүүхэд{" "}
        </span>
        <span id="tv_77" class="t s1_77">
          бүр аюулгүй, хүчирхийллээс ангид орчинд сурч хөгжих тухай заасан
          байдаг.{" "}
        </span>
        <span id="tw_77" class="t s5_77">
          Монгол Улсын Боловсрол Шинжлэх{" "}
        </span>
        <span id="tx_77" class="t s5_77">
          Ухааны сайдын 2022 оны А/453{" "}
        </span>
        <span id="ty_77" class="t s5_77">
          Ерөнхий{" "}
        </span>
        <span id="tz_77" class="t s5_77">
          боловсролын{" "}
        </span>
        <span id="t10_77" class="t s5_77">
          сургууль,{" "}
        </span>
        <span id="t11_77" class="t s5_77">
          дотуур{" "}
        </span>
        <span id="t12_77" class="t s5_77">
          байрын{" "}
        </span>
        <span id="t13_77" class="t s5_77">
          орчинд{" "}
        </span>
        <span id="t14_77" class="t s5_77">
          хүүхэд{" "}
        </span>
        <span id="t15_77" class="t s5_77">
          хамгааллын{" "}
        </span>
        <span id="t16_77" class="t s5_77">
          үйлчилгээ{" "}
        </span>
        <span id="t17_77" class="t s5_77">
          үзүүлэх{" "}
        </span>
        <span id="t18_77" class="t s5_77">
          журам{" "}
        </span>
        <span id="t19_77" class="t s5_77">
          Монгол{" "}
        </span>
        <span id="t1a_77" class="t s5_77">
          Улсын{" "}
        </span>
        <span id="t1b_77" class="t s5_77">
          Боловсрол{" "}
        </span>
        <span id="t1c_77" class="t s5_77">
          Шинжлэх{" "}
        </span>
        <span id="t1d_77" class="t s5_77">
          Ухааны сайдын 2022 оны А/214 Ерөнхий{" "}
        </span>
        <span id="t1e_77" class="t s5_77">
          боловсролын сургууль, дотуур байрын{" "}
        </span>
        <span id="t1f_77" class="t s5_77">
          орчинд{" "}
        </span>
        <span id="t1g_77" class="t s5_77">
          суралцагчийг үе тэнгийнхний{" "}
        </span>
        <span id="t1h_77" class="t s5_77">
          дарамт,{" "}
        </span>
        <span id="t1i_77" class="t s5_77">
          ялгаварлан{" "}
        </span>
        <span id="t1j_77" class="t s5_77">
          гадуурхалтад{" "}
        </span>
        <span id="t1k_77" class="t s5_77">
          өртөхөөс урьдчилан сэргийлэх, хамгаалах{" "}
        </span>
        <span id="t1l_77" class="t s5_77">
          үйл ажиллагааны чиглэл{" "}
        </span>
      </div>
    </div>
  );
}
