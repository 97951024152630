import React from "react";
export default function Seven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_7{left:676px;bottom:58px;}
#t2_7{left:679px;bottom:59px;}
#t3_7{left:87px;bottom:941px;letter-spacing:-0.12px;word-spacing:0.01px;}
#t4_7{left:299px;bottom:873px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t5_7{left:299px;bottom:853px;letter-spacing:-0.15px;word-spacing:0.06px;}
#t6_7{left:299px;bottom:833px;letter-spacing:-0.11px;word-spacing:0.01px;}
#t7_7{left:299px;bottom:813px;letter-spacing:-0.12px;word-spacing:0.03px;}
#t8_7{left:299px;bottom:793px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_7{left:299px;bottom:722px;letter-spacing:-0.18px;word-spacing:0.09px;}
#ta_7{left:299px;bottom:703px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tb_7{left:299px;bottom:685px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tc_7{left:299px;bottom:666px;letter-spacing:-0.16px;word-spacing:0.06px;}
#td_7{left:299px;bottom:648px;letter-spacing:-0.13px;word-spacing:0.04px;}
#te_7{left:299px;bottom:544px;letter-spacing:-0.21px;}
#tf_7{left:412px;bottom:544px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tg_7{left:299px;bottom:524px;letter-spacing:-0.14px;word-spacing:0.04px;}
#th_7{left:299px;bottom:504px;letter-spacing:-0.12px;word-spacing:0.02px;}
#ti_7{left:299px;bottom:484px;letter-spacing:-0.16px;word-spacing:0.07px;}
#tj_7{left:563px;bottom:484px;letter-spacing:-0.14px;}
#tk_7{left:87px;bottom:873px;letter-spacing:-0.1px;}
#tl_7{left:87px;bottom:853px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tm_7{left:87px;bottom:833px;letter-spacing:-0.15px;}
#tn_7{left:87px;bottom:813px;letter-spacing:-0.12px;word-spacing:0.02px;}
#to_7{left:87px;bottom:793px;letter-spacing:-0.11px;word-spacing:0.02px;}
#tp_7{left:87px;bottom:544px;letter-spacing:-0.1px;}
#tq_7{left:87px;bottom:524px;letter-spacing:-0.16px;}
#tr_7{left:87px;bottom:504px;letter-spacing:-0.12px;}
#ts_7{left:87px;bottom:484px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tt_7{left:87px;bottom:463px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tu_7{left:89px;bottom:722px;letter-spacing:-0.1px;}
#tv_7{left:89px;bottom:701px;letter-spacing:-0.16px;}
#tw_7{left:89px;bottom:681px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tx_7{left:89px;bottom:661px;letter-spacing:-0.1px;}
#ty_7{left:89px;bottom:641px;letter-spacing:-0.09px;}
#tz_7{left:89px;bottom:621px;letter-spacing:-0.08px;word-spacing:-0.01px;}
#t10_7{left:89px;bottom:601px;letter-spacing:-0.2px;word-spacing:0.11px;}
#t11_7{left:87px;bottom:377px;letter-spacing:-0.16px;word-spacing:-1.78px;}
#t12_7{left:87px;bottom:357px;letter-spacing:-0.15px;word-spacing:2.2px;}
#t13_7{left:87px;bottom:337px;letter-spacing:-0.15px;word-spacing:1.49px;}
#t14_7{left:87px;bottom:317px;letter-spacing:-0.16px;word-spacing:1.67px;}
#t15_7{left:87px;bottom:297px;letter-spacing:-0.12px;word-spacing:-0.94px;}
#t16_7{left:87px;bottom:276px;letter-spacing:-0.13px;word-spacing:2.92px;}
#t17_7{left:87px;bottom:256px;letter-spacing:-0.15px;word-spacing:0.05px;}

.s0_7{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_7{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_7{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#1D9FA6;}
.s3_7{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_7{font-size:17px;font-family:MogulArial-Bold_179;color:#20BEC6;}
.s5_7{font-size:17px;font-family:MogulArial_16s;color:#434343;}
.t.m0_7{transform:matrix(-1,0,0,1,0,0);}`}
      </style>

      <style id="fonts7" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg7" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="7/7.svg"
          type="image/svg+xml"
          id="pdf7"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_7" class="t s1_7">
          7{" "}
        </span>
        <span id="t3_7" class="t s2_7">
          Зөвлөмжийн агуулгын ерөнхий хүрээ{" "}
        </span>
        <span id="t4_7" class="t s3_7">
          Сургууль, багш, сурагч хувьсан өөрчлөгдөхийн{" "}
        </span>
        <span id="t5_7" class="t s3_7">
          гол суурь нь юу болох, тэдгээрийг олон улс,{" "}
        </span>
        <span id="t6_7" class="t s3_7">
          үндэсний хэмжээнд ямар эрх зүйн баримт{" "}
        </span>
        <span id="t7_7" class="t s3_7">
          бичгийн хүрээнд шийдвэрлэх, ямар загвар{" "}
        </span>
        <span id="t8_7" class="t s3_7">
          аргачлалаар хэрэгжүүлэх талаар тусгасан.{" "}
        </span>
        <span id="t9_7" class="t s3_7">
          Үнэлгээний ач холбогдол, сургуулийн болон{" "}
        </span>
        <span id="ta_7" class="t s3_7">
          багшийн гүйцэтгэлийн үнэлгээний үр дүнг{" "}
        </span>
        <span id="tb_7" class="t s3_7">
          менежментээ сайжруулахад хэрхэн ашиглах,{" "}
        </span>
        <span id="tc_7" class="t s3_7">
          асуудлаа хэрхэн тодорхойлж, шийдвэр гаргах{" "}
        </span>
        <span id="td_7" class="t s3_7">
          талаар тусгасан.{" "}
        </span>
        <span id="te_7" class="t s3_7">
          Сургуулиудад{" "}
        </span>
        <span id="tf_7" class="t s3_7">
          тулгамдаж буй асуудал,{" "}
        </span>
        <span id="tg_7" class="t s3_7">
          тэдгээрийг шийдвэрлэж байгаа жишээ, кейс{" "}
        </span>
        <span id="th_7" class="t s3_7">
          болон ижил төстэй асуудлыг хэрхэн шийдэж{" "}
        </span>
        <span id="ti_7" class="t s3_7">
          болох арга зам, арга аргачлалыг{" "}
        </span>
        <span id="tj_7" class="t s3_7">
          тусгасан.{" "}
        </span>
        <span id="tk_7" class="t s4_7">
          1-р бүлэг{" "}
        </span>
        <span id="tl_7" class="t s5_7">
          Сурагч төвтэй{" "}
        </span>
        <span id="tm_7" class="t s5_7">
          сургуулийн{" "}
        </span>
        <span id="tn_7" class="t s5_7">
          менежментийг хэрхэн{" "}
        </span>
        <span id="to_7" class="t s5_7">
          хөгжүүлэх вэ?{" "}
        </span>
        <span id="tp_7" class="t s4_7">
          3-р бүлэг{" "}
        </span>
        <span id="tq_7" class="t s5_7">
          Сургуулийн{" "}
        </span>
        <span id="tr_7" class="t s5_7">
          менежментийг{" "}
        </span>
        <span id="ts_7" class="t s5_7">
          сайжруулах арга зам,{" "}
        </span>
        <span id="tt_7" class="t s5_7">
          боломж юу байна вэ?{" "}
        </span>
        <span id="tu_7" class="t s4_7">
          2-р бүлэг{" "}
        </span>
        <span id="tv_7" class="t s5_7">
          Гүйцэтгэлийн{" "}
        </span>
        <span id="tw_7" class="t s5_7">
          үнэлгээний үр{" "}
        </span>
        <span id="tx_7" class="t s5_7">
          дүнд шинжилгээ{" "}
        </span>
        <span id="ty_7" class="t s5_7">
          хийх, түүнд{" "}
        </span>
        <span id="tz_7" class="t s5_7">
          үндэслэн шийдвэр{" "}
        </span>
        <span id="t10_7" class="t s5_7">
          гаргах нь{" "}
        </span>
        <span id="t11_7" class="t s3_7">
          Сургуулийн удирдлагууд агуулгыг ашиглахдаа өөрт тулгамдсан асуудал
          дээр{" "}
        </span>
        <span id="t12_7" class="t s3_7">
          тулгуурлан түүнийг шийдвэрлэхэд дэмжлэг болох агуулгыг сонгон судалж{" "}
        </span>
        <span id="t13_7" class="t s3_7">
          хэрэгжүүлж болно. Жишээлбэл, хэрэв алсын хараа тодорхойлохыг судлах{" "}
        </span>
        <span id="t14_7" class="t s3_7">
          сонирхол байгаа бол эсвэл сургуулийн өөрийн үнэлгээ хийх, гүйцэтгэлийн{" "}
        </span>
        <span id="t15_7" class="t s3_7">
          үнэлгээний үр дүнд шинжилгээ хийх чадварт суралцахыг хүсэж болно.
          Бүлэг{" "}
        </span>
        <span id="t16_7" class="t s3_7">
          бүрд тухайн агуулгын хүрээнд хэрэгжүүлэх үйл ажиллагааны аргачлалыг{" "}
        </span>
        <span id="t17_7" class="t s3_7">
          зөвлөмжилж өгсөн.{" "}
        </span>
      </div>
    </div>
  );
}
