import React from "react";
export default function Seventy() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_70{left:87px;bottom:58px;letter-spacing:-0.1px;}
#t2_70{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_70{left:87px;bottom:941px;letter-spacing:-0.15px;word-spacing:3.8px;}
#t4_70{left:87px;bottom:921px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t5_70{left:87px;bottom:888px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t6_70{left:87px;bottom:863px;letter-spacing:-0.37px;word-spacing:8.71px;}
#t7_70{left:87px;bottom:843px;letter-spacing:-0.36px;word-spacing:-2.08px;}
#t8_70{left:87px;bottom:823px;letter-spacing:-0.31px;word-spacing:-0.83px;}
#t9_70{left:87px;bottom:803px;letter-spacing:-0.31px;word-spacing:0.05px;}
#ta_70{left:93px;bottom:769px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tb_70{left:87px;bottom:487px;letter-spacing:-0.15px;word-spacing:2.64px;}
#tc_70{left:87px;bottom:467px;letter-spacing:-0.16px;word-spacing:1.83px;}
#td_70{left:87px;bottom:447px;letter-spacing:-0.18px;word-spacing:0.08px;}
#te_70{left:87px;bottom:414px;letter-spacing:-0.16px;word-spacing:5.11px;}
#tf_70{left:87px;bottom:394px;letter-spacing:-0.11px;word-spacing:0.19px;}
#tg_70{left:87px;bottom:373px;letter-spacing:-0.16px;word-spacing:11.37px;}
#th_70{left:87px;bottom:353px;letter-spacing:-0.18px;word-spacing:0.08px;}
#ti_70{left:360px;bottom:320px;letter-spacing:-0.16px;}
#tj_70{left:468px;bottom:320px;letter-spacing:-0.42px;}
#tk_70{left:547px;bottom:320px;letter-spacing:-0.08px;}
#tl_70{left:592px;bottom:320px;letter-spacing:-0.14px;}
#tm_70{left:360px;bottom:300px;letter-spacing:-0.07px;}
#tn_70{left:406px;bottom:300px;letter-spacing:-0.12px;}
#to_70{left:506px;bottom:300px;letter-spacing:-0.09px;}
#tp_70{left:606px;bottom:300px;letter-spacing:-0.09px;}
#tq_70{left:360px;bottom:280px;letter-spacing:-0.17px;word-spacing:5.5px;}
#tr_70{left:360px;bottom:260px;letter-spacing:-0.14px;word-spacing:-2.44px;}
#ts_70{left:87px;bottom:239px;letter-spacing:-0.13px;word-spacing:4.59px;}
#tt_70{left:87px;bottom:219px;letter-spacing:-0.14px;word-spacing:0.98px;}
#tu_70{left:87px;bottom:199px;letter-spacing:-0.16px;word-spacing:4.97px;}
#tv_70{left:87px;bottom:179px;letter-spacing:-0.14px;word-spacing:7.12px;}
#tw_70{left:87px;bottom:159px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tx_70{left:102px;bottom:312px;letter-spacing:-0.18px;word-spacing:0.09px;}
#ty_70{left:102px;bottom:291px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tz_70{left:102px;bottom:271px;letter-spacing:-0.16px;word-spacing:0.06px;}

.s0_70{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_70{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_70{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_70{font-size:17px;font-family:MogulArial-Bold_179;color:#8E2577;}
.s4_70{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts70" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg70" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="70/70.svg"
          type="image/svg+xml"
          id="pdf70"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_70" class="t s1_70">
          70{" "}
        </span>
        <span id="t3_70" class="t s2_70">
          системээс авсан мэдээлэлд шинжилгээ хийж, асуудлаа тодорхойлохдоо{" "}
        </span>
        <span id="t4_70" class="t s2_70">
          бидний санал болгож буй Загасан нурууны аргачлалыг ашиглаарай.{" "}
        </span>
        <span id="t5_70" class="t s3_70">
          Загасан нурууг хэрхэн бүтээх вэ?{" "}
        </span>
        <span id="t6_70" class="t s2_70">
          Тодорхой үйл явдлын шалтгаануудыг “загасны яс” хэлбэртэй зургаар{" "}
        </span>
        <span id="t7_70" class="t s2_70">
          харуулдаг арга ба тулгамдсан асуудлуудыг загасны “толгой” хэсэгт,
          тулгамдсан{" "}
        </span>
        <span id="t8_70" class="t s2_70">
          асуудлыг үндсэн шалтгааныг загасны “их биеийн ясны үзүүр” хэсэгт,
          хоёрдогч{" "}
        </span>
        <span id="t9_70" class="t s2_70">
          буюу бусад шалтгааныг “их биеийн ясны гол” хэсэгт харуулна.{" "}
        </span>
        <span id="ta_70" class="t s4_70">
          Зураг 2.20. Асуудлын шалтгааныг тодорхойлох Загасан нуруу аргачлалын
          ерөнхий загвар{" "}
        </span>
        <span id="tb_70" class="t s2_70">
          Сургуулийн зорилго, зорилтоо тодорхойлж, үйл ажиллагааны төлөвлөгөө{" "}
        </span>
        <span id="tc_70" class="t s2_70">
          боловсруулах ажлын эхлэл асуудлаа тодорхойлохоос эхлэх тул бид дээр{" "}
        </span>
        <span id="td_70" class="t s2_70">
          дурдсан аргачлалыг зөвлөж байгаа юм.{" "}
        </span>
        <span id="te_70" class="t s2_70">
          Сургуулийн хамт олныг бүхэлд нь хамруулан Загасан нурууны зургийг{" "}
        </span>
        <span id="tf_70" class="t s2_70">
          бүтээгээрэй. Бүтээсэн зураглалдаа үндэслэн асуудлын үндсэн шалтгааныг{" "}
        </span>
        <span id="tg_70" class="t s2_70">
          арилгах сургуулийн сургалт үйл ажиллагааны нэгдсэн төлөвлөгөөг{" "}
        </span>
        <span id="th_70" class="t s2_70">
          боловсруулж болно.{" "}
        </span>
        <span id="ti_70" class="t s2_70">
          Сургуулийн{" "}
        </span>
        <span id="tj_70" class="t s2_70">
          сургалт,{" "}
        </span>
        <span id="tk_70" class="t s2_70">
          үйл{" "}
        </span>
        <span id="tl_70" class="t s2_70">
          ажиллагаа{" "}
        </span>
        <span id="tm_70" class="t s2_70">
          нь{" "}
        </span>
        <span id="tn_70" class="t s2_70">
          бүхэлдээ{" "}
        </span>
        <span id="to_70" class="t s2_70">
          хүүхдийн{" "}
        </span>
        <span id="tp_70" class="t s2_70">
          хөгжлийг{" "}
        </span>
        <span id="tq_70" class="t s2_70">
          дэмжихэд зориулагдах тул сургуулийн{" "}
        </span>
        <span id="tr_70" class="t s2_70">
          менежментийн төвд хүүхэд байх учиртай.{" "}
        </span>
        <span id="ts_70" class="t s2_70">
          Тэгэхээр сурагч, эцэг эх, асран хамгаалагч, багш сурган хүмүүжүүлэгч,{" "}
        </span>
        <span id="tt_70" class="t s2_70">
          сургуулийн удирдлага, ажилтнууд, орон нутгийн засаг захиргааны нэгжийн{" "}
        </span>
        <span id="tu_70" class="t s2_70">
          холбогдох албан хаагчид, хамтрагч байгууллагуудын мэргэжилтнүүдийн{" "}
        </span>
        <span id="tv_70" class="t s2_70">
          нээлттэй идэвхтэй оролцоог хангаж, сургуулийн түвшин дэх бүхий л{" "}
        </span>
        <span id="tw_70" class="t s2_70">
          шийдвэрийг гаргах нь хүүхдийн хөгжлийн тулгуур болно.{" "}
        </span>
        <span id="tx_70" class="t s2_70">
          Танай сургууль оролцоонд{" "}
        </span>
        <span id="ty_70" class="t s2_70">
          тулгуурласан шийдэл гаргаж{" "}
        </span>
        <span id="tz_70" class="t s2_70">
          чадаж байгаа юу?{" "}
        </span>
      </div>
    </div>
  );
}
