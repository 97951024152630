import React from "react";
export default function HundredFourteen() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_114{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_114{left:142px;bottom:932px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_114{left:103px;bottom:893px;letter-spacing:-0.02px;}
#t4_114{left:148px;bottom:893px;letter-spacing:-0.04px;}
#t5_114{left:182px;bottom:893px;letter-spacing:-0.05px;word-spacing:0.49px;}
#t6_114{left:336px;bottom:893px;letter-spacing:-0.04px;}
#t7_114{left:434px;bottom:893px;letter-spacing:-0.13px;}
#t8_114{left:516px;bottom:893px;letter-spacing:-0.16px;}
#t9_114{left:556px;bottom:893px;letter-spacing:-0.04px;}
#ta_114{left:596px;bottom:893px;letter-spacing:-0.07px;}
#tb_114{left:103px;bottom:874px;letter-spacing:-0.06px;}
#tc_114{left:188px;bottom:874px;letter-spacing:-0.05px;}
#td_114{left:253px;bottom:874px;letter-spacing:-0.05px;word-spacing:-0.43px;}
#te_114{left:375px;bottom:874px;letter-spacing:-0.13px;}
#tf_114{left:431px;bottom:874px;letter-spacing:-0.08px;word-spacing:-0.41px;}
#tg_114{left:503px;bottom:874px;letter-spacing:-0.06px;}
#th_114{left:537px;bottom:874px;letter-spacing:-0.06px;}
#ti_114{left:616px;bottom:874px;letter-spacing:-0.02px;}
#tj_114{left:103px;bottom:856px;letter-spacing:-0.08px;word-spacing:0.05px;}
#tk_114{left:229px;bottom:856px;letter-spacing:-0.04px;word-spacing:0.03px;}
#tl_114{left:473px;bottom:856px;letter-spacing:-0.05px;word-spacing:0.04px;}
#tm_114{left:191px;bottom:831px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tn_114{left:434px;bottom:815px;letter-spacing:-0.15px;word-spacing:0.03px;}
#to_114{left:103px;bottom:778px;letter-spacing:0.09px;word-spacing:7.36px;}
#tp_114{left:103px;bottom:759px;letter-spacing:0.07px;word-spacing:0.07px;}
#tq_114{left:191px;bottom:739px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tr_114{left:434px;bottom:723px;letter-spacing:-0.16px;word-spacing:0.03px;}
#ts_114{left:103px;bottom:685px;letter-spacing:0.08px;word-spacing:3.07px;}
#tt_114{left:103px;bottom:667px;letter-spacing:0.11px;word-spacing:0.03px;}
#tu_114{left:191px;bottom:647px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tv_114{left:434px;bottom:630px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tw_114{left:103px;bottom:595px;letter-spacing:-0.38px;word-spacing:6.18px;}
#tx_114{left:103px;bottom:576px;letter-spacing:-0.37px;word-spacing:-0.37px;}
#ty_114{left:191px;bottom:552px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tz_114{left:434px;bottom:535px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t10_114{left:103px;bottom:498px;letter-spacing:0.1px;word-spacing:3.79px;}
#t11_114{left:103px;bottom:480px;letter-spacing:0.08px;word-spacing:0.99px;}
#t12_114{left:103px;bottom:461px;letter-spacing:0.11px;word-spacing:2.71px;}
#t13_114{left:103px;bottom:443px;letter-spacing:0.12px;word-spacing:0.03px;}
#t14_114{left:153px;bottom:419px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t15_114{left:434px;bottom:402px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t16_114{left:142px;bottom:346px;letter-spacing:0.15px;}
#t17_114{left:87px;bottom:318px;letter-spacing:-0.16px;word-spacing:5.09px;}
#t18_114{left:87px;bottom:298px;letter-spacing:-0.09px;}
#t19_114{left:87px;bottom:278px;letter-spacing:-0.15px;word-spacing:2.45px;}
#t1a_114{left:87px;bottom:258px;letter-spacing:-0.15px;word-spacing:3.95px;}
#t1b_114{left:87px;bottom:238px;letter-spacing:-0.16px;word-spacing:2.73px;}
#t1c_114{left:87px;bottom:217px;letter-spacing:-0.16px;word-spacing:5.69px;}
#t1d_114{left:87px;bottom:197px;letter-spacing:-0.17px;word-spacing:5.65px;}
#t1e_114{left:87px;bottom:177px;letter-spacing:-0.15px;word-spacing:1.9px;}
#t1f_114{left:87px;bottom:157px;letter-spacing:-0.16px;word-spacing:4.98px;}
#t1g_114{left:87px;bottom:137px;letter-spacing:-0.15px;word-spacing:4.69px;}
#t1h_114{left:87px;bottom:117px;letter-spacing:-0.14px;word-spacing:2.48px;}

.s0_114{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_114{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_114{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_114{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_114{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts114" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg114" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="114/114.svg"
          type="image/svg+xml"
          id="pdf114"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_114" class="t s0_114">
          114{" "}
        </span>
        <span id="t2_114" class="t s1_114">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_114" class="t s2_114">
          Багш{" "}
        </span>
        <span id="t4_114" class="t s2_114">
          бүр{" "}
        </span>
        <span id="t5_114" class="t s2_114">
          сургалтын хөтөлбөр,{" "}
        </span>
        <span id="t6_114" class="t s2_114">
          суралцахуйн{" "}
        </span>
        <span id="t7_114" class="t s2_114">
          удирдамж,{" "}
        </span>
        <span id="t8_114" class="t s2_114">
          арга{" "}
        </span>
        <span id="t9_114" class="t s2_114">
          зүйн{" "}
        </span>
        <span id="ta_114" class="t s2_114">
          зөвлөмж,{" "}
        </span>
        <span id="tb_114" class="t s2_114">
          үнэлгээний{" "}
        </span>
        <span id="tc_114" class="t s2_114">
          шалгуур{" "}
        </span>
        <span id="td_114" class="t s2_114">
          зөвлөмж зэргийг{" "}
        </span>
        <span id="te_114" class="t s2_114">
          судлан{" "}
        </span>
        <span id="tf_114" class="t s2_114">
          заах арга{" "}
        </span>
        <span id="tg_114" class="t s2_114">
          зүй,{" "}
        </span>
        <span id="th_114" class="t s2_114">
          хичээлийн{" "}
        </span>
        <span id="ti_114" class="t s2_114">
          явцын{" "}
        </span>
        <span id="tj_114" class="t s2_114">
          үнэлгээнд тусган{" "}
        </span>
        <span id="tk_114" class="t s2_114">
          ажиллаж, нэгж, ээлжит хичээлийн{" "}
        </span>
        <span id="tl_114" class="t s2_114">
          төлөвлөлтөө сайжруулсан.{" "}
        </span>
        <span id="tm_114" class="t s3_114">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tn_114" class="t s3_114">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="to_114" class="t s2_114">
          “Зорилготой 40 минут”, “Оновчтой хэрэглэгдэхүүн” ээлжит хичээлийг үе{" "}
        </span>
        <span id="tp_114" class="t s2_114">
          шаттайгаар зохион байгууллаа.{" "}
        </span>
        <span id="tq_114" class="t s3_114">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tr_114" class="t s3_114">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ts_114" class="t s2_114">
          8 платформыг идэвхтэй ашиглаж, хичээл, сургалт, хөтөлбөр, төлөвлөлтдөө{" "}
        </span>
        <span id="tt_114" class="t s2_114">
          тусган ажиллав.{" "}
        </span>
        <span id="tu_114" class="t s3_114">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tv_114" class="t s3_114">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tw_114" class="t s2_114">
          Судалгаат хичээл зохион байгуулснаар багш нар хичээлээ хамтран
          бэлтгэх,{" "}
        </span>
        <span id="tx_114" class="t s2_114">
          төлөвлөх, хэлэлцэх, үнэлэх, дүгнэх, шинжилгээ хийх чадварт ахиц
          өөрчлөлт гарсан.{" "}
        </span>
        <span id="ty_114" class="t s3_114">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tz_114" class="t s3_114">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t10_114" class="t s2_114">
          Зарим дутагдалтай байгаа мэргэжлийн багшийн хичээлийг 7 хоног бүрийн{" "}
        </span>
        <span id="t11_114" class="t s2_114">
          Бямба гаригт нэмэлт хичээлээр зохион байгуулсан. 8 сургууль
          сургуулийнхаа{" "}
        </span>
        <span id="t12_114" class="t s2_114">
          стратеги төлөвлөгөөнд хөгжлийн бэрхшээлтэй хүүхдийг тэгш хамран сургах{" "}
        </span>
        <span id="t13_114" class="t s2_114">
          талаар заалтыг нэмж оруулсан.{" "}
        </span>
        <span id="t14_114" class="t s3_114">
          ... нийслэлийн ... дүүргийн ерөнхий боловсролын ... сургуулийн
          менежментийг{" "}
        </span>
        <span id="t15_114" class="t s3_114">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t16_114" class="t s1_114">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t17_114" class="t s4_114">
          Сургуулийн удирдах ажилтан нь багш нарт дараах зөвлөмжийг хүргэж{" "}
        </span>
        <span id="t18_114" class="t s4_114">
          ажиллах хэрэгтэй.{" "}
        </span>
        <span id="t19_114" class="t s4_114">
          Анги танхим дахь хүүхдийн тоо их байх нь асуудалд олон ургалч үзлээр{" "}
        </span>
        <span id="t1a_114" class="t s4_114">
          хандах, асуудлын шалтгааныг олон талаас нь олж харах сурагч төвтэй{" "}
        </span>
        <span id="t1b_114" class="t s4_114">
          сургалтыг зохион байгуулах нэг гол хөшүүрэг болдог тул олон хүүхэдтэй{" "}
        </span>
        <span id="t1c_114" class="t s4_114">
          ангид ээлжит хичээлийг удирдан зохион байгуулах аргад суралцах нь{" "}
        </span>
        <span id="t1d_114" class="t s4_114">
          багшийн өмнө тулгамдаад байгаа бас нэг асуудал юм. Сурагч төвтэй{" "}
        </span>
        <span id="t1e_114" class="t s4_114">
          суралцахуйг хэрэгжүүлэхийн тулд багш туршигдаж нотлогдсон, сургалтын{" "}
        </span>
        <span id="t1f_114" class="t s4_114">
          онолд суурилсан арга зүйгээр ээлжит хичээлийг удирдан чиглүүлэх нь{" "}
        </span>
        <span id="t1g_114" class="t s4_114">
          хичээлийн явц дахь багшийн ачааллыг багасгаж, сурагчийн сурах арга{" "}
        </span>
        <span id="t1h_114" class="t s4_114">
          барилд ахиц гаргах нэг түлхүүр болно. Багш ээлжит хичээлийн явц дахь{" "}
        </span>
      </div>
    </div>
  );
}
