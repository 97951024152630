import React from "react";
export default function OneHundredEightyEigth() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_188{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_188{left:142px;bottom:941px;letter-spacing:-0.22px;word-spacing:0.58px;}
#t3_188{left:142px;bottom:921px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t4_188{left:114px;bottom:896px;}
#t5_188{left:142px;bottom:896px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t6_188{left:114px;bottom:872px;}
#t7_188{left:142px;bottom:872px;letter-spacing:-0.15px;word-spacing:0.97px;}
#t8_188{left:142px;bottom:852px;letter-spacing:-0.16px;word-spacing:7.14px;}
#t9_188{left:142px;bottom:831px;letter-spacing:-0.15px;}
#ta_188{left:114px;bottom:807px;}
#tb_188{left:142px;bottom:807px;letter-spacing:-0.17px;word-spacing:2.42px;}
#tc_188{left:142px;bottom:787px;letter-spacing:-0.15px;word-spacing:0.05px;}
#td_188{left:114px;bottom:762px;}
#te_188{left:142px;bottom:762px;letter-spacing:-0.17px;word-spacing:0.46px;}
#tf_188{left:142px;bottom:742px;letter-spacing:-0.15px;word-spacing:6.17px;}
#tg_188{left:142px;bottom:722px;letter-spacing:-0.1px;}
#th_188{left:114px;bottom:697px;}
#ti_188{left:142px;bottom:697px;letter-spacing:-0.13px;word-spacing:2.83px;}
#tj_188{left:142px;bottom:677px;letter-spacing:-0.16px;word-spacing:2.81px;}
#tk_188{left:142px;bottom:657px;letter-spacing:-0.18px;word-spacing:0.08px;}
#tl_188{left:87px;bottom:628px;letter-spacing:-0.16px;word-spacing:0.77px;}
#tm_188{left:249px;bottom:628px;letter-spacing:-0.17px;word-spacing:0.77px;}
#tn_188{left:87px;bottom:608px;letter-spacing:-0.12px;word-spacing:1.32px;}
#to_188{left:87px;bottom:588px;letter-spacing:-0.13px;word-spacing:-0.4px;}
#tp_188{left:87px;bottom:568px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tq_188{left:103px;bottom:506px;letter-spacing:0.07px;word-spacing:0.08px;}
#tr_188{left:103px;bottom:469px;letter-spacing:0.09px;}
#ts_188{left:122px;bottom:469px;letter-spacing:0.11px;word-spacing:0.03px;}
#tt_188{left:103px;bottom:451px;letter-spacing:0.11px;word-spacing:-0.16px;}
#tu_188{left:103px;bottom:433px;letter-spacing:0.12px;word-spacing:8.48px;}
#tv_188{left:103px;bottom:414px;letter-spacing:0.12px;word-spacing:3.56px;}
#tw_188{left:103px;bottom:396px;letter-spacing:0.11px;word-spacing:2.93px;}
#tx_188{left:103px;bottom:378px;letter-spacing:0.08px;word-spacing:-0.11px;}
#ty_188{left:103px;bottom:359px;letter-spacing:0.09px;word-spacing:2.15px;}
#tz_188{left:103px;bottom:341px;letter-spacing:0.08px;word-spacing:0.06px;}
#t10_188{left:103px;bottom:304px;letter-spacing:0.09px;}
#t11_188{left:123px;bottom:304px;letter-spacing:0.12px;word-spacing:0.68px;}
#t12_188{left:103px;bottom:286px;letter-spacing:0.14px;}
#t13_188{left:156px;bottom:286px;}
#t14_188{left:103px;bottom:268px;letter-spacing:0.16px;word-spacing:6.91px;}
#t15_188{left:139px;bottom:268px;letter-spacing:0.1px;word-spacing:6.96px;}
#t16_188{left:103px;bottom:249px;letter-spacing:0.09px;word-spacing:0.05px;}
#t17_188{left:103px;bottom:213px;letter-spacing:0.09px;}
#t18_188{left:122px;bottom:213px;letter-spacing:0.12px;word-spacing:0.03px;}
#t19_188{left:103px;bottom:194px;letter-spacing:0.09px;word-spacing:0.05px;}
#t1a_188{left:103px;bottom:176px;letter-spacing:0.12px;word-spacing:0.01px;}

.s0_188{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_188{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_188{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s3_188{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s4_188{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_188{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_188{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s7_188{font-size:15px;font-family:MogulArial-BoldItalic_17m;color:#000;}`}
      </style>

      <style id="fonts188" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg188" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="188/188.svg"
          type="image/svg+xml"
          id="pdf188"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_188" class="t s0_188">
          188{" "}
        </span>
        <span id="t2_188" class="t s1_188">
          уур амьсгалыг бий болгохын тулд ажилтнуудын ололт, амжилт, хувь{" "}
        </span>
        <span id="t3_188" class="t s1_188">
          нэмрийг үнэлэх{" "}
        </span>
        <span id="t4_188" class="t s2_188">
          •{" "}
        </span>
        <span id="t5_188" class="t s1_188">
          Гүйцэтгэлийн үнэлгээний шударга, үр дүнтэй тогтолцоог нэвтрүүлэх{" "}
        </span>
        <span id="t6_188" class="t s2_188">
          •{" "}
        </span>
        <span id="t7_188" class="t s1_188">
          Шаардлагатай үед манлайллын үүрэг гүйцэтгэхэд бэлэн мэргэшсэн{" "}
        </span>
        <span id="t8_188" class="t s1_188">
          хүмүүс байгаа эсэхийг баталгаажуулахын тулд залгамж халааг{" "}
        </span>
        <span id="t9_188" class="t s1_188">
          төлөвлөх{" "}
        </span>
        <span id="ta_188" class="t s2_188">
          •{" "}
        </span>
        <span id="tb_188" class="t s1_188">
          Ялгаварлан гадуурхах аливаа тохиолдлыг нэн даруй шийдвэрлэж,{" "}
        </span>
        <span id="tc_188" class="t s1_188">
          бүх ажилтанд тэгш боломж олгох.{" "}
        </span>
        <span id="td_188" class="t s2_188">
          •{" "}
        </span>
        <span id="te_188" class="t s1_188">
          Сургуулийн хамт олны доторх зөрчилдөөнийг шийдвэрлэх тодорхой{" "}
        </span>
        <span id="tf_188" class="t s1_188">
          бөгөөд шударга үйл явцыг бий болгох. Асуудлыг цаг алдалгүй{" "}
        </span>
        <span id="tg_188" class="t s1_188">
          шийдэж, ажилтнуудаа сонсож, дэмжих{" "}
        </span>
        <span id="th_188" class="t s2_188">
          •{" "}
        </span>
        <span id="ti_188" class="t s1_188">
          Хүний нөөцийн үйл ажиллагаа нь сургуулийн алсын хараа, эрхэм{" "}
        </span>
        <span id="tj_188" class="t s1_188">
          зорилго, үнэт зүйл, боловсролын зорилгод нийцэж байгаа эсэхийг{" "}
        </span>
        <span id="tk_188" class="t s1_188">
          шалгаж байх{" "}
        </span>
        <span id="tl_188" class="t s1_188">
          Эдгээр зөвлөмжийг{" "}
        </span>
        <span id="tm_188" class="t s1_188">
          сургуулийн удирдлагууд ажилтнуудынхаа сайн сайхан{" "}
        </span>
        <span id="tn_188" class="t s1_188">
          байдал, өсөлтийг дэмжих тогтвортой, дэмжлэгтэй орчныг бүрдүүлж, эцэст{" "}
        </span>
        <span id="to_188" class="t s1_188">
          нь сургуулийн нийт хамт олонд үр өгөөжөө өгөх боломжтой гэж үзэн
          тооцож{" "}
        </span>
        <span id="tp_188" class="t s1_188">
          ажиллахыг зөвлөж байна.{" "}
        </span>
        <span id="tq_188" class="t s3_188">
          Шигтгээ 5. Товч асуулт хариулт{" "}
        </span>
        <span id="tr_188" class="t s4_188">
          А:{" "}
        </span>
        <span id="ts_188" class="t s5_188">
          Ерөнхий боловсролын сургуулийн хүний нөөцийн бодлого гэж юу вэ?{" "}
        </span>
        <span id="tt_188" class="t s6_188">
          Х: Ерөнхий боловсролын сургуулийн хүний нөөцийн бодлого ( Хүний
          нөөцийн{" "}
        </span>
        <span id="tu_188" class="t s6_188">
          бодлого) нь ерөнхий боловсролын сургуулийн орчинд хүний нөөцийн{" "}
        </span>
        <span id="tv_188" class="t s6_188">
          менежментийг зохицуулах цогц заавар, зарчим, дүрэм юм. Энэ нь ажилд{" "}
        </span>
        <span id="tw_188" class="t s6_188">
          авах, боловсон хүчний хөгжил, гүйцэтгэлийн үнэлгээ, ажилчдын харилцаа,{" "}
        </span>
        <span id="tx_188" class="t s6_188">
          нөхөн олговор, тэтгэмж болон сургуулийн ажиллах хүчинтэй холбоотой
          бусад{" "}
        </span>
        <span id="ty_188" class="t s6_188">
          асуудлууд зэрэг хүний нөөцтэй холбоотой янз бүрийн асуудалд сургуулийн{" "}
        </span>
        <span id="tz_188" class="t s6_188">
          арга барилыг тодорхойлсон.{" "}
        </span>
        <span id="t10_188" class="t s4_188">
          А:{" "}
        </span>
        <span id="t11_188" class="t s5_188">
          Ерөнхий боловсролын сургуулийн хүний нөөцийн бодлогын зорилго ямар{" "}
        </span>
        <span id="t12_188" class="t s5_188">
          байх вэ
        </span>
        <span id="t13_188" class="t s7_188">
          ?{" "}
        </span>
        <span id="t14_188" class="t s4_188">
          Х: Б
        </span>
        <span id="t15_188" class="t s6_188">
          оловсон хүчнийг тууштай, шударга, үр дүнтэй удирдах тогтолцоог{" "}
        </span>
        <span id="t16_188" class="t s6_188">
          бүрдүүлэхэд оршино.{" "}
        </span>
        <span id="t17_188" class="t s4_188">
          А:{" "}
        </span>
        <span id="t18_188" class="t s5_188">
          Ерөнхий боловсролын сургуулийн хүний нөөцийн бодлогын үндсэн{" "}
        </span>
        <span id="t19_188" class="t s5_188">
          бүрэлдэхүүн хэсгүүдэд юу юу орох вэ?{" "}
        </span>
        <span id="t1a_188" class="t s4_188">
          Х: Дараах бүрэлдэхүүн хэсгүүд орно. Үүнд:{" "}
        </span>
      </div>
    </div>
  );
}
