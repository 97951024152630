import React from "react";
export default function SeventyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_71{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_71{left:132px;bottom:841px;}
#t3_71{left:242px;bottom:903px;letter-spacing:-0.41px;word-spacing:0.09px;}
#t4_71{left:243px;bottom:870px;letter-spacing:0.31px;word-spacing:-0.19px;}
#t5_71{left:275px;bottom:737px;letter-spacing:0.37px;word-spacing:-0.31px;}
#t6_71{left:152px;bottom:579px;}
#t7_71{left:152px;bottom:564px;letter-spacing:0.11px;word-spacing:0.03px;}
#t8_71{left:150px;bottom:538px;}
#t9_71{left:163px;bottom:538px;letter-spacing:-0.18px;word-spacing:0.02px;}
#ta_71{left:150px;bottom:525px;}
#tb_71{left:163px;bottom:525px;letter-spacing:-0.17px;word-spacing:0.02px;}
#tc_71{left:163px;bottom:512px;letter-spacing:-0.18px;word-spacing:0.03px;}
#td_71{left:183px;bottom:638px;}
#te_71{left:396px;bottom:537px;}
#tf_71{left:409px;bottom:537px;letter-spacing:-0.19px;word-spacing:0.03px;}
#tg_71{left:396px;bottom:525px;letter-spacing:-0.2px;word-spacing:3.17px;}
#th_71{left:396px;bottom:512px;}
#ti_71{left:409px;bottom:512px;letter-spacing:-0.18px;word-spacing:0.03px;}
#tj_71{left:396px;bottom:499px;}
#tk_71{left:409px;bottom:499px;letter-spacing:-0.17px;word-spacing:0.02px;}
#tl_71{left:395px;bottom:581px;}
#tm_71{left:395px;bottom:566px;letter-spacing:0.13px;word-spacing:0.02px;}
#tn_71{left:433px;bottom:636px;}
#to_71{left:329px;bottom:334px;letter-spacing:0.06px;}
#tp_71{left:329px;bottom:319px;letter-spacing:0.16px;}
#tq_71{left:327px;bottom:292px;letter-spacing:-0.17px;word-spacing:3.15px;}
#tr_71{left:340px;bottom:279px;letter-spacing:-0.2px;}
#ts_71{left:327px;bottom:266px;letter-spacing:-0.26px;word-spacing:3.25px;}
#tt_71{left:340px;bottom:253px;letter-spacing:-0.21px;}
#tu_71{left:327px;bottom:240px;}
#tv_71{left:340px;bottom:240px;letter-spacing:-0.18px;word-spacing:0.03px;}
#tw_71{left:501px;bottom:334px;letter-spacing:0.19px;}
#tx_71{left:501px;bottom:319px;letter-spacing:0.17px;word-spacing:0.14px;}
#ty_71{left:499px;bottom:292px;}
#tz_71{left:512px;bottom:292px;letter-spacing:-0.18px;word-spacing:0.04px;}
#t10_71{left:512px;bottom:279px;letter-spacing:-0.19px;word-spacing:0.03px;}
#t11_71{left:499px;bottom:266px;}
#t12_71{left:512px;bottom:266px;letter-spacing:-0.23px;word-spacing:0.08px;}
#t13_71{left:512px;bottom:253px;letter-spacing:-0.19px;word-spacing:0.05px;}
#t14_71{left:499px;bottom:240px;}
#t15_71{left:512px;bottom:240px;letter-spacing:-0.17px;word-spacing:0.01px;}
#t16_71{left:499px;bottom:227px;}
#t17_71{left:512px;bottom:227px;letter-spacing:-0.17px;word-spacing:0.01px;}
#t18_71{left:499px;bottom:215px;}
#t19_71{left:512px;bottom:215px;letter-spacing:-0.18px;word-spacing:0.02px;}
#t1a_71{left:87px;bottom:292px;}
#t1b_71{left:100px;bottom:292px;letter-spacing:-0.17px;word-spacing:0.02px;}
#t1c_71{left:87px;bottom:279px;}
#t1d_71{left:100px;bottom:279px;letter-spacing:-0.16px;word-spacing:0.01px;}
#t1e_71{left:100px;bottom:266px;letter-spacing:-0.16px;word-spacing:0.01px;}
#t1f_71{left:100px;bottom:253px;letter-spacing:-0.15px;word-spacing:-0.01px;}
#t1g_71{left:87px;bottom:240px;}
#t1h_71{left:100px;bottom:240px;letter-spacing:-0.2px;word-spacing:0.05px;}
#t1i_71{left:100px;bottom:227px;letter-spacing:-0.19px;word-spacing:0.03px;}
#t1j_71{left:88px;bottom:334px;letter-spacing:0.08px;}
#t1k_71{left:88px;bottom:319px;letter-spacing:0.16px;}
#t1l_71{left:528px;bottom:384px;}
#t1m_71{left:315px;bottom:384px;}
#t1n_71{left:110px;bottom:384px;}
#t1o_71{left:159px;bottom:122px;letter-spacing:-0.11px;}
#t1p_71{left:304px;bottom:121px;letter-spacing:0.07px;word-spacing:0.09px;}
#t1q_71{left:572px;bottom:120px;letter-spacing:0.18px;}

.s0_71{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_71{font-size:76px;font-family:Gotham-Light_17v;color:#000;}
.s2_71{font-size:28px;font-family:Arial-BoldMT_1li;color:#F05522;}
.s3_71{font-size:24px;font-family:Arial-BoldMT_1li;color:#F90;}
.s4_71{font-size:12px;font-family:MogulArial-Bold_179;color:#F05522;}
.s5_71{font-size:11px;font-family:MogulArial_16s;color:#000;}
.s6_71{font-size:34px;font-family:Gotham-Bold_185;color:#FFF;}
.s7_71{font-size:12px;font-family:MogulArial-Bold_179;color:#FFA600;}
.s8_71{font-size:12px;font-family:MogulArial-Bold_179;color:#910082;}
.s9_71{font-size:13px;font-family:MogulArial-Bold_179;color:#EE3A81;}
.sa_71{font-size:12px;font-family:MogulArial-Bold_179;color:#4E8EC3;}
.sb_71{font-size:15px;font-family:Calibri_1b4;color:#000;}
.t.v0_71{transform:scaleX(0.969);}
.t.v1_71{transform:scaleX(0.909);}`}
      </style>

      <style id="fonts71" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Calibri_1b4;
	src: url("fonts/Calibri_1b4.woff") format("woff");
}

@font-face {
	font-family: Gotham-Bold_185;
	src: url("fonts/Gotham-Bold_185.woff") format("woff");
}

@font-face {
	font-family: Gotham-Light_17v;
	src: url("fonts/Gotham-Light_17v.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg71" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="71/71.svg"
          type="image/svg+xml"
          id="pdf71"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_71" class="t s0_71">
          71{" "}
        </span>
        <span id="t2_71" class="t v0_71 s1_71">
          3{" "}
        </span>
        <span id="t3_71" class="t s2_71">
          СУРГУУЛИЙН МЕНЕЖМЕНТ:{" "}
        </span>
        <span id="t4_71" class="t s2_71">
          АСУУДАЛ ШИЙДЭЛ{" "}
        </span>
        <span id="t5_71" class="t v1_71 s3_71">
          III БҮЛГИЙН ТОЙМ{" "}
        </span>
        <span id="t6_71" class="t s4_71">
          СУРАГЧИЙН{" "}
        </span>
        <span id="t7_71" class="t s4_71">
          ХӨГЖИЛ, ХАМГААЛАЛ{" "}
        </span>
        <span id="t8_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t9_71" class="t s5_71">
          Сурагчийн хөгжил, хамгаалал{" "}
        </span>
        <span id="ta_71" class="t s5_71">
          •{" "}
        </span>
        <span id="tb_71" class="t s5_71">
          Сурагчийн хөгжил, хамгааллын{" "}
        </span>
        <span id="tc_71" class="t s5_71">
          бүтэц, зохион байгуулалт{" "}
        </span>
        <span id="td_71" class="t v0_71 s6_71">
          1{" "}
        </span>
        <span id="te_71" class="t s5_71">
          •{" "}
        </span>
        <span id="tf_71" class="t s5_71">
          Сургалтын хөтөлбөрийг судлах, ойлгох{" "}
        </span>
        <span id="tg_71" class="t s5_71">
          • Сургалтын төлөвлөлт{" "}
        </span>
        <span id="th_71" class="t s5_71">
          •{" "}
        </span>
        <span id="ti_71" class="t s5_71">
          Сургалтын хөтөлбөрийг хэрэгжүүлэх арга, технологи{" "}
        </span>
        <span id="tj_71" class="t s5_71">
          •{" "}
        </span>
        <span id="tk_71" class="t s5_71">
          Сургалтын хөтөлбөрийн хэрэгжилтийн үнэлгээ{" "}
        </span>
        <span id="tl_71" class="t s7_71">
          СУРГАЛТ{" "}
        </span>
        <span id="tm_71" class="t s7_71">
          ХӨТӨЛБӨРИЙН ХЭРЭГЖИЛТ{" "}
        </span>
        <span id="tn_71" class="t v0_71 s6_71">
          2{" "}
        </span>
        <span id="to_71" class="t s8_71">
          СУРГАЛТЫН{" "}
        </span>
        <span id="tp_71" class="t s8_71">
          ОРЧИН{" "}
        </span>
        <span id="tq_71" class="t s5_71">
          • Материаллаг нөөц,{" "}
        </span>
        <span id="tr_71" class="t s5_71">
          хүртээмж{" "}
        </span>
        <span id="ts_71" class="t s5_71">
          • Ашиглалт, зохион{" "}
        </span>
        <span id="tt_71" class="t s5_71">
          байгуулалт{" "}
        </span>
        <span id="tu_71" class="t s5_71">
          •{" "}
        </span>
        <span id="tv_71" class="t s5_71">
          Эрүүл, аюулгүй орчин{" "}
        </span>
        <span id="tw_71" class="t v1_71 s9_71">
          СУРГУУЛИЙН{" "}
        </span>
        <span id="tx_71" class="t v1_71 s9_71">
          УДИРДЛАГА, МАНЛАЙЛАЛ{" "}
        </span>
        <span id="ty_71" class="t s5_71">
          •{" "}
        </span>
        <span id="tz_71" class="t s5_71">
          Сургуулийн зорилго, үйл{" "}
        </span>
        <span id="t10_71" class="t s5_71">
          ажиллагааны нэгдсэн төлөвлөлт{" "}
        </span>
        <span id="t11_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t12_71" class="t s5_71">
          Сургалт, үйл ажиллагааны{" "}
        </span>
        <span id="t13_71" class="t s5_71">
          зохион байгуулалт{" "}
        </span>
        <span id="t14_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t15_71" class="t s5_71">
          Байгууллагын хүний нөөц{" "}
        </span>
        <span id="t16_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t17_71" class="t s5_71">
          Сургуулийн төсөв, санхүүжилт{" "}
        </span>
        <span id="t18_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t19_71" class="t s5_71">
          Хамтын ажиллагаа, түншлэл{" "}
        </span>
        <span id="t1a_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t1b_71" class="t s5_71">
          Багшийн хөгжих хэрэгцээ ба төлөвлөлт{" "}
        </span>
        <span id="t1c_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t1d_71" class="t s5_71">
          Багшийн манлайлал, хамтын{" "}
        </span>
        <span id="t1e_71" class="t s5_71">
          ажиллагаа, хамтран ажиллах соёл-{" "}
        </span>
        <span id="t1f_71" class="t s5_71">
          Мэргэжлийн хөгжпийн бүлэг{" "}
        </span>
        <span id="t1g_71" class="t s5_71">
          •{" "}
        </span>
        <span id="t1h_71" class="t s5_71">
          Багшийн өөрийн удирдлагатай{" "}
        </span>
        <span id="t1i_71" class="t s5_71">
          суралцахуй ба технологийн чадамж{" "}
        </span>
        <span id="t1j_71" class="t sa_71">
          БАГШИЙН{" "}
        </span>
        <span id="t1k_71" class="t sa_71">
          ХӨГЖИЛ{" "}
        </span>
        <span id="t1l_71" class="t v0_71 s6_71">
          5{" "}
        </span>
        <span id="t1m_71" class="t v0_71 s6_71">
          4{" "}
        </span>
        <span id="t1n_71" class="t v0_71 s6_71">
          3{" "}
        </span>
        <span id="t1o_71" class="t sb_71">
          АСУУДАЛ{" "}
        </span>
        <span id="t1p_71" class="t sb_71">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t1q_71" class="t sb_71">
          ЗӨВЛӨМЖ{" "}
        </span>
      </div>
    </div>
  );
}
