import React from "react";
export default function F134() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_134{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_134{left:139px;bottom:941px;}
#t3_134{left:165px;bottom:941px;letter-spacing:-0.11px;word-spacing:6.26px;}
#t4_134{left:165px;bottom:921px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t5_134{left:139px;bottom:896px;}
#t6_134{left:165px;bottom:896px;letter-spacing:-0.18px;word-spacing:6.97px;}
#t7_134{left:165px;bottom:876px;letter-spacing:-0.16px;word-spacing:8.98px;}
#t8_134{left:165px;bottom:856px;letter-spacing:-0.14px;}
#t9_134{left:139px;bottom:831px;}
#ta_134{left:165px;bottom:831px;letter-spacing:-0.17px;word-spacing:-0.72px;}
#tb_134{left:165px;bottom:811px;letter-spacing:-0.14px;word-spacing:1.28px;}
#tc_134{left:165px;bottom:791px;letter-spacing:-0.21px;word-spacing:0.12px;}
#td_134{left:142px;bottom:733px;letter-spacing:0.1px;word-spacing:0.12px;}
#te_134{left:106px;bottom:683px;letter-spacing:0.1px;word-spacing:-1.08px;}
#tf_134{left:106px;bottom:665px;letter-spacing:0.11px;word-spacing:3.04px;}
#tg_134{left:507px;bottom:665px;letter-spacing:0.13px;word-spacing:3.03px;}
#th_134{left:106px;bottom:647px;letter-spacing:0.11px;word-spacing:0.04px;}
#ti_134{left:106px;bottom:615px;letter-spacing:0.13px;}
#tj_134{left:219px;bottom:615px;letter-spacing:0.07px;}
#tk_134{left:311px;bottom:615px;letter-spacing:0.1px;}
#tl_134{left:385px;bottom:615px;letter-spacing:0.1px;}
#tm_134{left:490px;bottom:615px;}
#tn_134{left:595px;bottom:615px;letter-spacing:0.15px;}
#to_134{left:106px;bottom:597px;letter-spacing:0.13px;word-spacing:0.02px;}
#tp_134{left:106px;bottom:566px;letter-spacing:0.09px;word-spacing:-0.34px;}
#tq_134{left:338px;bottom:566px;letter-spacing:0.15px;word-spacing:-0.38px;}
#tr_134{left:652px;bottom:566px;}
#ts_134{left:106px;bottom:547px;letter-spacing:0.15px;word-spacing:4.51px;}
#tt_134{left:412px;bottom:547px;letter-spacing:0.16px;word-spacing:4.5px;}
#tu_134{left:106px;bottom:529px;letter-spacing:0.11px;word-spacing:0.02px;}
#tv_134{left:231px;bottom:500px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tw_134{left:429px;bottom:484px;letter-spacing:-0.16px;word-spacing:0.03px;}
#tx_134{left:106px;bottom:387px;letter-spacing:0.1px;word-spacing:1.41px;}
#ty_134{left:106px;bottom:369px;letter-spacing:0.1px;word-spacing:3.06px;}
#tz_134{left:507px;bottom:369px;letter-spacing:0.09px;word-spacing:3.07px;}
#t10_134{left:106px;bottom:351px;letter-spacing:0.08px;word-spacing:1.35px;}
#t11_134{left:106px;bottom:332px;letter-spacing:0.12px;word-spacing:0.02px;}
#t12_134{left:106px;bottom:301px;letter-spacing:0.09px;word-spacing:-0.02px;}
#t13_134{left:106px;bottom:283px;letter-spacing:0.1px;word-spacing:0.52px;}
#t14_134{left:106px;bottom:264px;letter-spacing:0.1px;word-spacing:1.2px;}
#t15_134{left:106px;bottom:246px;letter-spacing:0.1px;word-spacing:0.04px;}
#t16_134{left:231px;bottom:221px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t17_134{left:429px;bottom:205px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_134{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_134{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_134{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_134{font-size:18px;font-family:Arial-BoldMT_1li;color:#D1622B;}
.s4_134{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_134{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s6_134{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts134" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg134" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="134/134.svg"
          type="image/svg+xml"
          id="pdf134"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_134" class="t s0_134">
          134{" "}
        </span>
        <span id="t2_134" class="t s1_134">
          •{" "}
        </span>
        <span id="t3_134" class="t s2_134">
          Багшийн мэргэжлийн хөгжлийн ерөнхий төлөвлөгөөг хийхдээ{" "}
        </span>
        <span id="t4_134" class="t s2_134">
          багш нарын саналыг авдаггүй{" "}
        </span>
        <span id="t5_134" class="t s1_134">
          •{" "}
        </span>
        <span id="t6_134" class="t s2_134">
          Багш тодорхой зорилго, агуулгагүйгээр сургалтад хамрагдах,{" "}
        </span>
        <span id="t7_134" class="t s2_134">
          хичээлийн судалгаанд оролцох гэх мэтээр ерөнхий нэрээр{" "}
        </span>
        <span id="t8_134" class="t s2_134">
          төлөвлөдөг{" "}
        </span>
        <span id="t9_134" class="t s1_134">
          •{" "}
        </span>
        <span id="ta_134" class="t s2_134">
          Төлөвлөгөөний 90.0 хувь нь багш нарын сонгосон модуль сургалт,{" "}
        </span>
        <span id="tb_134" class="t s2_134">
          хамрагдсан МХБ нэрсийн жагсаалт төдий байна. Үйл ажиллагаа{" "}
        </span>
        <span id="tc_134" class="t s2_134">
          нь тодорхой бус{" "}
        </span>
        <span id="td_134" class="t s3_134">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="te_134" class="t s4_134">
          Сурагчдад тулгамдаж буй асуудлыг илрүүлснээр түүнийг шийдвэрлэх
          багшийг{" "}
        </span>
        <span id="tf_134" class="t s4_134">
          хөгжүүлэх хэрэгцээгээ тодорхойлсон байна. Ингэхдээ{" "}
        </span>
        <span id="tg_134" class="t s5_134">
          БШҮ - ээр доогуур{" "}
        </span>
        <span id="th_134" class="t s5_134">
          үзүүлэлттэй үнэлэгдсэн суралцахуйн зорилтуудын анализ{" "}
        </span>
        <span id="ti_134" class="t s4_134">
          Суралцахуйн{" "}
        </span>
        <span id="tj_134" class="t s4_134">
          зорилтууд{" "}
        </span>
        <span id="tk_134" class="t s4_134">
          доогуур{" "}
        </span>
        <span id="tl_134" class="t s4_134">
          үзүүлэлттэй{" "}
        </span>
        <span id="tm_134" class="t s4_134">
          үнэлэгдэхэд{" "}
        </span>
        <span id="tn_134" class="t s4_134">
          хүргэсэн{" "}
        </span>
        <span id="to_134" class="t s5_134">
          шалтгаануудыг илрүүлэх багшийн өөрийн үнэлгээ{" "}
        </span>
        <span id="tp_134" class="t s4_134">
          Доогуур үзүүлэлттэй үнэлэгдсэн{" "}
        </span>
        <span id="tq_134" class="t s5_134">
          суралцахуйн зорилтуудад хийсэн анализ
        </span>
        <span id="tr_134" class="t s4_134">
          ,{" "}
        </span>
        <span id="ts_134" class="t s4_134">
          өөрийн үнэлгээний үр дүнд үндэслэсэн{" "}
        </span>
        <span id="tt_134" class="t s5_134">
          багшийн хөгжлийн төлөвлөгөө{" "}
        </span>
        <span id="tu_134" class="t s4_134">
          гэсэн 3 үе шаттай ажил хийсэн байна.{" "}
        </span>
        <span id="tv_134" class="t s6_134">
          ... аймгийн ... ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="tw_134" class="t s6_134">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tx_134" class="t s4_134">
          Үүний дараа багш асуудлын шалтгааныг ярилцан бүлэглэснээр бага, суурь,{" "}
        </span>
        <span id="ty_134" class="t s4_134">
          ахлах ангийн сурагчдад тулгамдаж буй асуудлуудыг{" "}
        </span>
        <span id="tz_134" class="t s4_134">
          танин мэдэхүй буюу{" "}
        </span>
        <span id="t10_134" class="t s4_134">
          мэдлэг, чадварын, сэтгэл хөдлөл, нийгэмшихүй буюу хандлага төлөвшлийн,{" "}
        </span>
        <span id="t11_134" class="t s4_134">
          эрүүл мэнд, бие бялдрын гэж бүлэглэн нэгтгэв.{" "}
        </span>
        <span id="t12_134" class="t s4_134">
          Эдгээр асуудлуудыг үүсгэж буй шалтгаанаар нь багцлан, хэрхэн
          шийдвэрлэж{" "}
        </span>
        <span id="t13_134" class="t s4_134">
          болохыг ярилцсанаар нэн яаралтай шийдвэрлэх суурь асуудлуудын хүрээнд{" "}
        </span>
        <span id="t14_134" class="t s4_134">
          ирэх хичээлийн жилийн зорилгыг “Бүтээлч сэтгэлгээтэй, хамтран
          суралцагч,{" "}
        </span>
        <span id="t15_134" class="t s4_134">
          эрүүл хүүхэд” гэж тодорхойлохоор төсөөлсөн байна.{" "}
        </span>
        <span id="t16_134" class="t s6_134">
          ... аймгийн ... ерөнхий боловсролын ... сургуулийн менежментийг{" "}
        </span>
        <span id="t17_134" class="t s6_134">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
