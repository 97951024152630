import React from "react";
export default function F152() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_152{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_152{left:143px;bottom:941px;}
#t3_152{left:169px;bottom:941px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t4_152{left:143px;bottom:916px;}
#t5_152{left:169px;bottom:916px;letter-spacing:-0.13px;word-spacing:0.03px;}
#t6_152{left:143px;bottom:892px;}
#t7_152{left:169px;bottom:892px;letter-spacing:-0.17px;word-spacing:0.97px;}
#t8_152{left:169px;bottom:872px;letter-spacing:-0.16px;word-spacing:0.06px;}
#t9_152{left:143px;bottom:847px;}
#ta_152{left:169px;bottom:847px;letter-spacing:-0.85px;word-spacing:-0.39px;}
#tb_152{left:142px;bottom:798px;letter-spacing:-0.24px;word-spacing:0.11px;}
#tc_152{left:106px;bottom:745px;letter-spacing:0.13px;word-spacing:2.92px;}
#td_152{left:106px;bottom:727px;letter-spacing:0.1px;word-spacing:0.05px;}
#te_152{left:106px;bottom:704px;}
#tf_152{left:124px;bottom:704px;letter-spacing:0.11px;word-spacing:4.05px;}
#tg_152{left:124px;bottom:686px;letter-spacing:0.11px;word-spacing:4.67px;}
#th_152{left:124px;bottom:667px;letter-spacing:0.05px;}
#ti_152{left:106px;bottom:649px;}
#tj_152{left:124px;bottom:649px;letter-spacing:0.06px;word-spacing:0.08px;}
#tk_152{left:106px;bottom:631px;}
#tl_152{left:124px;bottom:631px;letter-spacing:0.1px;word-spacing:1.27px;}
#tm_152{left:124px;bottom:612px;letter-spacing:0.12px;word-spacing:0.01px;}
#tn_152{left:244px;bottom:583px;letter-spacing:-0.14px;word-spacing:0.03px;}
#to_152{left:326px;bottom:567px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tp_152{left:106px;bottom:504px;letter-spacing:0.11px;word-spacing:4.42px;}
#tq_152{left:106px;bottom:486px;letter-spacing:0.1px;word-spacing:0.05px;}
#tr_152{left:106px;bottom:463px;}
#ts_152{left:124px;bottom:463px;letter-spacing:0.09px;word-spacing:0.05px;}
#tt_152{left:106px;bottom:445px;}
#tu_152{left:124px;bottom:445px;letter-spacing:0.09px;word-spacing:0.04px;}
#tv_152{left:106px;bottom:426px;}
#tw_152{left:124px;bottom:426px;letter-spacing:0.08px;word-spacing:2.85px;}
#tx_152{left:124px;bottom:408px;letter-spacing:0.1px;word-spacing:0.04px;}
#ty_152{left:106px;bottom:390px;}
#tz_152{left:124px;bottom:390px;letter-spacing:0.1px;word-spacing:0.04px;}
#t10_152{left:106px;bottom:371px;}
#t11_152{left:124px;bottom:371px;letter-spacing:0.1px;word-spacing:0.05px;}
#t12_152{left:290px;bottom:347px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t13_152{left:326px;bottom:330px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t14_152{left:106px;bottom:263px;letter-spacing:0.11px;word-spacing:1.73px;}
#t15_152{left:106px;bottom:244px;letter-spacing:0.1px;word-spacing:0.05px;}
#t16_152{left:106px;bottom:222px;}
#t17_152{left:124px;bottom:222px;letter-spacing:0.1px;word-spacing:0.5px;}
#t18_152{left:124px;bottom:203px;letter-spacing:0.11px;word-spacing:-0.77px;}
#t19_152{left:124px;bottom:185px;letter-spacing:0.06px;}
#t1a_152{left:106px;bottom:162px;}
#t1b_152{left:124px;bottom:162px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1c_152{left:290px;bottom:138px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1d_152{left:326px;bottom:121px;letter-spacing:-0.16px;word-spacing:0.02px;}

.s0_152{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_152{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s2_152{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_152{font-size:17px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_152{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_152{font-size:15px;font-family:SymbolMT_19r;color:#000;}
.s6_152{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s7_152{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts152" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg152" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="152/152.svg"
          type="image/svg+xml"
          id="pdf152"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_152" class="t s0_152">
          152{" "}
        </span>
        <span id="t2_152" class="t s1_152">
          •{" "}
        </span>
        <span id="t3_152" class="t s2_152">
          Зарим тохиолдолд төлөвлөөгүй ажил хийх болдог{" "}
        </span>
        <span id="t4_152" class="t s1_152">
          •{" "}
        </span>
        <span id="t5_152" class="t s2_152">
          Үр дүнтэй, хэрэгцээтэй сургалт дутмаг байдаг{" "}
        </span>
        <span id="t6_152" class="t s1_152">
          •{" "}
        </span>
        <span id="t7_152" class="t s2_152">
          Багшийн хэрэгцээ шаардлагад нийцсэн сургалт, үйл ажиллагааг{" "}
        </span>
        <span id="t8_152" class="t s2_152">
          зохион байгуулдаггүй{" "}
        </span>
        <span id="t9_152" class="t s1_152">
          •{" "}
        </span>
        <span id="ta_152" class="t s2_152">
          Өөрсдийн санаачилгаар сургалт, үйл ажиллагаанд хамрагдах нь ховор{" "}
        </span>
        <span id="tb_152" class="t s3_152">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="tc_152" class="t s4_152">
          Цаг хугацаа хүрэлцдэггүй, төлөвлөгөөнүүд уялдаа холбоогүй байдагтай{" "}
        </span>
        <span id="td_152" class="t s4_152">
          холбоотой асуудлыг шийдвэрлэж буй сургуулиудын зарим туршлагаас{" "}
        </span>
        <span id="te_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tf_152" class="t s6_152">
          Сургуулийн багш бүр мэргэжлийн хөгжлийн төлөвлөгөө гаргасан. Багш{" "}
        </span>
        <span id="tg_152" class="t s6_152">
          нартай хамтран суралцахуйд суурилсан төлөвлөгөө гарган хэрэгжүүлж{" "}
        </span>
        <span id="th_152" class="t s6_152">
          байна.{" "}
        </span>
        <span id="ti_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tj_152" class="t s6_152">
          Сургуулийн төлөвлөгөөг хамт олноороо ярилцаж боловсруулдаг.{" "}
        </span>
        <span id="tk_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tl_152" class="t s6_152">
          Долоо хоногийн аль нэг өдрийн тодорхой цагийг багш хөгжих цаг болгож,{" "}
        </span>
        <span id="tm_152" class="t s6_152">
          тухайн цаг дээр сургуулийн зүгээс өөр ажил төлөвлөдөггүй.{" "}
        </span>
        <span id="tn_152" class="t s7_152">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="to_152" class="t s7_152">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tp_152" class="t s4_152">
          Мэргэжил дээшлүүлэх сургалт, үйл ажиллагаатай холбоотой асуудлыг{" "}
        </span>
        <span id="tq_152" class="t s4_152">
          шийдвэрлэж буй сургуулиудын зарим туршлагаас{" "}
        </span>
        <span id="tr_152" class="t s5_152">
          •{" "}
        </span>
        <span id="ts_152" class="t s6_152">
          Багш өөрийн хэрэгцээнд тулгуурлан сургалтуудаас сонгон хамрагдах{" "}
        </span>
        <span id="tt_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tu_152" class="t s6_152">
          Aplus.mn, Esurgalt.mn сургалтуудад хамрагдах{" "}
        </span>
        <span id="tv_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tw_152" class="t s6_152">
          Үндсэн сургалтад хамрагдсан багш нар бусад багш нартаа сурч мэдсэн{" "}
        </span>
        <span id="tx_152" class="t s6_152">
          зүйлээрээ сургалт зохион байгуулах, түгээн дэлгэрүүлэх{" "}
        </span>
        <span id="ty_152" class="t s5_152">
          •{" "}
        </span>
        <span id="tz_152" class="t s6_152">
          ЗАН болон бусад чөлөөт цагаараа бусдаасаа суралцах{" "}
        </span>
        <span id="t10_152" class="t s5_152">
          •{" "}
        </span>
        <span id="t11_152" class="t s6_152">
          Аймгийнхаа бусад багш нартай туршлага солилцох, хамтран ажиллах{" "}
        </span>
        <span id="t12_152" class="t s7_152">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="t13_152" class="t s7_152">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="t14_152" class="t s4_152">
          Мэргэжлийн хөгжлийг дэмжих орчинтой холбоотой асуудлыг шийдвэрлэж{" "}
        </span>
        <span id="t15_152" class="t s4_152">
          буй сургуулиудын зарим туршлагаас{" "}
        </span>
        <span id="t16_152" class="t s5_152">
          •{" "}
        </span>
        <span id="t17_152" class="t s6_152">
          Төсвийн шинэчлэлийн хүрээнд олгогдож буй санхүүжилтийг багш нар ЗАН{" "}
        </span>
        <span id="t18_152" class="t s6_152">
          болон хэсэг бүлгээрээ нийлж хэрэгцээтэй зарим техник хэрэгслээ
          худалдан{" "}
        </span>
        <span id="t19_152" class="t s6_152">
          авах{" "}
        </span>
        <span id="t1a_152" class="t s5_152">
          •{" "}
        </span>
        <span id="t1b_152" class="t s6_152">
          Нэмэлт интернэт датаг багш өөрөө зохицуулдаг{" "}
        </span>
        <span id="t1c_152" class="t s7_152">
          ... аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="t1d_152" class="t s7_152">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
