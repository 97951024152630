import React from "react";
export default function SeventyNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_79{left:674px;bottom:59px;letter-spacing:0.16px;}
#t2_79{left:142px;bottom:927px;letter-spacing:0.11px;word-spacing:0.12px;}
#t3_79{left:107px;bottom:868px;letter-spacing:0.11px;word-spacing:5.13px;}
#t4_79{left:511px;bottom:868px;letter-spacing:-0.05px;word-spacing:5.27px;}
#t5_79{left:107px;bottom:850px;letter-spacing:0.12px;word-spacing:3.74px;}
#t6_79{left:107px;bottom:831px;letter-spacing:0.12px;word-spacing:-0.94px;}
#t7_79{left:107px;bottom:813px;letter-spacing:0.1px;word-spacing:0.03px;}
#t8_79{left:446px;bottom:813px;letter-spacing:0.11px;word-spacing:0.03px;}
#t9_79{left:546px;bottom:813px;letter-spacing:0.11px;word-spacing:0.03px;}
#ta_79{left:107px;bottom:795px;letter-spacing:0.1px;word-spacing:4.1px;}
#tb_79{left:107px;bottom:776px;letter-spacing:0.11px;word-spacing:0.03px;}
#tc_79{left:291px;bottom:746px;}
#td_79{left:295px;bottom:746px;letter-spacing:-0.15px;word-spacing:0.03px;}
#te_79{left:327px;bottom:730px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tf_79{left:106px;bottom:630px;letter-spacing:0.11px;word-spacing:2.61px;}
#tg_79{left:511px;bottom:630px;letter-spacing:0.13px;word-spacing:2.59px;}
#th_79{left:106px;bottom:611px;letter-spacing:0.12px;word-spacing:2.72px;}
#ti_79{left:226px;bottom:611px;letter-spacing:0.09px;word-spacing:2.74px;}
#tj_79{left:106px;bottom:593px;letter-spacing:0.12px;word-spacing:3.85px;}
#tk_79{left:106px;bottom:575px;letter-spacing:0.1px;word-spacing:0.61px;}
#tl_79{left:106px;bottom:556px;letter-spacing:0.09px;word-spacing:7.35px;}
#tm_79{left:106px;bottom:538px;letter-spacing:0.12px;word-spacing:0.03px;}
#tn_79{left:321px;bottom:514px;letter-spacing:-0.14px;word-spacing:0.03px;}
#to_79{left:250px;bottom:497px;letter-spacing:-0.15px;}
#tp_79{left:326px;bottom:497px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tq_79{left:142px;bottom:406px;letter-spacing:0.18px;}
#tr_79{left:87px;bottom:378px;}
#ts_79{left:113px;bottom:378px;letter-spacing:-0.15px;word-spacing:9.86px;}
#tt_79{left:113px;bottom:357px;letter-spacing:-0.15px;word-spacing:3.85px;}
#tu_79{left:113px;bottom:337px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tv_79{left:87px;bottom:313px;}
#tw_79{left:113px;bottom:313px;letter-spacing:-0.15px;word-spacing:1.15px;}
#tx_79{left:504px;bottom:313px;letter-spacing:-0.15px;word-spacing:1.16px;}
#ty_79{left:113px;bottom:293px;letter-spacing:-0.13px;word-spacing:-1.19px;}
#tz_79{left:113px;bottom:272px;letter-spacing:-0.17px;word-spacing:-1.52px;}
#t10_79{left:113px;bottom:252px;letter-spacing:-0.17px;word-spacing:0.08px;}
#t11_79{left:87px;bottom:228px;}
#t12_79{left:113px;bottom:228px;letter-spacing:-0.12px;word-spacing:8.94px;}
#t13_79{left:113px;bottom:208px;letter-spacing:-0.16px;word-spacing:0.04px;}

.s0_79{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_79{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_79{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s3_79{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s4_79{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_79{font-size:15px;font-family:ArialMT_1lm;color:#000;}
.s6_79{font-size:15px;font-family:Arial-BoldMT_1li;color:#000;}
.s7_79{font-size:14px;font-family:Arial-ItalicMT_18u;color:#000;}
.s8_79{font-size:17px;font-family:SymbolMT_19r;color:#000;}
.s9_79{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts79" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: ArialMT_1lm;
	src: url("fonts/ArialMT_1lm.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: SymbolMT_19r;
	src: url("fonts/SymbolMT_19r.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg79" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="79/79.svg"
          type="image/svg+xml"
          id="pdf79"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_79" class="t s0_79">
          79{" "}
        </span>
        <span id="t2_79" class="t s1_79">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="t3_79" class="t s2_79">
          Орон нутгийн “Хүүхэд хамгааллын хамтарсан баг”,{" "}
        </span>
        <span id="t4_79" class="t s2_79">
          ГБХЗХГ, сургуулийн{" "}
        </span>
        <span id="t5_79" class="t s2_79">
          нийгмийн ажилтан нар эрсдэлт нөхцөлд байгаа, эрсдэл үүсэж болзошгүй{" "}
        </span>
        <span id="t6_79" class="t s2_79">
          хүүхдүүд дээр кейс үүсгэн хамтран ажиллаж шийдвэрлэжээ. Бага анги,
          дотуур{" "}
        </span>
        <span id="t7_79" class="t s2_79">
          байрны сурагчдыг эцэг эхэд нь хүлээлгэж өгөх ”
        </span>
        <span id="t8_79" class="t s3_79">
          гараас гарт”{" "}
        </span>
        <span id="t9_79" class="t s2_79">
          үйл ажиллагааг{" "}
        </span>
        <span id="ta_79" class="t s2_79">
          хэвшил болгосон, энд АУБ, эцэг эх, сурагчийн гурвалсан гэрээ байгуулж{" "}
        </span>
        <span id="tb_79" class="t s2_79">
          хэрэгжилтийг хангаж ажилладаг болжээ.{" "}
        </span>
        <span id="tc_79" class="t s3_79">
          .
        </span>
        <span id="td_79" class="t s4_79">
          .. аймгийн ... сумын ерөнхий боловсролын ... сургуулийн{" "}
        </span>
        <span id="te_79" class="t s4_79">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tf_79" class="t s5_79">
          Сургуулийн Хүүхэд хамгааллын Зөвлөлийн гишүүдийн{" "}
        </span>
        <span id="tg_79" class="t s6_79">
          Хүүхэд хамгаалал-{" "}
        </span>
        <span id="th_79" class="t s6_79">
          Хүүхэд хяналт{" "}
        </span>
        <span id="ti_79" class="t s5_79">
          үйлчилгээг хэрэгжүүлж ажиллав. Эрх нь зөрчигдөж байгаа{" "}
        </span>
        <span id="tj_79" class="t s5_79">
          сурагчдын ар гэрээр очиж, нөхцөл байдлын үнэлгээ хийж, эцэг эх асран{" "}
        </span>
        <span id="tk_79" class="t s5_79">
          хамгаалагч нарын үүрэг таниулах хуулийг танилцуулж, зөвлөгөө
          зөвлөмжийг{" "}
        </span>
        <span id="tl_79" class="t s5_79">
          өгч ажилладаг. Эрсдэлд өртсөн хүүхдэд Хамтарсан багийн үйлчилгээг{" "}
        </span>
        <span id="tm_79" class="t s5_79">
          шуурхай үзүүлж ажиллав.{" "}
        </span>
        <span id="tn_79" class="t s7_79">
          ... нийслэлийнн ... дүүргийн ерөнхий боловсролын ...{" "}
        </span>
        <span id="to_79" class="t s7_79">
          сургуулийн{" "}
        </span>
        <span id="tp_79" class="t s4_79">
          менежментийг сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="tq_79" class="t s1_79">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="tr_79" class="t s8_79">
          •{" "}
        </span>
        <span id="ts_79" class="t s9_79">
          Сургуулийн хүүхэд хөгжил, хамгааллын алба нь хүүхэд хөгжил,{" "}
        </span>
        <span id="tt_79" class="t s9_79">
          хамгааллын үйл ажиллагааны төлөвлөгөөг боловсруулж хэрэгжилтэд{" "}
        </span>
        <span id="tu_79" class="t s9_79">
          хяналт тавьж ажилладаг байх{" "}
        </span>
        <span id="tv_79" class="t s8_79">
          •{" "}
        </span>
        <span id="tw_79" class="t s9_79">
          Сургуулийн хүүхэд хөгжил, хамгааллын алба нь{" "}
        </span>
        <span id="tx_79" class="t s9_79">
          хүүхэд хамгаалал гэж{" "}
        </span>
        <span id="ty_79" class="t s9_79">
          юу болох, хамгааллын хүчин зүйлсийн талаарх багш ажилтан, сурагчдын{" "}
        </span>
        <span id="tz_79" class="t s9_79">
          мэдлэг ойлголт, чадвар, хандлагыг сайжруулах олон талт үйл ажиллагааг{" "}
        </span>
        <span id="t10_79" class="t s9_79">
          зохион байгуулдаг байх{" "}
        </span>
        <span id="t11_79" class="t s8_79">
          •{" "}
        </span>
        <span id="t12_79" class="t s9_79">
          Нийгмийн ажилтны орон тоон дээр мэргэжлийн хүн ажиллуулах{" "}
        </span>
        <span id="t13_79" class="t s9_79">
          шалгуурыг бий болгож ажлын байрны тодорхойлолтыг мөрдөж ажиллах{" "}
        </span>
      </div>
    </div>
  );
}
