import React from "react";
export default function HundredTwentyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_121{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_121{left:87px;bottom:941px;letter-spacing:-0.3px;word-spacing:0.04px;}
#t3_121{left:87px;bottom:902px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t4_121{left:87px;bottom:882px;letter-spacing:-0.14px;word-spacing:9.13px;}
#t5_121{left:87px;bottom:862px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t6_121{left:87px;bottom:842px;letter-spacing:-0.08px;}
#t7_121{left:285px;bottom:827px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t8_121{left:87px;bottom:436px;letter-spacing:-0.12px;word-spacing:8.38px;}
#t9_121{left:87px;bottom:417px;letter-spacing:-0.2px;word-spacing:0.11px;}
#ta_121{left:287px;bottom:382px;letter-spacing:-0.15px;word-spacing:0.02px;}

.s0_121{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_121{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_121{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts121" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg121" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="121/121.svg"
          type="image/svg+xml"
          id="pdf121"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_121" class="t s0_121">
          121{" "}
        </span>
        <span id="t2_121" class="t s1_121">
          Үнэлгээ бол араас нь хийдэг дүгнэлт бус сургалтын тасралтгүй үйл явц
          юм.{" "}
        </span>
        <span id="t3_121" class="t s1_121">
          Сургалтын хөтөлбөрийн хэрэгжилтийн үнэлгээг хэрхэн хийх вэ?{" "}
        </span>
        <span id="t4_121" class="t s1_121">
          Үнэлгээг хийхдээ суралцахуйн болон үнэлгээний зорилгоос хамаарч{" "}
        </span>
        <span id="t5_121" class="t s1_121">
          ялгаатай алхмуудыг гүйцэтгэх боловч дараах үндсэн үе шатуудыг дамждаг.{" "}
        </span>
        <span id="t6_121" class="t s1_121">
          Үүнд:{" "}
        </span>
        <span id="t7_121" class="t s2_121">
          Зураг 3.17. Үнэлгээний үе шат{" "}
        </span>
        <span id="t8_121" class="t s1_121">
          Дээрх үндсэн алхмын дагуу хөтөлбөрийн хэрэгжилтийг үнэлэх олон{" "}
        </span>
        <span id="t9_121" class="t s1_121">
          хэлбэрийн үнэлгээг бид хийдэг.{" "}
        </span>
        <span id="ta_121" class="t s2_121">
          Зураг 3.18. Үнэлгээний PDCA{" "}
        </span>
      </div>
    </div>
  );
}
