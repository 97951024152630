import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";

export default function SideBar1({ setPaperIndex, paperIndex }) {
  const menu = [
    {
      name: "Сурагч төвтэй сургуулийн менежментийг хэрхэн хөгжүүлэх вэ?",
      paper: 13,
      pages: 1,
    },
    { name: "1.1 Суралцахуйд гарч буй өөрчлөлт", paper: 14, pages: 1 },
    {
      name: "Сурагчид юу хүсэж байна вэ?",
      paper: 16,
      pages: 1,
    },
    {
      name: "Сургууль хэрхэн өөрчлөгдөж байна вэ?",
      paper: 18,
      pages: 1,
      path: 1,
    },
    {
      name: "Багш хэрхэн өөрчлөгдөх вэ? ",
      paper: 21,
      pages: 1,
      path: 1,
    },
    {
      name: "Сургуулийн захирал юу хийх вэ?",
      paper: 23,
      pages: 1,
      path: 1,
    },
    {
      name: "1.2 Сургуулийн менежментийн загвар",
      paper: 25,
      pages: 1,
      path: 1,
    },
    {
      name: "Гурван давхаргат пирамид” загвар",
      paper: 25,
      pages: 1,
      path: 1,
    },
    {
      name: "Богино хугацааны сургуулийн менежементийн зураглал",
      paper: 31,
      pages: 1,
      path: 1,
    },
  ];

  return (
    <List>
      {menu.map((element, index) => (
        <ListItem
          // sx={{
          //   backgroundColor:
          //     paperIndex === element.paper && "rgba(59,130,246,0.5)",
          // }}
          key={index}
          disablePadding
        >
          <a href={`#${element.paper}`} style={{ textDecoration: "none" }}>
            <ListItemButton
              onClick={() => setPaperIndex(element.paper)}
              variant="contained"
            >
              <Grid container spacing={2}>
                <Grid xs={10} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 500,
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={element.name}
                  />
                </Grid>
                <Grid xs={2} item>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "13px",
                      color:
                        paperIndex === element.paper
                          ? "rgb(59, 130, 246)"
                          : "black",
                    }}
                    primary={`(${element.paper})`}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </a>
        </ListItem>
      ))}
    </List>
  );
}
