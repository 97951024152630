import React from "react";
export default function HundredTwentyEight() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_128{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_128{left:343px;bottom:945px;letter-spacing:-0.11px;}
#t3_128{left:434px;bottom:945px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t4_128{left:127px;bottom:550px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t5_128{left:142px;bottom:491px;letter-spacing:0.18px;}
#t6_128{left:87px;bottom:459px;letter-spacing:-0.17px;word-spacing:-0.2px;}
#t7_128{left:87px;bottom:439px;letter-spacing:-0.17px;word-spacing:2.51px;}
#t8_128{left:87px;bottom:419px;letter-spacing:-0.16px;word-spacing:0.26px;}
#t9_128{left:87px;bottom:398px;letter-spacing:-0.15px;word-spacing:0.69px;}
#ta_128{left:87px;bottom:378px;letter-spacing:-0.14px;word-spacing:1.69px;}
#tb_128{left:87px;bottom:358px;letter-spacing:-0.13px;word-spacing:-1.72px;}
#tc_128{left:87px;bottom:338px;letter-spacing:-0.16px;word-spacing:6.54px;}
#td_128{left:87px;bottom:318px;letter-spacing:-0.19px;word-spacing:4.68px;}
#te_128{left:87px;bottom:298px;letter-spacing:-0.16px;word-spacing:1.72px;}
#tf_128{left:87px;bottom:277px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tg_128{left:87px;bottom:244px;letter-spacing:-0.13px;word-spacing:1.9px;}
#th_128{left:87px;bottom:224px;letter-spacing:-0.17px;word-spacing:1.73px;}
#ti_128{left:87px;bottom:204px;letter-spacing:-0.14px;word-spacing:0.28px;}
#tj_128{left:87px;bottom:184px;letter-spacing:-0.14px;word-spacing:-0.99px;}
#tk_128{left:87px;bottom:164px;letter-spacing:-0.12px;word-spacing:2.36px;}
#tl_128{left:87px;bottom:143px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tm_128{left:312px;bottom:152px;letter-spacing:-0.11px;}
#tn_128{left:323px;bottom:143px;}
#to_128{left:87px;bottom:108px;letter-spacing:0.08px;}
#tp_128{left:112px;bottom:107px;letter-spacing:-0.2px;word-spacing:0.07px;}
#tq_128{left:566px;bottom:107px;letter-spacing:-0.12px;}

.s0_128{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_128{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s2_128{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s3_128{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s4_128{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s5_128{font-size:10px;font-family:MogulArial_16s;color:#000;}
.s6_128{font-size:12px;font-family:MogulArial_16s;color:#000;}
.s7_128{font-size:14px;font-family:Arial-ItalicMT_18u;color:#000;}`}
      </style>

      <style id="fonts128" type="text/css">
        {`@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg128" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="128/128.svg"
          type="image/svg+xml"
          id="pdf128"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_128" class="t s0_128">
          128{" "}
        </span>
        <span id="t2_128" class="t s1_128">
          Хүснэгт 3.15.{" "}
        </span>
        <span id="t3_128" class="t s1_128">
          Нэгж хичээлийн даалгавар төлөвлөлт{" "}
        </span>
        <span id="t4_128" class="t s2_128">
          Эх сурвалж:...аймгийн ...–р сургууль, математикийн хичээлийн нэгжийн
          төлөвлөлт{" "}
        </span>
        <span id="t5_128" class="t s3_128">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t6_128" class="t s4_128">
          Сургуулиудад зөвлөн туслах ажлын явцад гарч байгаа нийтлэг асуудал бол{" "}
        </span>
        <span id="t7_128" class="t s4_128">
          БҮТ-өөс боловсруулан 2021 онд батлагдан гарсан “Хичээл, сургалтын үр{" "}
        </span>
        <span id="t8_128" class="t s4_128">
          дүнг үнэлэх шалгуур, түүнийг хэрэглэх зөвлөмж”-ийг төлөвлөлт, хичээл
          үйл{" "}
        </span>
        <span id="t9_128" class="t s4_128">
          ажиллагаандаа тусгаагүй, ойлголтын зөрүүтэй байдал нийтлэг ажиглагдаж{" "}
        </span>
        <span id="ta_128" class="t s4_128">
          байгаа нь багшийн төлөвлөсөн даалгавар үйл ажиллагаа нь хөтөлбөрөөр{" "}
        </span>
        <span id="tb_128" class="t s4_128">
          хүлээгдэж буй үр дүн хүрч буй эсэхэд тухай бүр нь хянах, боломжгүй
          байдалд{" "}
        </span>
        <span id="tc_128" class="t s4_128">
          хүргэдэг. Хяналтгүй тул алдааг нягталж сайжруулалт хийх боломжгүй{" "}
        </span>
        <span id="td_128" class="t s4_128">
          болдог. Иймд удирдлагын зүгээс үнэлгээний шалгуурыг судлах, ойлгох,{" "}
        </span>
        <span id="te_128" class="t s4_128">
          төлөвлөлт, даалгавар, үнэлгээнд тусган хэрэгжүүлэхэд арга зүйн дэмжлэг{" "}
        </span>
        <span id="tf_128" class="t s4_128">
          үзүүлэн ажиллах зайлшгүй шаардлага байна.{" "}
        </span>
        <span id="tg_128" class="t s4_128">
          Сурагчийн үнэлгээ гэдэгт “сурагчийн сурлагын амжилтын түвшин тогтоох,{" "}
        </span>
        <span id="th_128" class="t s4_128">
          мэдлэг, чадвар, хандлага төлөвшлийн ахиц, өөрчлөлтийг бодитой үнэлэх,{" "}
        </span>
        <span id="ti_128" class="t s4_128">
          түүнд тулгарч буй бэрхшээлийг илрүүлж дэмжлэг үзүүлэх, урамшуулах үйл{" "}
        </span>
        <span id="tj_128" class="t s4_128">
          явц”-ыг ойлгоно. Хичээл, сургалтын өдөр тутмын болон тодорхой
          хугацааны{" "}
        </span>
        <span id="tk_128" class="t s4_128">
          /бүлэг сэдэв, хагас жил, жилийн эцэс/ дараа хийдэг бүх төрлийн үнэлгээ{" "}
        </span>
        <span id="tl_128" class="t s4_128">
          “шалгуур”-т суурилсан байна{" "}
        </span>
        <span id="tm_128" class="t s5_128">
          14{" "}
        </span>
        <span id="tn_128" class="t s4_128">
          .{" "}
        </span>
        <span id="to_128" class="t s6_128">
          14{" "}
        </span>
        <span id="tp_128" class="t s7_128">
          Хичээл, сургалтын үр дүнг үнэлэх шалгуурыг хэрэглэх зөвлөмж БҮТ,{" "}
        </span>
        <span id="tq_128" class="t s7_128">
          УБ 2021{" "}
        </span>
      </div>
    </div>
  );
}
