import React from "react";
export default function ThirtyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_31{left:676px;bottom:58px;letter-spacing:-0.09px;}
#t2_31{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_31{left:87px;bottom:939px;letter-spacing:0.16px;word-spacing:0.06px;}
#t4_31{left:87px;bottom:913px;letter-spacing:-0.15px;}
#t5_31{left:87px;bottom:876px;letter-spacing:-0.15px;word-spacing:3.98px;}
#t6_31{left:87px;bottom:855px;letter-spacing:-0.2px;word-spacing:-0.82px;}
#t7_31{left:87px;bottom:835px;letter-spacing:-0.15px;word-spacing:3.02px;}
#t8_31{left:87px;bottom:815px;letter-spacing:-0.14px;word-spacing:0.57px;}
#t9_31{left:87px;bottom:795px;letter-spacing:-0.14px;word-spacing:0.79px;}
#ta_31{left:87px;bottom:775px;letter-spacing:-0.12px;word-spacing:-1.76px;}
#tb_31{left:87px;bottom:755px;letter-spacing:-0.06px;}
#tc_31{left:87px;bottom:714px;letter-spacing:-0.15px;word-spacing:0.42px;}
#td_31{left:87px;bottom:694px;letter-spacing:-0.13px;word-spacing:-1.28px;}
#te_31{left:87px;bottom:674px;letter-spacing:-0.16px;word-spacing:-1.27px;}
#tf_31{left:87px;bottom:654px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tg_31{left:87px;bottom:613px;letter-spacing:-0.15px;word-spacing:2.98px;}
#th_31{left:87px;bottom:593px;letter-spacing:-0.13px;word-spacing:4.26px;}
#ti_31{left:87px;bottom:573px;letter-spacing:-0.15px;word-spacing:1px;}
#tj_31{left:87px;bottom:553px;letter-spacing:-0.14px;word-spacing:1.84px;}
#tk_31{left:87px;bottom:533px;letter-spacing:-0.15px;word-spacing:3.41px;}
#tl_31{left:87px;bottom:513px;letter-spacing:-0.12px;word-spacing:0.03px;}
#tm_31{left:307px;bottom:475px;}
#tn_31{left:318px;bottom:475px;letter-spacing:0.12px;word-spacing:0.02px;}
#to_31{left:145px;bottom:433px;letter-spacing:-0.16px;word-spacing:0.02px;}
#tp_31{left:185px;bottom:415px;letter-spacing:-0.19px;}
#tq_31{left:478px;bottom:442px;letter-spacing:-0.16px;}
#tr_31{left:357px;bottom:420px;letter-spacing:-0.18px;word-spacing:0.05px;}
#ts_31{left:425px;bottom:420px;letter-spacing:-0.06px;}
#tt_31{left:370px;bottom:402px;letter-spacing:-0.18px;}
#tu_31{left:480px;bottom:420px;letter-spacing:-0.17px;word-spacing:0.04px;}
#tv_31{left:531px;bottom:420px;letter-spacing:-0.07px;}
#tw_31{left:482px;bottom:402px;letter-spacing:-0.18px;}
#tx_31{left:595px;bottom:420px;letter-spacing:-0.19px;word-spacing:0.06px;}
#ty_31{left:631px;bottom:420px;letter-spacing:-0.06px;}
#tz_31{left:589px;bottom:402px;letter-spacing:-0.18px;}
#t10_31{left:91px;bottom:353px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t11_31{left:194px;bottom:379px;letter-spacing:-0.23px;}
#t12_31{left:395px;bottom:376px;letter-spacing:-0.14px;}
#t13_31{left:507px;bottom:376px;letter-spacing:-0.14px;}
#t14_31{left:615px;bottom:376px;letter-spacing:-0.14px;}
#t15_31{left:194px;bottom:353px;letter-spacing:-0.01px;}
#t16_31{left:395px;bottom:350px;letter-spacing:-0.14px;}
#t17_31{left:507px;bottom:350px;letter-spacing:-0.14px;}
#t18_31{left:615px;bottom:350px;letter-spacing:-0.14px;}
#t19_31{left:194px;bottom:327px;letter-spacing:-0.14px;}
#t1a_31{left:395px;bottom:324px;letter-spacing:-0.14px;}
#t1b_31{left:511px;bottom:324px;}
#t1c_31{left:618px;bottom:324px;}
#t1d_31{left:91px;bottom:275px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1e_31{left:194px;bottom:301px;letter-spacing:-0.23px;}
#t1f_31{left:387px;bottom:298px;letter-spacing:-0.14px;}
#t1g_31{left:504px;bottom:298px;letter-spacing:-0.14px;}
#t1h_31{left:611px;bottom:298px;letter-spacing:-0.14px;}
#t1i_31{left:194px;bottom:275px;letter-spacing:-0.18px;}
#t1j_31{left:387px;bottom:272px;letter-spacing:-0.14px;}
#t1k_31{left:504px;bottom:272px;letter-spacing:-0.14px;}
#t1l_31{left:611px;bottom:272px;letter-spacing:-0.14px;}
#t1m_31{left:194px;bottom:249px;letter-spacing:-0.13px;}
#t1n_31{left:387px;bottom:246px;letter-spacing:-0.14px;}
#t1o_31{left:504px;bottom:246px;letter-spacing:-0.14px;}
#t1p_31{left:611px;bottom:246px;letter-spacing:-0.14px;}
#t1q_31{left:91px;bottom:197px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1r_31{left:194px;bottom:223px;letter-spacing:-0.14px;}
#t1s_31{left:391px;bottom:220px;letter-spacing:-0.14px;}
#t1t_31{left:507px;bottom:220px;letter-spacing:-0.14px;}
#t1u_31{left:615px;bottom:220px;letter-spacing:-0.14px;}
#t1v_31{left:194px;bottom:197px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1w_31{left:399px;bottom:194px;}
#t1x_31{left:511px;bottom:194px;}
#t1y_31{left:618px;bottom:194px;}
#t1z_31{left:194px;bottom:171px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t20_31{left:395px;bottom:168px;letter-spacing:-0.14px;}
#t21_31{left:511px;bottom:168px;}
#t22_31{left:618px;bottom:168px;}

.s0_31{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_31{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_31{font-size:18px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_31{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_31{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s5_31{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_31{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}
.s7_31{font-size:14px;font-family:MogulArial_16s;color:#000;}
.t.m0_31{transform:matrix(-1,0,0,1,0,0);}`}
      </style>
      <style id="fonts31" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg31" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="31/31.svg"
          type="image/svg+xml"
          id="pdf31"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_31" class="t s1_31">
          31{" "}
        </span>
        <span id="t3_31" class="t s2_31">
          БОГИНО ХУГАЦААНЫ СУРГУУЛИЙН МЕНЕЖЕМЕНТИЙН{" "}
        </span>
        <span id="t4_31" class="t s2_31">
          ЗУРАГЛАЛ{" "}
        </span>
        <span id="t5_31" class="t s3_31">
          Үнэлгээний үр дүн буюу бодит байдал нь сургууль юуг хэрхэн өөрчлөх{" "}
        </span>
        <span id="t6_31" class="t s3_31">
          шаардлагатай болохыг тодорхой болгож өгдөг. Өмнөх бүлэгт менежментийн{" "}
        </span>
        <span id="t7_31" class="t s3_31">
          гурван давхаргат пирамид загварт үнэлгээг сургуулийг хувирган өөрчлөх{" "}
        </span>
        <span id="t8_31" class="t s3_31">
          үйл явцын эхлэл цэг гэж авч үзсэн ба ялангуяа, сурагчдын өмнө
          тулгарсан{" "}
        </span>
        <span id="t9_31" class="t s3_31">
          асуудлыг илрүүлж, засаж залруулахаас гадна жилийн төлөвлөлтөд тусгах,{" "}
        </span>
        <span id="ta_31" class="t s3_31">
          түүнийгээ хэрэгжүүлэх, дахин үнэлж сайжруулах тасралтгүй үйл явц
          болохыг{" "}
        </span>
        <span id="tb_31" class="t s3_31">
          үзүүлсэн.{" "}
        </span>
        <span id="tc_31" class="t s3_31">
          Сургуулиуд хөндлөнгийн үнэлгээ, тухайлбал, байгууллагын болон багшийн{" "}
        </span>
        <span id="td_31" class="t s3_31">
          гүйцэтгэлийн үнэлгээний үр дүнд шинжилгээ хийж сурагчдынхаа тулгамдсан{" "}
        </span>
        <span id="te_31" class="t s3_31">
          асуудал, бодит байдлыг хэрхэн тодорхойлох талаар зөвлөмжийг хоёрдугаар{" "}
        </span>
        <span id="tf_31" class="t s3_31">
          бүлэгт дэлгэрэнгүй танилцуулна.{" "}
        </span>
        <span id="tg_31" class="t s3_31">
          Байгууллагын гүйцэтгэлийн үнэлгээг сургуулиудын байршлаар А кластер{" "}
        </span>
        <span id="th_31" class="t s3_31">
          буюу нийслэлийн, Б кластер буюу аймгийн, В кластер сум, багийн гэж{" "}
        </span>
        <span id="ti_31" class="t s3_31">
          ангилан кластер дотор нь эрэмбэлснийг та бүхэн мэднэ. Сургуулиуд газар{" "}
        </span>
        <span id="tj_31" class="t s3_31">
          зүйн байршлаас гадна, багш, сурагчдын тоо, материаллаг орчин, нөхцөл,{" "}
        </span>
        <span id="tk_31" class="t s3_31">
          удирдлагын бүтцийн хувьд харилцан адилгүй байгааг гурван сургуулийн{" "}
        </span>
        <span id="tl_31" class="t s3_31">
          жишээгээр хүснэгтээр харууллаа.{" "}
        </span>
        <span id="tm_31" class="t s4_31">
          Х
        </span>
        <span id="tn_31" class="t s5_31">
          үснэгт 1.2. Сургуулиуд ямар ялгаатай байна вэ?{" "}
        </span>
        <span id="to_31" class="t s6_31">
          Сургуулийн ерөнхий{" "}
        </span>
        <span id="tp_31" class="t s6_31">
          үзүүлэлт{" "}
        </span>
        <span id="tq_31" class="t s6_31">
          Байршил{" "}
        </span>
        <span id="tr_31" class="t s6_31">
          Нийслэл (
        </span>
        <span id="ts_31" class="t s6_31">
          ......{" "}
        </span>
        <span id="tt_31" class="t s6_31">
          сургууль){" "}
        </span>
        <span id="tu_31" class="t s6_31">
          Аймаг (
        </span>
        <span id="tv_31" class="t s6_31">
          .....{" "}
        </span>
        <span id="tw_31" class="t s6_31">
          сургууль){" "}
        </span>
        <span id="tx_31" class="t s6_31">
          Сум (
        </span>
        <span id="ty_31" class="t s6_31">
          .....{" "}
        </span>
        <span id="tz_31" class="t s6_31">
          сургууль){" "}
        </span>
        <span id="t10_31" class="t s7_31">
          Бүлгийн тоо{" "}
        </span>
        <span id="t11_31" class="t s7_31">
          Бага{" "}
        </span>
        <span id="t12_31" class="t s7_31">
          85{" "}
        </span>
        <span id="t13_31" class="t s7_31">
          17{" "}
        </span>
        <span id="t14_31" class="t s7_31">
          15{" "}
        </span>
        <span id="t15_31" class="t s7_31">
          Дунд{" "}
        </span>
        <span id="t16_31" class="t s7_31">
          56{" "}
        </span>
        <span id="t17_31" class="t s7_31">
          10{" "}
        </span>
        <span id="t18_31" class="t s7_31">
          12{" "}
        </span>
        <span id="t19_31" class="t s7_31">
          Ахлах{" "}
        </span>
        <span id="t1a_31" class="t s7_31">
          44{" "}
        </span>
        <span id="t1b_31" class="t s7_31">
          5{" "}
        </span>
        <span id="t1c_31" class="t s7_31">
          7{" "}
        </span>
        <span id="t1d_31" class="t s7_31">
          Хүүхдийн тоо{" "}
        </span>
        <span id="t1e_31" class="t s7_31">
          Бага{" "}
        </span>
        <span id="t1f_31" class="t s7_31">
          3200{" "}
        </span>
        <span id="t1g_31" class="t s7_31">
          654{" "}
        </span>
        <span id="t1h_31" class="t s7_31">
          450{" "}
        </span>
        <span id="t1i_31" class="t s7_31">
          Суурь{" "}
        </span>
        <span id="t1j_31" class="t s7_31">
          1800{" "}
        </span>
        <span id="t1k_31" class="t s7_31">
          264{" "}
        </span>
        <span id="t1l_31" class="t s7_31">
          250{" "}
        </span>
        <span id="t1m_31" class="t s7_31">
          Бүрэн дунд{" "}
        </span>
        <span id="t1n_31" class="t s7_31">
          1200{" "}
        </span>
        <span id="t1o_31" class="t s7_31">
          123{" "}
        </span>
        <span id="t1p_31" class="t s7_31">
          150{" "}
        </span>
        <span id="t1q_31" class="t s7_31">
          Багшийн тоо{" "}
        </span>
        <span id="t1r_31" class="t s7_31">
          Нийт{" "}
        </span>
        <span id="t1s_31" class="t s7_31">
          250{" "}
        </span>
        <span id="t1t_31" class="t s7_31">
          49{" "}
        </span>
        <span id="t1u_31" class="t s7_31">
          46{" "}
        </span>
        <span id="t1v_31" class="t s7_31">
          ЗАН-ийн тоо{" "}
        </span>
        <span id="t1w_31" class="t s7_31">
          8{" "}
        </span>
        <span id="t1x_31" class="t s7_31">
          5{" "}
        </span>
        <span id="t1y_31" class="t s7_31">
          4{" "}
        </span>
        <span id="t1z_31" class="t s7_31">
          МХБ-ийн тоо{" "}
        </span>
        <span id="t20_31" class="t s7_31">
          12{" "}
        </span>
        <span id="t21_31" class="t s7_31">
          8{" "}
        </span>
        <span id="t22_31" class="t s7_31">
          4{" "}
        </span>
      </div>
    </div>
  );
}
