import React from "react";
export default function OneHundredEightyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_182{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_182{left:106px;bottom:919px;letter-spacing:0.16px;word-spacing:0.82px;}
#t3_182{left:183px;bottom:919px;letter-spacing:0.1px;word-spacing:0.86px;}
#t4_182{left:106px;bottom:900px;letter-spacing:0.12px;word-spacing:5.1px;}
#t5_182{left:106px;bottom:882px;letter-spacing:0.13px;word-spacing:7.64px;}
#t6_182{left:106px;bottom:864px;letter-spacing:0.1px;word-spacing:6.31px;}
#t7_182{left:106px;bottom:845px;letter-spacing:0.07px;word-spacing:0.57px;}
#t8_182{left:106px;bottom:827px;letter-spacing:0.14px;word-spacing:5.07px;}
#t9_182{left:106px;bottom:809px;letter-spacing:0.1px;word-spacing:0.22px;}
#ta_182{left:487px;bottom:809px;letter-spacing:0.09px;word-spacing:0.22px;}
#tb_182{left:106px;bottom:790px;letter-spacing:0.06px;word-spacing:3.57px;}
#tc_182{left:352px;bottom:790px;letter-spacing:0.14px;word-spacing:3.5px;}
#td_182{left:106px;bottom:772px;letter-spacing:0.11px;word-spacing:1.39px;}
#te_182{left:106px;bottom:754px;letter-spacing:0.13px;word-spacing:0.08px;}
#tf_182{left:216px;bottom:754px;letter-spacing:0.11px;word-spacing:0.06px;}
#tg_182{left:274px;bottom:754px;letter-spacing:0.1px;word-spacing:0.1px;}
#th_182{left:106px;bottom:735px;letter-spacing:0.1px;word-spacing:4.47px;}
#ti_182{left:106px;bottom:717px;letter-spacing:0.1px;word-spacing:-0.27px;}
#tj_182{left:572px;bottom:717px;letter-spacing:0.05px;word-spacing:-0.24px;}
#tk_182{left:106px;bottom:699px;letter-spacing:0.11px;word-spacing:4.09px;}
#tl_182{left:279px;bottom:699px;letter-spacing:0.09px;word-spacing:4.11px;}
#tm_182{left:106px;bottom:680px;letter-spacing:0.07px;word-spacing:-0.41px;}
#tn_182{left:106px;bottom:662px;letter-spacing:0.13px;word-spacing:0.02px;}
#to_182{left:349px;bottom:662px;letter-spacing:0.1px;word-spacing:0.04px;}
#tp_182{left:187px;bottom:633px;letter-spacing:-0.14px;word-spacing:0.03px;}
#tq_182{left:480px;bottom:633px;letter-spacing:-0.15px;word-spacing:0.02px;}
#tr_182{left:429px;bottom:615px;letter-spacing:-0.16px;word-spacing:0.03px;}
#ts_182{left:106px;bottom:531px;letter-spacing:0.17px;word-spacing:3.36px;}
#tt_182{left:188px;bottom:531px;letter-spacing:0.14px;word-spacing:3.35px;}
#tu_182{left:272px;bottom:531px;letter-spacing:0.1px;word-spacing:3.38px;}
#tv_182{left:106px;bottom:512px;letter-spacing:0.12px;word-spacing:0.96px;}
#tw_182{left:272px;bottom:512px;letter-spacing:0.14px;word-spacing:0.97px;}
#tx_182{left:463px;bottom:512px;letter-spacing:0.09px;word-spacing:1px;}
#ty_182{left:106px;bottom:494px;letter-spacing:0.08px;word-spacing:-0.09px;}
#tz_182{left:458px;bottom:494px;letter-spacing:0.12px;word-spacing:-0.12px;}
#t10_182{left:106px;bottom:476px;letter-spacing:0.07px;word-spacing:3.35px;}
#t11_182{left:298px;bottom:476px;letter-spacing:0.05px;word-spacing:3.37px;}
#t12_182{left:106px;bottom:457px;letter-spacing:0.12px;word-spacing:3.26px;}
#t13_182{left:392px;bottom:457px;letter-spacing:0.07px;word-spacing:3.3px;}
#t14_182{left:106px;bottom:439px;letter-spacing:0.12px;word-spacing:1.72px;}
#t15_182{left:106px;bottom:421px;letter-spacing:0.1px;word-spacing:2.17px;}
#t16_182{left:106px;bottom:402px;letter-spacing:0.11px;word-spacing:0.29px;}
#t17_182{left:106px;bottom:384px;letter-spacing:0.1px;word-spacing:0.04px;}
#t18_182{left:106px;bottom:353px;letter-spacing:0.13px;word-spacing:0.93px;}
#t19_182{left:219px;bottom:353px;letter-spacing:0.1px;word-spacing:0.97px;}
#t1a_182{left:106px;bottom:334px;letter-spacing:0.1px;word-spacing:1.28px;}
#t1b_182{left:106px;bottom:316px;letter-spacing:0.12px;}
#t1c_182{left:149px;bottom:316px;letter-spacing:0.1px;word-spacing:-0.18px;}
#t1d_182{left:106px;bottom:298px;letter-spacing:0.07px;}
#t1e_182{left:173px;bottom:298px;letter-spacing:0.11px;word-spacing:3.01px;}
#t1f_182{left:106px;bottom:279px;letter-spacing:0.08px;word-spacing:0.06px;}
#t1g_182{left:158px;bottom:257px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1h_182{left:480px;bottom:257px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1i_182{left:429px;bottom:239px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_182{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_182{font-size:15px;font-family:MogulArial-Bold_179;color:#202124;}
.s2_182{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s3_182{font-size:15px;font-family:MogulArial-Italic_17h;color:#202124;}
.s4_182{font-size:14px;font-family:MogulArial-Italic_17h;color:#202124;}
.s5_182{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}
.s6_182{font-size:15px;font-family:MogulArial-BoldItalic_17m;color:#202124;}`}
      </style>

      <style id="fonts182" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg182" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="182/182.svg"
          type="image/svg+xml"
          id="pdf182"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_182" class="t s0_182">
          182{" "}
        </span>
        <span id="t2_182" class="t s1_182">
          Жишээ 2:{" "}
        </span>
        <span id="t3_182" class="t s2_182">
          Сургуулийн нийт багш нарын дунд “Сурах бичгээ судалж дуусгая”{" "}
        </span>
        <span id="t4_182" class="t s2_182">
          аяныг өрнүүлж, сургалтын хөтөлбөр, суралцахуйн удирдамж, үнэлгээний{" "}
        </span>
        <span id="t5_182" class="t s2_182">
          шалгуур, зөвлөмжийг судлах, өөрийн заадаг ангийнхаа сурах бичгийн{" "}
        </span>
        <span id="t6_182" class="t s2_182">
          дасгал даалгаврыг бодох, хийж гүйцэтгэх календарьчилсан төлөвлөгөөг{" "}
        </span>
        <span id="t7_182" class="t s2_182">
          гарган, сургуулийн захирлаар батлуулж, багш нар нь ажиллаж эхлээд
          байна.{" "}
        </span>
        <span id="t8_182" class="t s2_182">
          Мэргэжлийн багш нарын хамгийн эхнийх 2023 оны 5 дугаар сараас 10{" "}
        </span>
        <span id="t9_182" class="t s2_182">
          дугаар сарын хооронд дуусах бол сүүлийн багш нар{" "}
        </span>
        <span id="ta_182" class="t s2_182">
          2024 оны 3 дугаар сард{" "}
        </span>
        <span id="tb_182" class="t s2_182">
          дуусгахаар зураглалаа гаргасан.{" "}
        </span>
        <span id="tc_182" class="t s3_182">
          (Үүнд: Матемаикийн багш нар 6 дугаар{" "}
        </span>
        <span id="td_182" class="t s3_182">
          ангиас 12 дугаар анги хүртэлх 7 сурах бичгийг бодож дуусгах бол биеийн{" "}
        </span>
        <span id="te_182" class="t s3_182">
          тамрын багш{" "}
        </span>
        <span id="tf_182" class="t s3_182">
          1-ээс 5{" "}
        </span>
        <span id="tg_182" class="t s3_182">
          дугаар ангид хичээл заадаг бол дасгал бүрийг өөрөө{" "}
        </span>
        <span id="th_182" class="t s3_182">
          хийж, гарын жижиг булчинг хэрхэн хөгжүүлэх вэ? гэдгээс эрүүл мэндээ{" "}
        </span>
        <span id="ti_182" class="t s3_182">
          хамгаалах энгийн дадал хэвшлийг хэрхэн төлөвшүүлэх талаар{" "}
        </span>
        <span id="tj_182" class="t s3_182">
          нэг бүрчлэн{" "}
        </span>
        <span id="tk_182" class="t s3_182">
          хий\ж тэмдэглэх юм).{" "}
        </span>
        <span id="tl_182" class="t s2_182">
          Тухайн сургуулийн хувьд 2024 оны 3 дугаар сард{" "}
        </span>
        <span id="tm_182" class="t s1_182">
          “Зааж байгаа хичээлдээ онолын болон заах арга зүйн чиглэлээр мэдлэг,{" "}
        </span>
        <span id="tn_182" class="t s1_182">
          чадвар дутмаг багшгүй болно.”{" "}
        </span>
        <span id="to_182" class="t s2_182">
          гэж зорьж байна.{" "}
        </span>
        <span id="tp_182" class="t s4_182">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="tq_182" class="t s5_182">
          сургуулийн менежментийг{" "}
        </span>
        <span id="tr_182" class="t s5_182">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
        <span id="ts_182" class="t s1_182">
          Жишээ 3:{" "}
        </span>
        <span id="tt_182" class="t s2_182">
          Багш нар{" "}
        </span>
        <span id="tu_182" class="t s2_182">
          хүүхэд бүрийг хөгжүүлэх бодитой, үр дүн гаргахуйц{" "}
        </span>
        <span id="tv_182" class="t s2_182">
          шийдлийг эрэлхийлж,{" "}
        </span>
        <span id="tw_182" class="t s6_182">
          “Нэг баг–Нэг арга зүй”{" "}
        </span>
        <span id="tx_182" class="t s2_182">
          хичээлийн сайн технологи,{" "}
        </span>
        <span id="ty_182" class="t s2_182">
          багийн хичээлийг санаачлан зохион байгууллаа.{" "}
        </span>
        <span id="tz_182" class="t s2_182">
          Энэ нь багш нар сонирхлын{" "}
        </span>
        <span id="t10_182" class="t s2_182">
          бүлгээр багт хуваагдаж,{" "}
        </span>
        <span id="t11_182" class="t s2_182">
          багууд хичээл бүр дээр нэг, нэг шинэ арга зүйн{" "}
        </span>
        <span id="t12_182" class="t s2_182">
          санааг дэвшүүлж, хичээлээ бэлтгэнэ{" "}
        </span>
        <span id="t13_182" class="t s2_182">
          гэсэн үг. Бэлтгэсэн хичээлүүдээ нэг{" "}
        </span>
        <span id="t14_182" class="t s2_182">
          долоо хоногийн хугацаанд зааж дуусаад, энэ долоо хоногт ямар хичээлүүд{" "}
        </span>
        <span id="t15_182" class="t s2_182">
          дээр ямар үр дүн гарав, юуг сайжруулах шаардлагатай байв, цаашид арга{" "}
        </span>
        <span id="t16_182" class="t s2_182">
          зүйн санааг хэрхэн баяжуулж, сурагчдын идэвхи оролцоог сайжруулах вэ
          гэх{" "}
        </span>
        <span id="t17_182" class="t s2_182">
          мэтээр хэлэлцүүлэг өрнүүлдэг болсон байна.{" "}
        </span>
        <span id="t18_182" class="t s2_182">
          Үүний үр дүнд{" "}
        </span>
        <span id="t19_182" class="t s2_182">
          хичээлийн арга зүйн санаа баяжиж, агуулгын давхардал, нэг{" "}
        </span>
        <span id="t1a_182" class="t s2_182">
          хүүхдэд очих олон ижил төрлийн даалгаврын ачаалал багасч, хүүхдүүд бие{" "}
        </span>
        <span id="t1b_182" class="t s2_182">
          даан{" "}
        </span>
        <span id="t1c_182" class="t s2_182">
          сурах арга барилд суралцах, багш нар бие биенээсээ туршлага судлах,{" "}
        </span>
        <span id="t1d_182" class="t s2_182">
          улмаар{" "}
        </span>
        <span id="t1e_182" class="t s2_182">
          хамт олны дунд эерэг уур амьсгал бий болсон зэрэг олон давуу{" "}
        </span>
        <span id="t1f_182" class="t s2_182">
          талууд бий болсон.{" "}
        </span>
        <span id="t1g_182" class="t s3_182">
          ... аймгийн ... сумын ерөнхий боловсролын ...
        </span>
        <span id="t1h_182" class="t s5_182">
          сургуулийн менежментийг{" "}
        </span>
        <span id="t1i_182" class="t s5_182">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
