import React from "react";
export default function OneHundredEightyOne() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_181{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_181{left:165px;bottom:941px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t3_181{left:143px;bottom:921px;}
#t4_181{left:165px;bottom:921px;letter-spacing:-0.33px;word-spacing:5.28px;}
#t5_181{left:165px;bottom:901px;letter-spacing:-0.31px;word-spacing:4.31px;}
#t6_181{left:165px;bottom:880px;letter-spacing:-0.29px;word-spacing:-2px;}
#t7_181{left:143px;bottom:860px;}
#t8_181{left:165px;bottom:860px;letter-spacing:-0.11px;}
#t9_181{left:257px;bottom:860px;letter-spacing:-0.1px;}
#ta_181{left:315px;bottom:860px;letter-spacing:-0.08px;}
#tb_181{left:360px;bottom:860px;letter-spacing:-0.08px;}
#tc_181{left:418px;bottom:860px;letter-spacing:-0.13px;}
#td_181{left:501px;bottom:860px;letter-spacing:-0.1px;}
#te_181{left:588px;bottom:860px;letter-spacing:-0.1px;}
#tf_181{left:165px;bottom:840px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tg_181{left:147px;bottom:779px;letter-spacing:0.11px;word-spacing:0.12px;}
#th_181{left:106px;bottom:714px;letter-spacing:0.16px;word-spacing:-0.66px;}
#ti_181{left:180px;bottom:714px;letter-spacing:0.09px;word-spacing:-0.61px;}
#tj_181{left:106px;bottom:696px;letter-spacing:0.1px;word-spacing:1.9px;}
#tk_181{left:106px;bottom:678px;letter-spacing:0.1px;word-spacing:0.03px;}
#tl_181{left:106px;bottom:659px;letter-spacing:0.11px;word-spacing:-0.89px;}
#tm_181{left:106px;bottom:641px;letter-spacing:0.09px;word-spacing:0.04px;}
#tn_181{left:141px;bottom:619px;letter-spacing:0.05px;word-spacing:1.36px;}
#to_181{left:141px;bottom:596px;letter-spacing:0.11px;word-spacing:-0.56px;}
#tp_181{left:447px;bottom:596px;letter-spacing:0.11px;word-spacing:-1.65px;}
#tq_181{left:158px;bottom:578px;letter-spacing:0.07px;}
#tr_181{left:106px;bottom:555px;letter-spacing:0.12px;word-spacing:9.47px;}
#ts_181{left:106px;bottom:537px;letter-spacing:0.11px;word-spacing:-1.34px;}
#tt_181{left:106px;bottom:518px;letter-spacing:0.11px;word-spacing:4.92px;}
#tu_181{left:106px;bottom:500px;letter-spacing:0.11px;word-spacing:4.66px;}
#tv_181{left:106px;bottom:482px;letter-spacing:0.11px;}
#tw_181{left:106px;bottom:459px;letter-spacing:0.1px;word-spacing:-0.97px;}
#tx_181{left:106px;bottom:441px;letter-spacing:0.1px;word-spacing:0.03px;}
#ty_181{left:141px;bottom:418px;letter-spacing:0.13px;word-spacing:1.3px;}
#tz_181{left:141px;bottom:395px;letter-spacing:0.13px;word-spacing:0.78px;}
#t10_181{left:141px;bottom:373px;letter-spacing:0.13px;word-spacing:2.11px;}
#t11_181{left:506px;bottom:373px;letter-spacing:0.07px;word-spacing:1.81px;}
#t12_181{left:158px;bottom:354px;letter-spacing:0.13px;word-spacing:5.24px;}
#t13_181{left:158px;bottom:336px;letter-spacing:0.12px;word-spacing:2.24px;}
#t14_181{left:158px;bottom:318px;letter-spacing:0.13px;}
#t15_181{left:234px;bottom:318px;letter-spacing:0.07px;}
#t16_181{left:330px;bottom:318px;letter-spacing:0.13px;}
#t17_181{left:414px;bottom:318px;letter-spacing:0.09px;}
#t18_181{left:524px;bottom:318px;letter-spacing:0.09px;}
#t19_181{left:608px;bottom:318px;letter-spacing:-0.01px;}
#t1a_181{left:158px;bottom:299px;letter-spacing:0.08px;word-spacing:1.12px;}
#t1b_181{left:158px;bottom:281px;letter-spacing:0.1px;word-spacing:0.04px;}
#t1c_181{left:187px;bottom:252px;letter-spacing:-0.14px;word-spacing:0.03px;}
#t1d_181{left:480px;bottom:252px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1e_181{left:429px;bottom:234px;letter-spacing:-0.16px;word-spacing:0.03px;}

.s0_181{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_181{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_181{font-size:17px;font-family:MogulArial_18b;color:#000;}
.s3_181{font-size:17px;font-family:MogulArial-Italic_13p;color:#000;}
.s4_181{font-size:17px;font-family:MogulArial-Italic_17h;color:#000;}
.s5_181{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s6_181{font-size:15px;font-family:MogulArial-Bold_179;color:#202124;}
.s7_181{font-size:15px;font-family:MogulArial_16s;color:#202124;}
.s8_181{font-size:15px;font-family:MogulArial_18b;color:#202124;}
.s9_181{font-size:14px;font-family:MogulArial-Italic_17h;color:#202124;}
.sa_181{font-size:14px;font-family:MogulArial-Italic_17h;color:#000;}`}
      </style>

      <style id="fonts181" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_13p;
	src: url("fonts/MogulArial-Italic_13p.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulArial_18b;
	src: url("fonts/MogulArial_18b.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg181" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="181/181.svg"
          type="image/svg+xml"
          id="pdf181"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_181" class="t s0_181">
          181{" "}
        </span>
        <span id="t2_181" class="t s1_181">
          бие биеэ харилцан зөвшөөрөхгүй байх хандлагатай.{" "}
        </span>
        <span id="t3_181" class="t s2_181">
          ●{" "}
        </span>
        <span id="t4_181" class="t s1_181">
          Сургалтын аргуудыг оновчтой сонгох, хэрэглэх, анги сурагчтай{" "}
        </span>
        <span id="t5_181" class="t s1_181">
          ажиллах үйл ажиллагааг нэгдсэн бодлого, арга зүйгээр хангах,{" "}
        </span>
        <span id="t6_181" class="t s1_181">
          дэмжлэг үзүүлэх ажилд чиглүүлэх, зөвлөн туслах байдал хангалтгүй{" "}
        </span>
        <span id="t7_181" class="t s3_181">
          ●{" "}
        </span>
        <span id="t8_181" class="t s4_181">
          сурагчид,{" "}
        </span>
        <span id="t9_181" class="t s4_181">
          багш{" "}
        </span>
        <span id="ta_181" class="t s4_181">
          нар{" "}
        </span>
        <span id="tb_181" class="t s4_181">
          анги,{" "}
        </span>
        <span id="tc_181" class="t s4_181">
          танхим,{" "}
        </span>
        <span id="td_181" class="t s4_181">
          нийтийн{" "}
        </span>
        <span id="te_181" class="t s4_181">
          эзэмшлийн{" "}
        </span>
        <span id="tf_181" class="t s4_181">
          үйлчилгээнд анхаарал хандуулахдаа жигд биш{" "}
        </span>
        <span id="tg_181" class="t s5_181">
          ШИЙДВЭРЛЭЖ БУЙ ЖИШЭЭ{" "}
        </span>
        <span id="th_181" class="t s6_181">
          Жишээ 1:{" "}
        </span>
        <span id="ti_181" class="t s7_181">
          Сургуулийн захирлын санаачилгаар сургуулийн нийт сурагчид сард{" "}
        </span>
        <span id="tj_181" class="t s7_181">
          1 удаа, багш ажилчид улиралд 1-2 удаа амралтын өдрөөр сургуулийн анги{" "}
        </span>
        <span id="tk_181" class="t s7_181">
          танхим, өрөө, тасалгааг цэвэрлэх, үйлчилгээний ажилчдынхаа ажилд
          туслах,{" "}
        </span>
        <span id="tl_181" class="t s7_181">
          эцэг эх, асран хамгаалагч, харгалзан дэмжигч, сурагчдад зөв үлгэр
          дуурайлал{" "}
        </span>
        <span id="tm_181" class="t s7_181">
          үзүүлэх зорилгоор{" "}
        </span>
        <span id="tn_181" class="t s8_181">
          ● “Тоосгүй гутал” аян{" "}
        </span>
        <span id="to_181" class="t s8_181">
          ● “Хөөсний баяр” үйл ажиллагааг эхлүүлж,{" "}
        </span>
        <span id="tp_181" class="t s8_181">
          уламжлал болгон хэвшүүлсэн{" "}
        </span>
        <span id="tq_181" class="t s7_181">
          байна{" "}
        </span>
        <span id="tr_181" class="t s7_181">
          Сургуулийн захирал, хамт олонтойгоо анги, танхим, өрөө, тасалгааг{" "}
        </span>
        <span id="ts_181" class="t s7_181">
          цэвэрлэснээр, анги танхим нь эмх цэгцтэй, цэвэр, ая тухтай орчинд
          ажиллахад{" "}
        </span>
        <span id="tt_181" class="t s7_181">
          ихээхэн дэмжлэг үзүүлсэн. (Сургуулийн хаалгаар ороход цагаан өнгөтэй{" "}
        </span>
        <span id="tu_181" class="t s7_181">
          шалны алчуур угтдаг нь сургуулийн орчин ямар цэвэр цэмцгэр болохыг{" "}
        </span>
        <span id="tv_181" class="t s7_181">
          илэрхийлнэ).{" "}
        </span>
        <span id="tw_181" class="t s7_181">
          Хүүхдүүд хичээлийн бус цагаар коридор, буланд байршуулсан ширээ
          сандалд{" "}
        </span>
        <span id="tx_181" class="t s7_181">
          сууж, хичээлээ давтахдаа:{" "}
        </span>
        <span id="ty_181" class="t s8_181">
          ● Хүний хөдөлмөрийг хүндэтгэх{" "}
        </span>
        <span id="tz_181" class="t s8_181">
          ● Дүрэамт хувцас, гутлаа цэвэрхэн өмсөх{" "}
        </span>
        <span id="t10_181" class="t s8_181">
          ● Багш нараас зөв үлгэр дуурайлалд суралцдаг{" "}
        </span>
        <span id="t11_181" class="t s8_181">
          бол сургуулийн хамт{" "}
        </span>
        <span id="t12_181" class="t s7_181">
          олны уур амьсгалд эерэг дулаан харилцааг төлөвшүүлж чаджээ.{" "}
        </span>
        <span id="t13_181" class="t s7_181">
          Мөн энэхүү үйл ажиллагааг түгээн дэлгэрүүлэх хүрээнд Баянхонгор{" "}
        </span>
        <span id="t14_181" class="t s7_181">
          аймгийн{" "}
        </span>
        <span id="t15_181" class="t s7_181">
          сургуулийн{" "}
        </span>
        <span id="t16_181" class="t s7_181">
          нийгмийн{" "}
        </span>
        <span id="t17_181" class="t s7_181">
          ажилтнуудад{" "}
        </span>
        <span id="t18_181" class="t s7_181">
          туршлага{" "}
        </span>
        <span id="t19_181" class="t s7_181">
          болгон{" "}
        </span>
        <span id="t1a_181" class="t s7_181">
          танилцуулсанд тэд талархан хүлээж авсан бөгөөд Эрдэнэцогт, Баян-{" "}
        </span>
        <span id="t1b_181" class="t s7_181">
          Овоо зэрэг сумдын сургуулиуд хэрэгжүүлсэн байна.{" "}
        </span>
        <span id="t1c_181" class="t s9_181">
          ... аймгийн ... сумын ерөнхий боловсролын ...{" "}
        </span>
        <span id="t1d_181" class="t sa_181">
          сургуулийн менежментийг{" "}
        </span>
        <span id="t1e_181" class="t sa_181">
          сайжруулах хүрээнд хийсэн ажлаас{" "}
        </span>
      </div>
    </div>
  );
}
