import React from "react";
export default function OneHundredSixtyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_165{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_165{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:2.76px;}
#t3_165{left:87px;bottom:921px;letter-spacing:-0.2px;word-spacing:-1.59px;}
#t4_165{left:87px;bottom:901px;letter-spacing:-0.14px;word-spacing:3.55px;}
#t5_165{left:87px;bottom:880px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t6_165{left:87px;bottom:841px;letter-spacing:0.18px;word-spacing:3.89px;}
#t7_165{left:87px;bottom:821px;letter-spacing:0.12px;word-spacing:0.06px;}
#t8_165{left:87px;bottom:788px;letter-spacing:-0.15px;word-spacing:5.46px;}
#t9_165{left:87px;bottom:768px;letter-spacing:-0.13px;word-spacing:0.21px;}
#ta_165{left:87px;bottom:748px;letter-spacing:-0.14px;word-spacing:0.06px;}
#tb_165{left:87px;bottom:728px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tc_165{left:87px;bottom:695px;letter-spacing:-0.17px;word-spacing:-1.99px;}
#td_165{left:87px;bottom:675px;letter-spacing:-0.15px;word-spacing:4.27px;}
#te_165{left:87px;bottom:654px;letter-spacing:-0.14px;word-spacing:0.05px;}
#tf_165{left:87px;bottom:621px;letter-spacing:-0.14px;word-spacing:6.07px;}
#tg_165{left:87px;bottom:601px;letter-spacing:-0.16px;word-spacing:1.67px;}
#th_165{left:87px;bottom:581px;letter-spacing:-0.15px;word-spacing:0.64px;}
#ti_165{left:87px;bottom:561px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_165{left:122px;bottom:523px;letter-spacing:0.1px;word-spacing:0.03px;}
#tk_165{left:313px;bottom:505px;letter-spacing:0.11px;word-spacing:0.05px;}

.s0_165{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_165{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_165{font-size:18px;font-family:MogulArial-Bold_179;color:#F7941E;}
.s3_165{font-size:15px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts165" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg165" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="165/165.svg"
          type="image/svg+xml"
          id="pdf165"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_165" class="t s0_165">
          165{" "}
        </span>
        <span id="t2_165" class="t s1_165">
          Сургалтын багаж, төхөөрөмж, хэрэглэгдэхүүнээс гадна хэрэглэгдэхүүнийг{" "}
        </span>
        <span id="t3_165" class="t s1_165">
          хадгалах байршуулах кабинет, лаборатори, анги танхимын онцлог,
          ашиглалт{" "}
        </span>
        <span id="t4_165" class="t s1_165">
          зориулалтыг тооцон тоноглол, хийц, загвар, гоо зүй, интерактив байдал{" "}
        </span>
        <span id="t5_165" class="t s1_165">
          зэргийг тогтмол сайжруулна.{" "}
        </span>
        <span id="t6_165" class="t s2_165">
          Сургалт туршилтын байгууламж, анги танхимын хүртээмжийн{" "}
        </span>
        <span id="t7_165" class="t s2_165">
          асуудлыг хэрхэн шийдэх вэ?{" "}
        </span>
        <span id="t8_165" class="t s1_165">
          Төрийн болон төрийн бус байгууллага, иргэний нийгмийн байгууллага,{" "}
        </span>
        <span id="t9_165" class="t s1_165">
          хувийн хэвшил болон олон нийтийн, мэргэжлийн байгууллагуудтай хамтын{" "}
        </span>
        <span id="ta_165" class="t s1_165">
          ажиллагааг сайжруулж, орон нутгийн нөөц боломжид тулгуурлан танхимын{" "}
        </span>
        <span id="tb_165" class="t s1_165">
          бус сургалтаар хөгжүүлэх нь оновчтой.{" "}
        </span>
        <span id="tc_165" class="t s1_165">
          Монгол Улсын Засгийн Газрын “Хувьсах зардлын дундаж норматив, аргачлал{" "}
        </span>
        <span id="td_165" class="t s1_165">
          батлах тухай” 2022 оны 91 дүгээр тогтоолоор танхимын бус сургалтын{" "}
        </span>
        <span id="te_165" class="t s1_165">
          зардлыг нийт хувьсах зардлын 0.2 хувь байхаар баталсан.{" "}
        </span>
        <span id="tf_165" class="t s1_165">
          Нийслэлийн ерөнхий боловсролын сургуулийн сурагчдыг хөгжүүлэхийн{" "}
        </span>
        <span id="tg_165" class="t s1_165">
          тулд дараах байдлаар танхимын бус сургалтын зардлын тодорхой хувийг{" "}
        </span>
        <span id="th_165" class="t s1_165">
          тусгаж, сурагчийг хөгжүүлэх чиглэлээр байгууллагуудтай хамтран ажиллах{" "}
        </span>
        <span id="ti_165" class="t s1_165">
          боломжийг харуулсан жишээ загварыг санал болгож байна.{" "}
        </span>
        <span id="tj_165" class="t s3_165">
          Зураг 3.32. Хүүхэд хөгжлийн үйл ажиллагааг бусад нөөц дээр тулгуурлах{" "}
        </span>
        <span id="tk_165" class="t s3_165">
          шийдвэрлэх жишээ{" "}
        </span>
      </div>
    </div>
  );
}
