import React from "react";
export default function HundredNine() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_119{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_119{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:0.16px;}
#t3_119{left:87px;bottom:921px;letter-spacing:-0.16px;word-spacing:6.93px;}
#t4_119{left:87px;bottom:901px;letter-spacing:-0.12px;word-spacing:0.02px;}
#t5_119{left:102px;bottom:863px;letter-spacing:0.16px;word-spacing:0.05px;}
#t6_119{left:87px;bottom:824px;letter-spacing:-0.15px;word-spacing:9.13px;}
#t7_119{left:87px;bottom:803px;letter-spacing:-0.18px;word-spacing:10.38px;}
#t8_119{left:87px;bottom:783px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t9_119{left:87px;bottom:743px;letter-spacing:-0.12px;word-spacing:8.41px;}
#ta_119{left:87px;bottom:723px;letter-spacing:-0.14px;word-spacing:1.99px;}
#tb_119{left:87px;bottom:703px;letter-spacing:-0.16px;word-spacing:5.83px;}
#tc_119{left:87px;bottom:682px;letter-spacing:-0.17px;word-spacing:2.75px;}
#td_119{left:87px;bottom:662px;letter-spacing:-0.07px;word-spacing:-0.02px;}
#te_119{left:87px;bottom:622px;letter-spacing:-0.17px;word-spacing:0.08px;}
#tf_119{left:378px;bottom:595px;letter-spacing:-0.11px;}
#tg_119{left:461px;bottom:595px;letter-spacing:-0.17px;word-spacing:0.05px;}
#th_119{left:111px;bottom:569px;letter-spacing:-0.1px;}
#ti_119{left:114px;bottom:553px;letter-spacing:-0.14px;}
#tj_119{left:247px;bottom:561px;letter-spacing:-0.19px;}
#tk_119{left:420px;bottom:561px;letter-spacing:-0.15px;}
#tl_119{left:568px;bottom:561px;letter-spacing:-0.19px;word-spacing:0.07px;}
#tm_119{left:91px;bottom:529px;}
#tn_119{left:104px;bottom:530px;letter-spacing:-0.44px;word-spacing:0.05px;}
#to_119{left:104px;bottom:513px;letter-spacing:-0.39px;}
#tp_119{left:104px;bottom:497px;letter-spacing:-0.42px;}
#tq_119{left:91px;bottom:479px;}
#tr_119{left:104px;bottom:480px;letter-spacing:-0.41px;}
#ts_119{left:91px;bottom:463px;}
#tt_119{left:104px;bottom:464px;letter-spacing:-0.47px;}
#tu_119{left:91px;bottom:446px;}
#tv_119{left:104px;bottom:447px;letter-spacing:-0.49px;}
#tw_119{left:91px;bottom:430px;}
#tx_119{left:104px;bottom:431px;letter-spacing:-0.39px;}
#ty_119{left:104px;bottom:414px;letter-spacing:-0.41px;}
#tz_119{left:104px;bottom:398px;letter-spacing:-0.42px;}
#t10_119{left:105px;bottom:381px;letter-spacing:-0.5px;}
#t11_119{left:201px;bottom:529px;}
#t12_119{left:214px;bottom:530px;letter-spacing:-0.26px;word-spacing:0.14px;}
#t13_119{left:214px;bottom:513px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t14_119{left:214px;bottom:497px;letter-spacing:-0.13px;word-spacing:0.02px;}
#t15_119{left:201px;bottom:479px;}
#t16_119{left:214px;bottom:480px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t17_119{left:214px;bottom:464px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t18_119{left:214px;bottom:447px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t19_119{left:214px;bottom:431px;letter-spacing:-0.13px;}
#t1a_119{left:201px;bottom:413px;}
#t1b_119{left:214px;bottom:414px;letter-spacing:-0.22px;word-spacing:0.09px;}
#t1c_119{left:214px;bottom:398px;letter-spacing:-0.13px;word-spacing:-0.01px;}
#t1d_119{left:201px;bottom:380px;}
#t1e_119{left:214px;bottom:381px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t1f_119{left:368px;bottom:529px;}
#t1g_119{left:381px;bottom:530px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1h_119{left:381px;bottom:513px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1i_119{left:381px;bottom:497px;letter-spacing:-0.18px;}
#t1j_119{left:368px;bottom:479px;}
#t1k_119{left:381px;bottom:480px;letter-spacing:-0.28px;word-spacing:0.15px;}
#t1l_119{left:368px;bottom:463px;}
#t1m_119{left:381px;bottom:464px;letter-spacing:-0.13px;}
#t1n_119{left:381px;bottom:447px;letter-spacing:-0.22px;}
#t1o_119{left:381px;bottom:431px;letter-spacing:-0.15px;word-spacing:0.02px;}
#t1p_119{left:528px;bottom:529px;}
#t1q_119{left:541px;bottom:530px;letter-spacing:-0.12px;}
#t1r_119{left:541px;bottom:513px;letter-spacing:-0.27px;word-spacing:0.15px;}
#t1s_119{left:541px;bottom:497px;letter-spacing:-0.13px;}
#t1t_119{left:541px;bottom:480px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1u_119{left:541px;bottom:464px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t1v_119{left:541px;bottom:447px;letter-spacing:-0.14px;word-spacing:0.01px;}
#t1w_119{left:541px;bottom:431px;letter-spacing:-0.12px;}
#t1x_119{left:528px;bottom:413px;}
#t1y_119{left:541px;bottom:414px;letter-spacing:-0.16px;word-spacing:0.04px;}
#t1z_119{left:541px;bottom:398px;letter-spacing:-0.17px;}
#t20_119{left:541px;bottom:381px;letter-spacing:-0.2px;}
#t21_119{left:91px;bottom:342px;letter-spacing:-0.1px;}
#t22_119{left:91px;bottom:325px;letter-spacing:-0.14px;}
#t23_119{left:91px;bottom:309px;letter-spacing:-0.11px;}
#t24_119{left:201px;bottom:342px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t25_119{left:201px;bottom:325px;letter-spacing:-0.14px;word-spacing:0.02px;}
#t26_119{left:201px;bottom:309px;letter-spacing:-0.1px;}
#t27_119{left:368px;bottom:342px;letter-spacing:-0.11px;word-spacing:-0.02px;}
#t28_119{left:368px;bottom:325px;letter-spacing:-0.24px;word-spacing:0.12px;}
#t29_119{left:368px;bottom:309px;letter-spacing:-0.13px;}
#t2a_119{left:368px;bottom:292px;letter-spacing:-0.17px;word-spacing:0.06px;}
#t2b_119{left:368px;bottom:276px;letter-spacing:-0.18px;word-spacing:0.06px;}
#t2c_119{left:368px;bottom:259px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t2d_119{left:528px;bottom:342px;letter-spacing:-0.17px;word-spacing:0.05px;}
#t2e_119{left:528px;bottom:325px;letter-spacing:-0.16px;word-spacing:0.03px;}
#t2f_119{left:528px;bottom:309px;letter-spacing:-0.18px;word-spacing:0.07px;}
#t2g_119{left:528px;bottom:292px;letter-spacing:-0.17px;word-spacing:0.05px;}

.s0_119{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_119{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s2_119{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s3_119{font-size:17px;font-family:Calibri-Bold_1b-;color:#000;}
.s4_119{font-size:14px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts119" type="text/css">
        {`@font-face {
	font-family: Calibri-Bold_1b-;
	src: url("fonts/Calibri-Bold_1b-.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg119" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="119/119.svg"
          type="image/svg+xml"
          id="pdf119"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t1_119" class="t s0_119">
          119{" "}
        </span>
        <span id="t2_119" class="t s1_119">
          тул сургуулийн удирдлага боломжит цаг хугацааг гаргаж өгөх,
          урамшуулах,{" "}
        </span>
        <span id="t3_119" class="t s1_119">
          сургалтын орчинг бүрдүүлэхэд туслах, бусад талын оролцоо, хамтын{" "}
        </span>
        <span id="t4_119" class="t s1_119">
          ажиллагааг бэхжүүлэх зэргээр дэмжиж ажиллах хэрэгтэй болно.{" "}
        </span>
        <span id="t5_119" class="t s2_119">
          3.2.4 СУРГАЛТЫН ХӨТӨЛБӨРИЙН ХЭРЭГЖИЛТИЙН ҮНЭЛГЭЭ{" "}
        </span>
        <span id="t6_119" class="t s1_119">
          Сургалтын үйл ажиллагаанд анализ хийх, үнэлгээг ойлгож бодитой{" "}
        </span>
        <span id="t7_119" class="t s1_119">
          хэрэгжүүлэх замаар давуу, сул тал, асуудал, бэрхшээлээ илрүүлж{" "}
        </span>
        <span id="t8_119" class="t s1_119">
          сайжруулах нь сургуулийн менежментийн үндсэн асуудал юм.{" "}
        </span>
        <span id="t9_119" class="t s1_119">
          Сургалтын хөтөлбөрийг үр дүнтэй хэрэгжүүлэх суралцахуйн үр дүн,{" "}
        </span>
        <span id="ta_119" class="t s1_119">
          шалгуурыг хангаж байгаа байдал болон хүүхэд бүрийн хөгжлийн түвшинг{" "}
        </span>
        <span id="tb_119" class="t s1_119">
          бодитой тодорхойлох, суралцахуйд тулгамдаж буй асуудлыг илрүүлэх,{" "}
        </span>
        <span id="tc_119" class="t s1_119">
          улмаар багшлахуй болон суралцахуйг дэмжих сургалт, үйл ажиллагааны{" "}
        </span>
        <span id="td_119" class="t s1_119">
          үндсэн хэсэг юм.{" "}
        </span>
        <span id="te_119" class="t s3_119">
          Үнэлгээний өгөгдөл юунд хэрэгтэй вэ?{" "}
        </span>
        <span id="tf_119" class="t s4_119">
          Хүснэгт 3.9.{" "}
        </span>
        <span id="tg_119" class="t s4_119">
          Үнэлгээний өгөгдлийг хэрэглэх нь{" "}
        </span>
        <span id="th_119" class="t s4_119">
          Үндэсний{" "}
        </span>
        <span id="ti_119" class="t s4_119">
          түвшинд{" "}
        </span>
        <span id="tj_119" class="t s4_119">
          Сургуульд{" "}
        </span>
        <span id="tk_119" class="t s4_119">
          Багшид{" "}
        </span>
        <span id="tl_119" class="t s4_119">
          Эцэг эхэд{" "}
        </span>
        <span id="tm_119" class="t s1_119">
          •{" "}
        </span>
        <span id="tn_119" class="t s4_119">
          Сургалтын үр{" "}
        </span>
        <span id="to_119" class="t s4_119">
          дүнд хяналт{" "}
        </span>
        <span id="tp_119" class="t s4_119">
          тавих{" "}
        </span>
        <span id="tq_119" class="t s1_119">
          •{" "}
        </span>
        <span id="tr_119" class="t s4_119">
          Сэргээх{" "}
        </span>
        <span id="ts_119" class="t s1_119">
          •{" "}
        </span>
        <span id="tt_119" class="t s4_119">
          Зөвлөмж{" "}
        </span>
        <span id="tu_119" class="t s1_119">
          •{" "}
        </span>
        <span id="tv_119" class="t s4_119">
          Зохицуулга{" "}
        </span>
        <span id="tw_119" class="t s1_119">
          •{" "}
        </span>
        <span id="tx_119" class="t s4_119">
          Нөөцийг{" "}
        </span>
        <span id="ty_119" class="t s4_119">
          хуваарилах{" "}
        </span>
        <span id="tz_119" class="t s4_119">
          Шийдвэр{" "}
        </span>
        <span id="t10_119" class="t s4_119">
          гаргах{" "}
        </span>
        <span id="t11_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t12_119" class="t s4_119">
          Сургуулийн сургалт,{" "}
        </span>
        <span id="t13_119" class="t s4_119">
          үйл ажиллагаанд{" "}
        </span>
        <span id="t14_119" class="t s4_119">
          хяналт тавих{" "}
        </span>
        <span id="t15_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t16_119" class="t s4_119">
          Сургалтын чанарыг{" "}
        </span>
        <span id="t17_119" class="t s4_119">
          сайжруулах үйл{" "}
        </span>
        <span id="t18_119" class="t s4_119">
          ажиллагааг төлөвлөх,{" "}
        </span>
        <span id="t19_119" class="t s4_119">
          хэрэгжүүлэх{" "}
        </span>
        <span id="t1a_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1b_119" class="t s4_119">
          Нөөцийн хуваарилалт,{" "}
        </span>
        <span id="t1c_119" class="t s4_119">
          чадамжийг дэмжих{" "}
        </span>
        <span id="t1d_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1e_119" class="t s4_119">
          Шийдвэр гаргах{" "}
        </span>
        <span id="t1f_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1g_119" class="t s4_119">
          Өдөр тутмын сургалт{" "}
        </span>
        <span id="t1h_119" class="t s4_119">
          үйл ажиллагааг{" "}
        </span>
        <span id="t1i_119" class="t s4_119">
          төлөвлөх{" "}
        </span>
        <span id="t1j_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1k_119" class="t s4_119">
          Тохируулга хийх{" "}
        </span>
        <span id="t1l_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1m_119" class="t s4_119">
          Дэмжлэг{" "}
        </span>
        <span id="t1n_119" class="t s4_119">
          шаардлагатай{" "}
        </span>
        <span id="t1o_119" class="t s4_119">
          сурагчдыг илрүүлэх{" "}
        </span>
        <span id="t1p_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1q_119" class="t s4_119">
          Хүүхдийнхээ{" "}
        </span>
        <span id="t1r_119" class="t s4_119">
          сурлагын амжилт,{" "}
        </span>
        <span id="t1s_119" class="t s4_119">
          алдаа онооны{" "}
        </span>
        <span id="t1t_119" class="t s4_119">
          талаар баримжаа{" "}
        </span>
        <span id="t1u_119" class="t s4_119">
          авах цаашид{" "}
        </span>
        <span id="t1v_119" class="t s4_119">
          дэмжих, хамтрахад{" "}
        </span>
        <span id="t1w_119" class="t s4_119">
          санал өгөх,{" "}
        </span>
        <span id="t1x_119" class="t s1_119">
          •{" "}
        </span>
        <span id="t1y_119" class="t s4_119">
          Эцэг эхийн оролцоо{" "}
        </span>
        <span id="t1z_119" class="t s4_119">
          хариуцлага{" "}
        </span>
        <span id="t20_119" class="t s4_119">
          нэмэгдэх{" "}
        </span>
        <span id="t21_119" class="t s4_119">
          Үндэсний{" "}
        </span>
        <span id="t22_119" class="t s4_119">
          үнэлгээнүүдийн{" "}
        </span>
        <span id="t23_119" class="t s4_119">
          үр дүн{" "}
        </span>
        <span id="t24_119" class="t s4_119">
          Аймаг дүүрэг сургуулийн{" "}
        </span>
        <span id="t25_119" class="t s4_119">
          түвшний үнэлгээний үр{" "}
        </span>
        <span id="t26_119" class="t s4_119">
          дүн{" "}
        </span>
        <span id="t27_119" class="t s4_119">
          Өдөр тутмын{" "}
        </span>
        <span id="t28_119" class="t s4_119">
          хичээлийн ажиглалт,{" "}
        </span>
        <span id="t29_119" class="t s4_119">
          тэмдэглэгээ,{" "}
        </span>
        <span id="t2a_119" class="t s4_119">
          Нэгж, улирлын үнэлгээ,{" "}
        </span>
        <span id="t2b_119" class="t s4_119">
          СДҮА, даалгавар{" "}
        </span>
        <span id="t2c_119" class="t s4_119">
          үнэлгээний тайлан{" "}
        </span>
        <span id="t2d_119" class="t s4_119">
          Үнэлгээний хуудас{" "}
        </span>
        <span id="t2e_119" class="t s4_119">
          Даалгавар/ ажлын{" "}
        </span>
        <span id="t2f_119" class="t s4_119">
          хуудаст өгсөн{" "}
        </span>
        <span id="t2g_119" class="t s4_119">
          багшийн фийдбак,{" "}
        </span>
      </div>
    </div>
  );
}
