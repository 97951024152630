import React from "react";
export default function Four() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_4{left:87px;bottom:58px;}
#t2_4{left:75px;bottom:59px;}
#t3_4{left:334px;bottom:936px;letter-spacing:-0.12px;}
#t4_4{left:87px;bottom:888px;letter-spacing:-0.09px;}
#t5_4{left:161px;bottom:888px;letter-spacing:4.56px;}
#t6_4{left:665px;bottom:888px;}
#t7_4{left:87px;bottom:868px;letter-spacing:-0.14px;word-spacing:0.05px;}
#t8_4{left:301px;bottom:868px;letter-spacing:4.57px;}
#t9_4{left:665px;bottom:868px;}
#ta_4{left:87px;bottom:848px;letter-spacing:-0.15px;word-spacing:0.04px;}
#tb_4{left:273px;bottom:848px;letter-spacing:4.57px;}
#tc_4{left:665px;bottom:848px;}
#td_4{left:87px;bottom:807px;letter-spacing:-0.11px;}
#te_4{left:87px;bottom:779px;letter-spacing:-0.12px;}
#tf_4{left:87px;bottom:758px;letter-spacing:-0.12px;}
#tg_4{left:301px;bottom:758px;letter-spacing:-0.05px;}
#th_4{left:656px;bottom:758px;letter-spacing:-0.11px;}
#ti_4{left:108px;bottom:725px;letter-spacing:-0.16px;word-spacing:0.06px;}
#tj_4{left:395px;bottom:725px;letter-spacing:4.57px;}
#tk_4{left:656px;bottom:725px;letter-spacing:-0.11px;}
#tl_4{left:135px;bottom:705px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tm_4{left:367px;bottom:705px;letter-spacing:-0.05px;}
#tn_4{left:656px;bottom:705px;letter-spacing:-0.11px;}
#to_4{left:135px;bottom:685px;letter-spacing:-0.17px;word-spacing:0.07px;}
#tp_4{left:441px;bottom:685px;letter-spacing:-0.05px;}
#tq_4{left:656px;bottom:685px;letter-spacing:-0.11px;}
#tr_4{left:135px;bottom:665px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ts_4{left:357px;bottom:665px;letter-spacing:-0.05px;}
#tt_4{left:656px;bottom:665px;letter-spacing:-0.11px;}
#tu_4{left:135px;bottom:645px;letter-spacing:-0.12px;word-spacing:0.02px;}
#tv_4{left:395px;bottom:645px;letter-spacing:4.57px;}
#tw_4{left:656px;bottom:645px;letter-spacing:-0.11px;}
#tx_4{left:108px;bottom:604px;letter-spacing:-0.13px;word-spacing:0.03px;}
#ty_4{left:404px;bottom:604px;letter-spacing:-0.06px;}
#tz_4{left:656px;bottom:604px;letter-spacing:-0.11px;}
#t10_4{left:135px;bottom:584px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t11_4{left:413px;bottom:584px;letter-spacing:-0.05px;}
#t12_4{left:656px;bottom:584px;letter-spacing:-0.11px;}
#t13_4{left:135px;bottom:564px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t14_4{left:572px;bottom:564px;letter-spacing:4.57px;}
#t15_4{left:656px;bottom:564px;letter-spacing:-0.11px;}
#t16_4{left:87px;bottom:511px;letter-spacing:-0.1px;}
#t17_4{left:87px;bottom:482px;letter-spacing:-0.18px;}
#t18_4{left:209px;bottom:482px;letter-spacing:-0.12px;}
#t19_4{left:87px;bottom:462px;letter-spacing:-0.27px;word-spacing:0.15px;}
#t1a_4{left:255px;bottom:462px;letter-spacing:4.57px;}
#t1b_4{left:656px;bottom:462px;letter-spacing:-0.11px;}
#t1c_4{left:108px;bottom:428px;letter-spacing:-0.1px;}
#t1d_4{left:136px;bottom:408px;letter-spacing:-0.09px;}
#t1e_4{left:320px;bottom:408px;letter-spacing:-0.05px;}
#t1f_4{left:656px;bottom:408px;letter-spacing:-0.11px;}
#t1g_4{left:108px;bottom:356px;letter-spacing:-0.09px;}
#t1h_4{left:413px;bottom:356px;letter-spacing:4.57px;}
#t1i_4{left:656px;bottom:356px;letter-spacing:-0.11px;}
#t1j_4{left:108px;bottom:303px;letter-spacing:-0.1px;}
#t1k_4{left:136px;bottom:283px;letter-spacing:-0.09px;}
#t1l_4{left:413px;bottom:283px;letter-spacing:-0.06px;}
#t1m_4{left:656px;bottom:283px;letter-spacing:-0.11px;}
#t1n_4{left:108px;bottom:231px;letter-spacing:-0.09px;}
#t1o_4{left:141px;bottom:210px;letter-spacing:-0.1px;}
#t1p_4{left:451px;bottom:210px;letter-spacing:4.57px;}
#t1q_4{left:656px;bottom:210px;letter-spacing:-0.11px;}

.s0_4{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_4{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_4{font-size:21px;font-family:MogulArial-Bold_179;color:#1D9FA6;}
.s3_4{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s4_4{font-size:17px;font-family:MogulArial-Bold_179;color:#000;}
.s5_4{font-size:17px;font-family:MogulArial_16s;color:#20BEC6;}`}
      </style>

      <style id="fonts4" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg4" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="4/4.svg"
          type="image/svg+xml"
          id="pdf4"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t2_4" class="t s1_4">
          4{" "}
        </span>
        <span id="t3_4" class="t s2_4">
          АГУУЛГА{" "}
        </span>
        <span id="t4_4" class="t s3_4">
          Өмнөх үг{" "}
        </span>
        <span id="t5_4" class="t s3_4">
          ......................................................{" "}
        </span>
        <span id="t6_4" class="t s3_4">
          3{" "}
        </span>
        <span id="t7_4" class="t s3_4">
          Товчилсон үгийн жагсаалт{" "}
        </span>
        <span id="t8_4" class="t s3_4">
          .......................................{" "}
        </span>
        <span id="t9_4" class="t s3_4">
          8{" "}
        </span>
        <span id="ta_4" class="t s3_4">
          Нэр томьёоны тайлбар{" "}
        </span>
        <span id="tb_4" class="t s3_4">
          ..........................................{" "}
        </span>
        <span id="tc_4" class="t s3_4">
          9{" "}
        </span>
        <span id="td_4" class="t s4_4">
          I БҮЛЭГ{" "}
        </span>
        <span id="te_4" class="t s5_4">
          СУРАГЧ ТӨВТЭЙ СУРГУУЛИЙН МЕНЕЖМЕНТИЙГ{" "}
        </span>
        <span id="tf_4" class="t s5_4">
          ХЭРХЭН ХӨГЖҮҮЛЭХ ВЭ?{" "}
        </span>
        <span id="tg_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          . . .{" "}
        </span>
        <span id="th_4" class="t s3_4">
          13{" "}
        </span>
        <span id="ti_4" class="t s3_4">
          1.1 Суралцахуйд гарч буй өөрчлөлт{" "}
        </span>
        <span id="tj_4" class="t s3_4">
          ............................{" "}
        </span>
        <span id="tk_4" class="t s3_4">
          14{" "}
        </span>
        <span id="tl_4" class="t s3_4">
          Сурагчид юу хүсэж байна вэ?
        </span>
        <span id="tm_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="tn_4" class="t s3_4">
          16{" "}
        </span>
        <span id="to_4" class="t s3_4">
          Сургууль хэрхэн өөрчлөгдөж байна вэ?
        </span>
        <span id="tp_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="tq_4" class="t s3_4">
          18{" "}
        </span>
        <span id="tr_4" class="t s3_4">
          Багш хэрхэн өөрчлөгдөх вэ?{" "}
        </span>
        <span id="ts_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="tt_4" class="t s3_4">
          21{" "}
        </span>
        <span id="tu_4" class="t s3_4">
          Сургуулийн захирал юу хийх вэ?{" "}
        </span>
        <span id="tv_4" class="t s3_4">
          ............................{" "}
        </span>
        <span id="tw_4" class="t s3_4">
          23{" "}
        </span>
        <span id="tx_4" class="t s3_4">
          1.2 Сургуулийн менежментийн загвар{" "}
        </span>
        <span id="ty_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="tz_4" class="t s3_4">
          25{" "}
        </span>
        <span id="t10_4" class="t s3_4">
          “Гурван давхаргат пирамид” загвар{" "}
        </span>
        <span id="t11_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t12_4" class="t s3_4">
          25{" "}
        </span>
        <span id="t13_4" class="t s3_4">
          Богино хугацааны сургуулийн менежементийн зураглал{" "}
        </span>
        <span id="t14_4" class="t s3_4">
          .........{" "}
        </span>
        <span id="t15_4" class="t s3_4">
          31{" "}
        </span>
        <span id="t16_4" class="t s4_4">
          II БҮЛЭГ{" "}
        </span>
        <span id="t17_4" class="t s5_4">
          ҮНЭЛГЭЭНИЙ{" "}
        </span>
        <span id="t18_4" class="t s5_4">
          ҮР ДҮНД ШИНЖИЛГЭЭ ХИЙХ,{" "}
        </span>
        <span id="t19_4" class="t s5_4">
          ШИЙДВЭР ГАРГАЛТ{" "}
        </span>
        <span id="t1a_4" class="t s3_4">
          ...........................................{" "}
        </span>
        <span id="t1b_4" class="t s3_4">
          43{" "}
        </span>
        <span id="t1c_4" class="t s3_4">
          2.1 Менежментийг сайжруулах ажлыг хаанаас{" "}
        </span>
        <span id="t1d_4" class="t s3_4">
          эхлэх нь оновчтой вэ?{" "}
        </span>
        <span id="t1e_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          .{" "}
        </span>
        <span id="t1f_4" class="t s3_4">
          44{" "}
        </span>
        <span id="t1g_4" class="t s3_4">
          2.2. Сургуулийн гүйцэтгэлийн үнэлгээ{" "}
        </span>
        <span id="t1h_4" class="t s3_4">
          ..........................{" "}
        </span>
        <span id="t1i_4" class="t s3_4">
          49{" "}
        </span>
        <span id="t1j_4" class="t s3_4">
          2.3 Гүйцэтгэлийн үнэлгээний үр дүнгийн талаарх мэдээллийг{" "}
        </span>
        <span id="t1k_4" class="t s3_4">
          Хаанаас авах, хэрхэн ашиглах вэ?{" "}
        </span>
        <span id="t1l_4" class="t s3_4">
          . . . . . . . . . . . . . . . . . . . . . . . . . .{" "}
        </span>
        <span id="t1m_4" class="t s3_4">
          53{" "}
        </span>
        <span id="t1n_4" class="t s3_4">
          2.4. Гүйцэтгэлийн үнэлгээний үр дүнгээс тулгамдаж{" "}
        </span>
        <span id="t1o_4" class="t s3_4">
          буй асуудлыг хэрхэн тодорхойлох вэ?{" "}
        </span>
        <span id="t1p_4" class="t s3_4">
          ......................{" "}
        </span>
        <span id="t1q_4" class="t s3_4">
          68{" "}
        </span>
      </div>
    </div>
  );
}
