import React from "react";
export default function OneHundredSeventyEigth() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_178{left:66px;bottom:59px;letter-spacing:0.16px;}
#t2_178{left:184px;bottom:946px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t3_178{left:225px;bottom:927px;letter-spacing:-0.15px;word-spacing:0.04px;}
#t4_178{left:125px;bottom:891px;letter-spacing:0.12px;word-spacing:0.03px;}
#t5_178{left:523px;bottom:873px;letter-spacing:0.11px;word-spacing:0.04px;}
#t6_178{left:91px;bottom:823px;letter-spacing:-0.14px;}
#t7_178{left:179px;bottom:823px;letter-spacing:-0.17px;}
#t8_178{left:267px;bottom:823px;letter-spacing:-0.15px;}
#t9_178{left:325px;bottom:832px;letter-spacing:-0.17px;}
#ta_178{left:324px;bottom:814px;letter-spacing:-0.19px;}
#tb_178{left:407px;bottom:832px;letter-spacing:-0.17px;}
#tc_178{left:403px;bottom:814px;letter-spacing:-0.15px;}
#td_178{left:467px;bottom:832px;letter-spacing:-0.2px;}
#te_178{left:473px;bottom:814px;letter-spacing:-0.15px;}
#tf_178{left:541px;bottom:823px;letter-spacing:-0.14px;}
#tg_178{left:605px;bottom:832px;letter-spacing:-0.11px;}
#th_178{left:622px;bottom:814px;letter-spacing:-0.2px;}
#ti_178{left:91px;bottom:793px;letter-spacing:-0.15px;word-spacing:0.01px;}
#tj_178{left:91px;bottom:773px;letter-spacing:-0.14px;}
#tk_178{left:128px;bottom:754px;letter-spacing:-0.12px;}
#tl_178{left:156px;bottom:754px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tm_178{left:117px;bottom:735px;letter-spacing:-0.11px;}
#tn_178{left:156px;bottom:735px;letter-spacing:-0.15px;word-spacing:0.03px;}
#to_178{left:117px;bottom:713px;letter-spacing:-0.11px;}
#tp_178{left:156px;bottom:713px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tq_178{left:128px;bottom:695px;letter-spacing:-0.12px;}
#tr_178{left:156px;bottom:695px;letter-spacing:-0.15px;word-spacing:0.03px;}
#ts_178{left:128px;bottom:676px;letter-spacing:-0.12px;}
#tt_178{left:156px;bottom:676px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tu_178{left:91px;bottom:657px;letter-spacing:-0.14px;}
#tv_178{left:128px;bottom:639px;letter-spacing:-0.12px;}
#tw_178{left:156px;bottom:639px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tx_178{left:128px;bottom:620px;letter-spacing:-0.12px;}
#ty_178{left:156px;bottom:620px;letter-spacing:-0.15px;word-spacing:0.03px;}
#tz_178{left:128px;bottom:601px;letter-spacing:-0.12px;}
#t10_178{left:156px;bottom:601px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t11_178{left:91px;bottom:583px;letter-spacing:-0.14px;}
#t12_178{left:91px;bottom:564px;letter-spacing:-0.13px;}
#t13_178{left:144px;bottom:564px;}
#t14_178{left:128px;bottom:545px;letter-spacing:-0.12px;}
#t15_178{left:156px;bottom:545px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t16_178{left:128px;bottom:526px;letter-spacing:-0.12px;}
#t17_178{left:156px;bottom:526px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t18_178{left:128px;bottom:508px;letter-spacing:-0.12px;}
#t19_178{left:156px;bottom:508px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1a_178{left:91px;bottom:489px;letter-spacing:-0.14px;}
#t1b_178{left:128px;bottom:470px;letter-spacing:-0.12px;}
#t1c_178{left:156px;bottom:470px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1d_178{left:128px;bottom:452px;letter-spacing:-0.12px;}
#t1e_178{left:156px;bottom:452px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1f_178{left:128px;bottom:433px;letter-spacing:-0.12px;}
#t1g_178{left:156px;bottom:433px;letter-spacing:-0.15px;word-spacing:0.03px;}
#t1h_178{left:87px;bottom:376px;letter-spacing:-0.16px;}
#t1i_178{left:213px;bottom:376px;letter-spacing:-0.15px;word-spacing:5.62px;}
#t1j_178{left:87px;bottom:356px;letter-spacing:-0.19px;}
#t1k_178{left:87px;bottom:327px;letter-spacing:-0.15px;word-spacing:4.39px;}
#t1l_178{left:87px;bottom:307px;letter-spacing:-0.15px;word-spacing:-1.31px;}
#t1m_178{left:87px;bottom:287px;letter-spacing:-0.14px;word-spacing:-0.24px;}
#t1n_178{left:87px;bottom:267px;letter-spacing:-0.19px;word-spacing:4.32px;}
#t1o_178{left:87px;bottom:247px;letter-spacing:-0.14px;word-spacing:-1.39px;}
#t1p_178{left:87px;bottom:226px;letter-spacing:-0.12px;}
#t1q_178{left:178px;bottom:226px;letter-spacing:-0.09px;}
#t1r_178{left:267px;bottom:226px;letter-spacing:-0.2px;}
#t1s_178{left:377px;bottom:226px;letter-spacing:-0.09px;}
#t1t_178{left:468px;bottom:226px;letter-spacing:-0.13px;}
#t1u_178{left:574px;bottom:226px;letter-spacing:-0.19px;}
#t1v_178{left:87px;bottom:206px;letter-spacing:-0.13px;word-spacing:4.58px;}
#t1w_178{left:87px;bottom:186px;letter-spacing:-0.16px;word-spacing:2.75px;}
#t1x_178{left:87px;bottom:166px;letter-spacing:-0.14px;word-spacing:0.05px;}

.s0_178{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_178{font-size:17px;font-family:Arial-BoldMT_1li;color:#000;}
.s2_178{font-size:15px;font-family:Arial-ItalicMT_18u;color:#000;}
.s3_178{font-size:14px;font-family:MogulArial-Bold_179;color:#000;}
.s4_178{font-size:14px;font-family:MogulArial_16s;color:#000;}
.s5_178{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s6_178{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts178" type="text/css">
        {`@font-face {
	font-family: Arial-BoldMT_1li;
	src: url("fonts/Arial-BoldMT_1li.woff") format("woff");
}

@font-face {
	font-family: Arial-ItalicMT_18u;
	src: url("fonts/Arial-ItalicMT_18u.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg178" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="178/178.svg"
          type="image/svg+xml"
          id="pdf178"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_178" class="t s0_178">
          178{" "}
        </span>
        <span id="t2_178" class="t s1_178">
          Сургуулийн тухайн хичээлийн жилийн сургалт,{" "}
        </span>
        <span id="t3_178" class="t s1_178">
          үйл ажиллагааны нэгдсэн төлөвлөлт{" "}
        </span>
        <span id="t4_178" class="t s2_178">
          Хүснэгт 3.25. Сургуулийн сургалт, үйл ажиллагааны төлөвлөгөөг нэгтгэн{" "}
        </span>
        <span id="t5_178" class="t s2_178">
          хүснэгтлэх хувилбар{" "}
        </span>
        <span id="t6_178" class="t s3_178">
          Д/д{" "}
        </span>
        <span id="t7_178" class="t s3_178">
          Агуулга{" "}
        </span>
        <span id="t8_178" class="t s3_178">
          Төсөв{" "}
        </span>
        <span id="t9_178" class="t s3_178">
          Шалгуур{" "}
        </span>
        <span id="ta_178" class="t s3_178">
          үзүүлэлт{" "}
        </span>
        <span id="tb_178" class="t s3_178">
          Суурь{" "}
        </span>
        <span id="tc_178" class="t s3_178">
          түвшин{" "}
        </span>
        <span id="td_178" class="t s3_178">
          Зорилтот{" "}
        </span>
        <span id="te_178" class="t s3_178">
          түвшин{" "}
        </span>
        <span id="tf_178" class="t s3_178">
          Хугацаа{" "}
        </span>
        <span id="tg_178" class="t s3_178">
          Хариуцах{" "}
        </span>
        <span id="th_178" class="t s3_178">
          эзэн{" "}
        </span>
        <span id="ti_178" class="t s3_178">
          Сургалт үйл ажиллагаа{" "}
        </span>
        <span id="tj_178" class="t s3_178">
          Зорилт 1{" "}
        </span>
        <span id="tk_178" class="t s4_178">
          1.1{" "}
        </span>
        <span id="tl_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tm_178" class="t s4_178">
          1.1.1{" "}
        </span>
        <span id="tn_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="to_178" class="t s4_178">
          1.1.2{" "}
        </span>
        <span id="tp_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tq_178" class="t s4_178">
          1.2{" "}
        </span>
        <span id="tr_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="ts_178" class="t s4_178">
          1.3{" "}
        </span>
        <span id="tt_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tu_178" class="t s3_178">
          Зорилт 2{" "}
        </span>
        <span id="tv_178" class="t s4_178">
          2.1{" "}
        </span>
        <span id="tw_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tx_178" class="t s4_178">
          2.2{" "}
        </span>
        <span id="ty_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="tz_178" class="t s4_178">
          2.3{" "}
        </span>
        <span id="t10_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t11_178" class="t s3_178">
          Хүүхдийн хөгжил, хамгаалал{" "}
        </span>
        <span id="t12_178" class="t s3_178">
          Зорилт{" "}
        </span>
        <span id="t13_178" class="t s4_178">
          1{" "}
        </span>
        <span id="t14_178" class="t s4_178">
          1.1{" "}
        </span>
        <span id="t15_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t16_178" class="t s4_178">
          1.2{" "}
        </span>
        <span id="t17_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t18_178" class="t s4_178">
          1.3{" "}
        </span>
        <span id="t19_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t1a_178" class="t s3_178">
          Зорилт 2{" "}
        </span>
        <span id="t1b_178" class="t s4_178">
          2.1{" "}
        </span>
        <span id="t1c_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t1d_178" class="t s4_178">
          2.2{" "}
        </span>
        <span id="t1e_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t1f_178" class="t s4_178">
          2.3{" "}
        </span>
        <span id="t1g_178" class="t s4_178">
          Үйл ажиллагаа{" "}
        </span>
        <span id="t1h_178" class="t s5_178">
          Удирдлагын{" "}
        </span>
        <span id="t1i_178" class="t s5_178">
          манлайллыг дээшлүүлэх төлөвлөгөө боловсруулах{" "}
        </span>
        <span id="t1j_178" class="t s5_178">
          тухайд{" "}
        </span>
        <span id="t1k_178" class="t s6_178">
          Байгууллагын гүйцэтгэлийн үнэлгээнд ерөнхий боловсролын сургуулийн{" "}
        </span>
        <span id="t1l_178" class="t s6_178">
          удирдлагын удирдах чадварын жагсаалтыг анхлан боловсруулж, хэрэглэсэн{" "}
        </span>
        <span id="t1m_178" class="t s6_178">
          нь удирдах ажилтнууд өөрсдийн чадвараа хөгжүүлэх, хэрэгцээ шаардлагаа{" "}
        </span>
        <span id="t1n_178" class="t s6_178">
          тодорхойлоход ихээхэн ач холбогдолтой болов. Иймд удирдах ажилтан{" "}
        </span>
        <span id="t1o_178" class="t s6_178">
          өөрийн болон багш, хамт олны үнэлгээнд үндэслэн хөгжүүлэх шаардлагатай{" "}
        </span>
        <span id="t1p_178" class="t s6_178">
          чадвараа{" "}
        </span>
        <span id="t1q_178" class="t s6_178">
          илрүүлж,{" "}
        </span>
        <span id="t1r_178" class="t s6_178">
          удирдлагын{" "}
        </span>
        <span id="t1s_178" class="t s6_178">
          хөгжлийн{" "}
        </span>
        <span id="t1t_178" class="t s6_178">
          төлөвлөгөө{" "}
        </span>
        <span id="t1u_178" class="t s6_178">
          боловсруулж{" "}
        </span>
        <span id="t1v_178" class="t s6_178">
          ажиллахыг зөвлөмжилж байна. Бид зөвлөн тусалж ажилласан ерөнхий{" "}
        </span>
        <span id="t1w_178" class="t s6_178">
          боловсролын зарим сургуулийн багш нарын удирдлагуудынхаа чадварыг{" "}
        </span>
        <span id="t1x_178" class="t s6_178">
          үнэлсэн байдлыг жишээ болгон хүснэгт 12- р харуулав.{" "}
        </span>
      </div>
    </div>
  );
}
