import React from "react";
export default function FiftyTwo() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_52{left:87px;bottom:58px;letter-spacing:-0.11px;}
#t2_52{left:70px;bottom:59px;letter-spacing:0.16px;}
#t3_52{left:103px;bottom:916px;letter-spacing:0.16px;word-spacing:0.93px;}
#t4_52{left:206px;bottom:916px;letter-spacing:0.07px;word-spacing:1px;}
#t5_52{left:103px;bottom:898px;letter-spacing:0.1px;word-spacing:-0.85px;}
#t6_52{left:103px;bottom:879px;letter-spacing:0.11px;word-spacing:0.01px;}
#t7_52{left:103px;bottom:852px;letter-spacing:0.14px;word-spacing:3.24px;}
#t8_52{left:231px;bottom:852px;letter-spacing:0.08px;word-spacing:3.29px;}
#t9_52{left:103px;bottom:834px;letter-spacing:0.11px;word-spacing:3.98px;}
#ta_52{left:103px;bottom:816px;letter-spacing:0.13px;word-spacing:3.29px;}
#tb_52{left:103px;bottom:797px;letter-spacing:0.08px;word-spacing:0.05px;}
#tc_52{left:103px;bottom:779px;letter-spacing:-0.03px;}
#td_52{left:291px;bottom:751px;}
#te_52{left:300px;bottom:750px;}
#tf_52{left:309px;bottom:751px;}
#tg_52{left:324px;bottom:762px;}
#th_52{left:332px;bottom:761px;}
#ti_52{left:341px;bottom:762px;letter-spacing:3.51px;}
#tj_52{left:340px;bottom:741px;}
#tk_52{left:370px;bottom:751px;letter-spacing:0.19px;word-spacing:0.32px;}
#tl_52{left:103px;bottom:706px;letter-spacing:0.15px;word-spacing:4.74px;}
#tm_52{left:103px;bottom:688px;letter-spacing:0.1px;word-spacing:0.06px;}
#tn_52{left:103px;bottom:604px;letter-spacing:0.07px;}
#to_52{left:195px;bottom:604px;letter-spacing:0.17px;}
#tp_52{left:252px;bottom:604px;letter-spacing:-0.06px;}
#tq_52{left:317px;bottom:604px;letter-spacing:0.07px;}
#tr_52{left:408px;bottom:604px;letter-spacing:0.12px;}
#ts_52{left:471px;bottom:604px;letter-spacing:0.11px;}
#tt_52{left:555px;bottom:604px;letter-spacing:0.13px;}
#tu_52{left:627px;bottom:604px;letter-spacing:0.12px;}
#tv_52{left:103px;bottom:585px;letter-spacing:0.03px;}
#tw_52{left:213px;bottom:585px;letter-spacing:0.09px;word-spacing:0.05px;}
#tx_52{left:103px;bottom:558px;letter-spacing:0.12px;}
#ty_52{left:172px;bottom:558px;letter-spacing:0.1px;word-spacing:5.95px;}
#tz_52{left:103px;bottom:540px;letter-spacing:0.09px;word-spacing:-0.62px;}
#t10_52{left:103px;bottom:522px;letter-spacing:0.09px;word-spacing:0.03px;}
#t11_52{left:103px;bottom:495px;letter-spacing:0.12px;word-spacing:8.4px;}
#t12_52{left:103px;bottom:476px;letter-spacing:0.1px;word-spacing:1.87px;}
#t13_52{left:103px;bottom:458px;letter-spacing:0.1px;word-spacing:0.04px;}
#t14_52{left:87px;bottom:390px;letter-spacing:-0.14px;word-spacing:2.08px;}
#t15_52{left:87px;bottom:370px;letter-spacing:-0.13px;word-spacing:4.86px;}
#t16_52{left:87px;bottom:350px;letter-spacing:-0.15px;word-spacing:0.05px;}
#t17_52{left:87px;bottom:317px;letter-spacing:-0.12px;word-spacing:0.64px;}
#t18_52{left:87px;bottom:297px;letter-spacing:-0.16px;word-spacing:4.11px;}
#t19_52{left:87px;bottom:276px;letter-spacing:-0.19px;word-spacing:0.09px;}
#t1a_52{left:87px;bottom:243px;letter-spacing:-0.12px;word-spacing:-2.37px;}
#t1b_52{left:87px;bottom:223px;letter-spacing:-0.14px;word-spacing:2.01px;}
#t1c_52{left:87px;bottom:203px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t1d_52{left:87px;bottom:170px;letter-spacing:-0.13px;word-spacing:-1.2px;}
#t1e_52{left:87px;bottom:150px;letter-spacing:-0.14px;word-spacing:4.3px;}
#t1f_52{left:87px;bottom:130px;letter-spacing:-0.17px;word-spacing:0.08px;}

.s0_52{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_52{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_52{font-size:15px;font-family:MogulArial-Bold_179;color:#000;}
.s3_52{font-size:15px;font-family:MogulArial_16s;color:#000;}
.s4_52{font-size:15px;font-family:CambriaMath_ed;color:#000;}
.s5_52{font-size:11px;font-family:CambriaMath_ed;color:#000;}
.s6_52{font-size:15px;font-family:MogulArial-Italic_17h;color:#000;}
.s7_52{font-size:17px;font-family:MogulArial_16s;color:#000;}`}
      </style>

      <style id="fonts52" type="text/css">
        {`@font-face {
	font-family: CambriaMath_ed;
	src: url("fonts/CambriaMath_ed.woff") format("woff");
}

@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Italic_17h;
	src: url("fonts/MogulArial-Italic_17h.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg52" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="52/52.svg"
          type="image/svg+xml"
          id="pdf52"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_52" class="t s1_52">
          52{" "}
        </span>
        <span id="t3_52" class="t s2_52">
          Анхны оноо:{" "}
        </span>
        <span id="t4_52" class="t s3_52">
          Тухайн сургууль гүйцэтгэлийн үнэлгээгээр авбал зохих 100-аас{" "}
        </span>
        <span id="t5_52" class="t s3_52">
          авсан оноо. Нийт сургуулийг нийслэл, аймгийн төв, сум-баг хэмээн 3
          байршилд{" "}
        </span>
        <span id="t6_52" class="t s3_52">
          бүлэглэснийг кластер гэнэ.{" "}
        </span>
        <span id="t7_52" class="t s2_52">
          Стандарт оноо:{" "}
        </span>
        <span id="t8_52" class="t s3_52">
          Тухайн сургуулийн эрэмбийн хувийг тооцоолох математик{" "}
        </span>
        <span id="t9_52" class="t s3_52">
          үндэслэл нь үнэлгээний үр дүнг нормаль тархалттай гэж үзээд тархалтыг{" "}
        </span>
        <span id="ta_52" class="t s3_52">
          нь нормчлох юм. Иймд тухайн сургуулийн анхны оноог стандарт оноо рүү{" "}
        </span>
        <span id="tb_52" class="t s3_52">
          хөрвүүлж, үр дүнг тооцдог. Дараах томьёогоор анхны оноог стандарт оноо
          руу{" "}
        </span>
        <span id="tc_52" class="t s3_52">
          хөрвүүлдэг.{" "}
        </span>
        <span id="td_52" class="t s4_52">
          {" "}
        </span>
        <span id="te_52" class="t s5_52">
          {" "}
        </span>
        <span id="tf_52" class="t s4_52">
          {" "}
        </span>
        <span id="tg_52" class="t s4_52">
          {" "}
        </span>
        <span id="th_52" class="t s5_52">
          {" "}
        </span>
        <span id="ti_52" class="t s4_52">
          {" "}
        </span>
        <span id="tj_52" class="t s4_52">
          {" "}
        </span>
        <span id="tk_52" class="t s4_52">
             ||  {" "}
        </span>
        <span id="tl_52" class="t s6_52">
          Энд нь i-р багшийн анхны оноо, нь нийт багш нарын дундаж оноо, нь{" "}
        </span>
        <span id="tm_52" class="t s6_52">
          стандарт хазайлт{" "}
        </span>
        <span id="tn_52" class="t s2_52">
          Эрэмбийн{" "}
        </span>
        <span id="to_52" class="t s2_52">
          хувь:{" "}
        </span>
        <span id="tp_52" class="t s3_52">
          Тухайн{" "}
        </span>
        <span id="tq_52" class="t s3_52">
          сургуулийг{" "}
        </span>
        <span id="tr_52" class="t s3_52">
          түүний{" "}
        </span>
        <span id="ts_52" class="t s3_52">
          хамаарах{" "}
        </span>
        <span id="tt_52" class="t s3_52">
          кластер{" "}
        </span>
        <span id="tu_52" class="t s3_52">
          дахь{" "}
        </span>
        <span id="tv_52" class="t s3_52">
          сургуулиудтай{" "}
        </span>
        <span id="tw_52" class="t s3_52">
          нь харьцуулахад хаана байгааг илэрхийлнэ.{" "}
        </span>
        <span id="tx_52" class="t s2_52">
          Индекс:{" "}
        </span>
        <span id="ty_52" class="t s3_52">
          Тухайн сургуулийн хамаарах кластер дахь сургуулийн хөгжлийг{" "}
        </span>
        <span id="tz_52" class="t s3_52">
          илэрхийлэх тоон утга бөгөөд сургуулиудыг гүйцэтгэлийн үнэлгээний үр
          дүнгээр{" "}
        </span>
        <span id="t10_52" class="t s3_52">
          5 индекст авч үзнэ.{" "}
        </span>
        <span id="t11_52" class="t s3_52">
          Багшийн гүйцэтгэлийн үнэлгээний үр дүнд үндэслэн гарсан сургалтын{" "}
        </span>
        <span id="t12_52" class="t s3_52">
          байгууллагын эрэмбийн хувь нь тухайн сургуулийг нэмэлт урамшуулал авах{" "}
        </span>
        <span id="t13_52" class="t s3_52">
          эсэх, хэдэн хувийн урамшуулал авахыг тодорхойлно.{" "}
        </span>
        <span id="t14_52" class="t s7_52">
          Дээрх Жишээ 1-д авч үзсэн А сургуулийн эрэмбийн хувь 84.89 байгаа нь{" "}
        </span>
        <span id="t15_52" class="t s7_52">
          нийслэлийн кластер дахь 139 сургуулийн 15.1 хувь болох 21 сургууль{" "}
        </span>
        <span id="t16_52" class="t s7_52">
          тухайн А сургуулийн өмнө эрэмбэлэгдсэнийг илэрхийлж байна.{" "}
        </span>
        <span id="t17_52" class="t s7_52">
          Б сургуулийн эрэмбийн хувь 88.72 байгаа нь аймгийн төвийн кластер дахь{" "}
        </span>
        <span id="t18_52" class="t s7_52">
          133 сургуулийн 11.2 хувь болох 15 сургууль тухайн Б сургуулийн өмнө{" "}
        </span>
        <span id="t19_52" class="t s7_52">
          эрэмбэлэгдсэн байна.{" "}
        </span>
        <span id="t1a_52" class="t s7_52">
          В сургуулийн эрэмбийн хувь 96.08 нь сум-багийн кластер дахь 383
          сургуулийн{" "}
        </span>
        <span id="t1b_52" class="t s7_52">
          3.92 хувь болох 15 сургууль тухайн В сургуулийн өмнө эрэмбэлэгдсэнийг{" "}
        </span>
        <span id="t1c_52" class="t s7_52">
          илэрхийлж байна.{" "}
        </span>
        <span id="t1d_52" class="t s7_52">
          Гүйцэтгэлийн үнэлгээний үр дүнд үндэслэн ерөнхий боловсролын сургуульд{" "}
        </span>
        <span id="t1e_52" class="t s7_52">
          дараах хүснэгтэд үзүүлсэн нэмэлт урамшууллыг жилд нэг удаа тооцож{" "}
        </span>
        <span id="t1f_52" class="t s7_52">
          олгохоор заасан байна.{" "}
        </span>
      </div>
    </div>
  );
}
