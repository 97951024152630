import React from "react";
export default function OneHundredSeventyFive() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>

      <style type="text/css">
        {`#t1_175{left:670px;bottom:59px;letter-spacing:0.16px;}
#t2_175{left:142px;bottom:926px;letter-spacing:0.18px;}
#t3_175{left:87px;bottom:894px;letter-spacing:-0.16px;word-spacing:-2.14px;}
#t4_175{left:87px;bottom:874px;letter-spacing:-0.15px;word-spacing:2.45px;}
#t5_175{left:87px;bottom:853px;letter-spacing:-0.14px;word-spacing:2.64px;}
#t6_175{left:633px;bottom:853px;letter-spacing:-0.26px;}
#t7_175{left:87px;bottom:833px;letter-spacing:-0.15px;word-spacing:3.23px;}
#t8_175{left:391px;bottom:833px;letter-spacing:-0.24px;}
#t9_175{left:457px;bottom:833px;letter-spacing:-0.14px;word-spacing:3.21px;}
#ta_175{left:87px;bottom:813px;letter-spacing:-0.16px;word-spacing:3.78px;}
#tb_175{left:237px;bottom:813px;letter-spacing:-0.19px;}
#tc_175{left:313px;bottom:813px;letter-spacing:-0.13px;word-spacing:3.75px;}
#td_175{left:87px;bottom:793px;letter-spacing:-0.15px;word-spacing:10.06px;}
#te_175{left:87px;bottom:773px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tf_175{left:87px;bottom:732px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tg_175{left:113px;bottom:708px;letter-spacing:-0.05px;}
#th_175{left:139px;bottom:708px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ti_175{left:139px;bottom:688px;letter-spacing:-0.15px;word-spacing:0.05px;}
#tj_175{left:113px;bottom:663px;letter-spacing:-0.05px;}
#tk_175{left:139px;bottom:663px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tl_175{left:113px;bottom:639px;letter-spacing:-0.05px;}
#tm_175{left:139px;bottom:639px;letter-spacing:-0.14px;word-spacing:0.04px;}
#tn_175{left:113px;bottom:614px;letter-spacing:-0.05px;}
#to_175{left:139px;bottom:614px;letter-spacing:-0.11px;word-spacing:0.01px;}
#tp_175{left:139px;bottom:594px;letter-spacing:-0.13px;word-spacing:0.03px;}
#tq_175{left:113px;bottom:570px;letter-spacing:-0.05px;}
#tr_175{left:139px;bottom:570px;letter-spacing:-0.14px;word-spacing:0.04px;}
#ts_175{left:87px;bottom:525px;letter-spacing:-0.14px;word-spacing:7.5px;}
#tt_175{left:87px;bottom:505px;letter-spacing:-0.13px;word-spacing:0.04px;}
#tu_175{left:87px;bottom:464px;letter-spacing:-0.1px;word-spacing:-0.94px;}
#tv_175{left:87px;bottom:444px;letter-spacing:-0.1px;word-spacing:4.88px;}
#tw_175{left:87px;bottom:424px;letter-spacing:-0.1px;}
#tx_175{left:87px;bottom:384px;letter-spacing:-0.16px;word-spacing:0.06px;}
#ty_175{left:113px;bottom:359px;letter-spacing:-0.05px;}
#tz_175{left:139px;bottom:359px;letter-spacing:-0.1px;}
#t10_175{left:113px;bottom:337px;letter-spacing:-0.05px;}
#t11_175{left:139px;bottom:337px;letter-spacing:-0.1px;}
#t12_175{left:113px;bottom:315px;letter-spacing:-0.05px;}
#t13_175{left:139px;bottom:315px;letter-spacing:-0.1px;}
#t14_175{left:113px;bottom:292px;letter-spacing:-0.05px;}
#t15_175{left:139px;bottom:292px;letter-spacing:-0.1px;}
#t16_175{left:113px;bottom:270px;letter-spacing:-0.05px;}
#t17_175{left:139px;bottom:270px;letter-spacing:-0.1px;}
#t18_175{left:113px;bottom:248px;letter-spacing:-0.05px;}
#t19_175{left:139px;bottom:248px;letter-spacing:-0.1px;}
#t1a_175{left:113px;bottom:225px;letter-spacing:-0.05px;}
#t1b_175{left:139px;bottom:225px;letter-spacing:-0.1px;}
#t1c_175{left:113px;bottom:203px;letter-spacing:-0.05px;}
#t1d_175{left:139px;bottom:203px;letter-spacing:-0.1px;}
#t1e_175{left:113px;bottom:181px;letter-spacing:-0.05px;}
#t1f_175{left:139px;bottom:181px;letter-spacing:-0.1px;}
#t1g_175{left:113px;bottom:158px;letter-spacing:-0.06px;}
#t1h_175{left:139px;bottom:158px;letter-spacing:-0.1px;word-spacing:-1.54px;}
#t1i_175{left:113px;bottom:136px;letter-spacing:-0.37px;}
#t1j_175{left:139px;bottom:136px;letter-spacing:-0.1px;}
#t1k_175{left:113px;bottom:114px;letter-spacing:-0.06px;}
#t1l_175{left:139px;bottom:114px;letter-spacing:-0.1px;}

.s0_175{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s1_175{font-size:18px;font-family:MogulArial-Bold_179;color:#D1622B;}
.s2_175{font-size:17px;font-family:MogulArial_16s;color:#000;}
.s3_175{font-size:17px;font-family:MogulArial-BoldItalic_17m;color:#000;}
.s4_175{font-size:17px;font-family:TimesNewRomanPSMT_192;color:#000;}
.s5_175{font-size:17px;font-family:MogulArial_16s;color:#D1622B;}`}
      </style>

      <style id="fonts175" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial-BoldItalic_17m;
	src: url("fonts/MogulArial-BoldItalic_17m.woff") format("woff");
}

@font-face {
	font-family: MogulArial-Bold_179;
	src: url("fonts/MogulArial-Bold_179.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: TimesNewRomanPSMT_192;
	src: url("fonts/TimesNewRomanPSMT_192.woff") format("woff");
}`}
      </style>

      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg175" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="175/175.svg"
          type="image/svg+xml"
          id="pdf175"
          style={pdfStyle}
        ></object>
      </div>

      <div class="text-container">
        <span id="t1_175" class="t s0_175">
          175{" "}
        </span>
        <span id="t2_175" class="t s1_175">
          ЗӨВЛӨМЖ{" "}
        </span>
        <span id="t3_175" class="t s2_175">
          Сургуулийн зорилго, үйл ажиллгааны нэгдсэн төлөвлөлтийг багш,
          удирдлага,{" "}
        </span>
        <span id="t4_175" class="t s2_175">
          ажилтны ажлын байрны тодорхойлолтод үндэслэн тухайлсан бүдүүвчээр{" "}
        </span>
        <span id="t5_175" class="t s2_175">
          хийхийг зөвлөж байна. Яагаад бүдүүвчээр хийх нь чухал вэ гэвэл,{" "}
        </span>
        <span id="t6_175" class="t s3_175">
          нэгд,{" "}
        </span>
        <span id="t7_175" class="t s2_175">
          юу хийх вэ гэдгээ зохион байгуулах,{" "}
        </span>
        <span id="t8_175" class="t s3_175">
          хоёрт,{" "}
        </span>
        <span id="t9_175" class="t s2_175">
          хэн юу хийх гэж байгаа нь{" "}
        </span>
        <span id="ta_175" class="t s2_175">
          ойлгомжтой байх,{" "}
        </span>
        <span id="tb_175" class="t s3_175">
          гуравт,{" "}
        </span>
        <span id="tc_175" class="t s2_175">
          нарийн дараалал, дэгтэй байх нь ойлголтоо{" "}
        </span>
        <span id="td_175" class="t s2_175">
          нэгтгэж, ажил үүргүүд хуваарилагдаж, удирдлагын зүгээс чиглүүлэх{" "}
        </span>
        <span id="te_175" class="t s2_175">
          боломжийг бүрдүүлнэ.{" "}
        </span>
        <span id="tf_175" class="t s2_175">
          Бүдүүвчийг дараах асуултад хариулахаар зураглаарай. Үүнд:{" "}
        </span>
        <span id="tg_175" class="t s4_175">
          1.{" "}
        </span>
        <span id="th_175" class="t s2_175">
          Зорилгоо томъёолж, нэгдсэн төлөвлөлт хийх ажил ямар{" "}
        </span>
        <span id="ti_175" class="t s2_175">
          дараалалтай хийгдэх вэ?{" "}
        </span>
        <span id="tj_175" class="t s4_175">
          2.{" "}
        </span>
        <span id="tk_175" class="t s2_175">
          Ажлын үр дүн хаана нэгтгэгдэх вэ?{" "}
        </span>
        <span id="tl_175" class="t s4_175">
          3.{" "}
        </span>
        <span id="tm_175" class="t s2_175">
          Багшийн харъяалагдах анхан шатны нэгж аль нь вэ?{" "}
        </span>
        <span id="tn_175" class="t s4_175">
          4.{" "}
        </span>
        <span id="to_175" class="t s2_175">
          Анхан шатны нэгж ажлын үр дүнгээ дараагийн шатны нэгждээ{" "}
        </span>
        <span id="tp_175" class="t s2_175">
          хэрхэн танилцуулах, зөвлөгөө авах, тайлан өгөх вэ?{" "}
        </span>
        <span id="tq_175" class="t s4_175">
          5.{" "}
        </span>
        <span id="tr_175" class="t s2_175">
          Ямар дүрэм журмын хүрээнд зохицуулагдах вэ?{" "}
        </span>
        <span id="ts_175" class="t s2_175">
          Сургуулийн зорилго, үйл ажиллагааны нэгдсэн төлөвлөлтийг хийхдээ{" "}
        </span>
        <span id="tt_175" class="t s2_175">
          дараах 14 зарчмыг баримтална.{" "}
        </span>
        <span id="tu_175" class="t s2_175">
          Үе шат нь хэрэгцээ, шаардлагыг тодорхойлох, нөөцийг хуваарилах,
          түвшин/{" "}
        </span>
        <span id="tv_175" class="t s2_175">
          нэгж бүрээр төлөвлөх, хяналт-шинжилгээ, үнэлгээ хийх, тайлагнах үйл{" "}
        </span>
        <span id="tw_175" class="t s2_175">
          ажиллагаа гэж ойлгоно.{" "}
        </span>
        <span id="tx_175" class="t s5_175">
          Төлөвлөлтөд баримтлах зарчим{" "}
        </span>
        <span id="ty_175" class="t s4_175">
          1.{" "}
        </span>
        <span id="tz_175" class="t s2_175">
          Боловсролын салбарын бодлого, хууль эрх зүйд нийцсэн байх{" "}
        </span>
        <span id="t10_175" class="t s4_175">
          2.{" "}
        </span>
        <span id="t11_175" class="t s2_175">
          Дэлхий нийтийн хөгжлийн чиг хандлагад нийцсэн байх{" "}
        </span>
        <span id="t12_175" class="t s4_175">
          3.{" "}
        </span>
        <span id="t13_175" class="t s2_175">
          Нэгдмэл, цогц, харилцан уялдаатай байх{" "}
        </span>
        <span id="t14_175" class="t s4_175">
          4.{" "}
        </span>
        <span id="t15_175" class="t s2_175">
          Нөөцөд үндэслэсэн байх{" "}
        </span>
        <span id="t16_175" class="t s4_175">
          5.{" "}
        </span>
        <span id="t17_175" class="t s2_175">
          Ил тод, нээлттэй байх{" "}
        </span>
        <span id="t18_175" class="t s4_175">
          6.{" "}
        </span>
        <span id="t19_175" class="t s2_175">
          Судалгаа, шинжилгээнд үндэслэсэн байх{" "}
        </span>
        <span id="t1a_175" class="t s4_175">
          7.{" "}
        </span>
        <span id="t1b_175" class="t s2_175">
          Зорилго, зорилт хэмжигдэхүйц байх{" "}
        </span>
        <span id="t1c_175" class="t s4_175">
          8.{" "}
        </span>
        <span id="t1d_175" class="t s2_175">
          Шалгуур үзүүлэлт нь зорилготой нийцэж байх{" "}
        </span>
        <span id="t1e_175" class="t s4_175">
          9.{" "}
        </span>
        <span id="t1f_175" class="t s2_175">
          Төлөвлөлт нь үр дүнд чиглэсэн байх{" "}
        </span>
        <span id="t1g_175" class="t s4_175">
          10.{" "}
        </span>
        <span id="t1h_175" class="t s2_175">
          Төлөвлөлтийн дээрээс доош, доороос дээш чиглэх тэнцвэрийг хангах{" "}
        </span>
        <span id="t1i_175" class="t s4_175">
          11.{" "}
        </span>
        <span id="t1j_175" class="t s2_175">
          Бүх талын оролцоог хангасан байх{" "}
        </span>
        <span id="t1k_175" class="t s4_175">
          12.{" "}
        </span>
        <span id="t1l_175" class="t s2_175">
          Түншлэлийг зохистойгоор хангах{" "}
        </span>
      </div>
    </div>
  );
}
