import React from "react";
export default function TwentySeven() {
  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    width: "762px",
    height: "1082px",
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0)",
    WebkitUserSelect: "none",
  };

  const pdfStyle = {
    width: "762px",
    height: "1082px",
    MozTransform: "scale(1)",
    zIndex: 0,
  };
  return (
    <div id="p2" style={containerStyle}>
      <style class="shared-css" type="text/css">
        {`.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}`}
      </style>
      <style type="text/css">
        {`#t1_27{left:676px;bottom:58px;letter-spacing:-0.1px;}
#t2_27{left:674px;bottom:59px;letter-spacing:0.16px;}
#t3_27{left:87px;bottom:941px;letter-spacing:-0.16px;word-spacing:1.44px;}
#t4_27{left:87px;bottom:921px;letter-spacing:-0.12px;word-spacing:3.87px;}
#t5_27{left:87px;bottom:901px;letter-spacing:-0.13px;word-spacing:0.04px;}
#t6_27{left:87px;bottom:868px;letter-spacing:-0.13px;word-spacing:-0.17px;}
#t7_27{left:87px;bottom:848px;letter-spacing:-0.15px;word-spacing:4.16px;}
#t8_27{left:87px;bottom:827px;letter-spacing:-0.15px;word-spacing:8.31px;}
#t9_27{left:87px;bottom:807px;letter-spacing:-0.14px;word-spacing:4.57px;}
#ta_27{left:87px;bottom:787px;letter-spacing:-0.14px;word-spacing:6.39px;}
#tb_27{left:87px;bottom:767px;letter-spacing:-0.12px;word-spacing:3px;}
#tc_27{left:87px;bottom:747px;letter-spacing:-0.11px;word-spacing:0.01px;}
#td_27{left:87px;bottom:714px;letter-spacing:-0.12px;word-spacing:0.71px;}
#te_27{left:87px;bottom:693px;letter-spacing:-0.2px;word-spacing:5.53px;}
#tf_27{left:87px;bottom:673px;letter-spacing:-0.14px;word-spacing:5.48px;}
#tg_27{left:87px;bottom:653px;letter-spacing:-0.16px;word-spacing:7.68px;}
#th_27{left:87px;bottom:633px;letter-spacing:-0.13px;word-spacing:2.42px;}
#ti_27{left:87px;bottom:613px;letter-spacing:-0.13px;word-spacing:5.4px;}
#tj_27{left:87px;bottom:593px;letter-spacing:-0.13px;word-spacing:0.32px;}
#tk_27{left:87px;bottom:572px;letter-spacing:-0.15px;word-spacing:-0.33px;}
#tl_27{left:87px;bottom:552px;letter-spacing:-0.17px;word-spacing:3.52px;}
#tm_27{left:87px;bottom:532px;letter-spacing:-0.14px;word-spacing:4.2px;}
#tn_27{left:87px;bottom:512px;letter-spacing:-0.13px;word-spacing:0.77px;}
#to_27{left:87px;bottom:492px;letter-spacing:-0.11px;}
#tp_27{left:87px;bottom:459px;letter-spacing:-0.1px;}
#tq_27{left:179px;bottom:459px;letter-spacing:-0.1px;}
#tr_27{left:264px;bottom:459px;letter-spacing:-0.16px;}
#ts_27{left:361px;bottom:459px;letter-spacing:-0.1px;}
#tt_27{left:478px;bottom:459px;letter-spacing:-0.09px;}
#tu_27{left:571px;bottom:459px;letter-spacing:-0.21px;}
#tv_27{left:87px;bottom:438px;letter-spacing:-0.12px;word-spacing:1.67px;}
#tw_27{left:87px;bottom:418px;letter-spacing:-0.15px;word-spacing:-1.87px;}
#tx_27{left:87px;bottom:398px;letter-spacing:-0.15px;word-spacing:-0.01px;}
#ty_27{left:87px;bottom:378px;letter-spacing:-0.16px;word-spacing:4.4px;}
#tz_27{left:87px;bottom:358px;letter-spacing:-0.12px;word-spacing:4.68px;}
#t10_27{left:87px;bottom:338px;letter-spacing:-0.14px;word-spacing:0.04px;}
#t11_27{left:87px;bottom:304px;letter-spacing:-0.12px;word-spacing:-2.48px;}
#t12_27{left:87px;bottom:284px;letter-spacing:-0.21px;word-spacing:1.26px;}
#t13_27{left:87px;bottom:264px;letter-spacing:-0.16px;word-spacing:2.76px;}
#t14_27{left:87px;bottom:244px;letter-spacing:-0.15px;word-spacing:0.45px;}
#t15_27{left:87px;bottom:224px;letter-spacing:-0.22px;word-spacing:1.61px;}
#t16_27{left:87px;bottom:204px;letter-spacing:-0.12px;word-spacing:-1.65px;}
#t17_27{left:87px;bottom:183px;letter-spacing:-0.16px;word-spacing:0.07px;}
#t18_27{left:87px;bottom:150px;letter-spacing:-0.15px;word-spacing:5.71px;}
#t19_27{left:87px;bottom:130px;letter-spacing:-0.14px;word-spacing:3.76px;}

.s0_27{font-size:17px;font-family:MogulFreeset_16-;color:#000;}
.s1_27{font-size:18px;font-family:MinionPro-Regular_173;color:#FFF;}
.s2_27{font-size:17px;font-family:MogulArial_16s;color:#000;}
.t.m0_27{transform:matrix(-1,0,0,1,0,0);}`}
      </style>
      <style id="fonts27" type="text/css">
        {`@font-face {
	font-family: MinionPro-Regular_173;
	src: url("fonts/MinionPro-Regular_173.woff") format("woff");
}

@font-face {
	font-family: MogulArial_16s;
	src: url("fonts/MogulArial_16s.woff") format("woff");
}

@font-face {
	font-family: MogulFreeset_16-;
	src: url("fonts/MogulFreeset_16-.woff") format("woff");
}`}
      </style>
      <div id="pg2Overlay" style={overlayStyle}></div>
      <div id="pg27" style={{ WebkitUserSelect: "none" }}>
        <object
          width="909"
          height="1286"
          data="27/27.svg"
          type="image/svg+xml"
          id="pdf27"
          style={pdfStyle}
        ></object>
      </div>
      <div class="text-container">
        <span id="t2_27" class="t s1_27">
          27{" "}
        </span>
        <span id="t3_27" class="t s2_27">
          дүрслэв. Тухайлбал, сурагчдад тулгамдаж буй асуудал багшийн хөгжлийн{" "}
        </span>
        <span id="t4_27" class="t s2_27">
          хэрэгцээ, багшийн хөгжлийн хэрэгцээ нь сургуулийн хэрэгцээ болох гэх{" "}
        </span>
        <span id="t5_27" class="t s2_27">
          мэтээр нэг нь нөгөөгөөсөө урган гарах уялдаа холбоог илэрхийлсэн
          болно.{" "}
        </span>
        <span id="t6_27" class="t s2_27">
          Мөн энэ бүрэлдэхүүн хэсгүүд одоо ямар түвшинд байгаа, богино хугацаанд{" "}
        </span>
        <span id="t7_27" class="t s2_27">
          буюу тухайн жилдээ хэрхэн өөрчлөгдөж сайжрахыг Үнэлэх-Сайжруулах-{" "}
        </span>
        <span id="t8_27" class="t s2_27">
          Төлөвлөх-Хэрэгжүүлэх (цаашид ҮСТХ гэх) мөчлөгөөр хэвтээ чиглэлд{" "}
        </span>
        <span id="t9_27" class="t s2_27">
          эргэхийг илэрхийлсэн бол цаашид дунд болон урт хугацаандаа хэрхэн{" "}
        </span>
        <span id="ta_27" class="t s2_27">
          хувьсан өөрчлөгдөж, хөгжиж сайжрахыг сууриас орой хүртэл ороомог{" "}
        </span>
        <span id="tb_27" class="t s2_27">
          маягаар тасралтгүй хөгжиж, дараагийн түвшиндээ хүрэхдээ мөн л ҮСТХ{" "}
        </span>
        <span id="tc_27" class="t s2_27">
          мөчлөгөөр үйл явц өрнөхийг сумаар зааж дүрсэлсэн болно.{" "}
        </span>
        <span id="td_27" class="t s2_27">
          Суурийг оройтой холбосон, тасалдсан шугамаар интеграцчилах үйл явцыг{" "}
        </span>
        <span id="te_27" class="t s2_27">
          буюу салангид байдлаас хамтарсан хэлбэрт, цаашлаад нэгдмэл бүхэл{" "}
        </span>
        <span id="tf_27" class="t s2_27">
          байдалд шилжин хувьсан өөрчлөгдөх үйл явцыг илэрхийллээ. Өөрөөр{" "}
        </span>
        <span id="tg_27" class="t s2_27">
          хэлбэл, ирээдүйн чиг хандлагаас харахад сурагчдын мэдлэг чадвар,{" "}
        </span>
        <span id="th_27" class="t s2_27">
          хандлага төлөвшил, бие бялдар, сэтгэл зүйн эрүүл мэнд нь тэдний сайн{" "}
        </span>
        <span id="ti_27" class="t s2_27">
          сайхан байдлаар, цогц чадамжаар илэрхийлэгдэнэ. Үүнтэй нэгэн адил{" "}
        </span>
        <span id="tj_27" class="t s2_27">
          багш нарын арга зүй, мэдлэг чадвар, харилцаа хандлага ч хөгжлийн явцад{" "}
        </span>
        <span id="tk_27" class="t s2_27">
          улам цогц, нэгдмэл шинжтэй болох юм. Мөн багш нарын хамтын ажиллагаа{" "}
        </span>
        <span id="tl_27" class="t s2_27">
          сайжирч, дунд хугацаандаа баг, бүлгээрээ хамтрах байдал нь нэмэгдэх,{" "}
        </span>
        <span id="tm_27" class="t s2_27">
          урт хугацаандаа улам бүр нэгдэж, алсын хараандаа хүрэхийн тулд нэг{" "}
        </span>
        <span id="tn_27" class="t s2_27">
          итгэл үнэмшилд зангидагдаж, сургууль бүхлээрээ ажиллах арга хандлагыг{" "}
        </span>
        <span id="to_27" class="t s2_27">
          илэрхийлнэ.{" "}
        </span>
        <span id="tp_27" class="t s2_27">
          Өнөөгийн{" "}
        </span>
        <span id="tq_27" class="t s2_27">
          түвшинд{" "}
        </span>
        <span id="tr_27" class="t s2_27">
          сургалтын{" "}
        </span>
        <span id="ts_27" class="t s2_27">
          хөтөлбөрүүд{" "}
        </span>
        <span id="tt_27" class="t s2_27">
          салангид,{" "}
        </span>
        <span id="tu_27" class="t s2_27">
          судлагдахуун{" "}
        </span>
        <span id="tv_27" class="t s2_27">
          бүрийн суралцахуйн зорилтуудыг хэрэгжүүлэх, шинжлэх ухааны салангид{" "}
        </span>
        <span id="tw_27" class="t s2_27">
          мэдлэгийг эзэмшүүлэх хэв маяг давамгай байгаа бол урт хугацаандаа
          хичээл{" "}
        </span>
        <span id="tx_27" class="t s2_27">
          хоорондын уялдаа холбоо сайжирч, сурагчдын сонирхол, ялгаатай байдал,{" "}
        </span>
        <span id="ty_27" class="t s2_27">
          сургууль, орон нутгийн онцлог, нийгмийн хэрэгцээг хангахад чиглэгдсэн{" "}
        </span>
        <span id="tz_27" class="t s2_27">
          сурагч төвтэй сургуулийн сургалтын хөтөлбөр (school based curriculum){" "}
        </span>
        <span id="t10_27" class="t s2_27">
          болж интеграцчилагдах хандлагыг илэрхийлсэн болно.{" "}
        </span>
        <span id="t11_27" class="t s2_27">
          Сургуулийг материаллаг болон үйлийн, соёлын орчин, суралцахуйн
          зангилаа{" "}
        </span>
        <span id="t12_27" class="t s2_27">
          талбар буюу орон нутаг, талуудын оролцоог хангасан нэгдмэл орчин болж{" "}
        </span>
        <span id="t13_27" class="t s2_27">
          хувьсан өөрчлөгдөнө гэж үзэж байна. Сургуулийн ханаар тусгаарлагдсан{" "}
        </span>
        <span id="t14_27" class="t s2_27">
          анги танхим, сургуулиар хязгаарлагдсан боловсрол эзэмших үйл явц дунд,{" "}
        </span>
        <span id="t15_27" class="t s2_27">
          урт хугацаандаа улам нээлттэй, нэгдмэл болж орон нутаг, олон нийт,
          эцэг{" "}
        </span>
        <span id="t16_27" class="t s2_27">
          эхийн оролцоо дэмжлэг нэмэгдэж, бэхэжсэн орон зай, суралцахуйн
          зангилаа{" "}
        </span>
        <span id="t17_27" class="t s2_27">
          талбар болно гэдгийг мөн загварт тусгав.{" "}
        </span>
        <span id="t18_27" class="t s2_27">
          Бага, суурь, бүрэн дунд боловсролын түвшний сурагчдад тулгарч буй{" "}
        </span>
        <span id="t19_27" class="t s2_27">
          асуудал, тэдгээрийг шийдэх арга зам, хүрэх үр дүн, үнэлэх шалгуур нь{" "}
        </span>
      </div>
    </div>
  );
}
